import { Platform, StyleSheet } from 'react-native';
import scale from '../../../../../../common/theme';
import theme from '../../../../../../common/default-theme';

const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  // Popups
  popupBackground: {
    width: '100%',
    position: 'absolute',
    height: scale.deviceHeight,
  },
  popupContainer: {
    width: 240,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.s,
  },
  popupOptions: {
    maxHeight: 300,
    paddingVertical: 10,
    borderWidth: isWeb ? 0 : 2,
    borderColor: theme.colors.grey3,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  popupOption: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  popupLabel: {
    flex: 1,
  },
  // Tile – Common
  container: {
    width: 140,
    height: 74,
    overflow: 'hidden',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
  },
  // eslint-disable-next-line react-native/no-color-literals
  disclosure: {
    width: 26,
    height: 74,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  textView: {
    flex: 1,
    height: 74,
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
  },
  triangle: {
    top: 4,
    left: 30,
    width: 0,
    height: 0,
    position: 'absolute',
    borderTopWidth: 14,
    borderRightWidth: 14,
    borderColor: theme.colors.transparent,
    borderTopColor: theme.colors.white,
  },
  circle: {
    top: 6,
    right: 6,
    width: 8,
    height: 8,
    borderRadius: 8,
    position: 'absolute',
    backgroundColor: theme.colors.white,
  },
  // Tile – Back
  tileBack: {
    width: 140,
    height: 74,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: theme.colors.grey3,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  // Tile – Pagination
  tilePagination: {
    width: 140,
    height: 74,
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: theme.radius.s,
    borderWidth: 1,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.grey3,
  },
  btnPagination: {
    flex: 0.495,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
