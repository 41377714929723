import {
  DateRangeGranularity,
  DateRangeFilterPresets,
  ReportFieldDef,
  ColumnType,
  DateRangeFilterType,
  DateRange,
  ReportFilters,
  Widget,
  FilterValue,
  WidgetChartType,
  DateRangeFilter,
  ShiftFilter,
  ReportSegment,
} from '@oolio-group/domain';
import {
  IMap,
  ReportType,
  ReportTableColumn,
  DateRangeFilterInput,
  VarianceColor,
  HelperText,
} from './types';
import { columnStyleOptions } from '../../../components/DataGrid/DataGrid';
import {
  add,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import * as storage from '../../../storage/interface';
import { assign, isEqual, pick, keys } from 'lodash';
import { cloneJSON } from '@oolio-group/client-utils';
import { utcToZonedTime, format } from 'date-fns-tz';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';

export const CUSTOM_DATE_DISPLAY_FORMAT = 'yyyy-MM-dd';

export const BLANK_LABEL_COLOR = '#a6a6a6';

export const MAX_ROW_HEIGHT = 45;

export const TOOLTIP_GAP = -5;

export const EXPORT_COLUMN = {
  key: ColumnType.EXPORT,
  title: ColumnType.EXPORT,
  flex: 1,
  type: ColumnType.EXPORT,
  style: columnStyleOptions.OPTION,
};

export const PREVIEW_COLUMN = {
  key: ColumnType.PREVIEW,
  title: ColumnType.PREVIEW,
  flex: 1,
  type: ColumnType.PREVIEW,
  style: columnStyleOptions.OPTION,
};

export const REPORT_TYPES: string[] = [
  ReportType.DAILY,
  ReportType.WEEKLY,
  ReportType.MONTHLY,
];

export const SKIP_DATE_RANGE_PRESET: IMap<string[]> = {
  [ReportType.WEEKLY]: [
    DateRangeFilterPresets.TODAY,
    DateRangeFilterPresets.YESTERDAY,
    DateRangeFilterPresets.LAST_7_DAYS,
  ],
  [ReportType.MONTHLY]: [
    DateRangeFilterPresets.TODAY,
    DateRangeFilterPresets.YESTERDAY,
    DateRangeFilterPresets.THIS_WEEK,
    DateRangeFilterPresets.LAST_WEEK,
    DateRangeFilterPresets.LAST_7_DAYS,
    DateRangeFilterPresets.LAST_30_DAYS,
  ],
};

export const WIDGET_GRANULARITY: string[] = [
  DateRangeGranularity.HOUR,
  DateRangeGranularity.DAY,
  DateRangeGranularity.WEEK,
  DateRangeGranularity.MONTH,
];

export const GRANULARITY_AND_REPORT_TYPE_MAPPER = {
  [DateRangeGranularity.DAY]: ReportType.DAILY,
  [DateRangeGranularity.WEEK]: ReportType.WEEKLY,
  [DateRangeGranularity.MONTH]: ReportType.MONTHLY,
} as Record<string, ReportType>;

export const GROUP_BY_AND_REPORT_TYPE_MAPPER = {
  [ReportType.DAILY]: ReportSegment.GROUP_BY_DAY,
  [ReportType.WEEKLY]: ReportSegment.GROUP_BY_WEEK,
  [ReportType.MONTHLY]: ReportSegment.GROUP_BY_MONTH,
};

export const GRANULARITY_FORMATS: IMap<string> = {
  hour: 'h aaa',
  day: 'MMM d, yy',
  week: 'MMM d',
  month: 'MMM yy',
};

export const CHART_CONTAINER_STYLE = {
  height: '100%',
  backgroundColor: '#fafafa',
  marginLeft: 5,
  marginRight: 5,
};

export const DAY_FORMAT = 'dd/MM/yyyy hh:mm aaa';

export const COMMON_CHART_STYLES = {
  labels: {
    fontSize: 11,
  },
};

export const CHART_COMMON_COLORS = {
  stroke: '#e5e5e5',
};

export const LEGEND_BOTTOM_GAP = 20;

export const CHART_X_AXIS_STYLE = {
  axis: {
    stroke: CHART_COMMON_COLORS.stroke,
    strokeWidth: 1.5,
  },
  ticks: {
    stroke: CHART_COMMON_COLORS.stroke,
    strokeWidth: 1,
    size: 5,
  },
  tickLabels: {
    fontSize: COMMON_CHART_STYLES.labels.fontSize,
    padding: 1,
  },
};

export const CHART_Y_AXIS_STYLE = {
  grid: {
    stroke: CHART_COMMON_COLORS.stroke,
    strokeWidth: 1,
  },
  ticks: { strokeWidth: 0 },
  axis: { strokeWidth: 0 },
  tickLabels: { fontSize: COMMON_CHART_STYLES.labels.fontSize },
};

export const CHART_TOOLTIP_STYLE = {
  style: {
    fontSize: 10,
  },
  flyoutStyle: {
    fill: '#ffffff',
    stroke: CHART_COMMON_COLORS.stroke,
    strokeWidth: 1,
  },
};

export const CHART_COLOR_SCALE = [
  '#2196f3',
  '#ffc107',
  '#673ab7',
  '#4caf50',
  '#e53935',
  '#607d8b',
  '#a6a6a6',
];

export const CHART_LEGEND_STYLE = {
  style: {
    labels: COMMON_CHART_STYLES.labels,
  },
  symbolSpace: 4,
  legendSpace: 22,
};

export const BAR_CHART_STYLE = {
  data: {
    fill: '#2196f3',
  },
  labels: {
    fontSize: COMMON_CHART_STYLES.labels.fontSize,
    fontWeight: 500,
  },
};

export const PIE_POLAR_AXIS = {
  axis: { strokeWidth: 0 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fill: 'transparent' },
};

export const MULTI_BAR_COLOR_SCALE = ['#2196f3', '#3f51b5'];

export const DEFAULT_CHART_WRAPPER = {
  height: 300,
  marginVertical: 5,
};

export const DEFAULT_METRIC_WRAPPER = {
  height: 85,
  marginVertical: DEFAULT_CHART_WRAPPER.marginVertical,
};

export const DEFAULT_CARD_WRAPPER = {
  height: 150,
  marginVertical: 5,
};

export const BAR_VARIANCE_COLOR_SCALE = [
  VarianceColor.RECORDED,
  VarianceColor.DEFAULT,
  VarianceColor.POSITIVE,
  VarianceColor.NEGATIVE,
];
export const SHIFT_REPORT_COLUMNS: ReportTableColumn[] = [
  {
    key: 'shiftNumber',
    title: 'backOfficeShifts.shiftNumber',
    flex: 1,
    style: columnStyleOptions.SMALL,
    showByDefault: true,
  },
  {
    key: 'createdAt',
    title: 'backOfficeShifts.createdAt',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    type: ColumnType.DATE,
    showByDefault: true,
  },
  {
    key: 'closedAt',
    title: 'backOfficeShifts.closedAt',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    type: ColumnType.DATE,
    showByDefault: true,
  },
  {
    key: 'store',
    title: 'backOfficeShifts.store',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'device',
    title: 'backOfficeShifts.device',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'createdBy',
    title: 'backOfficeShifts.createdBy',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'closedBy',
    title: 'backOfficeShifts.closedBy',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'totalCounted',
    title: 'backOfficeShifts.totalCounted',
    flex: 1,
    style: columnStyleOptions.SMALL,
    type: ColumnType.MONEY,
    showByDefault: true,
  },
  {
    key: 'totalRecorded',
    title: 'backOfficeShifts.totalRecorded',
    flex: 1,
    style: columnStyleOptions.SMALL,
    type: ColumnType.MONEY,
    showByDefault: true,
  },
  {
    key: 'variance',
    title: 'backOfficeShifts.variance',
    flex: 1,
    style: columnStyleOptions.SMALL,
    type: ColumnType.MONEY,
    showByDefault: true,
  },
];

export const SHIFT_REPORT_FILTERS = [
  { key: 'venues', title: 'backOfficeReports.filters.venues' },
  { key: 'stores', title: 'backOfficeReports.filters.stores' },
  { key: 'devices', title: 'backOfficeReports.filters.devices' },
  { key: 'staff', title: 'backOfficeReports.filters.staff' },
];

export const WORKLOG_REPORT_COLUMNS: ReportTableColumn[] = [
  {
    key: 'userName',
    title: 'backOfficeWorklogs.user',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'clockInTime',
    title: 'backOfficeWorklogs.startTime',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'clockOutTime',
    title: 'backOfficeWorklogs.finishTime',
    flex: 1,
    style: columnStyleOptions.REGULAR,
    showByDefault: true,
  },
  {
    key: 'breakTime',
    title: 'backOfficeWorklogs.breakTime',
    flex: 1,
    style: columnStyleOptions.SMALL,
    type: ColumnType.TIME,
    showByDefault: true,
  },
  {
    key: 'totalTime',
    title: 'backOfficeWorklogs.totalTime',
    flex: 1,
    style: columnStyleOptions.SMALL,
    type: ColumnType.TIME,
    showByDefault: true,
  },
  {
    key: 'workTime',
    title: 'backOfficeWorklogs.workTime',
    flex: 1,
    style: columnStyleOptions.SMALL,
    type: ColumnType.TIME,
    showByDefault: true,
  },
];

export const WORKLOG_REPORT_FILTERS = [
  { key: 'venues', title: 'backOfficeReports.filters.venues' },
  { key: 'stores', title: 'backOfficeReports.filters.stores' },
  { key: 'devices', title: 'backOfficeReports.filters.devices' },
  { key: 'staff', title: 'backOfficeReports.filters.staff' },
];

export const SIMILAR_RANGE_PRESETS = {
  [ReportType.DAILY]: [
    DateRangeFilterPresets.TODAY,
    DateRangeFilterPresets.YESTERDAY,
  ],
  [ReportType.WEEKLY]: [
    DateRangeFilterPresets.THIS_WEEK,
    DateRangeFilterPresets.LAST_WEEK,
  ],
  [ReportType.MONTHLY]: [
    DateRangeFilterPresets.THIS_MONTH,
    DateRangeFilterPresets.LAST_MONTH,
  ],
};

export type DayOfWeekValue = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const TRADING_PERIOD_KEY = 'tradingPeriods';
export const convertToZoneTime = (dateTime: string, timeZone?: string) => {
  return utcToZonedTime(new Date(dateTime), timeZone as string);
};

const formatDistanceLocale: IMap<string> = {
  xHours: '%{x}h',
  xMinutes: '%{x}m',
  xSeconds: '%{x}s',
};

const shortEnLocale: Locale = {
  formatDistance: (key, val) => formatDistanceLocale[key].replace('%{x}', val),
};

export const formatMinutesToHourAndMinutes = (
  timeInMInutes: number,
): string => {
  return formatDuration(
    intervalToDuration({
      start: 0,
      end: timeInMInutes * 60 * 1000,
    }),
    {
      format: ['hours', 'minutes'],
      locale: shortEnLocale,
      delimiter: ' ',
    },
  );
};

export const getWeekRanges = (
  zoneTime: Date,
  minMaxRange?: [string, string],
) => {
  const weekStartsDay = zoneTime.getDay() as DayOfWeekValue;
  let startDate = startOfWeek(zoneTime, {
    weekStartsOn: weekStartsDay,
  });
  let endDate = endOfWeek(zoneTime, {
    weekStartsOn: weekStartsDay,
  });

  if (minMaxRange) {
    if (startDate < new Date(minMaxRange[0])) {
      startDate = new Date(minMaxRange[0]);
    }

    if (endDate > new Date(minMaxRange[1])) {
      endDate = new Date(minMaxRange[1]);
    }
  }

  return [startDate, endDate];
};

export const convertDateByFormat = (
  dateText: string,
  granularity: DateRangeGranularity,
  textFormat: string,
  timeZone = '',
  minMaxRange?: [string, string],
) => {
  if (!dateText.endsWith('Z')) {
    dateText = dateText + 'Z';
  }
  const formatOpt = { timeZone };
  const zoneTime = convertToZoneTime(dateText, timeZone);
  if (granularity === DateRangeGranularity.HOUR) {
    const start = format(zoneTime, textFormat, formatOpt);
    const end = format(add(zoneTime, { hours: 1 }), textFormat, formatOpt);
    return `${start} - ${end}`;
  } else if (granularity === DateRangeGranularity.WEEK) {
    const [startDate, endDate] = getWeekRanges(zoneTime, minMaxRange);
    const start = format(startDate, textFormat, formatOpt);
    const end = format(endDate, textFormat, formatOpt);
    return `${start} - ${end}`;
  }
  return format(zoneTime, textFormat, formatOpt);
};

export const transformFieldsToTableColumn = (fields: ReportFieldDef[]) => {
  return fields
    .filter(field => !field.hide)
    .map((field, index) => {
      return {
        key: field.key === '' ? `${index}` : field.key,
        dataKey: field.key === '' ? `${index}` : field.key,
        title: field.text,
        flex: 1,
        type: field.type || ColumnType.NUMBER,
        style: field.size || columnStyleOptions.SMALL,
        showByDefault: field.showByDefault === false ? false : true,
        blankValue: field.blankValue || '',
        cellAlignmentStyle: field.cellAlignmentStyle,
      } as ReportTableColumn;
    });
};

export const getMetricByType = (
  value: string,
  appendCurrency: (x: string) => string,
  type?: ColumnType,
) => {
  let updatedText = value;
  switch (type) {
    case ColumnType.MONEY:
      updatedText = appendCurrency(`${value}`);
      break;
    case ColumnType.PERCENT:
      updatedText = `${updatedText}%`;
      break;
  }

  return updatedText;
};

export const getLabelAccordingToType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datum: any,
  appendCurrency: (x: string) => string,
  type?: ColumnType,
) => {
  return `${datum.x}\n${getMetricByType(datum.y, appendCurrency, type)}`;
};

export const buildFilterObject = (value: DateRangeFilterInput) => {
  if (value.type === DateRangeFilterType.FILTER_BY_PRESET) {
    delete value.range;
  } else {
    const rangeOne =
      (value?.range && (value.range[0].split(' ') as string[])) || [];
    const rangeTwo =
      (value?.range && (value.range[1].split(' ') as string[])) || [];

    let startDate = rangeOne[0];
    let startTime = rangeOne[1];
    let endDate = rangeTwo[0];
    let endTime = rangeTwo[1];

    if (value?.startDate && value.startDate !== '')
      startDate = value.startDate as string;
    if (value?.endDate && value.endDate !== '')
      endDate = value.endDate as string;
    if (value?.startTime && value.startTime !== '')
      startTime = value.startTime as string;
    if (value?.endTime && value.endTime !== '')
      endTime = value.endTime as string;

    if (value?.time && value?.time !== '') {
      startTime = value.time.split('-')[0] as string;
      endTime = value.time.split('-')[1] as string;
    }

    let rangeOneText = startDate;
    if (startTime) rangeOneText += ` ${startTime}`;
    let rangeTwoText = endDate;
    if (endTime) rangeTwoText += ` ${endTime}`;

    value.range = [rangeOneText, rangeTwoText];
  }
  return value;
};

export const getDateRangeByPreset = (
  date: Date,
  presetType: DateRangeFilterPresets,
) => {
  let startDate, endDate;

  switch (presetType) {
    case DateRangeFilterPresets.TODAY:
      startDate = startOfDay(date).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.YESTERDAY:
      const yesterday = subDays(date, 1);
      startDate = startOfDay(yesterday).getTime();
      endDate = endOfDay(yesterday).getTime();
      break;
    case DateRangeFilterPresets.THIS_WEEK:
      startDate = startOfWeek(date).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.THIS_MONTH:
      startDate = startOfMonth(date).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.THIS_QUARTER:
      startDate = startOfQuarter(date).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.THIS_YEAR:
      startDate = startOfYear(date).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.LAST_7_DAYS:
      startDate = startOfDay(subDays(date, 7)).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.LAST_30_DAYS:
      startDate = startOfDay(subDays(date, 30)).getTime();
      endDate = date.getTime();
      break;
    case DateRangeFilterPresets.LAST_WEEK:
      const lastWeek = subWeeks(date, 1);
      startDate = startOfWeek(lastWeek).getTime();
      endDate = endOfWeek(lastWeek).getTime();
      break;
    case DateRangeFilterPresets.LAST_MONTH:
      const lastMonth = subMonths(date, 1);
      startDate = startOfMonth(lastMonth).getTime();
      endDate = endOfMonth(lastMonth).getTime();
      break;
    case DateRangeFilterPresets.LAST_QUARTER:
      const lastQuarter = subQuarters(date, 1);
      startDate = startOfQuarter(lastQuarter).getTime();
      endDate = endOfQuarter(lastQuarter).getTime();
      break;
    case DateRangeFilterPresets.LAST_YEAR:
      const lastYear = subYears(date, 1);
      startDate = startOfYear(lastYear).getTime();
      endDate = endOfYear(lastYear).getTime();
      break;
  }

  return {
    startDate,
    endDate,
  } as DateRange;
};

const getUpdatedDateRanges = (
  current: DateRangeFilter[],
  updated: DateRangeFilter[],
) => {
  const clonedCurrent = cloneJSON(current) as DateRangeFilter[];
  return clonedCurrent.map((dateRange, index) => {
    return assign(
      dateRange,
      pick(updated[index], [...keys(dateRange), 'range', 'granularity']),
    );
  });
};

export const setReportStates = async (
  widgets: Widget[],
  helperText: HelperText,
  primaryWidget: WidgetChartType,
  setWidgets: (widgets: Widget[]) => void,
  setFilters: (filters: FilterValue) => void,
  setDateRangeFilters: (dateRangeFilters: DateRangeFilterInput) => void,
) => {
  const cachedFilters: ReportFilters | undefined = await storage.getItem(
    helperText,
  );
  if (cachedFilters) {
    setWidgets(
      widgets.map(widget =>
        cloneJSON({
          ...widget,
          query: {
            ...widget.query,
            filters: cachedFilters.filters,
            dateRangeFilters: getUpdatedDateRanges(
              widget.query.dateRangeFilters as DateRangeFilter[],
              cachedFilters.dateRangeFilters as DateRangeFilter[],
            ),
          },
        }),
      ) as Widget[],
    );
    setFilters(cloneJSON(cachedFilters.filters));
    setDateRangeFilters(
      cloneJSON(cachedFilters?.dateRangeFilters?.[0] as DateRangeFilterInput),
    );
  } else {
    setWidgets(cloneJSON(widgets as Widget[]));
    const widget = widgets.find(widget => widget.chartType === primaryWidget);
    setFilters(cloneJSON(widget?.query?.filters as FilterValue));
    setDateRangeFilters(
      cloneJSON(widget?.query?.dateRangeFilters?.[0] as DateRangeFilterInput),
    );
  }
};

const PROP_KEYS_TO_COMPARE = [
  'columns',
  'granularityFormats',
  'widget',
  'filters',
  'updateCount',
  'loading',
  'data',
];

export const arePropsEqual = (prevProps: Object, nextProps: Object) => {
  let propsEqual = true;
  PROP_KEYS_TO_COMPARE.forEach(key => {
    if (key === 'updateCount') {
      if (prevProps[key as keyof Object] !== nextProps[key as keyof Object]) {
        propsEqual = false;
      }
    } else if (
      prevProps[key as keyof Object] !== undefined &&
      !isEqual(prevProps[key as keyof Object], nextProps[key as keyof Object])
    ) {
      propsEqual = false;
    }
  });
  return propsEqual;
};

const TWELVE_HOUR_FORMAT = 'h a';

export const getHourRangeIn12HrsFormat = (hour: number) => {
  const today = new Date();
  today.setHours(hour);

  const start = format(today, TWELVE_HOUR_FORMAT);
  const end = format(add(today, { hours: 1 }), TWELVE_HOUR_FORMAT);
  return { start, end };
};

export const generateFilterData = (
  filter: FilterValue,
  dateRangeFilters: DateRangeFilterInput,
) => {
  const filtersToApply: ShiftFilter = {};

  for (const key in filter) {
    if (filter[key as keyof FilterValue] instanceof Array) {
      filtersToApply[key as keyof FilterValue] =
        filter[key as keyof FilterValue];
    }
  }

  if (dateRangeFilters && dateRangeFilters?.value?.trim() !== '') {
    filtersToApply.dateRange = getDateRangeByPreset(
      new Date(Date.now()),
      dateRangeFilters?.value as DateRangeFilterPresets,
    );
  } else if (
    dateRangeFilters &&
    dateRangeFilters?.range &&
    dateRangeFilters?.range?.length === 2
  ) {
    const startTxt = dateRangeFilters?.range[0];
    const endTxt = dateRangeFilters?.range[1];

    let startDate, endDate;

    switch (dateRangeFilters?.type) {
      case DateRangeFilterType.CUSTOM_DATES:
        startDate = startOfDay(new Date(startTxt)).getTime();
        endDate = endOfDay(new Date(endTxt)).getTime();
        break;
      case DateRangeFilterType.FILTER_BY_TIME:
      case DateRangeFilterType.FILTER_BY_TRADING_PERIOD:
        startDate = new Date(startTxt).getTime();
        endDate = new Date(`${endTxt}:59`).getTime();
        break;
    }

    filtersToApply.dateRange = {
      startDate,
      endDate,
    } as DateRange;
  }

  return filtersToApply;
};
