import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import InputToggle from '../../../../components/Shared/Inputs/InputToggle';
import { useOrganization } from '../../../../hooks/app/useOrganization';
import { DeveloperSettings } from '@oolio-group/domain';
import { useSession } from '../../../../hooks/app/useSession';
import Section from '../../../../components/Office/Section/Section';
import theme from '../../../../common/default-theme';
import { stripProperties } from '../../../../utils/stripObjectProps';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';

export const DeveloperScreen: React.FC = () => {
  const {
    createOrUpdateDeveloperToolsSettings,
    getOrgDeveloperSettings,
    developerSettings,
  } = useOrganization();
  const [form, setForm] = useState<DeveloperSettings>(
    developerSettings || {
      virtualSms: false,
      virtualEmail: false,
    },
  );
  const [session] = useSession();
  const currentorgId = session?.currentOrganization?.id ?? '';
  const { showNotification } = useNotification();
  const { translate } = useTranslation();

  useEffect(() => {
    getOrgDeveloperSettings(currentorgId);
  }, [getOrgDeveloperSettings, currentorgId]);

  useEffect(() => {
    if (developerSettings) {
      setForm(developerSettings);
    }
  }, [developerSettings]);

  const saveDeveloperToolsSettings = async () => {
    const res = await createOrUpdateDeveloperToolsSettings(
      stripProperties(form, '__typename'),
    );
    if (res) {
      showNotification({
        message: translate(
          'backOfficeSettings.virtualSettingSavedSuccessfully',
        ),
        success: true,
      });
    } else {
      showNotification({
        message: translate('backOfficeSettings.virtualSettingSavedFailed'),
        success: true,
      });
    }
  };

  const onChangeFormInput = useCallback((key, value) => {
    setForm(prevForm => ({
      ...prevForm,
      [key]: value,
    }));
  }, []);

  return (
    <ScreenLayout title="Developer | Oolio" onSave={saveDeveloperToolsSettings}>
      <Section title={translate('backOfficeSettings.virtualCommunication')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-virtual-sms"
            title={translate('backOfficeSettings.virtualSms')}
            isToggled={form.virtualSms || false}
            onToggle={() => {
              onChangeFormInput('virtualSms', !form.virtualSms);
            }}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-virtual-email"
            title={translate('backOfficeSettings.virtualEmail')}
            isToggled={form.virtualEmail}
            onToggle={() => {
              onChangeFormInput('virtualEmail', !form.virtualEmail);
            }}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
