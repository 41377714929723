/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView } from 'react-native';
import { Category } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { ProductItemType } from '../../types';
import styles from './Modals.styles';
import theme from '../../../../../../common/default-theme';
import modalStyles from '../../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import Search from '../../../../../../components/Shared/Search/Search';

export interface AddExistingProductsProps {
  onAddProductToPage: (input: ProductItemType[]) => void;
  productItems: ProductItemType[];
  categoryMaps: Record<string, Category>;
}

const AddExistingProducts: React.FC<AddExistingProductsProps> = ({
  productItems,
  onAddProductToPage,
}) => {
  const { translate } = useTranslation();

  const [selectedProducts, setSelectedProducts] = useState<
    Record<string, boolean>
  >({});
  const [searchValue, setSearchValue] = useState('');

  const filteredProducts: ProductItemType[] = productItems.filter(item =>
    item.name?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const { closeModal } = useModal();

  const onSelectProduct = useCallback((productId: string) => {
    setSelectedProducts(pre => ({
      ...pre,
      [productId]: !Boolean(pre[productId]),
    }));
  }, []);

  const onAddShowPageItemTypes = useCallback(() => {
    const selectedItems = productItems.filter(
      item => selectedProducts[item.id],
    );
    onAddProductToPage(selectedItems);

    closeModal();
  }, [closeModal, onAddProductToPage, productItems, selectedProducts]);

  return (
    <View style={styles.background}>
      <KeyboardAvoidingView
        behavior="padding"
        style={[styles.container, { width: 600 }]}
      >
        <View style={modalStyles.title}>
          <Text style={styles.titleText}>
            {translate('menus.addExistingProduct')}
          </Text>
        </View>
        <View style={styles.content}>
          <Search
            testID="search-products"
            value={searchValue}
            onChangeText={setSearchValue}
            placeholder={translate('menus.searchProductPlaceholder')}
            containerStyle={styles.input}
          />
          <View>
            <View style={theme.tables.header}>
              <Text style={theme.tables.headerText}>
                {translate('backOfficeProducts.products')}
              </Text>
            </View>
            <ScrollView style={styles.table}>
              {filteredProducts.length == 0 ? (
                <View style={theme.tables.emptyView}>
                  <Text>{translate('menus.noResultFound')}</Text>
                </View>
              ) : (
                filteredProducts.map((product, i) => {
                  return (
                    <View key={i} style={styles.tableRow}>
                      <InputToggle
                        testID="select-product"
                        isToggled={selectedProducts[product.id]}
                        onToggle={onSelectProduct.bind(
                          null,
                          product?.id as string,
                        )}
                      />
                      <Text>{product.name}</Text>
                    </View>
                  );
                })
              )}
            </ScrollView>
          </View>
        </View>
        <View style={modalStyles.actions}>
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
          <TreatButton
            type="neutral"
            testID="btn-confirm"
            onPress={onAddShowPageItemTypes}
            label="Add to Menu"
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default AddExistingProducts;
