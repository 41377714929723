import React, { useCallback, useState } from 'react';
import { MoneyMovementReasonInput, MoneyEventType } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import { sentenceCase } from 'change-case';
import Select from '../../../../../../components/Shared/Select/Select';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';

interface CreateMoneyMovementReasonProps {
  onCreate: (moneyMovementReason: MoneyMovementReasonInput) => void;
}

export const CreateMoneyMovementReason: React.FC<
  CreateMoneyMovementReasonProps
> = ({ onCreate }: CreateMoneyMovementReasonProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [form, setForm] = useState({
    eventType: MoneyEventType.MONEY_IN,
  } as MoneyMovementReasonInput);

  const onChange = useCallback((prop: string, value) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onCreateMoneyMovementReason = useCallback(() => {
    if (!form.name || !form.eventType) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      onCreate(form);
      closeModal();
    }
  }, [form, onCreate, translate, showNotification, closeModal]);

  const eventTypes = [MoneyEventType.MONEY_IN, MoneyEventType.MONEY_OUT];
  const eventTypesData = eventTypes.map(event => ({
    label: sentenceCase(event).replace('_', ' '),
    value: event,
  }));

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeSettings.createReason')}
      onConfirm={{
        label: translate('button.createReason'),
        action: onCreateMoneyMovementReason,
      }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('form.manageCash.name')}
        placeholder={translate('form.manageCash.namePlaceholder')}
        value={form.name}
        onChangeText={onChange.bind(null, 'name')}
      />
      <Select
        testID="select-eventType"
        title={translate('form.manageCash.event')}
        selectedValue={form.eventType}
        options={eventTypesData}
        onValueChange={onChange.bind(null, 'eventType')}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginVertical: 20 }}
      />
      <InputText
        testID="input-notes"
        title={translate('form.manageCash.notes')}
        placeholder={translate('form.manageCash.notesPlaceholder')}
        value={form.notes}
        onChangeText={onChange.bind(null, 'notes')}
      />
    </TreatModal>
  );
};
