import React, { FC, useCallback, useEffect, useState } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import {
  Order,
  Resource,
  OrderAction,
  UpdateOrderLastCheckEvent,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { useFocusEffect, useNavigation } from '@react-navigation/core';
import { ordersReceivedViaPollingAt } from '../../../../../state/cache';
import { useTimeout } from '../../../../../hooks/useTimeout';
import { useNotification } from '../../../../../hooks/Notification';
import { usePrinting } from '../../../../../hooks/PrintingProvider';
import { useOrders } from '../../../../../hooks/app/orders/useOrders';
import useBehaviorSubjectState from '../../../../../hooks/app/useSubjectState';
import useBehaviorSubjectEffect from '../../../../../hooks/app/useSubjectEffect';
import { refetchOrderObservable } from '../../../../../hooks/app/orders/ordersObservableUtils';
import usePOSUserAuthorization from '../../../../../hooks/app/users/usePOSUserAuthorization';
import {
  orderIdSelectionController,
  TableAction,
  tableActionController,
  tableSelectionController,
  tableStatsVisibilityController,
} from '../Sections/floorViewObservables';
import { round } from 'lodash';
import moment from 'moment';
import { format } from 'date-fns';
import { styles } from './FloorViewSidePanel.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import { useCart } from '../../../../../hooks/orders/useCart';

interface TableInfoSidePanel {
  isVisible: Boolean;
  onClose: (bool: boolean) => void;
}

interface TableStatPanelRow {
  testID?: string;
  title: string;
  valueLeft: string;
  valueRight?: string;
  onPress?: () => void;
  onPressLabel?: string;
}

const TableStatPanelRow: FC<TableStatPanelRow> = ({
  title,
  valueLeft,
  valueRight,
  onPress,
  testID,
  onPressLabel,
}) => {
  return (
    <View testID={testID} style={styles.row}>
      <Text style={styles.rowTitle}>{title}</Text>
      <View style={styles.rowValues}>
        <Text style={styles.rowValue}>{valueLeft}</Text>
        {valueRight ? <Text style={styles.rowValue}>{valueRight}</Text> : null}
        {onPress && onPressLabel ? (
          <TouchableOpacity testID="btn-update" onPress={onPress}>
            <Text style={[styles.rowValue, styles.link]}>{onPressLabel}</Text>
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const TableInfoSidePanel: FC = () => {
  const navigation = useNavigation();
  const { printBill } = usePrinting();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { getOrderFromCache } = useOrders();
  const { canI } = usePOSUserAuthorization();
  const { showNotification } = useNotification();
  const { setCartParams, updateCart, resetCart, openOrderCart } = useCart();

  const { value: isVisible, setValue: setVisible } = useBehaviorSubjectState(
    tableStatsVisibilityController,
  );
  const { value: selectedTable, setValue: setSelectedTable } =
    useBehaviorSubjectState(tableSelectionController);
  const { value: currentOrderId } = useBehaviorSubjectState<string>(
    orderIdSelectionController,
  );
  const { setValue: setTableIconAction } = useBehaviorSubjectState(
    tableActionController,
  );

  const [orderOfCurrentTable, setOrderOfCurrentTable] = useState<Order>();

  const refreshOrder = useCallback(() => {
    const foundOrder = getOrderFromCache(currentOrderId ?? '');
    setOrderOfCurrentTable(foundOrder);
  }, [getOrderFromCache, currentOrderId]);

  const delayRefreshTableOrderMap = useTimeout(refreshOrder);

  const ordersUpdatedThroughPolling = useReactiveVar<number>(
    ordersReceivedViaPollingAt,
  );

  const onClose = useCallback(() => {
    setVisible(false);
    setSelectedTable(null);
    setTableIconAction(TableAction.DEFAULT);
    resetCart();
  }, [setSelectedTable, setVisible, setTableIconAction, resetCart]);

  const updateTableLastCheck = useCallback(() => {
    updateCart<UpdateOrderLastCheckEvent>(OrderAction.ORDER_UPDATE_LAST_CHECK, {
      lastCheck: Date.now(),
    });
    updateCart(OrderAction.ORDER_SAVE);
    delayRefreshTableOrderMap.start();
  }, [updateCart, delayRefreshTableOrderMap]);

  useFocusEffect(refreshOrder);
  useBehaviorSubjectEffect(refetchOrderObservable, refreshOrder);

  useEffect(() => {
    if (ordersUpdatedThroughPolling) {
      delayRefreshTableOrderMap.start(2000);
    }
  }, [ordersUpdatedThroughPolling, delayRefreshTableOrderMap]);

  useEffect(() => {
    if (orderOfCurrentTable) {
      setCartParams(
        orderOfCurrentTable.id,
        orderOfCurrentTable.orderType.id,
        undefined,
        true,
      );
    }
  }, [setCartParams, orderOfCurrentTable]);

  const navigateToTakeOrder = useCallback(() => {
    if (orderOfCurrentTable) {
      navigation.navigate('TakeOrder', {
        id: orderOfCurrentTable.id,
        isCompleted: false,
        isExisting: true,
      });
      openOrderCart(orderOfCurrentTable.id);
    }
  }, [navigation, orderOfCurrentTable, openOrderCart]);

  const printOrder = useCallback(async () => {
    if (orderOfCurrentTable) {
      const onPrintResult = await printBill(orderOfCurrentTable);
      if (onPrintResult && Object.keys(onPrintResult)?.length > 0) {
        if (onPrintResult.success) {
          await updateCart(OrderAction.ORDER_PRINT);
          await updateCart(OrderAction.ORDER_SAVE);
          refreshOrder();
        } else {
          showNotification(onPrintResult);
        }
      }
    }
  }, [
    orderOfCurrentTable,
    printBill,
    refreshOrder,
    showNotification,
    updateCart,
  ]);

  const payOrder = useCallback(() => {
    const allowPay = canI([{ onResource: Resource.FINISH_SALE }], {
      prompt: true,
    });
    if (allowPay) {
      if (orderOfCurrentTable) {
        navigation.navigate('Payment', { orderId: orderOfCurrentTable.id });
        openOrderCart(orderOfCurrentTable.id);
      }
    }
  }, [canI, navigation, orderOfCurrentTable, openOrderCart]);

  if (!isVisible) return null;
  if (!orderOfCurrentTable)
    return <TouchableOpacity testID="touch-on-screen" onPress={onClose} />;

  const getPricePerHour = (order: Order) =>
    round(
      order.totalPaymentAmount /
        (Math.floor(
          moment.duration(moment().diff(order?.createdAt)).asHours(),
        ) || 1),
      1,
    );

  const getCustomerName = (order: Order) =>
    !order.customer?.firstName && !order.customer?.lastName
      ? 'N/A'
      : `${order.customer?.firstName} ${order.customer?.lastName}`;

  const pricePerHour = getPricePerHour(orderOfCurrentTable);
  const customerName = getCustomerName(orderOfCurrentTable);
  const guestCount = orderOfCurrentTable?.table?.guestCount ?? 1;

  return (
    <>
      <TouchableOpacity onPress={onClose} style={styles.overlay} />
      <View style={styles.container}>
        <View style={styles.title}>
          <TouchableOpacity
            testID="btn-close"
            onPress={onClose}
            style={styles.btnClose}
          >
            <Icon name="times" size={18} color={theme.colors.dark} />
          </TouchableOpacity>
          <Text style={styles.titleText}>
            {translate('tableStatPanel.tableTitle', {
              name: selectedTable?.table?.name,
            })}
          </Text>
        </View>
        <View style={styles.content}>
          <ScrollView style={styles.details}>
            <TableStatPanelRow
              testID="row-occupied"
              title={translate('tableStatPanel.occupiedSince')}
              valueLeft={format(orderOfCurrentTable?.createdAt, 'hh:mm a')}
              valueRight={moment(orderOfCurrentTable.createdAt).fromNow()}
            />
            <TableStatPanelRow
              testID="row-customer"
              title={translate('tableStatPanel.customer')}
              valueLeft={customerName}
              valueRight={`${guestCount} ${translate('tableStatPanel.guest', {
                plural: guestCount > 1 ? 's' : '',
              })}`}
            />
            <TableStatPanelRow
              testID="row-spend"
              title={translate('tableStatPanel.currentSpend')}
              valueLeft={`${formatCurrency(
                orderOfCurrentTable?.totalPaymentAmount || 0,
              )} (${formatCurrency(pricePerHour)}/hr)`}
              valueRight={
                typeof orderOfCurrentTable.table.guestCount === 'number' &&
                orderOfCurrentTable.table.guestCount > 0
                  ? `${formatCurrency(
                      orderOfCurrentTable?.totalPaymentAmount /
                        orderOfCurrentTable.table.guestCount,
                    )}${translate('tableStatPanel.head')}`
                  : 'N/A'
              }
            />
            <TableStatPanelRow
              testID="row-lastOrder"
              title={translate('tableStatPanel.lastOrder')}
              valueLeft={
                orderOfCurrentTable?.lastOrderedAt
                  ? moment(orderOfCurrentTable?.lastOrderedAt).format('hh:mm A')
                  : 'N/A'
              }
              valueRight={
                orderOfCurrentTable?.lastOrderedAt
                  ? moment(orderOfCurrentTable?.lastOrderedAt).fromNow()
                  : 'N/A'
              }
            />
            <TableStatPanelRow
              testID="row-lastCheck"
              title={translate('tableStatPanel.lastCheck')}
              valueLeft={
                orderOfCurrentTable?.lastCheck
                  ? moment(orderOfCurrentTable?.lastCheck).fromNow()
                  : 'N/A'
              }
              onPress={updateTableLastCheck}
              onPressLabel={translate('tableStatPanel.update')}
            />
          </ScrollView>
        </View>
        <View style={styles.actions}>
          <TreatButton
            testID="btn-pay"
            type="positive"
            label={translate('button.payNow')}
            onPress={payOrder}
            height={60}
          />
          <View style={styles.actionsRow}>
            <TreatButton
              testID="btn-edit"
              type="positiveLight"
              label={translate('order.edit')}
              onPress={navigateToTakeOrder}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={[styles.btnAction, { marginRight: 5 }]}
              height={60}
            />
            <TreatButton
              testID="btn-print"
              type="neutralLight"
              label={translate('tableFloorView.print')}
              onPress={printOrder}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={[styles.btnAction, { marginLeft: 5 }]}
              height={60}
            />
          </View>
        </View>
      </View>
    </>
  );
};

export default TableInfoSidePanel;
