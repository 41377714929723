/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { KeyboardAvoidingView, Platform, View, Text } from 'react-native';
import Button, { ButtonProps } from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import { convertAlphaNumbericToNumber } from '@oolio-group/client-utils';
import theme from '../../../../../common/default-theme';
import styles from './SetTimeModal.styles';
import { analyticsService } from '../../../../../analytics/AnalyticsService';

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <IconButton icon="times" onPress={onPress} iconColor={theme.colors.white} />
);

interface SetBusyModalProps {
  setBusyTime?: (time: number) => void;
}

export const SetBusyModal: React.FC<SetBusyModalProps> = ({
  setBusyTime,
}: SetBusyModalProps) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [busyTime, setTime] = useState(0);

  const onSetBusyTime = useCallback(() => {
    analyticsService.capture('oo_event', {
      event: 'Set Busy',
    });
    setBusyTime && setBusyTime(busyTime);
    closeModal();
  }, [closeModal, setBusyTime, busyTime]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.background}
    >
      <View style={styles.titleContainer}>
        <CloseButton onPress={closeModal} />
        <Text style={styles.titleText}>
          {translate('onlineOrders.setBusy')}
        </Text>
        <View style={{ width: 24 }} />
      </View>
      <View style={styles.bodyContainer}>
        <FormInput
          error={false}
          testID="set-busy-time"
          placeholder={translate('onlineOrders.setBusyTime')}
          title={translate('onlineOrders.setBusyTime')}
          value={busyTime.toString()}
          alignTitle="left"
          onChangeText={value => setTime(convertAlphaNumbericToNumber(value))}
          keyboardType="number-pad"
          containerStyle={{ width: 280 }}
        />
        <Button
          testID="set-busy-button"
          onPress={onSetBusyTime}
          labelStyle={{ color: theme.colors.white }}
          containerStyle={styles.confirmButtonContainer}
          title={translate('onlineOrders.setBusy').toUpperCase()}
        />
      </View>
    </KeyboardAvoidingView>
  );
};
