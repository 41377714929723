import {
  Currency,
  DeviceMode,
  OrderPaymentStatus,
  OrderTypeCode,
} from '@oolio-group/domain';
import theme from '../common/default-theme';

export const SUPPORTED_IMAGE_FORMATS = ['png', 'jpeg', 'jpg'];

export const DEFAULT_PAYMENT_CURRENCY = Currency.AUD;

export const MAX_KEYPAD_INPUT = 6;

export const IN_COMPLETE_PAYMENT_STATUS_LIST = [
  OrderPaymentStatus.CANCELLED,
  OrderPaymentStatus.REJECTED,
  OrderPaymentStatus.PENDING,
];

export const ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER = ['cash'];

export const ONLINE_ORDER_TYPES_CODE_SUPPORTED = [
  OrderTypeCode.DELIVERY,
  OrderTypeCode.PICK_UP,
  OrderTypeCode.DINE_IN,
] as string[];

// 3 days in miliseconds
export const CDS_TOKEN_EXPIRATION_DURATION = 3 * 24 * 60 * 60 * 1000;

export const SKIP_PAYMENT_TYPES_ON_PAY_NOW = ['Cash', 'Online'];

export const CARD_PAYMENT_TYPE = 'Card';

export const INTEGRATION_SETTINGS_KEY = 'integrationSettings';

export const DEVICE_MODE_MAPPING = {
  [DeviceMode.F_POS]: 'POS',
  [DeviceMode.M_POS]: 'mPOS',
  [DeviceMode.KIOSK]: 'Kiosk',
};

export const DEVICE_MODE_COLOR_MAPPING = {
  [DeviceMode.F_POS]: theme.colors.primary,
  [DeviceMode.M_POS]: theme.colors.accent,
  [DeviceMode.KIOSK]: theme.colors.deepOrange,
};

export const NO_VALUE_OPTION = {
  label: 'None',
  value: '',
};

export const PRINT_ERROR_MESSAGES: Record<string, string> = {
  EPTR_AUTOMATICAL: 'Automatic recovery error occurred',
  EPTR_BATTERY_LOW: 'Battery has run out',
  EPTR_COVER_OPEN: 'Cover open error occurred',
  EPTR_CUTTER: 'Auto cutter error occurred',
  EPTR_MECHANICAL: 'Mechanical error occurred',
  EPTR_REC_EMPTY: 'No paper is left in the roll paper end detector',
  EPTR_UNRECOVERABLE: 'Unrecoverable error occurred',
  SchemaError: 'Error exists in the requested document syntax',
  DeviceNotFound: 'Printer specified by the device ID does not exist',
  PrintSystemError: 'Error occurred with the printing system',
  EX_BADPORT: 'An error occurred with the communication port',
  EX_TIMEOUT: 'Print timeout occurred',
  EX_SPOOLER: 'Print queue is full',
  JobNotFound: 'Specified job ID does not exist',
  Printing: 'Printing in progress',
  JobSpooling: 'Job is spooling.',
  TooManyRequests:
    'The number of print jobs sent to the printer has exceeded the allowable limit.',
  RequestEntityTooLarge:
    'The size of the print job data exceeds the capacity of the printer.',
  ERROR_WAIT_EJECT: 'Waiting for paper removal.',
};
