import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  headerMapAccount: {
    flex: 1,
  },
  headerXeroAccount: {
    width: 236,
  },
  cellMapAccount: {
    flex: 1,
    marginHorizontal: 10,
  },
  cellXeroAccount: {
    width: 250,
    marginRight: 10,
  },
  cellStore: {
    flex: 1,
    marginRight: 10,
  },
  productTypesContainer: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    marginBottom: 20,
  },
});

export default styles;
