import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { KeyboardAvoidingView, View } from 'react-native';
import {
  DEFAULT_USER_PIN,
  DEFAULT_USER_PASSWORD,
  DEFAULT_USER_LANGUAGE,
} from '@oolio-group/domain';
import { useNotification } from '../../../../../hooks/Notification';
import {
  getCountry,
  useTranslation,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import { CreateUserRequest } from '@oolio-group/domain';
import { isValidEmail } from '../../../../../utils/validator';
import { DEFAULT_COUNTRY_CODE } from '../../../../../constants';
import { useSession } from '../../../../../hooks/app/useSession';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../components/Shared/Inputs/InputPhone';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import theme from '../../../../../common/default-theme';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';

type UserWithPhoneCountryCode = CreateUserRequest & {
  countryCode: string;
};

interface UserModalProps {
  onDismiss: () => void;
  createUser: (input: CreateUserRequest, isEditMore: boolean) => void;
}

export const CreateUserModal: React.FC<UserModalProps> = ({
  createUser,
  onDismiss,
}: UserModalProps) => {
  const { translate } = useTranslation();
  const [userForm, setUserForm] = useState({
    countryCode: DEFAULT_COUNTRY_CODE,
  } as unknown as UserWithPhoneCountryCode);
  const { showNotification } = useNotification();
  const [session] = useSession();

  const onChangeUser = useCallback((prop: string, value: string | boolean) => {
    setUserForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const getFormattedPhone = useCallback(() => {
    if (userForm.countryCode) {
      return `${getCountry(userForm.countryCode).phone} ${userForm.phone}`;
    }
    return userForm.phone;
  }, [userForm.countryCode, userForm.phone]);

  const formValidationStatus: Record<string, boolean> = useMemo(
    () => ({
      firstName: userForm.firstName?.length > 0,
      email: isValidEmail(userForm.email),
      phone: userForm.phone ? isValidPhoneNumber(getFormattedPhone()) : false,
    }),
    [userForm.email, userForm.phone, getFormattedPhone, userForm.firstName],
  );

  const { country = 'AU' } = session?.currentOrganization || {};
  const defaultLanguage = country === 'GB' ? 'en-GB' : DEFAULT_USER_LANGUAGE;

  const onCreateUser = useCallback(
    (isEditMore: boolean) => {
      if (
        Object.keys(formValidationStatus).some(
          _key => formValidationStatus[_key] === false,
        )
      ) {
        showNotification({
          error: true,
          message: translate('backOfficeProducts.fillAllFields'),
        });
      } else {
        createUser(
          {
            firstName: userForm.firstName,
            lastName: userForm.lastName,
            phone: getFormattedPhone(),
            preferredLanguage: defaultLanguage,
            email: userForm.email,
            pin: DEFAULT_USER_PIN,
            password: DEFAULT_USER_PASSWORD,
            sendInvite: userForm.sendInvite,
          } as CreateUserRequest,
          isEditMore,
        );
      }
    },
    [
      formValidationStatus,
      showNotification,
      translate,
      createUser,
      userForm.firstName,
      userForm.lastName,
      userForm.email,
      userForm.sendInvite,
      getFormattedPhone,
      defaultLanguage,
    ],
  );

  useEffect(() => {
    if (country) {
      setUserForm(formData => ({
        ...formData,
        countryCode: country,
      }));
    }
  }, [country]);

  return (
    <KeyboardAvoidingView behavior="padding">
      <TreatModal
        type="positive"
        size="medium"
        title={translate('backOfficeUsers.createNewUser')}
        onConfirm={{
          label: translate('productSettings.create'),
          action: onCreateUser.bind(null, true),
        }}
        onDismiss={{ action: onDismiss }}
      >
        <View style={{ width: theme.layoutWidth.s }}>
          <View style={theme.forms.row}>
            <InputText
              testID="firstName"
              title={translate('form.firstName')}
              placeholder={translate('form.firstName')}
              value={userForm.firstName || ''}
              onChangeText={onChangeUser.bind(null, 'firstName')}
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              testID="lastName"
              title={translate('form.lastName')}
              placeholder={translate('form.lastName')}
              value={userForm.lastName || ''}
              onChangeText={onChangeUser.bind(null, 'lastName')}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
          <View style={theme.forms.row}>
            <InputEmail
              testID="email"
              title={translate('form.email')}
              placeholder={translate('form.email')}
              value={userForm.email || ''}
              onChangeText={onChangeUser.bind(null, 'email')}
              containerStyle={theme.forms.inputHalf}
            />
            <InputPhone
              testID="phoneNo"
              title={translate('form.phone')}
              value={userForm.phone || ''}
              defaultCountry={userForm.countryCode}
              onChangeText={onChangeUser.bind(null, 'phone')}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onPressCountry={(country: any): void =>
                onChangeUser('countryCode', country.toString())
              }
              containerStyle={theme.forms.inputHalf}
            />
          </View>
          <View style={theme.forms.row}>
            <InputToggle
              testID="sendInvite"
              title={translate('form.sendInvite')}
              onToggle={() => onChangeUser('sendInvite', !userForm.sendInvite)}
              isToggled={userForm.sendInvite ?? false}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        </View>
      </TreatModal>
    </KeyboardAvoidingView>
  );
};
