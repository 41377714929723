import React from 'react';
import { View } from 'react-native';
import { StyleFn, DayOfWeek } from '@oolio-group/domain';
import TableRow from '../../../components/TableComponent/TableRow';
import { useFela } from 'react-fela';
import { useTranslation } from '@oolio-group/localization';
import { timeSlotOptions } from '../../../utils/dateHelper';
import DropDown from '../../../components/FormInput/DropDown';
import Button from '../../Button/Button';
import { isWeb } from '../../../common/theme';

export interface AdjustmentObject {
  isActive: boolean;
  day?: DayOfWeek;
  startTime: string;
  endTime: string;
}

export interface TableRowProp {
  label: string;
  value: DayOfWeek;
}

export type WeekDayAdjustmentType = {
  [key in DayOfWeek]?: AdjustmentObject;
};

const DropDownContainer: StyleFn = () => ({
  flexDirection: 'row',
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  color: theme.colors.paragraph,
});

const checkContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small / 2,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  paddingLeft: 0,
});

const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: '85%',
  height: theme.input.height,
});

const dropdownViewStyle: StyleFn = () => ({
  width: 90,
});

const dropdownStyle: StyleFn = () => ({
  width: isWeb ? 80 : 0,
});

export const angleDownIconStyle: StyleFn = () => ({
  right: 15,
});

interface ScheduleRowProp {
  row: TableRowProp;
  weekDayAdjustment?: WeekDayAdjustmentType;
  onChangeWeekdayAdjustments: (
    key: DayOfWeek,
    value: Partial<AdjustmentObject>,
  ) => void;
  isScheduledEnabled?: boolean;
}

export const AdjustmentScheduleRow = ({
  row,
  weekDayAdjustment,
  onChangeWeekdayAdjustments,
  isScheduledEnabled,
}: ScheduleRowProp) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const isActive = weekDayAdjustment?.[row.value]?.isActive || false;
  const startTime = weekDayAdjustment?.[row.value]?.startTime || '00:00';
  const endTime = weekDayAdjustment?.[row.value]?.endTime || '23:59';

  return (
    <>
      <TableRow>
        <View>
          <Button
            title={translate('backofficeVenueSettingAdjustments.weekDay', {
              day: row.label,
            })}
            testID={'weekDay'}
            labelStyle={css(checkBoxTitleStyle)}
            onPress={() =>
              isScheduledEnabled &&
              onChangeWeekdayAdjustments(row.value, { isActive: !isActive })
            }
            iconPosition={'left'}
            containerStyle={css(checkBoxTitleContainer)}
            iconContainerStyle={css(checkContainer)}
            icon={isScheduledEnabled && isActive ? 'toggle-on' : 'toggle-off'}
            iconProps={{
              color:
                isScheduledEnabled && isActive
                  ? theme.colors.success
                  : theme.colors.paragraph,
            }}
          />
        </View>
        <View style={css(DropDownContainer)}>
          <DropDown
            values={timeSlotOptions}
            selectedValue={startTime}
            extraStyle={css(dropdownExtraStyle)}
            extraViewStyle={css(dropdownViewStyle)}
            style={css(dropdownStyle)}
            angleDownIconStyle={css(angleDownIconStyle)}
            onValueChange={value =>
              onChangeWeekdayAdjustments(row.value, { startTime: value })
            }
            enabled={isScheduledEnabled && isActive}
          />

          <DropDown
            values={timeSlotOptions}
            selectedValue={endTime}
            extraStyle={css(dropdownExtraStyle)}
            extraViewStyle={css(dropdownViewStyle)}
            style={css(dropdownStyle)}
            angleDownIconStyle={css(angleDownIconStyle)}
            onValueChange={value =>
              onChangeWeekdayAdjustments(row.value, { endTime: value })
            }
            enabled={isScheduledEnabled && isActive}
          />
        </View>
      </TableRow>
    </>
  );
};
