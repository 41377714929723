import React, { useState, useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../common/default-theme';
import InputText from '../../../components/Shared/Inputs/InputText';
import Picker from '../../../components/Shared/Select/Picker';
import Modal from '../../../components/Shared/Modals/Modal/Modal';
import {
  CreateDeviceInput,
  DeviceMode,
  DeviceProfile,
} from '@oolio-group/domain';

interface Props {
  deviceProfiles: DeviceProfile[];
  onSubmit: (form: CreateDeviceInput) => void;
}

const CreateDeviceModal: React.FC<Props> = ({ deviceProfiles, onSubmit }) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const initialForm = {
    isCreatedByBackoffice: true,
    deviceProfile: '',
    mode: DeviceMode.F_POS,
    name: '',
  };

  const [form, setForm] = useState({
    ...initialForm,
  } as unknown as CreateDeviceInput);

  const [isNameValid, setIsNameValid] = useState<boolean | undefined>(
    undefined,
  );

  const deviceProfilesOptions = deviceProfiles
    .filter(profile => profile.mode === form.mode)
    .map(profile => ({
      value: profile.id,
      label: profile.name,
    }));

  const onChange = useCallback((prop: string, value: string) => {
    setForm(prevForm => ({
      ...prevForm,
      [prop]: value,
    }));
    if (prop === 'name') {
      setIsNameValid(undefined);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    if (!form.name) {
      setIsNameValid(false);
      return;
    }
    onSubmit(form);
    closeModal();
  }, [form, onSubmit, closeModal]);

  useEffect(() => {
    const defaultProfile = deviceProfiles.find(profile => profile.isDefault);
    if (defaultProfile) {
      setForm(prevForm => ({
        ...prevForm,
        deviceProfile: defaultProfile.id,
      }));
    }
  }, [deviceProfiles]);

  return (
    <Modal
      type="positive"
      title={translate('backOfficeDeviceModal.createTitle')}
      onConfirm={{ label: translate('button.createNew'), action: handleSubmit }}
      onDismiss={{ action: () => closeModal() }}
    >
      <View style={theme.forms.row}>
        <InputText
          testID={'device-name-input'}
          title={translate('backOfficeDeviceModal.deviceName')}
          placeholder={translate('backOfficeDeviceModal.deviceName')}
          value={form.name}
          isVerified={isNameValid}
          onChangeText={value => onChange('name', value)}
          maxLength={50}
          containerStyle={theme.forms.inputFluid}
        />
      </View>
      <Picker
        title={translate('backOfficeDeviceModal.deviceProfileTitle')}
        options={deviceProfilesOptions}
        selectedValue={form.deviceProfile}
        onValueChange={value => onChange('deviceProfile', value)}
        testID={'device-profile-input'}
        containerStyle={theme.forms.inputFluid}
      />
    </Modal>
  );
};

export default CreateDeviceModal;
