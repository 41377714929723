import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  table: {
    marginBottom: 80,
    marginHorizontal: 20,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginVertical: 8,
  },
  tableRow: {
    height: 50,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  headerVariance: {
    width: 100,
    marginLeft: 10,
    textAlign: 'right',
  },
  headerGap: {
    marginRight: 94,
  },
  cellStatus: {
    width: 80,
    marginLeft: 12,
    textAlign: 'center',
  },
  cellNo: {
    width: 60,
    marginLeft: 10,
    textAlign: 'center',
  },
  cellDate: {
    width: 112,
    marginLeft: 20,
  },
  cellDevice: {
    width: 120,
    marginLeft: 20,
  },
  cellName: {
    flex: 1,
    marginLeft: 20,
  },
  cellAmount: {
    width: 100,
    marginLeft: 10,
    textAlign: 'right',
  },
  cellPrint: {
    marginLeft: 20,
    marginRight: 6,
  },
});

export default styles;
