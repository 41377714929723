import React, { FC, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './Pagination.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';

export interface PaginationProps {
  page: number;
  dataLength: number;
  pageLength: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  page,
  dataLength,
  pageLength,
  onPageChange,
}) => {
  const pages = Math.ceil(dataLength / pageLength);

  const fetchNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [onPageChange, page]);

  const handlePressBack = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  function getPaginationText() {
    const start = page === 1 ? 1 : (page - 1) * 10 + 1;
    const end = start + pageLength - 1;
    const adjustedEnd = Math.min(end, dataLength);
    return `${start}-${adjustedEnd}`;
  }

  return (
    <View style={styles.container}>
      <View style={styles.contextView}>
        <Text
          style={styles.contextText}
        >{`Showing ${getPaginationText()} of ${dataLength}`}</Text>
      </View>
      <TouchableOpacity
        disabled={page === 1}
        onPress={handlePressBack}
        style={styles.btnPagination}
      >
        <Icon
          name="arrow-left"
          size={20}
          color={page === 1 ? theme.colors.grey4 : theme.colors.black}
        />
      </TouchableOpacity>
      <TouchableOpacity
        disabled={page >= pages}
        onPress={fetchNextPage}
        style={styles.btnPagination}
      >
        <Icon
          name="arrow-right"
          size={20}
          color={page >= pages ? theme.colors.grey4 : theme.colors.black}
        />
      </TouchableOpacity>
    </View>
  );
};

export default Pagination;
