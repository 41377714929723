declare global {
  interface Console {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tron: any;
  }
}

console.tron = { log: Function.prototype };

if (process.env.NODE_ENV === 'development') {
  console.tron = { log: console.log };
}
export {};
