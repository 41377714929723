import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Customer } from '@oolio-group/domain';
import { formatPhoneNumber, parsePhoneNumber } from '@oolio-group/localization';
import styles from './ManageCustomers.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';

interface Props {
  customer: Customer;
  orgCountry: string;
  showPoints: boolean;
  onPress: () => void;
}

const CustomerRow: React.FC<Props> = ({
  customer,
  orgCountry,
  showPoints,
  onPress,
}) => {
  const getCustomerPhone = () => {
    if (customer.phone) {
      const countryCode = parsePhoneNumber(customer.phone).countryCode;
      return formatPhoneNumber(customer.phone, {
        countryCode: countryCode,
        intl: countryCode != orgCountry,
      });
    }

    return 'N/A';
  };

  return (
    <TouchableOpacity
      testID="data-row"
      onPress={onPress}
      style={theme.tables.row}
    >
      <Text numberOfLines={1} style={[styles.rowName, styles.cellName]}>
        {`${customer.firstName} ${customer.lastName}` || 'N/A'}
      </Text>
      <Text
        numberOfLines={1}
        style={[
          styles.rowText,
          styles.cellPhone,
          { color: customer.phone ? theme.colors.black1 : theme.colors.grey4 },
        ]}
      >
        {getCustomerPhone()}
      </Text>
      <Text
        numberOfLines={1}
        style={[
          styles.rowText,
          styles.cellEmail,
          { color: customer.email ? theme.colors.black1 : theme.colors.grey4 },
        ]}
      >
        {customer.email || 'N/A'}
      </Text>
      {showPoints ? (
        <Text
          style={[
            styles.rowPoints,
            styles.cellPoints,
            {
              color: customer.loyaltyPointsBalance
                ? theme.colors.accent
                : theme.colors.grey4,
            },
          ]}
        >
          {customer.loyaltyPointsBalance || 'N/A'}
        </Text>
      ) : (
        <></>
      )}
      <View style={theme.tables.disclosure}>
        <Icon size={20} name="angle-right" color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export default CustomerRow;
