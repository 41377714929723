import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  tables: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  table: {
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addTableContainer: {
    marginTop: 20,
    marginLeft: 'auto',
  },
  modal: {
    width: 380,
    padding: 30,
    paddingBottom: 0,
    alignSelf: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
  },
  title: {
    fontSize: 16,
    paddingLeft: 12,
    marginTop: 10,
    marginBottom: 20,
    fontFamily: theme.fonts.medium,
  },
  segments: {
    marginBottom: 30,
  },
  row: {
    width: 280,
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputFull: {
    flex: 1,
  },
  inputHalf: {
    width: 135,
  },
  actions: {
    paddingVertical: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btnCancel: {
    marginRight: 10,
  },
});

export default styles;
