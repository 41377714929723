import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  PanResponder,
  Animated,
  StyleSheet,
  StyleProp,
  GestureResponderEvent,
  PanResponderGestureState,
  ViewStyle,
  Platform,
} from 'react-native';
import { findKey, findIndex, differenceBy } from 'lodash';
import { ScrollEnabledState } from '../../../../../state/scrollState';

export interface IOnLayoutEvent {
  nativeEvent: {
    layout: { x: number; y: number; width: number; height: number };
  };
}

interface IBaseItemType {
  key: string | number;
  disabledDrag?: boolean;
  disabledReSorted?: boolean;
}

export interface IDraggableGridProps<DataType extends IBaseItemType> {
  numColumns: number;
  data: DataType[];
  renderItem: (input: {
    item: DataType;
    position: number;
    onLongPress: () => void;
  }) => React.ReactElement;
  style?: ViewStyle;
  itemHeight?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dragStartAnimation?: StyleProp<any>;
  onItemPress?: (item: DataType) => void;
  onDragStart?: (item: DataType) => void;
  onDragging?: (gestureState: PanResponderGestureState) => void;
  onDragRelease?: (newSortedData: DataType[]) => void;
  onResetSort?: (newSortedData: DataType[]) => void;
  delayLongPress?: number;
}
interface IMap<T> {
  [key: string]: T;
}
interface IPositionOffset {
  x: number;
  y: number;
}
interface IOrderMapItem {
  order: number;
}
interface IItem<DataType> {
  key: string | number;
  itemData: DataType;
  currentPosition: Animated.AnimatedValueXY;
}
let activeBlockOffset = { x: 0, y: 0 };

export const DraggableGrid = <DataType extends IBaseItemType>(
  props: IDraggableGridProps<DataType>,
) => {
  const [blockPositions] = useState<IPositionOffset[]>([]);
  const [orderMap] = useState<IMap<IOrderMapItem>>({});
  const [itemMap] = useState<IMap<DataType>>({});
  const [items] = useState<IItem<DataType>[]>([]);
  const [blockHeight, setBlockHeight] = useState(0);
  const [blockWidth, setBlockWidth] = useState(0);
  const [gridHeight] = useState<Animated.Value>(new Animated.Value(0));
  const [hadInitBlockSize, setHadInitBlockSize] = useState(false);
  const [dragStartAnimatedValue] = useState(new Animated.Value(1));
  const [gridLayout, setGridLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [activeItemIndex, setActiveItemIndexSource] = useState<
    undefined | number
  >();
  // need this ref as state is not update quick enough --> getActiveItem will return false
  const activeItemIndexRef = useRef<undefined | number>();

  const setActiveItemIndex = useCallback(
    (activeItemIndex: number | undefined) => {
      activeItemIndexRef.current = activeItemIndex;
      setActiveItemIndexSource(activeItemIndex);
    },
    [],
  );

  const [panResponderCapture, setPanResponderCapture] = useState(false);

  const assessGridSize = useCallback(
    (event: IOnLayoutEvent) => {
      if (!hadInitBlockSize) {
        const blockWidth = event.nativeEvent.layout.width / props.numColumns;
        const blockHeight = props.itemHeight || blockWidth;
        setBlockWidth(blockWidth);
        setBlockHeight(blockHeight);
        setGridLayout(event.nativeEvent.layout);
        setHadInitBlockSize(true);
      }
    },
    [hadInitBlockSize, props.itemHeight, props.numColumns],
  );

  const getActiveItem = useCallback(() => {
    if (activeItemIndexRef.current === undefined) return false;
    return items[activeItemIndexRef.current];
  }, [items]);

  const getDistance = useCallback(
    (startOffset: IPositionOffset, endOffset: IPositionOffset) => {
      const xDistance = startOffset.x + activeBlockOffset.x - endOffset.x;
      const yDistance = startOffset.y + activeBlockOffset.y - endOffset.y;
      return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
    },
    [],
  );

  const getSortData = useCallback(() => {
    const sortData: DataType[] = [];
    items.forEach(item => {
      sortData[orderMap[item.key].order] = item.itemData;
    });
    return sortData;
  }, [items, orderMap]);

  const getKeyByOrder = useCallback(
    (order: number) => {
      return findKey(
        orderMap,
        (item: IOrderMapItem) => item.order === order,
      ) as string;
    },
    [orderMap],
  );

  const moveBlockToBlockOrderPosition = useCallback(
    (itemKey: string | number) => {
      const itemIndex = findIndex(
        items,
        item => `${item.key}` === `${itemKey}`,
      );
      items[itemIndex].currentPosition.flattenOffset();
      Animated.timing(items[itemIndex].currentPosition, {
        toValue: blockPositions[orderMap[itemKey].order],
        duration: 200,
        useNativeDriver: false,
      }).start();
    },
    [blockPositions, items, orderMap],
  );

  const resetBlockPositionByOrder = useCallback(
    (activeItemOrder: number, insertedPositionOrder: number) => {
      let disabledReSortedItemCount = 0;
      if (activeItemOrder > insertedPositionOrder) {
        for (let i = activeItemOrder - 1; i >= insertedPositionOrder; i--) {
          const key = getKeyByOrder(i);
          const item = itemMap[key];
          if (item && item.disabledReSorted) {
            disabledReSortedItemCount++;
          } else {
            orderMap[key].order += disabledReSortedItemCount + 1;
            disabledReSortedItemCount = 0;
            moveBlockToBlockOrderPosition(key);
          }
        }
      } else {
        for (let i = activeItemOrder + 1; i <= insertedPositionOrder; i++) {
          const key = getKeyByOrder(i);
          const item = itemMap[key];
          if (item && item.disabledReSorted) {
            disabledReSortedItemCount++;
          } else {
            orderMap[key].order -= disabledReSortedItemCount + 1;
            disabledReSortedItemCount = 0;
            moveBlockToBlockOrderPosition(key);
          }
        }
      }
    },
    [getKeyByOrder, itemMap, moveBlockToBlockOrderPosition, orderMap],
  );

  const onStartDrag = useCallback(
    (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
      const activeItem = getActiveItem();
      if (!activeItem) return false;
      if (Platform.OS === 'android') {
        ScrollEnabledState.next(false);
      }
      props.onDragStart && props.onDragStart(activeItem.itemData);
      const { x0, y0, moveX, moveY } = gestureState;
      const activeOrigin = blockPositions[orderMap[activeItem.key].order];
      const x = activeOrigin.x - x0;
      const y = activeOrigin.y - y0;
      activeItem.currentPosition.setOffset({
        x,
        y,
      });
      activeBlockOffset = {
        x,
        y,
      };
      activeItem.currentPosition.setValue({
        x: moveX,
        y: moveY,
      });
    },
    [blockPositions, getActiveItem, orderMap, props],
  );

  const onHandMove = useCallback(
    (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
      const activeItem = getActiveItem();
      if (!activeItem) return false;
      const { moveX, moveY } = gestureState;
      props.onDragging && props.onDragging(gestureState);

      const xChokeAmount = Math.max(
        0,
        activeBlockOffset.x + moveX - (gridLayout.width - blockWidth),
      );
      const xMinChokeAmount = Math.min(0, activeBlockOffset.x + moveX);

      const dragPosition = {
        x: moveX - xChokeAmount - xMinChokeAmount,
        y: moveY,
      };
      const originPosition = blockPositions[orderMap[activeItem.key].order];
      const dragPositionToActivePositionDistance = getDistance(
        dragPosition,
        originPosition,
      );
      activeItem.currentPosition.setValue(dragPosition);

      let closetItemIndex = activeItemIndexRef.current as number;
      let closetDistance = dragPositionToActivePositionDistance;

      items.forEach((item, index) => {
        if (item.itemData.disabledReSorted) return;
        if (index != activeItemIndexRef.current) {
          const dragPositionToItemPositionDistance = getDistance(
            dragPosition,
            blockPositions[orderMap[item.key].order],
          );
          if (
            dragPositionToItemPositionDistance < closetDistance &&
            dragPositionToItemPositionDistance < blockWidth
          ) {
            closetItemIndex = index;
            closetDistance = dragPositionToItemPositionDistance;
          }
        }
      });
      if (activeItemIndexRef.current != closetItemIndex) {
        const closetOrder = orderMap[items[closetItemIndex].key].order;
        resetBlockPositionByOrder(orderMap[activeItem.key].order, closetOrder);
        orderMap[activeItem.key].order = closetOrder;
        props.onResetSort && props.onResetSort(getSortData());
      }
    },
    [
      blockPositions,
      blockWidth,
      getActiveItem,
      getDistance,
      getSortData,
      gridLayout.width,
      items,
      orderMap,
      props,
      resetBlockPositionByOrder,
    ],
  );

  const onHandRelease = useCallback(() => {
    const activeItem = getActiveItem();
    if (!activeItem) return false;
    if (Platform.OS === 'android') {
      ScrollEnabledState.next(true);
    }
    props.onDragRelease && props.onDragRelease(getSortData());
    setPanResponderCapture(false);
    activeItem.currentPosition.flattenOffset();
    moveBlockToBlockOrderPosition(activeItem.key);
    setActiveItemIndex(undefined);
  }, [
    getActiveItem,
    getSortData,
    moveBlockToBlockOrderPosition,
    props,
    setActiveItemIndex,
  ]);

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onStartShouldSetPanResponderCapture: () => false,
    onMoveShouldSetPanResponder: () => panResponderCapture,
    onMoveShouldSetPanResponderCapture: () => panResponderCapture,
    onShouldBlockNativeResponder: () => false,
    onPanResponderTerminationRequest: () => false,
    onPanResponderGrant: onStartDrag,
    onPanResponderMove: onHandMove,
    onPanResponderRelease: onHandRelease,
  });

  const getBlockPositionByOrder = useCallback(
    (order: number) => {
      if (blockPositions[order]) {
        return blockPositions[order];
      }
      const columnOnRow = order % props.numColumns;
      const y = blockHeight * Math.floor(order / props.numColumns);
      const x = columnOnRow * blockWidth;
      return {
        x,
        y,
      };
    },
    [blockHeight, blockPositions, blockWidth, props.numColumns],
  );

  const initBlockPositions = useCallback(() => {
    items.forEach((_, index) => {
      blockPositions[index] = getBlockPositionByOrder(index);
    });
  }, [blockPositions, getBlockPositionByOrder, items]);

  const setActiveBlock = useCallback(
    (itemIndex: number, item: DataType) => {
      if (item.disabledDrag) return;
      setPanResponderCapture(true);
      setActiveItemIndex(itemIndex);
    },
    [setActiveItemIndex],
  );

  const startDragStartAnimation = useCallback(() => {
    if (!props.dragStartAnimation) {
      dragStartAnimatedValue.setValue(1);
      Animated.timing(dragStartAnimatedValue, {
        toValue: 1.1,
        duration: 100,
        useNativeDriver: false,
      }).start();
    }
  }, [dragStartAnimatedValue, props.dragStartAnimation]);

  const getBlockStyle = useCallback(
    (itemIndex: number) => {
      return [
        {
          justifyContent: 'center',
          alignItems: 'center',
        },
        hadInitBlockSize && {
          width: blockWidth,
          height: blockHeight,
          position: 'absolute',
          top: items[itemIndex].currentPosition.getLayout().top,
          left: items[itemIndex].currentPosition.getLayout().left,
        },
      ];
    },
    [blockHeight, blockWidth, hadInitBlockSize, items],
  );

  const getDefaultDragStartAnimation = useCallback(() => {
    return {
      transform: [
        {
          scale: dragStartAnimatedValue,
        },
      ],
      shadowColor: '#000000',
      shadowOpacity: 0.2,
      shadowRadius: 6,
      shadowOffset: {
        width: 1,
        height: 1,
      },
    };
  }, [dragStartAnimatedValue]);

  const getDragStartAnimation = useCallback(
    (itemIndex: number) => {
      if (activeItemIndexRef.current != itemIndex) {
        return;
      }

      const dragStartAnimation =
        props.dragStartAnimation || getDefaultDragStartAnimation();
      return {
        zIndex: 3,
        ...dragStartAnimation,
      };
    },
    [getDefaultDragStartAnimation, props.dragStartAnimation],
  );

  function addItem(item: DataType, index: number) {
    blockPositions.push(getBlockPositionByOrder(items.length));
    orderMap[item.key] = {
      order: index,
    };
    itemMap[item.key] = item;
    items.push({
      key: item.key,
      itemData: item,
      currentPosition: new Animated.ValueXY(getBlockPositionByOrder(index)),
    });
  }

  function removeItem(item: IItem<DataType>) {
    const itemIndex = findIndex(items, curItem => curItem.key === item.key);
    items.splice(itemIndex, 1);
    blockPositions.pop();
    delete orderMap[item.key];
  }
  function diffData() {
    props.data.forEach((item, index) => {
      if (orderMap[item.key]) {
        if (orderMap[item.key].order != index) {
          orderMap[item.key].order = index;
          moveBlockToBlockOrderPosition(item.key);
        }
        const currentItem = items.find(i => i.key === item.key);
        if (currentItem) {
          currentItem.itemData = item;
        }
        itemMap[item.key] = item;
      } else {
        addItem(item, index);
      }
    });
    const deleteItems = differenceBy(items, props.data, 'key');
    deleteItems.forEach(item => {
      removeItem(item);
    });
  }

  useEffect(() => {
    startDragStartAnimation();
  }, [activeItemIndex, startDragStartAnimation]);

  useEffect(() => {
    if (hadInitBlockSize) {
      initBlockPositions();
    }
  }, [gridLayout, hadInitBlockSize, initBlockPositions]);

  useEffect(() => {
    // resetGridHeight
    const rowCount = Math.ceil(props.data.length / props.numColumns);
    gridHeight.setValue(rowCount * blockHeight);
  }, [blockHeight, gridHeight, props.data.length, props.numColumns]);

  if (hadInitBlockSize) {
    diffData();
  }

  const itemList = items.map((item, itemIndex) => {
    return (
      <Animated.View
        style={[
          styles.blockContainer,
          getBlockStyle(itemIndex),
          getDragStartAnimation(itemIndex),
        ]}
        {...panResponder.panHandlers}
        key={item.key}
      >
        {props.renderItem({
          item: item.itemData,
          position: orderMap[item.key].order,
          onLongPress: setActiveBlock.bind(null, itemIndex, item.itemData),
        })}
      </Animated.View>
    );
  });

  return (
    <Animated.View
      style={[
        styles.draggableGrid,
        props.style,
        {
          height: gridHeight,
        },
      ]}
      onLayout={assessGridSize}
    >
      {hadInitBlockSize && itemList}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  draggableGrid: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  blockContainer: {
    alignItems: 'center',
  },
  pagination: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});
