import { useCurrency, useTranslation } from '@oolio-group/localization';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FlatList, View, Text, ActivityIndicator } from 'react-native';
import { styles } from './RefundOrderPaymentStatus.styles';
import { styles as paymentTypeStyles } from '../RefundPaymentTypes/RefundPaymentTypes.styles';
import IconButton from '../../../../Button/IconButton';
import { OrderPayment } from '@oolio-group/domain';
import Icon from '../../../../Icon/Icon';
import theme from '../../../../../common/default-theme';
import { getCardNameAndLast4Digit } from '@oolio-group/client-utils';

interface OrderHistoryModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
  orderPayments: OrderPayment[];
  onPressRefundPayType: (orderPayment: OrderPayment) => void;
  onCompletedRefund: () => void;
  getPaymentIcon: (paymentName: string, type: string) => JSX.Element;
}

interface PaymentTypeState {
  isRefundCompleted: boolean;
  isRefundInProgress: boolean;
  error: string;
  amount: number;
  tendered: number;
  id: string;
}

interface PaymentTypeProp {
  [key: string]: PaymentTypeState;
}

const DEFAULT_PAYMENT_REFUND_STATE = {
  isRefundCompleted: false,
  isRefundInProgress: false,
  error: '',
  amount: 0,
  tendered: 0,
};

const RefundOrderPaymentStatus: React.FC<OrderHistoryModalProps> = forwardRef(
  (
    { orderPayments, onCompletedRefund, onPressRefundPayType, getPaymentIcon },
    ref,
  ) => {
    const { translate } = useTranslation();
    const { formatCurrency } = useCurrency();
    const [paymentTypeValue, setPaymentTypeValue] = useState<PaymentTypeProp>(
      orderPayments?.reduce(
        (acc, p: OrderPayment) => ({
          ...acc,
          [p.id]: {
            ...DEFAULT_PAYMENT_REFUND_STATE,
            id: p.id,
            amount: p.amount,
            tendered: p.tendered,
          },
        }),
        {},
      ),
    );

    useImperativeHandle(
      ref,
      () => ({
        verifyRefundPaymentStatus(
          orderPaymentToRefunded: OrderPayment,
          errorMessage?: string,
        ): string {
          const paymentKeys = Object.keys(paymentTypeValue);
          const paymentKey = paymentKeys.find(k => {
            const paymentState = paymentTypeValue[k];
            if (
              k !== orderPaymentToRefunded.id &&
              !paymentState.isRefundCompleted
            )
              return k;
          }) as string;
          if (paymentKey) {
            setPaymentTypeValue(prev => ({
              ...prev,
              [orderPaymentToRefunded.id]: {
                ...prev[orderPaymentToRefunded.id],
                isRefundCompleted: true,
                isRefundInProgress: false,
                error: errorMessage ?? '',
              },
              [paymentKey]: {
                ...prev[paymentKey],
                isRefundCompleted: false,
                isRefundInProgress: true,
              },
            }));

            setTimeout(() => {
              onPressRefundPayType(
                orderPayments.find(p => p.id === paymentKey) as OrderPayment,
              );
            });
          } else {
            setPaymentTypeValue(prev => ({
              ...prev,
              [orderPaymentToRefunded.id]: {
                ...prev[orderPaymentToRefunded.id],
                isRefundCompleted: true,
                isRefundInProgress: false,
                error: errorMessage ?? '',
              },
            }));
            onCompletedRefund();
          }
          return '';
        },
      }),
      [
        paymentTypeValue,
        onPressRefundPayType,
        orderPayments,
        onCompletedRefund,
      ],
    );

    useEffect(() => {
      setPaymentTypeValue({
        ...paymentTypeValue,
        [orderPayments[0].id]: {
          ...paymentTypeValue[orderPayments[0].id],
          isRefundCompleted: false,
          isRefundInProgress: true,
        },
      });
      onPressRefundPayType(orderPayments[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderPaymentRow = ({
      item,
    }: {
      item: PaymentTypeState;
      index: number;
    }) => {
      const orderPayment = orderPayments.find(
        p => p.id === item.id,
      ) as OrderPayment;
      const paymentName = getCardNameAndLast4Digit(orderPayment);

      const state = paymentTypeValue[item.id];
      const PaymentIcon = getPaymentIcon(
        orderPayment.paymentType.name,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (orderPayment?.paymentReceipt as any)?.cardType,
      );
      return (
        <View style={styles.row}>
          <View testID="payment-type-row" style={styles.subRow}>
            <View style={paymentTypeStyles.paymentIconSize}>{PaymentIcon}</View>

            <View style={paymentTypeStyles.paymentSubText}>
              <Text>
                {paymentName}
                {' - ' + formatCurrency(state.tendered)}
              </Text>
            </View>

            {state.isRefundCompleted && (
              <View>
                <Icon
                  size={24}
                  name={'check-circle'}
                  color={theme.colors.states.positive}
                />
              </View>
            )}
            {state.isRefundInProgress && (
              <View>
                <ActivityIndicator size={'small'} color={theme.colors.black} />
              </View>
            )}
            {!!state.error && (
              <View>
                <IconButton
                  icon="redo"
                  iconSize={20}
                  containerStyle={styles.retryBtn}
                  iconStyle={styles.retryIcon}
                />
              </View>
            )}
          </View>
          {!!state.error && (
            <View style={styles.bottomRow}>
              <Text style={styles.errorText}>{state.error}</Text>
            </View>
          )}
        </View>
      );
    };

    return (
      <View style={styles.container}>
        <View style={styles.title}>
          <Text style={styles.titleText}>
            {translate('refundOrder.refundInProgress')}
          </Text>
        </View>

        <View style={styles.modal}>
          <FlatList
            data={Object.values(paymentTypeValue || {})}
            keyExtractor={p => p.id}
            renderItem={renderPaymentRow}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>
    );
  },
);
RefundOrderPaymentStatus.displayName = 'RefundOrderPaymentStatus';
export default RefundOrderPaymentStatus;
