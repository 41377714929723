import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 600,
    padding: 30,
    paddingTop: 40,
    rowGap: 20,
    alignSelf: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.green,
    fontFamily: theme.fonts.medium,
    paddingLeft: 12,
  },
  body: {
    rowGap: 20,
    zIndex: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 20,
  },
  actions: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: 10,
  },
  keyboardAvoidingView: {
    flex: 1,
    justifyContent: 'center',
  },
});

export default styles;
