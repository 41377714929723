import { InMemoryCache } from '@apollo/client/core';

let cache: InMemoryCache | undefined;
export const getApolloCache = (): InMemoryCache => {
  if (!cache) {
    cache = new InMemoryCache({
      typePolicies: {
        ProductPricing: {
          keyFields: false,
        },
        PricingGroup: {
          keyFields: false,
        },
        ProductModifierGroup: {
          keyFields: false,
        },
        Adjustment: {
          keyFields: false,
        },
        Modifier: {
          keyFields: ['id', 'price', 'isDefault'],
        },
        OrderItemModifier: {
          keyFields: false,
        },
        ModifierPricingGroup: {
          keyFields: false,
        },
        OrderItem: {
          keyFields: ['id', 'status'],
        },
        RolePermissions: {
          keyFields: false,
        },
        Order: {
          fields: {
            isEdited: {
              read(isEdited = false) {
                return isEdited;
              },
            },
            dueAt: {
              read(cachedValue, { readField }) {
                return cachedValue || readField('requiredAt');
              },
            },
            lastSyncedEventId: {
              read(cachedValue, { readField }) {
                return cachedValue || readField('prevEventId') || 'unknown';
              },
              merge(existing, incoming, { readField }) {
                return (
                  incoming || existing || readField('prevEventId') || 'unknown'
                );
              },
            },
          },
        },
        OrderTable: {
          keyFields: false,
        },
        Query: {
          fields: {
            orders: {
              merge: (existingOrders, newOrders) => newOrders, // only take new data
            },
            products: {
              merge: (_, newProducts) => newProducts, // only take new data
            },
            variants: {
              merge: (_, newVariants) => newVariants, // only take new data
            },
            categories: {
              merge: (_, newCategories) => newCategories, // only take new data
            },
          },
        },
        SelectedOption: {
          keyFields: false,
        },
        CustomerCount: {
          keyFields: false,
        },
      },
    });
  }
  return cache;
};
