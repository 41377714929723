/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Venue, Store } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { useFocusEffect } from '@react-navigation/native';
import { useVenues } from '../../../../hooks/app/useVenues';
import { useStores } from '../../../../hooks/app/useStores';
import { useNotification } from '../../../../hooks/Notification';
import theme from '../../../../common/default-theme';
import styles from './VenuesScreen.styles';
import Icon from '../../../../components/Icon/Icon';
import Search from '../../../../components/Shared/Search/Search';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import CreateLocationModal from './CreateLocation/CreateLocationModal';

export const VenueSettingsScreen: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const [searchString, setSearchString] = useState('');
  const [venuesData, setVenuesData] = useState([] as Venue[]);

  const {
    getVenues,
    searchVenues,
    createVenue,
    createdVenueId,
    activatedVenueId,
    loading: venueLoading,
    error: venueError,
  } = useVenues();

  const {
    createStore,
    createdStoreId,
    loading: storeLoading,
    error: storeError,
  } = useStores({ storeId: '', venueId: '' });

  const loading = venueLoading || storeLoading;
  const error = venueError || storeError;

  useEffect(() => {
    getVenues();
  }, [getVenues]);

  useFocusEffect(
    useCallback(() => {
      getVenues();
    }, [getVenues]),
  );

  useEffect(() => {
    async function updateData() {
      const searchResult = await searchVenues(searchString);
      setVenuesData(searchResult);
    }
    updateData();
  }, [searchVenues, searchString]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onSearchTextChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  useEffect(() => {
    if (createdVenueId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.VenueCreatedSuccessfully'),
      });
      closeModal();
    }
  }, [closeModal, createdVenueId, showNotification, translate]);

  useEffect(() => {
    if (createdStoreId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.storeCreatedSuccessfully'),
      });
      getVenues();
      closeModal();
    }
  }, [getVenues, createdStoreId, showNotification, translate, closeModal]);

  useEffect(() => {
    if (activatedVenueId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
    }
  }, [activatedVenueId, showNotification, translate]);

  const onPressCreate = useCallback(() => {
    showModal(
      <CreateLocationModal
        venues={venuesData}
        onCreateVenue={createVenue}
        onCreateStore={createStore}
      />,
    );
  }, [createStore, createVenue, showModal, venuesData]);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter
      title={translate('navigation.venuesStores', {
        appName: translate('appName'),
      })}
    >
      <Section
        title={translate('backOfficeVenues.venuesAndStores')}
        subtitle={translate('backOfficeVenues.titleDescription')}
        layoutWidth="medium"
      >
        <View style={styles.filtersContainer}>
          <Search
            testID="search"
            maxLength={50}
            onChangeText={onSearchTextChange}
            placeholder={translate('backOfficeVenues.search')}
            containerStyle={styles.searchContainer}
          />
          <CreateButton onPress={onPressCreate} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeVenues.venuesAndStores')}
            </Text>
          </View>
          <View>
            {venuesData.map((venue: Venue, i: number) => {
              const stores = venue.stores as Store[];
              return (
                <View key={i}>
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('VenueSettings', {
                        venueId: venue.id,
                        title: venue.name,
                      })
                    }
                    style={styles.tableRow}
                    testID="venue-row"
                  >
                    <Text style={styles.tableText}>{venue.name}</Text>
                    <Icon
                      size={20}
                      color={theme.colors.grey5}
                      name="angle-right"
                    />
                  </TouchableOpacity>
                  {stores.map((store: Store, i: number) => (
                    <TouchableOpacity
                      key={i}
                      onPress={(): void =>
                        navigation.navigate('StoreSettings', {
                          venueId: venue.id,
                          storeId: store.id,
                          title: store.name,
                        })
                      }
                      style={styles.tableRow}
                    >
                      <View style={styles.storeName}>
                        <Icon
                          size={20}
                          color={theme.colors.grey5}
                          name="corner-down-right-alt"
                        />
                        {/* eslint-disable-next-line react-native/no-inline-styles */}
                        <Text style={[styles.tableText, { paddingLeft: 10 }]}>
                          {store.name}
                        </Text>
                      </View>
                      <Icon
                        size={20}
                        color={theme.colors.grey5}
                        name="angle-right"
                      />
                    </TouchableOpacity>
                  ))}
                </View>
              );
            })}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
