import React, { useCallback, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { IntegrationApps } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useStores } from '../../../../../../hooks/app/useStores';
import { useAccountingIntegration } from '../../../../../../hooks/app/accountingIntegrations/useAccountingIntegration';
import { useNotification } from '../../../../../../hooks/Notification';
import styles from './StoreSettings.styles';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import Sticker from '../../../../../../components/Shared/Sticker/Sticker';
import Icon from '../../../../../../components/Icon/Icon';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';

export const SettingsTab: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { showModal, closeModal } = useModal();
  const isFocused = useIsFocused();

  const { stores, getStores, loading: storesLoading, error } = useStores();
  const storeIds = useMemo(() => Object.keys(stores), [stores]);

  const {
    accountingIntegrationStoresSettings,
    getAccountingIntegrationStoresSettings,
    toggleAccountingIntegrationStoreConnection: toggleStoreConnection,
    loading: accountingIntegrationLoading,
  } = useAccountingIntegration();

  const { getIntegrationPartnerSettings, integrationPartners } =
    useIntegrationPartners();

  useEffect(() => {
    getStores();
  }, [getStores]);

  useEffect(() => {
    storeIds?.length &&
      getAccountingIntegrationStoresSettings(storeIds, IntegrationApps.XERO);
  }, [getAccountingIntegrationStoresSettings, storeIds]);

  useEffect(() => {
    if (isFocused) {
      getIntegrationPartnerSettings({ appName: IntegrationApps.XERO });
    }
  }, [getIntegrationPartnerSettings, isFocused]);

  const integrationPartnerData = Object.values(integrationPartners)?.[0];

  const storesConnectionStatus = storeIds.map(storeId => {
    const storeSettings = accountingIntegrationStoresSettings.find(
      storeSettings => storeSettings.store === storeId,
    );
    return {
      storeId,
      isActive: integrationPartnerData
        ? storeSettings?.isActive ?? false
        : false,
    };
  });

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const navigateToStore = useCallback(
    (storeId: string) => {
      navigation.navigate('XeroSettingsPage', {
        storeId,
        app: IntegrationApps.XERO,
      });
    },
    [navigation],
  );

  const onConfirm = useCallback(
    storeId => {
      toggleStoreConnection(storeId, IntegrationApps.XERO);
      closeModal();
    },
    [closeModal, toggleStoreConnection],
  );

  const showConfirmationModal = useCallback(
    (storeId: string) => {
      showModal(
        <ConfirmationModal
          onConfirm={() => onConfirm(storeId)}
          title={translate('backOfficeFeatures.disableConfirmationMessage')}
          confirmLabel={translate('settings.confirm')}
          message={translate(
            'backOfficeFeatures.disableConfirmationDescription',
          )}
        />,
        { onBackdropPress: closeModal },
      );
    },
    [showModal, translate, closeModal, onConfirm],
  );

  const loading = storesLoading || accountingIntegrationLoading;

  return (
    <ScreenLayout hideFooter loading={loading} title="Xero Settings | Oolio">
      <Section title={translate('backOfficeFeatures.storeConfiguration')}>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerMapAccount]}>
              {translate('backOfficeSalesFeed.Store')}
            </Text>
          </View>
          <View>
            {storesConnectionStatus?.map(
              (
                {
                  storeId,
                  isActive,
                }: {
                  storeId: string;
                  isActive: boolean;
                },
                i: number,
              ) => (
                <TouchableOpacity
                  key={i}
                  style={theme.tables.row}
                  disabled={!isActive}
                  onPress={() => navigateToStore(storeId)}
                >
                  <InputToggle
                    testID="toggle-store"
                    type="switch"
                    isToggled={isActive}
                    onToggle={() =>
                      isActive
                        ? showConfirmationModal(storeId)
                        : toggleStoreConnection(storeId, IntegrationApps.XERO)
                    }
                  />
                  <Text style={styles.cellStore}>{stores[storeId].name}</Text>
                  <Sticker
                    testID="status-store"
                    type={isActive ? 'positiveLight' : 'negativeLight'}
                    label={translate(
                      isActive ? 'pricings.active' : 'pricings.inActive',
                    )}
                  />
                  {isActive && (
                    <View style={theme.tables.disclosure}>
                      <Icon
                        size={20}
                        name="angle-right"
                        color={theme.colors.grey5}
                      />
                    </View>
                  )}
                </TouchableOpacity>
              ),
            )}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
