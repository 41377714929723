import { BehaviorSubject, Observable } from 'rxjs';
import {
  Colors,
  OnboardingSection,
  OnBoardingStatus,
  StyleFn,
  OnBoardingCheckListItem,
} from '@oolio-group/domain';

export type OnboardingActionListUI = UIOnboardingStatus & {
  action: Function;
  label: string;
  isCompleted: boolean;
  icon?: string;
  textStyle?: StyleFn;
  iconColor?: Colors;
  order: number;
};

export interface UIOnboardingStatus {
  areaName: string;
  sectionKey: OnboardingSection;
  checklist: OnBoardingCheckListItem[];
  order: number;
}

export type UIOnboardingStatusMap = Record<string, UIOnboardingStatus[]>;

export const mapOnboardingUIModel = (
  input: OnBoardingStatus,
): UIOnboardingStatusMap => {
  return input.areas.reduce((acc, area) => {
    return {
      ...acc,
      [area.name]: area.sections.map(section => {
        return {
          areaName: area.name,
          sectionKey: section.key,
          checklist: section.checklist,
          order: section.order,
        } as UIOnboardingStatus;
      }),
    };
  }, {});
};

export class OnboardingUtility {
  private subject = new BehaviorSubject<OnBoardingStatus | undefined>(
    undefined,
  );

  setOnboardingInfo(data: OnBoardingStatus) {
    this.subject.next(data);
  }

  clearOnboardingInfo() {
    this.subject.next(undefined);
  }

  get getOnboardingInfo$(): Observable<OnBoardingStatus | undefined> {
    return this.subject.asObservable();
  }

  complete() {
    this.subject.complete();
  }
}

export const onboardingUtility = new OnboardingUtility();
