/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyleFn } from '@oolio-group/domain';
import React, { useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ViewStyle } from 'react-native';
import GridViewPagination from './GridViewPagination';
import { isWeb } from '../../common/theme';
import maxBy from 'lodash/maxBy';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import isNumber from 'lodash/isNumber';

interface GridBaseViewItemType {
  priority?: number;
}

export interface CatalogProps<DataType extends GridBaseViewItemType> {
  rows: number;
  testID?: string;
  columns: number;
  data: Array<DataType>;
  paginate?: boolean;
  ignorePositionByPriority?: boolean;
  renderItem: (index: number, item?: DataType) => React.ReactNode;
  containerStyle?: ViewStyle | StyleFn;
}

const cell: StyleFn = ({ theme, rows, columns }) => ({
  width: `${(isWeb ? 97 : 95) / columns}%`,
  margin: 2,
  borderRadius: theme.radius.small,
  height: columns > 1 ? `${90 / rows}%` : `${85 / rows}%`,
  overflow: 'hidden',
  backgroundColor: theme.colors.boxBorder,
  justifyContent: 'center',
  alignSelf: 'stretch',
});

const pagination: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: null,
});

const checkDuplicatedPriority = (data: any[]) => {
  if (data?.length <= 0) return [];
  const sortedData = sortBy(data, item => item?.priority);
  let duplicated = false;
  for (let index = 0; index < sortedData.length; index++) {
    if (
      !isNumber(sortedData[index]?.priority) ||
      sortedData[index]?.priority === sortedData[index + 1]?.priority
    ) {
      duplicated = true;
    }
  }

  if (duplicated) {
    return sortedData.map((item, index) => ({ ...item, priority: index }));
  }
  return sortedData;
};

const CatalogGridView = <DataType extends GridBaseViewItemType>({
  rows,
  columns,
  data,
  paginate: showPaginationProp,
  containerStyle,
  renderItem,
  ignorePositionByPriority,
}: CatalogProps<DataType>) => {
  const { css, theme } = useFela();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const cellStyle = cell({ theme, rows, columns });

  const showPagination =
    showPaginationProp || (data || [])?.length > rows * columns;

  // excluding the pagination button cell
  const itemsPerPage = rows * columns - (showPagination ? 1 : 0);
  const paginationStartIndex = (currentPage - 1) * itemsPerPage;
  const paginationEndIndex = currentPage * itemsPerPage;

  const formattedData = ignorePositionByPriority
    ? data.map((item, index) => ({ ...item, priority: index }))
    : checkDuplicatedPriority(data);

  const maxPriority = maxBy(formattedData, d => d.priority)?.priority || 0;

  const numOfRequiredPage = Math.ceil((maxPriority + 1) / itemsPerPage);
  const totalItems = numOfRequiredPage * itemsPerPage;
  const itemPriorityMaps = keyBy(formattedData, 'priority');

  const updatedData = Array(totalItems)
    .fill(null)
    .map((_, index) => itemPriorityMaps[index]);

  const paginatedData = updatedData.slice(
    paginationStartIndex,
    paginationEndIndex,
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  return (
    <View style={css(containerStyle)}>
      {paginatedData.map((item, index) => renderItem(index, item))}
      {showPagination ? (
        <GridViewPagination
          containerStyle={[css(cellStyle), css(pagination)]}
          scrollDirection={columns === 1 ? 'vertical' : 'horizontal'}
          currentPage={currentPage}
          totalPages={numOfRequiredPage}
          onPressNext={(): void => setCurrentPage(prevPage => prevPage + 1)}
          onPressBack={(): void => setCurrentPage(prevPage => prevPage - 1)}
        />
      ) : null}
    </View>
  );
};

export default CatalogGridView;
