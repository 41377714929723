import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Order, UpdateCustomerRequest } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import ConfirmationModal from '../../../Modals/ConfirmationDialog';
import LoadingOverlay from '../../../Shared/Loaders/LoadingOverlay';

export interface EnableOnAccountModalProps {
  order: Order;
  onConfirm?: () => void;
  updateCustomerOnAccount: (input: UpdateCustomerRequest) => Promise<void>;
  loading?: boolean;
}

const EnableOnAccountModal: React.FC<EnableOnAccountModalProps> = ({
  order,
  onConfirm,
  updateCustomerOnAccount,
  loading,
}) => {
  const [session] = useSession();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const customerName = `${order?.customer?.firstName} ${order?.customer?.lastName}`;
  const customerAccountSettings =
    session?.currentVenue?.customerAccountSettings;

  const activateOnAccount = useCallback(() => {
    const { id, customerAccountDetails, ...restCustomerInput } =
      order?.customer || {};

    const customerAccountDetailsInput = {
      accountPayment: true,
      currentBalance: customerAccountDetails?.currentBalance || 0,
      maxOrderLimit:
        customerAccountDetails?.maxOrderLimit ||
        customerAccountSettings?.defaultMaxOrderLimit ||
        100,
      maxBalanceLimit:
        customerAccountDetails?.maxBalanceLimit ||
        customerAccountSettings?.defaultMaxBalanceLimit ||
        1000,
    };
    const customerForm = {
      id,
      customerAccountDetails: customerAccountDetailsInput,
      firstName: restCustomerInput?.firstName,
      lastName: restCustomerInput?.lastName,
    };

    if (order?.customer) {
      updateCustomerOnAccount(customerForm);
      onConfirm && onConfirm();
      closeModal && closeModal();
    }
  }, [
    closeModal,
    customerAccountSettings?.defaultMaxBalanceLimit,
    customerAccountSettings?.defaultMaxOrderLimit,
    onConfirm,
    order?.customer,
    updateCustomerOnAccount,
  ]);

  if (loading) return <LoadingOverlay isLoading={true} />;

  return (
    <View>
      <ConfirmationModal
        type="focus"
        title={translate('order.enableOnAccountModal.title')}
        message={translate('order.enableOnAccountModal.headerText', {
          customerName: customerName,
        })}
        onCancel={closeModal}
        onConfirm={activateOnAccount}
        confirmLabel={translate(
          'order.enableOnAccountModal.enableAccountPayment',
        )}
      />
    </View>
  );
};

export default EnableOnAccountModal;
