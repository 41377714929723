import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  menuScheduleRowContainer: {
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  menuScheduleRowMainDetail: {
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  menuScheduleRowSubDetailContainer: {
    paddingVertical: 20,
  },
  menuScheduleRowSubDetailRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 5,
  },
  menuScheduleRowSubDetailDateRangeContainer: {
    marginRight: 30,
  },
  menuScheduleRowSubDetailTimeBlockDay: {
    marginRight: 30,
  },
  menuScheduleRowSubDetailRowText: {
    color: theme.colors.grey6,
  },
  menuScheduleRowSubDetailDateRangeRowContainer: {
    flexDirection: 'column',
  },
  menuScheduleRowSubDetailRoutineContainer: {
    flexDirection: 'row',
  },
  scheduleRowEditIcon: {
    borderRadius: theme.radius.s,
  },
});

export default styles;
