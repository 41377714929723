import {
  getImportEntityState,
  setImportEntityState,
} from '../../../state/preferences';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useProductImport } from './useProductImport';
import { IMPORT_ENTITY_STATUS, ImportEntity } from '@oolio-group/domain';

const TIMER = 5 * 1000;

export function useImportEntityInterval(fromProductPage = false) {
  const timerRef = useRef<number>();
  const [isImportEntityRunning, setImportProgress] = useState<boolean>(false);
  const {
    importProductId,
    getImportProductDetail,
    importProductDetail,
    processImportProduct,
    loading,
  } = useProductImport();
  const [importProductDetailState, setImportProductDetailState] =
    useState<ImportEntity>();

  const setImportStatusTimer = useCallback(
    importEntityId => {
      timerRef.current = setInterval(() => {
        getImportProductDetail(importEntityId);
      }, TIMER) as unknown as number;
    },
    [getImportProductDetail],
  );

  useEffect(() => {
    if (importProductDetail) {
      setImportProductDetailState(importProductDetail);
      if (
        [IMPORT_ENTITY_STATUS.FAILED, IMPORT_ENTITY_STATUS.COMPLETED].includes(
          importProductDetail.status,
        )
      ) {
        setImportProgress(false);
        clearInterval(timerRef.current);
      } else if (importProductDetail.status === IMPORT_ENTITY_STATUS.RUNNING) {
        setImportProgress(true);
        timerRef.current && clearInterval(timerRef.current);
        setImportStatusTimer(importProductDetail.id);
      }
    }
  }, [importProductDetail, setImportStatusTimer]);

  const clearState = useCallback((isImportProduct = true) => {
    setImportProductDetailState(undefined);
    const storageValueToUpdate = isImportProduct
      ? { isImportProductMessageCleared: true }
      : { isProductListMessageCleared: true };
    setImportEntityState({ ...storageValueToUpdate });
  }, []);

  useEffect(() => {
    if (importProductId) {
      setImportEntityState({ productEntityId: importProductId });
      getImportProductDetail(importProductId);
    }
  }, [importProductId, getImportProductDetail]);

  const getImportProductDetails = useCallback(() => {
    async function cb() {
      const entityState = await getImportEntityState();
      setImportProductDetailState(undefined);
      const isCallable =
        fromProductPage && entityState?.isProductListMessageCleared
          ? false
          : !fromProductPage && entityState?.isImportProductMessageCleared
          ? false
          : true;
      entityState?.productEntityId &&
        isCallable &&
        getImportProductDetail(entityState?.productEntityId);
    }
    cb();
  }, [getImportProductDetail, fromProductPage]);

  useEffect(() => {
    !fromProductPage && getImportProductDetails();
  }, [fromProductPage, getImportProductDetails]);

  return useMemo(
    () => ({
      loading,
      isImportEntityRunning,
      importProductDetailState,
      processImportProduct,
      clearState,
      getImportProductDetails,
    }),
    [
      importProductDetailState,
      processImportProduct,
      isImportEntityRunning,
      loading,
      clearState,
      getImportProductDetails,
    ],
  );
}
