import { FeatureIDs } from '@oolio-group/domain';
import { useCallback, useMemo, useState } from 'react';
import { catalogueUtility } from '../../state/catalogueUtility';
import { syncAppUtility } from '../../state/syncAppUtility';
import { useCatalogue } from './catalogue/useCatalogue';
import { useCheckFeatureEnabled } from './features/useCheckFeatureEnabled';
import { useLoyalty } from './loyalty/useLoyalty';
import { useOrders } from './orders/useOrders';
import { useIntegrationPartners } from './useIntegrationPartners/useIntegrationPartners';
import { useSession } from './useSession';
import { useUserProfile } from './useUserProfile';
import { useNotification } from '../Notification';
import { useTranslation } from '@oolio-group/localization';
export interface useSettingsProps {
  syncApp: () => void;
  loading: boolean;
}

export const useSettingsSync = (): useSettingsProps => {
  const [session] = useSession();
  const { refetchOrdersFromServer } = useOrders();
  const { getMe } = useUserProfile();
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const { getIntegrationPartnerSettings } = useIntegrationPartners();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { getLoyaltyPrograms } = useLoyalty({
    venueId: session.currentVenue?.id,
  });
  const { getAllMenuOptions } = useCatalogue();

  const syncApp = useCallback(async () => {
    try {
      setTimeout(() => setSyncLoading(true), 100);
      syncAppUtility.syncApp();
      catalogueUtility.refetchCatalogue({ forceUpdate: true });
      getAllMenuOptions();
      await refetchOrdersFromServer();
      await getMe(session?.currentOrganization?.id);
      if (isLoyaltyEnabled) {
        getLoyaltyPrograms();
      }
      if (session.currentStore?.id) {
        getIntegrationPartnerSettings({ store: session.currentStore?.id });
      }
      setTimeout(() => setSyncLoading(false), 100);
    } catch (e) {
      showNotification({
        error: true,
        message: `${translate('settings.syncErrorOffline')}`,
      });
    }
    syncAppUtility.onSyncComplete();
  }, [
    getAllMenuOptions,
    refetchOrdersFromServer,
    getMe,
    session?.currentOrganization?.id,
    session.currentStore?.id,
    isLoyaltyEnabled,
    getLoyaltyPrograms,
    getIntegrationPartnerSettings,
    showNotification,
    translate,
  ]);

  return useMemo(
    () => ({
      syncApp: syncApp,
      loading: syncLoading,
    }),
    [syncApp, syncLoading],
  );
};
