import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { View } from 'react-native';
import {
  IntegrationApps,
  MerchantCodeState,
  OnlineOrderingPreferences,
  TimeBlock,
  UpdateIntegrationPartnerInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useStores } from '../../../../../../../hooks/app/useStores';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import { useNotification } from '../../../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { keyBy, mergeWith, isNull } from 'lodash';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import { useIsFocused } from '@react-navigation/native';
import theme from '../../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../../../components/Shared/Inputs/InputToggle';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import Message from '../../../../../../../components/Office/Message/Message';

export enum OrderAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

type IntegrationPreferences = OnlineOrderingPreferences & {
  operatingHoursMap?: { [key: string]: TimeBlock };
} & { locationId?: string; accountId?: string };

export const Checkout: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [integrationPreferences, setIntegrationPreferences] =
    useState<IntegrationPreferences>({
      merchantCode: '',
      enableTip: false,
    });
  const [merchantCodeState, setMerchantCodeState] =
    useState<MerchantCodeState>();

  const route = useRoute();
  const { storeId, app } = route.params as {
    storeId: string;
    app: IntegrationApps;
  };

  const isFocused = useIsFocused();

  const {
    stores,
    loading: storesLoading,
    error: storesError,
  } = useStores({ storeId });

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue.id : '';
  }, [storeId, stores]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    createIntegrationPartner,
    operation,
    error: integrationErr,
    checkMerchantCodeExists,
  } = useIntegrationPartners();

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  useEffect(() => {
    if (storeId && isFocused) {
      getIntegrationPartnerSettings({
        appName: app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, app, storeId, isFocused]);

  const checkMerchantCode = useCallback(async () => {
    if (app === IntegrationApps.OOLIO_STORE) {
      const res = await checkMerchantCodeExists(
        IntegrationApps.OOLIO_STORE,
        integrationPreferences?.merchantCode ?? '',
        integrationPreferences?.merchantStoreRef ?? '',
        storeId,
      );
      return res;
    }
  }, [
    app,
    checkMerchantCodeExists,
    integrationPreferences?.merchantCode,
    integrationPreferences?.merchantStoreRef,
    storeId,
  ]);

  useEffect(() => {
    if (integrationPreferences?.merchantCode) {
      checkMerchantCode().then(setMerchantCodeState);
    }
  }, [checkMerchantCode, integrationPreferences?.merchantCode]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;

      setIntegrationPreferences({
        merchantCode: preferences?.merchantCode || '',
        enableTip: preferences?.enableTip || false,
        merchantStoreRef: preferences?.merchantStoreRef || '',
      });
    }
  }, [integrationPartners, storeId]);

  const loading = storesLoading || integrationLoading;

  const error = storesError || integrationErr;

  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [isUpdated, app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const isSettingsChanged = useCallback(() => {
    const preferences =
      integrationPartners[storeId]?.preferences?.onlineOrdering;
    return (
      preferences &&
      (integrationPreferences?.merchantCode !== preferences?.merchantCode ||
        integrationPreferences?.enableTip !== preferences?.enableTip ||
        integrationPreferences?.merchantStoreRef !==
          preferences?.merchantStoreRef)
    );
  }, [
    integrationPartners,
    integrationPreferences?.enableTip,
    integrationPreferences?.merchantCode,
    integrationPreferences?.merchantStoreRef,
    storeId,
  ]);

  const saveIntegrationSettings = useCallback(async () => {
    const merchantCodeStateResult = await checkMerchantCode();
    setMerchantCodeState(merchantCodeStateResult);

    if (merchantCodeStateResult && merchantCodeStateResult.exist) {
      showNotification({
        error: true,
        message: translate('backOfficeFeatures.merchantCodeAndReferenceExists'),
      });
      return;
    }
    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        integrationPartners[storeId].preferences?.onlineOrdering,
        {
          merchantCode: integrationPreferences?.merchantCode || '',
          enableTip: integrationPreferences?.enableTip || false,
          merchantStoreRef: integrationPreferences?.merchantStoreRef || '',
          deliverySettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.deliverySettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.deliverySettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          pickUpSettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.pickUpSettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.pickUpSettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          dineInSettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.dineInSettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.dineInSettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          menuLayout:
            integrationPartners[storeId].preferences?.onlineOrdering
              ?.menuLayout,
        },
        (sourceValue, destinationValue) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering,
        },
        link: '',
        venue: venueId,
      },
    ];

    if (app === IntegrationApps.OOLIO_STORE && !integrationPartners[storeId]) {
      createIntegrationPartner(
        stripProperties({ ...updateSetting[0] }, '__typename'),
      );
    } else {
      if (!isSettingsChanged()) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noSettingsChanged'),
        });
        return;
      }
      updateIntegrationPartnerSettings(
        stripProperties(updateSetting, '__typename'),
      );
    }
  }, [
    checkMerchantCode,
    integrationPartners,
    storeId,
    integrationPreferences?.merchantCode,
    integrationPreferences?.enableTip,
    integrationPreferences?.merchantStoreRef,
    app,
    venueId,
    showNotification,
    translate,
    createIntegrationPartner,
    isSettingsChanged,
    updateIntegrationPartnerSettings,
  ]);

  const onChangeMainCheckout = useCallback((key, value) => {
    setIntegrationPreferences(prev => ({ ...prev, [key]: value }));
  }, []);

  const isTipEnabled = integrationPreferences?.enableTip;

  return (
    <ScreenLayout
      loading={loading}
      title="Checkout Settings | Oolio"
      onSave={saveIntegrationSettings}
    >
      <Section title={translate('backOfficeFeatures.general')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-tipping"
            type="switch"
            title={translate('backOfficeSettings.enableTipping')}
            isToggled={isTipEnabled || false}
            onToggle={() => onChangeMainCheckout('enableTip', !isTipEnabled)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeFeatures.onlinePayments')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-merchantCode"
            title={translate('backOfficeFeatures.merchantCode')}
            placeholder={translate('backOfficeFeatures.merchantCode')}
            value={integrationPreferences?.merchantCode?.toString()}
            onChangeText={value => onChangeMainCheckout('merchantCode', value)}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-merchantStoreRef"
            title={translate('backOfficeFeatures.merchantStoreRef')}
            placeholder={translate('backOfficeFeatures.merchantStoreRef')}
            value={integrationPreferences?.merchantStoreRef?.toString()}
            onChangeText={value =>
              onChangeMainCheckout('merchantStoreRef', value)
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        {merchantCodeState &&
        (integrationPreferences?.merchantCode ||
          integrationPreferences.merchantStoreRef) ? (
          <Message
            testID="message-status"
            type={merchantCodeState?.active ? 'positive' : 'negative'}
            message={translate(
              merchantCodeState?.active
                ? 'backOfficeFeatures.merchantCodeActiveMessage'
                : 'backOfficeFeatures.merchantCodeInactiveMessage',
            )}
          />
        ) : (
          <></>
        )}
      </Section>
    </ScreenLayout>
  );
};
