/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text } from 'react-native';
import format from 'date-fns/format';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { IMap } from '../../Reports/types';
import styles from '../HomeScreen.styles';
import Section from '../../../../components/Office/Section/Section';
import { StatCardLarge } from '../../../../components/Office/StatCard/StatCardLarge';

interface SalesSnapshotProps {
  dailySales: IMap<string | undefined> | undefined;
  monthlySales: IMap<string | undefined> | undefined;
}

const SalesSnapshot: React.FC<SalesSnapshotProps> = ({
  dailySales,
  monthlySales,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const formatTotalSalesData = (number: string | undefined) => {
    if (number === undefined) {
      return 'N/A';
    } else {
      return formatCurrency(parseFloat(number));
    }
  };

  const formatOrdersData = (number: string | undefined) => {
    if (number === undefined) {
      return 'N/A';
    } else {
      return number;
    }
  };

  return (
    <Section layoutWidth="medium">
      <View style={styles.header}>
        <Text style={styles.title}>{translate('home.salesSnapshotTitle')}</Text>
        <Text style={styles.subtitle}>
          {translate('home.salesSnapshotSubtitle')}
        </Text>
      </View>
      <View style={styles.grid}>
        <View style={styles.row}>
          <StatCardLarge
            testID="salesSnapshot-today"
            title={translate('home.today')}
            subtitle={format(new Date(), 'EEEE, MMMM do')}
            containerStyles={{ flex: 1 }}
            stats={[
              {
                label: translate('home.totalRevenue'),
                value: formatTotalSalesData(
                  dailySales?.['Orders.totalRevenue'],
                ),
              },
              {
                label: translate('home.orders'),
                value: formatOrdersData(dailySales?.['Orders.count']),
              },
            ]}
          />
          <StatCardLarge
            testID="salesSnapshot-month"
            title={translate('home.thisMonth')}
            subtitle={format(new Date(), 'MMMM yyyy')}
            stats={[
              {
                label: translate('home.totalRevenue'),
                value: formatTotalSalesData(
                  monthlySales?.['Orders.totalRevenue'],
                ),
              },
              {
                label: translate('home.orders'),
                value: formatOrdersData(monthlySales?.['Orders.count']),
              },
            ]}
            containerStyles={{ flex: 1 }}
          />
        </View>
      </View>
    </Section>
  );
};

export default SalesSnapshot;
