import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    zIndex: 2,
  },
  container: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  organisation: {
    flex: 1,
    flexDirection: 'row',
    columnGap: 6,
  },
  avatar: {
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.deepPurple,
    overflow: 'hidden',
  },
  avatarText: {
    fontSize: 13,
    lineHeight: 13,
    textAlign: 'center',
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
  },
  name: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
    overflow: 'hidden',
  },
  nameText: {
    fontSize: 13,
    lineHeight: 28,
    fontFamily: theme.fonts.medium,
    color: theme.colors.white,
    overflow: 'hidden',
  },
  nameIcon: {
    minWidth: 20,
  },
  btnExpand: {
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    borderWidth: 1,
    borderColor: theme.colors.black1,
    backgroundColor: theme.colors.teritiary1,
  },
  // Switcher
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  switcher: {
    width: 300,
    overflow: 'hidden',
    ...theme.shadow.strong,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    position: 'absolute',
    top: 60,
    left: 16,
  },
  list: {
    padding: 10,
    rowGap: 8,
  },
  locations: {
    rowGap: 4,
    maxHeight: 440,
  },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    margin: 4,
    padding: 4,
    borderRadius: theme.radius.s,
  },
  locationName: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 8,
  },
  locationNameText: {
    fontSize: 12,
    lineHeight: 24,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderTopWidth: 1,
    borderTopColor: theme.colors.grey3,
    backgroundColor: theme.colors.grey1,
  },
  footerText: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
  },
});

export default styles;
