import { getTokenNumberReceiptPrintableBuffer } from '../../../utils/printerTemplates/billingReceiptLayout';
import {
  PrinterConfig,
  validatePrintConfigurationOrInput,
} from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintBillWorkerInput,
  WorkerInput,
} from '../../utils';

const printTokenReceiptHandler = (
  message: WorkerInput,
): BufferHandlerResult[] => {
  const { order, printerTemplateMapping, session } =
    message.data as PrintBillWorkerInput;

  const data = validatePrintConfigurationOrInput(order, printerTemplateMapping);

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const { printer } = data as PrinterConfig;
  const buffer = getTokenNumberReceiptPrintableBuffer(order, session);

  return [
    {
      printer: {
        id: printer.id,
        device_name: printer.name,
        host: printer.ipAddress,
        port: DEFAULT_NET_PRINTER_PORT,
        bdAddress: printer.bdAddress,
        slug: printer.slug,
        series: printer.series,
        brand: printer.brand,
        type: printer.printerType,
      },
      buffer,
    },
  ];
};
export default printTokenReceiptHandler;
