import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const textStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  filters: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    marginBottom: 20,
  },
  search: {
    flex: 1,
  },
  headerName: {
    flex: 1,
    marginRight: 20,
  },
  headerProducts: {
    width: 70,
    textAlign: 'center',
    marginRight: 20,
  },
  headerAutoFire: {
    width: 70,
    marginRight: 22,
    textAlign: 'center',
  },
  cellName: {
    flex: 1,
    marginRight: 20,
    ...textStyle,
  },
  cellProducts: {
    width: 70,
    textAlign: 'center',
    marginRight: 20,
    fontVariant: ['tabular-nums'],
    ...textStyle,
  },
  cellAutoFire: {
    width: 70,
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Course Details
  searchProduct: {
    marginBottom: 20,
  },
  cellProduct: {
    flex: 1,
    marginLeft: 12,
    marginRight: 10,
    ...textStyle,
  },
  identifier: {
    marginLeft: 12,
    marginRight: 8,
  },
});

export default styles;
