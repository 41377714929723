import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNotification } from '../../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { usePrinters } from '../../../../../../hooks/app/usePrinters';
import {
  CreatePrinterInput,
  Printer,
  PrinterBrand,
  PrinterSeries,
  PrinterType,
  UpdatePrinterInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import pick from 'lodash/pick';
import { NEW_PRINTER_ID } from '../constants';
import {
  isValidIp,
  isValidBDAddress,
  isNotEmpty,
} from '@oolio-group/client-utils';
import { usePrintingWithTemplate } from '../../../../../../hooks/app/usePrintingWithTemplate';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import { sentenceCase } from 'change-case';

export interface ProductData {
  id: string;
  name: string;
  selected: boolean;
}

export interface PageData {
  id: string;
  name: string;
  selected: boolean;
  products: Record<string, ProductData>;
}

export const PrinterDetails: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<Printer>({
    printerType: PrinterType.BLUETOOTH,
  } as Printer);

  const params = route.params as {
    storeId: string;
    printerId: string;
  };
  const isNewPrinter = params.printerId === NEW_PRINTER_ID;
  const storeId = params.storeId;
  const printerId = isNewPrinter ? undefined : params.printerId;
  const { printTestTemplate } = usePrintingWithTemplate();

  const {
    printers,
    updatePrinter,
    createPrinter,
    updatedPrinterId,
    createdPrinterId,
    loading,
    error,
  } = usePrinters({
    printerId,
    storeId,
  });

  useEffect(() => {
    if (printerId && printers[printerId]) {
      !isNewPrinter && setForm(printers[printerId]);
      // update the tab title
      navigation.setOptions({
        tabBarLabel: printers[printerId].name,
      });
    }
  }, [navigation, printers, printerId, isNewPrinter]);

  useEffect(() => {
    // update the tab title
    isNewPrinter &&
      navigation.setOptions({
        tabBarLabel: 'Create New',
      });
  }, [navigation, isNewPrinter]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  // on change events
  const onChange = useCallback((prop: string, value: string): void => {
    let transformedValue = value;
    if (prop == 'bdAddress') transformedValue = value.toUpperCase();
    setForm(form => ({
      ...form,
      [prop]: value || null,
      ...(prop === 'printerType' && {
        bdAddress: undefined,
        ipAddress: undefined,
        slug: undefined,
      }),
      [prop]: transformedValue || null,
    }));
  }, []);

  const onSave = useCallback((): void => {
    const input: CreatePrinterInput | UpdatePrinterInput = pick(form, [
      'id',
      'name',
      'printerType',
      'ipAddress',
      'bdAddress',
      'slug',
      'series',
      'brand',
    ]);

    input.printerType = input.printerType || PrinterType.BLUETOOTH;
    input.brand = input.brand || PrinterBrand.EPSON;
    input.series = input.series || PrinterSeries.TM_M30II;
    if (
      !input.name ||
      (!input.bdAddress && !input.ipAddress && !input.slug) ||
      !isNotEmpty(input.name)
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.fieldsMissing'),
      });
      return;
    }
    if (
      (input.printerType === PrinterType.WIFI ||
        input.printerType === PrinterType.LAN) &&
      !isValidIp(input.ipAddress)
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.ipInvalid'),
      });
      return;
    }

    if (
      input.printerType === PrinterType.BLUETOOTH &&
      !isValidBDAddress(input.bdAddress)
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.bdAddressInvalid'),
      });
      return;
    }

    if (isNewPrinter) {
      createPrinter(input as CreatePrinterInput);
    } else {
      updatePrinter(input as UpdatePrinterInput);
    }
  }, [
    showNotification,
    translate,
    createPrinter,
    updatePrinter,
    form,
    isNewPrinter,
  ]);

  useEffect(() => {
    if (updatedPrinterId) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printerUpdatedSuccessfully'),
      });
    }
  }, [updatedPrinterId, showNotification, translate]);

  useEffect(() => {
    if (createdPrinterId) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printerCreatedSuccessfully'),
      });

      navigation.navigate('StoreSettings', {
        screen: 'Printers',
        storeId,
      });
    }
  }, [createdPrinterId, showNotification, translate, navigation, storeId]);

  const onTestPrint = useCallback(() => {
    printTestTemplate(form);
  }, [form, printTestTemplate]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${form.name || 'Printer'} | Oolio`}
      onSave={onSave}
    >
      <Section title={translate('backOfficeSettings.printerDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('backOfficeSettings.printerName')}
            value={form.name}
            placeholder={translate('backOfficeSettings.printerName')}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-type"
            title={translate('backOfficeSettings.printerType')}
            options={Object.values(PrinterType).map(printerType => ({
              value: printerType,
              label: printerType,
            }))}
            selectedValue={form.printerType}
            onValueChange={onChange.bind(null, 'printerType')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          {(() => {
            switch (form.printerType) {
              case PrinterType.BLUETOOTH:
                return (
                  <InputText
                    testID="input-bd"
                    title={translate('backOfficeSettings.bdAddress')}
                    value={form.bdAddress}
                    placeholder={translate('backOfficeSettings.bdAddress')}
                    onChangeText={onChange.bind(null, 'bdAddress')}
                    containerStyle={theme.forms.inputHalf}
                  />
                );
              case PrinterType.LAN:
                return (
                  <InputText
                    testID="input-ip"
                    title={translate('backOfficeSettings.ipAddress')}
                    value={form.ipAddress}
                    placeholder={translate('backOfficeSettings.ipAddress')}
                    onChangeText={onChange.bind(null, 'ipAddress')}
                    containerStyle={theme.forms.inputHalf}
                  />
                );
              case PrinterType.VIRTUAL:
                return (
                  <InputText
                    testID="input-slug"
                    title={translate('backOfficeSettings.slug')}
                    value={form.slug}
                    placeholder={translate('backOfficeSettings.slug')}
                    onChangeText={onChange.bind(null, 'slug')}
                    containerStyle={theme.forms.inputHalf}
                  />
                );
              default:
                return null;
            }
          })()}
          <TreatPicker
            testID="select-brand"
            title={translate('backOfficeSettings.brand')}
            options={Object.values(PrinterBrand).map(printerType => ({
              value: printerType,
              label: sentenceCase(printerType),
            }))}
            selectedValue={form.brand}
            onValueChange={onChange.bind(null, 'brand')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>

        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-series"
            title={translate('backOfficeSettings.series')}
            options={Object.values(PrinterSeries).map(series => ({
              value: series,
              label: series,
            }))}
            selectedValue={form.series}
            onValueChange={onChange.bind(null, 'series')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View>
          <TreatButton
            testID="btn-test"
            label={translate('button.testPrint')}
            onPress={onTestPrint}
            type="neutral"
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
