import React, { useEffect, useCallback, useState } from 'react';
import { Tax, CreateTaxInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { CREATE_TAX } from '../../../../../../graphql/settings';
import { useNotification } from '../../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { isLessThanLimit, isFloat } from '../../../../../../utils/validator';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface CreateTaxModalProps {
  onAddTax: (tax: TaxToTax) => void;
}

export const CreateTaxModal: React.FC<CreateTaxModalProps> = ({
  onAddTax,
}: CreateTaxModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [createForm, setCreateForm] = useState(
    {} as { name: string; code: string; rate: string },
  );

  const onChangeTax = useCallback((prop: string, value: string) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const [createTax, createdTax] = useMutation(CREATE_TAX, {
    onError: noopHandler,
  });

  const onCreateTax = useCallback(() => {
    if (!createForm.name || !createForm.code || !createForm.rate) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else if (
      !isFloat(String(createForm.rate)) ||
      !isLessThanLimit(createForm.rate, 100)
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'rate' }),
      });
    } else {
      const createTaxInputForm = {
        name: createForm.name,
        code: createForm.code,
        rate: parseFloat(createForm.rate) || 0,
      } as CreateTaxInput;
      createTax({
        variables: {
          input: createTaxInputForm,
        },
      });
    }
  }, [createForm, createTax, showNotification, translate]);

  useEffect(() => {
    if (createdTax.data && createdTax.data.createTax?.id) {
      onAddTax({
        name: createForm.name,
        id: createdTax.data.createTax.id,
        code: createForm.code,
        taxes: [],
        isActive: false,
        rate: createForm.rate,
      });
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.TaxItemCreatedSuccessfully'),
      });
    }
  }, [
    onAddTax,
    createForm,
    createdTax.data,
    showNotification,
    closeModal,
    translate,
  ]);

  useEffect(() => {
    if (createdTax.error) {
      showNotification({
        error: true,
        message: parseApolloError(createdTax.error),
      });
    }
  }, [createdTax.error, showNotification]);

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeSettings.createTax')}
      onConfirm={{
        label: translate('backOfficeSettings.createTax'),
        action: onCreateTax,
      }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('backOfficeSettings.taxName')}
        placeholder={translate('backOfficeSettings.taxName')}
        value={createForm.name}
        onChangeText={onChangeTax.bind(null, 'name')}
      />
      <InputText
        testID="input-code"
        title={translate('backOfficeSettings.taxCode')}
        placeholder={translate('backOfficeSettings.taxCode')}
        value={createForm.code}
        onChangeText={onChangeTax.bind(null, 'code')}
        maxLength={5}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginVertical: 20 }}
      />
      <InputText
        testID="input-rate"
        numericOnly
        alignText="right"
        title={translate('backOfficeSettings.taxRate')}
        placeholder="0.00"
        label="%"
        value={createForm.rate?.toString() || ''}
        onChangeText={onChangeTax.bind(null, 'rate')}
      />
    </TreatModal>
  );
};
