/**
 * Removes listed keys from provided object recursively
 * @param obj object of any type
 * @param propsToDelete list of properties to delete from object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stripProperties = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source: any,
  ...propsToDelete: string[]
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
any => {
  const obj = Array.isArray(source) ? [...source] : { ...source };

  for (const property in obj) {
    if (propsToDelete.includes(property) && !(obj[property] instanceof File)) {
      delete obj[property];
    } else if (typeof obj[property] === 'object' && obj[property]) {
      obj[property] = stripProperties(obj[property], ...propsToDelete);
    }
  }

  return obj;
};
