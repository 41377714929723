import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { TaxesScreen } from './Taxes/TaxesScreen';

const Tab = createMaterialTopTabNavigator();

const TaxesStack: React.FC = () => {
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Taxes"
    >
      <Tab.Screen name="Taxes" component={TaxesScreen} />
    </Tab.Navigator>
  );
};

export default TaxesStack;
