import React, { FC, useCallback } from 'react';
import { useTranslation } from '@oolio-group/localization';
import useBehaviorSubjectState from '../../../../../hooks/app/useSubjectState';
import {
  transferTablesController,
  transferItemsController,
  tableSelectionController,
  transferItemsVisibilityController,
} from '../Sections/floorViewObservables';

import TransferPreview, {
  TransferItem,
} from '../../../../../components/POS/Modals/TransferPreview/TransferPreview';
import { useTablesData } from '../../../../../hooks/app/tables/useTablesData';
import { useNotification } from '../../../../../hooks/Notification';
import { refetchOrderObservable } from '../../../../../hooks/app/orders/ordersObservableUtils';
import useBehaviorSubjectEffect from '../../../../../hooks/app/useSubjectEffect';
import { OrderTransferType } from '@oolio-group/domain';

interface TransferItemsSidePanel {
  isVisible: Boolean;
  onClose: (bool: boolean) => void;
}

const TransferItemsSidePanel: FC = () => {
  const { translate } = useTranslation();
  const { tableOrdersMap, getTablesData } = useTablesData();

  const { setValue: setSelectedTable, value: selectedTable } =
    useBehaviorSubjectState(tableSelectionController);
  const { setValue: setTransferItems } = useBehaviorSubjectState(
    transferItemsController,
  );
  const { value: isTransferItemsVisible, setValue: setIsTransferItemsVisible } =
    useBehaviorSubjectState<boolean>(transferItemsVisibilityController);

  useBehaviorSubjectEffect(refetchOrderObservable, getTablesData);

  const { showNotification } = useNotification();

  const onCloseTransferItems = useCallback(() => {
    setIsTransferItemsVisible(false);
    setSelectedTable(null);
  }, [setIsTransferItemsVisible, setSelectedTable]);

  const handleTransfer = useCallback(
    (
      sourceOrderItems: TransferItem[] = [],
      transferType = OrderTransferType.ITEMS,
    ) => {
      setTransferItems(sourceOrderItems);

      if (transferType === OrderTransferType.TABLES)
        transferTablesController.next(true);

      setIsTransferItemsVisible(false);

      showNotification({
        success: true,
        message: translate('tableFloorView.selectTargetTableMessage'),
      });
    },
    [setIsTransferItemsVisible, showNotification, setTransferItems, translate],
  );

  if (isTransferItemsVisible) {
    const orders = tableOrdersMap[selectedTable?.table?.id ?? ''] ?? [];
    const { orderIndex } = selectedTable || {};

    const selectedOrders =
      orderIndex !== undefined ? [orders[orderIndex]] : orders;

    return (
      <TransferPreview
        orders={selectedOrders}
        onClose={onCloseTransferItems}
        position="right"
        onSubmit={handleTransfer}
      />
    );
  }

  return null;
};

export default TransferItemsSidePanel;
