import React, { useEffect, useMemo } from 'react';
import { FeatureIDs } from '@oolio-group/domain';
import { useRoute } from '@react-navigation/native';
import { useFeatures } from '../../../../../hooks/app/features/useFeatures';
import CustomerAccountScreen from './CustomerAccountScreen';

const FeatureSettingsScreen: React.FC = () => {
  const route = useRoute();

  const routeParams = route.params as {
    featureId: string;
  };

  const { featureId } = routeParams;
  const { features, getFeatures } = useFeatures();

  const selectedFeature = features.find(
    feature => feature.featureId === featureId,
  );

  useEffect(() => {
    getFeatures();
  }, [getFeatures]);

  const featureSettingScreen = useMemo(() => {
    switch (selectedFeature?.featureId) {
      case FeatureIDs.CUSTOMER_ACCOUNT:
        return <CustomerAccountScreen />;
      default:
        return <></>;
    }
  }, [selectedFeature]);

  return <>{featureSettingScreen}</>;
};

export default FeatureSettingsScreen;
