import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Text,
  View,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  TouchableOpacity,
} from 'react-native';
import {
  Customer,
  FeatureIDs,
  RewardRule,
  LoyaltySettings,
} from '@oolio-group/domain';
import { useTranslation, formatPhoneNumber } from '@oolio-group/localization';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import AddCustomer from './AddCustomer';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { UseRewardsInterface } from '../../../hooks/orders/useRewards';
import CustomerRewardModal from '../CustomerLoyalty/CustomerRewardModal';
import styles from './SearchCustomer.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';
import Search from '../../Shared/Search/Search';
import ButtonIcon from '../../Shared/TreatButton/ButtonIcon';
import { capitalCase } from 'change-case';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import { debounce, slice } from 'lodash';
import { useSession } from '../../../hooks/app/useSession';
import { useOolioLoyalty } from '../../../hooks/useOolioLoyalty';

const MAX_RENDERING_ITEMS = 20;

export interface SearchCustomerProps {
  searchListLength?: number;
  orderId?: string;
  assignCustomerToOrder?: (customer: Customer) => void;
  unassignCustomerToOrder?: () => void;
  onClose?: () => void | Promise<void>;
  assignedCustomer?: Customer;
  onRewardRedeem?: ReturnType<UseRewardsInterface>['redeemRewards'];
  loyaltySettings: Partial<LoyaltySettings>;
  rewardRules: RewardRule[];
}

export interface CustomerOptionProps {
  customer: Customer;
  isLoyal: boolean;
  isAssigned?: boolean;
  onPointsPressed: () => void;
  onEdit: (customer: Customer) => void;
  onSelect: (customer: Customer) => void;
  orgCountry: string;
}

export const CustomerOptionRow: React.FC<CustomerOptionProps> = ({
  customer,
  isLoyal,
  isAssigned = false,
  onEdit,
  onSelect,
  onPointsPressed,
  orgCountry,
}: CustomerOptionProps) => {
  const customerCountry = customer?.preferredAddress?.isoCountryCode;
  return (
    <TouchableOpacity
      testID="customer"
      style={styles.option}
      onPress={() => onSelect(customer)}
    >
      <View style={styles.name}>
        <Text numberOfLines={1}>{`${capitalCase(
          customer.firstName,
        )} ${capitalCase(customer?.lastName || '')}`}</Text>
        <Text style={styles.label}>
          {customer.phone?.length > 5
            ? formatPhoneNumber(customer.phone, {
                countryCode: customerCountry,
                intl: customerCountry != orgCountry,
              })
            : customer.email}
        </Text>
      </View>
      {isLoyal && (
        <TouchableOpacity
          testID="btn-loyalty"
          onPress={onPointsPressed}
          style={styles.loyalty}
        >
          <Text style={styles.points}>{customer.loyaltyPointsBalance}</Text>
          <Icon name="star" size={18} color={theme.colors.blue} />
        </TouchableOpacity>
      )}
      <ButtonIcon
        icon="pen"
        type="neutralLight"
        onPress={() => onEdit(customer)}
      />
      <View style={isAssigned ? styles.btnUnassign : theme.tables.disclosure}>
        <Icon
          size={20}
          name={isAssigned ? 'times' : 'angle-right'}
          color={isAssigned ? theme.colors.red : theme.colors.grey5}
        />
      </View>
    </TouchableOpacity>
  );
};

const SearchCustomer: React.FC<SearchCustomerProps> = ({
  orderId,
  assignCustomerToOrder: onAssignCustomerToOrderProp,
  unassignCustomerToOrder,
  onClose,
  assignedCustomer,
  onRewardRedeem,
  loyaltySettings,
  rewardRules,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const {
    error,
    getPaginatedCustomers,
    paginatedCustomers,
    loading,
    updateCustomerInfo: updateCustomerToCache,
    customers: recentCachedCustomers,
  } = useCustomers();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const [session] = useSession();
  const orgCountry = session.currentOrganization?.country as unknown as string;
  const isLegacyLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const { isLoyaltyEnabled: isOolioLoyaltyEnabled } = useOolioLoyalty();
  const isLoyaltyEnabled = isOolioLoyaltyEnabled || isLegacyLoyaltyEnabled;

  const customers = useMemo(() => {
    if (!searchValue)
      return slice(recentCachedCustomers, 0, MAX_RENDERING_ITEMS);
    return paginatedCustomers;
  }, [paginatedCustomers, recentCachedCustomers, searchValue]);

  const debouncedSearch = React.useRef(
    debounce(async (searchValue: string) => {
      setSearchValue(searchValue);
    }, 300),
  ).current;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const assignCustomerToOrder = useCallback(
    (customer: Customer) => {
      // add selected customer to cache to show recent customers
      updateCustomerToCache(customer);
      onAssignCustomerToOrderProp && onAssignCustomerToOrderProp(customer);
    },
    [onAssignCustomerToOrderProp, updateCustomerToCache],
  );

  const onSelectCustomer = useCallback(
    (customer: Customer) => {
      // trigger assignCustomerToOrder event
      // on selecting customer trigger assign customer event
      assignCustomerToOrder && assignCustomerToOrder(customer);
      closeModal();
    },
    [closeModal, assignCustomerToOrder],
  );
  const onChangeSearch = useCallback(
    (searchValue: string) => {
      debouncedSearch(searchValue);
    },
    [debouncedSearch],
  );

  const onEditCustomer = useCallback(
    (customer: Customer) => {
      showModal(
        <AddCustomer
          customer={customer}
          isEditing
          orderId={orderId}
          assignCustomerToOrder={assignCustomerToOrder}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    },
    [showModal, closeModal, assignCustomerToOrder, orderId],
  );
  const onAddNewCustomer = useCallback(() => {
    showModal(
      <AddCustomer
        name={searchValue}
        isEditing={false}
        orderId={orderId}
        assignCustomerToOrder={assignCustomerToOrder}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, searchValue, closeModal, orderId, assignCustomerToOrder]);

  const onRedeem =
    (
      selectedCustomer: Customer,
    ): ReturnType<UseRewardsInterface>['redeemRewards'] =>
    (...args) => {
      closeModal();
      if (selectedCustomer.id !== assignedCustomer?.id) {
        assignCustomerToOrder && assignCustomerToOrder(selectedCustomer);
      }
      onRewardRedeem && onRewardRedeem(...args);
    };

  const onCloseModal = useCallback(() => {
    closeModal();
    onClose && onClose();
  }, [closeModal, onClose]);

  const onPressUnassign = useCallback(() => {
    unassignCustomerToOrder && unassignCustomerToOrder();
    onCloseModal();
  }, [onCloseModal, unassignCustomerToOrder]);

  useEffect(() => {
    if (!searchValue || searchValue.length <= 2) return; // Skip short searches
    getPaginatedCustomers(searchValue);
  }, [getPaginatedCustomers, searchValue]);

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <TouchableOpacity onPress={onCloseModal} style={styles.btnClose}>
        <Icon name="times" size={20} color={theme.colors.dark} />
      </TouchableOpacity>
      <Search
        testID="search-customers"
        placeholder={translate('customer.searchCustomerPlaceholder')}
        value={searchValue}
        onChangeText={onChangeSearch}
        autoFocus={true}
        // onCancel={setSearchValue.bind(null, '')}
        loading={loading}
      />
      <ScrollView style={styles.list}>
        {assignedCustomer && (
          <CustomerOptionRow
            isAssigned
            customer={assignedCustomer}
            isLoyal={
              (isLoyaltyEnabled && assignedCustomer.loyaltyMember) || false
            }
            orgCountry={orgCountry}
            onSelect={onPressUnassign}
            onEdit={() => onEditCustomer(assignedCustomer)}
            onPointsPressed={() => {
              showModal(
                <CustomerRewardModal
                  customer={assignedCustomer}
                  onRedeem={onRedeem(assignedCustomer)}
                  rewardRules={rewardRules}
                  loyaltySettings={loyaltySettings}
                />,
              );
            }}
          />
        )}
        <TouchableOpacity
          testID="btn-addCustomer"
          style={styles.option}
          onPress={onAddNewCustomer}
        >
          <Text>{translate('customer.addNewCustomer')}</Text>
          <View style={theme.tables.disclosure}>
            <Icon name="angle-right" size={20} color={theme.colors.grey5} />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          testID="btn-guestCustomer"
          style={styles.option}
          onPress={onPressUnassign}
        >
          <Text>{translate('customer.continueAsGuest')}</Text>
          <View style={theme.tables.disclosure}>
            <Icon name="angle-right" size={20} color={theme.colors.grey5} />
          </View>
        </TouchableOpacity>
        {customers
          ?.filter(cust => cust.id !== assignedCustomer?.id)
          .map((customer, i: number) => (
            <CustomerOptionRow
              key={i}
              customer={customer}
              isLoyal={(isLoyaltyEnabled && customer.loyaltyMember) || false}
              orgCountry={orgCountry}
              onSelect={() => onSelectCustomer(customer)}
              onEdit={() => onEditCustomer(customer)}
              onPointsPressed={() => {
                showModal(
                  <CustomerRewardModal
                    customer={customer}
                    onRedeem={onRedeem(customer)}
                    rewardRules={rewardRules}
                    loyaltySettings={loyaltySettings}
                  />,
                );
              }}
            />
          ))}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

SearchCustomer.defaultProps = {
  searchListLength: 10,
};

export default SearchCustomer;
