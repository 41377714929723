import React, { useEffect, useCallback } from 'react';
import { View, Text } from 'react-native';
import { OrderType } from '@oolio-group/domain';
import { DELETE_SALES_CHANNEL } from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@oolio-group/localization';
import styles from '../SalesChannels.styles';
import theme from '../../../../../common/default-theme';
import { CreateChannelModal } from '../Modals/CreateChannelModal';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';

interface SalesChannel {
  id: string;
  name: string;
  isActive: boolean;
  integrationStatus: string;
  orderTypes?: OrderType[];
}

interface SalesChannelRowProps {
  SalesChannel: SalesChannel;
  onDeleteSalesChannel: (id: string, name: string) => void;
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  index?: number;
  orderTypeData: OrderType[];
}

interface DeleteModalProps {
  id: string;
  name: string;
  onDeleteSalesChannel: (id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  onDeleteSalesChannel,
  id,
  name,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteSalesChannel, deleteRequest] = useMutation(
    DELETE_SALES_CHANNEL,
    {
      onError: noopHandler,
    },
  );

  useEffect((): void => {
    if (deleteRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(deleteRequest.error),
      });
    }
  }, [deleteRequest.error, showNotification]);

  useEffect((): void => {
    if (deleteRequest.data) {
      onDeleteSalesChannel(id);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.salesChannelDeleted'),
      });
    }
  }, [
    deleteRequest.data,
    showNotification,
    closeModal,
    name,
    onDeleteSalesChannel,
    id,
    translate,
  ]);

  const onDeletePage = useCallback((): void => {
    deleteSalesChannel({ variables: { id: id } });
  }, [deleteSalesChannel, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeletePage}
    />
  );
};

const SalesChannelRow: React.FC<SalesChannelRowProps> = ({
  SalesChannel,
  onDeleteSalesChannel,
  onChange,
  orderTypeData,
}: SalesChannelRowProps) => {
  const { translate } = useTranslation();
  const isDisableAction = ['In Store', 'Online'].includes(SalesChannel.name);

  return (
    <View testID="row-channel" style={theme.tables.row}>
      <InputToggle
        testID="toggle-channel"
        type="switch"
        isToggled={SalesChannel.isActive || false}
        onToggle={onChange.bind(
          null,
          SalesChannel.id,
          'isActive',
          !SalesChannel.isActive,
        )}
      />
      <InputText
        testID="channel-name"
        value={SalesChannel.name}
        placeholder={translate('backOfficeSettings.channelName')}
        onChangeText={onChange.bind(null, SalesChannel.id, 'name')}
        editable={!isDisableAction}
        containerStyle={styles.cellName}
      />
      <SelectMultiple
        testID="select-types"
        options={orderTypeData.map(order => ({
          label: order.name,
          value: order.id,
        }))}
        selectedValues={(SalesChannel.orderTypes || [])?.map(x => x.id)}
        onValueChange={onChange.bind(null, SalesChannel.id, 'orderTypes')}
        containerStyle={styles.cellTypes}
      />
      <ButtonIcon
        testID="btn-deleteChannel"
        icon="trash-alt"
        disabled={isDisableAction}
        type="negativeLight"
        onPress={(): void =>
          onDeleteSalesChannel(SalesChannel.id, SalesChannel.name)
        }
      />
    </View>
  );
};

interface SalesChannelsProps {
  salesChannelsData: SalesChannel[];
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  orderTypesData: OrderType[];
  onDeleteSalesChannel: (id: string) => void;
  onAddSalesChannel: (salesChannel: SalesChannel) => void;
}

export const SalesChannelsSection: React.FC<SalesChannelsProps> = ({
  salesChannelsData,
  onChange,
  orderTypesData,
  onDeleteSalesChannel,
  onAddSalesChannel,
}: SalesChannelsProps) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const onCreateNew = useCallback((): void => {
    showModal(
      <CreateChannelModal
        orderTypeData={orderTypesData}
        onAddSalesChannel={onAddSalesChannel}
      />,
    );
  }, [showModal, orderTypesData, onAddSalesChannel]);

  const onPressDelete = useCallback(
    (id: string, name: string): void => {
      showModal(
        <DeleteModal
          id={id}
          name={name}
          onDeleteSalesChannel={onDeleteSalesChannel}
        />,
      );
    },
    [showModal, onDeleteSalesChannel],
  );

  const sortedChannels = () => {
    return salesChannelsData.sort((a, b) => {
      if (a.name === 'In Store' || a.name === 'Online') {
        if (b.name === 'In Store' || b.name === 'Online') {
          return a.name.localeCompare(b.name);
        }
        return -1;
      }
      if (b.name === 'In Store' || b.name === 'Online') {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
  };

  return (
    <Section
      title={translate('backOfficeSettings.salesChannels')}
      layoutWidth="medium"
    >
      <View style={styles.tableContainer}>
        <View style={theme.tables.header}>
          <Text style={[theme.tables.headerText, styles.cellChannel]}>
            {translate('form.name')}
          </Text>
          <Text style={theme.tables.headerText}>
            {translate('backOfficeSettings.assignedOrderTypes')}
          </Text>
        </View>
        <View style={styles.tableBody}>
          {sortedChannels().map((channel: SalesChannel, i: number) => (
            <SalesChannelRow
              key={i}
              SalesChannel={channel}
              onDeleteSalesChannel={onPressDelete}
              onChange={onChange}
              index={i}
              orderTypeData={orderTypesData}
            />
          ))}
        </View>
        <CreateButton onPress={onCreateNew} />
      </View>
    </Section>
  );
};
