import { gql } from '@apollo/client';

export const FRAGMENTS = {
  terminalDetails: gql`
    fragment TerminalDetails on Terminal {
      id
      name
      uuid
    }
  `,
};

export const GET_TERMINAL_BY_ID_QUERY = gql`
  query terminalById($id: ID!) {
    device(id: $id) {
      ...TerminalDetails
    }
  }
  ${FRAGMENTS.terminalDetails}
`;

export const GET_TERMINALS_BY_STORE_QUERY = gql`
  query devicesByStore {
    devices {
      ...TerminalDetails
    }
  }
  ${FRAGMENTS.terminalDetails}
`;

export const CREATE_TERMINAL = gql`
  mutation createTerminal($input: CreateTerminalInput!) {
    createTerminal(input: $input) {
      ...TerminalDetails
    }
  }
  ${FRAGMENTS.terminalDetails}
`;

export const DELETE_TERMINAL = gql`
  mutation deleteTerminal($id: ID!) {
    deleteTerminal(id: $id)
  }
`;

export const DIAGNOSE_TERMINAL_QUERY = gql`
  query diagnoseTerminal($input: TerminalDiagnosisInput!) {
    diagnoseTerminal(input: $input) {
      success
      message
      additionalInfo {
        status
        canCommunicate
        batteryLevel
        printerStatus
        unconfirmedBatchCount
        firmware
        network
        merchant
        storeId
      }
    }
  }
`;
