import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { BookingStatusEnum } from '@oolio-group/domain';

export const styles = StyleSheet.create({
  container: {
    width: 340,
    rowGap: 8,
    backgroundColor: theme.colors.grey1,
    ...theme.shadow.light,
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    paddingRight: 38,
  },
  btnClose: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    padding: 20,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  meta: {
    rowGap: 16,
  },
  row: {
    rowGap: 4,
  },
  label: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
    fontVariant: ['tabular-nums'],
  },
  value: {
    fontSize: 14,
    lineHeight: 18,
    color: theme.colors.black1,
    fontFamily: theme.fonts.regular,
    fontVariant: ['tabular-nums'],
  },
  actions: {
    rowGap: 4,
  },
});

export const getStatusTextColor = (status: BookingStatusEnum) => {
  switch (status) {
    case BookingStatusEnum.CANCELLED:
    case BookingStatusEnum.NO_SHOW:
      return theme.colors.red;
    case BookingStatusEnum.CHECKED_IN:
    case BookingStatusEnum.COMPLETED:
      return theme.colors.green;
    case BookingStatusEnum.READY:
      return theme.colors.blue;
    default:
      return theme.colors.black1;
  }
};
