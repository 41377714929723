import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 4,
    paddingRight: 6,
    paddingBottom: 6,
  },
  main: {
    flex: 1,
    flexDirection: 'column',
  },
  tileDone: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
});

export default styles;
