/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Address, Tax } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import { useNotification } from '../../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../../utils/validator';
import { VenueDetail } from '../DetailsContainer';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import AddressForm from '../../../../../../components/Shared/Forms/AddressForm';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';

interface Props {
  testID?: string;
  form: VenueDetail;
  taxes: Tax[];
  onChange: (prop: keyof VenueDetail | string, value: string | Address) => void;
  onChangePrintOptions: (prop: string, value: boolean) => void;
  onChangeEmailReceipt: (value: string) => void;
  onPressDelete: () => void;
  onPressSave: () => void;
  targetCountry?: string;
}

export const Details: React.FC<Props> = ({
  form,
  taxes,
  onChange,
  onPressDelete,
  onPressSave,
  targetCountry,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();

  const onDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={() => {
          closeModal();
          onPressDelete();
        }}
      />,
    );
  }, [showModal, onPressDelete, closeModal, translate, form]);

  const onSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.VenueNameIsRequired'),
      });
      return;
    }
    onPressSave();
  }, [showNotification, translate, form, onPressSave]);

  return (
    <ScreenLayout
      title={`${form.name || 'Venue'} | Oolio`}
      onSave={onSave}
      onDelete={onDelete}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('form.venueName')}
            value={form.name || ''}
            placeholder={translate('form.venueName')}
            onChangeText={onChange.bind(null, 'name')}
            maxLength={50}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-tax"
            title={translate('form.defaultTax')}
            options={taxes.map(value => ({
              value: value.id,
              label: value.name,
            }))}
            selectedValue={form.defaultTax || ''}
            onValueChange={onChange.bind(null, 'defaultTax')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputEmail
            testID="input-email"
            title={translate('form.email')}
            value={form.email || ''}
            placeholder={translate('form.email')}
            onChangeText={onChange.bind(null, 'email')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputPhone
            testID="input-phone"
            title={translate('form.phoneNumber')}
            value={form.phoneNumber || ''}
            defaultCountry={form?.phoneCountryCode}
            onChangeText={text => onChange('phoneNumber', text)}
            onPressCountry={country => onChange('phoneCountryCode', country)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <AddressForm
          onChangeAddress={onChange}
          values={form.address}
          keyName="address"
          targetCountry={targetCountry}
        />
      </Section>
    </ScreenLayout>
  );
};
