import {
  OrderItem,
  OrderItemStatus,
  VoidReason,
} from '@oolio-group/domain/dist';
import { useTranslation } from '@oolio-group/localization/dist';
import { StyleFn, RenderProps } from '@oolio-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import CartProduct from '../../POS/Cart/CartProduct/CartProduct';
import { CounterKeypad } from '../../CounterKeypad/CounterKeypad';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import { ButtonProps } from '../../Button/Button';
import { FelaComponent } from 'react-fela';
import IconButton from '../../Button/IconButton';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCallback } from 'react';

export interface SetQuantityProps {
  item: OrderItem;
  onSubmit: (value: number, reason?: VoidReason) => void;
  showReason?: boolean;
  maxValue?: number;
}

const styles = StyleSheet.create({
  container: {
    width: 310,
    alignSelf: 'center',
    paddingVertical: 5,
  },
  titleContainer: {
    width: '100%',
    bottom: 10,
  },
});

const headerStyle: StyleFn = ({ theme }) => ({
  width: 310,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

export const SetQuantityModal: React.FC<SetQuantityProps> = ({
  item,
  onSubmit,
  showReason = true,
  maxValue,
}) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const handleSubmit = useCallback(
    (value: number, reason?: VoidReason) => {
      closeModal();
      onSubmit(value, reason);
    },
    [closeModal, onSubmit],
  );

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={translate('order.setQuantity')}
        titleLeft={<CloseButtons onPress={closeModal} />}
      />
      <Modal showCloseButton={false} contentStyle={styles.container}>
        <CounterKeypad
          maxNumberOfDigit={3}
          minValue={1}
          maxValue={maxValue}
          maxLength={6}
          value={item.quantity}
          onSubmit={handleSubmit}
          showReason={showReason && item.status === OrderItemStatus.IN_PROGRESS}
          title={
            <View style={styles.titleContainer}>
              <CartProduct minimised testID="product" orderItem={item} />
            </View>
          }
        />
      </Modal>
    </View>
  );
};
