import {
  StyleFn,
  Icons,
  WorklogEvent,
  WorklogAction,
  WorklogStatus,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Icon from '../../../components/Icon/Icon';
import scale from '../../../common/theme';
import TimeAndAttendanceScreenButtons from './TimeAndAttendanceButtonSet';
import { useSession } from '../../../hooks/app/useSession';
import { useWorklog } from '../../../hooks/app/worklog/useWorklog';
import { PosUser } from '../../../state/userUtility';
import TimeAndAttendanceTimer from './TimeAndAttendanceTimer';
import usePOSUserRoles from '../../../hooks/app/users/usePOSUserRoles';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { useNotification } from '../../../hooks/Notification';

const boxStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(250),
  height: scale.moderateScale(350),
  borderRadius: theme.radius.large,
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: theme.colors.white,
  paddingTop: theme.padding.large,
  paddingHorizontal: theme.padding.large,
  ...theme.shadow15,
  ...theme.border.borderSolid,
});

const posUserInstructionTitleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  fontFamily: theme.font.bold,
  marginBottom: theme.spacing.small,
  textAlign: 'center',
  color: theme.colors.success,
});

const posUserInstructionStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.regular,
  lineHeight: theme.spacing.medium,
  marginBottom: theme.spacing.big,
  textAlign: 'center',
  color: theme.colors.paragraph,
  paddingHorizontal: theme.spacing.big,
});

const clockStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small,
  alignSelf: 'center',
  height: 62,
  width: 150,
  color: theme.colors.green,
});

export interface WorkLogClockProps {
  user?: PosUser;
  onPressDismiss: () => void;
  onClockedInGoToPOS: () => void;
  title: string;
  instructions: string;
}

const TimeAndAttendanceComponent: React.FC<WorkLogClockProps> = ({
  onPressDismiss,
  onClockedInGoToPOS,
  user,
  title,
  instructions,
}) => {
  const { css } = useFela();
  const [session] = useSession();
  const { pushWorklog } = useWorklog();
  const { updateUserWorkLogBaseOnEvents, loading } = usePOSUserRoles();
  const { canUserAccessPOSApp } = usePOSUserAuthorization();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();

  // TODO: offline support
  // TODO: handle failed worklog events and sync to the api when device is online.

  const publishWorklogEvent = useCallback(
    async (action: WorklogAction) => {
      // TODO: this loading should nbe used from useWorklog
      const event = {
        action,
        timestamp: Date.now(),
        userId: user?.id,
        deviceId: session?.device?.id,
        storeId: session?.currentStore?.id,
        venueId: session?.currentVenue?.id,
        organizationId: session?.currentOrganization?.id,
      } as WorklogEvent;
      await pushWorklog(event);
      if (user?.id) {
        updateUserWorkLogBaseOnEvents([event]);
      }
    },
    [
      pushWorklog,
      session?.currentOrganization?.id,
      session?.currentStore?.id,
      session?.currentVenue?.id,
      session?.device?.id,
      updateUserWorkLogBaseOnEvents,
      user?.id,
    ],
  );

  const handleClockInAndRedirectToPOSApp = useCallback(async () => {
    showNotification({
      error: false,
      message: translate('interimLockScreen.clockScreen.clockedInSuccessfully'),
    });
    await publishWorklogEvent(WorklogAction.WORKLOG_CLOCK_IN);
    onClockedInGoToPOS();
  }, [onClockedInGoToPOS, publishWorklogEvent, showNotification, translate]);

  const handleClockIn = useCallback(async () => {
    await publishWorklogEvent(WorklogAction.WORKLOG_CLOCK_IN);
    onPressDismiss();
  }, [onPressDismiss, publishWorklogEvent]);

  const handleBreakIn = useCallback(async () => {
    await publishWorklogEvent(WorklogAction.WORKLOG_BREAK_START);
    onPressDismiss();
  }, [onPressDismiss, publishWorklogEvent]);

  const handleBreakOut = useCallback(async () => {
    await publishWorklogEvent(WorklogAction.WORKLOG_BREAK_END);
    onPressDismiss();
  }, [onPressDismiss, publishWorklogEvent]);

  const onPressClockOut = useCallback(async () => {
    await publishWorklogEvent(WorklogAction.WORKLOG_CLOCK_OUT);
    onPressDismiss();
  }, [onPressDismiss, publishWorklogEvent]);

  const isBreak = user?.lastWorklog?.status == WorklogStatus.IN_BREAK;

  const breaks = user?.lastWorklog?.breaks;
  const breakTimeStart =
    isBreak && breaks?.length ? breaks[breaks.length - 1].start : undefined;

  const isClockedIn =
    user?.lastWorklog && user?.lastWorklog?.status != WorklogStatus.CLOCKED_OUT;

  const worklogStartTime = isClockedIn
    ? user?.lastWorklog?.clockInTime
    : undefined;

  const breakTime = isClockedIn ? user?.lastWorklog?.breakTime : undefined;

  return (
    <View style={css(boxStyle)}>
      <Icon
        testID="logo"
        style={css(clockStyle)}
        name={Icons.Clock}
        size={60}
      />
      <Text nativeID="workLogTitle" style={css(posUserInstructionTitleStyle)}>
        {title}
      </Text>
      <Text nativeID="workLogInstruction" style={css(posUserInstructionStyle)}>
        {instructions}
      </Text>
      <TimeAndAttendanceTimer
        worklogStartTime={worklogStartTime}
        breakTimeStart={breakTimeStart}
        breakTime={breakTime}
      />
      <TimeAndAttendanceScreenButtons
        testID="timeAndAttendanceButtons"
        onPressClockInOnly={handleClockIn}
        onPressGotoPOS={handleClockInAndRedirectToPOSApp}
        onPressClockOut={onPressClockOut}
        onPressStartBreak={handleBreakIn}
        onPressEndBreak={handleBreakOut}
        onPressDismiss={onPressDismiss}
        isClockedIn={isClockedIn}
        isBreak={isBreak}
        loading={loading}
        canGoToPOS={canUserAccessPOSApp(user?.id || '')}
      />
    </View>
  );
};

const TimeAndAttendanceScreen = React.memo(TimeAndAttendanceComponent);

export default TimeAndAttendanceScreen;
