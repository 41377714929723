/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useNotification } from '../../../../../hooks/Notification';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useKitchenDisplay } from '../../../../../hooks/app/useKitchenDisplay';
import {
  CreateKitchenDisplayInput,
  KitchenDisplay,
  KitchenDisplayMode,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import CreateKitchenDisplayModal from './CreateModal/CreateKitchenDisplayModal';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import KitchenDisplayListRow from './TableRow/KitchenDisplaysRow';
import styles from './KitchenDisplays.styles';
import theme from '../../../../../common/default-theme';
import { useModal } from '@oolio-group/rn-use-modal';

export interface KitchenDisplayData extends KitchenDisplay {
  isCurrent?: boolean;
}

export const KitchenDisplays: React.FC = () => {
  const route = useRoute();
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const {
    kitchenDisplays,
    getKitchenDisplays,
    createKitchenDisplay,
    loading,
    error,
    createdKitchenDisplayId,
  } = useKitchenDisplay({
    storeId: params?.storeId,
  });

  const isCreated = !loading && !error && createdKitchenDisplayId;

  useEffect(() => {
    if (isCreated) {
      showNotification({
        message: translate('kitchenDisplay.createdSucessFully'),
        success: true,
      });
      closeModal();
    }
  }, [closeModal, isCreated, showNotification, translate]);

  const onCreateKitchenDisplayDevice = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (name: string, prepMode: KitchenDisplayMode) => {
      createKitchenDisplay({
        name: name,
      } as CreateKitchenDisplayInput);
    },
    [createKitchenDisplay],
  );

  const onPressCreate = useCallback(() => {
    showModal(
      <CreateKitchenDisplayModal
        onCreate={onCreateKitchenDisplayDevice}
        onDismiss={closeModal}
      />,
    );
  }, [closeModal, onCreateKitchenDisplayDevice, showModal]);

  useEffect(() => {
    if (params?.storeId && isFocused) {
      getKitchenDisplays();
    }
  }, [getKitchenDisplays, isFocused, params?.storeId]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const KitchenDisplayData = useMemo(
    () =>
      Object.values(kitchenDisplays).map(kitchenDisplays => ({
        ...kitchenDisplays,
      })),
    [kitchenDisplays],
  ) as unknown as KitchenDisplay[];

  return (
    <ScreenLayout loading={loading} hideFooter title="Kitchen Displays | Oolio">
      <Section
        title={translate('kitchenDisplay.kitchenDisplays')}
        subtitle={translate('kitchenDisplay.kitchenDisplayDesc')}
        layoutWidth="medium"
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>Name & UUID</Text>
            <Text style={[theme.tables.headerText, styles.headerStatus]}>
              Status
            </Text>
          </View>
          {KitchenDisplayData.map((display: KitchenDisplay, i: number) => (
            <KitchenDisplayListRow
              key={`${i}-${display.id}`}
              KitchenDisplay={display}
              storeId={params?.storeId}
            />
          ))}
        </View>
        <CreateButton onPress={onPressCreate} />
      </Section>
    </ScreenLayout>
  );
};

export default KitchenDisplays;
