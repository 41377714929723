import React from 'react';
import { Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon from '../../../../../components/Icon/Icon';
import theme from '../../../../../common/default-theme';
import styles from './DiscountRule.style';
import { translate } from '@oolio-group/localization';
import { DiscountRuleType } from '@oolio-group/domain';

interface DiscountRuleProp {
  ruleType?: DiscountRuleType;
  iconType: 'angle-down' | 'angle-right';
  onClick: (ruleType?: string) => void;
  discountRuleStyle?: ViewStyle;
  disabled?: boolean;
}

export const DiscountRule: React.FC<DiscountRuleProp> = ({
  iconType,
  ruleType,
  discountRuleStyle,
  onClick,
  disabled = false,
}) => {
  const getDiscountRuleText = (ruleType?: DiscountRuleType) => {
    let title = '',
      description = '';
    switch (ruleType) {
      case DiscountRuleType.PRODUCT_CATEGORY_RULE:
        title = translate(
          'backofficeVenueSettingAdjustments.productOrCategoryDiscount',
        );
        description = translate(
          'backofficeVenueSettingAdjustments.productOrCategoryDiscountDescription',
        );
        break;
      case DiscountRuleType.PRODUCT_CATEGORY_QTY_RULE:
        title = translate('backofficeVenueSettingAdjustments.qtyDiscountRule');
        description = translate(
          'backofficeVenueSettingAdjustments.qtyDiscountDescription',
        );
        break;
      case DiscountRuleType.PRODUCT_CATEGORY_BUY_SOME_GET_SOME_RULE:
        title = translate(
          'backofficeVenueSettingAdjustments.buySomeGetSomeDiscountRule',
        );
        description = translate(
          'backofficeVenueSettingAdjustments.buySomeGetSomeDiscountDescription',
        );
        break;
      default:
        title = translate(
          'backofficeVenueSettingAdjustments.productOrCategoryDiscount',
        );
        description = translate(
          'backofficeVenueSettingAdjustments.productOrCategoryDiscountDescription',
        );
        break;
    }
    return { title, description };
  };
  const { title, description } = getDiscountRuleText(ruleType);

  return (
    <TouchableOpacity
      style={[
        styles.container,
        discountRuleStyle && discountRuleStyle,
        // eslint-disable-next-line react-native/no-inline-styles
        { opacity: disabled ? 0.4 : 1 },
      ]}
      onPress={() => onClick(ruleType)}
      disabled={disabled}
    >
      <View style={styles.text}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
      <View style={styles.iconContainer}>
        <Icon size={24} color={theme.colors.dark} name={iconType} />
      </View>
    </TouchableOpacity>
  );
};
