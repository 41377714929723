import React from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import TreatButton from '../../../../Shared/TreatButton/TreatButton';
import styles from './RefundRequestModal.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../Icon/Icon';

interface RefundRequestModalProp {
  title: string;
  description: string;
  iconName?: string;
  iconColor?: string;
  type?: 'negative' | 'cancel';
}

const RefundRequestModal = ({
  title,
  description,
  iconName = 'exclamation-triangle',
  iconColor = theme.colors.states.negative,
  type = 'negative',
}: RefundRequestModalProp) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.accessory}>
        <Icon size={44} color={iconColor} name={iconName} />
      </View>
      <View>
        <Text style={styles.titleText}>{title}</Text>
        <Text style={styles.subtitleText}>{description}</Text>
      </View>
      <View style={styles.actions}>
        <TreatButton
          type={type}
          testID="btn-dismissRefundFailed"
          label={translate('button.dismiss')}
          onPress={closeModal}
        />
      </View>
    </View>
  );
};

export default RefundRequestModal;
