export const isOlderVersion = (current: string, check: string) => {
  let currentVersion = current.split(/\.|\_|\-/).map(Number);
  let checkVersion = check.split(/\.|\_|\-/).map(Number);
  // Handling cases where X.X version is considered X.X.0
  const maxLength = Math.max(currentVersion.length, checkVersion.length);
  currentVersion = [
    ...currentVersion,
    ...new Array(maxLength - currentVersion.length).fill(0),
  ];
  checkVersion = [
    ...checkVersion,
    ...new Array(maxLength - checkVersion.length).fill(0),
  ];

  for (let i = 0; i < maxLength; i++) {
    if (currentVersion[i] < checkVersion[i]) {
      return true;
    }
    if (currentVersion[i] > checkVersion[i]) {
      return false;
    }
  }

  // If versions are equal, current version is not considered older
  return false;
};
