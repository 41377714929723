/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: theme.colors.black,
  },
  side: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    position: 'absolute',
    top: 30,
    left: 30,
    ...theme.shadow.strong,
  },
  copy: {
    bottom: 40,
    position: 'absolute',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  copyText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  },
  errorContainer: {
    position: 'absolute',
    top: 30,
    right: 30,
    paddingVertical: 13,
    paddingHorizontal: 16,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.red,
  },
  errorText: {
    lineHeight: 20,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  userName: {
    fontSize: 16,
    lineHeight: 22,
    marginBottom: 20,
    textAlign: 'center',
    color: theme.colors.light,
    fontFamily: theme.fonts.medium,
  },
  input: {
    width: 316,
    height: 90,
    marginBottom: 30,
    paddingBottom: 8,
    borderRadius: 45,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#111111',
  },
  inputText: {
    fontSize: 60,
    letterSpacing: 2,
    color: theme.colors.states.focus,
  },
  clearButton: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 24,
    right: 20,
  },
  keypad: {
    width: 316,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  keyButton: {
    width: 100,
    height: 90,
    marginBottom: 8,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#111111',
    borderRadius: 46,
  },
  keyText: {
    fontSize: 24,
    color: theme.colors.white,
    fontFamily: theme.fonts.bold,
    textAlign: 'center',
  },
});

export default styles;
