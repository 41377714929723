import React from 'react';
import { View, Text, StyleSheet, ViewStyle } from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';

export interface ChangeDueProps {
  testID?: string;
  change: number;
  containerStyle?: ViewStyle;
}

const ChangeDue: React.FC<ChangeDueProps> = ({
  change,
  containerStyle,
}: ChangeDueProps) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  return (
    <View
      testID="change-summary-container"
      style={[styles.container, containerStyle]}
    >
      <Text style={styles.text}>{translate('payment.changeDue')}</Text>
      <Text style={styles.text}>{formatCurrency(change)}</Text>
    </View>
  );
};

export default ChangeDue;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
});
