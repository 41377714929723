import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    width: 480,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    textAlign: 'center',
    flex: 1,
    fontFamily: theme.fonts.medium,
    padding: 14,
  },
  modal: {
    width: 480,
    borderRadius: theme.radius.s,
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    ...theme.shadow.strong,
  },
  row: {
    borderColor: theme.colors.grey1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    justifyContent: 'center',
    paddingBottom: 10,
  },
  bottomRow: {
    justifyContent: 'center',
    paddingLeft: 60,
    paddingRight: 20,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.states.negative,
    fontFamily: theme.fonts.medium,
  },
  subRow: {
    height: 62,
    flexDirection: 'row',
    borderColor: theme.colors.grey1,
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  infoTitle: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  infoPositive: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.states.neutral,
    fontFamily: theme.fonts.medium,
  },
  closeIcon: {
    color: theme.colors.black,
    textTransform: 'capitalize',
  },
  closeBtn: {
    position: 'absolute',
    left: 10,
  },
  retryBtn: {
    height: 34,
    width: 34,
    backgroundColor: theme.colors.redLight,
    borderRadius: theme.radius.s,
  },
  retryIcon: {
    color: theme.colors.states.negative,
    transform: [{ scaleX: -1 }],
  },
});
