import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 8,
    paddingVertical: 60,
    paddingHorizontal: 40,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  icon: {
    transform: [{ scale: 0.8 }],
    marginBottom: 12,
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
  },
});

export default styles;
