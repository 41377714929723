import { v4 as uuidv4 } from 'uuid';
import {
  NotificationMode,
  NotificationType,
  OrderAction,
} from '@oolio-group/domain';
import { Session } from '../state/Session';
import { getCountry } from '@oolio-group/localization';

export function generateNotificationEvent(
  session: Session,
  orderId: string,
  triggeredBy: string,
  email: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  phone: any,
) {
  const emailText = email?.trim();
  const phoneText = phone?.number?.trim();

  if (emailText || phoneText) {
    let notificationType = NotificationType.SEND_RECEIPT_AND_MESSAGE;
    let notificationMode = NotificationMode.EMAIL_AND_PHONE;

    if (emailText && !phoneText) {
      notificationType = NotificationType.SEND_RECEIPT;
      notificationMode = NotificationMode.EMAIL;
    }

    if (phoneText && !emailText) {
      notificationType = NotificationType.SEND_MESSAGE;
      notificationMode = NotificationMode.PHONE;
    }

    return {
      action: OrderAction.CUSTOMER_NOTIFICATION,
      orderId: orderId,
      id: uuidv4(),
      notificationType,
      notificationMode,
      organizationId: session.currentOrganization?.id || '',
      venueId: session.currentVenue?.id || '',
      storeId: session.currentStore?.id || '',
      deviceId: session.device?.id || '',
      timestamp: new Date().getTime(),
      triggeredBy: triggeredBy,
      ...(email && { email: email }),
      ...(phone?.number && {
        phone: `${getCountry(phone.countryCode)?.phone} ${phone.number}`,
      }),
    };
  }
}
