import { PrinterProfileType } from '@oolio-group/domain';
import {
  PrinterConfig,
  validatePrintConfigurationOrInput,
} from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintCashDrawerWorkerInput,
  WorkerInput,
} from '../../utils';
import { getPrintableBuffer } from '../../../utils/printerTemplates/cashDrawerTemplate';

const openCashDrawerHandler = (message: WorkerInput): BufferHandlerResult[] => {
  const { printerTemplateMapping } = message.data as PrintCashDrawerWorkerInput;

  const data = validatePrintConfigurationOrInput(
    undefined,
    printerTemplateMapping,
    PrinterProfileType.BILLING || PrinterProfileType.KITCHEN,
  );

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const { printer } = data as PrinterConfig;
  const buffer = getPrintableBuffer();
  return [
    {
      printer: {
        id: printer.id,
        device_name: printer.name,
        host: printer.ipAddress,
        bdAddress: printer.bdAddress,
        series: printer.series,
        brand: printer.brand,
        port: DEFAULT_NET_PRINTER_PORT,
        type: printer.printerType,
      },
      buffer,
    },
  ];
};
export default openCashDrawerHandler;
