import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Customer } from '@oolio-group/domain';
import { formatPhoneNumber } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import styles from './CustomerTile.styles';
import Icon from '../../../../components/Icon/Icon';

interface Props {
  customer: Customer;
  onRemove: () => void;
}

const CustomerTile: React.FC<Props> = ({ customer, onRemove }) => {
  const phone = formatPhoneNumber(customer.phone as string, {
    countryCode: customer?.preferredAddress?.isoCountryCode,
    intl: true,
  });

  const hasEmail = !!customer?.email?.length;

  return (
    <View style={styles.container}>
      <View style={styles.text}>
        <Text
          style={styles.title}
        >{`${customer.firstName} ${customer.lastName}`}</Text>
        <Text style={styles.subtitle}>{`${phone}${
          hasEmail ? ` • ${customer.email}` : ''
        }`}</Text>
      </View>
      <TouchableOpacity onPress={onRemove} style={styles.btnRemove}>
        <Icon size={20} name="times" color={theme.colors.red} />
      </TouchableOpacity>
    </View>
  );
};

export default CustomerTile;
