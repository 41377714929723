/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { Printer, PrinterType } from '@oolio-group/domain';
import styles from '../Printers.styles';
import theme from '../../../../../../common/default-theme';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface Props {
  index: number;
  printer: Printer;
  storeId: string;
  onChange: (id: string, prop: string, value: string) => void;
  onCopy: (id: string) => void;
  onDelete: (id: string) => void;
}

const PRINTER_TYPE_OPTIONS = [
  {
    label: 'Bluetooth',
    value: PrinterType.BLUETOOTH,
  },
  {
    label: 'LAN',
    value: PrinterType.LAN,
  },
  {
    label: 'Virtual',
    value: PrinterType.VIRTUAL,
  },
  {
    label: 'Wi-Fi',
    value: PrinterType.WIFI,
  },
];

const PrintersRow: React.FC<Props> = ({
  index,
  printer,
  storeId,
  onChange,
  onCopy,
  onDelete,
}) => {
  const { translate } = useTranslation();
  const navigation = useNavigation();

  return (
    <View key={index} testID="row-printer" style={theme.tables.row}>
      <InputText
        testID="input-name"
        value={printer.name}
        placeholder={translate('backOfficeSettings.printerName')}
        onChangeText={onChange.bind(null, printer.id, 'name')}
        containerStyle={styles.cellAll}
      />
      <TreatPicker
        testID="select-type"
        options={PRINTER_TYPE_OPTIONS}
        selectedValue={printer.printerType}
        onValueChange={onChange.bind(null, printer.id, 'printerType')}
        containerStyle={styles.cellAll}
      />
      {printer.printerType === 'BLUETOOTH' ? (
        <InputText
          testID="input-bd"
          value={printer.bdAddress}
          placeholder={translate('backOfficeSettings.bdAddress')}
          onChangeText={onChange.bind(null, printer.id, 'bdAddress')}
          containerStyle={styles.cellAll}
        />
      ) : (
        <InputText
          testID="input-ip"
          value={printer.ipAddress}
          placeholder={translate('backOfficeSettings.ipAddress')}
          onChangeText={onChange.bind(null, printer.id, 'ipAddress')}
          containerStyle={styles.cellAll}
        />
      )}
      <ButtonIcon
        testID="btn-edit"
        icon="pen"
        type="neutralLight"
        onPress={(): void => {
          navigation.navigate('PrinterDetails', {
            printerId: printer.id,
            storeId,
          });
        }}
      />
      <ButtonIcon
        testID="btn-copy"
        icon="copy"
        type="neutralLight"
        onPress={onCopy.bind(null, printer.id)}
        containerStyle={{ marginHorizontal: 10 }}
      />
      <ButtonIcon
        testID="btn-delete"
        icon="trash-alt"
        type="negativeLight"
        onPress={onDelete.bind(null, printer.id)}
      />
    </View>
  );
};

export default PrintersRow;
