import React, { FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './SystemStatus.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';

export enum STATES {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
}

export interface SystemStatusAction {
  testID: string;
  label: string;
  type: STATES;
  action?: () => void;
}

const STATUS_MAPPING = {
  positive: {
    icon: {
      size: 20,
      name: 'check-circle',
      color: theme.colors.states.positive,
    },
    text: theme.colors.dark,
    prefix: '',
  },
  negative: {
    icon: {
      size: 18,
      name: 'angle-right',
      color: theme.colors.grey5,
    },
    text: theme.colors.states.negative,
    prefix: '⚠ ',
  },
  neutral: {
    icon: {
      size: 20,
      name: 'angle-right',
      color: theme.colors.grey5,
    },
    text: theme.colors.blue,
    prefix: '',
  },
};

const SystemStatusRow: FC<SystemStatusAction> = ({
  testID,
  label,
  type: status,
  action,
}) => {
  const { icon, text, prefix } =
    STATUS_MAPPING[status] || STATUS_MAPPING.neutral;

  return (
    <TouchableOpacity testID={testID} style={styles.option} onPress={action}>
      <Text style={[styles.text, { color: text }]}>{`${prefix}${label}`}</Text>
      <View style={styles.icon}>
        <Icon size={icon.size} name={icon.name} color={icon.color} />
      </View>
    </TouchableOpacity>
  );
};

export default SystemStatusRow;
