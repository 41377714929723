/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import Svg, { Path, G, Defs, Mask, Use } from 'react-native-svg';

const MasterCard: React.FC = () => {
  return (
    <Svg width="30" height="20" viewBox="0 0 30 20">
      <Defs>
        <Path
          id="path-1"
          d="M2 0h26a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        ></Path>
      </Defs>
      <G
        id="Logos/Mastercard"
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      >
        <Mask id="mask-2" fill="#fff">
          <Use xlinkHref="#path-1"></Use>
        </Mask>
        <Use
          id="Container"
          fill="#FAFAFA"
          fillRule="nonzero"
          xlinkHref="#path-1"
        ></Use>
        <G id="Logo" fillRule="nonzero" mask="url(#mask-2)">
          <G id="Path" transform="translate(5 3.833)">
            <Path
              fill="#F06022"
              d="M7.40769231 11.0051929L13.3615385 11.0051929 13.3615385 1.32057752 7.40769231 1.32057752 7.40769231 10.9975006z"
            ></Path>
            <Path
              fill="#EA1D25"
              d="M7.637 6.007A5.886 5.886 0 0110 1.294C7.537-.593 4.012-.384 1.803 1.781A5.885 5.885 0 000 6.007c0 1.586.649 3.106 1.803 4.226 2.21 2.164 5.734 2.374 8.197.487a5.953 5.953 0 01-2.363-4.713"
            ></Path>
            <Path
              fill="#F79D1D"
              d="M20 6.01a5.91 5.91 0 01-1.8 4.236c-2.223 2.142-5.73 2.347-8.2.48a5.98 5.98 0 002.362-4.716A5.914 5.914 0 0010 1.295c2.461-1.895 5.99-1.689 8.2.48A5.87 5.87 0 0120 6.01"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default MasterCard;
