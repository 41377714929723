import React, { useMemo, useState, useCallback } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { Order, RejectionReason } from '@oolio-group/domain';
import { keyBy } from 'lodash';
import styles from './OnlineOrderSidePanel.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import Sticker from '../../../../../components/Shared/Sticker/Sticker';
import SegmentControl from '../../../../../components/Shared/SegmentControl/SegmentControl';
import CartTotals from '../../../../../components/POS/Cart/CartTotals/CartTotals';
import OnlineOrderActions from './OnlineOrderActions';
import { getTableNameFromOrder } from '@oolio-group/order-helper';

export interface OnlineOrderSidePanelProps {
  loading?: boolean;
  order?: Order;
  showPanel: boolean;
  disableBtn?: boolean;
  tabOptions: { title: string; value: string; component: React.ReactNode }[];
  onClose: () => void;
  onPressComplete: (id: string) => void;
  onPressAccept: (ids: string[]) => void;
  onOrderView?: (orderId: string) => void;
  onPressReprintDocket: (id: string) => void;
  onPressReject: (id: string, reason: RejectionReason) => void;
}

const OnlineOrderSidePanel: React.FC<OnlineOrderSidePanelProps> = ({
  loading,
  order,
  showPanel,
  tabOptions,
  disableBtn,
  onClose,
  onOrderView,
  onPressAccept,
  onPressReject,
  onPressComplete,
  onPressReprintDocket,
}: OnlineOrderSidePanelProps) => {
  const safeHeight = theme.useSafeHeight();

  const [selectedTab, setSelectedTab] = useState(tabOptions?.[0]?.value || '');
  const tabOptionsMap = useMemo(() => keyBy(tabOptions, 'value'), [tabOptions]);

  const selectedTabContainer = useMemo(
    () => tabOptionsMap[selectedTab]?.component || <></>,
    [selectedTab, tabOptionsMap],
  );

  const onChangeTabs = useCallback(value => {
    setSelectedTab(value);
  }, []);

  const isDineIn = order?.table?.name;

  if (!showPanel) {
    return <></>;
  }

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading ? (
        <View style={styles.placeholder}>
          <ActivityIndicator size={20} color={theme.colors.grey5} />
        </View>
      ) : order ? (
        <>
          <View style={styles.title}>
            <TouchableOpacity
              testID="btn-close"
              style={styles.btnClose}
              onPress={onClose}
            >
              <Icon name="times" size={20} color={theme.colors.dark} />
            </TouchableOpacity>
            <Text testID="order-no" style={styles.titleText}>
              {order?.orderNumber}
            </Text>
            <Sticker
              testID="sticker-orderType"
              type={isDineIn ? 'neutralLight' : 'teal'}
              label={getTableNameFromOrder(order) || order?.orderType?.code}
              containerStyle={styles.orderType}
            />
          </View>
          <ScrollView style={styles.content}>
            <SegmentControl
              secondary
              tabs={tabOptions.map(x => ({ label: x.title, value: x.value }))}
              selectedTab={selectedTab}
              onPress={onChangeTabs}
              containerStyle={styles.segments}
            />
            {selectedTabContainer}
          </ScrollView>
          <CartTotals order={order} containerStyle={styles.cartTotals} />
          <OnlineOrderActions
            onPressAccept={onPressAccept}
            onPressComplete={onPressComplete}
            onPressReject={onPressReject}
            onPressReprintDocket={onPressReprintDocket}
            order={order}
            disableBtn={disableBtn}
            onOrderView={onOrderView}
          />
        </>
      ) : null}
    </View>
  );
};

export default OnlineOrderSidePanel;
