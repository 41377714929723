import React, { useEffect, useCallback, useState } from 'react';
import { OrderType, CreateOrderTypeInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useMutation } from '@apollo/client/react/hooks';
import { CREATE_ORDER_TYPE } from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputText from '../../../../../components/Shared/Inputs/InputText';

interface OrderTypeProps {
  onAddOrderType: (orderType: OrderType) => void;
}

export const CreateOrderTypeModal: React.FC<OrderTypeProps> = ({
  onAddOrderType,
}: OrderTypeProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [createForm, setCreateForm] = useState({} as CreateOrderTypeInput);

  const onChange = useCallback((prop: string, value: string) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const [createOrderType, createOperation] = useMutation(CREATE_ORDER_TYPE, {
    onError: noopHandler,
  });

  const onPressCreate = useCallback(() => {
    if (!createForm.code || !createForm.name) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      createOrderType({
        variables: { input: createForm as CreateOrderTypeInput },
      });
    }
  }, [createForm, createOrderType, showNotification, translate]);

  useEffect(() => {
    if (createOperation.data) {
      onAddOrderType(createOperation.data.createOrderType);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.newOrderTypeSuccessfullyAdded'),
      });
    }
  }, [
    createOperation.data,
    showNotification,
    closeModal,
    onAddOrderType,
    translate,
  ]);

  useEffect(() => {
    if (createOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(createOperation.error),
      });
    }
  }, [createOperation.error, showNotification]);

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeSettings.createOrderType')}
      onConfirm={{ action: onPressCreate }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('form.name')}
        placeholder={translate('form.name')}
        value={createForm.name}
        onChangeText={onChange.bind(null, 'name')}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginBottom: 20 }}
      />
      <InputText
        testID="input-code"
        title={translate('backOfficeSettings.code')}
        placeholder={translate('backOfficeSettings.code')}
        value={createForm.code}
        onChangeText={onChange.bind(null, 'code')}
        maxLength={4}
      />
    </TreatModal>
  );
};
