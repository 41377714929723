import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  filters: {
    margin: 20,
    flexDirection: 'row',
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  dropdown: {
    width: 180,
    marginRight: 10,
  },
  table: {
    marginBottom: 80,
    marginHorizontal: 20,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginVertical: 8,
  },
  tableRow: {
    height: 50,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  // Customers
  cellName: {
    width: 200,
    marginLeft: 12,
    marginRight: 20,
  },
  cellPhone: {
    width: 140,
    marginRight: 10,
  },
  cellFlex: {
    flex: 1,
    marginRight: 20,
  },
  cellAmount: {
    width: 100,
    marginRight: 20,
    textAlign: 'right',
  },
  cellButton: {
    marginLeft: 10,
  },
  // Customer History
  info: {
    height: 44,
    marginTop: -60,
    marginBottom: 60,
    marginHorizontal: 20,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  infoAmount: {
    flexDirection: 'row',
  },
  balance: {
    color: theme.colors.red,
    fontFamily: theme.fonts.medium,
  },
  cellType: {
    width: 60,
    marginLeft: 12,
    marginRight: 20,
    textAlign: 'center',
  },
  cellNo: {
    width: 100,
    marginRight: 20,
    textAlign: 'center',
  },
  cellDate: {
    width: 130,
    marginRight: 20,
  },
  btnReceipt: {
    marginHorizontal: 10,
  },
  loadingContainer: {
    marginTop: 80,
  },
});

export default styles;
