import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import {
  GET_AREAS,
  GET_INTEGRATION_PARTNERS_STORE_CONFIGS,
  GET_LOCATIONS,
  GET_WORKFORCE_PARTNER_CONSENT_URL,
  LINK_STORES_WITH_DEPUTY_LOCATIONS,
  CREATE_DEPUTY_INTEGRATION,
  SYNC_EMPLOYEES,
  TOGGLE_INTEGRATION_CONNECTION,
  UPDATE_STORE_WITH_DEPUTY_SETTINGS,
  GET_DEPUTY_INTEGRATION_DATA,
} from './graphql';
import {
  DeputyArea,
  DeputyLocation,
  DeputySettingsInput,
  DeputyStoreLocationLinkInput,
  IntegrationApps,
  IntegrationPartner,
  WorkforceIntegrationStorePreferences,
} from '@oolio-group/domain';
import { useNotification } from '../../Notification';
import { translate } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';

export interface UseWorkforceIntegration {
  loading: boolean;
  error?: string;
  workforcePartnerConsentUrl?: string;
  locations: DeputyLocation[];
  deputyAreas: DeputyArea[];
  integrationPartnerStores?: WorkforceIntegrationStorePreferences;
  getWorkforcePartnerConsentUrl: (integrationApp: IntegrationApps) => void;
  deputyIntegrationData?: IntegrationPartner;
  saveIntegrationDetails: (url: string) => void;
  getLocations: () => void;
  getAllDeputyAreas: (locationId: number) => void;
  getIntegrationPartnerStoreConfig: (storeId: string) => void;
  linkStoresWithDeputyLocations: (
    allStoreLocationLinks: DeputyStoreLocationLinkInput[],
    removeStoreLinks: String[],
    updateStoreLocationLinks: DeputyStoreLocationLinkInput[],
  ) => void;
  getDeputyIntegrationData: () => void;
  updateStoreWithDeputySettings: (
    input: DeputySettingsInput,
    storeId: string,
  ) => Promise<void>;
  toggleWorkforceIntegrationConnection: (
    integrationApp: IntegrationApps,
  ) => void;
  employeeSyncRequestSent: boolean;
  syncEmployees: (storeId: string) => void;
}

export function useWorkforceIntegration(): UseWorkforceIntegration {
  const [workforcePartnerConsentUrl, setWorkforcePartnerConsentUrl] = useState<
    string | undefined
  >();

  const [deputyIntegrationData, setDeputyIntegrationData] = useState<
    IntegrationPartner | undefined
  >();
  const { showNotification } = useNotification();
  const { closeModal } = useModal();
  const [locations, setLocations] = useState<DeputyLocation[]>([]);
  const [deputyAreas, setDeputyAreas] = useState<DeputyArea[]>([]);
  const [integrationPartnerStores, setIntegrationPartnerStores] =
    useState<WorkforceIntegrationStorePreferences>();
  const [employeeSyncRequestSent, setEmployeeSyncRequestSent] =
    useState<boolean>(false);

  const navigation = useNavigation();

  const onRetrieve = useCallback(
    data => {
      if (data && data.getWorkforcePartnerConsentUrl) {
        setWorkforcePartnerConsentUrl(data.getWorkforcePartnerConsentUrl);
      }
    },
    [setWorkforcePartnerConsentUrl],
  );

  const [
    getWorkforcePartnerConsentUrlRequest,
    getWorkforcePartnerConsentUrlResponse,
  ] = useLazyQuery(GET_WORKFORCE_PARTNER_CONSENT_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onRetrieve,
    onError: noopHandler,
  });

  const [getIntegrationPartners, integrationPartnersResponse] = useLazyQuery<{
    integrationPartners: [IntegrationPartner];
  }>(GET_DEPUTY_INTEGRATION_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      const deputyIntegration = response?.integrationPartners?.find(
        item => item.isActive && item.appName === IntegrationApps.DEPUTY,
      );
      setDeputyIntegrationData(deputyIntegration);
    },
  });

  const getDeputyIntegrationData = useCallback(() => {
    getIntegrationPartners({
      variables: { filter: { appName: IntegrationApps.DEPUTY } },
    });
  }, [getIntegrationPartners]);

  const getWorkforcePartnerConsentUrl = useCallback(
    (app: IntegrationApps) => {
      getWorkforcePartnerConsentUrlRequest({
        variables: {
          integrationPartner: app,
        },
      });
    },
    [getWorkforcePartnerConsentUrlRequest],
  );

  const [
    linkStoresWithDeputyLocationRequest,
    linkStoresWithDeputyLocationResponse,
  ] = useMutation<{ linkStoresWithDeputyLocations: IntegrationPartner }>(
    LINK_STORES_WITH_DEPUTY_LOCATIONS,
    {
      onError: noopHandler,
      onCompleted: response => {
        setDeputyIntegrationData(response?.linkStoresWithDeputyLocations);
        showNotification({
          success: true,
          message: translate('settingSection.deputy.notification.mapLocation'),
        });
      },
    },
  );

  const [
    updateStoreWithDeputySettingsRequest,
    updateStoreWithDeputySettingsResponse,
  ] = useMutation(UPDATE_STORE_WITH_DEPUTY_SETTINGS, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        success: true,
        message: translate('settingSection.deputy.notification.updatedAction'),
      });
      closeModal();
    },
  });

  const [saveIntegrationDetailsRequest, saveIntegrationDetailsResponse] =
    useMutation<{ createDeputyIntegration: IntegrationPartner }>(
      CREATE_DEPUTY_INTEGRATION,
      {
        onError: noopHandler,
        onCompleted: response => {
          setDeputyIntegrationData(response?.createDeputyIntegration);
          navigation.navigate('DeputySettings', { screen: 'SettingsTab' });
        },
      },
    );

  const [
    toggleIntegrationConnectionRequest,
    toggleIntegrationConnectionResponse,
  ] = useMutation(TOGGLE_INTEGRATION_CONNECTION, {
    onError: noopHandler,
    onCompleted: response => {
      setDeputyIntegrationData(response.toggleWorkforceIntegrationConnection);
    },
  });

  const [syncEmployeesRequest, syncEmployeesResponse] = useMutation(
    SYNC_EMPLOYEES,
    {
      onError: noopHandler,
    },
  );

  const syncEmployees = useCallback(
    (storeId: string) => {
      setEmployeeSyncRequestSent(false);
      syncEmployeesRequest({
        variables: {
          storeId,
        },
      });
    },
    [syncEmployeesRequest],
  );

  const saveIntegrationDetails = useCallback(
    (url: string) => {
      saveIntegrationDetailsRequest({
        variables: {
          input: url,
        },
      });
    },
    [saveIntegrationDetailsRequest],
  );

  const toggleWorkforceIntegrationConnection = useCallback(
    (app: IntegrationApps) => {
      toggleIntegrationConnectionRequest({
        variables: {
          integrationPartner: app,
        },
      });
    },
    [toggleIntegrationConnectionRequest],
  );

  useEffect(() => {
    if (syncEmployeesResponse.data?.syncEmployees) {
      setEmployeeSyncRequestSent(true);
    }
  }, [syncEmployeesResponse.data?.syncEmployees]);

  useEffect(() => {
    if (toggleIntegrationConnectionResponse.data) {
    }
  }, [toggleIntegrationConnectionResponse.data]);

  const [getLocationsRequest, getLocationsResponse] = useLazyQuery(
    GET_LOCATIONS,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: response => {
        setLocations(response.getAllDeputyLocations || []);
      },
    },
  );

  const [getAllDeputyAreasRequest, getAllDeputyAreasResponse] = useLazyQuery(
    GET_AREAS,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: response => {
        setDeputyAreas(response.getAllDeputyAreas || []);
      },
    },
  );

  const [
    getIntegrationPartnerStoreConfigRequest,
    getIntegrationPartnerStoreConfigResponse,
  ] = useLazyQuery(GET_INTEGRATION_PARTNERS_STORE_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
    onCompleted: response => {
      setIntegrationPartnerStores(
        response?.getIntegrationPartnerStoreConfigs?.preferences || [],
      );
    },
  });

  const getLocations = useCallback((): void => {
    getLocationsRequest();
  }, [getLocationsRequest]);

  const getAllDeputyAreas = useCallback(
    (locationId): void => {
      getAllDeputyAreasRequest({
        variables: {
          locationId: locationId,
        },
      });
    },
    [getAllDeputyAreasRequest],
  );

  const linkStoresWithDeputyLocations = useCallback(
    async (
      allStoreLocationLinks: DeputyStoreLocationLinkInput[],
      removeStoreLinks: String[],
      updateStoreLocationLinks: DeputyStoreLocationLinkInput[],
    ) => {
      linkStoresWithDeputyLocationRequest({
        variables: {
          allStoreLocationLinks,
          removeStoreLinks,
          updateStoreLocationLinks,
        },
      });
    },
    [linkStoresWithDeputyLocationRequest],
  );

  const updateStoreWithDeputySettings = useCallback(
    async (input: DeputySettingsInput, storeId: string): Promise<void> => {
      await updateStoreWithDeputySettingsRequest({
        variables: { input, storeId },
      });
    },
    [updateStoreWithDeputySettingsRequest],
  );

  const getIntegrationPartnerStoreConfig = useCallback(
    (storeId: string): void => {
      getIntegrationPartnerStoreConfigRequest({
        variables: {
          store: storeId,
        },
      });
    },
    [getIntegrationPartnerStoreConfigRequest],
  );

  const RESPONSE_ENTITIES = [
    getWorkforcePartnerConsentUrlResponse,
    saveIntegrationDetailsResponse,
    toggleIntegrationConnectionResponse,
    getLocationsResponse,
    getIntegrationPartnerStoreConfigResponse,
    linkStoresWithDeputyLocationResponse,
    syncEmployeesResponse,
    getAllDeputyAreasResponse,
    integrationPartnersResponse,
    updateStoreWithDeputySettingsResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      workforcePartnerConsentUrl,
      getWorkforcePartnerConsentUrl,
      saveIntegrationDetails,
      toggleWorkforceIntegrationConnection,
      deputyIntegrationData,
      locations,
      deputyAreas,
      getLocations,
      getAllDeputyAreas,
      integrationPartnerStores,
      getIntegrationPartnerStoreConfig,
      linkStoresWithDeputyLocations,
      updateStoreWithDeputySettings,
      employeeSyncRequestSent,
      syncEmployees,
      getDeputyIntegrationData,
    }),
    [
      deputyAreas,
      employeeSyncRequestSent,
      error,
      getAllDeputyAreas,
      getIntegrationPartnerStoreConfig,
      getLocations,
      getWorkforcePartnerConsentUrl,
      deputyIntegrationData,
      integrationPartnerStores,
      linkStoresWithDeputyLocations,
      loading,
      locations,
      saveIntegrationDetails,
      syncEmployees,
      toggleWorkforceIntegrationConnection,
      updateStoreWithDeputySettings,
      workforcePartnerConsentUrl,
      getDeputyIntegrationData,
    ],
  );
}
