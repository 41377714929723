import { StyleSheet, Dimensions } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 40,
    backgroundColor: theme.colors.light,
    minHeight: Dimensions.get('window').height - 122,
  },
  // View Styles
  header: {
    width: 680,
    paddingBottom: 40,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  content: {
    width: 600,
    paddingTop: 40,
  },
  section: {
    paddingBottom: 40,
  },
  subSection: {
    paddingTop: 20,
  },
  similarGrid: {
    width: 980,
    paddingVertical: 40,
    borderTopWidth: 1,
    borderTopColor: theme.colors.grey3,
  },
  tiles: {
    width: '100%',
    paddingTop: 20,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  featureIconContainer: {
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.primaryLight,
  },
  actions: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  imageContainer: {
    width: 44,
    height: 44,
    borderRadius: theme.radius.l,
  },
  // Text Styles
  heading1: {
    fontSize: 18,
    lineHeight: 18,
    color: theme.colors.black2,
    fontFamily: theme.fonts.semibold,
  },
  heading2: {
    fontSize: 16,
    lineHeight: 20,
    paddingBottom: 10,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  heading3: {
    paddingBottom: 8,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.black2,
  },
  modalText: {
    paddingHorizontal: 12,
    lineHeight: 20,
  },
  message: {
    backgroundColor: theme.colors.blueLightest,
    width: 600,
    marginBottom: 40,
  },
  messageContent: {
    color: theme.colors.blue,
    fontSize: 14,
    lineHeight: 20,
    padding: 12,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
