import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';
import { TableStatus } from '@oolio-group/domain';

export const styles = StyleSheet.create({
  // Shared
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 340,
    padding: 10,
    backgroundColor: theme.colors.grey1,
    ...theme.shadow.strong,
  },
  title: {
    height: 44,
    marginBottom: 8,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    marginRight: 44,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    flex: 1,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  details: {
    padding: 10,
  },
  actions: {
    marginTop: 10,
  },
  // Table Info
  actionsRow: {
    marginTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  btnAction: {
    flex: 1,
  },
  row: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  rowTitle: {
    fontSize: 13,
    lineHeight: 20,
    marginBottom: 6,
    color: theme.colors.grey6,
  },
  rowValues: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowValue: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  link: {
    color: theme.colors.states.neutral,
  },
  // Section Statistics
  tableRow: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  tableRowName: {
    paddingVertical: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dot: {
    width: 12,
    height: 12,
    marginLeft: 12,
    borderRadius: 12,
    backgroundColor: theme.colors.red,
  },
  tableRowText: {
    marginLeft: 12,
    fontFamily: theme.fonts.medium,
  },
  tableRowValue: {
    flex: 1,
    paddingRight: 12,
    textAlign: 'right',
    fontFamily: theme.fonts.medium,
  },
});

export const getStickerType = (status?: TableStatus) => {
  switch (status) {
    case TableStatus.SEATED:
      return theme.colors.blue;
    case TableStatus.MULTI_USE:
      return theme.colors.deepPurple;
    case TableStatus.OCCUPIED:
      return theme.colors.blue;
    case TableStatus.DONE_SOON:
      return theme.colors.green;
    default:
      return theme.colors.grey3;
  }
};

export default {
  styles,
  getStickerType,
};
