import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  btnRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  btnContainer: {
    marginRight: 10,
  },

  loadingView: {
    height: 260,
    width: '100%',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    paddingHorizontal: 40,
  },
  message: {
    marginBottom: 20,
  },
  cellLine: {
    width: 60,
  },
  cellName: {
    width: 140,
    marginLeft: 10,
    marginRight: 'auto',
  },
  cellError: {
    flex: 1,
    marginHorizontal: 10,
  },
  tableBody: {
    marginBottom: 20,
    paddingHorizontal: 12,
  },
});

export default styles;
