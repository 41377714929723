import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const baseTextStyle: TextStyle = {
  fontSize: 12,
  lineHeight: 16,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.transparent,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  selected: {
    borderColor: theme.colors.states.positive,
    backgroundColor: theme.colors.states.positiveXL,
  },
  text: {
    flex: 1,
    rowGap: 4,
  },
  name: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  points: {
    ...baseTextStyle,
    color: theme.colors.states.neutral,
  },
  note: {
    ...baseTextStyle,
    color: theme.colors.states.negative,
  },
  icon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
