import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 10,
    backgroundColor: theme.colors.grey1,
    ...theme.shadow.light,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnContainer: {
    flexDirection: 'row',
  },
  btnClose: {
    width: 46,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  orderType: {
    maxWidth: 74,
    marginRight: 4,
  },
  customer: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  customerIcon: {
    width: 48,
    height: 38,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholder: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  errorText: {
    lineHeight: 20,
    textAlign: 'center',
    marginVertical: 20,
    paddingHorizontal: 40,
    color: theme.colors.states.negative,
  },
  content: {
    padding: 10,
    marginTop: 8,
    marginBottom: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  cartTotals: {
    marginBottom: 10,
  },
});

export default styles;
