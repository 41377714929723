import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 380,
    alignSelf: 'center',
  },
  content: {
    padding: 30,
    marginTop: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  custom: {
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    zIndex: 2,
  },
  inputDate: {
    marginBottom: 20,
  },
  presets: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    rowGap: 8,
    columnGap: 8,
    zIndex: 1,
  },
  btnPreset: {
    width: 156,
  },
});

export default styles;
