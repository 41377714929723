import { gql } from '@apollo/client';

export const FRAGMENTS = {
  sectionDetails: gql`
    fragment SectionDetails on Section {
      id
      name
      tableCount
      defaultPrefix
      defaultTableShape
      isActive
      venue {
        id
      }
      stores {
        id
        name
      }
      tables {
        id
        name
      }
    }
  `,
};

export const GET_SECTION = gql`
  query sectionById($id: ID!) {
    section(id: $id) {
      ...SectionDetails
    }
  }
  ${FRAGMENTS.sectionDetails}
`;

export const GET_SECTIONS = gql`
  query sectionsByVenueId($id: ID!) {
    sections(venueId: $id) {
      ...SectionDetails
    }
  }
  ${FRAGMENTS.sectionDetails}
`;

export const CREATE_SECTION = gql`
  mutation createSection($input: CreateSectionInput!) {
    createSection(input: $input) {
      ...SectionDetails
    }
  }
  ${FRAGMENTS.sectionDetails}
`;

export const UPDATE_SECTION = gql`
  mutation updateSection($input: UpdateSectionInput!) {
    updateSection(input: $input) {
      id
    }
  }
`;

export const UPDATE_SECTIONS = gql`
  mutation updateSections($input: [UpdateSectionInput!]) {
    updateSections(input: $input) {
      ...SectionDetails
    }
  }
  ${FRAGMENTS.sectionDetails}
`;

export const DELETE_SECTION = gql`
  mutation deleteSection($id: ID!) {
    deleteSection(id: $id)
  }
`;

export const COPY_SECTION = gql`
  mutation copySection($id: ID!) {
    copySection(id: $id) {
      ...SectionDetails
    }
  }
  ${FRAGMENTS.sectionDetails}
`;
