import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EXPORT_ENTITY_STATUS, ExportEntity } from '@oolio-group/domain';
import { useProductExport } from './useProductExport';
import { downloadFile } from '../../../utils/csv';
import { setExportEntityState } from '../../../state/preferences';
import { useNotification } from '../../Notification';
import { useTranslation } from '@oolio-group/localization';

const TIMER = 5 * 1000;

export function useExportEntityInterval() {
  const timerRef = useRef<number>();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [isExportEntityRunning, setExportProgress] = useState<boolean>(false);
  const {
    getExportProductDetail,
    exportProductDetail,
    processExportProduct,
    loading,
    exportProductJobId,
  } = useProductExport();

  const [exportProductDetailState, setExportProductDetailState] =
    useState<ExportEntity>();

  const setExportStatusTimer = useCallback(
    exportEntityId => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        getExportProductDetail(exportEntityId);
      }, TIMER) as unknown as number;
    },
    [getExportProductDetail],
  );

  useEffect(() => {
    if (exportProductDetail) {
      setExportProductDetailState(exportProductDetail);
      if (exportProductDetail.status === EXPORT_ENTITY_STATUS.COMPLETED) {
        setExportProgress(false);
        showNotification({
          success: true,
          message: translate('backOfficeProducts.exportSuccess'),
        });
        downloadFile(exportProductDetail.fileUrl, 'Exported Products');
        clearInterval(timerRef.current);
      } else if (exportProductDetail.status === EXPORT_ENTITY_STATUS.RUNNING) {
        setExportProgress(true);
        setExportStatusTimer(exportProductDetail.id);
      } else if (exportProductDetail.status === EXPORT_ENTITY_STATUS.FAILED) {
        setExportProgress(false);
        showNotification({
          error: true,
          message: translate('backOfficeProducts.exportFailure'),
        });
        clearInterval(timerRef.current);
      }
    }
  }, [exportProductDetail, setExportStatusTimer, showNotification, translate]);

  useEffect(() => {
    if (exportProductJobId) {
      setExportEntityState({ productEntityId: exportProductJobId });
      getExportProductDetail(exportProductJobId);
    }
  }, [exportProductJobId, getExportProductDetail]);

  return useMemo(
    () => ({
      loading,
      isExportEntityRunning,
      exportProductDetailState,
      processExportProduct,
      getExportProductDetail,
    }),
    [
      loading,
      isExportEntityRunning,
      exportProductDetailState,
      processExportProduct,
      getExportProductDetail,
    ],
  );
}
