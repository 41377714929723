import { WorklogEvent } from '@oolio-group/domain';
import { useCallback } from 'react';
import { parseApolloError } from '../../../utils/errorHandlers';
import { useSubscription } from '@apollo/client/react/hooks';
import { WorklogEvents } from '../../../graphql/subscriptions';
import usePOSUserRoles from '../users/usePOSUserRoles';

interface UseWorkLogEvents {
  error?: string;
}
interface Props {
  deviceId: string;
  currentStoreId: string;
  lastActiveTime: number;
}

export const useWorkLogEvents = (props: Props): UseWorkLogEvents => {
  const { updateUserWorkLogBaseOnEvents } = usePOSUserRoles();

  const onSubscriptionComplete = useCallback(
    subscriptionData => {
      if (subscriptionData?.data?.worklogEvents?.length > 0) {
        const events = (subscriptionData?.data.worklogEvents as WorklogEvent[])
          .slice()
          .sort((a, b) => a.timestamp - b.timestamp);
        updateUserWorkLogBaseOnEvents(events);
      }
    },
    [updateUserWorkLogBaseOnEvents],
  );

  const { error } = useSubscription(WorklogEvents, {
    variables: {
      storeId: props.currentStoreId,
      deviceId: props.deviceId,
      lastActiveTime: props.lastActiveTime,
    },
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData }) =>
      onSubscriptionComplete(subscriptionData),
  });

  const value = {
    error: error ? parseApolloError(error) : undefined,
  };

  return value;
};
