import React from 'react';
import { PrinterProfiles } from './PrinterProfile/PrinterProfiles';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useTranslation } from '@oolio-group/localization';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { ReceiptTemplate } from './ReceiptTemplate/ReceiptTemplate';

const Tab = createMaterialTopTabNavigator();

const PrinterProfileAndReceiptStack: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="PrinterProfile"
    >
      <Tab.Screen
        name="PrinterProfile"
        options={{
          tabBarLabel: translate(
            'backOfficePrinterProfileAndReceiptStack.printerProfile',
          ),
        }}
        component={PrinterProfiles}
      />
      <Tab.Screen
        name="ReceiptOptions"
        options={{
          tabBarLabel: translate(
            'backOfficePrinterProfileAndReceiptStack.receiptOptions',
          ),
        }}
        component={ReceiptTemplate}
      />
    </Tab.Navigator>
  );
};

export default PrinterProfileAndReceiptStack;
