import { useModal } from '@oolio-group/rn-use-modal';
import React, { useCallback } from 'react';
import { View, Text, KeyboardAvoidingView } from 'react-native';
import Button from '../../Button/Button';
import { useFela } from 'react-fela';
import { StyleFn } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';

const actionContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: 30,
});

const buttonContainerStyle: StyleFn = () => ({
  marginLeft: 10,
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.semibold,
  textTransform: 'capitalize',
  letterSpacing: -0.4,
  color: theme.colors.orange,
  marginTop: 20,
});

const headingContainer: StyleFn = () => ({});

const descriptionStyle: StyleFn = ({ theme }) => ({
  marginTop: 20,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  color: theme.colors.textLight,
});

const modalStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.medium,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  width: 550,
  alignSelf: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 30,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  fontSize: 12,
  lineHeight: 21,
  letterSpacing: -0.4,
});

const confirmButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
});
const secondConfirmButton: StyleFn = ({
  theme,
  isSecondConfirmButtonDisabled,
}) => ({
  color: isSecondConfirmButtonDisabled
    ? theme.colors.textLight
    : theme.colors.orange,
});

export interface WarningModalProps {
  title: string;
  description: string;
  confirmButtonText: string;
  secondConfirmButtonText: string;
  isSecondConfirmButtonDisabled?: boolean;
  onConfirm: () => void;
  onConfirmSecondButton: (x: boolean) => void;
  onCancel: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  title,
  description,
  confirmButtonText,
  secondConfirmButtonText,
  isSecondConfirmButtonDisabled,
  onConfirm,
  onConfirmSecondButton,
  onCancel,
}) => {
  const { css, theme } = useFela({ isSecondConfirmButtonDisabled });
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [closeModal, onCancel]);

  const onPressConfirm = useCallback(() => {
    closeModal();
    onConfirm();
  }, [closeModal, onConfirm]);

  return (
    <KeyboardAvoidingView style={css(modalStyle)}>
      <View style={css(headingContainer)}>
        <Text style={css(titleStyle)}>{title}</Text>
        <Text style={css(descriptionStyle)}>{description}</Text>
      </View>
      <View style={css(actionContainerStyle)}>
        <Button
          size="small"
          testID="cancel-button"
          title={(translate('settings.cancel') as string).toUpperCase()}
          onPress={onPressCancel}
          labelStyle={css(buttonStyle, dismissButtonStyle)}
          containerStyle={css(buttonContainerStyle)}
          color={theme.colors.white}
        />
        {Boolean(secondConfirmButtonText) && (
          <Button
            size="small"
            testID="second-confirm-button"
            title={secondConfirmButtonText.toUpperCase()}
            onPress={() => {
              closeModal();
              onConfirmSecondButton(true);
            }}
            labelStyle={css(buttonStyle, secondConfirmButton)}
            containerStyle={css(buttonContainerStyle)}
            color={theme.colors.orangeLighter}
            disabled={isSecondConfirmButtonDisabled}
          />
        )}
        <Button
          size="small"
          testID="confirm-button"
          title={confirmButtonText}
          onPress={onPressConfirm}
          labelStyle={css(buttonStyle, confirmButtonStyle)}
          color={theme.colors.orange}
          containerStyle={css(buttonContainerStyle)}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default WarningModal;
