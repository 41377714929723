import React, { useMemo } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { View, ViewStyle } from 'react-native';
import theme from '../../../../../common/default-theme';
import styles from './Adjustments.style';
import {
  Adjustment,
  DiscountOperator,
  DiscountRuleCriteria as DiscountRuleCriteria_,
  DiscountRuleType,
} from '@oolio-group/domain';
import SelectSearch, {
  OptionsType,
} from '../../../../../components/Shared/Select/SelectSearch';
import cloneDeep from 'lodash/cloneDeep';
import Chip from '../../../../../components/Office/Chip/Chip';
import InputDropdown from '../../../../../components/Shared/Inputs/InputDropdown';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import Message from '../../../../../components/Office/Message/Message';

const DiscountOperatorMapper = {
  [DiscountOperator.EXACTLY]: 'Exactly',
  [DiscountOperator.MORE_THAN]: 'More than',
};

export enum RuleCriteriaMode {
  Purchased = 'Purchased',
  Discounted = 'Discounted',
}

interface AdvanceDiscountRuleCriteriaProp {
  mode: RuleCriteriaMode;
  form: Adjustment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeFormInput: (key: string, value: any) => void;
  entityOptions: OptionsType[];
  entityIds: {
    purchased: string[];
    discounted: string[];
  };
  onSelectEntity: (key: string, values: string[]) => void;
  getEntityName: (ids: string[], isCountDisplayed?: boolean) => string;
  onRemoveEntity: (key: string, entityId: string) => void;
  containerStyle?: ViewStyle;
}

export const AdvanceDiscountRuleCriteria: React.FC<
  AdvanceDiscountRuleCriteriaProp
> = ({
  mode,
  form,
  onChangeFormInput,
  entityOptions,
  entityIds,
  onSelectEntity,
  getEntityName,
  onRemoveEntity,
  containerStyle,
}) => {
  const { translate } = useTranslation();

  const discountMode = useMemo(() => {
    let discountKey = '',
      entityKey = '',
      purchasedOrDiscountInfoLabel = '',
      purchasedOrDiscountInputLabel = '',
      purchasedOrDiscountSearchPlaceholder = '';
    const operatorOptions = [
      {
        value: DiscountOperator.EXACTLY,
        label: DiscountOperatorMapper.EXACTLY,
      },
    ];
    switch (mode) {
      case RuleCriteriaMode.Purchased:
        discountKey = 'purchasedCriteria';
        entityKey = 'purchased';
        purchasedOrDiscountInfoLabel = translate(
          'backofficeVenueSettingAdjustments.productOrCategoryDiscountInfoText',
        );
        purchasedOrDiscountInputLabel = translate(
          'backofficeVenueSettingAdjustments.promoPurchaseQuantity',
        );
        purchasedOrDiscountSearchPlaceholder = translate(
          'backofficeVenueSettingAdjustments.productOrCategorySearchBox',
        );
        operatorOptions.push({
          value: DiscountOperator.MORE_THAN,
          label: DiscountOperatorMapper.MORE_THAN,
        });
        break;
      case RuleCriteriaMode.Discounted:
        discountKey = 'discountedCriteria';
        entityKey = 'discounted';
        purchasedOrDiscountInfoLabel = translate(
          'backofficeVenueSettingAdjustments.buySomeGetSomeDiscountedInfoText',
        );
        purchasedOrDiscountInputLabel = translate(
          'backofficeVenueSettingAdjustments.discountQuantity',
        );
        purchasedOrDiscountSearchPlaceholder = translate(
          'backofficeVenueSettingAdjustments.discountedEntitySearchPlaceholder',
        );
        break;
    }
    return {
      discountKey,
      entityKey,
      purchasedOrDiscountInfoLabel,
      purchasedOrDiscountInputLabel,
      purchasedOrDiscountSearchPlaceholder,
      operatorOptions,
    };
  }, [translate, mode]);

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={containerStyle}>
      <Message
        message={discountMode.purchasedOrDiscountInfoLabel}
        type={'neutral'}
        containerStyle={styles.topSpacing}
        icon="info-circle"
      />

      {[
        DiscountRuleType.PRODUCT_CATEGORY_QTY_RULE,
        DiscountRuleType.PRODUCT_CATEGORY_BUY_SOME_GET_SOME_RULE,
      ].includes(form.discountRuleType as DiscountRuleType) && (
        <InputDropdown
          testID="promo-operator"
          title={discountMode.purchasedOrDiscountInputLabel}
          placeholder={translate('backOfficeProductsSummary.Quantity')}
          value={String(
            form?.advanceRules?.[discountMode.discountKey]?.qty || '',
          )}
          selectedOption={
            form?.advanceRules?.[discountMode.discountKey]?.operator ||
            DiscountOperator.EXACTLY
          }
          options={discountMode.operatorOptions}
          onChangeText={value =>
            onChangeFormInput(discountMode.discountKey, {
              ...form.advanceRules?.[discountMode.discountKey],
              qty: +formatToDigitsStr(value),
            } as DiscountRuleCriteria_)
          }
          onOptionChange={value =>
            onChangeFormInput(discountMode.discountKey, {
              ...form.advanceRules?.[discountMode.discountKey],
              operator: value,
            } as DiscountRuleCriteria_)
          }
          direction="ltr"
          alignText="left"
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={[theme.forms.inputHalf, styles.topSpacing]}
        />
      )}

      <SelectSearch
        testID="search-product-or-category"
        options={entityOptions}
        selectedOptions={cloneDeep(entityIds?.[discountMode.entityKey])}
        onChangeOption={(values: string[]) =>
          onSelectEntity(discountMode.discountKey, values)
        }
        containerStyle={styles.topSpacing}
        placeholder={discountMode.purchasedOrDiscountSearchPlaceholder}
      />

      <View>
        {entityIds?.[discountMode.entityKey]?.length > 0 && (
          <View style={styles.selectedItemChipContainer}>
            {entityIds?.[discountMode.entityKey].map((id: string) => {
              const title = getEntityName([id]);
              if (!title) return null;
              return (
                <Chip
                  title={title}
                  icon="times"
                  key={id}
                  onPress={() => onRemoveEntity(discountMode.discountKey, id)}
                />
              );
            })}
          </View>
        )}
      </View>
    </View>
  );
};
