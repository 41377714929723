import { Table } from '@oolio-group/domain';

export const sortTablesByName = (tables: Table[]): Table[] => {
  const sortedTables = [] as Table[];

  const helperArray = tables.map(table => ({
    id: table.id,
    number: parseInt(table.name.replace(/(.+-)/g, '')),
  }));

  helperArray.sort((a, b) => a.number - b.number);

  helperArray.forEach(obj => {
    const table = tables.find(t => t.id === obj.id);
    table && sortedTables.push(table);
  });

  return sortedTables;
};

export const getHighestTableNumber = (tables: Table[]): number => {
  if (tables.length === 0) return 0;
  const tableNumbers = tables
    .map(table => parseInt(table.name.replace(/(.+-)/g, '')))
    .filter(number => !isNaN(number));
  return Math.max(...tableNumbers, 0);
};
