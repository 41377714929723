/* eslint-disable react-native/no-inline-styles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View, StyleSheet } from 'react-native';
import {
  useTranslation,
  getCountry,
  parsePhoneNumber,
  Timezone,
  TIMEZONES_MAP,
  getBusinessIdentifierType,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { ImageUploadInput, UpdateOrganizationInput } from '@oolio-group/domain';
import { useNotification } from '../../../hooks/Notification';
import { useSession } from '../../../hooks/app/useSession';
import { useOrganization } from '../../../hooks/app/useOrganization';
import { Address } from '@oolio-group/domain';
import {
  isValidDomain,
  isValidEmail,
  isValidName,
  isValidBusinessName,
  isNotEmpty,
  isValidBusinessIdentifier,
  isValidPostCode,
} from '../../../utils/validator';
import theme from '../../../common/default-theme';
import InputText from '../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../components/Shared/Inputs/InputPhone';
import InputEmail from '../../../components/Shared/Inputs/InputEmail';
import TreatPicker from '../../../components/Shared/Select/Picker';
import ImagePicker from '../../../components/Office/ImagePicker/ImagePicker';
import Message from '../../../components/Office/Message/Message';
import Section from '../../../components/Office/Section/Section';
import ScreenLayout from '../../../components/Office/ScreenLayout/ScreenLayout';
import { capitalCase } from 'change-case';
import AddressForm from '../../../components/Shared/Forms/AddressForm';
import { isEqual } from 'lodash';

interface FormData {
  // Organisation
  businessName: string;
  businessIdentifier: string;
  currency: string;
  website: string;
  // Contact
  contactName: string;
  phone: string;
  email: string;
  phoneCountryCode: string; // used for component internal identification
  // Location
  timeZone: string;
  address?: Address;
  logoUrl?: string;
  imageRawData?: ImageUploadInput;
}

const initialFormState: FormData = {
  businessName: '',
  businessIdentifier: '',
  website: '',
  contactName: '',
  phone: '',
  email: '',
  timeZone: '',
  currency: '',
  phoneCountryCode: '',
  imageRawData: undefined,
};

export const GeneralSettingsScreen: React.FC = () => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormData>(initialFormState);
  const originalStateRef = useRef<FormData>();
  const {
    organization,
    error,
    updateOrganization,
    getOrganizationById,
    loading,
  } = useOrganization();

  const businessIdentifierType = getBusinessIdentifierType(
    form?.address?.isoCountryCode || '',
  );

  const optionalFields = !!businessIdentifierType
    ? ['line2', 'suburb', 'website']
    : ['line2', 'suburb', 'website', 'businessIdentifier'];

  useEffect(() => {
    if (session.currentOrganization?.id) {
      getOrganizationById(session.currentOrganization.id);
    }
  }, [session, getOrganizationById]);

  useEffect((): void => {
    if (organization) {
      const { phoneNumber, countryCode } = parsePhoneNumber(
        organization.phone ?? '',
      );
      const initialForm = {
        // Organisation
        logoUrl: organization?.logoUrl || '',
        businessName: organization.name || '',
        businessIdentifier: organization.businessIdentifier || '',
        currency: organization.currencyCode || '',
        website: organization.website || '',
        // Contact
        phoneCountryCode: (countryCode || organization?.country) ?? '',
        phone: phoneNumber || '',
        contactName: organization?.contactName || '',
        timeZone: organization.timezone || '',
        email: organization.email || '',
        // Address
        // isoCountryCode getting from orgzanition.country for backward compatibility
        address: {
          ...organization.address,
          isoCountryCode:
            organization.address?.isoCountryCode ||
            organization.country ||
            DEFAULT_COUNTRY_CODE,
        } as Address,
      };

      setForm(initialForm);
      originalStateRef.current = initialForm;
    }
  }, [organization, session]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const getFormattedPhone = useCallback(() => {
    if (form.phoneCountryCode) {
      return `${getCountry(form.phoneCountryCode).phone} ${form.phone}`;
    }
    return form.phone;
  }, [form.phone, form.phoneCountryCode]);

  const formValidationStatus: Record<string, boolean> = useMemo(() => {
    const { line1, line2, city, state, postalCode, isoCountryCode } =
      form.address || {};
    return {
      // Organisation Details
      businessName: isValidBusinessName(form.businessName),
      businessIdentifier: isValidBusinessIdentifier(
        form.businessIdentifier,
        isoCountryCode,
      ),
      currency: isNotEmpty(form.currency),
      website: isValidDomain(form.website),
      // Contact Details
      contactName: isValidName(form.contactName),
      phone: isValidPhoneNumber(getFormattedPhone()),
      email: isValidEmail(form.email),
      // Location Details
      line1: isNotEmpty(line1),
      line2: isNotEmpty(line2),
      city: isNotEmpty(city),
      state: isNotEmpty(state),
      postalCode: postalCode
        ? isValidPostCode(postalCode, isoCountryCode)
        : false,
      country: isNotEmpty(isoCountryCode),
      timeZone: isNotEmpty(form.timeZone),
    };
  }, [form, getFormattedPhone]);

  const onChangeAddress = useCallback((_: string, address: Address) => {
    let defaultTimezone: Timezone | undefined;
    const { timezone, isoCountryCode = DEFAULT_COUNTRY_CODE } = address || {};
    if (timezone) {
      defaultTimezone = Object.values(TIMEZONES_MAP).find(
        _tz => _tz.name === timezone,
      );
    } else {
      defaultTimezone = Object.values(TIMEZONES_MAP)
        .sort((t1, t2) => t1.utcOffsetStr.localeCompare(t2.utcOffsetStr))
        .find((_tz: Timezone) => {
          return _tz.country === isoCountryCode;
        });
    }

    const selectedCountry = getCountry(isoCountryCode);
    setForm(prev => ({
      ...prev,
      address,
      country: isoCountryCode,
      timeZone: defaultTimezone?.name || '',
      // for US it  has multiple  currency "USD,USN,USS" need  to split and get the first one
      currency: selectedCountry?.currency?.split(',')[0] || '',
    }));
  }, []);

  const onChange = useCallback(
    (
      prop: keyof FormData,
      value: string | ImageUploadInput | Address,
    ): void => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
    },
    [],
  );

  const onPressSave = async (): Promise<void> => {
    if (
      organization &&
      Object.keys(formValidationStatus).every(_key => {
        if (optionalFields.includes(_key) && !form[_key as keyof FormData])
          return true;
        return formValidationStatus[_key] === true;
      })
    ) {
      const {
        line1 = '',
        line2,
        city = '',
        state = '',
        suburb,
        postalCode = '',
        country = '',
        isoCountryCode,
      } = form.address || {};
      const payload: UpdateOrganizationInput = {
        id: organization.id,
        phone: getFormattedPhone(),
        email: form.email,
        name: form.businessName,
        businessIdentifier: form.businessIdentifier,
        website: form.website,
        contactName: form.contactName,
        address: {
          line1,
          line2,
          city,
          state,
          suburb,
          postalCode,
          country,
          isoCountryCode,
        },
        country: form.address?.isoCountryCode,
        currencyCode: form.currency,
        timezone: form.timeZone,
        ...(form.imageRawData && {
          imageRawData: {
            base64: form.imageRawData.base64,
            name: form.imageRawData.name,
            type: form.imageRawData.type,
          },
        }),
      };
      const status = await updateOrganization(payload);
      if (status) {
        showNotification({
          success: true,
          message: translate('generalSettings.organizationUpdateSuccess'),
        });
      }
    } else {
      Object.keys(form).map(_key => {
        if (
          !optionalFields.includes(_key) &&
          formValidationStatus[_key] === false
        ) {
          showNotification({
            error: true,
            message: `Invalid ${capitalCase(_key)}`,
          });
        }
      });
    }
  };

  const hasChanges = useMemo(
    () => !isEqual(form, originalStateRef.current),
    [form],
  );

  return (
    <ScreenLayout
      loading={loading}
      onSave={() => onPressSave()}
      hideFooter={loading || !hasChanges}
      title={translate('navigation.generalSettingsPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section layoutWidth="small">
        <View>
          <View style={styles.imageContainer}>
            <ImagePicker
              imageUrl={form?.logoUrl}
              initials={(form?.businessName || 'Aa').charAt(0)}
              onComplete={imageRawData =>
                onChange('imageRawData', imageRawData)
              }
            />
          </View>
          <Message
            type="neutral"
            message="We recommend an image size of at least 256✗256."
            containerStyle={{
              width: theme.layoutWidth.s,
              marginBottom: 40,
            }}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="organization-name"
            value={form.businessName || ''}
            title={translate('form.organizationName')}
            placeholder={translate('form.organizationName')}
            onChangeText={onChange.bind(null, 'businessName')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.businessName
                ? 'Enter valid business name'
                : undefined
            }
            isVerified={formValidationStatus.businessName}
            containerStyle={
              !!businessIdentifierType
                ? theme.forms.inputHalf
                : theme.forms.inputFluid
            }
          />
          {!!businessIdentifierType ? (
            <InputText
              testID="business-number"
              value={form.businessIdentifier || ''}
              title={translate('form.businessNumber', {
                type: businessIdentifierType,
              })}
              placeholder={translate('form.businessNumber', {
                type: businessIdentifierType,
              })}
              onChangeText={onChange.bind(null, 'businessIdentifier')}
              autoCapitalize="words"
              maxLength={13}
              errorMessage={
                !formValidationStatus.businessIdentifier
                  ? translate('form.invalidInput', {
                      title: translate('form.businessNumber', {
                        type: businessIdentifierType,
                      }),
                    })
                  : undefined
              }
              isVerified={formValidationStatus.businessIdentifier}
              containerStyle={theme.forms.inputHalf}
            />
          ) : null}
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-currency"
            title={translate('form.currency')}
            selectedValue={form.currency}
            options="currencies"
            onValueChange={onChange.bind(null, 'currency')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="website"
            value={form.website || ''}
            title={translate('form.website')}
            placeholder={translate('form.website')}
            onChangeText={onChange.bind(null, 'website')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.website && form.website
                ? 'Enter valid website'
                : undefined
            }
            isVerified={formValidationStatus.website}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title="Contact Details" layoutWidth="small">
        <View style={theme.forms.row}>
          <InputText
            testID="contact-name"
            value={form.contactName || ''}
            title={translate('form.contactName')}
            placeholder={translate('form.contactName')}
            onChangeText={onChange.bind(null, 'contactName')}
            autoCapitalize="words"
            maxLength={50}
            errorMessage={
              !formValidationStatus.contactName ? 'Enter valid name' : undefined
            }
            containerStyle={theme.forms.inputHalf}
          />
          <InputPhone
            testID="phone-number"
            value={form.phone || ''}
            title={translate('form.phoneNumber')}
            onPressCountry={(code: string): void => {
              onChange('phoneCountryCode', code);
            }}
            defaultCountry={form.phoneCountryCode}
            onChangeText={onChange.bind(null, 'phone')}
            containerStyle={theme.forms.inputHalf}
            maxLength={12}
          />
        </View>
        <View style={theme.forms.row}>
          <InputEmail
            testID="email-address"
            title={translate('common.emailAddress')}
            value={form.email || ''}
            placeholder={translate('common.emailPlaceholder')}
            onChangeText={onChange.bind(null, 'email')}
            errorMessage={
              !formValidationStatus.email
                ? 'Enter valid email address'
                : undefined
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      <Section title="Location Details" layoutWidth="small">
        <AddressForm
          onChangeAddress={onChangeAddress}
          values={form.address}
          keyName="address"
        >
          <TreatPicker
            testID="select-timezone"
            title={translate('form.timeZone')}
            selectedValue={form.timeZone}
            options="timezones"
            onValueChange={onChange.bind(null, 'timeZone')}
            containerStyle={theme.forms.inputHalf}
          />
        </AddressForm>
      </Section>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: 540,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
