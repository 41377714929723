import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { Operation } from '../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  PrinterProfile,
  CreatePrinterProfileInput,
  UpdatePrinterProfileInput,
  PrinterProfileType,
} from '@oolio-group/domain';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import {
  CREATE_PRINTER_PROFILES,
  DELETE_PRINTER_PROFILE,
  GET_PRINTER_PROFILES_QUERY,
  UPDATE_PRINTER_PROFILES,
} from '../../graphql/printerProfiles';
import keyBy from 'lodash/keyBy';

export interface UsePrinterProfilesProps {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  printerProfiles: { [key: string]: PrinterProfile };
  getPrinterProfiles: () => void;
  createPrinterProfiles: (
    printerProfilesInput: CreatePrinterProfileInput[],
  ) => void;
  createdPrinterProfileIds: string[];
  updatePrinterProfiles: (
    printerProfilesInput: UpdatePrinterProfileInput[],
  ) => void;
  updatedPrinterProfileIds: string[];
  deletePrinterProfile: (id: string) => void;
  isPrinterProfileDeleted: boolean;
}

export function usePrinterProfiles(
  getKitchenOnlyPrinterProfileTypes = true,
): UsePrinterProfilesProps {
  const [printerProfiles, setPrinterProfiles] = useState<
    Record<string, PrinterProfile>
  >({});

  const [createdPrinterProfileIds, setCreatedPrinterProfileIds] = useState<
    string[]
  >([]);

  const [updatedPrinterProfileIds, setUpdatedPrinterProfileIds] = useState<
    string[]
  >([]);

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [isPrinterProfileDeleted, setIsPrinterProfileDeleted] = useState(false);

  const onCompleteGetPrinterProfilesRequest = useCallback(
    data => {
      if (data) {
        setIsPrinterProfileDeleted(false);
        const printerProfilesData = data.printerProfiles as PrinterProfile[];
        const printerProfilesDict: Record<string, PrinterProfile> = keyBy(
          printerProfilesData,
          'id',
        );
        setPrinterProfiles(printerProfilesDict);
      }
    },
    [setPrinterProfiles],
  );

  const [getPrinterProfiles, getPrinterProfilesResponse] = useLazyQuery(
    GET_PRINTER_PROFILES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: onCompleteGetPrinterProfilesRequest,
      // added this, as we are not showing  BILLING printer profile in backoffice / POS app
      ...(getKitchenOnlyPrinterProfileTypes && {
        variables: {
          filter: {
            printerProfileType: PrinterProfileType.KITCHEN,
          },
        },
      }),
    },
  );

  // create
  const [createPrinterProfilesRequest, createPrinterProfilesResponse] =
    useMutation(CREATE_PRINTER_PROFILES, {
      onError: noopHandler,
    });

  const createPrinterProfiles = useCallback(
    (createPrinterProfilesInput: CreatePrinterProfileInput[]) => {
      createPrinterProfilesRequest({
        variables: {
          input: createPrinterProfilesInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createPrinterProfilesRequest],
  );

  useEffect(() => {
    if (createPrinterProfilesResponse.data) {
      const newlyCreatedPrinterProfiles = createPrinterProfilesResponse.data
        .createPrinterProfiles as PrinterProfile[];

      setPrinterProfiles(printerProfiles => {
        return {
          ...printerProfiles,
          ...keyBy(newlyCreatedPrinterProfiles || [], 'id'),
        };
      });

      setCreatedPrinterProfileIds(
        newlyCreatedPrinterProfiles.map(printerProfile => printerProfile.id),
      );
    }
  }, [createPrinterProfilesResponse.data]);

  // create
  const [updatePrinterProfilesRequest, updatePrinterProfilesResponse] =
    useMutation(UPDATE_PRINTER_PROFILES, {
      onError: noopHandler,
    });

  useEffect(() => {
    if (updatePrinterProfilesResponse.data) {
      const updatedPrinterProfiles = updatePrinterProfilesResponse.data
        .updatePrinterProfiles as PrinterProfile[];

      setPrinterProfiles(printerProfiles => {
        return {
          ...printerProfiles,
          ...keyBy(updatedPrinterProfiles || [], 'id'),
        };
      });
      setUpdatedPrinterProfileIds(
        updatedPrinterProfiles.map(printerProfile => printerProfile.id),
      );
    }
  }, [updatePrinterProfilesResponse.data]);

  const updatePrinterProfiles = useCallback(
    (updatePrinterProfilesInput: UpdatePrinterProfileInput[]) => {
      updatePrinterProfilesRequest({
        variables: {
          input: updatePrinterProfilesInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updatePrinterProfilesRequest],
  );

  // delete

  const [deletePrinterProfileRequest, deletePrinterProfileResponse] =
    useMutation(DELETE_PRINTER_PROFILE, {
      onError: noopHandler,
    });

  const deletePrinterProfile = useCallback(
    (id: string) => {
      deletePrinterProfileRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deletePrinterProfileRequest],
  );

  useEffect(() => {
    if (deletePrinterProfileResponse.data) {
      setIsPrinterProfileDeleted(
        deletePrinterProfileResponse.data.deletePrinterProfile,
      );
    }
  }, [deletePrinterProfileResponse.data]);

  const loading: boolean =
    getPrinterProfilesResponse.loading ||
    updatePrinterProfilesResponse.loading ||
    createPrinterProfilesResponse.loading ||
    deletePrinterProfileResponse.loading;

  const error: ApolloError | undefined =
    getPrinterProfilesResponse.error ||
    updatePrinterProfilesResponse.error ||
    createPrinterProfilesResponse.error ||
    deletePrinterProfileResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      printerProfiles,
      getPrinterProfiles,
      createPrinterProfiles,
      createdPrinterProfileIds,
      updatePrinterProfiles,
      updatedPrinterProfileIds,
      deletePrinterProfile,
      isPrinterProfileDeleted,
    }),
    [
      loading,
      error,
      operation,
      printerProfiles,
      getPrinterProfiles,
      createPrinterProfiles,
      createdPrinterProfileIds,
      updatePrinterProfiles,
      updatedPrinterProfileIds,
      deletePrinterProfile,
      isPrinterProfileDeleted,
    ],
  );
}
