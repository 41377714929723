import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useReactiveVar } from '@apollo/client';
import { FeatureIDs } from '@oolio-group/domain';
import { DeveloperScreen } from './Developer/DeveloperScreen';
import { GeneralSettingsScreen } from './GeneralSettingsScreen';
import { isAdvanceDiscountEnabledVar } from '../../../state/cache';
import { PaymentsTypes } from './PaymentTypes/PaymentsTypes';
import { SalesChannelScreen } from './SalesChannel/SalesChannelScreen';
import { useCheckFeatureEnabled } from '../../../../src/hooks/app/features/useCheckFeatureEnabled';
import { VenueSettingsScreen } from './Venues/VenuesScreen';
import AdjustmentTab from './VenueSettings/Adjustments/AdjustmentTab';
import DeviceProfilesTabs from './StoreSettings/DeviceProfiles/DeviceProfilesTabs';
import DevicesTabs from './StoreSettings/Devices/DevicesTabs';
import KitchenDisplayTabs from './StoreSettings/KitchenDisplay/KitchenDisplayTab';
import MyStoreSettingsTabs from './VenueSettings/MyStore/MyStoreTabs';
import PrinterProfileAndReceiptStack from './Printing/PrinterProfileAndReceiptStack';
import PrinterTabs from './StoreSettings/Printers/PrinterTabs';
import RefundReasons from './RefundReasons/RefundReasons';
import SectionSettingsTabs from './VenueSettings/Sections/Details/SectionSettingsTabs';
import StoreSettingsTabs from './StoreSettings/StoreSettingsTabs';
import TaxesStack from './Taxes/TaxesStack';
import VenueSettingsTabs from './VenueSettings/VenueSettingsTabs';

const Stack = createStackNavigator();

const SettingsStack: React.FC = () => {
  const isAdvanceDiscountEnabled = useReactiveVar(isAdvanceDiscountEnabledVar);
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isMultiVenueEnabled = isFeatureEnabled(FeatureIDs.MULTI_VENUE);
  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName="Organisation"
      screenOptions={{
        animationEnabled: true,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
    >
      <Stack.Screen component={GeneralSettingsScreen} name="Organisation" />
      {isMultiVenueEnabled ? (
        <Stack.Screen component={VenueSettingsScreen} name="VenuesStores" />
      ) : (
        <Stack.Screen component={MyStoreSettingsTabs} name="MyStore" />
      )}
      {isAdvanceDiscountEnabled ? (
        <>
          {['discount', 'surcharge'].map(type => (
            <Stack.Screen component={AdjustmentTab} name={type} key={type} />
          ))}
        </>
      ) : (
        <Stack.Screen component={AdjustmentTab} name="Adjustment" />
      )}
      <Stack.Screen component={TaxesStack} name="TaxesFees" />
      <Stack.Screen component={PaymentsTypes} name="PaymentTypes" />
      <Stack.Screen component={VenueSettingsTabs} name="VenueSettings" />
      <Stack.Screen component={StoreSettingsTabs} name="StoreSettings" />
      <Stack.Screen
        component={DeviceProfilesTabs}
        name="DeviceProfileSettings"
      />
      <Stack.Screen component={DevicesTabs} name="DeviceSettings" />
      <Stack.Screen
        component={KitchenDisplayTabs}
        name="KitchenDisplaySettings"
      />
      <Stack.Screen component={SalesChannelScreen} name="SalesChannels" />
      <Stack.Screen component={PrinterTabs} name="PrinterDetails" />
      <Stack.Screen component={PrinterProfileAndReceiptStack} name="Printing" />
      <Stack.Screen component={RefundReasons} name="RefundReasons" />
      <Stack.Screen component={DeveloperScreen} name="Developer" />
      <Stack.Screen component={SectionSettingsTabs} name="SectionSettings" />
    </Stack.Navigator>
  );
};

export default SettingsStack;
