import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  btnAddRoutine: {
    width: 40,
    height: 40,
    marginLeft: 'auto',
    borderRadius: theme.radius.m,
  },
});

export default styles;
