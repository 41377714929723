import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import Icon from '../Icon/Icon';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import theme from '../../common/default-theme';
import styles from './TabBar.styles';

interface PreviousRouteProps {
  screen?: string;
  params?: Object;
}
export interface TabBarProps {
  tabBar: MaterialTopTabBarProps;
  showBack?: boolean;
  previousRoute?: string;
  previousScreen?: string | PreviousRouteProps;
}

const TabBar: React.FC<TabBarProps> = ({
  tabBar,
  showBack,
  previousRoute,
  previousScreen,
}: TabBarProps) => {
  return (
    <View style={styles.container}>
      {showBack && (
        <TouchableOpacity
          testID="backButton"
          onPress={
            previousRoute
              ? () => {
                  tabBar.navigation.navigate(
                    previousRoute,
                    previousScreen
                      ? typeof previousScreen === 'string'
                        ? { screen: previousScreen }
                        : previousScreen
                      : {},
                  );
                }
              : tabBar.navigation.goBack
          }
          style={styles.backBtnContainer}
        >
          <Icon name="arrow-left" color={theme.colors.black} />
        </TouchableOpacity>
      )}
      <View style={styles.menuContainer}>
        {tabBar.state.routes.map(
          (route: { name: string; key: string }, index: number) => {
            const isFocused = tabBar.state.index === index;
            const { options } = tabBar.descriptors[route.key] as {
              options: { tabBarLabel?: string };
            };
            const label: string = (options.tabBarLabel as string) || route.name;
            return (
              <TouchableOpacity
                key={index}
                testID={`tab-${index}`}
                onPress={(): void => {
                  !isFocused && tabBar.navigation.navigate(route.name);
                }}
              >
                <Text
                  style={[
                    styles.menuTabText,
                    isFocused && styles.menuTabActive,
                  ]}
                >
                  {label}
                </Text>
              </TouchableOpacity>
            );
          },
        )}
      </View>
      {showBack && <View style={styles.spacer} />}
    </View>
  );
};

TabBar.defaultProps = {
  showBack: true,
};
export default TabBar;
