/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Text, View, ViewStyle, TextInput, TextInputProps } from 'react-native';
import theme from '../../../common/default-theme';
import styles from './Inputs.styles';
import TreatPicker, { PickerOption } from '../Select/Picker';

export interface InputDropdownProps extends TextInputProps {
  title?: string;
  testID: string;
  value?: string | undefined;
  placeholder: string;
  errorMessage?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  inputWrapperStyle?: ViewStyle | ViewStyle[];
  direction?: 'ltr' | 'rtl';
  alignText?: 'left' | 'center' | 'right';
  options: PickerOption[];
  selectedOption?: string;
  extraWidth?: number;
  onOptionChange: (itemValue: string, itemPosition: number) => void;
}

const InputDropdown: React.FC<InputDropdownProps> = ({
  title,
  value,
  testID,
  options,
  selectedOption,
  onOptionChange,
  placeholder,
  errorMessage,
  containerStyle,
  inputWrapperStyle,
  direction = 'ltr',
  alignText = 'left',
  extraWidth,
  ...props
}: InputDropdownProps) => {
  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            inputWrapperStyle,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
            {
              flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
            },
          ]}
        >
          {options && (
            <TreatPicker
              testID={`select-${testID}`}
              options={options}
              onValueChange={onOptionChange}
              selectedValue={selectedOption}
              secondary
              extraWidth={extraWidth}
            />
          )}
          <TextInput
            testID={testID}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={theme.colors.grey5}
            style={[
              styles.inputContainer,
              { textAlign: alignText, borderRadius: 0 },
            ]}
            autoCorrect={false}
            textAlign={alignText}
            {...props}
          />
        </View>
      </View>
      {!!errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
    </View>
  );
};

export default InputDropdown;

InputDropdown.defaultProps = {
  editable: true,
};
