import React, { useEffect, useState, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { LOCALE, AlternateName } from '@oolio-group/domain';
import TreatModal from '../../Shared/Modals/Modal/Modal';
import InputText from '../../Shared/Inputs/InputText';
import { keyBy } from 'lodash';
import defaultTheme from '../../../common/default-theme';

interface Props {
  title: string;
  value?: AlternateName[];
  loading: boolean;
  onConfirm: (value: AlternateName[]) => void;
  onDismiss: () => void;
}

export type Language = {
  name: string;
  locale: LOCALE;
};

export const LANGUAGES: Language[] = [
  { name: '中文', locale: LOCALE.CHINESE },
  { name: 'Español', locale: LOCALE.SPANISH_SPAIN },
  { name: 'Deutsch', locale: LOCALE.DUTCH },
  { name: 'हिंदी', locale: LOCALE.HINDI },
];

const TranslationModal: React.FC<Props> = ({
  title,
  value: alternateNamesProp,
  loading,
  onConfirm,
  onDismiss,
}) => {
  const [alternateNameMaps, setAlternateNameMaps] = useState<
    Record<string, AlternateName>
  >({});

  const onPressUpdate = useCallback(() => {
    onConfirm(Object.values(alternateNameMaps));
  }, [alternateNameMaps, onConfirm]);

  const onChangeLocaleText = useCallback((value: string, locale: LOCALE) => {
    setAlternateNameMaps(pre => ({
      ...pre,
      [locale]: { ...pre[locale], display: value, locale },
    }));
  }, []);

  useEffect(() => {
    if (alternateNamesProp !== undefined)
      setAlternateNameMaps(keyBy(alternateNamesProp, 'locale'));
  }, [alternateNamesProp]);

  return (
    <TreatModal
      size="small"
      type="positive"
      loading={loading}
      title={title}
      onConfirm={{ action: onPressUpdate }}
      onDismiss={{ action: onDismiss }}
    >
      {LANGUAGES.map(lang => {
        return (
          <View key={lang.locale}>
            <InputText
              title={lang.name}
              testID="location-name"
              placeholder="Add Translation..."
              onChangeText={value => onChangeLocaleText(value, lang.locale)}
              containerStyle={styles.inputContainerStyle}
            />
          </View>
        );
      })}
    </TreatModal>
  );
};

const styles = StyleSheet.create({
  inputContainerStyle: {
    marginTop: defaultTheme.spacing.s,
  },
});

export default TranslationModal;
