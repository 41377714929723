import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    backgroundColor: theme.colors.blue,
  },
  text: {
    fontSize: 14,
    lineHeight: 16,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
