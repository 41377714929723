import { StyleFn } from '@oolio-group/domain';
import { isAndroid, isWeb } from '../../../common/theme';

export const headerStyle: StyleFn = () => ({
  width: 580,
  height: 44,
  alignSelf: 'center',
  marginHorizontal: 8,
});

export const cancelButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 10,
});

export const modalContainer: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const modalStyle: StyleFn = ({ theme }) => ({
  width: 580,
  height: isAndroid ? 480 : 580,
  borderRadius: 6,
  justifyContent: 'flex-start',

  backgroundColor: theme.colors.white,
  padding: theme.spacing.small,
});

export const searchView: StyleFn = () => ({
  height: 58,
});

export const scrollBodyStyle: StyleFn = ({}) => ({
  maxHeight: '85%',
  flex: 1,
});

export const infoMessageStyle: StyleFn = () => ({
  marginTop: 2,
  marginBottom: 2,
});

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  paddingVertical: theme.padding.medium + 5,
});

export const headerCellTextAlignment: StyleFn = () => ({
  textAlign: 'left',
});

export const rowCellTextAlignment: StyleFn = () => ({
  justifyContent: 'flex-start',
});

export const dataRowStyle: StyleFn = ({ theme }) => ({
  padding: isWeb ? 0 : theme.padding.small,
  paddingHorizontal: theme.padding.large - 4,
  top: isAndroid ? -5 : 0,
});

export const tableHeaderTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  lineHeight: 32,
});

//Table row

export const tableRowCells: StyleFn = () => ({
  flex: 1,
});

export const toggleCell: StyleFn = () => ({
  width: 100,
  alignItems: 'center',
});

export const checkBoxCell: StyleFn = () => ({
  width: 100,
  alignItems: 'center',
});

export const quantityCell: StyleFn = () => ({
  width: 100,
  alignItems: 'center',
});

export const tableRowCellsText: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 20,
  flexWrap: 'wrap',
});

export const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 18,
  width: 18,
});

export const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 18,
  width: 18,
});

export const checkIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

export const textInput: StyleFn = () => ({
  width: 60,
  marginHorizontal: 20,
});
export const textInputText: StyleFn = () => ({
  width: 50,
  height: 28,
  paddingLeft: 0,
  textAlign: 'center',
});

export const toggleContainerStyle: StyleFn = () => ({
  width: 100,
  alignItems: 'center',
});

export const rowStyle: StyleFn = ({ theme }) => ({
  minHeight: 55,
  flexDirection: 'row',
  alignItems: 'center',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});
