import { BehaviorSubject, Observable } from 'rxjs';
import { Catalogue } from '@oolio-group/domain';

export enum CatalogueAction {
  FETCH_CATALOGUE = 'FETCH_CATALOGUE',
  REFETCH_CATALOGUE = 'REFETCH_CATALOGUE',
  NEW_CATALOGUE_VERSION = 'NEW_CATALOGUE_VERSION',
  IGNORE_CATALOGUE_VERSION = 'NEW_CATALOGUE_VERSION',
}

export interface CatalogueData {
  data?: Catalogue;
  isFetching?: boolean;
  error?: string;
  action?: CatalogueAction;
  ignoredNewCatalogueVersion?: boolean;
  hasCatalogueUpdates?: boolean;
  forceUpdate?: boolean;
}

export class CatalogueUtility {
  private subject: BehaviorSubject<CatalogueData>;
  constructor() {
    this.subject = new BehaviorSubject<CatalogueData>({});
  }

  getValue() {
    return this.subject.value;
  }

  fetchCatalogue() {
    this.subject.next({
      ...this.getValue(),
      action: CatalogueAction.FETCH_CATALOGUE,
    });
  }

  refetchCatalogue(input?: { forceUpdate?: boolean }) {
    this.subject.next({
      ...this.getValue(),
      action: CatalogueAction.REFETCH_CATALOGUE,
      forceUpdate: input?.forceUpdate,
    });
  }

  onStartFetchingCatalogue() {
    this.subject.next({
      ...this.getValue(),
      isFetching: true,
      hasCatalogueUpdates: false,
    });
  }

  onFetchSuccess(data?: Catalogue) {
    this.subject.next({
      ...this.getValue(),
      isFetching: false,
      action: undefined,
      data,
      ignoredNewCatalogueVersion: false,
      forceUpdate: false,
    });
  }
  onFetchError(error: string, data?: Catalogue) {
    this.subject.next({
      ...this.getValue(),
      isFetching: false,
      action: undefined,
      forceUpdate: false,
      error,
      ...(data && { data }),
    });
  }

  onNewCatalogueVersion() {
    // if previous selection was "Not now" , we will not show the  banner again
    const ignoredNewVersionBefore = this.getValue().ignoredNewCatalogueVersion;
    this.subject.next({
      ...this.getValue(),
      hasCatalogueUpdates: !ignoredNewVersionBefore,
    });
  }

  onIgnoreUpdates() {
    this.subject.next({
      ...this.getValue(),
      ignoredNewCatalogueVersion: true,
      hasCatalogueUpdates: false,
    });
  }

  onAcceptUpdates() {
    this.subject.next({
      ...this.getValue(),
      ignoredNewCatalogueVersion: false,
      hasCatalogueUpdates: false,
    });
    this.refetchCatalogue();
  }

  get getSubscriptionState$(): Observable<CatalogueData> {
    return this.subject.asObservable();
  }
}

export const catalogueUtility = new CatalogueUtility();
