import React, { useMemo, useCallback } from 'react';
import { Text, ViewStyle, FlexAlignType, View } from 'react-native';
import { StyleFn, RenderProps } from '@oolio-group/domain';
import { FelaComponent, useFela } from 'react-fela';
import { withTheme } from 'react-fela';
import TableRow from '../TableComponent/TableRow';
import DraggableFlatList from 'react-native-draggable-flatlist';

export interface Column {
  title: string | React.ReactNode;
  width?: number;
  flex?: number;
  alignItems?: FlexAlignType;
  containerStyle?: ViewStyle;
}
export declare type RenderItemProps = {
  item: React.ReactNode;
  index?: number | undefined;
  drag: () => void;
  isActive: boolean;
};

export interface DraggableFlatListComponentProps {
  columns: Column[];
  renderRow: (
    item: React.ReactNode,
    index: number | undefined,
    drag: () => void,
    isActive?: boolean,
  ) => React.ReactNode;
  data: React.ReactNode[];
  columnContainerStyle?: ViewStyle;
  normalRows?: boolean;
  columnSpacing?: number;
  containerStyle?: ViewStyle;
  onDragEnd: (data: Array<unknown>) => void;
}

export interface ColumnCellProps {
  title: string | React.ReactNode;
  width: number;
  flex?: number;
  alignItems?: FlexAlignType;
  columnSpacing?: number;
  index: number;
  containerStyle?: ViewStyle;
}

const TableNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: 14,
  letterSpacing: 0,
  fontFamily: theme.font.medium,
});

const titleStyle: StyleFn = ({
  width,
  flex,
  index,
  alignItems,
  columnSpacing,
}) => ({
  width: width,
  flex: flex,
  alignItems: alignItems ? alignItems : index ? 'center' : 'flex-start',
  justifyContent: index ? 'center' : 'flex-start',
  marginRight: columnSpacing || 0,
});

export const ColumnCell: React.FC<ColumnCellProps> = props => {
  const { css } = useFela();
  return (
    <FelaComponent style={titleStyle} {...props}>
      {({ style }: RenderProps): React.ReactNode => (
        <View style={[style, props.containerStyle]}>
          <Text style={css(TableNameStyle)}>{props.title}</Text>
        </View>
      )}
    </FelaComponent>
  );
};

const DraggableFlatListComponent: React.FC<DraggableFlatListComponentProps> = ({
  columns,
  renderRow,
  data,
  columnContainerStyle,
  normalRows,
  columnSpacing,
  containerStyle,
  onDragEnd,
}: DraggableFlatListComponentProps) => {
  const dragEnd = useCallback(
    (data: Array<unknown>) => {
      onDragEnd(data);
    },
    [onDragEnd],
  );

  const columnCells = useMemo(
    () =>
      columns.map((x, i) => (
        <ColumnCell
          key={i}
          index={i}
          width={x.width || 80}
          title={x.title}
          flex={x.flex}
          alignItems={x.alignItems}
          // column spacing for right most element making 0
          columnSpacing={columns.length > i + 1 ? columnSpacing : 0}
          containerStyle={x.containerStyle}
        />
      )),
    [columns, columnSpacing],
  );

  const renderItem = ({ item, index, drag, isActive }: RenderItemProps) => {
    return renderRow(item, index, drag, isActive);
  };

  return (
    <View style={containerStyle}>
      {columnCells.length > 0 && (
        <TableRow containerStyle={columnContainerStyle} normalRows={normalRows}>
          {columnCells}
        </TableRow>
      )}
      <DraggableFlatList
        testID="draggable-flat-list"
        data={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
        onDragEnd={({ data }) => dragEnd(data)}
      />
    </View>
  );
};

export default withTheme(DraggableFlatListComponent);
