import React, { useCallback, useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';

interface AddStoresProps {
  storeOptions: { label: string; value: string }[];
  onSubmit: (selectedStores: string[]) => void;
}

export const AddStores: React.FC<AddStoresProps> = ({
  onSubmit,
  storeOptions,
}: AddStoresProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [selected, setSelected] = useState<string[]>([]);

  const onChangeStore = useCallback((values: string[]) => {
    setSelected(values);
  }, []);

  return (
    <TreatModal
      title={translate('productBulkOperations.addToStore')}
      onConfirm={{
        label: translate('productBulkOperations.addToStore'),
        action: () => onSubmit(selected),
      }}
      onDismiss={{ action: closeModal }}
    >
      <SelectMultiple
        testID="select-stores"
        title={translate('productBulkOperations.selectStores')}
        options={storeOptions}
        selectedValues={selected}
        onValueChange={onChangeStore}
      />
    </TreatModal>
  );
};
