import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    marginTop: 30,
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  seats: {
    marginTop: 20,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  seat: {
    width: 100,
    marginRight: 8,
    marginBottom: 5,
  },
  emptyTile: {
    height: 90,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  status: {
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: theme.colors.green,
    position: 'absolute',
    top: 8,
    right: 14,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

export default styles;
