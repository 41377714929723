import { Address } from '@oolio-group/domain';
import { getAddressFields, AddressFields } from '@oolio-group/localization';

interface Segment {
  prefix: string;
  key:
    | 'line1'
    | 'line2'
    | 'suburb'
    | 'city'
    | 'state'
    | 'postalCode'
    | 'country';
  suffix: string;
}

/**
 * The segments are the sub strings of address,
 * used to construct a specific formatted address in te application
 *
 */
const SEGMENTS: Array<{
  prefix: string;
  key:
    | 'line1'
    | 'line2'
    | 'suburb'
    | 'city'
    | 'state'
    | 'postalCode'
    | 'country';
  suffix: string;
}> = [
  { prefix: '', key: 'line1', suffix: '' },
  { prefix: ' ', key: 'line2', suffix: '' },
  { prefix: ', ', key: 'suburb', suffix: '' },
  { prefix: ', ', key: 'city', suffix: '' },
  { prefix: ', ', key: 'state', suffix: '' },
  { prefix: ' ', key: 'postalCode', suffix: '' },
  { prefix: ',', key: 'country', suffix: '' },
];

export const ROW_ONE_SEGMENTS: Array<Segment> = [
  { prefix: '   ', key: 'line1', suffix: ',' },
];

export const ROW_TWO_SEGMENTS: Array<Segment> = [
  { prefix: '', key: 'line2', suffix: ', ' },
  { prefix: '', key: 'suburb', suffix: ', ' },
  { prefix: '', key: 'city', suffix: ', ' },
  { prefix: '', key: 'state', suffix: '' },
  { prefix: ' ', key: 'postalCode', suffix: '' },
];

export const COMBINED_SEGMENT: Array<Segment> = [
  { prefix: '', key: 'line1', suffix: ', ' },
  { prefix: '', key: 'line2', suffix: ', ' },
  { prefix: '', key: 'suburb', suffix: ', ' },
  { prefix: '', key: 'city', suffix: ', ' },
  { prefix: '', key: 'state', suffix: '' },
  { prefix: ' ', key: 'postalCode', suffix: '' },
];

/**
 * Returns a string version of given address object
 * @param address
 */
export const getFormattedAddress = (
  address: Address,
  segments: Segment[] = SEGMENTS,
  country?: string,
): string => {
  const countryCode = address?.isoCountryCode || country || 'AU';
  const addressFields = getAddressFields(countryCode);
  return segments
    .filter(item => addressFields.includes(item.key as AddressFields))
    .reduce((formattedAddress, segment) => {
      if (
        address &&
        address[segment.key] &&
        (address[segment.key] || '').length > 0
      ) {
        return `${formattedAddress}${segment.prefix}${address[segment.key]}${
          segment.suffix
        }`;
      }
      return formattedAddress;
    }, '');
};
