import React from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { Text, View } from 'react-native';
import { DiscountRule } from '../DiscountRule';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import styles from './DiscountRuleModal.styles';
import { DiscountRuleType } from '@oolio-group/domain';

interface DiscountRuleModalProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeFormInput: (key: string, value: any) => void;
  ruleType?: DiscountRuleType;
}

export const DiscountRuleModal: React.FC<DiscountRuleModalProp> = ({
  onChangeFormInput,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();

  const onSelectDiscountType = (ruleType: DiscountRuleType) => {
    onChangeFormInput('discountRuleType', ruleType);
    closeModal();
  };

  return (
    <View style={styles.container}>
      <Text style={styles.ruleText}>
        {translate('backofficeVenueSettingAdjustments.selectRuleType')}
      </Text>
      {Object.values(DiscountRuleType).map((ruleType, index) => (
        <DiscountRule
          key={index}
          ruleType={ruleType}
          iconType="angle-right"
          discountRuleStyle={styles.discountRuleSection}
          onClick={() => onSelectDiscountType(ruleType)}
        />
      ))}
      <View style={styles.buttonAction}>
        <TreatButton
          testID="dismiss-discount-type"
          label={translate('button.cancel')}
          type="cancel"
          onPress={closeModal}
        />
      </View>
    </View>
  );
};
