import React, {
  FC,
  useRef,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  View,
  Text,
  ViewStyle,
  ScrollView,
  SectionList,
  TouchableOpacity,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import Icon from '../../Icon/Icon';
import styles from './Select.styles';
import theme from '../../../common/default-theme';

export interface SelectLocationProps {
  testID?: string;
  title?: string;
  selectedValue?: string;
  placeholder?: string;
  options: SelectLocationOptionsProps[];
  containerStyle?: ViewStyle;
  disabled?: boolean;
  sectionHeaderLabelSuffix?: string;
  onValueChange?: (value: OptionsSectionProps[]) => void;
}

export interface OptionsSectionProps {
  id: string;
  label: string;
  checked: boolean;
  data: OptionProps[];
}

interface OptionsGroupItemProps extends Pick<OptionProps, 'id' | 'label'> {
  checked?: boolean;
}

export interface SelectLocationOptionsProps
  extends Pick<OptionsSectionProps, 'id' | 'label'> {
  checked?: boolean;
  data: OptionsGroupItemProps[];
}

interface OptionProps {
  id: string;
  label: string;
  checked: boolean;
}

const OptionsGroupHeader: FC<{
  section: OptionsSectionProps;
  onSelectSection: (props: OptionsSectionProps, checked: boolean) => void;
  sectionHeaderLabelSuffix?: string;
}> = ({ section, onSelectSection, sectionHeaderLabelSuffix }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(section.checked);
  }, [section.checked]);

  const onPressOption = useCallback(() => {
    setChecked(prev => !prev);
    onSelectSection(section, !checked);
  }, [onSelectSection, section, checked]);

  return (
    <TouchableOpacity
      key={section.id}
      style={styles.option}
      onPress={onPressOption}
    >
      <Icon
        name={checked ? 'check-square' : 'square-full'}
        color={checked ? theme.colors.states.positive : theme.colors.grey4}
      />
      <Text style={styles.optionText}>
        {section.label} {sectionHeaderLabelSuffix}
      </Text>
    </TouchableOpacity>
  );
};

const Item: FC<{
  item: OptionProps;
  section: OptionsSectionProps;
  onSelectItem: (sectionId: string, itemId: string, checked: boolean) => void;
}> = ({ item, section, onSelectItem }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(item.checked);
  }, [item.checked]);

  const onPressItem = useCallback(() => {
    setChecked(prev => !prev);
    onSelectItem(section?.id, item.id, !checked);
  }, [onSelectItem, section?.id, item.id, checked]);

  return (
    <TouchableOpacity style={styles.option} key={item.id} onPress={onPressItem}>
      <Icon
        name={checked ? 'check-square' : 'square-full'}
        color={checked ? theme.colors.states.positive : theme.colors.grey4}
      />
      <Text style={styles.optionText}>{item.label}</Text>
    </TouchableOpacity>
  );
};

const SectionSeparatorComponent: FC<{
  trailingSection: OptionsSectionProps;
  trailingItem: OptionsGroupItemProps;
}> = ({ trailingSection, trailingItem }) => {
  if (trailingSection && !trailingItem) {
    return <View style={styles.seperator} />;
  }
  return null;
};

const SelectLocation: React.FC<SelectLocationProps> = ({
  testID,
  title,
  options,
  onValueChange,
  containerStyle,
  placeholder,
  disabled: isDisabled,
  sectionHeaderLabelSuffix,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const onToggleOptions = useCallback((): void => {
    if (!isDisabled) {
      setShowOptions(current => !current);
    }
  }, [isDisabled]);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const [optionsList, setOptionsList] = useState<OptionsSectionProps[]>([]);

  useEffect(() => {
    setOptionsList(
      options.map(section => ({
        ...section,
        checked: section.checked || false,
        data: section.data.map(item => ({
          ...item,
          checked: item.checked || false,
        })),
      })),
    );
  }, [options]);

  const onSelectOption = useCallback(
    (sectionId: string, itemId: string, checked: boolean) => {
      const currentSection = optionsList.find(
        eachSection => eachSection.id === sectionId,
      ) as OptionsSectionProps;
      const updatedSectionItems = currentSection?.data.map(item => {
        if (item.id === itemId) {
          return { ...item, checked };
        }
        return item;
      });
      const updatedCurrentSection = optionsList.map(eachSection => {
        if (eachSection.id === sectionId) {
          return {
            ...eachSection,
            checked: updatedSectionItems?.every(item => item.checked),
            data: updatedSectionItems,
          };
        }
        return eachSection;
      });

      setOptionsList(updatedCurrentSection);
      if (onValueChange && updatedCurrentSection) {
        onValueChange(updatedCurrentSection);
      }
    },
    [optionsList, onValueChange],
  );

  const onSelectSection = useCallback(
    (section: OptionsSectionProps, checked: boolean) => {
      const currentSection = optionsList.find(
        eachSection => eachSection.id === section.id,
      );
      const updatedCurrentSection = {
        ...currentSection,
        checked: checked,
        data: currentSection?.data.map(item => ({
          ...item,
          checked: checked,
        })),
      };

      const updatedOptions = optionsList.map(eachSection => {
        if (eachSection.id === section.id) {
          return updatedCurrentSection as OptionsSectionProps;
        }
        return eachSection;
      });
      setOptionsList(updatedOptions);
      if (onValueChange && updatedOptions) {
        onValueChange(updatedOptions);
      }
    },
    [optionsList, onValueChange],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <OptionsGroupHeader
        section={section}
        onSelectSection={onSelectSection}
        sectionHeaderLabelSuffix={sectionHeaderLabelSuffix}
      />
    ),
    [onSelectSection, sectionHeaderLabelSuffix],
  );

  const renderOption = useCallback(
    ({ item, section }) => {
      return (
        <Item section={section} item={item} onSelectItem={onSelectOption} />
      );
    },
    [onSelectOption],
  );

  const renderSelectedOptions = useCallback(() => {
    const selectedItems = optionsList.reduce((acc, section) => {
      const checkedItems = section.data
        .filter(item => {
          if (item.checked) {
            return item.label;
          }
          return false;
        })
        .map(item => item.label);

      if (section.checked) {
        checkedItems.push(section.label);
      }

      return [...acc, ...checkedItems];
    }, [] as string[]);

    if (selectedItems?.length) {
      const remainingItemsCount =
        selectedItems.length - selectedItems.slice(0, 1).length;

      const selectedItemsView = selectedItems
        .slice(0, 1)
        .map((checkedItem, i) => {
          return (
            <View key={i} style={styles.valueContainer}>
              <Text style={styles.value} numberOfLines={1}>
                {checkedItem}
              </Text>
            </View>
          );
        });

      return (
        <>
          {selectedItemsView}
          {remainingItemsCount ? (
            <View style={styles.valueContainer}>
              <Text style={styles.value}>{`+${remainingItemsCount}`}</Text>
            </View>
          ) : (
            <></>
          )}
        </>
      );
    }
    return <Text style={styles.placeholder}>{placeholder || 'Select...'}</Text>;
  }, [optionsList, placeholder]);

  const touchable = useRef(null);

  return (
    <View style={containerStyle} testID={testID}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <TouchableOpacity
          ref={touchable}
          testID={testID}
          onPress={onToggleOptions}
          disabled={isDisabled}
          style={[
            styles.inputContainer,
            showOptions
              ? theme.containers.active
              : isDisabled
              ? theme.containers.disabled
              : theme.containers.enabled,
          ]}
        >
          <View style={styles.values}>{renderSelectedOptions()}</View>
          {!isDisabled && (
            <View style={styles.accessory}>
              <Icon
                size={20}
                color={theme.colors.dark}
                name={showOptions ? 'angle-up' : 'angle-down'}
              />
            </View>
          )}
        </TouchableOpacity>
      </View>
      <Popover
        isVisible={showOptions}
        placement={PopoverPlacement.AUTO}
        onRequestClose={onRequestClosePopover}
        from={touchable}
        backgroundStyle={styles.bgStyle}
        popoverStyle={styles.optionsContainer}
      >
        <View style={styles.optionsPopup}>
          <ScrollView>
            <SectionList
              sections={optionsList}
              renderItem={renderOption}
              renderSectionHeader={renderSectionHeader}
              SectionSeparatorComponent={SectionSeparatorComponent}
            />
          </ScrollView>
        </View>
      </Popover>
    </View>
  );
};

export default SelectLocation;
