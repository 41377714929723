import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import {
  Adjustment,
  OrderPayment,
  OrderPaymentStatus,
  OrderTax,
} from '@oolio-group/domain';
import {
  getAdjustmentLabel,
  getAdjustmentValue,
  sumDecimals,
} from '@oolio-group/order-helper';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './OrderPayments.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import ModalTitle from '../ModalTitle/ModalTitle';

interface OrderPaymentsModalProps {
  orderNo: string;
  subtotal: number;
  rewardsDiscount?: number;
  discounts: Adjustment[];
  surcharges: Adjustment[];
  paymentSurcharge: number;
  tip?: number;
  deliveryFee?: number;
  serviceCharge?: number;
  taxes: OrderTax[];
  total: number;
  payments: OrderPayment[];
  amountDue?: number;
  onPress?: (payment: OrderPayment) => void;
}

interface OrderPaymentRowProps {
  testID: string;
  title: string;
  value: string;
  isFailedPayment?: boolean;
  onPress?: () => void;
}

const OrderPaymentRow: React.FC<OrderPaymentRowProps> = ({
  title,
  value,
  testID,
  isFailedPayment = false,
  onPress,
}) => {
  return (
    <View style={styles.row} testID={testID}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <Text
          numberOfLines={1}
          style={[
            styles.typeText,
            isFailedPayment && { color: theme.colors.red },
          ]}
        >
          {title}
        </Text>
        {isFailedPayment && (
          <TouchableOpacity style={styles.failedPaymentIcon} onPress={onPress}>
            <Icon name="question-circle" size={14} color={theme.colors.blue} />
          </TouchableOpacity>
        )}
      </View>
      <Text style={styles.amountText}>{value}</Text>
    </View>
  );
};

const OrderPaymentsModal: React.FC<OrderPaymentsModalProps> = ({
  orderNo,
  subtotal,
  rewardsDiscount,
  discounts,
  surcharges,
  paymentSurcharge,
  tip,
  deliveryFee,
  serviceCharge,
  taxes,
  total,
  payments,
  amountDue,
  onPress,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const onPressClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const totalPaid = useMemo(() => {
    return sumDecimals(payments.map(payment => payment.amount));
  }, [payments]);

  return (
    <View style={styles.container}>
      <ModalTitle title={orderNo} onDismiss={onPressClose} />
      <ScrollView style={styles.content}>
        <View style={styles.group}>
          <OrderPaymentRow
            testID="subtotal"
            title={translate('payment.subTotal')}
            value={formatCurrency(subtotal)}
          />
          {rewardsDiscount ? (
            <OrderPaymentRow
              testID="payment-rewardsDiscount"
              title={translate('customerLoyalty.rewardSummary')}
              value={formatCurrency(0 - rewardsDiscount)}
            />
          ) : null}
          {discounts.map((discount, i) => (
            <OrderPaymentRow
              key={`discount-${i}`}
              testID={`discount-${i}`}
              title={getAdjustmentLabel(discount)}
              value={formatCurrency(getAdjustmentValue(subtotal, [discount]))}
            />
          ))}
          {surcharges.map((surcharge, i) => (
            <OrderPaymentRow
              key={`surcharge-${i}`}
              testID={`surcharge-${i}`}
              title={getAdjustmentLabel(surcharge)}
              value={formatCurrency(getAdjustmentValue(subtotal, [surcharge]))}
            />
          ))}
          {paymentSurcharge > 0 ? (
            <OrderPaymentRow
              testID="payment-surcharge"
              title={translate('shift.totalPaymentSurcharge')}
              value={formatCurrency(paymentSurcharge)}
            />
          ) : null}
          {tip ? (
            <OrderPaymentRow
              testID="payment-tip"
              title={translate('onlineOrders.tip')}
              value={formatCurrency(tip)}
            />
          ) : null}
          {deliveryFee ? (
            <OrderPaymentRow
              testID="payment-deliveryFee"
              title={translate('onlineOrders.deliveryFee')}
              value={formatCurrency(deliveryFee)}
            />
          ) : null}
          {serviceCharge ? (
            <OrderPaymentRow
              testID="payment-serviceCharge"
              title={translate('onlineOrders.serviceCharge')}
              value={formatCurrency(serviceCharge)}
            />
          ) : null}
          {taxes.map(({ tax, amount }, i) => (
            <OrderPaymentRow
              key={`tax-${i}`}
              testID={`tax-${i}`}
              title={tax.name}
              value={formatCurrency(amount)}
            />
          ))}
          <View testID="orderTotal" style={styles.totalRow}>
            <Text style={styles.totalText}>
              {translate('payment.orderTotal')}
            </Text>
            <Text style={styles.totalText}>{formatCurrency(total)}</Text>
          </View>
        </View>
        {payments.length > 0 ? (
          <View style={styles.group}>
            {payments.map((payment, i) => {
              const isFailedPayment =
                payment.status === OrderPaymentStatus.REFUND_FAILED;
              return (
                <OrderPaymentRow
                  key={`payment-${i}`}
                  testID={`payment-${i}`}
                  title={
                    isFailedPayment
                      ? translate('refundOrder.failedPaymentType', {
                          paymentType: payment.paymentType?.name,
                        })
                      : payment.paymentType.name
                  }
                  value={formatCurrency(payment.amount)}
                  isFailedPayment={isFailedPayment}
                  onPress={() => onPress && onPress(payment)}
                />
              );
            })}
            <View testID="totalPaid" style={styles.totalRow}>
              <Text style={styles.totalText}>
                {translate('refundOrder.totalPaid')}
              </Text>
              <Text style={styles.totalText}>{formatCurrency(totalPaid)}</Text>
            </View>
          </View>
        ) : null}
        {amountDue ? (
          // eslint-disable-next-line react-native/no-inline-styles
          <View testID="amountDue" style={[styles.totalRow, { marginTop: 0 }]}>
            <Text style={styles.totalText}>
              {translate('payment.totalDue')}
            </Text>
            <Text style={styles.totalText}>{formatCurrency(amountDue)}</Text>
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};

export default OrderPaymentsModal;
