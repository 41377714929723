import React from 'react';
import { View } from 'react-native';
import { PanViewProps } from './PanView';
import styles from '../../FloorView.styles';
import './PanView.css';

const PanView: React.FC<PanViewProps> = ({ children }) => {
  return (
    <View style={styles.layoutContainer}>
      <View style={styles.layout}>
        <div className="pattern-container">{children}</div>
      </View>
    </View>
  );
};

export default PanView;
