import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useUserRoles } from '../../../../../../hooks/app/users/useUserRoles';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { useVenues } from '../../../../../../hooks/app/useVenues';
import { Subscription } from 'rxjs';
import { useNotification } from '../../../../../../hooks/Notification';
import {
  mapApiPayloadToCreateUserRole,
  StoreUserRoleMappingByRole,
  userRoleInputUtility,
  mapUserRolesByRoleIdAndStoreIds,
} from './userRoleInputUtility';
import UserPermissionRow from './Components/UserPermissionRow';
import NewUserPermissionRow from './Components/NewUserPermissionRow';
import useRolesContext from '../../../../../../hooks/app/users/useRolesContext';
import theme from '../../../../../../common/default-theme';
import styles from './UserPermissions.styles';
import Section from '../../../../../../components/Office/Section/Section';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

type ManageUserRolesParamList = {
  Venues: { userId: string };
};

export type ManageUserRolesRouteProp = RouteProp<
  ManageUserRolesParamList,
  'Venues'
>;

export const UserPermissions: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { rolesById } = useRolesContext();
  const { params } = useRoute<ManageUserRolesRouteProp>();
  const isFocused = useIsFocused();
  const {
    venues,
    loading: venuesLoading,
    error: venuesError,
    getVenues,
  } = useVenues();

  const onCreateOrUpdateComplete = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backOfficeUsers.userRolesCreatedUpdatedSuccessfully'),
    });
  }, [showNotification, translate]);

  const {
    userRoles: userRolesById,
    fetchUserRoles,
    batchCreateOrUpdateUserRoles,
    deleteUserRoles,
    loading,
    error,
    isOwner,
  } = useUserRoles({ userId: params?.userId, onCreateOrUpdateComplete });

  useEffect(() => {
    if (isFocused) {
      getVenues();
    }
  }, [getVenues, isFocused]);

  useEffect(() => {
    if (isFocused && params?.userId) {
      fetchUserRoles(params.userId);
    }
  }, [fetchUserRoles, params?.userId, isFocused]);

  const userRoles = useMemo(() => {
    return mapUserRolesByRoleIdAndStoreIds(userRolesById);
  }, [userRolesById]);

  useEffect(() => {
    userRoleInputUtility.init(userRoles);
  }, [userRoles]);

  useEffect(() => {
    if (error || venuesError) {
      showNotification({
        error: true,
        message: (error || venuesError) as string,
      });
    }
  }, [error, venuesError, showNotification]);

  const onSave = useCallback(() => {
    const userRolesInput = userRoleInputUtility.formInput;

    if (
      !Object.keys(userRolesInput).every(
        key =>
          userRolesInput[key].locations.length > 0 &&
          userRolesInput[key].roleId,
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeUsers.selectRoleAndStoresInput'),
      });
      return;
    }

    const userRolesToCreateOrUpdate = mapApiPayloadToCreateUserRole(
      params.userId,
      userRolesInput,
    );

    batchCreateOrUpdateUserRoles(userRolesToCreateOrUpdate);
  }, [
    params?.userId,
    batchCreateOrUpdateUserRoles,
    showNotification,
    translate,
  ]);

  const [emptyRows, setEmptyRowInputs] = useState<StoreUserRoleMappingByRole[]>(
    [],
  );

  const addEmptyRow = useCallback(() => {
    const status = userRoleInputUtility.addInput();
    if (!status) {
      showNotification({
        error: true,
        message: translate('backOfficeUsers.selectRoleAndStoresInput'),
      });
    }
  }, [showNotification, translate]);

  useEffect(() => {
    const subscription: Subscription =
      userRoleInputUtility.formInput$.subscribe(input => {
        const data = Object.values(input).filter(
          (formInput: StoreUserRoleMappingByRole) => formInput.unSaved === true,
        );
        setEmptyRowInputs(data);
      });
    return () => {
      userRoleInputUtility.reset();
      subscription.unsubscribe();
    };
  }, []);

  const renderExistingUserRole = useCallback(
    (userRoleGroup: StoreUserRoleMappingByRole): React.ReactNode => (
      <UserPermissionRow
        rolesById={rolesById}
        key={userRoleGroup.id}
        venues={venues}
        userRoleGroup={userRoleGroup}
        deleteUserRoles={deleteUserRoles}
        isOwner={isOwner}
        userId={params?.userId}
      />
    ),
    [rolesById, venues, deleteUserRoles, isOwner, params?.userId],
  );
  const renderNewUserRole = useCallback(
    (emptyRow: StoreUserRoleMappingByRole): React.ReactNode => (
      <NewUserPermissionRow
        rolesById={rolesById}
        key={emptyRow.id}
        venues={venues}
        newUserRoleData={emptyRow}
      />
    ),
    [rolesById, venues],
  );

  return (
    <ScreenLayout
      loading={loading || venuesLoading}
      onSave={onSave}
      hideFooter={isOwner}
      title={translate('navigation.jobRoleSettingsPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section
        layoutWidth="medium"
        title={translate('backOfficeUsers.manageRolesAndPermissions')}
      >
        <View testID="table">
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellJobRole]}>
              {translate('backOfficeUsers.jobRole')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellLocations]}>
              {translate('backOfficeUsers.stores')}
            </Text>
          </View>
          <View>
            {Object.values(userRoles).length > 0 || emptyRows.length > 0 ? (
              <>
                {Object.values(userRoles).map(jobRole =>
                  renderExistingUserRole(jobRole),
                )}
                {emptyRows.map(row => renderNewUserRole(row))}
              </>
            ) : (
              <View style={theme.tables.emptyView}>
                <Text style={theme.tables.emptyText}>
                  {translate('backOfficeUsers.noRolesAssigned')}
                </Text>
              </View>
            )}
          </View>
          {!isOwner && (
            <View style={styles.tableFooter}>
              <ButtonIcon
                testID="btnAddRole"
                icon="plus"
                type="positive"
                onPress={addEmptyRow}
              />
            </View>
          )}
        </View>
      </Section>
    </ScreenLayout>
  );
};
