import { gql } from '@apollo/client';

export const GET_REFUND_REASONS_QUERY = gql`
  query getRefundReasons {
    refundReasons {
      id
      name
      restock
    }
  }
`;
