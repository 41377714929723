// TODO: there is some improvement in code,
// will do it once we have more time :D
import { useMutation } from '@apollo/client/react/hooks';
import {
  DefaultPaymentTypes,
  EventInput,
  MoneyMovement,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderPaymentEvent,
  OrderStatus,
  Printer,
  PrinterProfileType,
  PrintingOptions,
  Product,
  Shift,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  computeOrderState,
  getPrintableOrderItems,
} from '@oolio-group/order-helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../../devConfig';
import { getQueue, queueEvents } from '../../events/eventsQueue';
import { pushEvents } from '../../events/pushEvents';
import { docketPrintedVar, workerInstanceVar } from '../../state/cache';
import { userUtility } from '../../state/userUtility';
import { generateOrderEvent } from '../../utils/orderEventHelper';
import rxjsInstance, {
  KitchenPrintEvent,
} from '../../utils/printerTemplates/kotEvents';
import {
  groupByPrinterProfileType,
  PrinterTemplateMapping,
} from '../../utils/printerTemplates/printingDataUtils';
import printHandler from '../../workers/handlers/printHandler';
import {
  openCashDrawerHandler,
  printBillHandler,
  printMoneyMovementReceiptHandler,
  printShiftHandler,
  printTestTemplateHandler,
  printTokenReceiptHandler,
  printRecipeHandler,
} from '../../workers/handlers/printHandlers';
import { printKitchenDocketHandler } from '../../workers/handlers/printHandlers/printKitchenDocketHandler';
import { useOrders } from './orders/useOrders';
import { useSyncOrderEvents } from './useSyncOrderEvents';
import {
  PrintBillWorkerInput,
  WorkerAction,
  WorkerActionResultStatus,
  WorkerInput,
} from '../../workers/utils';
import { ORDER_SAVE } from '../app/orders/graphql';
import { Notification, useNotification } from '../Notification';
import { useBase64Image } from './useBase64Image';
import { useDevices } from './useDevices';
import { useNetworkStatus } from './useNetworkStatus';
import { useSession } from './useSession';
import { dedupeOrderEvents } from '@oolio-group/order-helper';
import { disableTokenNumberForOrderType } from '../../utils/tokenNumber';
import { transferActionSummaryDocketHandler } from '../../workers/handlers/printHandlers/transferActionSummaryDocketHandler';
import { LoyaltyProgram } from '@oolio-group/loyalty-sdk';

export interface UsePrintingWithTemplate {
  printBill: (
    order: Order,
    nthPaymentToPrint?: number,
    additionalData?: { loyaltyProgram?: LoyaltyProgram },
  ) => Promise<Notification | void>;
  reprintKitchenDocket: (
    order: Order,
    orderItems: OrderItem[],
    reprintDocket?: boolean,
  ) => Promise<Notification | void>;
  printMoneyMovementReceipt: (
    moneyMovement: MoneyMovement,
  ) => Promise<Notification | void>;
  printShiftReceipt: (shift: Shift) => Promise<Notification | void>;
  printTestTemplate: (
    printer: Printer,
    template?: string,
  ) => Promise<Notification | void>;
  openCashDrawer: () => Promise<Notification | void>;
  printRecipe: (product: Product) => Promise<Notification | void>;
  printTransferActionSummary: (
    orderItems: OrderItem[],
    fromTables: string[],
    toTables: string[],
    transferredByName: string,
  ) => Promise<Notification | void>;
}

export enum DocketType {
  CANCEL_DOCKET = 'CANCEL_DOCKET',
  RESEND_DOCKET = 'RESEND_DOCKET',
  KITCHEN_DOCKET = 'KITCHEN_DOCKET',
}

export interface PrintError {
  message?: string;
}
/**
 * Used to print the receipt or order
 */
export function usePrintingWithTemplate(): UsePrintingWithTemplate {
  const [session] = useSession();

  // FIXME: once image issues are fixed with `pngjs`
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orgBase64Logo, setOrgBase64Logo] = useState<string>();
  const { currency } = useCurrency();

  const { getBase64Img, base64Logo } = useBase64Image();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const previousEventsRef = useRef<OrderEvent[]>([]);
  const { isConnected } = useNetworkStatus();

  const { getOrderFromCache } = useOrders();

  const { syncOrderEvents: syncOrderEventsToserver } = useSyncOrderEvents();

  const { devices, getDevicesSynchronously } = useDevices({
    deviceId: session?.device?.id,
    storeId: session?.currentStore?.id,
  });

  const reprintDocket = false;

  const [printerTemplateMapping, setPrinterTemplateMapping] =
    useState<PrinterTemplateMapping>({
      [PrinterProfileType.BILLING]: {},
      [PrinterProfileType.KITCHEN]: {},
    });

  const workerInstance = workerInstanceVar();

  const syncOrderEvents = useCallback(
    (events: EventInput[]) => {
      const syncAllEvents = async () => {
        const data = await getQueue();
        const uniqueEvents = dedupeOrderEvents(events);
        if (isConnected) {
          syncOrderEventsToserver([...data, ...uniqueEvents]);
        } else {
          await queueEvents(uniqueEvents);
        }
      };
      syncAllEvents();
    },
    [isConnected, syncOrderEventsToserver],
  );

  const [saveOrder] = useMutation(ORDER_SAVE, {});

  const onDocketPrint = useCallback(
    prevOrder => {
      const printDocketEvent = generateOrderEvent(
        OrderAction.ORDER_PRINT_KITCHEN_DOCKET,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
        },
        {
          orderId: prevOrder?.id,
          previous: prevOrder?.prevEventId,
        },
      );
      // Save Order for client Cache
      const order = computeOrderState([printDocketEvent], prevOrder);
      saveOrder({ variables: { data: order } });
      // Sync All Events
      const updatedEvent = [...previousEventsRef.current, printDocketEvent];

      docketPrintedVar(printDocketEvent);
      pushEvents(updatedEvent, syncOrderEvents);
      previousEventsRef.current = [];
    },
    [saveOrder, session, syncOrderEvents],
  );

  /**
   * Set base64 logo for printing
   */
  useEffect(() => {
    if (base64Logo) {
      setOrgBase64Logo(base64Logo);
    }
  }, [base64Logo]);

  /**
   * Get base64 logo for printing
   */
  useEffect(() => {
    // if (session.currentOrganization) {
    //   getBase64Img(
    //     'https://till-x-storage-development.s3-ap-southeast-2.amazonaws.com/images/organizations/logos/miniondevs-print.png',
    //   );
    // }
    if (
      session.currentOrganization?.id &&
      session.currentOrganization?.logoUrl
    ) {
      // fetch base64 logo
      getBase64Img(session.currentOrganization.logoUrl);
    }
  }, [session.currentOrganization, getBase64Img]);

  /**
   * Generate printer template, printer and printer profile mapping object.
   * It is used to pick the required configurations quickly while choosing between printer, template for printing
   */
  useEffect(() => {
    if (devices && session?.device?.id && devices[session?.device?.id]) {
      const currentDevice = devices[session?.device?.id];

      if (currentDevice.printingOptions) {
        const _groupByPrinterProfileType = groupByPrinterProfileType(
          currentDevice.printingOptions as PrintingOptions[],
        );
        setPrinterTemplateMapping(_groupByPrinterProfileType);
      }
    }
  }, [session.device?.id, devices]);

  /**
   * Print token receipts for a given order
   */
  const printTokenReceipt = useCallback(
    async (order: Order): Promise<Notification | void> => {
      if (disableTokenNumberForOrderType(order.orderType)) {
        // if order type is delivery or dine in we do not print token receipt
        return;
      }
      const bufferPayload: WorkerInput = {
        action: WorkerAction.PRINT_BILL,
        data: {
          order,
          currency,
          printerTemplateMapping,
          session,
        },
      };

      const isTokenActive = session.device?.isTokenNumberActive;

      const onlyPrintTokenOnReceipt =
        isTokenActive &&
        session.device?.tokenSettings?.onlyPrintTokenOnReceipts;

      if (!onlyPrintTokenOnReceipt) {
        // if print token on receipt was not checked then we do not print token receipt
        return;
      }

      let bufferObjs;
      try {
        bufferObjs = printTokenReceiptHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_BILL,
        data: {
          bufferObjs,
          order,
        },
        priority: 1,
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
      return { success: true, message: translate('printing.printSuccess') };
    },
    [
      printerTemplateMapping,
      session,
      workerInstance,
      currency,
      translate,
      showNotification,
    ],
  );

  /**
   * Print bill receipts for a given order
   */
  const printBill = useCallback(
    async (
      order: Order,
      nthPaymentToPrint?: number,
      additionalData?: { loyaltyProgram?: LoyaltyProgram },
    ): Promise<Notification | void> => {
      const bufferPayload: WorkerInput = {
        action: WorkerAction.PRINT_BILL,
        data: {
          order,
          currency,
          printerTemplateMapping,
          session,
          nthPaymentToPrint,
          additionalData,
        },
      };
      if (
        order?.tokenNumber &&
        disableTokenNumberForOrderType(order?.orderType)
      ) {
        // restrict printing token number if order type is dine in and delivery
        (bufferPayload.data as PrintBillWorkerInput).order = {
          ...order,
          tokenNumber: undefined,
        };
      }
      let bufferObjs;
      try {
        bufferObjs = printBillHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_BILL,
        data: {
          bufferObjs,
          order,
        },
        priority: 1,
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
      return { success: true, message: translate('printing.printSuccess') };
    },
    [
      printerTemplateMapping,
      session,
      workerInstance,
      currency,
      translate,
      showNotification,
    ],
  );

  /**
   * Print recipe for a product
   */
  const printRecipe = useCallback(
    async (product: Product): Promise<Notification | void> => {
      const bufferPayload: WorkerInput = {
        action: WorkerAction.PRINT_RECIPE,
        data: {
          product,
          printerTemplateMapping,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printRecipeHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_RECIPE,
        data: {
          bufferObjs,
        },
        priority: 1,
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
      return { success: true, message: translate('printing.printSuccess') };
    },
    [printerTemplateMapping, workerInstance, translate, showNotification],
  );

  const syncPendingEvents = useCallback(() => {
    if (!previousEventsRef.current.length) return;
    pushEvents([...previousEventsRef.current], syncOrderEvents);
    previousEventsRef.current = [];
  }, [syncOrderEvents]);

  /**
   * Used by kitchen printing (as of now)  /**
   * Used from TakeOrder screen relay on order events
   */
  const printKitchenDocket = useCallback(
    async (order: Order, printItems: OrderItem[], reprintDocket: boolean) => {
      const bufferPayload = {
        action: WorkerAction.PRINT_KITCHEN_DOCKET,
        data: {
          order,
          printerTemplateMapping,
          session,
          printItems,
          translatedNowCourse: translate('common.now'),
        },
      };
      if (
        order?.tokenNumber &&
        disableTokenNumberForOrderType(order.orderType)
      ) {
        // restrict printing token number if order type is dine in and delivery
        bufferPayload.data.order = {
          ...order,
          tokenNumber: undefined,
        };
      }

      let bufferObjs;

      try {
        bufferObjs = printKitchenDocketHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        syncPendingEvents();
        return;
      }

      if (!bufferObjs.length) {
        syncPendingEvents();
        return;
      }
      const workerPayload = {
        action: WorkerAction.PRINT_KITCHEN_DOCKET,
        data: {
          bufferObjs,
          order,
        },
        priority: 2,
      };

      onDocketPrint(order);

      function isNotOlderBy12Hours(
        orderTime: number,
        currentTime: number,
      ): boolean {
        const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
        const notOlderBy12Hours =
          currentTime - orderTime < twelveHoursInMilliseconds;

        //return true if order was less than 12 hours ago
        return notOlderBy12Hours;
      }

      const currentTime = Date.now();
      const onlineOrder = order.salesChannel?.name == 'Online' ? true : false;

      if (
        reprintDocket ||
        !onlineOrder ||
        isNotOlderBy12Hours(order.placedAt || order.createdAt, currentTime)
      ) {
        if (workerInstance) {
          workerInstance.send(workerPayload);
        } else {
          const results = await printHandler(workerPayload);
          if (
            results.some(
              result => result.status === WorkerActionResultStatus.ERROR,
            )
          ) {
            return {
              error: true,
              message: translate('printing.printFailed'),
            };
          }
        }
      } else if (
        !reprintDocket &&
        onlineOrder &&
        !isNotOlderBy12Hours(order.placedAt || order.createdAt, currentTime)
      ) {
        return;
      }
    },
    [
      printerTemplateMapping,
      session,
      onDocketPrint,
      workerInstance,
      showNotification,
      syncPendingEvents,
      translate,
    ],
  );

  useEffect(() => {
    const subscription = rxjsInstance.events.subscribe(
      (eventsData: KitchenPrintEvent) => {
        const { orderId, preEvents } = eventsData;
        const order = getOrderFromCache(orderId) as Order;
        const printItems = getPrintableOrderItems(order?.orderItems);
        previousEventsRef.current = preEvents;
        /**
         * Incase of payment events, find the integrated payment methods
         * - i.e., CARD, and mark them as partial paid -> do not print the dockets.
         * For the rest payment methods,
         * - i.e., CASH, ON_ACCOUNT, do not mark them as partial paid -> print the dockets.
         * If order itself is COMPLETED OR ON_ACCOUNT, do not mark them as partial paid -> print the dockets.
         */
        const isPartiallyPay =
          preEvents.some(
            event =>
              (event.action === OrderAction.ORDER_PAYMENT &&
                ![
                  DefaultPaymentTypes.CASH.toLowerCase(),
                  DefaultPaymentTypes.ON_ACCOUNT.toLowerCase(),
                ].includes(
                  (event as OrderPaymentEvent).paymentTypeName?.toLowerCase(),
                )) ||
              event.action === OrderAction.ORDER_PAYMENT_PROCESSED,
          ) &&
          ![OrderStatus.COMPLETED, OrderStatus.ON_ACCOUNT].includes(
            order.status,
          );
        if (printItems.length && !isPartiallyPay) {
          printTokenReceipt(order);
          printKitchenDocket(order, printItems, reprintDocket);
        } else {
          syncPendingEvents();
        }
      },
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [
    getOrderFromCache,
    printKitchenDocket,
    syncPendingEvents,
    printTokenReceipt,
    reprintDocket,
  ]);

  /**
   * Print money movement event receipts
   */
  const printMoneyMovementReceipt = useCallback(
    async (moneyMovement: MoneyMovement): Promise<Notification | void> => {
      const bufferPayload = {
        action: WorkerAction.PRINT_MONEY_MOVEMENT,
        data: {
          moneyMovement,
          printerTemplateMapping,
          session,
          currency,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printMoneyMovementReceiptHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_MONEY_MOVEMENT,
        data: {
          bufferObjs,
        },
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      printerTemplateMapping,
      currency,
      workerInstance,
      session,
      translate,
      showNotification,
    ],
  );

  /**
   * Used from TakeOrder screen to resend docket to kitchen
   */
  // temporary comments -> will handle this function later by using ref to cache latest print data ( just idea)
  const reprintKitchenDocket = useCallback(
    async (order: Order, orderItems: OrderItem[], reprintDocket?: boolean) => {
      reprintDocket = true;
      printKitchenDocket(order, orderItems, reprintDocket);
    },
    [printKitchenDocket],
  );

  /**
   * Print shift summary receipt
   */
  const printShiftReceipt = useCallback(
    async (shift: Shift): Promise<Notification | void> => {
      const bufferPayload = {
        action: WorkerAction.PRINT_SHIFT,
        data: {
          shift,
          printerTemplateMapping,
          session,
          currency,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printShiftHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_SHIFT,
        data: {
          bufferObjs,
        },
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);

        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        } else {
          return {
            success: true,
            message: translate('printing.shiftSummaryPrinted'),
          };
        }
      }
    },
    [
      printerTemplateMapping,
      session,
      workerInstance,
      currency,
      translate,
      showNotification,
    ],
  );

  const openCashDrawer = useCallback(async (): Promise<Notification | void> => {
    if (session.device?.trainingMode) return;
    const bufferPayload = {
      action: WorkerAction.PRINT_OPEN_CASH_DRAWER,
      data: {
        printerTemplateMapping,
      },
    };
    let bufferObjs;
    try {
      bufferObjs = openCashDrawerHandler(bufferPayload);
    } catch (error) {
      showNotification({
        info: true,
        message: (error as PrintError)?.message || '',
      });
      return;
    }

    const workerPayload = {
      action: WorkerAction.PRINT_OPEN_CASH_DRAWER,
      data: {
        bufferObjs,
      },
      priority: 0,
    };

    if (workerInstance) {
      workerInstance.send(workerPayload);
    } else {
      const results = await printHandler(workerPayload);
      if (
        results.some(result => result.status === WorkerActionResultStatus.ERROR)
      ) {
        return {
          error: true,
          message: translate('printing.printFailed'),
        };
      }
    }
  }, [
    printerTemplateMapping,
    workerInstance,
    translate,
    showNotification,
    session.device?.trainingMode,
  ]);

  const printTestTemplate = useCallback(
    async (
      printer: Printer,
      template?: string,
    ): Promise<Notification | void> => {
      const devices = await getDevicesSynchronously();
      const printerData = { ...printer };
      printerData.store = {
        ...printerData.store,
      };

      const bufferPayload = {
        action: WorkerAction.PRINT_TEST_RECEIPT,
        data: {
          devices: Object.values(devices),
          printer: printerData,
          session,
          template,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printTestTemplateHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_TEST_RECEIPT,
        data: {
          bufferObjs,
        },
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      session,
      workerInstance,
      getDevicesSynchronously,
      translate,
      showNotification,
    ],
  );

  const printTransferActionSummary = useCallback(
    async (
      orderItems: OrderItem[],
      fromTables: string[],
      toTables: string[],
      transferredByName: string,
    ): Promise<Notification | void> => {
      const bufferPayload = {
        action: WorkerAction.PRINT_TRANSFER_ACTION_SUMMARY,
        data: {
          printerTemplateMapping,
          session,
          orderItems,
          fromTables,
          toTables,
          transferredByName,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = transferActionSummaryDocketHandler(bufferPayload);
      } catch (error) {
        showNotification({
          info: true,
          message: (error as PrintError)?.message || '',
        });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_TRANSFER_ACTION_SUMMARY,
        data: {
          bufferObjs,
        },
        priority: 0,
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      printerTemplateMapping,
      session,
      workerInstance,
      showNotification,
      translate,
    ],
  );

  const value = useMemo(
    () => ({
      printBill,
      reprintKitchenDocket,
      printMoneyMovementReceipt,
      printShiftReceipt,
      printTestTemplate,
      openCashDrawer,
      printRecipe,
      printTransferActionSummary,
    }),
    [
      printBill,
      printMoneyMovementReceipt,
      reprintKitchenDocket,
      printShiftReceipt,
      printTestTemplate,
      openCashDrawer,
      printRecipe,
      printTransferActionSummary,
    ],
  );

  return value;
}
