import { View, StyleSheet, ViewStyle } from 'react-native';
import React from 'react';
import { StyleFn } from '@oolio-group/domain';
import { useFela, withTheme } from 'react-fela';
export interface TableRowProps {
  children?: React.ReactNode;
  action?: React.ReactNode;
  actionStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  normalRows?: boolean;
}

const container: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  paddingVertical: theme.padding.medium,
  marginRight: theme.spacing.small,
});

const childNodeStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
});

const styles = StyleSheet.create({
  firstChild: {
    marginLeft: 'auto',
  },
});

const TableRow: React.FC<TableRowProps> = ({
  children,
  action,
  actionStyle,
  containerStyle,
  normalRows,
}: TableRowProps) => {
  const { css } = useFela();
  return (
    <View style={[css(container), containerStyle]} testID={'table-header'}>
      {React.Children.map(children, (child, index) => {
        if (index === 1 && !normalRows) {
          return <View style={styles.firstChild}>{child}</View>;
        } else if (normalRows) {
          return child;
        } else {
          return <View style={css(childNodeStyle)}>{child}</View>;
        }
      })}
      {action && (
        <View style={[css(childNodeStyle), actionStyle]}>{action}</View>
      )}
    </View>
  );
};

export default withTheme(TableRow);
