/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { ProductModifierGroup } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { ProductModifiers } from '../../ProductSettings/Options/ProductModifiers';
import theme from '../../../../../common/default-theme';
import modalStyles from '../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';

interface AssignModifiersProps {
  onSubmit: (productModifiers: ProductModifierGroup[]) => void;
}

export const AssignModifiers: React.FC<AssignModifiersProps> = ({
  onSubmit,
}: AssignModifiersProps) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [productModifiers, setProductModifiers] = useState(
    [] as ProductModifierGroup[],
  );
  const onSave = () => {
    onSubmit(productModifiers);
    setTimeout(() => {
      closeModal();
    }, 200);
  };

  const onRemoveAll = () => {
    setProductModifiers([]);
  };

  const updateProductModifiers = (value: ProductModifierGroup[]) => {
    setProductModifiers(value);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <View testID="modal-assignOptions" style={styles.modal}>
        <View style={modalStyles.title}>
          <Text style={[modalStyles.titleText, styles.title]}>
            {translate('productBulkOperations.assignModifiers')}
          </Text>
        </View>
        <View style={styles.modalContent}>
          <ProductModifiers
            isModal
            productModifiers={productModifiers}
            setProductModifiers={updateProductModifiers}
          />
        </View>
        <View style={modalStyles.actions}>
          {productModifiers.length > 0 && (
            <TreatButton
              type="negativeLight"
              testID="btn-removeAll"
              onPress={onRemoveAll}
              label="Remove All"
              containerStyle={{ marginRight: 'auto' }}
            />
          )}
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={closeModal}
            label="Dismiss"
          />
          <TreatButton
            type="neutral"
            testID="btn-confirm"
            onPress={onSave}
            label="Update"
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 600,
    alignSelf: 'center',
  },
  title: {
    color: theme.colors.states.neutral,
  },
  // Modal
  modal: {
    width: 600,
    alignSelf: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
  },
  modalContent: {
    paddingHorizontal: 30,
  },
  modalInput: {
    width: 280,
    marginBottom: 20,
  },
  modalTable: {
    zIndex: -1,
  },
  modalTableBody: {
    height: 210,
  },
});
