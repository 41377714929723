import React from 'react';
import { ManageUsers } from './ManageUsers';
import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

const UsersTab: React.FC = () => {
  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName="ManageUsersTab"
      screenOptions={{
        animationEnabled: true,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
    >
      <Stack.Screen component={ManageUsers} name="ManageUsersTab" />
    </Stack.Navigator>
  );
};

export default UsersTab;
