/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeliverySettings, Adjustment } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { convertAlphaNumbericToNumber } from '@oolio-group/client-utils';
import { getCurrencySymbol, useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../../../hooks/Notification';
import InputText from '../../../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../../../../components/Shared/Inputs/InputToggle';
import SelectMultiple, {
  SelectMultipleOption,
} from '../../../../../../../../components/Shared/Select/SelectMultiple';

interface DeliveryModalProps {
  deliverySettings?: DeliverySettings;
  onCancel?: () => void;
  onConfirm?: (settingName: string, settings: DeliverySettings) => void;
  currencyCode?: string;
  adjustments?: Adjustment[];
}

const DeliveryModal: React.FC<DeliveryModalProps> = ({
  deliverySettings,
  currencyCode,
  onCancel,
  onConfirm,
  adjustments,
}: DeliveryModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [deliverySettingsData, setDeliverySettingsData] =
    useState<DeliverySettings>(deliverySettings as DeliverySettings);

  const errorValidation = useCallback(() => {
    return (
      (deliverySettingsData &&
        !deliverySettingsData.enablePayLater &&
        !deliverySettingsData.enablePayNow &&
        translate('backOfficeFeatures.noAllowedPayment')) ||
      undefined
    );
  }, [deliverySettingsData, translate]);

  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [onCancel, closeModal]);

  const onPressConfirm = useCallback(() => {
    const error = errorValidation();
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
      return;
    }
    closeModal();
    deliverySettingsData &&
      onConfirm &&
      onConfirm('deliverySettings', deliverySettingsData);
  }, [
    errorValidation,
    closeModal,
    onConfirm,
    deliverySettingsData,
    showNotification,
  ]);

  const onValueUpdate = useCallback(
    (key: keyof DeliverySettings, value: unknown) => {
      const formattedValue =
        key === 'adjustments'
          ? adjustments?.filter(adjustment =>
              (value as string[]).includes(adjustment.id as string),
            )
          : value;
      setDeliverySettingsData(settingData => ({
        ...settingData,
        [key]: formattedValue,
      }));
    },
    [adjustments],
  );

  useEffect(() => {
    deliverySettings && setDeliverySettingsData(deliverySettings);
  }, [deliverySettings]);

  const adjustmentsOptions = useMemo(() => {
    return adjustments?.map(adjustment => {
      return {
        value: adjustment.id,
        label: adjustment.name,
      } as SelectMultipleOption;
    });
  }, [adjustments]);

  return (
    <TreatModal
      title={translate('backOfficeFeatures.orderTypesSettings', {
        orderType: translate('backOfficeFeatures.delivery'),
      })}
      onConfirm={{ action: onPressConfirm }}
      onDismiss={{ action: onPressCancel }}
    >
      <InputText
        numericOnly
        testID="input-fee"
        title={translate('backOfficeFeatures.deliverySettings.deliveryFee')}
        label={getCurrencySymbol(currencyCode)}
        value={(deliverySettingsData?.deliveryFee || '0').toString()}
        placeholder={translate(
          'backOfficeFeatures.deliverySettings.deliveryFee',
        )}
        onChangeText={value =>
          onValueUpdate('deliveryFee', convertAlphaNumbericToNumber(value))
        }
        containerStyle={{ marginBottom: 20 }}
      />
      <SelectMultiple
        testID="select-adjustments"
        title={translate('backOfficeFeatures.adjustments')}
        options={adjustmentsOptions || []}
        selectedValues={
          deliverySettingsData?.adjustments?.map(
            (adjustment: Adjustment) => adjustment.id,
          ) as string[]
        }
        onValueChange={values => onValueUpdate('adjustments', values)}
        containerStyle={{ marginBottom: 20 }}
      />
      <InputText
        testID="input-time"
        numericOnly
        textAlign="right"
        title={translate('backOfficeFeatures.deliverySettings.deliveryTime')}
        value={(deliverySettingsData?.deliveryTime || '0').toString()}
        placeholder={translate(
          'backOfficeFeatures.deliverySettings.deliveryTime',
        )}
        onChangeText={value =>
          onValueUpdate('deliveryTime', convertAlphaNumbericToNumber(value))
        }
        containerStyle={{ marginBottom: 20 }}
      />
      <InputToggle
        testID="toggle-payLater"
        title={translate('backOfficeFeatures.allowPayLater')}
        isToggled={Boolean(deliverySettingsData?.enablePayLater)}
        onToggle={() =>
          onValueUpdate('enablePayLater', !deliverySettingsData?.enablePayLater)
        }
        containerStyle={{ marginBottom: 4 }}
      />
      <InputToggle
        testID="toggle-payNow"
        title={translate('backOfficeFeatures.allowPayNow')}
        isToggled={Boolean(deliverySettingsData?.enablePayNow)}
        onToggle={() =>
          onValueUpdate('enablePayNow', !deliverySettingsData?.enablePayNow)
        }
      />
    </TreatModal>
  );
};

export default DeliveryModal;
