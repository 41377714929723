import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useCallback, useMemo, useState } from 'react';
import { CreateRoleInput, Role, UpdateRoleInput } from '@oolio-group/domain';
import {
  CREATE_ROLE,
  DELETE_ROLE,
  GET_ALL_ROLES,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
} from '../../../graphql/users';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import useRolesContext from './useRolesContext';

export interface UseRoles {
  loading: boolean;
  error?: string;
  roles: Role[];
  rolesById: Record<string, Role>;
  createRole: (input: CreateRoleInput) => void;
  updateRole: (input: UpdateRoleInput) => void;
  fetchRoles: (input: GetAllRolesVars) => void;
  fetchRoleById: (id: string) => void;
  deleteRole: (roleId: string) => void;
}

interface GetAllRolesVars {
  includeSystemRoles?: boolean;
  includeUserCount?: boolean;
}

interface Props {
  onUpdateCompleted?: (role: Role) => void;
  onCreateCompleted?: (role: Role) => void;
  onDeleteCompleted?: (role: Role) => void;
}

export const useRoles = (props?: Props): UseRoles => {
  const [roles, setRoles] = useState<Record<string, Role>>({});
  const {
    addOrUpdateRole: addOrUpdateRoleInContext,
    removeRole: removeRoleInContext,
  } = useRolesContext();

  const [getRolesRequest, getRolesResponse] = useLazyQuery<
    {
      roles: Role[];
    },
    GetAllRolesVars
  >(GET_ALL_ROLES, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
    onCompleted: data => {
      setRoles(
        data.roles?.reduce((acc, role) => ({ ...acc, [role.id]: role }), {}),
      );
    },
  });

  const [getRoleByIdRequest, getRoleByIdResponse] = useLazyQuery<
    {
      role: Role;
    },
    { id: string }
  >(GET_ROLE_BY_ID, {
    fetchPolicy: 'cache-first',
    onError: noopHandler,
    onCompleted: data => {
      setRoles({ ...roles, [data.role.id]: data.role });
    },
  });

  const [createRoleRequest, createRoleResponse] = useMutation<
    { createRole: Role },
    { input: CreateRoleInput }
  >(CREATE_ROLE, {
    onError: noopHandler,
    onCompleted: data => {
      setRoles({ ...roles, [data.createRole.id]: data.createRole });
      addOrUpdateRoleInContext(data.createRole);
      if (props?.onCreateCompleted) {
        props.onCreateCompleted(data.createRole);
      }
    },
    update: (cache, mutationResult) => {
      if (mutationResult.data?.createRole) {
        const data = cache.readQuery<{ roles: Role[] }, GetAllRolesVars>({
          query: GET_ALL_ROLES,
          variables: { includeSystemRoles: true, includeUserCount: true },
        });
        cache.writeQuery({
          query: GET_ALL_ROLES,
          variables: { includeSystemRoles: true, includeUserCount: true },
          data: {
            roles: (data?.roles || []).concat(mutationResult.data.createRole),
          },
        });
      }
    },
  });

  const [deleteRoleRequest, deleteRoleResponse] = useMutation<
    { deleteRole: string },
    { id: string }
  >(DELETE_ROLE, {
    onError: noopHandler,
    onCompleted: data => {
      const { [data.deleteRole]: deletedRole, ...rest } = roles;
      setRoles(rest);
      removeRoleInContext(data.deleteRole);
      if (props?.onDeleteCompleted) {
        props.onDeleteCompleted(deletedRole);
      }
    },
    update: (cache, mutationResult) => {
      if (mutationResult?.data?.deleteRole) {
        const deletedRoleId = mutationResult.data.deleteRole;
        const data = cache.readQuery<{ roles: Role[] }, GetAllRolesVars>({
          query: GET_ALL_ROLES,
          variables: { includeSystemRoles: true, includeUserCount: true },
        });
        cache.writeQuery({
          query: GET_ALL_ROLES,
          variables: { includeSystemRoles: true, includeUserCount: true },
          data: {
            roles: (data?.roles || []).filter(
              role => role.id !== deletedRoleId,
            ),
          },
        });
      }
    },
  });

  const [updateRoleRequest, updateRoleResponse] = useMutation<
    { updateRole: Role },
    { input: UpdateRoleInput }
  >(UPDATE_ROLE, {
    onError: noopHandler,
    onCompleted: data => {
      setRoles({ ...roles, [data.updateRole.id]: data.updateRole });
      addOrUpdateRoleInContext(data.updateRole);
      if (props?.onUpdateCompleted) {
        props.onUpdateCompleted(data.updateRole);
      }
    },
    update: (cache, mutationResult) => {
      if (mutationResult.data?.updateRole) {
        const data = cache.readQuery<{ roles: Role[] }, GetAllRolesVars>({
          query: GET_ALL_ROLES,
          variables: { includeSystemRoles: true, includeUserCount: true },
        });
        cache.writeQuery({
          query: GET_ALL_ROLES,
          variables: { includeSystemRoles: true, includeUserCount: true },
          data: {
            roles: (data?.roles || []).map(eachRole => {
              if (eachRole.id === mutationResult?.data?.updateRole?.id) {
                return mutationResult.data.updateRole;
              }
              return eachRole;
            }),
          },
        });
      }
    },
  });

  const fetchRoles = useCallback(
    ({
      includeSystemRoles = false,
      includeUserCount = false,
    }: GetAllRolesVars): void => {
      getRolesRequest({ variables: { includeSystemRoles, includeUserCount } });
    },
    [getRolesRequest],
  );

  const fetchRoleById = useCallback(
    (id: string): void => {
      getRoleByIdRequest({ variables: { id } });
    },
    [getRoleByIdRequest],
  );

  const createRole = useCallback(
    async (input: CreateRoleInput) => {
      createRoleRequest({
        variables: {
          input,
        },
      });
    },
    [createRoleRequest],
  );

  const deleteRole = useCallback(
    (roleId: string) => {
      deleteRoleRequest({
        variables: {
          id: roleId,
        },
      });
    },
    [deleteRoleRequest],
  );

  const updateRole = useCallback(
    async (input: UpdateRoleInput) => {
      updateRoleRequest({
        variables: {
          input,
        },
      });
    },
    [updateRoleRequest],
  );

  const RESPONSE_ENTITIES = [
    createRoleResponse,
    deleteRoleResponse,
    getRolesResponse,
    getRoleByIdResponse,
    updateRoleResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      rolesById: roles,
      roles: Object.values(roles),
      loading,
      createRole,
      deleteRole,
      updateRole,
      fetchRoles,
      fetchRoleById,
      error: error ? parseApolloError(error) : undefined,
    }),
    [
      loading,
      error,
      roles,
      createRole,
      deleteRole,
      fetchRoles,
      fetchRoleById,
      updateRole,
    ],
  );
};
