import { IntegrationApps } from '@oolio-group/domain';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { Linking } from 'react-native';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import { useWorkforceIntegration } from '../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import { useNotification } from '../../../../../../hooks/Notification';
import { useIsFocused } from '@react-navigation/native';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import FeatureDetail from '../../../Layout/FeatureDetail/FeatureDetail';

export const AboutTab: React.FC = () => {
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const { getIntegrations, integrations, loading } = useFeatures();

  const featureData = integrations?.find(
    integration => integration.app === IntegrationApps.DEPUTY,
  );

  useEffect(() => {
    if (isFocused) getIntegrations();
  }, [getIntegrations, isFocused]);

  const {
    workforcePartnerConsentUrl,
    getWorkforcePartnerConsentUrl,
    loading: workforceIntegrationLoading,
    deputyIntegrationData,
    saveIntegrationDetails,
    toggleWorkforceIntegrationConnection,
    getDeputyIntegrationData,
  } = useWorkforceIntegration();

  useEffect(() => {
    async function redirectToDeputy() {
      if (workforcePartnerConsentUrl) {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(workforcePartnerConsentUrl);

        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(workforcePartnerConsentUrl);
        } else {
          showNotification({
            message: translate('backOfficeFeatures.invalidUrl'),
            error: true,
          });
        }
      }
    }
    redirectToDeputy();
  }, [workforcePartnerConsentUrl, showNotification, translate]);

  useEffect(() => {
    async function getURL() {
      const url = await Linking.getInitialURL();
      const isValidOAuthCallBackURL =
        !url?.includes('error=access_denied') && url?.includes('code');

      if (url && isValidOAuthCallBackURL && !deputyIntegrationData?.isActive) {
        saveIntegrationDetails(url);
      }
    }
    getURL();
  }, [deputyIntegrationData?.isActive, saveIntegrationDetails]);

  useEffect(() => {
    if (isFocused) getDeputyIntegrationData();
  }, [getDeputyIntegrationData, isFocused]);

  const toggleAccountingPartnerConnection = useCallback(() => {
    if (deputyIntegrationData?.isActive) {
      toggleWorkforceIntegrationConnection(IntegrationApps.DEPUTY);
    } else {
      getWorkforcePartnerConsentUrl(IntegrationApps.DEPUTY);
    }
  }, [
    getWorkforcePartnerConsentUrl,
    deputyIntegrationData,
    toggleWorkforceIntegrationConnection,
  ]);

  return (
    <ScreenLayout
      hideFooter
      loading={loading || workforceIntegrationLoading}
      title={translate('navigation.integrationsPage', {
        appName: translate('appName'),
      })}
    >
      <FeatureDetail
        feature={featureData}
        active={deputyIntegrationData?.isActive}
        onEnable={toggleAccountingPartnerConnection}
      />
    </ScreenLayout>
  );
};
