import { BehaviorSubject } from 'rxjs';

export class SyncAppUtility {
  private subject = new BehaviorSubject<boolean>(false);

  syncApp = () => {
    this.subject.next(true);
  };
  get getSubscriptionState$(): BehaviorSubject<boolean> {
    return this.subject;
  }

  onSyncComplete = () => {
    this.subject.next(false);
  };
}

export const syncAppUtility = new SyncAppUtility();
