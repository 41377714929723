import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ReportingGroups } from './ReportingGroups/ReportingGroups';
import { ReportSettings } from './ReportSettings/ReportSettings';

const Stack = createStackNavigator();

const ReportingStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="ReportingGroups"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen name="ReportingGroups" component={ReportingGroups} />
    <Stack.Screen name="ReportSettings" component={ReportSettings} />
  </Stack.Navigator>
);

export default ReportingStack;
