import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.transparent,
  },
  value: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.black1,
    fontFamily: theme.fonts.semibold,
    fontVariant: ['tabular-nums'],
  },
  button: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
