import React, { FC } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './CartNew.styles';

const CartNew: FC = () => {
  const { translate } = useTranslation();

  return (
    <View testID="cart-new" style={styles.container}>
      <Text style={styles.text}>{translate('order.newLabel')}</Text>
    </View>
  );
};

export default CartNew;
