import {
  RejectionReason,
  Order,
  RenderProps,
  StyleFn,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import React, { useCallback, useMemo, useState } from 'react';
import { FelaComponent } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import Button, { ButtonProps } from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import DropDown from '../../FormInput/DropDown';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import { useFela } from 'react-fela';
import scale, { isWeb } from '../../../common/theme';
import InputText from '../../Shared/Inputs/InputText';

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

const submitButtonStyle: StyleFn = () => ({
  marginBottom: isWeb ? 0 : 20,
  marginTop: 20,
});

const SubmitButton: React.FC<ButtonProps> = ({ onPress }) => {
  const { translate } = useTranslation();
  return (
    <FelaComponent style={submitButtonStyle}>
      {({ style }: RenderProps) => (
        <Button
          fluid
          danger
          onPress={onPress}
          containerStyle={style}
          testID="submit-button"
          title={translate('onlineOrders.rejectOrder')}
        />
      )}
    </FelaComponent>
  );
};

export interface RejectOrderModalProps {
  order: Order;
  onReject?: (reason: RejectionReason, description?: string) => void;
}

const styles = StyleSheet.create({
  container: {
    width: 400,
    alignSelf: 'center',
  },
  fullWidth: {
    justifyContent: 'space-between',
    width: '100%',
    height: 44,
    alignSelf: 'center',
  },
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  height: scale.moderateScale(35),
  width: 350,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: 350,
  height: scale.moderateScale(30),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownViewStyle: StyleFn = () => ({
  alignSelf: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: scale.moderateScale(2),
});

export const labelTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smaller,
  paddingLeft: theme.padding.medium,
});

export const textInputContainer: StyleFn = () => ({
  marginTop: 10,
  width: '100%',
  height: 150,
});

const popupContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  paddingVertical: theme.padding.medium * 1.9,
});

export const RejectOrderModal: React.FC<RejectOrderModalProps> = ({
  order,
  onReject,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [reason, setReason] = useState(RejectionReason.OUT_OF_STOCK);
  const [description, setDescription] = useState('');
  const { css } = useFela();

  const options = useMemo(
    () =>
      Object.values(RejectionReason).map(value => ({
        value,
        label: translate(`enums.${value}`),
      })),
    [translate],
  );

  const onPressSubmit = useCallback(() => {
    onReject && onReject(reason as RejectionReason, description);
    closeModal();
  }, [closeModal, description, reason, onReject]);

  return (
    <View style={styles.container}>
      <TitleBar
        primary
        testID="title"
        title={`${translate('onlineOrders.rejectOrder')} (#${
          order?.orderNumber
        })`}
        titleLeft={<CloseButton onPress={closeModal} />}
      />

      <PopupView containerStyle={css(popupContainerStyle)}>
        <DropDown
          fluid
          values={options}
          selectedValue={reason}
          onValueChange={setReason}
          style={styles.fullWidth}
          extraMainViewStyle={css(dropDownMainViewStyle)}
          extraStyle={css(dropdownExtraStyle)}
          extraViewStyle={css(dropdownViewStyle)}
          title={translate('onlineOrders.selectReason')}
          textStyle={css(labelTextStyle)}
        />
        <View style={css(textInputContainer)}>
          <InputText
            value={description}
            onChangeText={setDescription}
            testID="input-description"
            placeholder={translate('onlineOrders.descriptionPlaceholder')}
          />
        </View>
        <SubmitButton onPress={onPressSubmit} />
      </PopupView>
    </View>
  );
};
