import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

export const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  nutrient: {
    flex: 1,
    // minWidth: 260,
    marginRight: 10,
  },
  avgQtyServe: {
    width: 120,
    marginRight: 60,
    textAlign: 'center',
  },
  imageContainer: {
    width: 540,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageContainer: {
    width: theme.layoutWidth.s,
    marginBottom: 40,
  },
  btnRemove: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    marginLeft: 20,
  },
  btnAdd: {
    marginTop: 10,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});
