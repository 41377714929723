import {
  PrinterProfileType,
  Order,
  PrinterType,
  PrintingOptions,
  PrinterTemplate,
  PrinterProfile,
} from '@oolio-group/domain';
import { Notification } from '../../hooks/Notification';

import { Printer } from '@oolio-group/domain';

export interface PrinterConfig {
  printer: Printer;
  template: string;
}

interface PrinterTemplateMap {
  [x: string]: {
    printer: Printer;
    template: PrinterTemplate;
    profile: PrinterProfile;
  };
}
export type PrinterTemplateMapping = {
  [key in PrinterProfileType]: PrinterTemplateMap;
};
export const SUPPORTED_PRINTING_MODES = [
  PrinterType.LAN,
  PrinterType.WIFI,
  PrinterType.BLUETOOTH,
  PrinterType.VIRTUAL,
];

export const isPrinterSupportedByPrinting = (printer: Printer) =>
  new Set(SUPPORTED_PRINTING_MODES).has(printer?.printerType);

export const validatePrintConfigurationOrInput = (
  order: Order | undefined,
  printerTemplateMapping: PrinterTemplateMapping,
  printerProfileType: PrinterProfileType = PrinterProfileType.BILLING ||
    PrinterProfileType.KITCHEN,
): PrinterConfig | Notification | Array<PrinterConfig> => {
  if (
    !(
      printerTemplateMapping &&
      printerTemplateMapping[printerProfileType] &&
      Object.values(printerTemplateMapping[printerProfileType]).length > 0
    )
  ) {
    return {
      error: true,
      message: 'Printer Template or Printer profile mapping not found',
    };
  }

  // validate if order is present
  if (order && (order.orderItems || []).length === 0) {
    return { info: true, message: 'No order items present' };
  }

  if (printerProfileType === PrinterProfileType.BILLING) {
    // Billing PrinterProfileType will only have one RECEIPT as of now,
    // so doing this
    const [{ printer, template }] = Object.values(
      printerTemplateMapping[printerProfileType],
    );

    if (!isPrinterSupportedByPrinting(printer)) {
      return {
        info: true,
        message: `Currently Printing is not supported on ${
          printer?.printerType || 'assigned printer'
        }`,
      };
    }
    if (!template) {
      return {
        error: true,
        message: 'Printer template is not defined for Billing',
      };
    }

    return { printer, template: template.template };
  }
  if (printerProfileType === PrinterProfileType.KITCHEN) {
    // kitchen printerProfileType
    const unSupportedPrinter = Object.values(
      printerTemplateMapping[printerProfileType],
    )
      // ignore printer profile mapping, for which printer is not mapped
      .filter(
        printerTemplateMap =>
          typeof printerTemplateMap !== 'undefined' &&
          typeof printerTemplateMap.printer !== 'undefined',
      )
      .find(
        printerTemplateMap =>
          !isPrinterSupportedByPrinting(printerTemplateMap.printer),
      );

    if (unSupportedPrinter) {
      return {
        info: true,
        message: `Printing is not supported on ${
          unSupportedPrinter.printer?.printerType || 'assigned printer'
        }. Update settings for printer ${
          unSupportedPrinter.printer?.name || ''
        }`,
      };
    }

    const printerTemplateNotDefined = Object.values(
      printerTemplateMapping[printerProfileType],
    ).find(printerTemplateMap => {
      return !printerTemplateMap?.template?.template;
    });

    if (printerTemplateNotDefined) {
      return {
        error: true,
        message: `A template should be assigned to printer ${
          printerTemplateNotDefined.printer?.name || ''
        }`,
      };
    }

    return (
      Object.values(printerTemplateMapping[printerProfileType])
        // ignore printer profile mapping, for which printer is not mapped
        .filter(
          printerTemplateMap =>
            typeof printerTemplateMap !== 'undefined' &&
            typeof printerTemplateMap.printer !== 'undefined',
        )
        .map(printerTemplateMap => ({
          printer: printerTemplateMap?.printer,
          template: printerTemplateMap?.template?.template,
        }))
    );
  }
  return { info: true, message: `${printerProfileType} is not yet supported` };
};

export const groupByPrinterProfileType = (
  printingOptions: PrintingOptions[],
): PrinterTemplateMapping => {
  if (!printingOptions) {
    return {
      [PrinterProfileType.BILLING]: {},
      [PrinterProfileType.KITCHEN]: {},
    };
  }
  const defaultsMapping: PrinterTemplateMapping = {
    [PrinterProfileType.BILLING]: {},
    [PrinterProfileType.KITCHEN]: {},
  };
  return printingOptions.reduce((acc, printingOption: PrintingOptions) => {
    return {
      ...acc,
      [printingOption.printerProfileType]: {
        ...acc[printingOption.printerProfileType],
        [printingOption.profile.id]: printingOption,
      },
    } as PrinterTemplateMapping;
  }, defaultsMapping);
};
