import {
  Product,
  Tag,
  TagType,
  CreateTagInput,
  PRODUCT_UNITS,
  ProductType,
  LOCALE,
  GeneralProductData,
  NutrientInfo,
  NutrientNameKey,
  NutrientUnitKey,
} from '@oolio-group/domain';
import { UpdateProductInput } from '@oolio-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import {
  GET_TAGS_QUERY,
  ADD_TAG_MUTATION,
} from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useTranslation } from '@oolio-group/localization';
import { UnitType } from '@oolio-group/domain';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useProductTypes } from '../../../../../hooks/app/useProductTypes';
import { usePrinterProfiles } from '../../../../../hooks/app/usePrinterProfiles';
import { GeneralForm } from './GeneralForm';
import { PRODUCT_GENERAL_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import {
  encodeAlternateNameValuesToBase64,
  decodeAlternateNameValuesToBase64,
} from '@oolio-group/client-utils';
import { updateAlternateNamesWithNewValue } from '../../../../../utils/AlternateNameHelper';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import {
  CATEGORIES_AS_OPTIONS,
  useCategories,
} from '../../../../../hooks/app/categories/useCategories';
import { cloneDeep } from 'lodash';

interface GeneralProductProps {
  productId: string;
  isVariantProduct?: boolean;
}

const unitsValues: { [key: string]: { label: string; value: string }[] } = {
  Volume: PRODUCT_UNITS[UnitType.Volume].map(unit => ({
    label: unit,
    value: unit,
  })),
  Weight: PRODUCT_UNITS[UnitType.Weight].map(unit => ({
    label: unit,
    value: unit,
  })),
};

const DEFAULT_NUTRIENT: NutrientInfo = {
  name: 'ENERGY' as NutrientNameKey,
  value: 0,
  unit: 'KCAL' as NutrientUnitKey,
};

export const GeneralProduct: React.FC<GeneralProductProps> = ({
  productId,
  isVariantProduct,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [productData, setProductData] = useState<GeneralProductData>(
    {} as GeneralProductData,
  );

  const [dietaryTagsArray, setDietaryTagsArray] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    products,
    error: prodErr,
    updateProduct,
    loading,
  } = useProducts(productId, PRODUCT_GENERAL_FRAGMENT);

  const originalData = useMemo(
    () => products[productId] as Product,
    [productId, products],
  );

  const { productTypes, getProductTypes } = useProductTypes({
    fetchPolicy: 'cache-first',
  });
  const { printerProfiles, getPrinterProfiles } = usePrinterProfiles();
  const { categoryMaps, getCategories } = useCategories({
    customFragment: CATEGORIES_AS_OPTIONS,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getProductTypes();
    getPrinterProfiles();
    getCategories();
  }, [getProductTypes, getPrinterProfiles, getCategories]);

  const tagsQuery = useQuery(GET_TAGS_QUERY, {
    variables: { filter: { tagType: TagType.DIETARY } },
    fetchPolicy: 'cache-and-network',
  });

  const [addTag, addTagRequest] = useMutation(ADD_TAG_MUTATION, {
    onError: noopHandler,
  });

  useEffect(() => {
    if (tagsQuery.data?.tags?.length) {
      const tagsData = (tagsQuery.data?.tags as Tag[]).map(tag => ({
        label: tag.name,
        value: tag.id,
      }));
      setDietaryTagsArray(tagsData);
    }
  }, [tagsQuery.data]);

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [productTypes]);

  const categoryOptions = useMemo(() => {
    return Object.values(categoryMaps).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [categoryMaps]);

  const printerProfileOptions = useMemo(() => {
    return Object.values(printerProfiles).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [printerProfiles]);

  useEffect(() => {
    if (addTagRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(addTagRequest.error),
      });
    }
  }, [addTagRequest.error, showNotification]);

  useEffect(() => {
    if (addTagRequest.data) {
      showNotification({
        success: true,
        message: translate('productSettings.tagAddedSuccessfully'),
      });
      if (addTagRequest.data.createTag) {
        setDietaryTagsArray(previous => {
          const tempTagOptions = [...previous];
          tempTagOptions.push({
            label: addTagRequest.data.createTag.name,
            value: addTagRequest.data.createTag.id,
          });
          return tempTagOptions;
        });
      }
    }
  }, [addTagRequest.data, showNotification, translate]);

  useEffect(() => {
    if (tagsQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(tagsQuery.error),
      });
    }
  }, [tagsQuery.error, showNotification]);

  useEffect(() => {
    if (productId && products[productId]) {
      const product = products[productId] as Product;
      const nutrientInfo = cloneDeep([...(product.nutrientInfo ?? [])]);
      const productModal = {
        ...product,
        nutrientInfo,
        alternateNames: decodeAlternateNameValuesToBase64(
          product.alternateNames || [],
        ),
        printerProfiles: product.printerProfiles?.map(
          printerProfile => printerProfile.id,
        ),
        category: product?.category?.id,
      } as unknown as GeneralProductData;
      setProductData(productModal);
    }
  }, [products, productId]);

  useEffect(() => {
    if (prodErr) {
      showNotification({
        error: true,
        message: prodErr,
      });
    }
  }, [prodErr, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    if (prop === 'productType') {
      setProductData(form => {
        return {
          ...form,
          productType: { id: value } as ProductType,
        };
      });
    } else if (prop === 'dietaryTags') {
      setProductData(form => {
        return {
          ...form,
          dietaryTags: value.map(
            (tagId: string) => ({ id: tagId } as unknown as Tag),
          ),
        };
      });
    } else if (prop === 'allergens') {
      setProductData(form => {
        return {
          ...form,
          allergens: value,
        };
      });
    } else {
      setProductData(form => {
        return {
          ...form,
          [prop]: value,
        };
      });
    }
  }, []);

  const onChangeAlternateName = useCallback(
    (prop: string, value: string, locale: LOCALE): void => {
      setProductData(previousProduct => {
        return updateAlternateNamesWithNewValue(
          previousProduct as GeneralProductData,
          locale,
          prop,
          value,
        ) as GeneralProductData;
      });
    },
    [],
  );

  const onChangeNutrient = useCallback(
    (
      key: 'name' | 'unit' | 'value',
      value: NutrientNameKey | NutrientUnitKey | string,
      index: number,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nutrientInfo: any[] = productData.nutrientInfo ?? [];
      nutrientInfo[index][key] = value;
      setProductData({
        ...productData,
        nutrientInfo,
      });
    },
    [productData],
  );

  const onPressNutrientAction = useCallback(
    (action: 'add' | 'remove', index: number) => {
      if (action === 'add') {
        setProductData({
          ...productData,
          nutrientInfo: cloneDeep([
            ...(productData.nutrientInfo ?? []),
            DEFAULT_NUTRIENT,
          ]),
        });
      } else {
        const filteredNutrientInfo = productData.nutrientInfo?.filter(
          (_, i) => i !== index,
        );
        setProductData({
          ...productData,
          nutrientInfo: filteredNutrientInfo,
        });
      }
    },
    [productData],
  );

  const onAddTag = useCallback(
    (name: string): void => {
      addTag({
        variables: {
          input: { name: name, tagType: TagType.DIETARY } as CreateTagInput,
        },
      });
    },
    [addTag],
  );

  const onChangeMeasures = useCallback(
    (prop: string, value): void => {
      const product = { ...productData };
      product.measuredBy = {
        ...product.measuredBy,
        [prop]: value,
      };
      if (prop === 'unitType') {
        if (value === UnitType.Units) {
          product.measuredBy.units = '';
        } else {
          product.measuredBy.units = unitsValues[value][0]['value'];
        }
      }

      if (prop === 'defaultSize') {
        product.measuredBy.defaultSize = parseFloat(value);
      }
      setProductData(product);
    },
    [productData],
  );

  const isUnitType =
    productData.measuredBy?.unitType &&
    productData.measuredBy?.unitType === UnitType.Units;

  const onSaveProduct = useCallback((): void => {
    if (!productData.name?.trim()) {
      showNotification({
        error: true,
        message: translate('productSettings.pleaseEnterProductName'),
      });
      return;
    }

    const nutrientInfo = [...(productData.nutrientInfo ?? [])];
    const updateProductInput = {
      id: productData.id,
      name: productData.name,
      productType: productData?.productType?.id,
      printerProfiles: productData?.printerProfiles,
      barcode: productData.barcode,
      sku: productData.sku,
      plu: productData.plu,
      gtin: productData.gtin,
      color: productData.color,
      isSellable: productData.isSellable,
      isFeatured: productData.isFeatured,
      description: productData.description,
      variableQuantity: isUnitType ? false : productData.variableQuantity,
      measuredBy: {
        units: productData.measuredBy?.units || '',
        unitType: productData.measuredBy?.unitType || UnitType.Units,
        defaultSize: productData.measuredBy?.defaultSize || 1,
      },
      dietaryTags: (productData.dietaryTags || []).map(t => t.id),
      ...(productData.allergens && {
        allergens: productData.allergens ?? [],
      }),
      recipe: productData.recipe,
      alternateNames: encodeAlternateNameValuesToBase64(
        productData.alternateNames || [],
      ),
      nutrientInfo:
        nutrientInfo?.map(info => ({
          name: info.name,
          value: +(info.value ?? 0),
          unit: info.unit,
        })) ?? [],
      ...(productData?.imageRawData && {
        imageRawData: {
          base64: productData.imageRawData.base64,
          name: productData.imageRawData.name,
          type: productData.imageRawData.type,
        },
      }),
      ...(productData.category && {
        category: productData.category,
      }),
    } as unknown as UpdateProductInput;

    if (!productData.category && originalData?.category?.id) {
      updateProductInput.removeCategoryId = originalData.category.id;
    }

    updateProduct(updateProductInput);
  }, [
    productData,
    originalData,
    updateProduct,
    isUnitType,
    showNotification,
    translate,
  ]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <GeneralForm
      isProduct
      isVariantProduct={isVariantProduct}
      onAddTag={onAddTag}
      onChange={onChange}
      onChangeMeasures={onChangeMeasures}
      onChangeAlternateName={onChangeAlternateName}
      onChangeNutrient={onChangeNutrient}
      onPressNutrientAction={onPressNutrientAction}
      onSave={onSaveProduct}
      productData={productData}
      dietaryTagsOptions={dietaryTagsArray}
      productTypeOptions={productTypeOptions}
      categoryOptions={categoryOptions}
      printerProfileOptions={printerProfileOptions}
    />
  );
};
