import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

export const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    width: 320,
    height: 44,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.teritiary1,
    ...theme.shadow.strong,
    paddingHorizontal: 10,
  },
  bodyContainer: {
    width: 320,
    padding: 20,
    marginTop: 8,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  confirmButtonContainer: {
    height: 44,
    marginTop: 30,
    backgroundColor: theme.colors.green,
  },
  titleText: {
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.colors.white,
    fontFamily: theme.fonts.semibold,
  },
});

export default styles;
