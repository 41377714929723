import { View, ViewStyle, StyleProp } from 'react-native';
import React from 'react';

export interface DataGridLayoutInputProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

const DataGridLayout: React.FC<DataGridLayoutInputProps> = ({
  children,
  style,
}: DataGridLayoutInputProps) => {
  return <View style={style}>{children}</View>;
};

export default DataGridLayout;
