/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  btn: {
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
  btnLogout: {
    backgroundColor: '#57211d',
  },
  btnSupport: {
    backgroundColor: '#16344c',
  },
});

export default styles;
