import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://73e47659bca34fe39b4257910d4ce51f@o314552.ingest.us.sentry.io/1830344',
  environment: process.env['REACT_APP_LOGGING_ENV'] || process.env.NODE_ENV,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});

console.log('init');

export default Sentry;
