/* eslint-disable react-native/no-inline-styles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { View, Text, TouchableOpacity } from 'react-native';
import theme from '../../../../../common/default-theme';
import styles from './SetDelayModal.styles';
import { PartnerChannel, DelayOrdersChannelInput } from '@oolio-group/domain';
import Icon from '../../../../../components/Icon/Icon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import { addHours, format } from 'date-fns';
import { DurationOptions } from './DurationOptions';
import { orderBy } from 'lodash';
import Message from '../../../../../components/Office/Message/Message';

interface SetDelayProps {
  oomConnectedChannels: PartnerChannel[];
  onSetDelayOrders: (updatedChannels: DelayOrdersChannelInput[]) => void;
}

const DEFAULT_DELAY_UNTIL_HOUR = 1;

const DELAY_UNTIL_FORMAT_DATE = "yyyy-MM-dd'T'HH:mm:ssXXX";

const SET_DELAY_DURATIONS_IN_MINUTES = [
  { value: 10, label: '10m' },
  { value: 15, label: '15m' },
  { value: 20, label: '20m' },
  { value: 30, label: '30m' },
  { value: 0, label: 'None' },
];

export const SetDelayModal: React.FC<SetDelayProps> = ({
  oomConnectedChannels,
  onSetDelayOrders,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [delayOrderMaps, setDelayOrderMaps] = useState<
    Record<string, DelayOrdersChannelInput>
  >({});

  const originalSettingRef = useRef<Record<string, DelayOrdersChannelInput>>(
    {},
  );

  const sortedChannelByApiSupport = useMemo(() => {
    return orderBy(
      oomConnectedChannels,
      channel => channel.delayConfig?.apiSupport,
      'desc',
    );
  }, [oomConnectedChannels]);

  const onSelectOption = useCallback((channelCode: string, value: number) => {
    const willDelayOrders = value > 0;
    const delayUntil = willDelayOrders
      ? addHours(new Date(), DEFAULT_DELAY_UNTIL_HOUR)
      : new Date();
    setDelayOrderMaps(pre => ({
      ...pre,
      [channelCode]: {
        durationInMinutes: value,
        delayUntil: format(delayUntil, DELAY_UNTIL_FORMAT_DATE),
        code: channelCode,
      },
    }));
  }, []);

  useEffect(() => {
    if (!oomConnectedChannels?.length) return;
    const delaySettingsProps = oomConnectedChannels.reduce((maps, channel) => {
      const { delayConfig } = channel;
      const isBeingDelayed = Boolean(
        delayConfig?.delayUntil &&
          new Date(delayConfig?.delayUntil) > new Date(),
      );
      const pauseOrderInput: DelayOrdersChannelInput = {
        code: channel.code,
        durationInMinutes: isBeingDelayed
          ? delayConfig?.durationInMinutes || 0
          : 0,
        delayUntil: delayConfig?.delayUntil,
      };
      maps[channel.code] = pauseOrderInput;
      return maps;
    }, {} as Record<string, DelayOrdersChannelInput>);
    originalSettingRef.current = delaySettingsProps;
    setDelayOrderMaps(delaySettingsProps);
  }, [oomConnectedChannels]);

  const handleSetPauseOrders = useCallback(() => {
    closeModal();
    const updatedChannels = Object.values(delayOrderMaps).filter(input => {
      const originalSetting = originalSettingRef.current;
      return (
        originalSetting[input?.code]?.durationInMinutes !=
        input.durationInMinutes
      );
    });
    onSetDelayOrders(updatedChannels);
  }, [closeModal, delayOrderMaps, onSetDelayOrders]);

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={closeModal}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {translate('onlineOrders.setDelay')}
        </Text>
      </View>
      <View style={styles.content}>
        <View>
          <Message
            type="neutral"
            message={translate('oomChannels.delayInfo')}
            icon="info-circle"
            containerStyle={{ marginBottom: 20 }}
          />
        </View>
        {sortedChannelByApiSupport.map(channel => {
          return (
            <View key={channel.code} style={styles.optionContainer}>
              <Text style={styles.channelName}>{channel.name}</Text>
              {channel.delayConfig?.apiSupport ? (
                <DurationOptions
                  options={SET_DELAY_DURATIONS_IN_MINUTES}
                  selectedOption={
                    delayOrderMaps[channel.code]?.durationInMinutes || 0
                  }
                  onSelectOption={onSelectOption.bind(null, channel.code)}
                />
              ) : (
                <View style={styles.notSupported}>
                  {translate('oomChannels.notSupported')}
                </View>
              )}
            </View>
          );
        })}
        <TreatButton
          type="focus"
          testID="confirm-pause-orders"
          onPress={handleSetPauseOrders}
          containerStyle={styles.btnConfirm}
          label={translate('button.confirm')}
        />
      </View>
    </View>
  );
};
