import gql from 'graphql-tag';

const menuScheduleFragment = `
  id
  isActive
  menu
  salesChannels {
    id
    name
    code
  }
  orderTypes {
    id
    name
    code
  }
  stores {
    id
    name
  }
  schedules {
    dateRange {
      startDate
      endDate
    }
    timeBlocks {
      days
      timeSlot {
        startTime
        endTime
      }
    }
  }
  createdAt
`;

export const GET_MENU_SCHEDULES_QUERY = gql`
  query menuSchedules{
    menuSchedules {
      ${menuScheduleFragment}
    }
  }
`;

export const GET_MENU_SCHEDULE_QUERY = gql`
  query menuSchedule($id: ID!) {
    menuSchedule(id: $id) {
      ${menuScheduleFragment}
    }
  }
`;

export const CREATE_MENU_SCHEDULE = gql`
  mutation createMenuSchedule($input: CatalogueScheduleItemInput!) {
    createMenuSchedule(input: $input) {
      ${menuScheduleFragment}
    }
  }
`;

export const UPDATE_MENU_SCHEDULE = gql`
  mutation updateMenuSchedule($input: CatalogueScheduleItemInput) {
    updateMenuSchedule(input: $input) {
      ${menuScheduleFragment}
    }
  }
`;

export const DELETE_MENU_SCHEDULE = gql`
  mutation deleteMenuSchedule($id: ID!) {
    deleteMenuSchedule(id: $id)
  }
`;
