import { CreateOrUpdatePageInput, Page } from '@oolio-group/domain';
import React, { useCallback, useState } from 'react';
import { View, Text, KeyboardAvoidingView } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './Modals.styles';
import theme from '../../../../../../common/default-theme';
import modalStyles from '../../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import SelectColour from '../../../../../../components/Shared/Select/SelectColour';
import InputText from '../../../../../../components/Shared/Inputs/InputText';

interface CreateAndAddNewPageProp {
  onAddExistingPage: (input: {
    pageId: string;
    color: string;
    name: string;
  }) => void;
  createPage: (
    updateMenuInput: CreateOrUpdatePageInput,
  ) => Promise<Page | undefined>;
}

const CreateAndAddNewPage: React.FC<CreateAndAddNewPageProp> = ({
  onAddExistingPage,
  createPage,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [pageName, setPageName] = useState('');
  const [selectedColor, setSelectedColor] = useState<string>(theme.colors.teal);
  const onPressAddPage = useCallback(async () => {
    closeModal();
    const createdPage = await createPage({
      name: pageName,
      color: selectedColor,
    });

    if (createdPage) {
      onAddExistingPage({
        pageId: createdPage?.id as string,
        color: createdPage.color as string,
        name: createdPage.name as string,
      });
    }
  }, [closeModal, createPage, onAddExistingPage, pageName, selectedColor]);

  const onChangeColor = useCallback((color: string) => {
    setSelectedColor(color);
  }, []);

  const disabledSaveButton = !pageName.trim();

  return (
    <View style={styles.background}>
      <KeyboardAvoidingView behavior="padding" style={styles.container}>
        <View style={modalStyles.title}>
          <Text style={styles.titleText}>
            {translate('menus.createAndAddNewPage')}
          </Text>
        </View>
        <View style={styles.content}>
          <InputText
            testID="input-name"
            title={translate('backOfficeProducts.pageName')}
            value={pageName}
            placeholder={translate('backOfficeProducts.pageName')}
            containerStyle={styles.input}
            onChangeText={setPageName}
            maxLength={50}
          />
          <SelectColour
            testID="select-colour"
            title={translate('productSettings.tileColor')}
            value={selectedColor}
            onChange={onChangeColor}
            containerStyle={styles.input}
          />
        </View>
        <View style={styles.actions}>
          <TreatButton
            type="neutral"
            testID="btn-confirm"
            onPress={onPressAddPage}
            disabled={disabledSaveButton}
            label={translate('menus.createAndAddToMenu')}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginBottom: 10 }}
          />
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default CreateAndAddNewPage;
