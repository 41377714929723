import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintTestTemplateWorkerInput,
  WorkerInput,
} from '../../utils';
import { getPrintableBuffer } from '../../../utils/printerTemplates/testPrintTemplate';

const printTestTemplateHandler = (
  message: WorkerInput,
): BufferHandlerResult[] => {
  const { session, printer, devices, template } =
    message.data as PrintTestTemplateWorkerInput;

  const printerData = { ...printer };
  printerData.store = {
    ...printerData.store,
    devices: Object.values(devices),
  };
  const buffer = getPrintableBuffer(
    printerData,
    session.currentStore?.name || session.currentVenue?.name || '',
    template,
  );

  return [
    {
      printer: {
        id: printer.id,
        device_name: printer.name,
        host: printer.ipAddress,
        port: DEFAULT_NET_PRINTER_PORT,
        type: printer.printerType,
        bdAddress: printer.bdAddress,
        slug: printer.slug,
        series: printer.series,
        brand: printer.brand,
      },
      buffer,
    },
  ];
};
export default printTestTemplateHandler;
