import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Role, Venue } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import {
  StoreUserRoleMapping,
  StoreUserRoleMappingByRole,
  userRoleInputUtility,
} from '../userRoleInputUtility';
import { useNavigation } from '@react-navigation/native';
import theme from '../../../../../../../common/default-theme';
import styles from './UserPermissionRow.styles';
import ButtonIcon from '../../../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import SelectLocation, {
  OptionsSectionProps,
  SelectLocationOptionsProps,
} from '../../../../../../../components/Shared/Select/SelectLocation';

interface Props {
  rolesById: Record<string, Role>;
  venues: Record<string, Venue>;
  userRoleGroup: StoreUserRoleMappingByRole;
  deleteUserRoles: (ids: string[]) => void;
  isOwner: boolean;
  userId: string;
}

const UserPermissionRow: FC<Props> = ({
  rolesById,
  venues,
  userRoleGroup,
  deleteUserRoles,
  isOwner,
  userId,
}) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const [selectedRole, setSelectedRole] = useState(userRoleGroup.roleId);
  const [roleOptions, setRoleOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [venuesOptionsList, setVenuesOptionsList] = useState<
    SelectLocationOptionsProps[]
  >([]);

  useEffect(() => {
    setVenuesOptionsList(
      Object.values(venues).map(venue => {
        const checkedStores = userRoleGroup.locations.map(
          location => location.storeId,
        );
        const hasAccessToAllStores = venue.stores.every(store =>
          checkedStores.includes(store.id),
        );
        return {
          id: venue.id,
          label: venue.name,
          checked: hasAccessToAllStores,
          data: venue.stores.map(store => ({
            id: store.id,
            label: store.name,
            checked: checkedStores.includes(store.id),
          })),
        };
      }),
    );
  }, [venues, userRoleGroup]);

  useEffect(() => {
    setRoleOptions(
      Object.values(rolesById).map(role => ({
        value: role.id,
        label: role.name,
      })),
    );
  }, [rolesById]);

  const onChangeRole = useCallback(
    (roleId: string) => {
      setSelectedRole(roleId);
      userRoleInputUtility.modifyRole(userRoleGroup.id, roleId);
    },
    [userRoleGroup.id],
  );

  const onDelete = useCallback(() => {
    deleteUserRoles([userRoleGroup.id]);
  }, [deleteUserRoles, userRoleGroup.id]);

  const onChangeLocation = useCallback(
    (sections: OptionsSectionProps[]) => {
      const selectedLocations = sections.reduce<StoreUserRoleMapping[]>(
        (acc, venue) => {
          const locations = venue.data
            .filter(store => store.checked === true)
            .map(store => {
              return {
                venueId: venue.id,
                storeId: store.id,
              };
            });
          return [...acc, ...locations];
        },
        [],
      );
      userRoleInputUtility.modifyStores(userRoleGroup.id, selectedLocations);
    },
    [userRoleGroup.id],
  );

  const navigateToOverride = useCallback(() => {
    navigation.navigate('UserRoleTabs', {
      screen: 'CustomizeUserRole',
      params: {
        userRoleId: userRoleGroup.id,
        userId,
      },
    });
  }, [navigation, userRoleGroup.id, userId]);

  return (
    <View testID="userPermissionRow" style={theme.tables.row}>
      <TreatPicker
        testID="select-role"
        options={roleOptions}
        selectedValue={selectedRole}
        onValueChange={onChangeRole}
        containerStyle={styles.cellJobRole}
      />
      <SelectLocation
        options={venuesOptionsList}
        selectedValue={selectedRole}
        onValueChange={onChangeLocation}
        containerStyle={styles.cellLocation}
        sectionHeaderLabelSuffix={`(${translate('backOfficeUsers.allStores')})`}
      />
      {!isOwner && (
        <>
          <ButtonIcon
            icon="setting"
            type="neutralLight"
            testID="userRoleSettings"
            onPress={navigateToOverride}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginRight: 10 }}
          />
          <ButtonIcon
            icon="trash-alt"
            type="negativeLight"
            testID="deleteUserRolesButton"
            onPress={onDelete}
          />
        </>
      )}
    </View>
  );
};

export default UserPermissionRow;
