import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import styles from './Empty.styles';
import EmptyIcon from './Empty.icon';

interface Props {
  label: string;
  containerStyles?: ViewStyle | ViewStyle[];
}

const Empty: React.FC<Props> = ({ label, containerStyles }) => {
  return (
    <View style={[styles.container, containerStyles]}>
      <EmptyIcon containerStyle={styles.icon} />
      <Text style={styles.text}>{label}</Text>
    </View>
  );
};

export default Empty;
