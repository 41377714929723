import { gql } from '@apollo/client';

const categoryFragment = `
id
name
createdAt
products {
  id
  name
}
variants {
  id
  name
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const productsFragment = `
id
name
category {
  id
  name
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const variantsFragment = `
id
name
products {
  id
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
category {
  id
  name
}
`;

export const getCategoriesQuery = (fragment?: string) => {
  const GET_CATEGORIES_QUERY = gql`
  query getCategories {
    categories {
      ${fragment || categoryFragment}
    }
  }
`;

  const GET_CATEGORY_QUERY = gql`
  query Category($categoryId: ID!) {
    category(id: $categoryId) {
      ${fragment || categoryFragment}
    }
  }
`;

  const GET_CATEGORIZED_ITEMS_QUERY = gql`
    query getCategorizedItems {
      categorizedItems
    }
  `;
  return {
    GET_CATEGORIES_QUERY,
    GET_CATEGORY_QUERY,
    GET_CATEGORIZED_ITEMS_QUERY,
  };
};

export const CREATE_OR_UPDATE_CATEGORY = gql`
  mutation CreateOrUpdateCategoryInput($input: CreateOrUpdateCategoryInput) {
    createOrUpdateCategory(input: $input) {
      ${categoryFragment}
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;

export const BATCH_UPDATE_CATEGORIES = gql`
  mutation updateCategories($input: [BatchUpdateCategoriesInput]) {
    batchUpdateCategories(input: $input) {
      ${categoryFragment}
    }
  }
`;

// CUSTOM CATEGORY FRAGMENT

export const CATEGORIES_AS_OPTIONS = `
id
name
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const CATEGORIES_WITH_ENTITY_IDS = `
  id
  name
  products {
    id
  }
  variants {
    id
  }
`;

export const CATEGORIES_WITH_ENTITY_VARIANT_PRODUCTS = `
  id
  name
  products {
    id
    name
  }
  variants {
    id
    name
    products {
      id
    }
  }
`;
