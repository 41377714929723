import React from 'react';
import { Text, View } from 'react-native';
import styles from './TrainingModeBanner.styles';
import { useTranslation } from '@oolio-group/localization';

export const TrainingModeBanner = () => {
  const { translate } = useTranslation();
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {translate('trainingMode.enableTrainingMode')}
      </Text>
    </View>
  );
};
