import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Printer } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from '../PrintingQueue.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import TreatPicker from '../../../../components/Shared/Select/Picker';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';

export interface ReprintModalProps {
  printerId?: string;
  printers: Printer[];
  onSubmit: (printerId: string) => void;
}

const ReprintModal: React.FC<ReprintModalProps> = ({
  printerId,
  printers,
  onSubmit,
}: ReprintModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [formState, setFormState] = useState(printerId || '');

  const printerOptions = useMemo(() => {
    return (printers || []).map(printer => ({
      label: printer.name,
      value: printer.id,
    }));
  }, [printers]);

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onSubmitForm = useCallback(() => {
    closeModal();
    onSubmit && onSubmit(formState);
  }, [closeModal, onSubmit, formState]);

  return (
    <View style={styles.modal}>
      <View style={styles.modalTitle}>
        <TouchableOpacity style={styles.modalBtnClose} onPress={onCloseModal}>
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.modalTitleText}>
          {translate('printing.reprint')}
        </Text>
      </View>
      <View style={styles.modalBody}>
        <TreatPicker
          testID="select-printer"
          title={translate('printing.selectPrinter')}
          options={printerOptions}
          selectedValue={formState}
          onValueChange={setFormState}
          containerStyle={styles.modalDropdown}
        />
        <TreatButton
          testID="btn-confirm"
          type="neutral"
          label={translate('printing.reprint')}
          onPress={onSubmitForm}
        />
      </View>
    </View>
  );
};

export default ReprintModal;
