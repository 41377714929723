import { makeVar } from '@apollo/client';

export interface PrinterStatus {
  printerAddress: string;
  printerId: string;
  responseSuccess?: string;
  responseCode?: string;
  responseStatus?: string;
}

export const reachablePrintersVar = makeVar<Record<string, PrinterStatus>>({});
export const unreachablePrintersVar = makeVar<Record<string, PrinterStatus>>(
  {},
);
