import React, { useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import {
  getAddressFields,
  AddressFields,
  useTranslation,
  getAddressPlaceholder,
} from '@oolio-group/localization';
import InputText from '../Inputs/InputText';
import TreatPicker from '../Select/Picker';
import { isValidPostCode } from '../../../utils/validator';
import { Address } from '@oolio-group/domain';
import SelectAddress from '../Select/SelectAddress';

export interface AddressFormProps {
  onChangeAddress: (keyName: string, value: Address) => void;
  values?: Address;
  excludedFields?: AddressFields[];
  children?: React.ReactNode;
  keyName: string;
  targetCountry?: string;
}

const AddressForm: React.FC<AddressFormProps> = ({
  onChangeAddress,
  values,
  excludedFields = [],
  children,
  keyName,
  targetCountry,
}: AddressFormProps) => {
  const country = values?.isoCountryCode ?? 'AU';
  const addressFields = getAddressFields(country).filter(
    (field: AddressFields) => !excludedFields.includes(field),
  );
  const { translate } = useTranslation();
  const addressHint = getAddressPlaceholder(country);

  const onChange = useCallback(
    (addressKey: keyof Address, value: string) => {
      onChangeAddress(keyName, {
        ...values,
        [addressKey]: value,
      } as Address);
    },
    [keyName, onChangeAddress, values],
  );

  const onSelectAddress = useCallback(
    (address: Address, timezone?: string) => {
      if (address) {
        onChangeAddress(keyName, { ...address, timezone });
      }
    },
    [keyName, onChangeAddress],
  );

  const showSearchAddress = useMemo(() => {
    return !!addressFields.find(item => item == AddressFields.SEARCH);
  }, [addressFields]);

  const renderAddressFields = useCallback(
    (field: AddressFields) => {
      switch (field) {
        case AddressFields.LINE1:
          return (
            <InputText
              testID="input-line1"
              title={translate('form.addressLine1')}
              value={values?.line1 || ''}
              placeholder={translate('form.addressLine1')}
              onChangeText={onChange.bind(null, 'line1')}
              containerStyle={theme.forms.inputHalf}
            />
          );

        case AddressFields.LINE2:
          return (
            <InputText
              testID="input-line2"
              title={translate('form.addressLine2')}
              value={values?.line2 || ''}
              placeholder="Optional"
              onChangeText={onChange.bind(null, 'line2')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.CITY:
          return (
            <InputText
              testID="input-city"
              title={translate('form.city')}
              value={values?.city || ''}
              placeholder={translate('form.city')}
              onChangeText={onChange.bind(null, 'city')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.SUBURB:
          return (
            <InputText
              testID="input-suburb"
              title={translate('form.suburb')}
              value={values?.suburb || ''}
              placeholder={translate('form.suburb')}
              onChangeText={onChange.bind(null, 'suburb')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.STATE:
          return (
            <InputText
              testID="input-state"
              title={translate('form.state')}
              value={values?.state || ''}
              placeholder={translate('form.state')}
              onChangeText={onChange.bind(null, 'state')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.COUNTY:
          return (
            <InputText
              testID="input-county"
              title={translate('form.county')}
              value={values?.state || ''}
              placeholder={translate('form.county')}
              onChangeText={onChange.bind(null, 'state')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.CODE:
          return (
            <InputText
              testID="input-code"
              title={translate('form.code')}
              value={values?.postalCode}
              placeholder={translate('form.code')}
              errorMessage={
                values?.postalCode &&
                !isValidPostCode(values?.postalCode, values?.country)
                  ? translate('form.invalidInput', {
                      title: translate('form.code'),
                    })
                  : undefined
              }
              onChangeText={onChange.bind(null, 'postalCode')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.COUNTRY:
          return (
            <TreatPicker
              testID="select-country"
              title={translate('form.country')}
              selectedValue={values?.isoCountryCode || ''}
              options="countries"
              onValueChange={code => onChange('isoCountryCode', code)}
              containerStyle={theme.forms.inputHalf}
            />
          );

        default:
          break;
      }
    },
    [translate, values, onChange],
  );

  return (
    <View>
      {showSearchAddress && (
        <View style={theme.forms.row}>
          <SelectAddress
            title="Search Address"
            testID="business-address"
            placeholder={addressHint}
            onChangeAddress={onSelectAddress}
            address={values as Address}
            containerStyle={theme.forms.inputFluid}
            targetCountry={targetCountry}
          />
        </View>
      )}
      <View style={styles.container}>
        {addressFields.map((x: AddressFields) => renderAddressFields(x))}
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    zIndex: -1,
    gap: 20,
    marginBottom: 20,
  },
});

export default AddressForm;
