import React from 'react';
import {
  View,
  Text,
  ViewStyle,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';
import styles, { getTitleStyle, getValueStyle } from './SettingsOptions.styles';

export interface SettingsIconProps {
  title: string;
  icon?: string;
  iconSecondary?: string;
  value?: string;
  testID: string;
  onPress?: () => void;
  iconColor?: string;
  loading?: boolean;
  error?: string;
}

export interface SettingsToggleProps {
  title: string;
  testID: string;
  isToggled: boolean;
  onToggle: () => void;
}

export interface SettingsSelectProps {
  title: string;
  testID: string;
  isSelected: boolean;
  onPress: () => void;
}

export interface SettingsActionProps {
  label: string;
  testID: string;
  loading?: boolean;
  type: 'positive' | 'neutral' | 'focus' | 'negative';
  onPress: () => void;
  containerStyle?: ViewStyle;
}

export const SettingsIcon: React.FC<SettingsIconProps> = ({
  title,
  icon,
  value,
  testID,
  onPress,
  iconColor,
  loading,
  error,
  iconSecondary,
}) => {
  return (
    <TouchableOpacity
      testID={testID}
      style={styles.container}
      onPress={onPress}
    >
      <View style={styles.content}>
        <Text style={getTitleStyle(error)}>{title}</Text>
        <View style={styles.disclosure}>
          {!!value ? (
            <Text style={getValueStyle(error, value)}>{value}</Text>
          ) : null}
          {error ? (
            <Icon
              size={20}
              name="exclamation-triangle"
              color={theme.colors.states.negative}
            />
          ) : null}
          {iconSecondary ? (
            <Icon
              size={20}
              name={iconSecondary}
              color={theme.colors.states.neutral}
            />
          ) : null}
          {!!icon ? (
            <View style={styles.icon}>
              {!loading ? (
                <Icon
                  size={24}
                  name={icon || 'angle-right'}
                  color={iconColor ? iconColor : theme.colors.dark}
                />
              ) : (
                <ActivityIndicator size="small" color={theme.colors.grey6} />
              )}
            </View>
          ) : null}
        </View>
      </View>
      {error ? (
        <View style={styles.errorView}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

export const SettingsToggle: React.FC<SettingsToggleProps> = ({
  title,
  testID,
  isToggled,
  onToggle,
}) => {
  return (
    <TouchableOpacity
      testID={testID}
      onPress={onToggle}
      style={styles.container}
    >
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.icon}>
          <Icon
            size={24}
            name={isToggled ? 'toggle-on' : 'toggle-off'}
            color={isToggled ? theme.colors.green : theme.colors.grey5}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const SettingsSelect: React.FC<SettingsSelectProps> = ({
  title,
  testID,
  isSelected,
  onPress,
}) => {
  return (
    <TouchableOpacity
      testID={testID}
      style={styles.container}
      onPress={onPress}
    >
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        {isSelected ? (
          <View style={styles.icon}>
            <Icon size={20} name="check" color={theme.colors.states.positive} />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

export const SettingsAction: React.FC<SettingsActionProps> = ({
  testID,
  label,
  type,
  loading,
  onPress,
  containerStyle,
}) => {
  const getLabelColor = () => {
    switch (type) {
      case 'positive':
        return theme.colors.states.positive;
      case 'neutral':
        return theme.colors.states.neutral;
      case 'focus':
        return theme.colors.states.focus;
      case 'negative':
        return theme.colors.states.negative;
      default:
        return theme.colors.dark;
    }
  };

  return (
    <TouchableOpacity
      testID={testID}
      style={[containerStyle, styles.action]}
      disabled={loading}
      onPress={onPress}
    >
      {!loading ? (
        <Text style={[styles.actionLabel, { color: getLabelColor() }]}>
          {label}
        </Text>
      ) : (
        <ActivityIndicator size="small" color={theme.colors.grey6} />
      )}
    </TouchableOpacity>
  );
};
