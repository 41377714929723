import { Dimensions, Platform } from 'react-native';
const { width: viewportWidth, height: viewportHeight } =
  Dimensions.get('window');

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
// const guidelineBaseHeight = 680;

const scale = (size: number) => (viewportWidth / guidelineBaseWidth) * size;
export const isWeb = Platform.OS === 'web';
export const isAndroid = Platform.OS === 'android';
export const isIos = Platform.OS === 'ios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const moderateScale = (size: number, factor = 0.5) =>
  isWeb
    ? size * 1.4
    : isAndroid
    ? size + (scale(size) - size) * 0.35
    : size + (scale(size) - size) * 0.4;

const textInputWidth180 = isWeb ? 260 : '90%';

const backOfficeWidth400 = isWeb ? 540 : isAndroid ? '97%' : '100%';

const deviceWidth = viewportWidth;
const deviceHeight = viewportHeight;

const theme = {
  moderateScale,
  textInputWidth180,
  backOfficeWidth400,
  deviceWidth,
  deviceHeight,
};

export default theme;
