import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Shift, ShiftStatus } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { format } from 'date-fns';
import { capitalCase } from 'change-case';
import styles from './ShiftsTable.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

interface ShiftsTableProps {
  data: Shift[];
  showVariance?: boolean;
  onPressRow: (shift: Shift) => void;
  onPressPrint: (shift: Shift) => void;
}

const ShiftsTable: FC<ShiftsTableProps> = ({
  data,
  showVariance,
  onPressRow,
  onPressPrint,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const pageItems = useMemo(() => {
    return data?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [data, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const onPressShiftRow = useCallback(
    (shift: Shift) => {
      const isOpen = shift.shiftStatus === ShiftStatus.OPEN;
      analyticsService.capture('shift_event', {
        event: 'Print',
        location: isOpen ? 'Active' : 'History',
      });
      onPressRow(shift);
    },
    [onPressRow],
  );

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.cellStatus]}>
          {translate('shift.status')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellNo]}>
          {translate('shift.number')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDate]}>
          {translate('shift.createdAt')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDate]}>
          {translate('shift.closedAt')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDevice]}>
          {translate('shift.closingDevice')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellName]}>
          {translate('shift.closedBy')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellAmount]}>
          {translate('salesDashboard.SalesAmount')}
        </Text>
        {showVariance ? (
          <Text style={[theme.tables.headerText, styles.headerVariance]}>
            {translate('backOfficeShifts.variance')}
          </Text>
        ) : null}
        <View style={styles.headerGap} />
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((shift, i) => {
            const isOpen = shift.shiftStatus === ShiftStatus.OPEN;
            const textColour = {
              color: isOpen ? theme.colors.grey5 : theme.colors.black1,
            };

            return (
              <TouchableOpacity
                key={i}
                testID="shift-row"
                style={styles.tableRow}
                onPress={() => onPressShiftRow(shift)}
              >
                <Sticker
                  testID="sticker-status"
                  label={shift.shiftStatus}
                  type={isOpen ? 'positive' : 'cancel'}
                  containerStyle={styles.cellStatus}
                />
                <Sticker
                  testID="sticker-shiftNo"
                  type="cancel"
                  label={shift.shiftNumber}
                  containerStyle={styles.cellNo}
                />
                <Text testID="shift-createdAt" style={styles.cellDate}>
                  {format(shift.createdAt, 'dd/MM hh:mm a')}
                </Text>
                <Text
                  testID="shift-closedAt"
                  style={[styles.cellDate, textColour]}
                >
                  {isOpen ? 'N/A' : format(shift.closedAt, 'dd/MM hh:mm a')}
                </Text>
                <Text
                  testID="shift-closingDevice"
                  numberOfLines={1}
                  style={[styles.cellDevice, textColour]}
                >
                  {shift.closedByDevice ? shift.closedByDevice.name : 'N/A'}
                </Text>
                <Text
                  testID="shift-closingUser"
                  numberOfLines={1}
                  style={[styles.cellName, textColour]}
                >
                  {isOpen
                    ? 'N/A'
                    : capitalCase(shift.closedBy?.name || 'Unknown')}
                </Text>
                <Text testID="shift-salessAmount" style={styles.cellAmount}>
                  {formatCurrency(shift.totalSalesAmount)}
                </Text>
                {showVariance ? (
                  <Text
                    testID="shift-variance"
                    style={[
                      styles.cellAmount,
                      {
                        color:
                          shift.difference < 0
                            ? theme.colors.red
                            : isOpen
                            ? theme.colors.grey5
                            : theme.colors.black1,
                      },
                    ]}
                  >
                    {isOpen ? 'N/A' : formatCurrency(shift.difference)}
                  </Text>
                ) : null}
                <ButtonIcon
                  icon="print"
                  testID="btn-print"
                  type="neutralLight"
                  onPress={() => onPressPrint(shift)}
                  containerStyle={styles.cellPrint}
                />
                <View style={theme.tables.disclosure}>
                  <Icon
                    size={20}
                    name="angle-right"
                    color={theme.colors.grey5}
                  />
                </View>
              </TouchableOpacity>
            );
          })
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('shift.emptySearch')}
            </Text>
          </View>
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={currentPage}
          onPageChange={setCurrentPage}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default ShiftsTable;
