import React from 'react';
import { useRoute } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useTranslation } from '@oolio-group/localization';
import TabBar from '../../../../../components/TabBar/TabBar';
import { VariantGroup } from './VariantGroup';

const Tab = createMaterialTopTabNavigator();

export interface VariantGroupParams {
  id: string;
  name: string;
}

const VariantGroupTabs: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const params = route.params as VariantGroupParams;

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} />
      )}
      initialRouteName="VariantGroup"
    >
      <Tab.Screen
        name="VariantGroup"
        component={VariantGroup}
        options={{
          tabBarLabel: params?.name || translate('variants.variantGroup'),
        }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default VariantGroupTabs;
