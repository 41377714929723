import { StyleSheet, Platform } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    alignSelf: 'center',
    paddingBottom: Platform.select({ web: 380, default: 0 }),
  },
  content: {
    padding: 30,
    marginTop: 8,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  btnConfirm: {
    marginTop: 20,
  },
});

export default styles;
