import { gql } from '@apollo/client';

export const EXPORT_PRODUCT = gql`
  mutation exportProducts {
    exportProducts
  }
`;

export const EXPORT_CUSTOMERS = gql`
  mutation exportCustomers {
    exportCustomers
  }
`;

export const GET_EXPORT_ENTITY_DETAIL = gql`
  query getExportEntityDetail($id: String!) {
    getExportEntityDetail(id: $id) {
      id
      successCount
      failedCount
      createdAt
      fileUrl
      status
    }
  }
`;
