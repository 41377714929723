import React, { useCallback, useMemo, useState } from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/SalesDashboard.styles';
import { View, ScrollView, ViewStyle } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  DateRangeGranularity,
  Filters,
  WALK_IN_CUSTOMER,
  ColumnType,
} from '@oolio-group/domain';
import { SalesDashboardHeader } from './UIComponents/SalesDashboardHeader';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import { PieChart } from '../UIComponents/PieChart';
import { LineChart, LineChartData } from '../UIComponents/LineChart';
import { BarChart } from '../UIComponents/BarChart';
import {
  DEFAULT_CARD_WRAPPER,
  DEFAULT_CHART_WRAPPER,
  getHourRangeIn12HrsFormat,
} from '../reportsHelper';
import {
  LineChartType,
  PivotTableData,
  HelperText,
  DropDownFilter,
  CardComponentData,
  IMap,
} from '../types';
import { format } from 'date-fns-tz';
import { CubejsApi } from '@cubejs-client/core';
import { MetricCardWrapper } from '../UIComponents/MetricCard';
import { InfoCard } from '../UIComponents/InfoCard';
import { useCurrency } from '@oolio-group/localization';
import orderBy from 'lodash/orderBy';
import { ShiftVarianceMetric } from './UIComponents/ShiftVarianceMetric';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

export const SalesDashboard: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  updateFilters,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const defaultWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.LINE) ||
    ({} as Widget);

  const dateRangeFilters = useMemo(() => {
    if (defaultWidget.query?.dateRangeFilters) {
      return defaultWidget.query?.dateRangeFilters as DateRangeFilter[];
    } else {
      return [];
    }
  }, [defaultWidget.query]);

  const defaultGranularity: DateRangeGranularity =
    (defaultWidget &&
      defaultWidget.query &&
      dateRangeFilters.length > 0 &&
      dateRangeFilters[0].granularity) ||
    DateRangeGranularity.HOUR;

  const tableTimeZone = defaultWidget.query?.timezone;

  const groupDataByGranularity = useCallback(
    (
      pivotTableData: PivotTableData,
      timeKey: string,
      singleMetric?: string,
    ) => {
      const hourBasedArr: number[] = [];
      const hourBasedMap: IMap<number>[] = [];

      pivotTableData.forEach(rowData => {
        const date = new Date(rowData[timeKey] as string);
        const hour = parseInt(format(date, 'H', { timeZone: tableTimeZone }));

        if (singleMetric) {
          if (hourBasedArr[hour] === undefined) hourBasedArr[hour] = 0;

          if (rowData[singleMetric])
            hourBasedArr[hour] = (hourBasedArr[hour] +
              parseFloat(`${rowData[singleMetric]}`)) as number;
        } else {
          for (const key in rowData) {
            if (key !== timeKey) {
              if (hourBasedMap[hour] === undefined)
                hourBasedMap[hour] = {} as IMap<number>;
              if (hourBasedMap[hour][key] === undefined)
                hourBasedMap[hour][key] = 0;

              if (typeof rowData[key] === 'string') {
                hourBasedMap[hour][key] =
                  hourBasedMap[hour][key] + parseFloat(`${rowData[key]}`);
              }
            }
          }
        }
      });

      function mapHourData(data: number | IMap<number>, index: number) {
        const { start, end } = getHourRangeIn12HrsFormat(index);
        return {
          x: `${start} - ${end}`,
          y: data,
        };
      }

      if (singleMetric) {
        return hourBasedArr.map(mapHourData);
      } else {
        return hourBasedMap.map(mapHourData);
      }
    },
    [tableTimeZone],
  );

  const lineDataTransformationFn = useCallback(
    (pivotTableData: PivotTableData, widgetName: string): LineChartData[] => {
      let toReturn: LineChartData[] = [];

      if (widgetName && pivotTableData.length > 0) {
        const timeKey = `${dateRangeFilters[0].key}.${defaultGranularity}`;
        toReturn = [
          groupDataByGranularity(
            pivotTableData,
            timeKey,
            'Orders.salesTotalRevenue',
          ) as LineChartData,
        ];
      }

      return toReturn;
    },
    [dateRangeFilters, groupDataByGranularity, defaultGranularity],
  );

  const cardDataTransformationFn = useCallback(
    (pivotTableData: PivotTableData, widgetName: string): CardComponentData => {
      let title = '',
        revenue = '',
        orders = '';

      switch (widgetName) {
        case 'Best Customer':
          let customerIndex = 0;

          if (
            pivotTableData.length > 1 &&
            pivotTableData?.[0]?.['Orders.customerName'] === WALK_IN_CUSTOMER
          ) {
            customerIndex = 1;
          }

          title = pivotTableData?.[customerIndex]?.[
            'Orders.customerName'
          ] as string;
          revenue = pivotTableData?.[customerIndex]?.[
            'Orders.salesTotalRevenue'
          ] as string;
          orders = pivotTableData?.[customerIndex]?.[
            'Orders.salesCount'
          ] as string;
          break;
        case 'Best Employee':
          title = pivotTableData?.[0]?.['Orders.createdByName'] as string;
          revenue = pivotTableData?.[0]?.['Orders.salesTotalRevenue'] as string;
          orders = pivotTableData?.[0]?.['Orders.salesCount'] as string;
          break;
        case 'Best Hour':
          const processed = groupDataByGranularity(
            pivotTableData,
            `${dateRangeFilters[0].key}.${defaultGranularity}`,
          ) as { x: string; y: IMap<number> }[];

          const ordered = orderBy(
            processed.filter(
              item => item !== undefined && item.y !== undefined,
            ),
            [
              item => item.y['Orders.salesTotalRevenue'],
              item => item.y['Orders.salesCount'],
            ],
            ['desc', 'asc'],
          );

          if (ordered.length > 0) {
            title = ordered[0].x;
            revenue = ordered[0].y['Orders.salesTotalRevenue'].toString();
            orders = ordered[0].y['Orders.salesCount'].toString();
          }
          break;
      }

      return {
        title,
        value: `${appendCurrency(revenue || '0.00')} (${orders || '0'})`,
      } as CardComponentData;
    },
    [
      appendCurrency,
      dateRangeFilters,
      defaultGranularity,
      groupDataByGranularity,
    ],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <SalesDashboardHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              updateFilters={updateFilters}
              filters={filters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
            ></SalesDashboardHeader>
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets.map((widget, widgetIndex) => {
                  let renderComp;

                  const width = widget.width || '100%';

                  const dateRangeFilters = widget.query
                    .dateRangeFilters as DateRangeFilter[];
                  const granularity = dateRangeFilters[0].granularity || '';

                  const chartWidget = { ...widget, width: '100%' };

                  switch (widget.chartType) {
                    case WidgetChartType.METRIC:
                      renderComp = (
                        <>
                          <MetricCardWrapper
                            widget={widget}
                            helper={HelperText.SALES_DASHBOARD}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                          <ShiftVarianceMetric
                            filters={widget.query.filters}
                            dateRangeFilters={
                              widget.query.dateRangeFilters?.[0]
                            }
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        </>
                      );
                      return renderComp;
                    case WidgetChartType.PIE:
                      const pieKeys = {
                        name: widget.query.dimensions[0].key,
                        value: widget.query.measures[0].key,
                      };
                      renderComp = (
                        <PieChart
                          widget={chartWidget}
                          helper={HelperText.SALES_DASHBOARD}
                          keys={pieKeys}
                          generateTotal={widget.name === 'Payment Type Usage'}
                          columnType={widget.query.measures[0]?.type}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                        />
                      );
                      break;
                    case WidgetChartType.BAR:
                      const barKeys = {
                        name: widget.query.dimensions[0].key,
                        value: widget.query.measures[0].key,
                      };

                      renderComp = (
                        <BarChart
                          widget={chartWidget}
                          helper={HelperText.PRODUCTS_SUMMARY}
                          keys={barKeys}
                          widgetName={widget.name}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                          columnType={widget.query.measures[0].type}
                        />
                      );
                      break;
                    case WidgetChartType.LINE:
                      const lineKeys = widget.query.measures.map(measure => ({
                        name: `${dateRangeFilters[0].key}.${granularity}`,
                        value: measure.key,
                        type: (measure.type || ColumnType.NUMBER) as ColumnType,
                      }));
                      renderComp = (
                        <LineChart
                          widget={chartWidget}
                          helper={HelperText.SALES_SUMMARY}
                          keys={lineKeys}
                          type={LineChartType.BASIC}
                          cubejsApi={cubejsApi}
                          updateCount={updateCount}
                          dataTransformationFn={lineDataTransformationFn}
                          columnType={widget.query.measures[0].type}
                        />
                      );
                      break;
                    case WidgetChartType.CARD:
                      return (
                        <View
                          key={`widget-${widgetIndex}`}
                          testID={`widget-${widgetIndex}`}
                          style={
                            { ...DEFAULT_CARD_WRAPPER, width } as ViewStyle
                          }
                        >
                          <InfoCard
                            widget={widget}
                            helper={HelperText.SALES_DASHBOARD}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                            dataTransformationFn={cardDataTransformationFn}
                          />
                        </View>
                      );
                  }

                  if (renderComp) {
                    return (
                      <View
                        key={`widget-${widgetIndex}`}
                        testID={`widget-${widgetIndex}`}
                        style={{ ...DEFAULT_CHART_WRAPPER, width } as ViewStyle}
                      >
                        {renderComp}
                      </View>
                    );
                  }
                })}
              </>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default SalesDashboard;
