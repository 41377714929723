import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {
  DEFAULT_ENTITY_ID,
  Page,
  AddOrUpdatePageItemInput,
  LOCALE,
  AlternateName,
  EntityType,
  PageItem,
  CatalogueItemInput,
  Category,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import { useNotification } from '../../../../../hooks/Notification';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import {
  difference,
  isEqual,
  keyBy,
  last,
  maxBy,
  omit,
  sortBy,
  uniq,
} from 'lodash';
import { sentenceCase } from 'change-case';
import {
  productsFragment,
  variantsFragment,
} from '../../../../../hooks/app/categories/graphql';
import { useCategories } from '../../../../../hooks/app/categories/useCategories';
import { updateAlternateNamesWithNewValue } from '../../../../../utils/AlternateNameHelper';
import {
  decodeAlternateNameValuesToBase64,
  encodeAlternateNameValuesToBase64,
} from '@oolio-group/client-utils';
import { v4 as uuidV4 } from 'uuid';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import theme from '../../../../../common/default-theme';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { ProductRow } from './ProductRow/ProductRow';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import SelectColour from '../../../../../components/Shared/Select/SelectColour';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import SelectSearch, {
  OptionsType,
} from '../../../../../components/Shared/Select/SelectSearch';
import { AlternateNamesSection } from '../../ProductSettings/General/AlternateNamesSection';

interface PageInfo {
  name: string;
  color: string;
  id?: string;
  categories: string[];
}

const pageColorOptions = theme.colors.pools.allColours.map(option => ({
  label: option.name,
  value: option.color,
  color: option.color,
}));

const PageDetail: React.FC = () => {
  const { translate } = useTranslation();
  const params = useRoute().params as {
    pageId: string;
    name: string;
    menuId: string;
  };

  const navigation = useNavigation();
  const { pageId, menuId } = params;
  const isFocused = useIsFocused();
  const { showNotification } = useNotification();
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    color: pageColorOptions[0].value,
    name: '',
    categories: [],
  });
  const { showModal, closeModal } = useModal();

  const [assignedPageItems, setAssignedPageItems] = useState<PageItem[]>([]);
  const [alternateNames, setAlternateNames] = useState<AlternateName[]>([]);

  const currentMaxPriority = useRef(-1);
  const originalPage = useRef<Page>({} as Page);
  const removePageItemIdsRef = useRef<string[]>([]);
  const isCreatingNewPage = pageId === DEFAULT_ENTITY_ID;

  const {
    deletePage,
    pages: pageMaps,
    error: pageErr,
    loading: pageLoading,
    getPages,
    createOrUpdatePage,
  } = usePages();

  const {
    updateMenu,
    getMenu,
    menus: menuMaps,
    loading: catalogueLoading,
  } = useMenus();

  const {
    products: productMaps,
    getAllProducts,
    loading: prodLoading,
  } = useProducts(undefined, productsFragment);

  const {
    variants: variantMaps,
    getAllVariants,
    loading: varLoading,
  } = useVariants(undefined, variantsFragment);

  const menu = menuMaps[menuId];

  const loading = pageLoading || varLoading || prodLoading || catalogueLoading;

  const { getCategories, categoryMaps } = useCategories({
    fetchPolicy: 'cache-and-network',
  });

  const variantProductMaps = useMemo(
    () =>
      keyBy(
        Object.values(variantMaps).flatMap(variant => variant.products),
        'id',
      ),
    [variantMaps],
  );

  const allPageItemMaps = useMemo(() => {
    return Object.assign({}, pageMaps, productMaps, variantMaps);
  }, [pageMaps, productMaps, variantMaps]);

  const allPageItems = useMemo<OptionsType[]>(() => {
    const normalProductIds = Object.keys(productMaps).filter(
      productId => !variantProductMaps[productId],
    );
    const allProducts = normalProductIds.map(productId => ({
      title: productMaps[productId].name,
      value: productId,
      subtitle: EntityType.Product,
    }));

    const allVariants = Object.values(variantMaps).map(variant => ({
      title: variant.name,
      value: variant.id,
      subtitle: EntityType.Variant,
    }));

    const allPages = Object.values(pageMaps)
      .filter(page => page.id !== pageId)
      .map(page => ({
        title: page.name,
        value: page.id,
        subtitle: EntityType.Page,
      }));

    return sortBy(allProducts.concat(allVariants, allPages), item =>
      item.title?.toLowerCase(),
    );
  }, [productMaps, variantProductMaps, variantMaps, pageMaps, pageId]);

  const sortedByPriorityPageItems = useMemo(() => {
    const sortedItems = sortBy(assignedPageItems, item => item.priority);
    currentMaxPriority.current = last(sortedItems)?.priority ?? -1;
    return sortedItems;
  }, [assignedPageItems]);

  const selectedUnReferenceProductIds = useMemo(
    () =>
      uniq(
        assignedPageItems
          .filter(pageItem => !pageItem.category)
          .map(item => item.entityId),
      ),

    [assignedPageItems],
  );

  const getMaxPriority = useCallback(() => {
    currentMaxPriority.current += 1;
    return currentMaxPriority.current;
  }, []);

  const onChange = (prop: string, value: string) => {
    setPageInfo(prev => ({ ...prev, [prop]: value }));
  };

  const onDeletePage = useCallback(
    async id => {
      closeModal();
      await deletePage(id);
      navigation.navigate('Menus');
    },
    [closeModal, deletePage, navigation],
  );

  const onDeleteModal = useCallback(() => {
    if (pageId) {
      showModal(
        <ConfirmationModal
          title={
            translate('dialog.deleteTitle') +
            ' ' +
            translate('backOfficeProducts.page')
          }
          message={translate('dialog.deleteConfirmation', {
            label: pageInfo?.name as string,
          })}
          onConfirm={() => onDeletePage(pageId)}
        />,
      );
    }
  }, [pageId, pageInfo, onDeletePage, showModal, translate]);

  const onSelectPageItem = useCallback(
    (_: string[], item: OptionsType) => {
      const selectedEntityId = item.value;
      setAssignedPageItems(preItems => {
        const isItemAssigned = Boolean(
          assignedPageItems.find(
            item => !item.category && item.entityId === selectedEntityId,
          ),
        );
        if (isItemAssigned) {
          const removedItemIds = preItems
            .filter(
              item => !item.category && item.entityId === selectedEntityId,
            )
            .map(item => item.id);
          removePageItemIdsRef.current.push(...removedItemIds);
          return preItems.filter(item => !removedItemIds.includes(item.id));
        }

        return preItems.concat({
          id: uuidV4(),
          entityId: selectedEntityId,
          entityType: item.subtitle as EntityType,
          priority: getMaxPriority(),
        });
      });
    },
    [assignedPageItems, getMaxPriority],
  );

  const onDeleteRow = useCallback((id: string) => {
    removePageItemIdsRef.current.push(id);
    setAssignedPageItems(preItems => preItems.filter(item => item.id !== id));
  }, []);

  const addCategoryIds = difference(
    pageInfo.categories,
    originalPage.current?.categories || [],
  );

  const addOrUpdateItems = useMemo(
    () =>
      difference(assignedPageItems, originalPage.current?.items || []).map(
        item => omit(item, ['__typename']),
      ) as AddOrUpdatePageItemInput[],
    [assignedPageItems],
  );

  const removeCategoryIds = difference(
    originalPage.current?.categories || [],
    pageInfo.categories,
  );

  const serverPageItemIds = originalPage.current.items?.map(item => item.id);
  const removeItemIds = removePageItemIdsRef.current.filter(removeId =>
    serverPageItemIds?.includes(removeId),
  );

  const { name, color } = pageInfo;
  const {
    name: originalName,
    color: originalColor,
    alternateNames: orginalAlternateNames,
  } = originalPage.current;

  const hasBasicPageInfoChanged =
    name !== originalName ||
    color !== originalColor ||
    !isEqual(
      alternateNames,
      decodeAlternateNameValuesToBase64(orginalAlternateNames || []),
    );

  const hasDataChanged = Boolean(
    hasBasicPageInfoChanged ||
      addOrUpdateItems.length ||
      removeItemIds.length ||
      removeCategoryIds.length ||
      addCategoryIds.length,
  );

  const onPressSave = async () => {
    const encodedAlternateNames =
      encodeAlternateNameValuesToBase64(alternateNames);

    if (isCreatingNewPage) {
      const createdPage = await createOrUpdatePage({
        name: pageInfo.name,
        color: pageInfo.color,
        addCategoryIds,
        addOrUpdateItems,
        removeCategoryIds,
        removeItemIds,
        alternateNames: encodedAlternateNames,
      });
      if (createdPage) {
        // create successfully
        const maxPriority =
          maxBy(menu?.items, item => item.priority)?.priority ?? -1;
        const newCatalogueItem: CatalogueItemInput = {
          id: uuidV4(),
          page: createdPage?.id as string,
          priority: maxPriority + 1,
          color: createdPage?.color || '',
        };
        await updateMenu({
          id: menu?.id as string,
          name: menu?.name,
          stores: menu?.stores?.map(store => store.id),
          venues: menu?.venues?.map(venue => venue.id),
          addOrUpdateCatalogueItems: [newCatalogueItem],
        });
        navigation.navigate('MenuDetail', { menuId });
      }
    } else {
      createOrUpdatePage({
        id: pageId,
        name: pageInfo.name,
        color: pageInfo.color,
        addCategoryIds,
        addOrUpdateItems,
        removeCategoryIds,
        removeItemIds,
        alternateNames: encodedAlternateNames,
      });
    }
  };

  const onDragEnd = useCallback((data: PageItem[]) => {
    setAssignedPageItems(
      data.map((pageRow, index) => ({
        ...pageRow,
        priority: index,
      })),
    );
  }, []);

  const updatePageInfo = useCallback(
    (key: 'name' | 'color' | 'categories', value) => {
      setPageInfo(preData => ({ ...preData, [key]: value }));
    },
    [],
  );

  const batchUpdatePageItems = useCallback(
    (
      items: { entityId: string; entityType: EntityType; category: string }[],
    ) => {
      const updatedPageItems = items.map(item => ({
        ...item,
        id: uuidV4(),
        priority: getMaxPriority(),
      }));
      setAssignedPageItems(preItems => preItems.concat(updatedPageItems));
    },
    [getMaxPriority],
  );

  const removeBatchItemsByCategoryId = useCallback((categoryId: string) => {
    setAssignedPageItems(preItems => {
      const removeItemIds = preItems
        .filter(item => item.category === categoryId)
        .map(x => x.id);
      removePageItemIdsRef.current.push(...removeItemIds);
      return preItems.filter(item => !removeItemIds.includes(item.id));
    });
  }, []);

  const onSelectCategory = useCallback(
    (categoryId: string, isSelected: boolean) => {
      const updatedAssignedCategories = [...pageInfo.categories];
      if (!isSelected) {
        updatedAssignedCategories.push(categoryId);
        const { products, variants } = categoryMaps[categoryId] as Category;
        const productMaps = keyBy(products, 'id');
        const assignedProducts = sortBy([...products, ...variants], entity =>
          entity.name?.toLowerCase(),
        ).map(entity => ({
          entityId: entity.id,
          entityType: productMaps[entity.id]
            ? EntityType.Product
            : EntityType.Variant,
          category: categoryId,
        }));
        batchUpdatePageItems(assignedProducts);
      } else {
        const selectedIndex = updatedAssignedCategories.findIndex(
          id => id === categoryId,
        );
        updatedAssignedCategories.splice(selectedIndex, 1);
        removeBatchItemsByCategoryId(categoryId);
      }
      updatePageInfo('categories', updatedAssignedCategories);
    },
    [
      batchUpdatePageItems,
      categoryMaps,
      pageInfo.categories,
      removeBatchItemsByCategoryId,
      updatePageInfo,
    ],
  );

  const onChangeAlternateName = useCallback(
    (prop: string, value: string, locale: LOCALE): void => {
      const temp = updateAlternateNamesWithNewValue(
        { alternateNames: alternateNames } as Page,
        locale,
        prop,
        value,
      );
      setAlternateNames(temp?.alternateNames || []);
    },
    [alternateNames],
  );

  useEffect(() => {
    if (!params.name) return;
    updatePageInfo('name', params.name);
  }, [params.name, updatePageInfo]);

  useEffect(() => {
    if (!pageMaps[pageId]) return;
    navigation.setParams({
      name: pageMaps[pageId]?.name,
    });
    navigation.setOptions({
      tabBarLabel: pageMaps[pageId]?.name,
    });
  }, [navigation, pageId, pageMaps]);

  useEffect(() => {
    if (!pageId || pageId === DEFAULT_ENTITY_ID || !pageMaps[pageId]) return;
    const {
      color = '',
      categories = [],
      name,
      items = [],
      alternateNames,
    } = pageMaps[pageId];
    if (alternateNames) {
      setAlternateNames(
        decodeAlternateNameValuesToBase64(alternateNames) || [],
      );
    }
    setPageInfo({
      name,
      color,
      categories,
    });
    originalPage.current = pageMaps[pageId];
    removePageItemIdsRef.current = [];
    setAssignedPageItems(items);
  }, [pageId, pageMaps]);

  useEffect(() => {
    if (!isFocused) return;
    getAllProducts();
    getAllVariants();
    getPages();
    getCategories();
    menuId && getMenu(menuId);
  }, [
    getPages,
    getAllProducts,
    getAllVariants,
    isFocused,
    getCategories,
    menuId,
    getMenu,
  ]);

  useEffect(() => {
    if (!pageErr) return;
    showNotification({ error: true, message: pageErr });
  }, [pageErr, showNotification]);

  const sortedCategories = sortBy(Object.values(categoryMaps), category =>
    category.name?.toLowerCase(),
  );

  return (
    <ScreenLayout
      loading={loading}
      title={`${pageInfo.name} | Oolio`}
      onSave={onPressSave}
      onSaveLabel={
        isCreatingNewPage ? translate('button.add') : translate('button.save')
      }
      onDelete={onDeleteModal}
      onDeleteDisabled={isCreatingNewPage}
      hideSaveButton={!hasDataChanged || !pageInfo.name.trim()}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('backOfficeProducts.pageName')}
            value={pageInfo.name}
            placeholder={translate('backOfficeProducts.pageName')}
            onChangeText={onChange.bind(null, 'name')}
            maxLength={50}
            containerStyle={theme.forms.inputHalf}
          />
          <SelectColour
            testID="select-colour"
            title={translate('menus.selectPageColor')}
            value={pageInfo.color}
            onChange={onChange.bind(null, 'color')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section
        title={translate('menus.assignCategories')}
        subtitle={translate('menus.assignCategoriesDesc')}
      >
        {sortedCategories.length ? (
          <View style={styles.pages}>
            {sortedCategories.map(category => {
              const { products = [], variants = [], name, id } = category;
              const isAssigned = pageInfo.categories.includes(id);
              const numberOfProducts = products.length + variants.length;
              return (
                <View key={id} style={styles.page}>
                  <InputToggle
                    testID="toggle-page"
                    title={`${sentenceCase(name)} (${numberOfProducts})`}
                    isToggled={isAssigned}
                    onToggle={onSelectCategory.bind(
                      null,
                      id as string,
                      isAssigned,
                    )}
                  />
                </View>
              );
            })}
          </View>
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('menus.noCategoriesCreated')}
            </Text>
          </View>
        )}
      </Section>
      <Section title={translate('backOfficeProducts.assignProduct')}>
        <SelectSearch
          options={allPageItems}
          selectedOptions={selectedUnReferenceProductIds}
          onChangeOption={(s, e) => onSelectPageItem(s, e)}
          placeholder={translate(
            'backOfficeProducts.searchProductsPagesByName',
          )}
        />
        <DraggableFlatList
          data={sortedByPriorityPageItems}
          renderItem={params => {
            const { item, drag } = params;
            return (
              <ProductRow
                key={item.id}
                item={{ ...item, name: allPageItemMaps[item.entityId]?.name }}
                onDelete={onDeleteRow}
                drag={drag}
              />
            );
          }}
          keyExtractor={item => item.id}
          onDragEnd={({ data }) => onDragEnd(data)}
          containerStyle={styles.table}
        />
      </Section>
      <View style={styles.translations}>
        <AlternateNamesSection
          alternateNames={alternateNames}
          onChangeAlternateName={onChangeAlternateName}
        />
      </View>
    </ScreenLayout>
  );
};

export default PageDetail;

const styles = StyleSheet.create({
  pages: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  page: {
    width: 260,
    marginBottom: 4,
  },
  table: {
    marginBottom: 20,
    zIndex: -1,
  },
  translations: {
    zIndex: -1,
  },
});
