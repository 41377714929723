import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Section, TableShape } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../../hooks/Notification';
import { isDigit, isNotEmpty } from '../../../../../../../utils/validator';
import { capitalCase } from 'change-case';
import styles from '../SectionDetails.styles';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../../components/Shared/Modals/Modal/Modal';
import SegmentControl from '../../../../../../../components/Shared/SegmentControl/SegmentControl';
import { SEAT_OPTIONS } from '../EditTableModal/EditTableModal';

interface Props {
  onSingleTableCreate: (form: CreateTableModalForm) => void;
  onMultipleTableCreate: (form: CreateTableModalForm) => void;
  section: Section;
}

export interface CreateTableModalForm {
  name: string;
  prefix: string;
  shape: TableShape;
  verticalSeats: string;
  numberOfTables: string;
  horizontalSeats: string;
}

enum CreateMode {
  SINGLE = 'Single',
  MULTIPLE = 'Multiple',
}

export const CreateTableModal: React.FC<Props> = ({
  section,
  onSingleTableCreate,
  onMultipleTableCreate,
}: Props) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [tableCreationOption, setTableCreationOption] = useState<CreateMode>(
    CreateMode.MULTIPLE,
  );

  const [form, setForm] = useState<CreateTableModalForm>({
    name: '',
    verticalSeats: '1',
    horizontalSeats: '1',
    numberOfTables: '',
    shape: section.defaultTableShape,
    prefix: '',
  });

  const onChangeOption = useCallback(
    (selectOption: string) => {
      if (tableCreationOption !== selectOption) {
        setTableCreationOption(selectOption as CreateMode);
      }
    },
    [tableCreationOption],
  );

  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeNumber = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value ? parseInt(value.replace(/[^0-9]/g, '')) : '',
    }));
  }, []);

  const onPressCreate = useCallback(() => {
    const sanitisedForm = {
      ...form,
      numberOfTables: form.numberOfTables === '' ? '1' : form.numberOfTables,
    };

    if (!tableSeatValidation(form.verticalSeats, form.horizontalSeats)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.createTable.seatNumberRequired'),
      });
      return;
    }
    if (tableCreationOption === 'Single') {
      if (!form.name || !isNotEmpty(form.name)) {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.tableNameIsRequired'),
        });
        return;
      }
      onSingleTableCreate(sanitisedForm);
    } else {
      if (!(parseInt(sanitisedForm.numberOfTables) > 0)) {
        showNotification({
          error: true,
          message: translate(
            'backOfficeSettings.createTable.pleaseEnterTableCount',
          ),
        });
        return;
      }
      onMultipleTableCreate(sanitisedForm);
    }
  }, [
    form,
    onSingleTableCreate,
    onMultipleTableCreate,
    showNotification,
    tableCreationOption,
    translate,
  ]);

  const tableSeatValidation = (
    verticalSeats: string,
    horizontalSeats: string,
  ) => {
    return (
      isDigit(verticalSeats) &&
      isDigit(horizontalSeats) &&
      (Number(verticalSeats) > 0 || Number(horizontalSeats) > 0)
    );
  };

  const optionTabs = useMemo(() => {
    return [
      {
        label: translate('backOfficeSettings.createTable.multiple'),
        value: CreateMode.MULTIPLE,
      },
      {
        label: translate('backOfficeSettings.createTable.single'),
        value: CreateMode.SINGLE,
      },
    ];
  }, [translate]);

  return (
    <TreatModal
      title={translate('backOfficeSettings.createTable.createTables')}
      type="positive"
      onConfirm={{
        label: translate('button.createNew'),
        action: onPressCreate,
      }}
      onDismiss={{ action: closeModal }}
    >
      <View>
        <SegmentControl
          secondary
          onPress={onChangeOption}
          selectedTab={tableCreationOption}
          tabs={optionTabs}
          containerStyle={styles.segments}
        />
        {tableCreationOption === CreateMode.SINGLE ? (
          <View style={styles.row}>
            <InputText
              testID="table-name"
              title={translate('backOfficeSettings.createTable.tableName')}
              value={form.name}
              onChangeText={onChange.bind(null, 'name')}
              placeholder={translate(
                'backOfficeSettings.createTable.tableName',
              )}
              containerStyle={styles.inputFull}
            />
          </View>
        ) : null}
        {tableCreationOption === CreateMode.MULTIPLE ? (
          <View style={styles.row}>
            <InputText
              testID="table-name-prefix"
              title={translate('backOfficeSettings.createTable.prefix')}
              value={form.prefix}
              onChangeText={onChange.bind(null, 'prefix')}
              placeholder={translate('backOfficeSettings.createTable.prefix')}
              containerStyle={styles.inputHalf}
            />
            <InputText
              testID="tables-count"
              title={translate('backOfficeSettings.createTable.tables')}
              value={form.numberOfTables}
              onChangeText={onChangeNumber.bind(null, 'numberOfTables')}
              placeholder="1"
              keyboardType="numeric"
              containerStyle={styles.inputHalf}
              maxLength={2}
              numericOnly
            />
          </View>
        ) : null}
        <View style={styles.row}>
          <TreatPicker
            testID="tables-horizontal-seats"
            title={translate('backOfficeSettings.createTable.seatsHorizontal')}
            selectedValue={form.horizontalSeats}
            options={SEAT_OPTIONS}
            onValueChange={onChangeNumber.bind(null, 'horizontalSeats')}
            containerStyle={styles.inputHalf}
          />
          <TreatPicker
            testID="tables-vertical-seats"
            title={translate('backOfficeSettings.createTable.seatsVertical')}
            selectedValue={form.verticalSeats}
            options={SEAT_OPTIONS}
            onValueChange={onChangeNumber.bind(null, 'verticalSeats')}
            containerStyle={styles.inputHalf}
          />
        </View>
        <TreatPicker
          testID="table-shapes"
          title={translate('backOfficeSettings.createTable.tableShape')}
          selectedValue={form.shape}
          options={Object.values(TableShape).map((key: string) => ({
            value: key,
            label: capitalCase(key),
          }))}
          onValueChange={onChange.bind(null, 'shape')}
        />
      </View>
    </TreatModal>
  );
};
