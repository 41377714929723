import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import { Feature, ToggleFeatureInput } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../../hooks/app/useSession';
import { useModal } from '@oolio-group/rn-use-modal';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import Search from '../../../../../components/Shared/Search/Search';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';

export interface ToggleFeatureAtVenueModalProps {
  onToggleFeatureOnVenue: (input: ToggleFeatureInput) => void;
  feature?: Feature;
}

const VenueSelectionModal: React.FC<ToggleFeatureAtVenueModalProps> = ({
  onToggleFeatureOnVenue,
  feature,
}) => {
  const { translate } = useTranslation();
  const [searchVenueName, setSearchVenueName] = useState('');
  const [assignedVenueIds, setAssignedVenueIds] = useState<string[]>([]);
  const [session] = useSession();
  const { closeModal } = useModal();

  const onToggleAssignVenue = useCallback((selectedVenueId: string) => {
    setAssignedVenueIds(venueIds => {
      const updatedIds = [...venueIds];
      const venueIndex = updatedIds.indexOf(selectedVenueId);
      if (venueIndex >= 0) {
        updatedIds.splice(venueIndex, 1);
        return updatedIds;
      }
      return [...updatedIds, selectedVenueId];
    });
  }, []);

  const toggleFeatureOnVenue = useCallback(() => {
    onToggleFeatureOnVenue({
      id: feature?.id as string,
      venueIds: assignedVenueIds,
    });
    closeModal();
  }, [assignedVenueIds, closeModal, feature?.id, onToggleFeatureOnVenue]);

  const filteredVenues = useMemo(() => {
    return (session.user?.venues || []).filter(venue =>
      venue.name.toLowerCase().includes(searchVenueName.toLowerCase()),
    );
  }, [searchVenueName, session.user?.venues]);

  useEffect(() => {
    setAssignedVenueIds(feature?.venueIds || []);
  }, [feature?.venueIds]);

  const disableConfirmBtn = !feature?.enabled && !assignedVenueIds.length;

  const buttonLabel = useMemo(() => {
    if (!feature?.enabled) return 'backOffice.features.buttons.enable';
    if (assignedVenueIds.length) return 'backOffice.features.modals.update';
    return 'backOffice.features.buttons.disable';
  }, [assignedVenueIds.length, feature?.enabled]);

  return (
    <TreatModal
      type="positive"
      title={translate('backOffice.features.configureFeature', {
        featureName: feature?.title,
      })}
      subtitle={translate('backOffice.features.selectStores')}
      onConfirm={{
        label: translate(buttonLabel),
        action: toggleFeatureOnVenue,
        disabled: disableConfirmBtn,
      }}
      onDismiss={{ action: closeModal }}
    >
      <KeyboardAvoidingView behavior="padding">
        <Search
          testID="search-venues"
          value={searchVenueName}
          onChangeText={setSearchVenueName}
          placeholder={translate('backOffice.features.searchVenue')}
        />
        <View>
          {filteredVenues.length == 0 ? (
            <View>
              <Text testID="no-found-venue">
                {translate('backOffice.features.noVenuesFound')}
              </Text>
            </View>
          ) : (
            <ScrollView style={styles.venuesList}>
              {filteredVenues?.map(venue => {
                const isAssigned = assignedVenueIds.includes(venue.id);
                return (
                  <TouchableOpacity
                    key={venue?.id}
                    testID={'toggle-venue-action'}
                    onPress={onToggleAssignVenue.bind(
                      null,
                      venue?.id as string,
                    )}
                    style={styles.venueRow}
                  >
                    <Icon
                      name={isAssigned ? 'check-square' : 'square-full'}
                      color={
                        isAssigned
                          ? theme.colors.states.positive
                          : theme.colors.grey5
                      }
                      size={20}
                    />
                    {/* eslint-disable-next-line react-native/no-inline-styles */}
                    <Text style={{ marginLeft: 12 }}>{venue.name}</Text>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          )}
        </View>
      </KeyboardAvoidingView>
    </TreatModal>
  );
};

export default VenueSelectionModal;

const styles = StyleSheet.create({
  venuesList: {
    marginTop: 20,
    maxHeight: 200,
    borderTopWidth: 1,
    borderTopColor: theme.colors.grey3,
  },
  venueRow: {
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingLeft: 0,
    borderBottomColor: theme.colors.grey3,
  },
});
