import React, { useMemo } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../hooks/app/useSession';
import ModalPicker from '../../POS/Modals/ModalPicker/ModalPicker';

interface SectionsSelectModalProps {
  onSelectSection: (sectionId: string) => void;
  onClose?: () => void;
}

const SectionsSelectModal: React.FC<SectionsSelectModalProps> = ({
  onSelectSection,
  onClose,
}: SectionsSelectModalProps) => {
  const { translate } = useTranslation();
  const [{ deviceProfile }] = useSession();

  const listSections = useMemo(
    () =>
      deviceProfile?.sections?.map(x => ({
        label: x.name,
        value: x.id,
      })) ?? [],
    [deviceProfile?.sections],
  );

  return (
    <ModalPicker
      onSelect={onSelectSection}
      title={translate('order.selectSection')}
      options={listSections}
      onClose={onClose}
    />
  );
};

export default SectionsSelectModal;
