import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  headerContainer: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
    marginLeft: 10,
  },
  dateFilter: {
    width: 180,
    marginRight: 10,
    zIndex: 4,
  },
  btnRefresh: {
    marginHorizontal: 10,
  },
  tableHeader: {
    height: 38,
    marginHorizontal: 20,
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginHorizontal: 20,
  },
  tableRow: {
    height: 66,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  columnIcon: {
    width: 70,
    textAlign: 'center',
  },
  cellIcon: {
    height: 28,
    width: 28,
    borderRadius: theme.radius.s,
    marginLeft: 10,
  },
  columnGuests: {
    width: 70,
  },
  cellGuests: {
    width: 30,
    textAlign: 'center',
  },
  columnTable: {
    width: 100,
    paddingHorizontal: 6,
  },
  cellTableNo: {
    width: 100,
    marginBottom: 4,
  },
  cellTableArea: {
    width: 100,
    color: theme.colors.grey6,
  },
  columnCustomer: {
    width: 140,
    paddingHorizontal: 6,
  },
  cellCustomerName: {
    width: 140,
    marginBottom: 4,
  },
  cellCustomerPhone: {
    width: 140,
    color: theme.colors.grey6,
  },
  columnReservationTime: {
    width: 160,
    paddingHorizontal: 6,
  },
  cellReservationTimeDate: {
    width: 160,
    marginBottom: 4,
  },
  cellReservationTimeRange: {
    width: 160,
    color: theme.colors.grey6,
  },
  columnNotes: {
    flex: 1,
  },
  cellReservationNotes: {
    width: 330,
    marginBottom: 4,
  },
  cellCustomerNotes: {
    width: 330,
    color: theme.colors.grey6,
  },
  columnStatus: {
    flexGrow: 1,
    marginRight: 10,
  },
  cellStatus: {
    flex: 1,
    alignSelf: 'flex-end',
    borderRadius: 22,
  },
});

export default styles;
