import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.modalBg,
  },
  container: {
    overflow: 'hidden',
    alignSelf: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.elevated1,
  },
  title: {
    paddingTop: 40,
    paddingBottom: 30,
    paddingHorizontal: 42,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
  },
  subtitleText: {
    fontSize: 14,
    lineHeight: 20,
    paddingTop: 12,
    color: theme.colors.grey6,
  },
  content: {
    flex: 1,
    paddingHorizontal: 30,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 40,
    marginBottom: 30,
    paddingHorizontal: 30,
    backgroundColor: theme.colors.white,
  },
  actionsHz: {
    marginVertical: 30,
    paddingHorizontal: 30,
    flexDirection: 'column-reverse',
  },
});

export default styles;
