/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo, useState } from 'react';
import {
  DeviceMode,
  CreateDeviceInput,
  DeviceProfile,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { isNotEmpty } from '@oolio-group/client-utils';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import Select from '../../../../../../components/Shared/Select/Select';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';

interface CreateDeviceProps {
  onCreate: (input: CreateDeviceInput) => void;
  storeId: string;
  venueId: string;
  deviceProfiles: DeviceProfile[];
}
export const CreateDeviceModal: React.FC<CreateDeviceProps> = ({
  onCreate,
  storeId,
  venueId,
  deviceProfiles,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const defaultProfile = deviceProfiles.find(
    ({ isDefault }) => isDefault === true,
  ) as DeviceProfile;

  const initialForm = {
    store: storeId,
    venue: venueId,
    isCreatedByBackoffice: true,
    deviceProfile: defaultProfile.id,
    mode: DeviceMode.F_POS,
  };

  const [form, setForm] = useState({
    ...initialForm,
  } as unknown as CreateDeviceInput);

  const onChange = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const deviceProfilesOptions = useMemo(() => {
    /**
     * Load POS device profile for POS
     * Load Kiosk device profile for Kiosk
     * Load MPos device profile for MPos
     */
    const filteredDeviceProfiles = deviceProfiles
      .filter(deviceProfile => deviceProfile.mode === form.mode)
      .map(deviceProfile => ({
        value: deviceProfile.id,
        label: deviceProfile.name,
      }));
    onChange('deviceProfile', filteredDeviceProfiles[0]?.value);
    return filteredDeviceProfiles;
  }, [deviceProfiles, form.mode, onChange]);

  const onSubmit = useCallback(() => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceModal.errorDeviceNameMissing'),
      });
    } else if (form.mode === DeviceMode.M_POS && !form.poiId) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceModal.errorDevicePoiIdMissing'),
      });
    } else {
      onCreate(form);
      closeModal();
    }
  }, [form, showNotification, translate, onCreate, closeModal]);

  const deviceTypes = [
    {
      label: translate('backOfficeDeviceModal.posApp'),
      value: DeviceMode.F_POS,
    },
    {
      label: translate('backOfficeDeviceModal.mPos'),
      value: DeviceMode.M_POS,
    },
    {
      label: translate('backOfficeDeviceModal.kiosk'),
      value: DeviceMode.KIOSK,
    },
  ];

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeDeviceModal.createTitle')}
      onConfirm={{
        label: translate('backOfficeDeviceModal.createBtn'),
        action: onSubmit,
      }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('backOfficeDeviceModal.deviceName')}
        placeholder={translate('backOfficeDeviceModal.deviceName')}
        value={form.name}
        onChangeText={onChange.bind(null, 'name')}
      />
      <Select
        testID="select-type"
        title={translate('backOfficeDeviceModal.deviceTypes')}
        options={deviceTypes}
        selectedValue={form.mode}
        onValueChange={onChange.bind(null, 'mode')}
        containerStyle={{ marginTop: 20 }}
      />
      <Select
        testID="select-profile"
        title={translate('backOfficeDeviceModal.deviceProfileTitle')}
        options={deviceProfilesOptions}
        selectedValue={form.deviceProfile}
        onValueChange={onChange.bind(null, 'deviceProfile')}
        containerStyle={{ marginTop: 20 }}
      />
      {form.mode === DeviceMode.M_POS && (
        <>
          <InputText
            testID="input-poiId"
            title={translate('backOfficeDeviceModal.devicePoiId')}
            placeholder={translate('backOfficeDeviceModal.devicePoiId')}
            value={form.poiId}
            onChangeText={onChange.bind(null, 'poiId')}
            containerStyle={{ marginTop: 20 }}
          />
          <InputToggle
            testID="input-useCloudPayment"
            title={translate('backOfficeDeviceModal.useCloudPayment')}
            isToggled={form?.useCloudPayment ?? false}
            onToggle={(): void =>
              setForm(preForm => ({
                ...preForm,
                useCloudPayment: !preForm.useCloudPayment,
              }))
            }
          />
        </>
      )}
    </TreatModal>
  );
};
