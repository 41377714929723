import { RenderProps, StyleFn } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { GestureResponderEvent, View, ViewStyle } from 'react-native';
import Button from '../Button/Button';
import scale from '../../common/theme';
import IconButton from '../Button/IconButton';

export interface CatalogItemProps {
  title?: string;
  labelStyle?: ViewStyle;
  color?: string;
  testID?: string;
  onPress?: (event?: GestureResponderEvent) => void;
  onLongPress?: (event?: GestureResponderEvent) => void;
  containerStyle?: ViewStyle;
  disabled?: boolean;
  selected?: boolean;
  type?: string;
  isGroup?: boolean;
  trackedItemQuantity?: number;
  markedAsAllergic?: boolean;
  isAvailable?: boolean;
}

interface CatalogItemContainerProps {
  containerStyle?: ViewStyle;
  children?: React.ReactNode;
}

const catalogItemContainerStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'stretch',
});

const buttonStyle: StyleFn = () => ({
  height: '100%',
});

const pageButtonStyle: StyleFn = ({ theme, color }) => ({
  height: '100%',
  backgroundColor: color || theme.colors.info,
});
const backButtonStyle: StyleFn = ({ theme }) => ({
  height: '100%',
  backgroundColor: theme.colors.white,
});
const pageSelectedButtonStyle: StyleFn = ({ theme, color }) => ({
  height: '100%',
  backgroundColor: color || theme.colors.tealDark,
});

const typeContainer: StyleFn = () => ({
  position: 'absolute',
  zIndex: 2,
  margin: 6,
  top: 0,
  right: 0,
  height: 12,
  width: 12,
});

const numberOfItemsStyle: StyleFn = ({ theme, markedAsAllergic = false }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: theme.spacing.small,
  color: !markedAsAllergic ? theme.colors.white : theme.colors.black,
  ...theme.font14SemiBold,
  fontSize: 11,
  flexDirection: 'row',
});

const allergicItemStyle: StyleFn = () => ({
  position: 'absolute',
  top: 5,
  left: 5,
});

const variantNumberOfItemsStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  top: -7,
  right: 10,
  color: theme.colors.black,
  margin: theme.spacing.small,
  ...theme.font14SemiBold,
  fontSize: 11,
  flexDirection: 'row',
});

const negativeNumberOfItemsColor: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const selectionContainer: StyleFn = () => ({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  margin: 1,
  height: 40,
  width: 30,
  overflow: 'hidden',
  borderTopLeftRadius: 5,
});

const selectedStyle: { [key: string]: StyleFn } = {
  page: ({ theme }) => ({
    zIndex: 2,
    position: 'absolute',
    top: -13,
    left: -5,
    backgroundColor: theme.colors.white,
    height: 35,
    width: 19,
    transform: [{ rotate: '45deg' }],
  }),
  modifier: ({ theme }) => ({
    zIndex: 2,
    position: 'absolute',
    top: -13,
    left: -5,
    backgroundColor: theme.colors.success,
    height: 35,
    width: 19,
    transform: [{ rotate: '45deg' }],
  }),
  modifierDeSelect: ({ theme }) => ({
    zIndex: 2,
    position: 'absolute',
    top: -13,
    left: -5,
    backgroundColor: theme.colors.danger,
    height: 35,
    width: 19,
    transform: [{ rotate: '45deg' }],
  }),
};

const typeStyle: { [key: string]: StyleFn } = {
  variant: ({ theme }) => ({
    width: 0,
    height: 0,
    borderLeftWidth: 7,
    borderRightWidth: 7,
    borderBottomWidth: 10,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.colors.blue,
    transform: [{ rotate: '180deg' }],
  }),
  modifierGroup: ({ theme }) => ({
    zIndex: 2,
    position: 'absolute',
    backgroundColor: theme.colors.success,
    height: scale.moderateScale(5),
    width: scale.moderateScale(5),
    borderRadius: scale.moderateScale(3),
    right: 5,
    top: 1,
  }),
  modifierGroupDanger: ({ theme }) => ({
    zIndex: 2,
    position: 'absolute',
    backgroundColor: theme.colors.danger,
    height: scale.moderateScale(5),
    width: scale.moderateScale(5),
    borderRadius: scale.moderateScale(3),
    right: 5,
    top: 1,
  }),
  modifierGroupNotSelected: ({ theme }) => ({
    zIndex: 2,
    position: 'absolute',
    backgroundColor: theme.colors.amber,
    height: scale.moderateScale(5),
    width: scale.moderateScale(5),
    borderRadius: scale.moderateScale(3),
    right: 5,
    top: 1,
  }),
};

const CatalogItemContainer: React.FC<CatalogItemContainerProps> = ({
  containerStyle,
  ...props
}: CatalogItemContainerProps) => (
  <FelaComponent accessible={true} style={catalogItemContainerStyle}>
    {({ style }: RenderProps): React.ReactNode => (
      <View style={[style, containerStyle]} {...props} />
    )}
  </FelaComponent>
);

const CatalogItem: React.FC<CatalogItemProps> = ({
  title,
  color,
  testID,
  onPress,
  onLongPress,
  disabled,
  containerStyle,
  selected,
  type,
  isGroup,
  labelStyle,
  trackedItemQuantity,
  markedAsAllergic,
  isAvailable,
}: CatalogItemProps) => {
  const { css, theme } = useFela({ type });
  const negativeTrackedItemCount =
    typeof trackedItemQuantity === 'number' && trackedItemQuantity < 1;
  return (
    <CatalogItemContainer containerStyle={containerStyle}>
      {title && type ? (
        <View style={css(typeContainer)}>
          <View testID="type" style={css(typeStyle[type])} />
        </View>
      ) : undefined}
      {type !== undefined && selected && (
        <View style={css(selectionContainer)}>
          <View testID="selected" style={css(selectedStyle[type])} />
        </View>
      )}
      {title === 'BACK' ? (
        <IconButton
          icon={'ArrowLeft'}
          primary
          iconColor={theme.colors.black}
          containerStyle={css(backButtonStyle)}
          onPress={onPress}
          iconSize={28}
          testID={testID}
        />
      ) : (
        <Button
          activeOpacity={0.8}
          containerStyle={
            type === 'page' && title
              ? selected
                ? css(pageSelectedButtonStyle({ theme, color }))
                : css(pageButtonStyle({ theme, color }))
              : css(buttonStyle)
          }
          disabled={disabled}
          selected={isGroup}
          fluid
          testID={testID}
          title={title}
          color={
            negativeTrackedItemCount || isAvailable === false
              ? theme.colors.boxBorder
              : color
          }
          onPress={onPress}
          onLongPress={onLongPress}
          labelStyle={labelStyle}
          markedAsAllergic={markedAsAllergic}
          allergicItemStyle={css(allergicItemStyle)}
          trackedItemQuantity={isAvailable ? trackedItemQuantity : undefined}
          numberOfTrackedItemsStyle={
            type === 'variant'
              ? css([
                  variantNumberOfItemsStyle,
                  negativeTrackedItemCount && negativeNumberOfItemsColor,
                ])
              : css([
                  numberOfItemsStyle({ theme, markedAsAllergic }),
                  negativeTrackedItemCount && negativeNumberOfItemsColor,
                ])
          }
        />
      )}
    </CatalogItemContainer>
  );
};

CatalogItem.defaultProps = {
  color: '#e5e5e5',
};

export default CatalogItem;
