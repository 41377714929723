import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  headerStatus: {
    width: 100,
    marginRight: 22,
    marginLeft: 'auto',
    textAlign: 'center',
  },
  tableBody: {
    marginBottom: 20,
  },
  cellName: {
    flex: 1,
    paddingLeft: 12,
    marginRight: 10,
  },
  subtext: {
    marginTop: 4,
    color: theme.colors.grey6,
    fontVariant: ['tabular-nums'],
  },
});

export default styles;
