import { Order, OrderStatus } from '@oolio-group/domain';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import format from 'date-fns/format';
import _ from 'lodash';

/**
 * Order status text mapping for customer
 *
 * Notes:
 * - Cancelled is not allowed to print
 */

/**
 * Order details section has two columns and `n` row(s)
 */
const config: TableUserConfig = {
  columns: {
    0: {},
    1: {
      alignment: 'right',
      width: 25,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    // TODO: get these from api / our custom hook
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const orderTokenReceiptRows: Array<{
  label: string; // Label to be displayed on left
  path: string; // object path
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueTransformationFn?: (args: any) => string | undefined;
  hideEmptyValue?: boolean; // any transformation required on result
}> = [
  {
    label: 'Order No.',
    path: 'orderNumber',
  },
  {
    label: 'Order Date',
    path: 'createdAt',
    valueTransformationFn: (value: number) =>
      value ? format(new Date(value), 'dd.MM.yy hh:mm a') : undefined,
  },
];

/**
 * Generate the order details rows for billing or kitchen receipt
 * @param order
 *
 * Example output:
 * ```bash
 * Order No.                           SP-34234
 * Order Date                       20-Feb-2021
 * Order Status                     In Progress
 * Order Type                          Takeaway
 * Served By                             Minion
 * ```
 */

export const generateTokenDetails = (
  order: Order,
  columns?: string[],
): string => {
  const orderRows = orderTokenReceiptRows;

  const orderDetailsTable = orderRows.reduce((acc, row) => {
    let value = _.get(order, row.path);
    if (row.path === 'status' && value === OrderStatus.ON_HOLD) {
      if (order.amountDue === 0) {
        value = 'Paid';
      } else {
        value = 'Un Paid';
      }
    } else if (row.valueTransformationFn) {
      value = row.valueTransformationFn(value);
    }
    if ((row.hideEmptyValue && !value) || !columns?.includes(row.label)) {
      return acc;
    }
    const rowWithData = [row.label, value || ''];
    acc.push(rowWithData);
    return acc;
  }, [] as string[][]);

  return table(orderDetailsTable, config);
};

export const generateOrderDetails = (order: Order): string => {
  const dateTime = format(new Date(order.createdAt), 'dd.MM.yy hh:mm a');
  const orderStatus =
    order.status === 'COMPLETED' || order.status === 'ON_HOLD'
      ? 'Paid'
      : 'Unpaid';

  const orderLocation =
    order.orderType?.name === 'Dine In'
      ? `${order.table?.section?.name}, ${order.table?.name}`
      : order.salesChannel?.name ?? '';

  const orderTypeName = order.orderType?.name ?? '';

  const createdBy = order.createdBy?.name ?? '';

  const orderDetailsTable = [
    [order.orderNumber, dateTime],
    [orderTypeName, orderLocation],
    [createdBy, orderStatus],
  ];
  return table(orderDetailsTable, config);
};

export const BILL_RECEIPT_ORDER_COLUMNS = [
  'Order No.',
  'Channel Name',
  'Order Date',
  'Order Status',
  'Order Type',
  'Table No',
  'Served By',
  'Device',
];

export const REFUND_BILL_RECEIPT_ORDER_COLUMNS = [
  'Order No.',
  'Refund Date',
  'Refund Status',
  'Order Type',
  'Table No',
  'Served By',
  'Device',
];

export const DOCKET_ORDER_COLUMNS = [
  'Order No.',
  'Order Date',
  'Served By',
  'Device',
];
