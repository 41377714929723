import { useApolloClient } from '@apollo/client/react/hooks';
import { OrderEvent } from '@oolio-group/domain';
import { dedupeOrderEvents } from '@oolio-group/order-helper';
import { groupBy } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { getQueue, queueEvents } from '../../events/eventsQueue';
import { SYNC_ORDER_EVENTS } from '../../graphql/syncEvents';
import { ORDER_EVENTS_QUEUE_KEY } from '../../state/preferences';
import { setItem } from '../../storage/interface';
import { currentOrderActionObservable } from '../app/orders/ordersObservableUtils';
import { useNetworkStatus } from './useNetworkStatus';

export function useSyncOrderEvents(
  postCompleteSync?: () => void,
  postErrorSyncPost?: () => void,
) {
  const orderIdForCurrentSync = useRef<string>();
  const { isConnected } = useNetworkStatus();

  const clearEventInQueue = useCallback(async (events: OrderEvent[]) => {
    const queuedEvents = await getQueue();
    const syncedEventMap = events.reduce((acc, e) => {
      return { ...acc, [e.id]: true };
    }, {} as Record<string, boolean>);
    const filteredEvents = queuedEvents.filter(
      event => !syncedEventMap[event.id],
    );
    setItem(ORDER_EVENTS_QUEUE_KEY, filteredEvents);
  }, []);

  const onCompletedSync = useCallback(
    data => {
      if (data?.syncEvents) {
        if (orderIdForCurrentSync.current) {
          currentOrderActionObservable.next({
            orderId: orderIdForCurrentSync.current,
            timestamp: Date.now(),
            isSyncComplete: true,
          });
        }
        postCompleteSync && postCompleteSync();
      }
    },
    [postCompleteSync],
  );

  const client = useApolloClient();
  const syncEvents = useCallback(
    async (events: OrderEvent[]) => {
      try {
        const response = await client.mutate({
          mutation: SYNC_ORDER_EVENTS,
          variables: {
            input: events,
          },
        });
        onCompletedSync(response.data);
        await clearEventInQueue(events);
      } catch (error) {
        queueEvents(events);
        postErrorSyncPost?.();
      }
    },
    [client, onCompletedSync, clearEventInQueue, postErrorSyncPost],
  );

  const syncAllOrderEvents = useCallback(
    async (events?: OrderEvent[]) => {
      const data = events || (await getQueue()) || [];
      const uniqueEvents = dedupeOrderEvents([...data]);
      if (isConnected && uniqueEvents.length > 0) {
        return await Promise.allSettled(
          Object.values(groupBy(uniqueEvents, 'orderId')).map(eventsByOrder => {
            return syncEvents(eventsByOrder);
          }),
        );
      }
      return [];
    },
    [isConnected, syncEvents],
  );

  const syncOrderEvents = useCallback(
    async (events: OrderEvent[]) => {
      orderIdForCurrentSync.current = events[0].orderId;
      const uniqueEvents = dedupeOrderEvents([...events]);
      if (isConnected) {
        syncAllOrderEvents(events);
      } else {
        queueEvents(uniqueEvents);
      }
    },
    [isConnected, syncAllOrderEvents],
  );

  return useMemo(
    () => ({
      syncOrderEvents,
      syncAllOrderEvents,
    }),
    [syncAllOrderEvents, syncOrderEvents],
  );
}
