import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginTop: 20,
    zIndex: -1,
  },
  cellName: {
    flex: 1,
  },
  cellPrice: {
    width: 120,
    marginLeft: 10,
  },
  headerPrice: {
    width: 120,
    marginRight: 10,
    textAlign: 'center',
  },
  headerSetPrice: {
    width: 120,
    marginRight: 34,
    textAlign: 'center',
  },
});

export default styles;
