import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { translate } from '@oolio-group/localization';
import { EditRole } from './EditRole/EditRole';

const Tab = createMaterialTopTabNavigator();

const ManageRolesTab: React.FC = () => {
  const route = useRoute();
  const params = route.params as { roleId: string };

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} />
      )}
      initialRouteName="CreateOrEditRole"
    >
      <Tab.Screen
        name="CreateOrEditRole"
        component={EditRole}
        options={{
          tabBarLabel: translate('backofficeManageRoles.createNewRole'),
        }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default ManageRolesTab;
