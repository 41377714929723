import { useCallback } from 'react';
import { FunctionAction, Resource } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../app/useSession';
import usePOSUserAuthorization from '../app/users/usePOSUserAuthorization';

export const useFunctionMapHelper = () => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { canI } = usePOSUserAuthorization();

  const getFunctionMapName = useCallback(
    (name: string) => {
      const trainingMode = session.device?.trainingMode;
      switch (name) {
        case 'MANAGER FUNCTIONS':
          return translate('functionMaps.managerFunctions');
        case '$1 SURCHARGE':
          return translate('functionMaps.oneDollorSurcharge');
        case 'MONEY IN/OUT':
          return translate('functionMaps.moneyInOrOut');
        case 'SWITCH MENU':
          return translate('functionMaps.switchMenu');
        case 'CUSTOM DISCOUNT $':
          return translate('functionMaps.customDiscount');
        case 'SYSTEM LOGOUT':
          return translate('functionMaps.systemLogout');
        case 'PRINT OPTIONS':
          return translate('functionMaps.printOptions');
        case 'OPEN TABLE':
          return translate('functionMaps.openTable');
        case 'CUSTOM SURCHARGE %':
          return translate('functionMaps.customSurchargePercent');
        case 'OPEN DRAWER':
          return translate('functionMaps.openDrawer');
        case 'SCHEDULED ADJUSTMENTS':
          return translate('functionMaps.scheduledSurcharge');
        case '2.5% SURCHARGE':
          return translate('functionMaps.twoPointFiveSurcharge');
        case '$1.5 SURCHARGE':
          return translate('functionMaps.onePointFiveSurcharge');
        case '$2 SURCHARGE':
          return translate('functionMaps.twoDollorSurcharge');
        case 'VOID':
          return translate('functionMaps.void');
        case '5% OFF':
          return translate('functionMaps.fivePerCentOff');
        case 'SWITCH PRICING GRP':
          return translate('functionMaps.switchPricingGroup');
        case 'CASH PAY':
          return translate('functionMaps.cashPay');
        case 'CARD PAY':
          return translate('functionMaps.cardPay');
        case 'ADD NOTE':
          return translate('functionMaps.addNote');
        case 'PRINT SHIFT SUMMARY':
          return translate('functionMaps.printShiftSummary');
        case 'REWARDS':
          return translate('functionMaps.rewards');
        case 'CUSTOM DISCOUNT %':
          return translate('functionMaps.customDiscountPercent');
        case 'ENABLE TRAINING MODE':
          return translate(
            !trainingMode
              ? 'functionMaps.enableTrainingMode'
              : 'functionMaps.disableTrainingMode',
          );
        case 'SEND RECEIPT':
          return translate('functionMaps.sendReceipt');
        case '10% OFF':
          return translate('functionMaps.tenPerCentOff');
        case '$10 OFF':
          return translate('functionMaps.tenDollorOff');
        case 'PRINT PREV RECEIPT':
          return translate('functionMaps.printPrevReceipt');
        case 'CUSTOM SURCHARGE $':
          return translate('functionMaps.customSurchargeDollor');
        case '1.9% SURCHARGE':
          return translate('functionMaps.onePointNineSurchange');
        case '$5 OFF':
          return translate('functionMaps.fiveDollorOff');
        case 'EXACT CASH':
          return translate('functionMaps.exactCash');
        case '2% SURCHARGE':
          return translate('functionMaps.twoPerCentSurcharge');
        case '100% OFF':
          return translate('functionMaps.hunderedPerCentOff');
        case 'SURCHARGES':
          return translate('functionMaps.surcharges');
        case 'END SHIFT':
          return translate('functionMaps.endShift');
        case 'ITEM AVAILABILITY':
          return translate('functionMaps.itemAvailabilityTile');
        case 'DISCOUNTS':
          return translate('functionMaps.discounts');
        case 'RESEND TO KITCHEN':
          return translate('functionMaps.resendToKitchen');
        case '$2 OFF':
          return translate('functionMaps.twoDollorOff');
        case 'SWITCH COURSE':
          return translate('functionMaps.switchCourse');
        case 'EMAIL RECEIPT':
          return translate('functionMaps.emailReceipt');
        case 'ADD VOUCHER':
          return translate('functionMaps.addVoucher');
        case 'TRANSFER':
          return translate('functionMaps.transfer');
        default:
          return name;
      }
    },
    [session.device?.trainingMode, translate],
  );

  const translateFunctionMaps = useCallback(
    (actions: FunctionAction[], locale?: string) => {
      if (!locale) {
        return actions;
      }

      return actions.map(x => ({
        ...x,
        name: getFunctionMapName(x.name),
      }));
    },
    [getFunctionMapName],
  );

  const hasAccessToAnyManagerFunctions = useCallback(() => {
    return (
      canI([{ onResource: Resource.PERFORM_SHIFT_CLOSURE }]) ||
      canI([{ onResource: Resource.SWITCH_MENU }]) ||
      canI([{ onResource: Resource.ALLOW_SYSTEM_LOGOUT }]) ||
      canI([{ onResource: Resource.ITEM_AVAILABILITY }]) ||
      canI([{ onResource: Resource.ALLOW_MONEY_MOVEMENTS }]) ||
      canI([{ onResource: Resource.OPEN_CASH_DRAWER }]) ||
      canI([{ onResource: Resource.SWITCH_PRICING_GROUP }])
    );
  }, [canI]);

  return { translateFunctionMaps, hasAccessToAnyManagerFunctions };
};
