import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filters: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    marginBottom: 20,
  },
  search: {
    flex: 1,
  },
  cellGroup: {
    width: 240,
    marginRight: 10,
  },
  cellMargin: {
    width: 120,
    marginRight: 'auto',
  },
  headerGroup: {
    width: 240,
  },
  headerMargin: {
    width: 100,
    textAlign: 'center',
  },
  btnAdd: {
    marginTop: 20,
    alignSelf: 'flex-end',
  },
});

export default styles;
