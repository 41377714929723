import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  actions: {
    marginVertical: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  btnAdd: {
    marginTop: 10,
    marginLeft: 'auto',
  },
  // Variation Groups
  headerGroup: {
    width: 188,
    marginRight: 22,
  },
  cellGroup: {
    width: 200,
    marginRight: 10,
  },
  cellVariations: {
    flex: 1,
    marginRight: 10,
  },
  // Variations
  headerVariant: {
    flex: 1,
  },
  headerSellPrice: {
    width: 120,
    marginRight: 76,
    textAlign: 'center',
  },
  cellVariant: {
    flex: 1,
    marginHorizontal: 10,
  },
  cellPrice: {
    width: 120,
    marginRight: 10,
    textAlign: 'center',
  },
  cellEdit: {
    marginRight: 10,
  },
});

export default styles;
