import React, { useState, useCallback, useEffect, FC } from 'react';
import { View } from 'react-native';
import {
  Role,
  ResourceOperation,
  App,
  AppAccess,
  RolePermissions,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRoles } from '../../../../../hooks/app/users/useRoles';
import { usePermissions } from '../../../../../hooks/app/users/usePermissions';
import { jobRoleSettingsUtility } from '../../../../../state/jobRoleSettingsUtility';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import SearchPermissionInput from '../Components/SearchPermissionInput';
import OfficePermissions from '../Components/OfficePermissions';
import POSAppPermissions from '../Components/POSAppPermissions';
import PermissionSection from '../Components/PermissionSection';
import Section from '../../../../../components/Office/Section/Section';
import Message from '../../../../../components/Office/Message/Message';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import ControlledFormInput from '../../../../../components/Shared/Inputs/ControlledFormInput/ControlledFormInput';
import theme from '../../../../../common/default-theme';
import styles from '../ManageRoles.styles';

interface FormData {
  name: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(50)
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
});

export const EditRole: FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const params = route.params as {
    roleId: string;
  };

  const onCreateCompleted = useCallback(
    (role: Role) => {
      showNotification({
        success: true,
        message: translate('backofficeManageRoles.roleCreated'),
      });
      navigation.setParams({
        roleId: role.id,
      });
    },
    [navigation, showNotification, translate],
  );

  const onUpdateCompleted = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backofficeManageRoles.roleUpdated'),
    });
  }, [showNotification, translate]);

  const onDeleteCompleted = useCallback(() => {
    closeModal();
    navigation.navigate('JobRoles');
  }, [navigation, closeModal]);

  const {
    createRole,
    updateRole,
    deleteRole,
    rolesById,
    error,
    loading,
    fetchRoleById,
  } = useRoles({ onCreateCompleted, onDeleteCompleted, onUpdateCompleted });

  const { permissions, fetchPermissions } = usePermissions();
  const [roleData, setRoleData] = useState<Role>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    jobRoleSettingsUtility.isEditable = !readOnly;
  }, [readOnly]);

  useEffect(() => {
    if (roleData?.name) {
      reset({ name: roleData.name });
    }
  }, [roleData, reset]);

  useEffect(() => {
    if (rolesById && params.roleId && rolesById[params.roleId]) {
      setRoleData(rolesById[params.roleId]);
      const rolePermissions = rolesById[params.roleId].permissions;
      jobRoleSettingsUtility.selectedPermissions = Object.assign(
        {},
        ...rolePermissions.map(item => ({
          [item.id]: item?.operations,
        })),
      );
      jobRoleSettingsUtility.appAccessSettings = rolesById[params.roleId]
        .apps as AppAccess;
    }
  }, [params.roleId, rolesById]);

  useEffect(() => {
    if (error) {
      showNotification({ error: true, message: error });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (roleData) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: roleData.name,
      });
    }
  }, [navigation, roleData]);

  useEffect(() => {
    if (params.roleId) {
      fetchRoleById(params.roleId);
    }
  }, [fetchRoleById, params.roleId]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  useEffect(() => {
    return () => {
      jobRoleSettingsUtility.reset();
    };
  }, []);

  useEffect(() => {
    jobRoleSettingsUtility.permissions = permissions;
  }, [permissions]);

  const onSubmit = handleSubmit((data: FormData) => {
    const selectedPermissionsById = jobRoleSettingsUtility.selectedPermissions;

    const commonPayload = {
      apps: stripProperties(
        jobRoleSettingsUtility.appAccessSettings,
        '__typename',
      ),
      permissions: Object.keys(selectedPermissionsById)
        .filter(id => typeof id !== 'undefined')
        .reduce<RolePermissions[]>((acc, id) => {
          const permission = {
            id,
            operations: selectedPermissionsById[id],
          };
          if (Array.isArray(permission.operations)) {
            if ((permission.operations as ResourceOperation[]).length > 0) {
              acc.push(permission);
            }
          }
          if (typeof permission.operations === 'boolean') {
            acc.push(permission);
          }
          return acc;
        }, []),
    };
    if (roleData?.id) {
      updateRole({ id: roleData.id, ...data, ...commonPayload });
    } else {
      createRole({ ...data, ...commonPayload });
    }
  });

  const onDeleteConfirm = useCallback(() => {
    deleteRole(roleData?.id as string);
  }, [deleteRole, roleData?.id]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backofficeManageRoles.deleteRoleMessage')}
        onConfirm={onDeleteConfirm}
      />,
    );
  }, [translate, showModal, onDeleteConfirm]);

  useEffect(() => {
    setReadOnly(roleData ? !roleData.isEditable : false);
  }, [roleData]);

  return (
    <ScreenLayout
      title={translate('navigation.jobRoles', {
        appName: translate('appName'),
      })}
      loading={loading}
      hideFooter={readOnly}
      onDelete={onPressDelete}
      onDeleteDisabled={readOnly}
      onSave={onSubmit}
    >
      {readOnly ? (
        <Message
          type="neutral"
          icon="info-circle"
          message={translate('backofficeManageRoles.readOnlyRoles')}
          containerStyle={styles.message}
        />
      ) : null}
      <Section
        layoutWidth="small"
        title={translate('backofficeManageRoles.roleDetails')}
      >
        <View style={theme.forms.row}>
          <ControlledFormInput
            name="name"
            control={control}
            readOnly={readOnly}
            title={translate('backofficeManageRoles.form.roleName')}
            placeholder={translate('backofficeManageRoles.form.roleName')}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      <Section
        layoutWidth="small"
        title={translate('backofficeManageRoles.permissionDetails')}
      >
        <SearchPermissionInput />
        <PermissionSection app={App.BACKOFFICE}>
          <OfficePermissions />
        </PermissionSection>
        <PermissionSection app={App.POS_APP}>
          <POSAppPermissions />
        </PermissionSection>
      </Section>
    </ScreenLayout>
  );
};
