import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { View } from 'react-native';
import {
  IntegrationApps,
  OnlineOrderingPreferences,
  TimeBlock,
  UpdateIntegrationPartnerInput,
  OrderType,
  OrderTypeCode,
  DeliverySettings,
  BaseOrderTypeSettings,
  DineInSettings,
  PickUpSettings,
  DEFAULT_COMPLETE_PERIOD_MINS,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useStores } from '../../../../../../../hooks/app/useStores';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import { useNotification } from '../../../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { keyBy, isEqual, mergeWith, isNull } from 'lodash';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import { convertAlphaNumbericToNumber } from '@oolio-group/client-utils';
import { useDevices } from '../../../../../../../hooks/app/useDevices';
import { useDeviceProfiles } from '../../../../../../../hooks/app/useDeviceProfiles';
import { useOrganization } from '../../../../../../../hooks/app/useOrganization';
import { useSession } from '../../../../../../../hooks/app/useSession';
import { useAdjustments } from '../../../../../../../hooks/app/useAdjustments';
import { isOrderTypeEnabled } from '../../../../../../../utils/orderTypesHelper';
import { ONLINE_ORDER_TYPES_CODE_SUPPORTED } from '../../../../../../../types/Common';
import { useModal } from '@oolio-group/rn-use-modal';
import { useIsFocused } from '@react-navigation/native';
import OrderTypeRow from './OrderTypesRow';
import DeliveryModal from './OrderTypeModals/DeliveryModal';
import DineInModal from './OrderTypeModals/DineInModal';
import PickUpModal from './OrderTypeModals/PickUpModal';
import theme from '../../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../../../components/Shared/Inputs/InputToggle';

export enum OrderAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

type IntegrationPreferences = OnlineOrderingPreferences & {
  operatingHoursMap?: { [key: string]: TimeBlock };
} & { locationId?: string; accountId?: string };

const defaultDeliverySettingsState: DeliverySettings = {
  deliveryFee: 0,
  deliveryTime: 30,
};

const defaultBaseSettingsState: BaseOrderTypeSettings = {
  enablePayLater: false,
  enablePayNow: true,
  adjustments: [],
};

export const Fulfilments: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [integrationPreferences, setIntegrationPreferences] =
    useState<IntegrationPreferences>({
      autoAcceptOrders: false,
      isPrepTimeEnabled: false,
      defaultPrepTime: 0,
      autoCompleteOrders: false,
      autoCompletePeriod: DEFAULT_COMPLETE_PERIOD_MINS,
      printDevice: '',
      orderTypes: [],
      deliverySettings: {
        ...defaultBaseSettingsState,
        ...defaultDeliverySettingsState,
      },
      pickUpSettings: { ...defaultBaseSettingsState },
      dineInSettings: { ...defaultBaseSettingsState },
    });

  const route = useRoute();
  const { storeId, app } = route.params as {
    storeId: string;
    app: IntegrationApps;
  };

  const {
    stores,
    loading: storesLoading,
    error: storesError,
  } = useStores({ storeId });

  const [session] = useSession();
  const { organization, getOrganizationById } = useOrganization();

  const isFocused = useIsFocused();

  useEffect(() => {
    session.currentOrganization?.id &&
      getOrganizationById(session.currentOrganization?.id);
  }, [getOrganizationById, session.currentOrganization?.id]);

  const { showModal } = useModal();

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue.id : '';
  }, [storeId, stores]);

  const { devices, getDevices } = useDevices({ storeId });
  const deviceSelectOptions = useMemo(() => {
    return Object.values(devices).map(device => ({
      label: device.name,
      value: device.id,
    }));
  }, [devices]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const { orderTypes: orderTypesData, getOrderTypes } = useDeviceProfiles({
    storeId,
    venueId,
  });
  const orderTypes = useMemo(() => {
    return orderTypesData.filter(
      orderType =>
        ONLINE_ORDER_TYPES_CODE_SUPPORTED.indexOf(orderType.code) !== -1,
    );
  }, [orderTypesData]);

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    createIntegrationPartner,
    operation,
    error: integrationErr,
  } = useIntegrationPartners();

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  useEffect(() => {
    if (storeId && isFocused) {
      getIntegrationPartnerSettings({
        appName: app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, app, storeId, isFocused]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;

      setIntegrationPreferences({
        autoAcceptOrders: preferences?.autoAcceptOrders || false,
        autoCompleteOrders: preferences?.autoCompleteOrders || false,
        autoCompletePeriod:
          preferences?.autoCompletePeriod === undefined ||
          preferences?.autoCompletePeriod === null
            ? DEFAULT_COMPLETE_PERIOD_MINS
            : preferences?.autoCompletePeriod,
        isPrepTimeEnabled: preferences?.isPrepTimeEnabled || false,
        defaultPrepTime: preferences?.defaultPrepTime || 0,
        printDevice: preferences?.printDevice || '',
        orderTypes:
          preferences?.orderTypes || orderTypes.map(orderType => orderType.id),
        deliverySettings: preferences?.deliverySettings || {
          ...defaultBaseSettingsState,
          ...defaultDeliverySettingsState,
        },
        pickUpSettings: preferences?.pickUpSettings || {
          ...defaultBaseSettingsState,
        },
        dineInSettings: preferences?.dineInSettings || {
          ...defaultBaseSettingsState,
        },
      });
    }
  }, [integrationPartners, storeId, orderTypes]);

  const {
    adjustments,
    getAllAdjustments,
    loading: adjustmentsLoading,
  } = useAdjustments({ venueId });

  useEffect(() => {
    getAllAdjustments();
  }, [getAllAdjustments]);

  const loading = storesLoading || integrationLoading || adjustmentsLoading;

  const error = storesError || integrationErr;

  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [isUpdated, app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const isSettingsChanged = useCallback(() => {
    const preferences =
      integrationPartners[storeId]?.preferences?.onlineOrdering;

    return (
      !!preferences &&
      (integrationPreferences?.autoAcceptOrders !==
        preferences?.autoAcceptOrders ||
        integrationPreferences?.isPrepTimeEnabled !==
          preferences?.isPrepTimeEnabled ||
        integrationPreferences?.defaultPrepTime !==
          preferences?.defaultPrepTime ||
        integrationPreferences?.autoCompleteOrders !==
          preferences?.autoCompleteOrders ||
        integrationPreferences?.autoCompletePeriod !==
          preferences?.autoCompletePeriod ||
        integrationPreferences?.printDevice !== preferences?.printDevice ||
        (integrationPreferences?.orderTypes &&
          preferences?.orderTypes &&
          !isEqual(
            [...integrationPreferences?.orderTypes].sort(),
            [...preferences?.orderTypes].sort(),
          )) ||
        (!!preferences?.deliverySettings &&
          !isEqual(
            integrationPreferences?.deliverySettings,
            preferences.deliverySettings,
          )) ||
        (preferences?.pickUpSettings &&
          !isEqual(
            integrationPreferences?.pickUpSettings,
            preferences.pickUpSettings,
          )) ||
        (preferences?.dineInSettings &&
          !isEqual(
            integrationPreferences?.dineInSettings,
            preferences.dineInSettings,
          )))
    );
  }, [
    integrationPartners,
    storeId,
    integrationPreferences?.autoAcceptOrders,
    integrationPreferences?.isPrepTimeEnabled,
    integrationPreferences?.defaultPrepTime,
    integrationPreferences?.autoCompleteOrders,
    integrationPreferences?.autoCompletePeriod,
    integrationPreferences?.printDevice,
    integrationPreferences?.orderTypes,
    integrationPreferences?.deliverySettings,
    integrationPreferences?.pickUpSettings,
    integrationPreferences?.dineInSettings,
  ]);

  const saveIntegrationSettings = useCallback(() => {
    let defaultPrintDeviceId = integrationPreferences?.printDevice;

    if (deviceSelectOptions.length && !defaultPrintDeviceId) {
      defaultPrintDeviceId = deviceSelectOptions?.[0]?.value;
    }

    const enabledOrderTypesIds = integrationPreferences?.orderTypes as string[];
    const sortUpdatedOrderType =
      enabledOrderTypesIds.length > 1
        ? [...enabledOrderTypesIds].sort(
            (firstOrderTypeId, secondOrderTypeId) => {
              const firstOrderType = orderTypes.find(
                orderType => orderType.id === firstOrderTypeId,
              );
              const secondOrderType = orderTypes.find(
                orderType => orderType.id === secondOrderTypeId,
              );
              return ONLINE_ORDER_TYPES_CODE_SUPPORTED.indexOf(
                firstOrderType?.code as string,
              ) >
                ONLINE_ORDER_TYPES_CODE_SUPPORTED.indexOf(
                  secondOrderType?.code as string,
                )
                ? 1
                : -1;
            },
          )
        : integrationPreferences?.orderTypes;

    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        integrationPartners[storeId].preferences?.onlineOrdering,
        {
          orderTypes: sortUpdatedOrderType,
          deliverySettings: isOrderTypeEnabled(
            enabledOrderTypesIds,
            orderTypes,
            OrderTypeCode.DELIVERY,
          )
            ? {
                ...integrationPreferences?.deliverySettings,
                adjustments:
                  integrationPreferences.deliverySettings?.adjustments?.map(
                    adjustment => adjustment.id,
                  ),
              }
            : {},
          pickUpSettings: isOrderTypeEnabled(
            enabledOrderTypesIds,
            orderTypes,
            OrderTypeCode.PICK_UP,
          )
            ? {
                ...integrationPreferences?.pickUpSettings,
                adjustments:
                  integrationPreferences.pickUpSettings?.adjustments?.map(
                    adjustment => adjustment.id,
                  ),
              }
            : {},
          dineInSettings: isOrderTypeEnabled(
            enabledOrderTypesIds,
            orderTypes,
            OrderTypeCode.DINE_IN,
          )
            ? {
                ...integrationPreferences?.dineInSettings,
                adjustments:
                  integrationPreferences.dineInSettings?.adjustments?.map(
                    adjustment => adjustment.id,
                  ),
              }
            : {},
          autoAcceptOrders: integrationPreferences?.autoAcceptOrders,
          isPrepTimeEnabled: integrationPreferences?.isPrepTimeEnabled,
          defaultPrepTime: integrationPreferences?.defaultPrepTime,
          autoCompleteOrders: integrationPreferences?.autoCompleteOrders,
          autoCompletePeriod: integrationPreferences?.autoCompletePeriod,
          printDevice: defaultPrintDeviceId,
          menuLayout: integrationPreferences?.menuLayout,
        },
        (sourceValue: unknown, destinationValue: unknown) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering,
        },
        link: '',
        venue: venueId,
      },
    ];

    if (app === IntegrationApps.OOLIO_STORE && !integrationPartners[storeId]) {
      createIntegrationPartner(
        stripProperties({ ...updateSetting[0] }, '__typename'),
      );
    } else {
      if (
        updateSetting[0].preferences?.onlineOrdering?.orderTypes?.length === 0
      ) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noActiveOrderTypes'),
        });
        return;
      }
      if (!isSettingsChanged()) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noSettingsChanged'),
        });
        return;
      }
      updateIntegrationPartnerSettings(
        stripProperties(updateSetting, '__typename'),
      );
    }
  }, [
    integrationPreferences?.printDevice,
    integrationPreferences?.orderTypes,
    integrationPreferences.deliverySettings,
    integrationPreferences.pickUpSettings,
    integrationPreferences.dineInSettings,
    integrationPreferences?.autoAcceptOrders,
    integrationPreferences?.isPrepTimeEnabled,
    integrationPreferences?.defaultPrepTime,
    integrationPreferences?.autoCompleteOrders,
    integrationPreferences?.autoCompletePeriod,
    integrationPreferences?.menuLayout,
    deviceSelectOptions,
    integrationPartners,
    storeId,
    orderTypes,
    app,
    venueId,
    createIntegrationPartner,
    isSettingsChanged,
    updateIntegrationPartnerSettings,
    showNotification,
    translate,
  ]);

  const onChangeMainDetails = useCallback((key, value) => {
    setIntegrationPreferences(prev => ({ ...prev, [key]: value }));
  }, []);

  const orderAcceptanceOptions = useMemo(
    () => [
      {
        value: OrderAcceptance.Enable,
        label: translate('backOfficeFeatures.enabled'),
      },
      {
        value: OrderAcceptance.Disable,
        label: translate('backOfficeFeatures.disabled'),
      },
    ],
    [translate],
  );

  const orderAutoCompletePeriodOptions = useMemo(
    () => [
      {
        value: '-1',
        label: translate('backOfficeFeatures.never'),
      },
      {
        value: '0',
        label: translate('backOfficeFeatures.instantly'),
      },
      {
        value: '15',
        label: translate('backOfficeFeatures.timePeriodMinute', { time: 15 }),
      },
      {
        value: '30',
        label: translate('backOfficeFeatures.timePeriodMinute', { time: 30 }),
      },
      {
        value: '45',
        label: translate('backOfficeFeatures.timePeriodMinute', { time: 45 }),
      },
      {
        value: '60',
        label: translate('backOfficeFeatures.timePeriodHour', { time: 1 }),
      },
    ],
    [translate],
  );

  const onUpdateOrderTypeSettings = useCallback(
    (
      settingName: string,
      settings: DeliverySettings | DineInSettings | PickUpSettings,
    ) => {
      onChangeMainDetails(settingName, settings);
    },
    [onChangeMainDetails],
  );

  const openOrderTypeModal = useCallback(
    (code: OrderTypeCode) => {
      showModal(
        (code === OrderTypeCode.DELIVERY && (
          <DeliveryModal
            deliverySettings={integrationPreferences.deliverySettings}
            onConfirm={onUpdateOrderTypeSettings}
            currencyCode={organization?.currencyCode}
            adjustments={adjustments}
          />
        )) ||
          (code === OrderTypeCode.PICK_UP && (
            <PickUpModal
              pickUpSettings={integrationPreferences.pickUpSettings}
              onConfirm={onUpdateOrderTypeSettings}
              adjustments={adjustments}
            />
          )) ||
          (code === OrderTypeCode.DINE_IN && (
            <DineInModal
              dineInSettings={integrationPreferences.dineInSettings}
              onConfirm={onUpdateOrderTypeSettings}
              adjustments={adjustments}
            />
          )),
      );
    },
    [
      adjustments,
      integrationPreferences.deliverySettings,
      integrationPreferences.dineInSettings,
      integrationPreferences.pickUpSettings,
      onUpdateOrderTypeSettings,
      organization?.currencyCode,
      showModal,
    ],
  );

  const toggleOnOrderType = useCallback(
    (orderTypeId: string) => {
      const activeOrderTypes =
        (integrationPreferences?.orderTypes && [
          ...integrationPreferences.orderTypes,
        ]) ||
        [];
      activeOrderTypes.push(orderTypeId);
      onChangeMainDetails('orderTypes', activeOrderTypes);
    },
    [integrationPreferences?.orderTypes, onChangeMainDetails],
  );

  const toggleOffOrderType = useCallback(
    (orderTypeId: string) => {
      const activeOrderTypes =
        (integrationPreferences?.orderTypes && [
          ...integrationPreferences.orderTypes,
        ]) ||
        [];
      const orderTypeIndex = activeOrderTypes.indexOf(orderTypeId);
      orderTypeIndex !== -1 && activeOrderTypes.splice(orderTypeIndex, 1);
      onChangeMainDetails('orderTypes', activeOrderTypes);
    },
    [integrationPreferences?.orderTypes, onChangeMainDetails],
  );

  const onToggleOrderType = useCallback(
    (orderTypeId: string, value: boolean) => {
      if (value) toggleOnOrderType(orderTypeId);
      else toggleOffOrderType(orderTypeId);
    },
    [toggleOffOrderType, toggleOnOrderType],
  );

  const onAutoCompleteUpdate = useCallback(
    value => {
      if (parseFloat(value) === -1) {
        onChangeMainDetails('autoCompleteOrders', false);
      } else {
        onChangeMainDetails('autoCompleteOrders', true);
        onChangeMainDetails('autoCompletePeriod', parseFloat(value));
      }
    },
    [onChangeMainDetails],
  );

  const onTogglePrepTime = useCallback(() => {
    onChangeMainDetails(
      'isPrepTimeEnabled',
      !integrationPreferences?.isPrepTimeEnabled,
    );
  }, [integrationPreferences?.isPrepTimeEnabled, onChangeMainDetails]);

  const renderOrderTypes = useMemo(() => {
    return (
      <Section title={translate('backOfficeFeatures.orderTypes')}>
        <View>
          {orderTypes?.map((type: OrderType, i: number) => (
            <OrderTypeRow
              key={i}
              orderType={type}
              onToggle={onToggleOrderType}
              enabled={
                integrationPreferences.orderTypes?.indexOf(type.id) !== -1
                  ? true
                  : false
              }
              onOpenModal={openOrderTypeModal}
            />
          ))}
        </View>
      </Section>
    );
  }, [
    integrationPreferences.orderTypes,
    onToggleOrderType,
    openOrderTypeModal,
    orderTypes,
    translate,
  ]);

  return (
    <ScreenLayout
      loading={loading}
      title="Fulfilments | Oolio"
      onSave={saveIntegrationSettings}
    >
      <Section title={translate('backOfficeFeatures.autoAcceptOrders')}>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-acceptance"
            title={translate('backOfficeFeatures.autoAcceptOrders')}
            options={orderAcceptanceOptions}
            selectedValue={
              integrationPreferences?.autoAcceptOrders
                ? OrderAcceptance.Enable
                : OrderAcceptance.Disable
            }
            onValueChange={value =>
              onChangeMainDetails(
                'autoAcceptOrders',
                value === OrderAcceptance.Enable ? true : false,
              )
            }
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-printDevice"
            title={translate('backOfficeFeatures.printRoutingSelection')}
            options={deviceSelectOptions}
            selectedValue={integrationPreferences.printDevice}
            onValueChange={value => onChangeMainDetails('printDevice', value)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            containerStyle={theme.forms.inputHalf}
            testID="toggle-prepTime"
            type="switch"
            isToggled={integrationPreferences?.isPrepTimeEnabled || false}
            onToggle={onTogglePrepTime}
            title={translate('backOfficeFeatures.prepTime')}
          />
        </View>
        {integrationPreferences?.isPrepTimeEnabled ? (
          <View style={theme.forms.row}>
            <InputText
              testID="input-prepTime"
              title={translate('backOfficeFeatures.defaultPrepTime')}
              value={integrationPreferences?.defaultPrepTime?.toString()}
              placeholder={translate('backOfficeFeatures.defaultPrepTime')}
              onChangeText={value =>
                onChangeMainDetails(
                  'defaultPrepTime',
                  convertAlphaNumbericToNumber(value),
                )
              }
              keyboardType="number-pad"
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        ) : (
          <></>
        )}
      </Section>
      <Section title={translate('backOfficeFeatures.autoCompleteOrders')}>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-completion"
            title={translate('backOfficeFeatures.autoCompleteOrders')}
            options={orderAutoCompletePeriodOptions}
            selectedValue={(integrationPreferences?.autoCompleteOrders
              ? integrationPreferences.autoCompletePeriod
              : orderAutoCompletePeriodOptions.find(
                  option => option.value === '-1',
                )?.value
            )?.toString()}
            onValueChange={onAutoCompleteUpdate}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      {renderOrderTypes}
    </ScreenLayout>
  );
};
