import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import theme from '../../../../common/default-theme';
import styles from './CartCourse.styles';
import Icon from '../../../Icon/Icon';
import { OTHER_COURSE } from '@oolio-group/domain';

export interface CartCourseProps {
  id: string;
  name: string;
  disabled?: boolean;
  autoFire: boolean;
  isCollapsed?: boolean;
  readonlyCourse?: boolean;
  onPress?: () => void;
  onToggleAutoFire?: (courseId: string) => void;
  onPressCourseHeader?: (id: string) => void;
  onCollapseCourse: (id: string, status: boolean) => void;
  children?: React.ReactNode;
}

const CartCourse: React.FC<CartCourseProps> = ({
  id,
  name,
  disabled,
  autoFire,
  isCollapsed,
  readonlyCourse,
  children,
  onToggleAutoFire,
  onPressCourseHeader,
  onCollapseCourse,
}) => {
  const handleCollapseCourse = useCallback(() => {
    onCollapseCourse(id, !isCollapsed);
  }, [id, isCollapsed, onCollapseCourse]);

  const handleToggleAutoFire = useCallback(() => {
    onToggleAutoFire && onToggleAutoFire(id);
  }, [id, onToggleAutoFire]);

  const handlePressCourseHeader = useCallback(() => {
    onPressCourseHeader && onPressCourseHeader(id);
  }, [id, onPressCourseHeader]);

  const isOtherCourse = id === OTHER_COURSE;

  const backgroundColor = isOtherCourse
    ? theme.colors.tealLight
    : disabled
    ? theme.colors.grey2
    : autoFire
    ? theme.colors.greenLight
    : theme.colors.orangeLight;

  const foregroundColor = isOtherCourse
    ? theme.colors.teal
    : disabled
    ? theme.colors.dark
    : autoFire
    ? theme.colors.green
    : theme.colors.orange;

  return (
    <View>
      <View style={styles.parent}>
        {!readonlyCourse && !isOtherCourse ? (
          <TouchableOpacity
            testID="btn-toggleFire"
            disabled={disabled}
            onPress={handleToggleAutoFire}
            style={[styles.indicator, { backgroundColor: backgroundColor }]}
          >
            <Icon
              size={16}
              name={autoFire ? 'bolt-alt' : 'bolt-slash'}
              color={disabled ? theme.colors.grey4 : foregroundColor}
            />
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          testID="btn-toggleCourseProducts"
          onPress={handlePressCourseHeader}
          style={[styles.name, { backgroundColor: backgroundColor }]}
        >
          <Text
            testID="name"
            style={[
              styles.nameText,
              { color: foregroundColor },
              isOtherCourse && styles.textAlign,
            ]}
          >
            {name}
          </Text>
          {!readonlyCourse ? (
            <TouchableOpacity
              testID="toggle-collapse"
              onPress={handleCollapseCourse}
            >
              <Icon
                size={20}
                name={isCollapsed ? 'angle-down' : 'angle-up'}
                color={foregroundColor}
              />
            </TouchableOpacity>
          ) : null}
        </TouchableOpacity>
      </View>
      {(!isCollapsed || readonlyCourse) && <View>{children}</View>}
    </View>
  );
};

export default CartCourse;
