import React, { FC } from 'react';
import { View, Text, ViewStyle, TextStyle } from 'react-native';
import styles from './CartNote.styles';

interface CartNoteProps {
  note: string;
  testID: string;
  productNote?: boolean;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
}

const CartNote: FC<CartNoteProps> = ({
  note,
  testID,
  productNote,
  containerStyle,
  textStyle,
}) => {
  return (
    <View
      style={[
        styles.container,
        productNote ? styles.product : null,
        containerStyle && containerStyle,
      ]}
    >
      <Text
        testID={testID}
        style={[
          styles.text,
          productNote ? styles.productNote : null,
          textStyle && textStyle,
        ]}
      >
        {note}
      </Text>
    </View>
  );
};

export default CartNote;
