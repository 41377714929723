import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  rowLight: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginHorizontal: 12,
    backgroundColor: theme.colors.grey4,
  },
  cellName: {
    width: 200,
    marginRight: 10,
  },
  cellType: {
    flex: 1,
  },
  cellOrderType: {
    width: 240,
    marginRight: 'auto',
  },
  cellDefault: {
    marginRight: 15,
  },
  headerName: {
    width: 220,
    marginRight: 10,
  },
  headerCount: {
    width: 80,
    marginRight: 22,
    textAlign: 'center',
  },
  headerDefault: {
    width: 50,
    marginLeft: 'auto',
  },
  count: {
    minWidth: 80,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
    fontVariant: ['tabular-nums'],
  },
});

export default styles;
