/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text } from 'react-native';
import styles from './StatCard.styles';
import { StatCardLargeProps } from './StatCard.types';
import theme from '../../../common/default-theme';

export const StatCardLarge: React.FC<StatCardLargeProps> = ({
  testID,
  title,
  subtitle,
  stats,
  style,
  containerStyles,
}) => {
  const getChangeColor = () => {
    switch (style) {
      case 'positive':
        return theme.colors.states.positive;
      case 'negative':
        return theme.colors.states.negative;
      default:
        return theme.colors.accent;
    }
  };

  return (
    <View style={containerStyles}>
      <View style={[styles.container, style && styles[style]]}>
        <View style={styles.header}>
          <Text testID={`${testID}-title`} style={styles.headerTitle}>
            {title}
          </Text>
          <Text testID={`${testID}-subtitle`} style={styles.headerSubtitle}>
            {subtitle}
          </Text>
        </View>
        <View style={styles.stats}>
          {stats.map(({ label, value, change }, i) => {
            const isLastChild = stats.length - 1 === i;

            return (
              <View
                key={i}
                style={[
                  styles.stat,
                  isLastChild && { flex: 1, alignItems: 'flex-end' },
                ]}
              >
                <Text
                  testID={`${testID}-value-${i}`}
                  style={[
                    styles.value,
                    isLastChild && { flexDirection: 'row-reverse' },
                    value === 'N/A' && { color: theme.colors.grey5 },
                  ]}
                >
                  {value}
                  <Text
                    testID={`${testID}-change-${i}`}
                    style={[styles.change, { color: getChangeColor() }]}
                  >
                    {change}
                  </Text>
                </Text>
                <Text testID={`${testID}-label-${i}`} style={styles.label}>
                  {label}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};
