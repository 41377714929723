import React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './GridItem.styles';
import Icon from '../../../../../../components/Icon/Icon';
import theme from '../../../../../../common/default-theme';

export interface BackNavigationButtonProps {
  onPress: () => void;
}

const BackNavigationButton: React.FC<BackNavigationButtonProps> = ({
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.tileBack}>
      <Icon name="arrow-left" size={20} color={theme.colors.dark} />
    </TouchableOpacity>
  );
};

export default BackNavigationButton;
