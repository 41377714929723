import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import { capitalCase } from 'change-case';
import { useTranslation } from '@oolio-group/localization';
import {
  DeviceProfile,
  OrderTypeCode,
  UpdateDeviceProfileDetailsInput,
  OrderIdentifier,
  DeviceProfileStatus,
} from '@oolio-group/domain';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';
import { useStores } from '../../../../../../hooks/app/useStores';
import { useNotification } from '../../../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import pick from 'lodash/pick';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import theme from '../../../../../../common/default-theme';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';

type dropDownType = {
  label: string;
  value: string;
};

const emptyOption = {
  value: '',
  label: 'Select...',
};

type DeviceProfileForm = Omit<DeviceProfile, 'orderTypes' | 'menu'> & {
  orderTypes: string[];
  menu: string;
};

export const KioskProfileDetails: React.FC = () => {
  const { showNotification } = useNotification();

  const { translate } = useTranslation();
  const [form, setForm] = useState<DeviceProfileForm>({
    printerOptions: {
      emailReceipt: false,
      printReceipt: false,
    },
  } as DeviceProfileForm);
  const { showModal, closeModal } = useModal();

  const route = useRoute();

  const params = route.params as {
    storeId: string;
    venueId: string;
    deviceProfileId: string;
  };

  const storeId = params.storeId;
  const deviceProfileId = params.deviceProfileId;
  const venueId = params.venueId;

  const {
    deviceProfiles,
    loading,
    orderTypes,
    getOrderTypes,
    deleteDeviceProfile,
    updateDeviceProfileDetails,
    error,
    updatedDeviceProfileId,
    deletedDeviceProfile,
  } = useDeviceProfiles({
    deviceProfileId,
    storeId,
    venueId,
  });

  const { stores } = useStores({ storeId });

  const navigation = useNavigation();

  useEffect(() => {
    if (deviceProfiles?.[deviceProfileId]) {
      setForm(form => {
        return {
          ...form,
          ...deviceProfiles[deviceProfileId],
          orderTypes: deviceProfiles[deviceProfileId].orderTypes?.map(
            orderType => orderType.id,
          ),
          menu: deviceProfiles[deviceProfileId].menu?.id,
        };
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId, storeId, orderTypes]);

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  const onChange = useCallback(
    (prop: string, value: string | boolean): void => {
      setForm(prev => ({
        ...prev,
        [prop]: value,
      }));
    },
    [],
  );

  const onChangeMultiSelect = useCallback((prop: string, value: string[]) => {
    setForm(prev => ({
      ...prev,
      [prop]: value,
    }));
  }, []);

  const menuOptions = useMemo(() => {
    let result: dropDownType[] = [];
    if (storeId && stores[storeId]?.catalogues?.length) {
      result = stores[storeId]?.catalogues?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }

    return [emptyOption, ...result];
  }, [stores, storeId]);

  const pricingGroupOptions = useMemo(() => {
    let result: dropDownType[] = [];
    if (stores[storeId]?.pricingGroups?.length) {
      result = stores[storeId]?.pricingGroups?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }
    return [emptyOption, ...result];
  }, [stores, storeId]);

  const orderIdentifierOptions = Object.values(OrderIdentifier).map(item => ({
    value: item,
    label: capitalCase(item),
  }));
  const handleUpdate = useCallback(() => {
    if (!deviceProfileId) return;
    const input = {
      id: deviceProfileId,
      name: form.name,
      pricingGroupId: form.pricingGroupId,
      menu: form.menu,
      orderTypes: form.orderTypes,
      printerOptions: pick(form.printerOptions, [
        'emailReceipt',
        'printReceipt',
      ]),
      orderIdentifier: form.orderIdentifier,
      enableBarcodeScan: form.enableBarcodeScan,
    } as UpdateDeviceProfileDetailsInput;
    updateDeviceProfileDetails(input);
  }, [
    deviceProfileId,
    form.menu,
    form.name,
    form.orderIdentifier,
    form.orderTypes,
    form.pricingGroupId,
    form.printerOptions,
    form.enableBarcodeScan,
    updateDeviceProfileDetails,
  ]);

  const onPressSave = useCallback(() => {
    if (!form.name) {
      return showNotification({
        message: translate('backOfficeDeviceProfiles.nameRequired'),
        error: true,
      });
    }

    if (form.orderTypes.length === 0) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.orderTypeRequired'),
      });
      return;
    }

    if (!form.menu) {
      return showNotification({
        message: translate('kiosk.pleaseEnterKioskMenu'),
        error: true,
      });
    }
    return handleUpdate();
  }, [form, handleUpdate, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
        venueId,
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
    venueId,
  ]);

  const onPressUnassignOrDelete = useCallback(() => {
    const name = deviceProfiles[deviceProfileId]?.name;

    form.status === DeviceProfileStatus.ASSIGNED
      ? showModal(
          <ConfirmationDialog
            title={translate('dialog.unassignTitle')}
            message={translate('dialog.unassignConfirmation')}
            onConfirm={() => {
              closeModal();
            }}
          />,
        )
      : showModal(
          <ConfirmationDialog
            title={translate('dialog.deleteTitle')}
            message={translate('dialog.deleteConfirmation', { label: name })}
            onConfirm={() => {
              closeModal();
              deleteDeviceProfile(deviceProfileId);
            }}
          />,
        );
  }, [
    deviceProfiles,
    deviceProfileId,
    form.status,
    showModal,
    translate,
    closeModal,
    deleteDeviceProfile,
  ]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${form.name || 'Kiosk Profile'} | Oolio`}
      onSave={onPressSave}
      onDelete={onPressUnassignOrDelete}
      onDeleteLabel={
        form.status === DeviceProfileStatus.ASSIGNED
          ? translate('button.unassign')
          : translate('button.delete')
      }
      onDeleteDisabled={
        deviceProfiles[deviceProfileId]?.isDefault || !deviceProfileId
      }
    >
      <Section title={translate('backOfficeSettings.registerDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('form.registerName')}
            value={form.name}
            placeholder={translate('form.registerName')}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-identifier"
            title={translate('storesSettings.orderIdentifier')}
            options={orderIdentifierOptions}
            selectedValue={form.orderIdentifier}
            onValueChange={onChange.bind(null, 'orderIdentifier')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-menu"
            title={translate('backOfficeFeatures.menu')}
            options={menuOptions}
            selectedValue={form.menu}
            onValueChange={onChange.bind(null, 'menu')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-pricelist"
            title={translate('backOfficeFeatures.priceList')}
            options={pricingGroupOptions}
            selectedValue={form.pricingGroupId}
            onValueChange={onChange.bind(null, 'pricingGroupId')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-orderTypes"
            title={translate('backOfficeFeatures.orderTypes')}
            selectedValues={form.orderTypes}
            options={orderTypes
              .filter(ot =>
                [OrderTypeCode.DINE_IN, OrderTypeCode.TAKEAWAY].includes(
                  ot.code as OrderTypeCode,
                ),
              )
              .map(order => ({
                label: order.name,
                value: order.id,
              }))}
            onValueChange={(value): void =>
              onChangeMultiSelect('orderTypes', value)
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>

      <Section title={translate('storesSettings.preferences')}>
        <InputToggle
          type="switch"
          testID="toggle-barcodeScanningMode"
          onToggle={() =>
            onChange('enableBarcodeScan', !form.enableBarcodeScan)
          }
          isToggled={form.enableBarcodeScan}
          title={translate('backOfficeSettings.barcodeScanningMode')}
          subtitle={translate(
            'backOfficeSettings.barcodeScanningModeDescription',
          )}
          containerStyle={theme.forms.row}
        />
      </Section>
    </ScreenLayout>
  );
};
