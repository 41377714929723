import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

export const styles = StyleSheet.create({
  container: {
    width: 440,
    alignSelf: 'center',
  },
  title: {
    height: 44,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  btnBack: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    marginRight: 44,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    padding: 30,
    marginTop: 8,
    rowGap: 20,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  productSection: {
    maxHeight: 400,
  },
  products: {
    rowGap: 4,
  },
  row: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    columnGap: 16,
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.transparent,
    backgroundColor: theme.colors.grey1,
  },
  selected: {
    borderColor: theme.colors.states.positive,
    backgroundColor: theme.colors.states.positiveXL,
  },
  text: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
});
