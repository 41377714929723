import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    padding: 16,
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.grey1,
  },
  text: {
    flex: 1,
    rowGap: 4,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
    fontVariant: ['tabular-nums'],
  },
  btnRemove: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.redLight,
  },
});

export default styles;
