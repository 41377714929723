import { useCallback, useEffect, useMemo, useState } from 'react';
import { PreferencesByPrinterProfile } from './DevicePrintingRow';
import { useRoute } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { PrinterProfileType } from '@oolio-group/domain';
import { usePrinters } from '../../../../../../../hooks/app/usePrinters';
import { usePrinterProfiles } from '../../../../../../../hooks/app/usePrinterProfiles';
import { usePrinterTemplates } from '../../../../../../../hooks/app/usePrinterTemplates';
import { Column } from '../../../../../../../components/TableComponent/TableComponent';
import {
  CreateOrUpdatePrintingOptions,
  PrintingOptions,
} from '@oolio-group/domain';
import { useDevices } from '../../../../../../../hooks/app/useDevices';

export type FormState = Record<string, PreferencesByPrinterProfile>;

type PreferencesByPrinterProfileType = Record<
  string,
  Array<PreferencesByPrinterProfile>
>;
export interface DropdownOption {
  label: string;
  value: string;
}

type PrinterTemplatesByPrinterProfileType = Record<
  string,
  Array<{
    label: string;
    value: string;
  }>
>;

export interface SuccessOrError {
  error?: boolean;
  success?: boolean;
  message: string;
}
export interface UsePrintingSetup {
  groupPreferencesByPrinterProfileType: PreferencesByPrinterProfileType;
  isLoading: boolean;
  titleMappings: Record<string, string>;
  columns: Column[];
  printerOptions: Array<DropdownOption>;
  printerTemplatesByPrinterProfileType: PrinterTemplatesByPrinterProfileType;
  onChange: (
    attr: 'printer' | 'template',
    _preference: PreferencesByPrinterProfile,
    value: string,
  ) => void;
  error?: string;
  saveOrUpdatePrintingSetup: () => Promise<SuccessOrError | undefined>;
  formData?: FormState;
}
export function usePrintingSetup(): UsePrintingSetup {
  const { params } = useRoute();
  const { storeId, deviceId } = params as {
    storeId: string;
    deviceId: string;
  };

  const {
    devices,
    createOrUpdateDevicePrintingOptions,
    loading: deviceLoading,
  } = useDevices({
    deviceId,
  });

  const { translate } = useTranslation();
  const {
    printers,
    getPrinters,
    error: printersError,
    loading: printerLoading,
  } = usePrinters({ storeId });
  const {
    printerTemplates,
    getPrinterTemplates,
    error: printerTemplateError,
    loading: printerTemplateLoading,
  } = usePrinterTemplates();

  const {
    printerProfiles,
    getPrinterProfiles,
    loading: printerProfilesLoading,
    error: printerProfilesError,
  } = usePrinterProfiles(false);

  const isLoading =
    printerProfilesLoading ||
    printerTemplateLoading ||
    printerLoading ||
    deviceLoading;
  const error = printerProfilesError || printerTemplateError || printersError;

  const [formData, setFormData] = useState<FormState>({});

  useEffect(() => {
    if (devices && devices[deviceId] && devices[deviceId].printingOptions) {
      const printingOptions = devices[deviceId]
        .printingOptions as PrintingOptions[];
      setFormData(
        printingOptions.reduce((acc, pref) => {
          return {
            ...acc,
            [pref.profile.id]: {
              printer: pref.printer?.id,
              template: pref.template?.id,
              profile: pref.profile.id,
              printerProfileType: pref.printerProfileType,
            },
          };
        }, {}),
      );
    }
  }, [devices, deviceId]);

  useEffect(() => {
    // get all printers
    getPrinters();
    // Get all printer profiles under this org
    getPrinterProfiles();
    // Get all printer templates at org level
    getPrinterTemplates();
  }, [getPrinterProfiles, getPrinters, getPrinterTemplates]);

  // Get all printerProfileTypes like BILLING, KITCHEN
  const printerProfileTypes: string[] = useMemo(
    () => Object.values(PrinterProfileType),
    [],
  );

  /**
   * Group all saved printing setup preferences by PrinterProfileType
   * and populate default / saved values onto template and profile attributes
   */
  const groupPreferencesByPrinterProfileType: PreferencesByPrinterProfileType =
    useMemo(
      () =>
        printerProfileTypes.reduce((acc, printerProfileType) => {
          return {
            ...acc,
            [printerProfileType]: Object.keys(printerProfiles || {})
              .filter(
                printerProfile =>
                  printerProfiles[printerProfile].printerProfileType ===
                  printerProfileType,
              )
              .map(printerProfile => {
                const _printerProfile = printerProfiles[printerProfile];
                return {
                  profile: _printerProfile.id,
                  name: _printerProfile.name,
                  printerProfileType: _printerProfile.printerProfileType,
                  template: formData?.[_printerProfile.id]?.template,
                  printer: formData?.[_printerProfile.id]?.printer,
                };
              }),
          };
        }, {}),
      [formData, printerProfiles, printerProfileTypes],
    );

  /**
   * Titles for PrinterProfileType section
   */
  const titleMappings: Record<string, string> = printerProfileTypes.reduce(
    (acc, printerProfileType) => {
      return {
        ...acc,
        [printerProfileType]: translate(`printingSetup.${printerProfileType}`),
      };
    },
    {},
  );

  /**
   * Group all available printerTemplates by PrinterProfileType
   */
  const printerTemplatesByPrinterProfileType: PrinterTemplatesByPrinterProfileType =
    printerProfileTypes.reduce((acc, printerProfileType) => {
      return {
        ...acc,
        [printerProfileType]: [{ value: '', label: 'Choose template' }].concat(
          printerTemplates
            .filter(_printerTemplate => {
              return printerProfileType === _printerTemplate.templateType;
            })
            .map(_printerTemplate => ({
              value: _printerTemplate.id,
              label: _printerTemplate.name,
            })),
        ),
      };
    }, {});

  /**
   * Printer options input for dropdown values
   */
  const printerOptions: Array<{
    label: string;
    value: string;
  }> = [{ value: '', label: 'Choose printer' }].concat(
    Object.keys(printers).map(_printerId => ({
      value: printers[_printerId].id,
      label: printers[_printerId].name,
    })),
  );

  /**
   * When any attribute is changes, this will update the formData, to contain the current printer template, printer and printer profile mappings
   */
  const onChange = useCallback(
    (
      attr: 'printer' | 'template',
      _preference: PreferencesByPrinterProfile,
      value: string,
    ): void => {
      setFormData(_form => ({
        ..._form,
        [_preference.profile as string]: {
          ...(_form[_preference.profile as string] || {}),
          ..._preference,
          [attr]: value,
        },
      }));
    },
    [],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: translate('printingSetup.columnTitles.printerProfile'),
          flex: 1,
          alignItems: 'flex-start',
        },
        {
          title: translate('printingSetup.columnTitles.printer'),
          flex: 1,
          alignItems: 'flex-start',
        },
        {
          title: translate('printingSetup.columnTitles.template'),
          flex: 1,
          alignItems: 'flex-start',
        },
      ] as Column[],
    [translate],
  );

  /**
   *
   * Validate for required fields
   * If we want to mandate printer selection use this
   *  const hasErrorObj = validateSelections(preferences, 'template');
   *
   */
  const validateSelections = useCallback(
    (
      printingOptions: Array<CreateOrUpdatePrintingOptions>,
    ): SuccessOrError | undefined => {
      // only check if printing option has either printer or template
      const checkingPrintingOptions = printingOptions.filter(
        printingOption => printingOption.printer || printingOption.template,
      );

      const invalidPrintingOption = checkingPrintingOptions.find(
        printingOption => {
          return !printingOption.printer || !printingOption.template;
        },
      );

      if (invalidPrintingOption) {
        return {
          error: true,
          message: `Assign a ${
            !invalidPrintingOption.printer ? 'Printer' : 'Template'
          } to ${printerProfiles[invalidPrintingOption.profile].name}`,
        };
      }
      return undefined;
    },
    [printerProfiles],
  );

  const saveOrUpdatePrintingSetup = useCallback(async (): Promise<
    SuccessOrError | undefined
  > => {
    if (Object.keys(formData).length === 0) {
      return undefined;
    }
    const printingOptions: Array<CreateOrUpdatePrintingOptions> = Object.values(
      formData,
    ).map(_formField => {
      return {
        profile: _formField.profile,
        template: _formField.template,
        printer: _formField.printer,
        printerProfileType: _formField.printerProfileType,
      } as CreateOrUpdatePrintingOptions;
    });

    const hasErrorObj = validateSelections(printingOptions);
    if (hasErrorObj) {
      return hasErrorObj;
    }

    const result = await createOrUpdateDevicePrintingOptions({
      deviceId,
      printingOptions,
    });
    if (result) {
      return {
        success: true,
        message: translate('printingSetup.successMessage'),
      };
    }
    return {
      error: true,
      message: 'Something went wrong',
    };
  }, [
    formData,
    createOrUpdateDevicePrintingOptions,
    translate,
    deviceId,
    validateSelections,
  ]);

  const value = useMemo(
    () => ({
      groupPreferencesByPrinterProfileType,
      isLoading,
      titleMappings,
      columns,
      printerOptions,
      printerTemplatesByPrinterProfileType,
      onChange,
      error,
      saveOrUpdatePrintingSetup,
      formData,
    }),
    [
      groupPreferencesByPrinterProfileType,
      isLoading,
      titleMappings,
      columns,
      printerOptions,
      printerTemplatesByPrinterProfileType,
      onChange,
      error,
      saveOrUpdatePrintingSetup,
      formData,
    ],
  );

  return value;
}
