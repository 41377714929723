import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 30,
    ...theme.border.solid,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    ...theme.shadow.elevated1,
  },
  icon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.black,
  },
  title: {
    fontSize: 16,
    marginTop: 20,
    lineHeight: 24,
    color: theme.colors.black,
  },
  subtitle: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
});

export default styles;
