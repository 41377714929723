import React, { FC } from 'react';
import {
  View,
  Text,
  ViewStyle,
  GestureResponderEvent,
  TouchableOpacity,
  TextStyle,
} from 'react-native';
import Icon from '../../Icon/Icon';
import styles, { getIcon } from './MessageAction.styles';
import { getBackgroundColor, getTextColor } from '../Message/Message.styles';

export interface MessageActionProps {
  testID?: string;
  message: string;
  type: 'positive' | 'neutral' | 'focus' | 'negative';
  containerStyle?: ViewStyle;
  labelStyle?: TextStyle;
  onPress?: (event?: GestureResponderEvent) => void;
  onPressClose?: (event?: GestureResponderEvent) => void;
  displayActionIcon?: boolean;
}

const MessageAction: FC<MessageActionProps> = ({
  testID,
  message,
  type,
  containerStyle,
  labelStyle,
  onPress,
  onPressClose,
  displayActionIcon = true,
}) => {
  const handleOnPress = (e: GestureResponderEvent) => {
    onPressClose && onPressClose(e);
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={[
          styles.container,
          containerStyle,
          { backgroundColor: getBackgroundColor(type) },
        ]}
      >
        <View style={styles.rowCell}>
          <View style={styles.iconContainer}>
            <Icon name={getIcon(type)} color={getTextColor(type)} />
          </View>
          <Text
            testID={testID}
            style={[
              styles.messageText,
              { color: getTextColor(type) },
              labelStyle,
            ]}
          >
            {message}
          </Text>
        </View>
        {displayActionIcon && (
          <TouchableOpacity
            onPress={e =>
              ['positive', 'negative'].includes(type) && handleOnPress(e)
            }
          >
            <Icon
              name={type === 'neutral' ? 'angle-right' : 'times'}
              color={getTextColor(type)}
            />
          </TouchableOpacity>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default MessageAction;
