import { useReporting } from '../../../../hooks/app/useReporting';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SalesDashboardReport from './SalesDashboardReport';
import { useNotification } from '../../../../hooks/Notification';
import { ReportingContext } from '../ReportingContext';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  ReportFilters,
  ReportFilterDropdown,
  Filters,
} from '@oolio-group/domain';
import { View } from 'react-native';
import { ContainerStyles } from '../styles/Component.styles';
import { buildFilterObject, setReportStates } from '../reportsHelper';
import { cloneJSON } from '@oolio-group/client-utils';
import {
  DateRangeFilterInput,
  DropDownFilter,
  HelperText,
  ReportWidget,
} from '../types';
import * as storage from '../../../../storage/interface';
import { CubejsApi } from '@cubejs-client/core';

export const SalesDashboardContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const { cubejsApi, filters: allFilters } = useContext(ReportingContext);
  const [reportProps, setReportProps] = useState<ReportWidget>({
    updateCount: 0,
    widgets: [],
  });
  const [filters, setFilters] = useState<FilterValue>({});

  const [dateRangeFilters, setDateRangeFilters] =
    useState<DateRangeFilterInput>();
  const styles = ContainerStyles();

  const { reportMetadata, error, getReportMetadata } = useReporting(cubejsApi);

  const ddFilters = useMemo(() => {
    if (allFilters) {
      return reportMetadata?.filters?.map((filter: ReportFilterDropdown) => {
        return {
          ...filter,
          values: allFilters[filter.key as string as keyof Filters],
        } as DropDownFilter;
      });
    }
  }, [reportMetadata?.filters, allFilters]);

  const { widgets, updateCount } = reportProps;
  const setWidgets = (updatedWidgets: Widget[]) => {
    setReportProps(prev => ({ ...prev, widgets: updatedWidgets }));
  };

  useEffect(() => {
    allFilters &&
      getReportMetadata &&
      getReportMetadata(HelperText.SALES_DASHBOARD);
  }, [allFilters, getReportMetadata]);

  useEffect(() => {
    if (reportMetadata?.widgets) {
      setReportStates(
        reportMetadata?.widgets,
        HelperText.SALES_DASHBOARD,
        WidgetChartType.LINE,
        setWidgets,
        setFilters,
        setDateRangeFilters,
      );
    }
  }, [reportMetadata?.widgets]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const updateFilters = useCallback((filter: string, value: string[]) => {
    setFilters(filters => {
      return {
        ...filters,
        [filter]: value,
      };
    });
  }, []);

  const updateDateRangeFilters = useCallback((value: DateRangeFilterInput) => {
    const toSet = buildFilterObject(value);
    setDateRangeFilters(toSet);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  const widgetsWithGranularity = reportMetadata?.widgets
    ?.filter(
      widget => widget.query.dateRangeFilters?.[0].granularity !== undefined,
    )
    .map(widget => widget.name);

  const updateReport = useCallback(() => {
    delete dateRangeFilters?.startDate;
    delete dateRangeFilters?.endDate;
    delete dateRangeFilters?.startTime;
    delete dateRangeFilters?.endTime;

    const reportFilters: ReportFilters = {
      filters: {
        ...filters,
      },
      dateRangeFilters: [dateRangeFilters as DateRangeFilter],
    };

    storage.setItem(HelperText.SALES_DASHBOARD, reportFilters);

    const updatedWidgets = reportProps.widgets.map(widget => {
      const updatedWidget = {
        ...widget,
        query: {
          ...widget?.query,
          ...reportFilters,
        },
      } as Widget;

      const widgetClone = cloneJSON(updatedWidget);

      if (widgetsWithGranularity?.indexOf(widget.name) === -1) {
        delete widgetClone.query.dateRangeFilters[0].granularity;
        return widgetClone;
      } else {
        return widgetClone;
      }
    });

    setReportProps(prev => ({
      updateCount: prev.updateCount + 1,
      widgets: updatedWidgets,
    }));
  }, [dateRangeFilters, filters, widgetsWithGranularity, reportProps.widgets]);

  const loading = widgets && widgets.length > 0 ? false : true;

  return (
    <View style={styles.pageStyle}>
      <SalesDashboardReport
        filters={filters}
        filterOptions={ddFilters || ([] as DropDownFilter[])}
        allFilters={allFilters || ({} as Filters)}
        updateFilters={updateFilters}
        resetFilters={resetFilters}
        updateReport={updateReport}
        dateRangeFilter={dateRangeFilters || ({} as DateRangeFilterInput)}
        updateDateRangeFilters={updateDateRangeFilters}
        options={{ loading }}
        widgets={widgets}
        cubejsApi={cubejsApi as CubejsApi}
        updateCount={updateCount}
      />
    </View>
  );
};

export default SalesDashboardContainer;
