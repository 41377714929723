import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { formatPhoneNumber, useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import { format } from 'date-fns';
import { Booking, BookingStatusEnum } from '@oolio-group/domain';
import { capitalCase } from 'change-case';
import { styles, getStatusTextColor } from './BookingSidePanel.styles';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';

interface Props {
  show: boolean;
  booking?: Booking;
  onDismiss: () => void;
  onUpdate: (id: string, status: BookingStatusEnum) => void;
}

interface RowProps {
  label: string;
  value: string;
  color?: string;
}

const DATE_FORMAT = 'MMM dd, hh:mm a';

const Row: React.FC<RowProps> = ({ label, value, color }) => {
  const formattedValue = value ? value : 'N/A';

  return (
    <View style={styles.row}>
      <Text style={styles.label}>{label}</Text>
      <Text style={[styles.value, { color: color }]}>{formattedValue}</Text>
    </View>
  );
};

const BookingSidePanel: React.FC<Props> = ({
  show,
  booking,
  onDismiss,
  onUpdate,
}) => {
  const { translate } = useTranslation();

  const onUpdateStatus = useCallback(
    (status: BookingStatusEnum) => {
      if (booking) {
        onUpdate(booking.id, status);
      }
    },
    [booking, onUpdate],
  );
  const safeHeight = theme.useSafeHeight();

  if (!show || !booking) return <></>;

  const { customer, status, priority } = booking || {};
  const customerName = `${customer?.firstName} ${customer?.lastName}`;

  const info = [
    {
      label: translate('bookings.bookingId'),
      value: booking.id.split('-')[0].toUpperCase(),
      color: theme.colors.black1,
    },
    {
      label: translate('printing.status'),
      value: capitalCase(status),
      color: getStatusTextColor(status),
    },
    {
      label: translate('bookings.prioritised'),
      value: priority ? 'Yes' : 'No',
      color: priority ? theme.colors.green : theme.colors.red,
    },
    {
      label: translate('onlineOrders.customerName'),
      value: customerName,
      color: theme.colors.black1,
    },
    {
      label: translate('onlineOrders.customerPhone'),
      value: formatPhoneNumber(customer.phone, { countryCode: 'AU' }),
      color: theme.colors.black1,
    },
    {
      label: translate('onlineOrders.customerEmail'),
      value: customer.email,
      color: theme.colors.black1,
    },
    {
      label: translate('salesDashboard.CreatedAt'),
      value: format(booking.createdAt, DATE_FORMAT),
      color: theme.colors.black1,
    },
    {
      label: translate('common.lastUpdatedAt'),
      value: format(booking.updatedAt, DATE_FORMAT),
      color: theme.colors.black1,
    },
    {
      label: translate('openOrders.notes'),
      value: booking.notes,
      color: booking.notes ? theme.colors.black1 : theme.colors.grey4,
    },
  ];

  const canCancel = [
    BookingStatusEnum.CREATED,
    BookingStatusEnum.READY,
    BookingStatusEnum.NO_SHOW,
  ].includes(status);

  const canCheckIn = [BookingStatusEnum.READY].includes(status);

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={onDismiss}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text testID="booking-id" style={styles.titleText}>
          {customerName}
        </Text>
      </View>
      <ScrollView style={styles.content}>
        <View style={styles.meta}>
          {info.map((item, index) => (
            <Row
              key={index}
              label={item.label}
              value={item.value}
              color={item.color}
            />
          ))}
        </View>
      </ScrollView>
      <View style={styles.actions}>
        {status === BookingStatusEnum.CREATED && (
          <TreatButton
            key="btn-ready"
            testID="btn-ready"
            height={50}
            type="neutral"
            label={translate('bookings.markAsReady')}
            onPress={() => onUpdateStatus(BookingStatusEnum.READY)}
          />
        )}
        {canCheckIn && (
          <TreatButton
            key="btn-checkedIn"
            testID="btn-checkedIn"
            height={50}
            type="positive"
            label={translate('bookings.checkIn')}
            onPress={() => onUpdateStatus(BookingStatusEnum.CHECKED_IN)}
          />
        )}
        {status === BookingStatusEnum.READY && (
          <TreatButton
            key="btn-noShow"
            testID="btn-noShow"
            height={50}
            type="negative"
            label={translate('bookings.markAsNoShow')}
            onPress={() => onUpdateStatus(BookingStatusEnum.NO_SHOW)}
          />
        )}
        {status === BookingStatusEnum.CHECKED_IN && (
          <TreatButton
            testID="btn-complete"
            height={50}
            type="positive"
            label={translate('button.complete')}
            onPress={() => onUpdateStatus(BookingStatusEnum.COMPLETED)}
          />
        )}
        {canCancel && (
          <TreatButton
            testID="btn-cancel"
            height={50}
            type="negativeLight"
            label={translate('bookings.cancelBooking')}
            onPress={() => onUpdateStatus(BookingStatusEnum.CANCELLED)}
          />
        )}
      </View>
    </View>
  );
};

export default BookingSidePanel;
