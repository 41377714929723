import React, { FC } from 'react';
import { View, Text } from 'react-native';
import { OrderTax } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import styles from './CartTotalItems.styles';

export interface CartOrderTotalItems {
  subTotal: number;
  rewards?: number;
  discounts: AdjustmentCartItem[];
  surcharges: AdjustmentCartItem[];
  paymentSurcharge?: number;
  tip?: number;
  deliveryFee?: number;
  serviceCharge?: number;
  roundingAmount?: number;
  taxes?: OrderTax[];
  orderTotal: number;
  payments?: number;
}

interface CartTotalItemRowProps {
  testID: string;
  label: string;
  value: number;
}

export interface AdjustmentCartItem {
  name: string;
  amount: number;
}

const CartTotalItemRow: FC<CartTotalItemRowProps> = ({
  testID,
  label,
  value,
}) => {
  const { formatCurrency } = useCurrency();

  return (
    <View testID={testID} style={styles.row}>
      <Text numberOfLines={1} style={styles.label}>
        {label}
      </Text>
      <Text numberOfLines={1} style={styles.value}>
        {formatCurrency(value || 0)}
      </Text>
    </View>
  );
};

const CartTotalItems: FC<CartOrderTotalItems> = ({
  subTotal,
  rewards,
  discounts,
  surcharges,
  paymentSurcharge,
  tip,
  deliveryFee,
  serviceCharge,
  taxes,
  orderTotal,
  payments,
}) => {
  const { translate } = useTranslation();

  return (
    <View style={styles.container}>
      <CartTotalItemRow
        testID="cart-subtotal"
        label={translate('payment.subTotal')}
        value={subTotal}
      />
      {rewards ? (
        <CartTotalItemRow
          testID="cart-rewardAmount"
          label={translate('customerLoyalty.rewardSummary')}
          value={0 - rewards}
        />
      ) : null}
      {discounts.map((discount, i) => (
        <CartTotalItemRow
          testID={`cart-discount-${i}`}
          key={`discount-${i}`}
          label={discount.name}
          value={discount.amount}
        />
      ))}
      {surcharges.map((surcharge, i) => (
        <CartTotalItemRow
          testID={`cart-surcharge-${i}`}
          key={`surcharge-${i}`}
          label={surcharge.name}
          value={surcharge.amount}
        />
      ))}
      {paymentSurcharge ? (
        <CartTotalItemRow
          testID="cart-paymentSurcharge"
          label={translate('shift.totalPaymentSurcharge')}
          value={paymentSurcharge}
        />
      ) : null}
      {tip ? (
        <CartTotalItemRow
          testID="cart-tip"
          label={translate('onlineOrders.tip')}
          value={tip}
        />
      ) : null}
      {deliveryFee ? (
        <CartTotalItemRow
          testID="cart-deliveryFee"
          label={translate('onlineOrders.deliveryFee')}
          value={deliveryFee}
        />
      ) : null}
      {serviceCharge ? (
        <CartTotalItemRow
          testID="cartTotal-serviceCharge"
          label={translate('onlineOrders.serviceCharge')}
          value={serviceCharge}
        />
      ) : null}
      {taxes?.map(({ tax, amount }, i) => (
        <CartTotalItemRow
          testID="cart-"
          key={`tax-${i}`}
          label={tax.name}
          value={amount}
        />
      ))}
      <CartTotalItemRow
        testID="cartTotal-orderTotal"
        label={translate('payment.orderTotal')}
        value={orderTotal}
      />
      {payments ? (
        <CartTotalItemRow
          testID="cart-payments"
          label={translate('payment.payments')}
          value={payments}
        />
      ) : null}
    </View>
  );
};

export default CartTotalItems;
