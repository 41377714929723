import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.teritiary1,
    ...theme.shadow.strong,
  },
  text: {
    fontSize: 32,
    lineHeight: 44,
    textAlign: 'center',
    color: theme.colors.states.focus,
    fontFamily: theme.fonts.semibold,
  },
  btnEdit: {
    position: 'absolute',
    right: 20,
  },
});

export default styles;
