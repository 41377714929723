import { isEmpty } from 'lodash';
import { Order, RewardAdjustment, RewardRule } from '@oolio-group/domain';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { getAndCombineAllRedeemedRewards } from '@oolio-group/client-utils';

const configTwoCols: TableUserConfig = {
  columns: {
    0: {
      width: 26,
      alignment: 'left',
      wrapWord: true,
    },
    1: {
      alignment: 'right',
      width: 10,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const configSingleCol: TableUserConfig = {
  columns: {
    0: {
      width: 36,
      alignment: 'left',
      wrapWord: true,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateLoyaltySnapshot = (order: Order) => {
  if (isEmpty(order.loyaltySnapshot)) {
    return null;
  }
  const redeemedRewards = getAndCombineAllRedeemedRewards(order);
  const availableRewards = order.loyaltySnapshot?.availableRewards;
  const loyalty = {
    pointsEarned: order.loyaltySnapshot?.pointsEarned,
    availableBalance: order.loyaltySnapshot?.availableBalance,
  };
  return {
    loyaltyHeader: getLoyaltyHeader(),
    redeemedRewardsHeader: getRedeemedRewardsHeader(),
    availableRewardsHeader: getAvailableRewardsHeader(),
    loyalty: generateLoyalty(loyalty),
    redeemedRewards: generateRedeemedRewards(redeemedRewards),
    availableRewards: generateAvailableRewards(availableRewards),
  };
};

function generateLoyalty(loyalty: {
  pointsEarned?: number;
  availableBalance?: number;
}) {
  const data = [
    ['Points Earned', loyalty.pointsEarned],
    ['Points Available', loyalty.availableBalance],
  ];

  return table(data, configTwoCols);
}

function generateRedeemedRewards(redeemedRewards?: RewardAdjustment[]) {
  if (!redeemedRewards?.length) {
    return null;
  }

  return table(
    redeemedRewards.map(adj => [
      `${adj.name} (-${
        adj.pointsRequired * (adj.itemQuantity || adj.quantity)
      })`,
      adj.itemQuantity || adj.quantity,
    ]),
    configTwoCols,
  );
}

function generateAvailableRewards(availableRewards?: RewardRule[]) {
  if (!availableRewards?.length) {
    return null;
  }

  return table(
    availableRewards.map(reward => [reward.rewardName]),
    configSingleCol,
  );
}

function getLoyaltyHeader() {
  return table([['Loyalty', '']], configTwoCols);
}

function getRedeemedRewardsHeader() {
  return table([['Rewards Redeemed', 'Qty']], configTwoCols);
}

function getAvailableRewardsHeader() {
  return table([['Available Rewards', '']], configTwoCols);
}
