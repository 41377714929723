/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import Svg, { Path, G, Defs, Mask, Use } from 'react-native-svg';

const Maestro: React.FC = () => {
  return (
    <Svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="20"
      viewBox="0 0 30 20"
    >
      <Defs>
        <Path
          id="path-1"
          d="M2 0h26a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        ></Path>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Mask id="mask-2" fill="#fff">
          <Use xlinkHref="#path-1"></Use>
        </Mask>
        <Use fill="#FAFAFA" fillRule="nonzero" xlinkHref="#path-1"></Use>
        <G fillRule="nonzero" mask="url(#mask-2)">
          <G transform="translate(5.25 4)">
            <Path
              fill="#7375CF"
              d="M6.825 10.9989797L12.63 10.9989797 12.63 1.31983196 6.825 1.31983196 6.825 10.9912917z"
            ></Path>
            <Path
              fill="#EB001B"
              d="M7.446 6.003a5.934 5.934 0 012.304-4.71 6.028 6.028 0 00-7.992.487 5.954 5.954 0 000 8.447 6.028 6.028 0 007.992.487 6.001 6.001 0 01-2.304-4.71"
            ></Path>
            <Path
              fill="#00A2E5"
              d="M19.5 6.007a5.98 5.98 0 01-1.755 4.233 6.08 6.08 0 01-7.995.48 6.03 6.03 0 002.302-4.713A5.962 5.962 0 009.75 1.294a6.02 6.02 0 017.995.48A5.939 5.939 0 0119.5 6.006"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default Maestro;
