import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 70,
    marginBottom: 10,
    flexBasis: '19%',
    marginRight: '1%',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    overflow: 'hidden',
    padding: 4,
  },
  addonPrice: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    color: theme.colors.black1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
    flex: 1,
    marginBottom: 10,
  },
  triangle: {
    top: 2,
    left: 2,
    width: 0,
    height: 0,
    position: 'absolute',
    borderTopWidth: 20,
    borderRightWidth: 20,
    borderRightColor: theme.colors.transparent,
  },
  selectionAction: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 38,
  },
  title: {
    flex: 1,
    padding: 8,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
