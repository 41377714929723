import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    height: '100%',
  },
  cellAction: {
    width: 40,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellMenus: {
    width: 140,
    justifyContent: 'center',
  },
  cellOrderTypes: {
    width: 180,
    justifyContent: 'center',
  },
  cellStores: {
    flex: 1,
    justifyContent: 'center',
  },
  filtersContainer: {
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    paddingVertical: 15,
    height: 'auto',
  },
  headerRoutine: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  headerDeleteIconContainer: {
    marginLeft: 'auto',
  },
  headerCategory: {
    width: 300,
    marginHorizontal: 10,
  },
  scheduleContentContainer: {
    marginTop: 20,
  },
  scheduleRowContainer: {
    marginBottom: 20,
  },
  colDays: {
    color: theme.colors.grey5,
    width: 260,
  },
  colStart: {
    color: theme.colors.grey5,
    fontSize: 13,
    width: 100,
  },
  colEnd: {
    color: theme.colors.grey5,
    fontSize: 13,
    width: 100,
  },
  routineRowContainer: {
    marginBottom: 10,
    alignItems: 'center',
  },
  routineDeleteIcon: {
    borderRadius: theme.radius.s,
  },
});

export default styles;
