import { gql } from '@apollo/client';

const storeSettingsFragment = `
  appName
  isActive
  preferences {
    ... on AccountingIntegrationStorePreferences {
      invoicePrefix
      defaultInvoiceStatus
      reconciliation
      accountsMap {
        varianceAccount
        moneyAccount
        defaultIncomeAccount
        tipsAccount
        cashRoundingAccount
        balanceAdjustmentAccount
        paymentSurchargeAccount
      }
      taxCodes
      paymentTypes
      productTypes
      emailPreferences
    }
  }
  store
  integrationId
  organization
`;

export const GET_ACCOUNTING_PARTNER_CONSENT_URL = gql`
  query getAccountingPartnerConsentUrl {
    getAccountingPartnerConsentUrl
  }
`;

export const OAUTH_CALLBACK = gql`
  mutation createAccountingIntegration($input: String) {
    createAccountingIntegration(input: $input) {
      id
      appName
      isActive
      preferences {
        accounting {
          xero {
            activeTenantName
          }
        }
      }
      modules {
        accounting
      }
    }
  }
`;

export const GET_ACCOUNTING_INTEGRATION_STORES_SETTINGS = gql`
  query getAccountingIntegrationStoresSettings(
    $integrationPartner: App
    $storeIds: [String]
  ) {
    getAccountingIntegrationStoresSettings(
      integrationPartner: $integrationPartner
      storeIds: $storeIds
    ) {
      ${storeSettingsFragment}
    }
  }
`;

export const TOGGLE_ACCOUNTING_INTEGRATION_STORE_CONNECTION = gql`
  mutation toggleAccountingIntegrationStoreConnection(
    $storeId: String
    $integrationPartner: App
  ) {
    toggleAccountingIntegrationStoreConnection(
      storeId: $storeId
      integrationPartner: $integrationPartner
    ) {
      ${storeSettingsFragment}
    }
  }
`;

export const GET_CHART_OF_ACCOUNTS = gql`
  query getChartOfAccounts {
    getChartOfAccounts {
      code
      name
      type
      enablePaymentsToAccount
    }
  }
`;

export const GET_TAX_RATES = gql`
  query getTaxRates($integrationPartner: App) {
    getTaxRates(integrationPartner: $integrationPartner) {
      name
      effectiveRate
      taxType
    }
  }
`;

export const UPDATE_ACCOUNTING_INTEGRATION_STORES_SETTINGS = gql`
  mutation updateAccountingIntegrationStoreSettings(
    $accountingIntegrationStoreSettings: IntegrationPartnerStoreConfigurationInput
  ) {
    updateAccountingIntegrationStoreSettings(
      accountingIntegrationStoreSettings: $accountingIntegrationStoreSettings
    ) {
      ${storeSettingsFragment}
    }
  }
`;

export const DISCONNECT_ACCOUNTING_PARTNER = gql`
  mutation disconnectAccountingIntegration($integrationPartner: App) {
    disconnectAccountingIntegration(integrationPartner: $integrationPartner)
  }
`;

export const SYNC_SHIFTS = gql`
  mutation syncStoreInvoice(
    $shiftId: String
    $storeId: String
    $integrationPartner: App
  ) {
    syncStoreInvoice(
      shiftId: $shiftId
      storeId: $storeId
      integrationPartner: $integrationPartner
    )
  }
`;

export const GET_STORE_SHIFTS_LOG = gql`
  query getStoreInvoiceLogs(
    $storeId: String
    $integrationPartner: App
    $dateRange: StoreInvoiceDateRange
  ) {
    getStoreInvoiceLogs(
      storeId: $storeId
      integrationPartner: $integrationPartner
      dateRange: $dateRange
    ) {
      shiftId
      org
      store
      success
      status
      message
      deviceName
      closedAt
      shiftNo
    }
  }
`;
