import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  cellName: {
    width: 300,
    marginRight: 10,
  },
  cellToggle: {
    width: 200,
    marginRight: 'auto',
  },
});

export default styles;
