import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useOptions } from '../../../../../hooks/app/useOptions';
import styles from './Variants.styles';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import Search from '../../../../../components/Shared/Search/Search';
import Section from '../../../../../components/Office/Section/Section';
import Pagination from '../../../../../components/Office/Pagination/Pagination';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import CreateVariantGroupModal from './CreateVariantGroup';

export const VariantGroups: React.FC = () => {
  const { showModal } = useModal();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { options, loading, createOption } = useOptions();

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredList = useMemo(() => {
    return Object.values(options)
      .filter(option =>
        option.key.toLowerCase().includes(searchText.toLowerCase()),
      )
      .sort((a, b) => a.key.localeCompare(b.key));
  }, [options, searchText]);

  const pageItems = useMemo(() => {
    return filteredList.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [filteredList, currentPage]);

  const onPressCreate = useCallback(() => {
    showModal(<CreateVariantGroupModal onCreate={createOption} />);
  }, [createOption, showModal]);

  return (
    <ScreenLayout
      hideFooter
      loading={loading}
      title={`${translate('variants.variants')} | Oolio`}
    >
      <Section
        layoutWidth="large"
        title={translate('variants.variants')}
        subtitle={translate('variants.screenSubtitle')}
      >
        <View style={styles.filters}>
          <Search
            testID="search-variations"
            placeholder={translate('variants.searchPlaceholder')}
            onChangeText={setSearchText}
            containerStyle={styles.search}
          />
          <CreateButton onPress={onPressCreate} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('variants.variantGroup')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerValues]}>
              {translate('variants.variants')}
            </Text>
          </View>
          {pageItems.length > 0 ? (
            pageItems.map((group, i) => {
              const isGroupEmpty = group.values.length < 1;
              return (
                <TouchableOpacity
                  key={group.id}
                  testID={`variantGroup-${i}`}
                  onPress={() =>
                    navigation.navigate('VariantGroupTabs', {
                      id: group.id,
                      name: group.key,
                    })
                  }
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={[theme.tables.row, { height: 50 }]}
                >
                  <Text style={styles.cellName}>{group.key}</Text>
                  <Text
                    style={[
                      styles.cellValues,
                      isGroupEmpty && { color: theme.colors.grey4 },
                    ]}
                    numberOfLines={1}
                  >
                    {!isGroupEmpty
                      ? group.values.join(', ')
                      : translate('productSettings.none')}
                  </Text>
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey4}
                    />
                  </View>
                </TouchableOpacity>
              );
            })
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {Object.values(options).length < 1
                  ? translate('variants.noneCreated')
                  : translate('common.noMatches', {
                      entity: searchText,
                    })}
              </Text>
            </View>
          )}
          <Pagination
            page={currentPage}
            onPageChange={setCurrentPage}
            dataLength={filteredList.length}
            pageLength={pageItems.length}
            entityName={translate('variants.variantGroups')}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
