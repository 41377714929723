import React, { FC } from 'react';
import { View, Text, ViewStyle, TouchableOpacity } from 'react-native';
import styles, { getBackgroundColor, getTextColor } from './Message.styles';
import Icon from '../../Icon/Icon';
import { getIcon } from '../MessageAction/MessageAction.styles';

export interface MessageProps {
  testID?: string;
  message: string;
  onClose?: () => void;
  icon?: string;
  type: 'positive' | 'neutral' | 'focus' | 'negative';
  containerStyle?: ViewStyle;
}

const Message: FC<MessageProps> = ({
  testID,
  message,
  onClose,
  type,
  icon,
  containerStyle,
}) => {
  const hasAccessory = Boolean(onClose || icon);

  return (
    <View
      style={[
        styles.container,
        containerStyle,
        { backgroundColor: getBackgroundColor(type) },
      ]}
    >
      <View style={styles.rowCell}>
        {icon && (
          <View style={styles.iconContainer}>
            <Icon name={getIcon(type)} color={getTextColor(type)} />
          </View>
        )}
        <Text
          testID={testID}
          style={[
            styles.messageText,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              textAlign: hasAccessory ? 'left' : 'center',
              color: getTextColor(type),
            },
          ]}
        >
          {message}
        </Text>
      </View>
      {onClose ? (
        <TouchableOpacity style={styles.icon} onPress={onClose}>
          <Icon name="times-circle" size={20} color={getTextColor(type)} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default Message;
