/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import Svg, {
  Path,
  G,
  Defs,
  Mask,
  Use,
  LinearGradient,
} from 'react-native-svg';

const EFTPOS: React.FC = () => {
  return (
    <Svg width="30" height="20" viewBox="0 0 30 20">
      <Defs>
        <Path
          id="path-1"
          d="M2 0h26a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        ></Path>
        <LinearGradient
          id="linearGradient-3"
          x1="50.442%"
          x2="31.03%"
          y1="64.329%"
          y2="-40.418%"
        >
          <stop offset="14.11%" stopColor="#FFF"></stop>
          <stop offset="75%" stopColor="#FFF" stopOpacity="0"></stop>
        </LinearGradient>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Mask id="mask-2" fill="#fff">
          <Use xlinkHref="#path-1"></Use>
        </Mask>
        <Use fill="#1F0038" fillRule="nonzero" xlinkHref="#path-1"></Use>
        <G fillRule="nonzero" mask="url(#mask-2)">
          <G>
            <Path
              fill="url(#linearGradient-3)"
              d="M6.505 1.917c-1.618.756-2.712 2.203-2.712 3.865 0 2.097 1.888 3.798 4.217 3.798 1.069 0 1.82-.253 2.699-.794.094-.057.198-.1.268-.01.04.05.023.113-.008.17A5.822 5.822 0 015.864 12C2.626 12 0 9.314 0 6c0-1.892.856-3.58 2.193-4.68C3.2.494 5.717.38 6.505 1.917z"
              transform="translate(9.5 4)"
            ></Path>
            <Path
              fill="#FFF"
              d="M10.902 4.24c0 1.993-1.86 3.609-4.156 3.609-.334 0-1.238-.047-1.885-.355-.184-.088-.165-.198-.039-.252.294-.124 2.6-.577 2.6-3.002S4.976.042 2.194 1.32A5.758 5.758 0 015.864 0c2.724 0 5.038 1.898 5.038 4.24z"
              transform="translate(9.5 4)"
            ></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default EFTPOS;
