import React, { useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { StyleFn, Widget, Colors } from '@oolio-group/domain';
import { View, Text } from 'react-native';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';

import { arePropsEqual } from '../reportsHelper';
import { CardComponentData, PivotTableData } from '../types';
import IconButton from '../../../../components/Button/IconButton';

interface BarChartProps {
  widget: Widget;
  helper: string;
  cubejsApi: CubejsApi;
  updateCount: number;
  dataTransformationFn?: (
    data: PivotTableData,
    widgetName: string,
  ) => CardComponentData;
}

const containerStyle: StyleFn = ({ theme }) => ({
  height: '100%',
  backgroundColor: theme.colors.lightBlueSelect,
  marginLeft: 5,
  marginRight: 5,
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 10px)',
  alignContent: 'center',
  justifyContent: 'space-between',
});

const textStyle: StyleFn = () => ({
  textAlign: 'center',
  fontSize: 12,
  padding: 5,
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontWeight: 600,
  color: theme.colors.text,
  paddingTop: theme.spacing.medium,
  textTransform: 'uppercase',
});

const bottomTextStyle: StyleFn = ({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing.medium,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.skyBlue,
});

const InfoCardComponent: React.FC<BarChartProps> = ({
  widget,
  helper,
  cubejsApi,
  dataTransformationFn,
}) => {
  const { showNotification } = useNotification();
  const { css } = useFela();

  const { loading, error, widgetData, getWidgetData } = useReporting(cubejsApi);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    widget.query && getWidgetData(widget, helper);
  }, [widget, getWidgetData, helper]);

  const data = useMemo(() => {
    if (!widgetData || !widget?.name || !widgetData[widget.name])
      return {} as ResultSet;
    return widgetData[widget.name] as ResultSet;
  }, [widgetData, widget]);

  const cardInfo = useMemo((): CardComponentData => {
    const result = data.tablePivot && data.tablePivot();
    if (dataTransformationFn && result) {
      return dataTransformationFn(result, widget.name) as CardComponentData;
    }
    return { title: '', value: '' };
  }, [data, dataTransformationFn, widget.name]);

  return (
    <View testID={'info-card'} style={css(containerStyle)}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text testID={'info-card-title'} style={css(textStyle, titleStyle)}>
            {widget.name}
          </Text>
          <Text testID={'info-card-icon-section'} style={css(textStyle)}>
            <IconButton
              testID={'info-card-icon'}
              icon={widget.icon as string}
              iconColor={Colors.blue}
              containerStyle={css(iconContainerStyle)}
              iconSize={20}
              containerSize={40}
              circular
            />
          </Text>
          <Text testID={'info-card-text'} style={css(textStyle)}>
            {cardInfo.title}
          </Text>
          <Text
            testID={'info-card-stats'}
            style={css(textStyle, bottomTextStyle)}
          >
            {cardInfo.value}
          </Text>
        </>
      )}
    </View>
  );
};

export const InfoCard = React.memo(InfoCardComponent, arePropsEqual);
