import { useCurrency, useTranslation } from '@oolio-group/localization';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { getPaymentSectionHeight, styles } from './RefundPaymentTypes.styles';
import {
  OrderPayment,
  RefundType,
  Order,
  DefaultPaymentTypes,
} from '@oolio-group/domain';
import TreatButton from '../../../../Shared/TreatButton/TreatButton';
import { v4 as uuidv4 } from 'uuid';
import { limitDecimalCount, sumDecimals } from '@oolio-group/order-helper';
import { keyBy } from 'lodash';
import { usePaymentTypes } from '../../../../../hooks/app/usePaymentTypes';
import { useNotification } from '../../../../../hooks/Notification';
import InputText from '../../../../Shared/Inputs/InputText';
import { getCardNameAndLast4Digit } from '@oolio-group/client-utils';
import Message from '../../../../Office/Message/Message';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../Icon/Icon';

interface OrderHistoryModalProps {
  originalPaymentDetails: OrderPayment[];
  onConfirm: (orderPayments: OrderPayment[]) => void;
  refundType: RefundType;
  refundOrderForSelectedProducts?: Order;
  remainingRefundAmount: number;
  getPaymentIcon: (paymentName: string, type: string) => JSX.Element;
}

interface OrderPaymentExtended extends OrderPayment {
  error: string;
}

interface PaymentTypeValue {
  [key: string]: OrderPaymentExtended;
}

const RefundPaymentTypesModal: React.FC<OrderHistoryModalProps> = ({
  originalPaymentDetails,
  onConfirm,
  refundType,
  refundOrderForSelectedProducts,
  remainingRefundAmount,
  getPaymentIcon,
}) => {
  const { currencySymbol, formatCurrency } = useCurrency();
  const { showNotification } = useNotification();
  const {
    paymentTypes,
    status: { error },
  } = usePaymentTypes({ fetchPolicy: 'cache-first' });

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const filteredOrderPayments = useMemo(() => {
    const orderPaymentIds = originalPaymentDetails.map(p => p.paymentType.id);
    const filteredOrderPayments = paymentTypes
      .filter(
        p =>
          ![
            DefaultPaymentTypes.CARD,
            DefaultPaymentTypes.ONLINE,
            DefaultPaymentTypes.ON_ACCOUNT,
          ].includes(p.name as DefaultPaymentTypes) &&
          !orderPaymentIds.includes(p.id),
      )
      .map(p => {
        const id = uuidv4();
        return {
          id,
          amount: 0,
          tendered: 0,
          paymentType: p,
        } as OrderPayment;
      });
    return [...originalPaymentDetails, ...filteredOrderPayments];
  }, [paymentTypes, originalPaymentDetails]);

  const getOrderPaymentTypeValue = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let payments: any[] = [];
    if (refundType === RefundType.By_PRODUCT) {
      const totalPaymentAmount =
        refundOrderForSelectedProducts?.totalPaymentAmount ?? 0;
      const defaultPayment = filteredOrderPayments.find(
        p => p.amount >= totalPaymentAmount,
      );
      payments = filteredOrderPayments.map(p => {
        if (defaultPayment?.id === p.id) {
          return {
            ...p,
            amount: p.amount,
            tendered: totalPaymentAmount,
          };
        } else {
          return { ...p, amount: p.amount, tendered: 0, paymentSurcharge: 0 };
        }
      });
    } else {
      payments = filteredOrderPayments.map(p => ({ ...p }));
    }
    return keyBy(payments, 'id');
  }, [filteredOrderPayments, refundType, refundOrderForSelectedProducts]);

  const [paymentTypeValue, setPaymentTypeValue] = useState<PaymentTypeValue>(
    getOrderPaymentTypeValue() as unknown as PaymentTypeValue,
  );

  const isValidNumber = (val = '') => {
    return Number(val || 0) >= 0 ? true : false;
  };

  const onChange = useCallback(
    (id, value) => {
      if (!isValidNumber(value)) return false;
      const payment = filteredOrderPayments.find(
        p =>
          p.id === id &&
          [DefaultPaymentTypes.CARD, DefaultPaymentTypes.ONLINE].includes(
            p.paymentType.name as DefaultPaymentTypes,
          ),
      );
      const paymentState = payment && paymentTypeValue[payment?.id];
      const validationError =
        paymentState && +value > paymentState.amount ? 'Error' : '';
      setPaymentTypeValue({
        ...paymentTypeValue,
        [id]: {
          ...paymentTypeValue[id],
          tendered: value,
          error: validationError || '',
        },
      });
    },
    [paymentTypeValue, filteredOrderPayments],
  );

  const displayTotalAmountToRefund = useMemo(() => {
    if (refundType === RefundType.FULL) {
      return remainingRefundAmount;
    } else if (refundType === RefundType.BY_AMOUNT) {
      return remainingRefundAmount;
    } else {
      return refundOrderForSelectedProducts?.totalPaymentAmount ?? 0;
    }
  }, [refundType, refundOrderForSelectedProducts, remainingRefundAmount]);

  const refundInput = useMemo(() => {
    const paymentsValue = Object.values(paymentTypeValue);
    const totalValue = paymentsValue.reduce(
      (acc, payment) => sumDecimals([acc, payment.tendered]),
      0,
    );
    const validationError = paymentsValue.reduce(
      (acc, payment) => acc || payment.error,
      '',
    );

    return {
      totalValue,
      validationError,
    };
  }, [paymentTypeValue]);

  const isValidPaymentValues = useMemo(() => {
    let isValid = true;
    if (refundInput.validationError) {
      isValid = false;
    }
    return isValid;
  }, [refundInput]);

  const renderPaymentRow = ({
    item,
  }: {
    item: OrderPaymentExtended;
    index: number;
  }) => {
    const tendered = paymentTypeValue[item.id]?.tendered || '';
    const error = paymentTypeValue[item.id]?.error;
    const PaymentIcon = getPaymentIcon(
      item.paymentType.name,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item?.paymentReceipt as any)?.cardType,
    );
    const paymentName = getCardNameAndLast4Digit(item);

    return (
      <View style={styles.row}>
        <View testID="payment-type-row" style={styles.upperRow}>
          <View style={styles.paymentIconSize}>{PaymentIcon}</View>

          <View style={styles.paymentSubText}>
            <Text numberOfLines={1}>
              {paymentName}
              {' - ' + formatCurrency(item.amount)}
            </Text>
          </View>

          <View>
            <InputText
              testID="amount-input"
              alignText="right"
              value={String(tendered)}
              placeholder={'0.00'}
              label={currencySymbol}
              onChangeText={value => onChange(item.id, value)}
              containerStyle={error ? styles.inputError : styles.formInput}
              maxLength={7}
              keyboardType="numeric"
            />
          </View>
        </View>
        {/* <View testID="payment-type-row" style={styles.bottomRow}>
          <View style={styles.subRow}>
            <Text style={styles.infoTitle}>refunded</Text>
            <Text style={styles.infoPositive}>Rem. $14.22</Text>
          </View>
          <View style={styles.subRow}>
            <Text style={styles.infoTitle}>Refund 1 – $10.00</Text>
            <Text style={styles.infoNegative}>refunded</Text>
          </View>
        </View> */}
      </View>
    );
  };

  const totalRefundValue = useMemo(() => {
    const remainingRefund = sumDecimals([
      displayTotalAmountToRefund,
      -refundInput.totalValue,
    ]);
    return {
      totalRefundValue: formatCurrency(displayTotalAmountToRefund),
      inputValue: formatCurrency(refundInput.totalValue),
      remainingRefundAmount: formatCurrency(remainingRefund),
      isRemainingExceeded: remainingRefund < 0 ? true : false,
    };
  }, [displayTotalAmountToRefund, refundInput.totalValue, formatCurrency]);

  const onPressRefundAmount = useCallback(() => {
    if (
      ([RefundType.FULL, RefundType.By_PRODUCT].includes(refundType) &&
        refundInput.totalValue !== displayTotalAmountToRefund) ||
      (refundType === RefundType.BY_AMOUNT &&
        refundInput.totalValue > displayTotalAmountToRefund)
    ) {
      showNotification({
        error: true,
        message: translate('refundOrder.refundAmountWarningMessage', {
          refundAmount: totalRefundValue.totalRefundValue,
        }),
      });
      return;
    }

    const orderPayments = filteredOrderPayments
      .map(payment => {
        const amountToRefund = limitDecimalCount(
          +paymentTypeValue[payment.id].amount,
        );
        const tenderedAmount = limitDecimalCount(
          +paymentTypeValue[payment.id].tendered,
        );

        return {
          ...payment,
          amount: amountToRefund,
          tendered: tenderedAmount,
        };
      })
      .filter(p => p.tendered);
    orderPayments?.length && onConfirm(orderPayments);
  }, [
    refundInput.totalValue,
    displayTotalAmountToRefund,
    filteredOrderPayments,
    onConfirm,
    showNotification,
    translate,
    totalRefundValue.totalRefundValue,
    paymentTypeValue,
    refundType,
  ]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={styles.container}
    >
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={closeModal}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {translate('refundOrder.issueRefund')}
        </Text>
      </View>

      <View style={styles.modal}>
        <View>
          <FlatList
            contentContainerStyle={styles.paymentTypeRowContainer}
            data={Object.values(paymentTypeValue)}
            renderItem={renderPaymentRow}
            showsVerticalScrollIndicator={false}
            bounces={false}
            ListHeaderComponent={
              <>
                {refundType === RefundType.BY_AMOUNT && (
                  <View style={styles.messageContainer}>
                    <Message
                      type="neutral"
                      message={translate(
                        'refundOrder.refundAmountDiscrepancyMessage',
                      )}
                    />
                  </View>
                )}
              </>
            }
            style={{
              height: getPaymentSectionHeight(
                filteredOrderPayments.length,
                refundType === RefundType.BY_AMOUNT,
              ),
            }}
          />
          <View style={styles.summary}>
            <View style={styles.summaryRow}>
              <Text style={styles.summaryText}>
                {translate(
                  refundType === RefundType.BY_AMOUNT
                    ? 'refundOrder.totalRefundableAmount'
                    : 'refundOrder.refundAmount',
                )}
              </Text>
              <Text style={styles.summaryText}>
                {totalRefundValue.totalRefundValue}
              </Text>
            </View>
          </View>
        </View>
        <TreatButton
          type="focus"
          testID="btn-refund"
          label={translate('refundOrder.issueRefund')}
          onPress={onPressRefundAmount}
          disabled={!isValidPaymentValues}
          containerStyle={styles.btn}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default RefundPaymentTypesModal;
