import { App, OrderAction } from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import { userUtility } from '../../state/userUtility';
import { currentOrderActionObservable } from './orders/ordersObservableUtils';
import { postSalesObservableForLogin } from './usePostSalesNavigation';
import useBehaviorSubjectState from './useSubjectState';

export const useSwitchPosUserEffect = () => {
  const navigation = useNavigation();
  const { value: loginFlowTrigged } = useBehaviorSubjectState(
    postSalesObservableForLogin,
  );

  useEffect(() => {
    const subscribe = currentOrderActionObservable.subscribe(value => {
      if (
        loginFlowTrigged &&
        (value?.isSyncComplete ||
          value?.lastOrderAction == OrderAction.ORDER_SAVE)
      ) {
        postSalesObservableForLogin.next(false);
        userUtility.clearPosUser();
        navigation.navigate('Lock', {
          app: App.POS_APP,
          skipInit: true,
        });
      }
    });
    return () => subscribe?.unsubscribe?.();
  }, [loginFlowTrigged, navigation]);
};
