import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  filters: {
    margin: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
  },
  btnReprint: {
    marginHorizontal: 10,
  },
  table: {
    marginBottom: 100,
    paddingHorizontal: 20,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginVertical: 8,
  },
  tableRow: {
    height: 50,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  cellStatus: {
    width: 70,
    marginLeft: 12,
    marginRight: 20,
    textAlign: 'center',
  },
  cellOrder: {
    width: 110,
    marginRight: 10,
  },
  cellType: {
    width: 60,
    marginRight: 10,
  },
  cellTime: {
    width: 130,
    marginRight: 10,
  },
  cellPrinter: {
    width: 120,
    marginRight: 10,
  },
  cellDescription: {
    width: 120,
    marginRight: 10,
  },
  cellMessage: {
    flex: 1,
    marginRight: 20,
  },
  cellRemove: {
    marginLeft: 10,
    marginRight: 12,
  },
  // Reprint Modal
  modal: {
    width: 340,
    alignSelf: 'center',
  },
  modalTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  modalBtnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitleText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    marginRight: 44,
  },
  modalBody: {
    padding: 20,
    marginTop: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  modalDropdown: {
    marginTop: 20,
    marginBottom: 30,
  },
});

export default styles;
