import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { storage } from '../../storage/interface';
import { Platform } from 'react-native';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
const POS_PERSISTENT_DEVICE_ID = 'FINGERPRINT_KEY';

export function useDeviceId(): {
  deviceId: string;
} {
  const [deviceId, setId] = useState(
    Platform.select({
      web: window.localStorage?.getItem?.(POS_PERSISTENT_DEVICE_ID),
      default: '',
    }),
  );

  useEffect(() => {
    (async () => {
      const persistentId = await storage.getItem(POS_PERSISTENT_DEVICE_ID);
      const id = persistentId || v4();
      if (!persistentId) {
        storage.setItem(POS_PERSISTENT_DEVICE_ID, id);
      }
      setId(id);
    })();
  }, []);

  return {
    deviceId,
  };
}
