import React, { useCallback, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import theme from '../../../../common/default-theme';
import InputText from '../../Inputs/InputText';
import TreatButton from '../../TreatButton/TreatButton';
import { analyticsService } from '../../../../analytics/AnalyticsService';

const ReportIssueModal: React.FC = ({}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [issueTitle, setIssueTitle] = useState('');
  const [issueText, setIssueText] = useState('');

  const onPressConfirm = useCallback(() => {
    analyticsService.capture('report_bug', {
      state: 'submitted',
    });
    closeModal();
  }, [closeModal]);

  const onCloseModal = useCallback(() => {
    analyticsService.capture('report_bug', {
      state: 'cancelled',
    });
    closeModal();
  }, [closeModal]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{translate('reportIssue.titleText')}</Text>
      <View>
        <InputText
          testID="title-field"
          placeholder="Add title..."
          title={translate('reportIssue.titleField')}
          value={issueTitle}
          onChangeText={setIssueTitle}
          containerStyle={theme.forms.inputFluid}
        />
        <InputText
          multiline
          testID="message-field"
          placeholder="Full description of the issue..."
          title={translate('reportIssue.messageField')}
          value={issueText}
          onChangeText={setIssueText}
          containerStyle={styles.textarea}
          maxLength={300}
        />
      </View>
      <View style={styles.actions}>
        <TreatButton
          testID="btn-dismiss"
          type="cancel"
          label={translate('button.dismiss')}
          onPress={onCloseModal}
        />
        <TreatButton
          testID="btn-report"
          type="neutral"
          label={translate('reportIssue.titleText')}
          onPress={onPressConfirm}
          containerStyle={styles.btnConfirm}
        />
      </View>
    </View>
  );
};

export default ReportIssueModal;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    padding: 30,
    width: 340,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.light,
  },
  title: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    marginTop: 10,
    marginLeft: 12,
    marginBottom: 30,
    color: theme.colors.states.neutral,
  },
  textarea: {
    marginTop: 20,
  },
  actions: {
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  btnConfirm: {
    marginLeft: 10,
  },
});
