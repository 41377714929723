import React, { useCallback, useEffect, useMemo } from 'react';
import { Text, View, ScrollView, Platform } from 'react-native';
import { App, Resource } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import {
  DrawerActions,
  useLinkProps,
  useNavigationState,
} from '@react-navigation/native';
import { useReactiveVar } from '@apollo/client';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { userUtility } from '../../../state/userUtility';
import { useModal } from '@oolio-group/rn-use-modal';
import { useIntercom } from '../../../hooks/Intercom/useIntercom';
import { NavigationItem } from './NavigationItem';
import ReportIssueModal from '../../Shared/Modals/ReportIssue/ReportIssueModal';
import styles from './Navigation.styles';
import ButtonIcon from '../../Shared/TreatButton/ButtonIcon';
import * as Unicons from '@oolio-group/react-native-unicons';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';
import { failedPrintJobsCountVar, trainingModeVar } from '../../../state/cache';
import { isWeb } from '../../../common/theme';

interface RouteState {
  index?: number;
  key: string;
  name?: string;
  params?: {
    [key: string]: string;
  };
  state?: RouteState;
  routeNames?: string[];
  routes?: RouteState[];
}

function getFullRoutePath(route: RouteState): string[] {
  if (route?.state?.routes) {
    const idx = route?.state?.index || 0;
    const subRoute = route?.state?.routes[idx];
    const routeName = route?.state?.routes[idx]?.name || '';
    const screenName = route?.state?.routes[idx]?.params?.screen || '';
    const paths = getFullRoutePath(subRoute as RouteState) || [];
    if (subRoute?.params?.title) {
      return [...paths];
    } else {
      return [routeName, screenName, ...paths] as string[];
    }
  } else {
    const title: string = route?.params?.title || '';
    return [title];
  }
}

const Navigation: React.FC<DrawerContentComponentProps> = ({
  navigation,
  descriptors,
  state,
}) => {
  const failedPrintJobsCount = useReactiveVar<number>(failedPrintJobsCountVar);
  const trainingMode = useReactiveVar<boolean>(trainingModeVar);
  const { translate } = useTranslation();
  const [session] = useSession();
  const { isConnected } = useNetworkStatus();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const route = useNavigationState(
    state => state.routes[state.routes.length - 1],
  );
  const { canActiveUserAccessBackOffice, activeUser, canI } =
    usePOSUserAuthorization();
  const isMultiBannerEnabled = useMemo(
    () => failedPrintJobsCount > 0 && trainingMode,
    [failedPrintJobsCount, trainingMode],
  );

  const closeDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.closeDrawer());
  }, [navigation]);

  const onPressDrawerClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onPressSwitchUser = useCallback(
    routeName => {
      // Reset pos user
      userUtility.clearPosUser();

      navigation.navigate(routeName, {
        app: App.POS_APP,
      });
    },
    [navigation],
  );

  const onPressBackOffice = useCallback(async () => {
    if (isConnected) {
      const userActivity = userUtility.userActivity;
      const recentOfficeUser = userUtility.recentOfficeUser;
      if (canActiveUserAccessBackOffice) {
        if (
          userActivity.recentUserId === session.user?.id &&
          recentOfficeUser?.id === userActivity.recentUserId
        ) {
          if (recentOfficeUser.active) {
            navigation.reset({ index: 0, routes: [{ name: 'BackOffice' }] });
          } else {
            userUtility.clearPosUser();
            // ask the person to login to office
            navigation.navigate('Lock', {
              app: App.BACKOFFICE,
            });
          }
        } else {
          userUtility.clearPosUser();
          // ask the person to login
          navigation.navigate('Lock', {
            app: App.BACKOFFICE,
          });
        }
      } else {
        showNotification({
          message: translate('appAccess.noOfficeAccess'),
          info: true,
        });
        closeDrawer();
      }
    } else {
      showNotification({
        message: translate('order.offlineWarning'),
        info: true,
      });
      closeDrawer();
    }
  }, [
    navigation,
    translate,
    showNotification,
    canActiveUserAccessBackOffice,
    session.user?.id,
    closeDrawer,
    isConnected,
  ]);

  const onPressShifts = useCallback(
    routeName => {
      const hasAccess = canI([{ onResource: Resource.VIEW_SHIFT_SUMMARY }], {
        prompt: true,
      });
      closeDrawer();
      if (hasAccess) {
        navigation.navigate(routeName);
      }
    },
    [canI, navigation, closeDrawer],
  );

  const onPressManageMoney = useCallback(
    routeName => {
      const hasAccess = canI([{ onResource: Resource.ALLOW_MONEY_MOVEMENTS }], {
        prompt: true,
      });
      closeDrawer();
      if (hasAccess) {
        navigation.navigate(routeName);
      }
    },
    [canI, navigation, closeDrawer],
  );

  const { onPress: linkToTakeOrder } = useLinkProps({ to: '/pos/order' });
  const { onPress: linkToCustomer } = useLinkProps({ to: '/pos/customers' });
  const { onPress: linkToFloorView } = useLinkProps({
    to: '/pos/orders/floor-view/',
  });

  const onNavigateWithUrl = useCallback(
    (linkTo: Function, routeName: string, screenName: string) => {
      if (Platform.OS === 'web') {
        linkTo();
      } else {
        navigation.navigate(routeName, {
          screen: screenName,
        });
      }
    },
    [navigation],
  );

  const onPressReportIssue = useCallback(() => {
    closeDrawer();
    showModal(<ReportIssueModal />);
  }, [closeDrawer, showModal]);

  const Intercom = useIntercom();

  const onPressSupport = useCallback(() => {
    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          ...Platform.select({
            web: {
              companyId: session?.currentOrganization?.id || '',
            },
            native: {
              id: session?.currentOrganization?.id || '',
            },
          }),
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };

    Intercom.start(IntercomUser, Intercom.show);
    closeDrawer();
  }, [Intercom, closeDrawer, session]);

  useEffect(() => {
    if (route.state) {
      if (Platform.OS === 'web') {
        Intercom.update();
      }
      const routePaths = getFullRoutePath(route as RouteState);
      const filteredRoutePaths = routePaths.filter(
        (path, idx) => !!path && routePaths.indexOf(path) === idx,
      );

      const refactorRoutePaths = filteredRoutePaths.map((path, idx) => {
        return idx === 0 ? path : `/ ${path}`;
      });

      const getRouteString = () => {
        let level1Route = '';
        let level2Route = '';
        refactorRoutePaths.map((route, idx) => {
          if (idx === 0) {
            level1Route = route;
          } else {
            level2Route = route;
          }
        });
        return level1Route + level2Route;
      };

      Intercom.logEvent('navigation', { route: getRouteString() });
    }
  }, [route, Intercom]);

  useEffect(() => {
    /**
     * When trying to access POS app url without PIN verification, redirects to Lock
     */
    if (userUtility.posUser === undefined) {
      onPressSwitchUser('Lock');
    }
  }, [onPressSwitchUser]);

  const onPressDrawerItem = useCallback(
    (routeName, screenName) => {
      if (screenName === 'TakeOrder') {
        analyticsService.capture('btn_new_order', {
          location: 'Navigation',
        });
      }

      analyticsService.capture('navigation_pos', {
        screen: screenName,
      });

      const actionHandlers: Record<string, Function> = {
        Lock: onPressSwitchUser,
        OfficeSettings: onPressBackOffice,
        ManageMoney: onPressManageMoney,
        Shifts: onPressShifts,
        FloorViewStack: onNavigateWithUrl.bind(
          null,
          linkToFloorView,
          'Orders',
          'FloorView',
        ),
        Orders: onNavigateWithUrl.bind(
          null,
          linkToTakeOrder,
          'Orders',
          'TakeOrder',
        ),
        Customers: onNavigateWithUrl.bind(
          null,
          linkToCustomer,
          'Customers',
          'Customers',
        ),
      };
      if (actionHandlers[routeName]) {
        actionHandlers[routeName].call(null, routeName, screenName);
      } else if (routeName) {
        navigation.navigate(routeName, {
          screen: screenName,
        });
      }
    },
    [
      onPressSwitchUser,
      onPressBackOffice,
      navigation,
      onPressManageMoney,
      onPressShifts,
      onNavigateWithUrl,
      linkToTakeOrder,
      linkToFloorView,
      linkToCustomer,
    ],
  );

  const drawerItems = useMemo(() => {
    return Object.values(descriptors).map((route, i) => {
      const {
        options: { drawerIcon, drawerLabel, title },
      } = route;
      return (
        <NavigationItem
          key={i}
          icon={drawerIcon}
          screenName={title}
          title={drawerLabel as string}
          onPress={onPressDrawerItem}
          routeName={state.routeNames[i]}
        />
      );
    });
  }, [descriptors, onPressDrawerItem, state.routeNames]);

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.menu,
          isMultiBannerEnabled && isWeb && styles.menuSpacing,
        ]}
      >
        <ButtonIcon
          size={38}
          icon="times"
          type="accent"
          onPress={onPressDrawerClose}
        />
        <ScrollView>
          <View style={styles.menuGroup}>{drawerItems}</View>
          <View style={styles.menuGroup}>
            <NavigationItem
              title={translate('button.office')}
              onPress={onPressBackOffice}
              icon={Unicons.UilDesktop}
            />
            <NavigationItem
              title={translate('drawer.support')}
              onPress={onPressSupport}
              icon={Unicons.UilChat}
            />
            <NavigationItem
              title={translate('reportIssue.titleText')}
              onPress={onPressReportIssue}
              icon={Unicons.UilBug}
            />
          </View>
        </ScrollView>
      </View>
      {session ? (
        <View testID="posDetails" style={styles.infoContainer}>
          <Text style={styles.infoTitle} numberOfLines={1}>
            {session.currentVenue?.name}
          </Text>
          <Text style={styles.infoSubtitle} numberOfLines={1}>
            {session.currentStore?.name}
          </Text>
          <Text style={styles.infoSubtitle} numberOfLines={1}>
            {session.device?.name}
          </Text>
          <Text
            style={styles.infoSubtitle}
            testID="posUserName"
            numberOfLines={1}
          >
            {activeUser?.name}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

export default Navigation;
