import React, { useEffect, useRef } from 'react';
import { Text, View, ViewStyle, TextInput, TextInputProps } from 'react-native';
import { Icon } from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import styles from './Inputs.styles';
import { isValidEmail } from '../../../utils/validator';

export interface InputEmailProps extends TextInputProps {
  title?: string;
  testID: string;
  value?: string | undefined;
  placeholder: string;
  errorMessage?: string;
  containerStyle?: ViewStyle | ViewStyle[];
}

const InputEmail: React.FC<InputEmailProps> = ({
  title,
  value,
  testID,
  placeholder,
  errorMessage,
  containerStyle,
  ...props
}: InputEmailProps) => {
  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const input = inputRef.current;
      if (input && !!props.editable && props.autoFocus === true) {
        input.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef, props.autoFocus, props.editable]);

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
          ]}
        >
          <TextInput
            testID={testID}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={theme.colors.grey5}
            keyboardType="email-address"
            style={styles.inputContainer}
            autoComplete="email"
            autoCapitalize="none"
            autoCorrect={false}
            {...props}
            ref={inputRef}
          />
        </View>
        <View style={styles.accessory}>
          {value && value.length > 0 ? (
            <Icon
              size={20}
              color={
                isValidEmail(String(value))
                  ? theme.colors.states.positive
                  : theme.colors.states.negative
              }
              name={
                isValidEmail(String(value)) ? 'Check' : 'ExclamationTriangle'
              }
            />
          ) : undefined}
        </View>
      </View>
      {!!errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
    </View>
  );
};

export default InputEmail;

InputEmail.defaultProps = {
  editable: true,
};
