import React from 'react';
import { FilterObject } from '@oolio-group/domain';
import { FilterInputStyles } from '../styles/Component.styles';
import SelectMultiple from '../../../../components/Shared/Select/SelectMultiple';
import { useTranslation } from '@oolio-group/localization';

interface FilterInputProps {
  options: FilterObject[];
  name: string;
  title: string;
  index: number;
  selectedValues: string[];
  showLabel?: boolean;
  updateFilters: (filter: string, value: string[]) => void;
}

export const FilterInput: React.FC<FilterInputProps> = ({
  options,
  name,
  title,
  index,
  selectedValues,
  updateFilters,
  showLabel = true,
}) => {
  const { translate } = useTranslation();
  const styles = FilterInputStyles(1000 - 10 * index);
  const filterOptions = options || [];

  return (
    <SelectMultiple
      testID="select-multiple"
      title={showLabel ? translate(title) : undefined}
      options={filterOptions.map(option => ({
        label: option.name,
        value: option.id,
      }))}
      selectedValues={selectedValues || []}
      onValueChange={(value): void => updateFilters(name, value)}
      containerStyle={styles.fieldViewStyle}
    />
  );
};
