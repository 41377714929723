import React, { useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { StyleFn, Widget } from '@oolio-group/domain';
import { View, Text, ViewStyle } from 'react-native';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';
import { useCurrency } from '@oolio-group/localization';

import {
  arePropsEqual,
  DEFAULT_METRIC_WRAPPER,
  getMetricByType,
} from '../reportsHelper';
import { CardComponentData } from '../types';

interface MetricCardProps {
  key: string;
  width: string | undefined;
  data: CardComponentData;
  loading: boolean;
}

interface MetricContainerProps {
  widget: Widget;
  helper: string;
  cubejsApi: CubejsApi;
  updateCount: number;
}

const containerStyle: StyleFn = ({ theme }) => ({
  height: '100%',
  backgroundColor: theme.colors.successLightest,
  marginLeft: 5,
  marginRight: 5,
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 10px)',
  alignContent: 'center',
  justifyContent: 'space-between',
});

const numberStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  fontSize: 24,
  fontWeight: 'bold',
  color: theme.colors.green,
  height: '60%',
  padding: 5,
  paddingTop: theme.spacing.medium,
});

const textStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  fontSize: 12,
  fontWeight: 600,
  color: theme.colors.text,
  height: '40%',
  padding: 5,
  textTransform: 'uppercase',
});

export const MetricCard: React.FC<MetricCardProps> = ({
  key,
  width,
  data,
  loading,
}) => {
  const { translate } = useTranslation();
  const { css } = useFela();

  return (
    <View
      testID={'metric-card'}
      key={`metric-card-${key}`}
      style={{ ...DEFAULT_METRIC_WRAPPER, width } as ViewStyle}
    >
      <View style={css(containerStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Text style={css(numberStyle)}>{data.value}</Text>
            <Text style={css(textStyle)}>{translate(data.title)}</Text>
          </>
        )}
      </View>
    </View>
  );
};

const MetricCardWrapperComp: React.FC<MetricContainerProps> = ({
  widget,
  helper,
  cubejsApi,
}) => {
  const { showNotification } = useNotification();
  const { appendCurrency } = useCurrency();

  const { loading, error, widgetData, getWidgetData } = useReporting(cubejsApi);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    widget.query && getWidgetData(widget, helper);
  }, [widget, getWidgetData, helper]);

  const data = useMemo(() => {
    if (!widgetData || !widget?.name || !widgetData[widget.name])
      return {} as ResultSet;
    return widgetData[widget.name] as ResultSet;
  }, [widgetData, widget]);

  const cards = useMemo(() => {
    const serializedData = (data.tablePivot && data.tablePivot()) || [];
    let metricList: CardComponentData[] = [];

    if (widget?.query?.measures.length > 0) {
      metricList = widget?.query?.measures.map(measure => ({
        title: measure.text,
        value: getMetricByType(
          (serializedData?.[0]?.[measure.key] || '0') as string,
          appendCurrency,
          measure.type,
        ),
      }));
    }

    return metricList;
  }, [data, widget?.query?.measures, appendCurrency]);

  return (
    <>
      {cards.map((card, index) => (
        <MetricCard
          key={`${index}`}
          data={card}
          width={widget.width}
          loading={loading}
        />
      ))}
    </>
  );
};

export const MetricCardWrapper = React.memo(
  MetricCardWrapperComp,
  arePropsEqual,
);
