import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { StyleFn } from '@oolio-group/domain';

const styles = StyleSheet.create({
  container: {
    width: 340,
    alignSelf: 'center',
  },
  content: {
    marginTop: 8,
    padding: 20,
    paddingBottom: 30,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  items: {
    height: 240,
  },
  btnGap: {
    marginTop: 10,
  },
  actions: {
    marginTop: 10,
    paddingHorizontal: 10,
  },
});

export const pagination: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 3,
  height: 32,
});

export default styles;
