import gql from 'graphql-tag';

const catalogueFragment = `
  id
  name
  catalogueType
  venues {
    id
    name
  }
  stores {
    id
    name
  }
  items {
    id
    priority
    color
    page {
      id
      name
      alternateNames {
        locale
        display
        kitchen
        receipt
        description
      }
    }
  }
`;

export const GET_MENU_OPTIONS = gql`
  query getMenuData {
    catalogues {
      id
      name
      catalogueType
      stores {
        id
        name
      }
    }
  }
`;

export const GET_MENUS_QUERY = gql`
  query catalogues{
    catalogues {
      ${catalogueFragment}
    }
  }
`;

export const GET_MENU_QUERY = gql`
  query catalogue($id: ID!) {
    catalogue(id: $id) {
      ${catalogueFragment}
    }
  }
`;

export const CREATE_MENU = gql`
  mutation createCatalogue($input: CreateCatalogueInput!) {
    createCatalogue(input: $input) {
      ${catalogueFragment}
    }
  }
`;

export const COPY_MENU = gql`
  mutation cloneCatalogue($input: ID!) {
    cloneCatalogue(id: $input) {
      ${catalogueFragment}
    }
  }
`;

export const UPDATE_MENU = gql`
  mutation updateCatalogue($input: UpdateCatalogueInput) {
    updateCatalogue(input: $input) {
      ${catalogueFragment}
    }
  }
`;

export const DELETE_MENU = gql`
  mutation deleteCatalogue($id: ID!) {
    deleteCatalogue(id: $id)
  }
`;
