import { useMutation } from '@apollo/client/react/hooks';
import { useMemo, useCallback } from 'react';
import { WORKLOG_PUSH } from './graphql';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';

import { WorklogEvent } from '@oolio-group/domain';

export interface UseWorklogProps {
  pushWorklog: (worklogEvent: WorklogEvent) => Promise<WorklogEvent[]>;
  loading: boolean;
  error: string | undefined;
}

export function useWorklog(): UseWorklogProps {
  const [pushWorklogRequest, pushWorklogResponse] = useMutation(WORKLOG_PUSH, {
    onError: noopHandler,
  });

  const pushWorklog = useCallback(
    async (worklogEvent: WorklogEvent) => {
      const result = await pushWorklogRequest({
        variables: { input: [worklogEvent] },
      });
      return result.data.syncWorklogEvents;
    },
    [pushWorklogRequest],
  );

  const error: ApolloError | undefined = pushWorklogResponse.error;

  const loading: boolean = pushWorklogResponse.loading;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      pushWorklog,
    }),
    [pushWorklog, loading, error],
  );
}
