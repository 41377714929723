import gql from 'graphql-tag';

const pagesFragment = `
  id
  name
  color
  items {
    id
    entityId
    color
    priority
    entityType
    category
  }
  products {
    id
    name
    stores {
      id
    }
    isSellable
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
  }
  variants {
    id
    name
    stores {
      id
    }
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
  }
  pages {
    id
    name
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
  }
  categories
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;

export const pagesWithProductNameFragment = `
  id
  name
  products {
    id
    name
  }
  variants {
    id
    products {
      id
      name
    }
  }
`;

export const GET_PAGES_QUERY = gql`
  query getPages {
    pages {
      ${pagesFragment}
    }
  }
`;

export const GET_PAGE_QUERY = gql`
  query page($input: String!) {
    page(id: $input) {
      ${pagesFragment}
    }
  }
`;

export const CREATE_OR_UPDATE_PAGE = gql`
  mutation createOrUpdatePage($input: CreateOrUpdatePageInput) {
    createOrUpdatePage(input: $input) {
      ${pagesFragment}
    }
  }
`;

export const DELETE_PAGE = gql`
  mutation deletePage($input: ID) {
    deletePage(id: $input)
  }
`;

export const getPageQueryWithFilter = (customFragment?: string) => {
  const gqlQuery = gql`
    query pages($input: String) {
      pages(filter: { name: $input }) {
        ${customFragment ? customFragment : pagesFragment}
      }
    }
  `;
  return gqlQuery;
};
