import { OrderEvent } from '@oolio-group/domain';

export function pushEvents(
  events: OrderEvent[],
  callback?: (events: OrderEvent[]) => void,
): void {
  if (!events || events.some(x => typeof x !== 'object')) {
    throw new Error('Expected a list of events');
  }

  callback && callback(events);
}
