import React from 'react';
import { DeviceDetails } from './Settings/Details/DeviceDetails';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import DevicePrinting from './Settings/Printing/DevicePrinting';

const Tab = createMaterialTopTabNavigator();

const DevicesTabs: React.FC = () => {
  const route = useRoute();
  const params = route.params as {
    storeId: string;
    screen: string;
  };

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Details"
      lazy
    >
      <Tab.Screen
        name="Details"
        component={DeviceDetails}
        initialParams={params}
      />
      <Tab.Screen
        name="PrintingSetup"
        options={{ tabBarLabel: 'Printing' }}
        component={DevicePrinting}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default DevicesTabs;
