import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { Table, TableShape, UpdateTableInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../../../utils/validator';
import { pick } from 'lodash';
import { capitalCase } from 'change-case';
import styles from '../SectionDetails.styles';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../../components/Shared/Modals/Modal/Modal';

interface Props {
  table: Table;
  onTableUpdate: (updatedTable: UpdateTableInput) => void;
  onTableDelete: (id: string) => void;
}

export const SEAT_OPTIONS = ['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(
  (digit: string) => ({
    value: digit,
    label: digit,
  }),
);

export const EditTableModal: React.FC<Props> = ({
  table,
  onTableDelete,
  onTableUpdate,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [form, setForm] = useState<Table>(table);

  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeNumber = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: parseInt(value),
    }));
  }, []);

  const onPressUpdate = useCallback(() => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.tableNameIsRequired'),
      });
      return;
    }

    const updatedTable = {
      ...pick(form, ['id', 'name', 'shape']),
      horizontalSeats: form.horizontalSeats,
      verticalSeats: form.verticalSeats,
      section: form.section.id,
    } as UpdateTableInput;

    onTableUpdate(updatedTable);
  }, [form, onTableUpdate, showNotification, translate]);

  const onPressDelete = useCallback(
    () => onTableDelete(table.id),
    [onTableDelete, table.id],
  );

  return (
    <TreatModal
      title={translate('backOfficeSettings.createTable.editTable')}
      onConfirm={{
        label: translate('backOfficeSettings.createTable.update'),
        action: onPressUpdate,
      }}
      secondaryButton={{
        label: translate('backOfficeSettings.createTable.delete'),
        type: 'negativeLight',
        action: onPressDelete,
      }}
      onDismiss={{ action: closeModal }}
    >
      <View>
        <View style={styles.row}>
          <InputText
            testID="table-name"
            title={translate('backOfficeSettings.createTable.tableName')}
            value={form.name}
            onChangeText={onChange.bind(null, 'name')}
            placeholder={translate('backOfficeSettings.createTable.tableName')}
            containerStyle={styles.inputFull}
          />
        </View>
        <View style={styles.row}>
          <TreatPicker
            testID="tables-horizontal-seats"
            title={translate('backOfficeSettings.createTable.seatsHorizontal')}
            selectedValue={form.horizontalSeats.toString()}
            options={SEAT_OPTIONS}
            onValueChange={onChangeNumber.bind(null, 'horizontalSeats')}
            containerStyle={styles.inputHalf}
          />
          <TreatPicker
            testID="tables-vertical-seats"
            title={translate('backOfficeSettings.createTable.seatsVertical')}
            selectedValue={form.verticalSeats.toString()}
            options={SEAT_OPTIONS}
            onValueChange={onChangeNumber.bind(null, 'verticalSeats')}
            containerStyle={styles.inputHalf}
          />
        </View>
        <TreatPicker
          testID="table-shapes"
          title={translate('backOfficeSettings.createTable.tableShape')}
          selectedValue={form.shape}
          options={Object.values(TableShape).map((key: string) => ({
            value: key,
            label: capitalCase(key),
          }))}
          onValueChange={onChange.bind(null, 'shape')}
        />
      </View>
    </TreatModal>
  );
};
