import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    alignSelf: 'center',
    alignItems: 'center',
    padding: 30,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
  },
  titleText: {
    fontSize: 20,
    lineHeight: 28,
    textAlign: 'center',
    fontFamily: theme.fonts.bold,
    marginBottom: 12,
    color: theme.colors.dark,
  },
  subtitleText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  actions: {
    width: '100%',
    paddingTop: 40,
  },
});

export default styles;
