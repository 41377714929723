import { Address, Order } from '@oolio-group/domain';

const getAddress = (address?: Address): string => {
  const formatAddress = `${address?.line1 || ''} ${address?.line2 || ''} ${
    address?.city || ''
  } ${address?.state || ''} ${address?.postalCode || ''}`;
  return formatAddress.trim();
};

export const getCustomerInfo = (
  order: Order,
): { customerAddress: string; customerName: string; customerPhone: string } => {
  const {
    firstName = '',
    lastName = '',
    phone = '',
    preferredAddress = undefined,
  } = order.customer || {};
  return {
    customerName: [firstName, lastName].filter(x => !!x).join(' '),
    customerAddress: getAddress(preferredAddress) || '',
    customerPhone: phone || '',
  };
};
