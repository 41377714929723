import { gql } from '@apollo/client';

export const FRAGMENTS = {
  tableDetails: gql`
    fragment TableDetails on Table {
      id
      name
      horizontalSeats
      verticalSeats
      isActive
      section {
        id
      }
      shape
      status
      position {
        top
        left
      }
    }
  `,
};

export const GET_TABLE = gql`
  query tableById($id: ID!) {
    table(id: $id) {
      ...TableDetails
    }
  }
  ${FRAGMENTS.tableDetails}
`;

export const GET_TABLES = gql`
  query tablesBySectionId($id: ID!) {
    tables(sectionId: $id) {
      ...TableDetails
    }
  }
  ${FRAGMENTS.tableDetails}
`;

export const CREATE_TABLES = gql`
  mutation createTable($input: [CreateTableInput!]) {
    createTables(input: $input) {
      ...TableDetails
    }
  }
  ${FRAGMENTS.tableDetails}
`;

export const UPDATE_TABLES = gql`
  mutation updateTables($input: [UpdateTableInput!]) {
    updateTables(input: $input) {
      ...TableDetails
    }
  }
  ${FRAGMENTS.tableDetails}
`;

export const DELETE_TABLE = gql`
  mutation deleteTable($id: ID!) {
    deleteTable(id: $id)
  }
`;
