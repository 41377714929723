import {
  PrintBillWorkerInput,
  WebPrinter,
  WorkerActionResult,
  WorkerActionResultStatus,
  WorkerInput,
} from '../utils';
import { printOverNetwork } from '../../hooks/app/usePrintingHelper';
import '../../devConfig';
import { Buffer } from 'buffer';
import { PrinterTarget } from '../../utils/printerTemplates/printerUtils';
import { Order } from '@oolio-group/domain';

/**
 * Process print request.
 */
export default async function (
  message: WorkerInput,
): Promise<WorkerActionResult[]> {
  const handlerResult = message.data?.bufferObjs || [];
  const order = (message.data as PrintBillWorkerInput)?.order;

  const results: {
    success: boolean;
    message: string;
    data: {
      buffer: Buffer;
      printer: WebPrinter;
      order: Order;
    };
  }[] = [];
  // call platform specific print function
  for (const eachPrint of handlerResult) {
    try {
      const result = await printOverNetwork(
        eachPrint.printer as PrinterTarget,
        Buffer.from(eachPrint.buffer),
        message.action,
      );
      results.push({
        ...result,
        data: {
          buffer: eachPrint?.buffer,
          printer: eachPrint?.printer,
          order,
        },
      });
    } catch (exception) {
      results.push({
        success: false,
        message: `Print job failed: ${exception}`,
        data: {
          buffer: eachPrint?.buffer,
          printer: eachPrint?.printer,
          order,
        },
      });
    }
  }

  // in case of kitchen
  if (!results.length)
    return [
      {
        status: WorkerActionResultStatus.SUCCESS,
        message: '',
        data: {
          buffer: handlerResult[0]?.buffer,
          printer: handlerResult[0]?.printer,
          order,
        },
      },
    ];

  return results.map(result => ({
    status: result.success
      ? WorkerActionResultStatus.SUCCESS
      : WorkerActionResultStatus.ERROR,
    message: result.message,
    data: result.data,
  }));
}
