import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.tealLight,
  },
  text: {
    fontSize: CART_FONT_SIZE,
    lineHeight: 20,
    marginVertical: 3,
    color: theme.colors.teal,
    fontFamily: theme.fonts.medium,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

export default styles;
