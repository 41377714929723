import React, { createContext } from 'react';
import { Products } from './Products/Products';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { PriceListDetailsTab } from './Details/Details';

const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  pricingGroupId: string;
  screen?: string;
  isDefault?: boolean;
  isNavigatedFromCreate: boolean;
}

export const PriceListContext = createContext({} as { params: RouteParams });

const PriceListSettingsNavigator: React.FC = () => {
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <PriceListContext.Provider value={{ params }}>
      <Tab.Navigator
        tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
          <TabBar tabBar={props as MaterialTopTabBarProps} />
        )}
        backBehavior="none"
        initialRouteName={params?.screen || 'Details'}
      >
        <Tab.Screen
          name="Details"
          component={PriceListDetailsTab}
          initialParams={params}
        />
        <Tab.Screen
          name="Products"
          component={Products}
          initialParams={params}
        />
      </Tab.Navigator>
    </PriceListContext.Provider>
  );
};

export default PriceListSettingsNavigator;
