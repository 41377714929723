import {
  FilterValue,
  Filters,
  Shift,
  Worklog,
  DateRangeFilter,
  ReportFilterDropdown,
  FilterObject,
  Widget,
  StyleFn,
} from '@oolio-group/domain';
import { columnStyleOptions } from '../../../components/DataGrid/DataGrid';

export interface IMap<T> {
  [key: string]: T;
}

export interface ReportTypeSettings {
  value: string;
  options: string[];
}

export interface Search {
  type: string;
  value: string;
}

export enum ReportType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum LineChartType {
  BASIC = 'BASIC',
  DATE_RANGE = 'DATE_RANGE',
}

export interface ReportTableColumn {
  key: string;
  dataKey?: string;
  title: string;
  flex: number;
  type?: string;
  style?: columnStyleOptions;
  showByDefault?: boolean;
  blankValue?: string;
  textStyleCallback?: (data: PivotTableRow) => StyleFn;
  cellAlignmentStyle?: string;
}

export enum ExportType {
  CSV = 'CSV',
}

export interface ReportRange {
  from: string;
  to: string;
}

export interface ReportOrgDetails {
  name: string;
}

export interface GenerateReportInputs {
  organization: ReportOrgDetails;
  filters: FilterValue;
  range: ReportRange;
}

export interface DateRangeFilterInput extends DateRangeFilter {
  time?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}

export interface ShiftReport {
  filters: Filters;
  query: {
    filter: FilterValue;
    dateRange: DateRangeFilterInput;
    timezone?: string;
  };
  shifts: Shift[];
}

export interface WorklogReport extends Omit<ShiftReport, 'shifts'> {
  worklogs: Worklog[];
}

export enum CustomReports {
  SHIFT = 'Shift',
  WORKLOG = 'Worklog',
}

export interface ShiftQueryInput {
  filter: FilterValue;
  dateRange: DateRangeFilterInput;
}

export type WorklogQueryInput = ShiftQueryInput;

export type PivotTableRow = {
  [key: string]: string | number | boolean;
};

export type PivotTableData = PivotTableRow[];

export enum HelperText {
  SALES_FEED = 'SalesFeed',
  SALES_SUMMARY = 'SalesSummary',
  PRODUCTS_SUMMARY = 'ProductsSummary',
  HOURLY_SALES = 'HourlySales',
  PAYMENT_SUMMARY = 'PaymentSummary',
  SALES_BY_STAFF = 'SalesByStaff',
  DEVICE_SUMMARY = 'DeviceSummary',
  TAXES = 'Taxes',
  SALES_CUSTOMER = 'SalesCustomer',
  VOID_SUMMARY = 'VoidSummary',
  REFUND = 'Refund',
  SALES_DASHBOARD = 'SalesDashboard',
  ADJUSTMENTS = 'Adjustments',
}

export enum VarianceColor {
  RECORDED = '#a6a6a6',
  DEFAULT = '#2196f3',
  POSITIVE = '#4caf50',
  NEGATIVE = '#f44336',
}

export interface SidePanel {
  show: boolean;
  data?: IMap<string | number>;
}

export interface DropDownFilter extends ReportFilterDropdown {
  values: FilterObject[];
}

export interface ReportWidget {
  updateCount: number;
  widgets: Widget[];
}

export enum DisplayLabels {
  NO_RECORD = 'No records',
  BLANK_LABEL = 'Others',
  VOID_ORDER_PAYMENT_NAME = 'Void',
  SHIFT_NOT_CLOSED = 'Pending',
}

export interface CardComponentData {
  title: string;
  value: string;
}

export interface ShiftPreview extends Shift {
  device?: string;
}
