import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  TextInput,
  ViewStyle,
  TextInputProps,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import theme from '../../../common/default-theme';
import styles from './Search.styles';
import Icon from '../../Icon/Icon';

export interface SearchProps extends TextInputProps {
  testID: string;
  title?: string;
  placeholder?: string;
  value?: string | undefined;
  onPressEnter?: (text: string) => void;
  containerStyle?: ViewStyle | ViewStyle[];
  loading?: boolean;
}

const Search: React.FC<SearchProps> = ({
  testID,
  title,
  value,
  placeholder,
  onPressEnter,
  containerStyle,
  loading,
  ...props
}: SearchProps) => {
  const inputRef = useRef<TextInput>(null);
  const [text, setText] = useState('');

  const handleKeyPress = useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (event.nativeEvent.key === 'Enter' && onPressEnter) {
        onPressEnter(text);
      }
    },
    [onPressEnter, text],
  );

  const handleChangeText = useCallback(
    (text: string) => {
      setText(text);
      if (props.onChangeText) {
        props.onChangeText(text);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeText],
  );

  const onPressClear = useCallback(() => {
    setText('');
    handleChangeText('');
    onPressEnter && onPressEnter('');
  }, [handleChangeText, onPressEnter]);

  useEffect(() => {
    setText(value || '');
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const input = inputRef.current;
      if (input && !!props.editable && props.autoFocus === true) {
        input.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef, props.autoFocus, props.editable]);

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <TextInput
        value={text}
        testID={testID}
        placeholder={placeholder}
        placeholderTextColor={theme.colors.grey5}
        style={styles.inputContainer}
        autoCorrect={false}
        {...props}
        onChangeText={handleChangeText}
        onKeyPress={handleKeyPress}
        onSubmitEditing={() => onPressEnter && onPressEnter(text)}
        returnKeyType="search"
        ref={inputRef}
      />
      <View accessibilityRole="none" style={styles.accessory}>
        {loading ? (
          <ActivityIndicator color={theme.colors.states.neutral} size={16} />
        ) : text.length ? (
          <TouchableOpacity onPress={onPressClear}>
            <Icon size={20} color={theme.colors.dark} name="times-circle" />
          </TouchableOpacity>
        ) : (
          <Icon size={20} color={theme.colors.grey5} name="search" />
        )}
      </View>
    </View>
  );
};

export default Search;
