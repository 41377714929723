import { View, Text } from 'react-native';
import React, { useCallback, useState, useMemo } from 'react';
import {
  useCurrency,
  usePhoneNumber,
  useTranslation,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import { Customer, NotificationMode } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { isValidEmail } from '../../../../utils/validator';
import { useNotification } from '../../../../hooks/Notification';
import { DEFAULT_COUNTRY_CODE } from '../../../../constants';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import theme from '../../../../common/default-theme';
import styles from './SaleCompleteModal.styles';
import Icon from '../../../Icon/Icon';
import Gradient from '../../../Gradient/Gradient';
import InputPhone from '../../../Shared/Inputs/InputPhone';
import InputEmail from '../../../Shared/Inputs/InputEmail';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import ButtonActions from '../../../Shared/TreatButton/ButtonActions';
import { useSession } from '../../../../hooks/app/useSession';

export interface sendReceiptInput {
  email?: string;
  phone?: { countryCode: string; number: string };
  mode: NotificationMode;
}

interface ReceiptPrintFormProps {
  email: string;
  phone: string;
  countryCode: string;
}

export interface SaleCompleteModalProps {
  sendReceipt?: (input: sendReceiptInput) => void;
  onPressPrintReceipt: () => Promise<void>;
  onPressNewSale: () => void;
  changeDue: number | null;
  customer: Customer | undefined;
  amount?: number;
  isSplitPayment?: boolean;
  onPrintSplit: () => void;
  isOnAccount?: boolean;
  loyaltyAction: React.ReactElement | null;
  onHoldAction?: React.ReactElement | null;
  earnedPointInfo?: string;
  isOrderOnHold?: boolean;
  isLoyaltyEnabled?: boolean;
}

const SaleCompleteModal: React.FC<SaleCompleteModalProps> = ({
  sendReceipt,
  onPressPrintReceipt,
  onPressNewSale,
  changeDue,
  customer,
  amount,
  isSplitPayment,
  onPrintSplit,
  isOnAccount,
  loyaltyAction,
  earnedPointInfo,
  isOrderOnHold,
  isLoyaltyEnabled,
  onHoldAction,
}) => {
  const [session] = useSession();
  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showNotification } = useNotification();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const [form, setForm] = useState<ReceiptPrintFormProps>({
    email: customer?.email || '',
    phone: customer?.phoneNumber || '',
    countryCode: customer?.preferredAddress?.isoCountryCode || country,
  });

  const formValidationStatus = useMemo<Record<string, boolean>>(
    () => ({
      email: isValidEmail(form.email),
    }),
    [form.email],
  );
  const sendEmailReceipt = useCallback(() => {
    if (form.email && sendReceipt) {
      analyticsService.capture('send_receipt', {
        method: 'Email',
      });
      closeModal();
      const input: sendReceiptInput = {
        email: form.email,
        mode: NotificationMode.EMAIL,
      };
      sendReceipt(input);
    } else {
      showNotification({
        error: true,
        message: translate('sendReceipt.emptyEmailMsg'),
      });
    }
  }, [form.email, sendReceipt, closeModal, showNotification, translate]);

  const sendSmsReceiptToPhone = useCallback(() => {
    if (sendReceipt) {
      analyticsService.capture('send_receipt', {
        method: 'SMS',
      });
      closeModal();
      const input: sendReceiptInput = {
        phone: {
          countryCode: form.countryCode,
          number: form.phone,
        },
        mode: NotificationMode.PHONE,
      };
      sendReceipt(input);
    } else {
      showNotification({
        error: true,
        message: translate('sendReceipt.emptyPhoneMsg'),
      });
    }
  }, [
    form.phone,
    form.countryCode,
    sendReceipt,
    closeModal,
    showNotification,
    translate,
  ]);

  const onChange = useCallback(
    (key: keyof ReceiptPrintFormProps, value: string): void => {
      setForm(form => ({
        ...form,
        [key]: value,
      }));
    },
    [],
  );

  const handlePressNewSale = useCallback(() => {
    closeModal();
    onPressNewSale();
  }, [closeModal, onPressNewSale]);

  const totalAmount =
    (customer?.customerAccountDetails?.currentBalance || 0) + (amount || 0);

  const disableSendEmail = !formValidationStatus.email;

  const isPhoneValid = isValidPhoneNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );

  return (
    <View style={styles.container}>
      <Gradient
        style={styles.header}
        colors={[theme.colors.green, theme.colors.greenDark]}
      >
        <View style={styles.icon}>
          <Icon name="check" size={30} color={theme.colors.white} />
        </View>
        <Text testID="change" style={styles.title}>
          {changeDue === null
            ? translate('payment.saleComplete')
            : translate('payment.changeDueAmount', {
                changeDue: formatCurrency(changeDue || 0),
              })}
        </Text>
        {!changeDue ? (
          <Text style={styles.subtext}>{translate('payment.noChangeDue')}</Text>
        ) : null}
        {isOnAccount ? (
          <>
            <Text
              testID="totalOutstanding"
              style={styles.subtext}
            >{`${translate('payment.totalOutstanding')}: ${formatCurrency(
              totalAmount,
            )}`}</Text>
            <Text testID="customer" style={styles.subtext}>
              {`${customer?.firstName || ''} ${customer?.lastName || ''}`}
            </Text>
            <Text testID="order-total" style={styles.subtext}>
              {`${translate('payment.orderTotal')}: ${formatCurrency(
                amount as number,
              )}`}
            </Text>
          </>
        ) : null}
        {earnedPointInfo ? (
          <Text testID="earned-point" style={styles.subtext}>
            {earnedPointInfo}
          </Text>
        ) : null}
      </Gradient>
      <View style={styles.content}>
        <View style={styles.row}>
          <InputPhone
            testID="input-phone"
            value={form.phone}
            defaultCountry={form.countryCode}
            onPressCountry={onChange.bind(null, 'countryCode')}
            onChangeText={text => onChange('phone', text)}
            containerStyle={styles.input}
          />
          <TreatButton
            testID="btn-sendText"
            type="neutralLight"
            label={translate('button.send')}
            onPress={sendSmsReceiptToPhone}
            disabled={!isPhoneValid}
          />
        </View>
        <View style={styles.row}>
          <InputEmail
            testID="input-email"
            placeholder={translate('common.emailPlaceholder')}
            value={form.email || ''}
            onChangeText={onChange.bind(null, 'email')}
            containerStyle={styles.input}
          />
          <TreatButton
            testID="btn-sendEmail"
            type="neutralLight"
            label={translate('button.send')}
            onPress={sendEmailReceipt}
            disabled={disableSendEmail}
          />
        </View>
        <View style={styles.actions}>
          {onHoldAction && onHoldAction}
          {!isOrderOnHold ? (
            <View style={styles.actionsRow}>
              <TreatButton
                testID="btn-new"
                height={50}
                type="positive"
                label={translate('button.newSale')}
                onPress={handlePressNewSale}
                containerStyle={styles.btnFluid}
              />
            </View>
          ) : null}
          <View style={styles.actionsRow}>
            {isSplitPayment ? (
              <ButtonActions
                testID="btn-print"
                height={50}
                type="neutralLight"
                label={translate('common.printReceipt')}
                actions={[
                  {
                    label: translate('button.printSplit'),
                    action: onPrintSplit,
                  },
                  {
                    label: translate('common.printReceipt'),
                    action: onPressPrintReceipt,
                  },
                ]}
                containerStyle={styles.btnFluid}
              />
            ) : (
              <TreatButton
                testID="btn-printOrder"
                height={50}
                type="neutralLight"
                label={translate('common.printReceipt')}
                onPress={onPressPrintReceipt}
                containerStyle={styles.btnFluid}
              />
            )}
            {isLoyaltyEnabled && loyaltyAction}
          </View>
        </View>
      </View>
    </View>
  );
};

export default SaleCompleteModal;
