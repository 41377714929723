import { useContext } from 'react';
import {
  POSUserRoleContext,
  POSUserRoleContextType,
} from '../../POSUserRoleProvider';

const usePOSUserRoles = () => {
  const context = useContext<POSUserRoleContextType>(POSUserRoleContext);

  return context;
};

export default usePOSUserRoles;
