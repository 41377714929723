import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  GET_KITCHEN_DISPLAY_BY_ID_QUERY,
  GET_KITCHEN_DISPLAY_BY_STORE_QUERY,
  CREATE_KITCHEN_DISPLAY,
  UPDATE_KITCHEN_DISPLAY,
  DELETE_KITCHEN_DISPLAY,
  RESET_KITCHEN_DISPLAY,
} from '../../graphql/kitchenDisplay';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  KitchenDisplay,
  CreateKitchenDisplayInput,
  UpdateKitchenDisplayInput,
} from '@oolio-group/domain';
import { ApolloError } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { stripProperties } from '../../utils/stripObjectProps';

export interface UseKitchenDisplayProps {
  loading: boolean;
  error: string | undefined;
  kitchenDisplay?: KitchenDisplay;
  kitchenDisplays: { [key: string]: KitchenDisplay };
  createdKitchenDisplayId: string;
  updatedKitchenDisplayId: string;
  deletedKitchenDisplay: boolean;
  resetedKitchenDisplayCode: boolean;
  getKitchenDisplays: () => void;
  createKitchenDisplay: (
    kitchenDisplayCreate: CreateKitchenDisplayInput,
  ) => void;
  updateKitchenDisplay: (
    kitchenDisplayInput: UpdateKitchenDisplayInput,
  ) => void;
  resetKitchenDisplayCode: (id: string) => void;
  deleteKitchenDisplay: (id: string) => void;
  resetStateData: () => void;
}

export interface Props {
  kitchenDisplayId?: string;
  storeId?: string;
}

export const useKitchenDisplay = (props?: Props): UseKitchenDisplayProps => {
  const { kitchenDisplayId, storeId } = props || {};
  const [kitchenDisplays, setKitchenDisplays] = useState<
    Record<string, KitchenDisplay>
  >({});
  const [kitchenDisplay, setKitchenDisplay] = useState<KitchenDisplay>();
  const [createdKitchenDisplayId, setCreatedKitchenDisplayId] = useState('');
  const [updatedKitchenDisplayId, setUpdatedKitchenDisplayId] = useState('');
  const [deletedKitchenDisplay, setDeletedKitchenDisplay] = useState(false);
  const [resetedKitchenDisplayCode, setResetedKitchenDisplayCode] =
    useState(false);
  const resetStateData = useCallback(() => {
    setResetedKitchenDisplayCode(false);
    setDeletedKitchenDisplay(false);
    setUpdatedKitchenDisplayId('');
    setCreatedKitchenDisplayId('');
  }, []);
  const onCompleteGetKitchenDisplayRequest = useCallback(
    data => {
      if (data && data.kitchenDisplay) {
        const kitchenDisplayData = data.kitchenDisplay;
        setKitchenDisplay(kitchenDisplayData);
        setKitchenDisplays(kitchenDisplay => {
          const kitchenDisplayTemp = { ...kitchenDisplay };
          kitchenDisplayTemp[kitchenDisplayData.id] = kitchenDisplayData;
          return kitchenDisplayTemp;
        });
      }
    },
    [setKitchenDisplays],
  );

  const [getKitchenDisplay, getKitchenDisplayResponse] = useLazyQuery(
    GET_KITCHEN_DISPLAY_BY_ID_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetKitchenDisplayRequest,
    },
  );

  const onCompletedGetDevicesRequest = useCallback(data => {
    if (data) {
      const kitchenDisplayData = data.kitchenDisplays;
      if (kitchenDisplayData) {
        const kitchenDisplayDict: Record<string, KitchenDisplay> = keyBy(
          kitchenDisplayData,
          'id',
        );
        setKitchenDisplays(kitchenDisplayDict);
      }
    }
  }, []);

  const [getKitchenDisplays, getKitchenDisplaysResponse] = useLazyQuery(
    GET_KITCHEN_DISPLAY_BY_STORE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompletedGetDevicesRequest,
    },
  );

  const onCompletedCreateKitchenDisplay = useCallback(
    (kitchenDisplayId, data) => {
      if (data?.createKitchenDisplay) {
        const kitchenDisplayData = stripProperties(
          data.createKitchenDisplay as KitchenDisplay,
          '__typename',
        );
        setCreatedKitchenDisplayId(kitchenDisplayData.id);
        setKitchenDisplays(kitchenDisplay => {
          return {
            ...kitchenDisplay,
            [kitchenDisplayData.id]: kitchenDisplayData,
          };
        });
      }
    },
    [],
  );

  const [createKitchenDisplayRequest, createKitchenDisplayResponse] =
    useMutation(CREATE_KITCHEN_DISPLAY, {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompletedCreateKitchenDisplay.bind(null, kitchenDisplayId),
    });

  const onCompletedDeleteKitchenDisplayRequest = useCallback(
    (kitchenDisplayId, data) => {
      if (kitchenDisplayId && data?.deleteKitchenDisplay) {
        setDeletedKitchenDisplay(true);
      }
    },
    [],
  );

  const onCompletedUpdateKitchenDisplayRequest = useCallback(
    (kitchenDisplayId, data) => {
      if (kitchenDisplayId && data?.updateKitchenDisplay) {
        const deviceData = data.updateKitchenDisplay;
        setKitchenDisplays(kitchenDisplay => {
          const devicesTemp = { ...kitchenDisplay };
          devicesTemp[deviceData.id] = deviceData;
          return devicesTemp;
        });
        setUpdatedKitchenDisplayId(deviceData.id);
      }
    },
    [],
  );

  const onCompletedResetDeviceRequest = useCallback(
    (kitchenDisplayId, data) => {
      if (kitchenDisplayId && data?.resetKitchenDisplayCode) {
        setKitchenDisplays(kitchenDisplay => {
          const devicesTemp = { ...kitchenDisplay };
          devicesTemp[kitchenDisplayId.id] = {
            ...devicesTemp[kitchenDisplayId.id],
            deviceCode: data?.resetKitchenDisplayCode.deviceCode,
          };
          return devicesTemp;
        });
        setResetedKitchenDisplayCode(true);
      }
    },
    [],
  );

  const [deleteKitchenDisplayRequest, deleteKitchenDisplayResponse] =
    useMutation(DELETE_KITCHEN_DISPLAY, {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompletedDeleteKitchenDisplayRequest.bind(
        null,
        kitchenDisplayId,
      ),
    });

  const [
    resetKitchenDisplayDeviceCodeRequest,
    resetKitchenDisaplyDeviceCodeRequestResponse,
  ] = useMutation(RESET_KITCHEN_DISPLAY, {
    onError: noopHandler,
    context: {
      headers: { store: storeId },
    },
    onCompleted: onCompletedResetDeviceRequest.bind(null, kitchenDisplayId),
  });

  const [updateKitchenDisplayRequest, updateKitchenDisplayResponse] =
    useMutation(UPDATE_KITCHEN_DISPLAY, {
      onError: noopHandler,
      onCompleted: onCompletedUpdateKitchenDisplayRequest.bind(
        null,
        kitchenDisplayId,
      ),
      context: {
        headers: { store: storeId },
      },
    });

  useEffect(() => {
    if (kitchenDisplayId) {
      getKitchenDisplay({ variables: { id: kitchenDisplayId } });
    }
  }, [getKitchenDisplay, kitchenDisplayId]);

  const updateKitchenDisplay = useCallback(
    (device: UpdateKitchenDisplayInput) => {
      setUpdatedKitchenDisplayId('');
      updateKitchenDisplayRequest({
        variables: {
          input: device,
        },
      });
    },
    [updateKitchenDisplayRequest],
  );

  const createKitchenDisplay = useCallback(
    (deviceInput: CreateKitchenDisplayInput) => {
      createKitchenDisplayRequest({
        variables: {
          input: deviceInput,
        },
      });
    },
    [createKitchenDisplayRequest],
  );

  const deleteKitchenDisplay = useCallback(
    (id: string) => {
      deleteKitchenDisplayRequest({
        variables: {
          id,
        },
      });
    },
    [deleteKitchenDisplayRequest],
  );

  const resetKitchenDisplayCode = useCallback(
    (id: string) => {
      resetKitchenDisplayDeviceCodeRequest({
        variables: {
          id,
        },
      });
    },
    [resetKitchenDisplayDeviceCodeRequest],
  );

  const RESPONSES = [
    getKitchenDisplayResponse,
    getKitchenDisplaysResponse,
    updateKitchenDisplayResponse,
    deleteKitchenDisplayResponse,
    resetKitchenDisaplyDeviceCodeRequestResponse,
    createKitchenDisplayResponse,
  ];

  const error: ApolloError | undefined = getError(RESPONSES);
  const loading: boolean = isLoading(RESPONSES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      kitchenDisplay,
      kitchenDisplays,
      createdKitchenDisplayId,
      updatedKitchenDisplayId,
      deletedKitchenDisplay,
      resetedKitchenDisplayCode,
      getKitchenDisplays,
      createKitchenDisplay,
      updateKitchenDisplay,
      deleteKitchenDisplay,
      resetKitchenDisplayCode,
      resetStateData,
    }),
    [
      loading,
      error,
      kitchenDisplay,
      kitchenDisplays,
      createdKitchenDisplayId,
      updatedKitchenDisplayId,
      deletedKitchenDisplay,
      resetedKitchenDisplayCode,
      getKitchenDisplays,
      createKitchenDisplay,
      updateKitchenDisplay,
      deleteKitchenDisplay,
      resetKitchenDisplayCode,
      resetStateData,
    ],
  );
};
