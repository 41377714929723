import React, { useMemo, useState, useCallback } from 'react';
import { View, Image, TouchableOpacity, ViewStyle } from 'react-native';
import theme from '../../../common/default-theme';
import styles from './ImageCarousel.styles';
import Icon from '../../Icon/Icon';

export interface ImageCarouselProps {
  imageUrls: string[];
  containerStyle?: ViewStyle;
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  imageUrls,
  containerStyle,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const leftImageIndex = useMemo(() => {
    if (selectedImageIndex === 0 && imageUrls.length) {
      return imageUrls.length - 1;
    } else {
      return selectedImageIndex - 1;
    }
  }, [imageUrls, selectedImageIndex]);

  const rightImageIndex = useMemo(() => {
    if (selectedImageIndex >= imageUrls.length - 1) {
      return 0;
    } else {
      return selectedImageIndex + 1;
    }
  }, [imageUrls, selectedImageIndex]);

  const slideLeft = useCallback(() => {
    setSelectedImageIndex(prev => {
      if (prev <= 0) {
        return imageUrls.length - 1;
      } else {
        return prev - 1;
      }
    });
  }, [imageUrls]);

  const slideRight = useCallback(() => {
    setSelectedImageIndex(prev => {
      if (prev >= imageUrls.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [imageUrls]);

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.images}>
        <Image
          style={styles.image}
          source={{ uri: imageUrls[leftImageIndex] }}
        />
        <Image
          style={styles.image}
          source={{ uri: imageUrls[selectedImageIndex] }}
        />
        <Image
          style={styles.image}
          source={{ uri: imageUrls[rightImageIndex] }}
        />
      </View>
      <View style={styles.pagination}>
        <TouchableOpacity style={styles.btnPagination} onPress={slideLeft}>
          <Icon name="arrow-left" size={20} color={theme.colors.white} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.btnPagination} onPress={slideRight}>
          <Icon name="arrow-right" size={20} color={theme.colors.white} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
