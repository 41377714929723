import { gql } from '@apollo/client';

export const GET_PLACES = gql`
  query places($term: String!, $sessionToken: ID, $countries: [String]) {
    places(term: $term, sessionToken: $sessionToken, countries: $countries) {
      placeId
      description
    }
  }
`;

export const GET_PLACE_DETAILS = gql`
  query place($id: ID!, $sessionToken: ID) {
    place(id: $id, sessionToken: $sessionToken) {
      address {
        line1
        line2
        city
        suburb
        postalCode
        state
        country
        isoCountryCode
      }
      formattedAddress
      timezone
    }
  }
`;
