import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import TreatButton, { TreatButtonProps } from '../../TreatButton/TreatButton';
import styles from './Modal.styles';
import theme from '../../../../common/default-theme';

interface ModalButton {
  label?: string;
  action: () => void;
  disabled?: boolean;
  type?: TreatButtonProps['type'];
}

export interface TreatModalProps {
  size?: 'small' | 'medium' | 'large';
  type?: 'positive' | 'neutral' | 'focus' | 'negative';
  loading?: boolean;
  title: string;
  subtitle?: string;
  onConfirm: ModalButton;
  onDismiss?: ModalButton;
  secondaryButton?: ModalButton;
  children: React.ReactNode;
}

const getTitleColor = (type: string) => {
  switch (type) {
    case 'positive':
      return theme.colors.states.positive;
    case 'focus':
      return theme.colors.states.focus;
    case 'negative':
      return theme.colors.states.negative;
    default:
      return theme.colors.states.neutral;
  }
};

const getModalWidth = (size: string) => {
  switch (size) {
    case 'medium':
      return { width: 600 };
    case 'large':
      return { width: 800 };
    default:
      return { width: 340 };
  }
};

const TreatModal: React.FC<TreatModalProps> = ({
  size = 'small',
  type = 'neutral',
  loading,
  title,
  subtitle,
  children,
  onConfirm,
  onDismiss,
  secondaryButton,
}: TreatModalProps) => {
  const { height: vh } = theme.useResponsiveDimensions();

  const horizontalLayout = secondaryButton && size === 'small';

  const getGap = () => {
    if (horizontalLayout) {
      return { marginBottom: 10 };
    } else {
      return { marginLeft: 10 };
    }
  };

  return (
    <View
      style={[styles.container, getModalWidth(size), { maxHeight: vh - 200 }]}
    >
      <View style={styles.title}>
        <Text
          testID="mdl-title"
          style={[styles.titleText, { color: getTitleColor(type) }]}
        >
          {title}
        </Text>
        {!!subtitle && <Text style={styles.subtitleText}>{subtitle}</Text>}
      </View>
      <ScrollView>
        <View style={styles.content}>{children}</View>
      </ScrollView>
      <View style={horizontalLayout ? styles.actionsHz : styles.actions}>
        {onDismiss ? (
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            disabled={loading}
            onPress={onDismiss.action}
            label={onDismiss.label ? onDismiss.label : 'Dismiss'}
          />
        ) : null}
        {secondaryButton ? (
          <TreatButton
            testID="btn-dismiss"
            disabled={loading}
            type={secondaryButton.type}
            onPress={secondaryButton.action}
            label={secondaryButton.label ? secondaryButton.label : 'Dismiss'}
            containerStyle={getGap()}
          />
        ) : null}
        <TreatButton
          type={type}
          testID="btn-confirm"
          onPress={onConfirm.action}
          label={onConfirm.label ? onConfirm.label : 'Confirm'}
          containerStyle={getGap()}
          isLoading={loading}
          disabled={onConfirm.disabled}
        />
      </View>
    </View>
  );
};

export default TreatModal;
