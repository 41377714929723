import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Adjustment, AdjustmentUnit } from '@oolio-group/domain';
import styles from '../VenueSettings.styles';
import adjustmentStyle from './Adjustments.style';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import { useTranslation } from '@oolio-group/localization';

interface DiscountProps {
  discounts: Adjustment[];
  navigateToScreen: (adjustment?: Adjustment) => void;
  getFormattedAmount: (amount: number, type: AdjustmentUnit) => string;
  isActiveSchedule: (adjustment: Adjustment) => number | boolean | undefined;
  getStoreNames: (storeIds: string[]) => string;
}

const Discounts: React.FC<DiscountProps> = ({
  discounts,
  navigateToScreen,
  getFormattedAmount,
  isActiveSchedule,
  getStoreNames,
}) => {
  const { translate } = useTranslation();

  const DiscountRow = ({ discount }: { discount: Adjustment }) => {
    return (
      <TouchableOpacity
        key={discount.id}
        testID="data-row"
        style={theme.tables.row}
        onPress={() => navigateToScreen(discount)}
      >
        <Text
          numberOfLines={1}
          style={[adjustmentStyle.rowText, adjustmentStyle.cellName]}
        >
          {discount.name}
        </Text>
        <Text
          numberOfLines={1}
          style={[adjustmentStyle.rowText, adjustmentStyle.cellStoreName]}
        >
          {getStoreNames(discount?.stores ?? []) ||
            translate('backofficeVenueSettingAdjustments.allStores')}
        </Text>
        <Text style={[adjustmentStyle.rowText, adjustmentStyle.cellAmount]}>
          {getFormattedAmount(discount.amount, discount.adjustmentUnit)}
        </Text>
        <View
          style={[adjustmentStyle.rowText, adjustmentStyle.cellScheduleHeader]}
        >
          <Icon
            size={20}
            name={isActiveSchedule(discount) ? 'check-circle' : 'circle'}
            color={
              isActiveSchedule(discount)
                ? theme.colors.states.positive
                : theme.colors.grey4
            }
          />
        </View>

        <View style={adjustmentStyle.cellDisclosure}>
          <Icon size={20} name="angle-right" color={theme.colors.grey4} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.tableContainer}>
      <View style={theme.tables.header}>
        <Text style={[theme.tables.headerText, adjustmentStyle.cellName]}>
          {translate('backofficeVenueSettingAdjustments.discount')}
        </Text>
        <Text style={[theme.tables.headerText, adjustmentStyle.cellStoreName]}>
          {translate('backofficeVenueSettingAdjustments.stores')}
        </Text>
        <Text style={[theme.tables.headerText, adjustmentStyle.cellAmount]}>
          {translate('backofficeVenueSettingAdjustments.amount')}
        </Text>
        <Text
          style={[theme.tables.headerText, adjustmentStyle.cellScheduleHeader]}
        >
          {translate('backofficeVenueSettingAdjustments.scheduled')}
        </Text>
        <View style={adjustmentStyle.cellDisclosure} />
      </View>
      {discounts.map(discount => (
        <DiscountRow discount={discount} key={discount.id} />
      ))}
    </View>
  );
};

export default Discounts;
