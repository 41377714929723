import { useContext } from 'react';
import { Session } from '../../state/Session';
import { SessionContext } from '../SessionProvider';

export function useSession() {
  const { session, setSession } = useContext(SessionContext);

  if (!session && !setSession) {
    throw new Error('Did you wrap your component in SessionProvider?');
  }

  return [session, setSession] as [Session, (data: Session) => void];
}
