import { useState, useCallback, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { isErrorForDeviceOffline } from '../utils/validator';
import { useNetworkStatus } from './app/useNetworkStatus';

export interface Notification {
  id?: symbol;
  error?: boolean;
  success?: boolean;
  info?: boolean;
  message: string;
}

const DEFAULT_TTL = 3000; // in ms

export const useNotificationState = <T,>(subject: BehaviorSubject<T>) => {
  const [state, setState] = useState<T>(subject.value);
  useEffect(() => {
    const subscribe = subject.subscribe(state => setState(state));
    return () => subscribe?.unsubscribe?.();
  }, [subject]);

  return state;
};

export const notificationObserver = new BehaviorSubject<Array<Notification>>(
  [],
);

export const useNotificationsList = () =>
  useNotificationState(notificationObserver);

export function useNotification() {
  const { isConnected } = useNetworkStatus();
  const closeNotification = useCallback((id: symbol | undefined) => {
    if (!id) return;
    notificationObserver.next(
      notificationObserver.value.filter(
        notification => notification?.id !== id,
      ),
    );
  }, []);

  /**
   * @param newNotification Notification
   * @param autoClose boolean default value is true
   */
  const showNotification = useCallback(
    (newNotification: Notification, autoClose = true) => {
      if (!isConnected && isErrorForDeviceOffline(newNotification.message)) {
        return;
      }
      const id = Symbol(newNotification.message);
      notificationObserver.next([
        ...notificationObserver.value,
        { ...newNotification, id } as Notification,
      ]);
      if (autoClose) {
        setTimeout(() => {
          closeNotification(id);
        }, DEFAULT_TTL);
      }
    },
    [closeNotification, isConnected],
  );

  const closeAllNotifications = useCallback(() => {
    notificationObserver.next([]);
  }, []);

  return {
    showNotification,
    closeNotification,
    closeAllNotifications,
  };
}
