import React, { useState, useCallback, useEffect } from 'react';
import { View, Platform, KeyboardAvoidingView } from 'react-native';
import {
  PaymentType,
  MoneyEventType,
  MoneyMovementReason,
  MoneyMovement,
} from '@oolio-group/domain';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { useNotification } from '../../../../hooks/Notification';
import { usePaymentTypes } from '../../../../hooks/app/usePaymentTypes';
import {
  CREATE_MONEY_MOVEMENT,
  GET_MONEY_MOVEMENTS_REASONS,
  SET_MONEYMOVEMENT_RECORD,
} from '../../../../graphql/manageMoney';
import { noopHandler, parseApolloError } from '../../../../utils/errorHandlers';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { userUtility } from '../../../../state/userUtility';
import { ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER } from '../../../../types/Common';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { capitalCase } from 'change-case';
import styles from './AddMoneyEvent.styles';
import TreatPicker from '../../../Shared/Select/Picker';
import InputText from '../../../Shared/Inputs/InputText';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import SegmentControl from '../../../Shared/SegmentControl/SegmentControl';
import ModalTitle from '../ModalTitle/ModalTitle';

interface PaymentTypeDisplay extends PaymentType {
  value: string;
  label: string;
}

enum FormError {
  INVALID_AMOUNT = 'moneyMovements.invalidAmountMessage',
  INVALID_PAYMENTTYPE = 'moneyMovements.invalidPaymentTypeMessage',
  INVALID_REASON = 'moneyMovements.invalidReasonMessage',
}

export interface AddMoneyEventProps {
  openCashDrawer?: () => void;
  printMoneyMovementReceipt?: (moneyMovement: MoneyMovement) => void;
}

const initialForm = {
  reason: undefined,
  paymentType: '',
  amount: '',
  notes: '',
  eventType: MoneyEventType.MONEY_IN,
};

const AddMoneyEvent: React.FC<AddMoneyEventProps> = ({
  openCashDrawer,
  printMoneyMovementReceipt,
}: AddMoneyEventProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { paymentTypes, status } = usePaymentTypes({
    fetchPolicy: 'cache-first',
  });
  const { currencySymbol, currency } = useCurrency();
  const [setMoneyMovementRecord] = useMutation(SET_MONEYMOVEMENT_RECORD);

  const [errorMessage, setErrorMessage] = useState('');
  const [form, setForm] = useState({ ...initialForm });

  const [moneyMovementEvent, moneyMovementEventStatus] = useMutation(
    CREATE_MONEY_MOVEMENT,
    {
      onError: noopHandler,
    },
  );

  const { data: reasonsData, error: reasonsError } = useQuery(
    GET_MONEY_MOVEMENTS_REASONS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const eventReasons =
    reasonsData?.moneyMovementReasons.filter(
      (reason: MoneyMovementReason) => reason.eventType === form.eventType,
    ) || [];

  useEffect(() => {
    if (paymentTypes) {
      const cashPaymentType = paymentTypes.find(
        x => (x as unknown as PaymentTypeDisplay).label === 'Cash',
      );
      setForm({
        ...initialForm,
        paymentType: (cashPaymentType as PaymentTypeDisplay)?.value || '',
      });
    }
  }, [paymentTypes]);

  useEffect(() => {
    if (moneyMovementEventStatus.error) {
      showNotification({
        error: true,
        message: parseApolloError(moneyMovementEventStatus.error),
      });
    }
  }, [moneyMovementEventStatus.error, showNotification]);

  useEffect(() => {
    if (moneyMovementEventStatus.data) {
      const moneyMovement = moneyMovementEventStatus.data
        .moveMoney as MoneyMovement;
      showNotification({
        success: true,
        message: translate('moneyMovements.success', {
          event:
            moneyMovement.eventType === 'MONEY_IN'
              ? translate('moneyMovements.moneyIn')
              : translate('moneyMovements.moneyOut'),
        }),
      });
      if (
        ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER.includes(
          moneyMovement.paymentType?.name?.toLowerCase(),
        )
      ) {
        openCashDrawer && openCashDrawer();
      }

      // trigger print
      printMoneyMovementReceipt && printMoneyMovementReceipt(moneyMovement);

      setMoneyMovementRecord({
        variables: { data: moneyMovementEventStatus.data },
      });
      closeModal();
    }
  }, [
    moneyMovementEventStatus.data,
    showNotification,
    closeModal,
    setMoneyMovementRecord,
    printMoneyMovementReceipt,
    openCashDrawer,
    translate,
  ]);

  useEffect(() => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect(() => {
    if (reasonsError) {
      showNotification({
        error: true,
        message: parseApolloError(reasonsError),
      });
    }
  }, [reasonsError, showNotification]);

  const onChangeForm = useCallback(
    (prop: string, value: string) => {
      setForm({
        ...form,
        [prop]: value,
      });
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [form, errorMessage],
  );

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({ error: true, message: translate(errorMessage) });
    }
  }, [errorMessage, showNotification, translate]);

  const onSaveEvent = useCallback(() => {
    if (!form.reason) {
      setErrorMessage(FormError.INVALID_REASON);
    } else if (isNaN(parseInt(form.amount)) || parseInt(form.amount) <= 0) {
      setErrorMessage(FormError.INVALID_AMOUNT);
    } else if (!form.paymentType) {
      setErrorMessage(FormError.INVALID_PAYMENTTYPE);
    } else {
      const finalFormData = {
        ...form,
        amount: { amount: +form.amount, currency },
        userId: userUtility.posUser?.id,
      };
      analyticsService.capture('manage_cash', {
        event: capitalCase(form.eventType),
      });
      moneyMovementEvent({ variables: finalFormData });
      setErrorMessage('');
    }
  }, [form, currency, moneyMovementEvent]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.container}>
        <ModalTitle title={translate('moneyMovements.addMoneyEvent')} />
        <View style={styles.content}>
          <SegmentControl
            secondary
            selectedTab={form.eventType}
            onPress={onChangeForm.bind(null, 'eventType')}
            tabs={[
              {
                label: translate('moneyMovements.moneyIn'),
                value: MoneyEventType.MONEY_IN,
              },
              {
                label: translate('moneyMovements.moneyOut'),
                value: MoneyEventType.MONEY_OUT,
              },
            ]}
          />
          {paymentTypes && reasonsData ? (
            <View>
              <TreatPicker
                testID="select-reason"
                title={translate('moneyMovements.selectReason')}
                options={[
                  {
                    label: translate('modifiers.select'),
                    value: '',
                  },
                  ...eventReasons,
                ]}
                selectedValue={form.reason}
                onValueChange={onChangeForm.bind(null, 'reason')}
                containerStyle={styles.input}
              />
              <InputText
                testID="input-amount"
                label={currencySymbol}
                title={translate('moneyMovements.amount')}
                placeholder="0.00"
                value={form.amount}
                onChangeText={onChangeForm.bind(null, 'amount')}
                alignText="right"
                errorMessage={
                  errorMessage === FormError.INVALID_PAYMENTTYPE ||
                  errorMessage === FormError.INVALID_AMOUNT
                    ? 'Enter valid amount'
                    : undefined
                }
                containerStyle={styles.input}
              />
              <InputText
                multiline
                testID="input-note"
                title={translate('moneyMovements.addNotes')}
                placeholder={translate('form.manageCash.notesPlaceholder')}
                value={form.notes}
                onChangeText={onChangeForm.bind(null, 'notes')}
                containerStyle={styles.input}
              />
            </View>
          ) : null}
          <TreatButton
            testID="btn-confirm"
            type="positive"
            onPress={onSaveEvent}
            disabled={moneyMovementEventStatus.loading}
            isLoading={moneyMovementEventStatus.loading}
            label={translate('settings.confirm')}
            containerStyle={styles.btnConfirm}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default AddMoneyEvent;
