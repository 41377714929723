import React from 'react';
import { View, Text } from 'react-native';
import { Customer } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import TreatModal from '../../../Shared/Modals/Modal/Modal';
import styles from './ConfirmAccountPaymentModal.styles';

export interface ConfirmAccountPaymentModalProps {
  customer: Customer;
  processingAmount: number;
  onConfirmPay: () => void;
}

const ConfirmAccountPaymentModal: React.FC<ConfirmAccountPaymentModalProps> = ({
  customer,
  onConfirmPay,
  processingAmount,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const customerName = customer?.firstName + ' ' + customer?.lastName;

  return (
    <TreatModal
      type="focus"
      size="large"
      title={translate('order.customerAccountIdentity.title')}
      onDismiss={{ action: closeModal }}
      onConfirm={{
        label: translate(
          'order.customerAccountIdentity.confirmAndCompletePayment',
        ),
        action: onConfirmPay,
      }}
    >
      <View style={styles.content}>
        <Text>
          {translate('order.customerAccountIdentity.confirmation', {
            customerName: customerName,
            amount: formatCurrency(processingAmount),
          })}
        </Text>
        <View style={styles.values}>
          <View style={styles.value}>
            <Text style={styles.textMajor}>
              {formatCurrency(
                customer?.customerAccountDetails?.maxBalanceLimit || 0,
              )}
            </Text>
            <Text style={styles.textMinor}>
              {translate('order.customerAccountIdentity.creditLimit')}
            </Text>
          </View>
          <View style={styles.value}>
            <Text style={styles.textMajor}>
              {formatCurrency(
                customer?.customerAccountDetails?.maxOrderLimit || 0,
              )}
            </Text>
            <Text style={styles.textMinor}>
              {translate('order.customerAccountIdentity.maxOrderLimit')}
            </Text>
          </View>
          <View style={styles.value}>
            <Text style={styles.textMajor}>
              {formatCurrency(
                customer?.customerAccountDetails?.currentBalance || 0,
              )}
            </Text>
            <Text style={styles.textMinor}>
              {translate('order.customerAccountIdentity.totalOutstanding')}
            </Text>
          </View>
        </View>
      </View>
    </TreatModal>
  );
};

export default ConfirmAccountPaymentModal;
