import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useNotification } from '../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { useStores } from '../../../../../hooks/app/useStores';
import { ProfitMargin } from '@oolio-group/domain';
import { ProfitMarginRow } from './ProfitMarginRow';
import pick from 'lodash/pick';
import { useTranslation } from '@oolio-group/localization';
import { useProductTypes } from '../../../../../hooks/app/useProductTypes';
import styles from './ProfitMargins.styles';
import theme from '../../../../../common/default-theme';
import Section from '../../../../../components/Office/Section/Section';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';

let DEFAULT_MARGIN: ProfitMargin = {
  margin: 0.0,
  productTypeId: '',
};

export const ProfitMargins: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [form, setForm] = useState<ProfitMargin[]>([]);

  const params = route.params as {
    storeId: string;
    venueId: string;
  };
  const { storeId, venueId } = params;

  const {
    stores,
    updateStoreProfitMargins,
    updatedStoreId,
    loading: profitMarginsLoading,
    error: profitMarginsError,
  } = useStores({
    storeId,
    venueId,
  });

  const {
    productTypes,
    getProductTypes,
    loading: productTypesLoading,
    error: productTypesError,
  } = useProductTypes();

  const loading = profitMarginsLoading || productTypesLoading;
  const error = profitMarginsError || productTypesError;

  // fetch data
  useEffect(() => {
    getProductTypes();
  }, [getProductTypes]);

  useEffect(() => {
    if (stores[storeId]) {
      const profitMargins = stores[storeId]?.profitMargins || [];
      setForm(profitMargins as ProfitMargin[]);
    }
  }, [stores, storeId]);

  useEffect(() => {
    if (productTypes) {
      DEFAULT_MARGIN = {
        margin: 0,
        productTypeId: Object.values(productTypes)[0]?.id,
      };
    }
  }, [productTypes]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedStoreId) {
      showNotification({
        success: true,
        message: translate('common.toastSuccess', {
          entity: translate('profitMargins.profitMargin'),
          action: translate('common.updated').toLowerCase(),
        }),
      });
    }
  }, [updatedStoreId, showNotification, translate]);

  // on change events
  const onChange = useCallback(
    (prop: string, index: number, value: string): void => {
      const updatedForm = [...form];
      updatedForm[index] = { ...updatedForm[index], [prop]: value };
      setForm(updatedForm);
    },
    [form],
  );

  const onDelete = useCallback(
    (index: number): void => {
      const updatedForm = [...form];
      updatedForm.splice(index, 1);
      setForm(updatedForm);
    },
    [form],
  );

  const onPressCreateNew = useCallback(() => {
    setForm(form => [...form, DEFAULT_MARGIN as ProfitMargin]);
  }, []);

  const onSave = useCallback((): void => {
    const productTypeIds = Object.keys(productTypes);
    const dataToUpdate = Object.values(form)
      .map(
        profitMargin =>
          ({
            ...pick(profitMargin, ['id', 'name']),
            margin: +(+profitMargin.margin)?.toFixed(2),
            productTypeId: profitMargin.productTypeId,
          } as ProfitMargin),
      )
      .filter(
        pm => !pm.productTypeId || productTypeIds.includes(pm.productTypeId),
      );

    if (
      dataToUpdate.some(
        profitMargin =>
          !profitMargin.productTypeId || profitMargin.margin === undefined,
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeSections.fieldsMissing'),
      });
    } else {
    }
    updateStoreProfitMargins({
      id: storeId,
      profitMargins: dataToUpdate,
    });
  }, [
    updateStoreProfitMargins,
    form,
    storeId,
    showNotification,
    translate,
    productTypes,
  ]);

  const reportingGroups = useMemo(
    () => Object.values(productTypes),
    [productTypes],
  );

  const hasReportingGroups = reportingGroups.length > 0;

  return (
    <ScreenLayout
      loading={loading}
      title="Profit Margins | Oolio"
      onSave={onSave}
    >
      <Section
        title={translate('profitMargins.profitMargins')}
        subtitle={translate('profitMargins.sectionSubtitle')}
      >
        {hasReportingGroups ? (
          <View>
            <View style={theme.tables.header}>
              <Text style={[theme.tables.headerText, styles.headerGroup]}>
                {translate('backOfficeProducts.productType')}
              </Text>
              <Text style={[theme.tables.headerText, styles.headerMargin]}>
                {translate('profitMargins.margin')}
              </Text>
            </View>
            <View>
              {form.length > 0 ? (
                form.map((margin: ProfitMargin, i: number) => (
                  <ProfitMarginRow
                    index={i}
                    key={`${i}-${margin.productTypeId}`}
                    profitMargin={margin}
                    reportingGroups={reportingGroups}
                    onChange={onChange}
                    onDelete={onDelete}
                  />
                ))
              ) : (
                <View style={theme.tables.emptyView}>
                  <Text style={theme.tables.emptyText}>
                    {translate('profitMargins.emptyTable')}
                  </Text>
                </View>
              )}
              <ButtonIcon
                testID="btn-addMargin"
                size={34}
                icon="plus"
                type="positive"
                onPress={onPressCreateNew}
                containerStyle={styles.btnAdd}
              />
            </View>
          </View>
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('profitMargins.noReportingGroups')}
            </Text>
          </View>
        )}
      </Section>
    </ScreenLayout>
  );
};
