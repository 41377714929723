import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ManageFeaturesTabs from './ManageFeatures/ManageFeaturesTabs';
import FeatureSettingsTabs from './ManageFeatures/FeatureTabs';
import XeroSettingsTabs from './ManageIntegrations/Xero/XeroSettingsStack';
import DeputySettingsTabs from './ManageIntegrations/Deputy/DeputySettingsStack';
import OnlineOrderIntegrationsStoreSettingsTabs from './ManageIntegrations/OnlineOrders/StoreSettings/OnlineOrderIntegrationsStoreSettingsTabs';
import OnlineOrderIntegrationsSettingsTab from './ManageIntegrations/OnlineOrders/OnlineOrderIntegrationsSettingsTabs';
import XeroStoreSettingsPage from './ManageIntegrations/Xero/Settings/StoreSettingsPage';
import DeputyStoreTab from './ManageIntegrations/Deputy/Settings/StoreSettings/DeputyStoreTab';
import OolioReservationIntegrationsSettingsTabs from './ManageIntegrations/Reservations/OolioReservationIntegrationsSettingsTabs';
const Stack = createStackNavigator();

const FeaturesAndIntegrationsStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="ManageFeatures"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={ManageFeaturesTabs} name="ManageFeatures" />
    <Stack.Screen component={FeatureSettingsTabs} name="FeaturesSettings" />

    <Stack.Screen component={DeputyStoreTab} name="DeputyStoreTab" />
    <Stack.Screen component={XeroSettingsTabs} name="XeroSettings" />
    <Stack.Screen component={XeroStoreSettingsPage} name="XeroSettingsPage" />
    <Stack.Screen component={DeputySettingsTabs} name="DeputySettings" />
    <Stack.Screen
      component={OnlineOrderIntegrationsSettingsTab}
      name="OnlineOrderIntegrationsSettings"
    />
    <Stack.Screen
      component={OnlineOrderIntegrationsStoreSettingsTabs}
      name="OnlineOrderIntegrationStoreSettings"
    />
    <Stack.Screen
      component={OolioReservationIntegrationsSettingsTabs}
      name="OolioReservationIntegrationsSettings"
    />
  </Stack.Navigator>
);

export default FeaturesAndIntegrationsStack;
