import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './CartTraining.styles';

const CartTraining = () => {
  const { translate } = useTranslation();

  return (
    <View style={styles.container}>
      <Text testID={'training-transaction'} style={styles.text}>
        {translate('trainingMode.enableTrainingMode')}
      </Text>
    </View>
  );
};

export default CartTraining;
