import React from 'react';
import { Text, View } from 'react-native';
import styles from './Chip.styles';
import { TouchableOpacity } from 'react-native';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';

interface ChipProps {
  title: string;
  icon: string;
  onPress: () => void;
}

const Chip: React.FC<ChipProps> = ({ title, icon, onPress }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}&nbsp;</Text>
      <TouchableOpacity onPress={onPress}>
        <Icon
          name={icon}
          size={22}
          style={styles.icon}
          color={theme.colors.white}
        />
      </TouchableOpacity>
    </View>
  );
};

export default Chip;
