import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { Order, OrderTypeCode } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { WORKER_MESSAGES_KEY } from '../../../state/preferences';
import {
  DEFAULT_NET_PRINTER_PORT,
  PrintWorkerActionData,
  WorkerActionResult,
  WorkerActionResultStatus,
} from '../../../workers/utils';
import * as storage from '../../../storage/interface';
import { failedPrintJobsCountVar } from '../../../state/cache';
import { WorkerAction } from '../../../workers/utils';
import { workerInstanceVar } from '../../../state/cache';
import { useSession } from '../../../hooks/app/useSession';
import { usePrinters } from '../../../hooks/app/usePrinters';
import { format } from 'date-fns';
import styles from './PrintingQueue.styles';
import theme from '../../../common/default-theme';
import Search from '../../../components/Shared/Search/Search';
import Sticker from '../../../components/Shared/Sticker/Sticker';
import ReprintModal from './ReprintModal/ReprintModal';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import { getTableNameFromOrder } from '@oolio-group/order-helper';

export type PrintJob = WorkerActionResult;

const PrintJobs: React.FC = () => {
  const [session] = useSession();
  const { showModal } = useModal();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const workerInstance = workerInstanceVar();
  const failedPrintJobsCount = useReactiveVar(failedPrintJobsCountVar);
  const { printers, getPrinters } = usePrinters({
    storeId: session.currentStore?.id,
  });

  const [filterText, setFilterText] = useState<string>('');
  const [failedPrintJobs, setFailedPrintJobs] = useState<PrintJob[]>([]);

  useEffect(() => {
    getPrinters && getPrinters();
  }, [getPrinters]);

  useEffect(() => {
    (async () => {
      failedPrintJobsCount;
      const responses =
        (await storage.getItem<WorkerActionResult[]>(WORKER_MESSAGES_KEY)) ||
        [];

      setFailedPrintJobs(
        (responses || [])
          .filter(item => item.status === WorkerActionResultStatus.ERROR)
          .sort((item1, item2) =>
            (item1.timestamp || 0) < (item2.timestamp || 0) ? 0 : -1,
          ) as [],
      );
    })();
  }, [failedPrintJobsCount]);

  const remove = useCallback(
    async (printJobs: PrintJob[]) => {
      await storage.setItem<WorkerActionResult[]>(
        WORKER_MESSAGES_KEY,
        failedPrintJobs.filter(
          job =>
            !printJobs
              .map(printJob => printJob.requestId)
              .includes(job.requestId),
        ),
      );
    },
    [failedPrintJobs],
  );

  const reprint = useCallback(
    async (printJobs: PrintJob[]) => {
      await remove(printJobs);
      for (const printJob of printJobs) {
        const data = printJob.data as PrintWorkerActionData;
        if (workerInstance && data) {
          data.buffer &&
            data.printer &&
            workerInstance.send({
              action: WorkerAction.PRINT,
              data: {
                bufferObjs: [{ buffer: data.buffer, printer: data.printer }],
                order: data?.order,
              },
            });
        }
      }
    },
    [workerInstance, remove],
  );

  const filteredJobs = useMemo(
    () =>
      failedPrintJobs.filter(
        job =>
          (job.timestamp || '')
            ?.toString()
            .toLowerCase()
            .includes(filterText.toLowerCase()) ||
          ((job.data as PrintWorkerActionData)?.order?.orderNumber || '')
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase()),
      ),
    [filterText, failedPrintJobs],
  );

  const onPressReprintAll = useCallback(() => {
    reprint(filteredJobs);
  }, [reprint, filteredJobs]);

  const onPressClearAll = useCallback(() => {
    remove(filteredJobs);
  }, [remove, filteredJobs]);

  const onPressReprint = useCallback(
    (printJob: PrintJob) => {
      showModal(
        <ReprintModal
          onSubmit={(printerId?: string) => {
            printerId &&
              printers[printerId] &&
              reprint([
                {
                  ...printJob,
                  data: {
                    ...printJob.data,
                    printer: {
                      id: printerId,
                      device_name: printers[printerId].name,
                      host: printers[printerId].ipAddress,
                      bdAddress: printers[printerId].bdAddress,
                      slug: printers[printerId].slug,
                      series: printers[printerId].series,
                      brand: printers[printerId].brand,
                      port: DEFAULT_NET_PRINTER_PORT,
                      type: printers[printerId].printerType,
                    },
                  },
                },
              ]);
          }}
          printerId={(printJob.data as PrintWorkerActionData)?.printer?.id}
          printers={Object.values(printers)}
        ></ReprintModal>,
      );
    },
    [reprint, showModal, printers],
  );

  const getPrintType = useCallback((action?: WorkerAction) => {
    if (action) {
      let text;
      switch (action) {
        case WorkerAction.PRINT_BILL:
          text = 'Receipt';
          break;
        case WorkerAction.PRINT_KITCHEN_DOCKET:
        case WorkerAction.PRINT:
        case WorkerAction.PRINT_RESEND_KITCHEN_DOCKET:
          text = 'Docket';
          break;
      }
      return text;
    }
  }, []);

  const getDescripton = useCallback((order?: Order) => {
    if (order) {
      let text;
      switch (order.orderType?.code) {
        case OrderTypeCode.DINE_IN:
          text = getTableNameFromOrder(order);
          break;
        case OrderTypeCode.DELIVERY:
          text = order?.customer?.firstName;
          break;
        default:
          text = order?.orderNote;
          break;
      }
      return text;
    }
  }, []);

  return (
    <ScreenLayout
      title={translate('printing.printingQueueLabel')}
      onBack={() => navigation.goBack()}
    >
      <View style={styles.filters}>
        <Search
          testID="search-jobs"
          value={filterText}
          onChangeText={setFilterText}
          placeholder={translate('printing.searchPlaceholder')}
          containerStyle={styles.search}
        />
        <TreatButton
          testID="btn-reprintAll"
          type="neutral"
          label={translate('printing.reprintAll')}
          onPress={onPressReprintAll}
          containerStyle={styles.btnReprint}
        />
        <TreatButton
          testID="btn-clearAll"
          type="negative"
          label={translate('printing.clearAll')}
          onPress={onPressClearAll}
        />
      </View>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text style={[theme.tables.headerText, styles.cellStatus]}>
            {translate('printing.status')}
          </Text>
          <Text style={[theme.tables.headerText, styles.cellOrder]}>
            {translate('backOfficeSalesFeed.OrderNo')}
          </Text>
          <Text style={[theme.tables.headerText, styles.cellType]}>
            {translate('printing.printType')}
          </Text>
          <Text style={[theme.tables.headerText, styles.cellTime]}>
            {translate('printing.timestamp')}
          </Text>
          <Text style={[theme.tables.headerText, styles.cellPrinter]}>
            {translate('printing.printer')}
          </Text>
          <Text style={[theme.tables.headerText, styles.cellDescription]}>
            {translate('printing.description')}
          </Text>
          <Text style={[theme.tables.headerText, styles.cellMessage]}>
            {translate('printing.message')}
          </Text>
        </View>
        <View style={styles.tableBody}>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((job, i) => {
              return (
                <View key={i} testID="row-job" style={styles.tableRow}>
                  <Sticker
                    testID="job-status"
                    label="Failed"
                    type="negativeLight"
                    containerStyle={styles.cellStatus}
                  />
                  <Text testID="order-no" style={styles.cellOrder}>
                    {(
                      job.data as PrintWorkerActionData
                    )?.order?.orderNumber.slice(0, -9) || 'N/A'}
                  </Text>
                  <Text testID="job-type" style={styles.cellType}>
                    {getPrintType(job.action)}
                  </Text>
                  <Text testID="job-time" style={styles.cellTime}>
                    {job.timestamp &&
                      format(+job.timestamp, 'dd/MM/yy hh:mm a')}
                  </Text>
                  <Text testID="job-printer" style={styles.cellPrinter}>
                    {(job.data as PrintWorkerActionData)?.printer?.device_name}
                  </Text>
                  <Text
                    testID="job-description"
                    numberOfLines={1}
                    style={styles.cellDescription}
                  >
                    {getDescripton((job.data as PrintWorkerActionData)?.order)}
                  </Text>
                  <Text
                    testID="job-message"
                    numberOfLines={1}
                    style={styles.cellMessage}
                  >
                    {job.message ? job.message.replace('TypeError: ', '') : ''}
                  </Text>
                  <ButtonIcon
                    testID="btn-print"
                    icon="print"
                    type="neutralLight"
                    onPress={() => onPressReprint(job)}
                  />
                  <ButtonIcon
                    testID="btn-remove"
                    icon="times"
                    type="negativeLight"
                    onPress={() => remove([job])}
                    containerStyle={styles.cellRemove}
                  />
                </View>
              );
            })
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {translate('printing.emptyQueue')}
              </Text>
            </View>
          )}
        </View>
      </View>
    </ScreenLayout>
  );
};

export default PrintJobs;
