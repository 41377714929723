import { useEffect, useState, useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

const useBehaviorSubjectState = <T>(subject: BehaviorSubject<T>) => {
  const [state, setState] = useState<T>();
  useEffect(() => {
    const subscribe = subject.subscribe(state => setState(state));
    return () => subscribe?.unsubscribe?.();
  }, [subject]);

  const dispatchNewState = useCallback(
    (newValue: T) => {
      subject.next(newValue);
    },
    [subject],
  );

  return { value: state, setValue: dispatchNewState };
};

export default useBehaviorSubjectState;
