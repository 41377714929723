import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Text, StatusBar } from 'react-native';
import { Helmet } from 'react-helmet';
import { App } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import jwt_decode from 'jwt-decode';
import QrCode from 'react-native-qrcode-svg';
import { DeviceCodeLoginParams, useDeviceCodeLogin } from '@oolio-group/hooks';
import { AuthState, auth0Config } from '../../../constants';
import { useNotification } from '../../../hooks/Notification';
import { useUserProfile } from '../../../hooks/app/useUserProfile';
import useRolesContext from '../../../hooks/app/users/useRolesContext';
import { userUtility } from '../../../state/userUtility';
import { tokenUtility } from '../../../state/tokenUtility';
import { getExpiresAfter } from '../../../state/preferences';
import { navigateToLockScreen } from '../../../state/navigation';
import styles from './DeviceCodeLogin.styles';
import theme from '../../../common/default-theme';
import Gradient from '../../../components/Gradient/Gradient';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import 'text-encoding';

export interface State {
  deviceCode: {
    email: string;
  };
  isLoggedIn?: boolean;
  empPassCode: string;
  errorMessage: string;
  isFormComplete: boolean;
  hidePassword: boolean;
}

const deviceCodeConfig: DeviceCodeLoginParams = {
  clientId: auth0Config.clientId,
  audience: auth0Config.additionalParameters.audience,
  getTokenUrl: auth0Config.getTokenUrl,
  getDeviceCodeUrl: auth0Config.deviceCodeUrl,
};

export const CODE_IN_USE_ERROR =
  'Code is already in use by another device – try another code.';

const DeviceCodeLogin: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refreshQrCodeIntervalRef = useRef<any>();

  const { getMe } = useUserProfile();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { fetchRolesSync, updateRoles } = useRolesContext();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const {
    getDeviceCode,
    error,
    verificationUri,
    tokenPayload,
    userCode,
    expiresInRef: qrCodeTimeoutRef,
  } = useDeviceCodeLogin(deviceCodeConfig);
  const { width, height } = theme.useResponsiveDimensions();

  const [, setErrorMessage] = useState('');

  const fetchAndUpdateRoles = useCallback(async () => {
    const roles = await fetchRolesSync();
    updateRoles(roles);
  }, [fetchRolesSync, updateRoles]);

  const onPressBackButton = useCallback((): void => {
    setErrorMessage('');
    navigation.push('LoginTypeSelection');
  }, [navigation, setErrorMessage]);

  useEffect(() => {
    getDeviceCode();
  }, [getDeviceCode]);

  useEffect(() => {
    // refresh the QR code when it expires
    if (userCode && qrCodeTimeoutRef.current)
      refreshQrCodeIntervalRef.current = setTimeout(
        getDeviceCode,
        qrCodeTimeoutRef.current,
      );
    return () => {
      clearTimeout(refreshQrCodeIntervalRef.current);
    };
  }, [getDeviceCode, qrCodeTimeoutRef, userCode]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message:
          error === CODE_IN_USE_ERROR
            ? translate('deviceCodeLogin.codeInUseErr')
            : translate('deviceCodeLogin.invalidCode'),
      });
    }
  }, [error, showNotification, translate]);

  useEffect(() => {
    if (tokenPayload) {
      const tokenInfo = {
        idToken: tokenPayload.idToken,
        refreshToken: tokenPayload.refreshToken,
        token: tokenPayload.accessToken,
        expiresAfter: getExpiresAfter(tokenPayload.expiresIn as number),
        activeApp: App.POS_APP,
      };
      const idTokenPayload = jwt_decode<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        user_metadata: any;
        email: string;
      }>(tokenPayload.idToken as string);

      userUtility.addOfficeUser({
        name: idTokenPayload.user_metadata?.name,
        email: idTokenPayload.email,
        id: idTokenPayload.user_metadata?.id,
      });
      tokenUtility.setTokenInfo({
        ...tokenInfo,
        authState: AuthState.LOGGED_IN,
      });
      Promise.all([getMe(), fetchAndUpdateRoles()])
        .then(() => {
          navigation.reset(navigateToLockScreen());
        })
        .catch(() => {
          userUtility.clearUserActivity();
          tokenUtility.clearToken();
        });
    }
  }, [tokenPayload, getMe, fetchAndUpdateRoles, navigation, showNotification]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.loginPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={{ width, height }}>
        <Gradient style={styles.screen}>
          <View style={styles.modalContainer}>
            <ButtonIcon
              size={44}
              type="cancel"
              icon="arrow-left"
              onPress={onPressBackButton}
              containerStyle={styles.btnBack}
            />
            <Text style={styles.title}>
              {translate('deviceCodeLogin.title')}
            </Text>
            {!!verificationUri ? (
              <>
                <View style={styles.qrContainer}>
                  <QrCode value={verificationUri} size={160} />
                  <ButtonIcon
                    size={44}
                    icon="sync"
                    type="neutralLight"
                    onPress={getDeviceCode}
                    containerStyle={styles.btnRefresh}
                  />
                </View>
                <View style={styles.codeContainer}>
                  <Text style={styles.code}>{userCode}</Text>
                </View>
              </>
            ) : null}
            <Text style={styles.footnote}>
              {translate('deviceCodeLogin.description')}
            </Text>
          </View>
        </Gradient>
      </View>
    </>
  );
};

export default DeviceCodeLogin;
