import { useCallback, useState } from 'react';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  UpdateAdjustmentInput,
  Adjustment,
  AdjustmentType,
} from '@oolio-group/domain';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useSession } from '../app/useSession';
import { Venue } from '@oolio-group/domain';
import {
  GET_ADJUSTMENTS_BY_VENUE_ID,
  DELETE_ADJUSTMENT_BY_ID,
  CREATE_OR_UPDATE_ADJUSTMENTS,
  GET_ADJUSTMENT_BY_ID,
  GET_ADJUSTMENTS_BY_ADJUSTMENT_TYPE,
} from '../../graphql/adjustments';
import { useNotification } from '../Notification';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';

export interface UseAdjustmentReturn {
  loading: boolean;
  adjustment?: Adjustment;
  adjustments: Adjustment[];
  getAdjustmentById: (id: string) => void;
  getAllAdjustments: (type?: AdjustmentType) => void;
  deleteAdjustment: (id: string) => void;
  updateAdjustments: (input: UpdateAdjustmentInput) => void;
}

export interface UseAdjustmentInput {
  venueId: string;
  onDeleteComplete?: () => void;
}

export function useAdjustments(input: UseAdjustmentInput): UseAdjustmentReturn {
  const { venueId, onDeleteComplete } = input;
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [session, updateSession] = useSession();
  const navigation = useNavigation();

  const [adjustments, setAdjustments] = useState<Adjustment[]>([]);

  const handleCompleteRequest = (response: { venue: Venue }) => {
    setAdjustments(response?.venue?.adjustments || []);
  };

  const updateAdjustmentInSession = (input: Adjustment[]) => {
    updateSession({
      ...session,
      currentVenue: {
        ...session.currentVenue,
        adjustments: input,
      },
    });
  };

  const [
    getAdjustmentsRequest,
    { loading: getLoading, refetch: refreshAdjustmentList },
  ] = useLazyQuery(GET_ADJUSTMENTS_BY_VENUE_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleteRequest,
    onError: noopHandler,
  });

  const [
    getAdjustmentsByAdjustmentTypeRequest,
    { loading: adjRequestLoading },
  ] = useLazyQuery(GET_ADJUSTMENTS_BY_ADJUSTMENT_TYPE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      setAdjustments(response?.adjustmentsByType ?? []);
    },
    onError: noopHandler,
  });

  const [
    getAdjustmentByIdRequest,
    { loading: adjustmentLoading, data: adjustmentData },
  ] = useLazyQuery(GET_ADJUSTMENT_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
  });

  const [deleteAdjustmentRequest, { loading: deleteLoading }] = useMutation(
    DELETE_ADJUSTMENT_BY_ID,
    {
      onError: () => {
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.adjustmentFailedToDelete',
          ),
          error: true,
        });
      },
      onCompleted: () => {
        typeof onDeleteComplete == 'function' && onDeleteComplete();
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.adjustmentDeletedSuccessfully',
          ),
          success: true,
        });

        navigation.goBack();
      },
    },
  );

  const [updateAdjustmentsRequest, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_ADJUSTMENTS,
    {
      onError: error => {
        showNotification({
          message: parseApolloError(error),
          error: true,
        });
      },
      onCompleted: data => {
        const adjustmentIds = data.createOrUpdateAdjustments
          ?.map((r: Adjustment) => r.id)
          .filter((id: string | undefined) => id);
        const adjustmentArray: Adjustment[] =
          session.currentVenue?.adjustments?.filter(
            adjustment => !adjustmentIds.includes(adjustment?.id),
          ) ?? [];
        updateAdjustmentInSession([
          ...adjustmentArray,
          ...data.createOrUpdateAdjustments,
        ]);

        // createOrUpdateAdjustments api call will always return an array of a single object since we are creating or updating adjustments one at a time
        getAdjustmentById(data.createOrUpdateAdjustments[0]?.id);

        refreshAdjustmentList && refreshAdjustmentList();
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.adjustmentUpdateSuccessfully',
          ),
          success: true,
        });
      },
      context: {
        headers: { venue: venueId },
      },
    },
  );

  const updateAdjustments = (input: UpdateAdjustmentInput) => {
    updateAdjustmentsRequest({ variables: { input } });
  };

  const getAdjustmentById = useCallback(
    (id: string) => {
      getAdjustmentByIdRequest({ variables: { id } });
    },
    [getAdjustmentByIdRequest],
  );

  const getAllAdjustments = useCallback(
    (type?: AdjustmentType) => {
      if (!venueId) return;
      if (type) {
        getAdjustmentsByAdjustmentTypeRequest({
          variables: { input: { venueId, type } },
        });
      } else {
        getAdjustmentsRequest({ variables: { id: venueId } });
      }
    },
    [getAdjustmentsByAdjustmentTypeRequest, getAdjustmentsRequest, venueId],
  );

  const deleteAdjustment = (id: string) => {
    deleteAdjustmentRequest({ variables: { id } });
    const filteredAdjustments =
      session.currentVenue?.adjustments?.filter(
        adjustment => adjustment.id !== id,
      ) ?? [];
    updateAdjustmentInSession(filteredAdjustments);
    setAdjustments(filteredAdjustments);
  };

  const loading =
    adjRequestLoading ||
    getLoading ||
    adjustmentLoading ||
    deleteLoading ||
    updateLoading;

  return {
    loading,
    adjustment: adjustmentData?.adjustment,
    adjustments,
    getAdjustmentById,
    getAllAdjustments,
    deleteAdjustment,
    updateAdjustments,
  };
}
