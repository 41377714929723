import React, { useCallback, useEffect, useMemo } from 'react';
import { DraggableGrid } from './DraggableGrid';
import {
  Category,
  CreateOrUpdatePageInput,
  EntityType,
  Page,
  PageMaps,
} from '@oolio-group/domain';
import PageItemElement from './GridItem/PageItem';
import PaginationGrid from './GridItem/PaginationGrid/PaginationGrid';
import {
  EditMenuAction,
  EditMenuState,
  PageItemAction,
  ProductItemType,
  TileItem,
} from '../types';
import PageEmptyItem from './GridItem/PageEmptyItem';
import { useModal } from '@oolio-group/rn-use-modal';
import AddExistingProducts from './Modals/AddExistingProducts';
import {
  EditMenuActionProp,
  itemsPerSection,
  paginationItem,
} from './MenuLayout';
import { v4 as uuidv4 } from 'uuid';
import AddCategory from './Modals/AddCategory';
import AddExistingPage from './Modals//AddExistingPage';
import { difference, last, sortBy } from 'lodash';
import EditProduct from './Modals/EditProduct';
import ClearTileConfirmation from './Modals/ClearTileConfirm';
import CreateAndAddNewPage from './Modals/CreateAndAddPage';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import BackNavigationButton from './GridItem/BackNavigationButton';
import { translate } from '@oolio-group/localization';
import { v4 as uuidV4 } from 'uuid';
import { useNotification } from '../../../../../hooks/Notification';

interface ProductLayoutProps {
  dispatch: React.Dispatch<EditMenuActionProp>;
  state: EditMenuState;
  productItems: ProductItemType[];
  categoryMaps: Record<string, Category>;
  pageMaps: PageMaps;
  hasPageItemsChange?: boolean;
  onSaveMenu: () => void;
  createOrUpdatePage: (
    updateMenuInput: CreateOrUpdatePageInput,
  ) => Promise<Page | undefined>;
}

const PageItemsLayout: React.FC<ProductLayoutProps> = ({
  dispatch,
  state,
  productItems,
  categoryMaps,
  pageMaps,
  hasPageItemsChange,
  onSaveMenu,
  createOrUpdatePage,
}) => {
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    currentPageItemsSection: currentSection,
    pageItems,
    viewingNestedPageIds,
    menuItems,
    selectedMenuItemId,
    assigningCategoryIds,
  } = state;

  const selectedPageId = useMemo(
    () =>
      menuItems.find(menuItem => menuItem?.id === selectedMenuItemId)
        ?.entityId || '',

    [menuItems, selectedMenuItemId],
  );

  const viewingPageId = last(viewingNestedPageIds) || selectedPageId;

  const viewingPage = pageMaps[viewingPageId] as Page | undefined;

  const startIndex = (currentSection - 1) * itemsPerSection;
  const endIndex = currentSection * itemsPerSection;

  const dataWithPagination = pageItems
    .slice(startIndex, endIndex)
    .concat(paginationItem);

  const onChangePageItems = useCallback(
    (data: TileItem[]) => {
      data.pop();
      const updatedData = [...pageItems];
      updatedData.splice(startIndex, itemsPerSection, ...data);
      dispatch({
        type: EditMenuAction.UPDATE_PAGE_ITEM_DATA,
        payload: {
          data: updatedData,
        },
      });
    },
    [dispatch, pageItems, startIndex],
  );

  const onNextPage = useCallback(() => {
    dispatch({
      type: EditMenuAction.ON_CHANGE_PAGE_ITEM_PAGINATION,
      payload: {
        page: currentSection + 1,
      },
    });
  }, [currentSection, dispatch]);

  const onPreviousPage = useCallback(() => {
    dispatch({
      type: EditMenuAction.ON_CHANGE_PAGE_ITEM_PAGINATION,
      payload: {
        page: currentSection - 1,
      },
    });
  }, [currentSection, dispatch]);

  const onMoveItemToAnotherSection = useCallback(
    (
      item: TileItem,
      currentPosition: number,
      moveItemToNextSection?: boolean,
    ) => {
      if (currentSection === 1) {
        //  not allow to let the first section empty
        const dataAfterChange = dataWithPagination.filter(
          x => x.id && x.id !== item?.id,
        );
        if (!dataAfterChange.length) {
          return showNotification({
            message: translate('menus.firstSectionCannotBeEmpty'),
            error: true,
          });
        }
      }
      let foundIndex = -1;
      let findingSection = currentSection;
      do {
        findingSection = findingSection + (moveItemToNextSection ? 1 : -1);
        const startIndex = (findingSection - 1) * itemsPerSection;
        const endIndex = findingSection * itemsPerSection;
        for (let i = startIndex; i < endIndex; i++) {
          if (
            foundIndex == -1 &&
            !pageItems[i]?.id &&
            pageItems[i]?.entityType !== 'BACK_NAVIGATION'
          ) {
            foundIndex = i;
          }
        }
      } while (foundIndex == -1 && findingSection >= 0);

      if (foundIndex < 0) {
        showNotification({
          message: translate('menus.moveEntityUnsuccessfully', {
            name: item.name,
          }),
          error: true,
        });
        return;
      }
      const updatedData = [...pageItems];
      updatedData.splice(foundIndex, 1, item);
      updatedData[currentPosition] = { key: uuidV4() };
      dispatch({
        type: EditMenuAction.UPDATE_PAGE_ITEM_DATA,
        payload: {
          data: updatedData,
        },
      });

      dispatch({
        type: EditMenuAction.ON_CHANGE_PAGE_ITEM_PAGINATION,
        payload: {
          page: findingSection,
        },
      });
      showNotification({
        message: translate('menus.moveEntitySuccessfully', {
          name: item?.name,
        }),
        success: true,
      });
    },
    [currentSection, dataWithPagination, dispatch, pageItems, showNotification],
  );

  useEffect(() => {
    const currentMaxPage = Math.ceil(pageItems.length / itemsPerSection);
    if (currentSection <= currentMaxPage) return;
    const lastIndex = pageItems.length;
    const newEmptyItems = Array(itemsPerSection)
      .fill(null)
      .map((_, index) => ({
        key: `${lastIndex + index}`,
      }));

    dispatch({
      type: EditMenuAction.UPDATE_PAGE_ITEM_DATA,
      payload: {
        data: pageItems.concat(newEmptyItems),
      },
    });
  }, [currentSection, dispatch, pageItems]);

  useEffect(() => {
    const pageItemsLength = pageItems.length;
    const oddPageItems = pageItemsLength % itemsPerSection;
    if (oddPageItems === 0) return;
    const updatedPageItems = [...pageItems];
    const lastOddPageItems = pageItems.slice(-oddPageItems);
    const isAllEmptyItems = lastOddPageItems.every(item => !item.id);
    if (isAllEmptyItems) {
      // remove redundant empty tile at the end due to batch insert items.
      updatedPageItems.splice(pageItemsLength - oddPageItems);
    } else {
      const newEmptyItems = Array(itemsPerSection - oddPageItems)
        .fill(null)
        .map(() => ({
          key: uuidV4(),
        }));
      updatedPageItems.push(...newEmptyItems);
    }

    dispatch({
      type: EditMenuAction.UPDATE_PAGE_ITEM_DATA,
      payload: {
        data: updatedPageItems,
      },
    });
  }, [currentSection, dispatch, pageItems]);

  const onAddProductToPage = useCallback(
    (position: number, items: ProductItemType[], selectedCategory?: string) => {
      const formatItems = items.map(item => {
        const id = uuidv4();
        return {
          id,
          entityId: item.id,
          entityType: item.entityType,
          name: item.name,
          key: id,
          color: item.color,
          category: item.category,
        };
      }) as TileItem[];

      const sortedItems = sortBy(formatItems, item =>
        item?.name?.toLowerCase(),
      );

      dispatch({
        type: EditMenuAction.INSERT_PAGE_ITEMS,
        payload: {
          data: sortedItems,
          position,
          selectedCategory,
        },
      });
    },
    [dispatch],
  );

  const onConfirmClearPageItem = useCallback(
    (params: { item: TileItem; position: number }, clearAll: boolean) => {
      const { item, position } = params;
      closeModal();

      if (clearAll) {
        return dispatch({
          type: EditMenuAction.CLEAR_SAME_PAGE_ITEM_TILE,
          payload: {
            clearEntityId: item.entityId,
          },
        });
      }

      dispatch({
        type: EditMenuAction.CLEAR_PAGE_ITEM_TILE,
        payload: {
          position: position,
        },
      });
    },
    [closeModal, dispatch],
  );

  const onUpdateProduct = useCallback(
    (position: number, updatedItem: TileItem) => {
      dispatch({
        type: EditMenuAction.INSERT_PAGE_ITEMS,
        payload: { data: [updatedItem], position },
      });
      closeModal();
    },
    [closeModal, dispatch],
  );

  const onAddExistingPage = useCallback(
    (
      position: number,
      pageInfo: { pageId: string; color: string; name: string },
    ) => {
      const { pageId, color, name } = pageInfo;
      const id = uuidv4();
      const formatItem = {
        id,
        key: id,
        entityId: pageId,
        entityType: EntityType.Page,
        name,
        color,
      } as TileItem;

      dispatch({
        type: EditMenuAction.INSERT_PAGE_ITEMS,
        payload: {
          data: [formatItem],
          position: position,
        },
      });
    },
    [dispatch],
  );

  const onAddPageToMenu = useCallback(
    (
      params: { position: number },
      pageInfo: { pageId: string; color: string; name?: string },
    ) => {
      const { pageId, color, name } = pageInfo;
      const { position } = params;
      const id = uuidv4();
      const formatItem = {
        id,
        key: id,
        entityId: pageId,
        entityType: EntityType.Page,
        color,
        name,
      } as TileItem;

      dispatch({
        type: EditMenuAction.INSERT_PAGE_ITEMS,
        payload: {
          data: [formatItem],
          position,
        },
      });
    },
    [dispatch],
  );

  const onViewPage = useCallback(
    (selectedPageId: string) => {
      dispatch({
        type: EditMenuAction.VIEW_NESTED_PAGE,
        payload: { nestedPageId: selectedPageId },
      });
    },
    [dispatch],
  );

  const onConfirmBackToPage = useCallback(() => {
    dispatch({ type: EditMenuAction.VIEW_PREVIOUS_PAGE, payload: {} });
  }, [dispatch]);

  const onPressBackToPreviousPage = useCallback(() => {
    if (hasPageItemsChange) {
      const onConfirmSave = () => {
        closeModal();
        onSaveMenu();
      };
      return showModal(
        <ConfirmationModal
          title="Warning"
          message={'Data has been changed, please save before continue'}
          confirmLabel="Save"
          cancelLabel="Discard Changes"
          onConfirm={onConfirmSave}
          onCancel={onConfirmBackToPage}
          type="neutral"
        />,
      );
    }
    onConfirmBackToPage();
  }, [
    closeModal,
    hasPageItemsChange,
    onConfirmBackToPage,
    onSaveMenu,
    showModal,
  ]);

  const onEditPage = useCallback(
    (position: number, updateItem: TileItem) => {
      dispatch({
        type: EditMenuAction.INSERT_PAGE_ITEMS,
        payload: {
          data: [updateItem],
          position: position,
        },
      });
    },
    [dispatch],
  );

  const onImportCategories = useCallback(
    (position: number, selectedCategoryIds: string[]) => {
      const removingCategoryIds = difference(
        assigningCategoryIds,
        selectedCategoryIds,
      );
      const addingCategoryIds = difference(
        selectedCategoryIds,
        assigningCategoryIds,
      );

      const updatedPageItemsByCategory = removingCategoryIds.length
        ? pageItems.map(item => {
            if (item?.category && removingCategoryIds.includes(item?.category))
              return { key: item.key };
            return item;
          })
        : [...pageItems];

      if (addingCategoryIds.length) {
        // sort by category first, sorted the product inside of a category
        const sortedAddingCategory = sortBy(
          Object.values(categoryMaps),
          category => category.name?.toLowerCase(),
        ).filter(category => addingCategoryIds.includes(category.id));
        const addPageItems = sortedAddingCategory.reduce((items, category) => {
          const { products = [], variants = [] } = category || {};
          const categoryItems: TileItem[] = [];
          products.forEach(product => {
            const id = uuidV4();

            categoryItems.push({
              key: id,
              id,
              category: category.id,
              entityId: product.id,
              entityType: EntityType.Product,
              name: product?.name,
            });
          });

          variants.forEach(variant => {
            const id = uuidV4();
            categoryItems.push({
              key: id,
              id,
              category: category.id,
              entityId: variant.id,
              entityType: EntityType.Variant,
              name: variant?.name,
            });
          });

          const sortedCategoryItems = sortBy(categoryItems, item => item.name);
          items.push(...sortedCategoryItems);
          return items;
        }, [] as TileItem[]);
        if (addPageItems.length) {
          updatedPageItemsByCategory.splice(position, 1, ...addPageItems);
        }
      }

      dispatch({
        type: EditMenuAction.UPDATE_PAGE_ITEM_DATA,
        payload: {
          data: updatedPageItemsByCategory,
          assigningCategoryIds: selectedCategoryIds,
        },
      });
    },
    [assigningCategoryIds, categoryMaps, dispatch, pageItems],
  );

  const onSelectOption = useCallback(
    (params: { item: TileItem; position: number; option: PageItemAction }) => {
      const { option, item, position } = params;
      switch (option) {
        case PageItemAction.ADD_EXISTING_PRODUCT:
          showModal(
            <AddExistingProducts
              productItems={productItems}
              categoryMaps={categoryMaps}
              onAddProductToPage={onAddProductToPage.bind(null, position)}
            />,
          );
          break;
        case PageItemAction.ADD_FROM_CATEGORY:
          showModal(
            <AddCategory
              categoryMaps={categoryMaps}
              onImportCategories={onImportCategories.bind(null, position)}
              selectedCategoryIds={assigningCategoryIds}
            />,
          );
          break;
        case PageItemAction.ADD_EXISTING_PAGE:
          showModal(
            <AddExistingPage
              pageMaps={pageMaps}
              onAddExistingPage={onAddExistingPage.bind(null, position)}
            />,
          );
          break;
        case PageItemAction.ADD_NEW_PAGE:
          showModal(
            <CreateAndAddNewPage
              onAddExistingPage={onAddPageToMenu.bind(null, {
                position,
              })}
              createPage={createOrUpdatePage}
            />,
          );
          break;

        case PageItemAction.VIEW_PAGE:
          if (hasPageItemsChange) {
            const onConfirmSave = () => {
              closeModal();
              onSaveMenu();
            };
            return showModal(
              <ConfirmationModal
                title="Confirm Changes"
                message={'Data has been changed, please save before continue'}
                confirmLabel={translate('button.save')}
                cancelLabel={translate('button.discard')}
                onConfirm={onConfirmSave}
                onCancel={onViewPage.bind(null, item.entityId as string)}
                type="neutral"
              />,
            );
          }
          onViewPage(item.entityId as string);

          break;
        case PageItemAction.EDIT_TILE:
          showModal(
            <EditProduct
              item={item}
              onUpdateProduct={onUpdateProduct.bind(null, position)}
            />,
          );
          break;

        case PageItemAction.EDIT_PAGE_TILE:
          showModal(
            <AddExistingPage
              pageMaps={pageMaps}
              onEditPage={onEditPage.bind(null, position)}
              editingItem={item}
            />,
          );
          break;
        case PageItemAction.CLEAR_TILE:
          showModal(
            <ClearTileConfirmation
              onPressConfirm={onConfirmClearPageItem.bind(null, params)}
              onPressCancel={closeModal}
              entityName={item?.name as string}
              pageName={viewingPage?.name}
              hideClearAllCheck={
                item.entityType === EntityType.Page || !item?.name
              }
            />,
          );
          break;
        case PageItemAction.MOVE_NEXT_SECTION:
          onMoveItemToAnotherSection(item, position, true);
          break;
        case PageItemAction.MOVE_PREVIOUS_SECTION:
          onMoveItemToAnotherSection(item, position);
          break;
        default:
          break;
      }
    },
    [
      assigningCategoryIds,
      categoryMaps,
      closeModal,
      createOrUpdatePage,
      hasPageItemsChange,
      onAddExistingPage,
      onAddPageToMenu,
      onAddProductToPage,
      onConfirmClearPageItem,
      onEditPage,
      onImportCategories,
      onMoveItemToAnotherSection,
      onSaveMenu,
      onUpdateProduct,
      onViewPage,
      pageMaps,
      productItems,
      showModal,
      viewingPage?.name,
    ],
  );

  const renderItem = (data: {
    item: TileItem;
    onLongPress: () => void;
    position: number;
  }) => {
    const { item, onLongPress, position: rawPosition } = data;
    const position = rawPosition + itemsPerSection * (currentSection - 1);

    if (item.entityType === 'PAGINATION') {
      return (
        <PaginationGrid
          scrollDirection="horizontal"
          currentPage={currentSection}
          onPressNext={onNextPage}
          onPressBack={onPreviousPage}
        />
      );
    }

    if (item.entityType === 'BACK_NAVIGATION') {
      return <BackNavigationButton onPress={onPressBackToPreviousPage} />;
    }
    if (!item.id) {
      return (
        <PageEmptyItem
          onLongPress={onLongPress}
          onSelectOption={option => onSelectOption({ option, item, position })}
        />
      );
    }
    return (
      <PageItemElement
        item={item}
        onLongPress={onLongPress}
        onSelectOption={option => onSelectOption({ option, item, position })}
        currentSection={currentSection}
      />
    );
  };

  return (
    <DraggableGrid
      numColumns={5}
      renderItem={renderItem}
      data={dataWithPagination}
      onDragRelease={onChangePageItems}
      itemHeight={80}
    />
  );
};

export default PageItemsLayout;
