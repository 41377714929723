/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertEnumToArray = (input: any): any[] => {
  const arrayObjects: any[] = [];
  for (const [propertyKey, propertyValue] of Object.entries(input)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    arrayObjects.push({ key: propertyKey, value: propertyValue });
  }
  return arrayObjects;
};
