import { gql } from '@apollo/client';

export const defaultProductPricingFragment = `
  id
  name
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;

export const getProductPricingFragment = (customFragment?: string) => {
  const UPDATE_PRODUCT_PRICING_MUTATION = gql`
  mutation updateProductPricings($input: [ProductPricingInput]) {
    updateProductPricings(input: $input) {
        ${customFragment || defaultProductPricingFragment}
    }
  }
`;
  const DELETE_PRODUCT_PRICING_MUTATION = gql`
  mutation deleteProductPricings($input: [DeleteProductPricingInput!]!) {
    deleteProductPricings(input: $input){
      ${customFragment || defaultProductPricingFragment}
    }
  }
`;

  const ADD_PRODUCT_PRICING_MUTATION = gql`
  mutation addProductPricings($productId: ID, $pricings: [ProductPricesInput]) {
    addProductPricings(productId: $productId, pricings: $pricings) {
      ${customFragment || defaultProductPricingFragment}
    }
  }
`;

  const ADD_BULK_PRODUCT_PRICING_MUTATION = gql`
  mutation addBulkProductPricings($input: [AddProductPricingInput]) {
    addBulkProductPricings(input: $input) {
        ${customFragment || defaultProductPricingFragment}
    }
  }
`;

  return {
    UPDATE_PRODUCT_PRICING_MUTATION,
    DELETE_PRODUCT_PRICING_MUTATION,
    ADD_PRODUCT_PRICING_MUTATION,
    ADD_BULK_PRODUCT_PRICING_MUTATION,
  };
};
