import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { translate, useTranslation } from '@oolio-group/localization';
import TreatButton from '../../../../Shared/TreatButton/TreatButton';
import styles from './CloseShiftWarning.styles';
import { IntegrationApps, Order, OrderStatus } from '@oolio-group/domain';
import Section from '../../../../../components/Office/Section/Section';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';

export interface CloseShiftWarningProps {
  onClose?: () => void;
  orders: Order[];
  forceCloseShift?: boolean;
}
interface OpenOrderRowProps {
  message: string;
  source: string;
  navigateToScreen: (from: string) => void;
  forceCloseShift?: boolean;
}

const OpenOrderRow: React.FC<OpenOrderRowProps> = ({
  message,
  source,
  navigateToScreen,
  forceCloseShift = false,
}: OpenOrderRowProps) => {
  return (
    <View testID="open-order-row" style={theme.tables.row}>
      <Text style={[theme.textStyles.table.default, styles.cellOrder]}>
        {message}
      </Text>
      <TouchableOpacity
        testID={`btn-${
          source === IntegrationApps.OOLIO_STORE ? 'onlineOrders' : 'openOrders'
        }`}
        onPress={() => navigateToScreen(source)}
        style={styles.closeShiftRowActionContainer}
      >
        {!forceCloseShift ? (
          <>
            <Text style={styles.closeShiftTextMessage}>
              {translate('shift.view')}
            </Text>
            <Icon
              style={styles.closeShiftArrowSpace}
              name={'angle-right'}
              color={theme.colors.grey4}
              size={30}
            />
          </>
        ) : null}
      </TouchableOpacity>
    </View>
  );
};

const CloseShiftWarning: React.FC<CloseShiftWarningProps> = ({
  onClose,
  orders = [],
  forceCloseShift = false,
}: CloseShiftWarningProps) => {
  const { closeModal } = useModal();
  const { navigate } = useNavigation();
  const { translate } = useTranslation();

  const allOrders = useMemo(() => {
    const openOrders: Order[] = [],
      onlineOrders: Order[] = [];
    orders.forEach(o => {
      if (o.status === OrderStatus.IN_PROGRESS) {
        if (o.source === IntegrationApps.OOLIO_STORE) {
          onlineOrders.push(o);
        } else {
          openOrders.push(o);
        }
      }
    });
    return { openOrders, onlineOrders };
  }, [orders]);

  const tableData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableData: any[] = [];
    allOrders.openOrders.length &&
      tableData.push({
        message: translate('shift.openOrders', {
          ordersCount: allOrders.openOrders.length,
        }),
        source: '',
      });
    allOrders.onlineOrders.length &&
      tableData.push({
        message: translate('shift.onlineOrders', {
          ordersCount: allOrders.onlineOrders.length,
        }),
        source: IntegrationApps.OOLIO_STORE,
      });
    return tableData;
  }, [allOrders, translate]);

  const navigateToScreen = useCallback(
    (source: string) => {
      navigate('Orders', {
        screen:
          source === IntegrationApps.OOLIO_STORE
            ? 'OnlineOrders'
            : 'OpenOrders',
      });
      closeModal();
    },
    [closeModal, navigate],
  );

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <Text style={styles.titleText}>
          {translate('shift.pendingOpenOrders')}
        </Text>
        <Text style={styles.subtitleText}>
          {translate('shift.pendingOrdersMessage')}
        </Text>
        <Section>
          <View style={styles.tableBody}>
            {tableData?.map((row, i) => (
              <OpenOrderRow
                key={i}
                message={row.message}
                source={row.source}
                navigateToScreen={navigateToScreen}
                forceCloseShift={forceCloseShift}
              />
            ))}
          </View>
        </Section>
      </View>
      <View style={styles.actions}>
        {!forceCloseShift && (
          <TreatButton
            type="cancel"
            testID="btn-cancel"
            label={translate('shift.cancel')}
            onPress={closeModal}
          />
        )}
        <TreatButton
          type="negative"
          testID="btn-closeShift"
          label={translate('shift.close')}
          onPress={() => onClose && onClose()}
          containerStyle={styles.btnCloseShift}
        />
      </View>
    </View>
  );
};

export default CloseShiftWarning;
