import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { RefundReason } from '@oolio-group/domain';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { GET_REFUND_REASONS_QUERY } from './graphql';

export interface UseRefundReasonsProps {
  loading: boolean;
  error: string | undefined;
  refundReasons?: RefundReason[];
  getRefundReasons: () => void;
}

interface UseRefundReasonsInput {
  fetchPolicy?: WatchQueryFetchPolicy;
}

export function useCachedRefundReasons(
  input?: UseRefundReasonsInput,
): UseRefundReasonsProps {
  const [getRefundReasonsReq, getRefundReasonsRes] = useLazyQuery<{
    refundReasons: RefundReason[];
  }>(GET_REFUND_REASONS_QUERY, {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    onError: noopHandler,
  });

  const getRefundReasons = useCallback(() => {
    getRefundReasonsReq({});
  }, [getRefundReasonsReq]);

  const loading: boolean = getRefundReasonsRes.loading;

  const error: ApolloError | undefined = getRefundReasonsRes.error;

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    refundReasons: getRefundReasonsRes?.data?.refundReasons,
    getRefundReasons,
  };
}
