import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Connection,
  CreateAndUpdateEarningRule,
  CreateAndUpdateRewardRule,
  EarningRule,
  LoyaltyActivityLog,
  LoyaltyActivityLogFilterInput,
  LoyaltyProgram,
  LoyaltySettings,
  RewardRule,
  UpdateLoyaltySettingsInput,
} from '@oolio-group/domain';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { useTranslation } from '@oolio-group/localization';

import {
  GET_LOYALTY_SETTINGS,
  CREATE_AND_UPDATE_EARNING_RULES,
  CREATE_AND_UPDATE_REWARD_RULES,
  DELETE_EARNING_RULE,
  DELETE_REWARD_RULE,
  UPDATE_LOYALTY_SETTINGS,
  GET_PAGINATED_LOYALTY_ACTIVITY_LOGS,
} from './graphql';
import { useNotification } from '../../Notification';
import omitKey from 'lodash/omit';
import { QueryLazyOptions, WatchQueryFetchPolicy } from '@apollo/client';

export interface useLoyaltyProps {
  loading: boolean;
  error: string | undefined;
  loyaltySettings: Partial<LoyaltySettings>;
  earningRules: EarningRule[];
  rewardRules: RewardRule[];
  getLoyaltyPrograms: () => void;
  createAndUpdateEarningRule: (
    earningPointRule: CreateAndUpdateEarningRule,
  ) => void;
  createAndUpdateRewardRules: (redeemRule: CreateAndUpdateRewardRule) => void;
  deleteEarningRule: (id: string) => void;
  deleteRewardRule: (id: string) => void;
  updateLoyaltySetting: (input: UpdateLoyaltySettingsInput) => void;
  paginatedLoyaltyActivityLogs: Connection<LoyaltyActivityLog> | undefined;
  getPaginatedLoyaltyActivityLogs: (
    options: QueryLazyOptions<{
      first: number;
      after: string;
      filter: LoyaltyActivityLogFilterInput;
    }>,
  ) => void;
}

const removeTypename = <T extends object>(item: T) => {
  return omitKey(item, '__typename') as T;
};

interface UseLoyaltyInput {
  venueId?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export function useLoyalty(input?: UseLoyaltyInput): useLoyaltyProps {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();

  const [loyaltyPrograms, setLoyaltyPrograms] = useState<LoyaltyProgram>(
    {} as LoyaltyProgram,
  );

  const [paginatedLoyaltyActivityLogs, setPaginatedLoyaltyActivityLogs] =
    useState<Connection<LoyaltyActivityLog> | undefined>();

  const [getLoyaltyPrograms, getLoyaltyProgramsRes] = useLazyQuery<{
    loyaltyPrograms: LoyaltyProgram;
  }>(GET_LOYALTY_SETTINGS, {
    onError: noopHandler,
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
  });

  const refreshLoyaltyPrograms = useCallback(() => {
    if (typeof getLoyaltyProgramsRes?.refetch == 'function') {
      getLoyaltyProgramsRes?.refetch();
    }
  }, [getLoyaltyProgramsRes]);

  useEffect(() => {
    if (getLoyaltyProgramsRes.data) {
      const { loyaltyPrograms } = getLoyaltyProgramsRes.data;
      setLoyaltyPrograms(loyaltyPrograms);
    }
  }, [getLoyaltyProgramsRes.data]);

  const [getPaginatedLoyaltyActivityLogs, getPaginatedLoyaltyActivityLogsRes] =
    useLazyQuery<
      {
        paginatedLoyaltyActivityLogs: Connection<LoyaltyActivityLog>;
      },
      {
        first: number;
        after: string;
        filter: LoyaltyActivityLogFilterInput;
      }
    >(GET_PAGINATED_LOYALTY_ACTIVITY_LOGS, {
      onError: noopHandler,
      fetchPolicy: 'cache-and-network',
      onCompleted: response => {
        setPaginatedLoyaltyActivityLogs(response.paginatedLoyaltyActivityLogs);
      },
    });

  const [createAndUpdateEarningRuleReq, createAndUpdateEarningRuleRes] =
    useMutation<
      { createOrUpdateLoyaltyProgramEarningRule: EarningRule },
      { input: CreateAndUpdateEarningRule }
    >(CREATE_AND_UPDATE_EARNING_RULES, {
      onError: noopHandler,
      onCompleted: () => {
        refreshLoyaltyPrograms();
      },
    });

  const [createAndUpdateRewardRulesReq, createAndUpdateRewardRulesRes] =
    useMutation<
      { createOrUpdateLoyaltyProgramRewardRule: RewardRule },
      { input: CreateAndUpdateRewardRule }
    >(CREATE_AND_UPDATE_REWARD_RULES, {
      onError: noopHandler,
      onCompleted: () => {
        refreshLoyaltyPrograms();
      },
    });

  const [deleteEarningRuleReq, deleteEarningRuleRes] = useMutation<
    { deleteEarningRule: string },
    { id: string }
  >(DELETE_EARNING_RULE, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('backOfficeLoyalty.deleteEarningRuleSuccessfully'),
        success: true,
      });
      refreshLoyaltyPrograms();
    },
  });

  const [deleteRewardRuleReq, deleteRewardRuleRes] = useMutation<
    { deleteRewardRule: string },
    { id: string }
  >(DELETE_REWARD_RULE, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('backOfficeLoyalty.deleteRewardRuleSuccessfully'),
        success: true,
      });

      refreshLoyaltyPrograms();
    },
  });

  const [updateLoyaltySettingReq, updateLoyaltySettingRes] = useMutation<
    { updateLoyaltyProgramSettings: LoyaltySettings },
    { input: UpdateLoyaltySettingsInput }
  >(UPDATE_LOYALTY_SETTINGS, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate(
          'backOfficeLoyalty.updateLoyaltySettingSuccessfully',
        ),
        success: true,
      });
      refreshLoyaltyPrograms();
    },
  });

  const updateLoyaltySetting = useCallback(
    (input: UpdateLoyaltySettingsInput) => {
      updateLoyaltySettingReq({ variables: { input } });
    },
    [updateLoyaltySettingReq],
  );

  const createAndUpdateEarningRule = useCallback(
    (earningRule: CreateAndUpdateEarningRule) => {
      createAndUpdateEarningRuleReq({ variables: { input: earningRule } });
    },
    [createAndUpdateEarningRuleReq],
  );

  const createAndUpdateRewardRules = useCallback(
    (rewardRule: CreateAndUpdateRewardRule) => {
      createAndUpdateRewardRulesReq({ variables: { input: rewardRule } });
    },
    [createAndUpdateRewardRulesReq],
  );

  const deleteEarningRule = useCallback(
    (id: string) => {
      deleteEarningRuleReq({ variables: { id } });
    },
    [deleteEarningRuleReq],
  );

  const deleteRewardRule = useCallback(
    (id: string) => {
      deleteRewardRuleReq({ variables: { id } });
    },
    [deleteRewardRuleReq],
  );

  const error =
    getLoyaltyProgramsRes.error ||
    createAndUpdateRewardRulesRes.error ||
    createAndUpdateEarningRuleRes.error ||
    deleteEarningRuleRes.error ||
    deleteRewardRuleRes.error ||
    updateLoyaltySettingRes.error ||
    getPaginatedLoyaltyActivityLogsRes.error;

  const loading =
    getLoyaltyProgramsRes.loading ||
    createAndUpdateRewardRulesRes.loading ||
    createAndUpdateEarningRuleRes.loading ||
    deleteRewardRuleRes.loading ||
    deleteEarningRuleRes.loading ||
    updateLoyaltySettingRes.loading ||
    getPaginatedLoyaltyActivityLogsRes.loading;

  const loyaltySettings = useMemo(
    () => removeTypename(loyaltyPrograms.settings),
    [loyaltyPrograms.settings],
  );

  const earningRules = useMemo(
    () => (loyaltyPrograms?.rules?.earningRules || []).map(removeTypename),
    [loyaltyPrograms?.rules?.earningRules],
  );

  const rewardRules = useMemo(
    () => (loyaltyPrograms?.rules?.rewardRules || []).map(removeTypename),
    [loyaltyPrograms?.rules?.rewardRules],
  );

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      loyaltySettings,
      getLoyaltyPrograms,
      createAndUpdateEarningRule,
      createAndUpdateRewardRules,
      earningRules: input?.venueId
        ? earningRules.filter(earningRule =>
            (earningRule.venueIds || []).includes(input?.venueId as string),
          )
        : earningRules,
      rewardRules: input?.venueId
        ? rewardRules.filter(reward =>
            (reward.venueIds || []).includes(input?.venueId as string),
          )
        : rewardRules,
      deleteEarningRule,
      deleteRewardRule,
      updateLoyaltySetting,
      paginatedLoyaltyActivityLogs,
      getPaginatedLoyaltyActivityLogs,
    }),
    [
      loading,
      error,
      loyaltySettings,
      getLoyaltyPrograms,
      createAndUpdateEarningRule,
      createAndUpdateRewardRules,
      input?.venueId,
      earningRules,
      rewardRules,
      deleteEarningRule,
      deleteRewardRule,
      updateLoyaltySetting,
      paginatedLoyaltyActivityLogs,
      getPaginatedLoyaltyActivityLogs,
    ],
  );
}
