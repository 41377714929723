import React, { useMemo } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import FeatureAboutScreen from './FeatureAboutScreen/FeatureAboutScreen';
import { useRoute } from '@react-navigation/native';
import FeatureSettingScreen from './FeatureSettingScreen/FeatureSettingsScreen';
import { FeatureIDs } from '@oolio-group/domain';

interface FeatureTabParams {
  key: string;
  name: string;
  params: {
    featureId: FeatureIDs;
    title: string;
  };
}

const Tab = createMaterialTopTabNavigator();
const ManageFeaturesAndIntegrationsStack: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute<FeatureTabParams>();

  const isFeatureSettingTabEnabled = useMemo(() => {
    return [FeatureIDs.CUSTOMER_ACCOUNT].includes(route.params?.featureId);
  }, [route.params]);

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'ManageFeatures'}
        />
      )}
      initialRouteName="AboutTab"
    >
      <Tab.Screen
        name="AboutTab"
        component={FeatureAboutScreen}
        options={{ tabBarLabel: translate('tabNames.about') }}
        initialParams={route.params}
      />

      {isFeatureSettingTabEnabled && (
        <Tab.Screen
          name="SettingTab"
          component={FeatureSettingScreen}
          options={{ tabBarLabel: translate('tabNames.settings') }}
          initialParams={route.params}
        />
      )}
    </Tab.Navigator>
  );
};

export default ManageFeaturesAndIntegrationsStack;
