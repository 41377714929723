import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { View, StatusBar, ScrollView, StyleSheet } from 'react-native';
import {
  useNotification,
  useNotificationsList,
} from '../../../hooks/Notification';
import { NotificationList } from '../../Notification/NotificationList';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import ActionsBar, { ActionsBarProps } from '../ActionsBar/ActionsBar';
import theme from '../../../common/default-theme';
import { syncAppUtility } from '../../../state/syncAppUtility';

export interface ScreenLayoutProps extends ActionsBarProps {
  title: string;
  loading?: boolean;
  scrollEnabled?: boolean;
  children: React.ReactNode | React.ReactNode[];
  sidePanel?: React.ReactNode | React.ReactNode[];
  banner?: React.ReactElement;
}

const ScreenLayout: React.FC<ScreenLayoutProps> = ({
  title,
  loading,
  scrollEnabled = true,
  children,
  sidePanel,
  banner,
  ...props
}) => {
  const safeHeight = theme.useSafeHeight();
  const notifications = useNotificationsList();
  const { closeNotification } = useNotification();
  const [syncLoading, setSyncLoading] = useState(false);

  useEffect(() => {
    const subscription = syncAppUtility.getSubscriptionState$.subscribe(
      status => {
        setSyncLoading(status);
      },
    );
    return () => {
      subscription.unsubscribe();
    };
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={styles.screen}>
        <NotificationList
          notifications={notifications}
          onCloseNotification={closeNotification}
        />
        {banner && banner}
        <ActionsBar title={title} loading={loading} {...props} />
        {loading || syncLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ScrollView
              style={{ height: safeHeight }}
              scrollEnabled={scrollEnabled}
            >
              {children}
            </ScrollView>
            {sidePanel}
          </>
        )}
      </View>
    </>
  );
};

export default ScreenLayout;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: theme.colors.grey2,
  },
});
