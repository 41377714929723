import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    minHeight: 100,
    backgroundColor: theme.colors.grey1,
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    flex: 1,
    marginRight: 5,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  description: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  iconContainer: {
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
