import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  AdditionalPaymentInfo,
  AdjustmentType,
  AssignCustomerEvent,
  Customer,
  DEFAULT_TABLE_ABBREVIATION,
  DefaultPaymentTypes,
  EnrollCustomerInput,
  FeatureIDs,
  LoyaltyEnrolmentSource,
  NotificationMode,
  NotificationType,
  OnAccountAction,
  OnAccountEvent,
  OnAccountPaymentEvent,
  OnAccountPaymentProcessedEvent,
  OrderAction,
  OrderEvent,
  OrderItemStatus,
  OrderPaymentEvent,
  OrderPaymentProcessedEvent,
  OrderPaymentStatus,
  OrderStatus,
  PaymentAction,
  PaymentMode,
  PaymentSubScreen,
  PaymentType,
  ReceiptPrintOption,
  RewardAdjustment,
  SplitPaymentBy,
  SplitQuantityOrderItemEvent,
  UpdateCustomerEvent,
} from '@oolio-group/domain';
import { getCountry, useTranslation } from '@oolio-group/localization';
import {
  getAllRedeemedRewards,
  getLoyaltyUnit,
  roundOffByValue,
  calculatePointsEarnedForOrder,
} from '@oolio-group/client-utils';
import {
  computeTotalOrderItemsAmountWithReward,
  getSectionNameFromOrder,
  getTableNameFromOrder,
  getTablesFromOrder,
  limitDecimalCount,
  sumDecimals,
} from '@oolio-group/order-helper';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  useRoute,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { cloneDeep, isEmpty, keyBy, orderBy, round, uniq } from 'lodash';
import lastItem from 'lodash/last';
import { v4 as uuidv4 } from 'uuid';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { useMutation } from '@apollo/client/react/hooks';
import { AppScreen } from '../../../types/AppScreen';
import { changeDueVar } from '../../../App';
import { userUtility } from '../../../state/userUtility';
import { takeOrderUnFocusClearParamController } from '../Orders/TakeOrder/takeOrderObservable';
import { ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER } from '../../../types/Common';
import { getRoundOffValue } from '../../../utils/roundOffHelper';
import kitchenOrderEvents from '../../../utils/printerTemplates/kotEvents';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { useTimeout } from '../../../hooks/useTimeout';
import ModalWrapper from '../../../hooks/ModalWrapper';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { useRewards } from '../../../hooks/orders/useRewards';
import { ORDER_SAVE } from '../../../hooks/app/orders/graphql';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import { useCourses } from '../../../hooks/app/courses/useCourses';
import { useLoyalty } from '../../../hooks/app/loyalty/useLoyalty';
import { extractCounter } from '../../../hooks/orders/useOrderNumber';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import { useCart } from '../../../hooks/orders/useCart';
import { useSyncOrderEvents } from '../../../hooks/app/useSyncOrderEvents';
import { usePostSalesNavigation } from '../../../hooks/app/usePostSalesNavigation';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { useSyncItemAvailability } from '../../../hooks/app/orders/useSyncItemAvailability';
import { isWeb } from '../../../common/theme';
import theme from '../../../common/default-theme';
import styles from './PaymentScreen.styles';
import CartTotals, {
  CartTotalRow,
} from '../../../components/POS/Cart/CartTotals/CartTotals';
import SaleCompleteModal, {
  sendReceiptInput,
} from '../../../components/POS/Modals/SaleComplete/SaleCompleteModal';
import CartItems from '../../../components/POS/Cart/CartItems/CartItems';
import Sticker from '../../../components/Shared/Sticker/Sticker';
import { CartSelectionState } from '../../../components/POS/Cart/Cart';
import KeypadModal from '../../../components/POS/Modals/Keypad/KeypadModal';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import AccountTotals from '../../../components/POS/Cart/CartTotals/AccountTotals';
import AssignCustomer from '../../../components/POS/AssignCustomer/AssignCustomer';
import PaymentOptions from '../../../components/POS/PaymentsScreen/PaymentOptions';
import OnHoldOrderOptions from '../../../components/POS/PaymentsScreen/OnHoldPaymentActions';
import EnrollCustomerModal from '../../../components/Modals/CustomerLoyalty/EnrollCustomerModal';
import ShowAvailableRewards from '../../../components/Modals/CustomerLoyalty/ShowAvailableRewards';
import PaymentComplete from '../../../components/POS/PaymentsScreen/PaymentComplete/PaymentComplete';
import Gradient from '../../../components/Gradient/Gradient';
import { EnrollmentSource } from '@oolio-group/loyalty-sdk';
import Icon from '../../../components/Icon/Icon';
import {
  mapLoyaltySnapshot,
  mapRewardToLegacyReward,
  useOolioLoyalty,
} from '../../../hooks/useOolioLoyalty';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';

const CASH_PAYMENT_TYPE = 'cash';
const ON_ACCOUNT_PAYMENT_TYPE = 'On Account';

const nanoid = customAlphabet(alphanumeric, 10);

const proportionateAmount = (
  value: number,
  subTotal: number,
  adjustmentAmount: number,
) => {
  return limitDecimalCount((value * adjustmentAmount) / subTotal);
};

export interface OrderPaymentDetails {
  totalDueAmount: number;
  totalPaidAmount: number;
  remainingDueAmount: number;
  processingAmount: number;
  paymentMode: PaymentMode;
  nthPayment: number;
  activeScreen: PaymentSubScreen;
  isPaymentCompleted: boolean;
  isSplitPayment?: boolean;
  isSplitBySeat?: boolean;
  isSplitByProduct?: boolean;
  isCustomerBalancePayment: boolean;
  customerPaymentTransactionId?: string;
  lastChangeDueAmount: number;
  lastReceivedAmount: number;
  numberOfSplitPayment: number;
  nthSplitPayment: number;
  processingOrderItemIds: string[];
  processingPaymentSeat?: number;
  splitPaymentBy?: SplitPaymentBy;
}

interface PaymentActionPayload {
  paymentMode: PaymentMode;
  amountDue: number;
  totalAmount: number;
  processingAmount: number;
  isPaymentCompleted: boolean;
  nthPayment: number;
  customerAmount: number;
  lastChangeDueAmount: number;
  lastReceivedAmount: number;
  numberOfSplitPayment: number;
  processingOrderItemIds: string[];
  processingPaymentSeat: number;
}

export interface PaymentActionProp {
  type: PaymentAction;
  payload: Partial<PaymentActionPayload>;
}

export const initialState: OrderPaymentDetails = {
  activeScreen: PaymentSubScreen.MAIN_SCREEN,
  paymentMode: PaymentMode.PAY_IN_FULL,
  processingAmount: 0,
  nthPayment: 0,
  totalPaidAmount: 0,
  remainingDueAmount: 0,
  totalDueAmount: 0,
  isPaymentCompleted: false,
  isCustomerBalancePayment: false,
  customerPaymentTransactionId: '',
  lastReceivedAmount: 0,
  lastChangeDueAmount: 0,
  numberOfSplitPayment: Infinity,
  nthSplitPayment: Infinity,
  processingOrderItemIds: [],
};

function paymentReducer(
  state: OrderPaymentDetails,
  action: PaymentActionProp,
): OrderPaymentDetails {
  const { type, payload } = action;

  switch (type) {
    case PaymentAction.UPDATE_PAYMENT_MODE:
      return {
        ...state,
        paymentMode: payload.paymentMode as PaymentMode,
        isSplitPayment: payload.paymentMode === PaymentMode.SPLIT_PAYMENT,
      };

    case PaymentAction.SET_PAYMENT_AMOUNT:
      return {
        ...state,
        processingAmount: payload.processingAmount as number,
        nthPayment: state.nthPayment + 1,
        processingOrderItemIds: payload?.processingOrderItemIds || [],
        processingPaymentSeat: payload?.processingPaymentSeat,
        activeScreen: PaymentSubScreen.PAYOUT_SCREEN,
        splitPaymentBy: SplitPaymentBy.BY_AMOUNT,
      };

    case PaymentAction.SET_SEAT_AMOUNT:
      return {
        ...state,
        processingAmount: payload.processingAmount as number,
        nthPayment: state.nthPayment + 1,
        processingOrderItemIds: payload?.processingOrderItemIds || [],
        processingPaymentSeat: payload?.processingPaymentSeat,
        activeScreen: PaymentSubScreen.PAYOUT_SCREEN,
        splitPaymentBy: SplitPaymentBy.BY_SEAT,
      };

    case PaymentAction.UPDATE_PAYMENT_AMOUNT:
      return {
        ...state,
        processingAmount: payload.processingAmount as number,
      };

    case PaymentAction.UPDATE_PAYMENT_INFO_FROM_ORDER: {
      const {
        totalAmount = 0,
        amountDue = 0,
        isPaymentCompleted,
        nthPayment = 0,
        processingAmount = 0,
      } = payload;

      const totalPaidAmount = totalAmount - amountDue;
      const nextScreen = isPaymentCompleted
        ? PaymentSubScreen.PAYMENT_SUCCEEDED
        : state.activeScreen;

      const { nthSplitPayment, numberOfSplitPayment, remainingDueAmount } =
        state;

      const processingNumber =
        nthSplitPayment < numberOfSplitPayment
          ? round(
              remainingDueAmount / (numberOfSplitPayment + 1 - nthSplitPayment),
              2,
            )
          : processingAmount;

      return {
        ...state,
        activeScreen: nextScreen,
        totalDueAmount: totalAmount,
        totalPaidAmount,
        processingAmount: processingNumber,
        remainingDueAmount: amountDue,
        isPaymentCompleted: Boolean(isPaymentCompleted),
        nthPayment,
      };
    }
    case PaymentAction.TAKE_NEXT_PAYMENT:
      return {
        ...state,
        nthPayment: state.nthPayment + 1,
        activeScreen: state.isSplitBySeat
          ? PaymentSubScreen.MAIN_SCREEN
          : PaymentSubScreen.PAYOUT_SCREEN,
        nthSplitPayment: state.isSplitPayment
          ? state.nthSplitPayment + 1
          : state.nthSplitPayment,
      };

    case PaymentAction.CANCEL_CURRENT_STEP:
      return {
        ...state,
        processingAmount: state.totalDueAmount - state.totalPaidAmount,
        nthPayment: Math.max(0, state.nthPayment - 1),
        numberOfSplitPayment: Infinity,
        nthSplitPayment: Infinity,
        isSplitByProduct: false,
        activeScreen: PaymentSubScreen.MAIN_SCREEN,
        processingOrderItemIds: [],
      };
    case PaymentAction.CANCEL_PAY_BY_SEAT:
      return {
        ...state,
        processingAmount: state.totalDueAmount - state.totalPaidAmount,
        nthPayment: Math.max(0, state.nthPayment - 1),
        numberOfSplitPayment: Infinity,
        nthSplitPayment: Infinity,
        activeScreen: PaymentSubScreen.MAIN_SCREEN,
        processingOrderItemIds: [],
        isSplitBySeat: false,
      };
    case PaymentAction.PAY_OUT:
      const newState = {
        ...state,
        lastChangeDueAmount: payload.lastChangeDueAmount || 0,
        lastReceivedAmount: payload.lastReceivedAmount || 0,
        activeScreen: PaymentSubScreen.PAYMENT_SUCCEEDED,
        processingOrderItemIds: [],
      };
      if (state.isCustomerBalancePayment) {
        const newProcessingAmount =
          state.remainingDueAmount - state.processingAmount;
        const isPaymentCompleted = newProcessingAmount <= 0;
        return {
          ...newState,
          isPaymentCompleted,
          remainingDueAmount: newProcessingAmount,
          processingAmount: newProcessingAmount,
          totalPaidAmount: state.totalPaidAmount + state.processingAmount,
        };
      }
      return {
        ...newState,
        processingAmount: 0,
      };

    case PaymentAction.RESET_INITIAL_STATE:
      return initialState;

    case PaymentAction.UPDATE_CUSTOMER_BALANCE_PAYMENT:
      const { customerAmount = 0 } = payload;
      const customerPaymentTransactionId = nanoid();
      return {
        ...state,
        processingAmount: customerAmount,
        isCustomerBalancePayment: true,
        remainingDueAmount: customerAmount,
        totalDueAmount: customerAmount,
        nthPayment: 0,
        customerPaymentTransactionId,
      };

    case PaymentAction.SETUP_NUMBER_OF_SPLIT_PAYMENT:
      const { numberOfSplitPayment = 0 } = payload;
      return {
        ...state,
        numberOfSplitPayment: numberOfSplitPayment,
        isSplitPayment: true,
        activeScreen: PaymentSubScreen.PAYOUT_SCREEN,
        nthPayment: state.nthPayment + 1,
        nthSplitPayment: 1,
        splitPaymentBy: SplitPaymentBy.BY_AMOUNT,
      };

    case PaymentAction.SPLIT_BY_SEAT:
      return {
        ...state,
        isSplitPayment: true,
        isSplitBySeat: true,
        activeScreen: PaymentSubScreen.MAIN_SCREEN,
        splitPaymentBy: SplitPaymentBy.BY_SEAT,
      };
    case PaymentAction.SPLIT_BY_PRODUCT:
      return {
        ...state,
        isSplitByProduct: true,
        isSplitPayment: true,
        activeScreen: PaymentSubScreen.PAYOUT_SCREEN,
        splitPaymentBy: SplitPaymentBy.BY_PRODUCT,
      };

    case PaymentAction.UPDATE_SELECTED_ORDER_ITEM:
      return {
        ...state,
        processingOrderItemIds: payload?.processingOrderItemIds as string[],
      };
    default:
      return state;
  }
}

const PaymentScreen: React.FC = () => {
  const customerOriginalBalance = useRef<number>();

  const route = useRoute();
  const [session] = useSession();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const safeHeight = theme.useSafeHeight();
  const { getOrderFromCache } = useOrders();
  const { getCourses, courses } = useCourses();
  const { closeModal, showModal } = useModal();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { printBill, openCashDrawer } = usePrinting();
  const { syncItemAvailability } = useSyncItemAvailability();
  const { navigateToPostSaleScreen } = usePostSalesNavigation();
  const { isConnected } = useNetworkStatus();
  const {
    order,
    updateCart,
    openOrderCart,
    addEventsToCart,
    resetCart,
    setCartParams,
    closeOrderCart,
    status: { loading, error },
  } = useCart();
  const {
    customerMaps,
    enrollCustomerLoyalty,
    loading: customerLoading,
    resetCustomerBalanceState,
    updateCustomerBalanceEvent,
    updateCustomerBalance,
    customerBalanceState,
    getCustomerById,
    updateCustomerInfo,
    enrollMemberLoyalty,
    updateCustomer,
    addNewCustomer,
    error: customerError,
  } = useCustomers();

  const routeParams = route.params as {
    customerAmount: number;
    customerId: string;
    orderId?: string;
  };
  const {
    customerAmount,
    customerId,
    orderId: onAccountOrderId = '',
  } = routeParams;

  const [orderPaymentDetails, dispatch] = useReducer(
    paymentReducer,
    initialState,
  );

  const [navigationAllowed, toggleNavigation] = useState(true);
  const [isShowRewardModal, setShowRewardModal] = useState(false);
  const [assignedCustomerId, setAssignedCustomerId] = useState<string>('');
  const [isShowCompleteSaleModal, setShowCompleteSaleModal] = useState(false);
  const [isShowEnrollLoyaltyModal, setShowEnrollLoyaltyModal] = useState(false);
  const [selectedSeatNumber, setSelectSeatNumber] = useState<
    string | undefined
  >(undefined);

  const showCompleteSaleModalWithDelay = useTimeout(() =>
    setShowCompleteSaleModal(true),
  );

  const {
    remainingDueAmount,
    isPaymentCompleted,
    activeScreen,
    isSplitPayment,
    processingAmount,
    isCustomerBalancePayment,
    lastChangeDueAmount,
    lastReceivedAmount,
    processingPaymentSeat,
    isSplitByProduct,
    processingOrderItemIds,
    splitPaymentBy,
  } = orderPaymentDetails;

  const currentVenueId = session?.currentVenue?.id;
  const {
    isLoyaltyEnabled: isNewLoyaltyEnabled,
    program: newLoyaltySettings,
    estimateSnapshot,
    snapshot,
    enrollLoyaltyMember,
  } = useOolioLoyalty({
    getProgram: true,
  });
  const isLegacyLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const isLoyaltyEnabled = isNewLoyaltyEnabled || isLegacyLoyaltyEnabled;
  const {
    getLoyaltyPrograms,
    earningRules,
    rewardRules,
    loyaltySettings: legacyLoyaltySettings,
  } = useLoyalty({ venueId: currentVenueId, fetchPolicy: 'cache-first' });
  const {
    getLoyaltySnapshot,
    updateLoyaltySnapshot,
    calculateAndUpdateLoyaltySnapshot,
  } = useRewards(rewardRules);
  const [saveOrder] = useMutation(ORDER_SAVE);
  const loyaltySettings = newLoyaltySettings || legacyLoyaltySettings;

  const onSendSuccess = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
    if (order?.status !== OrderStatus.ON_HOLD) {
      navigateToPostSaleScreen(undefined, true);
    }
    closeModal();
  };

  const { syncOrderEvents } = useSyncOrderEvents(onSendSuccess);

  const orderType = session?.deviceProfile?.orderTypes?.find(
    orderType => orderType.id === order?.orderType?.id,
  );

  const courseEnabled = isFeatureEnabled(
    FeatureIDs.COURSES,
    session?.currentVenue?.id,
  );

  const isCoursesEnabled =
    session.deviceProfile?.enableCourses && courseEnabled;

  const enableQuickPaymentMode: boolean =
    session?.settings?.enableQuickPaymentModeSetting || false;

  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;

  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );

  const { postSaleScreen, receiptPrintOption, defaultOrderType } =
    session.deviceProfile || {};

  const showPrintReceiptButton =
    receiptPrintOption === ReceiptPrintOption.NO_PRINT;

  const orderItemMaps = useMemo(
    () => keyBy(order?.orderItems || [], 'id'),
    [order?.orderItems],
  );

  const updateSelectedOrderItemIds = useCallback(
    (itemId: string) => {
      const isItemSelected = processingOrderItemIds.includes(itemId);
      const updatedSelectedOrderItemIds = isItemSelected
        ? processingOrderItemIds.filter(id => id !== itemId)
        : processingOrderItemIds.concat(itemId);
      dispatch({
        type: PaymentAction.UPDATE_SELECTED_ORDER_ITEM,
        payload: {
          processingOrderItemIds: updatedSelectedOrderItemIds,
        },
      });
    },
    [processingOrderItemIds],
  );

  const onConfirmQuantitySplitByProduct = useCallback(
    (
      originalOrderItemId: string,
      originalQuantity: number,
      splitQuantity: number,
    ) => {
      if (splitQuantity >= originalQuantity) {
        showNotification({
          error: true,
          message: translate('payment.splitQuantityExceed', {
            orgQty: originalQuantity,
          }),
        });
        return;
      }

      closeModal();
      if (splitQuantity === originalQuantity) return;

      const splitOrderItemId = uuidv4();
      const updatedSelectedOrderItemIds = processingOrderItemIds
        .filter(id => id !== originalOrderItemId)
        .concat(splitOrderItemId);
      updateCart<SplitQuantityOrderItemEvent>(
        OrderAction.ORDER_ITEM_SPLIT_QUANTITY,
        {
          quantity: splitQuantity,
          orderItemId: originalOrderItemId,
          splitOrderItemId,
        },
      );
      dispatch({
        type: PaymentAction.UPDATE_SELECTED_ORDER_ITEM,
        payload: {
          processingOrderItemIds: updatedSelectedOrderItemIds,
        },
      });
    },
    [
      closeModal,
      processingOrderItemIds,
      showNotification,
      translate,
      updateCart,
    ],
  );

  const promptSplitProduct = useCallback(
    (itemId: string) => {
      const selectingItem = orderItemMaps[itemId];
      showModal(
        <KeypadModal
          title={translate('payment.selectSplitQty')}
          mode="decimal"
          product={selectingItem}
          initialValue="1"
          onConfirm={(splitQuantity: number) =>
            onConfirmQuantitySplitByProduct(
              itemId,
              selectingItem.quantity as number,
              splitQuantity,
            )
          }
          dismissOnConfirm={false}
          onDismiss={closeModal}
          secondaryButton={
            <TreatButton
              testID="btn-noSplit"
              height={50}
              type="focus"
              label={translate('payment.cancelSplit')}
              onPress={closeModal}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{ marginTop: 10 }}
            />
          }
        />,
      );
    },
    [
      orderItemMaps,
      showModal,
      translate,
      closeModal,
      onConfirmQuantitySplitByProduct,
    ],
  );

  const onSelectOrderItem = useCallback(
    (params: CartSelectionState) => {
      const itemId = params.item;
      const selectingItem = orderItemMaps[itemId];
      if (selectingItem?.parentCombo?.id) return;
      const paidItem =
        selectingItem.paymentStatus === OrderPaymentStatus.COMPLETE;

      const isItemSelected = processingOrderItemIds.includes(itemId);
      const isMultiQuantity = selectingItem.quantity > 1;

      // not allow to select order item while doing other split payment options
      if (!isSplitByProduct || paidItem) return;
      if (!isItemSelected && isMultiQuantity) promptSplitProduct(itemId);
      updateSelectedOrderItemIds(itemId);
    },
    [
      isSplitByProduct,
      orderItemMaps,
      processingOrderItemIds,
      promptSplitProduct,
      updateSelectedOrderItemIds,
    ],
  );

  useEffect(() => {
    if (customerError) {
      showNotification({
        error: true,
        message: customerError,
      });
    }
  }, [customerError, showNotification]);

  useEffect(() => {
    isCoursesEnabled && getCourses();
  }, [getCourses, isCoursesEnabled]);

  useEffect(() => {
    if (isLegacyLoyaltyEnabled && !earningRules?.length) {
      getLoyaltyPrograms();
    }
  }, [earningRules?.length, getLoyaltyPrograms, isLegacyLoyaltyEnabled]);

  const orderNumber = order?.orderNumber || '';

  useEffect(() => {
    if (customerAmount && isFocused) {
      resetCustomerBalanceState();
      dispatch({
        type: PaymentAction.UPDATE_CUSTOMER_BALANCE_PAYMENT,
        payload: {
          customerAmount,
        },
      });
    }
  }, [customerAmount, isFocused, resetCustomerBalanceState]);

  useEffect(() => {
    const selectedCustomerId = customerId || order?.customer?.id || '';
    setAssignedCustomerId(selectedCustomerId);
  }, [customerId, order?.customer?.id]);

  const assignedCustomer = useMemo(
    () => customerMaps[assignedCustomerId],
    [assignedCustomerId, customerMaps],
  );

  useEffect(() => {
    if (
      order &&
      assignedCustomer?.loyaltyMember &&
      isNewLoyaltyEnabled &&
      order.status !== OrderStatus.COMPLETED &&
      order?.customer?.loyaltyMember
    ) {
      estimateSnapshot(assignedCustomer.id, order);
    }
  }, [isNewLoyaltyEnabled, estimateSnapshot, order, assignedCustomer]);

  useEffect(() => {
    if (customerOriginalBalance.current === undefined && assignedCustomer) {
      // avoid customer update subscription to override original balance
      customerOriginalBalance.current =
        assignedCustomer.loyaltyPointsBalance || 0;
    }
  }, [assignedCustomer]);

  /**
   * On 'success' - send sale receipt event
   */
  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (customerAmount) return;
    let isPaymentCompleted = [
      OrderStatus.COMPLETED,
      OrderStatus.ON_ACCOUNT,
    ].includes(order?.status as OrderStatus);
    if (order?.amountDue === 0 && order.status == OrderStatus.ON_HOLD) {
      // to avoid take next payment when payment is done for on hold orders
      isPaymentCompleted = true;
    }
    const nthPayment = order?.payments?.length;

    let processingAmount = order?.amountDue || 0;

    if (nthPayment && nthPayment > 0) {
      const nthPaymentData = order?.payments[nthPayment - 1];
      if (
        nthPaymentData.paymentType?.name !== DefaultPaymentTypes.ON_ACCOUNT &&
        nthPaymentData.status === OrderPaymentStatus.PENDING
      ) {
        processingAmount = nthPaymentData.tendered;
      }
    }

    dispatch({
      type: PaymentAction.UPDATE_PAYMENT_INFO_FROM_ORDER,
      payload: {
        totalAmount: (order?.totalPaymentAmount || 0) as number,
        amountDue: (order?.amountDue || 0) as number,
        isPaymentCompleted: isPaymentCompleted,
        nthPayment: order?.payments?.length || 0,
        processingAmount: processingAmount,
      },
    });
  }, [
    customerAmount,
    order?.amountDue,
    order?.payments?.length,
    order?.payments,
    order?.status,
    order?.totalPaymentAmount,
  ]);

  const navigateToDefaultScreen = useCallback(async () => {
    if (order?.status === OrderStatus.ON_HOLD) {
      // if order is in on hold dont navigate to other screen
      return;
    }
    if (postSaleScreen && AppScreen[postSaleScreen] != AppScreen.NEW_ORDER) {
      const newOrderId = await resetCart();
      setCartParams(newOrderId, defaultOrderType?.id, '', false);
    }
    navigateToPostSaleScreen(undefined, true);
    closeModal();
  }, [
    closeModal,
    defaultOrderType?.id,
    navigateToPostSaleScreen,
    postSaleScreen,
    resetCart,
    setCartParams,
    order?.status,
  ]);

  const onPrintSplit = useCallback(async () => {
    if (order) {
      const cachedOrder = getOrderFromCache(order.id) || order;
      const nthPaymentToPrint = (cachedOrder?.payments || []).length - 1;
      updateCart(OrderAction.ORDER_PRINT);
      const result = await printBill(cachedOrder, nthPaymentToPrint);
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
      if (isPaymentCompleted) navigateToDefaultScreen();
    }
  }, [
    getOrderFromCache,
    isPaymentCompleted,
    navigateToDefaultScreen,
    order,
    printBill,
    showNotification,
    updateCart,
  ]);

  /**
   * Send order sale receipt to customer's email address
   *
   * @param email  email address of customer incase when the order is being placed without customer information
   */
  const sendOrderReceipt = useCallback(
    async (input: sendReceiptInput) => {
      if (!isConnected) {
        showNotification({
          error: true,
          message: translate('offline.operation'),
        });
        return;
      }
      if (session && order?.id) {
        const event = {
          action: OrderAction.CUSTOMER_NOTIFICATION,
          orderId: order.id,
          id: uuidv4(),
          notificationType:
            input.mode === NotificationMode.PHONE
              ? NotificationType.SEND_MESSAGE
              : NotificationType.SEND_RECEIPT,
          notificationMode: input.mode,
          organizationId: session.currentOrganization?.id || '',
          venueId: session.currentVenue?.id || '',
          storeId: session.currentStore?.id || '',
          deviceId: session.device?.id || '',
          timestamp: Date.now(),
          triggeredBy: userUtility.userActivity.posUser?.id || '',
          ...(input.email && { email: input.email }),
          ...(input.phone?.number && {
            phone: `${getCountry(input.phone.countryCode)?.phone} ${
              input.phone.number
            }`,
          }),
        };
        syncOrderEvents([event]);
      }
    },
    [
      session,
      order?.id,
      syncOrderEvents,
      isConnected,
      showNotification,
      translate,
    ],
  );

  useEffect(() => {
    if (!isFocused) return;
    return () => {
      dispatch({ type: PaymentAction.RESET_INITIAL_STATE, payload: {} });
      setShowCompleteSaleModal(false);
    };
  }, [isFocused]);

  useEffect(() => {
    if (processingPaymentSeat)
      setSelectSeatNumber(String(processingPaymentSeat));
  }, [processingPaymentSeat]);

  const onPressPrintReceipt = useCallback(async (): Promise<void> => {
    if (order) {
      analyticsService.capture('send_receipt', {
        method: 'Print',
      });
      const cachedOrder = getOrderFromCache(order.id) || order;
      updateCart(OrderAction.ORDER_PRINT);
      const result = await printBill(cachedOrder, undefined, {
        loyaltyProgram: newLoyaltySettings,
      });
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
      navigateToDefaultScreen();
    }
  }, [
    order,
    getOrderFromCache,
    printBill,
    navigateToDefaultScreen,
    showNotification,
    updateCart,
    newLoyaltySettings,
  ]);

  const onPressSendReceipt = useCallback(() => {
    if (!isConnected) {
      showNotification({
        error: true,
        message: translate('offline.operation'),
      });
      return;
    }
    setShowCompleteSaleModal(true);
  }, [isConnected, showNotification, translate]);

  const sortedSeatsHasItem = useMemo(() => {
    return uniq(
      order?.orderItems
        .filter(
          orderItem =>
            ![
              OrderItemStatus.CANCELLED,
              OrderItemStatus.VOID,
              OrderItemStatus.TRANSFERRED,
            ].includes(orderItem.status),
        )
        .map(orderItem => orderItem.seatNumber)
        .filter(seat => seat),
    )
      .sort((a = 0, b = 0) => a - b)
      .map(seat => String(seat)) as string[];
  }, [order?.orderItems]);

  const occupiedSeatsWithDefaultTable = useMemo(() => {
    if (sortedSeatsHasItem?.length)
      return [
        { id: DEFAULT_TABLE_ABBREVIATION, title: DEFAULT_TABLE_ABBREVIATION },
      ].concat(
        sortedSeatsHasItem.map(seatNumber => ({
          id: seatNumber,
          title: `S${seatNumber}`,
        })),
      );

    return [];
  }, [sortedSeatsHasItem]);

  const sharedOrderItems = useMemo(() => {
    if (!selectedSeatNumber || !sortedSeatsHasItem?.length) return [];
    const numberOfSeatOccupied = sortedSeatsHasItem.length;
    return order?.orderItems
      ?.filter(
        item =>
          !item.seatNumber &&
          item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      )
      .map(item => {
        return {
          ...item,
          unitPrice: item.unitPrice / numberOfSeatOccupied,
          modifiers: item.modifiers.map(modifier => ({
            ...modifier,
            unitPrice: modifier.unitPrice / numberOfSeatOccupied,
          })),
          discountAmount: item.discountAmount / numberOfSeatOccupied,
          surchargeAmount: (item?.surchargeAmount || 0) / numberOfSeatOccupied,
        };
      });
  }, [order?.orderItems, selectedSeatNumber, sortedSeatsHasItem?.length]);

  /**
   * Case1: If the customer receipt printing option is set as Print Receipts Automatically in the register profile settings
   * Receipt should be printed automatically when the complete sale button is selected from the payment screen
   *
   * OR
   *
   * Case2: If the customer receipt printing Option is set as Prompt for Print/Email Receipt in the register profile settings,
   * then the staff would be prompted with a modal after the complete sale button is selected where the print button would print the receipt , Ignore button wouldn't print the receipt
   */
  const onCompleteSale = useCallback(async () => {
    if (isCustomerBalancePayment) {
      resetCustomerBalanceState();
    }
    navigateToPostSaleScreen(!!isCustomerBalancePayment, true);

    if (postSaleScreen && AppScreen[postSaleScreen] != AppScreen.NEW_ORDER) {
      await closeOrderCart();
      const newOrderId = await resetCart();
      setCartParams(newOrderId, defaultOrderType?.id, '', false);
      takeOrderUnFocusClearParamController.next();
    }

    if (
      receiptPrintOption === ReceiptPrintOption.AUTO &&
      !isCustomerBalancePayment
    ) {
      // print bill receipt with delay silently
      setTimeout(() => onPressPrintReceipt(), 1000);
    }
  }, [
    isCustomerBalancePayment,
    postSaleScreen,
    receiptPrintOption,
    navigateToPostSaleScreen,
    closeOrderCart,
    resetCart,
    setCartParams,
    defaultOrderType?.id,
    onPressPrintReceipt,
    resetCustomerBalanceState,
  ]);

  const onAssignCustomerToOrder = useCallback(
    customer => {
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: isEmpty(customer?.customerAccountDetails)
          ? {}
          : {
              accountPayment: customer?.customerAccountDetails?.accountPayment,
              currentBalance: customer?.customerAccountDetails?.currentBalance,
              maxBalanceLimit:
                customer?.customerAccountDetails?.maxBalanceLimit,
              maxOrderLimit: customer?.customerAccountDetails?.maxOrderLimit,
            },
        isLoyaltyApplied: isLegacyLoyaltyEnabled && customer.loyaltyMember,
      });
      // reset cart
      updateCart(OrderAction.ORDER_REWARD_RESET);
    },
    [isLegacyLoyaltyEnabled, updateCart],
  );

  const recentEarnedPoints = useMemo(() => {
    if (order?.isTraining) return 0;
    if (isNewLoyaltyEnabled) {
      return snapshot?.pointsEarned || 0;
    } else if (isLegacyLoyaltyEnabled) {
      return calculatePointsEarnedForOrder(earningRules, order);
    }
    return 0;
  }, [
    earningRules,
    order,
    isLegacyLoyaltyEnabled,
    isNewLoyaltyEnabled,
    snapshot,
  ]);

  const recentRedeemedPoints = useMemo(() => {
    const allRedeemedRewards = getAllRedeemedRewards(order);

    return allRedeemedRewards.reduce(
      (acc, adj) =>
        acc + adj.pointsRequired * (adj.itemQuantity || adj.quantity),
      0,
    );
  }, [order]);

  const earnedPointInfo = useMemo(() => {
    if (!isLoyaltyEnabled || !assignedCustomer?.loyaltyMember) return;
    return translate('customerLoyalty.pointsEarned', {
      points: recentEarnedPoints,
      unit: getLoyaltyUnit(recentEarnedPoints, loyaltySettings),
    });
  }, [
    assignedCustomer?.loyaltyMember,
    isLoyaltyEnabled,
    loyaltySettings,
    recentEarnedPoints,
    translate,
  ]);

  const availableRewards = useMemo(() => {
    if (isNewLoyaltyEnabled) {
      return (
        snapshot?.availableRewards.map(reward =>
          mapRewardToLegacyReward(reward),
        ) || []
      );
    } else if (isLegacyLoyaltyEnabled) {
      const totalPoints =
        recentEarnedPoints +
        (customerOriginalBalance.current || 0) -
        recentRedeemedPoints;
      return rewardRules.filter(reward => reward.pointsRequired <= totalPoints);
    }
    return [];
  }, [
    recentEarnedPoints,
    recentRedeemedPoints,
    rewardRules,
    isNewLoyaltyEnabled,
    isLegacyLoyaltyEnabled,
    snapshot,
  ]);

  const onViewRewards = useCallback(() => {
    setShowCompleteSaleModal(false);
    setShowRewardModal(true);
  }, []);

  const saveLoyaltyInfoToOrdersCache = useCallback(
    (customer: Customer) => {
      if (isLegacyLoyaltyEnabled) {
        const loyaltySnapshot = getLoyaltySnapshot(
          customerOriginalBalance.current,
          recentEarnedPoints,
          recentRedeemedPoints,
        );

        const newOrder = {
          ...order,
          customer,
          loyaltySnapshot,
        };

        saveOrder({ variables: { data: newOrder } });
      }
    },
    [
      order,
      recentEarnedPoints,
      recentRedeemedPoints,
      getLoyaltySnapshot,
      saveOrder,
      isLegacyLoyaltyEnabled,
    ],
  );

  const onEnrollToLoyalty = useCallback(async () => {
    if (!isConnected) {
      showNotification({
        error: true,
        message: translate('offline.operation'),
      });
      return;
    }
    // if no customer is assigned, show the modal to enroll new customer
    if (!assignedCustomer) {
      if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
        setShowCompleteSaleModal(false);
      }
      setShowEnrollLoyaltyModal(true);
      return;
    }

    if (isNewLoyaltyEnabled && !assignedCustomer.phone) {
      setShowEnrollLoyaltyModal(true);
      return;
    }

    // else, enroll the currently assigned one
    if (isNewLoyaltyEnabled) {
      const member = await enrollLoyaltyMember(assignedCustomer.id);
      updateCustomerInfo({
        ...assignedCustomer,
        loyaltyMember: true,
        loyaltyPointsBalance: member.balance?.availableBalance || 0,
      });
    } else if (isLegacyLoyaltyEnabled) {
      const enrolledCustomer = await enrollCustomerLoyalty(
        {
          customerId: assignedCustomer.id,
          latestOrderId: order?.id,
          loyaltyEnrolmentSource: LoyaltyEnrolmentSource.POS,
        },
        recentEarnedPoints,
      );
      // update loyalty member flag
      updateCart<UpdateCustomerEvent>(OrderAction.ORDER_UPDATE_CUSTOMER, {
        loyaltyMember: true,
        isLoyaltyApplied: true,
      });
      updateCart(OrderAction.ORDER_SAVE);
      saveLoyaltyInfoToOrdersCache(enrolledCustomer);
    }
  }, [
    isConnected,
    assignedCustomer,
    isNewLoyaltyEnabled,
    isLegacyLoyaltyEnabled,
    showNotification,
    translate,
    receiptPrintOption,
    enrollLoyaltyMember,
    updateCustomerInfo,
    enrollCustomerLoyalty,
    order?.id,
    recentEarnedPoints,
    updateCart,
    saveLoyaltyInfoToOrdersCache,
  ]);

  const onUnassignCustomerToOrder = useCallback(() => {
    updateCart<OrderEvent>(OrderAction.ORDER_UNASSIGN_CUSTOMER);
    // reset cart
    updateCart(OrderAction.ORDER_REWARD_RESET);
  }, [updateCart]);

  const onPressLoyalty = useCallback(() => {
    if (order?.isTraining) return;
    assignedCustomer?.loyaltyMember ? onViewRewards() : onEnrollToLoyalty();
  }, [
    assignedCustomer?.loyaltyMember,
    onEnrollToLoyalty,
    onViewRewards,
    order?.isTraining,
  ]);

  const loyaltyAction = useMemo(() => {
    if (!isLoyaltyEnabled || !isPaymentCompleted) return null;
    return (
      <TreatButton
        testID="btn-loyalty"
        height={50}
        type="neutralLight"
        isLoading={customerLoading}
        disabled={customerLoading}
        label={
          assignedCustomer?.loyaltyMember
            ? translate('customerLoyalty.viewRewards', {
                numberOfReward: availableRewards.length,
              })
            : translate('customerLoyalty.enrollToLoyalty')
        }
        onPress={onPressLoyalty}
        containerStyle={styles.btnLoyalty}
      />
    );
  }, [
    assignedCustomer?.loyaltyMember,
    availableRewards.length,
    customerLoading,
    isLoyaltyEnabled,
    isPaymentCompleted,
    onPressLoyalty,
    translate,
  ]);

  const onPaymentComplete = useCallback(
    (roundedOffChange: number) => {
      if (enableQuickPaymentMode) {
        changeDueVar(roundedOffChange);
      }

      if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
        const timeout = isWeb ? 0 : isSplitPayment ? 1500 : 500;
        showCompleteSaleModalWithDelay.start(timeout);
      } else if (enableQuickPaymentMode) {
        onCompleteSale();
      }
      syncItemAvailability();
    },
    [
      enableQuickPaymentMode,
      receiptPrintOption,
      syncItemAvailability,
      isSplitPayment,
      showCompleteSaleModalWithDelay,
      onCompleteSale,
    ],
  );

  const updateCustomerDetails = useCallback(
    (amount: number, paymentType: string) => {
      const customer = cloneDeep(customerMaps[order?.customer?.id as string]);
      if (!order?.customer || !customer) return;
      let isCustomerInfoChanged = false;

      if (customer.loyaltyMember) {
        isCustomerInfoChanged = true;
        const totalPoints =
          recentEarnedPoints +
          (customerOriginalBalance.current || 0) -
          recentRedeemedPoints;

        customer.loyaltyPointsBalance = totalPoints;
        customer.lifetimeLoyaltyPoints =
          (customer.lifetimeLoyaltyPoints || 0) + recentEarnedPoints;
      }

      if (
        paymentType === DefaultPaymentTypes.ON_ACCOUNT &&
        assignedCustomer?.customerAccountDetails?.accountPayment
      ) {
        isCustomerInfoChanged = true;
        customer.customerAccountDetails = {
          ...customer.customerAccountDetails,
          currentBalance:
            (customer.customerAccountDetails?.currentBalance || 0) + amount,
        };
      }

      if (isCustomerInfoChanged) {
        updateCustomerInfo(customer);
      }
    },
    [
      customerMaps,
      order?.customer,
      assignedCustomer,
      recentEarnedPoints,
      recentRedeemedPoints,
      updateCustomerInfo,
    ],
  );

  /**
   * Add transaction and publish payment event
   */
  const onPressPay = useCallback(
    (
      paymentType: PaymentType,
      amount: number,
      surchargeAmount = 0,
      customer?: Customer,
      additionalInfo?: AdditionalPaymentInfo,
      saveEventId?: string,
    ) => {
      let validReceivedAmount = amount || 0;
      if (
        paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE &&
        enableRoundOff
      ) {
        validReceivedAmount = roundOffByValue(amount, roundOffValue);
      }

      const change = limitDecimalCount(
        sumDecimals([validReceivedAmount, -processingAmount, -surchargeAmount]),
      );

      const roundedOffChange = enableRoundOff
        ? roundOffByValue(change, roundOffValue)
        : change;

      if (isLoyaltyEnabled && assignedCustomer?.loyaltyMember) {
        if (snapshot) {
          const loyaltySnapshot = mapLoyaltySnapshot(snapshot);
          updateLoyaltySnapshot(loyaltySnapshot);
        } else {
          calculateAndUpdateLoyaltySnapshot(
            customerOriginalBalance.current,
            recentEarnedPoints,
            recentRedeemedPoints,
          );
        }
      }
      const paymentEventInput = {
        tendered: validReceivedAmount,
        paymentTypeId: paymentType.id,
        tip: 0,
        change: roundedOffChange,
        roundOffDifference: limitDecimalCount(
          sumDecimals([change, -roundedOffChange]),
        ),
        paymentTypeName: paymentType.name,
        ...(additionalInfo &&
          additionalInfo.paymentRequestId && {
            paymentRequestId: additionalInfo.paymentRequestId,
          }),
        ...(additionalInfo &&
          additionalInfo.paymentStatus && {
            paymentStatus: additionalInfo.paymentStatus,
          }),
      };

      if (isCustomerBalancePayment) {
        updateCustomerBalanceEvent<OnAccountPaymentEvent>({
          action: OnAccountAction.ON_ACCOUNT_PAYMENT,
          input: {
            ...paymentEventInput,
          },
          customerId,
          ...(onAccountOrderId && { orderId: onAccountOrderId }),
        });
      } else {
        const orderItemIds = orderPaymentDetails?.processingOrderItemIds || [];
        updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
          ...paymentEventInput,
          ...(orderItemIds.length && { orderItemIds }),
          onAccount: paymentType.name === ON_ACCOUNT_PAYMENT_TYPE,
          ...(splitPaymentBy && {
            splitPaymentBy,
          }),
          customer: order?.customer?.id,
        });
        // Open cash drawer
        if (
          ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER.includes(
            paymentType.name?.toLowerCase(),
          )
        ) {
          openCashDrawer();
        }

        if (saveEventId) {
          updateCart(OrderAction.ORDER_SAVE, undefined, saveEventId);
        } else {
          updateCart(OrderAction.ORDER_SAVE);
        }

        const isPaymentCompleted =
          remainingDueAmount === processingAmount &&
          additionalInfo?.paymentStatus !== OrderPaymentStatus.PENDING;
        if (isPaymentCompleted) {
          onPaymentComplete(roundedOffChange);
        }
      }

      if (additionalInfo?.paymentStatus !== OrderPaymentStatus.PENDING) {
        updateCustomerDetails(validReceivedAmount, paymentType.name);
        dispatch({
          type: PaymentAction.PAY_OUT,
          payload: {
            lastReceivedAmount: validReceivedAmount,
            lastChangeDueAmount: roundedOffChange,
          },
        });
      }
    },
    [
      enableRoundOff,
      processingAmount,
      roundOffValue,
      isLoyaltyEnabled,
      assignedCustomer?.loyaltyMember,
      isCustomerBalancePayment,
      snapshot,
      updateLoyaltySnapshot,
      calculateAndUpdateLoyaltySnapshot,
      recentEarnedPoints,
      recentRedeemedPoints,
      updateCustomerBalanceEvent,
      customerId,
      orderPaymentDetails?.processingOrderItemIds,
      updateCart,
      splitPaymentBy,
      order?.customer?.id,
      remainingDueAmount,
      openCashDrawer,
      onPaymentComplete,
      updateCustomerDetails,
      onAccountOrderId,
    ],
  );

  const onPaymentProcessed = useCallback(
    (events: OrderEvent[]) => {
      const paymentProcessedEvent = events.find(
        event => event.action === OrderAction.ORDER_PAYMENT_PROCESSED,
      ) as OrderPaymentProcessedEvent | undefined;

      if (paymentProcessedEvent) {
        const pendingPayment = order?.payments.find(
          payment =>
            payment.paymentRequestId === paymentProcessedEvent.paymentRequestId,
        );

        let paymentAmount: number = pendingPayment?.amount || 0;

        if (paymentProcessedEvent.paymentSurcharge)
          paymentAmount += paymentProcessedEvent.paymentSurcharge;

        if (paymentProcessedEvent.paymentTip)
          paymentAmount += paymentProcessedEvent.paymentTip;

        const roundedOffChange = 0;

        addEventsToCart(events);

        toggleNavigation(true);

        const isPaymentCompleted =
          remainingDueAmount === processingAmount &&
          paymentProcessedEvent.paymentStatus === OrderPaymentStatus.COMPLETE;

        if (isPaymentCompleted) {
          onPaymentComplete(roundedOffChange);
        } else if (
          paymentProcessedEvent.paymentStatus === OrderPaymentStatus.COMPLETE
        ) {
          // print dockets
          kitchenOrderEvents.publishToKotUtil({
            orderId: events[0]?.orderId,
            preEvents: [],
          });
        }

        if (
          paymentProcessedEvent.paymentStatus === OrderPaymentStatus.COMPLETE
        ) {
          dispatch({
            type: PaymentAction.PAY_OUT,
            payload: {
              lastReceivedAmount: paymentAmount,
              // Change will be 0 for terminal payments.
              lastChangeDueAmount: 0,
            },
          });
        }
      }
    },
    [
      addEventsToCart,
      onPaymentComplete,
      order?.payments,
      processingAmount,
      remainingDueAmount,
    ],
  );

  const onAccountPaymentProcessed = useCallback(
    (events: OnAccountEvent[]) => {
      const paymentProcessedEvent = events.find(
        event => event.action === OnAccountAction.ON_ACCOUNT_PAYMENT_PROCESSED,
      ) as OnAccountPaymentProcessedEvent | undefined;

      if (paymentProcessedEvent) {
        const pendingPayment = customerBalanceState.cardPayment;

        let paymentAmount: number = pendingPayment?.amount || 0;

        if (paymentProcessedEvent.paymentSurcharge)
          paymentAmount += paymentProcessedEvent.paymentSurcharge;

        if (paymentProcessedEvent.paymentTip)
          paymentAmount += paymentProcessedEvent.paymentTip;

        const roundedOffChange = 0;

        const isPaymentCompleted =
          remainingDueAmount === processingAmount &&
          paymentProcessedEvent.paymentStatus === OrderPaymentStatus.COMPLETE;

        if (isPaymentCompleted) {
          onPaymentComplete(roundedOffChange);
        }
        updateCustomerBalanceEvent({
          action: OnAccountAction.ON_ACCOUNT_PAYMENT_PROCESSED,
          existingEvent: paymentProcessedEvent,
        });

        if (
          paymentProcessedEvent.paymentStatus === OrderPaymentStatus.COMPLETE
        ) {
          dispatch({
            type: PaymentAction.PAY_OUT,
            payload: {
              lastReceivedAmount: paymentAmount,
              lastChangeDueAmount: 0,
            },
          });
        }
      }
    },
    [
      customerBalanceState,
      onPaymentComplete,
      processingAmount,
      remainingDueAmount,
      updateCustomerBalanceEvent,
    ],
  );

  const onPaymentInitiate = useCallback(() => {
    toggleNavigation(false);
  }, []);

  const onPaymentCancel = useCallback(() => {
    toggleNavigation(true);
  }, []);

  const onTakeNextPayment = useCallback(() => {
    dispatch({ type: PaymentAction.TAKE_NEXT_PAYMENT, payload: {} });
  }, []);

  const onSplitProduct = useCallback(() => {
    const selectingOrderItemId = processingOrderItemIds[0];
    const selectingItem = orderItemMaps[selectingOrderItemId];
    showModal(
      <KeypadModal
        title={translate('payment.selectSplitQty')}
        mode="decimal"
        product={selectingItem}
        initialValue="1"
        onConfirm={(splitQuantity: number) =>
          onConfirmQuantitySplitByProduct(
            selectingOrderItemId,
            selectingItem.quantity as number,
            splitQuantity,
          )
        }
        dismissOnConfirm={false}
        onDismiss={closeModal}
      />,
    );
  }, [
    processingOrderItemIds,
    orderItemMaps,
    showModal,
    translate,
    closeModal,
    onConfirmQuantitySplitByProduct,
  ]);

  const latestPayment = useMemo(
    () => lastItem(order?.payments),
    [order?.payments],
  );

  const onDismissRewardModal = useCallback(() => {
    setShowRewardModal(false);
    if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
      setShowCompleteSaleModal(true);
    }
  }, [receiptPrintOption]);

  const onCloseEnrollLoyaltyModal = useCallback(() => {
    setShowEnrollLoyaltyModal(false);
    if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
      setShowCompleteSaleModal(true);
    }
  }, [receiptPrintOption]);

  const updateAndEnrolAssignedCustomer = useCallback(
    async (customerInfo: EnrollCustomerInput) => {
      //update customer only if first name or phone changed
      if (
        assignedCustomer.firstName !== customerInfo.firstName ||
        assignedCustomer.phone !== customerInfo.phone
      ) {
        const updatedCustomer = await updateCustomer({
          id: assignedCustomer.id,
          firstName: customerInfo.firstName,
          phone: customerInfo.phone,
        });

        if (!updatedCustomer) return;
      }
      const updatedCustomer = await enrollMemberLoyalty(
        assignedCustomer.id,
        EnrollmentSource.POS,
      );
      if (updatedCustomer) {
        updateCart(OrderAction.ORDER_SAVE);
      }
      return updatedCustomer;
    },
    [
      assignedCustomer?.firstName,
      assignedCustomer?.id,
      assignedCustomer?.phone,
      enrollMemberLoyalty,
      updateCart,
      updateCustomer,
    ],
  );

  const onEnrollToLoyaltyByPhone = useCallback(
    async (customerInfo: EnrollCustomerInput) => {
      try {
        let enrolledMember;
        if (isNewLoyaltyEnabled) {
          // this is the logic for new loyalty
          if (assignedCustomer) {
            enrolledMember = await updateAndEnrolAssignedCustomer(customerInfo);
          } else {
            enrolledMember = await addNewCustomer({
              ...customerInfo,
              loyaltyMember: true,
            });
          }
          if (!enrolledMember) return;
          updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
            customerId: enrolledMember.id,
            firstName: enrolledMember.firstName,
            phone: enrolledMember.phone,
            loyaltyMember: enrolledMember.loyaltyMember,
            isLoyaltyApplied: false, // exclude the legacy flag (this triggers the legacy worker, which is not what we want for new loyalty)
          });
        } else {
          // this is the logic for legacy loyalty
          enrolledMember = await enrollCustomerLoyalty(
            {
              ...customerInfo,
              latestOrderId: order?.id,
            },
            recentEarnedPoints,
          );
        }
        setAssignedCustomerId(enrolledMember?.id || assignedCustomer?.id);
        onCloseEnrollLoyaltyModal();
        saveLoyaltyInfoToOrdersCache(enrolledMember);
      } catch (error) {
        showNotification({
          error: true,
          message: (error as { message: string })?.message,
        });
      }
    },
    [
      addNewCustomer,
      assignedCustomer,
      enrollCustomerLoyalty,
      isNewLoyaltyEnabled,
      order?.id,
      recentEarnedPoints,
      onCloseEnrollLoyaltyModal,
      saveLoyaltyInfoToOrdersCache,
      showNotification,
      updateCart,
      updateAndEnrolAssignedCustomer,
    ],
  );

  const rewardItems = useMemo(
    () =>
      (order?.adjustments || []).filter(
        adj => adj.adjustmentType == AdjustmentType.REWARD,
      ) as RewardAdjustment[],
    [order],
  );

  const orderItemByPaid = useMemo(
    () =>
      orderBy(
        order?.orderItems || [],
        [item => item.paymentStatus === OrderPaymentStatus.COMPLETE],
        ['asc'],
      ),
    [order?.orderItems],
  );

  const renderSubPaymentScreen = useMemo(() => {
    switch (activeScreen) {
      case PaymentSubScreen.MAIN_SCREEN:
      case PaymentSubScreen.PAYOUT_SCREEN:
        return (
          <PaymentOptions
            enableRoundOff={enableRoundOff}
            roundOffValue={roundOffValue}
            onPressPay={onPressPay}
            onPaymentProcessed={onPaymentProcessed}
            onAccountPaymentProcessed={onAccountPaymentProcessed}
            dispatch={dispatch}
            orderPaymentDetails={orderPaymentDetails}
            onPaymentInitiate={onPaymentInitiate}
            onPaymentCancel={onPaymentCancel}
            customerAmount={customerAmount}
            assignedCustomer={assignedCustomer}
            customerLoading={customerLoading}
            updateCustomerOnAccount={updateCustomerBalance}
          />
        );

      case PaymentSubScreen.PAYMENT_SUCCEEDED:
        return (
          <PaymentComplete
            onCompleteSale={onCompleteSale}
            onTakeNextPayment={onTakeNextPayment}
            onPressPrintReceipt={onPressPrintReceipt}
            onPressSendReceipt={onPressSendReceipt}
            showPrintReceiptButton={showPrintReceiptButton}
            orderPaymentDetails={orderPaymentDetails}
            changeDueAmount={lastChangeDueAmount}
            receivedAmount={lastReceivedAmount}
            onPrintSplit={onPrintSplit}
            loyaltyAction={loyaltyAction}
            earnedPointInfo={earnedPointInfo}
            dispatch={dispatch}
            orderStatus={order?.status}
          />
        );

      default:
        return null;
    }
  }, [
    activeScreen,
    earnedPointInfo,
    enableRoundOff,
    lastChangeDueAmount,
    lastReceivedAmount,
    loyaltyAction,
    onCompleteSale,
    onPaymentCancel,
    onPaymentInitiate,
    onPaymentProcessed,
    onPressPay,
    onPressPrintReceipt,
    onPressSendReceipt,
    onPrintSplit,
    onTakeNextPayment,
    orderPaymentDetails,
    roundOffValue,
    showPrintReceiptButton,
    order?.status,
    onAccountPaymentProcessed,
    customerAmount,
    assignedCustomer,
    updateCustomerBalance,
    customerLoading,
  ]);

  const showSplitProductButton = useMemo(
    // only show when oneItem selected
    () => isSplitByProduct && processingOrderItemIds.length === 1,
    [isSplitByProduct, processingOrderItemIds.length],
  );

  useEffect(() => {
    // updating the processing amount when select new Item.
    if (
      !isSplitByProduct ||
      activeScreen === PaymentSubScreen.PAYMENT_SUCCEEDED
    )
      return;
    const processingItems =
      order?.orderItems?.filter(item =>
        processingOrderItemIds.includes(item.id),
      ) || [];

    const activeItems = order?.orderItems.filter(
      item =>
        item.status !== OrderItemStatus.CANCELLED &&
        item.status !== OrderItemStatus.VOID &&
        item.status !== OrderItemStatus.TRANSFERRED,
    );

    const unPaidItems =
      activeItems?.filter(
        item => item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      ) || [];

    const totalUnPaidAmount =
      computeTotalOrderItemsAmountWithReward(unPaidItems);

    const totalItemsAmount =
      computeTotalOrderItemsAmountWithReward(processingItems);

    const processingAmount = proportionateAmount(
      totalItemsAmount,
      totalUnPaidAmount as number,
      order?.amountDue as number,
    );

    dispatch({
      type: PaymentAction.UPDATE_PAYMENT_AMOUNT,
      payload: {
        processingAmount,
      },
    });
  }, [
    activeScreen,
    isSplitByProduct,
    order?.orderItems,
    order?.amountDue,
    processingOrderItemIds,
  ]);

  useEffect(() => {
    if (!assignedCustomer && order?.customer?.id) {
      getCustomerById(order?.customer?.id);
    }
  }, [assignedCustomer, getCustomerById, order?.customer?.id]);

  const handleOnPressBack = () => {
    if (
      (order?.status === OrderStatus.COMPLETED ||
        order?.status === OrderStatus.ON_ACCOUNT) &&
      Math.abs(remainingDueAmount) == 0
    ) {
      onCompleteSale();
    } else {
      navigation.goBack();
    }
  };

  const getOrderTitle = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parts: string[] = [];

    const orderName = order?.orderType?.name || orderType?.name;

    if (orderName) {
      parts.push(orderName);
    }

    const tables = getTablesFromOrder(order);

    if (tables.length) {
      const sectionName = getSectionNameFromOrder(order);
      const tableName = getTableNameFromOrder(order);

      const tableParts = [sectionName, tableName].filter(Boolean);
      if (tableParts.length) parts.push(tableParts.join(', '));
    }

    return parts.join(' – ');
  };

  const accountDetails = assignedCustomer?.customerAccountDetails;
  const showTotalRemaining =
    (isSplitPayment && orderPaymentDetails.nthPayment > 1) ||
    (order?.payments && order.payments.length > 0);

  const newBalance = useMemo(() => {
    if (isNewLoyaltyEnabled) {
      return snapshot?.availableBalance || 0;
    } else if (isLegacyLoyaltyEnabled) {
      return (
        (customerOriginalBalance.current || 0) -
        recentRedeemedPoints +
        recentEarnedPoints
      );
    }
    return 0;
  }, [
    recentRedeemedPoints,
    recentEarnedPoints,
    isNewLoyaltyEnabled,
    isLegacyLoyaltyEnabled,
    snapshot,
  ]);

  return (
    <ScreenLayout
      hideNewOrder
      loading={loading}
      onBack={handleOnPressBack}
      hideRHS={!navigationAllowed}
      title={
        isCustomerBalancePayment
          ? translate('payment.balancePayment')
          : `${translate('payment.order')} ${orderNumber}`
      }
    >
      <View style={[styles.container, { height: safeHeight }]}>
        <View style={styles.order}>
          <AssignCustomer
            name={
              assignedCustomer &&
              `${assignedCustomer.firstName} ${assignedCustomer.lastName}`
            }
            assignedCustomer={assignedCustomer}
            orderId={order?.id || ''}
            payments={order?.payments}
            onAssign={onAssignCustomerToOrder}
            onUnassign={onUnassignCustomerToOrder}
            loyaltySettings={loyaltySettings}
            rewardRules={rewardRules}
          />
          {!isCustomerBalancePayment ? (
            <View style={styles.orderTitle}>
              {order?.id ? (
                <Sticker
                  testID="sticker-counter"
                  type="accent"
                  label={extractCounter(orderNumber).toString()}
                  // eslint-disable-next-line react-native/no-inline-styles
                  containerStyle={{ marginRight: 12 }}
                />
              ) : null}
              <Text style={styles.orderTitleText}>{getOrderTitle()}</Text>
            </View>
          ) : null}
          <CartItems
            items={orderItemByPaid}
            rewardItems={rewardItems}
            courses={courses}
            isCoursesEnabled={isCoursesEnabled}
            readonlyCourse
            subTotal={order?.subTotal}
            orderGuestCount={order?.table?.guestCount}
            orderType={orderType}
            selectedSeatNumber={
              processingPaymentSeat ? String(processingPaymentSeat) : undefined
            }
            seats={occupiedSeatsWithDefaultTable}
            extraItems={sharedOrderItems}
            setSelectedSeatNumber={setSelectSeatNumber}
            onSelectItem={onSelectOrderItem}
            selectedItemIds={isSplitByProduct ? processingOrderItemIds : []}
            containerStyle={styles.cartItems}
          />
          {showSplitProductButton ? (
            <TreatButton
              testID="btn-splitProduct"
              type="focus"
              height={44}
              label={translate('payment.splitProduct')}
              onPress={onSplitProduct}
              containerStyle={styles.gap}
            />
          ) : null}
          {order && !isCustomerBalancePayment ? (
            <CartTotals
              order={order}
              isPaymentScreen
              containerStyle={styles.totals}
            />
          ) : null}
          {showTotalRemaining ? (
            <View style={styles.totalRemaining}>
              <CartTotalRow
                testID="total-remaining"
                major
                marginTop={0}
                name={translate('payment.totalRemaining')}
                value={remainingDueAmount || 0}
              />
            </View>
          ) : null}
          {accountDetails?.accountPayment ? (
            <AccountTotals
              creditLimit={accountDetails?.maxBalanceLimit || 0}
              orderLimit={accountDetails?.maxOrderLimit || 0}
              balance={accountDetails?.currentBalance || 0}
              containerStyle={styles.gap}
            />
          ) : null}
        </View>
        <View style={styles.contentContainer}>
          {isNewLoyaltyEnabled &&
            activeScreen === PaymentSubScreen.MAIN_SCREEN &&
            !assignedCustomer?.loyaltyMember && (
              <Gradient style={styles.btnEnrollContainer}>
                <TouchableOpacity
                  onPress={() =>
                    setShowEnrollLoyaltyModal(!isShowEnrollLoyaltyModal)
                  }
                  style={styles.btnEnroll}
                  testID="btn-enrollMember"
                >
                  <Text style={styles.btnText}>
                    {translate('payment.enrolMember')}
                  </Text>
                  <Icon name="arrow-right" size={20} />
                </TouchableOpacity>
              </Gradient>
            )}
          <View style={styles.content}>{renderSubPaymentScreen}</View>
        </View>
      </View>
      <ModalWrapper isVisible={isShowCompleteSaleModal}>
        <SaleCompleteModal
          sendReceipt={sendOrderReceipt}
          onPressPrintReceipt={onPressPrintReceipt}
          onPressNewSale={onCompleteSale}
          changeDue={
            (latestPayment?.tendered || 0) - (latestPayment?.amount || 0)
          }
          isSplitPayment={isSplitPayment}
          onPrintSplit={onPrintSplit}
          customer={assignedCustomer}
          isOnAccount={
            latestPayment?.paymentType?.name === ON_ACCOUNT_PAYMENT_TYPE
          }
          amount={order?.subTotal}
          loyaltyAction={loyaltyAction}
          earnedPointInfo={earnedPointInfo}
          isOrderOnHold={order?.status === OrderStatus.ON_HOLD}
          isLoyaltyEnabled={isLoyaltyEnabled}
          onHoldAction={
            <OnHoldOrderOptions
              order={order}
              updateCart={updateCart}
              openOrderCart={openOrderCart}
            />
          }
        />
      </ModalWrapper>
      <ModalWrapper isVisible={isShowRewardModal}>
        <ShowAvailableRewards
          pointsBalance={newBalance}
          pointsEarned={recentEarnedPoints}
          availableRewards={availableRewards}
          loyaltySettings={loyaltySettings}
          onDismiss={onDismissRewardModal}
        />
      </ModalWrapper>
      <ModalWrapper isVisible={isShowEnrollLoyaltyModal}>
        <EnrollCustomerModal
          onCloseModal={onCloseEnrollLoyaltyModal}
          onEnrollLoyalty={onEnrollToLoyaltyByPhone}
          loading={customerLoading}
          assignedCustomerInfo={{
            phone: assignedCustomer?.phoneNumber,
            firstName: assignedCustomer?.firstName,
          }}
        />
      </ModalWrapper>
    </ScreenLayout>
  );
};

export default PaymentScreen;
