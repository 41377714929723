/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import sortBy from 'lodash/sortBy';
import { Page } from '@oolio-group/domain';
import { TileItem } from '../../types';
import styles from './Modals.styles';
import theme from '../../../../../../common/default-theme';
import modalStyles from '../../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import SelectColour from '../../../../../../components/Shared/Select/SelectColour';

interface AddOrEditExistingPageProp {
  onAddExistingPage?: (input: {
    pageId: string;
    color: string;
    name: string;
  }) => void;
  onEditPage?: (input: TileItem) => void;
  pageMaps: Record<string, Page>;
  isAddToMenu?: boolean;
  assignPageIds?: string[];
  editingItem?: TileItem;
}

const AddOrEditExistingPage: React.FC<AddOrEditExistingPageProp> = ({
  onAddExistingPage,
  pageMaps,
  isAddToMenu,
  assignPageIds,
  onEditPage,
  editingItem,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const pageOptions = useMemo(() => {
    const sortedPages = sortBy(
      Object.values(pageMaps).filter(
        page =>
          page.id === editingItem?.entityId ||
          !assignPageIds?.includes(page.id),
      ),
      page => page?.name?.toLowerCase(),
    );
    return sortedPages.map(page => ({ label: page.name, value: page.id }));
  }, [assignPageIds, editingItem?.entityId, pageMaps]);

  const [selectedPageId, setSelectedPageId] = useState('');
  const [selectedColor, setSelectedColor] = useState<string>(theme.colors.teal);
  const onPressAddPage = useCallback(() => {
    closeModal();

    if (editingItem) {
      const updatedItem: TileItem = {
        ...editingItem,
        entityId: selectedPageId,
        color: selectedColor,
        name: pageMaps[selectedPageId].name,
      };
      return onEditPage && onEditPage(updatedItem);
    }
    onAddExistingPage &&
      onAddExistingPage({
        pageId: selectedPageId,
        color: selectedColor,
        name: pageMaps[selectedPageId]?.name,
      });
  }, [
    closeModal,
    editingItem,
    onAddExistingPage,
    onEditPage,
    pageMaps,
    selectedColor,
    selectedPageId,
  ]);

  const onChangeColor = useCallback((color: string) => {
    setSelectedColor(color);
  }, []);

  useEffect(() => {
    if (editingItem) {
      setSelectedPageId(editingItem.entityId as string);
      setSelectedColor(editingItem.color || theme.colors.teal);
    } else {
      setSelectedPageId(pageOptions?.[0]?.value);
    }
  }, [editingItem, pageOptions]);

  return (
    <View style={styles.background}>
      <View style={styles.container}>
        <View style={modalStyles.title}>
          <Text style={styles.titleText}>
            {translate(
              editingItem ? 'menus.editExistingPage' : 'menus.addExistingPage',
            )}
          </Text>
        </View>
        <View style={styles.content}>
          <TreatPicker
            testID="select-page"
            title={translate('menus.selectPage')}
            options={pageOptions}
            selectedValue={selectedPageId}
            onValueChange={setSelectedPageId}
            containerStyle={styles.input}
          />
          <SelectColour
            testID="select-colour"
            title={translate('productSettings.tileColor')}
            value={selectedColor}
            onChange={onChangeColor}
            containerStyle={styles.input}
          />
        </View>
        <View style={styles.actions}>
          <TreatButton
            type="neutral"
            testID="btn-confirm"
            onPress={onPressAddPage}
            label={translate(
              isAddToMenu ? 'menus.addPageToMenu' : 'menus.addPageToPage',
            )}
            containerStyle={{ marginBottom: 10 }}
          />
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
        </View>
      </View>
    </View>
  );
};

export default AddOrEditExistingPage;
