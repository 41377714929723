import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 16,
    paddingLeft: 24,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  lhs: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  rhs: {
    columnGap: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  breadcrumbs: {
    columnGap: 6,
    flexDirection: 'row',
    marginLeft: 16,
  },
  breadcrumbText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.regular,
  },
  btnPOS: {
    width: 90,
    height: 38,
    columnGap: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
  btnPOSText: {
    textTransform: 'uppercase',
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
