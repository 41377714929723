import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 40,
  },
  tableBody: {
    marginBottom: 20,
  },
  cellCode: {
    width: 80,
  },
  cellName: {
    width: 240,
    marginLeft: 10,
    marginRight: 'auto',
  },
  cellTypes: {
    flex: 1,
    marginHorizontal: 10,
  },
  cellChannel: {
    width: 294,
    marginRight: 10,
  },
  headerCode: {
    width: 68,
    marginRight: 10,
    textAlign: 'center',
  },
  headerName: {
    width: 240,
    marginHorizontal: 12,
  },
});

export default styles;
