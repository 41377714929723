import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  inputContainer: {
    height: 44,
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
    borderWidth: 1,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
  },
  inputTitleText: {
    marginBottom: 6,
    paddingLeft: 12,
    color: theme.colors.grey6,
  },
  accessory: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
