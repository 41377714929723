import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  filters: {
    height: 44,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchContainer: {
    flex: 1,
    marginRight: 10,
  },
  headerName: {
    width: 228,
  },
  headerPhone: {
    width: 130,
    textAlign: 'right',
  },
  cellName: {
    width: 240,
    paddingLeft: 12,
  },
  cellEmail: {
    flex: 1,
    paddingLeft: 10,
    alignItems: 'center',
  },
  cellPhone: {
    width: 140,
    textAlign: 'right',
    paddingRight: 10,
  },
  message: {
    width: 540,
    marginBottom: 20,
  },
  name: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    color: theme.colors.black1,
  },
  warning: {
    fontSize: 12,
    lineHeight: 16,
    marginTop: 4,
    fontFamily: theme.fonts.regular,
    color: theme.colors.states.negative,
  },
});

export default styles;
