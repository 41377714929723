import React from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import theme from '../../../common/default-theme';
import styles from './Counter.styles';
import Icon from '../../Icon/Icon';

type CounterButton = {
  disabled?: boolean;
  onPress: () => void;
};

interface CounterProps {
  value: number;
  disabled?: boolean;
  decrease: CounterButton;
  increase: CounterButton;
  containerStyles?: ViewStyle | ViewStyle[];
}

const Counter: React.FC<CounterProps> = ({
  value,
  disabled = false,
  decrease,
  increase,
  containerStyles,
}) => {
  const isDecreaseDisabled = decrease.disabled || disabled;
  const isIncreaseDisabled = increase.disabled || disabled;

  return (
    <View style={[styles.container, containerStyles]}>
      <TouchableOpacity
        testID="btn-decrease"
        onPress={decrease.onPress}
        disabled={isDecreaseDisabled}
        style={styles.button}
      >
        <Icon
          size={20}
          name="angle-down"
          color={isDecreaseDisabled ? theme.colors.grey4 : theme.colors.black1}
        />
      </TouchableOpacity>
      <Text testID="value-counter" style={styles.value}>
        {value}
      </Text>
      <TouchableOpacity
        testID="btn-increase"
        onPress={increase.onPress}
        disabled={isIncreaseDisabled}
        style={styles.button}
      >
        <Icon
          size={20}
          name="angle-up"
          color={isIncreaseDisabled ? theme.colors.grey4 : theme.colors.black1}
        />
      </TouchableOpacity>
    </View>
  );
};

export default Counter;
