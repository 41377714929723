import {
  Device,
  DeviceMode,
  DeviceProfile,
  Terminal,
  UpdateDeviceInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation, useRoute } from '@react-navigation/native';
import pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import InputToggle from '../../../../../../..//components/Shared/Inputs/InputToggle';
import theme from '../../../../../../../common/default-theme';
import ConfirmationDialog from '../../../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import { useNotification } from '../../../../../../../hooks/Notification';
import { useDeviceProfiles } from '../../../../../../../hooks/app/useDeviceProfiles';
import { useDevices } from '../../../../../../../hooks/app/useDevices';
import styles from '../../Devices.styles';
import { TokenIdentifiers } from './TokenIdentifiers';

export const DeviceDetails: React.FC = () => {
  const route = useRoute();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<Device>({} as Device);
  const navigation = useNavigation();
  const { showModal, closeModal } = useModal();

  const params = route.params as {
    storeId: string;
    deviceId: string;
  };

  const storeId = params.storeId;
  const deviceId = params.deviceId;

  const {
    devices,
    updateDevice,
    deleteDevice,
    resetDeviceCode,
    resetedDeviceCode,
    deletedDevice,
    updatedDeviceId,
    loading: devicesLoading,
    error: devicesError,
  } = useDevices({
    deviceId,
    storeId,
  });
  const {
    deviceProfiles,
    getAllDeviceProfiles,
    loading: registersLoading,
    error: registersError,
  } = useDeviceProfiles({
    storeId,
  });

  const loading = devicesLoading || registersLoading;
  const error = devicesError || registersError;

  useEffect(() => {
    if (deviceId && devices[deviceId]) {
      setForm(devices[deviceId]);
    }
  }, [devices, deviceId]);

  useEffect(() => {
    getAllDeviceProfiles();
  }, [getAllDeviceProfiles]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  // on change events
  const onChange = useCallback((prop: string, value: string): void => {
    if (prop === 'register') {
      setForm(form => ({
        ...form,
        deviceProfile: { id: value } as DeviceProfile,
      }));
      return;
    }
    if (prop === 'paymentTerminal') {
      setForm(form => ({
        ...form,
        paymentTerminal: { uuid: value } as Terminal,
      }));
      return;
    }
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onToggle = useCallback(
    (prop: string, value: boolean | undefined): void => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
    },
    [],
  );

  const onChangeIsActiveToken = useCallback((value: boolean) => {
    setForm(form => ({
      ...form,
      isTokenNumberActive: value as boolean,
    }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTokenSettings = useCallback((prop: string, value: any) => {
    if (prop === 'start' || prop === 'end') {
      setForm(form => ({
        ...form,
        tokenSettings: {
          ...form.tokenSettings,
          tokenRange: {
            ...(form?.tokenSettings?.tokenRange || {}),
            [prop]: value,
          },
        },
      }));
    } else {
      setForm(form => ({
        ...form,
        tokenSettings: {
          ...form.tokenSettings,
          [prop]: value,
        },
      }));
    }
  }, []);

  const onSave = useCallback((): void => {
    const input = pick(form, [
      'id',
      'name',
      'salesPrefix',
      'returnPrefix',
      'isTokenNumberActive',
      'tokenSettings',
    ]) as UpdateDeviceInput;

    const tokenSettings = pick(input?.tokenSettings || {}, [
      'onlyPrintTokenOnReceipts',
      'resetTokenNumberOnShiftClosure',
      'tokenRange',
    ]);

    if (tokenSettings && tokenSettings?.tokenRange) {
      const startRange = +(tokenSettings?.tokenRange?.start || '0');
      const endRange = +(tokenSettings?.tokenRange?.end || '0');

      if (startRange === 0 || endRange === 0) {
        showNotification({
          error: true,
          message: translate('backOfficeDevices.tokenRangeNotBeZero'),
        });
        return;
      }

      if (startRange > endRange) {
        showNotification({
          error: true,
          message: translate('backOfficeDevices.errorTokenRange'),
        });
        return;
      }

      input.tokenSettings = {
        ...tokenSettings,
        tokenRange: {
          start: startRange,
          end: endRange,
        },
      };
    }

    const validateObject = pick(input, [
      'id',
      'name',
      'salesPrefix',
      'returnPrefix',
    ]);

    if (Object.values(validateObject).some(field => !field)) {
      showNotification({
        error: true,
        message: translate('backOfficeDevices.fieldsMissing'),
      });
      return;
    }
    input.deviceProfile = form.deviceProfile?.id;
    input.cashDrawer = 'default';
    input.disablePolling = form.disablePolling;
    input.disableLocalComms = form.disableLocalComms;
    // in case of kiosk/full pos, set payment terminal from here
    if (form.mode === DeviceMode.M_POS) {
      input.poiId = form.poiId;
      input.useCloudPayment = form.useCloudPayment;
    } else {
      input.paymentTerminal = form.paymentTerminal?.uuid;
    }

    updateDevice(input as UpdateDeviceInput);
  }, [showNotification, translate, updateDevice, form]);

  useEffect(() => {
    if (updatedDeviceId) {
      showNotification({
        success: true,
        message: translate('backOfficeDevices.deviceUpdatedSuccessfully'),
      });
    }
  }, [updatedDeviceId, showNotification, translate]);

  useEffect(() => {
    if (deletedDevice) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: form?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'Devices',
      });
    }
  }, [deletedDevice, navigation, showNotification, form, translate]);

  useEffect(() => {
    if (resetedDeviceCode) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deviceCodeResetSuccessful', {
          name: form?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'Devices',
      });
    }
  }, [resetedDeviceCode, navigation, showNotification, form, translate]);

  const onPressDelete = useCallback(() => {
    const name = form?.name;
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', { label: name })}
        onConfirm={() => {
          closeModal();
          deleteDevice(form.id);
        }}
      />,
    );
  }, [form?.name, form.id, showModal, translate, closeModal, deleteDevice]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${form.name || 'Device'} | Oolio`}
      onSave={onSave}
      onDelete={onPressDelete}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('backOfficeDevices.deviceName')}
            value={form.name}
            placeholder={translate('backOfficeDevices.deviceName')}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-profile"
            title={translate('backOfficeDevices.register')}
            options={Object.values(deviceProfiles)
              .filter(deviceProfile => deviceProfile.mode === form.mode)
              .map(deviceProfile => ({
                value: deviceProfile.id,
                label: deviceProfile.name,
              }))}
            selectedValue={form.deviceProfile?.id}
            onValueChange={onChange.bind(null, 'register')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-salesPrefix"
            title={translate('backOfficeDevices.salesPrefix')}
            value={form.salesPrefix}
            placeholder={translate('backOfficeDevices.salesPrefix')}
            onChangeText={onChange.bind(null, 'salesPrefix')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-terminal"
            title={translate('backOfficeDevices.paymentTerminal')}
            value={
              form.mode === DeviceMode.M_POS
                ? form.poiId
                : form.paymentTerminal?.uuid
            }
            placeholder={'Enter terminal ID...'}
            onChangeText={onChange.bind(
              null,
              form.mode === DeviceMode.M_POS ? 'poiId' : 'paymentTerminal',
            )}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-enable-order-polling"
            title={translate('backOfficeDevices.disablePolling')}
            isToggled={!!form.disablePolling}
            onToggle={onToggle.bind(
              null,
              'disablePolling',
              !form.disablePolling,
            )}
            containerStyle={theme.forms.inputHalf}
          />
          {form.mode === DeviceMode.M_POS ? (
            <InputToggle
              testID="input-useCloudPayment"
              title={translate('backOfficeDeviceModal.useCloudPayment')}
              isToggled={form?.useCloudPayment ?? false}
              onToggle={(): void =>
                setForm(preForm => ({
                  ...preForm,
                  useCloudPayment: !preForm.useCloudPayment,
                }))
              }
            />
          ) : null}
        </View>

        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-enable-local-comms"
            title={translate('backOfficeDevices.disableLocalComms')}
            isToggled={!!form.disableLocalComms}
            onToggle={onToggle.bind(
              null,
              'disableLocalComms',
              !form.disableLocalComms,
            )}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        {form.mode === DeviceMode.KIOSK ? (
          <View style={theme.forms.row}>
            <InputText
              testID="input-refundPrefix"
              title={translate('backOfficeDevices.returnPrefix')}
              value={form.returnPrefix}
              placeholder={translate('backOfficeDevices.returnPrefix')}
              onChangeText={onChange.bind(null, 'returnPrefix')}
              containerStyle={theme.forms.inputHalf}
            />
            {/* <DropDown
                title={translate('backOfficeDevices.cashDrawer')}
                options={['Default'].map(cashDrawer => ({
                  value: cashDrawer,
                  label: cashDrawer,
                }))}
                selectedValue={device.cashDrawer}
                extraPopoverStyle={styles.popOverStyle}
                containerStyle={styles.dropdownContainerStyle}
                itemsContainerStyle={styles.dropDownItemsContainerStyle}
                onValueChange={onChange.bind(null, 'cashDrawer')}
                titleStyle={styles.dropDownLabelText}
              /> */}
          </View>
        ) : (
          <></>
        )}
      </Section>

      {form.mode === DeviceMode.F_POS ? (
        <TokenIdentifiers
          form={form}
          onChangeIsActiveToken={onChangeIsActiveToken}
          onChangeTokenSettings={onChangeTokenSettings}
        />
      ) : null}

      {form.isPaired || form.uuid ? (
        <Section title={translate('backOfficeDevices.deviceInfo')}>
          <View style={styles.infoSection}>
            <Text style={styles.infoTitle}>
              {translate('backOfficeDevices.details')}
            </Text>
            <Text>{form.details || 'Unknown'}</Text>
          </View>
          <View style={styles.infoSection}>
            <Text style={styles.infoTitle}>
              {translate('backOfficeDevices.uuid')}
            </Text>
            <Text>{form.uuid || 'Unknown'}</Text>
          </View>
          <View style={theme.forms.row}>
            <TouchableOpacity
              testID="btn-reset"
              onPress={() => resetDeviceCode(deviceId)}
            >
              <Text style={styles.btnUnpair}>
                {translate('backOfficeDevices.unPair')}
              </Text>
            </TouchableOpacity>
          </View>
        </Section>
      ) : (
        <></>
      )}
    </ScreenLayout>
  );
};
