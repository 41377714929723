import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 620,
    padding: 40,
    alignItems: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  errorMessage: {
    padding: 8,
    width: '100%',
    marginBottom: 20,
    textAlign: 'center',
    color: theme.colors.white,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.negative,
  },
  prompt: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 40,
  },
  usersContainer: {
    width: 540,
    height: 276,
  },
  usersContentContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  emptyUserContainer: {
    width: 120,
    height: 120,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  actions: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
  },
  clock: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  support: {
    lineHeight: 44,
    marginRight: 'auto',
    color: theme.colors.grey6,
  },
});

export default styles;
