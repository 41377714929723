import { gql } from '@apollo/client';

export const UPDATE_MODIFIER_PRICING_MUTATION = gql`
  mutation updateModifierPricings($input: [ModifierPricingInput]) {
    updateModifierPricings(input: $input) {
      id
    }
  }
`;

export const ADD_MODIFIER_PRICING_MUTATION = gql`
  mutation addModifierPricings(
    $modifierId: ID
    $pricings: [ModifierPricesInput]
  ) {
    addModifierPricings(modifierId: $modifierId, pricings: $pricings) {
      id
    }
  }
`;
