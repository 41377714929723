import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  btn: {
    flexGrow: 1,
    height: 30,
    maxWidth: 55,
    marginBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    borderWidth: 1,
    borderColor: theme.colors.grey3,
  },
  btnText: {
    fontSize: CART_FONT_SIZE,
    lineHeight: 20,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
  },
  btnAdd: {
    marginLeft: 4,
    borderWidth: 0,
    backgroundColor: theme.colors.greenLight,
  },
});

export default styles;
