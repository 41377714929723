import React from 'react';
import { Text, View, TouchableOpacity, ViewStyle } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import styles from './CreateButton.styles';

export interface CreateButtonProps {
  onPress?: () => void;
  containerStyles?: ViewStyle | ViewStyle[];
}

const CreateButton: React.FC<CreateButtonProps> = ({
  onPress,
  containerStyles,
}: CreateButtonProps) => {
  const { translate } = useTranslation();

  return (
    <View style={containerStyles}>
      <TouchableOpacity
        onPress={onPress}
        testID="create-button"
        style={styles.container}
      >
        <Text style={styles.text}>{translate('button.createNew')}</Text>
        <View style={styles.icon}>
          <Icon name="plus" size={20} color={theme.colors.white} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default CreateButton;
