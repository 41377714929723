import { BehaviorSubject, Observable } from 'rxjs';
import { App } from '@oolio-group/domain';
import { AuthState, TokenInfo } from '../constants';
import { setAuthenticationState } from './preferences';

export const DEFAULT_TOKEN_INFO = {
  token: undefined,
  refreshToken: undefined,
  expiresAfter: undefined,
  authState: AuthState.LOADING,
  activeApp: App.BACKOFFICE, // the default landed app
  backOfficeToken: undefined,
  posAppToken: undefined,
};

export class TokenUtility {
  private subject = new BehaviorSubject<TokenInfo>(DEFAULT_TOKEN_INFO);

  setTokenInfo(data: TokenInfo) {
    this.subject.next({ ...this.subject.value, ...data });
    setAuthenticationState({ ...this.subject.value, ...data });
  }

  clearToken() {
    this.subject.next({ ...DEFAULT_TOKEN_INFO, authState: AuthState.LOGOUT });
    setAuthenticationState({
      ...DEFAULT_TOKEN_INFO,
      authState: AuthState.LOGOUT,
    });
  }

  get getTokenInfo$(): Observable<TokenInfo> {
    return this.subject.asObservable();
  }
  get token(): string | undefined {
    return this.subject.value.token;
  }
  get idToken(): string | undefined {
    return this.subject.value.idToken;
  }
  get authState(): AuthState | undefined {
    return this.subject.value.authState;
  }
  get expiresAfter(): number | undefined {
    return this.subject.value.expiresAfter;
  }
}

export const tokenUtility = new TokenUtility();
