import { gql } from '@apollo/client';

export const GET_PRINTER_PROFILES_QUERY = gql`
  query printerProfiles(
    $filter: PrinterProfilesFilterInput
    $limit: Int
    $skip: Int
  ) {
    printerProfiles(filter: $filter, limit: $limit, skip: $skip) {
      id
      name
      printerProfileType
      locale
      orderTypes
      singleItemPrinting
    }
  }
`;

export const CREATE_PRINTER_PROFILES = gql`
  mutation createPrinterProfiles($input: [CreatePrinterProfileInput!]) {
    createPrinterProfiles(input: $input) {
      id
      name
      printerProfileType
      locale
      orderTypes
      singleItemPrinting
    }
  }
`;

export const UPDATE_PRINTER_PROFILES = gql`
  mutation updatePrinterProfiles($input: [UpdatePrinterProfileInput!]) {
    updatePrinterProfiles(input: $input) {
      id
      name
      printerProfileType
      locale
      orderTypes
      singleItemPrinting
    }
  }
`;

export const DELETE_PRINTER_PROFILE = gql`
  mutation deletePrinterProfile($id: ID!) {
    deletePrinterProfile(id: $id)
  }
`;
