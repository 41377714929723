import { gql } from '@apollo/client';

const PRODUCT_TYPE_FRAGMENT = `
id
name
createdAt
`;

export const GET_PRODUCT_TYPES_QUERY = gql`
  query getProductTypes {
    productTypes {
      ${PRODUCT_TYPE_FRAGMENT}
    }
  }
`;

export const CREATE_PRODUCT_TYPES = gql`
  mutation createProductTypes($input: [CreateProductTypeInput!]) {
    createProductTypes(input: $input) {
      ${PRODUCT_TYPE_FRAGMENT}
    }
  }
`;

export const UPDATE_PRODUCT_TYPES = gql`
  mutation updateProductTypes($input: [UpdateProductTypeInput!]) {
    updateProductTypes(input: $input) {
      ${PRODUCT_TYPE_FRAGMENT}
    }
  }
`;

export const DELETE_PRODUCT_TYPE = gql`
  mutation deleteProductType($id: ID!) {
    deleteProductType(id: $id)
  }
`;
