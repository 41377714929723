import {
  PrinterConfig,
  validatePrintConfigurationOrInput,
} from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintRecipeWorkerInput,
  WorkerInput,
} from '../../utils';
import { PrinterProfileType } from '@oolio-group/domain';
import { getRecipePrintableBuffer } from '../../../utils/printerTemplates/productRecipeLayout';

const printRecipeHandler = (message: WorkerInput): BufferHandlerResult[] => {
  const { product, printerTemplateMapping } =
    message.data as PrintRecipeWorkerInput;
  const productPrinterProfiles = product.printerProfiles?.map(p => p.id) ?? [];
  const mappedPrinterProfiles = productPrinterProfiles.reduce(
    (acc, printerProfileId) => {
      const profileId =
        printerTemplateMapping[PrinterProfileType.KITCHEN]?.[printerProfileId];
      return {
        ...acc,
        ...(profileId && {
          [printerProfileId]: profileId,
        }),
      };
    },
    {},
  );

  const data = validatePrintConfigurationOrInput(
    undefined,
    {
      ...printerTemplateMapping,
      [PrinterProfileType.KITCHEN]: mappedPrinterProfiles,
    },
    PrinterProfileType.KITCHEN,
  );

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const config = data as PrinterConfig[];
  const results: BufferHandlerResult[] = [];
  config.forEach(c => {
    const buffer = getRecipePrintableBuffer(product, c.printer.series);
    results.push({
      printer: {
        id: c.printer.id,
        device_name: c.printer.name,
        host: c.printer.ipAddress,
        port: DEFAULT_NET_PRINTER_PORT,
        type: c.printer.printerType,
        bdAddress: c.printer.bdAddress,
        slug: c.printer.slug,
        series: c.printer.series,
        brand: c.printer.brand,
      },
      buffer: buffer,
    });
  });

  return results;
};
export default printRecipeHandler;
