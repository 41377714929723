import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
    justifyContent: 'center',
  },
  container: {
    width: 540,
    alignSelf: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.m,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 40,
    marginTop: 40,
  },
  allergensSection: {
    width: 290,
  },
  productImage: {
    minHeight: 150,
    width: 150,
    borderRadius: theme.radius.s,
    marginLeft: 10,
  },
  productImagePlaceholder: {
    minHeight: 150,
    width: 150,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  productName: {
    fontFamily: theme.fonts.semibold,
    fontSize: theme.fontSize.m,
    lineHeight: 22,
  },
  allergensName: {
    fontFamily: theme.fonts.medium,
    marginTop: 10,
    fontSize: theme.fontSize.s,
    color: theme.colors.red,
    lineHeight: 20,
  },
  dietaryTagName: {
    fontFamily: theme.fonts.medium,
    marginTop: 6,
    color: theme.colors.grey6,
    lineHeight: 20,
  },
  nutrientName: {
    fontFamily: theme.fonts.medium,
    marginTop: 6,
    color: theme.colors.grey6,
  },
  allergensInfo: {
    marginHorizontal: 40,
    marginTop: 20,
  },
  textName: {
    color: theme.colors.grey6,
  },
  textInfo: {
    marginTop: 6,
    lineHeight: 20,
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 60,
    marginTop: 30,
    marginRight: 20,
  },
  productCountContainer: {
    position: 'absolute',
    zIndex: 5,
    top: 10,
    right: 10,
    borderRadius: 20,
    backgroundColor: theme.colors.blue,
  },
  productCountText: {
    minWidth: 50,
    minHeight: 30,
    color: theme.colors.white,
    fontSize: theme.fontSize.l,
    textAlign: 'center',
    paddingTop: 3,
  },
});

export default styles;
