/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StyleFn } from '@oolio-group/domain';
import { RenderProps } from '@oolio-group/domain/dist';
import { useDimensions } from '@react-native-community/hooks';
import React from 'react';
import { FelaComponent, useFela, withTheme } from 'react-fela';
import { FlatList, ViewStyle, TextStyle } from 'react-native';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';

export interface KeyButton {
  name?: string;
  danger?: boolean;
  success?: boolean;
  onPress?: Function;
  containerStyle: ViewStyle;
  value?: string;
  iconColor?: string;
  width?: number;
  disabled?: boolean;
}

export interface NumberInputProps {
  onPressKey: Function;
  onPressClear?: Function;
  onSubmit?: Function;
  decimal?: boolean;
  testID?: string;
  containerStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  keys?: KeyButton[];
  keyWidth?: number;
  keyHeight?: number;
  twistKeyWidth?: number;
  rowStyle?: ViewStyle;
  buttonStyle?: ViewStyle;
  btnTextStyle?: TextStyle;
  pin?: string;
}

const keypadRowStyle: StyleFn = () => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '2%',
  marginHorizontal: 0,
});

const keyButtonStyle: StyleFn = ({ theme, keyWidth, height, width }) => ({
  height: height || keyWidth,
  width: width || keyWidth,
  marginHorizontal: 0,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.greyLight,
});

const defaultKeyArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
});

interface NumberKeyProps {
  value?: string;
  testID?: string;
  onPressKey: Function;
  keyWidth?: number;
  stretchKey?: boolean;
  height: number;
  width: number;
  labelStyle?: TextStyle | object;
  buttonStyle?: ViewStyle;
}

export const NumberKey: React.FC<NumberKeyProps> = props => (
  <FelaComponent style={keyButtonStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => {
      return (
        <Button
          keypad
          title={props.value}
          testID={props.value}
          onPress={(): void => props.onPressKey(props.value)}
          containerStyle={[style, props.buttonStyle]}
          labelStyle={props.labelStyle}
        />
      );
    }}
  </FelaComponent>
);

const NumberInput: React.FC<NumberInputProps> = ({
  onPressKey,
  onSubmit,
  testID,
  containerStyle,
  contentContainerStyle,
  keys,
  keyWidth,
  twistKeyWidth,
  keyHeight,
  rowStyle,
  buttonStyle,
  btnTextStyle,
  pin,
}: NumberInputProps) => {
  const windowHeight = useDimensions().window.height;
  const keyWidthComputed = keyWidth || windowHeight * 0.084;
  const { css, theme } = useFela({
    keyWidth: keyWidthComputed,
    height: keyHeight,
  });

  const defaultKeys = [
    { value: '1' } as KeyButton,
    { value: '2' } as KeyButton,
    { value: '3' } as KeyButton,
    { value: '4' } as KeyButton,
    { value: '5' } as KeyButton,
    { value: '6' } as KeyButton,
    { value: '7' } as KeyButton,
    { value: '8' } as KeyButton,
    { value: '9' } as KeyButton,
    { value: '0' } as KeyButton,
    {
      name: 'arrow-right',
      onPress: onSubmit,
      containerStyle: defaultKeyArrowStyle,
      iconColor: theme.colors.success,
    } as unknown as KeyButton,
  ];
  return (
    <FlatList
      style={containerStyle}
      contentContainerStyle={contentContainerStyle}
      testID={testID}
      numColumns={3}
      scrollEnabled={false}
      columnWrapperStyle={[css(keypadRowStyle), rowStyle]}
      data={keys || defaultKeys}
      renderItem={({ item: key, index }) =>
        key.value ? (
          <NumberKey
            key={index}
            value={key.value}
            testID={key.value}
            height={keyHeight || keyWidthComputed}
            width={
              key.width ||
              ((keys || defaultKeys).length % 3 === 2 &&
              index === (keys || defaultKeys).length - 2
                ? keyWidthComputed * (twistKeyWidth || 2.4)
                : keyWidthComputed)
            }
            onPressKey={(): void => onPressKey(key.value)}
            labelStyle={[css(btnTextStyle), btnTextStyle]}
            buttonStyle={buttonStyle}
          />
        ) : (
          <IconButton
            danger={key.danger}
            success={key.success}
            testID={key.name}
            icon={key.name || ''}
            iconSize={20}
            iconColor={key.iconColor}
            onPress={() => key.onPress && key.onPress(pin)}
            containerStyle={css(keyButtonStyle, key.containerStyle)}
            disabled={key.disabled}
          />
        )
      }
    />
  );
};

export default withTheme(NumberInput);
