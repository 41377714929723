/* eslint-disable react-native/no-inline-styles */
import React, { useMemo, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { Product } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import {
  keyBy,
  sortBy,
  concat,
  intersectionBy,
  differenceBy,
  pullAllBy,
  uniqBy,
  debounce,
} from 'lodash';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { pagesWithProductNameFragment } from '../../../../../hooks/app/pages/graphql';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import styles from '../Settings.styles';
import theme from '../../../../../common/default-theme';
import modalStyles from '../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import SelectSearch, {
  OptionsType,
} from '../../../../../components/Shared/Select/SelectSearch';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

enum SelectedItemType {
  Product = 'Product',
  Page = 'Page',
}

interface AssignedProduct {
  id: string;
  name: string;
}

interface SelectProductsModalProps {
  onSubmit: (selectedProducts: Product[]) => void;
  onBack: (selectedProducts: Product[]) => void;
  selectedProducts?: Product[];
  action: string;
}

const SelectProductsModal: React.FC<SelectProductsModalProps> = ({
  onSubmit,
  onBack,
  selectedProducts,
  action,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [assignedProducts, setAssignedProducts] = useState<Product[]>(
    selectedProducts || [],
  );
  const { products: productsMap, getProductsByName } = useProducts();
  const { pages: pageMaps, getPagesByName } = usePages(
    pagesWithProductNameFragment,
  );

  const onSearchChange = useMemo(
    () =>
      debounce((name: string) => {
        if (name.length >= 3) {
          getPagesByName(name);
          getProductsByName(name);
        }
      }, 300),
    [getPagesByName, getProductsByName],
  );

  const allPages = useMemo(() => {
    const pages = Object.values(pageMaps);
    if (!pages) return [];
    return pages.map(page => {
      const productOfVariant =
        page.variants?.reduce((allProducts, variant) => {
          if (variant.products) return [...allProducts, ...variant.products];
          return allProducts;
        }, [] as Product[]) || [];
      return {
        ...page,
        products: [...(page.products || []), ...productOfVariant],
      };
    });
  }, [pageMaps]);

  const pagesAndProducts = useMemo(() => {
    const productItems: OptionsType[] = Object.values(productsMap).map(
      product => ({
        type: SelectedItemType.Product,
        value: product.id,
        title: product.name,
        subtitle: translate('backOfficeProductsSummary.productName'),
      }),
    );

    const pageItems: OptionsType[] = allPages.map(page => {
      return {
        type: SelectedItemType.Page,
        value: page.id,
        title: page.name,
        subtitle: translate('backOfficeProducts.page'),
      };
    });
    return [...productItems, ...pageItems];
  }, [productsMap, allPages, translate]);

  const assignedProductIds = useMemo(
    () => assignedProducts.map(product => product.id),
    [assignedProducts],
  );

  const handleSubmit = () => {
    if (!assignedProducts.length) {
      showNotification({
        message: translate('backOfficeLoyalty.atLeastOneProduct'),
        error: true,
      });
      return;
    }
    onSubmit(assignedProducts);
  };

  const handleAssignedProducts = (_: string[], item: OptionsType) => {
    const selectedId = item.value;
    if (item.type === SelectedItemType.Product) {
      setAssignedProducts(preProducts => {
        if (assignedProductIds.includes(selectedId))
          return preProducts.filter(product => product.id !== selectedId);
        return [...preProducts].concat({
          id: selectedId,
          name: item.title,
        } as Product);
      });
    } else {
      const allPagesMaps = keyBy(allPages, 'id');
      const productsInPage = allPagesMaps[selectedId]?.products || [];

      setAssignedProducts(preProducts => {
        const updatedProducts = [...preProducts];
        const commonProducts = intersectionBy(
          updatedProducts,
          productsInPage,
          'id',
        );
        if (commonProducts.length === productsInPage.length) {
          pullAllBy(updatedProducts, productsInPage, 'id');
          return uniqBy(updatedProducts, 'id');
        }
        const notAssignedProducts = differenceBy(
          productsInPage,
          commonProducts,
          'id',
        );
        return uniqBy(concat(updatedProducts, notAssignedProducts), 'id');
      });
    }
  };

  const handleDeleteItem = (item: AssignedProduct) => {
    setAssignedProducts(preProducts => {
      return preProducts.filter(product => product.id !== item.id);
    });
  };

  const selectedValues = useMemo<string[]>(() => {
    const results: string[] = [];
    const allPagesMaps = keyBy(allPages, 'id');
    pagesAndProducts.forEach(item => {
      const { value: itemId, type } = item;
      if (type === SelectedItemType.Product) {
        assignedProductIds.includes(itemId) && results.push(itemId);
      } else {
        const productsInPage = (allPagesMaps[itemId]?.products || []).map(
          product => product.id,
        );
        const isAllProductsAssigned = productsInPage.every(productId =>
          assignedProductIds.includes(productId),
        );
        if (!productsInPage.length || !isAllProductsAssigned) return;
        results.push(itemId);
      }
    });
    return results;
  }, [allPages, assignedProductIds, pagesAndProducts]);

  const sortedAssignedProducts = useMemo<AssignedProduct[]>(() => {
    return sortBy(assignedProducts, product => product.name);
  }, [assignedProducts]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <View testID="modal" style={styles.modal}>
        <View style={modalStyles.title}>
          <Text
            testID="mdl-title"
            style={[modalStyles.titleText, { color: theme.colors.blue }]}
          >{`${action} - ${translate(
            'backOfficeLoyalty.selectProducts',
          )}`}</Text>
        </View>
        <View style={styles.modalContent}>
          <SelectSearch
            testID="select-addProduct"
            options={pagesAndProducts}
            selectedOptions={selectedValues}
            onChangeOption={handleAssignedProducts}
            onSearchChange={onSearchChange}
            placeholder={translate('backOfficeLoyalty.productsPlaceholder')}
            containerStyle={{ marginBottom: 20 }}
          />
          <View style={styles.modalTable}>
            <View style={theme.tables.header}>
              <Text style={theme.tables.headerText}>
                {translate('backOfficeProducts.products')}
              </Text>
            </View>
            <ScrollView style={styles.modalTableBody}>
              {sortedAssignedProducts.length > 0 ? (
                sortedAssignedProducts?.map(
                  (product: AssignedProduct, i: number) => (
                    <View
                      key={i}
                      testID="row-product"
                      style={[theme.tables.row, styles.rowProduct]}
                    >
                      <Text>{product.name}</Text>
                      <ButtonIcon
                        testID="btn-remove"
                        icon="times"
                        type="negativeLight"
                        onPress={() => handleDeleteItem(product)}
                      />
                    </View>
                  ),
                )
              ) : (
                <View style={theme.tables.emptyView}>
                  <Text testID="empty-table" style={theme.tables.emptyText}>
                    {translate('backOfficeLoyalty.emptyProducts')}
                  </Text>
                </View>
              )}
            </ScrollView>
          </View>
        </View>
        <View style={modalStyles.actions}>
          <TreatButton
            testID="btn-back"
            label={translate('button.back')}
            type="cancel"
            onPress={() => onBack(assignedProducts)}
          />
          <TreatButton
            testID="btn-confirm"
            label={action}
            type="neutral"
            onPress={handleSubmit}
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default SelectProductsModal;
