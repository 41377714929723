import { useModal } from '@oolio-group/rn-use-modal';
import { useEffect, useRef, useState, useCallback } from 'react';

export interface ChainItem<T> {
  action: (
    param: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect: (...arg: any[]) => void,
    onBack: () => void,
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformSubmitParamToState: (...arg: any[]) => T;
}

function useChainModal<T>(
  chain: ChainItem<T>[],
  onFinish: (param: T) => void,
  initialParam: T,
) {
  const currentState = useRef<T>(initialParam);
  const { closeModal } = useModal();
  const [currentIndex, setIndex] = useState<number>(-1);

  useEffect(() => {
    if (currentIndex >= 0) {
      if (currentIndex < chain.length) {
        chain[currentIndex].action(
          currentState.current,
          (...arg) => {
            const newState = chain[currentIndex].transformSubmitParamToState(
              ...arg,
            );
            currentState.current = { ...currentState.current, ...newState };
            setIndex(i => i + 1);
          },
          () => {
            closeModal();
            setIndex(i => i - 1);
          },
        );
      } else {
        onFinish(currentState.current);
      }
    }
    // trigger the effect only when currentIndex change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return useCallback(() => {
    setIndex(0);
  }, []);
}

export default useChainModal;
