import React, { useEffect } from 'react';
import { useNotification } from '../../hooks/Notification';
import { useSession } from '../../hooks/app/useSession';
import useBehaviorSubjectState from '../../hooks/app/useSubjectState';
import { lastActiveTimeSubject } from '../../state/lastActiveTime';
import { useWorkLogEvents } from '../../hooks/app/worklog/useWorkLogEvents';

export function WorklogEvent() {
  const [session] = useSession();
  const { showNotification } = useNotification();
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );
  const { error: worklogSubscriptionError } = useWorkLogEvents({
    currentStoreId: session?.currentStore?.id ?? '',
    deviceId: session?.device?.id ?? '',
    lastActiveTime: lastActiveTime ?? 0,
  });

  useEffect((): void => {
    if (worklogSubscriptionError) {
      showNotification({
        error: true,
        message: worklogSubscriptionError,
      });
    }
  }, [worklogSubscriptionError, showNotification]);

  return <></>;
}
