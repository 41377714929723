/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Adjustment, DineInSettings } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../../../../hooks/Notification';
import SelectMultiple, {
  SelectMultipleOption,
} from '../../../../../../../../components/Shared/Select/SelectMultiple';
import TreatModal from '../../../../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../../../../components/Shared/Inputs/InputToggle';

interface DineInModalProps {
  dineInSettings?: DineInSettings;
  onCancel?: () => void;
  onConfirm?: (settingName: string, settings: DineInSettings) => void;
  adjustments?: Adjustment[];
}

const DineInModal = ({
  dineInSettings,
  onCancel,
  onConfirm,
  adjustments,
}: DineInModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [dineInData, setDineInData] = useState<DineInSettings>(
    dineInSettings as DineInSettings,
  );

  const errorValidation = useCallback(() => {
    return (
      (dineInData &&
        !dineInData.enablePayLater &&
        !dineInData.enablePayNow &&
        translate('backOfficeFeatures.noAllowedPayment')) ||
      undefined
    );
  }, [dineInData, translate]);

  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [onCancel, closeModal]);

  const onPressConfirm = useCallback(() => {
    const error = errorValidation();
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
      return;
    }
    closeModal();
    dineInData && onConfirm && onConfirm('dineInSettings', dineInData);
  }, [closeModal, dineInData, errorValidation, onConfirm, showNotification]);

  const onValueUpdate = useCallback(
    (key: string, value: unknown) => {
      const formattedValue =
        key === 'adjustments'
          ? adjustments?.filter(adjustment =>
              (value as string[]).includes(adjustment.id as string),
            )
          : value;
      setDineInData(settingData => ({
        ...settingData,
        [key]: formattedValue,
      }));
    },
    [adjustments],
  );

  useEffect(() => {
    dineInSettings && setDineInData(dineInSettings);
  }, [dineInSettings]);

  const adjustmentsOptions = useMemo(() => {
    return adjustments?.map(adjustment => {
      return {
        value: adjustment.id,
        label: adjustment.name,
      } as SelectMultipleOption;
    });
  }, [adjustments]);

  return (
    <TreatModal
      title={translate('backOfficeFeatures.orderTypesSettings', {
        orderType: translate('backOfficeFeatures.dineIn'),
      })}
      onConfirm={{ action: onPressConfirm }}
      onDismiss={{ action: onPressCancel }}
    >
      <SelectMultiple
        testID="select-adjustments"
        title={translate('backOfficeFeatures.adjustments')}
        options={adjustmentsOptions || []}
        selectedValues={
          dineInData?.adjustments?.map(
            (adjustment: Adjustment) => adjustment.id,
          ) as string[]
        }
        onValueChange={values => onValueUpdate('adjustments', values)}
        containerStyle={{ marginBottom: 20 }}
      />
      <InputToggle
        testID="toggle-payLater"
        title={translate('backOfficeFeatures.allowPayLater')}
        isToggled={Boolean(dineInData?.enablePayLater)}
        onToggle={() =>
          onValueUpdate('enablePayLater', !dineInData?.enablePayLater)
        }
        containerStyle={{ marginBottom: 4 }}
      />
      <InputToggle
        testID="toggle-payNow"
        title={translate('backOfficeFeatures.allowPayNow')}
        isToggled={Boolean(dineInData?.enablePayNow)}
        onToggle={() =>
          onValueUpdate('enablePayNow', !dineInData?.enablePayNow)
        }
      />
    </TreatModal>
  );
};

export default DineInModal;
