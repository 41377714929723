import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: theme.layoutWidth.s,
    height: 392,
    alignSelf: 'center',
    alignItems: 'center',
    padding: 40,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
  },
  innerContainer: {
    width: 460,
    height: 242,
    alignSelf: 'center',
    alignItems: 'center',
    padding: 30,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.blueLightest,
  },
  titleText: {
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    fontFamily: theme.fonts.bold,
    marginBottom: 20,
    color: theme.colors.states.neutral,
  },
  subtitleText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  actions: {
    width: '100%',
    backgroundColor: theme.colors.white,
    marginTop: 26,
  },
  accessory: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    borderRadius: 30,
    borderWidth: 4,
    borderColor: theme.colors.states.neutral,
    height: 60,
    width: 60,
  },
});

export default styles;
