import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { View } from 'react-native';
import {
  UpdateCatalogueInput,
  STANDARD_MENU,
  DEFAULT_ENTITY_ID,
  CatalogueItemInput,
  Catalogue,
  PageMaps,
  Colors,
  Venue,
  Page,
} from '@oolio-group/domain';
import { useNotification } from '../../../../../hooks/Notification';
import { useLocalization, useTranslation } from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { v4 as uuidV4 } from 'uuid';
import { differenceWith, isEqual, maxBy, omit, sortBy } from 'lodash';
import { CATALOGUE_TYPE_OPTIONS } from '../../../../../constants';
import { useModal } from '@oolio-group/rn-use-modal';
import { getLocaleEntity } from '@oolio-group/client-utils';
import theme from '../../../../../common/default-theme';
import DraggableFlatList from 'react-native-draggable-flatlist';
import CatalogueItemRow from './CatalogueItemRow';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import SelectMultiple, {
  SelectMultipleOption,
} from '../../../../../components/Shared/Select/SelectMultiple';
import SelectSearch, {
  OptionsType,
} from '../../../../../components/Shared/Select/SelectSearch';

interface MenuDetailProp {
  menu?: Catalogue;
  pageMaps: PageMaps;
  loading: boolean;
  venues: Venue[];
  updateMenu: (updateMenuInput: UpdateCatalogueInput) => Promise<void>;
  deleteMenu: (menuId: string) => Promise<boolean | undefined>;
}

const MenuDetail: React.FC<MenuDetailProp> = ({
  menu: menuProp,
  pageMaps,
  loading,
  updateMenu,
  deleteMenu,
  venues,
}) => {
  const { translate } = useTranslation();
  const params = useRoute().params as { menuId: string; name: string };
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { showModal, closeModal } = useModal();
  const [searchPageValue, setSearchPageValue] = useState('');
  const [menuData, setMenuData] = useState<UpdateCatalogueInput>({
    id: '',
    name: '',
    venues: [],
    stores: [],
  });
  const originalMenuData = useRef<UpdateCatalogueInput>();
  const { locale } = useLocalization();

  const [assignedCatalogueItems, setAssignedCatalogueItems] = useState<
    CatalogueItemInput[]
  >([]);
  const removeItemIdsRef = useRef<string[]>([]);

  const originalAssignedCatalogueItems = useRef<CatalogueItemInput[]>([]);

  const venueOptions = useMemo(() => {
    return venues.map(x => ({ label: x.name, value: x.id }));
  }, [venues]);

  const getAvailableStoreOptions = useCallback(
    (selectedVenueIds: string[]): SelectMultipleOption[] => {
      return venues
        .filter(venue => selectedVenueIds.includes(venue.id as never))
        .map(selectedVenue =>
          selectedVenue.stores.map(store => ({
            value: store.id,
            label: store.name,
          })),
        )
        .flat();
    },
    [venues],
  );

  useEffect(() => {
    if (params.name) {
      navigation.setOptions({
        tabBarLabel: params.name,
      });
    }
  }, [navigation, params.name]);

  const isStandardMenu = useMemo(
    () => menuProp?.name === STANDARD_MENU,
    [menuProp?.name],
  );

  const pagesOptions = useMemo(() => {
    if (!pageMaps) return [];
    const pageOptionValues = Object.values(pageMaps || {}).map(
      (page: Page) => ({
        value: page.id,
        title: getLocaleEntity(page, locale.languageTag).name,
      }),
    );

    const isSearchPageExist = pageOptionValues.find(
      option => option.title?.toLowerCase() === searchPageValue?.toLowerCase(),
    );

    if (isSearchPageExist) return pageOptionValues;
    return [
      {
        value: DEFAULT_ENTITY_ID,
        title: translate('menus.createNewPagePlaceHolder', {
          pageName: searchPageValue,
        }),
      },
    ].concat(pageOptionValues);
  }, [locale.languageTag, pageMaps, searchPageValue, translate]);

  const onChangeName = useCallback((name: string) => {
    setMenuData(prev => ({ ...prev, name }));
  }, []);

  const addOrUpdateCatalogueItems = useMemo(
    () =>
      differenceWith(
        assignedCatalogueItems,
        originalAssignedCatalogueItems.current,
        isEqual,
      ).map(item => omit(item, ['__typename'])) as CatalogueItemInput[],
    [assignedCatalogueItems],
  );

  const onSaveCatalogue = useCallback(() => {
    if (!menuData?.name?.trim()) {
      showNotification({
        error: true,
        message: translate('menus.pleaseEnterMenuName'),
      });
    }

    updateMenu({
      ...menuData,
      addOrUpdateCatalogueItems,
      removeItemIds: removeItemIdsRef.current,
    });
  }, [
    addOrUpdateCatalogueItems,
    menuData,
    showNotification,
    translate,
    updateMenu,
  ]);

  const onChangeVenue = useCallback(
    selectedVenueIds => {
      setMenuData(prev => ({
        ...prev,
        venues: selectedVenueIds,
        stores: getAvailableStoreOptions(selectedVenueIds).map(
          option => option.value,
        ),
      }));
    },
    [getAvailableStoreOptions],
  );

  const onChangeStore = useCallback(selectedStoreIds => {
    setMenuData(prev => ({ ...prev, stores: selectedStoreIds }));
  }, []);

  const onSelectCatalogueItem = useCallback(
    (itemOption: OptionsType, isSelected: boolean) => {
      setSearchPageValue('');
      if (itemOption.value === DEFAULT_ENTITY_ID) {
        return navigation.navigate('PageDetail', {
          pageId: DEFAULT_ENTITY_ID,
          name: searchPageValue,
          menuId: menuProp?.id,
        });
      }

      setAssignedCatalogueItems(preItems => {
        if (isSelected)
          return preItems?.filter(item => item.page !== itemOption.value);

        const maxPriority =
          maxBy(preItems, item => item.priority)?.priority ?? -1;

        const pageId = itemOption.value;

        return preItems?.concat({
          id: uuidV4(),
          page: pageId,
          priority: maxPriority + 1,
          color: pageMaps?.[pageId]?.color || Colors.teal,
        });
      });
    },
    [menuProp?.id, navigation, pageMaps, searchPageValue],
  );

  const onDragEndCatalogueItem = useCallback((data: CatalogueItemInput[]) => {
    setAssignedCatalogueItems(
      data.map((pageRow, index) => ({ ...pageRow, priority: index })),
    );
  }, []);

  const onConfirmDeleteCatalogueItem = useCallback(
    (catalogueItemId: string) => {
      closeModal();
      removeItemIdsRef.current =
        removeItemIdsRef.current.concat(catalogueItemId);

      setAssignedCatalogueItems(preItems =>
        preItems.filter(item => item.id !== catalogueItemId),
      );
    },
    [closeModal],
  );

  const onDeleteCatalogueItem = useCallback(
    (catalogueItemId: string, pageId: string) => {
      showModal(
        <ConfirmationModal
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: pageMaps?.[pageId]?.name,
          })}
          onConfirm={onConfirmDeleteCatalogueItem.bind(null, catalogueItemId)}
        />,
      );
    },
    [onConfirmDeleteCatalogueItem, pageMaps, showModal, translate],
  );

  const onEditCatalogueItem = useCallback(
    (pageId: string) => {
      return navigation.navigate('PageDetail', {
        pageId,
        name: pageMaps?.[pageId]?.name,
      });
    },
    [navigation, pageMaps],
  );

  const onConfirmDeleteMenu = useCallback(
    async menuId => {
      closeModal();
      const response = await deleteMenu(menuId);
      if (response) {
        navigation.goBack();
      }
    },
    [closeModal, deleteMenu, navigation],
  );

  const handleDeleteMenu = useCallback(
    async (menuId: string) => {
      showModal(
        <ConfirmationModal
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: menuProp?.name,
          })}
          onCancel={closeModal}
          onConfirm={onConfirmDeleteMenu.bind(null, menuId)}
        />,
      );
    },
    [closeModal, menuProp?.name, onConfirmDeleteMenu, showModal, translate],
  );

  const menuTypeValue = useMemo(() => {
    return CATALOGUE_TYPE_OPTIONS.find(
      type => type.value === menuProp?.catalogueType,
    )?.label;
  }, [menuProp?.catalogueType]);

  const hasDataChanged = Boolean(
    addOrUpdateCatalogueItems.length ||
      removeItemIdsRef.current.length ||
      !isEqual(menuData, originalMenuData.current),
  );

  useEffect(() => {
    if (!menuProp) return;
    const updateData = {
      name: menuProp.name,
      id: menuProp.id,
      stores: menuProp?.stores?.map(store => store.id),
      venues: menuProp?.venues?.map(venue => venue.id),
    };
    setMenuData(updateData);
    originalMenuData.current = updateData;
    const validMenuItems = menuProp.items.filter(item => item.page);
    const sortedCatalogueItems = sortBy(
      validMenuItems,
      item => item.priority,
    ).map(item => ({ ...item, page: item?.page?.id }));
    setAssignedCatalogueItems(sortedCatalogueItems);
    originalAssignedCatalogueItems.current = sortedCatalogueItems;
    removeItemIdsRef.current = [];
  }, [menuProp]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${menuData.name || 'Menu'} | Oolio`}
      onSave={onSaveCatalogue}
      hideSaveButton={!hasDataChanged}
      onDelete={handleDeleteMenu.bind(null, menuProp?.id as string)}
      onDeleteDisabled={isStandardMenu}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('menus.menuName')}
            value={menuData.name}
            placeholder={translate('menus.menuName')}
            onChangeText={(!isStandardMenu && onChangeName) || undefined}
            containerStyle={theme.forms.inputHalf}
            editable={!isStandardMenu}
          />
          <InputText
            editable={false}
            testID="input-type"
            title={translate('menus.menuType')}
            value={translate(menuTypeValue)}
            placeholder={translate(menuTypeValue)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-venues"
            title={translate('menus.venues')}
            options={venueOptions}
            selectedValues={menuData.venues}
            onValueChange={onChangeVenue}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-stores"
            title={translate('menus.stores')}
            options={getAvailableStoreOptions(menuData.venues as string[])}
            selectedValues={menuData.stores}
            onValueChange={onChangeStore}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      <Section title={translate('menus.addPageToMenu')}>
        <SelectSearch
          testID="search-pages"
          options={pagesOptions}
          selectedOptions={assignedCatalogueItems.map(item => item.page)}
          onSearchChange={setSearchPageValue}
          onChangeOption={(s, e) => {
            const isSelected = Boolean(
              assignedCatalogueItems?.find(
                catalogueItem => catalogueItem.page === e.value,
              ),
            );
            onSelectCatalogueItem(e, isSelected);
          }}
          placeholder={translate(
            'backOfficeProducts.searchProductsPagesByName',
          )}
        />
        {assignedCatalogueItems.length > 0 ? (
          <DraggableFlatList
            data={assignedCatalogueItems}
            renderItem={params => {
              const { item, drag } = params;
              const { page: pageId, id } = item;
              return (
                <CatalogueItemRow
                  id={id}
                  name={
                    getLocaleEntity(pageMaps?.[pageId], locale.languageTag)
                      .name as string
                  }
                  pageId={pageId}
                  drag={drag}
                  onDeleteItem={onDeleteCatalogueItem}
                  onEditItem={onEditCatalogueItem}
                />
              );
            }}
            keyExtractor={item => item.id}
            onDragEnd={({ data }) => onDragEndCatalogueItem(data)}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ zIndex: -1 }}
          />
        ) : (
          <></>
        )}
      </Section>
    </ScreenLayout>
  );
};

export default MenuDetail;
