import React, { useMemo, useState } from 'react';
import { Organization } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import theme from '../../../common/default-theme';
import styles from './Transition.styles';
import Icon from '../../../components/Icon/Icon';
import Search from '../../../components/Shared/Search/Search';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';

interface Props {
  loading?: boolean;
  organizations: Pick<Organization, 'id' | 'name'>[];
  onSelect?: (org: Pick<Organization, 'id' | 'name'>) => void;
}

const OrganizationsList: React.FC<Props> = ({
  loading = false,
  organizations,
  onSelect = () => undefined,
}) => {
  const { translate } = useTranslation();

  const [searchText, setSearchText] = useState('');

  const filteredOrgs = useMemo(() => {
    return organizations.filter(org => {
      if (org.name.toLowerCase().includes(searchText.toLowerCase()))
        return true;
    });
  }, [searchText, organizations]);

  return (
    <View>
      <View style={styles.filters}>
        <Search
          testID="search"
          maxLength={50}
          onChangeText={setSearchText}
          placeholder={translate('transition.search', {
            entity: translate('transition.organisations').toLowerCase(),
          })}
          containerStyle={styles.search}
        />
      </View>
      {loading ? (
        <View style={theme.tables.emptyView}>
          <LoadingIndicator />
        </View>
      ) : (
        <ScrollView style={styles.list}>
          {filteredOrgs.length > 0 ? (
            filteredOrgs.map(org => (
              <TouchableOpacity
                key={org.id}
                onPress={() => onSelect(org)}
                style={styles.rowOrg}
              >
                <View style={styles.cellName}>
                  <Text style={styles.rowTitle}>{org.name}</Text>
                  <Text style={styles.rowSubtitle}>{org.id}</Text>
                </View>
                <Icon name="angle-right" size={20} color={theme.colors.grey5} />
              </TouchableOpacity>
            ))
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {translate('common.emptyTableSearch', {
                  entity: translate('transition.organisations').toLowerCase(),
                  searchText: searchText,
                })}
              </Text>
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default OrganizationsList;
