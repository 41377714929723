import React, { useEffect, useCallback } from 'react';
import { DraggableGrid } from './DraggableGrid';
import MenuItem from './GridItem/MenuItem';
import PaginationGrid from './GridItem/PaginationGrid/PaginationGrid';
import {
  EditMenuActionProp,
  menuItemsPerSection,
  paginationItem,
} from './MenuLayout';
import EmptyMenuItem from './GridItem/MenuEmptyItem';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import {
  EditMenuAction,
  EditMenuState,
  MenuItemAction,
  TileItem,
} from '../types';
import AddExistingPage from './Modals/AddExistingPage';
import {
  CreateOrUpdatePageInput,
  EntityType,
  Page,
  PageMaps,
} from '@oolio-group/domain';
import { v4 as uuidv4 } from 'uuid';
import { useNotification } from '../../../../../hooks/Notification';
import CreateAndAddNewPage from './Modals/CreateAndAddPage';
import { translate } from '@oolio-group/localization';

interface MenuItemsLayoutProp {
  dispatch: React.Dispatch<EditMenuActionProp>;
  state: EditMenuState;
  hasPageItemsChange: boolean;
  pageMaps: PageMaps;
  onSaveMenu: () => void;
  createOrUpdatePage: (
    updateMenuInput: CreateOrUpdatePageInput,
  ) => Promise<Page | undefined>;
}

const MenuItemsLayout: React.FC<MenuItemsLayoutProp> = ({
  dispatch,
  state,
  hasPageItemsChange,
  pageMaps,
  onSaveMenu,
  createOrUpdatePage,
}) => {
  const { currentMenuItemSection, menuItems, selectedMenuItemId } = state;

  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const startIndex = (currentMenuItemSection - 1) * menuItemsPerSection;
  const endIndex = currentMenuItemSection * menuItemsPerSection;
  const dataWithPagination = menuItems
    .slice(startIndex, endIndex)
    .concat(paginationItem);

  const onSelectCatalogueItem = useCallback(
    (selectedId: string) => {
      dispatch({
        type: EditMenuAction.UPDATE_SELECTED_MENU_ITEM,
        payload: {
          selectedMenuItemId: selectedId,
        },
      });
    },
    [dispatch],
  );

  const onChangeCatalogueItem = useCallback(
    (selectedItemId: string) => {
      if (hasPageItemsChange) {
        const onConfirmSave = () => {
          closeModal();
          onSaveMenu();
        };
        return showModal(
          <ConfirmationModal
            title="Warning"
            message={'Data has been changed, please save before continue'}
            confirmLabel="Save"
            cancelLabel="Discard Changes"
            onConfirm={onConfirmSave}
            onCancel={onSelectCatalogueItem.bind(null, selectedItemId)}
            type="neutral"
          />,
        );
      }
      onSelectCatalogueItem(selectedItemId);
    },
    [
      closeModal,
      hasPageItemsChange,
      onSaveMenu,
      onSelectCatalogueItem,
      showModal,
    ],
  );

  const onChangePageItems = useCallback(
    (data: TileItem[]) => {
      data.pop();
      const updatedData = [...menuItems];
      updatedData.splice(startIndex, menuItemsPerSection, ...data);
      dispatch({
        type: EditMenuAction.UPDATE_MENU_ITEM_DATA,
        payload: {
          data: updatedData,
        },
      });
    },
    [dispatch, menuItems, startIndex],
  );

  const onNextPage = useCallback(() => {
    dispatch({
      type: EditMenuAction.ON_CHANGE_MENU_ITEMS_PAGINATION,
      payload: {
        page: currentMenuItemSection + 1,
      },
    });
  }, [currentMenuItemSection, dispatch]);

  const onPreviousPage = useCallback(() => {
    dispatch({
      type: EditMenuAction.ON_CHANGE_MENU_ITEMS_PAGINATION,
      payload: {
        page: currentMenuItemSection - 1,
      },
    });
  }, [currentMenuItemSection, dispatch]);

  const onAddPageToMenu = useCallback(
    (
      params: { position: number },
      pageInfo: { pageId: string; color: string; name: string },
    ) => {
      const { pageId, color, name } = pageInfo;
      const { position } = params;
      const id = uuidv4();
      const formatItem = {
        id,
        key: id,
        entityId: pageId,
        entityType: EntityType.Page,
        name,
        color,
      } as TileItem;

      dispatch({
        type: EditMenuAction.INSERT_MENU_ITEMS,
        payload: {
          data: [formatItem],
          position,
        },
      });
    },
    [dispatch],
  );

  const onEditPage = useCallback(
    (position: number, updateItem: TileItem) => {
      dispatch({
        type: EditMenuAction.INSERT_MENU_ITEMS,
        payload: {
          data: [updateItem],
          position: position,
        },
      });
    },
    [dispatch],
  );

  const onClearMenuItemTile = useCallback(
    (position: number) => {
      closeModal();
      dispatch({
        type: EditMenuAction.CLEAR_MENU_ITEM_TILE,
        payload: {
          position: position,
        },
      });
    },
    [closeModal, dispatch],
  );

  const assignedPageIds = menuItems
    .filter(item => item.entityId as string)
    .map(item => item.entityId as string);

  const onSelectOption = useCallback(
    (params: { item: TileItem; option: MenuItemAction; position: number }) => {
      const { option, position, item } = params;
      switch (option) {
        case MenuItemAction.ADD_EXISTING_PAGE:
          const isAllPageAssigned =
            assignedPageIds.length === Object.values(pageMaps).length;
          if (isAllPageAssigned) {
            return showNotification({
              message: translate('menus.allPageAssignedMessage'),
              info: true,
            });
          }
          showModal(
            <AddExistingPage
              pageMaps={pageMaps}
              onAddExistingPage={onAddPageToMenu.bind(null, { position })}
              isAddToMenu
              assignPageIds={assignedPageIds}
            />,
          );
          break;
        case MenuItemAction.VIEW_PAGE:
          onChangeCatalogueItem(item.id as string);
          break;

        case MenuItemAction.EDIT_PAGE:
          showModal(
            <AddExistingPage
              pageMaps={pageMaps}
              onEditPage={onEditPage.bind(null, position)}
              isAddToMenu
              assignPageIds={menuItems.map(
                assignedItem => assignedItem.entityId as string,
              )}
              editingItem={item}
            />,
          );

          break;

        case MenuItemAction.ADD_NEW_PAGE:
          showModal(
            <CreateAndAddNewPage
              onAddExistingPage={onAddPageToMenu.bind(null, {
                position,
              })}
              createPage={createOrUpdatePage}
            />,
          );

          break;

        case MenuItemAction.CLEAR_TILE:
          showModal(
            <ConfirmationModal
              title={translate('menus.clearTile')}
              message={translate('menus.clearTileConfirmation', {
                name: item.name,
              })}
              onConfirm={onClearMenuItemTile.bind(null, position)}
            />,
          );
          break;

        default:
          break;
      }
    },
    [
      assignedPageIds,
      createOrUpdatePage,
      menuItems,
      onAddPageToMenu,
      onChangeCatalogueItem,
      onClearMenuItemTile,
      onEditPage,
      pageMaps,
      showModal,
      showNotification,
    ],
  );

  useEffect(() => {
    const currentMaxPage = Math.ceil(menuItems.length / menuItemsPerSection);
    if (currentMenuItemSection <= currentMaxPage) return;
    const lastIndex = menuItems.length;
    const newEmptyItems = Array(menuItemsPerSection)
      .fill(null)
      .map((_, index) => ({
        key: `${lastIndex + index}`,
      }));
    dispatch({
      type: EditMenuAction.UPDATE_MENU_ITEM_DATA,
      payload: {
        data: menuItems.concat(newEmptyItems),
      },
    });
  }, [currentMenuItemSection, dispatch, menuItems]);

  const renderItem = (data: {
    item: TileItem;
    onLongPress: () => void;
    position: number;
  }) => {
    const { item, position: rawPosition, onLongPress } = data;
    const position =
      rawPosition + menuItemsPerSection * (currentMenuItemSection - 1);

    if (item.entityType === 'PAGINATION') {
      return (
        <PaginationGrid
          scrollDirection="vertical"
          currentPage={currentMenuItemSection}
          onPressNext={onNextPage}
          onPressBack={onPreviousPage}
        />
      );
    }
    if (!item.id)
      return (
        <EmptyMenuItem
          onLongPress={onLongPress}
          onSelectOption={option => onSelectOption({ item, option, position })}
        />
      );
    return (
      <MenuItem
        onLongPress={onLongPress}
        isSelected={item.id === selectedMenuItemId}
        onSelectOption={option => onSelectOption({ item, option, position })}
        item={item}
      />
    );
  };

  return (
    <DraggableGrid
      numColumns={1}
      renderItem={renderItem}
      data={dataWithPagination}
      onDragRelease={onChangePageItems}
      itemHeight={80}
    />
  );
};

export default MenuItemsLayout;
