/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import {
  CreateAndUpdateEarningRule,
  EarningType,
  Venue,
  DEFAULT_ENTITY_ID,
  LoyaltySettings,
  Product,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import setKeyValue from 'lodash/set';
import { cloneDeep, differenceBy } from 'lodash';
import * as yup from 'yup';
import { TransformFunction } from 'yup/lib/types';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import SelectProductsModal from './SelectProductsModal';
import styles from '../Settings.styles';
import theme from '../../../../../common/default-theme';
import modalStyles from '../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';

const transformNumber: TransformFunction<yup.NumberSchema> = (
  value,
  original,
) => (original ? value : undefined);

const formSchema = yup.object().shape({
  earningType: yup.string().oneOf(Object.values(EarningType)).required(),
  amountSpend: yup
    .number()
    .transform(transformNumber)
    .when('earningType', {
      is: EarningType.EARN_BY_AMOUNT,
      then: schema => schema.positive().required(),
      otherwise: schema => schema.optional().transform(() => undefined),
    })
    .label('Amount Spent'),
  earningPoint: yup
    .number()
    .transform(transformNumber)
    .integer()
    .positive()
    .required()
    .label('Points Earned'),
  venueIds: yup.array().of(yup.string()).min(1),
  products: yup
    .array()
    .transform((value: Product[]) => value?.map(product => product.id))
    .optional(),
});

type EarningRuleState = {
  venueIds: string[];
  includedTax?: boolean;
  earningType: EarningType;
  amountSpend?: string;
  earningPoint: string;
  products?: Product[];
};

interface CreateAndEditEarningPointModalProps {
  onCreateAndUpdate: (earningRule: CreateAndUpdateEarningRule) => void;
  editingEarningRuleData?: EarningRuleState;
  allVenues: Venue[];
  loyaltySettings: Partial<LoyaltySettings>;
  editMode?: boolean;
}

const defaultEarningRule: EarningRuleState = {
  earningType: EarningType.EARN_BY_AMOUNT,
  venueIds: [],
  includedTax: true,
  amountSpend: '',
  earningPoint: '',
};

const CreateAndEditEarningPointModal: React.FC<
  CreateAndEditEarningPointModalProps
> = props => {
  const {
    onCreateAndUpdate,
    editingEarningRuleData,
    allVenues,
    loyaltySettings,
    editMode,
  } = props;
  const { showModal, closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { currencySymbol } = useCurrency();

  const isEditMode =
    editMode !== undefined ? editMode : editingEarningRuleData !== undefined;

  const [earningRuleData, setEarningRuleData] = useState<EarningRuleState>(
    editingEarningRuleData || {
      ...defaultEarningRule,
      venueIds: allVenues.map(venue => venue.id), // show all venues by default
    },
  );

  const onChangeItem = useCallback((path: string, value) => {
    setEarningRuleData(existingRuleData => {
      const newData = cloneDeep(existingRuleData);
      setKeyValue(newData, path, value);
      return newData;
    });
  }, []);

  const handleEarningPointValue = useCallback(
    (path: string, value) => {
      onChangeItem(path, value.replace(/\./g, ''));
    },
    [onChangeItem],
  );

  const validateInput = useCallback(
    (earningRuleData: EarningRuleState) => {
      if (earningRuleData.venueIds.length === 0) {
        showNotification({
          message: translate('backOfficeLoyalty.selectVenue'),
          error: true,
        });
        return undefined;
      }

      try {
        const updatedData = formSchema.validateSync(
          earningRuleData,
        ) as CreateAndUpdateEarningRule;

        return updatedData;
      } catch (error) {
        showNotification({
          message: (error as yup.ValidationError).message,
          error: true,
        });
        return undefined;
      }
    },
    [showNotification, translate],
  );

  const onCreateOrUpdateEarningRule = useCallback(
    (earningRuleData: EarningRuleState) => {
      const updatedData = validateInput(earningRuleData);
      updatedData && onCreateAndUpdate(updatedData);
    },
    [validateInput, onCreateAndUpdate],
  );

  const dropdownValues = useMemo(() => {
    return earningRuleData.venueIds.length == allVenues.length
      ? [DEFAULT_ENTITY_ID]
      : [...(earningRuleData?.venueIds || [])];
  }, [allVenues.length, earningRuleData.venueIds]);

  const onChangeVenues = useCallback(
    (selectedVenues: string[]) => {
      if (!selectedVenues.length) {
        return onChangeItem('venueIds', []);
      }
      const clickedItemId =
        differenceBy(selectedVenues, dropdownValues)[0] ||
        differenceBy(dropdownValues, selectedVenues)[0];
      if (clickedItemId === DEFAULT_ENTITY_ID) {
        const allVenueIds = allVenues.map(venue => venue.id);
        return onChangeItem('venueIds', allVenueIds);
      }
      const isItemSelected = earningRuleData.venueIds.includes(clickedItemId);
      const updatedVenueIds = isItemSelected
        ? earningRuleData.venueIds.filter(venueId => venueId !== clickedItemId)
        : earningRuleData.venueIds.concat(clickedItemId);
      onChangeItem('venueIds', updatedVenueIds);
    },
    [dropdownValues, earningRuleData.venueIds, onChangeItem, allVenues],
  );

  const showSelectProductsModal = () => {
    const updatedData = validateInput(earningRuleData);

    updatedData &&
      showModal(
        <SelectProductsModal
          onSubmit={assignedProducts => {
            onCreateOrUpdateEarningRule({
              ...earningRuleData,
              products: assignedProducts,
            });
          }}
          onBack={assignedProducts => {
            showModal(
              <CreateAndEditEarningPointModal
                {...props}
                editingEarningRuleData={{
                  ...earningRuleData,
                  products: assignedProducts,
                }}
                editMode={isEditMode}
              />,
            );
          }}
          selectedProducts={earningRuleData.products}
          action={
            isEditMode
              ? translate('backOfficeLoyalty.editRule')
              : translate('backOfficeLoyalty.createRule')
          }
        />,
      );
  };

  const isEarnByProducts =
    earningRuleData.earningType === EarningType.EARN_BY_PURCHASE_ITEM;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <View testID="modal" style={[styles.modal, { width: 340 }]}>
        <View style={modalStyles.title}>
          <Text style={[modalStyles.titleText, { color: theme.colors.blue }]}>
            {translate('backOfficeLoyalty.earningPoints', {
              points: loyaltySettings.pluralTerm,
            })}
          </Text>
        </View>
        <View style={styles.modalContent}>
          <TreatPicker
            testID="select-type"
            title={translate('backOfficeLoyalty.earnBy')}
            options={[
              {
                label: translate('backOfficeLoyalty.amountSpent'),
                value: EarningType.EARN_BY_AMOUNT,
              },
              {
                label: translate('backOfficeLoyalty.productPurchase'),
                value: EarningType.EARN_BY_PURCHASE_ITEM,
              },
            ]}
            selectedValue={earningRuleData?.earningType}
            onValueChange={onChangeItem.bind(null, 'earningType')}
            containerStyle={styles.modalInput}
          />
          {earningRuleData.earningType === EarningType.EARN_BY_AMOUNT && (
            <>
              <InputText
                testID="input-amount"
                title={translate('backOfficeLoyalty.amountSpent')}
                value={(earningRuleData?.amountSpend || '').toString()}
                placeholder="0.00"
                onChangeText={value =>
                  onChangeItem('amountSpend', formatToDigitsStr(value, false))
                }
                label={currencySymbol}
                keyboardType="numeric"
                maxLength={8}
                containerStyle={styles.modalInput}
              />
              <InputToggle
                testID="toggle-tax"
                isToggled={earningRuleData?.includedTax || false}
                title={translate('backOfficeLoyalty.includeTaxInAmount')}
                onToggle={() =>
                  onChangeItem(
                    'includedTax',
                    !Boolean(earningRuleData?.includedTax),
                  )
                }
                containerStyle={styles.modalInput}
              />
            </>
          )}
          <InputText
            testID="input-pointsEarned"
            title={translate('backOfficeLoyalty.pointsEarned', {
              points: loyaltySettings.pluralTerm,
            })}
            value={(earningRuleData?.earningPoint || '').toString()}
            placeholder="0"
            onChangeText={handleEarningPointValue.bind(null, 'earningPoint')}
            keyboardType="numeric"
            maxLength={8}
            containerStyle={styles.modalInput}
          />
          <SelectMultiple
            testID="select-venues"
            title={translate('backOfficeLoyalty.venues')}
            options={[
              {
                label: translate('backOfficeLoyalty.allVenues'),
                value: DEFAULT_ENTITY_ID,
              },
            ].concat(
              allVenues.map(venue => ({
                label: venue.name,
                value: venue.id,
              })),
            )}
            selectedValues={dropdownValues}
            onValueChange={onChangeVenues}
            containerStyle={styles.modalInput}
          />
        </View>
        <View style={modalStyles.actions}>
          <TreatButton
            testID="btn-dismiss"
            label={translate('form.dismiss')}
            type="cancel"
            onPress={() => closeModal()}
          />
          <TreatButton
            testID={isEarnByProducts ? 'btn-next' : 'btn-update'}
            label={
              isEarnByProducts
                ? translate('backOfficeLoyalty.selectProducts')
                : isEditMode
                ? translate('backOfficeLoyalty.editRule')
                : translate('backOfficeLoyalty.createRule')
            }
            type="neutral"
            onPress={
              isEarnByProducts
                ? () => showSelectProductsModal()
                : () => onCreateOrUpdateEarningRule(earningRuleData)
            }
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};
export default CreateAndEditEarningPointModal;
