import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Category } from '@oolio-group/domain';
import { useTranslation, useLocalization } from '@oolio-group/localization';
import { getLocaleEntity } from '@oolio-group/client-utils';
import { useCategories } from '../../../../hooks/app/categories/useCategories';
import { useNavigation } from '@react-navigation/native';
import { differenceWith, isEmpty, isEqual, sortBy } from 'lodash';
import { useNotification } from '../../../../hooks/Notification';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../common/default-theme';
import styles from './Categories.styles';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../components/Shared/Search/Search';
import Icon from '../../../../components/Icon/Icon';
import Pagination from '../../../../components/Office/Pagination/Pagination';

const validateDuplicationName = (
  categories: Category[],
): Record<string, boolean> => {
  const errors: Record<string, boolean> = {};
  const duplicateNameMap = categories.reduce((nameObj, current) => {
    const key = current.name?.toLowerCase().trim() || '';
    nameObj[key] = (nameObj[key] || 0) + 1;
    return nameObj;
  }, {} as Record<string, number>);
  categories.forEach(({ name, id }) => {
    const formattedName = name?.toLowerCase().trim() || '';
    if (duplicateNameMap[formattedName] > 1 || formattedName === '') {
      errors[id] = true;
    }
  });
  return errors;
};

const Categories: React.FC = () => {
  const navigation = useNavigation();
  const { locale } = useLocalization();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const {
    getCategories,
    loading,
    error,
    categoryMaps: categoryMapsFromServer,
    batchUpdateCategories,
  } = useCategories({ nextFetchPolicy: 'cache-and-network' });

  const originalCategoryRef = useRef<Record<string, Category>>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [categoryMaps, setCategoryMaps] = useState<Record<string, Category>>(
    {},
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handleCreateCategory = useCallback((): void => {
    navigation.navigate('CategorySettings', {
      title: translate('backOfficeProducts.createCategory'),
    });
  }, [navigation, translate]);

  const onEditCategory = useCallback(
    (item: Category) => {
      navigation.navigate('CategorySettings', {
        categoryId: item.id,
        title: item.name,
      });
    },
    [navigation],
  );

  const categories = useMemo(() => Object.values(categoryMaps), [categoryMaps]);

  const onPressSave = useCallback((): void => {
    const errors = validateDuplicationName(categories);
    if (isEmpty(errors)) {
      const categoriesToUpdate = differenceWith(
        categories,
        Object.values(originalCategoryRef.current),
        isEqual,
      ).map(({ name, id }) => ({ id, name }));
      batchUpdateCategories(categoriesToUpdate);
    } else {
      const hasEmptyValue = categories.find(
        category => !category?.name?.trim(),
      );
      const message = hasEmptyValue
        ? 'backOfficeProducts.emptyCategoryNameValidation'
        : 'backOfficeProducts.duplicatedCategoryName';
      showNotification({
        message: translate(message),
        error: true,
      });
    }
  }, [categories, batchUpdateCategories, showNotification, translate]);

  useEffect(() => {
    setCategoryMaps(categoryMapsFromServer);
    originalCategoryRef.current = categoryMapsFromServer;
    setSearchValue('');
  }, [categoryMapsFromServer]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  const filteredCategoryIds = useMemo(() => {
    if (!Object.values(categoryMaps).length) return [];
    const sortedCategories = sortBy(
      Object.values(originalCategoryRef.current),
      category => (category?.name || '').toLowerCase(),
    );

    return sortedCategories
      .filter(category =>
        category.name.toLowerCase().includes((searchValue || '').toLowerCase()),
      )
      .map(category => category.id);
  }, [categoryMaps, searchValue]);

  const pageItems = useMemo(() => {
    return filteredCategoryIds.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [filteredCategoryIds, currentPage]);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter
      title="Categories | Oolio"
      onSave={onPressSave}
    >
      <Section
        title={translate('backOfficeProducts.categories')}
        subtitle={translate('backOfficeProducts.categoryDescription')}
      >
        <View style={styles.filters}>
          <Search
            testID="search-categories"
            placeholder={translate('backOfficeProducts.searchCategories')}
            onChangeText={setSearchValue}
            containerStyle={styles.search}
          />
          <CreateButton onPress={handleCreateCategory} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerCategory]}>
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerProducts]}>
              {translate('backOfficeProducts.products')}
            </Text>
          </View>
          <View>
            {pageItems.map((id, i: number) => {
              const category = categoryMaps[id];
              const productsCount =
                (category?.products?.length || 0) +
                (category?.variants?.length || 0);

              return (
                <TouchableOpacity
                  key={i}
                  onPress={onEditCategory.bind(null, category)}
                  style={theme.tables.row}
                >
                  <Text
                    testID="text-name"
                    numberOfLines={1}
                    style={styles.cellCategory}
                  >
                    {getLocaleEntity(category, locale.languageTag)?.name}
                  </Text>
                  <Text style={styles.cellProducts}>{productsCount}</Text>
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey5}
                    />
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
          <Pagination
            page={currentPage}
            dataLength={filteredCategoryIds.length}
            entityName={translate('backOfficeProducts.categories')}
            onPageChange={setCurrentPage}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default Categories;
