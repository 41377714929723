import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { sentenceCase } from 'change-case';
import { useTranslation } from '@oolio-group/localization';
import { KitchenDisplay, KitchenDisplayMode } from '@oolio-group/domain';
import { useNotification } from '../../../../../../hooks/Notification';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';

interface CreateKitchenDisplayModalProps {
  onCreate: (name: string, KitchenDisplayType: KitchenDisplayMode) => void;
  onDismiss: () => void;
}

const CreateKitchenDisplayModal: React.FC<CreateKitchenDisplayModalProps> = ({
  onCreate,
  onDismiss,
}) => {
  const [KitchenDisplayDetails, setKitchenDisplayDetails] =
    useState<KitchenDisplay>({
      name: '',
      mode: KitchenDisplayMode.PREP,
    } as KitchenDisplay);

  const { showNotification } = useNotification();
  const { translate } = useTranslation();

  const onChange = useCallback((key: string, value: string) => {
    setKitchenDisplayDetails(prev => ({ ...prev, [key]: value }));
  }, []);

  const onCreateKitchenDisplay = useCallback(() => {
    if (!KitchenDisplayDetails?.name) {
      showNotification({
        message: translate('kitchenDisplay.pleaseEnterKitchenDisplayName'),
        error: true,
      });
    } else if (!KitchenDisplayDetails?.mode) {
      showNotification({
        message: translate('kitchenDisplay.pleaseSelectKitchenDisplayType'),
        error: true,
      });
    } else {
      onCreate(KitchenDisplayDetails?.name, KitchenDisplayDetails?.mode);
    }
  }, [
    KitchenDisplayDetails?.name,
    KitchenDisplayDetails?.mode,
    onCreate,
    showNotification,
    translate,
  ]);

  return (
    <TreatModal
      type="positive"
      title={translate('kitchenDisplay.addDisplay')}
      onConfirm={{ action: onCreateKitchenDisplay }}
      onDismiss={{ action: onDismiss }}
    >
      <InputText
        testID="input-name"
        title={translate('kitchenDisplay.displayName')}
        value={KitchenDisplayDetails.name}
        placeholder={translate('kitchenDisplay.displayName')}
        onChangeText={onChange.bind(null, 'name')}
        containerStyle={styles.inputContainer}
      />
      <TreatPicker
        testID="select-type"
        title={translate('kitchenDisplay.displayType')}
        options={Object.values(KitchenDisplayMode).map(x => ({
          value: x,
          label: sentenceCase(x),
        }))}
        selectedValue={KitchenDisplayDetails.mode}
        onValueChange={onChange.bind(null, 'kitchenDisplayMode')}
        containerStyle={styles.inputContainer}
      />
    </TreatModal>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    marginBottom: 20,
  },
});

export default CreateKitchenDisplayModal;
