import fetch from 'cross-fetch';
import { getAuthenticationState } from '../state/preferences';
import { REACT_APP_OOLIO_DOMAIN } from 'react-native-dotenv';

const userInfoEndpoint =
  'https://' +
  (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN) +
  '/userinfo';

export interface SsoProfile {
  id: string;
  email: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  app_metadata: any;
  user_metadata?: {
    firstName: string;
    lastName: string;
    displayName: string;
  };
  organizations: {
    id: string;
    name: string;
    apps: string[];
  }[];
}

export const fetchSsoProfile = async (): Promise<SsoProfile | undefined> => {
  const tokenState = await getAuthenticationState();
  try {
    const response = await fetch(userInfoEndpoint, {
      headers: {
        Authorization: 'Bearer ' + tokenState?.token,
      },
    });

    if (!response.ok) throw new Error('Fail to get user info');
    const data = await response.json();
    return data;
  } catch (error) {}
};
