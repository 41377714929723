import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { camelCase } from 'change-case';
import { Icon } from '../../../Icon/Icon';
import { FixedLink } from '../Navigation.types';
import styles from './FixedLink.styles';
import { analyticsService } from '../../../../analytics/AnalyticsService';

export const FixedNavigationLink: React.FC<FixedLink> = ({
  id,
  icon,
  title,
  count,
  route,
}) => {
  const navigation = useNavigation();

  const onPressLink = useCallback(
    (routeName: string) => {
      navigation.navigate(routeName);
      analyticsService.capture('navigation', {
        event: 'Pressed Link',
        link: title,
      });
    },
    [navigation, title],
  );

  return (
    <TouchableOpacity
      id={id}
      testID={`link-${camelCase(title)}`}
      onPress={() => onPressLink(route)}
      style={styles.container}
    >
      <View style={styles.icon}>
        <Icon size={18} name={icon} color="white" />
      </View>
      <Text numberOfLines={1} style={styles.title}>
        {title}
      </Text>
      {count && (
        <View style={styles.badge}>
          <Text style={styles.badgeText}>{count}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
};
