import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useState, useCallback, useEffect } from 'react';
import {
  GET_PAGES_QUERY,
  DELETE_PAGE,
  GET_PAGE_QUERY,
  getPageQueryWithFilter,
  CREATE_OR_UPDATE_PAGE,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';
import { Page, CreateOrUpdatePageInput } from '@oolio-group/domain';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';

export interface UsePagesProps {
  pages: Record<string, Page>;
  error: string | undefined;
  loading: boolean;
  getPages: () => void;
  deletePage: (id: string) => Promise<boolean | undefined>;
  getPage: (id: string) => void;
  createOrUpdatePage: (
    input: CreateOrUpdatePageInput,
  ) => Promise<Page | undefined>;
  getPagesByName: (name: string) => void;
}

export function usePages(customFragment?: string): UsePagesProps {
  const [pages, setPages] = useState<Record<string, Page>>({});

  const [getPagesReq, getPagesRes] = useLazyQuery(GET_PAGES_QUERY, {
    onError: noopHandler,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (getPagesRes.data) {
      setPages(keyBy(getPagesRes.data.pages as Page[], 'id'));
    }
  }, [getPagesRes.data]);

  const getPages = useCallback(() => {
    getPagesReq();
  }, [getPagesReq]);

  // get pages with name filter
  const [getPageFilter, getProductFilterRequest] = useLazyQuery(
    getPageQueryWithFilter(customFragment),
    {
      onError: noopHandler,
    },
  );

  const getPagesByNameData = useCallback(
    (name: string) => {
      getPageFilter({ variables: { input: name } });
    },
    [getPageFilter],
  );

  // update page cache
  const updatePageInfo = useCallback((page: Page) => {
    if (page) {
      setPages(prev => ({ ...prev, [page.id]: page }));
    }
  }, []);

  // TODO: cache is not being updated for priority. can be changed to cache-and-network.
  const [getPage, pageGetRequest] = useLazyQuery<{ page: Page }>(
    GET_PAGE_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: response => {
        updatePageInfo(response.page);
      },
      onError: noopHandler,
    },
  );

  const getPageData = useCallback(
    (pageId: string) => {
      getPage({
        variables: {
          input: pageId,
        },
      });
    },
    [getPage],
  );

  // create or Update Page Info
  const [createOrUpdatePageReq, createOrUpdateResponse] = useMutation<{
    createOrUpdatePage: Page;
  }>(CREATE_OR_UPDATE_PAGE, {
    onError: noopHandler,
  });

  const createOrUpdatePage = useCallback(
    async (input: CreateOrUpdatePageInput) => {
      const response = await createOrUpdatePageReq({ variables: { input } });
      if (response.data?.createOrUpdatePage)
        updatePageInfo(response.data?.createOrUpdatePage);
      return response.data?.createOrUpdatePage;
    },
    [createOrUpdatePageReq, updatePageInfo],
  );

  const [deletePage, deletePageResponse] = useMutation<{ deletePage: boolean }>(
    DELETE_PAGE,
    {
      onError: noopHandler,
    },
  );

  const deletePageData = useCallback(
    async (id: string) => {
      const response = await deletePage({
        variables: {
          input: id,
        },
      });
      return response?.data?.deletePage;
    },
    [deletePage],
  );

  const RESPONSE_ENTITIES = [
    getPagesRes,
    getProductFilterRequest,
    pageGetRequest,
    deletePageResponse,
    createOrUpdateResponse,
  ];

  const error: ApolloError | undefined = getError(RESPONSE_ENTITIES);
  const loading: boolean = isLoading(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      pages,
      getPages,
      error: error ? parseApolloError(error) : undefined,
      loading,
      deletePage: deletePageData,
      getPage: getPageData,
      getPagesByName: getPagesByNameData,
      createOrUpdatePage,
    }),
    [
      pages,
      getPages,
      error,
      loading,
      deletePageData,
      getPageData,
      getPagesByNameData,
      createOrUpdatePage,
    ],
  );
}
