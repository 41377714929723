import { useContext } from 'react';
import {
  POSUserAuthorizationContext,
  POSUserAuthorizationContextType,
} from '../../POSUserAuthorizationProvider';

const usePOSUserAuthorization = () => {
  const context = useContext<POSUserAuthorizationContextType>(
    POSUserAuthorizationContext,
  );

  return context;
};

export default usePOSUserAuthorization;
