import { RenderProps, StyleFn } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent, withTheme } from 'react-fela';
import { TouchableOpacity, View, ViewStyle, StyleProp } from 'react-native';
import Icon from '../Icon/Icon';

export interface GridViewPaginationProps {
  containerStyle: StyleProp<ViewStyle>;
  scrollDirection: 'vertical' | 'horizontal';
  currentPage: number;
  totalPages: number;
  onPressNext: () => void;
  onPressBack: () => void;
}

export interface PaginationBtnProps {
  testID?: string;
  type: 'next' | 'prev';
  onPress: () => void;
  scrollDirection: 'vertical' | 'horizontal';
  currentPage: number;
  totalPages: number;
  disabled?: boolean;
}

const paginationButtonStyle: StyleFn = ({ theme, disabled }) => ({
  flex: 0.495,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: disabled ? theme.colors.boxBorder : theme.colors.white,
  overflow: 'hidden',
});

const PaginationButton: React.FC<PaginationBtnProps> = (
  props: PaginationBtnProps,
) => (
  <FelaComponent style={paginationButtonStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <TouchableOpacity style={style} {...props}>
        <Icon
          name={
            props.type === 'prev'
              ? props.scrollDirection === 'vertical'
                ? 'AngleUp'
                : 'AngleLeft'
              : props.scrollDirection === 'vertical'
              ? 'AngleDown'
              : 'AngleRight'
          }
          disabled={props.disabled}
          primary={!props.disabled}
        />
      </TouchableOpacity>
    )}
  </FelaComponent>
);

const GridViewPagination: React.FC<GridViewPaginationProps> = ({
  containerStyle,
  scrollDirection,
  currentPage,
  totalPages,
  onPressBack,
  onPressNext,
}: GridViewPaginationProps): React.ReactElement => {
  return (
    <View style={containerStyle}>
      <PaginationButton
        type="prev"
        testID={'back-button'}
        scrollDirection={scrollDirection}
        currentPage={currentPage}
        totalPages={totalPages}
        onPress={onPressBack}
        disabled={currentPage <= 1}
      />
      <PaginationButton
        type="next"
        testID={'next-button'}
        scrollDirection={scrollDirection}
        currentPage={currentPage}
        totalPages={totalPages}
        onPress={onPressNext}
        disabled={currentPage >= totalPages}
      />
    </View>
  );
};

export default withTheme(GridViewPagination);
