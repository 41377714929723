import React, { useCallback, useEffect, useState } from 'react';
import { IntegrationApps, Feature } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import FeatureGrid from '../Layout/FeatureGrid/FeatureGrid';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useFeatures } from '../../../../hooks/app/features/useFeatures';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../constants';

const INTEGRATIONS_SETTINGS_FILES: Record<string, string> = {
  [IntegrationApps.DOSHII]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.DELIVERIT]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.OOLIO_STORE]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.DELIVERECT]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.XERO]: 'XeroSettings',
  [IntegrationApps.DEPUTY]: 'DeputySettings',
  [IntegrationApps.OOM]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.OOLIO_RESERVATION]: 'OolioReservationIntegrationsSettings',
};

export const ManageIntegrationsScreen: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();

  const [isReservationsEnabled, setIsReservationsEnabled] = useState<boolean>();

  useEffect(() => {
    async function checkFeatureFlag() {
      // check if reservations is enabled or not
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.OOLIO_RESERVATION,
      );
      setIsReservationsEnabled(isEnabled);
    }
    checkFeatureFlag();
  }, []);

  const onPressIntegrationTile = useCallback(
    (integration: Feature) => {
      navigation.navigate(INTEGRATIONS_SETTINGS_FILES[integration.id], {
        app: integration.app,
      });
    },
    [navigation],
  );

  const isFocused = useIsFocused();
  const { integrations, getIntegrations, loading } = useFeatures();

  useEffect(() => {
    if (isFocused) getIntegrations();
  }, [getIntegrations, isFocused]);

  const filteredIntegrations = integrations.filter(integration => {
    if (
      !isReservationsEnabled &&
      integration.app === IntegrationApps.OOLIO_RESERVATION
    ) {
      return false;
    }
    return true;
  });

  return (
    <ScreenLayout
      loading={loading}
      hideFooter={true}
      title={translate('navigation.integrationsPage', {
        appName: translate('appName'),
      })}
    >
      <Section layoutWidth="large">
        <FeatureGrid
          features={filteredIntegrations}
          onPressFeature={onPressIntegrationTile}
        />
      </Section>
    </ScreenLayout>
  );
};
