import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EXPORT_ENTITY_STATUS, ExportEntity } from '@oolio-group/domain';
import { downloadFile } from '../../../utils/csv';
import { useNotification } from '../../Notification';
import { useTranslation } from '@oolio-group/localization';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EXPORT_CUSTOMERS, GET_EXPORT_ENTITY_DETAIL } from './graphql';
import { noopHandler } from '../../../utils/errorHandlers';

const TIMER = 5 * 1000;

export function useExportCustomers() {
  const timerRef = useRef<number>();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [isExportEntityRunning, setExportProgress] = useState<boolean>(false);

  const [getExportCustomerDetailReq, getExportEntityDetailRes] = useLazyQuery<
    { getExportEntityDetail: ExportEntity },
    { id: string }
  >(GET_EXPORT_ENTITY_DETAIL, {
    onError: noopHandler,
    fetchPolicy: 'no-cache',
  });

  const [exportCustomerReq, exportCustomerRes] = useMutation(EXPORT_CUSTOMERS, {
    onError: noopHandler,
    fetchPolicy: 'no-cache',
  });

  const processCustomersExport = useCallback(() => {
    showNotification({
      info: true,
      message: translate('customer.exportRunning'),
    });
    exportCustomerReq();
  }, [exportCustomerReq, showNotification, translate]);

  const jobId = useMemo(
    () => exportCustomerRes?.data?.exportCustomers,
    [exportCustomerRes?.data?.exportCustomers],
  );

  const setExportStatusTimer = useCallback(
    exportEntityId => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        getExportCustomerDetailReq({ variables: { id: exportEntityId } });
      }, TIMER) as unknown as number;
    },
    [getExportCustomerDetailReq],
  );

  useEffect(() => {
    if (getExportEntityDetailRes?.data) {
      const exportProductDetail =
        getExportEntityDetailRes?.data?.getExportEntityDetail;
      if (exportProductDetail.status === EXPORT_ENTITY_STATUS.COMPLETED) {
        setExportProgress(false);
        showNotification({
          success: true,
          message: translate('customer.exportSuccess'),
        });
        downloadFile(exportProductDetail.fileUrl, 'Exported Products');
        clearInterval(timerRef.current);
      } else if (exportProductDetail.status === EXPORT_ENTITY_STATUS.RUNNING) {
        setExportProgress(true);
        setExportStatusTimer(exportProductDetail.id);
      } else if (exportProductDetail.status === EXPORT_ENTITY_STATUS.FAILED) {
        setExportProgress(false);
        showNotification({
          error: true,
          message: translate('customer.exportFailure'),
        });
        clearInterval(timerRef.current);
      }
    }
  }, [
    getExportEntityDetailRes?.data,
    setExportStatusTimer,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    if (jobId) {
      getExportCustomerDetailReq({
        variables: {
          id: jobId,
        },
      });
    }
  }, [jobId, getExportCustomerDetailReq]);

  useEffect(() => {
    // clear timer when unmount
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return useMemo(
    () => ({
      processCustomersExport,
      isExportEntityRunning,
    }),
    [processCustomersExport, isExportEntityRunning],
  );
}
