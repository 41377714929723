import React, { useCallback } from 'react';
import { EarningRule, EarningType } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { getLoyaltyUnit } from '@oolio-group/client-utils';
import { View, Text } from 'react-native';
import styles from '../Settings.styles';
import theme from '../../../../../common/default-theme';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

export interface ProductRowProps {
  item: EarningRule;
  singularTerm: string;
  pluralTerm: string;
  disabled?: boolean;
  onEdit: () => void;
  onDeleteItem: () => void;
}

const EarningRuleRow: React.FC<ProductRowProps> = ({
  item,
  onEdit,
  singularTerm,
  pluralTerm,
  disabled,
  onDeleteItem,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const generateDescription = useCallback(
    (earningRule: EarningRule) => {
      switch (earningRule.earningType) {
        case EarningType.EARN_BY_AMOUNT:
          const amount = formatCurrency(earningRule.amountSpend);

          return translate('backOfficeLoyalty.earnPointForAmount', {
            point: earningRule.earningPoint,
            amount,
            unit: getLoyaltyUnit(earningRule.earningPoint, {
              singularTerm,
              pluralTerm,
            }),
          });
        case EarningType.EARN_BY_PURCHASE_ITEM:
          return translate('backOfficeLoyalty.earnPointForProductPurchase', {
            points: earningRule.earningPoint,
            unit: getLoyaltyUnit(earningRule.earningPoint, {
              singularTerm,
              pluralTerm,
            }),
          });
        default:
          return '';
      }
    },
    [formatCurrency, pluralTerm, singularTerm, translate],
  );

  return (
    <View testID="row-earning" style={theme.tables.row}>
      <Text testID="text-name" style={styles.rowName}>
        {generateDescription(item)}
      </Text>
      <Text testID="text-value" style={styles.rowValue}>
        {`${item.earningPoint} ${
          item.earningPoint > 1 ? pluralTerm : singularTerm
        }`}
      </Text>
      <ButtonIcon
        testID="btn-editEarning"
        type="neutralLight"
        icon="pen"
        onPress={onEdit}
      />
      <ButtonIcon
        testID="btn-deleteEarning"
        type="negativeLight"
        icon="trash-alt"
        disabled={disabled}
        onPress={onDeleteItem}
        containerStyle={styles.btnDelete}
      />
    </View>
  );
};

export default EarningRuleRow;
