import React, { useEffect, useCallback, useState } from 'react';
import { PaymentType, CreatePaymentTypeInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useMutation } from '@apollo/client/react/hooks';
import { CREATE_PAYMENT_TYPE } from '../../../../../graphql/settings';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useNotification } from '../../../../../hooks/Notification';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';

export interface PaymentRowProps {
  paymentType: PaymentType;
  deletePaymentTypeFromState: (index: number, id: string) => void;
  onChange: (index: number, prop: string, value: string | boolean) => void;
  index: number;
}

interface CreatePaymentModalProps {
  onAddPayment: (paymentType: PaymentType) => void;
}

export const CreatePaymentModal: React.FC<CreatePaymentModalProps> = ({
  onAddPayment,
}: CreatePaymentModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [createForm, setCreateForm] = useState({} as CreatePaymentTypeInput);

  const onChangeModal = useCallback((prop: string, value) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const [createPaymentType, createPaymentRequest] = useMutation(
    CREATE_PAYMENT_TYPE,
    {
      onError: noopHandler,
    },
  );

  const onCreatePaymentType = useCallback(() => {
    if (!createForm.name) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      createPaymentType({
        variables: { input: createForm as CreatePaymentTypeInput },
      });
    }
  }, [createForm, createPaymentType, translate, showNotification]);

  useEffect(() => {
    if (createPaymentRequest.data) {
      const createdPaymentTypesData = createPaymentRequest.data
        .createPaymentType as PaymentType;
      onAddPayment({
        id: createdPaymentTypesData.id,
        adjustmentPrompt: createdPaymentTypesData.adjustmentPrompt,
        clearingAccount: createdPaymentTypesData.clearingAccount,
        isActive: createdPaymentTypesData.isActive,
        name: createdPaymentTypesData.name,
        quickPay: createdPaymentTypesData.quickPay,
        requireCustomer: createdPaymentTypesData.requireCustomer,
      });
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.newPaymentTypeSuccessfullyAdded',
        ),
      });
    }
  }, [
    createPaymentRequest.data,
    showNotification,
    closeModal,
    onAddPayment,
    translate,
  ]);

  useEffect(() => {
    if (createPaymentRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(createPaymentRequest.error),
      });
    }
  }, [createPaymentRequest.error, showNotification]);

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeSettings.createPaymentType')}
      onConfirm={{
        label: translate('backOfficeSettings.createPaymentType'),
        action: onCreatePaymentType,
      }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('form.name')}
        placeholder={translate('form.name')}
        value={createForm.name}
        onChangeText={onChangeModal.bind(null, 'name')}
      />
      <InputToggle
        testID="toggle-requireCustomer"
        title={translate('backOfficeSettings.requireCustomer')}
        isToggled={createForm.requireCustomer}
        onToggle={onChangeModal.bind(
          null,
          'requireCustomer',
          !createForm.requireCustomer,
        )}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginTop: 20, marginBottom: 4 }}
      />
      <InputToggle
        testID="toggle-promptAdjustment"
        title={translate('backOfficeSettings.promptAdjustment')}
        isToggled={createForm.adjustmentPrompt}
        onToggle={onChangeModal.bind(
          null,
          'adjustmentPrompt',
          !createForm.adjustmentPrompt,
        )}
      />
    </TreatModal>
  );
};
