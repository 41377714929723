import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  parent: {
    height: 28,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  indicator: {
    minWidth: 28,
    height: 28,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  name: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  nameText: {
    flex: 1,
    fontSize: 13,
    lineHeight: 20,
    paddingVertical: 4,
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
    textTransform: 'uppercase',
  },
  textAlign: {
    textAlign: 'center',
  },
});

export default styles;
