import React, { useCallback } from 'react';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import { useTranslation } from '@oolio-group/localization';
import { FilterValue } from './MenuSchedules';
import styles from './ManageScheduleHeaderFilter.styles';
import { View } from 'react-native';
export interface LabelFilter {
  value: string;
  label: string;
}

interface ProductRowProps {
  menus: LabelFilter[];
  storeOptions: LabelFilter[];
  filter: FilterValue;
  onChangeFilter: (value: FilterValue) => void;
}

export const ManageScheduleHeaderFilters: React.FC<ProductRowProps> = ({
  storeOptions,
  menus,
  filter,
  onChangeFilter,
}) => {
  const { translate } = useTranslation();
  const onChange = useCallback(
    (name: keyof FilterValue, value: string) => {
      onChangeFilter({ ...filter, [name]: value });
    },
    [filter, onChangeFilter],
  );
  return (
    <View style={styles.container}>
      <TreatPicker
        testID="select-store"
        selectedValue={filter.store}
        options={[
          {
            label: translate('menus.menuSchedules.filterByStore'),
            value: 'all',
          },
        ].concat(...storeOptions)}
        onValueChange={onChange.bind(null, 'store')}
        containerStyle={styles.dropdown}
      />
      <TreatPicker
        testID="select-menu"
        selectedValue={filter.menu}
        options={[
          {
            label: translate('menus.menuSchedules.filterByMenu'),
            value: 'all',
          },
        ].concat(...(menus || []))}
        onValueChange={onChange.bind(null, 'menu')}
        containerStyle={styles.dropdown}
      />
    </View>
  );
};
