import React, { useCallback, useMemo, useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import {
  OrderStatus,
  OrderType,
  OrderItem,
  OrderPayment,
  OrderTypeCode,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import format from 'date-fns/format';
import { getAgeFormat } from '../../../../../utils/dateHelper';
import theme from '../../../../../common/default-theme';
import styles from './OnlineOrdersTable.styles';
import Sticker, {
  StickerProps,
} from '../../../../../components/Shared/Sticker/Sticker';
import Icon from '../../../../../components/Icon/Icon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import Pagination from '../../../../../components/POS/Pagination/Pagination';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}

export interface OnlineOrdersDataProps {
  id: string;
  orderNumber: string;
  orderItems: Array<OrderItem>;
  createdAt: number;
  updatedAt?: number;
  customer: string;
  age?: string;
  channel: string;
  type: string;
  orderTypeCode: string;
  amount: number;
  status: OrderStatus;
  requiredAt?: number;
  amountDue?: number;
  totalPaymentAmount?: number;
  discountAmount?: number;
  surchargeAmount?: number;
  payments?: OrderPayment[];
  dueAt?: number;
}

export interface OrdersListViewProps {
  data: OnlineOrdersDataProps[];
  onSelectOrder: (id: string) => void;
  acceptOrders: (ids: string[]) => void;
  completeOrder: (id: string) => void;
  filterStatus?: OrderStatus;
  onOrderPay: (id: string) => void;
}

const OnlineOrdersTable: React.FC<OrdersListViewProps> = ({
  data,
  onSelectOrder,
  acceptOrders,
  completeOrder,
  filterStatus,
  onOrderPay,
}: OrdersListViewProps) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const orderActionButtonMapByStatus: Record<
    string,
    {
      title: string;
      type: 'positive' | 'focus' | 'neutral' | 'negative' | 'cancel';
      action?: (id: string) => void;
      disabled?: boolean;
    }
  > = useMemo(
    () => ({
      CREATED: {
        title: translate('onlineOrders.accept'),
        type: 'focus',
        action: id => acceptOrders([id]),
      },
      IN_PROGRESS: {
        title: translate('onlineOrders.complete'),
        type: 'neutral',
        action: completeOrder,
      },
      PAY_NOW: {
        title: translate('onlineOrders.payNow'),
        type: 'positive',
        action: onOrderPay,
      },
      CANCELLED: {
        title: translate('onlineOrders.cancelled'),
        type: 'cancel',
        disabled: true,
      },
      PARTNER_CANCELLED: {
        title: 'Partner Cancelled',
        type: 'cancel',
        disabled: true,
      },
      COMPLETED: {
        title: translate('onlineOrders.viewOrder'),
        type: 'neutral',
        disabled: true,
      },
    }),
    [translate, completeOrder, onOrderPay, acceptOrders],
  );

  const orderTimerStatus = useCallback(
    (input: number): StickerProps['type'] => {
      const diff = Date.now() - input;
      return diff <= 600000
        ? 'positiveLight'
        : diff > 600000 && diff <= 900000
        ? 'focusLight'
        : 'negativeLight';
    },
    [],
  );

  const getOrderActionMap = useCallback(
    (order: OnlineOrdersDataProps) => {
      let orderStatus: string = order.status;
      // when the order is in inprogress and amount is due we show pay now
      if (
        order.status === OrderStatus.IN_PROGRESS &&
        !!order.amountDue &&
        order.amountDue > 0
      ) {
        orderStatus = 'PAY_NOW';
      }
      return orderActionButtonMapByStatus[orderStatus];
    },
    [orderActionButtonMapByStatus],
  );

  const pageItems = useMemo(() => {
    return data?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [data, currentPage]);

  return (
    <View>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.cellAge]}>
          {translate('onlineOrders.age')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellHundred]}>
          {translate('onlineOrders.channel')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellType]}>
          {translate('onlineOrders.type')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDue]}>
          {translate('onlineOrders.dueAt')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellHundred]}>
          {translate('onlineOrders.customer')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellAmount]}>
          {translate('onlineOrders.amount')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((order, i) => (
            <TouchableOpacity
              key={order.id}
              style={styles.tableRow}
              onPress={onSelectOrder?.bind(null, order.id)}
              testID="row-online-order"
            >
              <Sticker
                testID={`online-order-${i}-age`}
                label={getAgeFormat(order.createdAt)}
                type={orderTimerStatus(order.createdAt)}
                containerStyle={styles.cellAge}
              />
              <Text
                testID={`online-order-${i}-channel`}
                style={styles.cellHundred}
                numberOfLines={1}
              >
                {order.channel || 'Online'}
              </Text>
              <Text
                testID={`online-order-${i}-type`}
                style={styles.cellType}
                numberOfLines={1}
              >
                {order.type}
              </Text>
              <Text
                testID={`online-order-${i}-scheduled-at`}
                style={styles.cellDue}
              >
                {order?.orderTypeCode === OrderTypeCode.DINE_IN
                  ? 'N/A'
                  : order.dueAt
                  ? format(new Date(order.dueAt), 'hh:mm a dd/MM')
                  : '-'}
              </Text>
              <Text
                testID={`online-order-${i}-customer`}
                numberOfLines={1}
                style={styles.cellHundred}
              >
                {order.customer}
              </Text>
              <Text
                testID={`online-order-${i}-order-amount`}
                style={styles.cellAmount}
              >
                {formatCurrency(order.amount)}
              </Text>
              <TreatButton
                testID={`online-order-${i}-status-button`}
                uppercase
                height={34}
                type={getOrderActionMap(order)?.type}
                label={
                  filterStatus === OrderStatus.CANCELLED
                    ? 'Cancelled'
                    : getOrderActionMap(order)?.title || ''
                }
                disabled={!!getOrderActionMap(order)?.disabled}
                onPress={() => getOrderActionMap(order)?.action?.(order.id)}
              />
              <View style={theme.tables.disclosure}>
                <Icon name="angle-right" size={20} color={theme.colors.grey5} />
              </View>
            </TouchableOpacity>
          ))
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('onlineOrders.noResults')}
            </Text>
          </View>
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={currentPage}
          dataLength={data.length}
          onPageChange={setCurrentPage}
        />
      ) : null}
    </View>
  );
};
export default OnlineOrdersTable;
