import React, { forwardRef, useState } from 'react';
import { Text, View, ViewStyle, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import format from 'date-fns/format';
import DatePickerComponent, { ReactDatePickerProps } from 'react-datepicker';
import theme, { DISPLAY_DATE_FORMAT } from '../../../common/default-theme';
import styles from './Select.styles';
import { Icon } from '../../Icon/Icon';
import 'react-datepicker/dist/react-datepicker.css';

interface SelectDateWebProps extends Omit<ReactDatePickerProps, 'onChange'> {
  onClick?(): void;
  onChangeDate?: (date: Date | undefined) => void;
  testID?: string;
  title?: string;
  valueDate?: Date;
  placeholder?: string;
  editable?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
  minDate?: Date;
  maxDate?: Date;
  optional?: boolean;
}

const SelectDate: React.FC<SelectDateWebProps> = ({
  title,
  testID,
  valueDate,
  editable = true,
  containerStyle,
  onChangeDate,
  minDate,
  maxDate,
  optional,
}: SelectDateWebProps) => {
  const { translate } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(valueDate);

  const onSelectDate = (date: Date | null) => {
    const setDate = date === null ? undefined : date;
    setSelectedDate(setDate);
    onChangeDate && onChangeDate(setDate);
  };

  const isClearable = editable && optional && valueDate;

  // eslint-disable-next-line react/display-name
  const Touchable = forwardRef<TouchableOpacity, SelectDateWebProps>(
    (props, ref) => {
      const date = valueDate || selectedDate;
      return (
        <TouchableOpacity
          ref={ref}
          disabled={!editable}
          style={[
            styles.inputContainer,
            editable ? theme.containers.enabled : theme.containers.disabled,
          ]}
          onPress={props.onClick}
        >
          <Text style={date ? styles.valueText : styles.placeholder}>
            {date
              ? format(date, DISPLAY_DATE_FORMAT)
              : translate('form.datePlaceholder')}
          </Text>
          <View style={styles.btnCalendar}>
            {isClearable ? (
              <TouchableOpacity onPress={() => onSelectDate(null)}>
                <Icon size={20} color={theme.colors.dark} name="times-circle" />
              </TouchableOpacity>
            ) : editable ? (
              <TouchableOpacity onPress={props.onClick}>
                <Icon size={20} color={theme.colors.dark} name="schedule" />
              </TouchableOpacity>
            ) : null}
          </View>
        </TouchableOpacity>
      );
    },
  );

  return (
    <View style={containerStyle} testID={testID}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <DatePickerComponent
          portalId="root-portal"
          withPortal={true}
          selected={selectedDate}
          onChange={onSelectDate}
          customInput={<Touchable />}
          minDate={minDate}
          maxDate={maxDate}
        />
      </View>
    </View>
  );
};

export default SelectDate;
