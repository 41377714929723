import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useIntercom } from '../../hooks/Intercom/useIntercom';
import Icon from '../Icon/Icon';
import theme from '../../common/default-theme';

export interface IntercomProviderProps {
  appId: string;
  children: React.ReactNode;
}

const CloseIntercom: React.FC = () => {
  const Intercom = useIntercom();

  const onPressClose = useCallback(() => {
    Intercom.stop();
  }, [Intercom]);

  return (
    <TouchableOpacity style={styles.container} onPress={onPressClose}>
      <Icon name="times" size={20} color={theme.colors.white} />
    </TouchableOpacity>
  );
};

export default CloseIntercom;

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: theme.colors.red,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 16,
    bottom: 90,
  },
});
