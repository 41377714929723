import React, { FC } from 'react';
import { View, Text } from 'react-native';
import styles from './CartName.styles';

interface CartNameProps {
  testID: string;
  name: string;
}

const CartName: FC<CartNameProps> = ({ testID, name }) => {
  return (
    <View style={styles.container}>
      <Text testID={testID} style={styles.text}>
        {name}
      </Text>
    </View>
  );
};

export default CartName;
