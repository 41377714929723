import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  CREATE_PRICING_GROUP,
  UPDATE_PRICING_GROUPS,
  DELETE_PRICING_GROUPS,
  CLONE_PRICING_GROUP,
  GET_PRICING_GROUPS_BY_STORE,
  getPricingGroupsQuery,
  getPricingGroupQuery,
  PRICING_GROUP_IN_PRICE_LIST_QUERY,
  PRICING_GROUP_IN_PRICE_LIST_DETAIL,
  getDefaultPricingGroupQuery,
} from '../../graphql/pricingGroups';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { Operation } from '../../types/Operation';
import {
  CreatePricingGroupInput,
  UpdatePricingGroupInput,
  ClonePricingGroupInput,
} from '@oolio-group/domain';
import keyBy from 'lodash/keyBy';

import { PricingGroup } from '@oolio-group/domain';
import { translate } from '@oolio-group/localization';
import { useNotification } from '../Notification';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
export interface usePricingGroupsProps {
  pricingGroups: { [key: string]: PricingGroup };
  defaultPricingGroup: PricingGroup | undefined;
  getPricingGroup: (pricingGroupId: string) => void;
  getAllPricingGroups: () => void;
  getAllPricingGroupsByStore: (store: string) => void;
  createPricingGroup: (
    createPricingGroupInput: Partial<CreatePricingGroupInput>,
  ) => void;
  updatePricingGroups: (
    updatePricingGroupInput: Partial<UpdatePricingGroupInput>[],
  ) => void;
  deletePricingGroups: (ids: string[]) => void;
  clonePricingGroup: (input: ClonePricingGroupInput) => void;
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  getDefaultPricingGroup: () => void;
}

interface usePricingGroupsInput {
  customFragment?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  onDeleteComplete?: () => void;
  onCreatedSuccess?: (id: string) => void;
}

export function usePricingGroups(
  input?: usePricingGroupsInput,
): usePricingGroupsProps {
  const [pricingGroups, setPricingGroups] = useState<
    Record<string, PricingGroup>
  >({});

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const { showNotification } = useNotification();

  // all graphql operations
  const [getPricingGroups, getPricingGroupsRes] = useLazyQuery<{
    pricingGroups: PricingGroup[];
  }>(getPricingGroupsQuery(input?.customFragment), {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    nextFetchPolicy: input?.nextFetchPolicy || 'cache-and-network',
    onError: noopHandler,
    onCompleted: data => {
      setPricingGroups(keyBy(data.pricingGroups || [], 'id'));
    },
  });

  const [getPricingGroup, getPricingGroupRes] = useLazyQuery<{
    pricingGroup: PricingGroup;
  }>(getPricingGroupQuery(input?.customFragment), {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    onError: noopHandler,
    onCompleted: data => {
      if (data?.pricingGroup?.id) {
        setPricingGroups(prev => {
          return {
            ...prev,
            [data.pricingGroup.id]: data.pricingGroup,
          };
        });
      }
    },
  });

  const [getDefaultPricingGroupReq, getDefaultPricingGroupRes] = useLazyQuery<{
    defaultPricingGroup: PricingGroup;
  }>(getDefaultPricingGroupQuery(input?.customFragment), {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    onError: noopHandler,
  });

  const defaultPricingGroup = useMemo(() => {
    return getDefaultPricingGroupRes.data?.defaultPricingGroup;
  }, [getDefaultPricingGroupRes.data?.defaultPricingGroup]);

  const [getPricingGroupsByStore, getPricingGroupsByStoreRes] = useLazyQuery(
    GET_PRICING_GROUPS_BY_STORE,
    {
      fetchPolicy: 'network-only',
      onError: noopHandler,
    },
  );

  const [createPricingGroup, createPricingGroupRes] = useMutation<{
    createPricingGroup: PricingGroup;
  }>(CREATE_PRICING_GROUP, {
    onError: noopHandler,
    onCompleted: data => {
      showNotification({
        success: true,
        message: translate('pricings.pricingCreatedSuccessfully'),
      });
      if (input?.onCreatedSuccess) {
        input.onCreatedSuccess(data.createPricingGroup.id);
      }
    },
  });

  const [deletePricingGroups, deletePricingGroupRes] = useMutation(
    DELETE_PRICING_GROUPS,
    {
      onError: noopHandler,
      onCompleted: () => {
        input?.onDeleteComplete && input.onDeleteComplete();
        showNotification({
          success: true,
          message: translate('pricings.productPricingsDeletedSuccessfully'),
        });
      },
    },
  );

  const [updatePricingGroupsReq, updatePricingGroupRes] = useMutation<{
    updatePricingGroups: PricingGroup[];
  }>(UPDATE_PRICING_GROUPS, {
    onError: noopHandler,
    onCompleted: data => {
      const updatedData = data.updatePricingGroups as PricingGroup[];
      setPricingGroups(prev => {
        const temp = { ...prev };
        updatedData.forEach(x => {
          temp[x.id] = x;
        });
        return temp;
      });
      showNotification({
        success: true,
        message: translate('pricings.productPricingUpdatedSuccessfully'),
      });
    },
  });

  const [clonePricingGroup, clonePricingGroupRes] = useMutation<{
    clonePricingGroup: PricingGroup;
  }>(CLONE_PRICING_GROUP, {
    onError: noopHandler,
    onCompleted: data => {
      showNotification({
        success: true,
        message: translate('pricings.pricingCreatedSuccessfully'),
      });
      if (input?.onCreatedSuccess) {
        input.onCreatedSuccess(data.clonePricingGroup.id);
      }
    },
  });

  const getAllPricingGroupsData = useCallback(() => {
    getPricingGroups();
    setOperation(Operation.READ);
  }, [getPricingGroups]);

  const getAllPricingGroupsByStore = useCallback(
    (storeId: string) => {
      getPricingGroupsByStore({ variables: { filter: { storeId } } });
      setOperation(Operation.READ);
    },
    [getPricingGroupsByStore],
  );

  useEffect(() => {
    if (getPricingGroupsByStoreRes.data) {
      const pricingGroupsData = getPricingGroupsByStoreRes.data
        .pricingGroups as PricingGroup[];
      if (pricingGroupsData.length > 0) {
        setPricingGroups(keyBy(pricingGroupsData, 'id'));
      }
    }
  }, [getPricingGroupsByStoreRes.data]);

  const getPricingGroupData = useCallback(
    (pricingGroupId: string) => {
      getPricingGroup({ variables: { id: pricingGroupId } });
      setOperation(Operation.READ);
    },
    [getPricingGroup],
  );

  const createPricingGroupData = useCallback(
    (pricingGroup: Partial<CreatePricingGroupInput>) => {
      createPricingGroup({
        variables: {
          input: pricingGroup,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createPricingGroup],
  );

  const deletePricingGroupsData = useCallback(
    (ids: string[]) => {
      deletePricingGroups({
        variables: {
          ids,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deletePricingGroups],
  );

  const updatePricingGroups = useCallback(
    (updatePricingGroupsData: Partial<UpdatePricingGroupInput>[]) => {
      updatePricingGroupsReq({
        variables: {
          input: updatePricingGroupsData,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updatePricingGroupsReq],
  );

  const clonePricingGroupData = useCallback(
    (clonePricingGroupsData: ClonePricingGroupInput) => {
      clonePricingGroup({
        variables: {
          input: clonePricingGroupsData,
        },
      });
    },
    [clonePricingGroup],
  );

  const getDefaultPricingGroup = useCallback(() => {
    getDefaultPricingGroupReq({ variables: {} });
  }, [getDefaultPricingGroupReq]);

  const RESPONSE_ENTITIES = [
    getPricingGroupsRes,
    getPricingGroupRes,
    createPricingGroupRes,
    deletePricingGroupRes,
    updatePricingGroupRes,
    clonePricingGroupRes,
    getPricingGroupsByStoreRes,
    getDefaultPricingGroupRes,
  ];

  const error: ApolloError | undefined = getError(RESPONSE_ENTITIES);
  const loading: boolean = isLoading(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      pricingGroups,
      defaultPricingGroup,
      getPricingGroup: getPricingGroupData,
      getAllPricingGroups: getAllPricingGroupsData,
      createPricingGroup: createPricingGroupData,
      deletePricingGroups: deletePricingGroupsData,
      updatePricingGroups,
      clonePricingGroup: clonePricingGroupData,
      getAllPricingGroupsByStore,
      error: error ? parseApolloError(error) : undefined,
      loading,
      operation,
      getDefaultPricingGroup,
    }),
    [
      pricingGroups,
      defaultPricingGroup,
      error,
      loading,
      operation,
      getPricingGroupData,
      getAllPricingGroupsData,
      createPricingGroupData,
      deletePricingGroupsData,
      updatePricingGroups,
      clonePricingGroupData,
      getAllPricingGroupsByStore,
      getDefaultPricingGroup,
    ],
  );
}

export {
  PRICING_GROUP_IN_PRICE_LIST_QUERY,
  PRICING_GROUP_IN_PRICE_LIST_DETAIL,
};
