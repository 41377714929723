import React, { useCallback, useMemo, useRef, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { EntityType } from '@oolio-group/domain';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { PageItemAction, TileItem } from '../../types';
import { translate } from '@oolio-group/localization';
import styles from './GridItem.styles';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';
import Item from './CommonItem';

interface PageItemProps {
  onLongPress: () => void;
  item: TileItem;
  onSelectOption: (option: PageItemAction) => void;
  currentSection: number;
}

const PageItemElement: React.FC<PageItemProps> = ({
  onLongPress,
  onSelectOption,
  item,
  currentSection,
}) => {
  const [showOption, setShowOption] = useState(false);
  const selectedOptionRef = useRef<PageItemAction | undefined>();

  const { name, color, entityType, category } = item;

  const onToggleOptions = () => {
    setShowOption(!showOption);
  };

  const onRequestClosePopover = () => {
    setShowOption(false);
  };

  const productOptions = useMemo(() => {
    return [
      {
        label: translate('menus.editTile'),
        action: PageItemAction.EDIT_TILE,
        disabled: false,
        color: '',
      },
      {
        label: translate('menus.moveToNextSection'),
        action: PageItemAction.MOVE_NEXT_SECTION,
      },
      {
        label: translate('menus.moveToPreviousSection'),
        action: PageItemAction.MOVE_PREVIOUS_SECTION,
        hide: currentSection == 1,
      },
      {
        label: translate('menus.clearTile'),
        action: PageItemAction.CLEAR_TILE,
        disabled: Boolean(category),
        color: theme.colors.states.negative,
      },
    ];
  }, [category, currentSection]);

  const pageItemOptions = useMemo(() => {
    return [
      {
        label: translate('menus.editTile'),
        action: PageItemAction.EDIT_PAGE_TILE,
        color: '',
        disabled: false,
      },
      {
        label: translate('menus.viewPage'),
        action: PageItemAction.VIEW_PAGE,
        color: '',
        disabled: false,
      },
      {
        label: translate('menus.moveToNextSection'),
        action: PageItemAction.MOVE_NEXT_SECTION,
      },
      {
        label: translate('menus.moveToPreviousSection'),
        action: PageItemAction.MOVE_PREVIOUS_SECTION,
        hide: currentSection == 1,
      },
      {
        label: translate('menus.clearTile'),
        action: PageItemAction.CLEAR_TILE,
        color: theme.colors.states.negative,
        disabled: false,
      },
    ];
  }, [currentSection]);

  const options = useMemo(() => {
    if (entityType === EntityType.Page) return pageItemOptions;
    return productOptions;
  }, [pageItemOptions, productOptions, entityType]);

  const filteredOptions = useMemo(
    () => options.filter(op => !op.hide),
    [options],
  );

  const onSelectPageOption = useCallback(option => {
    selectedOptionRef.current = option.action as PageItemAction;
    onRequestClosePopover();
  }, []);

  const onClosePopoverCompleted = useCallback(() => {
    onSelectOption(selectedOptionRef.current as PageItemAction);
  }, [onSelectOption]);

  const onOpenPopoverStart = useCallback(() => {
    selectedOptionRef.current = undefined;
  }, []);

  return (
    <Popover
      isVisible={showOption}
      placement={PopoverPlacement.AUTO}
      onRequestClose={onRequestClosePopover}
      onOpenStart={onOpenPopoverStart}
      onCloseComplete={onClosePopoverCompleted}
      from={
        <Item
          name={name}
          color={color}
          available={item.available}
          isGroupType={item.entityType === EntityType.Page}
          onPress={onToggleOptions}
          onLongPress={onLongPress}
        />
      }
      backgroundStyle={styles.popupBackground}
      popoverStyle={styles.popupContainer}
    >
      <View style={styles.popupOptions}>
        {filteredOptions.map((option, i: number) => (
          <TouchableOpacity
            key={i}
            testID="option-item"
            style={styles.popupOption}
            onPress={() => onSelectPageOption(option)}
            disabled={option?.disabled}
          >
            <Text
              style={[
                styles.popupLabel,
                {
                  color: option.disabled
                    ? theme.colors.grey3
                    : option.color || theme.colors.dark,
                },
              ]}
            >
              {option.label}
            </Text>
            <Icon name="angle-right" size={24} color={theme.colors.grey4} />
          </TouchableOpacity>
        ))}
      </View>
    </Popover>
  );
};

export default PageItemElement;
