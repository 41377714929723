import { Printer, PrinterSeries, PrinterType } from '@oolio-group/domain';
import printerTimeout from '../../utils/printerTimeout';
import * as PrinterWebSDK from './epson-sdk';

interface WebPrinter extends Partial<Printer> {
  device_name: string;
  host: string;
  port: number;
  slug?: string;
  type?: PrinterType;
}

/**
 * ----------------------------------------------------------------------------------
 * Network thermal printer (web only) and may be for desktop environments too
 * ----------------------------------------------------------------------------------
 * Supported printers: https://download.epson-biz.com/epson/epson_public_document.php?name=js2170.pdf
 *
 * Connect to network printer and then sends data to printer
 *
 * TODO: need to add other type like BLE / USB
 */
export const printOverNetwork = async (
  printer: WebPrinter,
  message: Buffer,
): Promise<PrinterWebSDK.PrinterResponse | undefined> => {
  try {
    const timeout = printerTimeout(printer.series || PrinterSeries.TM_M30II);
    return await PrinterWebSDK.connectAndSend(
      printer.host,
      message,
      {
        timeout,
      },
      undefined,
      printer.type === PrinterType.VIRTUAL ? printer.slug : undefined,
    );
  } catch (err) {
    return {
      success: false,
      message: err ? err.toString() : '',
    };
  }
};
