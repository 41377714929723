import React, { useCallback, useRef, useState } from 'react';
import {
  Text,
  View,
  ViewStyle,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { debounce } from 'lodash';
import theme from '../../../common/default-theme';
import styles from './Inputs.styles';
import Icon from '../../Icon/Icon';

export interface InputColourProps {
  testID: string;
  title?: string;
  label?: string;
  value?: string | undefined;
  disabled?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
  onChangeColour?: (value: string) => void;
}

const inputValueStyle = {
  visibility: 'hidden',
  position: 'absolute',
} as React.CSSProperties;

const InputColour: React.FC<InputColourProps> = ({
  title,
  value,
  label,
  testID,
  disabled,
  onChangeColour,
  containerStyle,
}: InputColourProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [realtimeValue, setRealtimeValue] = useState<string>();

  const debounceInputFunction = debounce(() => {
    onChangeColour && onChangeColour(inputRef.current?.value || '');
  }, 500);

  const onBlur = useCallback(() => {
    inputRef.current?.blur();
    setShowPicker(false);
  }, []);

  const onTogglePicker = useCallback(() => {
    if (!showPicker) {
      inputRef.current?.click();
      setShowPicker(true);
    } else {
      onBlur();
    }
  }, [onBlur, showPicker]);

  const debounceInput = useCallback(debounceInputFunction, [
    debounceInputFunction,
  ]);

  const handleColourChange = useCallback(
    event => {
      debounceInput();
      setRealtimeValue(event.target.value);
    },
    [debounceInput],
  );

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            disabled ? theme.containers.disabled : theme.containers.enabled,
          ]}
        >
          {!!label && (
            <View style={styles.labelContainer}>
              <Text style={styles.labelText}>{label}</Text>
            </View>
          )}
          <TextInput
            testID={testID}
            value={value}
            style={styles.inputContainer}
            autoCapitalize="none"
            autoCorrect={false}
            spellCheck={false}
            maxLength={7}
          />
        </View>
        <TouchableOpacity
          accessible={false}
          disabled={disabled}
          onPress={onTogglePicker}
          style={[styles.accessory, styles.colour]}
        >
          <View style={[styles.swatch, { backgroundColor: value }]} />
          {!disabled && (
            <Icon
              size={20}
              color={theme.colors.dark}
              name={showPicker ? 'angle-up' : 'angle-down'}
            />
          )}
          {Platform.OS === 'web' && (
            <input
              ref={inputRef}
              type="color"
              value={realtimeValue}
              style={inputValueStyle}
              onChange={event => handleColourChange(event)}
            />
          )}
          {/* TODO: Add Native Support */}
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default InputColour;
