import React, { useMemo, useState } from 'react';
import { ScrollView, TouchableOpacity, View, Text } from 'react-native';
import { Adjustment, CUSTOM_ADJUSTMENT_ID } from '@oolio-group/domain';
import styles from './ApplySurcharge.styles';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../common/default-theme';
import InputToggle from '../../Shared/Inputs/InputToggle';
import Icon from '../../Icon/Icon';

interface ApplySurchargeProps {
  adjustments: Adjustment[] | undefined;
  localAppliedAdjustments: Adjustment[] | undefined;
  onSelectAdjustment: (
    id: string,
    appliedAdjustments: Adjustment[] | undefined,
  ) => void;
  onDismiss: () => void;
}

export const ApplySurcharge = ({
  adjustments,
  localAppliedAdjustments,
  onSelectAdjustment,
  onDismiss,
}: ApplySurchargeProps) => {
  const { translate } = useTranslation();
  const { height: vh } = theme.useResponsiveDimensions();

  const [appliedAdjustments, setAppliedAdjustments] = useState(
    localAppliedAdjustments,
  );

  const handleToggle = (adjustmentId: string) => {
    const isAdjustmentApplied = appliedAdjustments?.some(
      appliedAdjustment => appliedAdjustment.id === adjustmentId,
    );

    let updatedAdjustments: Adjustment[] | undefined = [];

    if (isAdjustmentApplied) {
      // If adjustment is already applied, remove it
      updatedAdjustments = appliedAdjustments?.filter(
        appliedAdjustment => appliedAdjustment.id !== adjustmentId,
      );
    } else {
      // If adjustment is not applied, add it
      const adjustmentToAdd = adjustments?.find(
        adjustment => adjustment.id === adjustmentId,
      );
      if (adjustmentToAdd) {
        updatedAdjustments = [
          ...(appliedAdjustments || []),
          { ...adjustmentToAdd },
        ];
      }
    }

    setAppliedAdjustments(updatedAdjustments);
    onSelectAdjustment(adjustmentId, appliedAdjustments);
  };

  const containerStyle = useMemo(
    () => [styles.modalContainer, { maxHeight: 0.6 * vh }],
    [vh],
  );

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          testID="btn-close"
          style={styles.btnClose}
          onPress={onDismiss}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {translate('functionMaps.modal.scheduledSurcharge')}
        </Text>
      </View>
      <View style={containerStyle}>
        <ScrollView>
          <InputToggle
            key={CUSTOM_ADJUSTMENT_ID}
            type="switch"
            title={translate('adjustment.customAdjustment')}
            testID={'custom-adjustment'}
            onToggle={() => handleToggle(CUSTOM_ADJUSTMENT_ID)}
            isToggled={(appliedAdjustments || []).some(
              appliedAdjustment =>
                appliedAdjustment?.id === CUSTOM_ADJUSTMENT_ID,
            )}
          />
          {adjustments?.map(adjustment => (
            <InputToggle
              key={adjustment.id}
              title={adjustment.name ?? ''}
              type="switch"
              testID={`adjustment-${adjustment.name}`}
              onToggle={() => handleToggle(adjustment.id || '')}
              isToggled={(appliedAdjustments || []).some(
                appliedAdjustment => appliedAdjustment?.id === adjustment.id,
              )}
            />
          ))}
        </ScrollView>
      </View>
    </View>
  );
};
