export const formatEmail = (email: string): string =>
  email ? trimString(email).toLowerCase() : '';

export const formatPhone = (phone: string): string => {
  // remove first zero if exist
  return trimString(phone).replace(/^0/g, '');
};
export const formatNameToSearch = (name?: string): string => {
  return trimString(name || '').toLowerCase();
};

export const trimString = (text: string): string =>
  text ? text.replace(/\s/g, '') : '';

export function stringToUrlPattern(name: string) {
  return name
    .toLowerCase()
    .trim()
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
    .replace(/[^\w\s\-]/gi, '')
    .toLowerCase();
}
