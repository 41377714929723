import { useState, useEffect, useCallback } from 'react';
import * as settings from '../../state/preferences';
import { Locale } from '@oolio-group/localization';
import { Session } from '../../state/Session';
import { camelCase } from 'change-case';

type SettingsSelector =
  | 'locale'
  | 'session'
  | 'theme'
  | 'orderCounter'
  | 'integrationSettings'
  | 'tokenNumber'
  | 'localSurcharges';

// TODO: remove `state/preferences` to use storage directly here
// That can make this disappear
const getOperation = (
  operation: 'get' | 'set',
  selector: SettingsSelector,
): Function => {
  const key = camelCase(`${operation} ${selector}`);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (settings as any)[key];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _cache: Record<string, any> = {};

/**
 * Hook for app settings
 * This is a mean for getting settings value and setting it
 * Settings getter is async hence, value will be undefined at start
 *
 * ```typescript
 * const [locale, setLocale] = useSettings('locale');
 *
 * useEffect(() => {
 *  console.log('locale changed')
 * }, [locale])
 * ```
 * Throws exception if valid argument is not given
 * @param key valid settings key. see SettingsSelector type
 */
export function useSettings<T = Locale | Session>(key: SettingsSelector) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value, setValue] = useState<T | undefined>(_cache[key]);

  if (!key || typeof key !== 'string') {
    throw new Error('Settings key is required');
  }

  if (typeof getOperation('get', key) !== 'function') {
    throw new Error('Invalid settings key');
  }

  useEffect(() => {
    if (typeof _cache[key] !== 'undefined') {
      setValue(_cache[key]);
    } else {
      getOperation('get', key)().then((x: T) => {
        setValue(x);
        _cache[key] = x;
      });
    }
  }, [key]);

  const setter = useCallback(
    data => {
      getOperation('set', key)(data);
      setValue(data);
      _cache[key] = data;
    },
    [key],
  );

  return [value, setter] as [T | undefined, (arg0: T) => void];
}
