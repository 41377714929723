import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { View } from 'react-native';
import {
  User,
  UpdateUserRequest,
  DEFAULT_USER_LANGUAGE,
} from '@oolio-group/domain';
import {
  isValidEmail,
  isValidPinNumber,
} from '../../../../../../utils/validator';
import {
  useTranslation,
  getCountry,
  isValidPhoneNumber,
  parsePhoneNumber,
} from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useUsers } from '../../../../../../hooks/app/useUsers';
import { useUserRoles } from '../../../../../../hooks/app/users/useUserRoles';
import { Operation } from '../../../../../../types/Operation';
import { useNavigation } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { getDiffValues } from '@oolio-group/client-utils';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import { userUtility } from '../../../../../../state/userUtility';
import { mapUserRolesByRoleIdAndStoreIds } from '../Permissions/userRoleInputUtility';
import { LOCALE_OPTIONS } from '../../../../../../constants';
import theme from '../../../../../../common/default-theme';
import styles from '../../ManageUsers.styles';
import Section from '../../../../../../components/Office/Section/Section';
import Message from '../../../../../../components/Office/Message/Message';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import InputPassword from '../../../../../../components/Shared/Inputs/InputPassword';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import { analyticsService } from '../../../../../../analytics/AnalyticsService';
import { useSession } from '../../../../../../hooks/app/useSession';

type UserWithPhoneCountryCode = User & { phoneCountryCode: string };

export const General: React.FC = () => {
  const route = useRoute();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const [userData, setUserData] = useState<UserWithPhoneCountryCode>(
    {} as UserWithPhoneCountryCode,
  );

  const params = route.params as {
    userId: string;
  };

  const userId = params.userId || '';

  const {
    users,
    loading,
    error,
    updateUser,
    operation,
    deleteUser,
    deletedUserId,
  } = useUsers();
  const { userRoles: userRolesById, fetchUserRoles } = useUserRoles({
    userId: userId,
  });

  useEffect(() => {
    if (isFocused && params?.userId) {
      fetchUserRoles(params.userId);
    }
  }, [fetchUserRoles, params?.userId, isFocused]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error as string,
      });
    }
  }, [error, showNotification]);

  const [session] = useSession();
  const orgCountry = session.currentOrganization?.country as unknown as string;

  useEffect(() => {
    if (userId && users[userId] && !error && !loading) {
      const user = users[userId];
      const { phoneNumber, countryCode } = parsePhoneNumber(user?.phone ?? '');
      setUserData({
        ...user,
        phoneCountryCode: countryCode || orgCountry,
        phone: phoneNumber || '',
        pin: '',
      } as UserWithPhoneCountryCode);
    }
  }, [userId, users, error, loading, orgCountry]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullySaved'),
      });
    }
  }, [error, loading, operation, showNotification, translate]);

  const onChange = useCallback((prop, value): void => {
    setUserData(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const getFormattedPhone = () => {
    if (userData.phoneCountryCode) {
      return `${getCountry(userData.phoneCountryCode).phone} ${userData.phone}`;
    }
    return userData.phone;
  };

  const formValidationStatus: Record<string, boolean> = {
    firstName: !!userData.firstName,
    email: isValidEmail(userData.email),
    pin: userData.pin ? isValidPinNumber(userData.pin) : true,
    phone: userData.phone ? isValidPhoneNumber(getFormattedPhone()) : false,
  };

  const onPressSave = async (): Promise<void> => {
    if (
      Object.keys(formValidationStatus).some(
        _key => formValidationStatus[_key] === false,
      )
    ) {
      showNotification({
        error: true,
        message: 'Invalid values provided, check error messages.',
      });
    } else {
      const updatedValues = getDiffValues<
        Partial<User>,
        | 'preferredLanguage'
        | 'email'
        | 'firstName'
        | 'lastName'
        | 'phone'
        | 'skipPin'
      >(
        [
          'email',
          'firstName',
          'lastName',
          'preferredLanguage',
          'phone',
          'skipPin',
        ],
        users[userId],
        { ...userData, phone: getFormattedPhone() },
        { preferredLanguage: DEFAULT_USER_LANGUAGE },
      );
      if (Object.keys(updatedValues).length > 0 || userData.pin.length > 0) {
        updateUser(
          stripProperties(
            {
              ...updatedValues,
              id: userData.id,
              ...(userData.pin.length > 0 && { pin: userData.pin }),
            },
            '__typename',
          ) as UpdateUserRequest,
        );
        userUtility.updateOfficeUserAttr(userData.id, {
          ...(userData.name && { name: userData.name }),
          email: userData.email,
        });
        analyticsService.capture('POS User', {
          userId: userData.id,
          language: updatedValues.preferredLanguage || 'Unknown',
          skipPin: updatedValues.skipPin ? 'Disabled' : 'Enabled',
        });
      }
    }
  };

  useEffect(() => {
    if (!error && !loading && operation === Operation.DELETE && deletedUserId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deletedSuccessfully'),
      });
      closeModal();
      navigation.navigate('ManageUsersTab');
    }
  }, [
    deletedUserId,
    error,
    loading,
    operation,
    showNotification,
    translate,
    navigation,
    closeModal,
  ]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backOfficeUsers.deleteUserMessage')}
        onConfirm={() => userId && deleteUser(userId)}
      />,
    );
  }, [userId, deleteUser, translate, showModal]);

  const userRoles = useMemo(() => {
    return mapUserRolesByRoleIdAndStoreIds(userRolesById);
  }, [userRolesById]);

  return (
    <ScreenLayout
      loading={loading}
      onSave={onPressSave}
      onDelete={onPressDelete}
      onDeleteDisabled={userData.isOwner}
      title={translate('navigation.users', {
        appName: translate('appName'),
      })}
    >
      {Object.values(userRoles).length > 0 ? null : (
        <Message
          type="negative"
          icon="exclamation-triangle"
          message={translate('backOfficeUsers.permissionsUnset')}
          containerStyle={styles.message}
        />
      )}
      <Section title={translate('backofficeManageRoles.roleDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="firstName"
            title={translate('form.firstName')}
            placeholder={translate('form.firstName')}
            value={userData.firstName || ''}
            onChangeText={onChange.bind(null, 'firstName')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              !formValidationStatus.firstName ? 'Enter first name' : undefined
            }
          />
          <InputText
            testID="lastName"
            title={translate('form.lastName')}
            placeholder={translate('form.lastName')}
            value={userData.lastName || ''}
            onChangeText={onChange.bind(null, 'lastName')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputEmail
            testID="email"
            title={translate('form.email')}
            placeholder={translate('form.email')}
            value={userData.email || ''}
            onChangeText={onChange.bind(null, 'email')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              !formValidationStatus.email ? 'Enter valid email' : undefined
            }
          />
          <InputPhone
            testID="phone-number"
            title={translate('form.phone')}
            value={userData.phone || ''}
            onChangeText={onChange.bind(null, 'phone')}
            defaultCountry={userData.phoneCountryCode}
            onPressCountry={(country: string): void =>
              onChange('phoneCountryCode', country)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputPassword
            testID="userPin"
            title={translate('form.pin')}
            placeholder={translate('form.pin')}
            value={userData.pin || ''}
            maxLength={6}
            onChangeText={onChange.bind(null, 'pin')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              !formValidationStatus.pin
                ? translate('backOfficeUsers.pinDigitLength')
                : undefined
            }
            // infoText={translate('backOfficeUsers.pinDigitLength')}
          />
          <TreatPicker
            testID="select-language"
            title={translate('form.preferredLanguage')}
            options={LOCALE_OPTIONS}
            selectedValue={userData.preferredLanguage}
            onValueChange={onChange.bind(null, 'preferredLanguage')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            type="switch"
            testID="toggle-pin-required"
            title={translate('backOfficeUsers.askForPin')}
            subtitle={translate('backOfficeUsers.askForPinSubtitle')}
            isToggled={!userData.skipPin}
            onToggle={onChange.bind(null, 'skipPin', !userData.skipPin)}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
