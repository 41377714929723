import { gql } from '@apollo/client';

export const earningPointRule = `
... on EarnByAmount {
  id
  earningType
  venueIds
  earningPoint
  amountSpend
  includedTax
}
... on EarnByPurchaseItem {
  id
  earningType
  venueIds
  earningPoint
  products {
    id
    name
  }
}
`;

export const rewardRule = `
... on RewardByDiscountEntireSale {
  id
  rewardType
  rewardName
  venueIds
  pointsRequired
  discountAmount
  discountType
  maximumDiscountAmount
}
... on RewardByDiscountProduct {
  id
  rewardType
  rewardName
  venueIds
  pointsRequired
  discountAmount
  discountType
  maximumDiscountAmount
  products {
    id
    name
  }
}
... on RewardByFreeItem {
  id
  rewardType
  rewardName
  venueIds
  pointsRequired
  products {
    id
    name
  }
}
`;

export const loyaltySettings = `
  singularTerm
  pluralTerm
`;

export const loyaltyActivityLogFragment = `
  id
  customer {
    id
    firstName
  }
  store {
    id
    name
  }
  activityType
  timestamp
  pointsChanged
  reward {
    ... on RewardByDiscountEntireSale {
      id
      rewardName
    }
    ... on RewardByDiscountProduct {
      id
      rewardName
    }
    ... on RewardByFreeItem {
      id
      rewardName
    }
  }
  appliedRewardQuantity
`;

export const GET_LOYALTY_SETTINGS = gql`
  query LoyaltySettings {
    loyaltyPrograms {
      settings {
        ${loyaltySettings}
      }
      rules {
        rewardRules {
          ${rewardRule}
        }
        earningRules {
          ${earningPointRule}
        }
      }
    }
  }
`;

export const CREATE_AND_UPDATE_EARNING_RULES = gql`
  mutation CreateOrUpdateLoyaltyProgramEarningRule($input: CreateOrUpdateLoyaltyProgramEarningRuleInput) {
    createOrUpdateLoyaltyProgramEarningRule(input: $input) {
      ${earningPointRule}
    }
  }
`;

export const DELETE_EARNING_RULE = gql`
  mutation DeleteEarningRule($id: ID!) {
    deleteEarningRule(id: $id)
  }
`;
export const DELETE_REWARD_RULE = gql`
  mutation DeleteRewardRule($id: ID!) {
    deleteRewardRule(id: $id)
  }
`;

export const UPDATE_LOYALTY_SETTINGS = gql`
  mutation UpdateLoyaltySettings($input: LoyaltyProgramSettingsInput) {
    updateLoyaltyProgramSettings(input: $input) {
      ${loyaltySettings}
    }
  }
`;

export const CREATE_AND_UPDATE_REWARD_RULES = gql`
  mutation CreateOrUpdateLoyaltyProgramRewardRule($input: CreateOrUpdateLoyaltyProgramRewardRuleInput) {
    createOrUpdateLoyaltyProgramRewardRule(input: $input) {
      ${rewardRule}
    }
  }
`;

export const GET_PAGINATED_LOYALTY_ACTIVITY_LOGS = gql`
  query PaginatedLoyaltyActivityLogs(
    $first: Int
    $after: String
    $filter: LoyaltyActivityLogFilterInput
  ) {
    paginatedLoyaltyActivityLogs(
      first: $first
      after: $after
      filter: $filter
    ) {
      edges {
        node {
          ${loyaltyActivityLogFragment}
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
