import React, { useMemo } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import DeviceStatusModal from './DeviceStatusModal';
import { DeviceStatusMessage } from './DeviceStatusModalRow';

interface Props {
  visible: boolean;
  onDismiss: () => void;
}

const OfflineWarningModal: React.FC<Props> = ({ visible, onDismiss }) => {
  const { translate } = useTranslation();
  const offlineMessages: DeviceStatusMessage[] = useMemo(
    () => [
      {
        type: 'positive',
        message: translate('offline.ordersPaymentsLocalSync'),
      },
      {
        type: 'focus',
        message: translate('offline.integratedPaymentsUnavailable'),
      },
      { type: 'focus', message: translate('offline.sentOrdersKDSUnavailable') },
      { type: 'focus', message: translate('offline.loyaltyPointsUnavailable') },
      { type: 'negative', message: translate('offline.deviceRestrictions') },
    ],
    [translate],
  );

  if (!visible) return null;

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles, react-native/no-color-literals
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <DeviceStatusModal
        icon="warning"
        title={translate('offline.offlineMode')}
        subtitle={translate('offline.internetAndNetworkDisconnected')}
        messages={offlineMessages}
        onDismiss={onDismiss}
      />
    </View>
  );
};

export default OfflineWarningModal;
