import { useTranslation } from '@oolio-group/localization';
import {
  EnrollCustomerInput,
  Icons,
  StyleFn,
  LoyaltyEnrolmentSource,
} from '@oolio-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import FormInput from '../../FormInput/FormInput';
import { useNotification } from '../../../hooks/Notification';
import { countries } from 'countries-list';
import { usePhoneNumber, isValidPhoneNumber } from '@oolio-group/localization';
import { useSession } from '../../../hooks/app/useSession';

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  backgroundColor: theme.colors.white,
  minWidth: 50,
  textAlign: 'left',
  paddingLeft: 10,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  lineHeight: 24,
});

const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

const statisticView: StyleFn = ({ theme }) => ({
  height: 180,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: theme.radius.large,
  borderTopRightRadius: theme.radius.large,
  backgroundColor: theme.colors.brandPrimary,
});

const starIconGroup: StyleFn = ({}) => ({
  flexDirection: 'row',
});

const formInputContainerStyleCss: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  alignSelf: 'stretch',
  width: 300,
});

const formLabelStyleCss: StyleFn = ({}) => ({
  marginLeft: 7,
});

const enrollButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  textTransform: 'uppercase',
});

const enrollButton: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  backgroundColor: theme.colors.green,
  marginTop: theme.spacing.medium,
});

const formContainer: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.xl,
  paddingBottom: theme.padding.xl,
  backgroundColor: theme.colors.white,
  width: '100%',
  borderBottomLeftRadius: theme.radius.large,
  borderBottomRightRadius: theme.radius.large,
});

const enrollToLoyaltyContainer: StyleFn = ({}) => ({
  width: 380,
  alignSelf: 'center',
});

const descriptionText: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.smaller,
  color: theme.colors.white,
  marginTop: 10,
  paddingHorizontal: theme.spacing.big,
  opacity: 0.6,
});

const enrollHeading: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.large,
  color: theme.colors.white,
  marginTop: 10,
});

const enrollDescription: StyleFn = ({}) => ({
  alignSelf: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const closeButtonContainer: StyleFn = ({}) => ({
  left: 20,
  top: 20,
  position: 'absolute',
});

export interface OnboardCustomerModalModalProps {
  onCloseModal: () => void;
  loading?: boolean;
  onEnrollLoyalty: (customerInfo: EnrollCustomerInput) => void;
  assignedCustomerInfo?: { phone?: string; firstName?: string };
}

const EnrollCustomerModal: React.FC<OnboardCustomerModalModalProps> = ({
  onCloseModal,
  onEnrollLoyalty,
  loading,
  assignedCustomerInfo,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const [session] = useSession();
  const defaultCountry = session?.currentOrganization?.country || 'AU';
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const [customerInfo, setCustomerInfo] = useState<{
    phone: string;
    firstName: string;
  }>({
    phone: assignedCustomerInfo?.phone || '',
    firstName: assignedCustomerInfo?.firstName || '',
  });

  const onEnrollCustomer = useCallback(async () => {
    if (!customerInfo.firstName) {
      return showNotification({
        message: translate('backOfficeCustomerModal.errorFirstNameMissing'),
        error: true,
      });
    }
    if (
      !isValidPhoneNumber(
        getFullFormattedPhoneNumber(selectedCountry, customerInfo.phone),
      )
    ) {
      return showNotification({
        message: translate('customer.invalidPhoneMessage'),
        error: true,
      });
    }
    onEnrollLoyalty({
      phone: getFullFormattedPhoneNumber(selectedCountry, customerInfo.phone),
      firstName: customerInfo.firstName,
      loyaltyEnrolmentSource: LoyaltyEnrolmentSource.POS,
    });
  }, [
    customerInfo,
    getFullFormattedPhoneNumber,
    onEnrollLoyalty,
    selectedCountry,
    showNotification,
    translate,
  ]);

  const onChange = useCallback((key: string, value: string): void => {
    setCustomerInfo(preCustomer => ({
      ...preCustomer,
      [key]: value,
    }));
  }, []);

  const isPhoneValid = isValidPhoneNumber(
    getFullFormattedPhoneNumber(selectedCountry, customerInfo.phone),
  );

  const onSelectCountry = (countryCode: string) => {
    setSelectedCountry(countryCode);
  };

  return (
    <View style={css(enrollToLoyaltyContainer)}>
      <View style={css(statisticView)}>
        <View style={css(closeButtonContainer)}>
          <IconButton
            icon={Icons.Times}
            iconColor={theme.colors.white}
            iconSize={22}
            onPress={onCloseModal}
            testID="close-icon"
          />
        </View>
        <View style={css(starIconGroup)}>
          <IconButton
            icon={Icons.Star}
            iconColor={theme.colors.white}
            iconSize={22}
          />
          <IconButton
            icon={Icons.Star}
            iconColor={theme.colors.white}
            iconSize={22}
          />
          <IconButton
            icon={Icons.Star}
            iconColor={theme.colors.white}
            iconSize={22}
          />
        </View>
        <View style={css(enrollDescription)}>
          <Text style={css(enrollHeading)}>
            {translate('customerLoyalty.enrollCustomer')}
          </Text>
          <Text style={css(descriptionText)}>
            {translate('customerLoyalty.enrollLoyaltyDescription')}
          </Text>
        </View>
      </View>
      <View style={css(formContainer)}>
        <View>
          <FormInput
            title={translate('form.firstName')}
            value={customerInfo?.firstName}
            onChangeText={onChange.bind(null, 'firstName')}
            testID="first-name"
            alignTitle="left"
            containerStyle={css(formInputContainerStyleCss)}
            textStyle={css(formLabelStyleCss)}
          />
        </View>
        <View>
          <FormInput
            title={translate('form.phoneNumber')}
            type="phone"
            alignTitle={'left'}
            country={selectedCountry}
            verified={isPhoneValid}
            value={customerInfo.phone}
            onChangeText={onChange.bind(null, 'phone')}
            placeholder={'0423 456 789'}
            prefix={{
              text: selectedCountry
                ? `+${countries[selectedCountry].phone} `
                : '',
              textStyle: !isPhoneValid
                ? css(phonePrefixStyle, phonePrefixErrorBgStyle)
                : css(phonePrefixStyle),
            }}
            containerStyle={css(formInputContainerStyleCss)}
            testID="phone-number"
            onSelectChange={onSelectCountry}
          />
        </View>
        <View>
          <Button
            key={'enroll-customer'}
            testID={'enroll-customer'}
            success
            containerStyle={css(enrollButton)}
            labelStyle={css(enrollButtonStyle)}
            title={translate('button.enrollCustomer')}
            onPress={onEnrollCustomer}
            loading={loading}
          />
        </View>
      </View>
    </View>
  );
};

export default EnrollCustomerModal;
