import React, { useCallback, useState } from 'react';
import { CreateOptionInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';

interface CreateVariantGroupProps {
  onCreate: (variation: CreateOptionInput) => void;
}

const CreateVariantGroupModal: React.FC<CreateVariantGroupProps> = ({
  onCreate,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [name, setName] = useState('');

  const handleCreation = useCallback(() => {
    const placeholder: CreateOptionInput = {
      key: name,
      values: [],
    };
    onCreate(placeholder);
    setName('');
    showNotification({
      success: true,
      message: translate('variants.created'),
    });
    closeModal();
  }, [name, onCreate, showNotification, translate, closeModal]);

  return (
    <TreatModal
      type="positive"
      title={translate('variants.createVariantGroup')}
      onConfirm={{
        label: translate('button.createNew'),
        disabled: name.length < 1,
        action: handleCreation,
      }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-groupName"
        title={translate('modifiers.groupName')}
        placeholder={translate('form.namePlaceholder')}
        value={name}
        onChangeText={setName}
      />
    </TreatModal>
  );
};

export default CreateVariantGroupModal;
