import React, { useState } from 'react';
import { format } from 'date-fns';
import { View, Text, StyleSheet, ViewStyle } from 'react-native';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import { useEffect } from 'react';

interface ClockProps {
  containerStyle?: ViewStyle;
}

const Clock: React.FC<ClockProps> = ({ containerStyle }) => {
  const [time, setTime] = useState<string>(format(new Date(), 'hh:mm a'));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(format(new Date(), 'hh:mm a'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <View style={[styles.clockContainer, containerStyle]}>
      <Text style={styles.clockText}>{time}</Text>
      <Icon name="clock" size={18} color={theme.colors.dark} />
    </View>
  );
};

export default Clock;

const styles = StyleSheet.create({
  clockContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.shadow.strong,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  clockText: {
    marginRight: 8,
    fontFamily: theme.fonts.medium,
  },
});
