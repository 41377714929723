import { useContext } from 'react';
import { OfficeUserRoleContext } from '../../OfficeUserRoleProvider';

const useOfficeUserRoles = () => {
  const context = useContext(OfficeUserRoleContext);

  return context;
};

export default useOfficeUserRoles;
