import gql from 'graphql-tag';

export const GET_SALES_CHANNELS_QUERY = gql`
  query getSalesChannels {
    salesChannels {
      id
      name
      code
    }
  }
`;
