import { gql } from '@apollo/client';

export const GET_TRADING_PERIODS_QUERY = gql`
  query getTradingPeriods {
    tradingPeriods {
      id
      name
      startTime
    }
  }
`;

export const CREATE_TRADING_PERIODS = gql`
  mutation createTradingPeriods($input: [CreateTradingPeriodInput!]) {
    createTradingPeriods(input: $input) {
      id
      name
      startTime
    }
  }
`;

export const UPDATE_TRADING_PERIODS = gql`
  mutation updateTradingPeriods($input: [UpdateTradingPeriodInput!]) {
    updateTradingPeriods(input: $input) {
      id
      name
      startTime
    }
  }
`;

export const DELETE_TRADING_PERIOD = gql`
  mutation deleteTradingPeriod($id: ID!) {
    deleteTradingPeriod(id: $id)
  }
`;

export const GET_DATES_TIME_QUERY = gql`
  query getDateTime {
    dateTime {
      startTime
      startDay
      startMonth
    }
  }
`;
export const UPDATE_DATES_TIME_QUERY = gql`
  mutation updateDateTime($input: UpdateDateTimeInput!) {
    updateDateTime(input: $input) {
      startTime
      startDay
      startMonth
    }
  }
`;
