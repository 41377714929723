import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  bannerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    columnGap: 16,
    backgroundColor: theme.colors.states.positive,
    zIndex: 997,
    position: 'absolute',
    width: '100%',
  },
  textContent: {
    color: theme.colors.white,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  },
  actionContent: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 8,
  },
});

export default styles;
