import { Platform } from 'react-native';
import UAParser from 'ua-parser-js';

export const isSafari = () => {
  const parser = new UAParser(navigator.userAgent);
  return parser.getBrowser().name?.toLowerCase() === 'safari';
};

export const buildURI = (data: string) => {
  if (Platform.OS !== 'web') {
    return '';
  }

  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([data], { type });
  const dataURI = `data:${type};charset=utf-8;${data}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob);
};

export const downloadFile = (url: string, fileName: string) => {
  if (Platform.OS !== 'web') {
    return;
  }

  try {
    const link = document.createElement('a');
    const body = document.body || null;

    link.href = url;
    link.download = fileName;
    body.appendChild(link);
    link.click();
    body.removeChild(link);
  } catch (e) {
    console.log('Error initiating file download. fallback to window.open', e);
    if (window && typeof window.open === 'function') {
      window.open(url, '_blank');
    }
  }
};
