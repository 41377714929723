import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { Details } from './POS/Details';
import { KioskProfileDetails } from './Kiosk/Details';
import { Options } from './POS/Options';
import { Branding } from './Kiosk/Branding';
import { Workflow } from './POS/Workflow';
import { CustomerDisplay } from './POS/CustomerDisplay';
import { useCheckFeatureEnabled } from '../../../../../hooks/app/features/useCheckFeatureEnabled';
import { DeviceMode, FeatureIDs } from '@oolio-group/domain';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';
const Tab = createMaterialTopTabNavigator();

const DeviceProfilesTabs: React.FC = () => {
  const route = useRoute();
  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
    venueId: string;
  };
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isCdsEnabled = isFeatureEnabled(FeatureIDs.CDS);

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';
  const venueId = params.venueId || '';

  const { deviceProfiles } = useDeviceProfiles({
    deviceProfileId,
    storeId,
    venueId,
  });

  const deviceProfile = deviceProfiles[deviceProfileId];

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      backBehavior="none"
      initialRouteName="Details"
    >
      {deviceProfile?.mode === DeviceMode.KIOSK ? (
        <>
          <Tab.Screen
            name="Details"
            component={KioskProfileDetails}
            initialParams={params}
          />
          <Tab.Screen
            name="Branding"
            component={Branding}
            initialParams={params}
          />
        </>
      ) : (
        <>
          <Tab.Screen
            name="Details"
            component={Details}
            initialParams={params}
          />
          <Tab.Screen
            name="Options"
            component={Options}
            initialParams={params}
          />
          <Tab.Screen
            name="Workflow"
            component={Workflow}
            initialParams={params}
          />
          {isCdsEnabled && (
            <Tab.Screen
              name="Customer Display"
              component={CustomerDisplay}
              initialParams={params}
            />
          )}
        </>
      )}
    </Tab.Navigator>
  );
};

export default DeviceProfilesTabs;
