import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  useNotification,
  useNotificationsList,
} from '../../hooks/Notification';
import { NotificationList } from '../Notification/NotificationList';
import theme from '../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    opacity: 0.8,
    backgroundColor: theme.colors.black,
  },
});

const Backdrop: React.FC = () => {
  const { closeNotification } = useNotification();
  const notifications = useNotificationsList();

  return (
    <View style={styles.container}>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
    </View>
  );
};

export default Backdrop;
