import { StyleSheet } from 'react-native';
import theme from '../../../../../../../common/default-theme';

const styles = StyleSheet.create({
  cellJobRole: {
    width: 240,
    height: '100%',
  },
  cellLocation: {
    flex: 1,
    paddingHorizontal: 10,
  },
  popupContainer: {
    width: 260,
    ...theme.shadow.light,
    borderRadius: theme.radius.s,
  },
});

export default styles;
