/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { batchedUpdates } from '../utils/reactBatchedUpdates';

//  Custom hook that wraps a callback with batchedUpdates for performance optimization.
//  @param
//  @callback: Function to be batched.
//  @deps: Dependencies of the useCallback hook.
//  Returns a new function that executes the callback within a batchedUpdates call.
//  This will be removed once the new architecture is enabled as it handles the batching well.
export function useBatchedCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: any[],
): T {
  return useCallback(
    (...args: any[]) => {
      let result: ReturnType<T> = {} as ReturnType<T>;
      batchedUpdates(() => {
        result = callback(...args);
      });
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  ) as T;
}
