import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  OrderAction,
  OrderStatus,
  Order,
  OrderEvent,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { orderTotalItemsCount } from '../../../utils/cart';
import { useNotification } from '../../../hooks/Notification';
import { usePostSalesNavigation } from '../../../hooks/app/usePostSalesNavigation';
import TreatButton from '../../Shared/TreatButton/TreatButton';

export interface OnHoldPaymentActionsProps {
  order?: Order;
  isFromFunctionMap?: boolean;
  updateCart: <T extends OrderEvent>(
    action: OrderAction,
    input?: Omit<T, keyof OrderEvent>,
    eventId?: string,
    onSaveCompletedCallback?: (order: Order | null) => void,
  ) => void;
  openOrderCart: (orderId: string) => void;
}

const OnHoldPaymentActions: React.FC<OnHoldPaymentActionsProps> = ({
  order,
  updateCart,
  openOrderCart,
  isFromFunctionMap = false,
}: OnHoldPaymentActionsProps) => {
  const { closeModal } = useModal();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { navigateToPostSaleScreen } = usePostSalesNavigation();

  const goToNewOrder = useCallback(async () => {
    closeModal();
    navigateToPostSaleScreen();
  }, [navigateToPostSaleScreen, closeModal]);

  const finalizeOrder = useCallback(async () => {
    updateCart(OrderAction.REMOVE_ORDER_HOLD);
    await updateCart(OrderAction.ORDER_SAVE);
    showNotification({
      success: true,
      message: translate('order.holdOrderFinaliseSuccess'),
    });
    goToNewOrder();
  }, [updateCart, showNotification, translate, goToNewOrder]);

  const navigateToOrder = useCallback(async () => {
    if (order?.id) {
      navigation.navigate('TakeOrder', {
        id: order?.id,
        isCompleted: false,
        isExisting: true,
      });
      closeModal();
      openOrderCart(order?.id);
    }
  }, [navigation, openOrderCart, order?.id, closeModal]);

  if (order?.status !== OrderStatus.ON_HOLD) {
    // this component returns empty element if order is not on hold
    return <></>;
  }

  if (!isFromFunctionMap && order.amountDue !== 0) {
    // this component returns empty element if order amount due is not 0
    return <></>;
  }

  const orderItemsCount = orderTotalItemsCount(order);

  return (
    <View style={styles.container}>
      <TreatButton
        testID="btn-finalse"
        height={60}
        type="focus"
        label={`${translate('cart.finalise')} (${orderItemsCount})`}
        onPress={finalizeOrder}
        containerStyle={styles.btn}
      />
      <TreatButton
        testID="btn-addToOrder"
        height={60}
        type="neutral"
        label={translate('cart.addToOrder')}
        onPress={navigateToOrder}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={[styles.btn, { marginHorizontal: 10 }]}
      />
      <TreatButton
        testID="btn-newOrder"
        height={60}
        type="positive"
        label={translate('cart.newOrder')}
        onPress={goToNewOrder}
        containerStyle={styles.btn}
      />
    </View>
  );
};

export default OnHoldPaymentActions;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  btn: {
    flex: 1,
  },
});
