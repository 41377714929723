import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  DeviceProfile,
  CustomerDisplayImageType,
  UploadDeviceProfileCdsImageInput,
} from '@oolio-group/domain';
import { View, StyleSheet } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { ImageUploadInput } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import Message from '../../../../../../components/Office/Message/Message';
import ImagePicker from '../../../../../../components/Office/ImagePicker/ImagePicker';

export const Branding: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const route = useRoute();
  const [form, setForm] = useState({} as DeviceProfile);
  const [orgLogoRawData, setOrgLogoRawData] = useState<ImageUploadInput>();
  const [bgImageRawData, setBgImageRawData] = useState<ImageUploadInput>();

  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';

  const {
    deviceProfiles,
    loading,
    error,
    uploadDeviceProfileCustomerDisplayImage,
    updateDeviceProfileCustomerDisplay,
  } = useDeviceProfiles({ deviceProfileId, storeId });

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm({
        ...deviceProfiles[deviceProfileId],
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onPressSave = useCallback((): void => {
    const updateInput: UploadDeviceProfileCdsImageInput = {
      id: deviceProfileId,
      ...(orgLogoRawData && {
        orgLogoRawData: { ...orgLogoRawData },
      }),
      ...(bgImageRawData && {
        backgroundRawData: { ...bgImageRawData },
      }),
    };
    uploadDeviceProfileCustomerDisplayImage(updateInput);
  }, [
    deviceProfileId,
    orgLogoRawData,
    bgImageRawData,
    uploadDeviceProfileCustomerDisplayImage,
  ]);

  const onClear = useCallback(
    (dataType: CustomerDisplayImageType) => {
      if (form[dataType]) {
        const input = {
          id: deviceProfileId,
          [dataType]: null,
        };
        updateDeviceProfileCustomerDisplay(input);
      }
    },
    [deviceProfileId, form, updateDeviceProfileCustomerDisplay],
  );

  const logoContainer = useMemo((): JSX.Element => {
    return (
      <View style={styles.imageContainer}>
        <ImagePicker
          initials=""
          maxSize={2}
          imageUrl={orgLogoRawData?.base64 || form.orgLogo}
          onComplete={imageRawData => setOrgLogoRawData(imageRawData)}
          onClear={() => {
            onClear(CustomerDisplayImageType.orgLogo);
            if (orgLogoRawData) {
              setOrgLogoRawData(undefined);
            }
          }}
        />
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.orgLogo, orgLogoRawData?.base64]);

  const bgImageContainer = useMemo((): JSX.Element => {
    return (
      <ImagePicker
        large
        initials=""
        maxSize={3}
        imageUrl={bgImageRawData?.base64 || form.backgroundImage}
        onComplete={imageRawData => setBgImageRawData(imageRawData)}
        onClear={() => {
          onClear(CustomerDisplayImageType.backgroundImage);
          if (bgImageRawData) {
            setBgImageRawData(undefined);
          }
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.backgroundImage, bgImageRawData?.base64]);

  return (
    <ScreenLayout
      loading={loading}
      title="Kiosk Branding | Oolio"
      onSave={onPressSave}
    >
      <Section title="Business Logo">
        {logoContainer}
        <Message
          testID="message-logo"
          type="neutral"
          message={translate('backOfficeDeviceProfiles.recommendTranparentBg')}
          containerStyle={styles.messageContainer}
        />
      </Section>
      <Section title={translate('backOfficeDeviceProfiles.startScreenImage')}>
        {bgImageContainer}
        <Message
          testID="message-bg"
          type="neutral"
          message={translate('avatar.imageSizeDescription', {
            size: '1024x1366',
          })}
          containerStyle={styles.messageContainer}
        />
      </Section>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: 540,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  messageContainer: {
    marginVertical: 20,
  },
});
