/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import styles from '../HomeScreen.styles';
import theme from '../../../../common/default-theme';
import Section from '../../../../components/Office/Section/Section';
import HomeCard from '../../../../components/Office/HomeCard/HomeCard';

interface OtherSectionProps {
  onPressContact: () => void;
  onPressSupport: () => void;
}

const OtherSection: React.FC<OtherSectionProps> = ({
  onPressContact,
  onPressSupport,
}) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();

  return (
    <Section layoutWidth="medium">
      <View style={styles.header}>
        <Text style={styles.title}>
          {translate('home.doMoreTitle', {
            appName: translate('appName'),
          })}
        </Text>
        <Text style={styles.subtitle}>{translate('home.doMoreSubtitle')}</Text>
      </View>
      <View style={styles.grid}>
        <View style={styles.row}>
          <HomeCard
            testID="card-features"
            icon="CreateDashboard"
            color={theme.colors.orange}
            title={translate('home.featuresTitle')}
            description={translate('home.featuresSubtitle')}
            onPress={() =>
              navigation.navigate('Features', {
                screen: 'ManageFeatures',
              })
            }
            containerStyles={{ flex: 1 }}
          />
          <HomeCard
            testID="card-integrations"
            icon="BoltAlt"
            color={theme.colors.pink}
            title={translate('home.integrationsTitle')}
            description={translate('home.integrationsSubtitle')}
            onPress={() =>
              navigation.navigate('Features', {
                screen: 'ManageFeatures',
                params: {
                  screen: 'IntegrationsTab',
                },
              })
            }
            containerStyles={{ flex: 1 }}
          />
        </View>
        <View style={styles.row}>
          <HomeCard
            testID="card-contact"
            icon="PhoneVolume"
            color={theme.colors.green}
            title={translate('home.contactTitle')}
            description={translate('home.contactSubtitle')}
            onPress={onPressContact}
            containerStyles={{ flex: 1 }}
          />
          <HomeCard
            testID="card-support"
            icon="QuestionCircle"
            color={theme.colors.blue}
            title={translate('home.supportTitle')}
            description={translate('home.supportSubtitle', {
              appName: translate('appName'),
            })}
            onPress={onPressSupport}
            containerStyles={{ flex: 1 }}
          />
        </View>
      </View>
    </Section>
  );
};

export default OtherSection;
