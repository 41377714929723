import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { App, ClientAppPermission } from '@oolio-group/domain';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { useTranslation } from '@oolio-group/localization';
import arrayToTree from 'array-to-tree';
import {
  filterPermissionsBySearchTerm,
  jobRoleSettingsUtility,
  PermissionTreeNode,
} from '../../../../../state/jobRoleSettingsUtility';
import PermissionGroupHeader from './PermissionGroupHeader';
import PermissionTree from './PermissionTree';
import Accordion from 'react-native-collapsible/Accordion';
import theme from '../../../../../common/default-theme';

const OfficePermissions = () => {
  const { translate } = useTranslation();
  const [permissionSectionHeaders, setPermissionSectionHeaders] = useState<
    string[]
  >([]);
  const [collapsiblePermissions, setCollapsiblePermissions] = useState<
    ClientAppPermission[]
  >([]);
  const [nonCollapsiblePermissions, setNonCollapsiblePermissions] = useState<
    ClientAppPermission[]
  >([]);

  const renderHeader = (
    section: PermissionTreeNode,
    index: number,
    isActive: boolean,
  ) => {
    return <PermissionGroupHeader section={section} isActive={isActive} />;
  };

  const renderOfficeContent = (permission: PermissionTreeNode) => {
    return <PermissionTree permission={permission} />;
  };

  useEffect(() => {
    const excludedPermissions = [
      'Delete Products',
      'Pages',
      'Payments',
      'Adjustments',
      'Shifts & Devices',
      'Manage Permissions',
    ];
    const subscription: Subscription = combineLatest([
      jobRoleSettingsUtility.permissions$.pipe(
        map(permissions =>
          permissions.filter(
            permission =>
              permission.clientApp === App.BACKOFFICE &&
              !excludedPermissions.includes(permission.label),
          ),
        ),
      ),
      jobRoleSettingsUtility.search$,
    ]).subscribe(([officePermissions, searchTerm]) => {
      if (officePermissions?.length > 0) {
        const data = filterPermissionsBySearchTerm(
          officePermissions,
          searchTerm,
        );

        const tree = arrayToTree(data, {
          parentProperty: 'parent',
          customID: 'id',
        }).sort((a, b) => a.sortOrder - b.sortOrder);

        const permissionsTree = tree.filter(
          permission => permission.isPermissionGroup,
        );

        setCollapsiblePermissions(permissionsTree);
        // These are non-collapsible sections
        setNonCollapsiblePermissions(
          tree.filter(permission => !permission.isPermissionGroup),
        );
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const setOfficeSectionsOnClick = (sections: number[]) => {
    // As the library it self having wrong type definition for this using this
    setPermissionSectionHeaders(sections as unknown as string[]); // ["id of permission"]
  };

  return (
    <View style={styles.container}>
      <View style={theme.tables.header}>
        <Text style={[theme.tables.headerText, styles.cellPermission]}>
          {translate('backofficeManageRoles.permission')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellToggle]}>
          {translate('backofficeManageRoles.operations.enable')}
        </Text>
      </View>
      <View>
        <Accordion
          activeSections={permissionSectionHeaders}
          sections={collapsiblePermissions}
          touchableComponent={TouchableOpacity}
          renderHeader={renderHeader}
          renderContent={renderOfficeContent}
          duration={400}
          onChange={setOfficeSectionsOnClick}
          keyExtractor={permission => permission.id}
        />
      </View>
      <View>
        {nonCollapsiblePermissions.map(permission => {
          return (
            <View key={permission.id}>
              <PermissionTree permission={permission} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default OfficePermissions;

const styles = StyleSheet.create({
  container: {
    marginBottom: 40,
  },
  cellPermission: {
    flex: 1,
    paddingLeft: 38,
  },
  cellToggle: {
    width: 80,
    paddingRight: 20,
    textAlign: 'center',
  },
  cellView: {
    width: 80,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  cellUpdate: {
    width: 80,
    paddingRight: 20,
    textAlign: 'center',
  },
});
