import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useEffect, useState, useCallback } from 'react';
import {
  GET_MENUS_QUERY,
  GET_MENU_QUERY,
  CREATE_MENU,
  UPDATE_MENU,
  DELETE_MENU,
  COPY_MENU,
  GET_MENU_OPTIONS,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';
import {
  Catalogue,
  CreateCatalogueInput,
  UpdateCatalogueInput,
} from '@oolio-group/domain';
import { useNotification } from '../../Notification';
import { useTranslation } from '@oolio-group/localization';

export interface UseMenusProps {
  menus: Record<string, Catalogue>;
  error: string | undefined;
  loading: boolean;
  getMenus: () => void;
  createMenu: (input: CreateCatalogueInput) => void;
  copyMenu: (copyId: string) => void;
  updateMenu: (updateMenuInputs: UpdateCatalogueInput) => Promise<void>;
  deleteMenu: (id: string) => Promise<boolean | undefined>;
  getMenu: (id: string) => void;
  getMenusOptions: () => void;
}

export function useMenus(): UseMenusProps {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [menus, setMenus] = useState<Record<string, Catalogue>>({});

  const [getMenusRequest, getMenusRes] = useLazyQuery<{
    catalogues: Catalogue[];
  }>(GET_MENUS_QUERY, {
    onError: noopHandler,
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      setMenus(keyBy(response.catalogues, 'id'));
    },
  });
  const getMenus = useCallback(() => {
    getMenusRequest();
  }, [getMenusRequest]);

  const [getMenuReq, getMenuRes] = useLazyQuery<{ catalogue: Catalogue }>(
    GET_MENU_QUERY,
    {
      onError: noopHandler,
    },
  );

  const getMenu = useCallback(
    (menuId: string) => {
      getMenuReq({
        variables: {
          id: menuId,
        },
      });
    },
    [getMenuReq],
  );

  // get menu id and name only for drop down
  const onCompleteGetMenusOptionsRequest = useCallback(
    data => {
      if (data) {
        setMenus(keyBy(data.catalogues as Catalogue[], 'id'));
      }
    },
    [setMenus],
  );

  const [getMenusOptionsRequest, menusGetOptionsResponse] = useLazyQuery(
    GET_MENU_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetMenusOptionsRequest,
      onError: noopHandler,
    },
  );

  const getMenusOptions = useCallback(() => {
    getMenusOptionsRequest();
  }, [getMenusOptionsRequest]);

  const [createMenuRequest, createMenuResponse] = useMutation<{
    createCatalogue: Catalogue;
  }>(CREATE_MENU, {
    onError: noopHandler,
  });

  const createMenu = useCallback(
    (createMenuInput: CreateCatalogueInput) => {
      createMenuRequest({
        variables: {
          input: createMenuInput,
        },
      });
    },
    [createMenuRequest],
  );

  const [deleteMenuRequest, deleteMenuResponse] = useMutation<{
    deleteCatalogue: boolean;
  }>(DELETE_MENU, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('backOfficeSettings.successfullyDeleted'),
        success: true,
      });
    },
  });

  const deleteMenu = useCallback(
    async (id: string) => {
      const response = await deleteMenuRequest({
        variables: {
          id,
        },
      });
      return response.data?.deleteCatalogue;
    },
    [deleteMenuRequest],
  );

  useEffect(() => {
    if (createMenuResponse.data) {
      const newMenu = createMenuResponse.data.createCatalogue as Catalogue;
      setMenus(prev => ({ ...prev, [newMenu.id]: newMenu }));
    }
  }, [createMenuResponse.data]);

  const [copyMenuRequest, copyMenuResponse] = useMutation(COPY_MENU, {
    onError: noopHandler,
  });

  const copyMenu = useCallback(
    (copyId: string) => {
      copyMenuRequest({
        variables: {
          input: copyId,
        },
      });
    },
    [copyMenuRequest],
  );

  const [updateMenuRequest, updateMenusResponse] = useMutation<{
    updateCatalogue: Catalogue;
  }>(UPDATE_MENU, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('menus.menuUpdatedSuccessfully'),
        success: true,
      });
    },
  });

  const updateMenu = useCallback(
    async (updateMenuInput: UpdateCatalogueInput) => {
      await updateMenuRequest({
        variables: {
          input: updateMenuInput,
        },
      });
    },
    [updateMenuRequest],
  );

  useEffect(() => {
    if (updateMenusResponse.data) {
      const updatedMenu = updateMenusResponse?.data?.updateCatalogue;
      setMenus(prev => ({ ...prev, [updatedMenu?.id]: updatedMenu }));
    }
  }, [updateMenusResponse.data]);

  useEffect(() => {
    if (getMenuRes.data) {
      const menuData = getMenuRes.data.catalogue;
      menuData && setMenus(prev => ({ ...prev, [menuData.id]: menuData }));
    }
  }, [getMenuRes.data]);

  useEffect(() => {
    if (copyMenuResponse.data) {
      const newMenu = copyMenuResponse.data.cloneCatalogue as Catalogue;
      setMenus(prev => ({ ...prev, [newMenu.id]: newMenu }));
    }
  }, [copyMenuResponse.data]);

  const error: ApolloError | undefined =
    getMenusRes.error ||
    getMenuRes.error ||
    createMenuResponse.error ||
    updateMenusResponse.error ||
    deleteMenuResponse.error ||
    copyMenuResponse.error ||
    menusGetOptionsResponse.error;

  const loading: boolean =
    getMenusRes.loading ||
    getMenuRes.loading ||
    createMenuResponse.loading ||
    updateMenusResponse.loading ||
    deleteMenuResponse.loading ||
    copyMenuResponse.loading ||
    menusGetOptionsResponse.loading;

  return useMemo(
    () => ({
      menus,
      getMenus,
      createMenu,
      copyMenu,
      updateMenu,
      deleteMenu,
      getMenu,
      getMenusOptions,
      error: error ? parseApolloError(error) : undefined,
      loading,
    }),
    [
      menus,
      getMenus,
      createMenu,
      copyMenu,
      updateMenu,
      deleteMenu,
      getMenu,
      getMenusOptions,
      error,
      loading,
    ],
  );
}
