import {
  UpdateVenueInput,
  OnboardingCheckList,
  OnboardingAction,
  OnboardingArea,
  OnboardingSection,
  Address,
} from '@oolio-group/domain';
import { EmailReceipt } from '@oolio-group/domain';
import { Venue } from '@oolio-group/domain';
import React, { useState, useCallback, useEffect } from 'react';
import {
  useTranslation,
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCodeByCountryName,
  getFullFormattedPhoneNumber,
} from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  GET_VENUE_BY_ID_AND_TAXES_QUERY,
  UPDATE_VENUE_DETAILS,
  DELETE_VENUE,
} from '../../../../../graphql/venue';
import { Details } from './Form/DetailsForm';
import { isValidEmail, isValidPostCode } from '../../../../../utils/validator';
import { useOnboarding } from '../../../../../hooks/app/useOnboarding';
import { useSession } from '../../../../../hooks/app/useSession';
import { DEFAULT_COUNTRY_CODE } from '../../../../../constants';

export type VenueDetail = Venue & {
  countryCode: string;
  phoneNumber: string;
  phoneCountryCode: string;
};
export const DetailsContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const [session, setSession] = useSession();

  const { country: orgCountryCode = DEFAULT_COUNTRY_CODE } =
    session.currentOrganization || {};

  const { updateOnboardingStatus } = useOnboarding();
  const [venueDetails, setVenueDetails] = useState<VenueDetail>({
    receiptOptions: {
      printOptions: {
        printCustomer: false,
        printModifiersWithNoCharge: false,
        printNotes: false,
        printTips: false,
      },
    },
  } as VenueDetail);
  const params = route.params as {
    venueId: string;
  };

  const venueId = params.venueId || '';

  const venueDetailsQuery = useQuery(GET_VENUE_BY_ID_AND_TAXES_QUERY, {
    variables: { id: venueId },
    fetchPolicy: 'cache-and-network',
  });

  const [updateVenue, updatedVenue] = useMutation(UPDATE_VENUE_DETAILS, {
    onError: noopHandler,
    onCompleted: response =>
      response?.updateVenue?.id === session.currentVenue?.id &&
      setSession({
        ...session,
        currentVenue: {
          ...session.currentVenue,
          ...response?.updateVenue,
        },
      }),
  });

  const [deleteVenue, deletedVenue] = useMutation(DELETE_VENUE, {
    onError: noopHandler,
  });

  useEffect(() => {
    updateOnboardingStatus(
      OnboardingArea.SETTINGS,
      OnboardingSection.VIEW_VENUE_SETUP,
      OnboardingCheckList.VENUES,
      OnboardingAction.READ,
    );
  }, [updateOnboardingStatus]);

  useEffect((): void => {
    if (updatedVenue.error) {
      showNotification({
        error: true,
        message: parseApolloError(updatedVenue.error),
      });
    }
  }, [updatedVenue.error, showNotification]);

  useEffect((): void => {
    if (updatedVenue.data) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
    }
  }, [updatedVenue.data, showNotification, translate]);

  const removeVenueFromSession = useCallback(
    deletedVenueId => {
      if (session.user?.venues?.find(venue => venue.id == deletedVenueId)) {
        setSession({
          ...session,
          user: {
            ...session.user,
            venues: (session.user?.venues || []).filter(
              venue => venue.id != deletedVenueId,
            ),
          },
        });
      }
    },
    [session, setSession],
  );

  useEffect((): void => {
    if (deletedVenue.error) {
      showNotification({
        error: true,
        message: parseApolloError(deletedVenue.error),
      });
    }
  }, [deletedVenue.error, showNotification]);

  useEffect((): void => {
    if (
      deletedVenue.data &&
      session.user?.venues?.find(venue => venue.id == venueDetails.id)
    ) {
      removeVenueFromSession(venueDetails.id);
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyDeleted'),
      });
      navigation.navigate('VenuesStores', {});
    }
  }, [
    deletedVenue.data,
    showNotification,
    translate,
    navigation,
    removeVenueFromSession,
    venueDetails.id,
    session.user?.venues,
  ]);

  useEffect(() => {
    if (venueDetailsQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(venueDetailsQuery.error),
      });
    }
  }, [venueDetailsQuery.error, showNotification]);

  useEffect(() => {
    if (venueDetailsQuery.data) {
      const venueData = venueDetailsQuery.data.venue as Venue;

      const { address } = venueData;

      // this is for backward comparibility as venue data only has country field - name of country only
      const venueCountry =
        address?.isoCountryCode ||
        getCountryCodeByCountryName(venueData?.address?.country);

      const isoCountryCode = venueCountry || orgCountryCode || '';

      const {
        phoneNumber = '',
        countryCode: phoneCountryCode = isoCountryCode,
      } = parsePhoneNumber(venueData?.phone ?? '');

      setVenueDetails(prev => ({
        ...venueData,
        receiptOptions: {
          printOptions: {
            ...prev.receiptOptions.printOptions,
            ...venueData.receiptOptions?.printOptions,
          },
          emailReceipt:
            venueData.receiptOptions?.emailReceipt || EmailReceipt['NONE'],
        },
        address: {
          ...venueData?.address,
          isoCountryCode,
        },
        phoneNumber,
        countryCode: isoCountryCode,
        phoneCountryCode,
      }));
    }
  }, [orgCountryCode, venueDetailsQuery.data]);

  const onChange = useCallback((prop: string, value): void => {
    setVenueDetails(venueDetails => {
      return {
        ...venueDetails,
        [prop]: value,
      };
    });
  }, []);

  const onChangePrintOptions = useCallback((prop: string, value): void => {
    setVenueDetails(venueDetails => {
      return {
        ...venueDetails,
        receiptOptions: {
          ...venueDetails.receiptOptions,
          printOptions: {
            ...venueDetails.receiptOptions?.printOptions,
            [prop]: value,
          },
        },
      };
    });
  }, []);

  const onChangeEmailReceipt = useCallback((value): void => {
    setVenueDetails(venueDetails => {
      return {
        ...venueDetails,
        receiptOptions: {
          ...venueDetails.receiptOptions,
          emailReceipt: value,
        },
      };
    });
  }, []);

  const onPressDelete = useCallback((): void => {
    deleteVenue({ variables: { id: venueDetails.id } });
  }, [venueDetails, deleteVenue]);

  const onPressSave = useCallback((): void => {
    const { phoneNumber, address, name, email } = venueDetails;
    const phone = getFullFormattedPhoneNumber(
      venueDetails?.phoneCountryCode,
      phoneNumber,
    );
    if (name?.trim().length < 1) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'name' }),
      });
      return;
    } else if (!isValidEmail(email)) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'email' }),
      });
      return;
    } else if (!isValidPhoneNumber(phone)) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'phone' }),
      });
      return;
    } else if (
      !isValidPostCode(address?.postalCode, address?.isoCountryCode as string)
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'postal-code' }),
      });
      return;
    }

    const {
      line1,
      line2,
      city,
      suburb,
      state,
      postalCode,
      isoCountryCode,
      country,
    } = address || {};

    const updateInput: UpdateVenueInput = {
      id: venueId,
      email,
      phone,
      name,
      address: {
        line1,
        line2,
        city,
        suburb,
        state,
        postalCode,
        isoCountryCode,
        country,
      } as Address,
      isActive: venueDetails.isActive,
      defaultTax: venueDetails.defaultTax,
      receiptOptions: venueDetails.receiptOptions,
    };

    const removedTypeNameInput = stripProperties(updateInput, '__typename');
    updateVenue({
      variables: { input: removedTypeNameInput },
    });
  }, [venueDetails, venueId, updateVenue, showNotification, translate]);

  return (
    <Details
      testID="venueDetails"
      form={venueDetails}
      taxes={venueDetailsQuery?.data?.taxes || []}
      onChange={onChange}
      onChangeEmailReceipt={onChangeEmailReceipt}
      onChangePrintOptions={onChangePrintOptions}
      onPressDelete={onPressDelete}
      onPressSave={onPressSave}
      targetCountry={orgCountryCode}
    />
  );
};
