import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Section, Store } from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import theme from '../../../../../common/default-theme';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import Icon from '../../../../../components/Icon/Icon';
import styles from '../VenueSettings.styles';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface SectionRowProps {
  index: number;
  sectionData: Section;
  stores: Store[];
  onChange: (id: string, prop: string, value: string) => void;
  onStoresSelect: (id: string, values: string[]) => void;
  onSectionCopy: (section: Section) => void;
}

const SectionRow: React.FC<SectionRowProps> = ({
  index,
  sectionData,
  onChange,
  onStoresSelect,
  stores,
  onSectionCopy,
}: SectionRowProps) => {
  const navigation = useNavigation();

  const storeNamesData = stores.map(store => ({
    label: store.name,
    value: store.id,
  }));
  const selectedStores = sectionData.stores.map(store => store.id);

  return (
    <TouchableOpacity
      key={index}
      testID="section-row"
      style={theme.tables.row}
      onPress={(): void => {
        sectionData.id &&
          navigation.navigate('SectionSettings', {
            sectionId: sectionData.id,
            venueId: sectionData.venue.id,
          });
      }}
    >
      <InputText
        testID="input-name"
        value={sectionData.name}
        onChangeText={onChange.bind(null, sectionData.id, 'name')}
        placeholder="Section name..."
        containerStyle={styles.cellName}
      />
      <SelectMultiple
        testID="select-stores"
        selectedValues={selectedStores}
        options={storeNamesData}
        onValueChange={values => onStoresSelect(sectionData.id, values)}
        containerStyle={styles.cellNote}
      />
      <View testID="table-count" style={styles.countContainer}>
        <Text>{sectionData.tables.length}</Text>
      </View>
      <ButtonIcon
        testID="btn-copy"
        type="neutralLight"
        icon="copy"
        onPress={() => onSectionCopy(sectionData)}
      />
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey6} />
      </View>
    </TouchableOpacity>
  );
};

export default SectionRow;
