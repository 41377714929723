import { useTranslation } from '@oolio-group/localization';
import React, { useCallback, useState } from 'react';
import {
  View,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  Text,
} from 'react-native';
import InputEmail from '../../../../components/Shared/Inputs/InputEmail';
import { isValidEmail } from '../../../../utils/validator';
import { useModal } from '@oolio-group/rn-use-modal';
import { styles } from './CustomerStatementAction.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import {
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from 'date-fns';
import { PRESETS } from '../../../../utils/dateHelper';
import SelectDate from '../../../../components/Shared/Select/SelectDate';
import { paramCase } from 'change-case';
const STATEMENT_PRESETS = [
  PRESETS.THIS_WEEK,
  PRESETS.LAST_WEEK,
  PRESETS.THIS_MONTH,
  PRESETS.LAST_MONTH,
];

interface CustomerStatementActionModalProps {
  email: string;
  sendStatement: (email: string, startDate: Date, endDate: Date) => void;
}

const CustomerStatementActionModal: React.FC<
  CustomerStatementActionModalProps
> = ({ email: customerEmail, sendStatement }) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();

  const [email, setEmail] = useState<string>(customerEmail || '');

  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));
  const isEmailValid = isValidEmail(email);

  const onPressPreset = useCallback(
    (preset: string) => {
      if (!isEmailValid) return;
      const today = startOfDay(new Date());
      let startDateTime = startDate,
        endDateTime = endDate;
      switch (preset) {
        case PRESETS.THIS_WEEK:
          startDateTime = startOfWeek(today, { weekStartsOn: 1 });
          endDateTime = endOfWeek(today, { weekStartsOn: 1 });
          break;
        case PRESETS.LAST_WEEK:
          startDateTime = startOfWeek(subDays(today, 7), {
            weekStartsOn: 1,
          });
          endDateTime = endOfWeek(subDays(today, 7), { weekStartsOn: 1 });
          break;
        case PRESETS.THIS_MONTH:
          startDateTime = startOfMonth(today);
          endDateTime = endOfMonth(today);
          break;
        case PRESETS.LAST_MONTH:
          startDateTime = startOfMonth(subMonths(today, 1));
          endDateTime = endOfMonth(subMonths(today, 1));
          break;
      }
      sendStatement(email, startDateTime, endDateTime);
      closeModal();
    },
    [isEmailValid, closeModal, sendStatement, email, startDate, endDate],
  );

  const onChangeText = (value: string): void => {
    setEmail(value);
  };

  const onChangeDate = (day: 'startDate' | 'endDate', date?: Date) => {
    if (!date) return;
    if (day === 'startDate') {
      setStartDate(startOfDay(date));
      if (date.valueOf() > endDate.valueOf()) {
        setEndDate(endOfDay(date));
      }
    } else if (day === 'endDate') {
      setEndDate(endOfDay(date));
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={styles.keyboardAvoidingViewStyle}
    >
      <View style={styles.modalStyle}>
        <View style={styles.title}>
          <TouchableOpacity
            testID="btn-close"
            style={styles.btnClose}
            onPress={closeModal}
          >
            <Icon name="times" size={20} color={theme.colors.dark} />
          </TouchableOpacity>
          <Text style={styles.titleText}>
            {translate('onAccount.sendStatement')}
          </Text>
        </View>

        <View style={styles.containerStyle}>
          <View style={styles.inputContainerStyle}>
            <InputEmail
              title={translate('common.emailAddress')}
              autoFocus={true}
              testID="email-address"
              containerStyle={styles.emailTextInputStyle}
              placeholder={translate('common.emailPlaceholder')}
              value={email || ''}
              onChangeText={(text: string) => onChangeText(text)}
            />
          </View>

          <View>
            <SelectDate
              testID="date-start"
              title={translate('backOfficeReports.header.startDate')}
              valueDate={startDate}
              maxDate={endOfDay(new Date())}
              onChangeDate={date => onChangeDate('startDate', date)}
              containerStyle={styles.inputDate}
            />
            <SelectDate
              testID="date-end"
              title={translate('backOfficeReports.header.endDate')}
              valueDate={endDate}
              minDate={startOfDay(startDate)}
              maxDate={endOfDay(new Date())}
              onChangeDate={date => onChangeDate('endDate', date)}
              containerStyle={styles.inputDate}
            />
          </View>

          <View style={styles.presets}>
            {STATEMENT_PRESETS.map((preset, i) => {
              const id = `btn-preset-${paramCase(preset)}`;

              return (
                <TreatButton
                  key={id}
                  testID={id}
                  label={preset}
                  type="neutralLight"
                  onPress={(): void => onPressPreset(preset)}
                  disabled={!isEmailValid}
                  containerStyle={[
                    styles.btnPreset,
                    // eslint-disable-next-line react-native/no-inline-styles
                    { marginRight: i % 2 === 0 ? 10 : 0 },
                  ]}
                />
              );
            })}
          </View>

          <View style={styles.buttonContainerStyle}>
            <TreatButton
              label={translate('button.send')}
              type="neutral"
              disabled={!isEmailValid}
              testID="send-receipt"
              containerStyle={styles.buttonStyle}
              onPress={() => sendStatement(email, startDate, endDate)}
            />
          </View>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default CustomerStatementActionModal;
