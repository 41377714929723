import React, { FC } from 'react';
import { View, ViewStyle } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './CartTotals.styles';
import { CartTotalRow } from './CartTotals';

interface AccountTotalsProps {
  creditLimit: number;
  orderLimit: number;
  balance: number;
  containerStyle?: ViewStyle | ViewStyle[];
}

const AccountTotals: FC<AccountTotalsProps> = ({
  creditLimit,
  orderLimit,
  balance,
  containerStyle,
}) => {
  const { translate } = useTranslation();

  return (
    <View style={[styles.container, containerStyle]}>
      <CartTotalRow
        testID="credit-limit"
        name={translate('payment.creditLimit')}
        value={creditLimit}
      />
      <CartTotalRow
        testID="order-limit"
        name={translate('payment.orderLimit')}
        value={orderLimit}
      />
      <CartTotalRow
        major
        isNegative={balance < 0}
        testID="account-balance"
        name={translate('onAccount.accountBalance')}
        value={balance}
      />
    </View>
  );
};

export default AccountTotals;
