import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../common/default-theme';

const textStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  textAlign: 'center',
  color: theme.colors.black2,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  screen: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: 620,
    padding: 40,
    paddingTop: 60,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.elevated1,
    zIndex: 2,
  },
  form: {
    paddingTop: 60,
  },
  title: {
    ...textStyle,
    maxWidth: '80%',
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
  subtitle: {
    ...textStyle,
    marginVertical: 20,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: 10,
    marginTop: 40,
    zIndex: -1,
  },
});

export default styles;
