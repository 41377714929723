import { getPreferredStorage } from './getPreferredStorage';

export const storage = getPreferredStorage();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const subscriptions: Record<string, Array<(item: any) => void>> = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let cache: Record<string, any> = {};

export async function getItem<T>(key: string): Promise<T | undefined> {
  if (cache[key]) {
    return cache[key];
  }

  const data = await storage.getItem(key);

  if (data && typeof data === 'string') {
    const value = JSON.parse(data) as T;
    cache[key] = value;
    return value;
  }

  return undefined;
}

/**
 * List of all keys currently in storage
 */
export async function getAllKeys(): Promise<string[]> {
  if (typeof storage.getAllKeys === 'function') {
    return await storage.getAllKeys();
  }
  return Object.keys(storage);
}

export async function setItem<T>(key: string, item: T): Promise<void> {
  storage.setItem(key, JSON.stringify(item));

  cache[key] = item;

  if (subscriptions[key] && subscriptions[key].length) {
    subscriptions[key].forEach(callback => {
      callback(item);
    });
  }
}

export function removeItem(key: string) {
  cache[key] = undefined;
  storage.removeItem(key);
}

export async function clear() {
  cache = {};
  await storage.clear();
}

export function addSubscription<T>(key: string, callback: (item: T) => void) {
  subscriptions[key] = subscriptions[key] || [];
  subscriptions[key].push(callback);
}

export function removeSubscription<T>(
  key: string,
  callback: (item: T) => void,
) {
  const index = (subscriptions[key] || []).indexOf(callback);

  if (index > -1) {
    subscriptions[key].splice(index, 1);
  }
}
