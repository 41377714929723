import { Order, PrinterProfileType, PrinterSeries } from '@oolio-group/domain';
import { validatePrintConfigurationOrInput } from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintTransferItemsSummaryDocketWorkerInput,
  WorkerInput,
} from '../../utils';
import { getPrintableBuffer } from '../../../utils/printerTemplates/transferItemsSummaryDocketLayout';
import { groupItemsByPrinterProfile } from '../../../utils/printerTemplates/kitchenReceiptLayout';

export const transferActionSummaryDocketHandler = (
  message: WorkerInput,
): BufferHandlerResult[] => {
  const results: BufferHandlerResult[] = [];
  const {
    printerTemplateMapping,
    session,
    orderItems = [],
    fromTables = [],
    toTables = [],
    transferredByName,
  } = message.data as PrintTransferItemsSummaryDocketWorkerInput;

  const itemsByPrinterProfiles = groupItemsByPrinterProfile(orderItems);

  if (Object.keys(itemsByPrinterProfiles).length === 0) {
    return [];
  }

  const printerProfileIds = Object.keys(itemsByPrinterProfiles);

  const data = validatePrintConfigurationOrInput(
    { orderItems } as Order,
    {
      ...printerTemplateMapping,
      [PrinterProfileType.KITCHEN]: printerProfileIds.reduce(
        (acc, printerProfileId) => {
          return {
            ...acc,
            [printerProfileId]:
              printerTemplateMapping[PrinterProfileType.KITCHEN][
                printerProfileId
              ],
          };
        },
        {},
      ),
    },
    PrinterProfileType.KITCHEN,
  );

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const kitchenPrinterProfiles =
    printerTemplateMapping[PrinterProfileType.KITCHEN];

  printerProfileIds
    .filter(
      printerProfileId => itemsByPrinterProfiles[printerProfileId]?.length,
    )
    .forEach(printerProfileId => {
      if (!kitchenPrinterProfiles[printerProfileId]) {
        return;
      }
      const { printer, profile } = kitchenPrinterProfiles[printerProfileId];

      const locale = profile.locale;

      const printablePartialOrders: (Buffer | undefined)[] = [];
      const printItems = itemsByPrinterProfiles[printerProfileId];

      const fromTableName = fromTables.filter(name => !!name).join(', ');
      const toTableName = toTables.filter(name => !!name).join(', ');
      if (printItems.length) {
        printablePartialOrders.push(
          getPrintableBuffer({
            printItems,
            session,
            locale,
            fromTableName,
            toTableName,
            printerSeries: printer.series || PrinterSeries.TM_M30II,
            staffName: transferredByName,
          }),
        );
      }

      printablePartialOrders.forEach(printablePartialOrder => {
        printablePartialOrder &&
          results.push({
            printer: {
              id: printer.id,
              device_name: printer.name,
              host: printer.ipAddress,
              port: DEFAULT_NET_PRINTER_PORT,
              type: printer.printerType,
              bdAddress: printer.bdAddress,
              slug: printer.slug,
              series: printer.series,
              brand: printer.brand,
            },
            buffer: printablePartialOrder,
          });
      });
    });

  return results;
};
