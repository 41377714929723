/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView } from 'react-native';
import { Category } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import sortBy from 'lodash/sortBy';
import styles from './Modals.styles';
import theme from '../../../../../../common/default-theme';
import modalStyles from '../../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import Search from '../../../../../../components/Shared/Search/Search';
import { sentenceCase } from 'change-case';

interface FormatCategoryItem {
  name: string;
  id: string;
  numOfProducts: number;
}

const convertArrayToMaps = (values: string[], status: boolean) => {
  return values.reduce((acc, id) => {
    acc[id] = status;
    return acc;
  }, {} as Record<string, boolean>);
};
export interface AddFromCategoryProps {
  onImportCategories: (selectedCategoryIds: string[]) => void;
  categoryMaps: Record<string, Category>;
  selectedCategoryIds: string[];
}

const AddFromCategory: React.FC<AddFromCategoryProps> = ({
  categoryMaps,
  onImportCategories,
  selectedCategoryIds: selectedCategoryIdsProp,
}) => {
  const { translate } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const [selectedCategoryMaps, setSelectedCategoryMaps] = useState<
    Record<string, boolean>
  >({});

  const { closeModal } = useModal();

  const selectedCategoryIds = useMemo(
    () =>
      Object.keys(selectedCategoryMaps).filter(id => selectedCategoryMaps[id]),
    [selectedCategoryMaps],
  );

  const onImportCategoryToPage = useCallback(() => {
    onImportCategories(selectedCategoryIds);
    closeModal();
  }, [closeModal, onImportCategories, selectedCategoryIds]);

  const categories = useMemo(() => {
    const sortedCategories = sortBy(Object.values(categoryMaps), category =>
      category.name?.toLowerCase(),
    );

    return sortedCategories.map(
      ({ products = [], variants = [], name, id }) => ({
        name,
        id,
        numOfProducts: products.length + variants.length,
      }),
    ) as FormatCategoryItem[];
  }, [categoryMaps]);

  const onSelectCategory = useCallback((id: string) => {
    setSelectedCategoryMaps(pre => ({ ...pre, [id]: !pre[id] }));
  }, []);

  useEffect(() => {
    // controlled mode
    setSelectedCategoryMaps(convertArrayToMaps(selectedCategoryIdsProp, true));
  }, [selectedCategoryIdsProp]);

  const filteredCategories = useMemo(
    () =>
      categories.filter(item =>
        item.name?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [categories, searchValue],
  );

  const visibleIds = useMemo(
    () => filteredCategories.map(item => item.id),
    [filteredCategories],
  );

  const isAllVisibleItemsSelected = useMemo(
    () => visibleIds.every(id => selectedCategoryMaps[id]),
    [selectedCategoryMaps, visibleIds],
  );

  const onPressSelectAllItem = useCallback(() => {
    setSelectedCategoryMaps(pre => ({
      ...pre,
      ...convertArrayToMaps(visibleIds, !isAllVisibleItemsSelected),
    }));
  }, [isAllVisibleItemsSelected, visibleIds]);

  const isPartiallySelected = useMemo(
    () => visibleIds.some(id => selectedCategoryMaps[id]),
    [selectedCategoryMaps, visibleIds],
  );

  return (
    <View style={styles.background}>
      <KeyboardAvoidingView
        behavior="padding"
        style={[styles.container, { width: 600 }]}
      >
        <View style={modalStyles.title}>
          <Text style={styles.titleText}>
            {translate('menus.addProductsFromCategories')}
          </Text>
        </View>
        <View style={styles.content}>
          <Search
            testID="search-products"
            value={searchValue}
            onChangeText={setSearchValue}
            placeholder={translate('menus.searchCategoriesByName')}
            containerStyle={styles.input}
          />
          <View>
            <View style={[theme.tables.header, { paddingLeft: 0 }]}>
              <InputToggle
                testID="toggle-all"
                isToggled={isAllVisibleItemsSelected}
                isPartial={isPartiallySelected && !isAllVisibleItemsSelected}
                onToggle={onPressSelectAllItem}
              />
              <Text style={theme.tables.headerText}>
                {`${translate('backOfficeProducts.category')} (${translate(
                  'backOfficeProducts.products',
                )})`}
              </Text>
            </View>
            <ScrollView style={styles.table}>
              {filteredCategories.length == 0 ? (
                <View style={theme.tables.emptyView}>
                  <Text>{translate('menus.noResultFound')}</Text>
                </View>
              ) : (
                filteredCategories.map((category, i) => {
                  return (
                    <View key={i} style={styles.tableRow}>
                      <InputToggle
                        testID="select-product"
                        isToggled={selectedCategoryMaps[category.id]}
                        onToggle={onSelectCategory.bind(
                          null,
                          category?.id as string,
                        )}
                      />
                      <Text>{`${sentenceCase(category.name)} (${
                        category?.numOfProducts
                      })`}</Text>
                    </View>
                  );
                })
              )}
            </ScrollView>
          </View>
        </View>
        <View style={modalStyles.actions}>
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
          <TreatButton
            type="neutral"
            testID="btn-confirm"
            onPress={onImportCategoryToPage}
            label={translate('menus.updateMenuWithItems', {
              numOfItems: selectedCategoryIds.length
                ? `(${selectedCategoryIds.length})`
                : '',
            })}
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default AddFromCategory;
