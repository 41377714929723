import {
  Order,
  OrderItemStatus,
  OrderPaymentStatus,
} from '@oolio-group/domain';
import { limitDecimalCount } from '@oolio-group/order-helper';

export const orderTotalItemsCount = (order: Order) => {
  return limitDecimalCount(
    order.orderItems.reduce((prev, curr) => curr.quantity + prev, 0),
  );
};

/**
 * Returns wheter to perform save action on order or not
 * @param order
 * @returns true -
 * if order has some items which are not sent to kitchen(which are to be sent)
 * and had some payment attempts(for card payment cases) made
 * and no payment is in pending state
 * @returns false - otherwise
 * @returns false - if order is not present
 */
export const canPerformSaveAction = (order: Order) => {
  if (!order) return false;
  return (
    order.orderItems?.some(
      item =>
        !item.itemFired &&
        (item.printerProfiles?.length ||
          item.product?.printerProfiles?.length) &&
        item.status !== OrderItemStatus.ON_HOLD,
    ) &&
    order.payments?.length &&
    order.payments?.every(
      payment => payment.status !== OrderPaymentStatus.PENDING,
    )
  );
};
