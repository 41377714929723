import React, { useEffect, useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  CreateAndUpdateEarningRule,
  EarningRule,
  RewardRule,
  CreateAndUpdateRewardRule,
  LoyaltySettings,
  EarningType,
} from '@oolio-group/domain';
import { useLoyalty } from '../../../../hooks/app/loyalty/useLoyalty';
import { useModal } from '@oolio-group/rn-use-modal';
import { useVenues } from '../../../../hooks/app/useVenues';
import { trimString } from '../../../../utils/validator';
import { useNotification } from '../../../../hooks/Notification';
import { isEqual, intersection } from 'lodash';
import CreateAndEditEarningPointModal from './Modals/EarningRuleModal';
import CreateAndEditRewardRuleModal from './Modals/RewardRuleModal';
import EarningRuleRow from './Rows/EarningRuleRow';
import RewardRuleRow from './Rows/RewardRuleRow';
import styles from './Settings.styles';
import theme from '../../../../common/default-theme';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import InputText from '../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

const LoyaltySettingScreen: React.FC = () => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const [loyaltySettings, setLoyaltySettings] = useState<
    Partial<LoyaltySettings>
  >({});

  const { showNotification } = useNotification();
  const {
    getLoyaltyPrograms,
    loyaltySettings: loyaltySettingsProp,
    earningRules,
    rewardRules,
    loading,
    createAndUpdateEarningRule,
    createAndUpdateRewardRules,
    deleteEarningRule,
    deleteRewardRule,
    updateLoyaltySetting,
  } = useLoyalty();
  const { getVenues, venues } = useVenues();

  useEffect(() => {
    getLoyaltyPrograms();
    getVenues();
  }, [getLoyaltyPrograms, getVenues]);

  useEffect(() => {
    if (loyaltySettingsProp) setLoyaltySettings(loyaltySettingsProp);
  }, [loyaltySettingsProp]);

  /**
   * Do earning rule validation base on earning type
   * @param earningRule the rule to be verified
   */
  const isValidEarningRule = useCallback(
    (earningRule: CreateAndUpdateEarningRule) => {
      const otherRules = earningRules.filter(
        rule => rule.id !== earningRule.id,
      );

      // do not allow a single venue to have multiple types of earning
      const hasMultipleTypes = otherRules.some(
        rule =>
          intersection(rule.venueIds, earningRule.venueIds).length &&
          rule.earningType !== earningRule.earningType,
      );
      if (hasMultipleTypes) {
        showNotification({
          error: true,
          message: translate('backOfficeLoyalty.multipleEarningTypesError'),
        });
        return false;
      }

      // validation varies base on the actual type
      switch (earningRule.earningType) {
        case EarningType.EARN_BY_AMOUNT:
          // do not allow a venue to contain multiple rules of type EARN_BY_AMOUNT
          const duplicatedVenue = otherRules.some(
            rule =>
              rule.earningType === EarningType.EARN_BY_AMOUNT &&
              intersection(rule.venueIds, earningRule.venueIds).length,
          );

          if (duplicatedVenue) {
            showNotification({
              error: true,
              message: translate('customerLoyalty.duplicateEarningRuleError'),
            });
            return false;
          }
          return true;
        case EarningType.EARN_BY_PURCHASE_ITEM:
          // do not allow a product to be on multiple rules of type EARN_BY_PURCHASE_ITEM per venue
          const duplicatedProduct = otherRules.some(
            rule =>
              rule.earningType === EarningType.EARN_BY_PURCHASE_ITEM &&
              intersection(rule.venueIds, earningRule.venueIds).length &&
              intersection(
                rule.products.map(product => product.id),
                earningRule.products,
              ).length,
          );

          if (duplicatedProduct) {
            showNotification({
              error: true,
              message: translate('backOfficeLoyalty.duplicatedProductsError'),
            });
            return false;
          }
          return true;
        default:
          // return false for unknown type
          return false;
      }
    },
    [earningRules, showNotification, translate],
  );

  const onCreateOrUpdateEarningRule = useCallback(
    (updateEarningRule: CreateAndUpdateEarningRule) => {
      if (isValidEarningRule(updateEarningRule)) {
        closeModal();
        createAndUpdateEarningRule(updateEarningRule);
      }
    },
    [closeModal, createAndUpdateEarningRule, isValidEarningRule],
  );
  const onCreateOrEditEarningRule = useCallback(
    (selectedItem?: EarningRule) => {
      const editingValue = selectedItem
        ? {
            ...selectedItem,
            venueIds: selectedItem?.venueIds || [],
            earningPoint: selectedItem.earningPoint.toString(),
            amountSpend:
              selectedItem.earningType === EarningType.EARN_BY_AMOUNT
                ? selectedItem.amountSpend.toString()
                : undefined,
          }
        : undefined;
      showModal(
        <CreateAndEditEarningPointModal
          allVenues={Object.values(venues)}
          editingEarningRuleData={editingValue}
          onCreateAndUpdate={onCreateOrUpdateEarningRule}
          loyaltySettings={loyaltySettingsProp}
        />,
      );
    },
    [onCreateOrUpdateEarningRule, showModal, venues, loyaltySettingsProp],
  );

  const onCreateAndUpdateRewardRule = useCallback(
    (rewardRuleData: CreateAndUpdateRewardRule) => {
      closeModal();
      createAndUpdateRewardRules(rewardRuleData);
    },
    [createAndUpdateRewardRules, closeModal],
  );

  const onCreateOrEditRewardRule = useCallback(
    (selectedItem?: RewardRule) => {
      const editingRewardRule = selectedItem
        ? {
            ...selectedItem,
            venueIds: selectedItem?.venueIds || [],
            pointsRequired: selectedItem.pointsRequired.toString(),
            discountAmount: (
              ('discountAmount' in selectedItem &&
                selectedItem?.discountAmount) ||
              ''
            ).toString(),
            maximumDiscountAmount: (
              ('maximumDiscountAmount' in selectedItem &&
                selectedItem?.maximumDiscountAmount) ||
              ''
            ).toString(),
          }
        : undefined;
      showModal(
        <CreateAndEditRewardRuleModal
          allVenues={Object.values(venues)}
          onCreateAndUpdate={onCreateAndUpdateRewardRule}
          editingRewardRule={editingRewardRule}
          loyalSettings={loyaltySettingsProp}
        />,
      );
    },
    [onCreateAndUpdateRewardRule, showModal, venues, loyaltySettingsProp],
  );

  const onDeleteRuleItem = useCallback(
    (id: string, type: 'earningType' | 'rewardType') => {
      const onConfirmDeleteRule = () => {
        closeModal();
        if (type === 'earningType') {
          deleteEarningRule(id);
        } else {
          deleteRewardRule(id);
        }
      };
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate(
            type === 'earningType'
              ? 'backOfficeLoyalty.deleteEarningRule'
              : 'backOfficeLoyalty.deleteRewardRule',
          )}
          onConfirm={onConfirmDeleteRule}
        />,
      );
    },
    [closeModal, deleteEarningRule, deleteRewardRule, showModal, translate],
  );

  const onChangeLoyalSettings = useCallback((key: string, value: string) => {
    setLoyaltySettings(pre => ({
      ...pre,
      [key]: value,
    }));
  }, []);

  const onPressSave = useCallback(() => {
    const { singularTerm, pluralTerm } = loyaltySettings;
    if (!trimString(singularTerm || '') || !trimString(pluralTerm || '')) {
      showNotification({
        message: translate('loyaltySetting.singularTermAndPluralTermMsg'),
        error: true,
      });
      return;
    }
    updateLoyaltySetting(loyaltySettings);
  }, [loyaltySettings, showNotification, translate, updateLoyaltySetting]);

  const hasSettingsChanges = !isEqual(loyaltySettings, loyaltySettingsProp);

  return (
    <ScreenLayout
      loading={loading}
      title="Loyalty | Oolio"
      onSave={onPressSave}
      hideFooter={!hasSettingsChanges}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="input-singularTerm"
            title={translate('backOfficeLoyalty.singularTerm')}
            value={loyaltySettings?.singularTerm}
            placeholder={translate('backOfficeLoyalty.singularTerm')}
            onChangeText={onChangeLoyalSettings.bind(null, 'singularTerm')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-pluralTerm"
            title={translate('backOfficeLoyalty.pluralTerm')}
            value={loyaltySettings?.pluralTerm}
            placeholder={translate('backOfficeLoyalty.pluralTerm')}
            onChangeText={onChangeLoyalSettings.bind(null, 'pluralTerm')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title="Earning Rules">
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.colName]}>
              {translate('backOfficeLoyalty.description')}
            </Text>
            <Text style={[theme.tables.headerText, styles.colPoints]}>
              {loyaltySettingsProp?.pluralTerm || 'Points'}
            </Text>
          </View>
          <View>
            {earningRules?.map((rule: EarningRule, i: number) => (
              <EarningRuleRow
                key={i}
                item={rule}
                onEdit={() => onCreateOrEditEarningRule(rule)}
                pluralTerm={loyaltySettingsProp?.pluralTerm || 'Points'}
                singularTerm={loyaltySettingsProp?.singularTerm || 'Point'}
                onDeleteItem={() => onDeleteRuleItem(rule.id, 'earningType')}
                disabled={earningRules.length == 1}
              />
            ))}
          </View>
        </View>
        <ButtonIcon
          testID="btn-addEarning"
          type="positive"
          icon="plus"
          onPress={() => onCreateOrEditEarningRule()}
          containerStyle={styles.btnAdd}
        />
      </Section>
      <Section title="Reward Rules">
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.colName]}>
              {translate('backOfficeLoyalty.description')}
            </Text>
            <Text style={[theme.tables.headerText, styles.colPoints]}>
              {loyaltySettingsProp?.pluralTerm || 'Points'}
            </Text>
          </View>
          <View>
            {rewardRules?.map((rule: RewardRule, i: number) => (
              <RewardRuleRow
                key={i}
                item={rule}
                onEdit={() => onCreateOrEditRewardRule(rule)}
                pluralTerm={loyaltySettingsProp?.pluralTerm || 'Points'}
                singularTerm={loyaltySettingsProp?.singularTerm || 'Point'}
                onDeleteItem={() => onDeleteRuleItem(rule.id, 'rewardType')}
                disabled={rewardRules.length === 1}
              />
            ))}
          </View>
        </View>
        <ButtonIcon
          testID="btn-addReward"
          type="positive"
          icon="plus"
          onPress={() => onCreateOrEditRewardRule()}
          containerStyle={styles.btnAdd}
        />
      </Section>
    </ScreenLayout>
  );
};

export default LoyaltySettingScreen;
