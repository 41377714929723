import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import styles from '../HomeScreen.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import Section from '../../../../components/Office/Section/Section';
import { analyticsService } from '../../../../analytics/AnalyticsService';

const ShortcutButton = ({ onPress, label, color }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.btnShortcut, { backgroundColor: color }]}
  >
    <Text style={styles.shortcutLabel} numberOfLines={1}>
      {label}
    </Text>
    <Icon name="AngleRight" color={theme.colors.white} size={20} />
  </TouchableOpacity>
);

const Shortcuts: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();

  const handlePress = (button, screen, params = {}) => {
    analyticsService.capture('home_link', { button });
    navigation.navigate(screen, params);
  };

  return (
    <Section layoutWidth="medium">
      <View style={styles.header}>
        <Text style={styles.title}>{translate('home.quickLinksTitle')}</Text>
        <Text style={styles.subtitle}>
          {translate('home.quickLinksSubtitle')}
        </Text>
      </View>
      <View style={styles.shortcuts}>
        <ShortcutButton
          onPress={() =>
            handlePress('Add Product', 'Products', { screen: 'Manage' })
          }
          label={translate('home.addProduct')}
          color={theme.colors.purple}
        />
        <ShortcutButton
          onPress={() =>
            handlePress('Add Modifier', 'Products', {
              screen: 'Options',
              params: { screen: 'ModifierTab' },
            })
          }
          label={translate('home.addModifier')}
          color={theme.colors.indigo}
        />
        <ShortcutButton
          onPress={() =>
            handlePress('Add User', 'Users', { screen: 'ManageUsers' })
          }
          label={translate('home.addUser')}
          color={theme.colors.lightBlue}
        />
        <ShortcutButton
          onPress={() =>
            handlePress('Edit Menu', 'Products', { screen: 'Menus' })
          }
          label={translate('home.editMenu')}
          color={theme.colors.green}
        />
      </View>
    </Section>
  );
};

export default Shortcuts;
