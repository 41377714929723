import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { View, Text } from 'react-native';
import {
  IntegrationApps,
  InvoiceExportStatus,
  PaymentType,
  ProductType,
  Tax,
  AccountingIntegrationStorePreferences,
  IntegrationPartnerStoreConfiguration,
  FinancialAccount,
  IntegrationStorePreferences,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import { useNotification } from '../../../../../../hooks/Notification';
import { useAccountingIntegration } from '../../../../../../hooks/app/accountingIntegrations/useAccountingIntegration';
import { usePaymentTypes } from '../../../../../../hooks/app/usePaymentTypes';
import { useTaxes } from '../../../../../../hooks/app/useTaxes';
import { useProductTypes } from '../../../../../../hooks/app/useProductTypes';
import { set, cloneDeep } from 'lodash';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import theme from '../../../../../../common/default-theme';
import styles from './StoreSettings.styles';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatPicker, {
  PickerOption,
} from '../../../../../../components/Shared/Select/Picker';
import { excludeOnAccountPaymentType } from '@oolio-group/client-utils';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import { isValidEmail } from '../../../../../../utils/validator';

export const StoreSettings: React.FC = () => {
  const { translate } = useTranslation();

  const route = useRoute();
  const params = route.params as {
    storeId: string;
    app: IntegrationApps;
  };

  const storeId = params.storeId;

  const {
    accountingIntegrationStoresSettings,
    getAccountingIntegrationStoresSettings,
    loading,
    chartOfAccounts,
    getChartOfAccounts,
    taxRates,
    getTaxRates,
    updateAccountingIntegrationStoreSettings,
  } = useAccountingIntegration();

  const { paymentTypes } = usePaymentTypes();
  const { taxes } = useTaxes();
  const { productTypes, getProductTypes } = useProductTypes();
  const { showNotification } = useNotification();

  const [storeSettings, setStoreSettings] =
    useState<IntegrationPartnerStoreConfiguration>();

  const didUpdate = useRef<boolean>(false);

  const filteredPaymentTypes = useMemo(
    () => excludeOnAccountPaymentType(paymentTypes),
    [paymentTypes],
  );

  useEffect(() => {
    getProductTypes();
  }, [getProductTypes]);

  useEffect(() => {
    if (accountingIntegrationStoresSettings?.[0]) {
      if (didUpdate.current) {
        didUpdate.current = false;
        showNotification({
          success: true,
          message: translate('common.changesSaved'),
        });
      }
      setStoreSettings(cloneDeep(accountingIntegrationStoresSettings?.[0]));
    }
  }, [accountingIntegrationStoresSettings, showNotification, translate]);

  useEffect(() => {
    if (storeId) {
      getAccountingIntegrationStoresSettings([storeId], params.app);
    }
  }, [storeId, getAccountingIntegrationStoresSettings, params]);

  useEffect(() => {
    getChartOfAccounts();
  }, [getChartOfAccounts]);

  useEffect(() => {
    getTaxRates(params.app);
  }, [getTaxRates, params]);

  const saveAccountingIntegrationStoreSettings = useCallback(() => {
    if (storeSettings) {
      const updatedStoreSettings = stripProperties(
        { ...storeSettings },
        '__typename',
        'preferences.__typename',
      );
      didUpdate.current = true;
      updateAccountingIntegrationStoreSettings(updatedStoreSettings);
    }
  }, [storeSettings, updateAccountingIntegrationStoreSettings]);

  const onChangeStorePreferences = useCallback(
    (prop: string, value: string | string[]) => {
      setStoreSettings(storeSettings => {
        if (storeSettings) {
          const defaultPreferences = {
            taxCodes: {},
            paymentTypes: {},
          } as AccountingIntegrationStorePreferences;
          const updatedStoreSettings = {
            ...storeSettings,
            preferences: {
              ...(storeSettings.preferences || defaultPreferences),
            },
          };
          set(updatedStoreSettings.preferences, prop, value);
          return updatedStoreSettings;
        }
      });
    },
    [],
  );

  const invoiceExportStatuses = useMemo(
    () => [
      {
        value: InvoiceExportStatus.Approved,
        label: translate('backOfficeFeatures.approved'),
      },
      {
        value: InvoiceExportStatus.Draft,
        label: translate('backOfficeFeatures.draft'),
      },
    ],
    [translate],
  );

  const mapAccountsToOptions = useCallback(
    (accounts: FinancialAccount[]) => {
      return [
        {
          label: translate('backOfficeFeatures.select'),
          value: '',
        },
        ...accounts.map(({ code, name, type }) => {
          return {
            label: translate('backOfficeFeatures.accountOption', {
              code,
              name,
              type,
            }),
            value: code,
          };
        }),
      ];
    },
    [translate],
  );

  const chartOfAccountsOptions = mapAccountsToOptions(chartOfAccounts);
  const paymentTypeChartOfAccountsOptions = mapAccountsToOptions(
    chartOfAccounts.filter(({ enablePaymentsToAccount, type }) => {
      // because accounts of type bank have enabledPayementsToAccount always set to false
      // because xero-node package cannot be resolved, so the property has to be converted to string for comparison
      return enablePaymentsToAccount || `${type}` == 'BANK';
    }),
  );

  const mapTaxRatesToOptions = useMemo(() => {
    return [
      {
        label: translate('backOfficeFeatures.select'),
        value: '',
      },
      ...taxRates.map(({ name, effectiveRate, taxType }) => {
        const label = `${name} (${effectiveRate})`;
        return {
          label,
          value: taxType,
        };
      }),
    ] as PickerOption[];
  }, [taxRates, translate]);

  const isValueMissing = useCallback(
    (
      preferedData: Record<string, string> | undefined,
      filteredData: PaymentType[] | Tax[] = [],
    ) => {
      if (!preferedData) {
        return false;
      }

      const idsToCheck = filteredData.map(data => data.id);
      return idsToCheck.some(id => !preferedData?.[id]);
    },
    [],
  );

  const hasRequiredFields = useMemo(() => {
    const requiredFields = [
      'defaultIncomeAccount',
      'varianceAccount',
      'moneyAccount',
      'tipsAccount',
      'cashRoundingAccount',
    ];

    const storePreferences =
      storeSettings?.preferences as AccountingIntegrationStorePreferences;

    const accountMissingFields = requiredFields.reduce((acc, f) => {
      if (acc) return true;
      else if (
        storePreferences &&
        !storePreferences?.accountsMap?.[f as keyof IntegrationStorePreferences]
      ) {
        return true;
      } else {
        return false;
      }
    }, false);

    const isTaxMissing = isValueMissing(
      storePreferences?.taxCodes,
      Object.values(taxes),
    );
    const isPaymentTypeMissing = isValueMissing(
      storePreferences?.paymentTypes,
      filteredPaymentTypes,
    );

    const isDisabled =
      accountMissingFields ||
      !storePreferences?.invoicePrefix ||
      isTaxMissing ||
      isPaymentTypeMissing;
    return isDisabled;
  }, [filteredPaymentTypes, isValueMissing, storeSettings?.preferences, taxes]);

  const storePreferences =
    storeSettings?.preferences as AccountingIntegrationStorePreferences;

  const onChangeEmail = useCallback(
    (emails: string[]): void => {
      const emailsToUpdate = [...new Set(emails ?? [])];
      onChangeStorePreferences('emailPreferences', emailsToUpdate);
    },
    [onChangeStorePreferences],
  );

  const preferredEmails = useMemo(
    () =>
      (storeSettings?.preferences as AccountingIntegrationStorePreferences)
        ?.emailPreferences ?? [],
    [storeSettings?.preferences],
  );

  const onAddEmail = useCallback(
    (emailId: string): void => {
      if (!isValidEmail(emailId)) {
        showNotification({
          error: true,
          message: translate('storesSettings.invalidEmailFormat'),
        });
        return;
      }

      onChangeStorePreferences('emailPreferences', [
        ...preferredEmails,
        emailId,
      ]);
    },
    [onChangeStorePreferences, showNotification, translate, preferredEmails],
  );

  return (
    <ScreenLayout
      loading={loading}
      title="Xero Settings | Oolio"
      onSave={saveAccountingIntegrationStoreSettings}
      onSaveDisabled={hasRequiredFields}
    >
      <Section title={translate('backOfficeFeatures.invoicePrefixes')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-prefix"
            title={translate('backOfficeFeatures.invoicePrefix')}
            value={storePreferences?.invoicePrefix?.toString()}
            placeholder={storePreferences?.invoicePrefix?.toString()}
            onChangeText={value =>
              onChangeStorePreferences('invoicePrefix', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-status"
            options={invoiceExportStatuses}
            title={translate('backOfficeFeatures.reconciliationInvoiceStatus')}
            selectedValue={storePreferences?.defaultInvoiceStatus}
            onValueChange={value =>
              onChangeStorePreferences('defaultInvoiceStatus', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeFeatures.defaultAccounts')}>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-incomeAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.defaultIncomeAccount')}
            selectedValue={storePreferences?.accountsMap?.defaultIncomeAccount}
            onValueChange={value =>
              onChangeStorePreferences(
                'accountsMap.defaultIncomeAccount',
                value,
              )
            }
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-paySurchargeAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.paymentSurchargeAccount')}
            selectedValue={
              storePreferences?.accountsMap?.paymentSurchargeAccount
            }
            onValueChange={value =>
              onChangeStorePreferences(
                'accountsMap.paymentSurchargeAccount',
                value,
              )
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-varianceAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.varianceAccount')}
            selectedValue={storePreferences?.accountsMap?.varianceAccount}
            onValueChange={value =>
              onChangeStorePreferences('accountsMap.varianceAccount', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-moneyAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.moneyAccount')}
            selectedValue={storePreferences?.accountsMap?.moneyAccount}
            onValueChange={value =>
              onChangeStorePreferences('accountsMap.moneyAccount', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-tipsAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.tipsAccount')}
            selectedValue={storePreferences?.accountsMap?.tipsAccount}
            onValueChange={value =>
              onChangeStorePreferences('accountsMap.tipsAccount', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-cashRoundingAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.cashRoundingExpense')}
            selectedValue={storePreferences?.accountsMap?.cashRoundingAccount}
            onValueChange={value =>
              onChangeStorePreferences('accountsMap.cashRoundingAccount', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>

        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-balanceAdjustmentAccount"
            options={chartOfAccountsOptions}
            title={translate('backOfficeFeatures.balanceAdjustmentAccount')}
            selectedValue={
              storePreferences?.accountsMap?.balanceAdjustmentAccount
            }
            onValueChange={value =>
              onChangeStorePreferences(
                'accountsMap.balanceAdjustmentAccount',
                value,
              )
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeFeatures.incomeTaxCodes')}>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerMapAccount]}>
              {translate('hourlySales.Taxes')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerXeroAccount]}>
              {translate('backOfficeFeatures.xeroAccount')}
            </Text>
          </View>
          <View>
            {Object.values(taxes)?.map(({ id, name }: Tax, i: number) => (
              <View key={i} style={theme.tables.row}>
                <Text style={styles.cellMapAccount}>{name + '*'}</Text>
                <TreatPicker
                  testID="select-"
                  options={mapTaxRatesToOptions}
                  selectedValue={storePreferences?.taxCodes?.[id]}
                  onValueChange={value =>
                    onChangeStorePreferences(`taxCodes[${id}]`, value)
                  }
                  containerStyle={styles.cellXeroAccount}
                />
              </View>
            ))}
          </View>
        </View>
      </Section>
      <Section title={translate('backOfficeSettings.paymentTypes')}>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerMapAccount]}>
              {translate('orderHistory.paymentType')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerXeroAccount]}>
              {translate('backOfficeFeatures.xeroAccount')}
            </Text>
          </View>
          <View>
            {Object.values(filteredPaymentTypes)?.map(
              ({ id, name }: PaymentType, i: number) => (
                <View key={i} style={theme.tables.row}>
                  <Text style={styles.cellMapAccount}>{name + '*'}</Text>
                  <TreatPicker
                    testID="dropdown"
                    options={paymentTypeChartOfAccountsOptions}
                    selectedValue={storePreferences?.paymentTypes?.[id]}
                    onValueChange={value =>
                      onChangeStorePreferences(`paymentTypes[${id}]`, value)
                    }
                    containerStyle={styles.cellXeroAccount}
                  />
                </View>
              ),
            )}
          </View>
        </View>
      </Section>
      <Section title={translate('backOfficeSettings.productTypes')}>
        <View>
          <View style={styles.productTypesContainer}>
            <Text style={[theme.tables.headerText, styles.headerMapAccount]}>
              {translate('productSettings.productType')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerXeroAccount]}>
              {translate('backOfficeFeatures.xeroAccount')}
            </Text>
          </View>
          <View>
            {Object.values(productTypes)?.map(
              ({ id, name }: ProductType, i: number) => (
                <View key={i} style={theme.tables.row}>
                  <Text style={styles.cellMapAccount}>{name}</Text>
                  <TreatPicker
                    testID="dropdown"
                    options={chartOfAccountsOptions}
                    selectedValue={storePreferences?.productTypes?.[id]}
                    onValueChange={value =>
                      onChangeStorePreferences(`productTypes[${id}]`, value)
                    }
                    containerStyle={styles.cellXeroAccount}
                  />
                </View>
              ),
            )}
          </View>
          <Section title={translate('storesSettings.emailAddresses')}>
            <View>
              <SelectMultiple
                testID="select-emails"
                options={preferredEmails.map(email => ({
                  value: email,
                  label: email,
                }))}
                selectedValues={preferredEmails}
                onValueChange={onChangeEmail}
                onAddItem={onAddEmail}
              />
            </View>
          </Section>
        </View>
      </Section>
    </ScreenLayout>
    //   <View style={css(styles.actionsContainerStyle)}>
    //     <Button
    //       fluid
    //       testID="refresh-accounts"
    //       title={translate('backOfficeFeatures.refreshAccounts')}
    //       containerStyle={css(styles.refreshAccountsButtonStyle)}
    //       labelStyle={css(styles.refreshAccountsTextStyle)}
    //       onPress={refreshAccounts}
    //     />
    //   </View>
  );
};
