import React from 'react';
import {
  GestureResponderEvent,
  ViewStyle,
  TextStyle,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { StyleFn, StyleFnProps, RenderProps } from '@oolio-group/domain';
import { FelaComponent } from 'react-fela';
import theme from '../../common/default-theme';
import Icon from '../Icon/Icon';

const iconButtonStyle: StyleFn = ({
  theme,
  containerSize,
  circular,
  color,
  fluid,
}: StyleFnProps) => ({
  height: containerSize,
  width: containerSize,
  flex: fluid ? 1 : null,
  borderRadius: circular ? 70 : null,
  backgroundColor: color ? theme.colors[color] : null,
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
});

export interface IconButtonProps {
  testID?: string;
  icon: string;
  iconSize?: number;
  containerSize?: number;
  onPress?: (event?: GestureResponderEvent) => void;
  danger?: boolean;
  success?: boolean;
  circular?: boolean;
  primary?: boolean;
  secondary?: boolean;
  color?: string;
  fluid?: boolean;
  disabled?: boolean;
  iconStyle?: TextStyle;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
  text?: string;
  iconColor?: string;
  isLoading?: boolean;
  onLongPress?: ((event: GestureResponderEvent) => void) | undefined;
}

const IconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => (
  <FelaComponent style={iconButtonStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <TouchableOpacity
        testID={props.testID}
        onPress={props.onPress}
        disabled={props.disabled}
        style={[style, props.containerStyle]}
        onLongPress={props.onLongPress}
      >
        {props.isLoading ? (
          <ActivityIndicator color={theme.colors.light} />
        ) : (
          <>
            {props.text && <Text style={props.textStyle}>{props.text}</Text>}
            <Icon
              size={props.iconSize}
              name={props.icon}
              primary={props.primary}
              success={props.success}
              danger={props.danger}
              secondary={props.secondary}
              style={props.iconStyle}
              disabled={props.disabled}
              color={props.iconColor}
            />
          </>
        )}
      </TouchableOpacity>
    )}
  </FelaComponent>
);

IconButton.defaultProps = {
  iconSize: 24,
};

export default IconButton;
