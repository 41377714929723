import { EscPos } from '@tillpos/xml-escpos-helper';

export const cashDrawerTemplate = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <open-cash-drawer />
  </document>
  `;

export const getPrintableBuffer = () => {
  // Generate buffer
  return EscPos.getBufferFromTemplate(
    cashDrawerTemplate,
    {},
  ) as unknown as Buffer;
};
