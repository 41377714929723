import React, { useCallback, useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { ProductAllergens, AllergensKey } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import styles from './AllergensModal.styles';
import InputToggle from '../../../Shared/Inputs/InputToggle';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

interface AllergensModalProps {
  allergens?: AllergensKey[];
  setAllergensToOrder: (allergens: AllergensKey[]) => void;
}

const AllergensModal = ({
  allergens = [],
  setAllergensToOrder,
}: AllergensModalProps) => {
  const [selectedAllergens, setSelectedAllergens] =
    useState<AllergensKey[]>(allergens);
  const { translate } = useTranslation();
  const { closeModal } = useModal();

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const clearAllergens = useCallback(() => {
    setSelectedAllergens([]);
  }, []);

  const onApplyFilter = () => {
    setAllergensToOrder(selectedAllergens);
  };

  const setAllergens = useCallback(
    (allergen: AllergensKey) => {
      const isExist = selectedAllergens.includes(allergen);
      if (!isExist) {
        setSelectedAllergens([...selectedAllergens, allergen]);
      } else {
        const filteredAllergens = selectedAllergens.filter(a => a !== allergen);
        setSelectedAllergens(filteredAllergens);
      }
    },
    [selectedAllergens],
  );

  const onToggleAllergens = useCallback(
    (allergen: AllergensKey) => {
      setAllergens(allergen);
    },
    [setAllergens],
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onCloseModal} style={styles.btnClose}>
        <Icon name="times" size={20} color={theme.colors.dark} />
      </TouchableOpacity>
      <View style={styles.headerContainer}>
        <Text style={[styles.headerText, styles.filterText]}>
          {translate('modal.filterAllergens')}
        </Text>
        <TouchableOpacity
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ top: 12 }}
          onPress={clearAllergens}
        >
          <Text style={[styles.headerText, styles.clearFilterText]}>
            {translate('modal.clearFilters')}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.allergensContainer}>
        {Object.keys(ProductAllergens).map(allergenKey => {
          const key = allergenKey as AllergensKey;
          const isSelected = selectedAllergens.includes(key);
          return (
            <InputToggle
              testID={allergenKey}
              key={allergenKey}
              isToggled={selectedAllergens.includes(key)}
              title={ProductAllergens[key]}
              onToggle={() => onToggleAllergens(key)}
              isPartial={selectedAllergens.includes(key)}
              containerStyle={[
                styles.toggleInputStyle,
                {
                  backgroundColor: isSelected
                    ? theme.colors.redLight
                    : theme.colors.grey1,
                },
              ]}
              direction="rtl"
              partialIconColor={isSelected ? theme.colors.red : ''}
            />
          );
        })}
      </View>
      <View style={styles.btnContainer}>
        <TreatButton
          label={translate('productSettings.applyFilters')}
          type="positive"
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ width: 140, marginRight: 15 }}
          onPress={onApplyFilter}
        />
      </View>
    </View>
  );
};

export default AllergensModal;
