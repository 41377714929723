import React, { useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';

interface AssignReportingGroupProps {
  onSubmit: (selectedProductType: string) => void;
  productTypeOptions: { label: string; value: string }[];
  countOfproducts: number;
}

export const AssignReportingGroup: React.FC<AssignReportingGroupProps> = ({
  onSubmit,
  productTypeOptions,
}: AssignReportingGroupProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const defaultPT = productTypeOptions?.[0]?.value || '';
  const [selectedPT, setSelectedPT] = useState(defaultPT);

  return (
    <TreatModal
      title={translate('productBulkOperations.assignProductType')}
      onConfirm={{
        label: translate('productBulkOperations.assign'),
        action: () => onSubmit(selectedPT),
      }}
      onDismiss={{ action: closeModal }}
    >
      <TreatPicker
        testID="select-reportingGroup"
        title={translate('productBulkOperations.selectProductType')}
        options={productTypeOptions}
        selectedValue={selectedPT}
        onValueChange={val => setSelectedPT(val)}
      />
    </TreatModal>
  );
};
