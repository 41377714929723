import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    marginBottom: 4,
    paddingHorizontal: 14,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.light,
  },
  content: {
    minHeight: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  disclosure: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  value: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  },
  icon: {
    marginLeft: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.light,
  },
  actionLabel: {
    fontSize: 14,
    fontFamily: theme.fonts.medium,
  },
  errorView: {
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: theme.colors.grey3,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.negative,
  },
});

export const getValueStyle = (error?: string, value?: string) => {
  return {
    ...styles.value,
    color: error
      ? theme.colors.states.negative
      : value === 'OK'
      ? theme.colors.states.positive
      : theme.colors.grey6,
  };
};

export const getTitleStyle = (error?: string) => {
  return {
    ...styles.title,
    color: error ? theme.colors.states.negative : theme.colors.dark,
  };
};

export default styles;
