import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { App } from '@oolio-group/domain';
import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { REACT_APP_API_URL } from 'react-native-dotenv';
import { v4 as uuidV4 } from 'uuid';
import { AuthState, auth0Config } from '../../constants';
import * as settings from '../../state/preferences';
import { tokenUtility } from '../../state/tokenUtility';
import { noopHandler } from '../../utils/errorHandlers';

export const SERVICE_URI =
  process.env['REACT_APP_API_URL'] ||
  REACT_APP_API_URL ||
  'http://localhost:4000';

interface AuthHeader {
  Authorization?: string;
  organization?: string;
  venue?: string;
  store?: string;
  deviceProfile?: string;
  device?: string;
  App?: App;
}

export const tokenLookup = async (): Promise<AuthHeader> => {
  const [session, tokenState] = await Promise.all([
    settings.getSession(),
    settings.getAuthenticationState(),
  ]);

  return {
    Authorization: tokenState?.token,
    venue: session?.currentVenue?.id || '',
    store: session?.currentStore?.id || '',
    organization: session?.currentOrganization?.id || '',
    device: session?.device?.id || '',
    deviceProfile: session?.deviceProfile?.id || '',
    App: tokenState?.activeApp,
  };
};

export const withToken = setContext((_, { headers }) => {
  return tokenLookup().then(tokenHeaders => ({
    headers: {
      ...tokenHeaders,
      ...headers,
    },
  }));
});

export const withRequestId = setContext((_, { headers }) => {
  return {
    headers: {
      'x-request-id': uuidV4(),
      ...headers,
    },
  };
});

enum ErrorCodes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export const resetToken = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    if (
      graphQLErrors.some(
        gqlError => gqlError?.extensions?.code === ErrorCodes.UNAUTHENTICATED,
      )
    ) {
      clearSession();
    }
  }
});

export const clearSession = async () => {
  await settings.setSession({});
  await settings.setAuthenticationState({});
  tokenUtility.clearToken();
};

/**
 * This will only validate or handle refresh token related errors
 */
export const refreshSession = new TokenRefreshLink({
  isTokenValidOrUndefined: async () => {
    const tokenState = await settings.getAuthenticationState();
    if (
      tokenState?.token &&
      tokenState?.expiresAfter &&
      tokenState?.expiresAfter > settings.getExpiresAfter(0)
    ) {
      return true;
    }
    return false;
  },
  fetchAccessToken: async () => {
    const tokenState = await settings.getAuthenticationState();
    const tokenRequest = new TokenRequest({
      client_id: auth0Config.clientId,
      redirect_uri: auth0Config.redirectUrl,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: tokenState?.refreshToken,
    });
    const config = await AuthorizationServiceConfiguration.fetchFromIssuer(
      auth0Config.issuer || '',
      new FetchRequestor(),
    );
    return new BaseTokenRequestHandler(
      new FetchRequestor(),
    ).performTokenRequest(config, tokenRequest) as unknown as Promise<Response>;
  },
  handleResponse: () => async (response: Response) => {
    const { accessToken, refreshToken, expiresIn } =
      response as unknown as TokenResponse;
    const oldSession = await settings.getSession();

    // update new access token
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newTokenPayload: any = {
      token: accessToken as string,
      refreshToken: refreshToken as string,
      expiresAfter: settings.getExpiresAfter(expiresIn as number),
      authState: AuthState.LOGGED_IN,
    };
    tokenUtility.setTokenInfo(newTokenPayload);
    await settings.setSession({
      ...oldSession,
      ...newTokenPayload,
      expiresIn: expiresIn as number,
    });
    return {
      access_token: accessToken,
    };
  },
  handleFetch: noopHandler,
  handleError: async error => {
    // logout user
    console.error('FAIL TO REFRESH USER TOKEN', error);
    await clearSession();
  },
});

export const authFlowLink = [
  withRequestId,
  refreshSession,
  withToken,
  resetToken,
];
