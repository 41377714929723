import React, { useCallback } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { useLogout } from '../../../../hooks/app/useLogout';
import { useSession } from '../../../../hooks/app/useSession';
import { useIntercom } from '../../../../hooks/Intercom/useIntercom';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import styles from './NavigationFooter.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import Logo from '../../../Logo/Logo';

const NavigationFooter: React.FC = () => {
  const { logout } = useLogout();
  const Intercom = useIntercom();
  const [session] = useSession();

  const onPressLogout = useCallback(async () => {
    analyticsService.capture('navigation', {
      event: 'Pressed Logout',
    });
    await logout();
  }, [logout]);

  const onPressSupport = useCallback(() => {
    analyticsService.capture('navigation', {
      event: 'Pressed Support',
    });
    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          ...Platform.select({
            web: {
              companyId: session?.currentOrganization?.id || '',
            },
            native: {
              id: session?.currentOrganization?.id || '',
            },
          }),
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
  }, [Intercom, session]);

  return (
    <View style={styles.container}>
      <View style={styles.buttons}>
        <TouchableOpacity
          testID="btn-logout"
          onPress={onPressLogout}
          style={[styles.btn, styles.btnLogout]}
        >
          <Icon
            size={16}
            name="lock-alt"
            color={theme.colors.states.negative}
          />
        </TouchableOpacity>
        <TouchableOpacity
          testID="btn-support"
          onPress={onPressSupport}
          style={[styles.btn, styles.btnSupport]}
        >
          <Icon
            size={16}
            name="question-circle"
            color={theme.colors.states.neutral}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.btn}>
        <Logo width={18} height={18} color="light" />
      </View>
    </View>
  );
};

export default NavigationFooter;
