import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { getModifierGroupQuery, MODIFIER_GROUPS_AS_OPTIONS } from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import {
  CreateModifierGroupInput,
  ModifierGroup,
  UpdateModifierGroupInput,
  CloneModifierGroupInput,
} from '@oolio-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';

export interface UseModifierGroupsProps {
  modifierGroups: { [key: string]: ModifierGroup };
  createModifierGroups: (
    createModifierGroupsInput: CreateModifierGroupInput[],
  ) => void;
  error: string | undefined;
  loading: boolean;
  operation: Operation;
  createdIds: string[];
  getAllModifierGroups: () => void;
  deleteModifierGroups: (ids: string[]) => Promise<void>;
  updateModifierGroups: (data: UpdateModifierGroupInput[]) => void;
  cloneModifierGroup: (input: CloneModifierGroupInput) => void;
  getModifierGroupById: (modifierGroupId: string) => void;
}

export function useModifierGroups(
  modifierGroupId?: string,
  customFragment?: string,
): UseModifierGroupsProps {
  const [modifierGroups, setModifierGroups] = useState<
    Record<string, ModifierGroup>
  >({});

  const {
    GET_MODIFIER_GROUPS_QUERY,
    CREATE_MODIFIER_GROUPS,
    GET_MODIFIER_GROUP_QUERY,
    DELETE_MODIFIER_GROUPS,
    UPDATE_MODIFIER_GROUPS,
    CLONE_MODIFIER_GROUP,
  } = getModifierGroupQuery(customFragment);

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const [createdIds, setCreatedIds] = useState<string[]>([]);

  const onCompleteGetModifierGroupsRequest = useCallback(
    ({ modifierGroups }: { modifierGroups: ModifierGroup[] }) => {
      setModifierGroups(
        modifierGroups.reduce((acc, modifierGroup) => {
          acc[modifierGroup.id] = modifierGroup;
          return acc;
        }, {}),
      );
    },
    [],
  );

  const [getModifierGroups, modifierGroupsGetRequest] = useLazyQuery(
    GET_MODIFIER_GROUPS_QUERY,
    {
      // TODO: fix after apolo client 3.0 merged (cache issue)
      fetchPolicy: 'no-cache',
      onError: noopHandler,
      onCompleted: onCompleteGetModifierGroupsRequest,
    },
  );

  const [getModifierGroup, modifierGroupGetRequest] = useLazyQuery(
    GET_MODIFIER_GROUP_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
    },
  );

  const [createModifierGroups, createModifierGroupsRequest] = useMutation(
    CREATE_MODIFIER_GROUPS,
    {
      onError: noopHandler,
    },
  );

  const [deleteModifierGroups, deleteModifierGroupsRequest] = useMutation(
    DELETE_MODIFIER_GROUPS,
    {
      onError: noopHandler,
    },
  );

  const [updateModifierGroups, updateModifierGroupsRequest] = useMutation(
    UPDATE_MODIFIER_GROUPS,
    {
      onError: noopHandler,
    },
  );

  const [cloneModifierGroup, cloneModifierGroupRequest] = useMutation(
    CLONE_MODIFIER_GROUP,
    {
      onError: noopHandler,
    },
  );

  const setModifierGroupsDataToState = useCallback(
    (modifierGroups: ModifierGroup[]) => {
      setModifierGroups(prev => {
        const tempModifierGroups = { ...prev };
        modifierGroups.forEach(eachModifierGroup => {
          tempModifierGroups[eachModifierGroup.id] = eachModifierGroup;
        });
        return tempModifierGroups;
      });
    },
    [],
  );

  useEffect(() => {
    if (modifierGroupId) {
      getModifierGroup({ variables: { id: modifierGroupId } });
      setOperation(Operation.READ);
    }
  }, [getModifierGroups, modifierGroupId, getModifierGroup]);

  useEffect(() => {
    if (modifierGroupsGetRequest.data) {
      const modifierGroupsData = modifierGroupsGetRequest.data
        .modifierGroups as ModifierGroup[];

      setModifierGroupsDataToState(modifierGroupsData);
    }
  }, [modifierGroupsGetRequest.data, setModifierGroupsDataToState]);

  const getAllModifierGroups = useCallback(() => {
    getModifierGroups();
    setOperation(Operation.READ);
  }, [getModifierGroups]);

  const createModifierGroupsData = useCallback(
    (modifierGroups: CreateModifierGroupInput[]) => {
      createModifierGroups({
        variables: {
          input: modifierGroups,
        },
      });
      setOperation(Operation.CREATE);
      setCreatedIds([]);
    },
    [createModifierGroups],
  );

  useEffect(() => {
    if (createModifierGroupsRequest.data) {
      const createdModifierGroupsData = createModifierGroupsRequest.data
        .createModifierGroups as ModifierGroup[];

      setModifierGroupsDataToState(createdModifierGroupsData);
      setCreatedIds(createdModifierGroupsData.map(x => x.id));
    }
  }, [createModifierGroupsRequest.data, setModifierGroupsDataToState]);

  useEffect(() => {
    if (modifierGroupGetRequest.data) {
      const modifierGroupsData = modifierGroupGetRequest.data
        .modifierGroup as ModifierGroup;
      setModifierGroupsDataToState([modifierGroupsData]);
    }
  }, [modifierGroupGetRequest, setModifierGroupsDataToState]);

  const deleteModifierGroupsData = useCallback(
    async (ids: string[]) => {
      await deleteModifierGroups({
        variables: {
          ids,
        },
      });
      setOperation(Operation.DELETE);
      setDeletedIds(ids);
    },
    [deleteModifierGroups],
  );

  useEffect(() => {
    if (deleteModifierGroupsRequest.data) {
      setModifierGroups(prev => {
        const tempModGroups = { ...prev };
        deletedIds.forEach(x => delete tempModGroups[x]);
        return tempModGroups;
      });
    }
  }, [deletedIds, deleteModifierGroupsRequest.data]);

  useEffect(() => {
    if (updateModifierGroupsRequest.data) {
      const updatedData = updateModifierGroupsRequest.data
        .updateModifierGroups as ModifierGroup[];

      setModifierGroupsDataToState(updatedData);
    }
  }, [updateModifierGroupsRequest.data, setModifierGroupsDataToState]);

  const updateModifierGroupsData = useCallback(
    (updateModGroupData: UpdateModifierGroupInput[]) => {
      updateModifierGroups({
        variables: {
          input: updateModGroupData,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateModifierGroups],
  );

  useEffect(() => {
    if (cloneModifierGroupRequest.data) {
      const clonedModifierGroup = cloneModifierGroupRequest.data
        .cloneModifierGroup as ModifierGroup;
      setModifierGroupsDataToState([clonedModifierGroup]);
      setCreatedIds([clonedModifierGroup.id]);
    }
  }, [cloneModifierGroupRequest.data, setModifierGroupsDataToState]);

  const cloneModifierGroupData = useCallback(
    (cloneModifierGroupInput: CloneModifierGroupInput) => {
      cloneModifierGroup({
        variables: {
          input: cloneModifierGroupInput,
        },
      });
      setOperation(Operation.CREATE);
      setCreatedIds([]);
    },
    [cloneModifierGroup],
  );

  const getModifierGroupByIdData = useCallback(
    modifierGroupId => {
      getModifierGroup({ variables: { id: modifierGroupId } });
      setOperation(Operation.READ);
    },
    [getModifierGroup],
  );

  const error: ApolloError | undefined =
    modifierGroupGetRequest.error ||
    modifierGroupsGetRequest.error ||
    createModifierGroupsRequest.error ||
    deleteModifierGroupsRequest.error ||
    updateModifierGroupsRequest.error ||
    cloneModifierGroupRequest.error;

  const loading: boolean =
    modifierGroupGetRequest.loading ||
    modifierGroupsGetRequest.loading ||
    createModifierGroupsRequest.loading ||
    deleteModifierGroupsRequest.loading ||
    updateModifierGroupsRequest.loading ||
    cloneModifierGroupRequest.loading;

  return useMemo(
    () => ({
      modifierGroups,
      createModifierGroups: createModifierGroupsData,
      deleteModifierGroups: deleteModifierGroupsData,
      updateModifierGroups: updateModifierGroupsData,
      cloneModifierGroup: cloneModifierGroupData,
      getAllModifierGroups,
      getModifierGroupById: getModifierGroupByIdData,
      error: error ? parseApolloError(error) : undefined,
      loading,
      operation,
      createdIds,
    }),
    [
      modifierGroups,
      error,
      loading,
      createModifierGroupsData,
      operation,
      deleteModifierGroupsData,
      updateModifierGroupsData,
      getAllModifierGroups,
      getModifierGroupByIdData,
      cloneModifierGroupData,
      createdIds,
    ],
  );
}

export { MODIFIER_GROUPS_AS_OPTIONS };
