import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Gradient from '../../components/Gradient/Gradient';
import theme from '../../common/default-theme';

const styles = StyleSheet.create({
  screenContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    padding: 40,
    borderRadius: 6,
    alignItems: 'center',
    ...theme.shadow.strong,
    backgroundColor: theme.colors.white,
  },
});

interface LoaderProp {
  children?: React.ReactNode;
}

export const Loader: React.FC<LoaderProp> = ({ children }) => {
  return (
    <Gradient style={styles.screenContainer}>
      {children}
      <View style={styles.modalContainer}>
        <ActivityIndicator
          size="large"
          testID="loading-spinner"
          color={theme.colors.primary}
        />
      </View>
    </Gradient>
  );
};

export default Loader;
