import * as React from 'react';
import { View } from 'react-native';
import styles from '../GridItem.styles';
import PaginationButton from './PaginationButton';

export interface PaginationGridProp {
  currentPage: number;
  scrollDirection: 'vertical' | 'horizontal';
  onPressNext?: () => void;
  onPressBack?: () => void;
}
const PaginationGrid: React.FC<PaginationGridProp> = ({
  scrollDirection,
  currentPage,
  onPressBack,
  onPressNext,
}) => {
  return (
    <View style={styles.tilePagination}>
      <PaginationButton
        type="prev"
        testID={'back-button'}
        scrollDirection={scrollDirection}
        onPress={onPressBack}
        disabled={currentPage <= 1}
      />
      <PaginationButton
        type="next"
        testID={'next-button'}
        scrollDirection={scrollDirection}
        onPress={onPressNext}
        // disabled={!next || currentPage >= totalPages}
      />
    </View>
  );
};

export default PaginationGrid;
