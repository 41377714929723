import { EscPos } from '@tillpos/xml-escpos-helper';
import { divider } from './printDivider';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { Printer, PrinterProfileType } from '@oolio-group/domain';

export const testPrintTemplate = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
      <line-feed />
      <align mode="center">
        <bold>
          <text-line size="0:1">{{title}}</text-line>
        </bold>
        <line-feed />
        <bold>
          <text-line size="0:1">{{storeName}}</text-line>
        </bold>
        <line-feed />
      </align>
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <!-- description -->
      <line-feed />
      <align mode="center">
        <text-line>{{description}}</text-line>
      </align>
      <line-feed />
    <paper-cut />
  </document>
  `;

export const lineTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<document>
    <line-feed />
</document>
`;

export const getPrintableBuffer = (
  printer: Printer,
  storeName: string,
  template?: string,
) => {
  let printingTemplate;
  template
    ? (printingTemplate = lineTemplate)
    : (printingTemplate = testPrintTemplate);
  const payload = {
    title: 'Oolio Test Print',
    storeName: storeName,
    description: generateDescription(printer),
    divider: divider() + '\n',
  };

  // Generate buffer
  return EscPos.getBufferFromTemplate(
    printingTemplate,
    payload,
  ) as unknown as Buffer;
};

// Building print components
const config: TableUserConfig = {
  columns: {
    0: {
      alignment: 'center',
      width: 40,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateDescription = (printer: Printer): string => {
  const description: string[][] = [];
  printer?.store?.devices?.forEach(device => {
    const printingOptions = device.printingOptions?.filter(
      option => option.printer?.id === printer.id,
    );

    printingOptions?.forEach(option => {
      description.push([getProfileType(option.printerProfileType)]);
    });
  });

  if (!description.length) {
    description.push(['Not assigned']);
  }

  return table(description, config);
};

const getProfileType = (profile: PrinterProfileType) => {
  return profile === PrinterProfileType.BILLING ? 'Receipt' : 'Docket';
};
