import { Order } from '@oolio-group/domain';

export const getOrderIdentifier = (order: Order): string => {
  let customerName = order.customer
    ? order.customer?.firstName + ' ' + order.customer?.lastName
    : order.orderName ?? '';

  if (!customerName) {
    customerName = order.orderName || '';
  }

  if (!customerName) {
    customerName =
      order?.tokenNumber && Number.isInteger(order.tokenNumber)
        ? order?.tokenNumber?.toString()
        : 'N/A';
  }

  return customerName;
};
