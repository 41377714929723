import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation, getSupportNumber } from '@oolio-group/localization';
import { useNotification } from '../../../hooks/Notification';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { PosUser } from '../../../state/userUtility';
import usePOSUserRoles from '../../../hooks/app/users/usePOSUserRoles';
import TimeAndAttendanceScreen from './TimeAndAttendanceScreen';
import Icon from '../../../components/Icon/Icon';
import Gradient from '../../../components/Gradient/Gradient';
import styles from './PosUserPin.styles';
import theme from '../../../common/default-theme';
import { useSession } from '../../../hooks/app/useSession';
import Logo from '../../../components/Logo/Logo';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';

interface PosUserPinProps {
  user: PosUser;
  onBack: () => void;
  onVerificationComplete: (user: PosUser) => void;
}

const PosUserPinComponent: React.FC<PosUserPinProps> = ({
  user,
  onBack,
  onVerificationComplete,
}) => {
  const { translate } = useTranslation();
  const [value, setValue] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { validateUserPin } = usePOSUserRoles();
  const { showNotification } = useNotification();
  const { canUserAccessPOSApp } = usePOSUserAuthorization();
  const [displayClockInView, setDisplayClockInView] = useState(false);
  const { isConnected } = useNetworkStatus();
  const [session] = useSession();
  const supportNumber = getSupportNumber(session.currentOrganization?.country);

  const onPressKey = useCallback(
    (key: number): void => {
      setValue((value + key).substring(0, 4));
    },
    [value, setValue],
  );

  const onSubmitPin = (pin: string): void => {
    const currentUser = validateUserPin(pin, user.id);
    if (currentUser) {
      if (!canUserAccessPOSApp(currentUser.id)) {
        showNotification({
          info: true,
          message: translate('appAccess.noPOSAccess'),
        });
        return;
      }

      onVerificationComplete(user);
    } else {
      setShowError(true);
      setErrorMessage(translate('login.wrongPinMessage'));
      setValue('');
    }
  };

  const handleStaffAttendancePress = useCallback(
    (pin: string) => {
      const currentUser = validateUserPin(pin, user.id);
      if (currentUser) {
        setDisplayClockInView(true);
      } else {
        setShowError(true);
        setErrorMessage(translate('login.wrongPinMessage'));
        setValue('');
        return false;
      }
    },
    [translate, user, validateUserPin],
  );

  const clearErrorMessage = useCallback((): void => {
    setShowError(false);
    setErrorMessage('');
  }, [setShowError, setErrorMessage]);

  if (displayClockInView) {
    return (
      <TimeAndAttendanceScreen
        user={user}
        onPressDismiss={onBack}
        onClockedInGoToPOS={onVerificationComplete.bind(null, user)}
        title={translate('interimLockScreen.clockScreen.title')}
        instructions={translate('interimLockScreen.clockScreen.instruction')}
      />
    );
  }

  return (
    <View style={styles.container}>
      <Gradient style={styles.side}>
        <TouchableOpacity onPress={onBack} style={styles.backButton}>
          <Icon name="arrow-left" size={20} color={theme.colors.black} />
        </TouchableOpacity>
        <Logo color="light" type="logotype" height={60} width={230} />
        {!!supportNumber && (
          <View style={styles.copy}>
            <Text style={styles.copyText}>
              {translate('settings.callSupport', {
                number: supportNumber,
              })}
            </Text>
          </View>
        )}
      </Gradient>
      <View style={styles.side}>
        {showError || !isConnected ? (
          <View style={styles.errorContainer}>
            <Text testID="error" style={styles.errorText}>
              {!isConnected
                ? 'No Internet Connection'
                : `Error: ${errorMessage}`}
            </Text>
          </View>
        ) : null}
        <View>
          <Text style={styles.userName}>{`Hello, ${user.name} 👋`}</Text>
          <View style={styles.input}>
            <Text style={styles.inputText}>{value.replace(/[0-9]/g, '•')}</Text>
            <TouchableOpacity
              style={styles.clearButton}
              onPress={() => {
                setValue('');
                clearErrorMessage();
              }}
            >
              <Icon name="times-circle" size={20} color={theme.colors.white} />
            </TouchableOpacity>
          </View>
          <View style={styles.keypad}>
            {Array(9)
              .fill(null)
              .map((_, i) => (
                <TouchableOpacity
                  key={i}
                  onPress={() => onPressKey(i + 1)}
                  style={styles.keyButton}
                >
                  <Text style={styles.keyText}>{i + 1}</Text>
                </TouchableOpacity>
              ))}
            <TouchableOpacity
              testID="clockin-button"
              onPress={() => handleStaffAttendancePress(value)}
              style={styles.keyButton}
            >
              <Icon
                name="stopwatch"
                size={26}
                color={theme.colors.states.neutral}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => onPressKey(0)}
              style={styles.keyButton}
            >
              <Text style={styles.keyText}>0</Text>
            </TouchableOpacity>
            <TouchableOpacity
              testID="submit-button"
              style={styles.keyButton}
              onPress={() => onSubmitPin(value)}
            >
              <Icon
                name="arrow-right"
                size={32}
                color={theme.colors.states.positive}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const PosUserPin = React.memo(PosUserPinComponent);
PosUserPin.displayName = 'PosUserPin';

export default PosUserPin;
