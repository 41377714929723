import React, { useCallback, useMemo } from 'react';
import { CatalogueType, Venue } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { CATALOGUE_TYPE_OPTIONS } from '../../../../../constants';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import Select, { Option } from '../../../../../components/Shared/Select/Select';
import InputText from '../../../../../components/Shared/Inputs/InputText';

interface CreateMenuModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreate: (input: any) => void;
  venues: Venue[];
}

const CreateMenuModal: React.FC<CreateMenuModalProps> = ({
  onCreate,
  venues,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const { control, handleSubmit, setValue } = useForm<{
    catalogueType: CatalogueType;
    name: string;
    venues: string[];
    stores: string[];
  }>({
    defaultValues: {
      catalogueType: CatalogueType.POS,
      name: '',
      venues: [],
      stores: [],
    },
  });

  const selectedVenueIds = useWatch({ name: 'venues', control });
  const selectedStoreIds = useWatch({ name: 'stores', control });

  const menuTypeOptions = useMemo(
    () =>
      CATALOGUE_TYPE_OPTIONS.map(option => ({
        ...option,
        label: translate(option.label),
      })) as Option[],
    [translate],
  );

  const venueOptions = useMemo(
    () => venues.map(x => ({ label: x.name, value: x.id })),
    [venues],
  );

  const getAvailableStoreOptions = useCallback(
    (selectedVenueIds: string[]): Option[] => {
      return venues
        .filter(venue => selectedVenueIds.includes(venue.id))
        .map(selectedVenue =>
          selectedVenue.stores.map(store => ({
            value: store.id,
            label: store.name,
          })),
        )
        .flat();
    },
    [venues],
  );

  const onChangeVenue = useCallback(
    (selectedVenueIds: string[]) => {
      setValue('venues', selectedVenueIds);
      setValue(
        'stores',
        getAvailableStoreOptions(selectedVenueIds).map(store => store.value),
      );
    },
    [getAvailableStoreOptions, setValue],
  );

  const onChangeStore = useCallback(
    (values: string[]) => {
      setValue('stores', values);
    },
    [setValue],
  );

  const onCreateMenu = useCallback(
    input => {
      onCreate(input);
    },
    [onCreate],
  );

  return (
    <TreatModal
      title={translate('menus.createNewMenu')}
      type="positive"
      onConfirm={{ action: handleSubmit(onCreateMenu) }}
      onDismiss={{ action: closeModal }}
    >
      <Controller
        name="name"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <InputText
            testID="menu-name"
            title={translate('menus.menuName')}
            placeholder={translate('menus.menuName')}
            value={value}
            onChangeText={onChange}
            containerStyle={marginBottom}
          />
        )}
      />
      <Controller
        name="catalogueType"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            testID="create-menu"
            title={translate('menus.menuType')}
            options={menuTypeOptions}
            selectedValue={value}
            onValueChange={onChange}
            containerStyle={marginBottom}
          />
        )}
      />
      <SelectMultiple
        testID="select-venues"
        title={translate('menus.venues')}
        options={venueOptions}
        selectedValues={selectedVenueIds}
        onValueChange={onChangeVenue}
        containerStyle={marginBottom}
      />
      <SelectMultiple
        testID="select-stores"
        title={translate('menus.stores')}
        options={getAvailableStoreOptions(selectedVenueIds)}
        selectedValues={selectedStoreIds}
        onValueChange={onChangeStore}
      />
    </TreatModal>
  );
};

export default CreateMenuModal;

const marginBottom = {
  marginBottom: 20,
};
