import { useMutation, useLazyQuery } from '@apollo/client/react/hooks';
import { useCallback, useEffect } from 'react';
import { CLOSE_SHIFT, GET_SHIFTS } from '../../graphql/shifts';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import {
  Shift,
  ShiftFilter,
  CloseShiftInput,
  SalesSummary,
} from '@oolio-group/domain';
import { useNotification } from '../Notification';

export interface ShiftsProps {
  shifts: Shift[] | undefined;
  closeShift: (input: CloseShiftInput) => void;
  loading: boolean;
  mutationLoading: boolean;
  error: string | undefined;
  getShifts: (filter: ShiftFilter, retrieveAll?: boolean) => void;
  closedShiftData?: SalesSummary;
}

export function useShifts(): ShiftsProps {
  const { showNotification } = useNotification();
  const [getShiftsReq, getShiftsRes] = useLazyQuery<{ shifts: Shift[] }>(
    GET_SHIFTS,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onError: noopHandler,
    },
  );

  const [closeShiftMutation, shiftStatus] = useMutation<{ closeShift: Shift }>(
    CLOSE_SHIFT,
    {
      onError: noopHandler,
    },
  );

  const closeShift = useCallback(
    (input: CloseShiftInput) => {
      closeShiftMutation({ variables: { input } });
    },
    [closeShiftMutation],
  );

  const getShifts = useCallback(
    (filter: ShiftFilter, retrieveAll?: boolean) => {
      getShiftsReq({
        variables: { input: { filter, retrieveAll } },
      });
    },
    [getShiftsReq],
  );

  const err = getShiftsRes?.error || shiftStatus.error;

  useEffect(() => {
    if (err) {
      showNotification({
        error: true,
        message: parseApolloError(err),
      });
    }
  }, [err, showNotification]);

  return {
    shifts: getShiftsRes?.data?.shifts,
    closeShift,
    loading: getShiftsRes?.loading,
    mutationLoading: shiftStatus.loading,
    error: err ? parseApolloError(err) : undefined,
    getShifts,
    closedShiftData: shiftStatus?.data?.closeShift || undefined,
  };
}
