import { Locale, supportedLocales } from '@oolio-group/localization';
import * as storage from '../storage/interface';
import { AppliedAdjustment, Session } from './Session';
import * as RNLocalize from 'react-native-localize';
import { add, getUnixTime } from 'date-fns';
import { OnBoardingStatus } from '@oolio-group/domain';
import { UserActivity } from './userUtility';
import {
  ExportEntityStorage,
  ImportEntityStorage,
  TokenInfo,
} from '../constants';

export const LOCALE_KEY = 'preferredLocale';
export const SESSION_KEY = 'session';
export const THEME_KEY = 'preferredTheme';
export const ORDER_COUNTER_KEY = 'orderCounter';
export const ORDER_EVENTS_QUEUE_KEY = 'eventsQueue';
export const ONBOARDING_CHECKLIST_KEY = 'onboardingStatus';
export const WORKER_MESSAGES_KEY = 'workerMessages';
export const PUSH_NOTIFICATION_MESSAGE_KEY = 'pushNotificationMessages';
export const USER_ACTIVITY_KEY = 'user:activity';
export const IMPORT_ENTITY_KEY = 'importEntity';
export const EXPORT_ENTITY_KEY = 'exportEntity';
export const OPEN_DAYS_FOR_SHIFT = 'openDaysForShift';
export const AUTHENTICATION_KEY = 'authentication_state';
export const TOKEN_NUMBER_KEY = 'tokenNumber';
export const LOCAL_SURCHARGES = 'localSurcharges';

const availableLocales = Object.keys(supportedLocales);

export async function getLocale(): Promise<Locale | undefined> {
  try {
    const savedLocale = await storage.getItem<Locale>(LOCALE_KEY);

    if (savedLocale && savedLocale.languageTag) {
      return savedLocale;
    }

    const devicePreference =
      RNLocalize.findBestAvailableLanguage(availableLocales);

    return devicePreference as unknown as Locale;
  } catch (e) {}

  return undefined;
}

export async function setLocale(locale: Locale) {
  try {
    storage.setItem(LOCALE_KEY, locale);
  } catch (e) {}
}

export async function getSession(): Promise<Session | undefined> {
  try {
    const session = await storage.getItem<Session>(SESSION_KEY);

    return session;
  } catch (e) {}

  return undefined;
}

export async function setSession(data: Session) {
  try {
    storage.setItem(SESSION_KEY, data);
  } catch (e) {}
}

export async function getTheme(): Promise<string | undefined> {
  try {
    return await storage.getItem<string>(THEME_KEY);
  } catch (e) {}

  return undefined;
}

export async function setTheme(data: string) {
  try {
    storage.setItem(THEME_KEY, data);
  } catch (e) {}
}

export async function getOrderCounter(): Promise<number | undefined> {
  try {
    const counter = await storage.getItem<number>(ORDER_COUNTER_KEY);
    return counter || 0;
  } catch (e) {}

  return undefined;
}

export async function setOrderCounter(data: number) {
  try {
    storage.setItem(ORDER_COUNTER_KEY, data);
  } catch (e) {}
}

export async function getTokenNumber(): Promise<number | undefined> {
  try {
    const counter = await storage.getItem<number>(TOKEN_NUMBER_KEY);
    return counter || 0;
  } catch (e) {}

  return undefined;
}

export async function setTokenNumber(data: number) {
  try {
    storage.setItem(TOKEN_NUMBER_KEY, data);
  } catch (e) {}
}

export const getExpiresAfter = (seconds: number): number => {
  return getUnixTime(add(new Date(), { seconds }));
};

export async function getOnboardingStatus(): Promise<OnBoardingStatus | void> {
  return await getFromStorage<OnBoardingStatus>(ONBOARDING_CHECKLIST_KEY);
}

export async function setOnboardingStatus(data?: OnBoardingStatus) {
  setToStorage(ONBOARDING_CHECKLIST_KEY, data);
}

export async function getUserActivity(): Promise<UserActivity | void> {
  return await getFromStorage<UserActivity>(USER_ACTIVITY_KEY);
}

export async function setUserActivity(data: UserActivity) {
  try {
    storage.setItem(USER_ACTIVITY_KEY, data);
  } catch {}
}

async function getFromStorage<T>(key: string): Promise<T | void> {
  try {
    return await storage.getItem<T>(key);
  } catch {
    return undefined;
  }
}

async function setToStorage<T>(key: string, data: T) {
  try {
    storage.setItem(key, data);
  } catch {}
}

export async function getAuthenticationState(): Promise<TokenInfo | undefined> {
  try {
    const state = await storage.getItem<TokenInfo>(AUTHENTICATION_KEY);

    return state;
  } catch (e) {}

  return undefined;
}

export async function setAuthenticationState(data: TokenInfo) {
  try {
    storage.setItem(AUTHENTICATION_KEY, data);
  } catch (e) {}
}

export async function getImportEntityState(): Promise<
  ImportEntityStorage | undefined
> {
  try {
    const state = await storage.getItem<ImportEntityStorage>(IMPORT_ENTITY_KEY);
    return state;
  } catch (e) {}

  return undefined;
}

export async function setImportEntityState(data: Partial<ImportEntityStorage>) {
  try {
    const entityState = await getImportEntityState();
    storage.setItem(IMPORT_ENTITY_KEY, { ...entityState, ...data });
  } catch (e) {}
}

export async function setExportEntityState(data: Partial<ExportEntityStorage>) {
  try {
    const entityState = await storage.getItem<ExportEntityStorage>(
      EXPORT_ENTITY_KEY,
    );
    storage.setItem(EXPORT_ENTITY_KEY, { ...entityState, ...data });
  } catch (e) {}
}

export async function getLocalSurcharges(): Promise<
  AppliedAdjustment[] | undefined
> {
  try {
    const localSurcharges = await storage.getItem<AppliedAdjustment[]>(
      LOCAL_SURCHARGES,
    );
    return localSurcharges || [];
  } catch (e) {}

  return undefined;
}

export async function setLocalSurcharges(data: AppliedAdjustment[]) {
  try {
    storage.setItem(LOCAL_SURCHARGES, data);
  } catch (e) {}
}
