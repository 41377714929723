/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './Modals.styles';
import theme from '../../../../../../common/default-theme';
import modalStyles from '../../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';

export interface AddExistingProductsProps {
  onPressConfirm: (clearAll: boolean) => void;
  onPressCancel: () => void;
  entityName?: string;
  pageName?: string;
  hideClearAllCheck?: boolean;
}

const ClearTileConfirmation: React.FC<AddExistingProductsProps> = ({
  onPressConfirm,
  onPressCancel,
  entityName,
  pageName,
  hideClearAllCheck,
}) => {
  const { translate } = useTranslation();
  const [clearSameTile, setClearSameTile] = useState(false);

  const onToggleCheckBox = useCallback(() => {
    setClearSameTile(!clearSameTile);
  }, [clearSameTile]);

  return (
    <View style={styles.background}>
      <View style={[styles.container, { width: 600 }]}>
        <View style={modalStyles.title}>
          <Text
            style={[
              modalStyles.titleText,
              { color: theme.colors.states.negative },
            ]}
          >
            {translate('menus.clearTileConfirmTitle', {
              entityName: entityName || 'Product',
            })}
          </Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.message}>
            {translate('menus.clearTileDescription', { entityName })}
          </Text>
          {!hideClearAllCheck && (
            <InputToggle
              testID="toggle-selectAll"
              isToggled={false}
              title={translate('menus.clearAllConfirmMessage', {
                entityName,
                pageName,
              })}
              onToggle={onToggleCheckBox}
            />
          )}
        </View>
        <View style={modalStyles.actions}>
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={onPressCancel}
            label={translate('button.dismiss')}
          />
          <TreatButton
            type="negative"
            testID="btn-confirm"
            onPress={onPressConfirm.bind(null, clearSameTile)}
            label={translate('printing.clearAll')}
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </View>
    </View>
  );
};

export default ClearTileConfirmation;
