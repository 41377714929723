import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  dropdownContainer: {
    width: 180,
  },
  searchContainer: {
    flex: 1,
    marginHorizontal: 10,
  },
  tableContainer: {
    marginBottom: 20,
  },
  headerPrinter: {
    flex: 1,
    marginHorizontal: 12,
  },
  cellName: {
    width: 160,
    marginRight: 10,
  },
  cellType: {
    width: 100,
    marginRight: 10,
  },
  cellProfile: {
    width: 160,
    marginRight: 10,
  },
  cellVersion: {
    flex: 1,
    marginRight: 10,
  },
  cellStatus: {
    width: 100,
    marginRight: 22,
    textAlign: 'right',
  },
  rowLight: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginHorizontal: 12,
    backgroundColor: theme.colors.grey4,
  },
  cellPrinting: {
    flex: 1,
  },
  codeContainer: {
    padding: 40,
    alignItems: 'center',
  },
  code: {
    padding: 20,
    fontSize: 16,
    letterSpacing: 4,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.bold,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
  },
  info: {
    lineHeight: 20,
    textAlign: 'center',
    marginVertical: 20,
    color: theme.colors.grey6,
  },
  btnReset: {
    backgroundColor: theme.colors.white,
  },
  btnUnpair: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.negative,
    marginLeft: 12,
  },
  infoSection: {
    marginBottom: 20,
    paddingHorizontal: 12,
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 6,
    color: theme.colors.grey6,
  },
});

export default styles;
