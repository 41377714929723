import { useCallback, useMemo } from 'react';
import { useSession } from '../app/useSession';
import * as storage from '../../storage/interface';

export interface UseTokenNumberProps {
  getTokenNumber: () => Promise<number | undefined>;
}

export function useTokenNumber(): UseTokenNumberProps {
  const [session] = useSession();

  const getTokenNumberFromSession = useCallback(() => {
    const deviceSettings = { ...session?.device };
    const isTokenEnabled = deviceSettings?.isTokenNumberActive;
    const currentTokenNumber = deviceSettings?.currentTokenNumber;
    const range = session?.device?.tokenSettings?.tokenRange;
    const startRange = range?.start || 0;
    const endRange = range?.end || 0;

    if (!isTokenEnabled) {
      return undefined;
    }

    if (
      ((currentTokenNumber || currentTokenNumber === 0) &&
        currentTokenNumber < startRange) ||
      ((currentTokenNumber || currentTokenNumber === 0) &&
        currentTokenNumber > endRange)
    ) {
      return startRange;
    }

    if (
      Number.isInteger(currentTokenNumber) &&
      (currentTokenNumber || currentTokenNumber === 0)
    ) {
      return currentTokenNumber + 1;
    }

    if (!currentTokenNumber) {
      return startRange;
    }

    return undefined;
  }, [session]);

  const getTokenNumber = useCallback(async () => {
    const deviceSettings = { ...session?.device };
    const isTokenEnabled = deviceSettings?.isTokenNumberActive;

    if (!isTokenEnabled) {
      return undefined;
    }

    let latestTokenNumber = await storage.getItem<number>('tokenNumber');
    if (latestTokenNumber == undefined) {
      latestTokenNumber = getTokenNumberFromSession();
    }
    return latestTokenNumber;
  }, [getTokenNumberFromSession, session]);

  return useMemo(() => ({ getTokenNumber }), [getTokenNumber]);
}
