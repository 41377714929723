import React, { useContext, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GeneralVariant } from './GeneralVariant';
import { GeneralProduct } from './GeneralProduct';
import { ProductContext } from '../ProductSettingsStack';

export const General: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const productContext = useContext(ProductContext);
  const params =
    productContext.params ||
    (route.params as {
      productId: string;
      isVariant: boolean;
      isVariantProduct: boolean;
    });

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);
  const isVariant =
    productContext.params?.isVariant ||
    (
      route.params as {
        productId: string;
        isVariant: boolean;
        isVariantProduct: boolean;
      }
    ).isVariant;
  const productId = !isVariant ? params?.productId : undefined;
  const variantId = isVariant ? params?.productId : undefined;

  return (
    (variantId && <GeneralVariant variantId={variantId} />) ||
    (productId && (
      <GeneralProduct
        isVariantProduct={params.isVariantProduct}
        productId={productId}
      />
    )) ||
    null
  );
};
