import gql from 'graphql-tag';

export const GET_WORKLOGS_REPORT = gql`
  query Worklogs($input: WorklogFilter) {
    worklogs(input: $input) {
      user {
        name
      }
      clockInTime
      clockOutTime
      breakTime
      totalTime
      workTime
    }
  }
`;
