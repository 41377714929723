export const addToValue = (
  value: string,
  key: string,
  decimalSeparator: string,
  length?: number,
): string => {
  const maxLength =
    value.indexOf(decimalSeparator) > 0
      ? value.indexOf(decimalSeparator) + 3
      : length || Infinity;

  if (value.length < maxLength) {
    if (value.length === 0 && key === decimalSeparator) {
      // if the first key input is decimalSeparator (, or .)
      return '0.';
    } else if (
      value.indexOf(decimalSeparator) > 0 &&
      key === decimalSeparator
    ) {
      return value;
    } else return value + key;
  } else if (value.length === maxLength && key === decimalSeparator) {
    // eg. if value is 10000 and I want to input 10000.99
    return value + key;
  } else {
    // if integer, max length is 5, if float, max length is 8
    return value;
  }
};
