import { StyleSheet } from 'react-native';
import theme from '../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 30,
    paddingTop: 40,
    alignSelf: 'center',
    width: theme.layoutWidth.s,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.light,
  },
  content: {
    paddingHorizontal: 12,
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    fontFamily: theme.fonts.medium,
    marginBottom: 8,
  },
  message: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black2,
    fontFamily: theme.fonts.medium,
  },
  actions: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: 10,
  },
  confirmationCheck: {
    marginTop: 30,
  },
});

export default styles;
