import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ComboItem,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  Modifier,
  Money,
  Option,
  OptionValue,
  OrderItemModifier,
  Product,
  ProductModifierGroup,
  SelectionLimit,
  VariantAsItem,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { OptionItem, SelectedOptions } from '../../../../types/Options.type';
import { sumDecimals } from '@oolio-group/order-helper';
import { OptionGroupItem } from './RequiredOptionsModal';
import { generateDeselectDefaultOption } from '@oolio-group/client-utils';

interface OptionGroupHelperProp {
  productAndVariantMap: Record<string, Partial<ComboItem | Product>>;
  getFilteredVariantProductGroups: (
    variantOptionParam: VariantProductOptionGroupType,
  ) => ProductModifierGroup[];
  getSelectionLimitString: (limit?: SelectionLimit) => string;
  getSelectedModifiers: (
    selectedProductMaps: Record<string, OptionItem[]>,
    selectedProduct: OptionItem,
  ) => OrderItemModifier[];
  getVariantOptionValues: (
    comboItem: ComboItem,
    options: Option[],
  ) => OptionValue[];
  setOrRemoveVariantOptions: (
    qty: number,
    variantId: string,
    modifierGroup: ProductModifierGroup[],
    option: ComboItem,
  ) => void;
  getOptionItemStatus: (item: OptionGroupItem) => {
    isActive: boolean;
    status: 'optional' | 'selected' | 'required';
  };
  isGroupWithinLimits: (
    group?: ProductModifierGroup,
    optionSelectionsProp?: SelectedOptions,
  ) => boolean;
  getTotalSelection: (options?: OptionItem[]) => number;
  getSelectedComboProducts: (
    comboId: string,
    optionId: string,
    isComboOptions?: boolean,
  ) => OptionItem[];
  filterValidItem: (item: OptionItem) => boolean;
  mapDefaultOptionItem: (item: OptionItem) => OptionItem;
}

export interface VariantProductOptionGroupType {
  variant: VariantAsItem;
  productId: string;
  group: ProductModifierGroup;
  modifierGroups?: ProductModifierGroup[];
}

export function useOptionGroupHelper({
  product,
  selectedOptions,
  setSelectedOptions,
  selectedOptionsProp,
  isComboProduct,
  currentItem,
}: {
  product: Product;
  selectedOptions: SelectedOptions;
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>;
  selectedOptionsProp?: SelectedOptions;
  isComboProduct?: boolean;
  currentItem: {
    id: string;
    name: string;
    variant: VariantAsItem | undefined;
  };
}): OptionGroupHelperProp {
  const { translate } = useTranslation();

  // map for product and variant present in selected combo products
  const productAndVariantMap: Record<
    string,
    Partial<ComboItem | Product>
  > = useMemo(() => {
    if (product.modifierGroups?.length) {
      return product.modifierGroups.reduce((map, group) => {
        return group.products?.reduce((_, p) => {
          if (p.variantDetail) {
            map[p.variantDetail.id] = p;
            p.variantDetail.products?.forEach(vp => {
              if (vp.id && vp.productDetail) {
                map[vp.id] = vp.productDetail;
              }
            });
          }
          if (p.productDetail) {
            map[p.productDetail.id] = p.productDetail;
          }
          return map;
        }, {});
      }, {} as Record<string, Partial<VariantAsItem | Product>>);
    }
    return {};
  }, [product?.modifierGroups]);

  /**
   * This will return the modifier groups which can be used for variant products
   * 1. Default group is there for which variant is belongs
   * 2. If selected variant products has group then we're going to push with default one
   */
  const getFilteredVariantProductGroups = useCallback(
    (variantOptionParam: VariantProductOptionGroupType) => {
      const {
        variant,
        productId,
        group,
        modifierGroups = [],
      } = variantOptionParam;
      const updatedModifierGroups: ProductModifierGroup[] =
        modifierGroups || [];

      if (!modifierGroups?.length) {
        const selectedModifierGroups =
          variant.products?.find(p => p.id === productId)?.productDetail
            ?.modifierGroups || [];
        updatedModifierGroups.push(...selectedModifierGroups);
      }
      const variantOptionGroup: ProductModifierGroup = {
        ...group,
        isRequired: true,
        selectionLimit: {
          ...group.selectionLimit,
          min: 1,
          max: 1,
        },
        maxSelectionPerOption: 1,
        name: variant.options.map(o => o.key).join(' - '),
        products:
          variant.products?.map(p => ({
            ...p,
            name: p.name.split('-').slice(1).join('-'),
          })) || [],
      };

      return [variantOptionGroup, ...updatedModifierGroups];
    },
    [],
  );

  const filterValidItem = useCallback(
    (item: OptionItem) => Boolean(item?.quantity && item.quantity >= 1),
    [],
  );

  const mapDefaultOptionItem = useCallback((item: OptionItem) => {
    if (!item.isDefault) return item;
    const assumedQuantity = item.quantity;
    const validQuantity = item.removeDefault ? 1 : assumedQuantity - 1;
    return {
      ...item,
      quantity: validQuantity,
      price: {
        ...item.price,
        amount: item.removeDefault ? 0 : item.price?.amount,
      } as Money,
    };
  }, []);

  // Generates selection limit/criteria string for option group
  const getSelectionLimitString = useCallback(
    (limit?: SelectionLimit) => {
      const { min = 0, max = 0 } = limit || {};

      if (min === max && min !== 0) {
        return `${translate('modifiers.select')} ${min}`;
      }
      if (min === 0 && max === 0) {
        return translate('modifiers.selectAny');
      }
      if (min === 0 && max !== 0) {
        return translate('modifiers.selectUpTo', {
          n: max,
        });
      }

      return `${translate('modifiers.selectRange', {
        min,
        max,
      })}`;
    },
    [translate],
  );

  /**
   * Preparing the modifier object for particular combo product
   */
  const getSelectedModifiers = useCallback(
    (
      selectedProductMaps: Record<string, OptionItem[]>,
      selectedProduct: OptionItem,
    ) => {
      return Object.keys(selectedProductMaps || {})
        .map(groupId => {
          return selectedOptions?.[selectedProduct.id]?.[groupId]
            ?.filter(m => !(m as ComboItem).productDetail)
            .map(mapDefaultOptionItem)
            .filter(filterValidItem)
            .map(
              m =>
                ({
                  quantity: m.quantity,
                  unitPrice: m.price?.amount ?? 0,
                  modifierGroupId: groupId,
                  modifierGroupPriority: m.priority,
                  id: m.id,
                  name: m.removeDefault
                    ? generateDeselectDefaultOption(m.name)
                    : m.name,
                } as OrderItemModifier),
            );
        })
        .flat();
    },
    [filterValidItem, mapDefaultOptionItem, selectedOptions],
  );

  /**
   * Preparing the product object with variation option for combo product
   */
  const getVariantOptionValues = useCallback(
    (comboItem: ComboItem, options: Option[]) => {
      if (!options?.length) return [];
      const itemOptions = comboItem.name.split('-').slice(1);
      return itemOptions.reduce((list, itemOption) => {
        const option = itemOption.toLowerCase();
        const matchedOption = options.find(o =>
          o.values.find(value => value.toLowerCase() === option),
        );
        return matchedOption
          ? [
              ...list,
              {
                key: matchedOption.key,
                parentOptionId: matchedOption.id,
                value: itemOption,
              },
            ]
          : [];
      }, [] as OptionValue[]);
    },
    [],
  );

  const addDefaultQuantity = (mod: Modifier): OptionItem => ({
    ...mod,
    quantity: 1,
  });

  const defaultOptionSelections = useMemo(() => {
    return product.modifierGroups?.reduce((maps, optionGroup) => {
      if (!isComboProduct) {
        const defaultModifierOptions = optionGroup.modifiers
          .filter(mod => mod.isDefault)
          .map(addDefaultQuantity);
        if (!maps[product.id]) {
          maps[product.id] = {};
        }
        maps[product.id][optionGroup.id] = defaultModifierOptions;
      } else {
        (optionGroup.products || []).forEach(p => {
          const childProduct = p.productDetail as Product;
          if (!childProduct) return maps;
          const modifierGroups = childProduct?.modifierGroups || [];

          modifierGroups.forEach(g => {
            const productModifiers = g.modifiers || [];
            const defaultModifierOptions = productModifiers
              .filter(mod => mod.isDefault)
              .map(addDefaultQuantity);
            if (!maps[childProduct.id]) {
              maps[childProduct.id] = {};
            }
            defaultModifierOptions?.length &&
              (maps[childProduct.id][g.id] = defaultModifierOptions);
          });
        });
      }
      return maps;
    }, {} as SelectedOptions);
  }, [product.modifierGroups, product.id, isComboProduct]);

  useEffect(() => {
    if (selectedOptionsProp === undefined) {
      // Update default options for selection
      setSelectedOptions(defaultOptionSelections);
    } else {
      setSelectedOptions(selectedOptionsProp);
    }
  }, [defaultOptionSelections, selectedOptionsProp, setSelectedOptions]);

  /**
   * This function will set default options for variant product and
   * reset the selected modifiers for variant product if we unselect it
   * @param qty qty to update for variant product (-1 means remove >0 means add)
   * @param variantId variant id for which we needs to set options
   * @param modifierGroup variant products option groups
   * @param option selected variant product
   */
  const setOrRemoveVariantOptions = (
    qty: number,
    variantId: string,
    optionGroups: ProductModifierGroup[] = [],
    option: ComboItem,
  ) => {
    const isVariantProductRemoved = qty === -1 && option.productDetail;
    if (isVariantProductRemoved) {
      setSelectedOptions({ ...selectedOptions, [variantId]: {} });
    } else {
      const defaultVariantModifiers: Record<string, OptionItem[]> =
        optionGroups.reduce((map, g) => {
          return {
            ...map,
            [g.id]: g.modifiers
              .filter(m => m.isDefault)
              .map(m => ({
                ...m,
                quantity: 1,
              })),
          };
        }, {});
      if (Object.values(defaultVariantModifiers || {})?.length) {
        setSelectedOptions(prevOptions => {
          return {
            ...prevOptions,
            [variantId]: {
              ...prevOptions[variantId],
              ...defaultVariantModifiers,
            },
          };
        });
      }
    }
  };

  const getTotalSelection = useCallback((options?: OptionItem[]): number => {
    return sumDecimals((options || []).map(item => item.quantity));
  }, []);

  // Helper function to check if a group's selection criteria is met
  const isGroupWithinLimits = useCallback(
    (group?: ProductModifierGroup, optionSelectionsProp?: SelectedOptions) => {
      if (!group) return false;
      if (group?.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) return true;

      const numOfSelectedOptions = getTotalSelection(
        (optionSelectionsProp || selectedOptions)[currentItem.id]?.[group.id] ||
          [],
      );
      const { min, max } = group.selectionLimit || {};
      if (group?.isRequired)
        return (
          numOfSelectedOptions >= Math.max(min, 1) &&
          numOfSelectedOptions <= max
        );
      return numOfSelectedOptions <= max;
    },
    [selectedOptions, currentItem.id, getTotalSelection],
  );

  const getOptionItemStatus = (
    item: OptionGroupItem,
  ): {
    isActive: boolean;
    status: 'optional' | 'selected' | 'required';
  } => {
    const isActive = item?.isSelecting;
    const status = !item.isRequired
      ? 'optional'
      : isGroupWithinLimits(item)
      ? 'selected'
      : 'required';
    return { isActive, status };
  };

  const getSelectedComboProducts = (
    comboId: string,
    optionId: string,
    isComboOptions?: boolean,
  ) => {
    if (isComboOptions) {
      const selectedProducts = selectedOptions?.[comboId]?.[optionId] ?? [];
      selectedProducts.map(p => {
        const selectedVariantOptions = Object.values(
          selectedOptions?.[p.id] || {},
        ).flat();
        const modifiers = selectedVariantOptions?.filter(
          o => !(o as ComboItem).productDetail,
        ) as Modifier[];
        p.modifiers = modifiers ?? [];
      });
      return selectedProducts;
    } else return [];
  };

  return {
    productAndVariantMap,
    getFilteredVariantProductGroups,
    getSelectionLimitString,
    getSelectedModifiers,
    getVariantOptionValues,
    setOrRemoveVariantOptions,
    getOptionItemStatus,
    isGroupWithinLimits,
    getTotalSelection,
    getSelectedComboProducts,
    mapDefaultOptionItem,
    filterValidItem,
  };
}
