import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { View, Text } from 'react-native';
import {
  Option,
  Variant,
  DEFAULT_PRICING_GROUP,
  ProductPricingInput,
  Currency,
  CreateOptionInput,
  UpdateVariantInput,
  DEFAULT_TAX,
  UpdateProductInput,
} from '@oolio-group/domain';
import { v4 as uuidv4 } from 'uuid';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import {
  PRODUCTS_IN_VARIANTS_SCREEN,
  useProducts,
} from '../../../../../hooks/app/products/useProducts';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { useTaxes } from '../../../../../hooks/app/useTaxes';
import { useOptions } from '../../../../../hooks/app/useOptions';
import { useProductPricings } from '../../../../../hooks/app/productPricings/useProductPricings';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import { findIndex, cloneDeep } from 'lodash';
import { Operation } from '../../../../../types/Operation';
import { useModal } from '@oolio-group/rn-use-modal';
import { useIsFocused } from '@react-navigation/native';
import { VariationRow } from './Rows/VariationRow';
import { VariationGroupRow } from './Rows/VariationGroupRow';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import theme from '../../../../../common/default-theme';
import styles from './Variations.styles';

interface CustomVariantModel extends Variant {
  productPriceDictionary: Record<string, ProductPricingInput>;
  addProductPricing: {
    productId: string;
    pricings: {
      pricingGroupId: string;
      productPricing: ProductPricingInput;
    }[];
  }[];
}

interface VariantVariationsProps {
  variantId: string;
}

const variantProps = {
  isDefault: 'isDefault',
};

export const VariantVariations: React.FC<VariantVariationsProps> = ({
  variantId,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { closeModal, showModal } = useModal();
  const [variant, setVariant] = useState({} as CustomVariantModel);
  const [copyVariant, setCopyVariant] = useState([] as Option[]);
  const { currency, currencySymbol } = useCurrency();
  const [selectedOptionId, setSelectedOptionId] = useState('');
  const [createdOptionValue, setCreatedOptionValue] = useState('');
  const variantRef = useRef<CustomVariantModel | undefined>();
  const { error: taxesError, loading: taxesLoading, taxesOptions } = useTaxes();
  const {
    variants,
    error: varErr,
    updateVariant,
    loading: varLoading,
    operation: varOperation,
    getVariantData,
  } = useVariants(variantId);

  const {
    options,
    error: optErr,
    createOption,
    createdOptionId,
    loading: optLoading,
    operation: optOperation,
    updateOption,
  } = useOptions();

  const {
    update: updatePP,
    error: errPP,
    loading: loadingPP,
    operation: operationPP,
    addBulkProductPricings: addPP,
  } = useProductPricings();

  const {
    error: prodErr,
    updateProducts,
    loading: prodLoading,
    deleteProducts,
    deletedProductIds,
  } = useProducts(undefined, PRODUCTS_IN_VARIANTS_SCREEN);

  const {
    defaultPricingGroup,
    error: PGError,
    loading: PGLoading,
    getDefaultPricingGroup,
  } = usePricingGroups();

  const error = varErr || optErr || prodErr || errPP || PGError || taxesError;

  const loading =
    prodLoading ||
    loadingPP ||
    optLoading ||
    varLoading ||
    PGLoading ||
    taxesLoading;

  // default tax should be GST
  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value || '';
  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!varLoading && !varErr && varOperation === Operation.UPDATE) {
      addPP(variant.addProductPricing);
    }
  }, [varErr, varLoading, varOperation, addPP, variant]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getVariantData(variantId);
      getDefaultPricingGroup();
    }
  }, [isFocused, getVariantData, variantId, getDefaultPricingGroup]);

  useEffect(() => {
    if (!loadingPP && !errPP && operationPP === Operation.CREATE) {
      getVariantData(variantId);
    }
  }, [operationPP, errPP, loadingPP, getVariantData, variantId]);

  useEffect(() => {
    if (
      !optErr &&
      !optLoading &&
      createdOptionId &&
      optOperation === Operation.CREATE &&
      options[createdOptionId] &&
      selectedOptionId
    ) {
      setVariant(prev => {
        const variantData = { ...prev };
        const index = findIndex(variantData.options, {
          id: selectedOptionId,
        });
        if (index >= 0) {
          variantData.options[index] = {
            id: createdOptionId,
            key: options[createdOptionId].key,
            values: options[createdOptionId].values,
          };
        }
        return variantData;
      });
      showNotification({
        success: true,
        message: translate('productSettings.optionCreatedSuccessfully'),
      });
    }
  }, [
    optErr,
    optLoading,
    createdOptionId,
    optOperation,
    options,
    showNotification,
    translate,
    selectedOptionId,
  ]);

  useEffect(() => {
    if (
      !optErr &&
      !optLoading &&
      optOperation === Operation.UPDATE &&
      selectedOptionId &&
      createdOptionValue
    ) {
      showNotification({
        success: true,
        message: translate('productSettings.optionUpdatedSuccessfully'),
      });
      setVariant(prev => {
        const variantData = { ...prev };
        const index = findIndex(variantData.options, {
          id: selectedOptionId,
        });
        if (index >= 0) {
          variantData.options[index] = {
            ...variantData.options[index],
            values: [...variantData.options[index].values, createdOptionValue],
          };
        }
        return variantData;
      });
      setCreatedOptionValue('');
    }
  }, [
    optErr,
    optLoading,
    optOperation,
    options,
    showNotification,
    translate,
    selectedOptionId,
    createdOptionValue,
  ]);

  useEffect(() => {
    if (
      !prodLoading &&
      !prodErr &&
      !loadingPP &&
      !errPP &&
      operationPP === Operation.UPDATE
    ) {
      showNotification({
        success: true,
        message: translate('productSettings.updatedSuccessfully'),
      });
      getVariantData(variantId);
    }
  }, [
    prodErr,
    showNotification,
    prodLoading,
    operationPP,
    errPP,
    loadingPP,
    translate,
    getVariantData,
    variantId,
  ]);

  useEffect(() => {
    if (!prodLoading && !prodErr && deletedProductIds?.length) {
      if (deletedProductIds?.length && variant && variant?.products?.length) {
        const variantData = { ...variant };
        const index = findIndex(variantData.products, {
          id: deletedProductIds[0],
        });
        if (index >= 0) {
          closeModal();
          getVariantData(variantId);
        }
      }
    }
  }, [
    prodErr,
    prodLoading,
    showNotification,
    translate,
    closeModal,
    variant,
    deletedProductIds,
    getVariantData,
    variantId,
  ]);

  useEffect(() => {
    if (variantId && variants && variants[variantId]) {
      const variantTemp = cloneDeep(variants[variantId]);
      const productPriceInputDic: Record<string, ProductPricingInput> = {};
      const addProductPricings: {
        productId: string;
        pricings: {
          pricingGroupId: string;
          productPricing: ProductPricingInput;
        }[];
      }[] = [];
      variantTemp.products.forEach(prod => {
        const defaultPG = prod.pricingGroups.find(
          x => x.name === DEFAULT_PRICING_GROUP && x,
        );
        defaultPG?.prices.forEach(eachPrice => {
          productPriceInputDic[prod.id] = {
            pricingGroupId: defaultPG.id,
            product: prod.id,
            id: eachPrice.id,
            costPrice: {
              amount: eachPrice.costPrice?.amount || 0,
              currency: eachPrice?.costPrice?.currency || currency,
            },
            sellingPrice: {
              amount: eachPrice.sellingPrice?.amount || 0,
              currency: eachPrice.sellingPrice?.currency || currency,
            },
            sellingTax: defaultTax,
          } as ProductPricingInput;
        });
        if (!defaultPG && defaultPricingGroup?.id) {
          addProductPricings.push({
            pricings: [
              {
                pricingGroupId: defaultPricingGroup?.id,
                productPricing: {
                  pricingGroupId: defaultPricingGroup?.id,
                  product: prod.id,
                  costPrice: {
                    amount: 0,
                    currency: currency,
                  },
                  sellingPrice: {
                    amount: 0,
                    currency: currency,
                  },
                  sellingTax: defaultTax,
                  taxInclusive: true,
                } as ProductPricingInput,
              },
            ],
            productId: prod.id,
          });

          productPriceInputDic[prod.id] = {
            pricingGroupId: defaultPricingGroup?.id,
            product: prod.id,
            id: uuidv4(),
            costPrice: {
              amount: 0,
              currency: currency,
            },
            sellingPrice: {
              amount: 0,
              currency: currency,
            },
            sellingTax: defaultTax,
            taxInclusive: true,
          } as ProductPricingInput;
        }
      });
      if (!variantTemp?.options?.length) {
        variantTemp.options.push({
          id: '',
          key: '',
          values: [],
        });
      }
      setVariant({
        ...variantTemp,
        productPriceDictionary: productPriceInputDic,
        addProductPricing: addProductPricings,
      });
      if (!copyVariant.length) {
        setCopyVariant([...variantTemp.options]);
      }
    }
  }, [
    variants,
    variantId,
    currency,
    defaultPricingGroup,
    copyVariant,
    defaultTax,
  ]);

  const allKeys = useMemo(() => {
    const optionsAvailable = Object.keys(options);
    const optionsInVariant = (variant?.options || []).map(x => x.id);
    const keys: { value: string; label: string }[] = [];
    optionsAvailable.forEach(x => {
      if (!optionsInVariant.includes(x)) {
        keys.push({
          label: options[x].key,
          value: x,
        });
      }
    });
    return keys;
  }, [options, variant]);

  const onCreate = useCallback(() => {
    const variantData = { ...variant };
    const optionsAvailable = Object.keys(options);
    if (!variantData.options) {
      variantData.options = [];
    }
    const optionsInVariant = variantData.options.map(x => x.id);
    let optionToAdd = '';
    optionsAvailable.forEach(x => {
      if (!optionToAdd && !optionsInVariant.includes(x)) {
        optionToAdd = x;
      }
    });
    if (optionToAdd) {
      variantData.options.push({
        id: optionToAdd,
        key: options[optionToAdd].key,
        values: options[optionToAdd].values,
      });
    } else {
      variantData.options.push({
        id: '',
        key: '',
        values: [],
      });
    }
    setVariant(variantData);
  }, [variant, options]);

  const onChangeValues = useCallback(
    (id, values) => {
      const variantData = cloneDeep(variant);
      const index = findIndex(variantData.options, { id });
      if (index >= 0) {
        variantData.options[index].values = values;
        setVariant(() => variantData);
      }
    },
    [variant],
  );

  useEffect(() => {
    variantRef.current = variant;
    return () => {
      variantRef.current = undefined;
    };
  }, [variant]);

  const onChangeProductDetails = useCallback(
    (id, prop, value) => {
      const variantData = { ...variant };
      const index = findIndex(variantData.products, { id });
      if (index >= 0) {
        if (prop === variantProps.isDefault) {
          // remove old default variant
          const previousDefaultIndex = variantData.products.findIndex(
            prod => prod.isDefault,
          );

          variantData.products[previousDefaultIndex] = {
            ...variantData.products[previousDefaultIndex],
            isDefault: false,
          };
        }
        variantData.products[index] = {
          ...variantData.products[index],
          [prop]: value,
        };
        setVariant(variantData);
      }
    },
    [variant],
  );

  const onChangeProductPricings = useCallback(
    (id, prop, value) => {
      const variantData = { ...variant };
      if (prop === 'costPrice' || prop === 'sellingPrice') {
        const temp = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = { currency: currency as Currency, amount: temp };
      }

      variantData.productPriceDictionary[id] = {
        ...variantData.productPriceDictionary[id],
        product: id,
        pricingGroupId: defaultPricingGroup?.id as string,
        [prop]: value,
      };
      if (!variantData.productPriceDictionary[id]?.id) {
        const index = findIndex(variantData.addProductPricing, {
          productId: id,
        });
        variantData.addProductPricing[index].pricings[0].productPricing = {
          ...variantData.addProductPricing[index].pricings[0].productPricing,
          [prop]: value,
        };
      }
      setVariant(variantData);
    },
    [variant, currency, defaultPricingGroup, currencySymbol],
  );

  const onSaveProducts = useCallback(() => {
    const productPricingsInput = Object.values(variant.productPriceDictionary);
    const productInputs: UpdateProductInput[] = variant.products.map(
      prod =>
        ({
          id: prod.id,
          isSellable: prod.isSellable || false,
          isDefault: prod.isDefault || false,
          name: prod.name,
        } as UpdateProductInput),
    );
    const updateProdPricingInput: ProductPricingInput[] = [];

    productPricingsInput.forEach(x => {
      if (x && x.id) {
        updateProdPricingInput.push({
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: +x.sellingPrice.amount,
          },
          costPrice: {
            ...x.costPrice,
            amount: +x.costPrice.amount,
          },
        });
      }
    });
    updateProducts(productInputs);
    updatePP(updateProdPricingInput);
  }, [variant, updateProducts, updatePP]);

  const onAddOption = useCallback(
    (name, selectedOptionId) => {
      const input = { key: name, values: [] } as CreateOptionInput;
      if (selectedOptionId) {
        setSelectedOptionId(selectedOptionId);
      }
      createOption(input);
    },
    [createOption],
  );

  const onAddOptionValue = useCallback(
    (id, name) => {
      const currentOption = { ...options[id] };
      if (currentOption && !currentOption?.values?.includes(name)) {
        currentOption['values'] = [...currentOption.values, name];
        setSelectedOptionId(id);
        setCreatedOptionValue(name);
        updateOption({
          id: currentOption.id,
          key: currentOption.key,
          values: currentOption.values,
        });
      }
    },
    [options, updateOption],
  );

  const onDeleteOptionRow = useCallback(
    id => {
      const variantData = { ...variant };
      const index = findIndex(variantData.options, { id });
      if (index >= 0) {
        variantData.options.splice(index, 1);
        setVariant(variantData);
      }
    },
    [variant],
  );

  const updateVariantDetails = useCallback(() => {
    const variantData = variantRef.current;
    const updateInput = {
      id: variantId,
      options: variantData?.options.map(x => ({
        id: x?.id,
        values: x?.values,
      })),
    } as UpdateVariantInput;
    const previousVariantProducts = variants[variantId].products;
    if (previousVariantProducts && previousVariantProducts.length > 0) {
      deleteProducts(
        previousVariantProducts.map(product => product.id),
        () => {
          updateVariant(updateInput);
        },
      );
    } else {
      updateVariant(updateInput);
    }
  }, [deleteProducts, updateVariant, variantId, variants]);

  const onUpdateProduct = useCallback(() => {
    showModal(
      <ConfirmationDialog
        title={translate('productSettings.updateVariant')}
        message={translate('productSettings.updateVariantOptions')}
        onConfirm={() => {
          updateVariantDetails();
          closeModal();
        }}
      />,
    );
  }, [translate, showModal, updateVariantDetails, closeModal]);

  const onDeleteProduct = useCallback(
    (productId, name) => {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', { label: name })}
          onConfirm={() => deleteProducts([productId])}
        />,
      );
    },
    [showModal, translate, deleteProducts],
  );

  const onChangeSelectedOption = useCallback(
    (prevId, selectedId) => {
      const variantData = { ...variant };
      const index = findIndex(variantData.options, { id: prevId });
      if (index >= 0) {
        variantData.options[index] = {
          ...options[selectedId[0]],
        };
        setVariant(variantData);
      }
    },
    [options, variant],
  );

  const requestDeleteModal = useCallback(
    (onChangeOfDropDown, isSelected, value) => {
      let canShowDropDown = false;
      if (value && variants && variants[variantId]) {
        const allProducts = variants[variantId].products;
        if (
          allProducts.some(x => x.optionValues.some(y => y.value === value))
        ) {
          canShowDropDown = true;
        }
      }
      if (canShowDropDown) {
        showModal(
          <ConfirmationDialog
            title={translate('dialog.deleteTitle')}
            message={translate('productSettings.deleteVariantValues')}
            onConfirm={async () => {
              await onChangeOfDropDown(isSelected, value);
              await new Promise(resolve => setTimeout(resolve, 50));
              await updateVariantDetails();
              closeModal();
            }}
          />,
        );
      } else {
        onChangeOfDropDown(isSelected, value);
      }
    },
    [
      showModal,
      translate,
      closeModal,
      updateVariantDetails,
      variantId,
      variants,
    ],
  );

  const requestAddModal = useCallback(
    (onChangeOfDropDown, isSelected, value) => {
      showModal(
        <ConfirmationDialog
          title={translate('productSettings.addVariant')}
          message={translate('productSettings.addVariantValues')}
          onConfirm={async () => {
            await onChangeOfDropDown(isSelected, value);
            await new Promise(resolve => setTimeout(resolve, 50));
            await updateVariantDetails();
            closeModal();
          }}
        />,
      );
    },
    [showModal, closeModal, updateVariantDetails, translate],
  );

  return (
    <ScreenLayout
      loading={loading}
      title="Variations | Oolio"
      onSave={onSaveProducts}
    >
      <Section
        title={translate('variants.variants')}
        subtitle={translate('productSettings.attributesDescription')}
        layoutWidth="medium"
      >
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerGroup]}>
              {translate('variants.variantGroup')}
            </Text>
            <Text style={theme.tables.headerText}>
              {translate('variants.variants')}
            </Text>
          </View>
          <View>
            {variant.options?.map((item, i: number) => (
              <VariationGroupRow
                key={i}
                index={i}
                variationGroups={allKeys}
                selectedOption={item}
                allValues={options[item.id]?.values}
                onValuesChange={onChangeValues}
                onAddOption={onAddOption}
                onAddOptionValue={onAddOptionValue}
                onDeleteOptionRow={onDeleteOptionRow}
                onChangeSelectedOption={onChangeSelectedOption}
                isDisabled={false}
                deleteRequestModal={requestDeleteModal}
                addRequestModal={requestAddModal}
              />
            ))}
          </View>
        </View>
        <ButtonIcon
          type="positive"
          testID="btn-createVariations"
          icon="plus"
          onPress={onCreate}
          disabled={variant.options?.length > 2}
          containerStyle={styles.btnAdd}
        />
        <View style={styles.actions}>
          {(variant.options || []).length > 0 && (
            <TreatButton
              type="neutral"
              testID="btn-generateVariations"
              label={translate('productSettings.updateVariants')}
              onPress={onUpdateProduct}
            />
          )}
        </View>
      </Section>
      <Section title={translate('variants.variations')} layoutWidth="medium">
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerVariant]}>
              {translate('productSettings.defaultVariation')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellPrice]}>
              {translate('productSettings.costPrice')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerSellPrice]}>
              {translate('productSettings.sellPrice')}
            </Text>
          </View>
          <View>
            {variant.products?.map((item, i: number) => (
              <VariationRow
                key={i}
                variantId={variant.id}
                product={item}
                pricings={variant.productPriceDictionary[item.id]}
                currencySymbol={currencySymbol}
                onChangeProductDetails={onChangeProductDetails}
                onChangeProductPricings={onChangeProductPricings}
                onDeleteProduct={onDeleteProduct}
              />
            ))}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
