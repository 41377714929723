import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerName: {
    width: 244,
    marginRight: 10,
  },
  headerDropdown: {
    width: 240,
    marginLeft: 10,
  },
  cellName: {
    flex: 1,
    marginLeft: 12,
  },
  cellDropdown: {
    width: 240,
    marginLeft: 10,
  },
  cellAccessory: {
    marginLeft: 10,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  infoInput: {
    flex: 1,
    marginRight: 10,
  },
});

export default styles;
