import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useCurrency } from '@oolio-group/localization';
import { capitalCase } from 'change-case';
import { addToValue } from '../../../../utils/keypadHelper';
import { is9DigitWith2Decimal } from '../../../../utils/validator';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import theme from '../../../../common/default-theme';
import styles from './CartKeypad.styles';
import Icon from '../../../Icon/Icon';
import { CartKeypadActions } from './CartKeypadActions';
import ButtonIcon from '../../../Shared/TreatButton/ButtonIcon';
import CartKeypadButton from './CartKeypadButton';

export interface CartKeypadProps {
  cancelActionDisabled: boolean;
  updatePriceActionDisabled: boolean;
  addActionDisable: boolean;
  subtractActionDisabled: boolean;
  splitProductDisabled: boolean;
  keypadInput?: string;
  onPressAction: (action: string) => void;
  onPressSplitProductFromCart: () => void;
  setKeypadInput?: (value: number) => void;
}

const CartKeypad: React.FC<CartKeypadProps> = ({
  onPressAction,
  onPressSplitProductFromCart,
  cancelActionDisabled,
  updatePriceActionDisabled,
  addActionDisable,
  subtractActionDisabled,
  splitProductDisabled,
  keypadInput,
  setKeypadInput,
}) => {
  const { decimalSeparator, unFormatCurrency } = useCurrency();

  const [input, setInput] = useState(keypadInput || '');
  const [showInputDisplay, setShowInputDisplay] = useState(
    !!keypadInput || false,
  );

  const iconKeys = [
    {
      icon: 'trash-alt',
      color: theme.colors.red,
      action: CartKeypadActions.CancelAction,
    },
    {
      icon: 'usd-circle',
      color: theme.colors.blue,
      action: CartKeypadActions.UpdatePriceAction,
    },
    {
      icon: 'plus-circle',
      color: theme.colors.black,
      action: CartKeypadActions.AddAction,
    },
    {
      icon: 'minus-circle',
      color: theme.colors.black,
      action: CartKeypadActions.SubtractAction,
    },
  ];

  const numberKeys = [
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  useEffect(() => {
    setInput(keypadInput || '');
    setShowInputDisplay(!!keypadInput);
  }, [keypadInput]);

  const onPressKey = useCallback(
    (key: string): void => {
      analyticsService.capture('cart_actions', {
        type: 'Number Pad',
        value: key,
      });
      setShowInputDisplay(true);
      const newValue = input.concat(key);
      if (!is9DigitWith2Decimal(newValue)) return;
      setInput(value => addToValue(value.toString(), key, decimalSeparator));
      setKeypadInput && setKeypadInput(unFormatCurrency(newValue));
    },
    [input, setKeypadInput, decimalSeparator, unFormatCurrency],
  );

  const onResetAmount = useCallback(() => {
    setInput('');
    setKeypadInput && setKeypadInput(0);
    setShowInputDisplay(false);
  }, [setKeypadInput]);

  return (
    <View>
      {showInputDisplay ? (
        <View style={styles.input}>
          <ButtonIcon
            testID={CartKeypadActions.SplitAction}
            type="focusLight"
            icon="arrows-resize"
            onPress={() => {
              onPressSplitProductFromCart();
              onResetAmount();
            }}
            disabled={splitProductDisabled}
          />
          <Text testID="keypad-value" style={styles.inputText}>
            {input}
          </Text>
          <ButtonIcon
            testID={CartKeypadActions.ResetAction}
            type="cancel"
            icon="times-circle"
            onPress={onResetAmount}
          />
        </View>
      ) : null}
      <View style={styles.keypad}>
        <View style={styles.numpad}>
          {numberKeys.map(number => (
            <CartKeypadButton
              number={number.value}
              key={number.value}
              onPressKey={onPressKey}
            />
          ))}
        </View>
        <View style={styles.actions}>
          {iconKeys.map((key, i) => {
            const onPress = () => {
              analyticsService.capture('cart_actions', {
                type: capitalCase(key.action).replace('Action', ''),
              });
              onPressAction(key.action);
              onResetAmount();
            };

            const disabled =
              key.action === CartKeypadActions.CancelAction
                ? cancelActionDisabled
                : key.action === CartKeypadActions.UpdatePriceAction
                ? updatePriceActionDisabled
                : key.action === CartKeypadActions.AddAction
                ? addActionDisable
                : subtractActionDisabled;

            const iconColor =
              key.action === CartKeypadActions.CancelAction ||
              key.action === CartKeypadActions.UpdatePriceAction
                ? key.color
                : addActionDisable
                ? theme.colors.grey4
                : key.color;

            return (
              <TouchableOpacity
                key={i}
                disabled={disabled}
                testID={key.action}
                onPress={onPress}
                style={styles.keyAction}
              >
                <Icon
                  size={22}
                  name={key.icon}
                  color={!disabled ? iconColor : theme.colors.grey4}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </View>
  );
};
export default CartKeypad;
