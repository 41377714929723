import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useRoute } from '@react-navigation/native';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useTranslation } from '@oolio-group/localization';
import TabBar from '../../../../../../../components/TabBar/TabBar';
import { CustomizeUserRole } from './UserRole';

const Tab = createMaterialTopTabNavigator();

const UserRoleTabs: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();

  const params = route.params as {
    userId: string;
  };

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute="Permissions"
        />
      )}
      initialRouteName="CustomizeUserRole"
    >
      <Tab.Screen
        name="CustomizeUserRole"
        component={CustomizeUserRole}
        options={{ tabBarLabel: translate('backOfficeUsers.customiseRole') }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default UserRoleTabs;
