import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { useCallback, useEffect, useState } from 'react';
import { Table, CreateTableInput, UpdateTableInput } from '@oolio-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  DELETE_TABLE,
  GET_TABLES,
  GET_TABLE,
  UPDATE_TABLES,
  CREATE_TABLES,
} from './graphql';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { sortTablesByName } from '../../../utils/TableHelper';
import { keyBy, cloneDeep } from 'lodash';
import { useSession } from '../useSession';

export interface UseTables {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  table: Table;
  tables: { [key: string]: Table };
  updatedTableIds: string[];
  getTable: () => void;
  getTables: () => void;
  createTables: (tablesInput: CreateTableInput[]) => void;
  updateTables: (tablesInput: UpdateTableInput[]) => void;
  deleteTable: (id: string) => void;
}

export interface Props {
  sectionId?: string;
  tableId?: string;
}

export const useTables = (props?: Props): UseTables => {
  const { sectionId, tableId } = props || {};

  const [tables, setTables] = useState<Record<string, Table>>({});

  const [table, setTable] = useState<Table>({} as Table);

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [updatedTableIds, setUpdatedTableIds] = useState<string[]>([]);

  const [deletedTableId, setDeletedTableId] = useState<string>('');
  const [session, setSession] = useSession();

  const updateTableInSession = useCallback(
    (tables: Table[]) => {
      const updatedSession = cloneDeep(session);
      const tableIds = tables.map(table => table.id);
      if (updatedSession.deviceProfile) {
        const sectionIndex = (
          updatedSession.deviceProfile?.sections || []
        ).findIndex(section => section.id == sectionId);
        if (sectionIndex > -1) {
          const section =
            updatedSession.deviceProfile?.sections?.[sectionIndex];
          tableIds.forEach((id, indexId) => {
            const modifiedTableIndex = (section?.tables || []).findIndex(
              table => table.id === id,
            );
            if (id === deletedTableId && modifiedTableIndex > -1) {
              section?.tables?.splice(modifiedTableIndex, 1);
            } else if (
              modifiedTableIndex > -1 &&
              section?.tables?.[modifiedTableIndex]
            ) {
              section?.tables?.splice(modifiedTableIndex, 1, tables[indexId]);
            } else if (section?.tables && modifiedTableIndex === -1) {
              section.tables = [...(section?.tables ?? []), tables[indexId]];
            }
          });
          setSession(updatedSession);
        }
      }
    },
    [sectionId, session, setSession, deletedTableId],
  );

  const onCompleteUpdateTablesRequest = useCallback(
    data => {
      if (data && data.updateTables) {
        updateTableInSession(data.updateTables);
      }
    },
    [updateTableInSession],
  );
  const onCompleteCreateTablesRequest = useCallback(
    data => {
      if (data && data.createTables) {
        updateTableInSession(data.createTables);
      }
    },
    [updateTableInSession],
  );
  // get table
  const onCompleteGetTableRequest = useCallback(
    data => {
      if (data) {
        const tableData = data.table as Table;
        setTable({ ...tableData });
      }
    },
    [setTable],
  );

  const [getTableRequest, getTableResponse] = useLazyQuery(GET_TABLE, {
    fetchPolicy: 'cache-and-network',
    variables: { id: tableId },
    onCompleted: onCompleteGetTableRequest,
    onError: noopHandler,
  });

  // get tables
  const onCompleteGetTablesRequest = useCallback(data => {
    if (data) {
      const tablesData = sortTablesByName(data.tables as Table[]);
      setTables({ ...keyBy(tablesData, 'id') });
    }
  }, []);

  const [getTablesRequest, getTablesResponse] = useLazyQuery(GET_TABLES, {
    fetchPolicy: 'cache-and-network',
    variables: { id: sectionId },
    onCompleted: onCompleteGetTablesRequest,
    onError: noopHandler,
  });

  // batch update tables
  const [updateTablesRequest, updateTablesResponse] = useMutation(
    UPDATE_TABLES,
    {
      onCompleted: onCompleteUpdateTablesRequest,
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (updateTablesResponse.data) {
      const tablesData = updateTablesResponse.data.updateTables as Table[];

      setTables(previousTables => {
        const tablesTemp = { ...previousTables };
        tablesData.forEach(tableData => {
          tablesTemp[tableData.id] = {
            ...tablesTemp[tableData.id],
            ...tableData,
          };
        });
        return tablesTemp;
      });
      setUpdatedTableIds(tablesData.map(table => table.id));
    }
  }, [updateTablesResponse.data]);

  const updateTables = useCallback(
    (tablesInput: Partial<UpdateTableInput[]>) => {
      updateTablesRequest({
        variables: {
          input: tablesInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateTablesRequest],
  );

  // batch create
  const [createTablesRequest, createTablesResponse] = useMutation(
    CREATE_TABLES,
    {
      onCompleted: onCompleteCreateTablesRequest,
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (createTablesResponse.data) {
      const tablesData = createTablesResponse.data.createTables as Table[];
      const newTables = {} as { [key: string]: Table };

      tablesData.forEach(table => {
        newTables[table.id] = table;
      });

      setTables(tables => {
        return {
          ...tables,
          ...newTables,
        };
      });
    }
  }, [createTablesResponse.data]);

  const createTables = useCallback(
    (tablesInput: CreateTableInput[]) => {
      createTablesRequest({
        variables: {
          input: tablesInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createTablesRequest],
  );

  const onCompleteDeleteTableRequest = useCallback(
    data => {
      if (data && data.deleteTable) {
        updateTableInSession(Object.values(tables));
      }
    },
    [updateTableInSession, tables],
  );

  // delete
  const [deleteTableRequest, deleteTableResponse] = useMutation(DELETE_TABLE, {
    onError: noopHandler,
    onCompleted: onCompleteDeleteTableRequest,
  });

  const deleteTable = useCallback(
    (id: string) => {
      setDeletedTableId(id);
      deleteTableRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteTableRequest],
  );

  useEffect(() => {
    if (deleteTableResponse.data) {
      if (deletedTableId)
        setTables(tables => {
          delete tables[deletedTableId];
          return {
            ...tables,
          };
        });
    }
  }, [deleteTableResponse.data, deletedTableId]);

  const error: ApolloError | undefined =
    getTableResponse.error ||
    getTablesResponse.error ||
    updateTablesResponse.error ||
    deleteTableResponse.error ||
    createTablesResponse.error;

  const loading: boolean =
    getTableResponse.loading ||
    getTablesResponse.loading ||
    updateTablesResponse.loading ||
    deleteTableResponse.loading ||
    createTablesResponse.loading;

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    operation,
    table,
    tables,
    updatedTableIds,
    getTable: getTableRequest,
    getTables: getTablesRequest,
    createTables,
    updateTables,
    deleteTable,
  };
};
