import { useMutation } from '@apollo/client/react/hooks';
import { useCallback, useEffect } from 'react';
import { CreateDeviceInput, UpdateDeviceInput } from '@oolio-group/domain';
import {
  CREATE_DEVICE,
  DELETE_DEVICE,
  UPDATE_DEVICE,
} from '../../graphql/devices';
import { Session } from '../../state/Session';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useSession } from './useSession';

export interface DeviceAssignmentProps {
  success: boolean;
  loading: boolean;
  updateDevice: (input: UpdateDeviceInput) => void;
  createDevice: (input: CreateDeviceInput) => void;
  unAssignDevice: (input: { id: string; device: string }) => void;
  error?: string;
}

export function useDeviceAssignment(): DeviceAssignmentProps {
  const [session, setSession] = useSession();
  const { setOrderCounter } = useOrderNumber();
  const [createDeviceMutation, createdDeviceStatus] = useMutation(
    CREATE_DEVICE,
    {
      onError: noopHandler,
    },
  );

  const onUpdateDevice = useCallback(
    data => {
      if (data.updateDevice) {
        const device = data.updateDevice;
        const updatedDeviceProfile =
          session.currentStore?.deviceProfiles?.filter(
            x => x.id === device.deviceProfile.id,
          )[0];
        const updatedSession: Session = {
          ...session,
          device,
          deviceProfile: {
            ...updatedDeviceProfile,
            defaultOrderType:
              device.deviceProfile?.defaultOrderType ||
              device.deviceProfile?.orderTypes?.[0] ||
              {},
            functionMap:
              device.deviceProfile?.functionMap ||
              updatedDeviceProfile?.functionMap,
          },
        };
        setSession(updatedSession);
        if (device.lastOrderNumber) setOrderCounter(device.lastOrderNumber);
      }
    },
    [session, setOrderCounter, setSession],
  );

  const [updateDeviceRequest, updateDeviceResponse] = useMutation(
    UPDATE_DEVICE,
    {
      onCompleted: onUpdateDevice,
      onError: noopHandler,
      context: {
        headers: { store: session?.currentStore?.id },
      },
    },
  );

  const createDevice = useCallback(
    (input: CreateDeviceInput) => {
      createDeviceMutation({ variables: { input } });
    },
    [createDeviceMutation],
  );

  const updateDevice = useCallback(
    (input: UpdateDeviceInput) => {
      updateDeviceRequest({
        variables: {
          input,
        },
      });
    },
    [updateDeviceRequest],
  );

  useEffect(() => {
    if (!!createdDeviceStatus.data) {
      const device = createdDeviceStatus.data.createDevice;
      const createdDeviceProfile = session.currentStore?.deviceProfiles?.filter(
        x => x.id === device.deviceProfile.id,
      )[0];
      const updatedSession: Session = {
        ...session,
        deviceProfile: {
          ...createdDeviceProfile,
          defaultOrderType:
            device.deviceProfile?.defaultOrderType ||
            device.deviceProfile?.orderTypes?.[0] ||
            {},
          functionMap:
            device.deviceProfile?.functionMap ||
            createdDeviceProfile?.functionMap,
        },
        device,
      };
      setSession(updatedSession);
      setOrderCounter(device.previousOrder?.orderNumber);
    }
  }, [setSession, createdDeviceStatus, session, setOrderCounter]);

  const [unAssignDeviceMutation, unAssignDeviceStatus] = useMutation(
    DELETE_DEVICE,
    {
      onError: noopHandler,
    },
  );

  const unAssignDevice = useCallback(
    (input: { id: string; device: string }) => {
      unAssignDeviceMutation({ variables: input });
    },
    [unAssignDeviceMutation],
  );

  useEffect(() => {
    if (!!unAssignDeviceStatus.data) {
      const updatedSession: Session = {
        ...session,
        deviceProfile: undefined,
      };

      setSession(updatedSession);
    }
  }, [setSession, unAssignDeviceStatus, session]);

  const error =
    createdDeviceStatus.error ||
    unAssignDeviceStatus.error ||
    updateDeviceResponse.error;
  const success =
    !!createdDeviceStatus.data ||
    !!unAssignDeviceStatus.data ||
    !!updateDeviceResponse.data;
  const loading =
    createdDeviceStatus.loading ||
    unAssignDeviceStatus.loading ||
    updateDeviceResponse.loading;
  const result = {
    success,
    loading,
    createDevice,
    updateDevice,
    unAssignDevice,
    error: error ? parseApolloError(error) : undefined,
  };

  return result;
}
