import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import styles, { getContainerColor, getBorderRadius } from './Sticker.styles';
import { getLabelStyle } from '../TreatButton/TreatButton.styles';

export interface StickerProps {
  testID: string;
  type?:
    | 'primary'
    | 'primaryLight'
    | 'accent'
    | 'positive'
    | 'negative'
    | 'neutral'
    | 'positiveLight'
    | 'negativeLight'
    | 'neutralLight'
    | 'focus'
    | 'focusLight'
    | 'cancel'
    | 'teal';
  label: string;
  rounded?: boolean;
  showIndicator?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
}

const Sticker: React.FC<StickerProps> = ({
  testID,
  type = 'cancel',
  label,
  rounded = false,
  showIndicator = false,
  containerStyle,
}: StickerProps) => {
  return (
    <View
      style={[
        containerStyle,
        styles.container,
        getContainerColor(type),
        getBorderRadius(rounded),
      ]}
    >
      <Text
        testID={testID}
        numberOfLines={1}
        style={[styles.text, getLabelStyle(type)]}
      >
        {label}
      </Text>
      {showIndicator && <View style={styles.indicator}></View>}
    </View>
  );
};

export default Sticker;
