import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import {
  ProductType,
  CreateProductTypeInput,
  UpdateProductTypeInput,
} from '@oolio-group/domain';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client/react/hooks';
import {
  CREATE_PRODUCT_TYPES,
  DELETE_PRODUCT_TYPE,
  GET_PRODUCT_TYPES_QUERY,
  UPDATE_PRODUCT_TYPES,
} from '../../graphql/productTypes';
import keyBy from 'lodash/keyBy';
import { useNotification } from '../Notification';
import { translate } from '@oolio-group/localization';

export interface UseProductTypesProps {
  loading: boolean;
  error: string | undefined;
  productTypes: { [key: string]: ProductType };
  getProductTypes: () => void;
  createProductTypes: (productTypesInput: CreateProductTypeInput[]) => void;
  updateProductTypes: (productTypesInput: UpdateProductTypeInput[]) => void;
  deleteProductType: (id: string) => void;
}

interface UseProductTypesInput {
  fetchPolicy?: WatchQueryFetchPolicy;
}

export function useProductTypes(
  input?: UseProductTypesInput,
): UseProductTypesProps {
  const [productTypes, setProductTypes] = useState<Record<string, ProductType>>(
    {},
  );

  const { showNotification } = useNotification();
  const deletingProductTypeIdRef = useRef('');
  const client = useApolloClient();

  const [getProductTypes, getProductTypesRes] = useLazyQuery<{
    productTypes: ProductType[];
  }>(GET_PRODUCT_TYPES_QUERY, {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: noopHandler,
  });

  const getCachedProductTypes = useCallback(() => {
    const cachedData = client.cache.readQuery<{ productTypes: ProductType[] }>({
      query: GET_PRODUCT_TYPES_QUERY,
    });
    return cachedData?.productTypes || [];
  }, [client.cache]);

  const updateCachedProductTypes = useCallback(
    (productTypes: ProductType[]) => {
      client.cache.writeQuery({
        query: GET_PRODUCT_TYPES_QUERY,
        data: {
          productTypes,
        },
      });
    },
    [client.cache],
  );

  // create
  const [createProductTypesRequest, createProductTypesResponse] = useMutation<{
    createProductTypes: ProductType[];
  }>(CREATE_PRODUCT_TYPES, {
    onError: noopHandler,
    onCompleted: response => {
      const existingProductTypes = getCachedProductTypes();
      updateCachedProductTypes([
        ...existingProductTypes,
        ...response.createProductTypes,
      ]);
      showNotification({
        success: true,
        message: translate(
          'backOfficeReportingGroups.reportingGroupCreatedSuccessfully',
        ),
      });
    },
  });

  const createProductTypes = useCallback(
    (createProductTypesInput: CreateProductTypeInput[]) => {
      createProductTypesRequest({
        variables: {
          input: createProductTypesInput,
        },
      });
    },
    [createProductTypesRequest],
  );

  // create
  const [updateProductTypesRequest, updateProductTypesResponse] = useMutation<{
    updateProductTypes: ProductType[];
  }>(UPDATE_PRODUCT_TYPES, {
    onError: noopHandler,
    onCompleted: response => {
      const updatedData = response.updateProductTypes;
      showNotification({
        success: true,
        message: translate(
          `${
            updatedData.length > 1
              ? 'backOfficeReportingGroups.reportingGroupsUpdatedSuccessfully'
              : 'backOfficeReportingGroups.reportingGroupUpdatedSuccessfully'
          }`,
        ),
      });
    },
  });

  const updateProductTypes = useCallback(
    (updateProductTypesInput: UpdateProductTypeInput[]) => {
      updateProductTypesRequest({
        variables: {
          input: updateProductTypesInput,
        },
      });
    },
    [updateProductTypesRequest],
  );

  const [deleteProductTypeRequest, deleteProductTypeResponse] = useMutation(
    DELETE_PRODUCT_TYPE,
    {
      onError: noopHandler,
      onCompleted: () => {
        const existingProductTypes = getCachedProductTypes();
        const updatedData = existingProductTypes.filter(
          p => p.id !== deletingProductTypeIdRef.current,
        );
        updateCachedProductTypes(updatedData);
        showNotification({
          success: true,
          message: translate(
            'backOfficeReportingGroups.reportingGroupDeletedSuccessfully',
          ),
        });
      },
    },
  );

  const deleteProductType = useCallback(
    (id: string) => {
      deletingProductTypeIdRef.current = id;
      deleteProductTypeRequest({
        variables: {
          id,
        },
      });
    },
    [deleteProductTypeRequest],
  );

  useEffect(() => {
    if (getProductTypesRes.data) {
      const productTypesMaps = keyBy(
        getProductTypesRes.data.productTypes || [],
        'id',
      );
      setProductTypes(productTypesMaps);
    }
  }, [getProductTypesRes.data]);

  const loading: boolean =
    getProductTypesRes.loading ||
    updateProductTypesResponse.loading ||
    createProductTypesResponse.loading ||
    deleteProductTypeResponse.loading;

  const error: ApolloError | undefined =
    getProductTypesRes.error ||
    updateProductTypesResponse.error ||
    createProductTypesResponse.error ||
    deleteProductTypeResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      productTypes,
      getProductTypes,
      createProductTypes,
      updateProductTypes,
      deleteProductType,
    }),
    [
      loading,
      error,
      productTypes,
      getProductTypes,
      createProductTypes,
      updateProductTypes,
      deleteProductType,
    ],
  );
}
