import React, { FC } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { Shift, ShiftStatus, VoidReason } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { format } from 'date-fns';
import { capitalCase, sentenceCase } from 'change-case';
import styles from './ShiftsSidePanel.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import { ShiftDetailRow, ShiftDetailTotalRow, ShiftGroup } from './ShiftsRows';

interface SidePanelProps {
  shift: Shift;
  showVariance?: boolean;
  onPrint: (shift?: Shift) => void;
  onClose: () => void;
}

const ShiftSidePanel: FC<SidePanelProps> = ({
  shift,
  showVariance,
  onPrint,
  onClose,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const safeHeight = theme.useSafeHeight();

  const isOpen = shift.shiftStatus === ShiftStatus.OPEN;
  const DATE_FORMAT = 'dd/MM/yy hh:mm a';

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      <View style={styles.title}>
        <TouchableOpacity style={styles.btnClose} onPress={onClose}>
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.titleText}>{`${translate(
          'backOfficeShifts.rowPreview.shiftNumber',
        )} ${shift.shiftNumber}`}</Text>
      </View>
      <ScrollView style={styles.content}>
        <ShiftGroup title={translate('onlineOrders.details')}>
          <ShiftDetailRow
            testID="shiftNumber"
            value={shift.shiftNumber}
            title={translate('backOfficeShifts.rowPreview.shiftNumber')}
          />
          <ShiftDetailRow
            testID="shiftType"
            value={capitalCase(shift.shiftType ?? '')}
            title={translate('backOfficeShifts.rowPreview.shiftType')}
          />
          <ShiftDetailRow
            testID="shiftCreatedAt"
            value={format(shift.createdAt, DATE_FORMAT)}
            title={translate('shift.createdAt')}
          />
          <ShiftDetailRow
            testID="shiftCreatedBy"
            value={capitalCase(shift.createdBy.name ?? '')}
            title={translate('shift.createdBy')}
          />
          {!isOpen ? (
            <>
              <ShiftDetailRow
                testID="shiftClosedAt"
                title={translate('backOfficeShifts.rowPreview.closedAt')}
                value={isOpen ? 'N/A' : format(shift.closedAt, DATE_FORMAT)}
              />
              <ShiftDetailRow
                testID="shiftClosingDevice"
                value={shift.closedByDevice?.name || ''}
                title={translate('shift.closingDevice')}
              />
              <ShiftDetailRow
                testID="shiftClosedBy"
                value={shift.closedBy?.name || 'N/A'}
                title={translate('shift.closedBy')}
              />
            </>
          ) : null}
        </ShiftGroup>
        {shift?.salesByPaymentType?.map((type, i) => {
          const name = type.paymentType.name?.toLowerCase() ?? '';
          const isCash = name === 'cash';

          return (
            <ShiftGroup key={i} title={`${capitalCase(name ?? '')} Payments`}>
              <ShiftDetailRow
                testID={`${name}-transactionsCount`}
                title={translate(
                  'backOfficeShifts.rowPreview.transactionsCount',
                )}
                value={type.salesCount}
              />
              <ShiftDetailRow
                testID={`${name}-collected`}
                title={translate('backOfficeShifts.rowPreview.amount')}
                value={formatCurrency(type.amount || 0)}
              />
              <ShiftDetailRow
                testID={`${name}-tips`}
                title={'– ' + translate('backOfficeShifts.rowPreview.tips')}
                value={formatCurrency(type.tips || 0)}
              />
              {isCash && (
                <ShiftDetailRow
                  testID={`${name}-rounding`}
                  title={
                    '– ' +
                    translate('backOfficeShifts.rowPreview.roundingAmount')
                  }
                  value={formatCurrency(type.roundingAmount || 0)}
                />
              )}
              {type.paymentSurcharge ? (
                <ShiftDetailRow
                  testID={`${name}-paymentSurcharge`}
                  title={
                    '– ' +
                    translate('backOfficeShifts.rowPreview.paymentSurcharge')
                  }
                  value={formatCurrency(type.paymentSurcharge || 0)}
                />
              ) : null}
              <ShiftDetailRow
                testID={`${name}-refundAmount`}
                title={`– ${translate(
                  'backOfficeShifts.rowPreview.refundAmount',
                )} (x${type.refundsCount || 0})`}
                value={formatCurrency(type.refundAmount || 0)}
                negative={(type.refundAmount || 0) > 0}
              />
              {isCash && showVariance ? (
                <>
                  <ShiftDetailRow
                    testID={`${name}-counted`}
                    title={translate(
                      'backOfficeShifts.rowPreview.totalCounted',
                    )}
                    value={formatCurrency(type.totalCounted || 0)}
                  />
                  <ShiftDetailRow
                    testID={`${name}-recorded`}
                    title={translate(
                      'backOfficeShifts.rowPreview.recordedAmount',
                    )}
                    value={formatCurrency(type.recordedAmount || 0)}
                  />
                  <ShiftDetailRow
                    testID={`${name}-variance`}
                    title={translate('backOfficeShifts.rowPreview.variance')}
                    value={formatCurrency(type.variance || 0)}
                    negative={(type.variance || 0) < 0}
                  />
                </>
              ) : null}
              {isCash ? (
                <>
                  <ShiftDetailRow
                    testID={`${name}-moneyIn`}
                    title={`Money In (x${type.moneyInCount || 0})`}
                    value={formatCurrency(type.moneyIn || 0)}
                  />
                  <ShiftDetailRow
                    testID={`${name}-moneyOut`}
                    title={`Money Out (x${type.moneyOutCount || 0})`}
                    value={formatCurrency(type.moneyOut || 0)}
                  />
                </>
              ) : null}
            </ShiftGroup>
          );
        })}
        {shift.taxes.length > 0 ? (
          <ShiftGroup title={translate('backOfficeShiftsReport.TaxSummary')}>
            {shift.taxes.map((tax, i) => (
              <ShiftDetailRow
                key={`tax-${i}`}
                testID={`tax-${i}`}
                title={`${tax.tax.name} (${tax.tax.rate}%)`}
                value={formatCurrency(tax.amount || 0)}
              />
            ))}
            <ShiftDetailTotalRow
              testID="tax-all"
              title="All Taxes"
              value={formatCurrency(
                shift.taxes.reduce((a, t) => a + t.amount, 0),
              )}
            />
          </ShiftGroup>
        ) : null}
        <ShiftGroup title={translate('backOfficeReports.SalesSummary')}>
          <ShiftDetailRow
            testID="summary-count"
            title={translate('backOfficeShifts.rowPreview.totalSalesCount')}
            value={shift.totalSalesCount}
          />
          <ShiftDetailRow
            testID="summary-transactions"
            title={translate('backOfficeShifts.rowPreview.transactionsCount')}
            value={shift.transactionsCount}
          />
          <ShiftDetailRow
            testID="summary-refundsCount"
            title={translate('backOfficeShifts.rowPreview.refundCount')}
            value={shift.refundCount}
          />
          <ShiftDetailRow
            testID="summary-grossSales"
            title={translate('backOfficeShifts.rowPreview.totalGrossSales')}
            value={formatCurrency(shift.totalGrossSales || 0)}
          />
          <ShiftDetailRow
            testID="summary-refundAmount"
            title={
              '– ' + translate('backOfficeShifts.rowPreview.totalGrossRefund')
            }
            value={formatCurrency(shift.totalGrossRefund || 0)}
            negative={(shift.totalGrossRefund || 0) < 0}
          />
          <ShiftDetailRow
            testID="summary-discounts"
            title={
              '– ' + translate('backOfficeShifts.rowPreview.totalDiscount')
            }
            value={formatCurrency(shift.totalDiscount || 0)}
            negative={(shift.totalDiscount || 0) > 0}
          />
          <ShiftDetailRow
            testID="summary-totalSurcharges"
            title={
              '– ' + translate('backOfficeShifts.rowPreview.totalSurcharge')
            }
            value={formatCurrency(shift.totalSurcharge || 0)}
            negative={shift.totalSurcharge < 0}
          />
          <ShiftDetailRow
            testID="summary-netSales"
            title={translate('backOfficeShifts.rowPreview.totalNetAmount')}
            value={formatCurrency(shift.totalNetAmount || 0)}
          />
          <ShiftDetailRow
            testID="summary-netTaxes"
            title={translate('backOfficeShifts.rowPreview.totalTax')}
            value={formatCurrency(shift.totalTax || 0)}
          />
          <ShiftDetailTotalRow
            testID="summary-salesAmount"
            title={translate('backOfficeShifts.rowPreview.totalSalesAmount')}
            value={formatCurrency(shift.totalSalesAmount || 0)}
          />
          {shift.totalRefundByAmount ? (
            <ShiftDetailRow
              testID="summary-refundByAmount"
              title={translate(
                'backOfficeShifts.rowPreview.totalRefundByAmount',
              )}
              value={formatCurrency(shift.totalRefundByAmount || 0)}
              negative={shift.totalRefundByAmount > 0}
            />
          ) : null}
          {shift.tips ? (
            <ShiftDetailRow
              testID="summary-tips"
              title={translate('backOfficeShifts.rowPreview.tips')}
              value={formatCurrency(shift.tips || 0)}
            />
          ) : null}
          {shift.totalRoundingAmount ? (
            <ShiftDetailRow
              testID="summary-rounding"
              title={translate(
                'backOfficeShifts.rowPreview.totalRoundingAmount',
              )}
              value={formatCurrency(shift.totalRoundingAmount || 0)}
            />
          ) : null}
          {shift.totalPaymentSurcharge ? (
            <ShiftDetailRow
              testID="summary-paymentSurcharge"
              title={translate('backOfficeShifts.rowPreview.paymentSurcharge')}
              value={formatCurrency(shift.totalPaymentSurcharge || 0)}
            />
          ) : null}
          {shift.otherCharges ? (
            <ShiftDetailRow
              testID="summary-otherCharges"
              title={translate('backOfficeShifts.rowPreview.otherCharges')}
              value={formatCurrency(shift.otherCharges || 0)}
            />
          ) : null}
          <ShiftDetailTotalRow
            testID="summary-total"
            title={translate('backOfficeShifts.rowPreview.totalAmount')}
            value={formatCurrency(shift.totalAmount || 0)}
          />
          <ShiftDetailRow
            testID="summary-salesAverage"
            title={translate('backOfficeShifts.rowPreview.salesAverage')}
            value={formatCurrency(shift.salesAverage || 0)}
          />
          {shift.totalCostPrice > 0 ? (
            <>
              <ShiftDetailRow
                testID="summary-costPrice"
                title={translate('backOfficeShifts.rowPreview.totalCostPrice')}
                value={formatCurrency(shift.totalCostPrice || 0)}
              />
              <ShiftDetailTotalRow
                testID="summary-profit"
                title={translate('backOfficeShifts.rowPreview.profit')}
                value={formatCurrency(shift.profit || 0)}
              />
            </>
          ) : null}
        </ShiftGroup>
        {shift.discountByReward && shift.discountByReward.length > 0 ? (
          <ShiftGroup title={translate('backOfficeShiftsReport.Discounts')}>
            {shift.discountByReward?.map((discount, i) => (
              <ShiftDetailRow
                key={`discount-${i}`}
                testID={`discount-${i}`}
                title={`${discount.reward.rewardName} (x${discount.quantity})`}
                value={formatCurrency(discount.amount || 0)}
              />
            ))}
          </ShiftGroup>
        ) : null}
        {shift.voidedAmountByReason.length > 0 ? (
          <ShiftGroup title={translate('backOfficeShiftsReport.VoidSummary')}>
            {shift.voidedAmountByReason.map((reason, i) => (
              <ShiftDetailRow
                key={`void-${i}`}
                testID={`void-${i}`}
                title={capitalCase(reason.reason ?? VoidReason.OTHER)}
                value={formatCurrency(reason.amount || 0)}
              />
            ))}
          </ShiftGroup>
        ) : null}
        {shift.salesByProductType.length > 0 ? (
          <ShiftGroup
            title={translate('backOfficeReportingGroups.reportingGroups')}
          >
            {shift.salesByProductType.map((group, i) => (
              <ShiftDetailRow
                key={`reportingGroup-${i}`}
                testID={`reportingGroup-${i}`}
                title={`${sentenceCase(group.productType.name ?? '')} (x${
                  group.count || 0
                })`}
                value={
                  group.isComboReportingGroup
                    ? `(${formatCurrency(group.amount || 0)})`
                    : formatCurrency(group.amount || 0)
                }
              />
            ))}
            <ShiftDetailTotalRow
              testID="reportingGroup-all"
              title="All Reporting Groups"
              value={formatCurrency(
                shift.salesByProductType.reduce((a, t) => a + t.amount, 0),
              )}
            />
          </ShiftGroup>
        ) : null}
        {shift.onAccountSales &&
        (shift.onAccountSales.Payments.length ||
          shift.onAccountSales.Sales.length) ? (
          <ShiftGroup
            title={translate('backOfficeShifts.rowPreview.onAccountSale')}
          >
            <ShiftDetailTotalRow
              testID="onAccountPayments"
              title={`Payments (x${shift.onAccountSales.Payments.length})`}
              value={formatCurrency(
                shift.onAccountSales?.Payments.reduce(
                  (a, t) => a + t.amount,
                  0,
                ),
              )}
            />
            {shift.onAccountSales.Payments.map((payment, i) => {
              return (
                <ShiftDetailRow
                  key={`onAccountPayment-${i}`}
                  testID={`onAccountPayment-${i}`}
                  title={`– ${capitalCase(payment.customer.name ?? '')} (${
                    payment.paymentType.name
                  })`}
                  value={formatCurrency(payment.amount)}
                />
              );
            })}
            <ShiftDetailTotalRow
              testID="onAccountSales"
              title={`Sales (x${shift.onAccountSales.Sales.length})`}
              value={formatCurrency(
                shift.onAccountSales?.Sales.reduce((a, t) => a + t.amount, 0),
              )}
            />
            {shift.onAccountSales.Sales.map((sale, i) => {
              return (
                <ShiftDetailRow
                  key={`onAccountSale-${i}`}
                  testID={`onAccountSale-${i}`}
                  title={`– ${capitalCase(sale.customer.name ?? '')}`}
                  value={formatCurrency(sale.amount)}
                />
              );
            })}
          </ShiftGroup>
        ) : null}
        {shift.store.showHourlySplit && shift.salesByHour.length > 0 ? (
          <ShiftGroup
            title={translate('backOfficeShifts.rowPreview.hourlySummary')}
          >
            {shift.salesByHour.map((hour, i) => (
              <ShiftDetailRow
                key={`saleByHour-${i}`}
                testID={`saleByHour-${i}`}
                title={`${hour.hour} (x${hour.count || 0})`}
                value={formatCurrency(hour.amount || 0)}
              />
            ))}
            <ShiftDetailTotalRow
              testID="saleByHour-all"
              title={translate('shift.allHours')}
              value={formatCurrency(
                shift.salesByHour.reduce((a, t) => a + t.amount, 0),
              )}
            />
          </ShiftGroup>
        ) : null}

        {!!shift.totalTrainingSalesCount && (
          <ShiftGroup
            title={translate('backOfficeReports.trainingSalesSummary')}
          >
            <ShiftDetailRow
              testID={'training-sales-count'}
              title={translate('shift.sales')}
              value={shift.totalTrainingSalesCount || 0}
            />
            <ShiftDetailRow
              testID={'training-sales'}
              title={translate('backOfficeShifts.rowPreview.totalSalesAmount')}
              value={formatCurrency(shift.totalTrainingSalesAmount || 0)}
            />
          </ShiftGroup>
        )}
      </ScrollView>
      <TreatButton
        type="neutral"
        height={60}
        testID="btn-shiftPrintSidePanel"
        onPress={() => onPrint(shift)}
        label={translate('shift.printSummary')}
      />
    </View>
  );
};

export default ShiftSidePanel;
