import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

export default StyleSheet.create({
  container: {
    padding: 12,
    minHeight: 44,
    borderRadius: theme.radius.s,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  messageText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
  },
  rowCell: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    marginRight: 10,
  },
});

export const getIcon = (type: string) => {
  switch (type) {
    case 'positive':
      return 'check-circle';
    case 'negative':
      return 'exclamation-triangle';
    case 'neutral':
      return 'info-circle';
    default:
      return 'info-circle';
  }
};
