import '../devConfig';
import processRequest from './processRequest';
import { addToQueue, shiftQueue, hasItemsInQueue } from './PrintQueue';

let workerIsRunning = false;
/**
 * This is not an actual worker class implementation, but a class with synchronous functions which runs
 * on the main JS thread.
 */
export default class WebPlatformWorker {
  onmessage = null;
  // listen for messages
  postMessage(payload) {
    // If worker is currently running, push request to queue
    // Otherwise, execute the worker

    if (workerIsRunning) {
      addToQueue(payload);
    } else {
      workerIsRunning = true;
      this.runWorker(payload);
    }
  }

  // Runs worker on queued request
  async runWorker(payload) {
    try {
      const message = JSON.parse(payload);
      const results = await processRequest(message);

      results?.forEach(result => {
        // send message
        this.onmessage(
          JSON.stringify({
            ...result,
            requestId: message.requestId,
            timestamp: Date.now(),
            action: message.action,
          }),
        );
      });
    } catch (error) {
      this.onmessage(
        JSON.stringify({
          status: 'ERROR',
          message: error.toString(),
          timestamp: Date.now(),
        }),
      );
    }

    if (hasItemsInQueue()) {
      // run worker for next request
      this.runWorker(shiftQueue());
    } else {
      workerIsRunning = false;
    }
  }
}
