import {
  Order,
  OrderItemStatus,
  OrderPayment,
  PaymentType,
} from '@oolio-group/domain';
import { OrderType, Product } from '@oolio-group/domain';
import { cloneJSON } from '@oolio-group/client-utils';
import { OpenOrdersDataProps } from '../screens/POS/Orders/OpenOrders/Table/OpenOrdersTable';
import { OrderTypesMap, createOrderTypesMap } from './orderTypesHelper';
import {
  getTablesFromOrder,
  isInventoryBeingTracked,
} from '@oolio-group/order-helper';
import { getOrderIdentifier } from '../utils/customerHelper';
import { disableTokenNumberForOrderType } from './tokenNumber';

export const mapOrderToOpenOrdersView = (
  obj: Order,
  orderTypes: OrderTypesMap,
): OpenOrdersDataProps => {
  const orderType = (obj.orderType || {}) as unknown as OrderType;
  const customerName = getOrderIdentifier(obj);
  return {
    id: obj.id,
    orderNumber: obj.orderNumber,
    createdAt: obj.createdAt,
    updatedAt: obj.updatedAt || obj.createdAt,
    staff: obj.createdBy.name,
    orderItems: obj.orderItems
      ? obj.orderItems.map((item: { product: unknown }) => ({
          name: (item.product as unknown as Product).name,
        }))
      : [],
    customer: customerName,
    orderType: {
      ...orderType,
      colorId: orderTypes[orderType.id]?.colorId || 0,
      code: orderTypes[orderType.id]?.code || orderType.code || '',
    },
    orderNote: obj.orderNote || '',
    totalValue: obj.totalPaymentAmount || obj.totalPrice,
    table: obj.table,
    tables: obj.tables,
    isTraining: !!obj.isTraining,
    tokenNumber:
      Number.isInteger(obj.tokenNumber) &&
      !disableTokenNumberForOrderType(obj.orderType)
        ? obj?.tokenNumber?.toString()
        : '',
    payTypes: (obj.payments as unknown as Array<OrderPayment>).map(
      (payment: OrderPayment) => {
        const paymentName = (payment?.paymentType as unknown as PaymentType)
          ?.name;
        const paymentAmount = payment?.amount;
        return {
          id: (payment?.paymentType as unknown as PaymentType)?.id,
          name: paymentName,
          amount: paymentAmount,
        };
      },
    ),
  };
};

export const mapOrdersToOpenOrdersView = (
  array: Array<Order>,
  orderTypes: OrderType[],
): OpenOrdersDataProps[] => {
  const orderTypesMap = createOrderTypesMap(orderTypes);
  return array
    .map(item => mapOrderToOpenOrdersView(item, orderTypesMap))
    .sort((a, b) => b.createdAt - a.createdAt);
};

export const filterOpenOrdersData = (
  orders: Array<OpenOrdersDataProps>,
  orderTypeFilter: OrderType['name'],
  keywordFilter: string,
): Array<OpenOrdersDataProps> =>
  orders.filter(order => {
    if (
      orderTypeFilter &&
      orderTypeFilter !== 'all' &&
      !order.orderType?.name?.includes(orderTypeFilter)
    ) {
      return false;
    }

    if (keywordFilter) {
      const query = keywordFilter.toLowerCase();
      const customerMatch = !!order.customer?.toLowerCase().includes(query);
      const orderNumberMatch = !!order.orderNumber
        ?.toLowerCase()
        .includes(query);
      const tokenNumberMatch = !!order.tokenNumber?.includes(query);
      const itemsNameMatch = !!order.orderItems?.some(({ name }) =>
        name?.toLowerCase().includes(query),
      );
      const notesMatch = !!order.orderNote?.toLowerCase().includes(query);

      const tables = getTablesFromOrder(order);
      const tableNumberMatch = tables.some(t =>
        t.name?.toLowerCase().includes(query),
      );

      return (
        customerMatch ||
        orderNumberMatch ||
        itemsNameMatch ||
        notesMatch ||
        tableNumberMatch ||
        tokenNumberMatch
      );
    }
    return true;
  });

export const productQuantitiesToReturnOnVoidOrder = (
  allProducts: Record<string, Product>,
  order: Order,
  currentStore = '',
) => {
  const productQuantities = {} as Record<string, number>;

  const acceptedOrderItemStatuses = [
    OrderItemStatus.IN_PROGRESS,
    OrderItemStatus.ON_HOLD,
  ];
  order.orderItems.forEach(orderItem => {
    const itemProductId = orderItem.product.id;
    const product = cloneJSON(allProducts[itemProductId]);
    const isProductTracked = isInventoryBeingTracked(
      product?.storesInventory,
      currentStore,
    );
    if (
      isProductTracked &&
      acceptedOrderItemStatuses.includes(orderItem.status)
    ) {
      productQuantities[itemProductId] =
        (productQuantities[itemProductId] || 0) + orderItem.quantity;
    }
  });

  return productQuantities;
};
