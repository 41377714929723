import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View, Platform, TouchableOpacity } from 'react-native';
import Search from '../../../../components/Shared/Search/Search';
import TreatPicker from '../../../../components/Shared/Select/Picker';
import { useTranslation } from '@oolio-group/localization';
import { ProductListItem } from './types';
import { NON_CATEGORIZED_ITEMS } from '@oolio-group/domain';
import { FilterValue } from './ProductList';
import Icon from '../../../../components/Icon/Icon';
import theme from '../../../../common/default-theme';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import ProductFilterModal from './Modals/ProductFilterModal';
import { useExportEntityInterval } from '../../../../hooks/app/exportEntities/useExportEntityInterval';
import ButtonActions from '../../../../components/Shared/TreatButton/ButtonActions';
import { useNotification } from '../../../../hooks/Notification';

export interface LabelFilter {
  value: string;
  label: string;
}

interface ProductRowProps {
  categories: LabelFilter[];
  storeOptions: LabelFilter[];
  filter: FilterValue;
  onChangeFilter: (value: FilterValue) => void;
  onPressImport: () => void;
  isImportProductFeatureEnabled: boolean;
  printerProfileOptions: { value: string; label: string }[];
  optionsGroupOptions: { value: string; label: string }[];
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    width: Platform.select({
      web: 180,
      default: 140,
    }),
    marginRight: 10,
  },
  search: {
    flex: 1,
    height: 44,
    marginRight: 10,
  },
  uploadButton: {
    marginRight: 10,
  },
  btnContainer: {
    marginRight: 10,
  },
  filterContainer: {
    ...theme.forms.inputHalf,
    height: 200,
  },
  btn: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
});

export const filterBySearchQuery = (
  productItem: ProductListItem,
  searchQuery: string,
): boolean => {
  const searchString = searchQuery.toLowerCase();
  const isMatch =
    productItem?.name?.toLowerCase().includes(searchString) ||
    productItem?.barcode?.toLowerCase().includes(searchString);

  return isMatch;
};

export const filterByPrinterProfile = (
  productItem: ProductListItem,
  checkingPrinterProfiles: Set<string>,
): boolean => {
  if (
    checkingPrinterProfiles.has('none') &&
    !productItem?.printerProfiles?.length
  ) {
    return true;
  }
  const isMatch = productItem.printerProfiles?.some(profile =>
    checkingPrinterProfiles.has(profile),
  );
  return Boolean(isMatch);
};

export const filterByOptionGroups = (
  productItem: ProductListItem,
  validProductIds: Set<string>,
  filterNoneOption?: boolean,
): boolean => {
  if (filterNoneOption && validProductIds.has(productItem.id)) {
    return true;
  }
  return validProductIds.has(productItem.id);
};
export const filterByCategory = (
  item: ProductListItem,
  categoryEntities: Set<string>,
): boolean => {
  return categoryEntities.has(item.id);
};

export const filterByStore = (
  item: ProductListItem,
  storeEntities: Set<string>,
): boolean => {
  return storeEntities.has(item.id);
};

export const ProductHeaderFilters: React.FC<ProductRowProps> = ({
  storeOptions,
  categories,
  filter,
  printerProfileOptions,
  optionsGroupOptions,
  onChangeFilter,
  onPressImport,
  isImportProductFeatureEnabled,
}) => {
  const { translate } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { processExportProduct } = useExportEntityInterval();
  const { showNotification } = useNotification();
  const onChange = useCallback(
    (name: keyof FilterValue, value: string | string[]) => {
      onChangeFilter({ ...filter, [name]: value });
    },
    [filter, onChangeFilter],
  );

  const onSetFilters = useCallback(
    (printerProfiles: string[], optionGroups: string[]) => {
      onChangeFilter({
        ...filter,
        printerProfilesFilter: printerProfiles,
        optionGroups: optionGroups,
      });
    },
    [filter, onChangeFilter],
  );

  const onPressExport = useCallback(() => {
    showNotification({
      info: true,
      message: translate('backOfficeProducts.exportRunning'),
    });
    processExportProduct();
  }, [processExportProduct, showNotification, translate]);

  const ACTION_OPTIONS = useMemo(
    () => [
      {
        testID: 'btn-import',
        label: translate('backOfficeProducts.import'),
        icon: 'cloud-upload',
        iconColor: theme.colors.states.neutral,
        action: onPressImport,
      },
      {
        testID: 'btn-export',
        label: translate('backOfficeProducts.export'),
        icon: 'cloud-download',
        iconColor: theme.colors.states.neutral,
        action: onPressExport,
      },
    ],
    [onPressExport, onPressImport, translate],
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-filter"
        onPress={() => setIsModalVisible(true)}
        style={[
          styles.btn,
          styles.btnContainer,
          { backgroundColor: theme.colors.blueLight },
        ]}
      >
        <Icon name="sliders-v" size={20} color={theme.colors.blue} />
      </TouchableOpacity>
      <TreatPicker
        testID="select-venue"
        selectedValue={filter.store}
        options={[
          {
            label: translate('backOfficeProducts.productFilterByVenue'),
            value: '',
          },
        ].concat(...storeOptions)}
        onValueChange={onChange.bind(null, 'store')}
        containerStyle={styles.dropdown}
      />
      <TreatPicker
        testID="select-category"
        selectedValue={filter.category}
        options={[
          {
            label: translate('backOfficeProducts.productFilterByCategory'),
            value: '',
          },
          {
            label: translate('backOfficeProducts.notCategorizedProducts'),
            value: NON_CATEGORIZED_ITEMS,
          },
        ].concat(...(categories || []))}
        onValueChange={onChange.bind(null, 'category')}
        containerStyle={styles.dropdown}
      />
      <Search
        testID="search-products"
        value={filter.searchString}
        onChangeText={onChange.bind(null, 'searchString')}
        placeholder={translate('backOfficeProducts.productSearchByName')}
        containerStyle={styles.search}
      />
      {isImportProductFeatureEnabled && (
        <ButtonActions
          icon="exchange"
          actions={ACTION_OPTIONS}
          type="neutralLight"
          containerStyle={styles.btnContainer}
          label=""
        />
      )}
      <ModalWrapper isVisible={isModalVisible}>
        <ProductFilterModal
          selectedFilters={filter}
          onChangeFilter={onSetFilters}
          printerProfileOptions={printerProfileOptions}
          optionsGroupOptions={optionsGroupOptions}
          onCloseModal={() => setIsModalVisible(false)}
        />
      </ModalWrapper>
    </View>
  );
};
