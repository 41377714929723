import React, { FC } from 'react';
import { View, Text } from 'react-native';
import styles from './ShiftsSidePanel.styles';

interface ShiftDetailGroupProps {
  title?: string;
  children: React.ReactNode;
}

interface ShiftDetailRowProps {
  title: string;
  negative?: boolean;
  value: string | number;
  testID: string;
}

interface ShiftDetailTotalRowProps {
  title: string;
  value: string;
  testID: string;
}

export const ShiftDetailRow: FC<ShiftDetailRowProps> = ({
  title,
  value,
  negative = false,
  testID,
}: ShiftDetailRowProps) => (
  <View testID={testID} style={styles.row}>
    <Text
      numberOfLines={1}
      style={[styles.rowTitle, negative && styles.negative]}
    >
      {title}
    </Text>
    <Text
      numberOfLines={1}
      style={[styles.rowValue, negative && styles.negative]}
    >
      {`${negative && !value.toString().includes('-') ? '-' : ''}${value}`}
    </Text>
  </View>
);

export const ShiftDetailTotalRow: FC<ShiftDetailTotalRowProps> = ({
  title,
  value,
  testID,
}) => {
  return (
    <View testID={testID} style={styles.row}>
      <Text style={styles.rowTotalText}>{title}</Text>
      <Text style={styles.rowTotalText}>{value}</Text>
    </View>
  );
};

export const ShiftGroup: FC<ShiftDetailGroupProps> = ({
  title,
  children,
}: ShiftDetailGroupProps) => (
  <View style={styles.group}>
    {title ? (
      <Text numberOfLines={1} style={styles.groupTitle}>
        {title}
      </Text>
    ) : null}
    {children}
  </View>
);
