// Design System
// Typography

export const fonts = {
  regular: 'Inter-Regular',
  medium: 'Inter-Medium',
  semibold: 'Inter-Semibold',
  bold: 'Inter-Bold',
};

export const fontSize = {
  xs: 11,
  s: 14,
  m: 16,
  l: 18,
  xl: 24,
};

export const letterSpacing = -0.4;

export const lineHeight = (fontSize: number) => {
  return 2 * Math.round((fontSize * 1.4) / 2);
};

// Common Text Styles

export const sharedTextStyles = {
  button: {
    fontSize: fontSize.s,
    fontFamily: fonts.regular,
    letterSpacing: letterSpacing,
    lineHeight: fontSize.s,
    textTransform: 'uppercase',
  },
  table: {
    regular: {
      fontSize: fontSize.s,
      fontFamily: fonts.regular,
      letterSpacing: letterSpacing,
      lineHeight: lineHeight(fontSize.s),
    },
    medium: {
      fontSize: fontSize.s,
      fontFamily: fonts.medium,
      letterSpacing: letterSpacing,
      lineHeight: lineHeight(fontSize.s),
    },
  },
  input: {
    title: {
      fontSize: fontSize.s,
      fontFamily: fonts.medium,
      letterSpacing: letterSpacing,
      lineHeight: fontSize.s,
    },
    subtitle: {
      fontSize: fontSize.s,
      fontFamily: fonts.regular,
      letterSpacing: letterSpacing,
      lineHeight: lineHeight(fontSize.s),
    },
    label: {
      fontSize: fontSize.s,
      fontFamily: fonts.medium,
      letterSpacing: letterSpacing,
      lineHeight: fontSize.s,
    },
    value: {
      fontSize: fontSize.s,
      fontFamily: fonts.regular,
      letterSpacing: letterSpacing,
      lineHeight: fontSize.s,
    },
  },
  hotkey: {
    fontSize: fontSize.s,
    fontFamily: fonts.bold,
    letterSpacing: 0,
    lineHeight: lineHeight(fontSize.s),
    textAlign: 'center',
  },
  message: {
    fontSize: fontSize.s,
    fontFamily: fonts.medium,
    letterSpacing: letterSpacing,
    lineHeight: lineHeight(fontSize.s),
    textAlign: 'center',
  },
  modal: {
    title: {
      fontSize: fontSize.m,
      fontFamily: fonts.medium,
      letterSpacing: letterSpacing,
      lineHeight: fontSize.m,
    },
  },
  option: {
    fontSize: fontSize.s,
    fontFamily: fonts.medium,
    letterSpacing: letterSpacing,
    lineHeight: fontSize.s,
  },
  optionSubtitle: {
    fontSize: fontSize.s,
    fontFamily: fonts.medium,
    letterSpacing: letterSpacing,
    lineHeight: lineHeight(fontSize.s),
  },
  segment: {
    fontSize: fontSize.s,
    fontFamily: fonts.medium,
    letterSpacing: letterSpacing,
    lineHeight: fontSize.s,
    textTransform: 'capitalize',
  },
  status: {
    fontSize: 12,
    fontFamily: fonts.bold,
    letterSpacing: letterSpacing,
    lineHeight: 12,
    textAlign: 'center',
  },
  sticker: {
    fontSize: fontSize.s,
    fontFamily: fonts.bold,
    letterSpacing: letterSpacing,
    lineHeight: fontSize.s,
    textAlign: 'center',
  },
};
