import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import TabBar from '../../../../../../components/TabBar/TabBar';
import { Details } from './Details/Details';
import { Fulfilments } from './Fullfilments/Fullfilments';
import { IntegrationApps } from '@oolio-group/domain';
import { Checkout } from './Checkout/Checkout';
import { Communications } from './Communications/Communications';

const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  storeId: string;
  app: IntegrationApps;
}

const OnlineOrderIntegrationsStoreSettingsTabs: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'SettingsTab'}
        />
      )}
      initialRouteName="SettingsTab"
    >
      <Tab.Screen
        name="Details"
        component={Details}
        options={{
          tabBarLabel: translate('backOfficeFeatures.tabNames.details'),
        }}
        initialParams={params}
      />
      {params.app === IntegrationApps.OOLIO_STORE && (
        <>
          <Tab.Screen
            name="Fulfilments"
            component={Fulfilments}
            options={{
              tabBarLabel: translate('backOfficeFeatures.tabNames.fulfilments'),
            }}
            initialParams={params}
          />
          <Tab.Screen
            name="Checkout"
            component={Checkout}
            options={{
              tabBarLabel: translate('backOfficeFeatures.tabNames.checkout'),
            }}
            initialParams={params}
          />
          <Tab.Screen
            name="Communications"
            component={Communications}
            options={{
              tabBarLabel: translate(
                'backOfficeFeatures.tabNames.communications',
              ),
            }}
            initialParams={params}
          />
        </>
      )}
    </Tab.Navigator>
  );
};

export default OnlineOrderIntegrationsStoreSettingsTabs;
