import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { getBase64Data } from '@oolio-group/client-utils';
import Message from '../../../../../components/Office/Message/Message';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import SelectFile from '../../../../../components/Shared/Select/SelectFile';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import theme from '../../../../../common/default-theme';
import { setImportEntityState } from '../../../../../state/preferences';
import {
  IMPORT_ENTITY_FILENAME,
  IMPORT_ENTITY_STATUS,
} from '@oolio-group/domain';
import { useImportEntityInterval } from '../../../../../hooks/app/importEntities/useImportEntityInterval';
import { buildURI, downloadFile } from '../../../../../utils/csv';
import { useProductImport } from '../../../../../hooks/app/importEntities/useProductImport';
import styles from './ImportProduct.styles';
import MessageAction from '../../../../../components/Office/MessageAction/MessageAction';

interface ErrorRowProps {
  line: string | number;
  product: string;
  errors: string[];
}

const ErrorRow: React.FC<ErrorRowProps> = ({
  line,
  product,
  errors,
}: ErrorRowProps) => {
  return (
    <View testID="row-error" style={theme.tables.row}>
      <Text
        style={[theme.textStyles.table.default, styles.cellLine]}
        numberOfLines={1}
      >
        {line}
      </Text>
      <Text style={[theme.textStyles.table.default, styles.cellName]}>
        {product}
      </Text>
      <Text style={[theme.textStyles.table.default, styles.cellError]}>
        {errors.join(',').replaceAll(',', '\n')}
      </Text>
    </View>
  );
};

const ImportProducts: React.FC = () => {
  const { translate } = useTranslation();
  const [file, setFile] = useState<File | null>();
  const {
    importProductDetailState,
    processImportProduct,
    loading,
    clearState,
  } = useImportEntityInterval();
  const { getImportEntityData, importEntityFileData } = useProductImport();

  const onFileSelect = useCallback((file: File) => {
    setFile(file);
  }, []);

  const onDownloadTemplates = useCallback(() => {
    getImportEntityData();
  }, [getImportEntityData]);

  useEffect(() => {
    if (importEntityFileData) {
      const uri = buildURI(importEntityFileData);
      downloadFile(uri, IMPORT_ENTITY_FILENAME.PRODUCT);
    }
  }, [importEntityFileData]);

  const onPressImport = useCallback(async () => {
    try {
      const fileToUpload = file as unknown as File;
      const base64 = await getBase64Data(file as File);
      await setImportEntityState({
        productEntityId: '',
        isImportProductMessageCleared: false,
        isProductListMessageCleared: false,
      });
      processImportProduct({
        base64,
        name: fileToUpload.name,
        type: fileToUpload.name.split('.')[1],
      });
    } catch (ex) {
      console.log('Error while uploading :: ', ex);
    }
  }, [file, processImportProduct]);

  const clearImportHistory = useCallback(() => {
    clearState();
  }, [clearState]);

  useEffect(() => {
    if (
      [IMPORT_ENTITY_STATUS.FAILED, IMPORT_ENTITY_STATUS.COMPLETED].includes(
        importProductDetailState?.status as IMPORT_ENTITY_STATUS,
      )
    ) {
      setFile(null);
    }
  }, [importProductDetailState?.status]);

  return (
    <ScreenLayout title={translate('importEntity.importProductHeading')}>
      {importProductDetailState?.status === IMPORT_ENTITY_STATUS.COMPLETED ? (
        !importProductDetailState.validationMessage?.length ? (
          <MessageAction
            message={translate('importEntity.successMenuMessage', {
              successProductCount: importProductDetailState?.successCount,
              updateProductCount: importProductDetailState?.updateCount,
            })}
            type="positive"
            containerStyle={styles.message}
            onPressClose={clearImportHistory}
          />
        ) : (
          <Section>
            <Message
              type="negative"
              message={translate('importEntity.importProductError', {
                failedCount: importProductDetailState.failedCount,
              })}
              containerStyle={styles.message}
              onClose={() => clearImportHistory()}
            />

            <View style={theme.tables.header}>
              <Text style={[theme.tables.headerText, styles.cellLine]}>
                {translate('importEntity.line')}
              </Text>
              <Text style={[theme.tables.headerText, styles.cellName]}>
                {translate('importEntity.product')}
              </Text>
              <Text style={[theme.tables.headerText, styles.cellError]}>
                {translate('importEntity.error')}
              </Text>
            </View>
            <View style={styles.tableBody}>
              {importProductDetailState?.validationMessage?.map((x, i) => (
                <ErrorRow
                  key={i}
                  line={x.lineNo}
                  product={x.productName}
                  errors={x.messages.map(m => '- ' + m)}
                />
              ))}
            </View>
          </Section>
        )
      ) : null}
      <Section
        title={translate('importEntity.importProducts')}
        subtitle={translate('importEntity.importProductSubTitle')}
      >
        {!importProductDetailState ||
        [IMPORT_ENTITY_STATUS.COMPLETED, IMPORT_ENTITY_STATUS.FAILED].includes(
          importProductDetailState?.status,
        ) ? (
          <>
            <View style={theme.forms.row}>
              <SelectFile onComplete={onFileSelect} />
            </View>
            <View style={styles.btnRow}>
              <TreatButton
                label={translate('importEntity.downloadTemplates')}
                onPress={onDownloadTemplates}
                type="primaryLight"
                containerStyle={styles.btnContainer}
              />
              <TreatButton
                label={translate('importEntity.importProducts')}
                onPress={onPressImport}
                type="accent"
                disabled={!file || !!loading}
                isLoading={!!loading}
              />
            </View>
          </>
        ) : (
          <View style={styles.loadingView}>
            <ActivityIndicator size={'small'} color={theme.colors.grey6} />
            <Text style={theme.tables.emptyText}>
              {translate('importEntity.buildingMenu')}
            </Text>
          </View>
        )}
      </Section>
    </ScreenLayout>
  );
};

export default ImportProducts;
