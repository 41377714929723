import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    ...theme.shadow.strong,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    width: 600,
    alignSelf: 'center',
    zIndex: 10,
  },
  title: {
    paddingTop: 40,
    paddingBottom: 30,
    paddingHorizontal: 42,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.neutral,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 30,
  },
  subAddressContainerText: {
    width: 150,
  },
  pinCodeAddressContainerText: {
    width: 100,
  },
  formInputContainerStyle: {
    width: 280,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 40,
    marginBottom: 30,
    paddingHorizontal: 30,
    backgroundColor: theme.colors.white,
  },
});

export default styles;
