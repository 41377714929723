import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  icon: {
    alignSelf: 'center',
  },
  container: {
    borderRadius: 10,
    width: 340,
    padding: 30,
    alignItems: 'center',
  },
  header: {
    fontSize: 20,
    textAlign: 'center',
    color: theme.colors.black,
    fontFamily: theme.fonts.bold,
    marginTop: 32,
    marginBottom: 8,
    lineHeight: 28,
  },
  message: {
    fontSize: theme.fontSize.s,
    textAlign: 'center',
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
    lineHeight: 20,
  },
  btnSearchProduct: {
    marginTop: 30,
    marginBottom: 10,
  },
});

export default styles;
