import { useCallback, useState } from 'react';
import { Printer, PrinterBrand } from '@oolio-group/domain';
import { PrinterResponse, connectAndSend, XML_COMMAND } from './epson-sdk';
import { useSession } from './useSession';
import {
  unreachablePrintersVar,
  reachablePrintersVar,
  PrinterStatus,
} from './usePrinterStatusVar';

export interface UsePrinterTroubleshooterProps {
  getPrinterStatus: (
    printerIPAddress: string,
  ) => Promise<PrinterResponse | void>;
  refreshPrinter: (printerIPAddress: string) => Promise<PrinterResponse | void>;
  restartPrinter: (printerIPAddress: string) => Promise<PrinterResponse | void>;
  getPrinterStatuses: (printerIds?: string[]) => Promise<
    | {
        reachablePrinters: PrinterStatus[];
        unreachablePrinters: PrinterStatus[];
      }
    | undefined
  >;
  loading: boolean;
}

export function usePrinterTroubleshooter(): UsePrinterTroubleshooterProps {
  const [session] = useSession();
  const [loading, setLoading] = useState<boolean>(false);
  // Helper function that communicates with the printer
  // It sends a command to the printer based on the command argument
  // Possible commands now are 'status', 'recover', and 'reset'

  // Timeout is for printer response, not printer connection
  const executeCommand = useCallback(
    async (command: XML_COMMAND, printerIPAddress: string) => {
      setLoading(true);
      const messageBuffer = Buffer.from('Troubleshooting Printer');
      if (printerIPAddress) {
        const sendCommand = await connectAndSend(
          printerIPAddress,
          messageBuffer,
          {
            timeout: 10000,
          },
          command,
        );
        setLoading(false);
        return sendCommand;
      }
      setLoading(false);
      return;
    },
    [],
  );

  // Function to get printer's status.
  const getPrinterStatus = useCallback(
    async (printerIPAddress: string) => {
      return executeCommand(XML_COMMAND.STATUS, printerIPAddress);
    },
    [executeCommand],
  );

  // Function to refresh printer.
  const refreshPrinter = useCallback(
    async (printerIPAddress: string) => {
      return executeCommand(XML_COMMAND.RECOVER, printerIPAddress);
    },
    [executeCommand],
  );

  // Function to restart printer.
  const restartPrinter = useCallback(
    async (printerIPAddress: string) => {
      return executeCommand(XML_COMMAND.RESET, printerIPAddress);
    },
    [executeCommand],
  );

  const updatePrinterStatus = useCallback(
    (
      reachablePrinters: Record<string, PrinterStatus>,
      unreachablePrinters: Record<string, PrinterStatus>,
      updateAllPrinters: boolean,
    ) => {
      if (updateAllPrinters) {
        reachablePrintersVar(reachablePrinters);
        unreachablePrintersVar(unreachablePrinters);
        return;
      }

      const goodPrinters = Object.assign({}, reachablePrintersVar());
      const badPrinters = Object.assign({}, unreachablePrintersVar());

      const goodPrintersKeys = Object.keys(reachablePrinters);
      const badPrintersKeys = Object.keys(unreachablePrinters);
      // for each good printer, if it is present in bad printers, remove it
      goodPrintersKeys.forEach(id => {
        delete badPrinters[id];
        goodPrinters[id] = reachablePrinters[id];
      });
      // for each bad printer, if it is present in good printers, remove it
      badPrintersKeys.forEach(id => {
        delete goodPrinters[id];
        badPrinters[id] = unreachablePrinters[id];
      });
      reachablePrintersVar(goodPrinters);
      unreachablePrintersVar(badPrinters);
    },
    [],
  );

  // Function to get status of all printers in device
  const getPrinterStatuses = useCallback(
    async (printerIds?: string[]) => {
      setLoading(true);
      const uniquePrinterIds = new Set(
        printerIds ||
          (session.device?.printingOptions || []).map(
            option => option.printer.id,
          ),
      );
      const printers: Printer[] = [];
      for (const id of uniquePrinterIds) {
        const printer = (session.device?.printingOptions || []).find(
          option => option.printer?.id === id,
        )?.printer;

        if (printer) {
          printers.push(printer);
        }
      }

      const reachablePrinters: Record<string, PrinterStatus> = {};
      const unreachablePrinters: Record<string, PrinterStatus> = {};

      for (const printer of printers) {
        if (printer.brand == PrinterBrand.EPSON_WEB) {
          try {
            const response = await getPrinterStatus(printer.ipAddress || '');
            if (response && response.success) {
              reachablePrinters[printer.id] = {
                printerAddress: printer.ipAddress || '',
                printerId: printer.id,
              };
            } else {
              unreachablePrinters[printer.id] = {
                printerAddress: printer.ipAddress || '',
                printerId: printer.id,
                responseSuccess: response?.responseSuccess || '',
                responseCode: response?.responseCode || '',
                responseStatus: response?.responseStatus || '',
              };
            }
          } catch (error) {
            unreachablePrinters[printer.id] = {
              printerAddress: printer.ipAddress || '',
              printerId: printer.id,
            };
          }
        }
      }
      updatePrinterStatus(
        reachablePrinters,
        unreachablePrinters,
        !printerIds?.length,
      );
      setLoading(false);

      return {
        reachablePrinters: Object.values(reachablePrinters),
        unreachablePrinters: Object.values(unreachablePrinters),
      };
    },
    [getPrinterStatus, updatePrinterStatus, session.device?.printingOptions],
  );

  return {
    loading,
    getPrinterStatus,
    refreshPrinter,
    restartPrinter,
    getPrinterStatuses,
  };
}
