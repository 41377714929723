import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { Tax } from '@oolio-group/domain';
import find from 'lodash/find';
import { CreateGroupTaxModal } from '../Modals/CreateGroupTaxModal';
import styles from '../Taxes.styles';
import theme from '../../../../../../common/default-theme';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import Section from '../../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../../components/Office/CreateButton/CreateButton';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface TaxesProps {
  taxes: TaxToTax[];
  onChange: (index: number, prop: string, value: string | string[]) => void;
  openDeleteModal: (index: number, id: string, name: string) => void;
  onAddTax: (tax: TaxToTax) => void;
  isFeeModal?: boolean;
}

interface TaxesGroupRowProps {
  Tax: TaxToTax;
  onDeleteTax: (index: number, id: string, name: string) => void;
  onChange: (index: number, prop: string, value: string | string[]) => void;
  taxes: TaxToTax[];
  taxesWithoutGroupTax: { label: string; value: string }[];
  index: number;
  selectedTaxes: string[];
}

const TaxesGroupRow: React.FC<TaxesGroupRowProps> = ({
  Tax,
  onDeleteTax,
  taxes,
  taxesWithoutGroupTax,
  selectedTaxes,
  onChange,
  index,
}: TaxesGroupRowProps) => {
  const { translate } = useTranslation();
  const calculatedTax = useMemo(() => {
    let value = 0;
    (selectedTaxes || []).map(taxId => {
      const tax = find(taxes, { id: taxId });
      value = value + parseFloat(tax?.rate || '0');
      return value;
    });
    return value;
  }, [selectedTaxes, taxes]);

  return (
    (Tax.taxes.length && (
      <View testID="row-taxGroup" style={theme.tables.row}>
        <InputText
          testID="tax-code"
          value={Tax.code}
          placeholder={translate('backOfficeSettings.code')}
          onChangeText={onChange.bind(null, index, 'code')}
          maxLength={4}
          alignText="center"
          containerStyle={styles.cellCode}
        />
        <InputText
          testID="tax-name"
          value={Tax.name}
          placeholder={translate('backOfficeSettings.taxName')}
          onChangeText={onChange.bind(null, index, 'name')}
          maxLength={50}
          containerStyle={styles.cellName}
        />
        <SelectMultiple
          testID="select-taxes"
          options={taxesWithoutGroupTax}
          selectedValues={selectedTaxes || []}
          onValueChange={onChange.bind(null, index, 'taxes')}
          containerStyle={styles.cellTaxes}
        />
        <InputText
          testID="input-rate"
          label="%"
          value={calculatedTax.toString()}
          placeholder={translate('backOfficeSettings.rate')}
          onChangeText={onChange.bind(null, index, 'rate')}
          keyboardType="numeric"
          editable={false}
          alignText="right"
          containerStyle={styles.cellRate}
        />
        <ButtonIcon
          testID="btn-deleteTaxGroup"
          icon="trash-alt"
          type="negativeLight"
          onPress={(): void => onDeleteTax(index, Tax.id, Tax.name)}
        />
      </View>
    )) ||
    null
  );
};

export const TaxesGroupSection: React.FC<TaxesProps> = ({
  taxes,
  onChange,
  openDeleteModal,
  onAddTax,
}: TaxesProps) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const taxesWithoutGroupTax = useMemo(() => {
    return taxes
      .filter(x => x && x.taxes.length === 0)
      .map(eachTax => {
        return { label: eachTax.code, value: eachTax.id };
      });
  }, [taxes]);

  const onCreateNew = useCallback((): void => {
    showModal(
      <CreateGroupTaxModal
        taxes={taxes}
        onAddTax={onAddTax}
        taxesWithoutGroupTax={taxesWithoutGroupTax}
      />,
      translate('backOfficeSettings.createGroupTax'),
    );
  }, [showModal, taxes, onAddTax, translate, taxesWithoutGroupTax]);

  return (
    <Section
      title={translate('backOfficeSettings.groupTaxes')}
      layoutWidth="medium"
    >
      <View style={styles.tableContainer}>
        <View style={theme.tables.header}>
          <Text style={[theme.tables.headerText, styles.headerCode]}>
            {translate('backOfficeSettings.code')}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerName]}>
            {translate('form.name')}
          </Text>
          <Text style={theme.tables.headerText}>
            {translate('backOfficeSettings.assignedTaxes')}
          </Text>
          <Text style={[theme.tables.headerText, styles.headerRate]}>
            {translate('backOfficeSettings.rate')}
          </Text>
        </View>
        <View style={styles.tableBody}>
          {taxes.map((tax: TaxToTax, i: number) => {
            const selectedTaxes = tax.taxes.map(taxGroup => taxGroup.id);
            return (
              <TaxesGroupRow
                key={i}
                Tax={tax}
                onDeleteTax={openDeleteModal}
                taxes={taxes}
                taxesWithoutGroupTax={taxesWithoutGroupTax}
                selectedTaxes={selectedTaxes}
                onChange={onChange}
                index={i}
              />
            );
          })}
        </View>
        <CreateButton onPress={onCreateNew} />
      </View>
    </Section>
  );
};
