import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { View } from 'react-native';
import {
  AppScreen,
  OrderStatus,
  PaymentTypeSaleSummary,
  Resource,
  Shift,
  ShiftStatus,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  excludeOnAccountPaymentTypeSummary,
  sortShiftByPaymentType,
} from '@oolio-group/client-utils';
import { useShifts } from '../../../hooks/app/useShifts';
import { useDevices } from '../../../hooks/app/useDevices';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import { useNotification, Notification } from '../../../hooks/Notification';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import styles from './Shifts.styles';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import Search from '../../../components/Shared/Search/Search';
import TreatPicker from '../../../components/Shared/Select/Picker';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import ShiftsTable from './Table/ShiftsTable';
import ShiftSidePanel from './SidePanel/ShiftsSidePanel';
import Message from '../../../components/Office/Message/Message';
import ConfirmationModal from '../../../components/Modals/ConfirmationDialog';
import CloseShift from '../../../components/POS/Modals/Shifts/CloseShift/CloseShift';
import CloseShiftWarning from '../../../components/POS/Modals/Shifts/CloseShiftWarning/CloseShiftWarning';
import cloneDeep from 'lodash/cloneDeep';
import { startOfDay, subDays } from 'date-fns';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';

enum ShiftTypeFilter {
  showAll = 'ShowAll',
  open = 'OPEN',
  closed = 'CLOSED',
}

const Shifts: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { getDeviceDataById } = useDevices();
  const { canI } = usePOSUserAuthorization();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { isConnected } = useNetworkStatus();
  const {
    getShifts: getClosedShift,
    shifts: closedShifts = [],
    loading: closeShiftLoading,
  } = useShifts();
  const {
    getShifts: getOpenShift,
    shifts: openShifts = [],
    loading: openShiftLoading,
  } = useShifts();

  const shifts = useMemo(() => {
    if (!closeShiftLoading && !openShiftLoading) {
      return [...openShifts, ...closedShifts];
    } else return [];
  }, [closeShiftLoading, closedShifts, openShiftLoading, openShifts]);

  const { printShiftReceipt, openCashDrawer } = usePrinting();
  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
    getOrdersFromCache,
    returnOrdersFromCache,
  } = useOrders();

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedShift, setSelectedShift] = useState<Shift | null>();
  const [typeFilter, setTypeFilter] = useState<ShiftTypeFilter>(
    ShiftTypeFilter.open,
  );

  const navigateBack = useCallback(() => {
    closeModal();
    navigation.goBack();
  }, [closeModal, navigation]);

  const openOrders = useMemo(() => {
    const onlineOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS, true);
    return [...Object.values(orders), ...onlineOrders];
  }, [orders, returnOrdersFromCache]);

  useFocusEffect(
    useCallback(() => {
      if (isConnected) {
        closeModal();
      } else {
        showModal(
          <ConfirmationModal
            title={translate('offline.noInternet')}
            message={translate('shift.connectToInternet')}
            confirmLabel={translate('shift.goBack')}
            onConfirm={navigateBack}
            hideCancel
          />,
        );
      }
    }, [isConnected, closeModal, showModal, translate, navigateBack]),
  );

  const onPressPrint = useCallback(
    async (shift?: Shift) => {
      if (shift) {
        const hasAccess = canI([{ onResource: Resource.PRINT_SHIFT_SUMMARY }], {
          prompt: true,
        });
        if (!hasAccess) {
          return;
        }
        const res = await printShiftReceipt(shift);
        if (res) showNotification(res as Notification);
      }
    },
    [printShiftReceipt, showNotification, canI],
  );

  const openShift = shifts.find(item => item.shiftStatus === ShiftStatus.OPEN);
  const showVariance = canI(
    [{ onResource: Resource.VIEW_CLOSURE_DIFFERENCES }],
    {
      prompt: false,
    },
  );

  const onCloseShift = useCallback(() => {
    let sortedShiftData: Shift | undefined = undefined;

    if (openShift) {
      const openShiftCopy = {
        ...openShift,
        salesByPaymentType: [...openShift.salesByPaymentType],
      };
      sortedShiftData = sortShiftByPaymentType(openShiftCopy);
    }

    if (!!sortedShiftData) {
      const showViewClosureDifferences = canI(
        [{ onResource: Resource.VIEW_CLOSURE_DIFFERENCES }],
        {
          prompt: false,
        },
      );
      showModal(
        <CloseShift
          data={sortedShiftData}
          showViewClosureDifferences={showViewClosureDifferences}
          openCashDrawer={openCashDrawer}
          printShiftReceipt={printShiftReceipt}
          getDeviceDataById={getDeviceDataById}
        />,
      );
    }
  }, [
    openShift,
    canI,
    showModal,
    openCashDrawer,
    printShiftReceipt,
    getDeviceDataById,
  ]);

  const onPressCloseShift = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.PERFORM_SHIFT_CLOSURE }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    const filteredOrders = openOrders.filter(
      x => x.status === OrderStatus.IN_PROGRESS,
    );
    if (filteredOrders.length) {
      showModal(
        <CloseShiftWarning onClose={onCloseShift} orders={filteredOrders} />,
      );
    } else onCloseShift();
  }, [openOrders, showModal, onCloseShift, canI]);

  const shiftTypes = useMemo(
    () => [
      {
        value: ShiftTypeFilter.showAll,
        label: 'Show All',
      },
      {
        value: ShiftTypeFilter.open,
        label: 'Open',
      },
      {
        value: ShiftTypeFilter.closed,
        label: 'Closed',
      },
    ],
    [],
  );

  const getShiftByType = (
    shifts: Shift[],
    shiftTypeFilter: string,
  ): Shift[] => {
    if (shiftTypeFilter === ShiftTypeFilter.showAll) return shifts;
    return shifts.filter(shift => shift.shiftStatus === shiftTypeFilter);
  };

  const getShiftFromSearch = (
    shifts: Shift[],
    searchQuery: string,
  ): Shift[] => {
    return shifts.filter(
      shift =>
        shift.shiftNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (shift.closedBy?.name?.toLowerCase() ?? '').includes(
          searchQuery.toLowerCase(),
        ) ||
        (shift.closedByDevice?.name?.toLowerCase() ?? '').includes(
          searchQuery.toLowerCase(),
        ),
    );
  };

  const filteredShifts = useMemo(() => {
    let filteredList = shifts.length > 0 ? shifts : [];

    if (searchFilter) {
      filteredList = getShiftFromSearch(filteredList, searchFilter);
    }

    if (typeFilter) {
      filteredList = getShiftByType(filteredList, typeFilter);
    }

    return filteredList;
  }, [searchFilter, shifts, typeFilter]);

  useEffect(() => {
    if (isFocused) {
      const hasAccess = canI([{ onResource: Resource.VIEW_SHIFT_SUMMARY }], {
        prompt: false,
      });
      if (!hasAccess) {
        navigation.navigate('Orders', {
          screen: { name: AppScreen.NEW_ORDER },
        });
      }
    }
  }, [canI, navigation, isFocused]);

  useEffect(() => {
    getOpenShift({ status: ShiftStatus.OPEN });
  }, [getOpenShift]);

  useEffect(() => {
    if (typeFilter === ShiftTypeFilter.closed && closedShifts.length == 0) {
      // get recent 30 day shifts
      const today = new Date(Date.now());
      getClosedShift({
        status: ShiftStatus.CLOSED,
        dateRange: {
          startDate: startOfDay(subDays(today, 30)).getTime(),
          endDate: today.getTime(),
        },
      });
    }
  }, [closedShifts.length, getClosedShift, typeFilter]);

  useEffect(() => {
    getOrdersFromCache(OrderStatus.IN_PROGRESS);
  }, [getOrdersFromCache]);

  useEffect(() => {
    if (ordersError) {
      showNotification({
        error: true,
        message: ordersError,
      });
    }
  }, [ordersError, showNotification]);

  const onPressRow = useCallback((shift: Shift) => {
    const sortedShift = sortShiftByPaymentType(cloneDeep(shift));
    setSelectedShift({
      ...sortedShift,
      salesByPaymentType: excludeOnAccountPaymentTypeSummary(
        sortedShift?.salesByPaymentType ?? [],
      ) as PaymentTypeSaleSummary[],
    } as Shift);
  }, []);

  return (
    <ScreenLayout
      loading={openShiftLoading || closeShiftLoading ? true : false}
      title={translate('navigation.shifts')}
      onBack={() => navigation.goBack()}
      scrollEnabled={!selectedShift}
      sidePanel={
        selectedShift ? (
          <ShiftSidePanel
            shift={selectedShift}
            showVariance={showVariance}
            onPrint={onPressPrint}
            onClose={() => setSelectedShift(null)}
          />
        ) : null
      }
    >
      <View style={styles.content}>
        {!isConnected ? (
          <Message
            type="negative"
            message={translate('shift.noConnectivity')}
            containerStyle={styles.message}
          />
        ) : null}
        <View style={styles.filters}>
          <TreatPicker
            testID="select-type"
            options={shiftTypes}
            selectedValue={typeFilter}
            onValueChange={setTypeFilter}
            containerStyle={styles.dropdown}
          />
          <Search
            testID="search-events"
            containerStyle={styles.search}
            onChangeText={setSearchFilter}
            placeholder={translate('shift.searchPlaceholder')}
          />
          <TreatButton
            type="positive"
            testID="btn-closeShift"
            disabled={!openShift || !isConnected}
            isLoading={ordersLoading}
            label={translate('shift.closeShift')}
            onPress={onPressCloseShift}
          />
        </View>
        <ShiftsTable
          data={filteredShifts}
          showVariance={showVariance}
          onPressPrint={onPressPrint}
          onPressRow={onPressRow}
        />
      </View>
    </ScreenLayout>
  );
};

export default Shifts;
