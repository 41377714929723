import { gql } from '@apollo/client';

export const GET_ORDER_TYPES = gql`
  query getOrderTypes {
    orderTypes {
      id
      name
      code
    }
  }
`;
