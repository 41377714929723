import React, { useContext, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { VariantVariations } from './VariantVariations';
import { ProductContext } from '../ProductSettingsStack';

export const Variations: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const productContext = useContext(ProductContext);
  const params =
    productContext.params ||
    (route.params as {
      productId: string;
      isVariant: boolean;
      isVariantProduct: boolean;
    });

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  const variantId = params?.isVariant ? params?.productId : '';

  return <VariantVariations variantId={variantId} />;
};
