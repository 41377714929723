import React from 'react';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useApolloClient } from '@apollo/client/react/hooks';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { ServiceConfiguration, logout } from 'react-native-app-auth';
import { auth0Config } from '../../constants';
import { getQueue, resetQueue } from '../../events/eventsQueue';
import { onboardingUtility } from '../../state/onboardingUtility';
import { setSession, setUserActivity } from '../../state/preferences';
import { tokenUtility } from '../../state/tokenUtility';
import { userUtility } from '../../state/userUtility';
import Database from '../../storage/database';
import { analyticsService } from '../../analytics/AnalyticsService';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useSyncOrderEvents } from './useSyncOrderEvents';
import ConfirmationModal from '../../components/Modals/ConfirmationDialog';
import { mmkvStorage } from '../../storage/mmkv';

/**
 * This hook only be used within protected screens / components
 * It will listen to authState changes, and redirects user back to login screen
 *
 * @returns
 */
export const useLogout = () => {
  const client = useApolloClient();
  const { showModal } = useModal();
  const { setOrderCounter } = useOrderNumber();
  const { syncAllOrderEvents } = useSyncOrderEvents();
  const { translate } = useTranslation();

  const resetStorage = useCallback(async () => {
    if (Platform.OS === 'web') {
      await Database.reset();
    } else {
      await mmkvStorage.clear();
    }
  }, []);

  const resetLocalAuthState = useCallback(async () => {
    // Reset client cache and store
    await client.clearStore();
    userUtility.clearUserActivity();
    setUserActivity({
      posUser: undefined,
      recentUserId: undefined,
      officeUsers: {},
    });
    onboardingUtility.clearOnboardingInfo();
    tokenUtility.clearToken();
    // Clear order queued events
    resetQueue();
    setOrderCounter('-0');
    setSession({});
  }, [client, setOrderCounter]);

  const performLogout = useCallback(async () => {
    try {
      const idToken = tokenUtility.idToken;
      if (Platform.OS === 'web') {
        // Need to clean state before redirect away
        await resetLocalAuthState();
        await resetStorage();
        window.location.href =
          auth0Config.serviceConfiguration.endSessionEndpoint;
      } else {
        // System will show a popup to prompt for open browser
        // Display popup first then clean state
        const config = {
          issuer: auth0Config.issuer,
          clientId: auth0Config.clientId,
          serviceConfiguration: {
            authorizationEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
            tokenEndpoint: auth0Config.serviceConfiguration.endSessionEndpoint,
            endSessionEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
            revocationEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
            registrationEndpoint:
              auth0Config.serviceConfiguration.endSessionEndpoint,
          } as ServiceConfiguration,
        };
        await logout(config, {
          idToken: idToken as string,
          postLogoutRedirectUrl: auth0Config.redirectUrl,
        });

        await resetStorage();
        await resetLocalAuthState();
      }
    } catch (e) {
      console.log('err', e);
    }
  }, [resetLocalAuthState, resetStorage]);

  const syncAndLogout = useCallback(async () => {
    await syncAllOrderEvents();
    await performLogout();
  }, [syncAllOrderEvents, performLogout]);

  const onLogout = useCallback(async () => {
    const data = await getQueue();
    if (!data || !data.length) {
      analyticsService.reset();
      performLogout();
    } else {
      showModal(
        <ConfirmationModal
          title={translate('settings.syncEvents')}
          message={translate('settings.syncEventsMessage')}
          onConfirm={syncAndLogout}
          confirmLabel={translate('settings.sync')}
        />,
      );
    }
  }, [performLogout, showModal, syncAndLogout, translate]);

  return {
    logout: onLogout,
  };
};
