import { ClientAppPermission, ResourceOperation } from '@oolio-group/domain';
import React, { useState, useEffect, FC } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { jobRoleSettingsUtility } from '../../../../../state/jobRoleSettingsUtility';
import Icon from '../../../../../components/Icon/Icon';
import theme from '../../../../../common/default-theme';

const PermissionCheckBox: FC<{
  permission: ClientAppPermission;
  operation?: ResourceOperation;
}> = ({ permission, operation }) => {
  const [checked, setChecked] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const subscription: Subscription =
      jobRoleSettingsUtility.selectedPermissions$
        .pipe(pluck(permission.id), distinctUntilChanged())
        .subscribe(selectedPermissionValue => {
          if (permission.isAtomic) {
            setChecked(selectedPermissionValue as boolean);
          } else {
            setChecked(
              (selectedPermissionValue as ResourceOperation[])?.includes(
                operation as ResourceOperation,
              ),
            );
          }
        });
    return () => {
      subscription.unsubscribe();
    };
  }, [permission, operation]);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.isEditable$
      .pipe(distinctUntilChanged())
      .subscribe(setIsEditable);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onOperationSelected = () => {
    if (isEditable) {
      jobRoleSettingsUtility.setPermissionsToQueue(
        operation as ResourceOperation,
        permission,
      );
    }
  };
  return (
    <TouchableOpacity
      key={permission.id}
      disabled={!isEditable}
      onPress={onOperationSelected}
      style={styles.container}
    >
      <Icon
        name={checked ? 'check-square' : 'square-full'}
        color={
          !isEditable
            ? theme.colors.grey4
            : checked
            ? theme.colors.states.positive
            : theme.colors.grey4
        }
      />
    </TouchableOpacity>
  );
};

export default PermissionCheckBox;

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 38,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
