import { AdjustmentType, FeatureIDs } from '@oolio-group/domain';
import { translate } from '@oolio-group/localization';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useRoute } from '@react-navigation/native';
import React from 'react';
import TabBar from '../../../../components/TabBar/TabBar';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import Adjustments from './Adjustments/Adjustments';
import { ManageCashScreen } from './ManageCash/ManageCash';
import { DetailsContainer } from './Details/DetailsContainer';
import { SectionsScreen } from './Sections/SectionsScreen';
import { useReactiveVar } from '@apollo/client';
import { isAdvanceDiscountEnabledVar } from '../../../../state/cache';

const Tab = createMaterialTopTabNavigator();

const VenueSettingsTabs: React.FC = () => {
  const route = useRoute();
  const isAdvanceDiscountFeatureEnabled = useReactiveVar(
    isAdvanceDiscountEnabledVar,
  );
  const params = route.params as {
    venueId: string;
    screen: string;
    type?: AdjustmentType;
  };

  const isFeatureEnabled = useCheckFeatureEnabled();

  const isTableFeatureEnabled = isFeatureEnabled(
    FeatureIDs.TABLE_MANAGEMENT,
    params.venueId,
  );

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'VenuesStores'}
        />
      )}
      initialRouteName={params.screen ?? 'Details'}
    >
      <Tab.Screen
        name="Details"
        component={DetailsContainer}
        initialParams={params}
      />
      {isTableFeatureEnabled && (
        <Tab.Screen
          name="SectionManagement"
          component={SectionsScreen}
          options={{
            tabBarLabel: translate(
              'storesSettings.tabNames.sectionsManagement',
            ),
          }}
          initialParams={params}
        />
      )}
      {!isAdvanceDiscountFeatureEnabled ? (
        <Tab.Screen
          name="Adjustments"
          component={Adjustments}
          initialParams={params}
        />
      ) : (
        <>
          <Tab.Screen
            name="Discounts"
            component={Adjustments}
            initialParams={{ ...params, type: AdjustmentType.DISCOUNT }}
          />
          <Tab.Screen
            name="Surcharges"
            component={Adjustments}
            initialParams={{ ...params, type: AdjustmentType.SURCHARGE }}
          />
        </>
      )}
      <Tab.Screen
        name="CashManagement"
        component={ManageCashScreen}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.manageCash'),
        }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default VenueSettingsTabs;
