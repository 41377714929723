import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import {
  IntegrationApps,
  StoreShiftLog,
  SyncStatus,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import Section from '../../../../../../components/Office/Section/Section';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Search from '../../../../../../components/Shared/Search/Search';
import styles from './Shifts.styles';
import theme from '../../../../../../common/default-theme';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import Sticker from '../../../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../../../components/POS/Pagination/Pagination';
import { useAccountingIntegration } from '../../../../../../hooks/app/accountingIntegrations/useAccountingIntegration';
import { filterBy, endOfDay } from '../../../../../../utils/dateHelper';
import DateRangePicker from '../../../../../../components/POS/Modals/DateRangePicker/DateRangePicker';
import Icon from '../../../../../../components/Icon/Icon';
import Message from '../../../../../../components/Office/Message/Message';
import { useTimeout } from '../../../../../../hooks/useTimeout';

export enum DateRangeFilter {
  'Today' = 'T',
  'Yesterday' = 'Y',
  'Custom' = 'C',
}

const TIMER = 10000;

const ITEMS_PER_PAGE = 10;

export const Shifts: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as {
    storeId: string;
    app: IntegrationApps;
  };
  const { showModal, closeModal } = useModal();
  const [filter, setFilter] = useState<string | undefined>();
  const [dateFilter, setDateFilter] = useState('');
  const [isSyncing, setIsSyncing] = useState(false);
  const [logsFilter, setLogsFilter] = useState({
    fromDateTime: filterBy('T') as number,
    toDateTime: endOfDay(new Date()),
    searchText: '',
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [failedInvoiceCount, setFailedInvoiceCount] = useState<number>(0);
  const [syncQueue, setSyncQueue] = useState<string[]>([]);

  const { storeShiftLogs, getStoreInvoiceLogs, syncStoreInvoice, loading } =
    useAccountingIntegration();

  const storeId = params.storeId;

  useEffect(() => {
    if (storeId) {
      getStoreInvoiceLogs(storeId, IntegrationApps.XERO, {
        startDate: logsFilter.fromDateTime,
        endDate: logsFilter.toDateTime,
      });
    }
  }, [
    storeId,
    getStoreInvoiceLogs,
    logsFilter.fromDateTime,
    logsFilter.toDateTime,
  ]);

  useEffect(() => {
    if (storeShiftLogs) {
      const notSyncedCount = storeShiftLogs.filter(
        obj => obj.status === SyncStatus.NOT_SYNCED,
      ).length;
      if (notSyncedCount > 0) {
        setFailedInvoiceCount(notSyncedCount);
      }
    }
  }, [storeShiftLogs]);

  useEffect(() => {
    const startOfCurrentWeek = startOfWeek(new Date(), {
      weekStartsOn: 1,
    }).valueOf();
    const endOfCurrentWeek = endOfWeek(new Date(), {
      weekStartsOn: 1,
    }).valueOf();

    setLogsFilter(pre => ({
      ...pre,
      fromDateTime: startOfCurrentWeek,
      toDateTime: endOfCurrentWeek,
    }));
    setFilter(DateRangeFilter.Custom);

    setDateFilter(
      `${format(startOfCurrentWeek, 'dd/MM/yy')} to ${format(
        endOfCurrentWeek,
        'dd/MM/yy',
      )}`,
    );
  }, []);

  const onSelectCustomDates = useCallback(
    (startDate?: Date, endDate?: Date) => {
      setFilter(DateRangeFilter.Custom);
      if (!!startDate && !!endDate) {
        setDateFilter(
          `${format(
            new Date(startDate).setHours(0, 0),
            'dd/MM/yy',
          )} to ${format(new Date(endDate).setHours(0, 0), 'dd/MM/yy')}`,
        );
        const fromDateTime = new Date(startDate).setHours(0, 0);
        const toDateTime = endOfDay(new Date(endDate));
        setLogsFilter(pre => ({ ...pre, fromDateTime, toDateTime }));
        setCurrentPage(1);
      }
    },
    [],
  );

  const onPressDateRange = useCallback(
    () =>
      showModal(
        <DateRangePicker
          value={{
            start: new Date(logsFilter?.fromDateTime),
            end: new Date(logsFilter?.toDateTime),
          }}
          onSelectDate={onSelectCustomDates}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [
      closeModal,
      onSelectCustomDates,
      logsFilter?.fromDateTime,
      logsFilter?.toDateTime,
      showModal,
    ],
  );

  const getShiftFromFilters = (
    shifts: StoreShiftLog[],
    searchQuery: string,
    fromDateTime?: number,
    toDateTime?: number,
  ) => {
    return shifts.filter(
      shift =>
        (shift.shiftNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (shift.deviceName?.toLowerCase() ?? '').includes(
            searchQuery.toLowerCase(),
          )) &&
        (!fromDateTime || shift.closedAt >= fromDateTime) &&
        (!toDateTime || shift.closedAt <= toDateTime),
    );
  };

  const filteredShifts = useMemo(() => {
    let filteredList = storeShiftLogs ?? [];
    filteredList = getShiftFromFilters(
      filteredList,
      logsFilter.searchText,
      logsFilter.fromDateTime,
      logsFilter.toDateTime,
    );

    return filteredList;
  }, [
    logsFilter.fromDateTime,
    logsFilter.searchText,
    logsFilter.toDateTime,
    storeShiftLogs,
  ]);

  const pageItems = useMemo(() => {
    let itemsToDisplay;

    if (
      logsFilter.searchText ||
      logsFilter.fromDateTime ||
      logsFilter.toDateTime
    ) {
      itemsToDisplay = filteredShifts?.slice(
        (currentPage - 1) * 10,
        currentPage * 10,
      );
    } else {
      itemsToDisplay = storeShiftLogs?.slice(
        (currentPage - 1) * 10,
        currentPage * 10,
      );
    }
    return itemsToDisplay;
  }, [
    currentPage,
    filteredShifts,
    logsFilter.fromDateTime,
    logsFilter.searchText,
    logsFilter.toDateTime,
    storeShiftLogs,
  ]);

  const updateSearchText = (text: string) => {
    if (!isSyncing) {
      setLogsFilter(prevFilter => ({
        ...prevFilter,
        searchText: text,
      }));
    }
  };

  const fetchStoreInvoiceLogs = () => {
    getStoreInvoiceLogs(storeId, IntegrationApps.XERO, {
      startDate: logsFilter.fromDateTime,
      endDate: logsFilter.toDateTime,
    });
    setIsSyncing(false);
    setSyncQueue([]);
  };

  const getStoreInvoiceLogsWithDelay = useTimeout(fetchStoreInvoiceLogs);

  const onClickSync = useCallback(
    ({ shiftId, storeId }) => {
      setSyncQueue(prevQueue => [...prevQueue, shiftId]);
      syncStoreInvoice(shiftId, storeId, IntegrationApps.XERO);
      if (!isSyncing) {
        setIsSyncing(true);
        getStoreInvoiceLogsWithDelay.start(TIMER);
      }
    },
    [syncStoreInvoice, isSyncing, getStoreInvoiceLogsWithDelay],
  );

  return (
    <ScreenLayout title="Xero Shifts | Oolio" loading={loading}>
      {failedInvoiceCount > 0 ? (
        <Message
          type="negative"
          message={translate('backOfficeFeatures.failedInvoicesMessage', {
            failedCount: failedInvoiceCount,
          })}
          containerStyle={styles.message}
        />
      ) : (
        <></>
      )}
      <Section title={translate('backOfficeFeatures.shiftsSyncHistory')}>
        <View style={theme.forms.row}>
          <TouchableOpacity
            testID="btn-dates"
            disabled={loading || isSyncing}
            onPress={onPressDateRange}
            style={styles.datePicker}
          >
            <Text numberOfLines={1} style={styles.dateLabel}>
              {filter ? dateFilter : translate('orderHistory.today')}
            </Text>
            <Icon name="angle-down" size={20} color={theme.colors.dark} />
          </TouchableOpacity>
          <Search
            testID="search-events"
            containerStyle={styles.search}
            onChangeText={updateSearchText}
            placeholder={translate('shift.searchPlaceholder')}
          />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerDate]}>
              {translate('onlineOrders.date')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerShiftNo]}>
              {translate('backOfficeShifts.shiftNumber')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerDevice]}>
              {translate('backOfficeShifts.device')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerXeroAccount]}>
              {translate('backOfficeShifts.status')}
            </Text>
          </View>
          <View>
            {pageItems ? (
              pageItems.map(shift => (
                <View key={shift.shiftNo}>
                  <View style={styles.row}>
                    <Text style={styles.cellDate}>
                      {format(shift.closedAt, 'dd/MM hh:mm a')}
                    </Text>
                    <Text style={styles.cellShiftNo}>{shift.shiftNo}</Text>
                    <Text style={styles.cellDevice}>{shift.deviceName}</Text>
                    <Sticker
                      testID="sticker-rate"
                      type={
                        syncQueue.includes(shift.shiftId)
                          ? 'focusLight'
                          : shift && shift.status === SyncStatus.NOT_SYNCED
                          ? 'negativeLight'
                          : 'positiveLight'
                      }
                      label={
                        syncQueue.includes(shift.shiftId)
                          ? translate('onlineOrders.pending').toUpperCase()
                          : shift && shift.status === SyncStatus.NOT_SYNCED
                          ? translate('backOfficeFeatures.notSynced')
                          : translate('backOfficeFeatures.synced')
                      }
                      containerStyle={styles.cellXeroAccount}
                    />
                    <ButtonIcon
                      icon="redo"
                      type="neutralLight"
                      onPress={() => {
                        onClickSync({
                          shiftId: shift.shiftId,
                          storeId: shift.store,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.failureMessageView}>
                    {shift.status === SyncStatus.NOT_SYNCED ? (
                      <>
                        <Icon
                          name="exclamation-triangle"
                          size={20}
                          color="red"
                        />
                        <Text style={styles.failureMessage}>
                          {shift.message}
                        </Text>
                      </>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              ))
            ) : (
              <View style={theme.tables.emptyView}>
                <Text style={theme.tables.emptyText}>
                  {translate('shift.emptySearch')}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View>
          {storeShiftLogs && storeShiftLogs.length > 10 ? (
            <Pagination
              pageLength={ITEMS_PER_PAGE}
              page={currentPage}
              onPageChange={setCurrentPage}
              dataLength={filteredShifts.length}
            />
          ) : null}
        </View>
      </Section>
    </ScreenLayout>
  );
};
