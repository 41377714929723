import { IdentifierStyle, OrderTypeIdentifierCode } from './Identifier.types';
import theme from '../../../common/default-theme';

export const EntityStyleMap: Record<string, IdentifierStyle> = {
  modifier: {
    displayChar: 'M',
    background: theme.colors.blueLightest,
    foreground: theme.colors.blue,
  },
  product: {
    displayChar: 'P',
    background: theme.colors.orangeLightest,
    foreground: theme.colors.orange,
  },
  variant: {
    displayChar: 'V',
    background: theme.colors.tealLightest,
    foreground: theme.colors.teal,
  },
  category: {
    displayChar: 'C',
    background: theme.colors.deepPurpleLightest,
    foreground: theme.colors.deepPurple,
  },
  combo: {
    displayChar: 'C',
    background: theme.colors.orange,
    foreground: theme.colors.white,
  },
};

export const OrderTypeStyleMap: Record<
  OrderTypeIdentifierCode,
  IdentifierStyle
> = {
  D: {
    displayChar: 'DI',
    background: theme.colors.blueLightest,
    foreground: theme.colors.blue,
  },
  T: {
    displayChar: 'TA',
    background: theme.colors.orangeLightest,
    foreground: theme.colors.orange,
  },
  DL: {
    displayChar: 'DE',
    background: theme.colors.tealLightest,
    foreground: theme.colors.teal,
  },
  P: {
    displayChar: 'PU',
    background: theme.colors.deepPurpleLightest,
    foreground: theme.colors.deepPurple,
  },
  C: {
    displayChar: 'CT',
    background: theme.colors.pinkLightest,
    foreground: theme.colors.pink,
  },
  other: {
    displayChar: 'O',
    background: theme.colors.grey1,
    foreground: theme.colors.grey6,
  },
};

export const DefaultStyle: IdentifierStyle = {
  displayChar: 'O',
  background: theme.colors.grey1,
  foreground: theme.colors.grey6,
};
