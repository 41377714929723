import { Text, ViewStyle, View } from 'react-native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { StyleFn, RenderProps } from '@oolio-group/domain';
import { FelaComponent, useFela } from 'react-fela';
import TableRow from './TableRow';
import TablePagination from '../TablePagination/TablePagination';
export interface Column {
  title?: string | React.ReactNode;
  width?: number;
  flex?: number;
  alignItems?: string;
  containerStyle?: ViewStyle;
}

export interface TableComponentProps {
  columns?: Column[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRow: (item: any, index: number) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[] | React.ReactNode[];
  columnContainerStyle?: ViewStyle;
  normalRows?: boolean;
  columnSpacing?: number;
  containerStyle?: ViewStyle;
  paginationStyle?: ViewStyle;
  pageSize?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  showPagination?: boolean;
}
export interface ColumnCellProps {
  title: string | React.ReactNode;
  width: number;
  flex?: number;
  alignItems?: string;
  columnSpacing?: number;
  index: number;
  containerStyle?: ViewStyle;
}

const TableNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: 0,
  fontFamily: theme.font.medium,
});

const titleStyle: StyleFn = ({
  width,
  flex,
  index,
  alignItems,
  columnSpacing,
}) => ({
  width: width,
  flex: flex,
  alignItems: alignItems ? alignItems : index ? 'center' : 'flex-start',
  justifyContent: index ? 'center' : 'flex-start',
  marginRight: columnSpacing || 0,
});

export const ColumnCell: React.FC<ColumnCellProps> = props => {
  const { css } = useFela();
  return (
    <FelaComponent style={titleStyle} {...props}>
      {({ style }: RenderProps): React.ReactNode => (
        <View style={[style, props.containerStyle]}>
          <Text style={css(TableNameStyle)}>{props.title}</Text>
        </View>
      )}
    </FelaComponent>
  );
};

const TableComponent: React.FC<TableComponentProps> = ({
  columns = [],
  renderRow,
  data,
  columnContainerStyle,
  normalRows,
  columnSpacing,
  containerStyle,
  paginationStyle,
  pageSize,
  onPageChange: onPageChangeProp,
  page: pageProp,
  showPagination,
}) => {
  const itemsPerPage = pageSize || (data?.length < 10 ? data.length : 10);
  const [page, setPage] = React.useState(1);
  const columnCells = useMemo(
    () =>
      columns.map((x, i) => (
        <ColumnCell
          key={i}
          index={i}
          width={x.width || 80}
          title={x.title}
          flex={x.flex}
          alignItems={x.alignItems}
          // column spacing for right most element making 0
          columnSpacing={columns.length > i + 1 ? columnSpacing : 0}
          containerStyle={x.containerStyle}
        />
      )),
    [columns, columnSpacing],
  );

  const onChangePage = useCallback(
    (page: number) => {
      onPageChangeProp && onPageChangeProp(page);
      setPage(page);
    },
    [onPageChangeProp],
  );

  const viewData = useMemo(() => {
    if (!showPagination) return data;
    return data?.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  }, [data, itemsPerPage, page, showPagination]);

  useEffect(() => {
    // controlled mode
    if (pageProp) setPage(pageProp);
  }, [pageProp]);

  return (
    <View style={containerStyle}>
      {columnCells && (
        <TableRow containerStyle={columnContainerStyle} normalRows={normalRows}>
          {columnCells}
        </TableRow>
      )}
      {viewData.map((x, i) => renderRow(x, i))}
      {showPagination && data?.length > itemsPerPage && (
        <View style={paginationStyle}>
          <TablePagination
            isGreyPagination
            page={page}
            numberOfPages={Math.ceil(data?.length / itemsPerPage)}
            startIndex={(page - 1) * itemsPerPage}
            endIndex={page * itemsPerPage}
            total={data?.length}
            onPageChange={onChangePage}
          />
        </View>
      )}
    </View>
  );
};

export default TableComponent;
