import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 10,
    backgroundColor: theme.colors.grey1,
    ...theme.shadow.light,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnClose: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    marginRight: 38,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    padding: 20,
    marginVertical: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  group: {
    marginBottom: 14,
    paddingBottom: 14,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  groupTitle: {
    fontSize: 13,
    lineHeight: 20,
    marginBottom: 12,
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  rowTitle: {
    flex: 1,
    fontSize: 13,
    lineHeight: 18,
    marginRight: 10,
    color: theme.colors.grey6,
  },
  rowValue: {
    width: 120,
    fontSize: 13,
    lineHeight: 18,
    textAlign: 'right',
    color: theme.colors.black2,
  },
  rowTotalText: {
    fontSize: 13,
    lineHeight: 18,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  negative: {
    color: theme.colors.states.negative,
  },
  positive: {
    color: theme.colors.states.negative,
  },
});

export default styles;
