import { useCallback, useMemo } from 'react';
import { keyBy } from 'lodash';
import { useSession } from '../useSession';
import { Order, UpdateProductInput } from '@oolio-group/domain';
import { stripProperties } from '@oolio-group/client-utils';
import { inventoryProductFragment, useProducts } from '../products/useProducts';
import { isInventoryBeingTracked } from '@oolio-group/order-helper';
import { useCart } from '../../orders/useCart';

export function useSyncItemAvailability() {
  const [session] = useSession();
  const { syncProductsAvailabilityEvent, getCacheProductFragment } =
    useProducts(undefined, inventoryProductFragment);
  const { order } = useCart();
  const currentStoreId = session.currentStore?.id ?? '';
  const getItemAvailabilityDataToSync = useCallback(
    (savedOrder?: Order | undefined): UpdateProductInput[] => {
      if (savedOrder?.isTraining || order?.isTraining) {
        return [];
      }
      const tempOrderItems = savedOrder?.orderItems || order?.orderItems;
      const orderItemsByProductId = keyBy(tempOrderItems, 'product.id');
      const productQuantities = Object.keys(orderItemsByProductId);
      const productsToUpdate: UpdateProductInput[] = productQuantities
        .map(id => {
          const product = getCacheProductFragment(id);
          const isBeingTracked = isInventoryBeingTracked(
            product?.storesInventory,
            currentStoreId,
          );
          if (!isBeingTracked) return;
          const productCopy = stripProperties(product, '__typename');
          return {
            id: productCopy.id,
            name: productCopy.name,
            storesInventory: productCopy.storesInventory,
          };
        })
        .filter(p => p) as UpdateProductInput[];
      return productsToUpdate;
    },
    [
      currentStoreId,
      getCacheProductFragment,
      order?.orderItems,
      order?.isTraining,
    ],
  );

  // Sync item availability for the same store
  const syncItemAvailability = useCallback(
    (updatedProducts?) => {
      const productsToUpdate = updatedProducts?.length
        ? updatedProducts
        : getItemAvailabilityDataToSync();
      if (productsToUpdate.length) {
        const currentDeviceId = session.device?.id ?? '';
        syncProductsAvailabilityEvent({
          deviceId: currentDeviceId,
          products: productsToUpdate,
          storeId: currentStoreId,
        });
      }
    },
    [
      currentStoreId,
      getItemAvailabilityDataToSync,
      session.device?.id,
      syncProductsAvailabilityEvent,
    ],
  );

  return useMemo(
    () => ({
      syncItemAvailability,
      getItemAvailabilityDataToSync,
    }),
    [syncItemAvailability, getItemAvailabilityDataToSync],
  );
}
