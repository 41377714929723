import { useContext } from 'react';
import {
  OfficeUserAuthorizationContext,
  OfficeUserAuthorizationContextType,
} from '../../OfficeUserAuthorizationProvider';

const useOfficeUserAuthorization = () => {
  const context = useContext<OfficeUserAuthorizationContextType>(
    OfficeUserAuthorizationContext,
  );

  return context;
};

export default useOfficeUserAuthorization;
