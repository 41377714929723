import React, { FC, memo } from 'react';
import { View } from 'react-native';
import { App } from '@oolio-group/domain';
import { PermissionTreeNode } from '../../../../../state/jobRoleSettingsUtility';
import PermissionDetail from './PermissionDetail';

interface PermissionTreeProps {
  permission: PermissionTreeNode;
  level?: number;
}

const PermissionTree: FC<PermissionTreeProps> = memo(
  ({ permission, level = 1 }) => {
    if (permission?.children && permission?.children?.length > 0) {
      // first need to render this node, then the leaf child nodes
      return (
        <View>
          {!(
            permission.isPermissionGroup &&
            permission.clientApp === App.BACKOFFICE
          ) && <PermissionDetail permission={permission} key={permission.id} />}
          {permission.children
            ?.sort((a, b) => a.sortOrder - b.sortOrder)
            .map((child: PermissionTreeNode) => {
              return (
                <PermissionTree
                  key={child.id}
                  permission={child}
                  level={level + 1}
                />
              );
            })}
        </View>
      );
    }

    if (permission) {
      return (
        <PermissionDetail
          level={level}
          permission={permission}
          key={permission.id}
        />
      );
    }
    return null;
  },
);

PermissionTree.displayName = 'PermissionTree';

export default PermissionTree;
