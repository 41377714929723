import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filters: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    width: 200,
    marginRight: 10,
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  tableContainer: {
    marginBottom: 20,
  },
  cellName: {
    width: 200,
    marginRight: 10,
  },
  cellOptions: {
    flex: 1,
    marginRight: 20,
  },
  cellCopy: {
    marginLeft: 10,
  },
  cellPrice: {
    width: 120,
    marginRight: 10,
  },
  cellTax: {
    width: 180,
  },
  // Option Groups
  headerName: {
    width: 244,
  },
  headerAddOnPrice: {
    width: 120,
    marginRight: 32,
    textAlign: 'center',
  },
  // Modifiers
  headerModifier: {
    width: 186,
    marginRight: 10,
  },
  headerGroups: {
    flex: 1,
    paddingLeft: 12,
    marginRight: 10,
  },
  headerPrice: {
    width: 120,
    textAlign: 'center',
  },
  headerTax: {
    width: 268,
    paddingLeft: 22,
  },
  identifier: {
    marginHorizontal: 12,
  },
});

export default styles;
