import { StyleSheet, Dimensions } from 'react-native';
import theme from '../../../../../common/default-theme';

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    width: 480,
    alignSelf: 'center',
  },
  header: {
    height: 44,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  headerText: {
    flex: 1,
    marginRight: 44,
    textAlign: 'center',
    color: theme.colors.black1,
    fontFamily: theme.fonts.semibold,
    textTransform: 'uppercase',
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    padding: 30,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  counts: {
    maxHeight: height * 0.4,
  },
  count: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    paddingHorizontal: 16,
    marginBottom: 10,
  },
  totals: {
    marginTop: 20,
    paddingTop: 20,
    borderTopWidth: 1,
    borderColor: theme.colors.grey3,
  },
  total: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.grey1,
    paddingHorizontal: 16,
    marginBottom: 10,
  },
  totalText: {
    fontFamily: theme.fonts.medium,
  },
  actions: {
    flexDirection: 'row',
    marginTop: 20,
  },
});

export default styles;
