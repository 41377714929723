import { setItem, getItem } from '../storage/interface';
import { OrderEvent } from '@oolio-group/domain';
import { ORDER_EVENTS_QUEUE_KEY } from '../state/preferences';
import { dedupeOrderEvents } from '@oolio-group/order-helper';

export const getQueue = async (): Promise<OrderEvent[]> => {
  const data = await getItem(ORDER_EVENTS_QUEUE_KEY);
  if (data) {
    return data as OrderEvent[];
  } else return [];
};

export const queueEvents = async (events: OrderEvent[]) => {
  const data = await getQueue();
  const uniqueEvents = dedupeOrderEvents([...data, ...events]);
  setItem(ORDER_EVENTS_QUEUE_KEY, uniqueEvents);
};

export const resetQueue = () => {
  setItem(ORDER_EVENTS_QUEUE_KEY, []);
};
