import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { format } from 'date-fns';
import { capitalCase } from 'change-case';
import { Booking } from '@oolio-group/domain';
import { formatPhoneNumber } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import {
  styles,
  getPositionStickerType,
  getStatusStickerType,
} from './BookingsTable.styles';
import Icon from '../../../../components/Icon/Icon';
import Sticker from '../../../../components/Shared/Sticker/Sticker';

interface Props {
  index: number;
  booking: Booking;
  onPress: () => void;
  filter: string;
}

const BookingRow: React.FC<Props> = ({ index, filter, booking, onPress }) => {
  const { status, customer, priority } = booking;
  const customerName = `${customer.firstName} ${customer.lastName}`;

  const isWaitlist = filter === 'WAITLIST';

  return (
    <TouchableOpacity onPress={onPress} style={styles.row}>
      {isWaitlist && (
        <Sticker
          testID="sticker-position"
          label={String(index)}
          type={getPositionStickerType(index)}
          containerStyle={styles.stickerPosition}
        />
      )}
      <Text numberOfLines={1} style={styles.cellName}>
        {`${priority ? '★ ' : ''}${customerName}`}
      </Text>
      <Text numberOfLines={1} style={styles.cellPhone}>
        {formatPhoneNumber(customer.phone as string, {
          countryCode: customer?.preferredAddress?.isoCountryCode,
          intl: true,
        })}
      </Text>
      <Text numberOfLines={1} style={styles.cellCreated}>
        {format(booking.createdAt, 'MMM dd, hh:mm a')}
      </Text>
      <Sticker
        testID="sticker-status"
        rounded
        type={getStatusStickerType(status)}
        label={capitalCase(status)}
      />
      <View style={styles.disclosure}>
        <Icon name="angle-right" color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export default BookingRow;
