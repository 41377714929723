import React, { useMemo, useCallback, useState, useRef } from 'react';
import { View } from 'react-native';
import { Table } from '@oolio-group/domain';
import { MaterialTopTabScreenProps } from '@react-navigation/material-top-tabs';
import Draggable from 'react-native-draggable';
import { TableIcon, TableIconProps } from './TableIcon/TableIcon';
import useBehaviorSubjectState from '../../../../../../hooks/app/useSubjectState';
import {
  editModeController,
  TableAction,
  transferTablesController,
} from '../floorViewObservables';
import MultiOrderTableIcon, {
  MultiOrderTableIconProps,
} from './MultiOrderTableIcon';
import theme from '../../../../../../common/default-theme';

export const SECTION_VIEW_NUMBER_TABLE_COLUMNS = 8;

export type SectionViewParam = MaterialTopTabScreenProps<
  {
    SectionView: { id: string };
  },
  'SectionView'
>;

export const calculateTablePositionX = (index: number) =>
  (index % SECTION_VIEW_NUMBER_TABLE_COLUMNS) * 120 +
  (1024 - 120 * SECTION_VIEW_NUMBER_TABLE_COLUMNS) / 2;

export const calculateTablePositionY = (index: number) =>
  Math.floor(index / SECTION_VIEW_NUMBER_TABLE_COLUMNS) * 80 + 50;

export interface DraggableTableIconProps
  extends TableIconProps,
    Omit<MultiOrderTableIconProps, 'showPopover'> {
  onPressIn: () => void;
  onDragTable: (t: Table) => void;
  onDragReleaseTable: (t: Table) => void;
  isDraggingTable: boolean;
  index: number;
  displayAsGrid?: boolean;
  tableAction?: TableAction;
}

const DraggableTableIcon: React.FC<DraggableTableIconProps> = props => {
  const {
    onPressIn,
    onDragTable,
    onDragReleaseTable,
    isDraggingTable,
    index,
    displayAsGrid = false,
    onRequestClose,
    orders,
    onPressSubTable,
    onPressTable,
    tableAction,
    ...tableIconProps
  } = props;
  const { table } = tableIconProps;
  const [displayPopover, setDisplayPopover] = useState<boolean>(false);
  const { value: isEditMode } = useBehaviorSubjectState(editModeController);
  const tableRef = useRef(null);

  const onDrag = useCallback(() => {
    onDragTable(table);
  }, [onDragTable, table]);

  const onDragRelease = useCallback(
    (event, gestureState, bound) => {
      onDragReleaseTable({ ...table, position: bound });
    },
    [onDragReleaseTable, table],
  );

  const positionX = useMemo(() => {
    if (typeof table?.position?.left === 'number') return table?.position?.left;
    if (!displayAsGrid) return 20;
    return calculateTablePositionX(index);
  }, [table?.position?.left, displayAsGrid, index]);

  const positionY = useMemo(() => {
    if (typeof table?.position?.top === 'number') return table?.position?.top;
    if (!displayAsGrid) return 80 * index;
    return calculateTablePositionY(index);
  }, [table?.position?.top, displayAsGrid, index]);

  const onPressTableAndOpenPopover = useCallback(
    (table: Table) => {
      onPressTable(table);
      const isOrderTableTransfer = transferTablesController.getValue();
      if (
        orders?.length &&
        orders?.length > 1 &&
        tableAction !== TableAction.MERGE &&
        !isOrderTableTransfer
      ) {
        setDisplayPopover(true);
      }
    },
    [onPressTable, orders?.length, tableAction],
  );

  const onCloseTablePopover = useCallback(() => {
    onRequestClose();
    setDisplayPopover(false);
  }, [onRequestClose]);

  const onPressSubTableAndClosePopover = useCallback(
    (table: Table, orderIndex?: number) => {
      onPressSubTable(table, orderIndex);
      setDisplayPopover(false);
    },
    [onPressSubTable],
  );

  return (
    <Draggable
      key={`${table.id}-top-${table?.position?.top}-left-${table?.position?.left}`}
      x={positionX}
      y={positionY}
      z={50 + index}
      onPressIn={onPressIn}
      onDrag={onDrag}
      onDragRelease={onDragRelease}
      disabled={!isEditMode}
      touchableOpacityProps={{ activeOpacity: 1.0 }}
      minX={20}
      minY={20}
      maxX={1004}
      maxY={650}
      animatedViewProps={{ height: 1004 }}
    >
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          borderRadius: theme.radius.s,
          borderWidth: isDraggingTable ? 2 : 0,
          borderColor: theme.colors.states.neutral,
        }}
      >
        {isEditMode ? (
          <TableIcon {...tableIconProps} onPressTable={onPressTable} />
        ) : (
          <MultiOrderTableIcon
            key={table.id}
            showPopover={displayPopover} // Popover in RN_MODAL need to controlled with state
            onRequestClose={onCloseTablePopover}
            orders={orders}
            onPressSubTable={onPressSubTableAndClosePopover}
            table={table}
            childRef={tableRef}
          >
            <TableIcon
              {...tableIconProps}
              onPressTable={onPressTableAndOpenPopover}
              isMultiOrder={(orders?.length || 0) > 1 ? true : false}
              reference={tableRef}
            />
          </MultiOrderTableIcon>
        )}
      </View>
    </Draggable>
  );
};

export default DraggableTableIcon;
