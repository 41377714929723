import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchContainer: {
    flex: 1,
    marginRight: 10,
  },
  tableContainer: {
    marginVertical: 20,
  },
  headerName: {
    minWidth: 200,
  },
  headerOrderType: {
    minWidth: 200,
  },
  headerLocale: {
    minWidth: 190,
  },
  headerSingleItemPrinting: {
    minWidth: 190,
  },
  cellProfile: {
    flex: 2,
    marginRight: 10,
  },
  cellSingleItemPrinting: {
    flex: 1,
    alignItems: 'center',
  },
  cellDelete: {
    flex: 1,
    alignItems: 'flex-end',
  },
});

export default styles;
