import React, { useCallback, useState } from 'react';
import { DeviceMode, CreateDeviceInput } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import { isNotEmpty } from '@oolio-group/client-utils';
import Select from '../../../../../../components/Shared/Select/Select';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';

interface CreateDeviceProfileProps {
  onCreate: (input: CreateDeviceInput) => void;
}
export const CreateDeviceProfileModal: React.FC<CreateDeviceProfileProps> = ({
  onCreate,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const initialForm = {
    mode: DeviceMode.F_POS,
  };
  const [form, setForm] = useState({
    ...initialForm,
  } as unknown as CreateDeviceInput);

  const onChange = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSubmit = useCallback(() => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.errorNameMissing'),
      });
    } else {
      onCreate(form);
      closeModal();
    }
  }, [form, showNotification, translate, onCreate, closeModal]);

  const deviceTypes = [
    {
      label: translate('backOfficeDeviceModal.posApp'),
      value: DeviceMode.F_POS,
    },
    {
      label: translate('backOfficeDeviceModal.mPos'),
      value: DeviceMode.M_POS,
    },
    {
      label: translate('backOfficeDeviceModal.kiosk'),
      value: DeviceMode.KIOSK,
    },
  ];

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeDeviceProfiles.createDeviceProfile')}
      onConfirm={{
        label: translate('productSettings.create'),
        action: onSubmit,
      }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('backOfficeDeviceProfiles.profileName')}
        placeholder={translate('backOfficeDeviceProfiles.profileName')}
        value={form.name}
        onChangeText={onChange.bind(null, 'name')}
      />
      <Select
        testID="select-type"
        title={translate('backOfficeDeviceProfiles.profileType')}
        options={deviceTypes}
        selectedValue={form.mode}
        onValueChange={onChange.bind(null, 'mode')}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginTop: 20 }}
      />
    </TreatModal>
  );
};
