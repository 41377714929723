import { gql, Resolvers } from '@apollo/client';

export const GET_MONEY_MOVEMENTS = gql`
  query getMoneyMoments {
    moneyMovements {
      id
      timestamp
      eventType
      amount {
        amount
        currency
      }
      reason {
        id
        eventType
        name
      }
      device {
        id
        name
      }
      notes
      user {
        id
        name
      }
      paymentType {
        id
        name
      }
    }
  }
`;

export const GET_MONEY_MOVEMENTS_REASONS = gql`
  query getMoneyMomentsReasons {
    moneyMovementReasons {
      id
      eventType
      label: name
      value: id
      name
      notes
    }
  }
`;

export const CREATE_MONEY_MOVEMENTS_REASON = gql`
  mutation createMoneyMovementReasons($input: CreateMoneyMovementReasonInput) {
    createMoneyMovementReasons(input: [$input]) {
      id
      eventType
      name
      notes
    }
  }
`;

export const UPDATE_MONEY_MOVEMENTS_REASONS = gql`
  mutation updateMoneyMovementReasons(
    $input: [UpdateMoneyMovementReasonInput]
  ) {
    updateMoneyMovementReasons(input: $input) {
      id
      eventType
      name
      notes
    }
  }
`;

export const DELETE_MONEY_MOVEMENTS_REASON = gql`
  mutation deleteMoneyMovementReason($id: ID) {
    deleteMoneyMovementReason(id: $id)
  }
`;

export const CREATE_MONEY_MOVEMENT = gql`
  mutation moveMoney(
    $eventType: MoneyEventType!
    $reason: ID!
    $paymentType: ID!
    $amount: MoneyInput!
    $notes: String!
    $userId: ID!
  ) {
    moveMoney(
      input: {
        eventType: $eventType
        reason: $reason
        paymentType: $paymentType
        amount: $amount
        notes: $notes
        userId: $userId
      }
    ) {
      id
      timestamp
      eventType
      amount {
        amount
        currency
      }
      reason {
        id
        eventType
        name
      }
      notes
      user {
        id
        name
      }
      paymentType {
        id
        name
      }
      device {
        id
        name
      }
    }
  }
`;

export const SET_MONEYMOVEMENT_RECORD = gql`
  mutation setMoneyMovementRecord($data: MoneyMovement) {
    setMoneyMovementRecord(input: $data) @client
  }
`;

export const resolvers: Resolvers = {
  Mutation: {
    setMoneyMovementRecord: (root, { input }, { cache }): void => {
      const { moveMoney } = input;
      const { moneyMovements } = cache.readQuery({
        query: GET_MONEY_MOVEMENTS,
        fetchPolicy: 'cache-and-network',
      });
      cache.writeQuery({
        query: GET_MONEY_MOVEMENTS,
        data: {
          moneyMovements: [moveMoney, ...(moneyMovements || [])],
        },
      });
    },
  },
};
