import {
  KitchenDisplay,
  OrderItem,
  OrderItemStatus,
} from '@oolio-group/domain';

export const isOrderItemSentToKitchen = ({
  item,
  restrictOrderItemModifications = false,
  kdsDevicesInStore = [],
  orderTypeCode = '',
}: {
  item?: OrderItem;
  restrictOrderItemModifications?: boolean;
  kdsDevicesInStore?: KitchenDisplay[];
  orderTypeCode?: string;
}) => {
  if (
    !restrictOrderItemModifications ||
    !item ||
    !item.saved ||
    [OrderItemStatus.CREATED, OrderItemStatus.ON_HOLD].includes(item.status) ||
    !item.product?.printerProfiles?.length
  ) {
    return false;
  }
  // TODO: Fix issue where itemFired is true when no printer profiles are assigned to the product
  if (item.itemFired) {
    return true;
  }
  for (const device of kdsDevicesInStore) {
    if (!device.isPaired) continue;

    for (const printerProfile of device.printerProfiles || []) {
      const itemHasDevicePrinterProfile = item.product?.printerProfiles
        ?.map(p => p.id)
        ?.includes(printerProfile.id);

      if (!itemHasDevicePrinterProfile) continue;

      const orderTypes = printerProfile.orderTypes;
      if (!orderTypes?.length || orderTypes.includes(orderTypeCode)) {
        return true;
      }
    }
  }
  return false;
};
