import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  icon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
