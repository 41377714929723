import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { IntegrationApps } from '@oolio-group/domain';
import theme from '../../../../../../common/default-theme';
import styles from './Settings.styles';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface StoreRowProps {
  isEnabled: boolean;
  name: string;
  menu: string;
  pricingGroup: string;
  pricingGroups: string[];
  menusOptions: { label: string; value: string }[];
  pricingGroupsOptions: { label: string; value: string }[];
  onChange: (
    storeId: string,
    key: string,
    value: string | boolean | string[],
  ) => void;
  storeId: string;
  id: string;
  integrationApp: IntegrationApps;
  onOpenStoreInfo: (storeId: string) => void;
}

export const StoreRow: React.FC<StoreRowProps> = ({
  isEnabled,
  menu,
  name,
  pricingGroups,
  pricingGroup,
  menusOptions,
  pricingGroupsOptions,
  id: integrationId,
  onChange,
  storeId,
  integrationApp,
  onOpenStoreInfo,
}: StoreRowProps) => {
  const navigation = useNavigation();

  const navigateToStoreSettings = useCallback(() => {
    navigation.navigate('OnlineOrderIntegrationStoreSettings', {
      storeId,
      app: integrationApp,
    });
  }, [integrationApp, navigation, storeId]);

  const isDeliverect = integrationApp === IntegrationApps.DELIVERECT;
  const isOOM = integrationApp === IntegrationApps.OOM;
  const isFieldEnabled = isEnabled && !!integrationId;

  const handleOnToggleChange = useCallback(() => {
    if (isDeliverect) return;
    onChange(storeId || integrationId, 'isActive', !isEnabled);
  }, [integrationId, isDeliverect, isEnabled, onChange, storeId]);

  return (
    <View style={theme.tables.row}>
      <InputToggle
        testID="toggle-store"
        type="switch"
        isToggled={isEnabled}
        onToggle={handleOnToggleChange}
      />
      <Text style={styles.cellName}>{name}</Text>
      <TreatPicker
        testID="select-menu"
        editable={isFieldEnabled}
        options={menusOptions}
        selectedValue={menu}
        onValueChange={value =>
          isFieldEnabled && onChange(storeId, 'menu', value)
        }
        containerStyle={styles.cellDropdown}
      />
      {integrationApp == IntegrationApps.OOLIO_STORE ? (
        <SelectMultiple
          testID="select-pricelists"
          disabled={!isFieldEnabled}
          options={pricingGroupsOptions}
          selectedValues={pricingGroups}
          onValueChange={value =>
            isFieldEnabled && onChange(storeId, 'pricingGroups', value)
          }
          containerStyle={styles.cellDropdown}
        />
      ) : (
        <TreatPicker
          testID="select-pricelists"
          editable={isFieldEnabled}
          options={pricingGroupsOptions}
          selectedValue={pricingGroup}
          onValueChange={value =>
            isFieldEnabled && onChange(storeId, 'pricingGroup', value)
          }
          containerStyle={styles.cellDropdown}
        />
      )}
      {(isDeliverect || isOOM) && (
        <ButtonIcon
          type="neutralLight"
          icon="info-circle"
          onPress={() => onOpenStoreInfo(storeId)}
          containerStyle={styles.cellAccessory}
        />
      )}
      {isFieldEnabled && (
        <ButtonIcon
          type="neutralLight"
          icon="cog"
          disabled={!isFieldEnabled}
          onPress={navigateToStoreSettings}
          containerStyle={styles.cellAccessory}
        />
      )}
    </View>
  );
};
