import * as React from 'react';
import {
  StyleProp,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@oolio-group/domain';
import { alignment } from './DataGrid';

export type DataTableBodyCellProps = {
  children: React.ReactNode;
  option?: boolean;
  onPress?: () => void;
  numberOfLines?: number;
  style?: StyleProp<ViewStyle>;
  small?: boolean;
  clickable?: boolean;
  textAlign?: alignment;
};

const smallContainer: StyleFn = () => ({
  flex: 2,
});

const optionsContainer: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
});

const openRowButtonContainer: StyleFn = () => ({
  flex: 0.2,
  justifyContent: 'flex-end',
});

const container: StyleFn = () => ({
  flex: 3.6,
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'center',
});

const right: StyleFn = () => ({
  justifyContent: 'flex-end',
});

const left: StyleFn = () => ({
  justifyContent: 'flex-start',
});

const centerAlign: StyleFn = () => ({
  justifyContent: 'center',
});

const DataTableBodyCell: React.FC<DataTableBodyCellProps> = props => {
  const { css } = useFela();
  const {
    children,
    textAlign,
    onPress,
    numberOfLines,
    style,
    option,
    small,
    clickable,
    ...rest
  } = props;

  const alignRight = textAlign === alignment.RIGHT;
  const alignLeft = textAlign === alignment.LEFT;
  const center = textAlign === alignment.CENTER;

  return (
    <TouchableWithoutFeedback disabled={!onPress} onPress={onPress} {...rest}>
      <View
        style={[
          css(container),
          option && css(optionsContainer),
          small && css(smallContainer),
          alignRight && css(right),
          alignLeft && css(left),
          style,
          option && center && css(centerAlign),
          clickable && css(openRowButtonContainer),
        ]}
      >
        <Text numberOfLines={numberOfLines}>{children}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default DataTableBodyCell;
