import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  iconOnly: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    minHeight: 44,
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    alignItems: 'center',
    alignSelf: 'flex-start',
    justifyContent: 'center',
  },
  text: {
    flex: 1,
    rowGap: 6,
    marginLeft: 12,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
  },
});

export default styles;
