import React, { useCallback, useEffect, useMemo, useState } from 'react';
import theme from '../../../../../common/default-theme';
import { View } from 'react-native';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import styles from './MenuSchedules.styles';
import {
  daysOption as dayOptionsHelper,
  timeSlotOptions,
  timeSlotCloseOptions,
} from '../../../../../utils/dateHelper';
import { DayOfWeek, TimeRange } from '@oolio-group/domain';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface RoutineRowProps {
  timeRoutine: TimeRange;
  onChangeTime?: (time: TimeRange) => void;
  onDeleteTime: () => void;
}

const RoutineRow: React.FC<RoutineRowProps> = ({
  timeRoutine: timeRoutineProps,
  onChangeTime,
  onDeleteTime,
}) => {
  const [timeRoutine, setTimeRoutine] = useState<TimeRange>();

  const onChangeTimeRange = useCallback(
    (key: keyof TimeRange, value: unknown) => {
      const timeRoutineUpdated = {
        ...timeRoutine,
        [key]: value,
      } as TimeRange;
      onChangeTime && onChangeTime(timeRoutineUpdated);
    },
    [onChangeTime, timeRoutine],
  );

  const onChangeSelectedDays = useCallback(
    (value: string[]) => {
      let tempValues = [...value];
      const allValueIndex = value.indexOf(DayOfWeek.ALL);
      if (allValueIndex !== -1) {
        if (tempValues.length > 1 && allValueIndex !== tempValues.length - 1)
          tempValues.splice(allValueIndex, 1);
        else tempValues = [DayOfWeek.ALL];
      }
      const timeRoutineUpdated = {
        ...timeRoutine,
        days: tempValues,
      } as TimeRange;
      onChangeTime && onChangeTime(timeRoutineUpdated);
    },
    [onChangeTime, timeRoutine],
  );

  const daysOptions = useMemo(() => {
    return [
      {
        label: 'All Days',
        value: DayOfWeek.ALL,
      },
      ...dayOptionsHelper,
    ];
  }, []);

  const startTimeOptions = useMemo(() => {
    const endTime = parseFloat(
      timeRoutineProps?.timeSlot?.endTime.split(':').join(''),
    );
    const clonedOptions = [...timeSlotOptions];
    if (!timeRoutineProps?.timeSlot?.endTime) clonedOptions.pop();
    return timeRoutineProps?.timeSlot?.endTime
      ? clonedOptions.filter((timeSlotOption, index) => {
          const startTime = parseFloat(
            timeSlotOption.value.split(':').join(''),
          );
          return index !== timeSlotOptions.length - 1 && startTime < endTime;
        })
      : timeSlotOptions;
  }, [timeRoutineProps?.timeSlot?.endTime]);

  const endTimeOptions = useMemo(() => {
    const startTime = parseFloat(
      timeRoutineProps?.timeSlot?.startTime.split(':').join(''),
    );
    return timeRoutineProps?.timeSlot?.startTime
      ? timeSlotCloseOptions.filter(timeSlotOption => {
          const endTime = parseFloat(timeSlotOption.value.split(':').join(''));
          return endTime > startTime;
        })
      : timeSlotOptions;
  }, [timeRoutineProps?.timeSlot?.startTime]);

  useEffect(() => {
    setTimeRoutine(timeRoutineProps);
  }, [timeRoutineProps]);

  useEffect(() => {
    if (
      !timeRoutineProps?.timeSlot?.startTime ||
      !timeRoutineProps?.timeSlot?.endTime
    ) {
      onChangeTime &&
        onChangeTime({
          ...timeRoutineProps,
          timeSlot: {
            startTime:
              timeRoutineProps?.timeSlot?.startTime ||
              startTimeOptions?.[0]?.value,
            endTime:
              timeRoutineProps?.timeSlot?.endTime ||
              endTimeOptions?.[endTimeOptions.length - 1]?.value,
          },
        });
    }
  }, [endTimeOptions, onChangeTime, startTimeOptions, timeRoutineProps]);

  return (
    <View style={[theme.forms.row, styles.routineRowContainer]}>
      <SelectMultiple
        testID="select-days"
        options={daysOptions}
        selectedValues={timeRoutine?.days || [DayOfWeek.ALL]}
        onValueChange={onChangeSelectedDays}
        containerStyle={styles.colDays}
      />
      <TreatPicker
        testID="select-startTime"
        options={startTimeOptions}
        selectedValue={timeRoutine?.timeSlot?.startTime}
        onValueChange={value =>
          onChangeTimeRange('timeSlot', {
            startTime: value,
            endTime: timeRoutine?.timeSlot?.endTime,
          })
        }
        containerStyle={styles.colStart}
      />
      <TreatPicker
        testID="select-startTime"
        options={endTimeOptions}
        selectedValue={timeRoutine?.timeSlot?.endTime}
        onValueChange={value => {
          onChangeTimeRange('timeSlot', {
            startTime: timeRoutine?.timeSlot?.startTime,
            endTime: value,
          });
        }}
        containerStyle={styles.colEnd}
      />
      <ButtonIcon
        icon="trash-alt"
        containerStyle={styles.routineDeleteIcon}
        onPress={onDeleteTime}
        type="negativeLight"
      />
    </View>
  );
};

export default RoutineRow;
