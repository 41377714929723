import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  selectedItemChipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 15,
    backgroundColor: theme.colors.blue,
    minHeight: 30,
    borderRadius: 15,
    marginLeft: 5,
  },
  title: {
    color: theme.colors.white,
    marginLeft: 10,
  },
  icon: {
    marginTop: 3,
    marginRight: 10,
  },
});

export default styles;
