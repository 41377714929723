import { gql } from '@apollo/client';

const deputyIntegrationPartnerFragment = `
      id
      appName
      isActive
      preferences {
        workforce {
          deputy {
            companyName
            storeLocationMappings {
              locationId
              companyName
              storeId
            }
          }
        }
      }
      modules {
        workforce
      }`;

export const GET_WORKFORCE_PARTNER_CONSENT_URL = gql`
  query getWorkforcePartnerConsentUrl($integrationPartner: App) {
    getWorkforcePartnerConsentUrl(integrationPartner: $integrationPartner)
  }
`;

export const GET_INTEGRATION_PARTNERS_STORE_CONFIGS = gql`
  query getIntegrationPartnerStoreConfigs($store: String) {
    getIntegrationPartnerStoreConfigs(store: $store) {
      id
      store
      preferences {
        syncOrders
        syncUsers
        syncTimesheets
        userSyncDetail {
          lastSyncTime
          numDeputyEmployeesCreated
          numDeputyEmployeesFailed
          numTillUsersCreated
          numTillUsersFailed
        }
        posTerminalAreaMappings {
          posTerminalId
          posTerminalName
          areaId
          areaName
        }
      }
    }
  }
`;

export const SYNC_EMPLOYEES = gql`
  mutation syncEmployees($storeId: String) {
    syncEmployees(storeId: $storeId)
  }
`;

export const GET_AREAS = gql`
  query getAllDeputyAreas($locationId: Int) {
    getAllDeputyAreas(locationId: $locationId) {
      id
      active
      areaName
      companyId
    }
  }
`;

export const LINK_STORES_WITH_DEPUTY_LOCATIONS = gql`
  mutation linkStoresWithDeputyLocations(
    $allStoreLocationLinks: [DeputyStoreLocationLinkInput],
    $removeStoreLinks:[String],
    $updateStoreLocationLinks: [DeputyStoreLocationLinkInput]
  ) {
    linkStoresWithDeputyLocations(allStoreLocationLinks: $allStoreLocationLinks, removeStoreLinks: $removeStoreLinks, updateStoreLocationLinks: $updateStoreLocationLinks) {
      ${deputyIntegrationPartnerFragment}
    }
  }
`;

export const GET_DEPUTY_INTEGRATION_DATA = gql`
query integrationPartners ($filter : OrganizationIntegrationsFilter){
  integrationPartners (filter : $filter){
    ${deputyIntegrationPartnerFragment}
  }
}
`;

export const UPDATE_STORE_WITH_DEPUTY_SETTINGS = gql`
  mutation updateStoreWithDeputySettings(
    $input: DeputySettingsInput
    $storeId: String
  ) {
    updateStoreWithDeputySettings(deputySettings: $input, store: $storeId)
  }
`;

export const GET_LOCATIONS = gql`
  query getAllDeputyLocations {
    getAllDeputyLocations {
      id
      active
      companyName
    }
  }
`;

export const CREATE_DEPUTY_INTEGRATION = gql`
  mutation createDeputyIntegration($input: String) {
    createDeputyIntegration(input: $input) {
      ${deputyIntegrationPartnerFragment}

    }
  }
`;

export const TOGGLE_INTEGRATION_CONNECTION = gql`
  mutation toggleWorkforceIntegrationConnection($integrationPartner: App) {
    toggleWorkforceIntegrationConnection(
      integrationPartner: $integrationPartner
    ) {
      id
      appName
      isActive
      preferences {
        workforce {
          deputy {
            companyName
          }
        }
      }
      modules {
        workforce
      }
    }
  }
`;
