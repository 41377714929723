import { DrawerScreenProps } from '@react-navigation/drawer';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import OnAccountOrders from './OnAccountOrders/OnAccountOrders';
import Customers from './Customers';

const Stack = createStackNavigator();

export type CustomerStackParam = DrawerScreenProps<
  {
    CustomerStack: { screen: string; fromBalancePaymentScreen: boolean };
  },
  'CustomerStack'
>;

const CustomersStack: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute<CustomerStackParam['route']>();

  useFocusEffect(
    useCallback(() => {
      if (route.params?.screen) {
        navigation.navigate(route.params?.screen);
        return;
      }
    }, [navigation, route.params?.screen]),
  );

  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName={'Customers'}
      screenOptions={{
        animationEnabled: false,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
    >
      <Stack.Screen component={Customers} name="Customers" />
      <Stack.Screen component={OnAccountOrders} name="OnAccountOrders" />
    </Stack.Navigator>
  );
};

export default CustomersStack;
