import { ViewStyle, StyleProp, Text, View } from 'react-native';
import React, { useEffect, useCallback, useMemo } from 'react';
import DataGridLayout from './DataGridLayout';
import DataTableHeader from './DataTableHeader';
import DataTableHeaderCell from './DataTableHeaderCell';
import DataTableRow from './DataTableRow';
import DataTableBodyCell from './DataTableBodyCell';
import TablePagination from '../TablePagination/TablePagination';
import { StyleFn } from '@oolio-group/domain';
import { useFela, withTheme } from 'react-fela';

export enum columnStyleOptions {
  SMALL = 'small',
  OPTION = 'option',
  REGULAR = 'regular',
  CLICKABLE = 'click',
}

export enum alignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export type columnForDataGrid = {
  columnName: string | React.ReactNode;
  columnDataKey: string;
  columnStyle?: columnStyleOptions;
  cellAlignmentStyle?: alignment;
  style?: ViewStyle;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataItem = Record<string, any>;

export interface DataGridProps {
  data: DataItem[];
  columns?: columnForDataGrid[];
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  dataRowStyle?: StyleProp<ViewStyle>;
  scrollStyle?: StyleProp<ViewStyle>;
  paginationStyle?: StyleProp<ViewStyle>;
  rowCellTextAlignment?: StyleProp<ViewStyle>;
  headerCellTextAlignment?: StyleProp<ViewStyle>;
  pageSize?: number;
  setToStartPageIfDataChanges?: boolean;
  renderHeaderItem?: (item: string | React.ReactNode) => React.ReactNode;
  renderDataItem?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: any,
    columnName: string,
    columnStyle: string,
    index: number,
  ) => React.ReactNode;
  renderOptionItems?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: any | string,
    columnName: string,
  ) => React.ReactNode;
  onPageChangeCallback?: (page: number) => void;
  isGreyPagination?: boolean;
  paginationContainerStyle?: ViewStyle;
}
const scrollViewStyle: StyleFn = () => ({
  height: '100%',
  flex: 1,
});

const subItemsStyle: StyleFn = () => ({
  marginLeft: 24,
});

const DataGrid: React.FC<DataGridProps> = ({
  data,
  style,
  headerStyle,
  scrollStyle,
  dataRowStyle,
  paginationStyle,
  columns,
  pageSize,
  headerCellTextAlignment,
  rowCellTextAlignment,
  isGreyPagination,
  paginationContainerStyle,
  setToStartPageIfDataChanges,
  renderHeaderItem,
  renderDataItem,
  renderOptionItems,
  onPageChangeCallback,
}: DataGridProps) => {
  const { css } = useFela();
  const itemsPerPage = pageSize || (data?.length < 10 ? data.length : 10);
  const itemsDataInPageInital =
    data && data.slice((1 - 1) * itemsPerPage, 1 * itemsPerPage);
  const [itemsDataInPage, setPageData] = React.useState(itemsDataInPageInital);
  const [page, setPage] = React.useState(1);
  useEffect(() => {
    onPageChangeCallback && onPageChangeCallback(page);
    const itemsDataInPageIncremental = data?.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage,
    );
    setPageData(itemsDataInPageIncremental);
  }, [page, onPageChangeCallback, data, itemsPerPage]);

  const onPageNumberPress = useCallback(page => {
    setPage(page);
  }, []);

  const headerColumns = useMemo<columnForDataGrid[]>(() => {
    if (!columns) {
      const header: string[] =
        itemsDataInPage && itemsDataInPage.length > 0
          ? Object.keys(itemsDataInPage[0])
          : [];
      return header.map(headerRow => ({
        columnName: headerRow,
        columnDataKey: headerRow,
      }));
    }

    return columns;
  }, [columns, itemsDataInPage]);

  //If data changes set page
  useEffect(() => {
    if (data && setToStartPageIfDataChanges) {
      setPage(1);
    }
  }, [data, setToStartPageIfDataChanges]);

  const renderRowItem = useCallback(
    (dataRow: DataItem) => {
      return headerColumns.map((dataKeys, index3) => {
        const { columnStyle, cellAlignmentStyle, columnDataKey } = dataKeys;
        if (
          columnStyle != columnStyleOptions.OPTION &&
          columnStyle != columnStyleOptions.CLICKABLE
        ) {
          return (
            <DataTableBodyCell
              key={index3}
              style={rowCellTextAlignment}
              small={columnStyle === columnStyleOptions.SMALL}
              textAlign={cellAlignmentStyle}
            >
              {renderDataItem ? (
                renderDataItem(
                  dataRow,
                  columnDataKey,
                  columnStyle || '',
                  index3,
                )
              ) : (
                <Text>{dataRow[columnDataKey]}</Text>
              )}
            </DataTableBodyCell>
          );
        } else {
          return (
            <DataTableBodyCell
              key={index3}
              style={rowCellTextAlignment}
              option={columnStyle === columnStyleOptions.OPTION}
              clickable={columnStyle === columnStyleOptions.CLICKABLE}
              textAlign={cellAlignmentStyle}
            >
              {renderOptionItems ? (
                renderOptionItems(dataRow, columnDataKey)
              ) : (
                <Text>{dataRow[columnDataKey]}</Text>
              )}
            </DataTableBodyCell>
          );
        }
      });
    },
    [headerColumns, renderDataItem, renderOptionItems, rowCellTextAlignment],
  );

  return (
    <DataGridLayout style={style}>
      <DataTableHeader style={headerStyle}>
        {headerColumns &&
          headerColumns.map((renderRow, index) => {
            if (
              renderRow.columnStyle !== columnStyleOptions.OPTION &&
              renderRow.columnStyle !== columnStyleOptions.CLICKABLE
            ) {
              return (
                <DataTableHeaderCell
                  textAlignmentStyle={headerCellTextAlignment}
                  small={
                    renderRow.columnStyle === columnStyleOptions.SMALL
                      ? true
                      : false
                  }
                  key={index}
                  style={renderRow?.style}
                >
                  {renderHeaderItem ? (
                    renderHeaderItem(renderRow.columnName)
                  ) : (
                    <Text>{renderRow.columnName}</Text>
                  )}
                </DataTableHeaderCell>
              );
            } else {
              return (
                <DataTableHeaderCell
                  textAlignmentStyle={headerCellTextAlignment}
                  option={renderRow.columnStyle === columnStyleOptions.OPTION}
                  clickable={
                    renderRow.columnStyle === columnStyleOptions.CLICKABLE
                  }
                  center={renderRow.cellAlignmentStyle === alignment.CENTER}
                  key={index}
                >
                  {renderHeaderItem ? (
                    renderHeaderItem(renderRow.columnName)
                  ) : (
                    <Text>{renderRow.columnName}</Text>
                  )}
                </DataTableHeaderCell>
              );
            }
          })}
      </DataTableHeader>
      <View style={[css(scrollViewStyle), scrollStyle]}>
        {itemsDataInPage &&
          itemsDataInPage.map((dataRow, index2) => {
            return (
              <DataTableRow
                style={dataRowStyle}
                key={dataRow?.id ? dataRow?.id : index2}
                rowIndex={index2}
                subItems={
                  dataRow.subItems ? (
                    <View style={css(subItemsStyle)}>
                      {(dataRow.subItems as DataItem[]).map(subItem => (
                        <DataTableRow
                          style={dataRowStyle}
                          key={subItem?.id ? subItem?.id : index2}
                          rowIndex={index2}
                        >
                          {renderRowItem(subItem)}
                        </DataTableRow>
                      ))}
                    </View>
                  ) : undefined
                }
              >
                {renderRowItem(dataRow)}
              </DataTableRow>
            );
          })}
      </View>
      {data?.length > itemsPerPage && (
        <View style={paginationContainerStyle}>
          <TablePagination
            style={paginationStyle}
            page={page}
            isGreyPagination={isGreyPagination}
            numberOfPages={Math.ceil(data?.length / itemsPerPage)}
            startIndex={(page - 1) * itemsPerPage}
            endIndex={page * itemsPerPage}
            total={data?.length}
            onPageChange={onPageNumberPress}
          />
        </View>
      )}
    </DataGridLayout>
  );
};

export default withTheme(DataGrid);
