import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { View } from 'react-native';
import {
  IntegrationApps,
  UpdateIntegrationPartnerInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';
import { keyBy, mergeWith, isNull } from 'lodash';
import { useStores } from '../../../../../../../hooks/app/useStores';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import theme from '../../../../../../../common/default-theme';
import { useNotification } from '../../../../../../../hooks/Notification';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../../../components/Shared/Inputs/InputToggle';

export enum CommunicationAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

export const Communications: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [isSmsEnabled, setIsSmsEnabled] = useState<boolean>(true);

  const route = useRoute();
  const { storeId, app } = route.params as {
    storeId: string;
    app: IntegrationApps;
  };
  const isFocused = useIsFocused();

  const {
    stores,
    loading: storesLoading,
    error: storesError,
  } = useStores({ storeId });

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue?.id : '';
  }, [storeId, stores]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    createIntegrationPartner,
    operation,
    error: integrationErr,
  } = useIntegrationPartners();

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  useEffect(() => {
    if (storeId && isFocused) {
      getIntegrationPartnerSettings({
        appName: app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, app, storeId, isFocused]);

  const isSettingsChanged = useCallback(() => {
    const preferences =
      integrationPartners[storeId]?.preferences?.onlineOrdering;
    return preferences && isSmsEnabled !== preferences?.enableSmsCommunication;
  }, [integrationPartners, isSmsEnabled, storeId]);

  const saveIntegrationSettings = useCallback(async () => {
    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        integrationPartners[storeId].preferences?.onlineOrdering,
        {
          enableSmsCommunication: isSmsEnabled,
          deliverySettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.deliverySettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.deliverySettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          pickUpSettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.pickUpSettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.pickUpSettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          dineInSettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.dineInSettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.dineInSettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          menuLayout:
            integrationPartners[storeId].preferences?.onlineOrdering
              ?.menuLayout,
        },
        (sourceValue, destinationValue) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering,
        },
        link: '',
        venue: venueId,
      },
    ];

    if (app === IntegrationApps.OOLIO_STORE && !integrationPartners[storeId]) {
      createIntegrationPartner(
        stripProperties({ ...updateSetting[0] }, '__typename'),
      );
    } else {
      if (!isSettingsChanged()) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noSettingsChanged'),
        });
        return;
      }
      updateIntegrationPartnerSettings(
        stripProperties(updateSetting, '__typename'),
      );
    }
  }, [
    integrationPartners,
    storeId,
    isSmsEnabled,
    app,
    venueId,
    createIntegrationPartner,
    isSettingsChanged,
    updateIntegrationPartnerSettings,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;
      setIsSmsEnabled(preferences?.enableSmsCommunication ?? true);
    }
  }, [integrationPartners, storeId]);

  const loading = storesLoading || integrationLoading;
  const error = storesError || integrationErr;
  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [isUpdated, app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onToggleSmsPreference = useCallback(() => {
    setIsSmsEnabled(isSmsEnabled => !isSmsEnabled);
  }, []);

  return (
    <ScreenLayout
      loading={loading}
      title="Communication Settings | Oolio"
      onSave={saveIntegrationSettings}
    >
      <Section title={translate('backOfficeFeatures.smsMessaging')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-communication"
            isToggled={isSmsEnabled}
            onToggle={onToggleSmsPreference}
            type="switch"
            title={translate('backOfficeFeatures.enableSmsCommunication')}
            subtitle={translate(
              'backOfficeFeatures.enableSmsCommunicationDescription',
            )}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
