import { StyleSheet, Dimensions } from 'react-native';
import theme from '../../../../../../common/default-theme';

const { height } = Dimensions.get('window');

export const styles = StyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 340,
    ...theme.shadow.strong,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    maxHeight: height - 100,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.neutral,
  },
  content: {
    paddingHorizontal: 30,
  },
  message: {
    fontSize: 14,
    lineHeight: 20,
    paddingHorizontal: 12,
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
  },
  table: {
    height: 300,
  },
  tableRow: {
    paddingVertical: 6,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  actions: {
    marginTop: 10,
    marginBottom: 30,
    paddingHorizontal: 30,
    backgroundColor: theme.colors.white,
  },
});

export default styles;
