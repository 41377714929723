import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { ProductsFiltersProps } from './Products.type';
import { useTranslation } from '@oolio-group/localization';
import SelectSearch, {
  OptionsType,
} from '../../../../../../components/Shared/Select/SelectSearch';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import ButtonActions from '../../../../../../components/Shared/TreatButton/ButtonActions';
import styles from '../../PriceLists.styles';

export const ProductsFilters: React.FC<ProductsFiltersProps> = ({
  standardPrices,
  addedProducts,
  categoryOptions,
  selectedCategory,
  onPressAdd,
  onPressAddAll,
  setPrices,
  modifyPrices,
  resetPrices,
  onChangeCategory,
  categoryMaps,
}) => {
  const { translate } = useTranslation();

  const productCategoryMaps = useMemo(() => {
    const maps = {} as Record<string, string>;
    Object.values(categoryMaps).forEach(category => {
      category.products.forEach(product => {
        maps[product.id] = category.id;
      });
    });
    return maps;
  }, [categoryMaps]);

  const allProductOptions = useMemo(() => {
    return standardPrices.map(item => ({
      value: item.productId,
      title: item.productName,
      subtitle:
        productCategoryMaps[item.productId] ||
        translate('backOfficeProducts.product'),
      type: 'product',
    }));
  }, [productCategoryMaps, standardPrices, translate]);

  const combinedOptions = useMemo(() => {
    const categoryOptions = Object.values(categoryMaps).map(category => ({
      title: category.name,
      value: category.id,
      subtitle: translate('backOfficeProducts.category'),
      type: 'category',
    }));
    return allProductOptions.concat(categoryOptions);
  }, [allProductOptions, categoryMaps, translate]);

  const handleOptionSelect = (option: OptionsType) => {
    if (option.type === 'category') {
      const selectedCategory = categoryMaps[option.value];
      const { products = [], variants = [] } = selectedCategory;
      const normalProductIds = products.map(product => product.id);
      const variantsProductIds = variants.reduce((ids, variant) => {
        return ids.concat(variant.products.map(product => product.id));
      }, [] as string[]);
      onPressAddAll([...normalProductIds, ...variantsProductIds]);
    } else {
      onPressAdd(option.value);
    }
  };

  const onAddAllProducts = useCallback(() => {
    const allProductIds = standardPrices.map(item => item.productId);
    onPressAddAll(allProductIds);
  }, [onPressAddAll, standardPrices]);

  const bulkOptions = [
    {
      label: translate('pricings.addAllProducts'),
      action: onAddAllProducts,
    },
    { label: translate('productSettings.setPrice'), action: setPrices },
    { label: translate('productSettings.modifyPrice'), action: modifyPrices },
    { label: translate('productSettings.resetPrice'), action: resetPrices },
  ];

  return (
    <View style={styles.filters}>
      <TreatPicker
        testID="select-category"
        options={categoryOptions}
        selectedValue={selectedCategory}
        onValueChange={onChangeCategory}
        containerStyle={styles.dropdown}
      />
      <SelectSearch
        testID="search-products"
        placeholder={translate('pricings.searchCategoriesOrProducts')}
        options={combinedOptions}
        selectedOptions={addedProducts}
        onChangeOption={(_s, i) => handleOptionSelect(i)}
        containerStyle={styles.search}
      />
      <ButtonActions
        type="neutral"
        label={translate('pricings.bulkOptions')}
        actions={bulkOptions}
        containerStyle={styles.btnOptions}
      />
    </View>
  );
};
