import React from 'react';
import { StyleSheet, KeyboardAvoidingView, Platform, View } from 'react-native';
import ModalComponent, { ModalProps } from 'react-native-modal';
import Backdrop from '../components/Modals/Backdrop';
import theme from '../common/default-theme';

const styles = StyleSheet.create({
  modalStyle: {
    flex: 1,
  },
  keyboardAvoidingViewStyle: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  contentChild: {
    alignContent: 'center',
    justifyContent: 'center',
  },
});

interface ModalWrapperProp {
  isVisible: boolean;
  children?: React.ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProp> = ({ children, isVisible }) => {
  const { height, width } = theme.useResponsiveDimensions();

  const defaultModalProps: Partial<ModalProps> = {
    deviceHeight: height,
    deviceWidth: width,
    customBackdrop: <Backdrop />,
    animationInTiming: 50,
    animationOutTiming: 50,
    animationIn: 'fadeIn',
    animationOut: 'fadeOut',
  };

  if (!isVisible) return null;

  return (
    <ModalComponent
      {...defaultModalProps}
      isVisible={isVisible}
      useNativeDriver
      hideModalContentWhileAnimating
      hardwareAccelerated={true}
      presentationStyle="overFullScreen"
      style={styles.modalStyle}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={styles.keyboardAvoidingViewStyle}
      >
        <View style={styles.contentChild}>{children}</View>
      </KeyboardAvoidingView>
    </ModalComponent>
  );
};

export default ModalWrapper;
