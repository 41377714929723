import React, { createContext } from 'react';
import { Availability } from './Availability/Availability';
import { Pricing } from './Pricing/Pricing';
import { General } from './General/General';
import { Variations } from './Variations/Variations';
import { ProductOptions } from './Options/Options';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  productId: string;
  isVariant: boolean;
  isVariantProduct: boolean;
  screen?: string;
}
export const ProductContext = createContext({} as { params: RouteParams });

const ProductSettingsStack: React.FC = () => {
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <ProductContext.Provider value={{ params }}>
      <Tab.Navigator
        tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
          <TabBar tabBar={props as MaterialTopTabBarProps} />
        )}
        backBehavior="none"
        initialRouteName={params.screen || 'General'}
        lazy
      >
        <Tab.Screen name="General" component={General} initialParams={params} />

        {!params?.isVariantProduct && (
          <Tab.Screen
            name="Availability"
            component={Availability}
            initialParams={params}
          />
        )}
        {!params?.isVariant && (
          <Tab.Screen
            name="Pricing"
            component={Pricing}
            initialParams={params}
          />
        )}
        {params?.isVariant && (
          <Tab.Screen
            name="Variations"
            component={Variations}
            initialParams={params}
          />
        )}
        {!params?.isVariant && (
          <Tab.Screen
            name="Options"
            component={ProductOptions}
            initialParams={params}
          />
        )}
      </Tab.Navigator>
    </ProductContext.Provider>
  );
};

export default ProductSettingsStack;
