import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  inputWrapper: {
    height: 44,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 6,
  },
  inputContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingHorizontal: 12,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  inputTitleText: {
    marginBottom: 6,
    paddingLeft: 12,
    color: theme.colors.grey6,
  },
  errorText: {
    paddingTop: 6,
    paddingLeft: 12,
    color: theme.colors.red,
  },
  accessory: {
    position: 'absolute',
    right: 0,
    top: 22,
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelContainer: {
    height: '100%',
    borderRightWidth: 1,
    paddingHorizontal: 12,
    backgroundColor: theme.colors.grey1,
    borderRightColor: theme.colors.grey3,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    marginRight: 1,
  },
  labelText: {
    fontSize: 14,
    color: theme.colors.grey6,
  },
});

export default styles;
