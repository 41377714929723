import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const COLUMN_GAP = 20;

const styles = StyleSheet.create({
  filters: {
    padding: 20,
    flexDirection: 'row',
  },
  search: {
    flex: 1,
  },
  dropdown: {
    width: 180,
    marginRight: 10,
  },
  noteContainer: {
    width: 380,
    padding: 20,
    alignSelf: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  note: {
    marginTop: 20,
    marginBottom: 40,
    alignItems: 'center',
    fontFamily: theme.fonts.medium,
  },
  noteTitle: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
    fontFamily: theme.fonts.medium,
  },
  table: {
    marginBottom: 100,
    paddingHorizontal: 20,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    rowGap: 4,
    marginVertical: 8,
  },
  cellPlacedAt: {
    marginLeft: 124,
    width: 124,
    marginRight: COLUMN_GAP,
  },
  cell100: {
    width: 100,
    marginRight: COLUMN_GAP,
  },
  cellName: {
    flex: 1,
    marginRight: COLUMN_GAP,
  },
  cellNote: {
    flex: 1,
    marginRight: COLUMN_GAP,
  },
  cellLastAction: {
    width: 100,
    textAlign: 'right',
    marginRight: COLUMN_GAP,
  },
  cellAmount: {
    width: 74,
    textAlign: 'right',
    marginRight: 194,
  },
});

export default styles;
