import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { App } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import Collapsible from 'react-native-collapsible';
import { Subscription } from 'rxjs';
import { jobRoleSettingsUtility } from '../../../../../state/jobRoleSettingsUtility';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';

interface PermissionSectionProps {
  app: App;
  children: ReactNode;
}

const PermissionSection: FC<PermissionSectionProps> = ({ app, children }) => {
  const { translate } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [enableAppAccess, setEnableAppAccess] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.appAccessSettings$
      .pipe(pluck(app))
      .subscribe(data => {
        setEnableAppAccess(!!data);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [app]);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.isEditable$
      .pipe(distinctUntilChanged())
      .subscribe(setIsEditable);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const switchAppAccess = useCallback(() => {
    if (isEditable) {
      jobRoleSettingsUtility.appAccessSettings = {
        ...jobRoleSettingsUtility.appAccessSettings,
        [app]: !jobRoleSettingsUtility.appAccessSettings[app],
      };
    }
  }, [app, isEditable]);

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.btnAppAccess}
          onPress={switchAppAccess}
          disabled={!isEditable}
        >
          <Icon
            name={enableAppAccess ? 'check-square' : 'square-full'}
            color={
              enableAppAccess
                ? theme.colors.states.positive
                : theme.colors.grey5
            }
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={toggle}
          testID="btnToggle"
          style={styles.parentContainer}
        >
          <Text>{translate(`backofficeManageRoles.${app}`)}</Text>
          <Icon
            size={20}
            color={theme.colors.black1}
            testID="expandCollapseIcon"
            name={isActive ? 'angle-down' : 'angle-up'}
          />
        </TouchableOpacity>
      </View>
      <Collapsible collapsed={isActive}>{children}</Collapsible>
    </>
  );
};

export default PermissionSection;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    marginBottom: 10,
  },
  parentContainer: {
    flex: 1,
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnAppAccess: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
