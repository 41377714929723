import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const styles = StyleSheet.create({
  container: {
    marginBottom: 4,
    paddingHorizontal: 10,
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.amberLight,
  },
  product: {
    marginLeft: 32,
    color: theme.colors.grey6,
  },
  text: {
    fontSize: CART_FONT_SIZE,
    lineHeight: 20,
    paddingVertical: 3,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  productNote: {
    textAlign: 'left',
  },
});

export default styles;
