import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 12,
    color: theme.colors.grey6,
    paddingHorizontal: 14,
  },
  optionContainer: {
    padding: 12,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: theme.radius.s,
  },
  optionLabel: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
