import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  createScheduleIconContainer: {
    ...theme.forms.inputFluid,
    marginTop: -30,
    width: '100%',
    backgroundColor: theme.colors.greenLight,
    paddingVertical: 5,
    alignItems: 'center',
  },
});

export default styles;
