import React from 'react';
import { View, TextInput, Text, ViewStyle } from 'react-native';
import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import theme from '../../../../common/default-theme';
import styles from './ControlledFormInput.styles';

export interface ControlledFormInputProps<T extends FieldValues> {
  title: string;
  testID?: string;
  name: FieldPath<T>;
  readOnly?: boolean;
  control: Control<T>;
  placeholder?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  alignText?: 'left' | 'center' | 'right';
}

/**
 * Originally these are un-controlled
 * @param param0
 * @returns
 */
const ControlledFormInput = <T extends FieldValues>({
  control,
  name,
  title,
  placeholder,
  readOnly,
  testID,
  containerStyle,
  alignText,
}: ControlledFormInputProps<T>) => {
  return (
    <Controller
      control={control}
      render={({
        field: { onChange, onBlur, value },
        formState: { isDirty, errors },
      }) => (
        <View style={containerStyle}>
          {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
          <View
            style={[
              styles.inputWrapper,
              readOnly !== true
                ? theme.containers.enabled
                : theme.containers.disabled,
            ]}
          >
            <TextInput
              testID={testID}
              defaultValue={value as string}
              placeholder={placeholder}
              placeholderTextColor={theme.colors.grey5}
              style={[styles.inputContainer, { textAlign: alignText }]}
              autoCorrect={false}
              onBlur={onBlur}
              onChangeText={onChange}
              editable={!readOnly}
            />
          </View>
          {isDirty && (errors[name] as FieldError)?.message && (
            <Text testID="errorMessage" style={styles.errorText}>
              {(errors[name] as FieldError)?.message}
            </Text>
          )}
        </View>
      )}
      name={name}
    />
  );
};

export default ControlledFormInput;
