import {
  AddOrderLoyaltySnapshotEvent,
  AddRewardItemEvent,
  AdjustmentType,
  DiscountType,
  LoyaltySnapshot,
  Order,
  OrderAction,
  RemoveRewardItemEvent,
  RewardAdjustment,
  RewardRule,
  RewardType,
  UpdateRewardItemEvent,
} from '@oolio-group/domain';
import { useCallback } from 'react';
import { getOptionalProperty } from '@oolio-group/client-utils';
import { useCart } from './useCart';
import { v4 as uuidv4 } from 'uuid';

export interface RewardMap {
  [id: string]: {
    quantity: number;
    points: number;
    type: RewardType;
    productId?: string;
    itemQuantity?: number;
  }[];
}
export interface UseRewardsInterface {
  (rewardRules: RewardRule[]): {
    redeemRewards: (
      oldRewardMap: RewardMap,
      newRewardMap: RewardMap,
      overridingRules?: Array<RewardRule>,
      order?: Order,
    ) => void;
    updateLoyaltySnapshot: (snapshot: LoyaltySnapshot) => void;
    calculateAndUpdateLoyaltySnapshot: (
      currentBalance: number | undefined,
      pointsEarned: number,
      pointsRedeemed: number,
    ) => void;
    getLoyaltySnapshot: (
      currentBalance: number | undefined,
      pointsEarned: number,
      pointsRedeemed: number,
    ) => LoyaltySnapshot;
  };
}

export const useRewards: UseRewardsInterface = (rewardRules: RewardRule[]) => {
  const { order: orderFromCart, updateCart } = useCart();

  const getRewardDiscountValues = (rewardRule: RewardRule) => {
    const result = {
      discountAmount: getOptionalProperty(rewardRule, 'discountAmount'),
      discountType: getOptionalProperty(rewardRule, 'discountType'),
      maximumDiscountAmount: getOptionalProperty(
        rewardRule,
        'maximumDiscountAmount',
      ),
    };

    // Edge Cases,
    // for now only applicable for FREE ITEM reward
    switch (rewardRule.rewardType) {
      case RewardType.FREE_ITEM:
        // discount 100% on item
        result.discountAmount = 100;
        result.discountType = DiscountType.PERCENTAGE;
        result.maximumDiscountAmount = undefined; // uncap
    }

    return result;
  };

  const redeemRewards = useCallback(
    (
      oldRewardMap: RewardMap,
      newRewardMap: RewardMap,
      overridingRules?: Array<RewardRule>,
      order?: Order,
    ) => {
      const orderObject = order || orderFromCart;
      Object.keys(newRewardMap).forEach(id => {
        newRewardMap[id].forEach(redemption => {
          let existingRewardItem: RewardAdjustment;
          if (redemption.productId) {
            const orderItem = orderObject?.orderItems?.find(
              item =>
                item.product.id === redemption.productId ||
                item.variant?.id === redemption.productId,
            );

            existingRewardItem = orderItem?.adjustments?.find(
              adj =>
                adj.id === id && adj.adjustmentType === AdjustmentType.REWARD,
            ) as RewardAdjustment;
          } else {
            existingRewardItem = orderObject?.adjustments?.find(
              adj =>
                adj.adjustmentType === AdjustmentType.REWARD && adj.id === id,
            ) as RewardAdjustment;
          }

          if (existingRewardItem && redemption.quantity > 0) {
            // update quantity
            updateCart<UpdateRewardItemEvent>(
              OrderAction.ORDER_REWARD_UPDATE_QUANTITY,
              {
                rewardId: existingRewardItem.id,
                productId: redemption.productId,
                quantity: redemption.quantity,
                itemQuantity: redemption.itemQuantity,
              },
            );
          } else if (redemption.quantity <= 0) {
            // remove reward
            oldRewardMap[id] &&
              updateCart<RemoveRewardItemEvent>(
                OrderAction.ORDER_REWARD_REMOVE,
                {
                  rewardId: id,
                  productId: redemption.productId,
                },
              );
          } else {
            // new reward
            const rules = overridingRules || rewardRules;
            const rewardRule = rules.find(reward => reward.id === id);
            rewardRule &&
              updateCart<AddRewardItemEvent>(OrderAction.ORDER_REWARD_ADD, {
                rewardId: rewardRule.id,
                rewardName: rewardRule.rewardName,
                rewardType: rewardRule.rewardType,
                pointsRequired: rewardRule.pointsRequired,
                productId: redemption.productId,
                quantity: redemption.quantity,
                itemQuantity: redemption.itemQuantity,
                ...getRewardDiscountValues(rewardRule),
              });
          }
        });
      });
    },
    [rewardRules, updateCart, orderFromCart],
  );

  const getLoyaltySnapshot = (
    currentBalance: number | undefined,
    pointsEarned: number,
    pointsRedeemed: number,
  ) => {
    const availableBalance =
      (currentBalance || 0) + pointsEarned - pointsRedeemed;

    return {
      pointsEarned,
      availableBalance,
      availableRewards: rewardRules.filter(
        reward => reward.pointsRequired <= availableBalance,
      ),
    } as LoyaltySnapshot;
  };

  const updateLoyaltySnapshot = (snapshot: LoyaltySnapshot) => {
    if (orderFromCart?.isTraining) return;
    updateCart<AddOrderLoyaltySnapshotEvent>(
      OrderAction.ORDER_REWARD_ADD_SNAPSHOT,
      { loyaltySnapshot: { ...snapshot, id: snapshot.id || uuidv4() } },
    );
  };

  const calculateAndUpdateLoyaltySnapshot = (
    currentBalance: number | undefined,
    pointsEarned: number,
    pointsRedeemed: number,
  ) => {
    const loyaltySnapshot = getLoyaltySnapshot(
      currentBalance,
      pointsEarned,
      pointsRedeemed,
    );

    updateLoyaltySnapshot(loyaltySnapshot);
  };

  return {
    redeemRewards,
    getLoyaltySnapshot,
    updateLoyaltySnapshot,
    calculateAndUpdateLoyaltySnapshot,
  };
};
