import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import theme from '../../../../common/default-theme';
import styles from './PaymentHeader.styles';
import Icon from '../../../Icon/Icon';

export interface PaymentHeaderProps {
  title: string;
  onClose?: () => void;
}

const PaymentHeader: React.FC<PaymentHeaderProps> = ({ title, onClose }) => {
  return (
    <View style={styles.container}>
      {onClose ? (
        <TouchableOpacity
          testID="btn-close"
          style={styles.icon}
          onPress={onClose}
        >
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
      ) : null}
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <Text style={[styles.title, { marginRight: onClose ? 44 : 0 }]}>
        {title}
      </Text>
    </View>
  );
};

export default PaymentHeader;
