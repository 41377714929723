import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import pick from 'lodash/pick';
import jwt_decode from 'jwt-decode';
import { REACT_APP_OOLIO_DOMAIN } from 'react-native-dotenv';
import {
  Device,
  Organization,
  Store,
  UpdateDeviceInput,
  Venue,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useSsoProfile } from '@oolio-group/hooks';
import { useVenues } from '../../../hooks/app/useVenues';
import { useSession } from '../../../hooks/app/useSession';
import { useDeviceId } from '../../../hooks/app/useDeviceId';
import { useNotification } from '../../../hooks/Notification';
import { useDeviceInfo } from '../../../hooks/app/useDeviceInfo';
import { useUserProfile } from '../../../hooks/app/useUserProfile';
import { useDeviceAssignment } from '../../../hooks/app/useDeviceAssignment';
import { userUtility } from '../../../state/userUtility';
import { tokenUtility } from '../../../state/tokenUtility';
import { navigateToBackOfficeParams } from '../../../state/navigation';
import { stripProperties } from '../../../utils/stripObjectProps';
import Layout from './Layout';
import DeviceList from './DeviceList';
import StoresList from './StoresList';
import OrganizationsList from './OrganizationsList';

export enum TransitionStep {
  ORGANIZATION = 'Organization',
  STORE = 'Store',
  DEVICE = 'Device',
  PRINTER_PROFILE = 'Printer Profile',
}

const userInfoEndpoint =
  'https://' +
  (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN) +
  '/userinfo';

const TransitionScreen: React.FC = () => {
  const { getMe } = useUserProfile();
  const { deviceId } = useDeviceId();
  const navigation = useNavigation();
  const deviceInfo = useDeviceInfo();
  const { translate } = useTranslation();
  const [session, setSession] = useSession();
  const { showNotification } = useNotification();

  const {
    venues,
    getVenues,
    searchVenues,
    loading: loadingVenues,
    error: errorVenues,
  } = useVenues();

  const {
    updateDevice,
    loading: loadingDevices,
    error: errorDevices,
  } = useDeviceAssignment();

  const { user, loading: loadingUsers } = useSsoProfile(
    userInfoEndpoint,
    tokenUtility.token as string,
  );

  const [searchString, setSearchString] = useState('');
  const [isBackPressed, setIsBackPressed] = useState(false);
  const [venuesData, setVenuesData] = useState<Venue[]>([]);
  const [step, setStep] = useState<TransitionStep>(TransitionStep.ORGANIZATION);

  const allVenues = useMemo(() => Object.values(venues), [venues]);

  const navigateToBackOffice = useCallback(() => {
    setSession({
      ...session,
      currentVenue: undefined,
      currentStore: undefined,
    });
    navigation.reset(navigateToBackOfficeParams);
  }, [navigation, session, setSession]);

  const onPressBack = useCallback(() => {
    setIsBackPressed(true);
    if (step === TransitionStep.STORE) {
      navigateToBackOffice();
    } else {
      setStep(TransitionStep.STORE);
      setSession({
        ...session,
        currentStore: undefined,
      });
    }
  }, [step, navigateToBackOffice, session, setSession]);

  const loading = loadingDevices || loadingVenues;
  const error = errorDevices || errorVenues;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useFocusEffect(
    useCallback(() => {
      getVenues();
    }, [getVenues]),
  );

  useEffect(() => {
    async function updateData() {
      const searchResult = await searchVenues(searchString);
      setVenuesData(searchResult);
    }
    updateData();
  }, [searchVenues, searchString]);

  const onUpdateDevice = useCallback(
    async (item: Device) => {
      const prevOrderNumber = item.previousOrder?.orderNumber;
      userUtility.clearPosUser();
      const input = pick(
        {
          ...item,
          ...stripProperties(deviceInfo, 'versionNum'),
          name: item.name,
          deviceProfile: item.deviceProfile.id,
          cashDrawer: item.cashDrawer || 'default',
          paymentTerminal: item?.paymentTerminal?.id || 'default',
          isPaired: true,
          ...(Boolean(prevOrderNumber) && { lastOrderNumber: prevOrderNumber }),
          uuid: deviceId.toString(),
        },
        [
          'id',
          'name',
          'details',
          'uuid',
          'salesPrefix',
          'returnPrefix',
          'deviceProfile',
          'cashDrawer',
          'isPaired',
          'lastOrderNumber',
        ],
      ) as unknown as UpdateDeviceInput;
      updateDevice(input);
      //TODO : unAssignDevice Existing Device
      // TODO: refactor further to fetch neccessary data for POS depend on selected store
      getMe(session.currentOrganization?.id);
    },
    [
      deviceInfo,
      updateDevice,
      deviceId,
      getMe,
      session.currentOrganization?.id,
    ],
  );

  const onSelectOrganization = useCallback(
    (organization: Pick<Organization, 'id' | 'name'>) => {
      // Setting first organization as default if not provided
      const currentOrganization =
        session.availableOrganizations?.find(
          org => org.id === organization.id,
        ) || session.availableOrganizations?.[0];
      setSession({
        ...session,
        currentOrganization: currentOrganization,
      });
      getVenues();
      setStep(TransitionStep.STORE);
    },
    [session, setSession, getVenues],
  );

  const onSelectStore = useCallback(
    (venue: Partial<Venue>, store: Store) => {
      setIsBackPressed(false);
      setSession({
        ...session,
        currentVenue: venue,
        currentStore: store,
      });
      setStep(TransitionStep.DEVICE);
    },
    [session, setSession],
  );

  useEffect(() => {
    if (!user) return;
    switch (step) {
      case TransitionStep.ORGANIZATION:
        if (session.currentOrganization?.id) {
          // pick the active organization
          onSelectOrganization({
            id: session.currentOrganization.id,
            name: session.currentOrganization.name as string,
          });
        } else if (user?.organizations?.length === 1) {
          // pick the only organization
          onSelectOrganization(user.organizations[0]);
        } else if (user?.organizations?.length === 0) {
          // backward compatible
          // pick default organization
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const tokenPayload = jwt_decode<{ app_metadata: any }>(
            tokenUtility.token as string,
          );
          onSelectOrganization({
            id: tokenPayload.app_metadata.organization_id,
            name: tokenPayload.app_metadata.organization_name,
          } as Organization);
        }
        // else continue with selection
        break;
      case TransitionStep.STORE:
        if (
          !isBackPressed &&
          allVenues.length === 1 &&
          allVenues[0]?.stores?.length === 1
        ) {
          onSelectStore(allVenues[0], allVenues[0].stores[0]);
        }
        break;
      case TransitionStep.DEVICE:
        //TODO :printer setup
        break;
    }
    // On selectStore callback change does not trigger the effect to rerun
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isBackPressed, allVenues, user, session.currentOrganization]);

  return (
    <Layout
      onPressBack={onPressBack}
      title={translate('transition.title', {
        step: step.toLowerCase(),
      })}
    >
      {step === TransitionStep.ORGANIZATION && (
        <OrganizationsList
          organizations={user?.organizations ?? []}
          onSelect={onSelectOrganization}
          loading={loadingUsers}
        />
      )}
      {step === TransitionStep.STORE && (
        <StoresList
          venues={venuesData}
          onSelect={onSelectStore}
          onSearch={setSearchString}
          loading={loading}
          searchText={searchString}
        />
      )}
      {step === TransitionStep.DEVICE && (
        <DeviceList loading={loading} onSelect={onUpdateDevice} />
      )}
    </Layout>
  );
};

export default TransitionScreen;
