import React from 'react';
import { View } from 'react-native';
import { Order, Table } from '@oolio-group/domain';
import Popover, {
  PopoverMode,
  PopoverPlacement,
} from 'react-native-popover-view';
import { TableIcon } from './TableIcon/TableIcon';
import { styles } from './TableIcon/TableIcon.styles';

export interface MultiOrderTableIconProps {
  showPopover: boolean;
  onRequestClose: () => void;
  children?: React.ReactNode;
  table: Table;
  orders?: Order[];
  onPressSubTable: (table: Table, orderIndex?: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childRef?: React.MutableRefObject<any>;
}

const MultiOrderTableIcon: React.FC<MultiOrderTableIconProps> = ({
  children,
  showPopover,
  onRequestClose,
  orders = [],
  table,
  onPressSubTable,
  childRef,
}) => {
  return (
    <>
      {children}
      <Popover
        placement={PopoverPlacement.AUTO}
        from={childRef}
        onRequestClose={onRequestClose}
        isVisible={showPopover}
        mode={PopoverMode.RN_MODAL}
        backgroundStyle={styles.popupBg}
        popoverStyle={[
          styles.popupContainer,
          { height: Math.ceil(orders.length / 3) * 80 + 20 },
        ]}
      >
        {orders.map((order, i) => {
          const subTable = {
            name: `${table.name}-${i + 1}`,
            shape: table.shape,
            status: order.table?.status,
            verticalSeats: 1,
            horizontalSeats: 3,
          } as Table;

          return (
            <View
              key={i}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{ margin: 10 }}
            >
              <TableIcon
                table={subTable}
                onPressTable={() => onPressSubTable(table, i)}
              />
            </View>
          );
        })}
      </Popover>
    </>
  );
};

export default MultiOrderTableIcon;
