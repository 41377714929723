import React, { useCallback, useState, useMemo } from 'react';
import {
  Text,
  View,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  TouchableOpacity,
} from 'react-native';
import { Product } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import styles from './SearchProduct.styles';
import theme from '../../../../common/default-theme';
import Search from '../../../Shared/Search/Search';
import Icon from '../../../Icon/Icon';

export interface SearchProductProps {
  onSelectProduct?: (item: Product) => void;
  searchListLength?: number;
  allProducts: Record<string, Product>;
}

const SearchProduct: React.FC<SearchProductProps> = ({
  onSelectProduct,
  searchListLength,
  allProducts,
}: SearchProductProps) => {
  const { translate } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const { closeModal } = useModal();

  const onPress = useCallback(
    (selectedData: Product): void => {
      onSelectProduct && onSelectProduct(selectedData);
      closeModal();
    },
    [closeModal, onSelectProduct],
  );

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const searchResults = useMemo(() => {
    if (searchString !== '') {
      const searchStr = searchString.toLowerCase();
      const results = Object.values(allProducts)
        .filter(
          (product: Product) =>
            product.name.toLowerCase().includes(searchStr) ||
            product.barcode?.toLowerCase()?.includes(searchStr),
        )
        .slice(0, searchListLength);
      if (
        results.length === 1 &&
        results[0]?.barcode?.toLowerCase() === searchStr
      ) {
        onSelectProduct && onSelectProduct(results[0]);
        closeModal();
      } else {
        return results;
      }
    }
    return [];
  }, [
    searchString,
    allProducts,
    searchListLength,
    onSelectProduct,
    closeModal,
  ]);

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <TouchableOpacity
        testID="btn-close"
        onPress={onCloseModal}
        style={styles.btnClose}
      >
        <Icon name="times" size={20} color={theme.colors.dark} />
      </TouchableOpacity>
      <Search
        testID="search-products"
        placeholder={translate('order.searchProduct.searchProductPlaceholder')}
        value={searchString}
        onChangeText={setSearchString}
        autoFocus={true}
        // onCancel={setSearchString.bind(null, '')}
      />
      <ScrollView style={styles.list}>
        {searchResults?.map((product, i: number) => (
          <TouchableOpacity
            key={i}
            testID="search-result"
            onPress={onPress.bind(null, product)}
            style={styles.option}
          >
            <Text testID="search-result-name">{product.name}</Text>
            <View style={theme.tables.disclosure}>
              <Icon name="angle-right" size={20} color={theme.colors.grey5} />
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

SearchProduct.defaultProps = {
  searchListLength: 10,
};

export default SearchProduct;
