import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import KeyEvent from 'react-native-keyevent';

export interface UseCodeScannerProps {
  enabled?: boolean;
  callback: (code: string) => void;
}

export const useCodeScanner = ({ enabled, callback }: UseCodeScannerProps) => {
  const [scannedCode, setScannedCode] = useState<string[]>([]);

  // trigger callback when return key is detected
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const lastChar = scannedCode[scannedCode.length - 1];
    if (lastChar === '\r' || lastChar === 'Enter') {
      //complete string of barcode digits
      const combinedCode = scannedCode.slice(0, -1).join('');
      callback(combinedCode);
      setScannedCode([]);
    }
  }, [scannedCode, callback, enabled]);

  // listen to code scanner key events
  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (Platform.OS === 'android') {
      KeyEvent.onKeyDownListener(
        (keyEvent: { pressedKey: string; keyCode: number }) => {
          // ignore "Shift" key
          if (keyEvent.keyCode !== 59) {
            setScannedCode(scannedCode =>
              scannedCode.concat(
                // Android sends '66' for Enter key
                keyEvent.keyCode === 66 ? 'Enter' : keyEvent.pressedKey,
              ),
            );
          }
        },
      );

      return () => {
        KeyEvent.removeKeyDownListener();
      };
    }
    if (Platform.OS === 'ios') {
      KeyEvent.onKeyUpListener((keyEvent: { pressedKey: string }) => {
        setScannedCode(scannedCode => scannedCode.concat(keyEvent.pressedKey));
      });

      return () => {
        KeyEvent.removeKeyUpListener();
      };
    }
    if (Platform.OS === 'web') {
      const onKeyDown = (event: KeyboardEvent) => {
        /*
          This will remove the focus from the other element
          so that only barcode scan event triggered
        */
        if (document.activeElement !== document.body) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (document.activeElement as any)?.blur();
        }
        // ignore "Shift" key"
        if (event.key !== 'Shift') {
          setScannedCode(scannedCode => scannedCode.concat(event.key));
        }
      };
      addEventListener('keydown', onKeyDown);
      return () => {
        removeEventListener('keydown', onKeyDown);
      };
    }
  }, [enabled]);

  // reset scannedCode on idle
  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (scannedCode.length > 0) {
      const timeout = setTimeout(() => {
        setScannedCode([]);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [scannedCode, enabled]);
};
