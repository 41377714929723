import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    zIndex: -1,
    marginTop: 20,
  },
  tableBody: {
    height: 250,
  },
  cellGroup: {
    flex: 1,
  },
  cellToggle: {
    width: 60,
    marginLeft: 10,
    alignItems: 'center',
  },
  cellButton: {
    marginLeft: 10,
  },
  cellPrice: {
    width: 100,
    textAlign: 'right',
    paddingRight: 12,
  },
  headerGroup: {
    flex: 1,
    marginRight: 10,
  },
  headerToggleGroup: {
    width: 60,
    textAlign: 'center',
    marginHorizontal: 10,
  },
  headerToggleRequire: {
    width: 60,
    marginRight: 76,
    textAlign: 'center',
  },
});

export default styles;
