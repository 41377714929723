import { Order } from '@oolio-group/domain';
import { formatMoneyValue } from '@oolio-group/localization';
import { table } from 'table';
import { config, FixedTuple } from './orderItems';

export const generateOrderTotalDue = (order: Order, currency: string) => {
  const rows: FixedTuple[] = [
    [
      '',
      order.refundOf ? 'Total Refunded' : 'Total',
      formatMoneyValue(
        +(order.totalPaymentAmount || 0) + (order.roundingAmount || 0),
        currency,
      ),
    ],
  ];

  return table(rows, config);
};
