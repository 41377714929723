import { RxJsonSchema } from 'rxdb/dist/types/types/rx-schema';
import { ApolloCacheModel } from '../models/apolloCache';

export const ApolloCacheSchema: RxJsonSchema<ApolloCacheModel> = {
  version: 0,
  title: 'Apollo Cache',
  description: 'Stores apollo cached values',
  type: 'object',
  primaryKey: 'key',
  properties: {
    key: {
      type: 'string',
    },
    value: {
      type: 'string',
    },
  },
};
