import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Text,
  View,
  ViewStyle,
  TextInput,
  StyleSheet,
  TextInputProps,
  TouchableOpacity,
  ScrollView,
  Platform,
} from 'react-native';
import scale from '../../../common/theme';
import theme from '../../../common/default-theme';
import { Icon } from '../../Icon/Icon';
import {
  Country,
  formatPhoneNumber,
  getPhonePlaceHolder,
  isValidPhoneNumber,
  listCountries,
  usePhoneNumber,
  useTranslation,
} from '@oolio-group/localization';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import styles from './Inputs.styles';
import Search from '../Search/Search';

export interface InputPhoneProps extends TextInputProps {
  title?: string;
  testID: string;
  placeholder?: string;
  value?: string | undefined;
  onChangeText: (text: string) => void;
  defaultCountry?: string;
  onPressCountry: (country: string) => void;
  containerStyle?: ViewStyle | ViewStyle[];
  errorMessage?: string;
}

const PRIORITISED_COUNTRIES = ['AU', 'CA', 'NZ', 'GB', 'US'];

const InputPhone: React.FC<InputPhoneProps> = ({
  title,
  value,
  testID,
  placeholder,
  containerStyle,
  onPressCountry,
  defaultCountry,
  onChangeText,
  errorMessage,
  ...props
}: InputPhoneProps) => {
  const touchable = useRef(null);

  const { translate } = useTranslation();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const [isValid, setIsValid] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    defaultCountry || 'AU',
  );
  const [maskedPhone, setMaskedPhone] = useState(
    formatPhoneNumber(value ?? '', { countryCode: selectedCountry }),
  );

  useEffect(() => {
    setSelectedCountry(defaultCountry || 'AU');
  }, [defaultCountry]);

  useEffect(() => {
    setMaskedPhone(() => {
      if (isValidPhoneNumber(value ?? '', selectedCountry)) {
        return formatPhoneNumber(value ?? '', { countryCode: selectedCountry });
      } else {
        return value ?? '';
      }
    });
  }, [selectedCountry, value]);

  useEffect(() => {
    setIsValid(
      isValidPhoneNumber(
        getFullFormattedPhoneNumber(selectedCountry, String(value)),
      ),
    );
  }, [defaultCountry, getFullFormattedPhoneNumber, selectedCountry, value]);

  const onChangeCountry = (code: string) => {
    setSelectedCountry(code);
    onPressCountry(code);
    setShowOptions(false);
  };

  const sortedCountries = useMemo(() => {
    return listCountries()
      .map((item: Country) => ({
        name: item.name,
        code: item.code,
        emoji: item.emoji,
      }))
      .sort((a, b) => {
        const aPriority = PRIORITISED_COUNTRIES.indexOf(a.code);
        const bPriority = PRIORITISED_COUNTRIES.indexOf(b.code);

        if (aPriority !== -1 && bPriority !== -1) {
          return aPriority - bPriority;
        }

        if (aPriority !== -1) return -1;
        if (bPriority !== -1) return 1;

        return a.name.localeCompare(b.name);
      });
  }, []);

  const filteredCountries = useMemo(() => {
    return sortedCountries.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, sortedCountries]);

  const onChangePhone = (text: string) => {
    const re = /^[0-9\b]+$/;
    const lastChar = text?.substring(text?.length - 1);
    if (text?.length && !re.test(lastChar)) return;

    const phoneInput = text.replace(/[^0-9.]/g, '');
    onChangeText(phoneInput);
  };

  const maxPhoneLength = maskedPhone?.replace(/[0-9]/g, '').length + 13;

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
          ]}
        >
          <TouchableOpacity
            ref={touchable}
            style={localStyles.btnFlag}
            onPress={() => setShowOptions(!showOptions)}
          >
            <Text style={localStyles.flag}>
              {sortedCountries.find(item => item.code === selectedCountry)
                ?.emoji || '?'}
            </Text>
          </TouchableOpacity>
          <TextInput
            value={maskedPhone}
            testID={testID}
            keyboardType="phone-pad"
            placeholder={placeholder || getPhonePlaceHolder(selectedCountry)}
            placeholderTextColor={theme.colors.grey5}
            style={[
              styles.inputContainer,
              // eslint-disable-next-line react-native/no-inline-styles
              { paddingLeft: 44 },
            ]}
            autoCapitalize="none"
            autoComplete="tel"
            autoCorrect={false}
            onChangeText={onChangePhone}
            maxLength={maxPhoneLength}
            {...props}
          />
        </View>
        <View style={styles.accessory}>
          {value && value.length > 0 ? (
            <Icon
              size={20}
              color={
                isValid
                  ? theme.colors.states.positive
                  : theme.colors.states.negative
              }
              name={isValid ? 'check' : 'exclamation-circle'}
            />
          ) : undefined}
        </View>
      </View>
      <Popover
        isVisible={showOptions}
        placement={PopoverPlacement.AUTO}
        onRequestClose={() => setShowOptions(false)}
        from={touchable}
        backgroundStyle={localStyles.bgStyle}
        popoverStyle={localStyles.optionsContainer}
      >
        <View style={localStyles.optionsPopup}>
          <Search
            testID="search-countries"
            value={searchText}
            onChangeText={setSearchText}
            placeholder="Search..."
          />
          <ScrollView>
            {filteredCountries.map((item, i) => {
              return (
                <TouchableOpacity
                  key={`${item.name}-${i}`}
                  style={localStyles.option}
                  onPress={() => onChangeCountry(item.code)}
                >
                  <Text>{item.emoji + '  ' + item.name}</Text>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </Popover>
      {errorMessage ? (
        <Text style={styles.errorText}>{errorMessage}</Text>
      ) : (
        !isValid &&
        String(value).length > 9 && (
          <Text style={styles.errorText}>{translate('common.invalid')}</Text>
        )
      )}
    </View>
  );
};

export default InputPhone;

InputPhone.defaultProps = {
  editable: true,
  value: '',
};

const isWeb = Platform.OS === 'web';

const localStyles = StyleSheet.create({
  btnFlag: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  flag: {
    fontSize: 20,
  },
  bgStyle: {
    width: '100%',
    position: 'absolute',
    height: scale.deviceHeight,
  },
  optionsContainer: {
    width: 300,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  optionsPopup: {
    rowGap: 10,
    padding: 10,
    maxHeight: 340,
    borderWidth: isWeb ? 0 : 2,
    borderRadius: theme.radius.m,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  option: {
    padding: 12,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: theme.colors.grey3,
  },
});
