import React from 'react';
import { View } from 'react-native';
import { useSession } from '../../../hooks/app/useSession';
import styles from './OrdersLogContainer.style';

export const OrdersLogContainer: React.FC = () => {
  const [session] = useSession();
  const ordersUrl = `${process.env.REACT_APP_ORDERS_URL}${
    session?.currentOrganization?.id
      ? `/?orgId=${session?.currentOrganization?.id}`
      : ''
  }`;

  return (
    <View style={styles.container}>
      <iframe
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ border: 'none' }}
        height="100%"
        width="100%"
        src={ordersUrl}
      />
    </View>
  );
};
