import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { OrderSettingsOptions } from '../Actions/OrderSettingsOptions';
import styles from './OnlineOrdersTable.styles';
import Search from '../../../../../components/Shared/Search/Search';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import {
  DelayOrdersChannelInput,
  PartnerChannel,
  PauseOrdersChannelInput,
} from '@oolio-group/domain';

export interface OrdersListHeaderProps {
  onSearchTextChange: (name: string) => void;
  setPreparationTime?: (time: number) => void;
  setBusyTime?: (time: number) => void;
  onAcceptAllOrders?: () => void;
  oomConnectedChannels: PartnerChannel[];
  onSetPauseOrders: (updatedChannels: PauseOrdersChannelInput[]) => void;
  onSetDelayOrders: (updatedChannels: DelayOrdersChannelInput[]) => void;
  onClickRefresh: () => void;
  isLoading: boolean;
}

const OnlineOrdersHeader: React.FC<OrdersListHeaderProps> = ({
  setBusyTime,
  setPreparationTime,
  onAcceptAllOrders,
  onSearchTextChange,
  onClickRefresh,
  isLoading,
  oomConnectedChannels,
  onSetPauseOrders,
  onSetDelayOrders,
}) => {
  const { translate } = useTranslation();

  const onSearch = useCallback(
    (text: string) => {
      onSearchTextChange(text);
    },
    [onSearchTextChange],
  );

  const onPressAcceptAll = useCallback(() => {
    onAcceptAllOrders && onAcceptAllOrders();
  }, [onAcceptAllOrders]);

  return (
    <View style={styles.headerContainer}>
      <OrderSettingsOptions
        setBusyTime={setBusyTime}
        setPreparationTime={setPreparationTime}
        oomConnectedChannels={oomConnectedChannels}
        onSetPauseOrders={onSetPauseOrders}
        onSetDelayOrders={onSetDelayOrders}
      />
      <Search
        testID="online-orders-search"
        onChangeText={onSearch}
        placeholder={translate('onlineOrders.searchByName')}
        containerStyle={styles.search}
      />
      <ButtonIcon
        testID="refresh-online-order"
        size={44}
        icon="sync"
        type="neutral"
        disabled={isLoading}
        onPress={onClickRefresh}
        containerStyle={styles.btnRefresh}
      />
      <TreatButton
        testID={'online-order-accept-all-orders'}
        type="positive"
        label={translate('onlineOrders.acceptAll')}
        onPress={onPressAcceptAll}
        isLoading={isLoading}
      />
    </View>
  );
};

export default OnlineOrdersHeader;
