import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  TerminalDiagnosisInfo,
  TerminalPrinterStatus,
  TerminalStatus,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import styles from './TerminalDiagnosis.styles';
import Icon from '../../../Icon/Icon';

export interface TerminalDiagnosisRowProps {
  label: keyof TerminalDiagnosisInfo;
  value: string | number | boolean | TerminalStatus | TerminalPrinterStatus;
}

export const TerminalDiagnosisRow: React.FC<TerminalDiagnosisRowProps> = ({
  label,
  value,
}: TerminalDiagnosisRowProps) => {
  const { translate } = useTranslation();

  const getText = useCallback((text: string | number, colour: string) => {
    return (
      <Text
        testID={'diagnosis-text-value'}
        style={[styles.contentValue, { color: colour }]}
      >
        {text}
      </Text>
    );
  }, []);

  const getIconBlock = useCallback((value: boolean) => {
    const isPositive = value as boolean;

    return (
      <Icon
        size={20}
        testID={'diagnosis-icon-value'}
        name={isPositive ? 'check-circle' : 'times-circle'}
        color={
          isPositive
            ? theme.colors.states.positive
            : theme.colors.states.negative
        }
      />
    );
  }, []);

  const labelBlock = useMemo(() => {
    if (typeof value === 'boolean') {
      return getIconBlock(value);
    }

    switch (label) {
      case 'status':
        return getIconBlock(value === 'OK');
      case 'printerStatus':
        return getIconBlock(value === 'OK');
      case 'batteryLevel':
        const batteryLevel = parseFloat((value as string).replace('%', ''));
        return getText(
          `${batteryLevel}%`,
          batteryLevel > 50 ? theme.colors.green : theme.colors.red,
        );
      default:
        return getText(value as string | number, theme.colors.dark);
    }
  }, [value, label, getIconBlock, getText]);

  return (
    <View testID="diagnosis-row" style={styles.contentRow}>
      <Text testID="diagnosis-label" style={styles.contentTitle}>
        {translate(`paymentTerminal.diagnosis.labels.${label}`)}
      </Text>
      {labelBlock}
    </View>
  );
};
