import React, { useCallback, useEffect, useMemo, useState } from 'react';
import theme from '../../../../../common/default-theme';
import { View, Text } from 'react-native';
import commonStyles from './MenuSchedules.styles';
import styles from './ScheduleRow.styles';
import SelectDate from '../../../../../components/Shared/Select/SelectDate';
import RoutineRow from './RoutineRow';
import {
  CatalogueSchedule,
  DateRange,
  DayOfWeek,
  TimeRange,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
interface ScheduleRowProps {
  schedule?: CatalogueSchedule;
  onUpdateSchedule?: (schedule: CatalogueSchedule) => void;
  onDeleteSchedule?: () => void;
}

const ScheduleRow: React.FC<ScheduleRowProps> = ({
  schedule: scheduleProps,
  onUpdateSchedule,
  onDeleteSchedule,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [schedule, setSchedule] = useState<CatalogueSchedule>();
  const { translate } = useTranslation();

  const scheduleDateText = useMemo(() => {
    return schedule?.dateRange?.startDate &&
      schedule?.dateRange?.endDate &&
      schedule?.dateRange?.startDate === schedule?.dateRange?.endDate
      ? format(schedule?.dateRange?.startDate, 'P', {
          locale: ptBrLocale,
        })
      : `${
          schedule?.dateRange?.startDate
            ? `From ${format(schedule?.dateRange?.startDate, 'P', {
                locale: ptBrLocale,
              })}`
            : ''
        }${
          schedule?.dateRange?.endDate
            ? ` → ${format(schedule?.dateRange?.endDate, 'P', {
                locale: ptBrLocale,
              })}`
            : ''
        }`;
  }, [schedule?.dateRange?.endDate, schedule?.dateRange?.startDate]);

  const onCollapse = useCallback(() => {
    setExpanded(expand => !expand);
  }, []);

  const onChangeDateRange = useCallback(
    (key: keyof DateRange, value: Date | null | undefined) => {
      onUpdateSchedule &&
        onUpdateSchedule({
          ...schedule,
          dateRange: {
            ...schedule?.dateRange,
            [key]: value ? value.getTime() : undefined,
          },
        } as CatalogueSchedule);
    },
    [onUpdateSchedule, schedule],
  );

  const onChangeRoutine = useCallback(
    (routine: TimeRange, index: number) => {
      const timeRoutines = [...(schedule?.timeBlocks || [])];
      if (timeRoutines) {
        timeRoutines[index] = routine;
        onUpdateSchedule &&
          onUpdateSchedule({
            ...schedule,
            timeBlocks: timeRoutines,
          } as CatalogueSchedule);
      }
    },
    [onUpdateSchedule, schedule],
  );

  const onDeleteRoutine = useCallback(
    (index: number) => {
      const timeRoutines = [...(schedule?.timeBlocks || [])];
      if (timeRoutines) {
        timeRoutines.splice(index, 1);
        onUpdateSchedule &&
          onUpdateSchedule({
            ...schedule,
            timeBlocks: timeRoutines,
          } as CatalogueSchedule);
      }
    },
    [onUpdateSchedule, schedule],
  );

  const onAddRoutine = useCallback(() => {
    const defaultRoutine = {
      days: [DayOfWeek.ALL],
    } as TimeRange;
    onUpdateSchedule &&
      onUpdateSchedule({
        ...schedule,
        timeBlocks: [...(schedule?.timeBlocks || []), defaultRoutine],
      } as CatalogueSchedule);
  }, [onUpdateSchedule, schedule]);

  useEffect(() => {
    setSchedule(scheduleProps);
  }, [scheduleProps]);

  return (
    <View style={commonStyles.scheduleRowContainer}>
      <View style={[theme.tables.header, commonStyles.headerContainer]}>
        <ButtonIcon
          icon={expanded ? 'angle-down' : 'angle-right'}
          onPress={onCollapse}
        />
        <Text style={commonStyles.headerCategory}>{scheduleDateText}</Text>
        <View style={commonStyles.headerDeleteIconContainer}>
          <ButtonIcon
            icon="trash-alt"
            type="negativeLight"
            onPress={onDeleteSchedule}
          />
        </View>
      </View>
      {expanded ? (
        <View style={commonStyles.scheduleContentContainer}>
          <View style={theme.forms.row}>
            <SelectDate
              testID="select-start-date"
              containerStyle={theme.forms.inputHalf}
              title={translate('menus.menuSchedules.validFrom')}
              minDate={new Date()}
              maxDate={
                schedule?.dateRange?.endDate
                  ? new Date(schedule?.dateRange?.endDate)
                  : undefined
              }
              valueDate={
                schedule?.dateRange?.startDate
                  ? new Date(schedule?.dateRange?.startDate)
                  : undefined
              }
              onChangeDate={date => onChangeDateRange('startDate', date)}
              optional={true}
            />
            <SelectDate
              testID="select-end-date"
              containerStyle={theme.forms.inputHalf}
              title={translate('menus.menuSchedules.validTo')}
              minDate={new Date(schedule?.dateRange?.startDate || Date.now())}
              valueDate={
                schedule?.dateRange?.endDate
                  ? new Date(schedule?.dateRange?.endDate)
                  : undefined
              }
              onChangeDate={date => onChangeDateRange('endDate', date)}
              optional={true}
            />
          </View>
          <View
            style={[
              theme.tables.header,
              commonStyles.headerContainer,
              commonStyles.headerRoutine,
            ]}
          >
            <Text style={commonStyles.colDays}>
              {translate('menus.menuSchedules.selectDays')}
            </Text>
            <Text style={commonStyles.colStart}>
              {translate('backOfficeDevices.start')}
            </Text>
            <Text style={commonStyles.colEnd}>
              {translate('backOfficeDevices.end')}
            </Text>
          </View>
          {(schedule?.timeBlocks || []).map((timeRoutine, index) => {
            return (
              <RoutineRow
                key={index}
                timeRoutine={timeRoutine}
                onChangeTime={timeRoutine =>
                  onChangeRoutine(timeRoutine, index)
                }
                onDeleteTime={() => onDeleteRoutine(index)}
              />
            );
          })}
          <ButtonIcon
            icon="plus"
            type="positive"
            onPress={onAddRoutine}
            containerStyle={styles.btnAddRoutine}
            size={38}
          />
        </View>
      ) : null}
    </View>
  );
};

export default ScheduleRow;
