import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { OrderType } from '@oolio-group/domain';
import { useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';

import { GET_ORDER_TYPES } from './graphql';

export interface UseOrderTypeProps {
  loading: boolean;
  error: string | undefined;
  orderTypes?: OrderType[];
  getOrderTypes: () => void;
}

interface UseOrderTypesInput {
  fetchPolicy?: WatchQueryFetchPolicy;
}

export function useOrderTypes(input?: UseOrderTypesInput): UseOrderTypeProps {
  const [getOrderTypesReq, getOrderTypesRes] = useLazyQuery<{
    orderTypes: OrderType[];
  }>(GET_ORDER_TYPES, {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
  });

  const getOrderTypes = useCallback(() => {
    getOrderTypesReq({});
  }, [getOrderTypesReq]);

  const error: ApolloError | undefined = getOrderTypesRes.error;

  return useMemo(
    () => ({
      loading: getOrderTypesRes?.loading,
      error: error ? (error as ApolloError)?.message : '',
      orderTypes: getOrderTypesRes?.data?.orderTypes,
      getOrderTypes,
    }),
    [
      getOrderTypesRes?.loading,
      getOrderTypesRes?.data?.orderTypes,
      error,
      getOrderTypes,
    ],
  );
}
