import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { ProductInventory } from './ItemAvailability';
import * as styles from './ItemAvailability.style';
import { useFela } from 'react-fela';
import IconButton from '../../Button/IconButton';
import InputText from '../../Shared/Inputs/InputText';

interface TableRowProps {
  item: ProductInventory;
  onChange: (
    id: string,
    key: keyof ProductInventory,
    value: string | boolean,
  ) => void;
  onChangeAvailability?: (id: string, value: string | boolean) => void;
}
const ItemRow: React.FC<TableRowProps> = ({
  item,
  onChange,
  onChangeAvailability,
}) => {
  const { css, theme } = useFela();
  const { isBeingTracked, availableQuantity, name, id, isAvailable } = item;

  const onChangeItem = useCallback(
    (key: keyof ProductInventory, value: string | boolean) => {
      onChange(id, key, value);
    },
    [id, onChange],
  );

  const onChangeAvailable = useCallback(
    (value: string | boolean) => {
      onChangeAvailability && onChangeAvailability(id, value);
    },
    [id, onChangeAvailability],
  );

  return (
    <View testID="table-row" style={css(styles.rowStyle)}>
      <View style={css(styles.checkBoxCell)}>
        <IconButton
          primary
          icon={!(isAvailable === false) ? 'check' : ''}
          iconSize={15}
          containerSize={30}
          containerStyle={
            !(isAvailable === false)
              ? css(styles.checkIconContainer)
              : css(styles.unCheckContainer)
          }
          iconColor={isAvailable && theme.colors.success}
          onPress={onChangeAvailable.bind(
            null,
            isAvailable === false ? true : false,
          )}
        />
      </View>
      <View style={css(styles.tableRowCells)}>
        <Text style={css(styles.tableRowCellsText)}>{name}</Text>
      </View>
      <View style={css(styles.toggleCell)}>
        <IconButton
          testID="toggle-button"
          primary
          icon={isBeingTracked ? 'toggle-on' : 'toggle-off'}
          iconSize={25}
          onPress={onChangeItem.bind(
            null,
            'isBeingTracked',
            !Boolean(isBeingTracked),
          )}
          containerStyle={css(styles.toggleContainerStyle)}
          iconColor={
            isBeingTracked ? theme.colors.success : theme.colors.boxBorder
          }
        />
      </View>
      <View style={css(styles.quantityCell)}>
        <InputText
          testID={'input-quantity'}
          placeholder={'-'}
          value={availableQuantity.toString()}
          containerStyle={css(styles.textInput)}
          onChangeText={onChangeItem.bind(null, 'availableQuantity')}
          keyboardType={'numeric'}
          textAlign={'center'}
        />
      </View>
    </View>
  );
};

export default ItemRow;
