import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  catalog: {
    flex: 1,
  },
  btnClose: {
    position: 'absolute',
    left: 10,
  },
  inputCustomer: {
    width: 306,
    marginLeft: -4,
  },
  btnPrint: {
    position: 'relative',
    marginRight: 10,
  },
  indicator: {
    position: 'absolute',
    top: 6,
    right: 6,
    width: 12,
    height: 12,
    borderRadius: 12,
    backgroundColor: theme.colors.red,
    zIndex: 1,
  },
  btnGap: { marginRight: 6 },
});

export default styles;
