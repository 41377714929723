import { createRenderer } from 'fela-native';
import React, { Suspense } from 'react';
import { AppRegistry, Platform } from 'react-native';
import { RendererProvider } from 'react-fela';
import { enableScreens } from 'react-native-screens';
import { LoadingScreen } from './screens/Loading/Loading';
import Sentry from './utils/Sentry/Sentry';
require('react-native-gesture-handler');

enableScreens();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const App = require('./App').default;
const renderer = createRenderer();

const TouchEventBoundary = Platform.select({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  default: Sentry.TouchEventBoundary as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  web: React.Fragment as any,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const wrappedApp = () => (
  <RendererProvider renderer={renderer}>
    <Suspense fallback={<LoadingScreen />}>
      <TouchEventBoundary maxComponentTreeSize={5}>
        <App />
      </TouchEventBoundary>
    </Suspense>
  </RendererProvider>
);

export { wrappedApp as App };

AppRegistry.registerComponent('POSApp', () => wrappedApp);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('POSApp', {
    rootTag: document.getElementById('root'),
  });
}
if (Platform.OS !== 'web') {
  Sentry.wrap(App);
}
