import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { ColumnType, Widget } from '@oolio-group/domain';
import { View, Text, ViewStyle } from 'react-native';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import {
  VictoryChart,
  VictoryLegend,
  VictoryPie,
  VictoryPolarAxis,
  VictoryTooltip,
} from 'victory-native';
import { Styles } from '../styles/Component.styles';
import { IMap, PivotTableData, DisplayLabels } from '../types';
import {
  CHART_COLOR_SCALE,
  COMMON_CHART_STYLES,
  CHART_CONTAINER_STYLE,
  CHART_LEGEND_STYLE,
  LEGEND_BOTTOM_GAP,
  CHART_TOOLTIP_STYLE,
  PIE_POLAR_AXIS,
  TOOLTIP_GAP,
  arePropsEqual,
  getLabelAccordingToType,
} from '../reportsHelper';
import orderBy from 'lodash/orderBy';
import { useCurrency } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';

interface PieChartProps {
  widget: Widget;
  helper: string;
  keys: IMap<string>;
  generateTotal?: boolean;
  columnType?: ColumnType;
  legendWidth?: number;
  dataTransformationFn?: (data: PivotTableData) => PivotTableData;
  cubejsApi: CubejsApi;
  updateCount: number;
}

const PieChartComponent: React.FC<PieChartProps> = ({
  widget,
  helper,
  keys,
  generateTotal,
  legendWidth,
  dataTransformationFn,
  cubejsApi,
  columnType,
}) => {
  const { showNotification } = useNotification();
  const { appendCurrency } = useCurrency();
  const { width: vw } = theme.useResponsiveDimensions();

  const { loading, error, widgetData, getWidgetData } = useReporting(cubejsApi);

  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useCallback(node => {
    if (node !== null) {
      node.getBoundingClientRect &&
        setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const styles = Styles();

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    widget.query && getWidgetData(widget, helper);
  }, [widget, getWidgetData, helper]);

  const data = useMemo(() => {
    if (!widgetData || !widget?.name || !widgetData[widget.name])
      return {} as ResultSet;
    return widgetData[widget.name] as ResultSet;
  }, [widgetData, widget]);

  const chartMargin =
    CHART_CONTAINER_STYLE.marginLeft + CHART_CONTAINER_STYLE.marginRight;
  const chartStyle = {
    ...CHART_CONTAINER_STYLE,
    width: vw - chartMargin,
  };

  const serializedData = useMemo(() => {
    let result = data.tablePivot && data.tablePivot();
    if (dataTransformationFn && result) {
      result = dataTransformationFn(result);
    }
    return result || [];
  }, [data, dataTransformationFn]);

  const chartData =
    serializedData.length > 0
      ? serializedData.map(row => {
          return {
            x:
              typeof row[keys.name] === 'string' && row[keys.name] !== ''
                ? row[keys.name]
                : DisplayLabels.BLANK_LABEL,
            y:
              typeof row[keys.value] === 'string' && row[keys.value] !== ''
                ? parseFloat(row[keys.value] as string)
                : 0,
          };
        })
      : [{ x: DisplayLabels.NO_RECORD, y: 0 }];

  if (generateTotal) {
    const total = chartData.map(el => el.y).reduce((a, b) => a + b, 0);
    chartData.forEach(el => {
      el.y = parseFloat(((el.y / total) * 100).toFixed(2));
    });
  }

  const legendLimit = widget.width && widget.width === '50%' ? 5 : 4;
  const currentLegendWidth =
    legendWidth || (widget.width && widget.width === '50%' ? 35 : 20);

  const legends = orderBy(chartData, ['y'], ['desc'])
    .slice(0, legendLimit)
    .map(data => ({
      name: data.x as string,
    }));
  const legendsWidth = legends.length * currentLegendWidth;
  const legendX = boundingRect.width / 2 - legendsWidth;
  const legendY = boundingRect.height - LEGEND_BOTTOM_GAP;

  return (
    <View style={chartStyle as ViewStyle} ref={graphRef}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VictoryChart
          width={boundingRect.width}
          height={boundingRect.height ? boundingRect.height : 0}
        >
          <VictoryPolarAxis
            tickFormat={() => ''}
            dependentAxis
            style={PIE_POLAR_AXIS}
          />
          <VictoryPie
            data={chartData}
            innerRadius={26}
            labelComponent={
              <VictoryTooltip
                dy={TOOLTIP_GAP}
                style={CHART_TOOLTIP_STYLE.style}
                flyoutStyle={CHART_TOOLTIP_STYLE.flyoutStyle}
              />
            }
            labels={({ datum }) => {
              return getLabelAccordingToType(datum, appendCurrency, columnType);
            }}
            radius={50}
            colorScale={CHART_COLOR_SCALE}
            style={COMMON_CHART_STYLES}
          />
          <VictoryLegend
            style={CHART_LEGEND_STYLE.style}
            x={legendX}
            y={legendY}
            orientation="horizontal"
            symbolSpacer={CHART_LEGEND_STYLE.symbolSpace}
            gutter={CHART_LEGEND_STYLE.legendSpace}
            colorScale={CHART_COLOR_SCALE}
            data={legends}
          />
        </VictoryChart>
      )}
      <Text style={styles.chartTitleStyle}>{widget.name}</Text>
    </View>
  );
};

export const PieChart = React.memo(PieChartComponent, arePropsEqual);
