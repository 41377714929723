import { DefaultPaymentTypes, Order, OrderPayment } from '@oolio-group/domain';
import { limitDecimalCount, sumDecimals } from '@oolio-group/order-helper';
import { v4 as uuidv4 } from 'uuid';

export const getRemainingRefundAmount = (selectedOrder: Order) => {
  return limitDecimalCount(
    sumDecimals([
      selectedOrder.totalPaymentAmount,
      selectedOrder.roundingAmount ?? 0,
    ]),
  );
};

export function mergeOrderPaymentsForCash(orderPayments: OrderPayment[]) {
  const cashPaymentId = uuidv4();
  const allPayments = [] as OrderPayment[];
  let cashOrderPayment = undefined as unknown as OrderPayment;
  orderPayments?.forEach(payment => {
    if (payment.paymentType.name === DefaultPaymentTypes.CASH) {
      cashOrderPayment = {
        ...payment,
        id: cashPaymentId,
        amount: sumDecimals([payment.amount, cashOrderPayment?.amount ?? 0]),
        tendered: sumDecimals([payment.amount, cashOrderPayment?.amount ?? 0]),
        roundOffDifference: sumDecimals([
          payment.roundOffDifference ?? 0,
          cashOrderPayment?.roundOffDifference ?? 0,
        ]),
      };
    } else {
      allPayments.push(payment);
    }
  });
  cashOrderPayment && allPayments.push(cashOrderPayment);
  return allPayments;
}
