import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  DeviceProfile,
  UpdateDeviceProfileWorkflowInput,
  AppScreen,
} from '@oolio-group/domain';
import { pick } from 'lodash';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';
import { useNotification } from '../../../../../../hooks/Notification';
import { capitalCase } from 'change-case';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import theme from '../../../../../../common/default-theme';

const removeUnderScores = (str: string): string => {
  return capitalCase(str.replace('_', ' '));
};

export const Workflow: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({} as DeviceProfile);

  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';

  const {
    deviceProfiles,
    loading,
    error,
    updatedDeviceProfileId,
    unAssignedDeviceProfileId,
    deletedDeviceProfile,
    updateDeviceProfileWorkflow,
  } = useDeviceProfiles({ deviceProfileId, storeId });

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm({
        ...deviceProfiles[deviceProfileId],
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onPressSave = useCallback((): void => {
    const updateParams: UpdateDeviceProfileWorkflowInput = pick(form, [
      'id',
      'defaultSaleScreen',
      'postSaleScreen',
    ]);

    updateDeviceProfileWorkflow(updateParams);
  }, [form, updateDeviceProfileWorkflow]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
  ]);

  useEffect(() => {
    if (unAssignedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.registerUnassignedSuccessfully'),
      });
    }
  }, [showNotification, translate, unAssignedDeviceProfileId]);

  const orderCompletionScreen = Object.values(AppScreen).filter(
    (screen: AppScreen) => {
      const excludeScreensArray = [AppScreen.OPEN_ORDER];
      if (!form.enableOnlineOrders) {
        excludeScreensArray.push(AppScreen.ONLINE_ORDER);
      }
      if (!form.enableFloorView) {
        excludeScreensArray.push(AppScreen.FLOOR_VIEW);
      }
      return !excludeScreensArray.includes(screen);
    },
  );
  const defaultSalesScreen = Object.values(AppScreen).filter(
    (screen: AppScreen) => {
      const excludeScreensArray = [AppScreen.LOGIN, AppScreen.ORDER_HISTORY];
      if (!form.enableOnlineOrders) {
        excludeScreensArray.push(AppScreen.ONLINE_ORDER);
      }
      if (!form.enableFloorView) {
        excludeScreensArray.push(AppScreen.FLOOR_VIEW);
      }
      return !excludeScreensArray.includes(screen);
    },
  );

  return (
    <ScreenLayout
      loading={loading}
      title="Device Workflow | Oolio"
      onSave={onPressSave}
    >
      {/* <DeviceProfileSettingsFooter
        deleteDeviceProfile={deleteDeviceProfile}
        unAssignDeviceProfile={unAssignDeviceProfile}
        onPressSave={onPressSave}
        deviceProfileId={deviceProfileId}
        deviceProfiles={deviceProfiles}
        status={form.status}
      /> */}
      <Section title={translate('backOfficeSettings.registerWorkflow')}>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-orderScreen"
            title={translate('form.screenAfterOrderPlaced')}
            options={orderCompletionScreen.map(screen => ({
              label: removeUnderScores(screen),
              value: screen,
            }))}
            selectedValue={form.postSaleScreen}
            onValueChange={onChange.bind(null, 'postSaleScreen')}
            containerStyle={theme.forms.inputHalf}
          />

          <TreatPicker
            testID="select-salesScreen"
            title={translate('form.defaultSalesScreen')}
            options={defaultSalesScreen.map(screen => ({
              label: removeUnderScores(screen),
              value: screen,
            }))}
            selectedValue={form.defaultSaleScreen}
            onValueChange={onChange.bind(null, 'defaultSaleScreen')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
