import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  pointsTitle: {
    fontSize: 32,
    lineHeight: 44,
    textAlign: 'center',
    color: theme.colors.blue,
    fontFamily: theme.fonts.bold,
  },
  pointsSubtitle: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
    marginBottom: 40,
  },
  rowReward: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.blueLightest,
  },
  rowActivity: {
    height: 64,
    paddingLeft: 10,
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  headerTimestamp: {
    flex: 1,
    textAlign: 'right',
  },
  colActivity: {
    flex: 1,
    paddingLeft: 12,
  },
  textStore: {
    marginTop: 4,
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.grey6,
  },
  textReward: {
    fontSize: 14,
    lineHeight: 20,
    marginLeft: 16,
  },
  expiry: {
    fontSize: 12,
    lineHeight: 16,
    marginLeft: 16,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.negative,
  },
  pagination: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnPagination: {
    marginLeft: 'auto',
    flexDirection: 'row',
  },
});

export default styles;
