import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { DeputySettingsInput } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useWorkforceIntegration } from '../../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import { useModal } from '@oolio-group/rn-use-modal';
import DeputyModal from './DeputyRunModal/DeputyModal';
import styles from '../Settings.styles';
import theme from '../../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../../../../components/Shared/TreatButton/ButtonIcon';

interface SettingItem {
  active?: boolean;
  onToggle: (value: boolean) => void;
  onPressSetting?: () => void;
  label: string;
  testID: string;
}

const SettingItem: React.FC<SettingItem> = ({
  active,
  onToggle,
  onPressSetting,
  label,
  testID,
}) => {
  return (
    <View
      style={[
        styles.settingContainer,
        {
          backgroundColor: active
            ? theme.colors.states.positiveXL
            : theme.colors.grey1,
        },
      ]}
    >
      <InputToggle
        type="switch"
        testID={testID}
        isToggled={active || false}
        onToggle={onToggle.bind(null, !Boolean(active))}
      />
      <Text style={styles.actionText}>{label}</Text>
      {active && onPressSetting && (
        <ButtonIcon icon="cog" type="neutralLight" onPress={onPressSetting} />
      )}
    </View>
  );
};

export const StoreSettings: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const { showModal } = useModal();
  const [storeSettings, setStoreSettings] = useState<
    Partial<DeputySettingsInput>
  >({});
  const routeParams = route.params as {
    locationId: number;
    storeId: string;
  };
  const { locationId, storeId } = routeParams;
  const isFocused = useIsFocused();
  const {
    loading,
    getIntegrationPartnerStoreConfig,
    updateStoreWithDeputySettings,
    integrationPartnerStores,
  } = useWorkforceIntegration();

  useEffect(() => {
    if (isFocused) getIntegrationPartnerStoreConfig(storeId);
  }, [getIntegrationPartnerStoreConfig, storeId, isFocused]);

  const onStoreSettingToggle = useCallback((key: string, value: boolean) => {
    setStoreSettings(pre => ({ ...pre, [key]: value }));
  }, []);

  const onPressSyncOrderSetting = useCallback(
    (isSyncOrder?: boolean) => {
      showModal(
        <DeputyModal
          storeId={storeId}
          storeSettings={storeSettings}
          locationId={locationId}
          isSyncOrder={isSyncOrder}
        />,
      );
    },
    [locationId, showModal, storeId, storeSettings],
  );

  const onPressSave = useCallback(() => {
    const input: DeputySettingsInput = {
      syncOrders: Boolean(storeSettings.syncOrders),
      syncUsers: Boolean(storeSettings.syncUsers),
      syncTimesheets: Boolean(storeSettings.syncTimesheets),
    };
    updateStoreWithDeputySettings(input, storeId);
  }, [storeId, storeSettings, updateStoreWithDeputySettings]);

  useEffect(() => {
    if (integrationPartnerStores) {
      const storeConfig: DeputySettingsInput = {
        syncOrders: Boolean(integrationPartnerStores.syncOrders),
        syncUsers: Boolean(integrationPartnerStores.syncUsers),
        syncTimesheets: Boolean(integrationPartnerStores.syncTimesheets),
      };
      setStoreSettings(storeConfig);
    }
  }, [integrationPartnerStores]);

  return (
    <ScreenLayout loading={loading} title="Deputy | Oolio" onSave={onPressSave}>
      <Section title={translate('tabHeaders.deputy.availableActions')}>
        <View>
          <SettingItem
            label={translate('settingSection.deputy.syncOrderDesc')}
            onToggle={onStoreSettingToggle.bind(null, 'syncOrders')}
            onPressSetting={onPressSyncOrderSetting.bind(null, true)}
            active={storeSettings?.syncOrders}
            testID="buttonOrders"
          />
          <SettingItem
            label={translate('settingSection.deputy.syncUserDesc')}
            onToggle={onStoreSettingToggle.bind(null, 'syncUsers')}
            onPressSetting={onPressSyncOrderSetting.bind(null, false)}
            active={storeSettings?.syncUsers}
            testID="buttonUsers"
          />
          <SettingItem
            label={translate('settingSection.deputy.syncTimesheetDesc')}
            onToggle={onStoreSettingToggle.bind(null, 'syncTimesheets')}
            active={storeSettings?.syncTimesheets}
            testID="buttonTimeSheets"
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
