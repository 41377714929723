import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import theme from '../../../common/default-theme';
import styles from './HomeCard.styles';
import Icon from '../../Icon/Icon';
import { analyticsService } from '../../../analytics/AnalyticsService';

interface HomeCardProps {
  testID: string;
  icon: string;
  color: string;
  title: string;
  description: string;
  onPress: () => void;
  containerStyles?: ViewStyle | ViewStyle[];
}

const HomeCard: React.FC<HomeCardProps> = ({
  testID,
  icon,
  color,
  title,
  description,
  onPress,
  containerStyles,
}) => {
  const handleOnPress = useCallback(() => {
    analyticsService.capture('home_link', {
      button: title,
    });
    onPress();
  }, [onPress, title]);

  return (
    <TouchableOpacity
      testID={testID}
      style={[styles.container, containerStyles]}
      onPress={handleOnPress}
    >
      <View
        style={[
          styles.icon,
          {
            backgroundColor: color,
          },
        ]}
      >
        <Icon name={icon} color={theme.colors.white} size={20} />
      </View>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{description}</Text>
    </TouchableOpacity>
  );
};

export default HomeCard;
