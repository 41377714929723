import { RenderProps, StyleFn } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { KeyboardAvoidingView, Platform, ViewProps } from 'react-native';

export interface PopupViewProps {
  containerStyle?: ViewProps['style'];
  children: React.ReactNode;
}

const popupStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  elevation: 8,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  paddingVertical: theme.spacing.big + 5,
  paddingHorizontal: theme.spacing.medium + 5,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PopupView: React.FC<PopupViewProps> = ({ containerStyle, ...props }) => (
  <FelaComponent style={popupStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={containerStyle ? [style, containerStyle] : style}
        {...props}
      />
    )}
  </FelaComponent>
);

export default PopupView;
