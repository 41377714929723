import React, { useCallback } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { Feature } from '@oolio-group/domain';
import styles from './FeatureTile.styles';
import theme from '../../../../../common/default-theme';
import { Icon } from '../../../../../components/Icon/Icon';

export interface FeaturesTileProps {
  feature: Feature;
  onPress: (feature: Feature) => void;
}

const FeatureTile: React.FC<FeaturesTileProps> = ({
  feature,
  onPress: onClickItem,
}) => {
  const onPressItem = useCallback(() => {
    onClickItem(feature);
  }, [feature, onClickItem]);

  const isEnabled = feature?.enabled;

  return (
    <TouchableOpacity
      key={feature.id}
      onPress={onPressItem}
      testID={'feature-item'}
      style={[
        styles.container,
        {
          backgroundColor: isEnabled
            ? theme.colors.states.positiveXL
            : theme.colors.grey1,
        },
      ]}
    >
      <View style={styles.iconsContainer}>
        <View style={styles.featureIconContainer}>
          {feature.featureId ? (
            <Icon
              testID="feature-icon"
              size={20}
              name={feature.icon}
              color={theme.colors.accent}
            />
          ) : (
            <Image
              style={styles.imageContainer}
              source={{ uri: feature.icon }}
            />
          )}
        </View>
        {isEnabled && (
          <View style={styles.enabledIconContainer}>
            <Icon
              testID="feature-icon"
              name="check"
              size={20}
              color={theme.colors.white}
            />
          </View>
        )}
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.textTitle} testID="feature-title">
          {feature?.title}
        </Text>
        <Text
          numberOfLines={2}
          style={styles.textDesc}
          testID="toggle-action-description"
        >
          {feature.description || feature.about}
        </Text>
      </View>
      <View style={styles.iconsContainer}>
        <Text style={styles.categoryText}>{feature.category}</Text>
        <Icon name="arrow-right" size={20} color={theme.colors.grey6} />
      </View>
    </TouchableOpacity>
  );
};

export default FeatureTile;
