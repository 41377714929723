import React, { useCallback } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { OrderItem } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './ModalPicker.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import ModalTitle from '../ModalTitle/ModalTitle';
import { Option } from '../../../Shared/Select/Select';
import CartProduct from '../../Cart/CartProduct/CartProduct';

interface Props {
  title: string;
  options: Option[];
  item?: OrderItem;
  selectedOption?: string;
  closeAfterSelection?: boolean;
  onSelect: (value: string) => void;
  onClose?: () => void;
}

const ModalPicker: React.FC<Props> = ({
  title,
  options,
  item,
  selectedOption,
  onSelect,
  onClose,
}) => {
  const { closeModal } = useModal();

  const handleClose = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  return (
    <View style={styles.container} testID="mdl-picker">
      <ModalTitle title={title} onDismiss={handleClose} />
      {item && (
        <View style={styles.product}>
          <CartProduct minimised testID="product" orderItem={item} />
        </View>
      )}
      <ScrollView style={styles.options}>
        {options.map(({ label, value }, i) => {
          const isSelected = selectedOption === value;
          return (
            <TouchableOpacity
              key={`option-${i}`}
              testID={`option-${value}`}
              onPress={() => onSelect(value)}
              style={styles.option}
            >
              <View style={styles.optionLabel}>
                <Text style={styles.optionText}>{label}</Text>
              </View>
              {isSelected ? (
                <Icon size={20} name="check" color={theme.colors.green} />
              ) : (
                <Icon size={20} name="angle-right" color={theme.colors.grey4} />
              )}
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default ModalPicker;
