import React from 'react';
import { View } from 'react-native';
import { Modifier as ModifierDefault } from '@oolio-group/domain';
import { getCurrencySymbol } from '@oolio-group/localization';
import {
  getBestPriceOfModifier,
  getTaxFromModifier,
} from '@oolio-group/catalog-helper';
import styles from '../Options.styles';
import theme from '../../../../../common/default-theme';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface Modifier extends ModifierDefault {
  isSelected?: boolean;
  tax?: string;
  priceAmount?: string;
}

interface ModifiersRowProps {
  modifier: Modifier;
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  modifierGroups: { value: string; label: string }[];
  taxes: { value: string; label: string }[];
  onDeleteRow: (id: string, name: string) => void;
  rowIndex: number;
  openTranslationModal: (id: string) => void;
}

export const ModifiersRow: React.FC<ModifiersRowProps> = ({
  modifier,
  onChange,
  modifierGroups,
  taxes,
  onDeleteRow,
  rowIndex,
  openTranslationModal,
}) => {
  const price =
    modifier?.priceAmount !== undefined
      ? modifier?.priceAmount
      : getBestPriceOfModifier(modifier);
  const tax = modifier?.tax || getTaxFromModifier(modifier)?.[0]?.id;

  return (
    <View testID="row-modifier" key={rowIndex} style={theme.tables.row}>
      <InputText
        testID="input-name"
        value={modifier.name}
        placeholder={modifier.name}
        onChangeText={onChange.bind(null, modifier.id, 'name')}
        maxLength={50}
        containerStyle={styles.cellName}
      />
      <SelectMultiple
        testID="select-groups"
        options={modifierGroups}
        selectedValues={(modifier.modifierGroups || [])?.map(
          x => x?.id || (x as unknown as string),
        )}
        onValueChange={onChange.bind(null, modifier.id, 'modifierGroups')}
        containerStyle={styles.cellOptions}
      />
      <InputText
        testID="input-price"
        label={getCurrencySymbol()}
        value={price?.toString()}
        placeholder="0.00"
        alignText="right"
        onChangeText={onChange.bind(null, modifier.id, 'priceAmount')}
        maxLength={50}
        containerStyle={styles.cellPrice}
      />
      <TreatPicker
        testID="select-tax"
        options={taxes}
        selectedValue={tax || ''}
        onValueChange={onChange.bind(null, modifier.id, 'tax')}
        containerStyle={styles.cellTax}
      />
      <ButtonIcon
        testID="btn-translate"
        type="neutralLight"
        icon="english-to-chinese"
        onPress={() => openTranslationModal(modifier.id)}
        containerStyle={styles.cellCopy}
      />
      <ButtonIcon
        testID="btn-delete"
        type="negativeLight"
        icon="trash-alt"
        onPress={() => onDeleteRow(modifier.id, modifier.name)}
        containerStyle={styles.cellCopy}
      />
    </View>
  );
};
