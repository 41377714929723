import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: 700,
    padding: 60,
    alignItems: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  title: {
    flex: 1,
    fontSize: 18,
    lineHeight: 26,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
  },
  btnBack: {
    position: 'absolute',
    left: 20,
    top: 20,
  },
  qrContainer: {
    width: 180,
    height: 180,
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnRefresh: {
    position: 'absolute',
    right: -54,
  },
  codeContainer: {
    width: 180,
    padding: 10,
    marginTop: 20,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  code: {
    fontSize: 22,
    lineHeight: 38,
    letterSpacing: 1,
    textAlign: 'center',
    fontFamily: theme.fonts.bold,
  },
  footnote: {
    fontSize: 14,
    lineHeight: 26,
    textAlign: 'center',
    marginTop: 40,
    maxWidth: 500,
    color: theme.colors.grey6,
  },
});

export default styles;
