import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Organization, Resource } from '@oolio-group/domain';
import { OfficeFixedLinks, OfficeLinkGroups } from '../../../state/navigation';
import { Session } from '../../../state/Session';
import { useSession } from '../../../hooks/app/useSession';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { FEATURES } from '../../../constants';
import styles from './Navigation.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';
import { LinkGroup } from './Links/LinkGroup';
import { FixedNavigationLink } from './Links/FixedLink';
import NavigationFooter from './Footer/NavigationFooter';
import NavigationContext from './Context/NavigationContext';
import { analyticsService } from '../../../analytics/AnalyticsService';
import useOfficeUserAuthorization from '../../../hooks/app/users/useOfficeUserAuthorization';
import { useRestart } from '../../../hooks/app/useRestart';

const Navigation: React.FC = () => {
  const navigation = useNavigation();
  const [session, setSession] = useSession();
  const isFeatureEnabled = useCheckFeatureEnabled();

  const { restartDevice } = useRestart();
  const [collapsed, setCollapsed] = useState(false);
  const [recordSessions, setRecordSessions] = useState(false);
  const { canI } = useOfficeUserAuthorization();
  useEffect(() => {
    async function getRecordSessionsFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.RECORD_SESSIONS,
      );
      setRecordSessions(isEnabled);
    }
    getRecordSessionsFlag();
  }, [recordSessions]);

  useEffect(() => {
    if (recordSessions) {
      analyticsService.stopSessionRecording();
      analyticsService.startSessionRecording();
    }
  }, [recordSessions]);

  const {
    currentOrganization: currentOrg,
    availableOrganizations: organisations,
    user,
  }: Partial<Session> = session || {};

  const orgOptions = useMemo(
    () =>
      organisations?.map(org => ({
        label: org.name,
        value: org.id,
      })) || [],
    [organisations],
  );

  const activeOrg = useMemo<Partial<Organization> | undefined>(
    () => (organisations || []).find(x => x.id === currentOrg?.id),
    [organisations, currentOrg?.id],
  );

  const onSwitchOrganization = useCallback(
    orgId => {
      const nextOrg = session.availableOrganizations?.find(
        org => org.id === orgId,
      );
      if (!nextOrg) {
        const newOnboardingOrg = session.ssoOrganizations?.find(
          org => org.id === orgId,
        );
        navigation.navigate('onboarding', {
          orgId,
          orgName: newOnboardingOrg?.name,
          user: session.user?.id,
        });
      } else {
        setSession({
          ...session,
          currentOrganization: nextOrg,
        });
        restartDevice();
      }
    },
    [navigation, session, setSession, restartDevice],
  );

  const fixedLinks = useMemo(() => {
    return OfficeFixedLinks.filter(link => {
      if (link.id === 'dashboard') {
        if (
          canI([
            {
              doOperations: ['view'],
              onResource: Resource.DASHBOARD,
            },
          ])
        )
          return Platform.OS !== 'ios' && Platform.OS !== 'android';
        else return false;
      } else {
        return true;
      }
    });
  }, [canI]);

  const links = useMemo(() => {
    return OfficeLinkGroups.map(group => ({
      ...group,
      links: group.links.filter(link => {
        const canAccess = canI([
          {
            doOperations: ['view'],
            onResource: link.resource,
          },
        ]);
        if (!link.feature) return true && canAccess;

        const { featureId, showTabIfDisabled } = link.feature;
        const featureEnabled = isFeatureEnabled(featureId);
        return (
          (showTabIfDisabled && !featureEnabled) ||
          (featureEnabled && canAccess)
        );
      }),
    })).filter(group => group.links.length > 0);
  }, [isFeatureEnabled, canI]);

  if (collapsed) {
    return (
      <View style={styles.collapsed}>
        <TouchableOpacity
          testID="btn-collapse"
          style={styles.btnCollapse}
          onPress={() => setCollapsed(false)}
        >
          <Icon
            size={22}
            name="angle-double-right"
            color={theme.colors.white}
          />
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <NavigationContext
        user={{
          email: user?.email || '',
          org: activeOrg?.name || 'Oolio',
          orgLogo: activeOrg?.logoUrl,
        }}
        switcher={Platform.select({
          default: {
            options: orgOptions,
            selectedOption: activeOrg?.id || '',
            onSelectOption: onSwitchOrganization,
          },
          web: undefined,
        })}
        onCollapse={setCollapsed}
      />
      {fixedLinks.length > 0 ? (
        <View style={styles.fixed}>
          {fixedLinks.map((props, i) => (
            <FixedNavigationLink {...props} key={`fixedLink-${i}`} />
          ))}
        </View>
      ) : (
        <></>
      )}
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <View style={styles.links}>
          {links.map((props, i) => (
            <LinkGroup {...props} key={`linkGroup-${i}`} />
          ))}
        </View>
      </ScrollView>
      <NavigationFooter />
    </View>
  );
};

export default Navigation;
