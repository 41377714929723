import { StyleFn, CopyPrinterInput } from '@oolio-group/domain';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import FormInput from '../../FormInput/FormInput';
import { useTranslation } from '@oolio-group/localization';
import Button from '../../Button/Button';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import Modal from '../Modal';

const createModalContainerStyle: StyleFn = () => ({
  width: '330',
  flexDirection: 'column',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.medium,
});

const formTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const textInputStyle: StyleFn = () => ({
  height: 38,
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: 38,
  marginBottom: theme.spacing.medium,
});

interface CopyPrinterProps {
  onCopy: (input: CopyPrinterInput) => void;
  printerId: string;
}

export const CopyPrinterModal: React.FC<CopyPrinterProps> = ({
  onCopy,
  printerId,
}: CopyPrinterProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [form, setForm] = useState<CopyPrinterInput>({
    name: '',
    printerId,
  });
  const { showNotification } = useNotification();

  const onChange = useCallback((prop: string, value) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onCreatePrinter = useCallback(() => {
    if (!form.name) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.pleaseEnterPrinterName'),
      });
    } else {
      onCopy(form);
      closeModal();
    }
  }, [form, onCopy, translate, showNotification, closeModal]);

  return (
    <Modal
      title={translate('backOfficeSettings.copy')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <View>
        <FormInput
          title={translate('form.printers.name')}
          placeholder={translate('form.printers.namePlaceholder')}
          value={form.name}
          containerStyle={css(formInputStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, 'name')}
          textInputStyle={css(textInputStyle)}
        />

        <Button
          title={translate('button.copyPrinter')}
          containerStyle={css(buttonContainerStyle)}
          labelStyle={css(createLabelStyle)}
          color={theme.colors.green}
          onPress={onCreatePrinter}
        />
      </View>
    </Modal>
  );
};
