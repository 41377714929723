/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { Section, UpdateSectionInput } from '@oolio-group/domain';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';
import { trimTrailingSpaces } from '@oolio-group/client-utils';
import {
  useNavigation,
  useRoute,
  useIsFocused,
} from '@react-navigation/native';
import { useSections } from '../../../../../hooks/app/sections/useSections';
import { pick } from 'lodash';
import { Operation } from '../../../../../types/Operation';
import { useStores } from '../../../../../hooks/app/useStores';
import SectionRow from './SectionRow';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import { default as OfficeSection } from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import styles from '../VenueSettings.styles';
import theme from '../../../../../common/default-theme';

type FormState = Record<string, Section & { isChanged: boolean }>;

export const SectionsScreen: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormState>({});
  const route = useRoute();
  const navigation = useNavigation();
  const isFocussed = useIsFocused();

  const params = route.params as {
    venueId: string;
    storeId?: string;
  };

  const venueId = params.venueId || '';
  const storeId = params?.storeId || '';

  const {
    loading: loadingSection,
    error: errorSection,
    sections,
    getSections,
    updateSections,
    updatedSectionIds,
    copySection,
  } = useSections({ venueId });

  const {
    stores,
    getStores,
    loading: loadingStores,
    error: errorStores,
  } = useStores();

  const loading = loadingStores || loadingSection;

  const error = errorStores || errorSection;

  useEffect(() => {
    if (isFocussed) {
      getSections();
      getStores();
    }
  }, [isFocussed, getSections, getStores]);

  useEffect(() => {
    if (sections) {
      setForm(sections as FormState);
    }
  }, [sections]);

  const venueStores = useMemo(() => {
    const storesData = Object.values(stores || []).filter(
      store => store?.venue?.id === venueId,
    );
    return storesData;
  }, [venueId, stores]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedSectionIds.length > 0) {
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionUpdatedSuccessfully'),
      });
    }
  }, [updatedSectionIds, showNotification, translate]);

  const onChange = useCallback(
    (id: string, prop: string, value: string): void => {
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          [prop]: value,
          isChanged: true,
        },
      }));
    },
    [],
  );

  const onStoresSelect = useCallback(
    (id: string, values: string[]) => {
      const selectedStores = venueStores.filter(store =>
        values.includes(store.id),
      );
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          stores: selectedStores,
          isChanged: true,
        },
      }));
    },
    [venueStores],
  );

  const onSectionCopy = useCallback(
    (section: Section): void => {
      copySection(section.id);
    },
    [copySection],
  );

  const onPressCreateNew = useCallback((): void => {
    const paramsToNavigate = {
      venueId,
      operation: Operation.CREATE,
      storeId: storeId,
      fromStoreSection: Boolean(storeId),
    };
    navigation.navigate('SectionSettings', paramsToNavigate);
  }, [navigation, venueId, storeId]);

  const onPressSave = useCallback((): void => {
    const data = Object.values(form)
      .filter(section => section.isChanged)
      .map(
        section =>
          ({
            ...pick(section, ['id']),
            name: trimTrailingSpaces(section.name),
            venue: venueId,
            stores: section.stores.map(({ id }) => id),
          } as UpdateSectionInput),
      );

    if (data.length > 0) {
      const lowerCasedSectionNames = Object.values(form).map(({ name }) =>
        (name || '').toLowerCase(),
      );
      const isDuplicateSectionName = lowerCasedSectionNames.some(
        (element, index) => {
          return lowerCasedSectionNames.indexOf(element) !== index;
        },
      );

      if (data.some(section => !section.name)) {
        showNotification({
          error: true,
          message: translate('backOfficeSections.sectionNameIsMissing'),
        });
      } else if (isDuplicateSectionName) {
        showNotification({
          error: true,
          message: translate('backOfficeSections.sectionWithSameNameExists'),
        });
      } else {
        updateSections(data);
      }
    }
  }, [form, showNotification, updateSections, venueId, translate]);

  const sectionsData = useMemo(() => Object.values(form), [form]);

  return (
    <ScreenLayout
      loading={loading}
      title="Sections & Tables | Oolio"
      onSave={onPressSave}
    >
      <OfficeSection
        layoutWidth="medium"
        title="Sections & Tables"
        subtitle={translate(
          'backOfficeSettings.manageSection.sectionDescription',
        )}
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, { width: 250 }]}>
              {translate('backOfficeSettings.manageSection.section')}
            </Text>
            <Text style={[theme.tables.headerText, { flex: 1 }]}>
              {translate('backOfficeSettings.manageSection.storeAvailability')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellCountText]}>
              {translate('backOfficeSettings.manageSection.tables')}
            </Text>
            <View style={{ width: 58 }} />
          </View>
          <View>
            {sectionsData.map((section: Section, i: number) => (
              <SectionRow
                sectionData={section}
                key={i}
                index={i}
                onChange={onChange}
                stores={venueStores}
                onStoresSelect={onStoresSelect}
                onSectionCopy={onSectionCopy}
              />
            ))}
          </View>
        </View>
        <CreateButton onPress={onPressCreateNew} />
      </OfficeSection>
    </ScreenLayout>
  );
};
