import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  CustomerAccountFeatureSettings,
  Feature,
  FeatureIDs,
  ToggleFeatureInput,
} from '@oolio-group/domain';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translate } from '@oolio-group/localization';
import { parseApolloError } from '../../../utils/errorHandlers';
import { useNotification } from '../../Notification';
import { isLoading } from '../../../utils/apolloErrorResponse.util';

import { useSession } from '../useSession';
import {
  CREATE_UPDATE_ACCOUNT_FEATURE_SETTINGS,
  GET_ACCOUNT_FEATURE_SETTINGS,
  GET_FEATURES,
  GET_INTEGRATIONS,
  TOGGLE_FEATURE,
} from './graphql';
import { keyBy } from 'lodash';

export interface UseFeaturesReturn {
  loading: boolean;
  integrations: Feature[];
  getIntegrations: () => void;
  getFeatures: () => void;
  features: Feature[];
  toggleFeature: (input: ToggleFeatureInput) => void;
  featureSettings?: CustomerAccountFeatureSettings;
  getAccountFeatureSettings: () => void;
  createOrUpdateAccountFeature: (input: CustomerAccountFeatureSettings) => void;
}

export const useFeatures = (): UseFeaturesReturn => {
  const [featureMaps, setFeatureMaps] = useState<Record<string, Feature>>({});
  const [integrations, setIntegrations] = useState<Feature[]>([]);
  const { showNotification } = useNotification();
  const [session, setSession] = useSession();
  const sessionRef = useRef(session);

  const onErrorHandler = useCallback(
    error => {
      showNotification({ error: true, message: parseApolloError(error) });
    },
    [showNotification],
  );

  const [getFeaturesRequest, getFeaturesRes] = useLazyQuery<{
    features: Feature[];
  }>(GET_FEATURES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      setFeatureMaps(keyBy(response.features || [], 'id'));
    },
    onError: onErrorHandler,
  });

  const [getFeatureSettingsRequest, getFeatureSettingsRes] = useLazyQuery<{
    customerAccountFeatureSettings: CustomerAccountFeatureSettings;
  }>(GET_ACCOUNT_FEATURE_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    onError: onErrorHandler,
  });

  const [getIntegrationsRequest, getIntegrationsRes] = useLazyQuery(
    GET_INTEGRATIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => setIntegrations(data.integrations),
      onError: onErrorHandler,
    },
  );

  const [toggleFeatureReq, toggleFeatureRes] = useMutation<
    { toggleFeature: Feature },
    { input: ToggleFeatureInput }
  >(TOGGLE_FEATURE, {
    onError: onErrorHandler,
    onCompleted: response => {
      const updatedFeature = response.toggleFeature;
      if (updatedFeature?.enabled) {
        showNotification({
          success: true,
          message: translate(
            'backOffice.features.notifications.featureEnabled',
            {
              featureName: updatedFeature.name,
            },
          ),
        });
      } else {
        showNotification({
          success: true,
          message: translate(
            'backOffice.features.notifications.featureDisabled',
            {
              featureName: updatedFeature.name,
            },
          ),
        });
      }

      setFeatureMaps(featureMaps => ({
        ...featureMaps,
        [updatedFeature.id]: updatedFeature,
      }));
    },
  });

  const [updateAccountFeatureSettingReq] = useMutation(
    CREATE_UPDATE_ACCOUNT_FEATURE_SETTINGS,
    {
      onError: onErrorHandler,
      onCompleted: data => {
        data?.createOrUpdateFeatureCustomerAccountSettings &&
          showNotification({
            success: true,
            message: translate(
              'backOffice.features.featureSettingUpdatedMessage',
            ),
          });
      },
    },
  );

  const toggleFeature = useCallback(
    (input: ToggleFeatureInput) => {
      toggleFeatureReq({ variables: { input } });
    },
    [toggleFeatureReq],
  );

  const getAccountFeatureSettings = useCallback(() => {
    getFeatureSettingsRequest({
      variables: { featureId: FeatureIDs.CUSTOMER_ACCOUNT },
    });
  }, [getFeatureSettingsRequest]);

  const createOrUpdateAccountFeature = useCallback(
    (input: CustomerAccountFeatureSettings) => {
      updateAccountFeatureSettingReq({ variables: { input } });
    },
    [updateAccountFeatureSettingReq],
  );

  const features = useMemo(() => Object.values(featureMaps), [featureMaps]);

  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  useEffect(() => {
    const session = sessionRef.current;
    if (!features.length) return;
    setSession({
      ...session,
      currentOrganization: {
        ...session.currentOrganization,
        features,
      },
    });
  }, [features, setSession]);

  const loading = isLoading([
    getFeaturesRes,
    getIntegrationsRes,
    toggleFeatureRes,
  ]);

  const featureSettings =
    getFeatureSettingsRes.data?.customerAccountFeatureSettings;

  return {
    loading,
    integrations,
    getIntegrations: getIntegrationsRequest,
    getFeatures: getFeaturesRequest,
    getAccountFeatureSettings,
    createOrUpdateAccountFeature,
    features,
    toggleFeature,
    featureSettings,
  };
};
