import { RenderProps, StyleFn } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { TextStyle, View, ViewStyle } from 'react-native';
import Title from '../Title/Title';

export interface TitleBarProps {
  title?: string;
  testID?: string;
  containerStyle?: ViewStyle;
  primary?: boolean;
  titleLeft?: React.ReactElement;
  titleRight?: React.ReactElement;
  labelStyle?: TextStyle;
  labelContainerStyle?: ViewStyle;
}

const titleBarStyle: StyleFn = ({ theme, primary, titleLeft, titleRight }) => ({
  backgroundColor: primary ? theme.colors.primary : theme.colors.white,
  height: 44,
  minHeight: 30,
  flexDirection: 'row',
  justifyContent: titleLeft && titleRight ? 'space-between' : 'center',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing.small * 1.5,
  marginTop: theme.spacing.small,
  textTransform: 'uppercase',
  textAlign: 'center',
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.medium,
});

const TitleBar: React.FC<TitleBarProps> = ({
  testID,
  titleLeft,
  title,
  titleRight,
  containerStyle,
  labelContainerStyle,
  ...props
}: TitleBarProps) => {
  return (
    <FelaComponent style={titleBarStyle} {...props}>
      {({ style }: RenderProps): React.ReactNode => (
        <View testID={testID} style={[style, containerStyle]}>
          {titleLeft}
          {title ? (
            <Title {...props} containerStyle={labelContainerStyle}>
              {title}
            </Title>
          ) : null}
          {titleRight}
        </View>
      )}
    </FelaComponent>
  );
};

export default TitleBar;
