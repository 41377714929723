import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { OrderStatus } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { AppScreen } from '../../../types/AppScreen';
import {
  pendingOnlineOrdersCountVar,
  ordersReceivedViaPollingAt,
} from '../../../state/cache';
import { useSession } from '../../../hooks/app/useSession';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import SegmentControl, {
  SegmentTab,
} from '../../../components/Shared/SegmentControl/SegmentControl';

interface OrdersSegmentTabsProps {
  activeScreen: string;
  previousScreen: string;
}

const OrdersSegmentTabs: FC<OrdersSegmentTabsProps> = ({
  activeScreen,
  previousScreen,
}) => {
  const [session] = useSession();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { openOrdersCount, getOrdersFromCache } = useOrders();

  const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;
  const onlineOrdersCount = useReactiveVar<number>(pendingOnlineOrdersCountVar);
  const ordersUpdatedThroughPolling = useReactiveVar<number>(
    ordersReceivedViaPollingAt,
  );

  const TABS = useMemo((): SegmentTab[] => {
    const fromTakeOrder = previousScreen === AppScreen.NEW_ORDER;

    const options = [
      {
        label: fromTakeOrder
          ? translate('order.currentOrder')
          : translate('navigation.floorView'),
        value: fromTakeOrder ? AppScreen.NEW_ORDER : AppScreen.FLOOR_VIEW,
      },
      {
        label: `${translate('order.openOrders')} (${openOrdersCount})`,
        value: 'OpenOrders',
      },
    ];

    if (isOnlineOrdersEnabled) {
      options.push({
        label: translate('order.onlineOrders'),
        value: AppScreen.ONLINE_ORDER,
        badge: Boolean(onlineOrdersCount),
      } as SegmentTab);
    }

    return options;
  }, [
    isOnlineOrdersEnabled,
    onlineOrdersCount,
    openOrdersCount,
    previousScreen,
    translate,
  ]);

  const onChangeTabs = useCallback(
    (screen: string) => {
      navigation.navigate(screen, {
        previousScreen: previousScreen,
      });
    },
    [navigation, previousScreen],
  );

  useEffect(() => {
    let timeoutId: number | null;
    if (ordersUpdatedThroughPolling) {
      timeoutId = setTimeout(() => {
        getOrdersFromCache(OrderStatus.IN_PROGRESS);
        getOrdersFromCache(OrderStatus.ON_HOLD);
      }, 2000) as unknown as number;
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [getOrdersFromCache, ordersUpdatedThroughPolling]);

  useEffect(() => {
    if (isFocused) {
      getOrdersFromCache(OrderStatus.IN_PROGRESS);
      getOrdersFromCache(OrderStatus.ON_HOLD);
    }
  }, [getOrdersFromCache, isFocused]);

  return (
    <SegmentControl
      tabs={TABS}
      testID="segments-orderScreens"
      selectedTab={activeScreen}
      onPress={onChangeTabs}
    />
  );
};

export default OrdersSegmentTabs;
