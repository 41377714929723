import React from 'react';
import CreateOrUpdateAdjustment from './CreateOrUpdateAdjustment';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';

interface RouteParams {
  venueId: string;
  adjustmentId?: string;
  routeType?: string;
}
const Tab = createMaterialTopTabNavigator();

const AdjustmentTab: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const routeParams = route.params as RouteParams;

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => {
        return <TabBar tabBar={props} />;
      }}
      initialRouteName="CreateOrEditAdjustment"
    >
      <Tab.Screen
        name="CreateOrEditAdjustment"
        component={CreateOrUpdateAdjustment}
        initialParams={routeParams}
        options={{
          tabBarLabel: routeParams.routeType
            ? translate(
                `backofficeVenueSettingAdjustments.${routeParams.routeType.toLowerCase()}`,
              )
            : translate('backofficeVenueSettingAdjustments.adjustment'),
        }}
      />
    </Tab.Navigator>
  );
};

export default AdjustmentTab;
