import React, { useCallback } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { OrderType, OrderTypeCode } from '@oolio-group/domain';
import theme from '../../../../../../../common/default-theme';
import InputToggle from '../../../../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../../../../components/Shared/TreatButton/ButtonIcon';

interface OrderTypeRowProps {
  orderType: OrderType;
  enabled: boolean;
  onToggle?: (id: string, value: boolean) => void;
  onOpenModal?: (code: OrderTypeCode) => void;
}

const OrderTypeRow: React.FC<OrderTypeRowProps> = ({
  onToggle,
  orderType,
  enabled,
  onOpenModal,
}: OrderTypeRowProps) => {
  const onPressHandler = useCallback(() => {
    onToggle && onToggle(orderType.id, !enabled);
  }, [onToggle, orderType.id, enabled]);

  const onPressOpenModal = useCallback(() => {
    onOpenModal && onOpenModal(orderType.code as OrderTypeCode);
  }, [onOpenModal, orderType.code]);

  return (
    <View
      style={[
        styles.typeContainer,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: enabled
            ? theme.colors.states.positiveXL
            : theme.colors.grey1,
        },
      ]}
    >
      <InputToggle
        testID="toggle-orderType"
        type="switch"
        isToggled={enabled}
        onToggle={onPressHandler}
      />
      <Text testID="text-name" style={styles.typeName}>
        {orderType.name}
      </Text>
      {enabled && (
        <ButtonIcon
          testID="btn-settings"
          type="neutralLight"
          icon="cog"
          onPress={onPressOpenModal}
        />
      )}
    </View>
  );
};

export default OrderTypeRow;

const styles = StyleSheet.create({
  typeContainer: {
    marginBottom: 10,
    paddingRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  typeName: {
    flex: 1,
    marginLeft: 10,
    marginRight: 'auto',
  },
});
