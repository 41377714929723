import React from 'react';
import { PrinterProfileType } from '@oolio-group/domain';
import { View } from 'react-native';
import { CreateOrUpdatePrintingOptions } from '@oolio-group/domain';
import styles from '../../Devices.styles';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import theme from '../../../../../../../common/default-theme';

export interface Props {
  index: number;
  preference: PreferencesByPrinterProfile;
  printerOptions: Array<{ value: string; label: string }>;
  printerTemplateOptions: Array<{ value: string; label: string }>;
  printerProfileType: PrinterProfileType;
  onChange: (
    attr: 'printer' | 'template',
    preference: PreferencesByPrinterProfile,
    printerOrTemplateId: string,
  ) => void;
}

export type PreferencesByPrinterProfile = Partial<
  CreateOrUpdatePrintingOptions & { name: string }
>;

const DevicePrintingRow: React.FC<Props> = ({
  preference,
  printerOptions,
  printerTemplateOptions,
  onChange,
  index,
}) => {
  return (
    <View key={index} testID="row-profile" style={theme.tables.row}>
      <InputText
        testID="input-profile"
        editable={false}
        value={preference.name}
        placeholder={preference.name || ''}
        containerStyle={styles.cellPrinting}
      />
      <TreatPicker
        testID="select-printer"
        options={printerOptions}
        selectedValue={preference.printer}
        onValueChange={onChange.bind(undefined, 'printer', preference)}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={[styles.cellPrinting, { marginHorizontal: 10 }]}
      />
      <TreatPicker
        testID="select-template"
        options={printerTemplateOptions}
        selectedValue={preference.template}
        onValueChange={onChange.bind(undefined, 'template', preference)}
        containerStyle={styles.cellPrinting}
      />
    </View>
  );
};

export default DevicePrintingRow;
