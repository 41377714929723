import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import styles from '../GridItem.styles';
import theme from '../../../../../../../common/default-theme';
import Icon from '../../../../../../../components/Icon/Icon';

export interface PaginationBtnProps {
  testID?: string;
  disabled?: boolean;
  type: 'next' | 'prev' | 'done';
  scrollDirection: 'vertical' | 'horizontal';
  onPress?: () => void;
}

export const PaginationButton: React.FC<PaginationBtnProps> = (
  props: PaginationBtnProps,
) => (
  <TouchableOpacity
    style={[
      styles.btnPagination,
      {
        backgroundColor: !props.disabled
          ? theme.colors.white
          : theme.colors.grey2,
      },
    ]}
    onPress={props.onPress}
    disabled={props.disabled}
  >
    <Icon
      name={
        props.type === 'done'
          ? 'check'
          : props.type === 'prev'
          ? props.scrollDirection === 'vertical'
            ? 'angle-up'
            : 'angle-left'
          : props.scrollDirection === 'vertical'
          ? 'angle-down'
          : 'angle-right'
      }
      color={!props.disabled ? theme.colors.dark : theme.colors.grey4}
    />
  </TouchableOpacity>
);

export default PaginationButton;
