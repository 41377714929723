import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  cellStore: {
    flex: 1,
    paddingLeft: 12,
  },
  cellMapLocation: {
    width: 200,
    marginHorizontal: 10,
  },
  headerMapLocation: {
    width: 200,
    marginLeft: 'auto',
    marginRight: 18,
  },
  settingContainer: {
    paddingRight: 4,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  actionText: {
    flex: 1,
  },
});

export default styles;
