import React from 'react';
import { ScannerProps } from './BarcodeScanner';

const BarcodeScanner: React.FC<ScannerProps> = ({}: ScannerProps) => {
  //TODO: Barcode Scanner Integration

  // const onBarCodeRead = useCallback(
  //   (data: string, type: string) => {
  //     onBarcodeDetected({ data, type });
  //   },
  //   [onBarcodeDetected],
  // );

  return null;
};

export default BarcodeScanner;
