import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Icon from '../../../../../components/Icon/Icon';
import theme from '../../../../../common/default-theme';
import styles from './ComboTypesModal.styles';
import { translate } from '@oolio-group/localization';
import { ComboType } from '@oolio-group/domain';
import { CreateProductForm } from './CreateProductModal';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';

interface ComboTypeModalProp {
  onSelect: (comboType: ComboType, productInput: CreateProductForm) => void;
  productInput: CreateProductForm;
  closeModal: () => void;
}

export const ComboTypeModal: React.FC<ComboTypeModalProp> = ({
  onSelect,
  productInput,
  closeModal,
}) => {
  const comboTypeOptions = [
    {
      title: translate('comboOptions.regularTitle'),
      description: translate('comboOptions.regularDescription'),
      value: ComboType.REGULAR,
    },
    {
      title: translate('comboOptions.fixedTitle'),
      description: translate('comboOptions.fixedDescription'),
      value: ComboType.FIXED,
      disabled: true,
    },
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.modalTitle}>
        {translate('comboOptions.modalTitle')}
      </Text>
      {comboTypeOptions.map((option, index) => (
        <TouchableOpacity
          style={[
            styles.comboRow,
            option.disabled && styles.comboTypeSelectionDisabled,
          ]}
          onPress={() => onSelect(option.value, productInput)}
          disabled={option.disabled}
          key={index}
        >
          <View style={styles.text}>
            <Text style={styles.title}>{option.title}</Text>
            <Text style={styles.description}>{option.description}</Text>
          </View>
          <View style={styles.iconContainer}>
            <Icon size={24} color={theme.colors.dark} name={'angle-right'} />
          </View>
        </TouchableOpacity>
      ))}
      <TreatButton
        testID="btn-cancel"
        label={translate('button.cancel')}
        type="cancel"
        onPress={closeModal}
        containerStyle={styles.buttonAction}
      />
    </View>
  );
};
