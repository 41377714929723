import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { IntegrationApps } from '@oolio-group/domain';
import { useClipboard } from '@react-native-clipboard/clipboard';
import { useNotification } from '../../../../../../hooks/Notification';
import styles from './Settings.styles';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface IntegrationWebhookInfoModalProps {
  webhookUrl: string;
  storeId: string;
  storeName: string;
  app?: string;
}

const IntegrationWebhookInfoModal: React.FC<
  IntegrationWebhookInfoModalProps
> = ({ webhookUrl, storeId, storeName, app }) => {
  const [, setString] = useClipboard();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const isOOM = app && app === IntegrationApps.OOM;

  const onPressCopy = useCallback(
    (text: string, message: string) => {
      try {
        setString(text);
        showNotification({ message, success: true });
      } catch (error) {
        showNotification({
          message: translate('common.copyUnsuccessful'),
          error: true,
        });
      }
    },
    [setString, showNotification, translate],
  );

  return (
    <TreatModal
      size="large"
      title={storeName}
      onConfirm={{ label: translate('button.dismiss'), action: closeModal }}
    >
      <View style={styles.infoRow}>
        <InputText
          testID="input-url"
          editable={false}
          title={translate('backOfficeFeatures.webhookURL')}
          value={webhookUrl}
          placeholder={webhookUrl}
          containerStyle={styles.infoInput}
        />
        <ButtonIcon
          testID="btn-copyUrl"
          type="neutralLight"
          size={44}
          icon="copy"
          onPress={() =>
            onPressCopy(
              webhookUrl,
              translate('backOfficeFeatures.webhookURLCopied'),
            )
          }
        />
      </View>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={[styles.infoRow, { marginTop: 20 }]}>
        <InputText
          testID="input-storeId"
          editable={false}
          title={
            isOOM
              ? translate('backOfficeFeatures.posLocationID')
              : translate('backOfficeFeatures.storeID')
          }
          value={storeId}
          placeholder={storeId}
          containerStyle={styles.infoInput}
        />
        <ButtonIcon
          testID="btn-copyStoreId"
          type="neutralLight"
          size={44}
          icon="copy"
          onPress={() =>
            onPressCopy(storeId, translate('backOfficeFeatures.storeIDCopied'))
          }
        />
      </View>
    </TreatModal>
  );
};

export default IntegrationWebhookInfoModal;
