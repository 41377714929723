import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 16,
    paddingTop: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupPaid: {
    marginTop: 16,
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: theme.colors.grey3,
  },
  textMinor: {
    lineHeight: 16,
    color: theme.colors.grey6,
  },
  textMajor: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: theme.fonts.medium,
  },
  textPayment: {
    fontFamily: theme.fonts.medium,
  },
  btnFailedPayment: {
    marginLeft: 6,
  },
});

export default styles;
