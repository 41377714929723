import React from 'react';
import { Platform } from 'react-native';
import { IntercomProvider as Provider } from 'react-use-intercom';
import CloseIntercom from '../../components/CloseIntercom/CloseIntercom';
import { closeButtonVisibilityController } from './intercomObservables';
import useBehaviorSubjectState from '../app/useSubjectState';

export interface IntercomProviderProps {
  children: React.ReactNode;
}

const INTERCOM_APP_ID = process.env['REACT_APP_INTERCOM_APP_ID'] || '';

export const IntercomProvider: React.FC<IntercomProviderProps> = ({
  children,
}: IntercomProviderProps) => {
  const { value: isVisible, setValue: setVisible } =
    useBehaviorSubjectState<boolean>(closeButtonVisibilityController);

  const onShow = () => {
    setVisible(true);
  };
  if (Platform.OS !== 'web') return <>{children}</>;
  return (
    <>
      <Provider
        appId={INTERCOM_APP_ID}
        autoBoot
        shouldInitialize
        autoBootProps={{ verticalPadding: 40 }}
        onShow={onShow}
      >
        {children}
        {isVisible && <CloseIntercom />}
      </Provider>
    </>
  );
};
