import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Order, OrderStatus, RejectionReason } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { analyticsService } from '../../../../../analytics/AnalyticsService';
import styles from './OnlineOrderSidePanel.styles';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import { RejectOrderModal } from '../../../../../components/Modals/CancelOrder/RejectOrderModal';

interface OnlineOrderActionsProps {
  order: Order;
  disableBtn?: boolean;
  onPressComplete: (id: string) => void;
  onPressAccept: (ids: string[]) => void;
  onPressReject: (
    id: string,
    reason: RejectionReason,
    description?: string,
  ) => void;
  onPressReprintDocket: (id: string) => void;
  onOrderView?: (orderId: string) => void;
}

const OnlineOrderActions: React.FC<OnlineOrderActionsProps> = ({
  order,
  disableBtn,
  onPressComplete,
  onPressAccept,
  onPressReject,
  onPressReprintDocket,
  onOrderView,
}) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const rejectOrder = useCallback(
    (reason: RejectionReason, description?: string) => {
      analyticsService.capture('oo_order_event', {
        event: 'Reject Order',
      });
      onPressReject(order.id, reason, description);
      closeModal();
    },
    [onPressReject, order, closeModal],
  );

  const showRejectionModal = useCallback(() => {
    showModal(
      <RejectOrderModal order={order as Order} onReject={rejectOrder} />,
    );
  }, [showModal, rejectOrder, order]);

  const onPressOrderBtn = useCallback(() => {
    analyticsService.capture('oo_order_event', {
      event: 'Accept Order',
    });
    onPressAccept([order.id]);
  }, [onPressAccept, order.id]);

  const onPressCompleteBtn = useCallback(() => {
    analyticsService.capture('oo_order_event', {
      event: 'Complete Order',
    });
    onPressComplete(order.id);
  }, [onPressComplete, order.id]);

  const onPressReprintDocketBtn = useCallback(() => {
    analyticsService.capture('oo_order_event', {
      event: 'Reprint Docket',
    });
    onPressReprintDocket(order.id);
  }, [onPressReprintDocket, order.id]);

  const onPressEdit = useCallback(() => {
    analyticsService.capture('oo_order_event', {
      event: 'Edit Order',
    });
    onOrderView && onOrderView(order.id);
  }, [onOrderView, order]);

  const onPressOrderViewBtn = useCallback(() => {
    analyticsService.capture('oo_order_event', {
      event: 'View Order',
    });
    onOrderView && onOrderView(order?.id);
  }, [onOrderView, order]);

  return (
    <View>
      {order.status == OrderStatus.CREATED ? (
        <View style={styles.actions}>
          <TreatButton
            key={0}
            testID={'reject-cart-btn'}
            label={translate('onlineOrders.reject')}
            disabled={disableBtn}
            onPress={showRejectionModal}
            height={60}
            type="negativeLight"
            containerStyle={styles.btnAction}
          />
          <TreatButton
            key={1}
            testID={'accept-cart-btn'}
            label={translate('onlineOrders.accept')}
            disabled={disableBtn}
            onPress={onPressOrderBtn}
            height={60}
            type="positive"
            containerStyle={styles.btnAction}
          />
        </View>
      ) : order.status == OrderStatus.IN_PROGRESS ? (
        <>
          {order.amountDue === 0 ? (
            <View style={styles.actions}>
              <TreatButton
                key={2}
                testID={'complete-cart-btn'}
                label={translate('onlineOrders.markAsCompleted')}
                disabled={disableBtn}
                onPress={onPressCompleteBtn}
                height={60}
                type="positive"
                // eslint-disable-next-line react-native/no-inline-styles
                containerStyle={[styles.btnFull, { marginBottom: 10 }]}
              />
            </View>
          ) : null}
          {(!!order.amountDue && order.amountDue > 0 && (
            <View style={styles.actions}>
              <TreatButton
                key={3}
                testID={'btn-edit-order'}
                label={translate('order.edit')}
                disabled={disableBtn}
                onPress={onPressEdit}
                height={60}
                type="positiveLight"
                containerStyle={styles.btnAction}
              />
              <TreatButton
                key={3}
                testID={'reprint-cart-btn'}
                label={translate('onlineOrders.rePrintDocket')}
                disabled={disableBtn}
                onPress={onPressReprintDocketBtn}
                height={60}
                type="neutralLight"
                containerStyle={styles.btnAction}
              />
            </View>
          )) || (
            <View style={styles.actions}>
              <TreatButton
                key={3}
                testID={'reprint-cart-btn'}
                label={translate('onlineOrders.rePrintDocket')}
                disabled={disableBtn}
                onPress={onPressReprintDocketBtn}
                height={60}
                type="neutralLight"
                containerStyle={styles.btnFull}
              />
            </View>
          )}
        </>
      ) : (
        <View style={styles.actions}>
          <TreatButton
            key={3}
            testID={'pay-now-cart-btn'}
            label={translate('onlineOrders.viewOrder')}
            disabled={disableBtn}
            onPress={onPressOrderViewBtn}
            height={60}
            type="positiveLight"
            containerStyle={styles.btnAction}
          />
          <TreatButton
            key={3}
            testID={'reprint-cart-btn'}
            label={translate('onlineOrders.rePrintDocket')}
            disabled={disableBtn}
            onPress={onPressReprintDocketBtn}
            height={60}
            type="neutralLight"
            containerStyle={styles.btnAction}
          />
        </View>
      )}
    </View>
  );
};

export default OnlineOrderActions;
