/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState } from 'react';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import { Venue, CreateVenueInput, CreateStoreInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';

enum LocationType {
  Venue = 'Venue',
  Store = 'Store',
}

interface CreateLocationModalProps {
  venues: Venue[];
  onCreateVenue: (form: CreateVenueInput) => void;
  onCreateStore: (form: CreateStoreInput) => void;
}

const CreateLocationModal: React.FC<CreateLocationModalProps> = ({
  venues,
  onCreateVenue,
  onCreateStore,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [type, setType] = useState<LocationType>(LocationType.Venue);
  const [venueForm, setVenueForm] = useState({} as CreateVenueInput);
  const [storeForm, setStoreForm] = useState({} as CreateStoreInput);

  const onChangeVenue = useCallback((prop: string, value: string) => {
    if (prop === 'name') {
      setVenueForm((venueForm: CreateVenueInput) => ({
        ...venueForm,
        [prop]: value,
        isActive: true,
      }));
    } else {
      setVenueForm((venueForm: CreateVenueInput) => ({
        ...venueForm,
        copyFrom: {
          ...venueForm.copyFrom,
          [prop]: value,
        },
        isActive: true,
      }));
    }
  }, []);

  const onChangeStore = useCallback((prop: string, value: string) => {
    setStoreForm((storeForm: CreateStoreInput) => ({
      ...storeForm,
      [prop]: value,
    }));
  }, []);

  const handleLocationCreation = useCallback(() => {
    if (type === LocationType.Venue) {
      if (!venueForm.name) {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.VenueNameIsRequired'),
        });
      } else {
        onCreateVenue(venueForm);
        closeModal();
      }
    } else {
      if (!storeForm.name) {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.storeNameIsRequired'),
        });
      } else if (!storeForm.venue) {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.venueIsRequired'),
        });
      } else {
        onCreateStore(storeForm);
        closeModal();
      }
    }
  }, [
    closeModal,
    onCreateStore,
    onCreateVenue,
    showNotification,
    storeForm,
    translate,
    type,
    venueForm,
  ]);

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <TreatModal
        type="positive"
        title={`Create New ${type}`}
        onConfirm={{ label: `Create ${type}`, action: handleLocationCreation }}
        onDismiss={{ action: closeModal }}
      >
        <TreatPicker
          testID="select-type"
          title="Select Type"
          options={Object.values(LocationType).map(value => ({
            value: value,
            label: value,
          }))}
          selectedValue={type}
          onValueChange={(value: LocationType) => {
            setType(value);
            if (value === LocationType.Store) {
              setStoreForm({ name: '', venue: venues[0].id });
            }
          }}
        />
        {type === LocationType.Store && (
          <TreatPicker
            testID="select-venue"
            title="Select Venue"
            options={venues.map(venue => ({
              value: venue.id,
              label: venue.name,
            }))}
            selectedValue={storeForm.venue}
            onValueChange={onChangeStore.bind(null, 'venue')}
            containerStyle={{ marginTop: 20 }}
          />
        )}
        <InputText
          title={`${type} Name`}
          testID="location-name"
          placeholder="Enter name..."
          onChangeText={
            type === LocationType.Venue
              ? onChangeVenue.bind(null, 'name')
              : onChangeStore.bind(null, 'name')
          }
          containerStyle={{ marginTop: 20 }}
        />
      </TreatModal>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, alignContent: 'center', justifyContent: 'center' },
});

export default CreateLocationModal;
