import React from 'react';
import { View } from 'react-native';
import { Product, ProductPricingInput } from '@oolio-group/domain';
import { StackActions, useNavigation } from '@react-navigation/native';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import theme from '../../../../../../common/default-theme';
import styles from '../Variations.styles';

interface VariationRowProps {
  variantId: string;
  product: Product;
  pricings: ProductPricingInput;
  currencySymbol: string;
  onChangeProductDetails: (
    id: string,
    prop: string,
    value: string | boolean,
  ) => void;
  onChangeProductPricings: (id: string, prop: string, value: string) => void;
  onDeleteProduct: (id: string, name: string) => void;
}

export const VariationRow: React.FC<VariationRowProps> = ({
  variantId,
  product,
  pricings,
  currencySymbol,
  onChangeProductDetails,
  onChangeProductPricings,
  onDeleteProduct,
}: VariationRowProps) => {
  const costPrice = pricings?.costPrice?.amount;
  const selPrice = pricings?.sellingPrice?.amount;
  const isDefault = product?.isDefault || false;

  const navigation = useNavigation();

  const onEditVariant = () => {
    navigation.dispatch(
      StackActions.push('ProductSettings', {
        productId: variantId,
        isVariant: true,
        isVariantProduct: undefined,
        screen: 'Variants',
      }),
    );
    navigation.navigate('ProductSettings', {
      productId: product.id,
      title: product.name,
      isVariant: undefined,
      isVariantProduct: true,
      screen: 'General',
    });
  };

  return (
    <View style={theme.tables.row}>
      <InputToggle
        testID="checkbox-default"
        type="radio"
        isToggled={isDefault}
        onToggle={onChangeProductDetails.bind(
          null,
          product.id,
          'isDefault',
          !isDefault,
        )}
      />
      <InputText
        testID="input-name"
        value={product.name}
        placeholder="Variation"
        onChangeText={value =>
          onChangeProductDetails(product.id, 'name', value)
        }
        containerStyle={styles.cellVariant}
      />
      <InputText
        testID="input-costPrice"
        label={currencySymbol}
        value={
          costPrice?.toString() || costPrice === 0 ? costPrice?.toString() : ''
        }
        placeholder="0.00"
        onChangeText={value =>
          onChangeProductPricings(product.id, 'costPrice', value)
        }
        alignText="right"
        containerStyle={styles.cellPrice}
      />
      <InputText
        testID="input-sellingPrice"
        label={currencySymbol}
        value={
          selPrice?.toString() || selPrice === 0 ? selPrice?.toString() : ''
        }
        placeholder="0.00"
        onChangeText={value =>
          onChangeProductPricings(product.id, 'sellingPrice', value)
        }
        alignText="right"
        containerStyle={styles.cellPrice}
      />
      <ButtonIcon
        testID="btn-editVariant"
        type="neutralLight"
        icon="pen"
        onPress={onEditVariant}
        containerStyle={styles.cellEdit}
      />
      <ButtonIcon
        testID="btn-deleteVariant"
        type="negativeLight"
        icon="trash-alt"
        onPress={() => onDeleteProduct(product.id, product.name)}
      />
    </View>
  );
};
