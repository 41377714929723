import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import theme from '../../../../common/default-theme';
import styles from '../CartCourse/CartCourse.styles';
import Icon from '../../../Icon/Icon';

export interface CartComboProductsProps {
  id: string;
  name: string;
  isCollapsed?: boolean;
  onCollapse: (id: string, status: boolean) => void;
  children?: React.ReactNode;
}

const CartComboProducts: React.FC<CartComboProductsProps> = ({
  id,
  name,
  isCollapsed,
  children,
  onCollapse,
}) => {
  const handleCollapse = useCallback(() => {
    onCollapse(id, !isCollapsed);
  }, [id, isCollapsed, onCollapse]);

  const foregroundColor = theme.colors.black;
  return (
    <View>
      <View style={styles.parent}>
        <TouchableOpacity testID="btn-toggleComboProducts" style={styles.name}>
          <Text
            testID="combo-name"
            style={[
              styles.nameText,
              // eslint-disable-next-line react-native/no-inline-styles
              { color: foregroundColor, textTransform: 'none' },
            ]}
          >
            {name}
          </Text>
          <TouchableOpacity
            testID="toggle-combo-collapse"
            onPress={handleCollapse}
          >
            <Icon
              size={20}
              name={isCollapsed ? 'angle-down' : 'angle-up'}
              color={foregroundColor}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      </View>
      {!isCollapsed && <View>{children}</View>}
    </View>
  );
};

export default CartComboProducts;
