import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    width: 480,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    marginRight: 44,
    textAlign: 'center',
    color: theme.colors.black,
    fontFamily: theme.fonts.medium,
  },
  modal: {
    width: 480,
    maxHeight: 530,
    borderRadius: 6,
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    ...theme.shadow.strong,
  },
  loaderContainer: {
    marginTop: 100,
    height: 50,
    overflow: 'hidden',
  },
  loaderText: {
    marginTop: 100,
    height: 50,
    overflow: 'hidden',
  },
  row: {
    minHeight: 62,
    borderColor: theme.colors.grey3,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 10,
    justifyContent: 'center',
  },
  upperRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  bottomRow: {
    alignItems: 'center',
    paddingLeft: 60,
    paddingRight: 20,
  },
  subRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  infoTitle: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  infoPositive: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.states.neutral,
    fontFamily: theme.fonts.medium,
  },
  infoNegative: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.states.negative,
    fontFamily: theme.fonts.medium,
  },
  formInput: {
    width: 110,
  },
  inputError: {
    width: 110,
    borderColor: theme.colors.states.negative,
    borderWidth: 1,
    borderRadius: 6,
  },
  summary: {
    height: 62,
    backgroundColor: theme.colors.grey1,
    paddingVertical: 20,
    paddingHorizontal: 32,
    borderWidth: 1,
    borderColor: theme.colors.grey2,
  },
  summaryRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 2,
  },
  messageContainer: {
    margin: 20,
    fontSize: 14,
  },
  summaryText: {
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  btn: {
    padding: 20,
  },
  btnText: {
    color: theme.colors.grey6,
    textTransform: 'uppercase',
  },
  textButton: {
    color: theme.colors.black,
    textTransform: 'capitalize',
  },
  closeBtn: {
    position: 'absolute',
    left: 10,
  },
  paymentTypeRowContainer: {
    paddingBottom: 20,
  },
  paymentIconSize: {
    width: 30,
    marginRight: 10,
  },
  paymentSubText: {
    flex: 1,
  },
});

// Height for the flat-list section, without specifying it scroll doesn't work as expected
export const getPaymentSectionHeight = (
  paymentsCount = 0,
  isRefundByAmount: boolean,
) => {
  if (isRefundByAmount) {
    return paymentsCount > 2 ? 350 : 280;
  } else {
    return paymentsCount > 2 ? 280 : 200;
  }
};
