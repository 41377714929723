import React, { useCallback, useMemo, useRef, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { translate } from '@oolio-group/localization';
import { PageItemAction } from '../../types';
import styles from './GridItem.styles';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';
import Item from './CommonItem';

interface PageEmptyItemProp {
  onLongPress: () => void;
  onSelectOption: (option: PageItemAction) => void;
}

const PageEmptyItem: React.FC<PageEmptyItemProp> = ({
  onLongPress,
  onSelectOption,
}) => {
  const [showOption, setShowOption] = useState(false);
  const selectedOptionRef = useRef<PageItemAction | undefined>();

  const onToggleOptions = () => {
    setShowOption(!showOption);
  };

  const onRequestClosePopover = () => {
    setShowOption(false);
  };

  const options = useMemo(() => {
    return [
      {
        label: translate('menus.existingProduct'),
        name: PageItemAction.ADD_EXISTING_PRODUCT,
      },
      {
        label: translate('menus.existingPage'),
        name: PageItemAction.ADD_EXISTING_PAGE,
      },
      {
        label: translate('menus.newPage'),
        name: PageItemAction.ADD_NEW_PAGE,
      },
      {
        label: translate('menus.addFromCategory'),
        name: PageItemAction.ADD_FROM_CATEGORY,
      },
    ];
  }, []);

  const onSelectPageOption = useCallback(option => {
    selectedOptionRef.current = option.name as PageItemAction;
    onRequestClosePopover();
  }, []);

  const onClosePopoverCompleted = useCallback(() => {
    onSelectOption(selectedOptionRef.current as PageItemAction);
  }, [onSelectOption]);

  const onOpenPopoverStart = useCallback(() => {
    selectedOptionRef.current = undefined;
  }, []);

  return (
    <Popover
      placement={PopoverPlacement.AUTO}
      popoverStyle={styles.popupContainer}
      from={
        <Item
          onLongPress={onLongPress}
          onPress={onToggleOptions}
          color={theme.colors.grey1}
          isEmptyTile
        />
      }
      backgroundStyle={styles.popupBackground}
      isVisible={showOption}
      onRequestClose={onRequestClosePopover}
      onCloseComplete={onClosePopoverCompleted}
      onOpenStart={onOpenPopoverStart}
    >
      <View style={styles.popupOptions}>
        {options.map((option, i: number) => (
          <TouchableOpacity
            key={i}
            testID="option-item"
            style={styles.popupOption}
            onPress={() => onSelectPageOption(option)}
          >
            <Text style={styles.popupLabel}>{option.label}</Text>
            <Icon name="angle-right" size={24} color={theme.colors.grey4} />
          </TouchableOpacity>
        ))}
      </View>
    </Popover>
  );
};

export default PageEmptyItem;
