import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { View, Text } from 'react-native';
import { useLocalization, useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { keyBy, difference, sortBy } from 'lodash';
import { useCategories } from '../../../../hooks/app/categories/useCategories';
import {
  EntityType,
  CreateOrUpdateCategoryInput,
  Item,
  AlternateName,
  Product,
  Variant,
  Category,
} from '@oolio-group/domain';
import { useProducts } from '../../../../hooks/app/products/useProducts';
import {
  productsFragment,
  variantsFragment,
} from '../../../../hooks/app/categories/graphql';
import { useVariants } from '../../../../hooks/app/variants/useVariants';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { getLocaleEntity } from '@oolio-group/client-utils';
import TranslationModal from '../../../../components/Modals/TranslationModal/TranslationModal';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import InputText from '../../../../components/Shared/Inputs/InputText';
import Identifier from '../../../../components/Shared/Identifier/Identifier';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import SelectSearch, {
  OptionsType,
} from '../../../../components/Shared/Select/SelectSearch';
import theme from '../../../../common/default-theme';
import styles from './Categories.styles';

interface CategoryDetailType {
  name: string;
  assignedItemIds: string[];
  alternateNames: AlternateName[];
}

const CategoryDetail: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { locale } = useLocalization();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<CategoryDetailType>({
    defaultValues: {
      name: '',
      assignedItemIds: [],
      alternateNames: [],
    },
  });

  const assignedItemIds = useWatch({
    name: 'assignedItemIds',
    control,
    defaultValue: [],
  });
  const originalAssignedItemIdsRef = useRef<string[]>([]);

  const { categoryId } = route?.params as {
    categoryId: string;
    title: string;
  };

  const {
    error,
    getCategory,
    loading: getCategoryLoading,
    deleteCategory,
    createOrUpdateCategory,
    category,
  } = useCategories();

  const {
    products,
    getAllProducts,
    loading: getProductsLoading,
    updateCachedProducts,
  } = useProducts(undefined, productsFragment, undefined, 'cache-first');

  const {
    variants,
    getAllVariants,
    loading: variantLoading,
    updateCachedVariants,
  } = useVariants(undefined, variantsFragment, undefined, 'cache-first');

  const variantProductMaps = useMemo(
    () =>
      keyBy(
        Object.values(variants).flatMap(variant => variant.products),
        'id',
      ),
    [variants],
  );

  const handleDeleteItem = (item: Item) => {
    setValue(
      'assignedItemIds',
      assignedItemIds.filter(productId => productId !== item.id),
      { shouldDirty: true },
    );
  };

  const handleConfirmDeleteCategory = useCallback(
    async (categoryId: string) => {
      const response = await deleteCategory(categoryId);
      // update the cached of product and categories associations
      if (response) {
        const deletedProductIds = category?.products?.map(
          (x: Product) => x.id,
        ) as string[];

        if (deletedProductIds.length) {
          const updatedProducts = Object.values(products).map(product => {
            return {
              ...product,
              category: deletedProductIds.includes(product.id)
                ? (null as unknown as Category)
                : product.category,
            };
          });
          updateCachedProducts(updatedProducts);
        }

        const deletedVariantIds = category?.variants?.map(
          (x: Variant) => x.id,
        ) as string[];
        if (deletedVariantIds.length) {
          const updateVariants = Object.values(variants).map(variant => {
            return {
              ...variant,
              category: deletedVariantIds.includes(variant.id)
                ? (null as unknown as Category)
                : variant.category,
            };
          });

          updateCachedVariants(updateVariants);
        }
      }
    },
    [
      category?.products,
      category?.variants,
      deleteCategory,
      products,
      updateCachedProducts,
      updateCachedVariants,
      variants,
    ],
  );

  const handleDeleteCategory = useCallback(
    (categoryId: string) => {
      showModal(
        <ConfirmationDialog
          title={
            translate('dialog.deleteTitle') +
            ' ' +
            translate('backOfficeProducts.category')
          }
          message={translate('dialog.deleteConfirmation', {
            label: category?.name,
          })}
          onConfirm={() => {
            handleConfirmDeleteCategory(categoryId);
            closeModal();
          }}
        />,
      );
    },
    [
      category?.name,
      closeModal,
      handleConfirmDeleteCategory,
      showModal,
      translate,
    ],
  );

  const productAndVariants = useMemo<OptionsType[]>(() => {
    const normalProductIds = difference(
      Object.keys(products),
      Object.keys(variantProductMaps),
    );
    const allProducts = normalProductIds.map(productId => ({
      title: getLocaleEntity(products[productId], locale?.languageTag)?.name,
      value: productId,
      subtitle: translate('backOfficeProductsSummary.productName'),
      type: EntityType.Product,
    }));

    const allVariants = Object.values(variants).map(variant => ({
      title: variant.name,
      value: variant.id,
      subtitle: translate('backOfficeProductsSummary.productName'),
      type: EntityType.Variant,
    }));
    return allProducts.concat(allVariants);
  }, [locale?.languageTag, products, translate, variantProductMaps, variants]);

  const productAndVariantMaps = useMemo(
    () => keyBy(productAndVariants, 'value'),
    [productAndVariants],
  );

  const sortedAssignedItems = useMemo<Item[]>(() => {
    return sortBy(
      assignedItemIds.map(itemId => ({
        id: itemId,
        name: productAndVariantMaps[itemId]?.title,
      })),
      product => product.name,
    );
  }, [assignedItemIds, productAndVariantMaps]);

  const removeItemIds = useMemo(() => {
    const originalAssignedItems = originalAssignedItemIdsRef.current;
    return difference(originalAssignedItems, assignedItemIds) as string[];
  }, [assignedItemIds]);

  const addItems = useMemo(() => {
    const originalAssignedItems = originalAssignedItemIdsRef.current;
    const addItemIds = difference(assignedItemIds, originalAssignedItems);
    return addItemIds.map(id => ({
      id,
      entityType: productAndVariantMaps[id].type,
    })) as CreateOrUpdateCategoryInput['addItems'];
  }, [assignedItemIds, productAndVariantMaps]);

  const getEntityCategory = useCallback(
    (entityId: string, currentCategory?: Category) => {
      if (addItems?.find(item => item.id == entityId))
        return {
          __typename: 'Category',
          id: categoryId,
        } as unknown as Category;
      if (removeItemIds.includes(entityId)) return null as unknown as Category;

      return currentCategory;
    },
    [addItems, categoryId, removeItemIds],
  );
  const handleSaveCategory = useCallback(
    async (values: CategoryDetailType) => {
      const { name, alternateNames } = values;
      if (!name)
        return showNotification({
          error: true,
          message: translate('backOfficeProducts.emptyCategoryNameValidation'),
        });

      const response = await createOrUpdateCategory({
        id: category?.id,
        name,
        addItems,
        removeItemIds,
        alternateNames,
      });
      if (response) {
        // update the cached of product and category associations
        const updatedProducts = Object.values(products).map(product => {
          return {
            ...product,
            category: getEntityCategory(product.id, product.category),
          };
        });
        updateCachedProducts(updatedProducts);

        const updatedVariants = Object.values(variants).map(variant => {
          return {
            ...variant,
            category: getEntityCategory(variant.id, variant.category),
          };
        });
        updateCachedVariants(updatedVariants);
      }
    },
    [
      addItems,
      category?.id,
      createOrUpdateCategory,
      getEntityCategory,
      products,
      removeItemIds,
      showNotification,
      translate,
      updateCachedProducts,
      updateCachedVariants,
      variants,
    ],
  );

  useEffect(() => {
    if (!categoryId) return;
    getCategory(categoryId);
  }, [categoryId, getCategory]);

  useEffect(() => {
    getAllProducts();
    getAllVariants();
  }, [getAllProducts, getAllVariants]);

  useEffect(() => {
    const tabBarLabel = category?.name;
    if (!tabBarLabel) return;
    navigation.setOptions({ tabBarLabel });
  }, [category?.name, categoryId, navigation]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  useEffect(() => {
    if (!category) return;
    const { products = [], variants = [], name, alternateNames } = category;
    const assignedItemIds = [
      ...products.map(product => product.id),
      ...variants.map(variant => variant.id),
    ].sort();

    originalAssignedItemIdsRef.current = [...assignedItemIds];
    reset({ name, assignedItemIds, alternateNames }, { keepDirty: false });
  }, [category, reset, setValue]);

  const loading = getCategoryLoading || getProductsLoading || variantLoading;

  const onPressTranslate = useCallback(() => {
    showModal(
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TranslationModal
            loading={loading}
            onConfirm={value => {
              onChange(value);
              closeModal();
            }}
            onDismiss={closeModal}
            title={translate('backOfficeProducts.translateEntity', {
              entityName: category?.name || 'Category',
            })}
            value={value}
          />
        )}
        name="alternateNames"
      />,
    );
  }, [category?.name, closeModal, control, loading, showModal, translate]);

  const showSaveButton = Boolean(
    isDirty || addItems?.length || removeItemIds?.length,
  );

  return (
    <ScreenLayout
      loading={loading}
      title={`${category?.name || 'Category'} | Oolio`}
      onSave={handleSubmit(handleSaveCategory)}
      onDeleteDisabled={!category}
      onDelete={category && handleDeleteCategory.bind(null, category.id)}
      hideSaveButton={!showSaveButton}
    >
      <Section title="Details">
        <View style={theme.forms.row}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                testID="input-name"
                value={value}
                title={translate('form.name')}
                placeholder={translate('backOfficeProducts.categoryName')}
                onChangeText={onChange}
                containerStyle={theme.forms.inputFluid}
              />
            )}
            name="name"
          />
          <ButtonIcon
            icon="english-to-chinese"
            type="neutralLight"
            size={44}
            onPress={onPressTranslate}
            containerStyle={styles.btnTranslate}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeProducts.products')}>
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectSearch
              options={productAndVariants}
              selectedOptions={value}
              onChangeOption={onChange}
              placeholder={translate('backOfficeProducts.searchProductsByName')}
            />
          )}
          name="assignedItemIds"
        />
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeProducts.products')}
            </Text>
          </View>
          {sortedAssignedItems.length > 0 ? (
            <View>
              {sortedAssignedItems.map((item, i: number) => (
                <View key={i} style={theme.tables.row}>
                  <Identifier
                    entity="product"
                    containerStyles={styles.identifier}
                  />
                  <Text style={styles.cellProduct}>{item.name}</Text>
                  <ButtonIcon
                    icon="times"
                    type="negativeLight"
                    onPress={handleDeleteItem.bind(null, item)}
                  />
                </View>
              ))}
            </View>
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {translate('backOfficeProducts.noProductAssignedToCategory')}
              </Text>
            </View>
          )}
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default CategoryDetail;
