import React, { PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { StyleFn } from '@oolio-group/domain';
import { useFela } from 'react-fela';
import { useIsMounted } from './../../useIsMounted';
import { DEFAULT_GRADIENT } from './DefaultGradient';
import { GradientProps } from './GradientProps';

const Gradient: React.FC<PropsWithChildren<GradientProps>> = ({
  start = DEFAULT_GRADIENT.start,
  end = DEFAULT_GRADIENT.end,
  colors = DEFAULT_GRADIENT.colors,
  locations = DEFAULT_GRADIENT.locations,
  useAngle = DEFAULT_GRADIENT.useAngle,
  angle = DEFAULT_GRADIENT.angle,
  style,
  children,
}) => {
  const { css } = useFela();
  const isMounted = useIsMounted();

  const gradientStyle: StyleFn = () => ({
    backgroundImage: `linear-gradient(${getAngle()},${getColors()})`,
  });

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const measure = ({ nativeEvent }: any) => {
    isMounted() && setWidth(nativeEvent.layout.width);
    isMounted() && setHeight(nativeEvent.layout.height);
  };

  const getAngle = () => {
    if (useAngle) {
      return angle + 'deg';
    }
    const anglePoint =
      Math.atan2(width * (end.y - start.y), height * (end.x - start.x)) +
      Math.PI / 2;
    return anglePoint + 'rad';
  };

  const getColors = () =>
    colors
      .map((color, index) => {
        const location = locations[index];
        let locationStyle = '';
        if (location) {
          locationStyle = ' ' + location * 100 + '%';
        }
        return color + locationStyle;
      })
      .join(',');

  return (
    <View style={[style, css(gradientStyle)]} onLayout={measure}>
      {children}
    </View>
  );
};

export default Gradient;
