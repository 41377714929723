import React, { useState, useCallback, useRef } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './SelectFile.styles';
import { useTranslation } from '@oolio-group/localization';

export interface FilePickerProps {
  onClear?: () => void;
  onFail?: (error: string) => void;
  onComplete: (data: File) => void;
}

const SelectFile: React.FC<FilePickerProps> = ({
  onFail = () => undefined,
  onComplete,
}: FilePickerProps) => {
  const { translate } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();

  const onFileSelect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const file = event.target.files[0];
      if (file) {
        setFile(file);
        onComplete(file);
      } else {
        onFail('something went wrong');
      }
    },
    [onComplete, onFail],
  );

  const placeholderText =
    file && file?.name ? file.name : translate('form.selectFilePlaceholder');

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-change"
        onPress={() => {
          inputRef.current?.click();
        }}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ flex: 1 }}
      >
        <View style={styles.input}>
          <View style={styles.webInput}>
            <label onChange={event => onFileSelect(event)}>
              <input type="file" ref={inputRef} accept=".csv" />
            </label>
          </View>
          <Text style={styles.inputText}>{placeholderText}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default SelectFile;
