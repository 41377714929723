import processRequest from './processRequest';
import { addToQueue, shiftQueue, hasItemsInQueue } from './PrintQueue';

let workerIsRunning = false;
/**
 * This is not an actual worker class implementation, but a class with synchronous functions which runs
 * on the main JS thread.
 */
export default class AndroidWorker {
  onmessage = null;
  postMessage(payload) {
    if (workerIsRunning) {
      addToQueue(payload);
    } else {
      workerIsRunning = true;
      this.runWorker(payload);
    }
  }

  async runWorker(payload) {
    try {
      const message = JSON.parse(payload);
      const results = await processRequest(message);
      results?.forEach(result => {
        // send message
        this.onmessage(
          JSON.stringify({
            ...result,
            requestId: message.requestId,
            timestamp: Date.now(),
            action: message.action,
          }),
        );
      });
    } catch (error) {
      this.onmessage(
        JSON.stringify({
          status: 'ERROR',
          message: error.toString(),
          timestamp: Date.now(),
        }),
      );
    }
    if (hasItemsInQueue()) {
      /**
       * Runs the next item in the queue after 1s to settle down the printer.
       * TODO: This is a temporary solution, The EPSON sdk should be able to handle the queue.
       * Upgrading the sdk to latest will solve the issue with new printing architecture.
       * https://github.com/tr3v3r/react-native-esc-pos-printer/pull/139
       */
      setTimeout(() => {
        this.runWorker(shiftQueue());
        workerIsRunning = true;
      }, 1000);
    } else {
      workerIsRunning = false;
    }
  }
}
