import React, { createContext } from 'react';
import { useRoute } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { FeatureIDs } from '@oolio-group/domain';
import { Details } from './Details/Details';
import Devices from './Devices/Devices';
import { DeviceProfiles } from './DeviceProfiles/DeviceProfiles';
import Printers from './Printers/Printers';
import { KitchenDisplays } from './KitchenDisplay/KitchenDisplays';
import { Checkout } from './Checkout/Checkout';
import { ProfitMargins } from './ProfitMargins/ProfitMargins';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';

const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  storeId: string;
  venueId?: string;
  screen?: string;
}
export const StoreSettingsContext = createContext(
  {} as { params: RouteParams },
);

const StoreSettingsTabs: React.FC = () => {
  const route = useRoute();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const params = route.params as RouteParams;
  const isKitchenDisplayEnabled = isFeatureEnabled(
    FeatureIDs.KDS,
    params.venueId,
  );

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'VenuesStores'}
        />
      )}
      initialRouteName={params.screen ?? 'Details'}
    >
      <Tab.Screen name="Details" component={Details} initialParams={params} />
      <Tab.Screen name="Devices" component={Devices} initialParams={params} />
      <Tab.Screen
        name="DeviceProfiles"
        component={DeviceProfiles}
        initialParams={params}
        options={{ tabBarLabel: 'Device Profiles' }}
      />
      <Tab.Screen name="Printers" component={Printers} initialParams={params} />
      {isKitchenDisplayEnabled && (
        <Tab.Screen
          name="KitchenDisplays"
          component={KitchenDisplays}
          initialParams={params}
          options={{ tabBarLabel: 'Kitchen Displays' }}
        />
      )}
      <Tab.Screen name="Checkout" component={Checkout} initialParams={params} />
      <Tab.Screen
        name="ProfitMargins"
        component={ProfitMargins}
        options={{ tabBarLabel: 'Profit Margins' }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default StoreSettingsTabs;
