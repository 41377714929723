import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 480,
    overflow: 'hidden',
    alignSelf: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  header: {
    padding: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.states.positive,
  },
  // eslint-disable-next-line react-native/no-color-literals
  icon: {
    width: 50,
    height: 50,
    marginBottom: 20,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: 20,
    lineHeight: 24,
    color: theme.colors.white,
    fontFamily: theme.fonts.semibold,
  },
  subtext: {
    marginTop: 12,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
    opacity: 0.8,
  },
  content: {
    padding: 40,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  input: {
    flex: 1,
    marginRight: 10,
  },
  actions: {
    marginTop: 20,
  },
  actionsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  btnFluid: {
    flex: 1,
  },
});

export default styles;
