import {
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';

type timeoutHandlerType = {
  start(overrideTimeout?: number | undefined): void;
  stop(): void;
};

export function useTimeout(callback: Function) {
  const timeoutId = useRef<number | null>();
  const savedCallback = useRef(callback);
  const timeout = 0;

  const clear = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  //if callback is change change the ref
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const handler: timeoutHandlerType = useMemo(() => {
    return {
      start(overrideTimeout?: number) {
        clear();
        timeoutId.current = setTimeout(
          savedCallback.current,
          overrideTimeout ?? timeout,
        );
      },
      stop() {
        clear();
      },
    };
  }, [clear]);

  //clear on unmount without any deps
  useEffect(() => {
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handler;
}
