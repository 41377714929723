import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Printer, PrinterType } from '@oolio-group/domain';
import { translate } from '@oolio-group/localization';
import { usePrinterTroubleshooter } from '../../../../hooks/app/usePrinterTroubleshooter';
import { PRINT_ERROR_MESSAGES } from '../../../../types/Common';
import { capitalCase } from 'change-case';
import styles from '../SettingsScreen.styles';
import {
  SettingsIcon,
  SettingsAction,
} from '../../../../components/POS/SettingsOptions/SettingsOptions';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { unreachablePrintersVar } from '../../../../hooks/app/usePrinterStatusVar';
import { useReactiveVar } from '@apollo/client';
import { useIsFocused } from '@react-navigation/native';

export interface PrinterRowProps {
  printer: Printer;
  isSelected: boolean;
  onPress: (id: string) => void;
  onPressTestPrinter: (printer: Printer, template?: string) => void;
}

const PrinterRow: React.FC<PrinterRowProps> = ({
  printer,
  isSelected,
  onPress,
  onPressTestPrinter,
}) => {
  const unreachablePrinters = useReactiveVar(unreachablePrintersVar);

  const [error, setError] = useState<string>('');
  const [restartingPrinter, setRestartingPrinter] = useState<boolean>(false);
  const [verifyingPrinter, setVerifyingPrinter] = useState<boolean>(false);
  const isFocused = useIsFocused();
  const { restartPrinter, getPrinterStatuses, loading } =
    usePrinterTroubleshooter();

  const onPressVerifyPrinter = useCallback(
    async (id: string, ipAddress: string) => {
      setVerifyingPrinter(true);
      await getPrinterStatuses([id]);
      setVerifyingPrinter(false);
      analyticsService.capture('printer_status', {
        action: 'verify_status',
        location: 'Hardware settings',
        printerIp: ipAddress,
      });
    },

    [getPrinterStatuses],
  );

  const onPressRestartPrinter = useCallback(
    async (id: string, ipAddress: string) => {
      setRestartingPrinter(true);
      await restartPrinter(ipAddress);
      await getPrinterStatuses([id]);
      setRestartingPrinter(false);
      analyticsService.capture('printer_status', {
        action: 'restart_printer',
        location: 'Hardware settings',
        printerIp: ipAddress,
      });
    },
    [restartPrinter, getPrinterStatuses],
  );

  useEffect(() => {
    const badPrinter = unreachablePrinters[printer.id];

    if (badPrinter) {
      const error = badPrinter.responseCode
        ? `${PRINT_ERROR_MESSAGES[badPrinter.responseCode || '']} (${
            badPrinter.responseCode
          } ${badPrinter.responseStatus})`
        : 'Failed to connect - check network status or installed certificates';
      setError(error);
    } else {
      setError('');
    }
  }, [unreachablePrinters, printer.id, printer.ipAddress]);

  useEffect(() => {
    if (isFocused) {
      onPressTestPrinter(printer, 'line');
      getPrinterStatuses([printer.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const isBluetooth = printer.printerType === PrinterType.BLUETOOTH;

  return (
    <View>
      <SettingsIcon
        testID={`toggle-printer-${printer.id}`}
        title={printer.name}
        icon={`angle-${isSelected ? 'up' : 'down'}`}
        iconSecondary={isBluetooth ? 'bluetooth-b' : undefined}
        onPress={() => onPress(printer.id)}
        value={loading ? '' : !error && !isBluetooth ? 'OK' : undefined}
        error={!loading && !isBluetooth && error ? error : undefined}
        loading={loading}
      />
      {isSelected ? (
        <View style={styles.printers}>
          <SettingsIcon
            testID="printer-address"
            title={
              isBluetooth
                ? translate('backOfficeSettings.bdAddress')
                : translate('backOfficeSettings.ipAddress')
            }
            value={printer.ipAddress || printer.bdAddress || 'N/A'}
          />
          <SettingsIcon
            testID="printer-type"
            title={translate('backOfficeSettings.printerType')}
            value={printer.printerType || 'N/A'}
          />
          <SettingsIcon
            testID="printer-series"
            title={translate('backOfficeSettings.series')}
            value={`${capitalCase(
              printer.brand || '',
            )} ${printer.series?.replaceAll('_', ' ')}`}
          />
          <SettingsAction
            type="neutral"
            testID="btn-testPrinter"
            label={translate('button.testPrint')}
            onPress={() => onPressTestPrinter(printer)}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginTop: 6 }}
          />
          {printer.printerType === PrinterType.LAN ||
          printer.printerType === PrinterType.WIFI ? (
            <>
              <SettingsAction
                type="neutral"
                testID="btn-verifyPrinter"
                label={translate('button.verifyPrinterStatus')}
                onPress={() =>
                  onPressVerifyPrinter(
                    printer.id || '',
                    printer.ipAddress || '',
                  )
                }
                // eslint-disable-next-line react-native/no-inline-styles
                containerStyle={{ marginVertical: 4 }}
                loading={verifyingPrinter}
              />
              <SettingsAction
                type="negative"
                testID="btn-restartPrinter"
                label={translate('button.restartPrinter')}
                onPress={() =>
                  onPressRestartPrinter(
                    printer.id || '',
                    printer.ipAddress || '',
                  )
                }
                containerStyle={styles.printerAction}
                loading={restartingPrinter}
              />
            </>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

export default PrinterRow;
