import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  MoneyMovementReason,
  MoneyMovementReasonInput,
} from '@oolio-group/domain';
import { Operation } from '../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  CREATE_MONEY_MOVEMENTS_REASON,
  DELETE_MONEY_MOVEMENTS_REASON,
  GET_MONEY_MOVEMENTS_REASONS,
  UPDATE_MONEY_MOVEMENTS_REASONS,
} from '../../graphql/manageMoney';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';

export interface UseMoneyMovements {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  moneyMovementReasons: { [key: string]: MoneyMovementReason };
  createdMoneyMovementReasonId: string;
  updatedMoneyMovementReasonIds: string[];
  deletedMoneyMovementReason: boolean;
  getMoneyMovementReasons: () => void;
  createMoneyMovementReason: (
    moneyMovementReasonsInput: MoneyMovementReasonInput,
  ) => void;
  updateMoneyMovementReasons: (
    moneyMovementReasonsInput: MoneyMovementReasonInput[],
  ) => void;
  deleteMoneyMovementReason: (id: string) => void;
}

export interface Props {
  venueId?: string;
}

export const useMoneyMovements = (props?: Props): UseMoneyMovements => {
  const { venueId } = props || {};

  const [moneyMovementReasons, setMoneyMovementReasons] = useState<
    Record<string, MoneyMovementReason>
  >({});

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [createdMoneyMovementReasonId, setCreatedMoneyMovementReasonId] =
    useState<string>('');

  const [updatedMoneyMovementReasonIds, setUpdatedMoneyMovementReasonIds] =
    useState<string[]>([]);

  const [deletedMoneyMovementReason, setDeletedMoneyMovementReason] =
    useState<boolean>(false);

  // get moneyMovementReasons
  const onCompleteGetMoneyMovementReasonsRequest = useCallback(
    data => {
      if (data) {
        const moneyMovementReasonsData =
          data.moneyMovementReasons as MoneyMovementReason[];
        const moneyMovementReasonsTemp = {} as Record<
          string,
          MoneyMovementReason
        >;
        moneyMovementReasonsData.forEach(moneyMovementReasonData => {
          moneyMovementReasonsTemp[moneyMovementReasonData.id] =
            moneyMovementReasonData;
        });
        setMoneyMovementReasons(moneyMovementReasonsTemp);
        // As this is a refetch call, resetting to false
        setDeletedMoneyMovementReason(false);
      }
    },
    [setMoneyMovementReasons],
  );

  const [getMoneyMovementReasonsRequest, getMoneyMovementReasonsResponse] =
    useLazyQuery(GET_MONEY_MOVEMENTS_REASONS, {
      fetchPolicy: 'network-only',
      context: {
        headers: { venue: venueId },
      },
      onCompleted: onCompleteGetMoneyMovementReasonsRequest,
      onError: noopHandler,
    });

  // update
  const [
    updateMoneyMovementReasonsRequest,
    updateMoneyMovementReasonsResponse,
  ] = useMutation(UPDATE_MONEY_MOVEMENTS_REASONS, {
    onError: noopHandler,
    context: {
      headers: { venue: venueId },
    },
  });

  useEffect(() => {
    if (updateMoneyMovementReasonsResponse.data) {
      const moneyMovementReasonsData = updateMoneyMovementReasonsResponse.data
        .updateMoneyMovementReasons as MoneyMovementReason[];

      setMoneyMovementReasons(reasons => {
        const moneyMovementReasonsTemp = { ...reasons };
        moneyMovementReasonsData.forEach(moneyMovementReasonData => {
          moneyMovementReasonsTemp[moneyMovementReasonData.id] =
            moneyMovementReasonData;
        });
        return moneyMovementReasonsTemp;
      });
      setUpdatedMoneyMovementReasonIds(
        moneyMovementReasonsData.map(reason => reason.id),
      );
    }
  }, [updateMoneyMovementReasonsResponse.data]);

  const updateMoneyMovementReasons = useCallback(
    (moneyMovementReasonsInput: Partial<MoneyMovementReasonInput[]>) => {
      updateMoneyMovementReasonsRequest({
        variables: {
          input: moneyMovementReasonsInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateMoneyMovementReasonsRequest],
  );

  // create
  const [createMoneyMovementReasonRequest, createMoneyMovementReasonResponse] =
    useMutation(CREATE_MONEY_MOVEMENTS_REASON, {
      onError: noopHandler,
      context: {
        headers: { venue: venueId },
      },
    });

  useEffect(() => {
    if (createMoneyMovementReasonResponse.data) {
      const moneyMovementReasonsData = createMoneyMovementReasonResponse.data
        .createMoneyMovementReasons as MoneyMovementReason[];
      const newlyCreatedReason = moneyMovementReasonsData[0];

      setMoneyMovementReasons(reasons => {
        return {
          ...reasons,
          [newlyCreatedReason.id]: newlyCreatedReason,
        };
      });
      setCreatedMoneyMovementReasonId(newlyCreatedReason.id);
    }
  }, [createMoneyMovementReasonResponse.data]);

  const createMoneyMovementReason = useCallback(
    (moneyMovementReasonInputs: MoneyMovementReasonInput) => {
      createMoneyMovementReasonRequest({
        variables: {
          input: moneyMovementReasonInputs,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createMoneyMovementReasonRequest],
  );

  // delete
  const [deleteMoneyMovementReasonRequest, deleteMoneyMovementReasonResponse] =
    useMutation(DELETE_MONEY_MOVEMENTS_REASON, {
      onError: noopHandler,
      context: {
        headers: { venue: venueId },
      },
    });

  const deleteMoneyMovementReason = useCallback(
    (id: string) => {
      deleteMoneyMovementReasonRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteMoneyMovementReasonRequest],
  );

  useEffect(() => {
    if (deleteMoneyMovementReasonResponse.data) {
      setDeletedMoneyMovementReason(
        deleteMoneyMovementReasonResponse.data.deleteMoneyMovementReason,
      );
    }
  }, [deleteMoneyMovementReasonResponse.data]);

  const error: ApolloError | undefined =
    getMoneyMovementReasonsResponse.error ||
    updateMoneyMovementReasonsResponse.error ||
    deleteMoneyMovementReasonResponse.error ||
    createMoneyMovementReasonResponse.error;

  const loading: boolean =
    getMoneyMovementReasonsResponse.loading ||
    updateMoneyMovementReasonsResponse.loading ||
    deleteMoneyMovementReasonResponse.loading ||
    createMoneyMovementReasonResponse.loading;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      moneyMovementReasons,
      createdMoneyMovementReasonId,
      updatedMoneyMovementReasonIds,
      deletedMoneyMovementReason,
      getMoneyMovementReasons: getMoneyMovementReasonsRequest,
      createMoneyMovementReason,
      updateMoneyMovementReasons,
      deleteMoneyMovementReason,
    }),
    [
      loading,
      error,
      operation,
      moneyMovementReasons,
      createdMoneyMovementReasonId,
      updatedMoneyMovementReasonIds,
      deletedMoneyMovementReason,
      createMoneyMovementReason,
      getMoneyMovementReasonsRequest,
      updateMoneyMovementReasons,
      deleteMoneyMovementReason,
    ],
  );
};
