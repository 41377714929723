import React from 'react';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import { useTranslation } from '@oolio-group/localization';
import { noopHandler } from '../../../../../utils/errorHandlers';

interface ClashedMenuScheduleModalProps {
  clashesMenuName: string;
  onConfirm: () => void;
  operationName: 'Active' | 'Create' | 'Update';
}

const ClashedMenuScheduleModal: React.FC<ClashedMenuScheduleModalProps> = ({
  clashesMenuName,
  onConfirm,
  operationName,
}) => {
  const { translate } = useTranslation();

  return (
    <ConfirmationModal
      title={translate('menus.menuSchedules.modalTitle')}
      message={translate('menus.menuSchedules.modalDescription', {
        itemName: clashesMenuName,
      })}
      confirmLabel={translate('menus.menuSchedules.modalConfirm', {
        operationName,
      })}
      onCancel={noopHandler}
      onConfirm={onConfirm}
    />
  );
};

export default ClashedMenuScheduleModal;
