import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 600,
    alignSelf: 'center',
    position: 'absolute',
    top: 40,
  },
  btnClose: {
    position: 'absolute',
    top: -0,
    left: -54,
    width: 44,
    height: 44,
    borderRadius: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
  },
  list: {
    marginTop: 8,
    maxHeight: 300,
    overflow: 'hidden',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  option: {
    height: 50,
    paddingLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  name: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    width: 200,
    fontSize: 12,
    marginLeft: 6,
    marginRight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  loyalty: {
    height: 32,
    marginRight: 6,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.blueLight,
  },
  points: {
    marginRight: 8,
    lineHeight: 18,
    color: theme.colors.blue,
    fontFamily: theme.fonts.medium,
  },
  btnUnassign: {
    width: 34,
    height: 34,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
