import React from 'react';
import ImportProducts from './ImportProducts/ImportProducts';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';

const Tab = createMaterialTopTabNavigator();

const ImportProductTab: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => {
        return <TabBar tabBar={props} />;
      }}
      initialRouteName="ImportProducts"
    >
      <Tab.Screen
        name="ImportProducts"
        component={ImportProducts}
        options={{ tabBarLabel: translate('importEntity.importProducts') }}
      />
    </Tab.Navigator>
  );
};

export default ImportProductTab;
