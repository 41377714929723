import React from 'react';
import { NotificationList } from '../../components/Notification/NotificationList';
import {
  useNotification,
  useNotificationsList,
} from '../../hooks/Notification';
import Loader from './Loader';

export const LoadingScreen: React.FC = () => {
  const notifications = useNotificationsList();
  const { closeNotification } = useNotification();
  return (
    <Loader>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
    </Loader>
  );
};

export default LoadingScreen;
