import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    rowGap: 8,
    alignSelf: 'center',
    transform: [{ scale: 0.9 }],
  },
  content: {
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.elevated1,
    overflow: 'hidden',
  },
  floor: {
    width: theme.tableLayout.width,
    height: theme.tableLayout.height,
  },
});

export default styles;
