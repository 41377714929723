import React from 'react';
import Amex from '../../../../components/Shared/Icons/IconPayment/Amex';
import Visa from '../../../../components/Shared/Icons/IconPayment/Visa';
import JCB from '../../../../components/Shared/Icons/IconPayment/JCB';
import MasterCard from '../../../../components/Shared/Icons/IconPayment/MasterCard';
import Maestro from '../../../../components/Shared/Icons/IconPayment/Maestro';
import ApplePay from '../../../../components/Shared/Icons/IconPayment/ApplePay';
import GooglePay from '../../../../components/Shared/Icons/IconPayment/GooglePay';
import EFTPOS from '../../../../components/Shared/Icons/IconPayment/EFTPOS';
import CashIcon from '../../../../components/Shared/Icons/IconPayment/Cash';
import OtherIcon from '../../../../components/Shared/Icons/IconPayment/Other';

interface PaymentIconProps {
  type: string;
}

const Card: React.FC<PaymentIconProps> = ({ type }) => {
  switch (type) {
    case 'amex':
      return <Amex />;
    case 'visa':
      return <Visa />;
    case 'jcb':
      return <JCB />;
    case 'mc':
      return <MasterCard />;
    case 'maestro':
      return <Maestro />;
    default:
      return <OtherIcon />;
  }
};

const Online: React.FC<PaymentIconProps> = ({ type }) => {
  switch (type) {
    case 'applepay':
      return <ApplePay />;
    case 'gpay':
      return <GooglePay />;
    case 'EFTPOS':
      return <EFTPOS />;
    case 'amex':
      return <Amex />;
    case 'visa':
      return <Visa />;
    case 'jcb':
      return <JCB />;
    case 'mc':
      return <MasterCard />;
    case 'maestro':
      return <Maestro />;
    default:
      return <OtherIcon />;
  }
};

const Cash: React.FC = () => {
  return <CashIcon />;
};

const Other: React.FC = () => {
  return <OtherIcon />;
};

export default { Card, Online, Cash, Other };
