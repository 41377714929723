import {
  isCurrentMenuScheduleHaveCollision,
  isMenuScheduleCollisionWithPrevious,
  mapMenuScheduleToMenuScheduleModel,
  stripProperties,
} from '@oolio-group/client-utils';
import {
  CatalogueSchedule,
  CatalogueScheduleItemInput,
  OrderTypeCode,
  SalesChannelCode,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useIsFocused, useRoute } from '@react-navigation/native';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TouchableOpacity, View } from 'react-native';
import theme from '../../../../../common/default-theme';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useNotification } from '../../../../../hooks/Notification';
import { useMenuSchedules } from '../../../../../hooks/app/menuSchedules/useMenuSchedules';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import { useOrderTypes } from '../../../../../hooks/app/orderTypes/useOrderTypes';
import { useSalesChannels } from '../../../../../hooks/app/salesChannels/useSalesChannels';
import { useStores } from '../../../../../hooks/app/useStores';
import { noopHandler } from '../../../../../utils/errorHandlers';
import ClashedMenuScheduleModal from './ClashedMenuScheduleModal';
import styles from './ScheduleDetail.styles';
import ScheduleRow from './ScheduleRow';

const Tab = createMaterialTopTabNavigator();
const allowedOrderTypeCode = [
  OrderTypeCode.PICK_UP,
  OrderTypeCode.DELIVERY,
  OrderTypeCode.DINE_IN,
];

const ScheduleDetail: React.FC = () => {
  const defaultItem = useMemo(
    () => ({
      menu: '',
      salesChannels: [],
      orderTypes: [],
      stores: [],
      schedules: [],
      isActive: true,
    }),
    [],
  );
  // data
  const { menus, getMenus, loading: menuLoading } = useMenus();
  const {
    salesChannels,
    getSalesChannels,
    loading: saleChannelLoading,
  } = useSalesChannels();
  const { stores, getStores, loading: storeLoading } = useStores();
  const {
    orderTypes,
    getOrderTypes,
    loading: orderTypesLoading,
  } = useOrderTypes();
  const {
    menuSchedules,
    getMenuSchedule,
    getMenuSchedules,
    createMenuSchedule,
    updateMenuSchedule,
    deleteMenuSchedule,
    loading: menuScheduleLoading,
  } = useMenuSchedules();
  // condition var
  const isFocused = useIsFocused();
  const route: { params?: { id?: string } } | undefined = useRoute();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const { translate } = useTranslation();
  // state
  const defaultSaleChannels = useMemo(() => {
    return Object.values(salesChannels)
      .filter(salesChannel => salesChannel.code === SalesChannelCode.ONLINE)
      .map(salesChannel => salesChannel.id);
  }, [salesChannels]);
  const [catalogueScheduleItem, setCatalogueScheduleItem] =
    useState<CatalogueScheduleItemInput>(defaultItem);
  const clashesMenu = useRef<string[]>();

  const loading =
    menuLoading ||
    saleChannelLoading ||
    storeLoading ||
    orderTypesLoading ||
    menuScheduleLoading;

  const menuOptions = useMemo(() => {
    return Object.values(menus).map(menu => ({
      value: menu.id,
      label: menu.name,
    }));
  }, [menus]);

  const saleChannelOptions = useMemo(() => {
    return Object.values(salesChannels).map(saleChannel => ({
      value: saleChannel.id,
      label: saleChannel.name,
    }));
  }, [salesChannels]);

  const storeOptions = useMemo(() => {
    return Object.values(stores).map(store => ({
      value: store.id,
      label: store.name,
    }));
  }, [stores]);

  const orderTypesOptions = useMemo(() => {
    return (orderTypes || [])
      .filter(orderType => {
        return allowedOrderTypeCode.includes(orderType.code as OrderTypeCode);
      })
      .map(orderType => ({
        value: orderType.id,
        label: orderType.name,
      }));
  }, [orderTypes]);

  const onSaveMenuSchedule = useCallback(() => {
    const itemToHandle = stripProperties(
      catalogueScheduleItem,
      '__typename',
    ) as CatalogueScheduleItemInput;
    if (route.params?.id) {
      updateMenuSchedule(itemToHandle);
    } else {
      createMenuSchedule({
        ...itemToHandle,
        isActive: true,
      });
    }
  }, [
    catalogueScheduleItem,
    createMenuSchedule,
    route.params?.id,
    updateMenuSchedule,
  ]);

  const handleConfirmWarningModal = useCallback(() => {
    (clashesMenu.current || []).forEach(id => {
      const itemToHandle = stripProperties(menuSchedules[id], '__typename');
      updateMenuSchedule({
        ...mapMenuScheduleToMenuScheduleModel(itemToHandle),
        isActive: false,
      });
    });
    onSaveMenuSchedule();
    closeModal();
  }, [onSaveMenuSchedule, closeModal, menuSchedules, updateMenuSchedule]);

  const onShowWarningModal = useCallback(() => {
    const clashesMenuName = (clashesMenu.current || [])
      .map(id => {
        const menuId = menuSchedules?.[id].menu;
        return menus?.[menuId].name;
      })
      .join(', ');
    showModal(
      <ClashedMenuScheduleModal
        clashesMenuName={clashesMenuName}
        onConfirm={handleConfirmWarningModal}
        operationName={route.params?.id ? 'Update' : 'Create'}
      />,
      {
        onBackButtonPress: closeModal,
        onModalHide: noopHandler,
      },
    );
  }, [
    showModal,
    handleConfirmWarningModal,
    route.params?.id,
    closeModal,
    menuSchedules,
    menus,
  ]);

  const isMenuScheduleValid = useCallback(() => {
    const isMissingInfo =
      catalogueScheduleItem.orderTypes.length === 0 ||
      catalogueScheduleItem.stores.length === 0 ||
      catalogueScheduleItem.salesChannels.length === 0 ||
      catalogueScheduleItem.schedules.length === 0;
    if (isMissingInfo) {
      showNotification({
        message: translate('menus.menuSchedules.missingDetails'),
        error: true,
      });
      return false;
    }
    const isSchedulesEmpty = catalogueScheduleItem.schedules?.length === 0;
    const isScheduleDaysEmpty = catalogueScheduleItem.schedules?.find(
      schedule =>
        !schedule?.timeBlocks?.length ||
        schedule?.timeBlocks?.find(timeBlock => timeBlock?.days?.length === 0),
    );

    if (isSchedulesEmpty || isScheduleDaysEmpty) {
      showNotification({
        message: translate('menus.menuSchedules.emptySchedules'),
        error: true,
      });
      return false;
    }

    const isCurrentScheduleCollide = isCurrentMenuScheduleHaveCollision(
      catalogueScheduleItem,
    );
    const isMenuScheduleCollideWithPrevious =
      isMenuScheduleCollisionWithPrevious(
        catalogueScheduleItem,
        Object.values(menuSchedules).filter(
          menuSchedule => menuSchedule.id !== catalogueScheduleItem.id,
        ),
      );
    if (isCurrentScheduleCollide) {
      showNotification({
        message: translate('menus.menuSchedules.menuCollide'),
        error: true,
      });
      return false;
    }
    if (isMenuScheduleCollideWithPrevious.isClashes) {
      clashesMenu.current = isMenuScheduleCollideWithPrevious.clashesMenus;
      onShowWarningModal();
      return false;
    }
    return true;
  }, [
    catalogueScheduleItem,
    menuSchedules,
    onShowWarningModal,
    showNotification,
    translate,
  ]);

  const handleSaveMenuSchedule = useCallback(() => {
    if (!isMenuScheduleValid()) return;
    onSaveMenuSchedule();
  }, [isMenuScheduleValid, onSaveMenuSchedule]);

  const handleDeleteMenuSchedule = useCallback(
    (id?: string) => {
      if (id) deleteMenuSchedule(id);
    },
    [deleteMenuSchedule],
  );

  const onChangeMainDetails = useCallback(
    (key: keyof CatalogueScheduleItemInput, value: unknown) => {
      setCatalogueScheduleItem(item => {
        return {
          ...item,
          [key]: value,
        };
      });
    },
    [],
  );

  const onUpdateSchedule = useCallback(
    (schedule: CatalogueSchedule, index: number) => {
      setCatalogueScheduleItem(item => {
        const schedules = [...item.schedules];
        schedules[index] = schedule;
        return {
          ...item,
          schedules,
        };
      });
    },
    [],
  );

  const onDeleteSchedule = useCallback((index: number) => {
    setCatalogueScheduleItem(item => {
      const schedules = [...item.schedules];
      schedules.splice(index, 1);
      return {
        ...item,
        schedules,
      };
    });
  }, []);

  const addSchedule = useCallback(() => {
    setCatalogueScheduleItem(item => {
      const schedules = [...item.schedules];
      schedules.push({} as CatalogueSchedule);
      return {
        ...item,
        schedules,
      };
    });
  }, []);

  useEffect(() => {
    isFocused && getMenus();
  }, [getMenus, isFocused]);

  useEffect(() => {
    isFocused && getSalesChannels();
  }, [getSalesChannels, isFocused]);

  useEffect(() => {
    isFocused && getStores();
  }, [getStores, isFocused]);

  useEffect(() => {
    isFocused && getOrderTypes();
  }, [getOrderTypes, isFocused]);

  useEffect(() => {
    isFocused && getMenuSchedules();
  }, [getMenuSchedules, isFocused]);

  useEffect(() => {
    if (route.params?.id) getMenuSchedule(route.params?.id);
  }, [getMenuSchedule, route.params?.id]);

  useEffect(() => {
    if (route.params?.id && menuSchedules[route.params.id]) {
      const id = route.params?.id as string;
      setCatalogueScheduleItem(item => ({
        ...item,
        ...mapMenuScheduleToMenuScheduleModel(menuSchedules[id]),
      }));
    }
  }, [menuSchedules, route.params?.id]);

  useEffect(() => {
    if (
      !catalogueScheduleItem.menu &&
      !catalogueScheduleItem.salesChannels.length &&
      !route.params?.id &&
      menuOptions.length &&
      defaultSaleChannels?.length
    ) {
      setCatalogueScheduleItem(item => ({
        ...item,
        ...defaultItem,
        menu: menuOptions[0]?.value,
        salesChannels: defaultSaleChannels,
      }));
    }
  }, [
    catalogueScheduleItem.menu,
    catalogueScheduleItem.salesChannels,
    defaultItem,
    defaultSaleChannels,
    menuOptions,
    route?.params?.id,
  ]);

  return (
    <ScreenLayout
      loading={loading}
      title={'Schedule | Oolio'}
      onSave={handleSaveMenuSchedule}
      onDeleteDisabled={!route.params?.id}
      onDelete={() => handleDeleteMenuSchedule(route.params?.id || '')}
    >
      <Section title={translate('menus.menuSchedules.details')}>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-menu"
            title={translate('backOfficeFeatures.selectMenus')}
            options={menuOptions}
            selectedValue={catalogueScheduleItem.menu || menuOptions[0]?.value}
            onValueChange={value => onChangeMainDetails('menu', value)}
            containerStyle={theme.forms.inputHalf}
          />
          <SelectMultiple
            testID="select-sale-channel"
            title={translate('backOfficeSettings.salesChannels')}
            options={saleChannelOptions}
            selectedValues={
              (catalogueScheduleItem.salesChannels.length
                ? catalogueScheduleItem.salesChannels
                : defaultSaleChannels) as string[]
            }
            onValueChange={value => onChangeMainDetails('salesChannels', value)}
            containerStyle={theme.forms.inputHalf}
            disabled={true}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-order-types"
            title={translate('backOfficeSettings.OrderTypes')}
            options={orderTypesOptions}
            selectedValues={catalogueScheduleItem.orderTypes}
            onValueChange={value => onChangeMainDetails('orderTypes', value)}
            containerStyle={theme.forms.inputHalf}
          />
          <SelectMultiple
            testID="select-stores"
            title={translate('backOfficeSettings.stores')}
            options={storeOptions}
            selectedValues={catalogueScheduleItem.stores}
            onValueChange={value => onChangeMainDetails('stores', value)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('menus.menuSchedules.schedules')}>
        {(catalogueScheduleItem.schedules || []).map((schedule, index) => {
          return (
            <ScheduleRow
              key={index}
              schedule={schedule}
              onUpdateSchedule={scheduleParam =>
                onUpdateSchedule(scheduleParam, index)
              }
              onDeleteSchedule={() => onDeleteSchedule(index)}
            />
          );
        })}
      </Section>
      <Section>
        <TouchableOpacity onPress={addSchedule}>
          <ButtonIcon
            icon="plus"
            containerStyle={styles.createScheduleIconContainer}
            type="positiveLight"
            onPress={addSchedule}
          />
        </TouchableOpacity>
      </Section>
    </ScreenLayout>
  );
};

const ScheduleDetailContainer: React.FC = () => {
  const route = useRoute();
  const params = route.params as { id?: string; title: string };
  const { translate } = useTranslation();

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => {
        return <TabBar tabBar={props} />;
      }}
      initialRouteName="ScheduleDetail"
    >
      <Tab.Screen
        name="ScheduleDetail"
        component={ScheduleDetail}
        options={{
          tabBarLabel: translate(
            !params?.id
              ? 'backOfficeProducts.createNew'
              : 'menus.menuSchedules.details',
          ),
        }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default ScheduleDetailContainer;
