import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { FilterValue } from '../ProductList';
import theme from '../../../../../common/default-theme';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import { Option } from '../../../../../components/Shared/Select/Select';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';

interface ProductFilterModalProps {
  selectedFilters: FilterValue;
  optionsGroupOptions: Option[];
  printerProfileOptions: Option[];
  onChangeFilter: (
    printerProfilesFilter: string[],
    optionGroups: string[],
  ) => void;
  onCloseModal: () => void;
}

const ProductFilterModal: React.FC<ProductFilterModalProps> = ({
  onChangeFilter,
  printerProfileOptions,
  optionsGroupOptions,
  selectedFilters,
  onCloseModal,
}) => {
  const { translate } = useTranslation();

  const [printerProfiles, setPrinterProfiles] = useState<Array<string>>(
    selectedFilters.printerProfilesFilter,
  );
  const [optionGroups, setOptionsGroups] = useState<Array<string>>(
    selectedFilters.optionGroups,
  );

  const onApplyFilters = useCallback(() => {
    onChangeFilter(printerProfiles, optionGroups);
    onCloseModal();
  }, [onChangeFilter, onCloseModal, optionGroups, printerProfiles]);

  const onClearFilters = useCallback(() => {
    setPrinterProfiles([]);
    setOptionsGroups([]);
    onChangeFilter([], []);
    onCloseModal();
  }, [onChangeFilter, onCloseModal]);

  return (
    <TreatModal
      title={translate('productSettings.filterModalTitle')}
      onConfirm={{
        label: translate('productSettings.applyFilters'),
        action: onApplyFilters,
      }}
      secondaryButton={{
        label: translate('productSettings.clearFilters'),
        type: 'neutralLight',
        action: onClearFilters,
      }}
      onDismiss={{ action: onCloseModal }}
    >
      <View style={theme.forms.row}>
        <SelectMultiple
          title={translate('backOfficePrinterProfiles.printerProfiles')}
          testID={'filter-printer-profile'}
          options={printerProfileOptions.concat({
            label: translate('productSettings.none'),
            value: 'none',
          })}
          onValueChange={selectedValues => setPrinterProfiles(selectedValues)}
          selectedValues={printerProfiles}
          containerStyle={theme.forms.inputFluid}
        />
      </View>
      <SelectMultiple
        title={translate('modifiers.optionGroups')}
        testID={'filter-options'}
        options={optionsGroupOptions}
        onValueChange={selectedValues => setOptionsGroups(selectedValues)}
        selectedValues={optionGroups}
      />
    </TreatModal>
  );
};

export default ProductFilterModal;
