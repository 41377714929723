import { PrinterSeries, Product } from '@oolio-group/domain';
import { EscPos } from '@tillpos/xml-escpos-helper';
import { recipeTemplate } from './xmlTemplates';
import { TableUserConfig, getBorderCharacters, table } from 'table';
import { divider } from './printDivider';

const config: TableUserConfig = {
  columns: {
    0: {
      wrapWord: true,
      width: 40,
      alignment: 'left',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

export const getRecipePrintableBuffer = (
  product: Product,
  printerSeries?: PrinterSeries,
) => {
  const printMode = printerSeries !== PrinterSeries.TM_U220 ? 'REST' : 'U220';

  const payload = {
    title: '*** RECIPE ***',
    productName: product.name.toUpperCase(),
    divider: divider(),
    recipe: table([[product.recipe]], config),
    printMode,
  };
  const data = EscPos.getBufferFromTemplate(
    recipeTemplate,
    payload,
  ) as unknown as Buffer;

  return data;
};
