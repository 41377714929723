import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import theme from '../../../../common/default-theme';
import styles from './ModalTitle.styles';
import Icon from '../../../Icon/Icon';

interface Props {
  title: string;
  onDismiss?: () => void;
}

const ModalTitle: React.FC<Props> = ({ title, onDismiss }) => {
  const { closeModal } = useModal();

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-close"
        onPress={onDismiss ? onDismiss : closeModal}
        style={styles.btnClose}
      >
        <Icon size={20} name="times" color={theme.colors.dark} />
      </TouchableOpacity>
      <Text testID="mdl-title" style={styles.title}>
        {title}
      </Text>
    </View>
  );
};

export default ModalTitle;
