import { gql } from '@apollo/client';
import { PRODUCTS_IN_PRODUCT_LIST_SCREEN } from '../products/graphql';

const variantQuery = `
      id
      name
      options {
        id
        key
        values
      }
      imageUrl
      color
      products {
        id
        name
        isSellable
        isDefault
        optionValues {
          parentOptionId
          key
          value
        }
        pricingGroups {
          id
          name
          prices {
            id
            taxInclusive
            sellingPrice {
              amount
              currency
            }
            costPrice {
              amount
              currency
            }
            sellingTax {
              id
            }
          }
        }
      }
      productType {
        id
        name
      }
      printerProfiles {
        id
        name
        printerProfileType
      }
      barcode
      sku
      measuredBy {
        unitType
        defaultSize
        units
      }
      description
      dietaryTags {
        id
        name
      }
      allergens
      recipe
      nutrientInfo {
        name
        value
        unit
      }
      alternateNames {
        locale
        display
        kitchen
        receipt
        description
      }
      salesChannels {
        id
      }
      stores {
        id
      }
`;
export const GET_VARIANT_QUERY = gql`
  query variant($id: ID!) {
    variant(id: $id) {
      ${variantQuery}
    }
  }
`;

export const GET_VARIANTS_QUERY = gql`
  query variants {
    variants {
      ${variantQuery}
    }
  }
`;

export const UPDATE_VARIANT_DETAILS_MUTATION = gql`
  mutation updateVariant($input: UpdateVariantInput) {
    updateVariant(input: $input) {
      ${variantQuery}
    }
  }
`;

export const UPDATE_VARIANTS = gql`
  mutation updateVariants($input: [UpdateVariantInput!]!) {
    updateVariants(input: $input) {
      ${variantQuery}
    }
  }
`;

export const CREATE_VARIANT_DETAILS_MUTATION = gql`
  mutation createVariant($input: CreateVariantProductInput) {
    createVariant(input: $input) {
      ${variantQuery}
    }
  }
`;

export const COPY_VARIANT_MUTATION = gql`
  mutation copyVariant($input: CopyVariantInput) {
    copyVariant(input: $input) {
      ${variantQuery}
    }
  }
`;

export const CONVERT_PRODUCT_TO_VARIANT_MUTATION = gql`
  mutation convertProductToVariant($input: ConvertProductToVariantInput) {
    convertProductToVariant(input: $input) {
      ${variantQuery}
    }
  }
`;

export const DELETE_VARIANTS_MUTATION = gql`
  mutation deleteVariants($input: [ID!]!) {
    deleteVariants(ids: $input)
  }
`;

/**
 * custom graph query implementation in hooks
 */

export const VARIANTS_IN_PRODUCT_LIST_SCREEN = `
  id
  name
  productType {
    id
    name
  }
  products {
   id
  }
  printerProfiles{
    id
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;
export const VARIANTS_FOR_COPY = `
  id
  name
  productType {
    id
    name
  }
  products {
   ${PRODUCTS_IN_PRODUCT_LIST_SCREEN}
  }
  stores {
    id
  }
  category {
    id
    name
  }
`;

export const VARIANTS_GENERAL_FRAGMENT = `
  id
  name
  plu
  productType {
    id
    name
  }
  imageUrl
  color
  printerProfiles{
    id
    name
    printerProfileType
  }
  barcode
  sku
  measuredBy {
    unitType
    defaultSize
    units
  }
  description
  dietaryTags {
    id
    name
  }
  allergens
  recipe
  nutrientInfo {
    name
    value
    unit
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  isFeatured
  category {
    id
    name
  }
`;

export const VARIANT_AVAILABILITY_FRAGMENT = `
  id
  name
  salesChannels {
    id
  }
  stores {
    id
  }
`;

export const BULK_UPDATE_VARIANT_CATEGORY = gql`
  mutation bulkUpdateVariantsCategory($input: BulkUpdateEntityCategoryInput) {
    bulkUpdateVariantsCategory(input: $input)
  }
`;

export const getVariantQuery = (customFragment?: string) => {
  const gqlQuery = gql`
    query variant($id: ID!) {
      variant(id: $id) {
        ${customFragment ? customFragment : variantQuery}
      }
    }
  `;
  return gqlQuery;
};

export const getVariantsQuery = (customFragment?: string) => {
  const gqlQuery = gql`
    query variants {
      variants {
        ${customFragment ? customFragment : variantQuery}
      }
    }
   `;
  return gqlQuery;
};

export const updateVariantMutation = (customFragment?: string) => {
  const gqlQuery = gql`
    mutation updateVariant($input: UpdateVariantInput) {
      updateVariant(input: $input) {
        ${customFragment ? customFragment : variantQuery}
      }
    }
   `;
  return gqlQuery;
};

export const updateVariantsMutation = (customFragment?: string) => {
  const gqlQuery = gql`
    mutation updateVariants($input: [UpdateVariantInput!]!) {
      updateVariants(input: $input) {
        ${customFragment ? customFragment : variantQuery}
      }
    }
  `;
  return gqlQuery;
};

export const createVariantMutation = (customFragment?: string) => {
  const gqlQuery = gql`
    mutation createVariant($input: CreateVariantProductInput) {
      createVariant(input: $input) {
        ${customFragment ? customFragment : variantQuery}
      }
    }
  `;
  return gqlQuery;
};

export const copyVariantMutation = (customFragment?: string) => {
  const gqlQuery = gql`
    mutation copyVariant($input: CopyVariantInput) {
      copyVariant(input: $input) {
        ${customFragment ? customFragment : variantQuery}
      }
    }
  `;
  return gqlQuery;
};

export const VARIANT_IDS_OF_STORES = gql`
  query variants($filter: VariantsFilterInput) {
    variants(filter: $filter) {
      id
    }
  }
`;
