import React, { useMemo, useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { KitchenDisplayData } from '../KitchenDisplays';
import { useTranslation } from '@oolio-group/localization';
import { Operation } from '../../../../../../types/Operation';
import theme from '../../../../../../common/default-theme';
import styles from '../KitchenDisplays.styles';
import Icon from '../../../../../../components/Icon/Icon';
import Sticker from '../../../../../../components/Shared/Sticker/Sticker';

interface Props {
  KitchenDisplay: KitchenDisplayData;
  storeId: string;
}

const KitchenDisplayListRow: React.FC<Props> = ({
  KitchenDisplay: display,
  storeId,
}) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();

  const KitchenDisplayDetails: string = useMemo(() => {
    let text = display.details || '';
    if (display.uuid) text += `•••• ${display.uuid.slice(-4).toUpperCase()}`;
    return text;
  }, [display.details, display.uuid]);

  const navigateToKitchenDisplayDetail = useCallback(() => {
    if (storeId && display.id)
      navigation.navigate('KitchenDisplaySettings', {
        kitchenDisplayId: display.id,
        storeId,
        mode: Operation.UPDATE,
      });
  }, [display.id, navigation, storeId]);

  return (
    <TouchableOpacity
      testID="row-display"
      style={theme.tables.row}
      onPress={navigateToKitchenDisplayDetail}
    >
      <View style={styles.cellName}>
        <Text>{display.name}</Text>
        <Text testID="display-details" style={styles.subtext}>
          {KitchenDisplayDetails}
        </Text>
      </View>
      <Sticker
        testID="sticker-status"
        type={display.isPaired ? 'positiveLight' : 'negativeLight'}
        label={
          display.isPaired
            ? translate('backOfficeDevices.paired')
            : translate('backOfficeDevices.unpaired')
        }
      />
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey5} />
      </View>
    </TouchableOpacity>
  );
};

export default KitchenDisplayListRow;
