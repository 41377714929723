import { gql } from '@apollo/client';

export const GET_OPTION_QUERY = gql`
  query option($id: ID!) {
    option(id: $id) {
      id
      key
      values
    }
  }
`;

export const GET_OPTIONS_QUERY = gql`
  query options {
    options {
      id
      key
      values
    }
  }
`;

export const UPDATE_OPTION_MUTATION = gql`
  mutation updateOption($input: UpdateOptionInput) {
    updateOption(input: $input) {
      id
      key
      values
    }
  }
`;

export const CREATE_OPTION_MUTATION = gql`
  mutation createOption($input: CreateOptionInput) {
    createOption(input: $input) {
      id
      key
      values
    }
  }
`;
