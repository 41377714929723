import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filters: {
    height: 44,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  table: {
    marginBottom: 20,
  },
  headerName: {
    flex: 1,
  },
  headerCount: {
    width: 82,
    marginRight: 10,
    textAlign: 'center',
  },
  headerUsers: {
    width: 82,
    marginRight: 34,
    textAlign: 'center',
  },
  roleRow: {
    height: 50,
  },
  cellRoleName: {
    flex: 1,
    paddingLeft: 12,
    textTransform: 'capitalize',
  },
  cellCount: {
    width: 82,
    marginRight: 10,
    textAlign: 'center',
    fontVariant: ['tabular-nums'],
  },
  message: {
    width: 540,
    marginBottom: 20,
  },
});

export default styles;
