import 'react-native-get-random-values';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { GET_PLACES, GET_PLACE_DETAILS } from '../../graphql/places';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Place, PlaceDetail } from '@oolio-group/domain';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { GQLClient } from '@oolio-group/gql-client';
import { REACT_APP_PLACES_API_URL } from 'react-native-dotenv';

// Min no of characters to be entered to trigger places api call
export const MIN_CHARS_TO_TRIGGER_SEARCH = 4;
const PLACE_SERVICE_URI =
  process.env.REACT_APP_PLACES_API_URL || REACT_APP_PLACES_API_URL;

export interface UsePlacesProps {
  placesList: Array<Place>;
  handlePlaceSelect: (placeId: string) => void;
  placeDetails: PlaceDetail;
  loading: boolean;
  error: string | undefined;
}

export function usePlaces(
  input = '',
  hidePlacesList = true,
  targetCountry?: string,
): UsePlacesProps {
  const sessionRef = useRef<string>(uuidv4());
  const placeDataRef = useRef<string>();
  const [placeSvcClient] = useState(() =>
    new GQLClient(
      {
        cache: undefined,
        resolvers: [],
        middlewares: [],
        service: PLACE_SERVICE_URI,
        getSession: () => ({}),
        client: 'pos-app',
        version: '1.0',
      },
      false,
    ).getApolloClient(),
  );

  const [fetchPlaces, fetchPlacesResponse] = useLazyQuery<
    { places: Place[] },
    {
      term: string;
      sessionToken: string;
      countries?: string[];
    }
  >(GET_PLACES, {
    fetchPolicy: 'network-only',
    onError: noopHandler,
    client: placeSvcClient,
  });

  const [fetchPlace, fetchPlaceResponse] = useLazyQuery(GET_PLACE_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ place }) => {
      // Regenerate token - :4.1
      sessionRef.current = uuidv4();
      placeDataRef.current = place.formattedAddress;
    },
    onError: noopHandler,
    client: placeSvcClient,
  });

  /**
   * get place details for a given place id - :3
   *
   * @param placeId
   */
  const handlePlaceSelect = useCallback(
    (placeId: string): void => {
      fetchPlace({
        variables: { id: placeId, sessionToken: sessionRef.current },
      });
    },
    [fetchPlace],
  );

  /**
   * Whenever input changes, request for places api to get some suggestions - :1
   * The min input required is 4 chars
   */
  useEffect(() => {
    if (!hidePlacesList) {
      if (MIN_CHARS_TO_TRIGGER_SEARCH <= input.replace(/\s/g, '').length) {
        // if the input is same as the passed formatted address then ignore
        if (placeDataRef.current !== input) {
          fetchPlaces({
            variables: {
              term: input,
              sessionToken: sessionRef.current,
              ...(targetCountry && { countries: [targetCountry] }),
            },
          });
        }
      }
    }
  }, [input, fetchPlaces, hidePlacesList, targetCountry]);

  const error: ApolloError | undefined =
    fetchPlacesResponse.error || fetchPlaceResponse.error;

  const loading: boolean =
    fetchPlacesResponse.loading || fetchPlaceResponse.loading;

  return useMemo(
    () => ({
      placesList: fetchPlacesResponse?.data?.places || [], // Return the places list - :2
      handlePlaceSelect,
      placeDetails: fetchPlaceResponse?.data?.place, // Return place details - :4
      loading,
      error: error ? parseApolloError(error) : undefined,
    }),
    [
      fetchPlacesResponse?.data?.places,
      fetchPlaceResponse?.data?.place,
      handlePlaceSelect,
      loading,
      error,
    ],
  );
}
