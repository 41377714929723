import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  filtersContainer: {
    width: '100%',
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchContainer: {
    flex: 1,
    marginRight: 10,
  },
  storeName: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingHorizontal: 12,
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.grey3,
  },
  tableText: {
    lineHeight: 20,
    paddingVertical: 14,
  },
});

export default styles;
