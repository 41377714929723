import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ManageCustomers from './ManageCustomers/ManageCustomers';
import ManageCustomerTabs from './ManageCustomers/CustomerTabs/ManageCustomerTabs';

const Stack = createStackNavigator();

const CustomerStack: React.FC = () => {
  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName="ManageCustomers"
      screenOptions={{
        animationEnabled: true,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
    >
      <Stack.Screen name="ManageCustomers" component={ManageCustomers} />
      <Stack.Screen name="ManageCustomersTab" component={ManageCustomerTabs} />
    </Stack.Navigator>
  );
};

export default CustomerStack;
