import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import {
  ShiftType,
  Store,
  DayOfWeek,
  OperatingHours,
  EmailType,
  IntegrationApps,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import { useNavigation, useRoute } from '@react-navigation/native';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import { useStores } from '../../../../../hooks/app/useStores';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { isNotEmpty, isValidEmail } from '../../../../../utils/validator';
import { cloneJSON } from '@oolio-group/client-utils';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import { cloneDeep } from 'lodash';
import { useIntegrationPartners } from '../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import Message from '../../../../../components/Office/Message/Message';
import { DetailsForm } from './Form/DetailsForm';
import { Address } from '@oolio-group/domain';
import { useSession } from '../../../../../hooks/app/useSession';
import { DEFAULT_COUNTRY_CODE } from '../../../../../constants';
import theme from '../../../../../common/default-theme';

export const Details: React.FC = () => {
  const {
    DAILY_SALES_SUMMARY,
    LOW_INVENTORY_STOCK,
    PURCHASE_ORDER,
    SHIFT_SUMMARY,
  } = EmailType;
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const route = useRoute();
  const { translate } = useTranslation();
  const [form, setForm] = useState({} as Store);
  const { showModal, closeModal } = useModal();
  const [emails, setEmails] = useState<string[]>([]);
  const { checkMerchantCodeExists } = useIntegrationPartners();
  const [isMerchantCodeValid, setMerchantCodeValidation] = useState<boolean>();
  const emailPreferencesDictionary = useMemo(() => {
    return keyBy(form.emailPreferences || [], 'emailType');
  }, [form.emailPreferences]);

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const { venueId, storeId } = params;

  const {
    stores,
    deleteStore,
    deletedStore,
    loading,
    error,
    getStores,
    updateStoreDetailsPage,
    updateStoreDetailsResponse,
  } = useStores({
    storeId,
    venueId,
  });

  const showErrorNotification = useCallback(
    (msg: string) =>
      showNotification({
        error: true,
        message: msg,
      }),
    [showNotification],
  );

  const showSuccessNotification = useCallback(
    (msg: string) =>
      showNotification({
        success: true,
        message: msg,
      }),
    [showNotification],
  );

  useEffect(() => {
    getStores();
  }, [getStores]);

  useEffect((): void => {
    if (error) {
      showErrorNotification(error);
    }
  }, [error, showErrorNotification]);

  useEffect((): void => {
    if (updateStoreDetailsResponse.error) {
      showErrorNotification('Error updating store');
    }
  }, [updateStoreDetailsResponse.error, showErrorNotification]);

  useEffect((): void => {
    if (updateStoreDetailsResponse.data) {
      showSuccessNotification(
        translate('backOfficeSettings.successfullyUpdated'),
      );
    }
  }, [updateStoreDetailsResponse.data, translate, showSuccessNotification]);

  const checkAndSetMerchantValidation = useCallback(
    value => {
      checkMerchantCodeExists(IntegrationApps.OOLIO_STORE, value, '').then(
        val => setMerchantCodeValidation(val.active),
      );
    },
    [checkMerchantCodeExists],
  );

  const [session] = useSession();
  const orgCountry =
    session.currentOrganization?.country || DEFAULT_COUNTRY_CODE;

  useEffect(() => {
    if (stores && stores[storeId]) {
      const storeData = stores[storeId] as Store;
      // adding default time slots to form if not exist
      let { operatingHours } = cloneJSON(storeData) as Store;
      const { emailPreferences } = storeData;

      if (emailPreferences) {
        const tempEmails = emailPreferences
          .map(preference =>
            [EmailType.DAILY_SALES_SUMMARY, EmailType.SHIFT_SUMMARY].includes(
              preference.emailType,
            ) &&
            preference.isEnabled &&
            preference.emails?.length
              ? preference.emails
              : '',
          )
          .filter(p => p);
        setEmails([...new Set(tempEmails.flat())]);
      }

      if (!operatingHours) {
        operatingHours = {} as OperatingHours;
        operatingHours.name = 'operating-hours';
        operatingHours.timeBlocks = [];
      }
      const allDays = Object.keys(DayOfWeek);
      const daysOperatingHoursTracked = (operatingHours.timeBlocks || []).map(
        eachBlock => eachBlock.day,
      );
      difference(allDays, daysOperatingHoursTracked as string[]).map(
        unTrackedDay =>
          operatingHours.timeBlocks.push({
            day: unTrackedDay as DayOfWeek,
            isActive: false,
            timeSlots: [{ endTime: '00:00', startTime: '00:00' }],
          }),
      );

      const isoCountryCode =
        storeData?.address?.isoCountryCode ||
        storeData?.address?.country ||
        orgCountry;

      const address: Address = {
        ...storeData?.address,
        line1: storeData?.address?.line1 || '',
        city: storeData?.address?.city || '',
        state: storeData?.address?.state || '',
        postalCode: storeData?.address?.postalCode || '',
        country: storeData?.address?.country || '',
        isoCountryCode,
      };
      setForm({
        ...storeData,
        operatingHours,
        address,
      });
      if (storeData.merchantCode) {
        checkAndSetMerchantValidation(storeData.merchantCode);
      }
    }
  }, [
    stores,
    storeId,
    DAILY_SALES_SUMMARY,
    LOW_INVENTORY_STOCK,
    PURCHASE_ORDER,
    SHIFT_SUMMARY,
    checkAndSetMerchantValidation,
    orgCountry,
  ]);

  const onChange = useCallback(
    (prop: string, value): void => {
      if (prop === 'merchantCode' && value) {
        checkAndSetMerchantValidation(value);
      }
      setForm(form => {
        const newForm = { ...form };

        // Handle nested payStoreRefs updates
        if (prop.startsWith('payStoreRefs.')) {
          const [, child] = prop.split('.');
          newForm.payStoreRefs = {
            ...newForm.payStoreRefs,
            [child]: value,
          };
          return newForm;
        }

        // Handle regular field updates
        return {
          ...form,
          [prop]: value,
        };
      });
    },
    [checkAndSetMerchantValidation],
  );

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChangeEmail = useCallback((prop: string, value: string[]): void => {
    setEmails(value);

    setForm(form => {
      return {
        ...form,
        emailPreferences: form.emailPreferences.map(pref => {
          if (pref.emailType === prop) {
            return { ...pref, emails: value };
          }
          return pref;
        }),
      };
    });
  }, []);

  const onChangeEmailPreference = useCallback(
    (emailType, isEnabled): void => {
      emailPreferencesDictionary[emailType] = {
        emailType,
        isEnabled,
      };
      setForm(form => {
        form.emailPreferences = Object.values(emailPreferencesDictionary);
        return {
          ...form,
        };
      });
    },
    [emailPreferencesDictionary],
  );

  useEffect(() => {
    if (deletedStore) {
      showSuccessNotification(
        translate('backOfficeStores.storeDeletedSuccessfully'),
      );
      navigation.navigate('VenuesStores', {});
    }
  }, [deletedStore, translate, navigation, showSuccessNotification]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={() => {
          closeModal();
          deleteStore(form.id);
        }}
      />,
    );
  }, [showModal, deleteStore, closeModal, translate, form]);

  // Update shift and daily sales summary emails
  const updateShiftProperties = useCallback(
    (form: Store) => {
      form?.emailPreferences?.forEach(ep => {
        if ([DAILY_SALES_SUMMARY, SHIFT_SUMMARY].includes(ep.emailType)) {
          ep.emails = ep.isEnabled ? emails : undefined;
        }
      });
      return form;
    },
    [DAILY_SALES_SUMMARY, SHIFT_SUMMARY, emails],
  );

  /* This is for checking email validation;
  If summary checkbox is selected then email address is must */
  const isEmailPreferenceInvalid = useCallback(
    (form: Store) => {
      let isFlagEnabled = false;
      form?.emailPreferences?.forEach(ep => {
        if ([DAILY_SALES_SUMMARY, SHIFT_SUMMARY].includes(ep.emailType)) {
          if (ep?.isEnabled) isFlagEnabled = ep.isEnabled;
        }
      });
      return isFlagEnabled && !emails.length ? true : false;
    },
    [DAILY_SALES_SUMMARY, SHIFT_SUMMARY, emails],
  );

  const isMerchantCodeVerified = useMemo(() => {
    if (form.merchantCode && isMerchantCodeValid) return true;
    else if (form.merchantCode && isMerchantCodeValid === false) return false;
    else return undefined;
  }, [form.merchantCode, isMerchantCodeValid]);

  const onPressSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showErrorNotification(
        translate('backOfficeSettings.storeNameIsRequired'),
      );
      return;
    }
    if (isEmailPreferenceInvalid(form)) {
      showErrorNotification(
        translate('backOfficeSettings.emptyEmailValidationMessage'),
      );
      return;
    }

    const formCopy = updateShiftProperties(cloneDeep(form));

    const updateStoreDetailsInput = stripProperties(
      {
        id: formCopy.id,
        name: formCopy.name,
        inventoryOptions: formCopy.inventoryOptions,
        emailPreferences: formCopy.emailPreferences,
        operatingHours: formCopy.operatingHours,
        shiftType: formCopy.shiftType,
        showHourlySplit: formCopy.showHourlySplit,
        merchantCode: formCopy.merchantCode ?? '',
        businessIdentifier: formCopy.businessIdentifier,
        phoneNumber: formCopy.phoneNumber,
        email: formCopy.email,
        address: formCopy.address,
        timezone: formCopy.timezone,
        payStoreRefs: formCopy.payStoreRefs,
      },
      '__typename',
    );

    updateStoreDetailsPage(updateStoreDetailsInput);
  }, [
    form,
    updateShiftProperties,
    updateStoreDetailsPage,
    showErrorNotification,
    translate,
    isEmailPreferenceInvalid,
  ]);

  const appendEmail = useCallback(
    (emailArr: string[], emailId: string): string[] => {
      if (emailArr.indexOf(emailId) === -1) {
        emailArr.push(emailId);
        return emailArr;
      }
      showErrorNotification(translate('storesSettings.emailAlreadyExists'));
      return emailArr;
    },
    [showErrorNotification, translate],
  );

  const getEmailType = useCallback(
    (emailType: string): EmailType => {
      switch (emailType) {
        case DAILY_SALES_SUMMARY:
          return DAILY_SALES_SUMMARY;
        case LOW_INVENTORY_STOCK:
          return LOW_INVENTORY_STOCK;
        case PURCHASE_ORDER:
          return LOW_INVENTORY_STOCK;
        case SHIFT_SUMMARY:
          return SHIFT_SUMMARY;

        default:
          return '' as EmailType;
      }
    },
    [DAILY_SALES_SUMMARY, LOW_INVENTORY_STOCK, PURCHASE_ORDER, SHIFT_SUMMARY],
  );

  const onAddEmail = useCallback(
    (emailType: string, emailId: string): void => {
      if (!isValidEmail(emailId)) {
        showErrorNotification(translate('storesSettings.invalidEmailFormat'));
        return;
      }
      const _emailType = getEmailType(emailType);

      const newEmailPreferences =
        emailPreferencesDictionary[emailType] && emails
          ? {
              ...emailPreferencesDictionary[emailType],
              emails: appendEmail(emails, emailId),
              __typename: 'EmailPreferences',
            }
          : {
              emailType: _emailType,
              isEnabled: false,
              emails: appendEmail(emails, emailId),
              __typename: 'EmailPreferences',
            };

      setEmails([...emails]);

      setForm(prevForm => {
        const formCopy = cloneJSON(prevForm) as Store;
        const existingPreferences = formCopy.emailPreferences?.find(
          ep => ep.emailType === emailType,
        );
        if (existingPreferences) {
          existingPreferences.emails = newEmailPreferences.emails;
          return formCopy;
        } else {
          return {
            ...formCopy,
            emailPreferences: [
              ...(formCopy.emailPreferences || []),
              newEmailPreferences,
            ],
          };
        }
      });
    },
    [
      appendEmail,
      emailPreferencesDictionary,
      emails,
      getEmailType,
      showErrorNotification,
      translate,
    ],
  );

  const SHIFT_TYPE_OPTIONS = [
    {
      value: ShiftType.EMPLOYEE,
      label: translate('storesSettings.byEmployee'),
    },
    {
      value: ShiftType.DEVICE,
      label: translate('storesSettings.byDevice'),
    },
    {
      value: ShiftType.STORE,
      label: translate('storesSettings.byStore'),
    },
  ];

  return (
    <ScreenLayout
      loading={loading}
      title={`${form.name || 'Store'} | Oolio`}
      onSave={onPressSave}
      onDelete={onPressDelete}
    >
      <Section title={translate('backOfficeSettings.storeDetails')}>
        <DetailsForm
          testID="Details"
          form={form}
          onChange={onChange}
          targetCountry={orgCountry}
        />
        {!isMerchantCodeVerified && (
          <View style={theme.forms.row}>
            <Message
              type="neutral"
              icon="info-circle"
              message={translate('backOfficeSettings.merchantCodeInfo')}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        )}
        {form.merchantCode && typeof isMerchantCodeVerified === 'boolean' ? (
          <Message
            message={translate(
              isMerchantCodeVerified
                ? 'backOfficeSettings.referencedRefundEnabled'
                : 'backOfficeSettings.invalidMerchantCodeMessage',
            )}
            icon={isMerchantCodeVerified ? 'check-circle' : 'times-check'}
            type={isMerchantCodeVerified ? 'positive' : 'negative'}
          />
        ) : (
          <></>
        )}
      </Section>
      <Section title={translate('backOfficeSettings.shiftType')}>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-shiftType"
            title="End Shift"
            selectedValue={form.shiftType}
            onValueChange={onChange.bind(null, 'shiftType')}
            options={SHIFT_TYPE_OPTIONS}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-salesHour"
            title={translate(
              'backOfficeReportSettings.salesHourForShiftSummary.salesHour',
            )}
            isToggled={form?.showHourlySplit ?? false}
            onToggle={(): void =>
              setForm({
                ...form,
                showHourlySplit: !form?.showHourlySplit,
              })
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeSettings.emailPreferences')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-dailySummary"
            title={translate('storesSettings.dailySalesSummary')}
            isToggled={
              emailPreferencesDictionary['DAILY_SALES_SUMMARY']?.isEnabled
            }
            onToggle={(): void =>
              onChangeEmailPreference(
                DAILY_SALES_SUMMARY,
                !emailPreferencesDictionary[DAILY_SALES_SUMMARY]?.isEnabled,
              )
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-shift-summary"
            title={translate('storesSettings.shiftSummary')}
            isToggled={emailPreferencesDictionary['SHIFT_SUMMARY']?.isEnabled}
            onToggle={(): void =>
              onChangeEmailPreference(
                SHIFT_SUMMARY,
                !emailPreferencesDictionary[SHIFT_SUMMARY]?.isEnabled,
              )
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        {emailPreferencesDictionary[DAILY_SALES_SUMMARY]?.isEnabled ||
        emailPreferencesDictionary[SHIFT_SUMMARY]?.isEnabled ? (
          <View style={theme.forms.row}>
            <SelectMultiple
              testID="select-emails"
              title={translate('storesSettings.emailAddresses')}
              options={emails.map(email => ({
                value: email,
                label: email,
              }))}
              selectedValues={emails}
              onValueChange={onChangeEmail.bind(null, DAILY_SALES_SUMMARY)}
              onAddItem={email => onAddEmail(DAILY_SALES_SUMMARY, email)}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        ) : (
          <></>
        )}
      </Section>
      {/* <Section title={translate('backOfficeSettings.operatingHours')}>
        <View>
          {form.operatingHours?.timeBlocks.map((item, index) => {
            return (
              <OperatingHoursRow
                closingTime={item.timeSlots[0].endTime}
                day={pascalCase(item.day)}
                isActive={item.isActive}
                openingTime={item.timeSlots[0].startTime}
                key={index}
                index={index}
                onChange={onChangeOperatingHours}
              />
            );
          })}
        </View>
      </Section>
      <Section title={translate('backOfficeSettings.inventoryPreferences')}>
        <InputToggle
          testID="toggle-dailySummary"
          title={translate('backOfficeSettings.negativeInventory')}
          subtitle={translate('backOfficeSettings.inventoryInfo')}
          isToggled={allowSellingWithNegativeInventory}
          onToggle={onPressInventoryOptions.bind(
            null,
            'allowSellingWithNegativeInventory',
            !allowSellingWithNegativeInventory,
          )}
        />
      </Section> */}
    </ScreenLayout>
  );
};

// Future Code

// interface OperatingHoursProps {
//   id?: string;
//   isActive: boolean;
//   day: string;
//   openingTime: string;
//   closingTime: string;
//   onChange: Function;
//   index: number;
// }

// const OperatingHoursRow: React.FC<OperatingHoursProps> = ({
//   closingTime,
//   day,
//   isActive,
//   openingTime,
//   onChange,
//   index,
// }: OperatingHoursProps) => {
//   const { css, theme } = useFela();
//   return (
//     <TableRow containerStyle={css(tableRowContainerStyle)}>
//       <View style={css(ButtonContainer)}>
//         <Button
//           title={day}
//           labelStyle={css(checkBoxTitleStyle({ theme, isActive }))}
//           onPress={onChange.bind(null, index, 'isActive', !isActive)}
//           fluid
//           iconPosition={'left'}
//           containerStyle={css(
//             checkBoxTitleContainerStyle({
//               theme,
//               noMargin: true,
//               marginLeft: -theme.spacing.small * 1.5,
//             }),
//           )}
//           iconContainerStyle={
//             isActive ? css(checkIconContainer) : css(unCheckContainer)
//           }
//           icon={isActive ? 'check' : 'null'}
//           iconProps={{
//             color: theme.colors.success,
//             size: 15,
//           }}
//         />
//         <DropDown
//           values={timeSlotOptions}
//           selectedValue={openingTime}
//           extraStyle={css(dropdownExtraStyle)}
//           extraViewStyle={css(dropdownViewStyle)}
//           style={css(timeSlotFormStyle({ theme, isActive }))}
//           onValueChange={isActive && onChange.bind(null, index, 'startTime')}
//         />

//         <DropDown
//           values={timeSlotOptions}
//           selectedValue={closingTime}
//           extraStyle={css(dropdownExtraStyle)}
//           extraViewStyle={css(dropdownViewStyle)}
//           style={css(timeSlotFormStyle({ theme, isActive }))}
//           onValueChange={isActive && onChange.bind(null, index, 'endTime')}
//         />
//       </View>
//     </TableRow>
//   );
// };

// const onChangeOperatingHours = useCallback(
//   (index: number, prop: string, value): void => {
//     const OperatingHoursTemp = form;
//     if (prop === 'isActive') {
//       OperatingHoursTemp.operatingHours.timeBlocks[index] = {
//         ...OperatingHoursTemp.operatingHours.timeBlocks[index],
//         [prop]: value,
//       };
//     } else {
//       OperatingHoursTemp.operatingHours.timeBlocks[index].timeSlots[0] = {
//         ...OperatingHoursTemp.operatingHours.timeBlocks[index].timeSlots[0],
//         [prop]: value,
//       };
//     }
//     setForm({
//       ...OperatingHoursTemp,
//     });
//   },
//   [form],
// );

// const onPressInventoryOptions = useCallback((prop: string, value): void => {
//   setForm(form => {
//     return {
//       ...form,
//       inventoryOptions: {
//         ...form.inventoryOptions,
//         [prop]: value,
//       },
//     };
//   });
// }, []);

// const allowSellingWithNegativeInventory =
//   form.inventoryOptions?.allowSellingWithNegativeInventory;
