import React from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { Store, Venue } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../common/default-theme';
import styles from './Transition.styles';
import Icon from '../../../components/Icon/Icon';
import Search from '../../../components/Shared/Search/Search';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';

interface Props {
  loading?: boolean;
  venues: Venue[];
  searchText?: string;
  onSearch?: (searchString: string) => void;
  onSelect?: (venue: Venue, store: Store) => void;
}

const StoresList: React.FC<Props> = ({
  loading = false,
  venues,
  searchText,
  onSearch = () => undefined,
  onSelect = () => undefined,
}) => {
  const { translate } = useTranslation();

  return (
    <View>
      <View style={styles.filters}>
        <Search
          testID="search"
          maxLength={50}
          onChangeText={onSearch}
          placeholder={translate('transition.search', {
            entity: translate('menus.stores').toLowerCase(),
          })}
          containerStyle={styles.search}
        />
      </View>
      {loading ? (
        <View style={theme.tables.emptyView}>
          <LoadingIndicator />
        </View>
      ) : (
        <ScrollView style={styles.list}>
          {venues.length > 0 ? (
            venues.map(venue => (
              <View key={venue.id} style={styles.rowVenue}>
                <View style={theme.tables.header}>
                  <Text style={theme.tables.headerText}>{venue.name}</Text>
                </View>
                {venue.stores.map(store => (
                  <TouchableOpacity
                    key={store.id}
                    style={styles.rowStore}
                    onPress={() => onSelect(venue, store)}
                  >
                    <View style={styles.cellName}>
                      <Text style={styles.rowTitle}>{store.name}</Text>
                    </View>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey4}
                    />
                  </TouchableOpacity>
                ))}
              </View>
            ))
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {translate('common.emptyTableSearch', {
                  entity: translate('menus.stores').toLowerCase(),
                  searchText: searchText,
                })}
              </Text>
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default StoresList;
