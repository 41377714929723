/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import styles from './ActionsBar.styles';
import NewOrderButton from '../NewOrderButton/NewOrderButton';
import ButtonIcon from '../../Shared/TreatButton/ButtonIcon';
import SystemStatus from '../SystemStatus/SystemStatus';

export interface ActionsBarProps {
  title?: string;
  loading?: boolean;
  onBack?: () => void;
  tabs?: React.ReactNode;
  hideNewOrder?: boolean;
  hideRHS?: boolean;
  actionsLeft?: React.ReactNode;
  actionsRight?: React.ReactNode;
}

const ActionsBar: React.FC<ActionsBarProps> = ({
  tabs,
  title,
  loading,
  actionsLeft,
  actionsRight,
  hideNewOrder,
  hideRHS = false,
  onBack,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const onPressNavigation = useCallback(() => {
    navigation.toggleDrawer();
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.lhs}>
        {onBack && (
          <ButtonIcon
            size={44}
            type="interface"
            icon="arrow-left"
            onPress={onBack}
            testID="btn-back"
            disabled={loading}
            containerStyle={{ marginRight: 6 }}
          />
        )}
        {actionsLeft}
      </View>
      <View style={styles.center}>
        {tabs ? tabs : <Text style={styles.title}>{title || 'Screen'}</Text>}
      </View>
      <View style={styles.rhs}>
        {actionsRight}
        {!hideRHS ? (
          <>
            {!hideNewOrder ? <NewOrderButton /> : <></>}
            <SystemStatus />
            <ButtonIcon
              size={44}
              icon="ellipsis-h"
              type="interface"
              testID="btn-menu"
              disabled={loading}
              onPress={onPressNavigation}
              containerStyle={styles.btnMenu}
            />
          </>
        ) : null}
      </View>
    </View>
  );
};

export default ActionsBar;
