import React, { useCallback, useEffect, useState } from 'react';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import { Option } from '../../../../../components/Shared/Select/Select';
import TreatPicker from '../../../../../components/Shared/Select/Picker';

export interface AssignCategoryModalProps {
  onSelectCategory: (selectedCategory: string) => void;
  categoryOptions: Option[];
}

const AssignCategoryModal: React.FC<AssignCategoryModalProps> = ({
  categoryOptions,
  onSelectCategory,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState('');

  const onChangeCategory = useCallback((categoryId: string) => {
    setSelectedCategory(categoryId);
  }, []);

  useEffect(() => {
    const firstCategoryOption = categoryOptions[0]?.value;
    setSelectedCategory(firstCategoryOption);
  }, [categoryOptions]);

  return (
    <TreatModal
      title={translate('productBulkOperations.addProductsToCategory')}
      onConfirm={{
        label: translate('productBulkOperations.addToCategory'),
        action: onSelectCategory.bind(null, selectedCategory),
      }}
      onDismiss={{ action: closeModal }}
    >
      <TreatPicker
        testID="product-bulk-add-category"
        title={translate('backOfficeProducts.selectCategory')}
        options={categoryOptions}
        selectedValue={selectedCategory}
        onValueChange={onChangeCategory}
      />
    </TreatModal>
  );
};

export default AssignCategoryModal;
