import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  DEFAULT_TABLE_ABBREVIATION,
  OrderItem,
  PaymentAction,
  OrderPaymentStatus,
  OrderItemStatus,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { computeTotalOrderItemsAmountWithReward } from '@oolio-group/order-helper';
import groupBy from 'lodash/groupBy';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../../../screens/POS/Payments/PaymentScreen';
import theme from '../../../../common/default-theme';
import styles from './SplitBySeatOptions.styles';
import { Icon } from '../../../Icon/Icon';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

interface SplitBySeatOptionsProp {
  orderItems: OrderItem[];
  paymentState: OrderPaymentDetails;
  dispatch: React.Dispatch<PaymentActionProp>;
  discountAmount: number;
  surchargeAmount: number;
  subTotal: number;
}

const proportionateAmount = (subTotal: number, amount: number) => {
  return (value: number) => (value * amount) / subTotal;
};

const SplitBySeatOptions: React.FC<SplitBySeatOptionsProp> = ({
  orderItems,
  paymentState,
  dispatch,
  discountAmount,
  surchargeAmount,
  subTotal,
}) => {
  const { translate } = useTranslation();

  const itemBySeats = useMemo(
    () =>
      groupBy(
        orderItems.filter(
          orderItem =>
            ![OrderItemStatus.CANCELLED, OrderItemStatus.VOID].includes(
              orderItem.status,
            ),
        ),
        item => item.seatNumber || DEFAULT_TABLE_ABBREVIATION,
      ),
    [orderItems],
  );

  const occupiedSeats = useMemo(() => {
    return Object.keys(itemBySeats)
      .filter(seat => seat !== DEFAULT_TABLE_ABBREVIATION)
      .map(seatNumber => ({
        seatNumber,
        isPaid: itemBySeats[seatNumber].every(
          orderItem => orderItem.paymentStatus === OrderPaymentStatus.COMPLETE,
        ),
      }));
  }, [itemBySeats]);

  const onPaymentBySeat = useCallback(
    (selectedSeat: number) => {
      const unpaidSeats = occupiedSeats.filter(({ isPaid }) => !isPaid);
      let processingAmount = 0;
      let processingOrderItemIds: string[] = [];
      const isLastSeatPayment = unpaidSeats.length === 1;
      // for the last seat payment
      if (isLastSeatPayment) {
        processingAmount = paymentState.remainingDueAmount;
        processingOrderItemIds = orderItems
          ?.filter(
            orderItem =>
              orderItem.paymentStatus !== OrderPaymentStatus.COMPLETE &&
              ![OrderItemStatus.CANCELLED, OrderItemStatus.VOID].includes(
                orderItem.status,
              ),
          )
          .map(item => item.id) as string[];

        return dispatch({
          type: PaymentAction.SET_SEAT_AMOUNT,
          payload: {
            processingAmount,
            processingOrderItemIds,
            processingPaymentSeat: selectedSeat,
          },
        });
      }

      const activeOrderItems = (itemBySeats[selectedSeat] || []).filter(
        item => item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      );

      const proportionDiscountFn = proportionateAmount(
        subTotal,
        discountAmount,
      );
      const proportionSurchargeFn = proportionateAmount(
        subTotal,
        surchargeAmount,
      );

      let extraShareAmount = 0;

      if (Boolean(itemBySeats[DEFAULT_TABLE_ABBREVIATION])) {
        const validShareItems = itemBySeats[DEFAULT_TABLE_ABBREVIATION].filter(
          item => item.paymentStatus !== OrderPaymentStatus.COMPLETE,
        );
        const totalShareAmount =
          computeTotalOrderItemsAmountWithReward(validShareItems);
        const numberOfSeatOccupied = Object.keys(itemBySeats).length - 1;
        extraShareAmount = numberOfSeatOccupied
          ? totalShareAmount / numberOfSeatOccupied
          : 0;
      }
      const totalAmountBySeat =
        computeTotalOrderItemsAmountWithReward(activeOrderItems);
      const discountBySeat = proportionDiscountFn(
        totalAmountBySeat + extraShareAmount,
      );
      const surchargeBySeat = proportionSurchargeFn(
        totalAmountBySeat + extraShareAmount,
      );
      processingAmount =
        totalAmountBySeat + surchargeBySeat - discountBySeat + extraShareAmount;
      processingOrderItemIds = activeOrderItems.map(item => item.id);
      dispatch({
        type: PaymentAction.SET_SEAT_AMOUNT,
        payload: {
          processingAmount,
          processingOrderItemIds,
          processingPaymentSeat: selectedSeat,
        },
      });
    },
    [
      discountAmount,
      dispatch,
      itemBySeats,
      occupiedSeats,
      orderItems,
      paymentState.remainingDueAmount,
      subTotal,
      surchargeAmount,
    ],
  );

  const seatsToShow = [...occupiedSeats];
  if (occupiedSeats.length < 10) {
    while (seatsToShow.length < 10) {
      seatsToShow.push({
        seatNumber: '',
        isPaid: false,
      });
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Select Seat to Pay</Text>
      <View testID="seat-list" style={styles.seats}>
        {seatsToShow.map(({ seatNumber, isPaid }, i) => {
          if (seatNumber !== '') {
            return (
              <View key={`seat-${i}`}>
                {isPaid ? (
                  <View style={styles.status}>
                    <Icon name="check" size={16} color={theme.colors.white} />
                  </View>
                ) : null}
                <TreatButton
                  key={`seat-${i}`}
                  testID={`btn-seat${seatNumber}`}
                  height={90}
                  disabled={isPaid}
                  type="neutral"
                  onPress={() => onPaymentBySeat(parseInt(seatNumber))}
                  label={`${translate('payment.seat')} ${seatNumber}`}
                  containerStyle={styles.seat}
                />
              </View>
            );
          } else {
            return (
              <View style={[styles.seat, styles.emptyTile]} key={`seat-${i}`} />
            );
          }
        })}
      </View>
    </View>
  );
};

export default SplitBySeatOptions;
