import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const textStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  btnCreate: {
    marginLeft: 10,
  },
  message: {
    width: theme.layoutWidth.s,
    marginBottom: 20,
  },
  headerName: {
    flex: 1,
    marginLeft: 32,
  },
  headerProducts: {
    width: 80,
    marginRight: 66,
    textAlign: 'center',
  },
  cellName: {
    flex: 1,
    ...textStyle,
  },
  cellProducts: {
    width: 80,
    ...textStyle,
    marginRight: 10,
    textAlign: 'center',
    fontVariant: ['tabular-nums'],
  },
  // Schedule Table
  table: {
    zIndex: -1,
  },
  btnAddSlot: {
    marginTop: 20,
    marginLeft: 'auto',
  },
  headerDay: {
    width: 262,
    marginRight: 10,
  },
  headerTime: {
    width: 100,
    marginRight: 10,
    textAlign: 'center',
  },
  cellDay: {
    flex: 1,
    marginRight: 10,
  },
  cellTime: {
    width: 100,
    marginRight: 10,
  },
  // Products Filters
  filters: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    width: 200,
    marginRight: 10,
  },
  search: {
    flex: 1,
  },
  btnOptions: {
    marginLeft: 10,
  },
  // Products Table
  cellProduct: {
    flex: 1,
  },
  cellProductText: {
    flex: 1,
    ...textStyle,
  },
  cellPrice: {
    width: 120,
    marginLeft: 20,
    textAlign: 'center',
  },
  cellPriceText: {
    textAlign: 'center',
    fontVariant: ['tabular-nums'],
    ...textStyle,
  },
});

export default styles;
