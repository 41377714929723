import { Theme } from '@oolio-group/domain';
import themes from '@oolio-group/branding';

export const defaultBranding = process.env.BRANDING || 'tillpos';

export const loadTheme = (themeName: string, brand?: string): Theme => {
  const branding = brand || defaultBranding;
  // Load theme file for the branding
  return themes[branding][themeName === 'no-preference' ? 'light' : themeName];
};
