import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const textStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  filters: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    marginBottom: 20,
  },
  dropdown: {
    width: 180,
  },
  search: {
    flex: 1,
  },
  headerName: {
    width: 160,
    marginLeft: 20,
  },
  headerType: {
    flex: 1,
    marginLeft: 20,
  },
  headerCount: {
    width: 80,
    marginRight: 22,
    textAlign: 'center',
  },
  cellName: {
    width: 160,
    marginRight: 20,
    ...textStyle,
  },
  cellType: {
    flex: 1,
    ...textStyle,
  },
  cellCount: {
    width: 80,
    textAlign: 'center',
    fontVariant: ['tabular-nums'],
    ...textStyle,
  },
  rowLight: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginHorizontal: 12,
    backgroundColor: theme.colors.red,
  },
});

export default styles;
