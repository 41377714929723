import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
    marginLeft: 10,
  },
  btnRefresh: {
    marginHorizontal: 10,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginVertical: 8,
  },
  tableRow: {
    height: 50,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  cellAge: {
    width: 70,
    marginLeft: 12,
    textAlign: 'center',
  },
  cellHundred: {
    width: 100,
    marginLeft: 10,
  },
  cellType: {
    width: 80,
    marginLeft: 10,
  },
  cellDue: {
    width: 110,
    marginLeft: 10,
  },
  cellAmount: {
    flex: 1,
    marginLeft: 10,
    fontVariant: ['tabular-nums'],
  },
});

export default styles;
