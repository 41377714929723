import React, { useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import { OrderStatus, DateFilter } from '@oolio-group/domain';
import SelectList from '../../../../../components/Shared/SelectList/SelectList';
import { useTranslation } from '@oolio-group/localization';
import { analyticsService } from '../../../../../analytics/AnalyticsService';
import { capitalCase } from 'change-case';
import styles from '../OnlineOrders.styles';

const allOption = {
  value: '',
  label: 'All',
};

export interface OrderFiltersProps {
  onChangeOfFilter: (key: string, value: string) => void;
  salesChannelOptions: { value: string; label: string }[];
  filters: {
    status: string;
    dateFilter: string;
    salesChannel: string;
    searchValue: string;
  };
}

const OnlineOrdersFilters: React.FC<OrderFiltersProps> = ({
  salesChannelOptions,
  filters,
  onChangeOfFilter,
}) => {
  const { translate } = useTranslation();

  const statusOptions = useMemo(
    () => [
      allOption,
      {
        label: translate('onlineOrders.pending'),
        value: OrderStatus.CREATED,
      },
      {
        label: translate('onlineOrders.inProgress'),
        value: OrderStatus.IN_PROGRESS,
      },
      {
        label: translate('onlineOrders.completed'),
        value: OrderStatus.COMPLETED,
      },
      {
        label: translate('onlineOrders.cancelled'),
        value: OrderStatus.CANCELLED,
      },
      {
        label: 'Partner Cancelled',
        value: OrderStatus.PARTNER_CANCELLED,
      },
    ],
    [translate],
  );

  const dateOptions = useMemo(
    () => [
      {
        label: translate('onlineOrders.today'),
        value: DateFilter.TODAY,
      },
      {
        label: translate('onlineOrders.upcoming'),
        value: DateFilter.UPCOMING,
      },
      {
        label: translate('onlineOrders.olderOrders'),
        value: DateFilter.OLDER,
      },
    ],
    [translate],
  );

  return (
    <View style={styles.filters}>
      <ScrollView>
        <SelectList
          values={dateOptions}
          selectedValue={filters.dateFilter || DateFilter.TODAY}
          title={translate('onlineOrders.date')}
          onPress={value => {
            analyticsService.capture('oo_filter_date', {
              value: capitalCase(value),
            });
            onChangeOfFilter('dateFilter', value);
          }}
        />
        <SelectList
          values={statusOptions}
          selectedValue={filters.status}
          title={translate('onlineOrders.status')}
          onPress={value => {
            analyticsService.capture('oo_filter_status', {
              value: capitalCase(value),
            });
            onChangeOfFilter('status', value);
          }}
        />
        <SelectList
          values={[allOption, ...salesChannelOptions]}
          selectedValue={filters.salesChannel}
          title={translate('onlineOrders.salesChannel')}
          onPress={value => {
            analyticsService.capture('oo_filter_channel', {
              value: capitalCase(value),
            });
            onChangeOfFilter('salesChannel', value);
          }}
        />
      </ScrollView>
    </View>
  );
};

export default OnlineOrdersFilters;
