/* istanbul ignore file */
import { Resolvers } from '@apollo/client';
import GMR from 'graphql-merge-resolvers';
import * as MoneyMovements from './manageMoney';
import * as Orders from './orders';

export const resolvers = GMR.merge([
  MoneyMovements.resolvers,
  Orders.resolvers,
]) as Resolvers;
