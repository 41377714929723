import React from 'react';
import { View, Text } from 'react-native';
import styles from './ModalDialog.styles';
import { IconStatusProps } from '../../Icons/IconStatus/IconStatus';
import TreatButton from '../../TreatButton/TreatButton';

type ModalButton = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  action: () => void | Promise<void>;
};

interface Props {
  testID: string;
  type: 'positive' | 'neutral' | 'focus' | 'negative';
  icon: React.ReactElement<IconStatusProps>;
  title: string;
  message: string;
  onDismiss: ModalButton;
  onConfirm?: ModalButton;
}

const ModalDialog: React.FC<Props> = ({
  testID,
  type,
  icon,
  title,
  message,
  onDismiss,
  onConfirm,
}) => {
  return (
    <View testID={testID} style={styles.container}>
      <View style={styles.content}>
        {icon}
        <Text testID="mdl-title" style={styles.title}>
          {title}
        </Text>
        <Text style={styles.message}>{message}</Text>
      </View>
      <View style={styles.actions}>
        {onConfirm && (
          <TreatButton
            testID="btn-modalConfirm"
            height={44}
            type={type}
            label={onConfirm.label || 'Confirm'}
            onPress={onConfirm.action}
            disabled={onConfirm.disabled}
            isLoading={onConfirm.loading || false}
          />
        )}
        <TreatButton
          testID="btn-modalCancel"
          height={44}
          type="cancel"
          label={onDismiss.label || 'Dismiss'}
          onPress={onDismiss.action}
          disabled={onDismiss.disabled}
          isLoading={onDismiss.loading || false}
        />
      </View>
    </View>
  );
};

export default ModalDialog;
