import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.black,
  },
  menu: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-end',
    padding: 20,
  },
  menuGroup: {
    marginTop: 32,
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tabTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  tabIcon: {
    width: 44,
    height: 44,
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: 24,
    paddingHorizontal: 16,
    borderTopWidth: 1,
    borderTopColor: theme.colors.black2,
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 12,
    textAlign: 'center',
    color: theme.colors.white,
  },
  infoSubtitle: {
    fontSize: 12,
    marginTop: 4,
    lineHeight: 14,
    textAlign: 'center',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  menuSpacing: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
});

export default styles;
