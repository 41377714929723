import React, { useCallback, useMemo, useState, useRef } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import Item from './CommonItem';
import { translate } from '@oolio-group/localization';
import Icon from '../../../../../../components/Icon/Icon';
import { MenuItemAction, TileItem } from '../../types';
import styles from './GridItem.styles';
import theme from '../../../../../../common/default-theme';

interface MenuItemProps {
  onLongPress: () => void;
  isSelected?: boolean;
  item: TileItem;
  onSelectOption: (option: MenuItemAction) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  onLongPress,
  isSelected,
  item,
  onSelectOption,
}) => {
  const [showOption, setShowOption] = useState(false);
  const selectedOptionRef = useRef<MenuItemAction | undefined>();
  const { name, color } = item;

  const onToggleOptions = () => {
    setShowOption(!showOption);
  };

  const onRequestClosePopover = () => {
    setShowOption(false);
  };

  const options = useMemo(() => {
    return [
      {
        label: translate('menus.viewPage'),
        name: MenuItemAction.VIEW_PAGE,
      },
      {
        label: translate('menus.editPage'),
        name: MenuItemAction.EDIT_PAGE,
      },
      {
        label: translate('menus.clearTile'),
        color: theme.colors.states.negative,
        name: MenuItemAction.CLEAR_TILE,
      },
    ];
  }, []);

  const onSelectPageOption = useCallback(option => {
    selectedOptionRef.current = option.name as MenuItemAction;
    onRequestClosePopover();
  }, []);

  const onClosePopoverCompleted = useCallback(() => {
    onSelectOption(selectedOptionRef.current as MenuItemAction);
  }, [onSelectOption]);

  const onOpenPopoverStart = useCallback(() => {
    selectedOptionRef.current = undefined;
  }, []);

  return (
    <Popover
      isVisible={showOption}
      placement={PopoverPlacement.AUTO}
      onRequestClose={onRequestClosePopover}
      from={
        <Item
          name={name}
          color={color}
          isSelected={isSelected}
          onPress={onToggleOptions}
          onLongPress={onLongPress}
        />
      }
      backgroundStyle={styles.popupBackground}
      popoverStyle={styles.popupContainer}
      onCloseComplete={onClosePopoverCompleted}
      onOpenStart={onOpenPopoverStart}
    >
      <View style={styles.popupOptions}>
        {options.map((option, i: number) => (
          <TouchableOpacity
            key={i}
            testID="option-item"
            style={styles.popupOption}
            onPress={() => onSelectPageOption(option)}
          >
            <Text
              style={[
                styles.popupLabel,
                { color: option.color || theme.colors.dark },
              ]}
            >
              {option.label}
            </Text>
            <Icon name="angle-right" size={24} color={theme.colors.grey4} />
          </TouchableOpacity>
        ))}
      </View>
    </Popover>
  );
};

export default MenuItem;
