import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Role, Venue } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import {
  StoreUserRoleMapping,
  StoreUserRoleMappingByRole,
  userRoleInputUtility,
} from '../userRoleInputUtility';
import theme from '../../../../../../../common/default-theme';
import styles from './UserPermissionRow.styles';
import ButtonIcon from '../../../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import SelectLocation, {
  OptionsSectionProps,
  SelectLocationOptionsProps,
} from '../../../../../../../components/Shared/Select/SelectLocation';

const NewUserPermissionRow: FC<{
  rolesById: Record<string, Role>;
  venues: Record<string, Venue>;
  newUserRoleData: StoreUserRoleMappingByRole;
}> = ({ rolesById, venues, newUserRoleData }) => {
  const { translate } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [roleOptions, setRoleOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [venuesOptionsList, setVenuesOptionsList] = useState<
    SelectLocationOptionsProps[]
  >([]);
  useEffect(() => {
    setVenuesOptionsList(
      Object.values(venues).map(venue => {
        const checkedStores = newUserRoleData.locations.map(
          location => location.storeId,
        );
        const hasAccessToAllStores = venue.stores.every(store =>
          checkedStores.includes(store.id),
        );
        return {
          id: venue.id,
          label: venue.name,
          checked: hasAccessToAllStores,
          data: venue.stores.map(store => ({
            id: store.id,
            label: store.name,
            checked: checkedStores.includes(store.id),
          })),
        };
      }),
    );
  }, [venues, newUserRoleData.locations]);

  useEffect(() => {
    const options = Object.values(rolesById).reduce(
      (acc, role) => {
        return [
          ...acc,
          {
            value: role.id,
            label: role.name,
          },
        ];
      },
      [{ value: '', label: translate('backOfficeUsers.selectRole') }],
    );
    setRoleOptions(options);
  }, [rolesById, translate]);

  const onChangeRole = useCallback(
    (roleId: string) => {
      setSelectedRole(roleId);
      userRoleInputUtility.modifyRole(newUserRoleData.id, roleId);
    },
    [newUserRoleData.id],
  );

  const onDelete = useCallback(() => {
    userRoleInputUtility.deleteInput(newUserRoleData.id);
  }, [newUserRoleData.id]);

  const onChangeLocation = useCallback(
    (sections: OptionsSectionProps[]) => {
      const selectedLocations = sections.reduce<StoreUserRoleMapping[]>(
        (acc, venue) => {
          const locations = venue.data
            .filter(store => store.checked === true)
            .map(store => {
              return {
                venueId: venue.id,
                storeId: store.id,
              };
            });
          return [...acc, ...locations];
        },
        [],
      );
      userRoleInputUtility.modifyStores(newUserRoleData.id, selectedLocations);
    },
    [newUserRoleData.id],
  );

  return (
    <View style={theme.tables.row}>
      <TreatPicker
        testID="select-role"
        options={roleOptions}
        selectedValue={selectedRole}
        onValueChange={onChangeRole}
        containerStyle={styles.cellJobRole}
      />
      <SelectLocation
        options={venuesOptionsList}
        onValueChange={onChangeLocation}
        containerStyle={styles.cellLocation}
        sectionHeaderLabelSuffix={`(${translate('backOfficeUsers.allStores')})`}
      />
      <ButtonIcon
        testID="deleteUserRolesButton"
        icon="times"
        onPress={onDelete}
        type="negativeLight"
      />
    </View>
  );
};

export default NewUserPermissionRow;
