import { table, getBorderCharacters, TableUserConfig } from 'table';

const config: TableUserConfig = {
  columns: {
    0: {
      wrapWord: true,
      width: 40,
      alignment: 'left',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

const loyaltyConfig: TableUserConfig = {
  columns: {
    0: {
      wrapWord: true,
      width: 40,
      alignment: 'center',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateHeaderText = (headerText: string[]): string => {
  return table([headerText], config);
};

export const generateFooterText = (footerText: string[]): string => {
  return table([footerText], config);
};

export const generateLoyaltyText = (loyaltyText: string[]): string => {
  return table([loyaltyText], loyaltyConfig);
};
