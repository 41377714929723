import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  PrinterTemplate,
  UpdatePrinterTemplateInput,
} from '@oolio-group/domain';
import { isLoading, getError } from '../../utils/apolloErrorResponse.util';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useState, useCallback, useMemo } from 'react';
import {
  GET_PRINTER_TEMPLATE,
  GET_PRINTER_TEMPLATES,
  UPDATE_PRINTER_TEMPLATE,
} from '../../graphql/printerTemplates';

export interface UsePrinterTemplates {
  printerTemplates: Array<PrinterTemplate>;
  loading: boolean;
  error: string | undefined;
  getPrinterTemplates: () => void;
  printerTemplate?: PrinterTemplate;
  getPrinterTemplate: (templateId: string) => void;
  updatePrinterTemplate: (
    templateInput: UpdatePrinterTemplateInput,
  ) => Promise<boolean>;
}

/**
 * Get printer template(s)
 */
export function usePrinterTemplates(): UsePrinterTemplates {
  const [printerTemplates, setPrinterTemplates] = useState<
    Array<PrinterTemplate>
  >([]);
  const [printerTemplate, setPrinterTemplate] = useState<PrinterTemplate>();

  const getAllPrinterTemplatesResReceived = useCallback(data => {
    if (data) {
      setPrinterTemplates(data.printerTemplates);
    }
  }, []);

  const getAllPrinterTemplateResReceived = useCallback(data => {
    if (data) {
      setPrinterTemplate(data.printerTemplate);
    }
  }, []);

  const [getPrinterTemplates, getAllPrinterTemplatesRes] = useLazyQuery(
    GET_PRINTER_TEMPLATES,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: getAllPrinterTemplatesResReceived,
    },
  );

  const [getPrinterTemplateReq, getPrinterTemplateRes] = useLazyQuery(
    GET_PRINTER_TEMPLATE,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: getAllPrinterTemplateResReceived,
    },
  );

  /**
   * Get printer template by id
   */
  const getPrinterTemplate = useCallback(
    (templateId: string) => {
      getPrinterTemplateReq({
        variables: {
          id: templateId,
        },
      });
    },
    [getPrinterTemplateReq],
  );

  const [updatePrinterTemplateRequest, updatePrinterTemplateResponse] =
    useMutation(UPDATE_PRINTER_TEMPLATE, {
      onError: noopHandler,
    });

  const updatePrinterTemplate = useCallback(
    async (printerTemplate: UpdatePrinterTemplateInput) => {
      const response = await updatePrinterTemplateRequest({
        variables: {
          input: printerTemplate,
        },
      });

      if (response) {
        return true;
      }
      return false;
    },
    [updatePrinterTemplateRequest],
  );

  const RESPONSE_ENTITIES = [
    getAllPrinterTemplatesRes,
    getPrinterTemplateRes,
    updatePrinterTemplateResponse,
  ];
  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      printerTemplate,
      getPrinterTemplates,
      loading,
      error: error ? parseApolloError(error) : undefined,
      printerTemplates,
      getPrinterTemplate,
      updatePrinterTemplate,
    }),
    [
      loading,
      error,
      getPrinterTemplates,
      printerTemplates,
      getPrinterTemplate,
      printerTemplate,
      updatePrinterTemplate,
    ],
  );
}
