import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filters: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  headerName: {
    width: 240 - 12,
  },
  headerValues: {
    flex: 1,
    marginLeft: 20,
  },
  cellName: {
    width: 240,
    paddingLeft: 12,
  },
  tableVariations: {
    marginTop: -20,
  },
  cellValues: {
    flex: 1,
    marginHorizontal: 20,
  },
  inputName: {
    flex: 1,
    marginRight: 10,
  },
});

export default styles;
