import React from 'react';
import { View, Text } from 'react-native';
import styles from './DeviceStatusModal.styles';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import DeviceStatusModalRow, {
  DeviceStatusMessage,
} from './DeviceStatusModalRow';
import { IconStatus } from '../../../../components/Shared/Icons/IconStatus/IconStatus';

interface Props {
  icon:
    | 'success'
    | 'success-dark'
    | 'warning'
    | 'error'
    | 'error-dark'
    | 'help'
    | 'active'
    | 'inactive';
  title: string;
  subtitle: string;
  messages: DeviceStatusMessage[];
  onDismiss: () => void;
}

const DeviceStatusModal: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  messages,
  onDismiss,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <IconStatus name={icon} />
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
      <View style={styles.messages}>
        {messages.map(msg => (
          <DeviceStatusModalRow
            type={msg.type}
            message={msg.message}
            key={msg.message}
          />
        ))}
      </View>
      <TreatButton
        testID="btn-dismiss"
        type="cancel"
        label="Dismiss"
        onPress={onDismiss}
      />
    </View>
  );
};

export default DeviceStatusModal;
