import { gql } from '@apollo/client';

const pricingGroupFragment = `
  id
  name
  isActive
  venues{
    id
  }
  orderTypes {
    id
  }
  salesChannels {
    id
  }
  stores {
    id
  }
  schedule {
    dateRange {
      startDate
      endDate
    }
    timeBlocks {
      day
      isActive
      timeSlot {
        startTime
        endTime
      }
    }
  }
`;
const productModifiers = `
modifierGroups{
  id
  name
  isGrouped
  isRequired,
  priority
  maxSelectionPerOption
  products {
    id
    name
    priority
    price {
      amount
      currency
    }
    isDefault
    productDetail {
      id
      name
      isSellable
      category {
        id
        name
      }
      imageUrl
      variantId
      pricings {
        id
        taxInclusive
        sellingPrice {
          amount
          currency
          __typename
        }
        sellingTax {
          id
          name
          code
          rate
          __typename
        }
        pricingGroup {
          ${pricingGroupFragment}
        }
      }
    }
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  modifiers {
    id
    name
    isDefault
    priority
    price {
      amount
      currency
    }
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
    pricingGroups {
      id
      name
      prices {
        id
        sellingPrice{
          amount
          currency
        }
        sellingTax {
          id
          name
          code
          rate
        }
      }
    }
  }
  selectionLimit {
    min
    max
  }
}`;
const productFragment = `
  id
  name
  isSellable
  isDefault
  isBeingTracked
  isAvailable
  isCombo
  comboType
  barcode
  category {
      id
      name
  }
  imageUrl
  storesInventory
  ${productModifiers}
  stores {
    id
  }
  allergens
  recipe
  nutrientInfo {
      name
      value
      unit
  }
  optionValues {
    parentOptionId
    key
    value
  }
  dietaryTags {
      id
      name
  }
  minSellingPrice
  maxSellingPrice
  measuredBy {
    unitType
    defaultSize
    units
  }
  variablePricing
  variableQuantity
  variantId
  printerProfiles {
    id
  }
  pricings {
    id
    taxInclusive
    sellingPrice {
      amount
      currency
    }
    costPrice {
      amount
      currency
    }
    sellingTax {
      id
      name
      code
      rate
    }
    pricingGroup {
      ${pricingGroupFragment}
    }
  }
  course {
    id
    name
    priority
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;

export const GET_MENU_OPTIONS = gql`
  query menusOptions {
    catalogues {
      id
      name
      catalogueType
      stores {
        id
        name
      }
    }
  }
`;

const variantFragment = `
  id
  name
  stores {
    id
  }
  description
  products {
    ${productFragment}
  }
  options {
    id
    key
    values
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;

const singlePageFragment = `
      id
      name
      color
      items {
        id
        entityId
        color
        priority
        entityType
        category
      }
      categories
      products {
        ${productFragment}
      }
      variants {
        ${variantFragment}
      }
      alternateNames {
        locale
        display
        kitchen
        receipt
        description
      }
  `;

const generatePageFragment = (nestedLevel: number): string => {
  if (nestedLevel === 1) return singlePageFragment;
  return `${singlePageFragment} pages {${generatePageFragment(
    nestedLevel - 1,
  )}}`;
};

const menuFragment = `
  id
  name
  catalogueType
  venues {
    id
    name
  }
  stores {
    id
    name
  }
  items {
    id
    priority
    color
    page {
      ${generatePageFragment(5)}
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products {
    products {
      ${productFragment}
  }
}`;

export const GET_CATALOGUE_QUERY = gql`
  query catalogue($id: ID!) {
    catalogue(id: $id) {
      ${menuFragment}
    }
  }
`;

export const CATALOGUE_EVENT = gql`
  subscription updateCatalogueEvent($catalogueId: String!, $deviceId: String) {
    updateCatalogueEvent(catalogueId: $catalogueId, deviceId: $deviceId) {
      id
    }
  }
`;
