import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 32,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  entity: {
    width: 24,
    borderRadius: 24,
  },
  text: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.colors.grey6,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
  },
});

export default styles;
