import { addMinutes, format, parseISO } from 'date-fns';

export interface FormattedDateTime {
  reservationTime: string;
  dateString: string;
  reservationDateTime: string;
}

/**
 * Formats the reservation start time, end time, and duration.
 *
 * @param startTime - The start time of the reservation in the format 'yyyy-MM-dd HH:mm:ss'.
 * @param duration - The duration of the reservation in minutes.
 * @returns A tuple containing the formatted reservation time and an additional date string.
 */
export function formatReservationTimeAndDate(
  startTime: string,
  duration: number,
): FormattedDateTime {
  try {
    const startTimeDate = parseISO(startTime);
    const endTimeDate = addMinutes(startTimeDate, duration);
    const formattedStartTime = format(startTimeDate, 'HH:mm');
    const formattedEndTime = format(endTimeDate, 'HH:mm');
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    const formattedReservationTime = `${formattedStartTime} -> ${formattedEndTime} (${formattedDuration})`;
    const additionalDateString = format(startTimeDate, 'EEE, MMM dd');
    const formattedDateTime = {
      reservationTime: formattedReservationTime,
      dateString: additionalDateString,
      reservationDateTime: `${additionalDateString} ${formattedStartTime} -> ${formattedEndTime}`,
    };
    return formattedDateTime;
  } catch (err) {
    console.error(err);
    return {
      reservationTime: '',
      dateString: '',
      reservationDateTime: '',
    };
  }
}
