import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useOptions } from '../../../../../hooks/app/useOptions';
import { VariantGroupParams } from './VariantGroupTabs';
import styles from './Variants.styles';
import theme from '../../../../../common/default-theme';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';

const hasEmptyVariant = (variants: string[]) => {
  return variants.some(variant => variant.trim().length === 0);
};

const hasDuplicateVariant = (variants: string[]) => {
  const uniqueVariants = new Set(variants);
  return uniqueVariants.size !== variants.length;
};

export const VariantGroup: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { error, loading, getOptionById, updateOption } = useOptions();

  const { id, name } = route?.params as VariantGroupParams;
  const group = getOptionById(id);

  const [form, setForm] = useState({
    key: group?.key || '',
    values: group?.values || [],
  });
  const [newValue, setNewValue] = useState('');
  const [newVariations, setNewVariations] = useState<string[]>([]);

  useEffect(() => {
    if (group) {
      setForm({ key: group.key, values: group.values });
    }
  }, [group]);

  const handleKeyChange = useCallback((newKey: string) => {
    setForm(prev => ({ ...prev, key: newKey }));
  }, []);

  const handleValueChange = useCallback(
    (newValue: string, index: number) => {
      const newValues = [...form.values];
      newValues[index] = newValue.trim();
      setForm(prev => ({ ...prev, values: newValues }));
    },
    [form.values],
  );

  const handleNewVariationChange = useCallback(
    (newValue: string, index: number) => {
      const updatedVariations = [...newVariations];
      updatedVariations[index] = newValue.trim();
      setNewVariations(updatedVariations);
    },
    [newVariations],
  );

  const onDeleteVariant = useCallback(
    (value: string, index: number) => {
      if (index < form.values.length) {
        setForm(prev => ({
          ...prev,
          values: prev.values.filter((_, i) => i !== index),
        }));
      } else {
        const newVariationIndex = index - form.values.length;
        setNewVariations(curr =>
          curr.filter((_, i) => i !== newVariationIndex),
        );
      }
    },
    [form.values],
  );

  const onAddVariant = useCallback(() => {
    const trimmedValue = newValue.trim();
    if (trimmedValue.length > 0) {
      const allVariants = [...form.values, ...newVariations];
      const isDuplicate = allVariants.includes(trimmedValue);

      if (isDuplicate) {
        showNotification({
          error: true,
          message: translate('variants.duplicateName'),
        });
      } else {
        setNewVariations(current => [...current, trimmedValue]);
        setNewValue('');
      }
    } else {
      showNotification({
        error: true,
        message: translate('variants.emptyVariantName'),
      });
    }
  }, [newValue, form.values, newVariations, showNotification, translate]);

  const onPressSave = () => {
    if (!group || form.key.trim().length === 0) {
      showNotification({
        error: true,
        message: translate('variants.emptyGroupNameError'),
      });
      return;
    }

    const allVariants = [...form.values, ...newVariations];

    if (hasEmptyVariant(allVariants)) {
      showNotification({
        error: true,
        message: translate('variants.emptyVariantName'),
      });
      return;
    }

    if (hasDuplicateVariant(allVariants)) {
      showNotification({
        error: true,
        message: translate('variants.duplicateName'),
      });
      return;
    }

    try {
      updateOption({
        id: group.id,
        key: form.key,
        values: allVariants,
      });
      setNewValue('');
      setNewVariations([]);
      showNotification({
        success: true,
        message: translate('variants.updated'),
      });
    } catch (err) {
      setNewValue('');
      showNotification({
        error: true,
        message: translate('variants.failed'),
      });
      console.error('An error occurred while saving variations', err);
    }
  };

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  return (
    <ScreenLayout
      title={`${name} | Oolio`}
      loading={loading}
      onSave={onPressSave}
      onSaveDisabled={form.key.trim().length < 1}
    >
      <Section title={translate('backOfficeSettings.registerDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-groupName"
            title={translate('form.name')}
            placeholder={translate('form.namePlaceholder')}
            value={form.key}
            onChangeText={handleKeyChange}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('variants.variations')}>
        <View style={styles.tableVariations}>
          {form.values.map((value, i) => (
            <View key={i} style={theme.tables.row}>
              <InputText
                testID={`input-variantName-${i}`}
                placeholder={translate('form.namePlaceholder')}
                value={value}
                onChangeText={text => handleValueChange(text, i)}
                containerStyle={styles.inputName}
              />
              <ButtonIcon
                testID="btn-removeVariant"
                icon="trash-alt"
                type="negativeLight"
                onPress={() => onDeleteVariant(value, i)}
              />
            </View>
          ))}
          {newVariations.map((value, i) => (
            <View key={i + form.values.length} style={theme.tables.row}>
              <InputText
                testID={`input-newVariantName-${i}`}
                placeholder={translate('form.namePlaceholder')}
                value={value}
                onChangeText={text => handleNewVariationChange(text, i)}
                containerStyle={styles.inputName}
              />
              <ButtonIcon
                testID="btn-removeNewVariant"
                icon="trash-alt"
                type="negativeLight"
                onPress={() => onDeleteVariant(value, i + form.values.length)}
              />
            </View>
          ))}
          <View style={theme.tables.row}>
            <InputText
              testID="input-newVariant"
              placeholder={translate('form.namePlaceholder')}
              value={newValue}
              onChangeText={setNewValue}
              containerStyle={styles.inputName}
            />
            <ButtonIcon
              testID="btn-addVariant"
              icon="plus"
              type="positive"
              onPress={onAddVariant}
              disabled={newValue.trim().length < 1}
            />
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
