/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import Svg, { Path, G, Defs, Mask, Use } from 'react-native-svg';

const ApplePay: React.FC = () => {
  return (
    <Svg width="30" height="20" viewBox="0 0 30 20">
      <Defs>
        <Path
          id="path-1"
          d="M2 0h26a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        ></Path>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Mask id="mask-2" fill="#fff">
          <Use xlinkHref="#path-1"></Use>
        </Mask>
        <G mask="url(#mask-2)">
          <G fillRule="nonzero" transform="translate(0 .5)">
            <Path
              fill="#000"
              d="M27.313 0H2.38l-.26.004a3.807 3.807 0 00-.563.05 1.92 1.92 0 00-.537.174A1.8 1.8 0 00.054 1.54a3.663 3.663 0 00-.05.558L0 2.353V16.647l.004.256c.005.186.017.374.05.558.034.187.09.36.177.53a1.775 1.775 0 00.788.78c.172.087.348.142.537.176.185.033.375.044.564.049.086.002.172.003.259.003l.308.001H27.62l.26-.004c.187-.005.377-.016.564-.05.188-.033.364-.088.536-.174a1.79 1.79 0 00.965-1.311c.033-.184.044-.372.05-.558.002-.085.003-.17.003-.256l.001-.305V2.658v-.305c-.001-.085-.002-.17-.005-.256a3.663 3.663 0 00-.05-.558A1.79 1.79 0 0028.98.23a1.921 1.921 0 00-.535-.175 3.817 3.817 0 00-.565-.05 13.083 13.083 0 00-.26-.003L27.314 0z"
            ></Path>
            <Path
              fill="#FFF"
              d="M27.313.633h.303c.082.001.164.002.247.005.144.003.311.011.468.039.136.024.25.06.36.116a1.158 1.158 0 01.625.859c.028.153.035.32.04.462.002.081.003.162.003.244v14.286c0 .08-.001.161-.004.242-.004.143-.011.31-.04.464a1.148 1.148 0 01-.626.857 1.263 1.263 0 01-.357.116 3.29 3.29 0 01-.466.04c-.083.001-.166.003-.25.003H2.382c-.083 0-.165-.002-.245-.003a3.295 3.295 0 01-.468-.04 1.267 1.267 0 01-.36-.116 1.148 1.148 0 01-.508-.503 1.234 1.234 0 01-.117-.356 3.1 3.1 0 01-.04-.462 11.71 11.71 0 01-.003-.243V2.358c0-.082.001-.163.003-.244.004-.142.012-.307.04-.463.025-.135.062-.247.118-.356A1.143 1.143 0 011.31.793a1.27 1.27 0 01.359-.116 3.23 3.23 0 01.468-.04l.246-.003h24.93"
            ></Path>
            <G fill="#000" strokeWidth="1" transform="translate(4.066 5.21)">
              <G>
                <Path d="M4.123 1.18C4.38.864 4.555.437 4.51 0c-.376.018-.835.245-1.1.564-.24.272-.45.717-.395 1.134.422.037.843-.208 1.11-.517M4.504 1.78c-.613-.036-1.134.344-1.427.344-.293 0-.741-.326-1.226-.317a1.81 1.81 0 00-1.537.924C-.345 3.854.14 5.52.781 6.434c.31.453.686.951 1.18.933.466-.018.65-.299 1.217-.299.566 0 .731.3 1.225.29.513-.009.833-.453 1.144-.906.357-.516.503-1.014.512-1.041-.01-.009-.988-.38-.997-1.494-.01-.933.768-1.376.805-1.404-.44-.642-1.125-.715-1.363-.733"></Path>
              </G>
              <G transform="translate(7.267 .518)">
                <Path d="M2.574 0c1.333 0 2.26.909 2.26 2.231 0 1.328-.946 2.241-2.293 2.241H1.066v2.32H0V0h2.574zM1.066 3.587h1.223c.928 0 1.456-.494 1.456-1.35 0-.858-.528-1.347-1.452-1.347H1.066v2.697zM5.113 5.385c0-.866.67-1.398 1.86-1.464l1.37-.08V3.46c0-.55-.375-.88-1.003-.88-.595 0-.966.282-1.056.725h-.971C5.37 2.41 6.14 1.75 7.378 1.75c1.213 0 1.989.636 1.989 1.629v3.413h-.985v-.815h-.024c-.29.551-.923.9-1.58.9-.98 0-1.665-.603-1.665-1.493zm3.23-.447v-.39l-1.232.075c-.613.042-.96.31-.96.734 0 .433.36.716.913.716.718 0 1.28-.49 1.28-1.135z"></Path>
                <Path d="M10.297 8.615V7.79c.076.019.247.019.333.019.476 0 .733-.198.89-.706 0-.01.09-.302.09-.306L9.802 1.84h1.113l1.266 4.03h.02l1.265-4.03h1.085l-1.875 5.21c-.428 1.201-.923 1.587-1.96 1.587-.086 0-.343-.01-.42-.023z"></Path>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default ApplePay;
