import React, { useCallback, useMemo } from 'react';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { View } from 'react-native';
import theme from '../../../../../common/default-theme';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import { DiscountRule } from './DiscountRule';
import { DiscountRuleModal } from './Modals/DiscountRuleModal';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './Adjustments.style';
import {
  Adjustment,
  AdjustmentUnit,
  Category,
  DiscountOperator,
  DiscountRuleCriteria,
  DiscountRuleType,
  EntityType,
  Product,
} from '@oolio-group/domain';
import { OptionsType } from '../../../../../components/Shared/Select/SelectSearch';
import Message from '../../../../../components/Office/Message/Message';
import {
  AdvanceDiscountRuleCriteria,
  RuleCriteriaMode,
} from './AdvanceDiscountRuleCriteria';

const DiscountOperatorMapper = {
  [DiscountOperator.EXACTLY]: 'Exactly',
  [DiscountOperator.MORE_THAN]: 'More than',
};

interface AdvanceDiscountRulesProp {
  form: Adjustment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeFormInput: (key: string, value: any) => void;
  categories: Record<string, Category>;
  products: Record<string, Product>;
}

const AdvanceDiscountRules: React.FC<AdvanceDiscountRulesProp> = ({
  form,
  onChangeFormInput,
  categories,
  products,
}) => {
  const { translate } = useTranslation();
  const { showModal } = useModal();
  const { formatCurrency, formatMoneyValue } = useCurrency();

  const getFormattedAmount = useCallback(
    (amount: number, type: AdjustmentUnit) => {
      if (type === AdjustmentUnit.PERCENTAGE)
        return `${formatMoneyValue(amount)}%`;
      else return formatCurrency(amount);
    },
    [formatCurrency, formatMoneyValue],
  );

  const getEntityName = useCallback(
    (ids: string[], isCountDisplayed = true) => {
      const getName = (id: string) => {
        const entity = categories[id] || products[id];
        if (!entity) return '';
        return (entity.products?.length || entity.variants?.length) &&
          isCountDisplayed
          ? `${entity.name} - ${
              (entity.products?.length || 0) + (entity.variants?.length || 0)
            }`
          : entity.name;
      };
      return (
        ids
          ?.map(getName)
          .filter(n => n)
          ?.join(', ') || ''
      );
    },
    [categories, products],
  );

  const isValidItemIds = useCallback(
    (itemIds: string[]) => {
      return itemIds?.some(id => id in products || id in categories);
    },
    [categories, products],
  );

  const entityOptions: OptionsType[] = useMemo(() => {
    const allProducts = Object.values(products);
    const allCategories = Object.values(categories);
    return [...allProducts, ...allCategories].map(
      entity =>
        ({
          title: entity.name,
          subtitle: (entity as Category).products ? 'Category' : 'Product',
          value: entity.id,
          type: (entity as Category).products
            ? EntityType.Page
            : EntityType.Product,
        } as OptionsType),
    );
  }, [products, categories]);

  const showDiscountTypeModal = useCallback(() => {
    showModal(<DiscountRuleModal onChangeFormInput={onChangeFormInput} />);
  }, [showModal, onChangeFormInput]);

  const onSelectEntity = useCallback(
    (key: string, values: string[]) => {
      const { categoryIds = [], productIds = [] } =
        form.advanceRules?.[key] || {};
      const selectedEntityId = values[values.length - 1];
      const entity = entityOptions.find(e => e.value === selectedEntityId);
      onChangeFormInput(key, {
        ...form.advanceRules?.[key],
        ...(entity?.type === EntityType.Page && {
          categoryIds: [...(categoryIds ?? []), entity.value],
        }),
        ...(entity?.type === EntityType.Product && {
          productIds: [...(productIds ?? []), entity.value],
        }),
      } as DiscountRuleCriteria);
    },
    [entityOptions, form.advanceRules, onChangeFormInput],
  );

  const onRemoveEntity = (key: string, entityId: string) => {
    const entityKey = form.advanceRules?.[key]?.productIds?.includes(entityId)
      ? 'productIds'
      : 'categoryIds';
    onChangeFormInput(key, {
      ...form.advanceRules?.[key],
      [entityKey]: form.advanceRules?.[key]?.[entityKey].filter(
        (id: string) => id !== entityId,
      ),
    } as DiscountRuleCriteria);
  };

  const entityIds = useMemo(() => {
    const {
      productIds: purchasedProductIds,
      categoryIds: purchasedCategoryIds,
    } = form.advanceRules?.purchasedCriteria || {};
    const {
      productIds: discountedProductIds,
      categoryIds: discountedCategoryIds,
    } = form.advanceRules?.discountedCriteria || {};

    const purchased = [
      ...(purchasedProductIds ?? []),
      ...(purchasedCategoryIds ?? []),
    ];
    const discounted = [
      ...(discountedProductIds ?? []),
      ...(discountedCategoryIds ?? []),
    ];
    return { purchased, discounted };
  }, [
    form.advanceRules?.purchasedCriteria,
    form.advanceRules?.discountedCriteria,
  ]);

  const discountRuleDetail = useMemo(() => {
    let selectedDiscountRuleText = '';
    const { operator: purchasedOperator, qty: purchasedQty } =
      form.advanceRules?.purchasedCriteria || {};
    const purchasedOperatorText =
      DiscountOperator.EXACTLY === purchasedOperator
        ? `${purchasedOperator.toLowerCase()} ${purchasedQty}`
        : `${purchasedQty} or more`;

    const { operator: discountedOperator, qty: discountedQty } =
      form.advanceRules?.discountedCriteria || {};
    const discountedOperatorText = `${DiscountOperatorMapper[
      discountedOperator as DiscountOperator
    ]?.toLowerCase()} ${discountedQty || 'a'}`;

    switch (form.discountRuleType) {
      case DiscountRuleType.PRODUCT_CATEGORY_RULE:
        selectedDiscountRuleText = translate(
          'backofficeVenueSettingAdjustments.productCategoryDiscountInfoValue',
          {
            productsOrCategories: getEntityName(entityIds.purchased, false),
            amount: getFormattedAmount(+form.amount, form.adjustmentUnit),
          },
        );
        break;
      case DiscountRuleType.PRODUCT_CATEGORY_QTY_RULE:
        selectedDiscountRuleText = translate(
          form.adjustmentUnit === AdjustmentUnit.FLAT
            ? 'backofficeVenueSettingAdjustments.qtyDiscountInfoValueFlatDiscount'
            : purchasedOperator === DiscountOperator.EXACTLY
            ? 'backofficeVenueSettingAdjustments.qtyDiscountInfoValueExact'
            : 'backofficeVenueSettingAdjustments.qtyDiscountInfoValueMoreThan',
          {
            productsOrCategories: getEntityName(entityIds.purchased, false),
            amount: getFormattedAmount(+form.amount, form.adjustmentUnit),

            operatorText: purchasedOperatorText,
          },
        );
        break;

      case DiscountRuleType.PRODUCT_CATEGORY_BUY_SOME_GET_SOME_RULE:
        selectedDiscountRuleText = translate(
          'backofficeVenueSettingAdjustments.buySomeGetSomeDiscountSelectionInfoValue',
          {
            purchasedProductsOrCategories: getEntityName(
              entityIds.purchased,
              false,
            ),
            purchasedOperatorText,
            discountedProductsOrCategories: getEntityName(
              entityIds.discounted,
              false,
            ),
            discountedOperatorText,
            amount: getFormattedAmount(+form.amount, form.adjustmentUnit),
          },
        );

        break;
    }
    return {
      purchasedOperatorText,
      selectedDiscountRuleText,
    };
  }, [
    form.discountRuleType,
    form.advanceRules,
    form.amount,
    form.adjustmentUnit,
    translate,
    getEntityName,
    entityIds.purchased,
    entityIds.discounted,
    getFormattedAmount,
  ]);

  const isValidSelectionCriteria = useMemo(() => {
    if (
      form.discountRuleType ===
      DiscountRuleType.PRODUCT_CATEGORY_BUY_SOME_GET_SOME_RULE
    ) {
      return (
        form.amount &&
        entityIds.purchased?.length > 0 &&
        entityIds.discounted?.length > 0 &&
        isValidItemIds(entityIds.purchased) &&
        isValidItemIds(entityIds.discounted)
      );
    } else {
      return (
        form.amount &&
        entityIds.purchased?.length > 0 &&
        isValidItemIds(entityIds.purchased)
      );
    }
  }, [form.amount, entityIds, isValidItemIds, form.discountRuleType]);

  return (
    <>
      <View style={theme.forms.row}>
        <InputToggle
          type="switch"
          testID="discount-rule"
          onToggle={() =>
            onChangeFormInput(
              'isDiscountRuleEnabled',
              !form.isDiscountRuleEnabled,
            )
          }
          isToggled={form.isDiscountRuleEnabled ?? false}
          title={translate('backofficeVenueSettingAdjustments.discountRules')}
          containerStyle={theme.forms.inputFluid}
          subtitle={translate(
            'backofficeVenueSettingAdjustments.discountRulesDescription',
          )}
        />
      </View>

      {form.isDiscountRuleEnabled ? (
        <>
          <DiscountRule
            ruleType={form.discountRuleType}
            iconType="angle-down"
            onClick={showDiscountTypeModal}
          />

          <AdvanceDiscountRuleCriteria
            mode={RuleCriteriaMode.Purchased}
            form={form}
            onChangeFormInput={onChangeFormInput}
            entityOptions={entityOptions}
            entityIds={entityIds}
            onSelectEntity={onSelectEntity}
            getEntityName={getEntityName}
            onRemoveEntity={onRemoveEntity}
            containerStyle={styles.topSpacing}
          />

          {form.discountRuleType ===
            DiscountRuleType.PRODUCT_CATEGORY_BUY_SOME_GET_SOME_RULE && (
            <AdvanceDiscountRuleCriteria
              mode={RuleCriteriaMode.Discounted}
              form={form}
              onChangeFormInput={onChangeFormInput}
              entityOptions={entityOptions}
              entityIds={entityIds}
              onSelectEntity={onSelectEntity}
              getEntityName={getEntityName}
              onRemoveEntity={onRemoveEntity}
            />
          )}

          <View style={styles.zIndex}>
            {isValidSelectionCriteria ? (
              <>
                <Message
                  message={discountRuleDetail.selectedDiscountRuleText}
                  type={'neutral'}
                  containerStyle={styles.topSpacing}
                />
                <Message
                  message={translate(
                    'backofficeVenueSettingAdjustments.multipleAutomaticDiscountInfo',
                  )}
                  type={'neutral'}
                  containerStyle={styles.topSpacing}
                />
              </>
            ) : null}
          </View>
        </>
      ) : null}
    </>
  );
};

export default AdvanceDiscountRules;
