import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../common/default-theme';

const baseTextStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.white,
  fontFamily: theme.fonts.medium,
  textAlign: 'center',
};

const styles = StyleSheet.create({
  container: {
    width: 600,
    alignSelf: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
  },
  header: {
    padding: 40,
    rowGap: 16,
  },
  btnClose: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  textGroup: {
    rowGap: 4,
  },
  title: {
    ...baseTextStyle,
    fontSize: 24,
    lineHeight: 34,
    fontFamily: theme.fonts.bold,
    fontVariant: ['tabular-nums'],
  },
  textH2: {
    ...baseTextStyle,
  },
  textH3: {
    ...baseTextStyle,
    opacity: 0.6,
  },
  content: {
    padding: 30,
  },
  rewardSection: {
    maxHeight: 400,
  },
  emptyView: {
    padding: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnSubmit: {
    marginTop: 30,
  },
  rewards: {
    rowGap: 8,
  },
  loading: {
    width: 120,
    height: 120,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: 10,
    marginTop: 30,
  },
});

export default styles;
