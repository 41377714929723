import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    minHeight: 430,
    alignSelf: 'center',
    padding: 30,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.m,
    rowGap: 10,
  },
  icon: {
    alignSelf: 'center',
    paddingBottom: 8,
  },
  title: {
    alignSelf: 'center',
  },
  titleText: {
    textAlign: 'center',
    fontFamily: theme.fonts.bold,
    fontSize: 20,
    lineHeight: 28,
    color: theme.colors.black1,
    padding: 8,
  },
  subTitleText: {
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
  },
  scrollView: {
    rowGap: 4,
    minHeight: 60,
    maxHeight: 240,
  },
  button: {
    width: '100%',
  },
});

export default styles;
