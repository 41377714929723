import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  colName: {
    flex: 1,
  },
  colPoints: {
    width: 120,
    marginLeft: 10,
    textAlign: 'right',
    marginRight: 82,
  },
  btnAdd: {
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 'auto',
  },
  btnDelete: {
    marginLeft: 6,
  },
  rowName: {
    flex: 1,
    paddingLeft: 12,
  },
  rowValue: {
    flex: 1,
    marginRight: 20,
    textAlign: 'right',
  },
  rowProduct: {
    paddingHorizontal: 12,
    justifyContent: 'space-between',
  },
  // Modal
  modal: {
    width: 600,
    alignSelf: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
  },
  modalContent: {
    paddingHorizontal: 30,
  },
  modalInput: {
    width: 280,
    marginBottom: 20,
  },
  modalTable: {
    zIndex: -1,
  },
  modalTableBody: {
    height: 210,
  },
});

export default styles;
