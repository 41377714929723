import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  GET_USERS_DETAILS,
  CREATE_USER_DETAILS,
  UPDATE_USER_DETAILS,
  DELETE_USER_DETAILS,
} from '../../graphql/users';
import {
  User,
  CreateUserRequest,
  UpdateUserRequest,
} from '@oolio-group/domain';
import keyBy from 'lodash/keyBy';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import { Operation } from '../../types/Operation';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';

export interface useUsersProps {
  users: { [key: string]: User };
  usersArray: User[];
  searchUsers: (searchString: string) => User[];
  refetch: () => void;
  createUser: (input: CreateUserRequest) => void;
  createdUserId: string;
  updateUser: (input: UpdateUserRequest) => void;
  deleteUser: (id: string) => void;
  deletedUserId: string;
  loading: boolean;
  error: string | undefined;
  operation: Operation;
}

export function useUsers(): useUsersProps {
  const [users, setUsers] = useState<Record<string, User>>({});
  const [createdUserId, setCreatedUserId] = useState<string>('');
  const [deletedUserId, setDeletedUserId] = useState<string>('');
  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const {
    data: usersData,
    loading: fetchLoading,
    error: userFetchError,
    refetch,
  } = useQuery(GET_USERS_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  const [createUser, createRequest] = useMutation(CREATE_USER_DETAILS, {
    onError: noopHandler,
  });

  const [updateUser, updateRequest] = useMutation(UPDATE_USER_DETAILS, {
    onError: noopHandler,
  });

  const [deleteUser, deleteUserRequest] = useMutation(DELETE_USER_DETAILS, {
    onError: noopHandler,
  });

  useEffect(() => {
    if (createRequest.data) {
      const createdUser = createRequest.data.createUser as User;
      setUsers(prev => ({
        ...prev,
        [createdUser.id]: createdUser,
      }));
      setCreatedUserId(createdUser.id);
    }
  }, [createRequest.data]);

  const createUserData = useCallback(
    (input: CreateUserRequest) => {
      setCreatedUserId('');
      createUser({
        variables: {
          input,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createUser],
  );

  useEffect(() => {
    if (updateRequest.data) {
      const updatedUser = updateRequest.data.updateUser as User;
      setUsers(prev => ({
        ...prev,
        [updatedUser.id]: updatedUser,
      }));
    }
  }, [updateRequest.data]);

  const updateUserData = useCallback(
    (input: UpdateUserRequest) => {
      updateUser({
        variables: {
          input,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateUser],
  );

  useEffect(() => {
    if (deleteUserRequest.data && deletedUserId) {
      setUsers(prev => {
        const tempUsers = { ...prev };
        delete tempUsers[deletedUserId];
        return tempUsers;
      });
    }
  }, [deleteUserRequest.data, deletedUserId]);

  const deleteUserDetails = useCallback(
    (id: string) => {
      deleteUser({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
      setDeletedUserId(id);
    },
    [deleteUser],
  );

  useEffect(() => {
    if (usersData) {
      setUsers(keyBy(usersData.users || [], 'id'));
    }
  }, [usersData]);

  const usersArray = useMemo(() => {
    return Object.values(users);
  }, [users]);

  /**
   * Search users
   *
   * filters by user name
   * matches users names containing search string
   */
  const searchUsers = useCallback(
    (searchString: string) => {
      return usersArray.filter(user =>
        user.firstName.toLowerCase().includes(searchString.toLowerCase()),
      );
    },
    [usersArray],
  );

  const RESPONSE_ENTITIES = [createRequest, updateRequest, deleteUserRequest];
  const loading = fetchLoading || isLoading(RESPONSE_ENTITIES);

  const error = userFetchError || getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      users,
      usersArray,
      searchUsers,
      refetch,
      createUser: createUserData,
      createdUserId,
      updateUser: updateUserData,
      deleteUser: deleteUserDetails,
      deletedUserId,
      operation,
      loading,
      error: error ? parseApolloError(error) : undefined,
    }),
    [
      users,
      usersArray,
      searchUsers,
      refetch,
      createUserData,
      createdUserId,
      updateUserData,
      deleteUserDetails,
      deletedUserId,
      operation,
      loading,
      error,
    ],
  );
}
