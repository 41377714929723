import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { PageItem } from '@oolio-group/domain';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface ProductRowProps {
  item: PageItem & { name: string };
  onDelete: (id: string) => void;
  drag?: () => void;
}

export const ProductRow: React.FC<ProductRowProps> = ({
  item,
  onDelete,
  drag,
}) => {
  const linked = Boolean(item.category);

  return (
    <TouchableOpacity style={theme.tables.row} onLongPress={drag}>
      <View style={theme.tables.disclosure}>
        <Icon name="draggabledots" size={20} color={theme.colors.grey5} />
      </View>
      <Text>{item.name}</Text>
      <ButtonIcon
        disabled={linked}
        type="negativeLight"
        onPress={() => onDelete(item.id)}
        icon={linked ? 'link' : 'trash-alt'}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginLeft: 'auto' }}
      />
    </TouchableOpacity>
  );
};
