import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    borderRadius: theme.radius.s,
  },
  icon: {
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
    fontSize: 13,
    lineHeight: 20,
    color: theme.colors.grey3,
    fontFamily: theme.fonts.medium,
  },
  badge: {
    minWidth: 22,
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.orangeDark,
  },
  badgeText: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: theme.fonts.medium,
    textAlign: 'center',
    fontVariant: ['tabular-nums'],
    color: theme.colors.white,
  },
});

export default styles;
