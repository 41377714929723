import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  filters: {
    columnGap: 10,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  search: {
    flex: 1,
  },
  headerCategory: {
    flex: 1,
    marginRight: 10,
  },
  headerProducts: {
    width: 80,
    marginRight: 22,
    textAlign: 'center',
  },
  cellCategory: {
    flex: 1,
    marginRight: 10,
    paddingLeft: 12,
  },
  cellProducts: {
    width: 80,
    textAlign: 'center',
    fontVariant: ['tabular-nums'],
  },
  // Details
  btnTranslate: {
    marginLeft: 10,
    marginTop: 'auto',
  },
  tableContainer: {
    marginTop: 20,
    zIndex: -1,
  },
  identifier: {
    marginLeft: 12,
  },
  cellProduct: {
    flex: 1,
    paddingLeft: 12,
  },
});

export default styles;
