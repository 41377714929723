import {
  useLazyQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client/react/hooks';
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import {
  DELETE_PRODUCTS_MUTATION,
  UPLOAD_PRODUCT_IMAGE_MUTATION,
  UPDATE_PRODUCTS_AVAILABILITY_EVENT,
  BULK_UPDATE_PRODUCT_CATEGORY,
  getProductFragments,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import {
  CreateProductInput,
  UpdateProductsAvailabilityEventInput,
  CopyProductInput,
  UpdateProductInput,
  Product,
  ImageUploadInput,
  BulkUpdateEntityAndCategoryInput,
  UpdateProductAvailabilityInput,
} from '@oolio-group/domain';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { keyBy } from 'lodash';
import {
  filterEntityByStore,
  stripProperties,
} from '@oolio-group/client-utils';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import { useNotification } from '../../Notification';
import { translate } from '@oolio-group/localization';
import { getCurrentQuantity } from '@oolio-group/order-helper';

interface FilterProductInput {
  storeId?: string;
  modGroupIds?: string[];
}

export interface useProductsProps {
  products: { [key: string]: Product };
  updateProduct: (productDetails: UpdateProductInput) => void;
  updateProducts: (productsDetails: UpdateProductInput[]) => void;
  deleteProducts: (productIds: string[], callback?: () => void) => void;
  getAllProducts: () => void;
  copyProduct: (productInput: CopyProductInput) => Promise<Product | undefined>;
  createProduct: (
    productCreate: CreateProductInput,
  ) => Promise<Product | undefined>;
  getProductsByName: (name: string) => void;
  deletedProductIds: string[];
  loading: boolean;
  error: string | undefined;
  getProductById: (productId: string) => void;
  updateProductsInCache: (productsMap: Record<string, Product>) => void;
  uploadProductImage: (input: ImageUploadInput, productId: string) => void;
  getProductsFromCache: () => Product[];
  syncProductsAvailabilityEvent: (
    event: UpdateProductsAvailabilityEventInput,
  ) => void;
  bulkUpdateProductsCategory: (input: BulkUpdateEntityAndCategoryInput) => void;
  updateProductsAvailability: (input: UpdateProductAvailabilityInput[]) => void;
  refreshProducts: () => void;
  updateCachedProducts: (products: Product[]) => void;
  updateCacheProductQuantities: (
    productQuantityMaps: Record<string, number>,
    storeId: string,
  ) => void;
  getCacheProductFragment: (id: string) => Product | null;
  getProductIdsByStore: (storeId: string) => void;
  productIdsOfStoreMaps: Record<string, string[]>;
  getProductIdsByModGroups: (modGroupIds: string[]) => void;
  productIdsOfModGroups: string[];
}

export function useProducts(
  productId?: string,
  customFragment?: string,
  filteringByStoreIds?: string[],
  fetchPolicyProp?: WatchQueryFetchPolicy,
): useProductsProps {
  const [products, setProducts] = useState<Record<string, Product>>({});
  const [productIdsOfStoreMaps, setProductIdsOfStoreMaps] = useState<
    Record<string, string[]>
  >({});
  const fetchingProductIdsOfStoreRef = useRef<string>('');
  const [productIdsOfModGroups, setProductIdsOfModGroups] = useState<string[]>(
    [],
  );

  const deletingProductIdsRef = useRef<string[]>([]);
  const bulkUpdateReqVariableRef = useRef<BulkUpdateEntityAndCategoryInput>({
    category: '',
    entityIds: [],
  });

  const {
    GET_PRODUCTS_QUERY,
    GET_PRODUCT_QUERY,
    UPDATE_PRODUCT_MUTATION,
    UPDATE_PRODUCTS_MUTATION,
    GET_PRODUCTS_WITH_FILTER,
    CREATE_PRODUCT_MUTATION,
    COPY_PRODUCT_MUTATION,
    UPDATE_PRODUCT_AVAILABILITY,
    PRODUCT_CACHE_FRAGMENT,
    PRODUCT_IDS_OF_STORES,
    PRODUCT_IDS_OF_MOD_GROUPS,
  } = getProductFragments(customFragment);

  const client = useApolloClient();
  const { showNotification } = useNotification();

  const getCachedProducts = useCallback(() => {
    const cachedData = client.cache.readQuery<{ products: Product[] }>({
      query: GET_PRODUCTS_QUERY,
    });
    return cachedData?.products || [];
  }, [GET_PRODUCTS_QUERY, client.cache]);

  const updateCachedProducts = useCallback(
    (products: Product[]) => {
      client.cache.writeQuery({
        query: GET_PRODUCTS_QUERY,
        data: {
          products,
        },
      });
    },
    [GET_PRODUCTS_QUERY, client.cache],
  );

  const [syncProductAvailabilityEvents, syncUpdateProductResponse] =
    useMutation(UPDATE_PRODUCTS_AVAILABILITY_EVENT, {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
    });

  // Get product by id
  const onGetProductRes = useCallback(data => {
    if (data.product) {
      setProducts(products => ({
        ...products,
        [data.product.id]: data.product,
      }));
    }
  }, []);

  const [getProductReq] = useLazyQuery<{ product: Product }>(
    GET_PRODUCT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: onGetProductRes,
    },
  );

  const [getProducts, getProductsRes] = useLazyQuery<{ products: Product[] }>(
    GET_PRODUCTS_QUERY,
    {
      fetchPolicy: fetchPolicyProp || 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
      onError: noopHandler,
    },
  );

  const [getProductIdsOfStoreReq, getProductIdsOfStoreRes] = useLazyQuery<
    {
      products: { id: string }[];
    },
    {
      filter: FilterProductInput;
    }
  >(PRODUCT_IDS_OF_STORES, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
    onCompleted: data => {
      const storeId = fetchingProductIdsOfStoreRef.current;
      setProductIdsOfStoreMaps(prev => {
        return {
          ...prev,
          [storeId]: data?.products?.map(p => p.id) || [],
        };
      });
    },
  });

  const [getProductIdsOfModGroupsReq, getProductIdsOfModGroupsRes] =
    useLazyQuery<
      {
        products: { id: string }[];
      },
      { filter: FilterProductInput }
    >(PRODUCT_IDS_OF_MOD_GROUPS, {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: data => {
        const productIds = data.products.map(p => p.id);
        setProductIdsOfModGroups(productIds);
      },
    });

  const [getProductFilterReq, getProductFilterRes] = useLazyQuery(
    GET_PRODUCTS_WITH_FILTER,
    {
      fetchPolicy: 'no-cache',
      onError: noopHandler,
    },
  );

  const [updateProductReq, updateProductRes] = useMutation(
    UPDATE_PRODUCT_MUTATION,
    {
      onError: noopHandler,
      onCompleted: () => {
        showNotification({
          success: true,
          message: translate('productBulkOperations.successfullyUpdated'),
        });
      },
    },
  );

  const [bulkUpdateCategoryReq, bulkUpdateCategoryRes] = useMutation<{
    bulkUpdateProductsCategory: boolean;
  }>(BULK_UPDATE_PRODUCT_CATEGORY, {
    onError: noopHandler,
  });

  const refreshProducts = useCallback(() => {
    getProductsRes?.refetch && getProductsRes.refetch();
  }, [getProductsRes]);

  const bulkUpdateProductsCategory = useCallback(
    async (input: BulkUpdateEntityAndCategoryInput) => {
      bulkUpdateReqVariableRef.current = input;
      bulkUpdateCategoryReq({ variables: { input } });
    },
    [bulkUpdateCategoryReq],
  );

  const [updateProductsAvailabilityReq, updateProductsAvailabilityRes] =
    useMutation(UPDATE_PRODUCT_AVAILABILITY, {
      onError: noopHandler,
    });

  const updateProductsAvailability = useCallback(
    (input: UpdateProductAvailabilityInput[]) => {
      updateProductsAvailabilityReq({ variables: { input } });
    },
    [updateProductsAvailabilityReq],
  );

  const [updateProductsReq, updateProductsRes] = useMutation(
    UPDATE_PRODUCTS_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const syncProductsAvailabilityEvent = useCallback(
    (event: UpdateProductsAvailabilityEventInput): void => {
      syncProductAvailabilityEvents({
        variables: { input: [event] },
      });
    },
    [syncProductAvailabilityEvents],
  );

  const updateNewCreatedProductToCached = useCallback(
    (createdProduct: Product) => {
      showNotification({
        success: true,
        message: translate('productSettings.productSuccessfullyAdded', {
          name: createdProduct?.name,
        }),
      });
      const existingProducts = getCachedProducts();
      updateCachedProducts([...existingProducts, createdProduct]);
    },
    [getCachedProducts, showNotification, updateCachedProducts],
  );

  const [uploadProdImage, uploadProdImageResponse] = useMutation(
    UPLOAD_PRODUCT_IMAGE_MUTATION,
    {
      onError: noopHandler,
    },
  );
  // For CREATE / COPY / DELETE Action product we need to update the cached manually
  // Because of changing the length of Products
  const [createProductReq, createProductRes] = useMutation<{
    createProduct: Product;
  }>(CREATE_PRODUCT_MUTATION, {
    onError: noopHandler,
    onCompleted: data => {
      const createdProduct = data.createProduct;
      updateNewCreatedProductToCached(createdProduct);
    },
  });

  const [deleteProductsReq, deleteProductsRes] = useMutation(
    DELETE_PRODUCTS_MUTATION,
    {
      onError: noopHandler,
      onCompleted: () => {
        const existingProducts = getCachedProducts();
        const updatedProducts = existingProducts.filter(
          product => !deletingProductIdsRef.current.includes(product.id),
        );
        updateCachedProducts(updatedProducts);
        showNotification({
          success: true,
          message: translate('productSettings.productDeletedSuccessfully'),
        });
      },
    },
  );

  const [copyProductReq, copyProductRes] = useMutation<{
    copyProduct: Product;
  }>(COPY_PRODUCT_MUTATION, {
    onError: noopHandler,
    onCompleted: data => {
      const createdProduct = data.copyProduct;
      updateNewCreatedProductToCached(createdProduct);
    },
  });

  const copyProduct = useCallback(
    async (input: CopyProductInput) => {
      const response = await copyProductReq({ variables: { input } });
      return response.data?.copyProduct;
    },
    [copyProductReq],
  );

  const getProductById = useCallback(
    productId => {
      getProductReq({ variables: { id: productId } });
    },
    [getProductReq],
  );

  useEffect(() => {
    if (getProductsRes.data) {
      const updatedProducts = getProductsRes.data.products;
      updatedProducts && setProducts(keyBy(updatedProducts, 'id'));
    }
  }, [getProductsRes.data]);
  // update product
  useEffect(() => {
    if (getProductFilterRes.data) {
      const productsData = getProductFilterRes.data.products;

      setProducts(keyBy(productsData, 'id'));
    }
  }, [getProductFilterRes.data]);

  const updateProduct = useCallback(
    (product: UpdateProductInput) => {
      updateProductReq({
        variables: {
          input: product,
        },
      });
    },
    [updateProductReq],
  );

  const updateProductMapsToCache = useCallback(
    (productsMap: Record<string, Product>) => {
      let updatedProducts = Object.values(productsMap);
      const updatedProductIds = Object.keys(productsMap);
      const products = getCachedProducts();
      updatedProducts = products.map(prod =>
        updatedProductIds.includes(prod.id) ? productsMap[`${prod.id}`] : prod,
      );
      updateCachedProducts(updatedProducts);
    },
    [getCachedProducts, updateCachedProducts],
  );

  const updateProducts = useCallback(
    (products: UpdateProductInput[]) => {
      updateProductsReq({
        variables: {
          input: products,
        },
      });
    },
    [updateProductsReq],
  );

  const createProductDetails = useCallback(
    async (productInput: CreateProductInput) => {
      const response = await createProductReq({
        variables: {
          input: productInput,
        },
      });
      return response?.data?.createProduct;
    },
    [createProductReq],
  );

  const uploadProductImage = useCallback(
    (input: ImageUploadInput, productId: string) => {
      uploadProdImage({
        variables: {
          input,
          productId,
        },
      });
    },
    [uploadProdImage],
  );

  const deleteProducts = useCallback(
    (productIds: string[], callback?: () => void) => {
      deletingProductIdsRef.current = productIds;
      deleteProductsReq({
        variables: {
          input: productIds,
        },
        update: callback,
      });
    },
    [deleteProductsReq],
  );

  const getAllProducts = useCallback(() => {
    getProducts();
  }, [getProducts]);

  const filteredByStores = useMemo(() => {
    if (!filteringByStoreIds) return products;
    return keyBy(
      filterEntityByStore(Object.values(products), filteringByStoreIds),
      'id',
    );
  }, [filteringByStoreIds, products]);

  useEffect(() => {
    if (productId) {
      getProductReq({ variables: { id: productId } });
    }
  }, [getProductReq, productId]);

  const getProductsByName = useCallback(
    (name: string) => {
      getProductFilterReq({
        variables: {
          input: name,
        },
      });
    },
    [getProductFilterReq],
  );

  const updateCacheProductFragment = useCallback(
    (product: Partial<Product>) => {
      client.cache.writeFragment({
        id: `Product:${product.id}`,
        data: product,
        fragment: PRODUCT_CACHE_FRAGMENT,
      });
    },
    [PRODUCT_CACHE_FRAGMENT, client.cache],
  );

  const getCacheProductFragment = useCallback(
    (id: string) => {
      return client.cache.readFragment<Product>({
        id: `Product:${id}`,
        fragment: PRODUCT_CACHE_FRAGMENT,
      });
    },
    [PRODUCT_CACHE_FRAGMENT, client.cache],
  );

  const getProductIdsByStore = useCallback(
    (storeId: string) => {
      fetchingProductIdsOfStoreRef.current = storeId;
      getProductIdsOfStoreReq({
        variables: {
          filter: { storeId },
        },
      });
    },
    [getProductIdsOfStoreReq],
  );

  const getProductIdsByModGroups = useCallback(
    (modGroupIds: string[]) => {
      getProductIdsOfModGroupsReq({
        variables: {
          filter: { modGroupIds },
        },
      });
    },
    [getProductIdsOfModGroupsReq],
  );

  const updateCacheProductQuantities = useCallback(
    (maps: Record<string, number>, currentStoreId: string) => {
      const updatedProducts = Object.keys(maps).map(productId => {
        const product = getCacheProductFragment(productId);
        if (!product) return;
        const currentQuantity = getCurrentQuantity(product, currentStoreId);
        const updatedProduct: Partial<Product> = {
          ...product,
          storesInventory: {
            [currentStoreId]: {
              ...product?.storesInventory?.[currentStoreId],
              availableQuantity: currentQuantity + maps[productId],
            },
          },
        };
        updateCacheProductFragment(updatedProduct);
        return stripProperties(updatedProduct, '__typename');
      });
      return updatedProducts.filter(p => p);
    },
    [getCacheProductFragment, updateCacheProductFragment],
  );

  const RESPONSE_ENTITIES = [
    updateProductRes,
    getProductsRes,
    updateProductsRes,
    createProductRes,
    getProductFilterRes,
    deleteProductsRes,
    uploadProdImageResponse,
    syncUpdateProductResponse,
    copyProductRes,
    bulkUpdateCategoryRes,
    updateProductsAvailabilityRes,
    getProductIdsOfStoreRes,
    getProductIdsOfModGroupsRes,
  ];

  const error: ApolloError | undefined = getError(RESPONSE_ENTITIES);
  const loading: boolean = isLoading(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      products: filteredByStores,
      updateProduct,
      updateProducts,
      deleteProducts,
      createProduct: createProductDetails,
      getProductsByName: getProductsByName,
      deletedProductIds: deletingProductIdsRef.current,
      getAllProducts,
      loading,
      error: error ? parseApolloError(error) : undefined,
      getProductById,
      updateProductsInCache: updateProductMapsToCache,
      uploadProductImage,
      getProductsFromCache: getCachedProducts,
      syncProductsAvailabilityEvent,
      copyProduct,
      bulkUpdateProductsCategory,
      refreshProducts,
      updateCachedProducts,
      updateProductsAvailability,
      updateCacheProductQuantities,
      getCacheProductFragment,
      getProductIdsByStore,
      productIdsOfStoreMaps,
      getProductIdsByModGroups,
      productIdsOfModGroups,
    }),
    [
      filteredByStores,
      updateProduct,
      updateProducts,
      deleteProducts,
      createProductDetails,
      getProductsByName,
      getAllProducts,
      loading,
      error,
      getProductById,
      updateProductMapsToCache,
      uploadProductImage,
      getCachedProducts,
      syncProductsAvailabilityEvent,
      copyProduct,
      bulkUpdateProductsCategory,
      refreshProducts,
      updateCachedProducts,
      updateProductsAvailability,
      updateCacheProductQuantities,
      getCacheProductFragment,
      getProductIdsByStore,
      productIdsOfStoreMaps,
      getProductIdsByModGroups,
      productIdsOfModGroups,
    ],
  );
}

export * from './graphql';
