import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  // Adjustments Rows
  cellName: {
    width: 240,
    marginRight: 10,
  },
  cellAmount: {
    width: 140,
    marginRight: 10,
  },
  cellAllow: {
    flex: 1,
    marginRight: 'auto',
  },
  // Cash Events Rows
  cellType: {
    width: 200,
    marginRight: 10,
  },
  cellNote: {
    flex: 1,
    marginRight: 10,
  },
  // Sections
  countContainer: {
    width: 80,
    height: 44,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  cellCountText: {
    width: 80,
    marginRight: 10,
    textAlign: 'center',
  },
});

export default styles;
