import React from 'react';
import { View, Text } from 'react-native';
import {
  EntityStyleMap,
  OrderTypeStyleMap,
  DefaultStyle,
} from './Identifier.maps';
import styles from './Identifier.styles';
import { IdentifierStyle, IdentifierProps } from './Identifier.types';

const Identifier: React.FC<IdentifierProps> = ({
  entity,
  orderType,
  containerStyles,
}) => {
  const style: IdentifierStyle =
    (entity && EntityStyleMap[entity]) ||
    (orderType && OrderTypeStyleMap[orderType]) ||
    DefaultStyle;

  const { background: bgColor, foreground: fgColor, displayChar } = style;

  return (
    <View
      style={[
        styles.container,
        entity && styles.entity,
        { backgroundColor: bgColor },
        containerStyles,
      ]}
    >
      <Text style={[styles.text, { color: fgColor }]}>{displayChar}</Text>
    </View>
  );
};

export default Identifier;
