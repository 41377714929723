import React from 'react';
import { View, Text } from 'react-native';
import theme from '../../../../common/default-theme';
import styles from './DeviceStatusModal.styles';
import Icon from '../../../Icon/Icon';

export interface DeviceStatusMessage {
  type: 'positive' | 'neutral' | 'focus' | 'negative';
  message: string;
}

const DeviceStatusModalRow: React.FC<DeviceStatusMessage> = ({
  type,
  message,
}) => {
  const getBgColor = () => {
    if (type === 'positive') {
      return theme.colors.states.positiveXL;
    }
    if (type === 'negative') {
      return theme.colors.redLight;
    }
    if (type === 'focus') {
      return theme.colors.amberLight;
    }

    return theme.colors.blueLight;
  };

  const getStatusIcon = () => {
    if (type === 'positive') {
      return (
        <Icon
          size={20}
          name="check-circle"
          color={theme.colors.states.positive}
        />
      );
    }
    if (type === 'negative') {
      return (
        <Icon
          size={20}
          name="exclamation-circle"
          color={theme.colors.states.negative}
        />
      );
    }
    if (type === 'focus') {
      return (
        <Icon
          size={20}
          name="exclamation-triangle"
          color={theme.colors.states.focus}
        />
      );
    }

    return (
      <Icon size={20} name="info-circle" color={theme.colors.states.neutral} />
    );
  };

  return (
    <View style={[styles.messageRow, { backgroundColor: getBgColor() }]}>
      <View style={styles.icon}>{getStatusIcon()}</View>
      <Text style={styles.text}>{message}</Text>
    </View>
  );
};

export default DeviceStatusModalRow;
