import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  content: {
    height: '100%',
  },
  filters: {
    margin: 20,
    flexDirection: 'row',
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  dropdown: {
    width: 180,
    marginRight: 10,
  },
  message: {
    margin: 20,
    marginBottom: 0,
  },
});

export default styles;
