import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import Icon from '../../../../../components/Icon/Icon';

export interface PageItem {
  name: string;
  pageId: string;
}

interface CatalogueItemRowProps {
  id: string;
  name: string;
  pageId: string;
  drag?: () => void;
  onDeleteItem: (catalogueItemId: string, pageId: string) => void;
  onEditItem: (pageId: string) => void;
}

const CatalogueItemRow: React.FC<CatalogueItemRowProps> = ({
  id,
  pageId,
  name,
  drag,
  onDeleteItem,
  onEditItem,
}) => {
  return (
    <TouchableOpacity key={id} onLongPress={drag} style={theme.tables.row}>
      <View style={theme.tables.disclosure}>
        <Icon size={20} name="draggabledots" color={theme.colors.grey5} />
      </View>
      <Text style={styles.cellPage}>{name}</Text>
      <ButtonIcon
        type="neutralLight"
        icon="pen"
        onPress={() => onEditItem(pageId)}
        containerStyle={styles.btnEdit}
      />
      <ButtonIcon
        type="negativeLight"
        icon="trash-alt"
        onPress={() => onDeleteItem(id, pageId)}
      />
    </TouchableOpacity>
  );
};

export default CatalogueItemRow;

const styles = StyleSheet.create({
  cellPage: {
    flex: 1,
    marginRight: 10,
  },
  btnEdit: {
    marginRight: 6,
  },
});
