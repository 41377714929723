import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    padding: 30,
    paddingTop: 20,
    marginBottom: 10,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: '32.5%',
  },
  textContainer: {
    flex: 1,
  },
  textTitle: {
    marginTop: 20,
    fontFamily: theme.fonts.medium,
  },
  textDesc: {
    lineHeight: 20,
    marginVertical: 12,
  },
  iconsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  categoryText: {
    color: theme.colors.grey6,
  },
  enabledIconContainer: {
    width: 18,
    height: 18,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.states.positive,
  },
  featureIconContainer: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  imageContainer: {
    width: 44,
    height: 44,
    borderRadius: theme.radius.l,
  },
});

export default styles;
