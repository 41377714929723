import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useCallback } from 'react';
import {
  FileUploadInput,
  IMPORT_ENTITY_TYPE,
  ImportEntity,
} from '@oolio-group/domain';
import { noopHandler } from '../../../utils/errorHandlers';
import {
  GET_IMPORT_ENTITY_DETAIL,
  GET_IMPORT_ENTITY_FILE_DATA,
  IMPORT_PRODUCT,
} from './graphql';

export interface useProductsProps {
  processImportProduct: (file: FileUploadInput) => void;
  loading: Boolean;
  importProductId: string;
  importProductDetail: ImportEntity;
  getImportProductDetail: (id: string) => void;
  getImportEntityData: () => void;
  importEntityFileData: string;
}

export function useProductImport(): useProductsProps {
  const [getImportEntityDetail, importEntityDetailResponse] = useLazyQuery(
    GET_IMPORT_ENTITY_DETAIL,
    {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
    },
  );

  const [importProductMutation, importProductResponse] = useMutation(
    IMPORT_PRODUCT,
    {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
    },
  );

  const [getImportEntityFileData, importEntityUrlResponse] = useLazyQuery(
    GET_IMPORT_ENTITY_FILE_DATA,
    {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
    },
  );

  const processImportProduct = useCallback(
    (file: FileUploadInput) => {
      importProductMutation({ variables: { file } });
    },
    [importProductMutation],
  );

  const getImportEntityData = useCallback(() => {
    getImportEntityFileData({
      variables: { type: IMPORT_ENTITY_TYPE.PRODUCT },
    });
  }, [getImportEntityFileData]);

  const getImportProductDetail = useCallback(
    (id: string) => {
      getImportEntityDetail({ variables: { id } });
    },
    [getImportEntityDetail],
  );

  const loading = importProductResponse.loading;
  const importEntityFileData =
    importEntityUrlResponse.data?.getImportEntityFileData ?? '';

  return useMemo(
    () => ({
      loading,
      processImportProduct,
      importProductId: importProductResponse.data?.importProducts ?? '',
      importProductDetail:
        importEntityDetailResponse.data?.getImportEntityDetail,
      getImportProductDetail,
      getImportEntityData,
      importEntityFileData,
    }),
    [
      processImportProduct,
      loading,
      importProductResponse.data?.importProducts,
      importEntityDetailResponse.data,
      getImportProductDetail,
      getImportEntityData,
      importEntityFileData,
    ],
  );
}

export * from './graphql';
