import { useCallback, useMemo } from 'react';
import { VoucherResponse } from '@oolio-group/domain';
import { VALIDATE_VOUCHER } from './graphql';
import { useApolloClient } from '@apollo/client/react/hooks';

interface useVouchersProps {
  validateVoucher: (voucherCode: string) => Promise<VoucherResponse>;
}

export function useVouchers(): useVouchersProps {
  const client = useApolloClient();

  const validateVoucher = useCallback(
    async voucherCode => {
      const response = await client.query<{ validateVoucher: VoucherResponse }>(
        {
          query: VALIDATE_VOUCHER,
          fetchPolicy: 'network-only',
          variables: {
            voucherCode,
          },
        },
      );
      return response.data.validateVoucher;
    },
    [client],
  );

  return useMemo(
    () => ({
      validateVoucher,
    }),
    [validateVoucher],
  );
}
