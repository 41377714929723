import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import theme from '../../../common/default-theme';

interface Props {
  size?: number;
  color?: string;
}

const AppSwitcherIcon: React.FC<Props> = ({
  size = 16,
  color = theme.colors.primary,
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G transform="translate(-12, -12)" fill={color}>
          <Path d="M26,28 C27.1045695,28 28,27.1045695 28,26 C28,24.8954305 27.1045695,24 26,24 C24.8954305,24 24,24.8954305 24,26 C24,27.1045695 24.8954305,28 26,28 Z M20,28 C21.1045695,28 22,27.1045695 22,26 C22,24.8954305 21.1045695,24 20,24 C18.8954305,24 18,24.8954305 18,26 C18,27.1045695 18.8954305,28 20,28 Z M14,28 C15.1045695,28 16,27.1045695 16,26 C16,24.8954305 15.1045695,24 14,24 C12.8954305,24 12,24.8954305 12,26 C12,27.1045695 12.8954305,28 14,28 Z M26,22 C27.1045695,22 28,21.1045695 28,20 C28,18.8954305 27.1045695,18 26,18 C24.8954305,18 24,18.8954305 24,20 C24,21.1045695 24.8954305,22 26,22 Z M20,22 C21.1045695,22 22,21.1045695 22,20 C22,18.8954305 21.1045695,18 20,18 C18.8954305,18 18,18.8954305 18,20 C18,21.1045695 18.8954305,22 20,22 Z M14,22 C15.1045695,22 16,21.1045695 16,20 C16,18.8954305 15.1045695,18 14,18 C12.8954305,18 12,18.8954305 12,20 C12,21.1045695 12.8954305,22 14,22 Z M26,16 C27.1045695,16 28,15.1045695 28,14 C28,12.8954305 27.1045695,12 26,12 C24.8954305,12 24,12.8954305 24,14 C24,15.1045695 24.8954305,16 26,16 Z M20,16 C21.1045695,16 22,15.1045695 22,14 C22,12.8954305 21.1045695,12 20,12 C18.8954305,12 18,12.8954305 18,14 C18,15.1045695 18.8954305,16 20,16 Z M14,16 C15.1045695,16 16,15.1045695 16,14 C16,12.8954305 15.1045695,12 14,12 C12.8954305,12 12,12.8954305 12,14 C12,15.1045695 12.8954305,16 14,16 Z"></Path>
        </G>
      </G>
    </Svg>
  );
};

export default AppSwitcherIcon;
