import React, { useState, useCallback, useRef } from 'react';
import {
  View,
  Text,
  ViewStyle,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
  StyleSheet,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import {
  getContainerStyle,
  getLabelStyle,
  getIconColor,
} from './TreatButton.styles';
import selectStyles from '../Select/Select.styles';

export interface Action {
  id?: string;
  label: string;
  icon?: string;
  iconColor?: string;
  disabled?: boolean;
  textStyle?: 'default' | 'positive' | 'neutral' | 'focus' | 'negative';
  action: () => void;
}

export interface ButtonActionsProps {
  testID?: string;
  label: string;
  icon?: string;
  type?:
    | 'primary'
    | 'primaryLight'
    | 'accent'
    | 'positive'
    | 'negative'
    | 'neutral'
    | 'positiveLight'
    | 'negativeLight'
    | 'neutralLight'
    | 'focus'
    | 'focusLight'
    | 'cancel'
    | 'interface';
  containerStyle?: ViewStyle | ViewStyle[];
  loading?: boolean;
  placement?: PopoverPlacement;
  uppercase?: boolean;
  disabled?: boolean;
  actions: Action[];
  height?: number;
}

const ButtonActions: React.FC<ButtonActionsProps> = ({
  testID,
  label,
  icon,
  actions,
  loading,
  placement,
  uppercase,
  type,
  containerStyle,
  disabled,
  height,
}: ButtonActionsProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const onToggleOptions = useCallback((): void => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const handlePress = useCallback(
    (action: Function) => {
      action();
      closeDropDown();
    },
    [closeDropDown],
  );

  const onToggle = useCallback(
    (action: Function) => {
      onToggleOptions();
      setTimeout(() => {
        handlePress(action);
      }, 500);
    },
    [handlePress, onToggleOptions],
  );

  const touchable = useRef(null);

  const iconColor = getIconColor(
    !disabled ? type : type === 'interface' ? 'disabledInterface' : 'disabled',
  );

  return (
    <View style={containerStyle}>
      <View>
        <TouchableOpacity
          ref={touchable}
          testID={testID}
          onBlur={onBlur}
          disabled={disabled}
          onPress={onToggleOptions}
          style={[
            getContainerStyle(
              !disabled
                ? type
                : type === 'interface'
                ? 'disabledInterface'
                : 'disabled',
            ),
            { height: height || 44, minWidth: icon ? height : undefined },
          ]}
        >
          {loading ? (
            <ActivityIndicator
              color={
                type === 'primaryLight' ? theme.colors.dark : theme.colors.light
              }
            />
          ) : !icon ? (
            <>
              <Text
                style={getLabelStyle(!disabled ? type : 'disabled', uppercase)}
              >
                {label}
              </Text>
              <Icon
                size={20}
                name={icon ? icon : showOptions ? 'angle-up' : 'angle-down'}
                color={iconColor}
                style={styles.btnIcon}
              />
            </>
          ) : (
            <Icon size={20} name={icon} color={iconColor} />
          )}
        </TouchableOpacity>
      </View>
      <Popover
        from={touchable}
        isVisible={showOptions}
        placement={placement ?? PopoverPlacement.AUTO}
        onRequestClose={() => onRequestClosePopover()}
        backgroundStyle={selectStyles.bgStyle}
        popoverStyle={selectStyles.optionsContainer}
      >
        <View
          style={[
            selectStyles.optionsPopup,
            actions.length < 10 && { maxHeight: actions.length * 40 + 20 },
          ]}
        >
          <ScrollView>
            {actions.map((action, i: number) => (
              <TouchableOpacity
                key={i}
                disabled={action.disabled}
                onPress={() => onToggle(action.action)}
                // eslint-disable-next-line react-native/no-inline-styles
                style={[styles.option, { opacity: action.disabled ? 0.3 : 1 }]}
              >
                <Text
                  numberOfLines={1}
                  style={[
                    selectStyles.optionText,
                    action.textStyle && action.textStyle !== 'default'
                      ? { color: theme.colors.states[action.textStyle] }
                      : null,
                  ]}
                >
                  {action.label}
                </Text>
                <View style={styles.icon}>
                  {action.icon ? (
                    <Icon
                      size={20}
                      name={action.icon}
                      color={action.iconColor}
                    />
                  ) : (
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey4}
                    />
                  )}
                </View>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      </Popover>
    </View>
  );
};

export default ButtonActions;

const styles = StyleSheet.create({
  btnIcon: {
    marginLeft: 4,
  },
  icon: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 10,
  },
});
