import * as React from 'react';
import {
  View,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@oolio-group/domain';
import IconButton from '../Button/IconButton';
export interface DataTableRowProps {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  rowIndex?: number;
  subItems?: React.ReactElement;
  children?: React.ReactNode;
}

const container: StyleFn = ({ theme }) => ({
  minHeight: theme.spacing.medium,
  paddingHorizontal: theme.padding.small * 3,
});

const iconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraphLight,
});

const content: StyleFn = ({ theme, rowIndex }) => ({
  flex: 1,
  minHeight: 42,
  flexDirection: 'row',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 0.8,
  borderStyle: 'solid',
  paddingTop: theme.padding.medium,
  zIndex: 2000 - 20 * rowIndex,
  alignItems: 'center',
});

const DataTableRow: React.FC<DataTableRowProps> = props => {
  const { css, theme } = useFela();
  const { style, onPress, subItems, ...rest } = props;
  const isExpandAndCollapseMode = subItems !== undefined;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onPressExpandCollapse = React.useCallback(() => {
    setIsExpanded(status => !status);
  }, []);

  return (
    <>
      <TouchableWithoutFeedback
        {...rest}
        onPress={onPress}
        style={css(container)}
      >
        <View
          style={[css(content({ theme, rowIndex: props.rowIndex })), style]}
        >
          {isExpandAndCollapseMode && (
            <IconButton
              icon={isExpanded ? 'AngleUp' : 'AngleRight'}
              iconStyle={css(iconStyle)}
              containerSize={25}
              iconSize={25}
              testID="expand-collapse-icon"
              onPress={onPressExpandCollapse}
            />
          )}
          {props.children}
        </View>
      </TouchableWithoutFeedback>
      {isExpanded && <View>{subItems}</View>}
    </>
  );
};

export default DataTableRow;

export { DataTableRow };
