import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import {
  CreateAndUpdateRewardRule,
  RewardType,
  DiscountType,
  Venue,
  DEFAULT_ENTITY_ID,
  LoyaltySettings,
  Product,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import setKeyValue from 'lodash/set';
import { cloneDeep, differenceBy } from 'lodash';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import * as yup from 'yup';
import { TransformFunction } from 'yup/lib/types';
import SelectProductsModal from './SelectProductsModal';
import styles from '../Settings.styles';
import theme from '../../../../../common/default-theme';
import modalStyles from '../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import InputDropdown from '../../../../../components/Shared/Inputs/InputDropdown';

const TYPE_WITH_DISCOUNT_AMOUNT = [
  RewardType.DISCOUNT_ENTIRE_SALE,
  RewardType.PRODUCT_DISCOUNT,
];

export type RewardRuleState = {
  id?: string;
  venueIds: string[];
  rewardType: RewardType;
  rewardName: string;
  pointsRequired: string;
  discountAmount?: string;
  discountType?: DiscountType;
  maximumDiscountAmount?: string;
  products?: Product[];
};

interface CreateAndEditRewardRuleModalProps {
  onCreateAndUpdate: (rewardRule: CreateAndUpdateRewardRule) => void;
  editingRewardRule?: RewardRuleState;
  allVenues: Venue[];
  loyalSettings: Partial<LoyaltySettings>;
  editMode?: boolean;
}

const defaultRewardRule: RewardRuleState = {
  rewardType: RewardType.DISCOUNT_ENTIRE_SALE,
  rewardName: '',
  pointsRequired: '',
  discountAmount: '',
  discountType: DiscountType.PERCENTAGE,
  venueIds: [],
  maximumDiscountAmount: '',
  products: undefined,
};

const transformNumber: TransformFunction<yup.NumberSchema> = (
  value,
  original,
) => (original ? value : undefined);

const formSchema = yup.object().shape({
  rewardType: yup.string().oneOf(Object.values(RewardType)).required(),
  rewardName: yup.string().required().label('Reward Name'),
  pointsRequired: yup
    .number()
    .transform(transformNumber)
    .required()
    .integer()
    .positive()
    .label('Reward Value'),
  discountType: yup
    .string()
    .oneOf(Object.values(DiscountType))
    .when('rewardType', {
      is: RewardType.FREE_ITEM,
      then: schema => schema.optional(),
      otherwise: schema => schema.required(),
    }),
  discountAmount: yup
    .number()
    .transform(transformNumber)
    .moreThan(0)
    .positive()
    .when('rewardType', {
      is: RewardType.FREE_ITEM,
      then: schema => schema.optional(),
      otherwise: schema => schema.required(),
    })
    .when('discountType', {
      is: DiscountType.PERCENTAGE,
      then: schema => schema.integer().max(100),
    })
    .label('Discount Amount'),
  maximumDiscountAmount: yup
    .number()
    .transform(transformNumber)
    .positive()
    .optional()
    .when('discountType', {
      is: DiscountType.FLAT,
      // remove maximumDiscountAmount for discount type of FLAT
      then: schema => schema.transform(() => undefined),
    })
    .label('Maximum Discount'),
  venueIds: yup.array().of(yup.string()).optional(),
  products: yup
    .array()
    .transform((value: Product[]) => value?.map(product => product.id))
    .optional(),
});

const CreateAndEditRewardRuleModal: React.FC<
  CreateAndEditRewardRuleModalProps
> = props => {
  const {
    onCreateAndUpdate,
    editingRewardRule,
    allVenues,
    loyalSettings,
    editMode,
  } = props;
  const { showModal, closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { currencySymbol } = useCurrency();

  const isEditMode =
    editMode !== undefined ? editMode : editingRewardRule !== undefined;

  const [rewardRuleData, setRewardRuleData] = useState(
    editingRewardRule || defaultRewardRule,
  );

  const onChangeItem = useCallback((path: string, value) => {
    setRewardRuleData(existingRuleData => {
      const newData = cloneDeep(existingRuleData);
      setKeyValue(newData, path, value);
      return newData;
    });
  }, []);

  const onChangeRewardType = useCallback(value => {
    setRewardRuleData(existingRuleData => {
      const newData = cloneDeep(existingRuleData);
      setKeyValue(newData, 'rewardType', value);
      switch (value) {
        case RewardType.FREE_ITEM:
          // if changed to Free Item, reset irrelevant fields
          setKeyValue(newData, 'discountType', undefined);
          setKeyValue(newData, 'discountAmount', undefined);
          setKeyValue(newData, 'maximumDiscountAmount', undefined);
          break;
        default:
          // else, retain values or set to default
          setKeyValue(
            newData,
            'discountType',
            newData.discountType || defaultRewardRule.discountType,
          );
          setKeyValue(
            newData,
            'discountAmount',
            newData.discountAmount || defaultRewardRule.discountAmount,
          );
          setKeyValue(
            newData,
            'maximumDiscountAmount',
            newData.maximumDiscountAmount ||
              defaultRewardRule.maximumDiscountAmount,
          );
          break;
      }
      return newData;
    });
  }, []);

  const validateInput = useCallback(
    (rewardData: RewardRuleState) => {
      if (rewardData.venueIds.length === 0) {
        showNotification({
          message: translate('backOfficeLoyalty.selectVenue'),
          error: true,
        });
        return;
      }

      try {
        const updatedData = formSchema.validateSync(
          rewardData,
        ) as CreateAndUpdateRewardRule;

        return updatedData;
      } catch (error) {
        showNotification({
          message: (error as yup.ValidationError).message,
          error: true,
        });
        return undefined;
      }
    },
    [showNotification, translate],
  );

  const onCreateAndUpdateRewardRule = useCallback(
    (rewardData: RewardRuleState) => {
      const updatedData = validateInput(rewardData);

      updatedData && onCreateAndUpdate(updatedData);
    },
    [onCreateAndUpdate, validateInput],
  );

  const dropdownValues = useMemo(() => {
    return rewardRuleData.venueIds.length == allVenues.length
      ? [DEFAULT_ENTITY_ID]
      : [...(rewardRuleData?.venueIds || [])];
  }, [allVenues.length, rewardRuleData.venueIds]);

  const onChangeVenues = useCallback(
    (selectedVenues: string[]) => {
      if (!selectedVenues.length) {
        return onChangeItem('venueIds', []);
      }

      const clickedItemId =
        differenceBy(selectedVenues, dropdownValues)[0] ||
        differenceBy(dropdownValues, selectedVenues)[0];

      if (clickedItemId === DEFAULT_ENTITY_ID) {
        const allVenueIds = allVenues.map(venue => venue.id);
        return onChangeItem('venueIds', allVenueIds);
      }

      const isItemSelected = rewardRuleData.venueIds.includes(clickedItemId);
      const updatedVenueIds = isItemSelected
        ? rewardRuleData.venueIds.filter(venueId => venueId !== clickedItemId)
        : rewardRuleData.venueIds.concat(clickedItemId);
      onChangeItem('venueIds', updatedVenueIds);
    },
    [dropdownValues, rewardRuleData.venueIds, onChangeItem, allVenues],
  );

  const showSelectProductsModal = () => {
    const updatedData = validateInput(rewardRuleData);

    updatedData &&
      showModal(
        <SelectProductsModal
          onSubmit={assignedProducts => {
            onCreateAndUpdateRewardRule({
              ...rewardRuleData,
              products: assignedProducts,
            });
          }}
          onBack={assignedProducts => {
            showModal(
              <CreateAndEditRewardRuleModal
                {...props}
                editingRewardRule={{
                  ...rewardRuleData,
                  products: assignedProducts,
                }}
                editMode={isEditMode}
              />,
            );
          }}
          selectedProducts={rewardRuleData.products}
          action={
            isEditMode
              ? translate('backOfficeLoyalty.editReward')
              : translate('backOfficeLoyalty.createReward')
          }
        />,
      );
  };

  const isOrderDiscount =
    rewardRuleData.rewardType === RewardType.DISCOUNT_ENTIRE_SALE;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <View testID="modal" style={styles.modal}>
        <View style={modalStyles.title}>
          <Text style={[modalStyles.titleText, { color: theme.colors.blue }]}>
            {isEditMode
              ? translate('backOfficeLoyalty.editReward')
              : translate('backOfficeLoyalty.createReward')}
          </Text>
        </View>
        <View style={styles.modalContent}>
          <View style={theme.forms.row}>
            <InputText
              testID="input-name"
              title={translate('backOfficeLoyalty.rewardName')}
              value={rewardRuleData.rewardName}
              placeholder="Enter name..."
              onChangeText={onChangeItem.bind(null, 'rewardName')}
              maxLength={50}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
          <View style={theme.forms.row}>
            <TreatPicker
              testID="select-type"
              title={translate('backOfficeLoyalty.rewardType')}
              options={[
                {
                  label: translate('backOfficeLoyalty.discountEntireSale'),
                  value: RewardType.DISCOUNT_ENTIRE_SALE,
                },
                {
                  label: translate('backOfficeLoyalty.productDiscount'),
                  value: RewardType.PRODUCT_DISCOUNT,
                },
                {
                  label: translate('backOfficeLoyalty.freeItem'),
                  value: RewardType.FREE_ITEM,
                },
              ]}
              selectedValue={rewardRuleData.rewardType}
              onValueChange={onChangeRewardType}
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              testID="input-rewardValue"
              title={translate('backOfficeLoyalty.rewardValue')}
              value={rewardRuleData.pointsRequired.toString()}
              placeholder="0"
              onChangeText={onChangeItem.bind(null, 'pointsRequired')}
              label={loyalSettings.pluralTerm || 'Points'}
              alignText="right"
              keyboardType="numeric"
              maxLength={8}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
          {TYPE_WITH_DISCOUNT_AMOUNT.includes(rewardRuleData.rewardType) && (
            <View style={theme.forms.row}>
              <InputDropdown
                testID="input-amount"
                title={translate('backOfficeLoyalty.discountAmount')}
                value={rewardRuleData.discountAmount?.toString()}
                placeholder="0.00"
                options={[
                  { value: DiscountType.PERCENTAGE, label: '%' },
                  {
                    value: DiscountType.FLAT,
                    label: currencySymbol,
                  },
                ]}
                selectedOption={rewardRuleData.discountType}
                onOptionChange={value => onChangeItem('discountType', value)}
                onChangeText={value =>
                  onChangeItem(
                    'discountAmount',
                    formatToDigitsStr(value, false),
                  )
                }
                alignText="right"
                keyboardType="numeric"
                containerStyle={theme.forms.inputHalf}
              />
            </View>
          )}
          {rewardRuleData.discountType === DiscountType.PERCENTAGE && (
            <View style={theme.forms.row}>
              <InputText
                testID="input-maxAmount"
                title={translate('backOfficeLoyalty.maximumDiscount')}
                label={currencySymbol}
                value={rewardRuleData.maximumDiscountAmount?.toString()}
                placeholder="0.00"
                onChangeText={value =>
                  onChangeItem(
                    'maximumDiscountAmount',
                    formatToDigitsStr(value, false),
                  )
                }
                maxLength={8}
                alignText="right"
                keyboardType="numeric"
                containerStyle={theme.forms.inputHalf}
              />
            </View>
          )}
          <View style={theme.forms.row}>
            <SelectMultiple
              testID="select-venues"
              title={translate('backOfficeLoyalty.venues')}
              options={[
                {
                  label: translate('backOfficeLoyalty.allVenues'),
                  value: DEFAULT_ENTITY_ID,
                },
              ].concat(
                allVenues.map(venue => ({
                  label: venue.name,
                  value: venue.id,
                })),
              )}
              selectedValues={dropdownValues}
              onValueChange={onChangeVenues}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        </View>
        <View style={modalStyles.actions}>
          <TreatButton
            testID="btn-dismiss"
            label={translate('form.dismiss')}
            type="cancel"
            onPress={closeModal}
          />
          <TreatButton
            testID={isOrderDiscount ? 'btn-update' : 'btn-next'}
            label={
              isOrderDiscount && isEditMode
                ? translate('backOfficeLoyalty.editReward')
                : isOrderDiscount && !isEditMode
                ? translate('backOfficeLoyalty.createReward')
                : translate('backOfficeLoyalty.selectProducts')
            }
            type="neutral"
            onPress={
              isOrderDiscount
                ? () => onCreateAndUpdateRewardRule(rewardRuleData)
                : () => showSelectProductsModal()
            }
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default CreateAndEditRewardRuleModal;
