import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
  section: {
    marginTop: 40,
    width: theme.layoutWidth.s,
  },
  sectionTitle: {
    fontSize: 14,
    lineHeight: 14,
    paddingLeft: 14,
    marginBottom: 12,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  credit: {
    opacity: 0.1,
    marginVertical: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  creditText: {
    fontSize: 12,
    lineHeight: 14,
    marginBottom: 12,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.bold,
  },
  locales: {
    paddingLeft: 20,
    marginBottom: 8,
  },
  printers: {
    marginTop: 6,
    marginLeft: 20,
    marginBottom: 14,
  },
  printerAction: {
    marginBottom: 4,
  },
});

export default styles;
