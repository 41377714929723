import { useCallback } from 'react';
import {
  Adjustment,
  OrderAction,
  AdjustmentByOrderItemMap,
  UpdateOrderAdjustmentsEvent,
  UpdateOrderItemsAdjustmentEvent,
  RemoveOrderAdjustments,
  SplitQuantityOrderItemEvent,
} from '@oolio-group/domain';
import {
  isScheduleAdjustmentsAppliedInCart,
  getAdvancedDiscountDetailByOrderItems,
} from '@oolio-group/order-helper';
import { useCart } from '../orders/useCart';

interface UseAdjustmentScheduleReturn {
  applyScheduleAdjustments: (adjustments: Adjustment[]) => void;
  applyAdvancedDiscounts: (
    adjustmentByOrderItemMap?: AdjustmentByOrderItemMap,
  ) => void;
}

export const useAdjustmentSchedule = (): UseAdjustmentScheduleReturn => {
  const { updateCart, order } = useCart();

  const applyScheduleAdjustments = useCallback(
    (adjustments: Adjustment[]) => {
      const isScheduleAdjustmentAppliedInCart =
        isScheduleAdjustmentsAppliedInCart(
          adjustments,
          order?.adjustments || [],
        );
      if (isScheduleAdjustmentAppliedInCart) return;
      updateCart<UpdateOrderAdjustmentsEvent>(
        OrderAction.ORDER_UPDATE_ADJUSTMENTS,
        {
          adjustments: adjustments,
        },
      );
    },
    [order?.adjustments, updateCart],
  );

  const applyAdvancedDiscounts = useCallback(
    (adjustmentByOrderItemMap = {} as AdjustmentByOrderItemMap) => {
      const orderItemKeys = Object.keys(adjustmentByOrderItemMap || {});
      if (!orderItemKeys?.length) return;
      const {
        groupAdjustmentByItemIds,
        adjustmentToRemoveFromOrderItemIds,
        splittedOrderItems,
      } = getAdvancedDiscountDetailByOrderItems(adjustmentByOrderItemMap);

      // Add adjustment to orderItemIds
      groupAdjustmentByItemIds.forEach(item => {
        updateCart<UpdateOrderItemsAdjustmentEvent>(
          OrderAction.ORDER_ITEMS_UPDATE_ADJUSTMENT,
          {
            orderItemIds: item.orderItemIds,
            adjustment: item.adjustment,
          },
        );
      });

      // Remove adjustment from orderItems
      if (adjustmentToRemoveFromOrderItemIds?.length) {
        updateCart<RemoveOrderAdjustments>(
          OrderAction.ORDER_REMOVE_ADJUSTMENTS,
          {
            orderItemIds: adjustmentToRemoveFromOrderItemIds,
          },
        );
      }

      if (splittedOrderItems?.length) {
        splittedOrderItems.forEach(item => {
          updateCart<SplitQuantityOrderItemEvent>(
            OrderAction.ORDER_ITEM_SPLIT_QUANTITY,
            {
              quantity: item.quantity,
              orderItemId: item.orderItemId,
              splitOrderItemId: item.splitOrderItemId,
              splitViaAdvanceDiscountRule: true,
            } as SplitQuantityOrderItemEvent,
          );
        });
      }
    },
    [updateCart],
  );

  return {
    applyScheduleAdjustments,
    applyAdvancedDiscounts,
  };
};
