/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import Svg, { Path, G, Defs, Mask, Use } from 'react-native-svg';

const GooglePay: React.FC = () => {
  return (
    <Svg width="30" height="20" viewBox="0 0 30 20">
      <Defs>
        <Path
          id="path-1"
          d="M2 0h26a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        ></Path>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Mask id="mask-2" fill="#fff">
          <Use xlinkHref="#path-1"></Use>
        </Mask>
        <G mask="url(#mask-2)">
          <G fillRule="nonzero" transform="translate(0 2)">
            <Path
              fill="#FFF"
              d="M22.021 0H7.98C3.59 0 0 3.6 0 8s3.59 8 7.979 8H22.02C26.41 16 30 12.4 30 8s-3.59-8-7.979-8z"
            ></Path>
            <Path
              fill="#3C4043"
              d="M22.021.648c.986 0 1.943.196 2.845.58a7.395 7.395 0 013.91 3.92c.382.904.578 1.864.578 2.852 0 .988-.196 1.948-.579 2.852a7.417 7.417 0 01-3.91 3.92 7.221 7.221 0 01-2.844.58H7.98a7.221 7.221 0 01-2.845-.58 7.395 7.395 0 01-3.91-3.92A7.274 7.274 0 01.647 8c0-.988.196-1.948.579-2.852a7.417 7.417 0 013.91-3.92 7.221 7.221 0 012.844-.58H22.02m0-.648H7.98C3.59 0 0 3.6 0 8s3.59 8 7.979 8H22.02C26.41 16 30 12.4 30 8s-3.59-8-7.979-8z"
            ></Path>
            <G fill="none" strokeWidth="1" transform="translate(4.568 4.576)">
              <G fill="#3C4043" transform="translate(8.972 .436)">
                <Path d="M.766 3.556v2.42H0V0h2.03c.515 0 .954.172 1.313.516.367.344.55.764.55 1.26 0 .508-.183.928-.55 1.268-.355.34-.794.508-1.312.508H.766v.004zm0-2.82V2.82h1.28c.304 0 .56-.104.759-.308.203-.204.307-.452.307-.732a.997.997 0 00-.307-.724.992.992 0 00-.758-.316H.766V.736z"></Path>
                <Path d="M5.896 1.752c.567 0 1.014.152 1.34.456.328.304.491.72.491 1.248v2.52h-.73v-.568h-.032c-.315.468-.738.7-1.264.7-.451 0-.826-.132-1.13-.4a1.279 1.279 0 01-.454-1c0-.424.16-.76.479-1.008.319-.252.746-.376 1.276-.376.455 0 .83.084 1.121.252V3.4a.873.873 0 00-.315-.68 1.08 1.08 0 00-.742-.28c-.427 0-.766.18-1.013.544l-.674-.424c.37-.54.921-.808 1.647-.808zm-.99 2.968c0 .2.085.368.256.5.168.132.367.2.595.2.323 0 .61-.12.861-.36.252-.24.38-.52.38-.844-.24-.188-.571-.284-.998-.284-.311 0-.57.076-.778.224-.211.156-.315.344-.315.564z"></Path>
                <Path d="M11.8922872 1.884L9.33909574 7.772 8.54920213 7.772 9.49867021 5.712 7.81515957 1.884 8.64893617 1.884 9.86170213 4.82 9.87765957 4.82 11.0585106 1.884z"></Path>
              </G>
              <G>
                <Path
                  fill="#4285F4"
                  d="M6.691 3.504c0-.25-.022-.49-.063-.72H3.417v1.32h1.849c-.075.44-.316.814-.686 1.063v.857h1.1c.643-.597 1.011-1.478 1.011-2.52z"
                ></Path>
                <Path
                  fill="#34A853"
                  d="M4.58 5.167c-.306.207-.7.329-1.162.329-.892 0-1.648-.603-1.92-1.415H.364v.883a3.417 3.417 0 003.055 1.888c.923 0 1.698-.305 2.263-.829l-1.1-.856z"
                ></Path>
                <Path
                  fill="#FABB05"
                  d="M1.392 3.426c0-.228.038-.448.107-.656v-.883H.363A3.416 3.416 0 000 3.426c0 .554.131 1.076.363 1.539L1.5 4.082a2.071 2.071 0 01-.107-.656z"
                ></Path>
                <Path
                  fill="#E94235"
                  d="M3.418 1.356c.504 0 .955.174 1.311.514l.975-.977A3.279 3.279 0 003.418 0 3.417 3.417 0 00.363 1.887L1.5 2.77c.27-.812 1.027-1.414 1.919-1.414z"
                ></Path>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default GooglePay;
