import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { OrderTypeCode, FeatureIDs } from '@oolio-group/domain';
import {
  DeviceProfile,
  UpdateDeviceProfileOptionsInput,
} from '@oolio-group/domain';
import { pick } from 'lodash';
import { useNotification } from '../../../../../../hooks/Notification';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';
import { WithFeature } from '../../../../../../components/features/WithFeature';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import theme from '../../../../../../common/default-theme';

export const Options: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({} as DeviceProfile);
  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
    venueId: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';
  const venueId = params.venueId || '';

  const {
    deviceProfiles: deviceProfiles,
    orderTypes,
    loading,
    error,
    updatedDeviceProfileId,
    unAssignedDeviceProfileId,
    deletedDeviceProfile,
    getOrderTypes,
    updateDeviceProfileOptions,
  } = useDeviceProfiles({ deviceProfileId, storeId, venueId });

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm({
        ...deviceProfiles[deviceProfileId],
        ...(deviceProfiles[deviceProfileId].printZeroValueItems === null && {
          printZeroValueItems: true,
        }),
        ...(deviceProfiles[deviceProfileId].printZeroValueModifiers ===
          null && { printZeroValueModifiers: true }),
        ...(deviceProfiles[deviceProfileId].printNotes === null && {
          printNotes: true,
        }),
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const disableSeatManagementIfFloorViewIsDisabled = (
    updateParams: UpdateDeviceProfileOptionsInput,
  ) => {
    if (updateParams.enableFloorView) return updateParams;
    else return { ...updateParams, enableSeatManagement: false };
  };

  const onPressSave = useCallback((): void => {
    let updateParams: UpdateDeviceProfileOptionsInput = pick(form, [
      'id',
      'enableAudio',
      'receiptPrintOption',
      'signatureAndTipping',
      'enableCashManagement',
      'enableFloorView',
      'enableAllergens',
      'enableCourses',
      'enableSeatManagement',
      'enableHideZeroPrices',
      'enableComplimentaryMode',
      'enableOnlineOrders',
      'enableQRPrint',
      'enableKitchenBuzzer',
      'showPrinterProfileInDocket',
      'printZeroValueItems',
      'printZeroValueModifiers',
      'printNotes',
    ]);

    if (form.enableFloorView) {
      const dineInOrderTypeId =
        orderTypes &&
        (orderTypes.find(orderType => orderType.code === OrderTypeCode.DINE_IN)
          ?.id as string);

      updateParams.orderTypes = form.orderTypes.map(orderType => orderType.id);

      if (!updateParams.orderTypes.includes(dineInOrderTypeId || '')) {
        updateParams.orderTypes = [
          ...updateParams.orderTypes,
          dineInOrderTypeId,
        ];
      }
    }
    updateParams = disableSeatManagementIfFloorViewIsDisabled(updateParams);
    updateDeviceProfileOptions(updateParams);
  }, [form, updateDeviceProfileOptions, orderTypes]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
  ]);

  useEffect(() => {
    if (unAssignedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.deviceProfileUnassignedSuccessfully',
        ),
      });
    }
  }, [showNotification, translate, unAssignedDeviceProfileId]);

  const setOrderTypeOnEnablingFloorView = useCallback((): void => {
    if (!form.enableFloorView) {
      const dineInOrderType = orderTypes?.find(
        orderType => orderType?.code == OrderTypeCode.DINE_IN,
      );
      const isDineInEnabled =
        dineInOrderType &&
        Boolean(
          form.orderTypes.filter(
            orderType => orderType.id == dineInOrderType.id,
          ).length,
        );
      !isDineInEnabled &&
        dineInOrderType &&
        setForm({ ...form, orderTypes: [...form.orderTypes, dineInOrderType] });
    }
    onChange('enableFloorView', !form.enableFloorView);
  }, [form, onChange, orderTypes]);

  const onChangeEnableSeatManagement = () => {
    if (!form.enableSeatManagement && !form.enableFloorView)
      showNotification({
        error: true,
        message: translate(
          'deviceProfileSettings.enableSeatManagementExceptionForFloorView',
        ),
      });
    else onChange('enableSeatManagement', !form.enableSeatManagement);
  };

  const onChangeComplementaryMode = () => {
    if (!form.enableHideZeroPrices && !form.enableComplimentaryMode)
      showNotification({
        error: true,
        message: translate(
          'deviceProfileSettings.enableComplimentaryModeExceptionForHideZeroItems',
        ),
      });
    else onChange('enableComplimentaryMode', !form.enableComplimentaryMode);
  };

  return (
    <ScreenLayout
      loading={loading}
      title="Device Options | Oolio"
      onSave={onPressSave}
    >
      <Section title={translate('tabNames.features')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-seats"
            type="switch"
            title={translate('deviceProfileSettings.enableSeatManagement')}
            isToggled={form.enableSeatManagement}
            onToggle={onChangeEnableSeatManagement}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-onlineOrders"
            type="switch"
            title={translate('deviceProfileSettings.enableOnlineOrder')}
            isToggled={form.enableOnlineOrders}
            onToggle={onChange.bind(
              null,
              'enableOnlineOrders',
              !form.enableOnlineOrders,
            )}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-cashManagement"
            type="switch"
            title={translate('deviceProfileSettings.enableCashManagement')}
            isToggled={form.enableCashManagement}
            onToggle={onChange.bind(
              null,
              'enableCashManagement',
              !form.enableCashManagement,
            )}
            containerStyle={theme.forms.inputHalf}
          />
          <WithFeature
            feature={{
              featureId: FeatureIDs.ALLERGENS,
            }}
            venue={params.venueId}
          >
            <InputToggle
              testID="toggle-allergens"
              type="switch"
              title={translate('deviceProfileSettings.enableAllergens')}
              isToggled={!!form.enableAllergens}
              onToggle={() =>
                onChange('enableAllergens', !form.enableAllergens)
              }
              containerStyle={theme.forms.inputHalf}
            />
          </WithFeature>
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-enableHideZeroPrices"
            type="switch"
            title={translate('deviceProfileSettings.enableHideZeroPrices')}
            isToggled={form.enableHideZeroPrices}
            onToggle={onChange.bind(
              null,
              'enableHideZeroPrices',
              !form.enableHideZeroPrices,
            )}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-enableComplimentaryMode"
            type="switch"
            title={translate('deviceProfileSettings.enableComplimentaryMode')}
            isToggled={form.enableComplimentaryMode}
            onToggle={onChangeComplementaryMode}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <WithFeature
            feature={{
              featureId: FeatureIDs.TABLE_MANAGEMENT,
            }}
            venue={params.venueId}
          >
            <InputToggle
              testID="toggle-floorView"
              type="switch"
              title={translate('deviceProfileSettings.enableFloorView')}
              isToggled={form.enableFloorView}
              onToggle={setOrderTypeOnEnablingFloorView}
              containerStyle={theme.forms.inputHalf}
            />
          </WithFeature>
          <WithFeature
            feature={{
              featureId: FeatureIDs.COURSES,
            }}
            venue={params.venueId}
          >
            <InputToggle
              testID="toggle-courses"
              type="switch"
              title={translate('deviceProfileSettings.enableCourses')}
              isToggled={form.enableCourses}
              onToggle={onChange.bind(
                null,
                'enableCourses',
                !form.enableCourses,
              )}
              containerStyle={theme.forms.inputHalf}
            />
          </WithFeature>
        </View>
      </Section>
      <Section title={translate('backOfficeSettings.customerReceiptPrinting')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-printAuto"
            type="radio"
            title={translate(
              'deviceProfileSettings.printReceiptsAutomatically',
            )}
            isToggled={form.receiptPrintOption === 'AUTO'}
            onToggle={onChange.bind(null, 'receiptPrintOption', 'AUTO')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-printPrompt"
            type="radio"
            title={translate(
              'deviceProfileSettings.promptForPrintEmailReceipts',
            )}
            isToggled={form.receiptPrintOption === 'PROMPT'}
            onToggle={onChange.bind(null, 'receiptPrintOption', 'PROMPT')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-printNo"
            type="radio"
            title={translate('deviceProfileSettings.doNotPrintOrPrompt')}
            isToggled={form.receiptPrintOption === 'NO_PRINT'}
            onToggle={onChange.bind(null, 'receiptPrintOption', 'NO_PRINT')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-printNotes"
            type="switch"
            title={translate('deviceProfileSettings.printNotes')}
            isToggled={form.printNotes || false}
            onToggle={onChange.bind(null, 'printNotes', !form.printNotes)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-printZeroValueItems"
            type="switch"
            title={translate('deviceProfileSettings.printZeroValueItems')}
            isToggled={form.printZeroValueItems || false}
            onToggle={onChange.bind(
              null,
              'printZeroValueItems',
              !form.printZeroValueItems,
            )}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-printZeroValueModifiers "
            type="switch"
            title={translate('deviceProfileSettings.printZeroValueModifiers')}
            isToggled={form.printZeroValueModifiers || false}
            onToggle={onChange.bind(
              null,
              'printZeroValueModifiers',
              !form.printZeroValueModifiers,
            )}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('backOfficeSettings.receiptPrintingFeatures')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-docketProfile"
            type="switch"
            title={translate(
              'deviceProfileSettings.showPrinterProfileInDocket',
            )}
            isToggled={form.showPrinterProfileInDocket || false}
            onToggle={onChange.bind(
              null,
              'showPrinterProfileInDocket',
              !form.showPrinterProfileInDocket,
            )}
            containerStyle={theme.forms.inputHalf}
          />
          <InputToggle
            testID="toggle-qrPrint"
            type="switch"
            title={translate('deviceProfileSettings.enableQRPrint')}
            isToggled={form.enableQRPrint}
            onToggle={onChange.bind(null, 'enableQRPrint', !form.enableQRPrint)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-kitchenBuzzer"
            type="switch"
            title={translate('deviceProfileSettings.enableKitchenBuzzer')}
            isToggled={form.enableKitchenBuzzer}
            onToggle={onChange.bind(
              null,
              'enableKitchenBuzzer',
              !form.enableKitchenBuzzer,
            )}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
    </ScreenLayout>

    //       {/* <BackOfficeSection
    //         title={translate(
    //           'backOfficeSettings.signatureTippingMerchantCopies',
    //         )}
    //         contentContainerStyle={css(formContainerStyleSmall)}
    //         containerStyle={css(backOfficeContainerStyle)}
    //         titleBorderBottom
    //       >
    //         <Button
    //           title={translate('deviceProfileSettings.tipSignOnPaymentScreen')}
    //           labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
    //           testID="deviceProfile-signatureAndTipping-TIP_AND_SIGN_PAYMENT_SCREEN"
    //           fluid
    //           iconPosition={'left'}
    //           onPress={onChange.bind(
    //             null,
    //             'signatureAndTipping',
    //             'TIP_AND_SIGN_PAYMENT_SCREEN',
    //           )}
    //           containerStyle={css(checkBoxTitleContainer({ theme }))}
    //           iconContainerStyle={css(radioCheckStyle)}
    //           icon={
    //             form.signatureAndTipping === 'TIP_AND_SIGN_PAYMENT_SCREEN'
    //               ? 'recordAudio'
    //               : 'circle'
    //           }
    //           iconProps={{
    //             color: theme.colors.success,
    //             size: 20,
    //           }}
    //         />

    //         <View>
    //           <Text style={css(labelTextStyle)}>
    //             {translate('deviceProfileSettings.tipSignOnPaymentScreenDesc')}
    //           </Text>
    //         </View>

    //         <Button
    //           title={translate(
    //             'deviceProfileSettings.tipSignOnPaymentDeviceProfile',
    //           )}
    //           labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
    //           testID="deviceProfile-signatureAndTipping-TIP_AND_SIGN_PAYMENT_DEVICE"
    //           fluid
    //           iconPosition={'left'}
    //           onPress={onChange.bind(
    //             null,
    //             'signatureAndTipping',
    //             'TIP_AND_SIGN_PAYMENT_DEVICE',
    //           )}
    //           containerStyle={css(checkBoxTitleContainer({ theme }))}
    //           iconContainerStyle={css(radioCheckStyle)}
    //           icon={
    //             form.signatureAndTipping === 'TIP_AND_SIGN_PAYMENT_DEVICE'
    //               ? 'recordAudio'
    //               : 'circle'
    //           }
    //           iconProps={{
    //             color: theme.colors.success,
    //             size: 20,
    //           }}
    //         />

    //         <View>
    //           <Text style={css(labelTextStyle)}>
    //             {translate(
    //               'deviceProfileSettings.tipSignOnPaymentDeviceProfileDesc',
    //             )}
    //           </Text>
    //         </View>

    //         <Button
    //           title={translate('deviceProfileSettings.tipSignOnBillReceipt')}
    //           labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
    //           testID="deviceProfile-signatureAndTipping-TIP_AND_SIGN_RECEIPT"
    //           fluid
    //           iconPosition={'left'}
    //           onPress={onChange.bind(
    //             null,
    //             'signatureAndTipping',
    //             'TIP_AND_SIGN_RECEIPT',
    //           )}
    //           containerStyle={css(checkBoxTitleContainer({ theme }))}
    //           iconContainerStyle={css(radioCheckStyle)}
    //           icon={
    //             form.signatureAndTipping === 'TIP_AND_SIGN_RECEIPT'
    //               ? 'recordAudio'
    //               : 'circle'
    //           }
    //           iconProps={{
    //             color: theme.colors.success,
    //             size: 20,
    //           }}
    //         />

    //         <View>
    //           <Text style={css(labelTextStyle)}>
    //             {translate('deviceProfileSettings.tipSignOnBillReceiptDesc')}
    //           </Text>
    //         </View>

    //         <Button
    //           title={translate('deviceProfileSettings.doNothing')}
    //           labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
    //           testID="deviceProfile-signatureAndTipping-NONE"
    //           fluid
    //           iconPosition={'left'}
    //           onPress={onChange.bind(null, 'signatureAndTipping', 'NONE')}
    //           containerStyle={css(checkBoxTitleContainer({ theme }))}
    //           iconContainerStyle={css(radioCheckStyle)}
    //           icon={
    //             form.signatureAndTipping === 'NONE' ? 'recordAudio' : 'circle'
    //           }
    //           iconProps={{
    //             color: theme.colors.success,
    //             size: 20,
    //           }}
    //         />

    //         <View>
    //           <Text style={css(labelTextStyle)}>
    //             {translate('deviceProfileSettings.doNothingDesc')}
    //           </Text>
    //         </View>
    //       </BackOfficeSection>
    //       */}
    //     </ScrollView>
    //     <DeviceProfileSettingsFooter
    //       deleteDeviceProfile={deleteDeviceProfile}
    //       unAssignDeviceProfile={unAssignDeviceProfile}
    //       onPressSave={}
    //       deviceProfileId={deviceProfileId}
    //       deviceProfiles={deviceProfiles}
    //       status={form.status}
    //     />
    //   </View>
    // </>
  );
};

//       {/* <BackOfficeSection
//         title={translate('backOfficeSettings.audioNotifications')}
//         contentContainerStyle={css(formContainerStyleSmall)}
//         containerStyle={css(backOfficeContainerStyle)}
//         titleBorderBottom
//       >
//         <Button
//           title={translate('deviceProfileSettings.onlineOrders')}
//           labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
//           fluid
//           testID="deviceProfile-enableAudio"
//           iconPosition={'left'}
//           onPress={onChange.bind(null, 'enableAudio', !form.enableAudio)}
//           containerStyle={css(checkBoxTitleContainer({ theme }))}
//           iconContainerStyle={
//             form.enableAudio
//               ? css(checkIconContainer)
//               : css(unCheckContainer)
//           }
//           icon={form.enableAudio ? 'check' : 'null'}
//           iconProps={{
//             color: theme.colors.success,
//             size: 15,
//           }}
//         />

//         <View>
//           <Text style={css(labelTextStyle)}>
//             {translate('deviceProfileSettings.onlineOrdersDesc')}
//           </Text>
//         </View>
//       </BackOfficeSection> */}
