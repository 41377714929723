/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import TreatButton from '../../../../Shared/TreatButton/TreatButton';
import styles from './RefundCashConfirmationModal.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../Icon/Icon';

export interface RefundCashConfirmationModalProps {
  amount: number;
  onConfirmRefund: () => void;
}

const RefundCashConfirmationModal: React.FC<
  RefundCashConfirmationModalProps
> = ({ amount, onConfirmRefund }) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const onConfirm = useCallback(() => {
    onConfirmRefund();
    closeModal();
  }, [onConfirmRefund, closeModal]);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.accessory}>
          <Icon
            size={30}
            color={theme.colors.states.neutral}
            name={'usd-circle'}
          />
        </View>
        <View>
          <Text style={styles.titleText}>
            {translate('refundOrder.cashRefundTitle', {
              refundAmount: formatCurrency(amount),
            })}
          </Text>
          <Text style={styles.subtitleText}>
            {translate('refundOrder.cashRefundMessage')}
          </Text>
        </View>
      </View>
      <View style={styles.actions}>
        <TreatButton
          type="positive"
          testID="btn-confirmCashRefund"
          label={translate('refundOrder.confirmCashRefund')}
          onPress={onConfirm}
          height={50}
        />
      </View>
    </View>
  );
};

export default RefundCashConfirmationModal;
