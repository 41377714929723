import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Course } from '@oolio-group/domain';
import styles from '../Courses.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';

interface CourseRowProps {
  course: Course;
  onDrag?: () => void;
  onEditCourse: (id: string) => void;
}

export type FieldKeys = 'name' | 'autoFire';

const CoursesRow: React.FC<CourseRowProps> = ({
  course,
  onDrag,
  onEditCourse,
}) => {
  return (
    <TouchableOpacity
      testID="row-course"
      style={theme.tables.row}
      onPress={() => onEditCourse(course.id)}
      onLongPress={onDrag}
    >
      <View style={theme.tables.disclosure}>
        <Icon name="draggabledots" size={20} color={theme.colors.grey5} />
      </View>
      <Text style={styles.cellName}>{course.name}</Text>
      <Text style={styles.cellProducts}>{course?.products?.length || '0'}</Text>
      <View style={styles.cellAutoFire}>
        <Icon
          name={course.autoFire ? 'check-circle' : 'circle'}
          color={
            course.autoFire ? theme.colors.states.positive : theme.colors.grey4
          }
        />
      </View>
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export default CoursesRow;
