import { ApolloClient, ApolloProvider, makeVar } from '@apollo/client';
import { GQLClient, SubscriptionState } from '@oolio-group/gql-client';
import {
  Locale,
  LocalizationProvider,
  supportedLocales,
} from '@oolio-group/localization';
import { persistCache } from 'apollo3-cache-persist';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-fela';
import { AppState, I18nManager } from 'react-native';
import { skip, Subscription } from 'rxjs';
import { APOLLO_CACHE_PERSIST_KEY } from './constants';
import './devConfig';
import { resolvers } from './graphql';
import { authFlowLink, SERVICE_URI } from './graphql/links/authFlowLink';
import { cdsEventLink } from './graphql/links/orderLink';
import { subscriptionStatus } from './hooks/app/useNetworkStatusVar';
import { useSettings } from './hooks/app/useSettings';
import { IntercomProvider } from './hooks/Intercom/IntercomProvider';
import OfficeUserAuthorizationProvider from './hooks/OfficeUserAuthorizationProvider';
import OfficeUserRoleProvider from './hooks/OfficeUserRoleProvider';
import RoleProvider from './hooks/RoleProvider';
import { SessionProvider } from './hooks/SessionProvider';
import Navigator from './Navigator';
import { Loader } from './screens/Loading/Loader';
import { workerInstanceVar } from './state/cache';
import * as settings from './state/preferences';
import { Session } from './state/Session';
import { subscriptionStateUtil } from './state/subscriptionStateUtils';
import * as storage from './storage/interface';
import { getApolloCache } from './utils/apolloClient';
import { loadTheme } from './utils/ThemeFactory';
import AnalyticsInitilizer from './analytics/AnalyticsInitilizer';
import BackgroundWorker from './workers/BackgroundWorker';
import { getPreferredApolloStorage } from './storage/getPreferredApolloStorage';
import Sentry from './utils/Sentry/Sentry';

const DEFAULT_COUNTRY_CODE = 'AU';
const DEFAULT_THEME = 'light';

export const changeDueVar = makeVar<number | null>(null);
const middlewares = [...authFlowLink, cdsEventLink];

const App: React.FC = () => {
  // Cache the value
  useSettings('orderCounter');
  const [theme] = useSettings<string>('theme');
  const [locale, setLocale] = useState<Locale>(supportedLocales['en-AU']);
  const [country, setCountry] = useState(DEFAULT_COUNTRY_CODE);
  const [client, setClient] = useState<ApolloClient<object> | undefined>(
    undefined,
  );
  const subscriptionStateRef = useRef<Subscription>();

  const onLocaleChange = useCallback((locale: Locale | undefined) => {
    if (locale) {
      setLocale(locale);
      I18nManager.forceRTL(locale.isRTL || false);
    }
  }, []);

  const onSessionChange = useCallback(
    (session: Partial<Session> | undefined) => {
      setCountry(session?.currentOrganization?.country ?? DEFAULT_COUNTRY_CODE);
      const sentryTags = {
        organizationName: session?.currentOrganization?.name,
        organization: session?.currentOrganization?.id,
        venue: session?.currentVenue?.id,
        store: session?.currentStore?.id,
        device: session?.device?.id,
        app: session?.activeApp,
        userId: session?.user?.id,
        userEmail: session?.user?.email,
        appVersion: session?.device?.appVersion,
      };
      Sentry.setTags(sentryTags);
    },
    [],
  );

  useEffect(() => {
    const worker = new BackgroundWorker();
    if (worker.worker) workerInstanceVar(worker);
    return () => {
      if (worker.worker) worker.worker.terminate();
    };
  }, []);

  useEffect(() => {
    settings.getLocale().then(onLocaleChange);
    settings.getSession().then(onSessionChange);
    storage.addSubscription(settings.LOCALE_KEY, onLocaleChange);
    storage.addSubscription(settings.SESSION_KEY, onSessionChange);
    return () => {
      storage.removeSubscription(settings.LOCALE_KEY, onLocaleChange);
      storage.removeSubscription(settings.SESSION_KEY, onSessionChange);
    };
  }, [onLocaleChange, onSessionChange]);

  // On component mount, restore cache
  // Meanwhile app renders a loading screen

  const initGQLClient = useCallback(async () => {
    const cache = getApolloCache();

    const getSession = async () => {
      const authState = await settings.getAuthenticationState();
      const session = await settings.getSession();
      return {
        ...session,
        token: authState?.token,
      };
    };

    // Waiting for storage initialization
    const storage = await getPreferredApolloStorage();
    await persistCache({
      cache,
      key: APOLLO_CACHE_PERSIST_KEY,
      storage: storage,
      maxSize: 10485760, // 10MB
    });

    const client = new GQLClient(
      {
        cache,
        resolvers,
        middlewares: middlewares,
        service: SERVICE_URI,
        getSession,
        client: 'pos-app',
        version: '1.0',
      },
      true,
      {
        onChangeSubscriptionState: subscriptionStateUtil.updateState,
      },
    ).getApolloClient();
    setClient(client);
  }, []);

  useEffect(() => {
    initGQLClient();
  }, [initGQLClient]);

  useEffect(() => {
    if (!subscriptionStateRef.current) {
      // Use subscription connection status to determine the network state
      // low latency & reliable comparing with useNetInfo depended on the system
      subscriptionStateRef.current = subscriptionStateUtil.getSubscriptionState$
        .pipe(skip(1))
        .subscribe((state: SubscriptionState) => {
          const active = state === SubscriptionState.CONNECTING;
          subscriptionStatus(active);
        });
    }

    return () => {
      subscriptionStateRef.current?.unsubscribe?.();
      subscriptionStateRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    const appStateSub = AppState.addEventListener('change', state => {
      if (/inactive/.test(state)) {
      }
    });
    return () => {
      appStateSub?.remove?.();
    };
  }, []);

  return (
    <ThemeProvider theme={loadTheme(theme || DEFAULT_THEME)}>
      {client && locale ? (
        <LocalizationProvider locale={locale} country={country}>
          <SessionProvider>
            <AnalyticsInitilizer>
              <ApolloProvider client={client}>
                <IntercomProvider>
                  <RoleProvider>
                    <OfficeUserRoleProvider>
                      <OfficeUserAuthorizationProvider>
                        <Navigator />
                      </OfficeUserAuthorizationProvider>
                    </OfficeUserRoleProvider>
                  </RoleProvider>
                </IntercomProvider>
              </ApolloProvider>
            </AnalyticsInitilizer>
          </SessionProvider>
        </LocalizationProvider>
      ) : (
        <Loader />
      )}
    </ThemeProvider>
  );
};

export default App;
