import React, { useCallback } from 'react';
import {
  View,
  Text,
  StatusBar,
  TouchableOpacity,
  Platform,
  Linking,
} from 'react-native';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuthentication } from '../../../hooks/app/useAuthentication';
import { styles } from './Login.styles';
import theme from '../../../common/default-theme';
import Logo from '../../../components/Logo/Logo';
import Gradient from '../../../components/Gradient/Gradient';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import { auth0Config } from '../../../constants';
import { useUserProfile } from '../../../hooks/app/useUserProfile';
import { analyticsService } from '../../../analytics/AnalyticsService';

export interface State {
  form: {
    email: string;
    password: string;
  };
  isLoggedIn?: boolean;
  empPassCode: string;
  errorMessage: string;
  isFormComplete: boolean;
  hidePassword: boolean;
}

const LoginTypeSelectionScreen: React.FC<{}> = ({}) => {
  const { getMe } = useUserProfile();
  const { translate } = useTranslation();
  const { width, height } = theme.useResponsiveDimensions();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<StackNavigationProp<any>>();

  const signUpURL = process.env['REACT_APP_SIGNUP_REDIRECT_URL'] || '';

  const onFinishLogin = useCallback(async () => {
    try {
      await getMe();
      navigation.navigate('BackOffice');
    } catch (error) {
      navigation.navigate('LoginTypeSelection');
    }
  }, [getMe, navigation]);

  const { login } = useAuthentication({
    redirectUrl: auth0Config.redirectUrl,
    onAuthorized: onFinishLogin,
  });

  const onPressLogin = useCallback(() => {
    login();
    analyticsService.capture('Login Method', {
      type: 'email',
    });
  }, [login]);

  const onPressCodeLogin = useCallback(() => {
    navigation.push('DeviceCodeLogin');
    analyticsService.capture('Login Method', {
      type: 'deviceCode',
    });
  }, [navigation]);

  const onPressCreateAccount = useCallback(() => {
    analyticsService.capture('Pressed Create Account');
    if (Platform.OS === 'web') {
      window.open(signUpURL);
    } else {
      Linking.openURL(signUpURL);
    }
  }, [signUpURL]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.loginPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={{ width, height }}>
        <Gradient style={styles.screen}>
          <View style={styles.modalContainer}>
            <Logo
              width={108}
              height={28}
              type="logotype"
              color="primary"
              style={styles.logo}
            />
            <TreatButton onPress={onPressLogin} label="Login" type="primary" />
            <TreatButton
              type="primaryLight"
              testID="device-code-button"
              label={translate('button.codeLogin')}
              onPress={onPressCodeLogin}
              containerStyle={styles.btnCode}
            />
            <View>
              <View style={styles.btnFooter}>
                <Text style={styles.btnFooterText}>
                  {translate('button.loginSupport')}
                  <Text style={styles.phone}>1300 166 546</Text>
                </Text>
              </View>
              <TouchableOpacity
                style={[styles.btnFooter, styles.btnCreate]}
                onPress={onPressCreateAccount}
              >
                <Text style={styles.btnFooterText}>
                  {translate('button.createAccount')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Gradient>
      </View>
    </>
  );
};

export default LoginTypeSelectionScreen;
