import { SubscriptionState } from '@oolio-group/gql-client';
import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { skip, Subscription } from 'rxjs';
import { getAuthenticationState } from '../state/preferences';
import { Session } from '../state/Session';
import { subscriptionStateUtil } from '../state/subscriptionStateUtils';
import { tokenUtility } from '../state/tokenUtility';
import { useSettings } from './app/useSettings';

export interface SessionProviderProps {
  children: React.ReactNode;
}

interface SessionContextValue {
  session: Session | undefined;
  setSession: (data: Session) => void;
}

export const SessionContext = createContext<SessionContextValue>(
  {} as SessionContextValue,
);

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}: SessionProviderProps) => {
  const subscriptionStateRef = useRef<Subscription>();
  const [session, setSessionValue] = useSettings<Session>('session');

  const setSession = useCallback(
    (data: Session) => {
      setSessionValue(data);
    },
    [setSessionValue],
  );

  const initTokenInformation = useCallback(async () => {
    const authState = await getAuthenticationState();
    if (authState) {
      tokenUtility.setTokenInfo(authState);
    } else {
      tokenUtility.clearToken();
    }
  }, []);

  useEffect(() => {
    initTokenInformation();
  }, [initTokenInformation]);

  useEffect(() => {
    if (!subscriptionStateRef.current) {
      subscriptionStateRef.current = subscriptionStateUtil.getSubscriptionState$
        .pipe(skip(1))
        .subscribe((state: SubscriptionState) => {
          if (session?.subscriptionState !== state) {
            setSession({
              ...session,
              subscriptionState: state,
            } as Session);
          }
        });
    }

    return () => {
      subscriptionStateRef.current?.unsubscribe?.();
      subscriptionStateRef.current = undefined;
    };
  }, [session, setSession]);

  const value = { session, setSession };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
