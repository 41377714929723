import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tableContainer: {
    marginBottom: 20,
  },
  cellName: {
    width: 300,
    marginRight: 'auto',
  },
  cellPeriod: {
    flex: 1,
  },
  cellTime: {
    width: 100,
    textAlign: 'center',
  },
  cellActions: {
    width: 34,
  },
  inputPeriodContainer: {
    width: 300,
    marginRight: 'auto',
  },
  inputTimeContainer: {
    width: 100,
    marginHorizontal: 10,
  },
});

export default styles;
