import { FeatureIDs } from '@oolio-group/domain';
import { useCallback } from 'react';
import { useSession } from '../useSession';

export const useCheckFeatureEnabled = () => {
  const [session] = useSession();
  const isFeatureEnabled = useCallback(
    (featureId: FeatureIDs, checkingVenueId?: string): boolean => {
      const allFeatures = session?.currentOrganization?.features;
      const checkingFeature = allFeatures?.find(
        feature => feature.featureId === featureId,
      );

      if (checkingVenueId)
        return !!(checkingFeature?.venueIds || []).includes(checkingVenueId);

      return !!checkingFeature?.enabled;
    },
    [session?.currentOrganization?.features],
  );
  return isFeatureEnabled;
};
