import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useNotification } from '../../../../../hooks/Notification';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { usePrinters } from '../../../../../hooks/app/usePrinters';
import PrintersRow from './TableRow/PrintersRow';
import { Printer, PrinterType } from '@oolio-group/domain';
import pick from 'lodash/pick';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { CopyPrinterModal } from '../../../../../components/Modals/Printers/CopyPrinterModal';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { isValidIp, isValidBDAddress } from '@oolio-group/client-utils';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import styles from './Printers.styles';
import theme from '../../../../../common/default-theme';
import Search from '../../../../../components/Shared/Search/Search';

type FormState = Record<string, Printer & { isChanged: boolean }>;

export const Printers: React.FC = () => {
  const route = useRoute();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState<FormState>({});

  const params = route.params as {
    storeId: string;
  };

  const {
    printers,
    getPrinters,
    updatePrinters,
    updatedPrinterIds,
    copyPrinter,
    copyPrinterId,
    deletePrinter,
    deletedPrinter,
    loading,
    error,
  } = usePrinters({
    storeId: params?.storeId,
  });

  // fetch data
  useEffect(() => {
    getPrinters();
  }, [getPrinters]);

  useEffect(() => {
    isFocused && getPrinters();
  }, [getPrinters, isFocused]);

  useEffect(() => {
    if (printers) {
      setForm(printers as FormState);
    }
  }, [printers]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedPrinterIds.length > 0) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printersUpdatedSuccessfully'),
      });
    }
  }, [updatedPrinterIds, showNotification, translate]);

  useEffect((): void => {
    if (copyPrinterId) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printerCopiedSuccessfully'),
      });
    }
  }, [copyPrinterId, showNotification, translate]);

  // on change events
  const onChange = useCallback(
    (id: string, prop: string, value: string): void => {
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          [prop]: value,
          isChanged: true,
        },
      }));
    },
    [],
  );

  const onCopy = useCallback(
    (printerId: string): void => {
      showModal(
        <CopyPrinterModal onCopy={copyPrinter} printerId={printerId} />,
        translate('button.copyPrinter'),
      );
    },
    [copyPrinter, showModal, translate],
  );

  const onDelete = useCallback(
    (printerId: string): void => {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: printers[printerId]?.name,
          })}
          onConfirm={() => {
            closeModal();
            deletePrinter(printerId);
          }}
        />,
      );
    },
    [showModal, deletePrinter, closeModal, translate, printers],
  );

  useEffect((): void => {
    if (deletedPrinter) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printerDeletedSuccessfully'),
      });
      // refetch;
      getPrinters();
    }
  }, [deletedPrinter, showNotification, translate, getPrinters]);

  const onSave = useCallback((): void => {
    const data = Object.values(form)
      .filter(printer => printer.isChanged)
      .map(printer =>
        pick(printer, [
          'id',
          'name',
          'printerType',
          'ipAddress',
          'bdAddress',
          'slug',
          'series',
          'brand',
        ]),
      );

    if (data.length > 0) {
      if (data.some(printer => !printer.name || !printer.printerType)) {
        showNotification({
          error: true,
          message: translate('backOfficePrinters.fieldsMissing'),
        });
      }
      if (
        data.some(
          printer =>
            ((printer.printerType === PrinterType.WIFI ||
              printer.printerType === PrinterType.LAN) &&
              !isValidIp(printer.ipAddress)) ||
            (printer.printerType === PrinterType.BLUETOOTH &&
              !isValidBDAddress(printer.bdAddress)),
        )
      ) {
        showNotification({
          error: true,
          message: translate('backOfficePrinters.addressInvalid'),
        });
      } else {
        updatePrinters(data);
      }
    }
  }, [updatePrinters, form, showNotification, translate]);

  const printersData = useMemo(() => Object.values(form), [form]);

  const onCreateNew = useCallback(() => {
    navigation.navigate('PrinterDetails', {
      printerId: 'new',
      storeId: params?.storeId,
    });
  }, [navigation, params?.storeId]);

  return (
    <ScreenLayout loading={loading} title="Printers | Oolio" onSave={onSave}>
      <Section
        title={translate('backOfficeSettings.printerDetails')}
        layoutWidth="medium"
      >
        <View style={styles.filters}>
          <Search
            testID="search-printers"
            value={searchText}
            onChangeText={setSearchText}
            placeholder="Search for printers by name to filter..."
            containerStyle={styles.search}
          />
          <CreateButton onPress={onCreateNew} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellAll]}>
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellAll]}>
              {translate('printing.printType')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellAll]}>
              {translate('backOfficeSettings.address')}
            </Text>
            <View style={styles.headerActions} />
          </View>
          {printersData.map((printer: Printer, i: number) => (
            <PrintersRow
              index={i}
              key={printer.id}
              printer={printer}
              storeId={params?.storeId}
              onChange={onChange}
              onCopy={onCopy}
              onDelete={onDelete}
            />
          ))}
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default Printers;
