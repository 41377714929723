import { Order, OrderStatus } from '@oolio-group/domain';
import { GET_ORDERS } from './graphql';
import { useQuery } from '@apollo/client/react/hooks';

export const useOnlineOrderQuery = (status: OrderStatus) => {
  const { data } = useQuery<{ orders: Order[] }>(GET_ORDERS, {
    variables: {
      filter: {
        status,
        isOnline: true,
      },
    },
    fetchPolicy: 'cache-only',
    returnPartialData: true,
  });

  return data?.orders || [];
};
