import React, { FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './RequiredOptionsModal.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import { OptionItem } from '../../../../types/Options.type';

export interface OptionGroupTabProps {
  name: string;
  subtitle?: string;
  modifiers?: string;
  isComboOptions?: boolean;
  isActive?: boolean;
  status?: 'required' | 'optional' | 'selected';
  selectedProducts: OptionItem[];
  onPress: () => void;
  onShowNotes: (id: string, note?: string) => void;
}

const OptionGroupTab: FC<OptionGroupTabProps> = ({
  name,
  subtitle,
  modifiers,
  status,
  isActive,
  isComboOptions,
  selectedProducts = [],
  onPress,
  onShowNotes,
}) => {
  const { translate } = useTranslation();
  const getFormattedComboProducts = () => {
    return (
      selectedProducts?.map(p => ({
        id: p.id,
        name: p.name,
        modifiers: (
          p.modifiers?.filter(m => m?.quantity)?.map(m => m.name) ?? []
        ).join(', '),
        note: p.notes,
      })) || []
    );
  };
  const formattedComboProducts = getFormattedComboProducts();

  const getGroupIcon = () => {
    if (!isComboOptions && isActive) {
      return <Icon name="angle-right" size={20} color={theme.colors.white} />;
    }
    switch (status) {
      case 'selected':
        return (
          <Icon
            size={20}
            name="check-circle"
            color={theme.colors.states.positive}
          />
        );
      case 'optional':
        return (
          <Icon
            size={20}
            name="check-circle"
            color={theme.colors.states.neutral}
          />
        );
      default:
        return (
          <Icon
            size={20}
            name="exclamation-triangle"
            color={theme.colors.states.negative}
          />
        );
    }
  };

  const renderComboOptions = () => {
    return (
      <>
        <View style={styles.comboOption}>
          <View style={styles.row}>
            <View style={styles.text}>
              <Text numberOfLines={2} style={styles.comboGroup}>
                {name}
              </Text>
            </View>

            <View style={styles.icon}>{getGroupIcon()}</View>
          </View>
          {formattedComboProducts.length ? (
            formattedComboProducts.map((p, i) => {
              return (
                <>
                  <TouchableOpacity
                    key={p.name + i}
                    onPress={onPress}
                    style={styles.selectedComboProductTextContainer}
                  >
                    <Text style={styles.selectedComboProductText}>
                      {p.name}
                    </Text>
                    {p.modifiers && (
                      <Text
                        style={[
                          styles.selectedComboProductText,
                          styles.selectedComboProductModifierText,
                        ]}
                      >
                        {p.modifiers}
                      </Text>
                    )}
                    {p.note && (
                      <Text
                        style={[styles.selectedComboProductText, styles.notes]}
                      >
                        {p.note}
                      </Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.noteContainer}
                    onPress={() => onShowNotes(p.id, p.note)}
                  >
                    <Text style={styles.notesLabel}>
                      {translate(p.note ? 'modal.editNote' : 'modal.addNote')}
                    </Text>
                  </TouchableOpacity>
                </>
              );
            })
          ) : (
            <TouchableOpacity
              onPress={onPress}
              style={isActive ? styles.groupActive : styles.comboOptionBorder}
            >
              <View style={styles.row}>
                <Text
                  style={[
                    styles.comboGroup,
                    { color: theme.colors.grey6 },
                    isActive && { color: theme.colors.white },
                  ]}
                >
                  {translate('modal.select')}
                </Text>
                <View style={styles.icon}>
                  <Icon
                    name="angle-right"
                    size={20}
                    color={isActive ? theme.colors.white : theme.colors.grey4}
                  />
                </View>
              </View>
            </TouchableOpacity>
          )}
        </View>
      </>
    );
  };

  return (
    <>
      {isComboOptions ? (
        renderComboOptions()
      ) : (
        <TouchableOpacity
          onPress={onPress}
          style={[styles.group, isActive && styles.groupActive]}
        >
          <View style={styles.text}>
            <Text
              numberOfLines={2}
              style={[styles.title, isActive && styles.activeText]}
            >
              {name}
            </Text>
            <Text style={[styles.subtitle, isActive && styles.activeText]}>
              {subtitle || translate('modifiers.notSelected')}
            </Text>
            {modifiers && (
              <Text numberOfLines={1} style={styles.modifiers}>
                {modifiers}
              </Text>
            )}
          </View>
          <View style={styles.icon}>{getGroupIcon()}</View>
        </TouchableOpacity>
      )}
    </>
  );
};

export default OptionGroupTab;
