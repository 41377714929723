import { useTranslation } from '@oolio-group/localization';
import { Voucher } from '@oolio-group/domain';
import React, { useCallback, useState } from 'react';
import {
  View,
  Text,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import Icon from '../../Icon/Icon';
import InputText from '../../Shared/Inputs/InputText';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import theme from '../../../common/default-theme';
import { useVouchers } from '../../../hooks/app/vouchers/useVouchers';
import BarcodeScanner, {
  Barcode,
} from '../../Shared/BarcodeScanner/BarcodeScanner';
import styles from './AddVoucher.styles';

export interface AddVoucherModalProps {
  onSubmit?: (voucher: Voucher) => void;
  onClose?: () => void | Promise<void>;
  title?: string;
  maxLength?: number;
}
export enum ScanMode {
  MANUAL = 'MANUAL',
  CAMERA = 'CAMERA',
}

const isIos = Platform.OS === 'ios';
const InitialMode = isIos ? ScanMode.CAMERA : ScanMode.MANUAL;

const AddVoucherModal: React.FC<AddVoucherModalProps> = ({
  onSubmit = () => undefined,
  onClose = () => undefined,
  maxLength = 200,
}: AddVoucherModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [formState, setFormState] = useState<string>('');
  const [scanMode, setScanMode] = useState<ScanMode>(InitialMode);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { validateVoucher: validateVoucherReq } = useVouchers();

  const onCloseModal = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  const submitVoucher = useCallback(
    (voucher: Voucher) => {
      closeModal();
      onSubmit && onSubmit(voucher);
    },
    [closeModal, onSubmit],
  );

  const validateVoucher = useCallback(
    async (id: string) => {
      if (loading) return;
      setLoading(true);
      const result = await validateVoucherReq(id);

      if (result.success) {
        setError('');
        if (result.voucherInfo) submitVoucher(result.voucherInfo);
        closeModal();
      } else {
        setError(result.message || '');
      }
      setLoading(false);
    },
    [closeModal, loading, submitVoucher, validateVoucherReq],
  );

  const onPressFindVoucher = useCallback(async () => {
    validateVoucher(formState);
  }, [formState, validateVoucher]);

  const changeScanMode = (mode: ScanMode) => {
    setScanMode(mode);
  };

  const onBarcodeDetected = useCallback(
    (barcode: Barcode) => {
      validateVoucher(barcode.data);
    },
    [validateVoucher],
  );

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={styles.keyboardAvoidingView}
    >
      {scanMode === ScanMode.MANUAL ? (
        <View style={styles.container}>
          <View style={styles.icon}>
            <Icon name="gift" color={theme.colors.states.neutral} size={34} />
          </View>
          <View>
            <Text style={styles.title}>{translate('vouchers.title')}</Text>
            <Text style={styles.subTitle}>
              {translate('vouchers.description')}
            </Text>
          </View>
          <View style={styles.inputWrapper}>
            <InputText
              testID={'input'}
              maxLength={maxLength}
              placeholder={translate('vouchers.inputPlaceholder')}
              containerStyle={styles.input}
              onChangeText={text => setFormState(text)}
              errorMessage={error}
            />
            {isIos && (
              <TouchableOpacity
                style={styles.iconBtn}
                onPress={() => {
                  changeScanMode(ScanMode.CAMERA);
                }}
              >
                <Icon
                  name="qrcode-scan"
                  color={theme.colors.states.neutral}
                  size={24}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.btnWrapper}>
            <View style={styles.btn}>
              <TreatButton
                label={translate('button.dismiss')}
                type="cancel"
                onPress={onCloseModal}
              />
            </View>
            <View style={styles.btn}>
              <TreatButton
                label={translate('vouchers.findVoucher')}
                type="neutral"
                onPress={onPressFindVoucher}
                isLoading={loading}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.cameraContainer}>
          <View style={styles.icon}>
            <Icon name="gift" color={theme.colors.states.neutral} size={34} />
          </View>
          <View>
            <Text style={styles.title}>{translate('vouchers.title')}</Text>
            <Text style={styles.subTitle}>
              {translate('vouchers.scanDescription')}
            </Text>
          </View>
          <View style={styles.camera}>
            <BarcodeScanner
              onBarcodeDetected={onBarcodeDetected}
              loading={loading}
            />
          </View>
          {!!error ? <Text style={styles.errorText}>{error}</Text> : null}
          <View style={styles.btnWrapperCameraMode}>
            <View style={styles.btn}>
              <TreatButton
                label={translate('button.dismiss')}
                type="cancel"
                onPress={onCloseModal}
              />
            </View>
            <View style={styles.btn}>
              <TreatButton
                label={translate('vouchers.manual')}
                type="neutralLight"
                onPress={() => {
                  changeScanMode(ScanMode.MANUAL);
                }}
              />
            </View>
          </View>
        </View>
      )}
    </KeyboardAvoidingView>
  );
};

export default AddVoucherModal;
