import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  headerXeroAccount: {
    width: 115,
    marginRight: 32,
  },
  headerShiftNo: {
    width: 136,
  },
  headerDate: {
    width: 195,
  },
  headerDevice: {
    width: 370,
  },
  search: {
    flex: 1,
  },
  cellShiftNo: {
    width: 80,
  },
  cellDate: {
    width: 120,
    marginLeft: 10,
  },
  cellDevice: {
    width: 143,
  },
  message: {
    marginBottom: 20,
    width: '100%',
  },
  row: {
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  failureMessageView: {
    marginLeft: 10,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  failureMessage: {
    color: theme.colors.states.negative,
    marginLeft: 5,
  },
  cellXeroAccount: {
    width: 115,
    marginRight: 10,
    textAlign: 'right',
  },
  btn: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
  datePicker: {
    width: 180,
    flexDirection: 'row',
    paddingHorizontal: 12,
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    marginRight: 10,
  },
  dateLabel: {
    flex: 1,
  },
});

export default styles;
