import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.teritiary1,
    height: 68,
  },
  lhs: {
    flex: 1,
    flexDirection: 'row',
  },
  rhs: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  center: {
    flex: 1,
    maxWidth: 370,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.teritiary2,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    paddingVertical: 12,
    textAlign: 'center',
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  btnMenu: { marginLeft: 6 },
});

export default styles;
