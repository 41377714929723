import { gql } from '@apollo/client';

export const GET_TAXES_DETAILS = gql`
  query getTaxDetails {
    taxes {
      id
      name
    }
  }
`;
