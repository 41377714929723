import React from 'react';
import { Text, View } from 'react-native';
import { AssignedProduct } from './CourseDetail';
import styles from '../Courses.styles';
import theme from '../../../../../common/default-theme';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import Identifier from '../../../../../components/Shared/Identifier/Identifier';

export interface SelectedItem {
  id: string;
  name: string;
  type: string;
  priority: number;
  entityType?: string;
}
interface ProductRowProps {
  rowIndex: number;
  item: AssignedProduct;
  onDelete: () => void;
}

const ProductRow: React.FC<ProductRowProps> = ({
  item,
  onDelete,
  rowIndex,
}) => {
  return (
    <View style={theme.tables.row}>
      <Identifier entity="product" containerStyles={styles.identifier} />
      <Text
        style={styles.cellProduct}
        testID={`course-product-name-${rowIndex}`}
      >
        {item.name}
      </Text>
      <ButtonIcon
        testID="btn-removeItem"
        size={34}
        icon="times"
        type="negativeLight"
        onPress={onDelete}
      />
    </View>
  );
};

export default ProductRow;
