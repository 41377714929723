import React from 'react';
import { Table, TableShape, TableStatus } from '@oolio-group/domain';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './TableIcon.styles';
import theme from '../../../../../../../common/default-theme';

export interface TableIconProps {
  table: Table;
  blur?: boolean;
  highlight?: boolean;
  isMultiOrder?: boolean;
  disabled?: boolean;
  onPressTable: (table: Table) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reference?: React.MutableRefObject<any>;
}

const SEAT_SIZE = 34;

export const TableIcon: React.FC<TableIconProps> = ({
  table,
  blur,
  highlight,
  isMultiOrder = false,
  disabled = false,
  onPressTable,
  reference,
}) => {
  const status = isMultiOrder ? TableStatus.MULTI_USE : table.status;

  return (
    <>
      <TouchableOpacity
        testID="table"
        disabled={disabled}
        onPress={() => onPressTable(table)}
        ref={reference}
        style={[
          styles.getContainerStyle(status),
          // eslint-disable-next-line react-native/no-inline-styles
          {
            opacity: blur ? 0.3 : 1,
            height: table.verticalSeats * SEAT_SIZE,
            width: table.horizontalSeats * SEAT_SIZE,
            borderRadius: table.shape === TableShape.ROUND ? 100 : 4,
          },
          highlight && { ...theme.shadow.light },
        ]}
      >
        <Text style={styles.getLabelStyle(status)}>{table.name}</Text>
      </TouchableOpacity>
      {isMultiOrder ? (
        <View style={styles.styles.dots}>
          {Array.from({ length: 3 }).map((_, i) => (
            <View key={i} style={styles.styles.dot} />
          ))}
        </View>
      ) : null}
    </>
  );
};
