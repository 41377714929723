import React, { FC, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from '../../Icon/Icon';
import { useTranslation } from '@oolio-group/localization';
import theme, { DEFAULT_PAGE_SIZE } from '../../../common/default-theme';
import styles from './Pagination.styles';

export interface PaginationProps {
  page: number;
  dataLength: number;
  pageLength?: number;
  entityName: string;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  page,
  entityName,
  onPageChange,
  dataLength,
  pageLength,
}) => {
  const { translate } = useTranslation();
  const pages = Math.ceil(dataLength / 10);

  const fetchNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [onPageChange, page]);

  const handlePressBack = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  function getPaginationText() {
    const start = page === 1 ? 1 : (page - 1) * 10 + 1;
    return `${start}-${start + (pageLength || DEFAULT_PAGE_SIZE) - 1}`;
  }

  if (dataLength <= (pageLength || DEFAULT_PAGE_SIZE)) {
    return <></>;
  }

  return (
    <View style={styles.footerContainer}>
      <Text style={styles.footerText}>
        {translate('common.pagination', {
          pageLength: getPaginationText(),
          dataLength: dataLength,
          entity: entityName.toLowerCase(),
        })}
      </Text>
      <View style={styles.paginationContainer}>
        <TouchableOpacity
          disabled={page === 1}
          onPress={handlePressBack}
          style={styles.btnPagination}
        >
          <Icon
            size={20}
            name="arrow-left"
            color={page === 1 ? theme.colors.grey4 : theme.colors.black}
          />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={page >= pages}
          style={styles.btnPagination}
          onPress={fetchNextPage}
        >
          <Icon
            size={20}
            name="arrow-right"
            color={page >= pages ? theme.colors.grey4 : theme.colors.black}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Pagination;
