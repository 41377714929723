import React, { useCallback } from 'react';
import { View } from 'react-native';
import { TerminalDiagnosisInfo } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import styles from './TerminalDiagnosis.styles';
import {
  TerminalDiagnosisRow,
  TerminalDiagnosisRowProps,
} from './TerminalDiagnosisRow';
import ModalTitle from '../ModalTitle/ModalTitle';

export type TerminalDiagnosisProps = {
  diagnosisData?: TerminalDiagnosisInfo;
};

const TerminalDiagnosis: React.FC<TerminalDiagnosisProps> = ({
  diagnosisData,
}: TerminalDiagnosisProps) => {
  const { translate } = useTranslation();

  const getDataToDisplay = useCallback(() => {
    if (diagnosisData) {
      return (Object.keys(diagnosisData) as [keyof TerminalDiagnosisInfo])
        .filter(label => {
          if (
            diagnosisData[label as keyof TerminalDiagnosisInfo] === null ||
            diagnosisData[label as keyof TerminalDiagnosisInfo] === undefined
          ) {
            return false;
          }
          return true;
        })
        .map(label => (
          <TerminalDiagnosisRow
            label={label}
            key={`diagnosis-info-row-${label}`}
            value={diagnosisData[label] as TerminalDiagnosisRowProps['value']}
          />
        ));
    }

    return [];
  }, [diagnosisData]);

  return (
    <View style={styles.container}>
      <ModalTitle title={translate('paymentTerminal.diagnosis.header')} />
      <View style={styles.content}>{getDataToDisplay()}</View>
    </View>
  );
};

export default TerminalDiagnosis;
