import { useTranslation } from '@oolio-group/localization';
import {
  DeputySettingsInput,
  StyleFn,
  Device,
  POSTerminalDeputyAreaMapInput,
} from '@oolio-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text, ScrollView } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import Button from '../../../../../../../../components/Button/Button';
import PopupView from '../../../../../../../../components/PopupView/PopupView';
import DropDown from '../../../../../../../../components/FormInput/DropDown';
import { useWorkforceIntegration } from '../../../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import { useDevices } from '../../../../../../../../hooks/app/useDevices';
import LoadingIndicator from '../../../../../../../../components/LoadingIndicator/LoadingIndicator';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

export interface DeputyModalProps {
  locationId: number;
  storeId: string;
  storeSettings: Partial<DeputySettingsInput>;
  isSyncOrder?: boolean;
}

const containerStyle: StyleFn = ({ theme }) => ({
  width: 450,
  alignSelf: 'center',
  justifyContent: 'center',
  marginTop: 40,
  borderRadius: theme.radius.small,
  flex: 1,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.skyBlue,
  color: theme.colors.blue,
});

const buttonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  alignItems: 'center',
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const actionButtonContainer: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
  height: 'auto',
  marginTop: 30,
  marginBottom: 30,
  justifyContent: 'space-between',
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  paddingBottom: theme.padding.medium / 2,
  borderBottomColor: theme.colors.boxBorder,
  justifyContent: 'space-between',
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  justifyContent: 'space-between',
  paddingLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  paddingRight: 20,
  marginLeft: 10,
  marginTop: theme.spacing.medium,
});

const headerText: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
});

const footerText: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  marginTop: 15,
  alignSelf: 'center',
});

export const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: '100%',
});

export const dropdownLabelStyle: StyleFn = () => ({
  width: 400,
});

export const defaulLabelStyle: StyleFn = () => ({
  width: '100%',
  marginTop: 20,
});

export const labelStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  marginBottom: 10,
  color: theme.colors.paragraph,
  paddingLeft: theme.spacing.medium,
});

export const defaultContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  marginRight: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  width: 'auto',
});

export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  paddingRight: theme.spacing.medium,
  marginTop: 10,
  width: 120,
  ...theme.font14RegularCharcoal,
});

export const headerStyle: StyleFn = ({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
  width: '100%',
  height: 50,
  backgroundColor: theme.colors.greyLight,
});

export const footerStyle: StyleFn = ({ theme }) => ({
  marginTop: 10,
  width: '100%',
  height: 50,
  justifyContent: 'space-between',
  backgroundColor: theme.colors.greyLight,
  alignItems: 'center',
});

const DeputyModal: React.FC<DeputyModalProps> = ({
  locationId,
  storeId,
  isSyncOrder,
  storeSettings: storeSettingsProp,
}) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const {
    loading,
    deputyAreas,
    getAllDeputyAreas,
    integrationPartnerStores,
    getIntegrationPartnerStoreConfig,
    updateStoreWithDeputySettings,
    employeeSyncRequestSent,
    syncEmployees,
  } = useWorkforceIntegration();
  const {
    devices,
    getDevices,
    loading: loadingDevices,
  } = useDevices({
    storeId,
  });

  const [posTerminalAndAreaMaps, setPosTerminalAndAreaMaps] = useState<
    Record<string, POSTerminalDeputyAreaMapInput>
  >({});

  useEffect(() => {
    if (storeId && isSyncOrder) {
      getDevices();
      getIntegrationPartnerStoreConfig(storeId);
    }
  }, [getDevices, getIntegrationPartnerStoreConfig, isSyncOrder, storeId]);

  const onPress = useCallback(() => {
    syncEmployees(storeId);
    closeModal();
  }, [syncEmployees, storeId, closeModal]);

  const onPressDismiss = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onPressSave = useCallback(() => {
    updateStoreWithDeputySettings(
      {
        ...storeSettingsProp,
        posTerminalAreaMappings: Object.values(posTerminalAndAreaMaps).filter(
          item => item,
        ),
      },
      storeId,
    );
  }, [
    posTerminalAndAreaMaps,
    storeId,
    storeSettingsProp,
    updateStoreWithDeputySettings,
  ]);

  const deputyAreasMaps = useMemo(
    () => keyBy(deputyAreas, 'id'),
    [deputyAreas],
  );

  const onChangeArea = useCallback(
    (posTerminalId: string, areaId: string) => {
      const data = (
        deputyAreasMaps[areaId]
          ? {
              posTerminalId,
              posTerminalName: devices[posTerminalId]?.name,
              areaId: Number(areaId),
              areaName: deputyAreasMaps[areaId]?.areaName,
            }
          : null
      ) as POSTerminalDeputyAreaMapInput;
      setPosTerminalAndAreaMaps(pre => ({
        ...pre,
        [posTerminalId]: data,
      }));
    },
    [deputyAreasMaps, devices],
  );

  useEffect(() => {
    if (isSyncOrder) getAllDeputyAreas(locationId);
  }, [getAllDeputyAreas, isSyncOrder, locationId]);

  useEffect(
    () =>
      setPosTerminalAndAreaMaps(
        keyBy(
          integrationPartnerStores?.posTerminalAreaMappings?.map(item =>
            omit(item, '__typename'),
          ),
          'posTerminalId',
        ),
      ),
    [integrationPartnerStores?.posTerminalAreaMappings],
  );

  if (loading || loadingDevices) return <LoadingIndicator />;

  return (
    <ScrollView scrollEnabled scrollsToTop>
      <View style={css(containerStyle)}>
        <PopupView containerStyle={css(popupStyle)}>
          {isSyncOrder && (
            <>
              <View style={css(headerStyle)}>
                <View style={css(headerTextStyle)}>
                  <Text style={css(headerText)}>
                    {translate('settingSection.deputy.modal.posTerminal')}
                  </Text>
                  <Text style={css(headerText)}>
                    {translate('settingSection.deputy.modal.area')}
                  </Text>
                </View>
              </View>
              {Object.values(devices)?.map(({ id: deviceId, name }: Device) => {
                return (
                  <View key={deviceId} style={css(rowStyle)}>
                    <View style={css(defaultContainerStyle)}>
                      <Text style={css(defaultTextStyle)}>{name}</Text>
                    </View>
                    <DropDown
                      values={[
                        {
                          value: '',
                          label: translate('settingSection.deputy.selectArea'),
                        },
                        ...deputyAreas.map(area => {
                          return {
                            value: area.id,
                            label: area.areaName,
                          };
                        }),
                      ]}
                      selectedValue={String(
                        posTerminalAndAreaMaps[deviceId]?.areaId || '',
                      )}
                      onValueChange={onChangeArea.bind(null, deviceId)}
                      testID={'change-value'}
                      extraStyle={css(dropdownExtraStyle)}
                    />
                  </View>
                );
              })}
            </>
          )}

          <View style={css(footerStyle)}>
            <Text style={css(footerText)}>
              {employeeSyncRequestSent &&
                translate('settingSection.deputy.syncEmployeeSuccess')}
            </Text>
          </View>
          <View style={css(actionButtonContainer)}>
            <Button
              title={translate('settingSection.deputy.modal.button.dismiss')}
              size="small"
              onPress={onPressDismiss}
              testID={'close-modal'}
            />

            <Button
              testID={'run-deputy'}
              title={translate('settingSection.deputy.modal.button.runNow')}
              containerStyle={css(buttonContainerStyle)}
              labelStyle={css(buttonLabelStyle)}
              size="small"
              onPress={onPress}
              loading={loading}
            />
            <Button
              testID={'save-deputy'}
              title={translate('settingSection.deputy.modal.button.save')}
              success
              size="small"
              onPress={onPressSave}
              disabled={!isSyncOrder}
            />
          </View>
        </PopupView>
      </View>
    </ScrollView>
  );
};

export default DeputyModal;
