import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useTranslation } from '@oolio-group/localization';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import { View } from 'react-native';
import styles from './MenuSchedules.styles';
import { ManageScheduleHeaderFilters } from './ManageScheduleHeaderFilters';
import { useAvailabilityOptions } from '../../../../../hooks/app/useAvailabilityOptions';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import { useIsFocused } from '@react-navigation/native';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import { useNavigation } from '@react-navigation/native';
import MenuScheduleList from './MenuScheduleList';
import { useMenuSchedules } from '../../../../../hooks/app/menuSchedules/useMenuSchedules';
import { CatalogueScheduleItemInput } from '@oolio-group/domain';
import {
  isMenuScheduleCollisionWithPrevious,
  mapMenuScheduleToMenuScheduleModel,
} from '@oolio-group/client-utils';
import ClashedMenuScheduleModal from './ClashedMenuScheduleModal';
import { useModal } from '@oolio-group/rn-use-modal';
import { noopHandler } from '../../../../../utils/errorHandlers';

export interface FilterValue {
  menu: string;
  store: string;
}

const defaultFilter: FilterValue = {
  menu: 'all',
  store: 'all',
};

export const MenuSchedules = () => {
  const { translate } = useTranslation();
  const isFocused = useIsFocused();
  const [filter, setFilter] = useState<FilterValue>(defaultFilter);
  const { loading: optionsLoading, stores } = useAvailabilityOptions();
  const [menusActive, setMenusActive] =
    useState<Record<string, { active: boolean }>>();
  const clashesMenu = useRef<string[]>();
  const currentActiveMenuId = useRef<string>();

  const { loading: menusLoading, getMenus, menus: menuMaps } = useMenus();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const {
    menuSchedules,
    getMenuSchedules,
    updateMenuSchedule,
    loading: menuScheduleLoading,
  } = useMenuSchedules();

  useEffect(() => {
    isFocused && getMenus();
  }, [getMenus, isFocused]);

  useEffect(() => {
    isFocused && getMenuSchedules();
  }, [getMenuSchedules, isFocused]);

  useEffect(() => {
    !isFocused && setMenusActive({});
  }, [isFocused]);

  const loading = menusLoading || optionsLoading || menuScheduleLoading;

  const menuOptions = useMemo(() => {
    return Object.values(menuMaps).map(menu => ({
      value: menu.id,
      label: menu.name,
    }));
  }, [menuMaps]);

  const onCreateMenuSchedule = useCallback(() => {
    navigation.navigate('ScheduleDetail', {
      title: translate('menus.menuSchedules.createSchedule'),
    });
  }, [navigation, translate]);

  const handleConfirmWarningModal = useCallback(() => {
    if (currentActiveMenuId.current) {
      setMenusActive(curActive => {
        const actives = { ...curActive };
        clashesMenu.current?.forEach(id => {
          actives[id] = {
            active: false,
          };
        });
        actives[currentActiveMenuId.current || ''] = { active: true };
        return actives;
      });
    }
    closeModal();
  }, [closeModal]);

  const onShowConflictModal = useCallback(() => {
    const clashesMenuName = (clashesMenu.current || [])
      .map(id => {
        const menuId = menuSchedules?.[id].menu;
        return menuMaps?.[menuId].name;
      })
      .join(', ');
    showModal(
      <ClashedMenuScheduleModal
        clashesMenuName={clashesMenuName}
        onConfirm={handleConfirmWarningModal}
        operationName="Active"
      />,
      {
        onBackButtonPress: closeModal,
        onModalHide: noopHandler,
      },
    );
  }, [
    showModal,
    handleConfirmWarningModal,
    closeModal,
    menuSchedules,
    menuMaps,
  ]);

  const handleSaveActiveMenuSchedule = useCallback(() => {
    const changedMenuSchedule = Object.values(menuSchedules).filter(
      menuSchedule =>
        menuSchedule.isActive !== menusActive?.[menuSchedule.id]?.active,
    );
    changedMenuSchedule.forEach(menuSchedule => {
      updateMenuSchedule({
        id: menuSchedule.id,
        isActive: menusActive?.[menuSchedule.id]?.active,
      } as CatalogueScheduleItemInput);
    });
  }, [menuSchedules, menusActive, updateMenuSchedule]);

  const onSetMenuActives = useCallback(
    (id: string) => {
      const currentActive = menusActive?.[id]?.active;
      if (!currentActive) {
        const isMenuScheduleCollideWithPrevious =
          isMenuScheduleCollisionWithPrevious(
            mapMenuScheduleToMenuScheduleModel(menuSchedules?.[id]),
            Object.values(menuSchedules)
              .filter(menuSchedule => menuSchedule.id !== id)
              .map(menuSchedule => ({
                ...menuSchedule,
                isActive: menusActive?.[menuSchedule.id]?.active,
              })),
          );
        if (isMenuScheduleCollideWithPrevious.isClashes) {
          clashesMenu.current = isMenuScheduleCollideWithPrevious.clashesMenus;
          currentActiveMenuId.current = id;
          onShowConflictModal();
          return;
        }
      }
      setMenusActive(curActive => {
        const actives = { ...curActive };
        actives[id] = {
          active: !currentActive,
        };
        return actives;
      });
    },
    [menuSchedules, menusActive, onShowConflictModal],
  );

  useEffect(() => {
    setMenusActive(curActives => {
      const actives = { ...curActives };
      Object.values(menuSchedules).forEach(item => {
        actives[item.id] = {
          active:
            actives?.[item.id]?.active !== undefined
              ? actives?.[item.id]?.active
              : item.isActive !== undefined
              ? item.isActive
              : false,
        };
      });
      return actives;
    });
  }, [menuSchedules]);

  return (
    <ScreenLayout
      loading={loading}
      title="Menus | Oolio"
      onSave={handleSaveActiveMenuSchedule}
    >
      <Section
        title={translate('menus.menuSchedules.heading')}
        subtitle={translate('menus.menuSchedules.description')}
        layoutWidth="medium"
      >
        <View style={styles.filtersContainer}>
          <ManageScheduleHeaderFilters
            menus={menuOptions}
            storeOptions={stores}
            filter={filter}
            onChangeFilter={setFilter}
          />
          <CreateButton onPress={onCreateMenuSchedule} />
        </View>
        <MenuScheduleList
          menuSchedules={menuSchedules}
          onUpdateActiveMenu={onSetMenuActives}
          actives={menusActive}
          filter={filter}
          menus={menuMaps}
        />
      </Section>
    </ScreenLayout>
  );
};
