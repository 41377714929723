import * as React from 'react';
import {
  StyleProp,
  TouchableWithoutFeedback,
  View,
  Text,
  ViewStyle,
} from 'react-native';
import { StyleFn } from '@oolio-group/domain';
import { useFela, withTheme } from 'react-fela';
export type DataTableHeaderCellProps = {
  children: React.ReactNode;
  alignRight?: boolean;
  option?: boolean;
  small?: boolean;
  center?: boolean;
  clickable?: boolean;
  sortDirection?: 'ascending' | 'descending';
  numberOfLines?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  textAlignmentStyle?: StyleProp<ViewStyle>;
};

const smallContainer: StyleFn = () => ({
  flex: 2,
});

const optionsContainer: StyleFn = () => ({
  flex: 1,
  alignItems: 'center',
});

const openRowButtonContainer: StyleFn = () => ({
  flex: 0.2,
  justifyContent: 'flex-end',
});

const container: StyleFn = ({ theme }) => ({
  flex: 3.6,
  flexDirection: 'row',
  alignContent: 'center',
  paddingVertical: theme.padding.small,
  alignItems: 'center',
});

const right: StyleFn = () => ({
  justifyContent: 'flex-end',
});

const cell: StyleFn = ({ theme }) => ({
  height: 24,
  lineHeight: 24,
  fontSize: theme.fontSize.smaller,
  fontWeight: '500',
  alignItems: 'center',
  width: '100%',
  textAlign: 'center',
});

const sorted: StyleFn = () => ({
  marginLeft: 8,
});

const centerAlign: StyleFn = () => ({
  textAlign: 'center',
});

const DataTableHeaderCell: React.FC<DataTableHeaderCellProps> = props => {
  const { css } = useFela();

  const {
    alignRight,
    textAlignmentStyle,
    children,
    onPress,
    sortDirection,
    style,
    numberOfLines = 1,
    option,
    small,
    center,
    clickable,
    ...rest
  } = props;
  const textColor = 'black';

  return (
    <TouchableWithoutFeedback disabled={!onPress} onPress={onPress} {...rest}>
      <View
        style={[
          css(container),
          alignRight && css(right),
          small && css(smallContainer),
          option && css(optionsContainer),
          style,
          option && center && css(centerAlign),
          clickable && css(openRowButtonContainer),
        ]}
      >
        {children && (
          <Text
            style={[
              css(cell),
              option && center ? css(centerAlign) : textAlignmentStyle,
              sortDirection ? css(sorted) : { color: textColor },
            ]}
            numberOfLines={numberOfLines}
          >
            {children}
          </Text>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default withTheme(DataTableHeaderCell);

export { DataTableHeaderCell };
