/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { MoneyMovementReason, MoneyEventType } from '@oolio-group/domain';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import { useMoneyMovements } from '../../../../../hooks/app/useMoneyMovements';
import { useModal } from '@oolio-group/rn-use-modal';
import { pick } from 'lodash';
import { capitalCase } from 'change-case';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { CreateMoneyMovementReason } from './CreateReason/CreateMoneyMovementReason';
import theme from '../../../../../common/default-theme';
import styles from '../VenueSettings.styles';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface ManageCashRowProps {
  index: number;
  moneyMovementReason: MoneyMovementReason;
  onChange: (id: string, prop: string, value: string) => void;
  onDeleteReason: (id: string) => void;
}

const ManageCashRow: React.FC<ManageCashRowProps> = ({
  index,
  moneyMovementReason,
  onChange,
  onDeleteReason,
}: ManageCashRowProps) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: moneyMovementReason.name,
        })}
        onConfirm={() => {
          closeModal();
          onDeleteReason(moneyMovementReason.id);
        }}
      />,
    );
  }, [showModal, onDeleteReason, closeModal, translate, moneyMovementReason]);

  const eventTypes = [MoneyEventType.MONEY_IN, MoneyEventType.MONEY_OUT];

  const eventTypesData = eventTypes.map(event => ({
    label: capitalCase(event).replace('_', ' '),
    value: event,
  }));

  return (
    <View key={index} testID="row-event" style={theme.tables.row}>
      <InputText
        testID="input-name"
        value={moneyMovementReason.name}
        onChangeText={onChange.bind(null, moneyMovementReason.id, 'name')}
        placeholder="Name"
        containerStyle={styles.cellName}
      />
      <TreatPicker
        testID="select-type"
        options={eventTypesData}
        selectedValue={moneyMovementReason.eventType}
        onValueChange={onChange.bind(null, moneyMovementReason.id, 'eventType')}
        containerStyle={styles.cellType}
      />
      <InputText
        testID="input-notes"
        value={moneyMovementReason.notes}
        onChangeText={onChange.bind(null, moneyMovementReason.id, 'notes')}
        placeholder="Add note..."
        containerStyle={styles.cellNote}
      />
      <ButtonIcon
        type="negativeLight"
        icon="trash-alt"
        onPress={onPressDelete}
      />
    </View>
  );
};

type FormState = Record<string, MoneyMovementReason & { isChanged: boolean }>;

export const ManageCashScreen: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormState>({});
  const { showModal } = useModal();

  const route = useRoute();

  const params = route.params as {
    venueId: string;
  };

  const venueId = params.venueId || '';

  const {
    loading,
    error,
    moneyMovementReasons,
    getMoneyMovementReasons,
    createMoneyMovementReason,
    createdMoneyMovementReasonId,
    updateMoneyMovementReasons,
    updatedMoneyMovementReasonIds,
    deleteMoneyMovementReason,
    deletedMoneyMovementReason,
  } = useMoneyMovements({ venueId });

  useEffect(() => {
    getMoneyMovementReasons();
  }, [getMoneyMovementReasons]);

  useEffect(() => {
    if (moneyMovementReasons) {
      setForm(moneyMovementReasons as FormState);
    }
  }, [moneyMovementReasons]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (createdMoneyMovementReasonId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDevices.moneyMovementReasonCreatedSuccessfully',
        ),
      });
    }
  }, [createdMoneyMovementReasonId, showNotification, translate]);

  useEffect((): void => {
    if (updatedMoneyMovementReasonIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDevices.moneyMovementReasonUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedMoneyMovementReasonIds, showNotification, translate]);

  const onChange = useCallback(
    (id: string, prop: string, value: string): void => {
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          [prop]: value,
          isChanged: true,
        },
      }));
    },
    [],
  );

  const onDeleteReason = useCallback(
    (id: string): void => {
      deleteMoneyMovementReason(id);
    },
    [deleteMoneyMovementReason],
  );

  useEffect((): void => {
    if (deletedMoneyMovementReason) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDevices.moneyMovementReasonDeletedSuccessfully',
        ),
      });
      // refetch;
      getMoneyMovementReasons();
    }
  }, [
    deletedMoneyMovementReason,
    showNotification,
    translate,
    getMoneyMovementReasons,
  ]);

  const onCreateNew = useCallback((): void => {
    showModal(
      <CreateMoneyMovementReason onCreate={createMoneyMovementReason} />,
      translate('backOfficeSettings.createReason'),
    );
  }, [createMoneyMovementReason, showModal, translate]);

  const onPressSave = useCallback((): void => {
    const data = Object.values(form)
      .filter(reason => reason.isChanged)
      .map(reason => pick(reason, ['id', 'name', 'eventType', 'notes']));

    if (data.length > 0) {
      if (data.some(reason => !reason.name || !reason.eventType)) {
        showNotification({
          error: true,
          message: translate('backOfficeDevices.fieldsMissing'),
        });
      } else {
        updateMoneyMovementReasons(data);
      }
    }
  }, [updateMoneyMovementReasons, form, showNotification, translate]);

  const manageCashData = Object.values(form);

  return (
    <ScreenLayout
      loading={loading}
      title="Cash Events | Oolio"
      onSave={onPressSave}
    >
      <Section title="Cash Movement Events" layoutWidth="medium">
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, { width: 250 }]}>
              {translate('backOfficeSettings.manageCash.reasonName')}
            </Text>
            <Text style={[theme.tables.headerText, { width: 212 }]}>
              {translate('backOfficeSettings.manageCash.event')}
            </Text>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeSettings.manageCash.notes')}
            </Text>
          </View>
          <View>
            {manageCashData.map((event: MoneyMovementReason, i: number) => (
              <ManageCashRow
                key={i}
                index={i}
                onChange={onChange}
                onDeleteReason={onDeleteReason}
                moneyMovementReason={event}
              />
            ))}
          </View>
        </View>
        <CreateButton onPress={onCreateNew} />
      </Section>
    </ScreenLayout>
  );
};
