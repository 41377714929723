import { StyleSheet } from 'react-native';
import theme from '../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    backgroundColor: theme.colors.white,
    borderBottomColor: theme.colors.grey3,
  },
  backBtnContainer: {
    width: 48,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  menuTabText: {
    lineHeight: 20,
    paddingVertical: 6,
    paddingHorizontal: 10,
    color: theme.colors.grey6,
    borderRadius: theme.radius.s,
  },
  menuTabActive: {
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
    backgroundColor: theme.colors.grey1,
  },
  spacer: {
    width: 48,
    height: 44,
  },
});

export default styles;
