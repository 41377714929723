const EXTRACT_VALUE_PATTERN = /\d+(\.\d+)?/;

export const replaceFunctionMapCurrency = (
  functionMapLabel: string,
  formatFunction: Function,
): string => {
  const valueResult = EXTRACT_VALUE_PATTERN.exec(
    functionMapLabel,
  ) as RegExpExecArray;
  const splitedStrings = functionMapLabel?.split(EXTRACT_VALUE_PATTERN);
  const textToShow = splitedStrings?.[splitedStrings?.length - 1] || '';
  return `${formatFunction(valueResult?.[0] || '0')}${textToShow}`;
};
