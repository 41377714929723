import React from 'react';
import { View } from 'react-native';
import { Option } from '@oolio-group/domain';
import Select from '../../../../../../components/Shared/Select/Select';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import theme from '../../../../../../common/default-theme';
import styles from '../Variations.styles';

interface VariationGroupRowProps {
  variationGroups: { value: string; label: string }[];
  selectedOption: Option;
  allValues: string[];
  index: number;
  onValuesChange?: (id: string, values: string[]) => void;
  onAddOption?: (name: string, selectedOptionId: string) => void;
  onAddOptionValue?: (id: string, name: string) => void;
  onDeleteOptionRow?: (id: string) => void;
  onChangeSelectedOption?: (id: string, values: string[]) => void;
  isDisabled: boolean;
  deleteRequestModal?: (
    func: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
  addRequestModal?: (
    func: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
}

export const VariationGroupRow: React.FC<VariationGroupRowProps> = ({
  variationGroups,
  selectedOption,
  allValues,
  index,
  onValuesChange,
  onAddOption,
  onAddOptionValue,
  onDeleteOptionRow,
  onChangeSelectedOption,
  isDisabled,
  deleteRequestModal,
  addRequestModal,
}: VariationGroupRowProps) => {
  return (
    <View testID="row-group" key={index} style={theme.tables.row}>
      <Select
        testID="select-group"
        options={variationGroups}
        selectedValue={selectedOption.key}
        placeholder="Select group..."
        onAddOption={name =>
          onAddOption && onAddOption(name, selectedOption.id)
        }
        onValueChange={value =>
          onChangeSelectedOption &&
          onChangeSelectedOption(selectedOption.id, [value])
        }
        editable={!isDisabled}
        containerStyle={styles.cellGroup}
        hideSearch={false}
      />
      <SelectMultiple
        testID="select-variations"
        selectedValues={selectedOption.values}
        options={(allValues || []).map(x => ({
          value: x,
          label: x,
        }))}
        onAddItem={value =>
          onAddOptionValue && onAddOptionValue(selectedOption.id, value)
        }
        onValueChange={values =>
          onValuesChange && onValuesChange(selectedOption.id, values)
        }
        disabled={isDisabled}
        deleteRequestModal={deleteRequestModal}
        addRequestModal={addRequestModal}
        containerStyle={styles.cellVariations}
      />
      {onDeleteOptionRow && (
        <ButtonIcon
          testID="btn-deleteVariationGroup"
          icon="trash-alt"
          type="negativeLight"
          onPress={() => onDeleteOptionRow(selectedOption.id)}
        />
      )}
    </View>
  );
};
