import { RxJsonSchema } from 'rxdb/dist/types/types/rx-schema';
import { OrderEventModel } from '../models/orderEvent';

export const OrderEventsSchema: RxJsonSchema<OrderEventModel> = {
  version: 0,
  title: 'Order Events',
  description: 'Stores order events',
  type: 'object',
  primaryKey: 'eventID',
  properties: {
    eventID: {
      type: 'string',
    },
    orderID: {
      type: 'string',
    },
    previous: {
      type: 'string',
    },
    timestamp: {
      type: 'number',
    },
    payload: {
      type: 'object',
    },
  },
};
