import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    gap: 4,
    paddingTop: 16,
    paddingBottom: 18,
    paddingHorizontal: 20,
    ...theme.border.solid,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
    ...theme.shadow.elevated1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  headerTitle: {
    fontSize: 14,
    marginRight: 8,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.regular,
  },
  headerSubtitle: {
    flex: 1,
    fontSize: 12,
    lineHeight: 20,
    textAlign: 'right',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
  },
  stats: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 20,
  },
  stat: {
    gap: 4,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.regular,
    color: theme.colors.grey6,
  },
  value: {
    fontSize: 22,
    lineHeight: 24,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.black1,
  },
  change: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: theme.fonts.medium,
  },
  positive: {
    borderColor: theme.colors.states.positive,
    backgroundColor: theme.colors.states.positiveXL,
  },
  negative: {
    borderColor: theme.colors.states.negative,
    backgroundColor: theme.colors.states.negativeXL,
  },
});

export default styles;
