import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  dropdown: {
    flex: 1,
    marginRight: 10,
  },
});

export default styles;
