import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  images: {
    overflow: 'scroll',
    flexDirection: 'row',
  },
  image: {
    width: 320,
    height: 240,
    marginRight: 10,
    borderRadius: theme.radius.s,
  },
  pagination: {
    margin: 10,
    width: '96%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '50%',
    transform: [{ translateY: -24 }],
  },
  btnPagination: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.black,
  },
});

export default styles;
