import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import styles from './CartActions.styles';
import Icon from '../../../Icon/Icon';
import theme from '../../../../common/default-theme';

export interface CartAction {
  icon: string;
  iconColor: string;
  testID: string;
  disabled?: boolean;
  action: () => void;
}

interface CartActionProps {
  actions: CartAction[];
}

const CartActions: React.FC<CartActionProps> = ({ actions }) => {
  return (
    <View testID="cart-actions" style={styles.container}>
      {actions.map((action, i) => (
        <TouchableOpacity
          key={i}
          testID={action.testID}
          disabled={action.disabled}
          onPress={action.action}
          style={styles.button}
        >
          <Icon
            name={action.icon}
            color={action.disabled ? theme.colors.grey4 : action.iconColor}
            size={action.icon === 'align-center-h' ? 26 : 22}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default CartActions;
