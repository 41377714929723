import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity, Platform, Linking } from 'react-native';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { camelCase } from 'change-case';
import { Link, LinkGroupProps } from '../Navigation.types';
import styles from './LinkGroup.styles';
import theme from '../../../../common/default-theme';
import { Icon } from '../../../Icon/Icon';
import { WithFeature } from '../../../features/WithFeature';
import { analyticsService } from '../../../../analytics/AnalyticsService';

export const LinkGroup: React.FC<LinkGroupProps> = ({
  id,
  icon,
  title,
  links,
}) => {
  const navigation = useNavigation();
  const route = useNavigationState(
    state => state.routes[state.routes.length - 1],
  );

  const [showChildren, setShowChildren] = useState(true);

  const handleOnPress = useCallback(
    (link: Link) => {
      analyticsService.capture('navigation', {
        event: 'Pressed Link',
        link: title,
      });

      if (link.isExternal) {
        if (Platform.OS === 'web') {
          window.open(link.route, '_blank');
        } else {
          Linking.openURL(link.route);
        }
      } else {
        const [stack, screen] = link.route.split('::');
        navigation.navigate(stack, { screen });
      }
    },
    [navigation, title],
  );

  const currentRoute = useMemo(() => {
    const routes = route.state?.routes;
    if (routes) {
      const current = routes[routes.length - 1];

      const stack = current.name;
      const screen = current.params
        ? (current.params['screen'] as unknown as string)
        : '';

      return screen ? `${stack}::${screen}` : stack;
    }
    return route.name;
  }, [route.state, route.name]);

  return (
    <View id={id} style={styles.container}>
      <TouchableOpacity
        testID={`linkParent-${camelCase(title)}`}
        style={styles.parent}
        onPress={() => setShowChildren(current => !current)}
      >
        <View style={styles.icon}>
          <Icon size={18} name={icon} color={theme.colors.grey6} />
        </View>
        <View style={styles.title}>
          <Text numberOfLines={1} style={styles.titleText}>
            {title}
          </Text>
          <Icon
            size={18}
            color={theme.colors.grey6}
            name={showChildren ? 'angle-up' : 'angle-down'}
          />
        </View>
      </TouchableOpacity>
      {links && showChildren && (
        <View style={styles.links}>
          {links.map((link, i) => {
            const isActive = link.route === currentRoute;

            if (
              link.title === 'Developer' &&
              process.env.REACT_APP_ENABLE_DEVELOPER_SETTING === 'false'
            ) {
              return null;
            }

            return (
              <WithFeature key={i} feature={link.feature}>
                <TouchableOpacity
                  testID={`link-${camelCase(link.title)}`}
                  onPress={() => handleOnPress(link)}
                  style={styles.link}
                >
                  {isActive && <View style={styles.dot} />}
                  <Text numberOfLines={1} style={styles.linkText}>
                    {link.title}
                    {link.isExternal && (
                      <Icon
                        size={14}
                        name="arrow-up-right"
                        color={theme.colors.grey3}
                      />
                    )}
                  </Text>
                </TouchableOpacity>
              </WithFeature>
            );
          })}
        </View>
      )}
    </View>
  );
};
