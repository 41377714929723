import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView } from 'react-native';
import { CreateBookingInput, Customer } from '@oolio-group/domain';
import {
  getFullFormattedPhoneNumber,
  isValidPhoneNumber,
  useTranslation,
} from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import theme from '../../../../common/default-theme';
import styles from './CreateBookingModal.styles';
import CustomerTile from './CustomerTile';
import CustomerSearch from './CustomerSearch';
import InputText from '../../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../../components/Shared/Inputs/InputPhone';
import InputEmail from '../../../../components/Shared/Inputs/InputEmail';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import InputToggle from '../../../../components/Shared/Inputs/InputToggle';
import { DEFAULT_COUNTRY_CODE } from '../../../../constants';

interface Props {
  onDismiss: () => void;
  onConfirm: (input: CreateBookingInput) => void;
}

interface BookingForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  countryCode: string;
  email: string;
  notes: string;
  priority: boolean;
}

const CreateBookingModal: React.FC<Props> = ({ onDismiss, onConfirm }) => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { maxHeight } = theme.useModalMaxHeight();

  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const [errorMessage, setErrorMessage] = useState('');
  const [existingCustomer, setExistingCustomer] = useState<Customer>();

  const [form, setForm] = useState<BookingForm>({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: country,
    phoneNumber: '',
    priority: false,
    notes: '',
  });

  const onChangeFormInput = useCallback(
    (key: keyof BookingForm, value: string | boolean) => {
      setForm(form => ({
        ...form,
        [key]: value,
      }));
    },
    [],
  );

  const handleOnConfirm = useCallback(() => {
    if (existingCustomer?.id) {
      onConfirm({
        customer: { id: existingCustomer.id },
        notes: form.notes,
        priority: form.priority,
      });
      return;
    }
    const isPhoneValid = isValidPhoneNumber(
      getFullFormattedPhoneNumber(form.countryCode, form.phoneNumber),
    );
    if (!isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    }
    onConfirm({
      customer: {
        firstName: form.firstName,
        lastName: form.lastName,
        phoneNumber: form.phoneNumber,
        isoCountryCode: form.countryCode,
        phone: getFullFormattedPhoneNumber(form.countryCode, form.phoneNumber),
        email: form.email,
      },
      notes: form.notes,
      priority: form.priority,
    });
  }, [existingCustomer, form, onConfirm, translate]);

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({
        error: true,
        message: errorMessage,
      });
      setErrorMessage('');
    }
  }, [errorMessage, showNotification]);

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={styles.keyboardAvoidingView}
    >
      <View style={[styles.container, { maxHeight: maxHeight }]}>
        <Text style={styles.title}>{translate('bookings.createBooking')}</Text>
        <ScrollView contentContainerStyle={styles.body}>
          <CustomerSearch onSelect={value => setExistingCustomer(value)} />
          <View style={styles.body}>
            {existingCustomer ? (
              <CustomerTile
                customer={existingCustomer}
                onRemove={() => setExistingCustomer(undefined)}
              />
            ) : (
              <>
                <View style={styles.row}>
                  <InputText
                    testID="input-firstName"
                    title={translate('form.firstName')}
                    placeholder={translate('common.enterPlaceholder')}
                    containerStyle={theme.forms.inputHalf}
                    onChangeText={onChangeFormInput.bind(null, 'firstName')}
                  />
                  <InputText
                    testID="input-lastName"
                    title={translate('form.lastName')}
                    placeholder={translate('common.enterPlaceholder')}
                    containerStyle={theme.forms.inputHalf}
                    onChangeText={onChangeFormInput.bind(null, 'lastName')}
                  />
                </View>
                <View style={styles.row}>
                  <InputPhone
                    testID="input-phone"
                    title={translate('form.phone')}
                    placeholder={translate('common.enterPlaceholder')}
                    containerStyle={theme.forms.inputHalf}
                    value={form.phoneNumber}
                    onChangeText={onChangeFormInput.bind(null, 'phoneNumber')}
                    onPressCountry={onChangeFormInput.bind(null, 'countryCode')}
                    defaultCountry={form.countryCode}
                  />
                  <InputEmail
                    testID="input-email"
                    title={translate('form.email')}
                    placeholder={translate('form.optional')}
                    containerStyle={theme.forms.inputHalf}
                    onChangeText={onChangeFormInput.bind(null, 'email')}
                  />
                </View>
              </>
            )}
            <InputText
              testID="input-notes"
              multiline
              title={translate('openOrders.notes')}
              placeholder={translate('form.optional')}
              onChangeText={onChangeFormInput.bind(null, 'notes')}
            />
            <InputToggle
              testID="toggle-priority"
              isToggled={form.priority}
              title={translate('bookings.priority')}
              onToggle={onChangeFormInput.bind(
                null,
                'priority',
                !form.priority,
              )}
            />
          </View>
        </ScrollView>
        <View style={styles.actions}>
          <TreatButton
            testID="btn-cancel"
            type="cancel"
            label={translate('button.dismiss')}
            onPress={onDismiss}
          />
          <TreatButton
            testID="btn-confirm"
            type="positive"
            label={translate('bookings.createBooking')}
            onPress={handleOnConfirm}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default CreateBookingModal;
