import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

const useBehaviorSubjectEffect = <T>(
  subject: BehaviorSubject<T>,
  callback: (state: T) => void,
) => {
  useEffect(() => {
    const subscribe = subject.subscribe(callback);
    return () => subscribe?.unsubscribe?.();
  }, [callback, subject]);
};

export default useBehaviorSubjectEffect;
