import { BehaviorSubject, Observable } from 'rxjs';
import {
  App,
  AppAccess,
  ClientAppPermission,
  ResourceOperation,
} from '@oolio-group/domain';
import { noopHandler } from '../utils/errorHandlers';
import keyBy from 'lodash/keyBy';
import theme from '../common/default-theme';

export enum PermissionGroupState {
  PARTIAL = 'partial',
  UN_CHECKED = 'un-checked',
  CHECKED = 'checked',
}
export const getPermissionGroupIconColor = (): Record<
  PermissionGroupState,
  { icon: string; color: string }
> => ({
  [PermissionGroupState.PARTIAL]: {
    color: theme.colors.states.neutral,
    icon: 'minus-square-full',
  },
  [PermissionGroupState.UN_CHECKED]: {
    color: theme.colors.grey1,
    icon: 'square-full',
  },
  [PermissionGroupState.CHECKED]: {
    color: theme.colors.states.positive,
    icon: 'check-square',
  },
});

export const filterPermissionsBySearchTerm = (
  permissions: ClientAppPermission[],
  searchTerm?: string,
) => {
  if (!searchTerm) return permissions;
  const permissionsById = keyBy(permissions, 'id');

  const getParentId = (
    node: ClientAppPermission,
    acc: string[] = [],
  ): string[] => {
    if (node.parent) {
      return getParentId(permissionsById[node.parent], [...acc, node.parent]);
    }
    return acc;
  };

  const permissionsMatchingSearchTerm = permissions.filter(
    permission =>
      (!permission.isPermissionGroup &&
        permission.label.toLowerCase().includes(searchTerm.toLowerCase())) ||
      permission.description?.toLowerCase()?.includes(searchTerm.toLowerCase()),
  );

  let matchingPermissionAncestors: string[] = [];
  permissionsMatchingSearchTerm.forEach(permissionMatchingSearchTerm => {
    const parentNodes = getParentId(permissionMatchingSearchTerm);
    matchingPermissionAncestors = [
      ...matchingPermissionAncestors,
      ...parentNodes,
      permissionMatchingSearchTerm.id,
    ];
  });

  const matchingPermissionAncestorsSet = new Set(
    matchingPermissionAncestors.filter(id => !!id),
  );
  return permissions.filter(permission =>
    matchingPermissionAncestorsSet.has(permission.id),
  );
};

export const flatten = (data?: PermissionTreeNode[]): ClientAppPermission[] => {
  if (!(data && data?.length > 0)) {
    return [];
  }
  return data.reduce((acc, record) => {
    const { children, ...rest } = record;
    acc.push(rest);
    if (children) acc.push(...flatten(children));
    return acc;
  }, [] as ClientAppPermission[]);
};

export interface PermissionTreeNode extends ClientAppPermission {
  children?: ClientAppPermission[];
}

export const groupPermissionsByIdAndOperations = (
  permissions: ClientAppPermission[],
) => {
  return permissions.reduce(
    (acc, permission) => ({ ...acc, [permission.id]: permission.operation }),
    {},
  );
};

export const dependableResourceOperations = (
  operation: ResourceOperation,
  allowedOperations: ResourceOperation[],
  prevOperations: ResourceOperation[] = [],
): ResourceOperation[] => {
  if (operation === 'update') {
    if (
      allowedOperations.includes('view') &&
      !prevOperations.includes('view')
    ) {
      return ['view', operation];
    }
  }
  if (prevOperations.includes(operation)) {
    return prevOperations;
  }
  return [...prevOperations, operation];
};

export type SelectedPermission = Record<string, ResourceOperation[] | boolean>;

const DEFAULT_APP_SETTINGS: AppAccess = {
  [App.POS_APP]: false,
  [App.BACKOFFICE]: false,
};
export class JobRoleSettingsUtility {
  private _search = new BehaviorSubject<string>('');
  private _permissions = new BehaviorSubject<ClientAppPermission[]>([]);
  private _selectedPermissions = new BehaviorSubject<SelectedPermission>({});
  private _appAccessSettings = new BehaviorSubject<AppAccess>(
    DEFAULT_APP_SETTINGS,
  );
  private _isEditable = new BehaviorSubject<boolean>(false);

  set search(value: string) {
    this._search.next(value);
  }

  get search() {
    return this._search.value;
  }

  get search$(): Observable<string> {
    return this._search.asObservable();
  }

  set permissions(value: ClientAppPermission[]) {
    this._permissions.next(value);
  }

  get permissions() {
    return this._permissions.value;
  }

  get operationsByResourceId(): Record<string, ResourceOperation[]> {
    return groupPermissionsByIdAndOperations(this.permissions);
  }

  get permissions$(): Observable<ClientAppPermission[]> {
    return this._permissions.asObservable();
  }

  /**
   *
   * @param operation ResourceOperation | boolean
   * @param shortCut true, yes all are shortcuts (quick actions)
   * @param permission permission (parent or short cut)
   */
  updateGroupPermissions(
    operation: ResourceOperation | boolean,
    shortCut: PermissionGroupState,
    permissionGroup: PermissionTreeNode,
  ) {
    const latest = this._selectedPermissions.value;

    const childPermissions = flatten(permissionGroup.children)
      .filter(permission => {
        if (Array.isArray(permissionGroup.operation)) {
          return (permission.operation as ResourceOperation[])?.includes(
            operation as ResourceOperation,
          );
        }
        return true;
      })
      .map(permission => permission.id);

    if (childPermissions.length > 0) {
      if (['update', 'view'].includes(operation as ResourceOperation)) {
        // Office app
        switch (shortCut) {
          case PermissionGroupState.CHECKED:
            // un check all
            this._selectedPermissions.next({
              ...latest,
              ...childPermissions.reduce((acc, permissionId) => {
                return {
                  ...acc,
                  [permissionId]: (
                    latest[permissionId] as ResourceOperation[]
                  )?.filter(eachOperation => eachOperation !== operation),
                };
              }, {}),
            });
            break;
          case PermissionGroupState.PARTIAL:
          case PermissionGroupState.UN_CHECKED:
            // check all
            this._selectedPermissions.next({
              ...latest,
              ...childPermissions.reduce((acc, permissionId) => {
                if (latest[permissionId]) {
                  if (
                    (latest[permissionId] as ResourceOperation[]).includes(
                      operation as ResourceOperation,
                    )
                  ) {
                    return acc;
                  }
                  return {
                    ...acc,
                    [permissionId]: dependableResourceOperations(
                      operation as ResourceOperation,
                      this.operationsByResourceId[permissionId],
                      latest[permissionId] as ResourceOperation[],
                    ),
                  };
                }
                return {
                  ...acc,
                  [permissionId]: dependableResourceOperations(
                    operation as ResourceOperation,
                    this.operationsByResourceId[permissionId],
                  ),
                };
              }, {}),
            });
            break;
          default:
            noopHandler();
        }
      } else if (typeof operation === 'boolean') {
        // POS app
        switch (shortCut) {
          case PermissionGroupState.CHECKED:
            // un check all
            this._selectedPermissions.next({
              ...latest,
              ...childPermissions.reduce((acc, permissionId) => {
                return { ...acc, [permissionId]: false };
              }, {}),
            });
            break;
          case PermissionGroupState.PARTIAL:
          case PermissionGroupState.UN_CHECKED:
            // check all
            this._selectedPermissions.next({
              ...latest,
              ...childPermissions.reduce((acc, permissionId) => {
                return { ...acc, [permissionId]: true };
              }, {}),
            });
            break;
          default:
            noopHandler();
        }
      }
    }
  }

  /**
   * Add or removes permission to or from the context
   * @param operation
   * @param permission
   */
  setPermissionsToQueue(
    operation: ResourceOperation,
    permission: ClientAppPermission,
  ) {
    const latest = this._selectedPermissions.value;

    /**
     * should be atomic, which means it doesn't have any operation types like `view`, `update`
     */
    if (permission.isAtomic) {
      // Mostly in case of single select, i.e isAtomic
      if (latest[permission.id]) {
        this._selectedPermissions.next({
          ...latest,
          [permission.id]: false,
        });
      } else {
        this._selectedPermissions.next({
          ...latest,
          [permission.id]: true,
        });
      }
    } else if (permission.operation && !permission.isPermissionGroup) {
      /**
       * Must have operation but not a group (or short cut) to apply group of nested permissions
       */
      if (latest[permission.id]) {
        if (
          (latest[permission.id] as ResourceOperation[])?.includes(operation)
        ) {
          latest[permission.id] = (
            latest[permission.id] as ResourceOperation[]
          ).filter(eachOperation => eachOperation !== operation);
          this._selectedPermissions.next(latest);
        } else {
          // add the new operation to operation
          latest[permission.id] = dependableResourceOperations(
            operation,
            this.operationsByResourceId[permission.id],
            latest[permission.id] as ResourceOperation[],
          );
          this._selectedPermissions.next(latest);
        }
      } else {
        // add the new permission & operation
        this._selectedPermissions.next({
          ...latest,
          [permission.id]: dependableResourceOperations(
            operation,
            this.operationsByResourceId[permission.id],
          ),
        });
      }
    }
  }

  set selectedPermissions(value: SelectedPermission) {
    this._selectedPermissions.next(value);
  }

  get selectedPermissions() {
    return this._selectedPermissions.value;
  }

  get selectedPermissions$(): Observable<SelectedPermission> {
    return this._selectedPermissions.asObservable();
  }

  set appAccessSettings(value: AppAccess) {
    this._appAccessSettings.next(value);
  }

  get appAccessSettings() {
    return this._appAccessSettings.value || {};
  }

  get appAccessSettings$(): Observable<AppAccess> {
    return this._appAccessSettings.asObservable();
  }

  reset() {
    this._appAccessSettings.next(DEFAULT_APP_SETTINGS);
    this._search.next('');
    this._permissions.next([]);
    this._selectedPermissions.next({});
    this._isEditable.next(false);
  }

  set isEditable(value: boolean) {
    this._isEditable.next(value);
  }

  get isEditable() {
    return this._isEditable.value;
  }

  get isEditable$(): Observable<boolean> {
    return this._isEditable.asObservable();
  }
}

export const jobRoleSettingsUtility = new JobRoleSettingsUtility();
