import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 12,
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  values: {
    marginTop: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  value: {
    marginRight: 40,
  },
  textMajor: {
    fontSize: 16,
    lineHeight: 24,
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
  },
  textMinor: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
});

export default styles;
