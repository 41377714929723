import { gql } from '@apollo/client';

export const paymentResponseFragment = `
  requestId
  success
  message
  additionalInfo {
    transactionId
    transactionCompletedAt
    transactionAdjustment {
      amount
      percentage
    }
    shopperInfo
    paymentReceipt
    transactionTip
    merchantCode
  }
  events
`;

export const INITIATE_PAYMENT_MUTATION = gql`
  mutation initiatePayment($input: InitiatePaymentInput!) {
    initiatePayment(input: $input) {
      ${paymentResponseFragment}
    }
  }
`;

export const VERIFY_PAYMENT_QUERY = gql`
  query verifyPayment($input: PaymentInput!) {
    verifyPayment(input: $input) {
      ${paymentResponseFragment}
    }
  }
`;

export const INITIATE_REFUND_MUTATION = gql`
  mutation initiateRefund($input: InitiateRefundInput!) {
    initiateRefund(input: $input) {
      ${paymentResponseFragment}
    }
  }
`;

export const CANCEL_PAYMENT_MUTATION = gql`
  mutation cancelPayment($input: PaymentInput!) {
    cancelPayment(input: $input) {
      ${paymentResponseFragment}
    }
  }
`;
