import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ClientAppPermission, Role } from '@oolio-group/domain';
import { useRoles } from './app/users/useRoles';
import { usePermissions } from './app/users/usePermissions';
import { useApolloClient } from '@apollo/client/react/hooks';
import { GET_ALL_ROLES, GET_SYSTEM_PERMISSIONS } from '../graphql/users';
import { tokenUtility } from '../state/tokenUtility';
import { AuthState } from '../constants';
import { distinctUntilChanged, pluck } from 'rxjs';
interface Props {
  children: ReactNode;
}

export interface RoleContextType {
  /**
   * With expanded permissions
   */
  getRoleDetails: (id: string) => Role;
  rolesById: Record<string, Role>;
  permissions: Record<string, ClientAppPermission>;
  loading: boolean;
  fetchRolesSync: () => Promise<{ roles: Role[] }>;
  updateRoles: (data: { roles: Role[] }) => void;
  /**
   * Call this whenever a new role is created or existing role details are modified
   */
  addOrUpdateRole: (role: Role) => void;
  /**
   * Call this whenever a existing role is removed
   */
  removeRole: (roleId: string) => void;
}

export const RoleContext = createContext<RoleContextType>(
  {} as RoleContextType,
);

const RoleProvider: FC<Props> = ({ children }) => {
  const client = useApolloClient();
  const [rolesById, setRoleById] = useState<Record<string, Role>>({});
  const { rolesById: rolesFromAPI, loading: loadingRoles } = useRoles();
  const {
    fetchPermissions,
    permissions: permissionFromAPI,
    loading: loadingPermissions,
  } = usePermissions();
  const [permissions, setPermissions] = useState<
    Record<string, ClientAppPermission>
  >({});

  const restoreOrFetchPermissions = useCallback(() => {
    const data = client.cache.readQuery<{
      appPermissions: ClientAppPermission[];
    }>({
      query: GET_SYSTEM_PERMISSIONS,
    });
    if (data?.appPermissions) {
      setPermissions(
        data.appPermissions.reduce((acc, permission) => {
          return { ...acc, [permission.id]: permission };
        }, {}),
      );
    } else {
      fetchPermissions();
    }
  }, [client, fetchPermissions]);

  const restoreRoles = useCallback(() => {
    const data = client.cache.readQuery<
      {
        roles: Role[];
      },
      { includeSystemRoles?: boolean; includeUserCount?: boolean }
    >({
      query: GET_ALL_ROLES,
      variables: {
        includeSystemRoles: true,
        includeUserCount: false,
      },
    });

    if (data) {
      setRoleById(
        data.roles?.reduce((acc, role) => ({ ...acc, [role.id]: role }), {}),
      );
    }
  }, [client]);

  const updateRoles = useCallback((data: { roles: Role[] }) => {
    setRoleById(
      data.roles?.reduce((acc, role) => ({ ...acc, [role.id]: role }), {}),
    );
  }, []);

  const getRoleDetails = useCallback(
    (id: string) => {
      return rolesById[id];
    },
    [rolesById],
  );

  const fetchRolesSync = useCallback(async () => {
    const { data } = await client.query<
      {
        roles: Role[];
      },
      { includeSystemRoles?: boolean; includeUserCount?: boolean }
    >({
      query: GET_ALL_ROLES,
      variables: {
        includeSystemRoles: true,
        includeUserCount: false,
      },
    });
    return data;
  }, [client]);

  const addOrUpdateRole = useCallback((data: Role) => {
    setRoleById(prev => ({ ...prev, [data.id]: data }));
  }, []);

  const removeRole = useCallback((roleId: string) => {
    setRoleById(prev => {
      const clone = { ...prev };
      delete clone[roleId];
      return clone;
    });
  }, []);

  useEffect(() => {
    setRoleById(rolesFromAPI);
  }, [rolesFromAPI]);

  useEffect(() => {
    setPermissions(
      permissionFromAPI.reduce((acc, permission) => {
        return { ...acc, [permission.id]: permission };
      }, {}),
    );
  }, [permissionFromAPI]);

  useEffect(() => {
    const subscription = tokenUtility.getTokenInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.authState === curr.authState),
        pluck('authState'),
      )
      .subscribe(authState => {
        if (authState === AuthState.LOGGED_IN) {
          restoreOrFetchPermissions();
          restoreRoles();
        }
      });

    return subscription.unsubscribe;
  }, [restoreOrFetchPermissions, restoreRoles]);

  // TODO: a `ws`  connection which will listen to role-added event and add this to context (from remote devices)

  const value = useMemo(
    () => ({
      getRoleDetails,
      rolesById,
      permissions,
      loading: loadingRoles || loadingPermissions,
      fetchRolesSync,
      updateRoles,
      addOrUpdateRole,
      removeRole,
    }),
    [
      getRoleDetails,
      rolesById,
      permissions,
      loadingRoles,
      loadingPermissions,
      fetchRolesSync,
      updateRoles,
      addOrUpdateRole,
      removeRole,
    ],
  );

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export default RoleProvider;
