import { gql } from '@apollo/client';

const bookingFragment = `
  id
  customer {
    id
    firstName
    lastName
    phoneNumber
    email
    phone
    preferredAddress {
      isoCountryCode
    }
  }
  createdAt
  updatedAt
  status
  startAt
  endAt
  waitTime
  guestCount
  priority
  notes
`;

export const GET_BOOKINGS = gql`
  query bookings($filter: BookingFilterInput) {
    bookings(filter: $filter) {
      ${bookingFragment}
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation createBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      ${bookingFragment}
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateBooking($input: UpdateBookingInput!) {
    updateBooking(input: $input) {
      ${bookingFragment}
    }
  }
`;
