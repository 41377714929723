import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { View } from 'react-native';
import {
  Section,
  Store,
  TableShape,
  CreateTableInput,
  UpdateTableInput,
  CreateSectionInput,
  UpdateSectionInput,
  Table,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../../utils/validator';
import {
  CreateTableModal,
  CreateTableModalForm,
} from './CreateTableModal/CreateTableModal';
import { useTables } from '../../../../../../hooks/app/tables/useTables';
import { pick } from 'lodash';
import { getHighestTableNumber } from '../../../../../../utils/TableHelper';
import { trimTrailingSpaces } from '@oolio-group/client-utils';
import { Operation } from '../../../../../../types/Operation';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useSections } from '../../../../../../hooks/app/sections/useSections';
import { useStores } from '../../../../../../hooks/app/useStores';
import { EditTableModal } from './EditTableModal/EditTableModal';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import { default as OfficeSection } from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import { TableIcon } from '../../../../../POS/Orders/FloorView/Sections/Tables/TableIcon/TableIcon';
import styles from './SectionDetails.styles';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

type FormState = Section & { isChanged: boolean };

export const SectionDetails: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const [form, setForm] = useState<FormState>({
    name: '',
    defaultPrefix: '',
    defaultTableShape: TableShape.SQUARE,
  } as FormState);

  const params = route.params as {
    venueId: string;
    sectionId: string;
    operation: Operation;
  };

  const venueId = params.venueId || '';
  const sectionId = params.sectionId || '';
  const operationRef = useRef(params.operation || 'edit');

  const {
    getStores,
    stores,
    loading: loadingStores,
    error: errorStores,
  } = useStores();

  const {
    loading: sectionLoading,
    error: sectionError,
    section,
    getSection,
    createSection,
    createdSectionId,
    updateSection,
    deleteSection,
    operation: sectionOperation,
  } = useSections({ venueId, sectionId });

  const {
    error: tableError,
    getTables,
    createTables,
    deleteTable,
    updateTables,
    operation: tablesOperation,
    loading: tableLoading,
    tables,
  } = useTables({ sectionId });

  useEffect(() => {
    if (sectionId) {
      getSection();
      getTables();
    }
    getStores();
  }, [getSection, sectionId, getTables, getStores]);

  const venueStores = useMemo(() => {
    const storesData = Object.values(stores || []).filter(
      store => store?.venue?.id === venueId,
    );
    return storesData;
  }, [venueId, stores]);

  const storesOptions = useMemo(
    () => venueStores.map(x => ({ label: x.name, value: x.id })),
    [venueStores],
  );

  const error = tableError || sectionError || errorStores;
  const loading = tableLoading || sectionLoading || loadingStores;

  // Tables
  const isTableCreated =
    tablesOperation === Operation.CREATE && !tableLoading && !tableError;
  const isTableUpdated =
    tablesOperation === Operation.UPDATE && !tableLoading && !tableError;
  const isTableDeleted =
    tablesOperation === Operation.DELETE && !tableLoading && !tableError;

  // Sections
  const isSectionCreated =
    sectionOperation === Operation.CREATE && !sectionLoading && !sectionError;
  const isSectionUpdated =
    sectionOperation === Operation.UPDATE && !sectionLoading && !sectionError;
  const isSectionDeleted =
    sectionOperation === Operation.DELETE && !sectionLoading && !sectionError;

  useEffect((): void => {
    if (isTableCreated) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.tableCreatedSuccessfully'),
      });
      closeModal();
    }
  }, [closeModal, isTableCreated, showNotification, translate]);

  useEffect((): void => {
    if (isTableUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
      closeModal();
    }
  }, [closeModal, isTableUpdated, showNotification, translate]);

  useEffect((): void => {
    if (isTableDeleted) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyDeleted'),
      });
      closeModal();
    }
  }, [closeModal, isTableDeleted, showNotification, translate]);

  useEffect(() => {
    if (section) {
      setForm(previousForm => ({ ...previousForm, ...section } as FormState));
    }
  }, [section]);

  useEffect(() => {
    if (Object.values(tables).length) {
      setForm(
        previousForm =>
          ({ ...previousForm, tables: Object.values(tables) } as FormState),
      );
    }
  }, [tables]);

  useEffect((): void => {
    if (isSectionCreated && createdSectionId !== '') {
      operationRef.current = Operation.UPDATE;
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionCreatedSuccessfully'),
      });
      /*
      navigation.goBack();
         */
      navigation.navigate('SectionSettings', {
        venueId,
        sectionId: createdSectionId,
      });
      setForm(
        previousForm =>
          ({ ...previousForm, id: createdSectionId } as FormState),
      );
    }
  }, [
    isSectionCreated,
    createdSectionId,
    navigation,
    showNotification,
    translate,
    venueId,
  ]);

  useEffect((): void => {
    if (isSectionUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionUpdatedSuccessfully'),
      });
    }
  }, [isSectionUpdated, showNotification, translate]);

  useEffect((): void => {
    if (isSectionDeleted) {
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionDeletedSuccessfully'),
      });
      closeModal();
      navigation.goBack();
    }
  }, [closeModal, isSectionDeleted, navigation, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(sectionDetails => {
      return {
        ...sectionDetails,
        [prop]: value,
      };
    });
  }, []);

  const onStoreSelect = useCallback(
    (values: string[]): void => {
      setForm(sectionDetails => {
        return {
          ...sectionDetails,
          stores: values.map(x => x && stores[x]) as Store[],
        };
      });
    },
    [stores],
  );

  const onPressDelete = useCallback((): void => {
    deleteSection(form.id);
  }, [deleteSection, form.id]);

  const onPressSave = useCallback((): void => {
    if (operationRef.current === Operation.CREATE) {
      // create
      if (!form?.stores || form?.stores?.length < 1) {
        showNotification({
          error: true,
          message: translate('backOfficeSections.storeRequired'),
        });
        return;
      }
      const createdSection = {
        name: trimTrailingSpaces(form.name),
        venue: venueId,
        defaultPrefix: form.defaultPrefix,
        defaultTableShape: form.defaultTableShape,
        stores: (form.stores || []).map(store => store.id),
        tables: (form.tables || []).map(table => table.id),
        isActive: true,
      } as CreateSectionInput;
      createSection(createdSection);
    } else {
      const updatedSection = {
        ...pick(form, ['id', 'defaultPrefix', 'defaultTableShape', 'isActive']),
        name: trimTrailingSpaces(form.name),
        venue: venueId,
        stores: (form.stores || []).map(store => store.id),
        tables: (form.tables || []).map(table => table.id),
      } as UpdateSectionInput;
      updateSection(updatedSection);
    }
    setForm(prev => ({ ...prev, name: trimTrailingSpaces(form.name) }));
  }, [
    createSection,
    form,
    showNotification,
    translate,
    updateSection,
    venueId,
  ]);

  const {} = useTables({
    sectionId: form.id,
  });

  const selectedStores = (form.stores || []).map(store => store.id);

  const onDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={onPressDelete}
      />,
    );
  }, [showModal, onPressDelete, translate, form]);

  const onSingleTableCreate = useCallback(
    (createForm: CreateTableModalForm) => {
      const newTable = {
        ...pick(createForm, ['name', 'shape']),
        horizontalSeats: parseInt(createForm.horizontalSeats || '2'),
        verticalSeats: parseInt(createForm.verticalSeats || '2'),
        section: form.id,
      } as CreateTableInput;

      createTables([newTable]);
    },
    [createTables, form.id],
  );

  const onMultipleTableCreate = useCallback(
    (createForm: CreateTableModalForm) => {
      const namePrefix = createForm.prefix;

      const highestTableNumber = getHighestTableNumber(Object.values(tables));

      const tempTable = {
        shape: createForm.shape,
        horizontalSeats: parseInt(createForm.horizontalSeats),
        verticalSeats: parseInt(createForm.verticalSeats),
        section: form.id,
      } as CreateTableInput;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newTables: any[] = [];

      for (let i = 1; i <= parseInt(createForm.numberOfTables); i++) {
        const newTable = {
          ...tempTable,
          name:
            namePrefix && namePrefix !== ''
              ? `${namePrefix}-${i + highestTableNumber}`
              : `${i + highestTableNumber}`,
        };
        newTables.push(newTable);
      }

      createTables(newTables);
    },
    [createTables, form, tables],
  );

  const onTableUpdate = useCallback(
    (updatedTable: UpdateTableInput) => {
      const isValidTableName = !Object.values(tables)
        .filter(table => table.id !== updatedTable.id)
        .map(table => table.name)
        .includes(updatedTable.name || '');
      if (isValidTableName) {
        updateTables([updatedTable]);
      } else {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.duplicateTableName'),
        });
      }
    },
    [updateTables, tables, showNotification, translate],
  );

  const onTableDelete = useCallback(
    (tableId: string) => deleteTable(tableId),
    [deleteTable],
  );

  const onPressAddTable = useCallback(() => {
    if (form.id)
      showModal(
        <CreateTableModal
          section={form}
          onSingleTableCreate={onSingleTableCreate}
          onMultipleTableCreate={onMultipleTableCreate}
        />,
      );
    else
      showNotification({
        error: true,
        message: translate('backOfficeSettings.pleaseSaveSectionFirst'),
      });
  }, [
    form,
    onMultipleTableCreate,
    onSingleTableCreate,
    showModal,
    showNotification,
    translate,
  ]);

  const onSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.sectionNameIsRequired'),
      });
      return;
    }
    onPressSave();
  }, [showNotification, translate, form, onPressSave]);

  const onPressTable = useCallback(
    (table: Table) => {
      showModal(
        <EditTableModal
          table={table}
          onTableUpdate={onTableUpdate}
          onTableDelete={() => onTableDelete(table.id)}
        />,
      );
    },
    [onTableDelete, onTableUpdate, showModal],
  );

  return (
    <ScreenLayout
      loading={loading}
      title={`${form.name || 'Sections'} | Oolio`}
      onSave={onSave}
      onDelete={onDelete}
    >
      <OfficeSection title="Details">
        <InputText
          testID="section-name"
          title={translate('backOfficeSettings.sectionName')}
          value={form.name || ''}
          placeholder={translate('backOfficeSettings.sectionName')}
          onChangeText={onChange.bind(null, 'name')}
          maxLength={50}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginBottom: 20 }}
        />
        <SelectMultiple
          testID="store-availability"
          title={translate('backOfficeSettings.storeAvailability')}
          options={storesOptions}
          selectedValues={selectedStores}
          onValueChange={onStoreSelect}
        />
      </OfficeSection>
      <OfficeSection title="Tables">
        <View style={styles.tables}>
          {Object.values(tables).map((table, i) => (
            <View key={`table-${i}`} style={styles.table}>
              <TableIcon onPressTable={onPressTable} table={table} />
            </View>
          ))}
        </View>
        <ButtonIcon
          size={44}
          icon="plus"
          type="positive"
          testID="btn-addTable"
          onPress={onPressAddTable}
          containerStyle={styles.addTableContainer}
        />
      </OfficeSection>
    </ScreenLayout>
  );
};
