import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  Adjustment,
  AdjustmentType,
  AdjustmentUnit,
} from '@oolio-group/domain';
import styles from '../VenueSettings.styles';
import adjustmentStyle from './Adjustments.style';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';

interface SurchargeProps {
  surcharges: Adjustment[];
  routeType?: AdjustmentType;
  navigateToScreen: (adjustment?: Adjustment) => void;
  getFormattedAmount: (amount: number, type: AdjustmentUnit) => string;
  isActiveSchedule: (adjustment: Adjustment) => number | boolean | undefined;
  getStoreNames: (storeIds: string[]) => string;
}

const Surcharges: React.FC<SurchargeProps> = ({
  surcharges,
  routeType,
  navigateToScreen,
  isActiveSchedule,
  getFormattedAmount,
  getStoreNames,
}) => {
  const { translate } = useTranslation();

  const SurchargeRow = ({ surcharge }: { surcharge: Adjustment }) => {
    return (
      <TouchableOpacity
        key={surcharge.id}
        testID="data-row"
        style={theme.tables.row}
        onPress={() => navigateToScreen(surcharge)}
      >
        <Text
          numberOfLines={1}
          style={[adjustmentStyle.rowText, adjustmentStyle.cellName]}
        >
          {surcharge.name}
        </Text>
        <Text style={[adjustmentStyle.rowText, adjustmentStyle.cellStoreName]}>
          {getStoreNames(surcharge?.stores ?? []) ||
            translate('backofficeVenueSettingAdjustments.allStores')}
        </Text>
        <Text style={[adjustmentStyle.rowText, adjustmentStyle.cellAmount]}>
          {getFormattedAmount(surcharge.amount, surcharge.adjustmentUnit)}
        </Text>
        <View
          style={[adjustmentStyle.rowText, adjustmentStyle.cellScheduleHeader]}
        >
          <Icon
            size={20}
            name={isActiveSchedule(surcharge) ? 'check-circle' : 'circle'}
            color={
              isActiveSchedule(surcharge)
                ? theme.colors.states.positive
                : theme.colors.grey4
            }
          />
        </View>

        <View style={adjustmentStyle.cellDisclosure}>
          <Icon size={20} name="angle-right" color={theme.colors.grey4} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.tableContainer}>
      <View style={theme.tables.header}>
        <Text style={[theme.tables.headerText, adjustmentStyle.cellName]}>
          {translate(
            routeType
              ? 'backofficeVenueSettingAdjustments.surcharge'
              : 'backofficeVenueSettingAdjustments.adjustment',
          )}
        </Text>
        <Text style={[theme.tables.headerText, adjustmentStyle.cellStoreName]}>
          {translate('backofficeVenueSettingAdjustments.stores')}
        </Text>
        <Text style={[theme.tables.headerText, adjustmentStyle.cellAmount]}>
          {translate('backofficeVenueSettingAdjustments.amount')}
        </Text>
        <Text
          style={[theme.tables.headerText, adjustmentStyle.cellScheduleHeader]}
        >
          {translate('backofficeVenueSettingAdjustments.scheduled')}
        </Text>
        <View style={adjustmentStyle.cellDisclosure} />
      </View>
      {surcharges.map(surcharge => (
        <SurchargeRow surcharge={surcharge} key={surcharge.id} />
      ))}
    </View>
  );
};

export default Surcharges;
