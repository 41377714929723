import React, { useCallback, useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import fetch from 'cross-fetch';
import { Resource } from '@oolio-group/domain';
import { useSession } from '../../../hooks/app/useSession';
import { useIntercom } from '../../../hooks/Intercom/useIntercom';
import useOfficeUserAuthorization from '../../../hooks/app/users/useOfficeUserAuthorization';
import { IMap } from '../Reports/types';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { tokenUtility } from '../../../state/tokenUtility';
import { URL_INTERCOM } from '../../../constants';
import Shortcuts from './Sections/Shortcuts';
import OtherSection from './Sections/OtherSection';
import SalesSnapshot from './Sections/SalesSnapshot';
import ScreenLayout from '../../../components/Office/ScreenLayout/ScreenLayout';

export const OverviewScreen: React.FC = () => {
  const [session] = useSession();
  const Intercom = useIntercom();
  const { canI } = useOfficeUserAuthorization();

  const [salesDailyData, setSalesDailyData] = useState<
    IMap<string | undefined> | undefined
  >(undefined);
  const [salesMonthlyData, setSalesMonthlyData] = useState<
    IMap<string | undefined> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const REACT_APP_ANALYTICS_API_URL =
    process.env['REACT_APP_ANALYTICS_API_URL'] ||
    'http://localhost:4020/api/analytics/v1';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function getSalesData(source: any, duration: string) {
      setIsLoading(true);
      const query = {
        measures: ['Orders.count', 'Orders.totalRevenue'],
        timeDimensions: [
          {
            dimension: 'Orders.createdAt',
            dateRange: duration,
          },
        ],
        order: {
          'Orders.createdAt': 'asc',
        },
        timezone: session.currentOrganization?.timezone,
      };

      const queryUrl = `${REACT_APP_ANALYTICS_API_URL}/load?query=${JSON.stringify(
        query,
      )}`;

      const request = await fetch(queryUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          organization: session?.currentOrganization?.id || '',
          Authorization: `Bearer ${tokenUtility.token || ''}`,
        },
      });

      const response = await request.json();
      setIsLoading(false);
      source(response?.data?.[0]);
    }

    if (!salesDailyData) getSalesData(setSalesDailyData, 'Today');

    if (!salesMonthlyData) getSalesData(setSalesMonthlyData, 'This month');
  }, [
    salesDailyData,
    salesMonthlyData,
    session.currentOrganization?.timezone,
    session.currentOrganization?.id,
    REACT_APP_ANALYTICS_API_URL,
  ]);

  const onPressContact = useCallback(() => {
    analyticsService.capture('home_link', {
      button: 'Contact Us',
    });

    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          ...Platform.select({
            web: {
              companyId: session?.currentOrganization?.id || '',
            },
            native: {
              id: session?.currentOrganization?.id || '',
            },
          }),
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
  }, [Intercom, session]);

  const onPressSupport = () => {
    analyticsService.capture('home_link', {
      button: 'Support Center',
    });

    if (Platform.OS === 'web') {
      window.open(URL_INTERCOM, '_blank');
    } else {
      Linking.openURL(URL_INTERCOM);
    }
  };

  return (
    <ScreenLayout hideFooter loading={isLoading} title="Office | Oolio">
      {canI([
        { doOperations: ['view'], onResource: Resource.MANAGE_PRODUCTS },
      ]) && <Shortcuts />}
      {canI([
        { doOperations: ['view'], onResource: Resource.REPORT_SALES },
      ]) && (
        <SalesSnapshot
          dailySales={salesDailyData}
          monthlySales={salesMonthlyData}
        />
      )}
      <OtherSection
        onPressContact={onPressContact}
        onPressSupport={onPressSupport}
      />
    </ScreenLayout>
  );
};
