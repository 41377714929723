export const productsFragment = `
id
name
stores {
  id
  name
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const variantsFragment = `
id
name
products {
  id
}
stores {
  id
  name
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const minimalVariantsFragment = `
id
name
products {
  id
}
`;
