import { formatDuration, intervalToDuration } from 'date-fns';
import { DURATION_FORMAT } from '../constants';
import { DayOfWeek } from '@oolio-group/domain';
import { DAYS_IN_WEEK } from '@oolio-group/client-utils';
import moment from 'moment';
import { format } from 'date-fns';
import { sentenceCase } from 'change-case';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export enum PRESETS {
  'TODAY' = 'Today',
  'YESTERDAY' = 'Yesterday',
  'THIS_WEEK' = 'This Week',
  'LAST_WEEK' = 'Last Week',
  'THIS_MONTH' = 'This Month',
  'LAST_MONTH' = 'Last Month',
  'THIS_YEAR' = 'This Year',
  'LAST_YEAR' = 'Last Year',
}

moment.relativeTimeRounding(Math.floor);
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1min',
    ss: '1min',
    m: '1min',
    mm: '%dmins',
    h: '1hr',
    hh: '%dhrs',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY',
  },
});

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 31);
moment.relativeTimeThreshold('M', 12);
moment.relativeTimeThreshold('y', 365);

export const calculateStartDayOfMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
};

export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getStartingMonthOfQuarter = (
  months: string[],
  currentMonth: string,
) => {
  const monthIndex = months.findIndex(month => month === currentMonth);
  if (0 <= monthIndex && monthIndex <= 2) {
    return months[0];
  }
  if (3 <= monthIndex && monthIndex <= 5) {
    return months[3];
  }
  if (6 <= monthIndex && monthIndex <= 8) {
    return months[6];
  }
  if (9 <= monthIndex && monthIndex <= 11) {
    return months[9];
  }

  return months[0];
};

export const filterBy = (
  filter: string,
  hours = 0,
  minutes = 0,
  startMonth?: string,
): number | undefined => {
  const month = new Date(Date.now()).getMonth();
  const year = new Date(Date.now()).getFullYear();
  const startMonthIndex = MONTHS.findIndex(month => month === startMonth);
  switch (filter) {
    case 'Y':
      return new Date(Date.now() - 86400000).setHours(hours, minutes, 0, 0);
    case '1W':
      return new Date(Date.now() - 604800000).setHours(hours, minutes, 0, 0);
    case '4W':
      return new Date(Date.now() - 604800000 * 4).setHours(
        hours,
        minutes,
        0,
        0,
      );
    case '1Y':
      return new Date(Date.now() - 604800000 * 52).setHours(
        hours,
        minutes,
        0,
        0,
      );
    case 'MTD':
      return new Date(year, month).setHours(hours, minutes);
    case 'QTD':
      const quarter = Math.floor((month + 1) / 3);
      const currentMonth = MONTHS[month];
      const customMonthSequence = [
        ...MONTHS.slice(startMonthIndex),
        ...MONTHS.slice(0, startMonthIndex),
      ];
      const startingMonthOfQuarter = getStartingMonthOfQuarter(
        customMonthSequence,
        currentMonth,
      );
      const startingMonthOfQuarterIndex = MONTHS.findIndex(
        month => month === startingMonthOfQuarter,
      );
      return startMonth
        ? new Date(
            startingMonthOfQuarterIndex > month ? year - 1 : year,
            startingMonthOfQuarterIndex,
          ).setHours(hours, minutes)
        : new Date(year, quarter * 3).setHours(hours, minutes);
    case 'YTD':
      return startMonth
        ? new Date(
            startMonthIndex > month ? year - 1 : year,
            startMonthIndex,
          ).setHours(hours, minutes)
        : new Date(year, 0).setHours(hours, minutes);
    case 'C':
      return;
    default:
      return new Date(Date.now()).setHours(hours, minutes, 0, 0);
  }
};

export const prefixZero = (n: number): string => (n < 9 ? `0${n}` : `${n}`);

export const getTimeElapsed = (input: number): string => {
  const timeDiff = Date.now() - input;
  const diffSeconds = Math.ceil((timeDiff % 60000) / 1000);
  const diffMinutes = Math.floor(timeDiff / 60000);
  return `${prefixZero(diffMinutes)}:${prefixZero(diffSeconds)}`;
};

export const getAgeFormat = (input: number): string => {
  if (input === 0) {
    return '--';
  }
  const diffDays = moment().diff(moment(input), 'days');
  if (diffDays >= 2) {
    return `${diffDays}d`;
  } else {
    const diffMinutes = moment().diff(moment(input), 'minutes');
    const hours = Math.floor(diffMinutes / 60);
    const minutes = Math.round((diffMinutes / 60 - hours) * 60);

    const prefixZero = (number: number) =>
      number < 10 ? `0${number}` : number.toString();

    return `${prefixZero(hours)}:${prefixZero(minutes)}`;
  }
};

export const getAgeColor = (input: number): number => {
  if (input === 0) {
    return 10;
  }
  const diffMinutes = moment().diff(moment(input), 'minutes');
  const hours = diffMinutes / 60;
  const roundHours = Math.floor(hours);
  const minutes = (hours - roundHours) * 60;
  const roundMinutes = Math.round(minutes);
  const hoursMinutesNumber = roundHours + roundMinutes / 100;
  if (hoursMinutesNumber < 9.59) {
    return 10;
  } else if (hoursMinutesNumber >= 10 && hoursMinutesNumber < 29.59) {
    return 11;
  } else {
    return 12;
  }
};

export const getDayFormat = (input: number): string => {
  if (input === 0) {
    return '--';
  }

  const duration = intervalToDuration({
    start: new Date(input),
    end: new Date(),
  });
  const formattedDuration = formatDuration(duration, {
    format: DURATION_FORMAT,
  });

  const formats = formattedDuration.split(' ');

  return formattedDuration ? formats[0] + ' ' + formats[1] : '1 second';
};

export const getDurationFormat = (input: number | undefined): string => {
  if (!input) {
    return '-';
  }

  return moment(input).fromNow() || '-';
};

/**
 * A function to format date into DD-MM-YYYY.
 * @param input - A date being formatted
 * @param separator - separator between date component. default is '-'
 * @returns {string} - DD-MM-YYYY
 */
export const formatDate: (
  input: Date | string | number,
  separator?: '/' | '-',
) => string = (input, separator = '-') => {
  if (typeof input === 'string') input = new Date(input);
  return format(input, `d${separator}L${separator}y`);
};

/**
 * Time-slot generated for 24 hrs in format HH:MM with 30 minutes interval
 */
export const timeSlotOptions: { label: string; value: string }[] = [
  ...[...Array(24).keys()].map(num => {
    const firstHalfSlot = {
      label: `${num}:00`,
      value: `${num}:00`,
    };
    const secondHalfSlot = {
      label: `${num}:30`,
      value: `${num}:30`,
    };
    if (num < 10) {
      firstHalfSlot.label = '0' + firstHalfSlot.label;
      firstHalfSlot.value = '0' + firstHalfSlot.value;
      secondHalfSlot.label = '0' + secondHalfSlot.label;
      secondHalfSlot.value = '0' + secondHalfSlot.value;
    }
    return [firstHalfSlot, secondHalfSlot];
  }),
  {
    label: '23:59',
    value: '23:59',
  },
].flat();

export const timeSlotCloseOptions: { label: string; value: string }[] = [
  ...[...Array(24).keys()].map(num => {
    const firstHalfSlot = {
      label: `${num}:29`,
      value: `${num}:29`,
    };
    const secondHalfSlot = {
      label: `${num}:59`,
      value: `${num}:59`,
    };
    if (num < 10) {
      firstHalfSlot.label = '0' + firstHalfSlot.label;
      firstHalfSlot.value = '0' + firstHalfSlot.value;
      secondHalfSlot.label = '0' + secondHalfSlot.label;
      secondHalfSlot.value = '0' + secondHalfSlot.value;
    }
    return [firstHalfSlot, secondHalfSlot];
  }),
  {
    label: '23:59',
    value: '23:59',
  },
].flat();

export const weekDaysOptions = [
  ...Object.keys(DayOfWeek).map(key => ({
    label: sentenceCase(key),
    value: DayOfWeek[key as keyof typeof DayOfWeek],
  })),
];

export const defaultStartTime = '00:00';
export const defaultEndTime = '23:59';

export const endOfDay = (date: Date) => date.setHours(23, 59, 59, 999);

export function capitalizeFirstLetter(text = '') {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const daysOption = [
  ...DAYS_IN_WEEK.map(key => ({
    label: capitalizeFirstLetter(key),
    value: DayOfWeek[key as keyof typeof DayOfWeek],
  })),
];
