import React, { useCallback, useEffect } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { PrinterProfileType } from '@oolio-group/domain';
import { View, Text } from 'react-native';
import DevicePrintingRow, {
  PreferencesByPrinterProfile,
} from './DevicePrintingRow';
import { usePrintingSetup } from './useDevicePrinterSetup';
import { useNotification } from '../../../../../../../hooks/Notification';
import styles from '../../Devices.styles';
import theme from '../../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';

const DevicePrinting: React.FC = () => {
  const {
    groupPreferencesByPrinterProfileType,
    isLoading,
    titleMappings,
    printerOptions,
    printerTemplatesByPrinterProfileType,
    onChange,
    saveOrUpdatePrintingSetup,
    error,
  } = usePrintingSetup();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [showNotification, error]);

  const onSave = useCallback(async (): Promise<void> => {
    const result = await saveOrUpdatePrintingSetup();
    if (result) {
      showNotification(result);
    }
  }, [saveOrUpdatePrintingSetup, showNotification]);

  return (
    <ScreenLayout
      loading={isLoading}
      title="Device Printing | Oolio"
      onSave={onSave}
    >
      {Object.keys(groupPreferencesByPrinterProfileType).map(
        (preferencesByPrinterProfileType, i) => {
          return (
            <Section
              key={i}
              title={titleMappings[preferencesByPrinterProfileType]}
              layoutWidth="medium"
            >
              {groupPreferencesByPrinterProfileType[
                preferencesByPrinterProfileType
              ].length === 0 ? (
                <View>
                  <Text testID="empty-list">
                    {translate('printingSetup.noKitchenPrinterProfile')}
                  </Text>
                </View>
              ) : (
                <View style={styles.tableContainer}>
                  <View style={theme.tables.header}>
                    <Text
                      style={[theme.tables.headerText, styles.cellPrinting]}
                    >
                      Profile
                    </Text>
                    <Text
                      style={[theme.tables.headerText, styles.headerPrinter]}
                    >
                      Printer
                    </Text>
                    <Text
                      style={[theme.tables.headerText, styles.cellPrinting]}
                    >
                      Template
                    </Text>
                  </View>
                  {groupPreferencesByPrinterProfileType[
                    preferencesByPrinterProfileType
                  ].map((profile: PreferencesByPrinterProfile, i: number) => (
                    <DevicePrintingRow
                      key={i}
                      index={i}
                      printerProfileType={
                        preferencesByPrinterProfileType as PrinterProfileType
                      }
                      preference={profile}
                      printerOptions={printerOptions}
                      printerTemplateOptions={
                        printerTemplatesByPrinterProfileType[
                          preferencesByPrinterProfileType
                        ]
                      }
                      onChange={onChange}
                    />
                  ))}
                </View>
              )}
            </Section>
          );
        },
      )}
    </ScreenLayout>
  );
};

export default DevicePrinting;
