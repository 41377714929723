import {
  IntegrationApps,
  UpdateIntegrationPartnerInput,
} from '@oolio-group/domain';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { SettingsContext } from '../OnlineOrderIntegrationsSettingsTabs';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import FeatureDetail from '../../../Layout/FeatureDetail/FeatureDetail';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';

export const AboutTab: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const context = useContext(SettingsContext);
  const navigation = useNavigation();
  const [totalLocationConnected, setTotalLocationConnected] =
    useState<number>(0);
  const isFocused = useIsFocused();

  const params = context.params || (route.params as { app: IntegrationApps });

  const {
    getIntegrations,
    integrations,
    loading: loadingIntegrations,
  } = useFeatures();

  const featureData = integrations?.find(
    integration => integration.app === params.app,
  );

  useEffect(() => {
    if (isFocused) getIntegrations();
  }, [getIntegrations, isFocused]);

  useFocusEffect(getIntegrations);

  const {
    loading,
    getIntegrationPartnerSettings,
    integrationPartners,
    updateIntegrationPartnerSettings,
  } = useIntegrationPartners();

  useFocusEffect(
    useCallback(() => {
      getIntegrationPartnerSettings({ appName: params.app });
    }, [getIntegrationPartnerSettings, params.app]),
  );

  useEffect(() => {
    const integrationPartnersArray = Object.values(
      stripProperties(integrationPartners, '__typename'),
    ) as UpdateIntegrationPartnerInput[];

    const integrationPartnerArrayConnected = integrationPartnersArray.filter(
      integrationPartner => integrationPartner.isActive,
    );

    setTotalLocationConnected(integrationPartnerArrayConnected?.length);
  }, [integrationPartners]);

  const toggleConnect = useCallback(() => {
    if (totalLocationConnected > 0) {
      const integrationPartnersArray = Object.values(
        stripProperties(integrationPartners, '__typename'),
      ) as UpdateIntegrationPartnerInput[];
      integrationPartnersArray.forEach(
        integrationPartner => (integrationPartner.isActive = false),
      );

      updateIntegrationPartnerSettings(
        integrationPartnersArray.map(integrationPartner => {
          return {
            ...integrationPartner,
            preferences: integrationPartner.preferences && {
              ...integrationPartner.preferences,
              onlineOrdering: integrationPartner.preferences
                ?.onlineOrdering && {
                ...integrationPartner.preferences?.onlineOrdering,
                deliverySettings: {
                  ...integrationPartner.preferences?.onlineOrdering
                    ?.deliverySettings,
                  adjustments:
                    integrationPartner.preferences?.onlineOrdering?.deliverySettings?.adjustments?.map(
                      adjustment => adjustment.id,
                    ),
                },
                pickUpSettings: {
                  ...integrationPartner.preferences?.onlineOrdering
                    ?.pickUpSettings,
                  adjustments:
                    integrationPartner.preferences?.onlineOrdering?.pickUpSettings?.adjustments?.map(
                      adjustment => adjustment.id,
                    ),
                },
                dineInSettings: {
                  ...integrationPartner.preferences?.onlineOrdering
                    ?.dineInSettings,
                  adjustments:
                    integrationPartner.preferences?.onlineOrdering?.dineInSettings?.adjustments?.map(
                      adjustment => adjustment.id,
                    ),
                },
              },
            },
            accountId: undefined,
          } as UpdateIntegrationPartnerInput;
        }),
      );
    } else {
      // Have to enable at least one store in Settings Tab to connect the Oolio. So, app should navigate to SettingsTab
      navigation.navigate('SettingsTab');
    }
  }, [
    totalLocationConnected,
    integrationPartners,
    updateIntegrationPartnerSettings,
    navigation,
  ]);

  return (
    <ScreenLayout
      hideFooter
      loading={loading || loadingIntegrations}
      title={translate('navigation.integrationsPage', {
        appName: translate('appName'),
      })}
    >
      <FeatureDetail
        feature={featureData}
        onEnable={toggleConnect}
        totalLocationConnected={totalLocationConnected}
      />
    </ScreenLayout>
  );
};
