import React, { useMemo } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { OrderType, Reservation } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import styles from './ReservationsTable.styles';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import { formatReservationTimeAndDate } from '../utils';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Icon from '../../../../components/Icon/Icon';
import { Image } from 'react-native';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}

export interface ReservationsListViewProps {
  data: Reservation[];
  onSelectReservation: (reservation: Reservation) => void;
  loading: boolean;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const ReservationsTable: React.FC<ReservationsListViewProps> = ({
  data,
  onSelectReservation,
  loading,
  currentPage,
  setCurrentPage,
}: ReservationsListViewProps) => {
  const { translate } = useTranslation();

  const reservationButtonMapByStatus: Record<
    string,
    {
      title: string;
      type: 'positive' | 'focus' | 'neutral' | 'negative' | 'cancel';
      action?: (id: string) => void;
      disabled?: boolean;
    }
  > = useMemo(
    () => ({
      ARRIVED: {
        title: translate('reservations.arrived'),
        type: 'positive',
      },
      PARTIALLY_ARRIVED: {
        title: translate('reservations.partiallyArrived'),
        type: 'neutral',
      },
      PRE_ARRIVED: {
        title: translate('reservations.arrived'),
        type: 'positive',
      },
      PRE_PARTIALLY_ARRIVED: {
        title: translate('reservations.partiallyArrived'),
        type: 'neutral',
      },
      CONFIRMED: {
        title: translate('reservations.confirmed'),
        type: 'focus',
      },
      BOOKED: {
        title: translate('reservations.booked'),
        type: 'focus',
      },
      NOT_RECONCILED: {
        title: translate('reservations.booked'),
        type: 'focus',
      },
    }),
    [translate],
  );

  const pageItems = useMemo(() => {
    return data?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [data, currentPage]);

  return (
    <View>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.columnIcon]}>{}</Text>
        <Text style={[theme.tables.headerText, styles.columnGuests]}>
          {translate('reservations.guests')}
        </Text>
        <Text style={[theme.tables.headerText, styles.columnTable]}>
          {translate('reservations.table')}
        </Text>
        <Text style={[theme.tables.headerText, styles.columnCustomer]}>
          {translate('reservations.customer')}
        </Text>
        <Text style={[theme.tables.headerText, styles.columnReservationTime]}>
          {translate('reservations.reservationTime')}
        </Text>
        <Text style={[theme.tables.headerText, styles.columnNotes]}>
          {translate('reservations.notes')}
        </Text>
        <Text style={[theme.tables.headerText, styles.columnStatus]}>{}</Text>
      </View>
      <View style={styles.tableBody}>
        {loading ? (
          <View style={theme.tables.emptyView}>
            <LoadingIndicator size={'large'} />
          </View>
        ) : data.length > 0 ? (
          pageItems.map((reservation, i) => {
            const { reservationTime, dateString } =
              formatReservationTimeAndDate(
                reservation.real_datetime_of_slot,
                reservation.duration,
              );
            return (
              <TouchableOpacity
                key={reservation.reference_code}
                style={styles.tableRow}
                onPress={() => onSelectReservation(reservation)}
                testID="row-online-reservation"
              >
                <Text
                  testID={`online-reservation-${i}-source`}
                  style={styles.columnIcon}
                  numberOfLines={1}
                >
                  <Image
                    source={{
                      uri: 'https://till-x-storage-development.s3.ap-south-1.amazonaws.com/integrations/logos/sevenrooms.jpeg',
                    }}
                    style={styles.cellIcon}
                  ></Image>
                </Text>
                <View style={styles.columnGuests}>
                  <Sticker
                    testID={`online-reservation-${i}-guests`}
                    type={'primaryLight'}
                    label={`${reservation.max_guests}`}
                    containerStyle={styles.cellGuests}
                  />
                </View>
                <View style={styles.columnTable}>
                  <Text
                    testID={`online-reservation-${i}-table-no`}
                    style={styles.cellTableNo}
                    numberOfLines={1}
                  >
                    {reservation.table_numbers.join(', ')}
                  </Text>
                  <Text
                    testID={`online-reservation-${i}-table-area`}
                    style={styles.cellTableArea}
                    numberOfLines={1}
                  >
                    {reservation.venue_seating_area_name}
                  </Text>
                </View>
                <View style={styles.columnCustomer}>
                  <Text
                    testID={`online-reservation-${i}-customer-name`}
                    style={styles.cellCustomerName}
                    numberOfLines={1}
                  >
                    {reservation.full_name}
                  </Text>
                  <Text
                    testID={`online-reservation-${i}-customer-phone`}
                    style={styles.cellCustomerPhone}
                    numberOfLines={1}
                  >
                    {reservation.phone_number}
                  </Text>
                </View>
                <View style={styles.columnReservationTime}>
                  <Text
                    testID={`online-reservation-${i}-reservation-date`}
                    style={styles.cellReservationTimeDate}
                    numberOfLines={1}
                  >
                    {dateString}
                  </Text>
                  <Text
                    testID={`online-reservation-${i}-reservation-time-range`}
                    style={styles.cellReservationTimeRange}
                    numberOfLines={1}
                  >
                    {reservationTime}
                  </Text>
                </View>
                <View style={styles.columnNotes}>
                  <Text
                    testID={`online-reservation-${i}-reservation-notes`}
                    style={styles.cellReservationNotes}
                    numberOfLines={1}
                  >
                    {reservation.notes}
                  </Text>
                </View>
                <View style={styles.columnStatus}>
                  {reservationButtonMapByStatus[reservation.status] && (
                    <Sticker
                      testID={`online-reservation-${i}-status-button`}
                      type={
                        reservationButtonMapByStatus[reservation.status]
                          ?.type || 'neutral'
                      }
                      label={
                        reservationButtonMapByStatus[reservation.status]
                          ?.title || ''
                      }
                      containerStyle={styles.cellStatus}
                      showIndicator
                      rounded
                    />
                  )}
                </View>
                <View style={theme.tables.disclosure}>
                  <Icon
                    name="angle-right"
                    size={20}
                    color={theme.colors.grey4}
                  />
                </View>
              </TouchableOpacity>
            );
          })
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('reservations.noResults')}
            </Text>
          </View>
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={currentPage}
          dataLength={data.length}
          onPageChange={setCurrentPage}
        />
      ) : null}
    </View>
  );
};
export default ReservationsTable;
