import React, { useMemo } from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import {
  useNotification,
  useNotificationsList,
} from '../../../hooks/Notification';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import { NotificationList } from '../../Notification/NotificationList';
import theme from '../../../common/default-theme';

export interface LayoutProps {
  children: React.ReactNode;
}

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    flexDirection: 'row-reverse',
    backgroundColor: theme.colors.black,
  },
  screen: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  sidebar: {
    height: '100%',
  },
});

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const { closeNotification } = useNotification();
  const notifications = useNotificationsList();

  return (
    <SafeAreaView style={styles.layout}>
      <StatusBar animated={true} barStyle={'light-content'} />
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
      <View style={styles.screen}>
        <Header />
        {useMemo(
          () => (
            <ScrollView contentContainerStyle={styles.scrollView}>
              {children}
            </ScrollView>
          ),
          [children],
        )}
      </View>
      <Navigation />
    </SafeAreaView>
  );
};

export default Layout;
