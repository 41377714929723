import { StyleFn } from '@oolio-group/domain';

export const grid = {
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 2,
};

export const productsContainer: StyleFn = () => ({
  flex: 5,
  ...grid,
});

export const optionsContainer: StyleFn = () => ({
  flex: 3,
  ...grid,
});

export const tileContainer: StyleFn = ({ rows, columns }) => ({
  height: `${100 / rows}%`,
  width: `${98.2 / columns}%`,
  paddingBottom: 4,
});

export const backLabelStyling: StyleFn = () => ({
  fontSize: 14,
});

export const allergensLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.black,
});
