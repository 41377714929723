import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './GridItem.styles';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';

interface ItemProps {
  name?: string;
  color?: string;
  isSelected?: boolean;
  isEmptyTile?: boolean;
  isGroupType?: boolean;
  available?: boolean;
  onPress: () => void;
  onLongPress: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Item: React.ForwardRefRenderFunction<any, ItemProps> = (
  {
    name,
    onLongPress,
    onPress,
    color,
    isSelected,
    isEmptyTile,
    isGroupType,
    available,
  },
  ref,
) => {
  const isUnavailable = available === false;

  return (
    <TouchableOpacity
      ref={ref}
      onPress={onPress}
      onLongPress={onLongPress}
      style={[
        styles.container,
        {
          backgroundColor: isUnavailable
            ? theme.colors.grey1
            : color || theme.colors.deepPurple,
        },
      ]}
    >
      {!!isGroupType && <View style={styles.circle} />}
      {isSelected && <View style={styles.triangle} />}
      <View style={styles.disclosure}>
        <Icon name="draggabledots" size={18} color={theme.colors.white} />
      </View>
      <View style={styles.textView}>
        {!!name && (
          <Text
            style={[
              styles.text,
              {
                color: isUnavailable ? theme.colors.grey5 : theme.colors.white,
              },
            ]}
            numberOfLines={2}
          >
            {name}
          </Text>
        )}
        {!!isEmptyTile && (
          <Icon name="plus" size={18} color={theme.colors.states.positive} />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default React.forwardRef(Item);
