import { StyleFn } from '@oolio-group/domain';

const phoneTextInputStyle: StyleFn = () => ({
  width: '100%',
  marginVertical: 10,
});

export const styles = {
  phoneTextInputStyle,
};
