import React, { useState } from 'react';
import {
  Text,
  View,
  ViewStyle,
  TextInput,
  TouchableOpacity,
  TextInputProps,
} from 'react-native';
import theme from '../../../common/default-theme';
import styles from './Inputs.styles';
import Icon from '../../Icon/Icon';

export interface InputPasswordProps extends TextInputProps {
  title?: string;
  testID: string;
  value?: string | undefined;
  placeholder: string;
  errorMessage?: string;
  containerStyle?: ViewStyle | ViewStyle[];
}

const InputPassword: React.FC<InputPasswordProps> = ({
  title,
  value,
  testID,
  placeholder,
  errorMessage,
  containerStyle,
  ...props
}: InputPasswordProps) => {
  const [secureText, setSecureText] = useState<boolean>(true);

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
          ]}
        >
          <TextInput
            testID={testID}
            secureTextEntry={secureText}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={theme.colors.grey5}
            style={styles.inputContainer}
            autoCapitalize="none"
            autoComplete="password"
            autoCorrect={false}
            spellCheck={false}
            {...props}
          />
        </View>
        <TouchableOpacity
          accessible={false}
          onPress={() => setSecureText(!secureText)}
          style={styles.accessory}
        >
          <Icon
            size={20}
            color={theme.colors.dark}
            name={secureText ? 'Eye' : 'EyeSlash'}
          />
        </TouchableOpacity>
      </View>
      {!!errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
    </View>
  );
};

export default InputPassword;

InputPassword.defaultProps = {
  editable: true,
};
