import React, { useEffect, useCallback, useMemo } from 'react';
import { FeatureContext, FeatureIDs } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import { useFeatures } from '../../../../../hooks/app/features/useFeatures';
import { useModal } from '@oolio-group/rn-use-modal';
import VenueSelectionModal from './VenueSelectionModal';
import { useSession } from '../../../../../hooks/app/useSession';
import { useNotification } from '../../../../../hooks/Notification';
import FeatureDetail from '../../Layout/FeatureDetail/FeatureDetail';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';

const FeatureAboutScreen: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const routeParams = route.params as {
    featureId: string;
  };

  const { featureId } = routeParams;
  const { features, loading, getFeatures, toggleFeature } = useFeatures();

  const [session] = useSession();

  const selectedFeature = features.find(
    feature => feature.featureId === featureId,
  );

  const relatedFeatures = features.filter(
    feature => feature.id !== selectedFeature?.id && !feature.enabled,
  );

  useEffect(() => {
    getFeatures();
  }, [getFeatures]);

  const allVenues = useMemo(
    () => session?.user?.venues || [],
    [session?.user?.venues],
  );

  const showVenueSelectionModal = useCallback(() => {
    showModal(
      <VenueSelectionModal
        onToggleFeatureOnVenue={toggleFeature}
        feature={selectedFeature}
      />,
    );
  }, [selectedFeature, showModal, toggleFeature]);

  const onEnableFeature = useCallback(() => {
    if (
      selectedFeature?.context === FeatureContext.VENUE &&
      allVenues?.length > 1
    ) {
      return showVenueSelectionModal();
    }

    const venueIds =
      selectedFeature?.context === FeatureContext.VENUE
        ? allVenues.map(venue => venue.id)
        : [];
    toggleFeature({ id: selectedFeature?.id as string, venueIds });
  }, [
    allVenues,
    selectedFeature?.context,
    selectedFeature?.id,
    showVenueSelectionModal,
    toggleFeature,
  ]);

  const onConfirmDisabled = useCallback(() => {
    toggleFeature({ id: selectedFeature?.id as string, venueIds: [] });
  }, [selectedFeature?.id, toggleFeature]);

  const onDisableFeature = useCallback(() => {
    if (
      selectedFeature?.featureId === FeatureIDs.MULTI_VENUE &&
      allVenues.length > 1
    ) {
      return showNotification({
        error: true,
        message: translate('backOffice.cantDisableFeature'),
      });
    }
    onConfirmDisabled();
    closeModal();
  }, [
    allVenues.length,
    closeModal,
    onConfirmDisabled,
    selectedFeature?.featureId,
    showNotification,
    translate,
  ]);

  const onPressDisable = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={translate('backOffice.features.disableFeature', {
          featureName: selectedFeature?.name,
        })}
        message={translate('backOffice.features.warningDisableMessage', {
          featureName: selectedFeature?.name,
        })}
        onConfirm={() => {
          closeModal();
          onDisableFeature();
        }}
      />,
    );
  }, [
    closeModal,
    onDisableFeature,
    selectedFeature?.name,
    showModal,
    translate,
  ]);

  return (
    <ScreenLayout
      hideFooter
      loading={loading}
      title={translate('navigation.featuresPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section>
        <FeatureDetail
          feature={selectedFeature}
          relatedFeatures={relatedFeatures}
          venues={allVenues}
          onEnable={onEnableFeature}
          onDisable={onPressDisable}
          onShowModal={showVenueSelectionModal}
        />
      </Section>
    </ScreenLayout>
  );
};

export default FeatureAboutScreen;
