import React, { useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import {
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  Modifier as ModifierDefault,
  ModifierGroup,
  ComboItem,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { getBestPriceOfModifier } from '@oolio-group/catalog-helper';
import Icon from '../../../../../components/Icon/Icon';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import theme from '../../../../../common/default-theme';
import styles from './ProductOptions.styles';

interface Modifier extends ModifierDefault {
  isDefault?: boolean;
}

interface ProductModifierRowProps {
  modifierGroup: ModifierGroup;
  modifiers: Modifier[];
  isProductAsModifierGroup?: boolean;
  products: ComboItem[];
  isGrouped: boolean;
  isRequired: boolean;
  onChange: (
    modifierGroupId: string,
    prop: string,
    val: string | boolean,
    modifierId?: string,
  ) => void;
  rowIndex: number;
  onDeleteRow: (id: string) => void;
  drag?: () => void;
  isCombo?: boolean;
}

export const ProductModifierRow: React.FC<ProductModifierRowProps> = ({
  modifierGroup,
  onDeleteRow,
  onChange,
  isRequired,
  isGrouped,
  modifiers,
  drag,
  isProductAsModifierGroup,
  products,
  isCombo,
}) => {
  const [showChild, setShowChild] = useState(false);
  const { appendCurrency } = useCurrency();
  const { translate } = useTranslation();
  const toggleRow = useCallback(() => {
    setShowChild(prev => !prev);
  }, []);
  const isDefaultModGroup =
    modifierGroup.id === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME;
  const shouldShowCheckBoxForGroup =
    (!products || !products.length) && !isDefaultModGroup;
  const shouldShowCheckBoxForRequiredOption =
    !isDefaultModGroup &&
    (!!modifierGroup.products?.length || !!modifierGroup.modifiers?.length);

  const changeIsDefault = useCallback(
    (item: ComboItem | Modifier) =>
      onChange(modifierGroup.id, 'isDefault', !item.isDefault, item.id),
    [onChange, modifierGroup],
  );
  return (
    <View>
      <TouchableOpacity
        testID="row-group"
        // eslint-disable-next-line react-native/no-inline-styles
        style={[theme.tables.row, { borderBottomWidth: showChild ? 0 : 1 }]}
        onLongPress={drag}
      >
        <View style={theme.tables.disclosure}>
          <Icon size={20} name="draggabledots" color={theme.colors.grey5} />
        </View>
        <Text style={styles.cellGroup}>{modifierGroup.name}</Text>
        {shouldShowCheckBoxForGroup && (
          <InputToggle
            testID="toggle-group"
            isToggled={isGrouped}
            onToggle={() => onChange(modifierGroup.id, 'isGrouped', !isGrouped)}
            containerStyle={styles.cellToggle}
          />
        )}
        {shouldShowCheckBoxForRequiredOption && (
          <InputToggle
            testID="toggle-require"
            isToggled={isRequired}
            onToggle={() =>
              onChange(modifierGroup.id, 'isRequired', !isRequired)
            }
            containerStyle={styles.cellToggle}
          />
        )}
        {!isProductAsModifierGroup && (
          <ButtonIcon
            testID="btn-expand"
            icon={showChild ? 'angle-up' : 'angle-down'}
            type="neutralLight"
            onPress={toggleRow}
            containerStyle={styles.cellButton}
          />
        )}
        <ButtonIcon
          testID="btn-removeGroup"
          icon="trash-alt"
          type="negativeLight"
          onPress={() => onDeleteRow(modifierGroup.id)}
          containerStyle={styles.cellButton}
        />
      </TouchableOpacity>
      {showChild && (
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellGroup]}>
              {translate('modifiers.default')}
            </Text>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeProducts.productPrice')}
            </Text>
          </View>
          <View>
            {modifiers?.map((mod, i) => (
              <View testID="row-modifier" key={i} style={theme.tables.row}>
                <View style={theme.tables.disclosure}>
                  <Icon
                    size={20}
                    name="corner-down-right-alt"
                    color={theme.colors.grey5}
                  />
                </View>
                <InputToggle
                  testID="toggle-default"
                  title={mod.name}
                  isToggled={mod.isDefault || false}
                  onToggle={() => changeIsDefault(mod)}
                  containerStyle={styles.headerGroup}
                />
                <Text testID="price-modifier" style={styles.cellPrice}>
                  {appendCurrency(
                    getBestPriceOfModifier(mod as ModifierDefault).toFixed(2),
                  )}
                </Text>
              </View>
            ))}
          </View>
        </View>
      )}
      {!isProductAsModifierGroup &&
        showChild &&
        products?.map((product, i) => (
          <View testID="row-product" key={i} style={theme.tables.row}>
            <View style={theme.tables.disclosure}>
              <Icon
                size={20}
                name="corner-down-right-alt"
                color={theme.colors.grey5}
              />
            </View>
            {isCombo ? (
              <Text style={styles.headerGroup}>{product.name}</Text>
            ) : (
              <InputToggle
                testID="toggle-default"
                title={product.name}
                isToggled={product.isDefault || false}
                onToggle={() => changeIsDefault(product)}
                containerStyle={styles.headerGroup}
              />
            )}
            <Text style={styles.cellPrice}>
              {appendCurrency(product.price?.amount.toFixed(2) || '0.00')}
            </Text>
          </View>
        ))}
    </View>
  );
};
