import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

export const styles = StyleSheet.create({
  container: {
    width: 620,
    alignSelf: 'center',
  },
  content: {
    padding: 40,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  body: {
    marginVertical: 40,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  cards: {
    marginTop: 20,
  },
  card: {
    width: 140,
    height: 140,
    marginRight: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  cardTitle: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
    marginVertical: 8,
  },
  cardSubtitle: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
