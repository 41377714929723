import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import ManageFeaturesScreen from './ManageFeaturesScreen';
import { ManageIntegrationsScreen } from '../ManageIntegrations/ManageIntegrationsScreen';

const Tab = createMaterialTopTabNavigator();

const ManageFeatureTabs: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} showBack={false} />
      )}
      initialRouteName="FeaturesTab"
    >
      <Tab.Screen
        name="FeaturesTab"
        component={ManageFeaturesScreen}
        options={{ tabBarLabel: translate('tabNames.features') }}
      />
      <Tab.Screen
        name="IntegrationsTab"
        component={ManageIntegrationsScreen}
        options={{ tabBarLabel: translate('tabNames.integrations') }}
      />
    </Tab.Navigator>
  );
};

export default ManageFeatureTabs;
