import React, { useCallback } from 'react';
import { View } from 'react-native';
import { ProductType, ProfitMargin } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import theme from '../../../../../common/default-theme';
import styles from './ProfitMargins.styles';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';

interface Props {
  index: number;
  profitMargin: ProfitMargin;
  reportingGroups: ProductType[];
  onChange: (prop: string, index: number, value: string) => void;
  onDelete: (index: number) => void;
}

export const ProfitMarginRow: React.FC<Props> = ({
  index,
  profitMargin,
  reportingGroups,
  onChange,
  onDelete,
}) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const groupOptions = reportingGroups.map(productType => ({
    value: productType.id,
    label: productType.name,
  }));

  const onPressDelete = useCallback((): void => {
    const selectedGroup = reportingGroups.find(
      pt => pt.id === profitMargin.productTypeId,
    );
    const selectedReportingGroupName = selectedGroup
      ? `"${selectedGroup.name}"`
      : 'this reporting group';

    showModal(
      <ConfirmationDialog
        title={translate('profitMargins.delete')}
        message={translate('profitMargins.deleteMessage', {
          group: selectedReportingGroupName,
        })}
        onConfirm={() => {
          onDelete(index);
          closeModal();
          showNotification({
            success: true,
            message: translate('common.toastSuccess', {
              entity: translate('profitMargins.profitMargin'),
              action: translate('common.deleted').toLowerCase(),
            }),
          });
        }}
      />,
    );
  }, [
    closeModal,
    index,
    onDelete,
    profitMargin.productTypeId,
    reportingGroups,
    showModal,
    showNotification,
    translate,
  ]);

  if (
    profitMargin.productTypeId &&
    !reportingGroups.some(pt => pt.id === profitMargin.productTypeId)
  )
    return null;

  return (
    <View testID="row-margin" style={theme.tables.row}>
      <TreatPicker
        testID="select-type"
        options={groupOptions}
        selectedValue={profitMargin.productTypeId}
        onValueChange={onChange.bind(null, 'productTypeId', index)}
        containerStyle={styles.cellGroup}
      />
      <InputText
        testID="input-margin"
        label="%"
        value={`${profitMargin.margin || 0}`}
        placeholder="0.00"
        keyboardType="numeric"
        onChangeText={onChange.bind(null, 'margin', index)}
        alignText="right"
        containerStyle={styles.cellMargin}
        onBlur={() =>
          onChange(
            'margin',
            index,
            (parseFloat(profitMargin.margin.toString()) || 0).toFixed(2),
          )
        }
      />
      <ButtonIcon
        icon="trash-alt"
        type="negativeLight"
        onPress={onPressDelete}
      />
    </View>
  );
};
