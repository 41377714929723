import { useTranslation } from '@oolio-group/localization';
import {
  AllergensKey,
  NutrientUnit,
  NutrientUnitKey,
  Product,
  ProductAllergens,
  Variant,
} from '@oolio-group/domain';
import React, { useCallback, useMemo } from 'react';
import { Image, ScrollView } from 'react-native';
import { View, Text, KeyboardAvoidingView } from 'react-native';
import styles from './ProductAllergens.style';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import { isInventoryBeingTracked } from '@oolio-group/order-helper';
import { useNotification } from '../../../hooks/Notification';

export interface ProductAllergensProps {
  product: Product;
  currentStoreId: string;
  printRecipe: (product: Product) => void;
  variant?: Variant;
  addToCart: () => void;
  onClose: () => void;
}

const ProductAllergensModal: React.FC<ProductAllergensProps> = ({
  product,
  variant = {} as Variant,
  currentStoreId = '',
  printRecipe,
  addToCart,
  onClose,
}: ProductAllergensProps) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const printProductRecipe = useCallback(() => {
    if (!product.printerProfiles?.length) {
      showNotification({
        message: translate('printing.missingPrinterProfileForProduct'),
        info: true,
      });
      return;
    }
    printRecipe(product);
  }, [printRecipe, product, showNotification, translate]);

  const productInfo = useMemo(() => {
    const name = product.name;
    const imageUrl = product.imageUrl || variant?.imageUrl;
    const recipe = product.recipe || variant?.recipe;
    const description = product.description || variant?.description;
    const isTracked = isInventoryBeingTracked(
      product.storesInventory,
      currentStoreId,
    );
    const availableQty =
      product.storesInventory?.[currentStoreId]?.availableQuantity || 0;

    const allergens =
      (product.allergens?.length ? product.allergens : variant?.allergens) ||
      [];
    const tags =
      (product.dietaryTags?.length
        ? product.dietaryTags
        : variant?.dietaryTags) || [];

    const nutrientInfo =
      (product.nutrientInfo?.length
        ? product.nutrientInfo
        : variant?.nutrientInfo) || [];
    return {
      name,
      imageUrl,
      recipe,
      description,
      allergens,
      tags,
      nutrientInfo,
      isTracked,
      availableQty,
    };
  }, [product, variant, currentStoreId]);

  const allergensText = useMemo(() => {
    return productInfo.allergens?.length
      ? translate('modal.containsAllergens', {
          allergens: productInfo.allergens
            .map((a: AllergensKey) => ProductAllergens[a])
            .join(', '),
        })
      : '';
  }, [productInfo.allergens, translate]);

  const dietaryTagsText = useMemo(() => {
    return productInfo.tags?.length
      ? productInfo.tags.map(t => t.name).join(', ')
      : translate('modal.emptyDietaryTags');
  }, [productInfo.tags, translate]);

  const nutrientValue = useMemo(() => {
    const energies = productInfo.nutrientInfo.filter(
      n =>
        n.name === 'ENERGY' &&
        (['KCAL', 'KJ', 'CAL'] as Array<NutrientUnitKey>).includes(n.unit),
    );
    return energies
      .map(e => e.value + NutrientUnit[e.unit as NutrientUnitKey])
      .join(' / ');
  }, [productInfo.nutrientInfo]);

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={styles.keyboardAvoidingView}
    >
      <View style={styles.container}>
        <View style={styles.row}>
          <View style={styles.allergensSection}>
            <Text style={styles.productName}>{productInfo.name}</Text>
            <Text style={styles.allergensName}>{allergensText}</Text>
            <Text style={styles.dietaryTagName}>{dietaryTagsText}</Text>
            <Text style={styles.nutrientName}>{nutrientValue}</Text>
          </View>
          <View
            style={
              productInfo.imageUrl
                ? styles.productImage
                : styles.productImagePlaceholder
            }
          >
            {productInfo.isTracked && (
              <View style={styles.productCountContainer}>
                <Text style={styles.productCountText}>
                  {productInfo.availableQty}
                </Text>
              </View>
            )}
            {productInfo.imageUrl ? (
              <Image
                source={{
                  uri: productInfo.imageUrl,
                }}
                style={styles.productImage}
              ></Image>
            ) : (
              <Icon name="image-times" color={theme.colors.grey4} size={32} />
            )}
          </View>
        </View>

        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <ScrollView style={{ height: 250 }}>
          <View style={styles.allergensInfo}>
            <Text style={styles.textName}>
              {translate('productSettings.description')}
            </Text>
            <Text style={styles.textInfo}>
              {productInfo.description || '-'}
            </Text>
          </View>

          <View style={styles.allergensInfo}>
            <Text style={styles.textName}>
              {translate('productSettings.recipe')}
            </Text>

            {productInfo.recipe ? (
              productInfo.recipe.split('\n').map((r, i) => {
                return (
                  <Text key={i} style={styles.textInfo}>
                    {r || ' '}
                  </Text>
                );
              })
            ) : (
              <Text>-</Text>
            )}
          </View>
        </ScrollView>

        <View style={styles.btnContainer}>
          <TreatButton
            label={translate('button.dismiss')}
            type="cancel"
            onPress={onClose}
          />
          <TreatButton
            label={translate('button.printRecipe')}
            type="neutralLight"
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginLeft: 10 }}
            disabled={!productInfo.recipe}
            onPress={printProductRecipe}
          />
          <TreatButton
            label={translate('cart.addToCart')}
            type="positive"
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginLeft: 10, width: 140 }}
            onPress={addToCart}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default ProductAllergensModal;
