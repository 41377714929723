/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import { ProductPricingRowProps } from './Products.type';
import { useCurrency } from '@oolio-group/localization';
import theme from '../../../../../../common/default-theme';
import styles from '../../PriceLists.styles';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

export const ProductsRow: React.FC<ProductPricingRowProps> = ({
  productPricing,
  defaultPricing,
  isDefault,
  onChange,
  onDeleteRow,
  rowIndex,
}) => {
  const { appendCurrency, currencySymbol } = useCurrency();

  const prices = useMemo(() => {
    const defaultPrice =
      (defaultPricing?.sellingPrice && defaultPricing?.sellingPrice.amount) ||
      0;
    const productPrice =
      productPricing?.sellingPrice && productPricing?.sellingPrice.amount;
    const change = (productPrice || 0) - defaultPrice;
    return {
      defaultPrice: defaultPrice,
      productPrice: productPrice,
      change: change.toFixed(2),
    };
  }, [defaultPricing, productPricing]);

  const getPriceColor = (price: string) => {
    const delta = Number(price);
    if (delta === 0) {
      return theme.colors.grey5;
    } else if (delta < 0) {
      return theme.colors.states.negative;
    } else {
      return theme.colors.black1;
    }
  };

  const handleOnChangePrice = useCallback(
    (text: string) => {
      const validatedText = text.replace(/[^0-9.]/g, '');
      onChange(productPricing.product.id, 'sellingPrice', validatedText);
    },
    [onChange, productPricing.product.id],
  );

  return (
    <View
      testID="row-product"
      key={rowIndex}
      style={[theme.tables.row, { minHeight: 54 }]}
    >
      {!isDefault && (
        <InputToggle
          testID="toggle-product"
          isToggled={productPricing?.isSelected || false}
          onToggle={() =>
            onChange(
              productPricing.product.id,
              'isSelected',
              !productPricing?.isSelected,
            )
          }
        />
      )}
      <Text
        style={[styles.cellProductText, { paddingLeft: isDefault ? 12 : 0 }]}
      >
        {productPricing.product?.name}
      </Text>
      <View style={styles.cellPrice}>
        <Text
          testID="price-default"
          style={[
            styles.cellPriceText,
            isDefault && { textAlign: 'right', paddingRight: 20 },
          ]}
        >
          {appendCurrency(`${prices.defaultPrice.toFixed(2) || '0.00'}`)}
        </Text>
      </View>
      {!isDefault && (
        <>
          <InputText
            testID="input-price"
            label={currencySymbol}
            value={String(prices.productPrice)}
            placeholder="0.00"
            onChangeText={handleOnChangePrice}
            maxLength={7}
            alignText="right"
            keyboardType="decimal-pad"
            containerStyle={styles.cellPrice}
          />
          <View style={styles.cellPrice}>
            <Text
              testID="price-delta"
              style={[
                styles.cellPriceText,
                {
                  color: getPriceColor(prices.change),
                },
              ]}
            >
              {appendCurrency(`${prices.change || '0.00'}`)}
            </Text>
          </View>
          <ButtonIcon
            testID="btn-delete"
            icon="times"
            type="negativeLight"
            onPress={() => onDeleteRow(productPricing.product.id)}
            containerStyle={{ marginLeft: 10 }}
          />
        </>
      )}
    </View>
  );
};
