import {
  Order,
  OrderStatus,
  IntegrationPartner,
  IntegrationApps,
} from '@oolio-group/domain';
import { useCallback, useRef } from 'react';
import { useOrders } from './useOrders';
import { useOnlineOrderEvents } from './useOnlineOrderEvents';
import { getItem } from '../../../storage/interface';
import { INTEGRATION_SETTINGS_KEY } from '../../../types/Common';

export interface UseAutoCompleteOrders {
  startTimer: () => Promise<void>;
  stopTimer: () => void;
}

export const useAutoCompleteOrders = (): UseAutoCompleteOrders => {
  const autoCompleteIntervalRef = useRef<number>();
  const { returnOrdersFromCache } = useOrders();
  const { completeOrder } = useOnlineOrderEvents();

  const isOolioStoreOrder = useCallback((order: Order) => {
    return order.source === IntegrationApps.OOLIO_STORE;
  }, []);

  const stopTimer = useCallback(() => {
    autoCompleteIntervalRef.current &&
      clearInterval(autoCompleteIntervalRef.current);
  }, []);

  const startTimer = useCallback(async () => {
    if (autoCompleteIntervalRef.current) return;
    const result: IntegrationPartner[] | undefined = await getItem(
      INTEGRATION_SETTINGS_KEY,
    );
    const integrationPartner = result?.find(
      setting => setting.appName === IntegrationApps.OOLIO_STORE,
    );
    stopTimer();
    if (
      integrationPartner?.preferences?.onlineOrdering?.autoCompletePeriod &&
      integrationPartner?.preferences?.onlineOrdering?.autoCompletePeriod !== 0
    ) {
      autoCompleteIntervalRef.current = setInterval(async () => {
        const orders = returnOrdersFromCache(OrderStatus.IN_PROGRESS, true);
        console.warn('auto complete run at', new Date(Date.now()).getMinutes());
        const paidOrders = orders?.filter(
          order =>
            isOolioStoreOrder(order) &&
            order.status === OrderStatus.IN_PROGRESS &&
            order.amountDue !== undefined &&
            order.amountDue <= 0,
        );
        if (paidOrders?.length) {
          for (let i = 0; i < paidOrders.length; i++) {
            const order = paidOrders[i];
            await completeOrder(order);
          }
        }
      }, integrationPartner?.preferences?.onlineOrdering?.autoCompletePeriod * 60 * 1000);
    }
    addEventListener('unload', () => {
      stopTimer();
    });
  }, [completeOrder, isOolioStoreOrder, returnOrdersFromCache, stopTimer]);

  return { startTimer, stopTimer };
};
