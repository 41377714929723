import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 620,
    alignSelf: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    ...theme.shadow.strong,
  },
  title: {
    paddingTop: 40,
    paddingHorizontal: 42,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.negative,
  },
  subtitleText: {
    fontSize: 14,
    lineHeight: 26,
    paddingTop: 12,
    color: theme.colors.black1,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 30,
    paddingHorizontal: 30,
    backgroundColor: theme.colors.white,
  },
  btnCloseShift: {
    marginHorizontal: 5,
  },
  btnOpenOrders: {
    marginHorizontal: 5,
  },
  tableBody: {
    marginBottom: 20,
    borderTopWidth: 0.5,
    borderTopColor: theme.colors.grey3,
    width: theme.layoutWidth.s,
  },
  cellOrder: {
    width: 440,
    marginLeft: 10,
  },
  closeShiftRowActionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  closeShiftTextMessage: {
    color: theme.colors.blue,
    fontSize: theme.fontSize.m,
  },
  closeShiftArrowSpace: {
    marginLeft: 10,
  },
});

export default styles;
