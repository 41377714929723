import { ResourceOperation } from '@oolio-group/domain';
import { PermissionTreeNode } from '../../../../../state/jobRoleSettingsUtility';
import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PermissionGroupCheckBox from './PermissionGroupCheckBox';
import PermissionCheckBox from './PermissionCheckBox';
import theme from '../../../../../common/default-theme';

interface PermissionDetailProps {
  level?: number;
  permission: PermissionTreeNode;
}
const PermissionDetail: FC<PermissionDetailProps> = ({ level, permission }) => {
  return (
    <View style={styles.container}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={[styles.titleView, { paddingLeft: level === 2 ? 12 : 0 }]}>
        <Text style={styles.title} testID="permissionTitle">
          {permission.label}
        </Text>
        {!!permission.description && (
          <Text style={styles.subtitle} testID="permissionDescription">
            {permission.description}
          </Text>
        )}
      </View>
      <View style={styles.checkboxes}>
        <Text>{permission.isPermissionGroup}</Text>
        {permission.isPermissionGroup && (
          <PermissionGroupCheckBox
            permissionGroup={permission}
            operation={permission.operation as boolean}
          />
        )}
        {!permission.isPermissionGroup &&
        !Array.isArray(permission.operation) ? (
          <PermissionCheckBox permission={permission} />
        ) : (
          permission.operation &&
          Array.isArray(permission.operation) &&
          (['view'] as ResourceOperation[]).map((operation, i) => {
            if (
              (permission.operation as ResourceOperation[])?.includes(operation)
            ) {
              return (
                <PermissionCheckBox
                  key={permission.id + operation}
                  permission={permission}
                  operation={operation}
                />
              );
            }
            return (
              <View key={permission.id + i} style={styles.toggleContainer} />
            );
          })
        )}
      </View>
    </View>
  );
};

export default PermissionDetail;

const styles = StyleSheet.create({
  container: {
    minHeight: 62,
    paddingLeft: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  titleView: {
    flex: 1,
    paddingRight: 20,
    paddingVertical: 12,
  },
  title: {
    fontSize: 14,
    color: theme.colors.black1,
  },
  subtitle: {
    fontSize: 12,
    marginTop: 6,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
  checkboxes: {
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  permissions: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.purple,
  },
  toggleContainer: {
    width: 60,
    height: 38,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
