import React, { useCallback, useState } from 'react';
import { View, ViewStyle, ScrollView, StyleSheet } from 'react-native';
import {
  useCurrency,
  getCurrencyOptions,
  useLocalization,
} from '@oolio-group/localization';
import InputText from '../../Inputs/InputText';

export interface DenominationProps {
  index: number;
  count: string;
  currency: string;
  onChange?: (currency: string, value: string) => void;
}

export interface DenominationInputProps {
  options?: Record<string, string>;
  onChange?: (value: string) => void;
  containerStyle?: ViewStyle | ViewStyle[];
}

const Denomination: React.FC<DenominationProps> = ({
  index,
  count,
  currency,
  onChange,
}) => {
  const { formatCurrency } = useCurrency();
  const [value, setValue] = useState(count);

  const onChangeValue = useCallback(
    (val: string) => {
      setValue(val);
      onChange && onChange(currency, val);
    },
    [onChange, currency],
  );

  return (
    <InputText
      testID="input-denomination"
      title={formatCurrency(+currency)}
      value={value}
      placeholder="0"
      keyboardType="number-pad"
      onChangeText={onChangeValue}
      containerStyle={[
        styles.input,
        // eslint-disable-next-line react-native/no-inline-styles
        { marginRight: (index + 1) % 3 === 0 ? 0 : 16 },
      ]}
    />
  );
};

const DenominationInput: React.FC<DenominationInputProps> = ({
  onChange,
  containerStyle,
}: DenominationInputProps) => {
  const { country } = useLocalization();

  const initialOptions = getCurrencyOptions(country).reduce(
    (acc, x) => ({ ...acc, [x]: '0' }),
    {},
  );

  const [options, setOptions] = useState<Record<string, string>>({
    ...initialOptions,
  });

  const onChangeCurrencyValue = useCallback(
    (prop: string, value: string) => {
      const updatedOptions = { ...options, [prop]: value };
      setOptions(updatedOptions);
      const totalCounted = Object.keys(updatedOptions).reduce(
        (acc, x) => acc + +x * +updatedOptions[x],
        0,
      );
      onChange && onChange(totalCounted.toString());
    },
    [onChange, options],
  );

  return (
    <ScrollView>
      <View style={[styles.container, containerStyle]}>
        {Object.keys(options)
          .sort((a, b) => +b - +a)
          ?.map((denomination, i) => {
            return (
              <Denomination
                key={i}
                index={i}
                currency={denomination}
                count={options[denomination]}
                onChange={onChangeCurrencyValue}
              />
            );
          })}
      </View>
    </ScrollView>
  );
};

export default DenominationInput;

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingTop: 10,
  },
  input: {
    width: '30%',
    marginBottom: 10,
  },
});
