import { useQuery } from '@apollo/client/react/hooks';
import React, { useMemo, useState } from 'react';
import { useModal } from '@oolio-group/rn-use-modal/lib';
import { useTranslation } from '@oolio-group/localization';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import { GET_TAGS_QUERY } from '../../../../../graphql/settings';
import { ProductAllergens, Tag, TagType } from '@oolio-group/domain';

export interface AssignTagsModalProps {
  onSubmit: (dietary: string[], allergens: string[]) => void;
  isAllergensEnabled?: boolean;
}

const AssignTagsModal: React.FC<AssignTagsModalProps> = ({
  onSubmit,
  isAllergensEnabled,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const { data: tagsResponse } = useQuery(GET_TAGS_QUERY, {
    variables: { filter: { tagType: TagType.DIETARY } },
    fetchPolicy: 'cache-and-network',
  });

  const [tags, setSelectedTags] = useState({
    dietary: [],
    allergens: [],
  });

  const dietaryOptions = useMemo(() => {
    if (tagsResponse?.tags?.length) {
      return tagsResponse?.tags.map((tag: Tag) => ({
        label: tag.name,
        value: tag.id,
      }));
    } else return [];
  }, [tagsResponse?.tags]);

  const getAllergensOptions = () => {
    return Object.keys(ProductAllergens).map(key => ({
      label: ProductAllergens[key as keyof typeof ProductAllergens],
      value: key,
    }));
  };

  const setSelectedValue = (key: 'dietary' | 'allergens', values: string[]) => {
    setSelectedTags({ ...tags, [key]: values });
  };

  const onConfirm = () => {
    if (tags.allergens?.length || tags.dietary?.length) {
      onSubmit(tags.dietary, tags.allergens);
    } else {
      closeModal();
    }
  };

  return (
    <TreatModal
      title={translate('productBulkOperations.assignTagsToProducts')}
      onConfirm={{
        label: translate('productBulkOperations.assignTags'),
        action: onConfirm,
      }}
      onDismiss={{ action: closeModal }}
    >
      <SelectMultiple
        testID="assign-dietary-tags"
        title={translate('productSettings.dietaryTags')}
        options={dietaryOptions}
        selectedValues={tags.dietary}
        onValueChange={values => setSelectedValue('dietary', values)}
      />

      {isAllergensEnabled && (
        <SelectMultiple
          testID="assign-allergens"
          title={translate('productSettings.allergenLabels')}
          options={getAllergensOptions()}
          selectedValues={tags.allergens}
          onValueChange={values => setSelectedValue('allergens', values)}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginTop: 20 }}
        />
      )}
    </TreatModal>
  );
};

export default AssignTagsModal;
