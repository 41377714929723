import { gql } from '@apollo/client';

const modifierGroupQueryFragment = `
  id
  name
  kitchenPrintLabel
  selectionLimit {
    min
    max
  }
  maxSelectionPerOption
  modifiers{
    id
    name
    price {
      amount
      currency
    }
    priority
    isDefault
  }
  products {
    id
    name
    price {
      amount
      currency
    }
    priority
    isDefault
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;

export const getModifierGroupQuery = (fragment?: string) => {
  const GET_MODIFIER_GROUPS_QUERY = gql`
  query modifierGroupsData {
    modifierGroups {
      ${fragment || modifierGroupQueryFragment}
    }
  }
`;

  const CREATE_MODIFIER_GROUPS = gql`
  mutation createModifierGroups($input: [CreateModifierGroupInput!]!) {
    createModifierGroups(input: $input) {
      ${fragment || modifierGroupQueryFragment}
    }
  }
`;

  const UPDATE_MODIFIER_GROUPS = gql`
  mutation updateModifierGroups($input: [UpdateModifierGroupInput!]!) {
    updateModifierGroups(input: $input) {
      ${fragment || modifierGroupQueryFragment}
    }
  }
`;

  const DELETE_MODIFIER_GROUPS = gql`
    mutation deleteModifierGroups($ids: [ID!]!) {
      deleteModifierGroups(ids: $ids)
    }
  `;

  const CLONE_MODIFIER_GROUP = gql`
mutation cloneModifierGroup($input: CloneModifierGroupInput!) {
  cloneModifierGroup(input: $input){
    ${fragment || modifierGroupQueryFragment}
  }
}
`;
  const GET_MODIFIER_GROUP_QUERY = gql`
  query modifierGroupData($id: ID!) {
    modifierGroup(id: $id) {
      ${fragment || modifierGroupQueryFragment}
    }
  }
`;
  return {
    GET_MODIFIER_GROUPS_QUERY,
    CREATE_MODIFIER_GROUPS,
    GET_MODIFIER_GROUP_QUERY,
    DELETE_MODIFIER_GROUPS,
    UPDATE_MODIFIER_GROUPS,
    CLONE_MODIFIER_GROUP,
  };
};

export const MODIFIER_GROUPS_AS_OPTIONS = `
  id
  name
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
`;
