import React from 'react';
import { View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../../../../common/default-theme';
import Section from '../../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../../..//components/Shared/Inputs/InputToggle';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import { Device } from '@oolio-group/domain';

interface TokenIdentifiersProps {
  form: Device;
  onChangeIsActiveToken: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeTokenSettings: (prop: string, value: any) => void;
}

export const TokenIdentifiers: React.FC<TokenIdentifiersProps> = ({
  form,
  onChangeIsActiveToken,
  onChangeTokenSettings,
}) => {
  const { translate } = useTranslation();
  return (
    <Section title={translate('backOfficeDevices.orderIdentifier')}>
      <>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-tokenNumbers"
            type="switch"
            isToggled={form?.isTokenNumberActive || false}
            onToggle={onChangeIsActiveToken.bind(
              null,
              !form.isTokenNumberActive,
            )}
            title={translate('backOfficeDevices.orderTokenNumbers')}
            subtitle={translate('backOfficeDevices.orderTokenNumbersDesc')}
            containerStyle={theme.forms.inputFluid}
          />
        </View>

        {form?.isTokenNumberActive ? (
          <>
            <View style={theme.forms.row}>
              <InputText
                testID="input-tokenStartRange"
                title={translate('backOfficeDevices.tokenRange')}
                value={(
                  form?.tokenSettings?.tokenRange?.start || '0'
                ).toString()}
                placeholder={'000'}
                onChangeText={value =>
                  onChangeTokenSettings(
                    'start',
                    formatToDigitsStr(value, false, undefined, 0),
                  )
                }
                containerStyle={theme.forms.inputHalf}
                label={translate('backOfficeDevices.start')}
              />

              <InputText
                testID="input-tokenEndRange"
                title={translate('backOfficeDevices.tokenRange')}
                value={(
                  form?.tokenSettings?.tokenRange?.end || '0'
                )?.toString()}
                placeholder={'000'}
                onChangeText={value =>
                  onChangeTokenSettings(
                    'end',
                    formatToDigitsStr(value, false, undefined, 0),
                  )
                }
                containerStyle={theme.forms.inputHalf}
                label={translate('backOfficeDevices.end')}
              />
            </View>

            <View style={theme.forms.row}>
              <InputToggle
                testID="toggle-resetTokenOnShiftClosure"
                title={translate(
                  'backOfficeDevices.resetOrderTokenNumberOnShiftClosure',
                )}
                isToggled={
                  form?.tokenSettings?.resetTokenNumberOnShiftClosure || false
                }
                onToggle={() =>
                  onChangeTokenSettings(
                    'resetTokenNumberOnShiftClosure',
                    !form?.tokenSettings?.resetTokenNumberOnShiftClosure,
                  )
                }
                containerStyle={theme.forms.inputFluid}
              />
            </View>

            <View style={theme.forms.row}>
              <InputToggle
                testID="toggle-printTokenOnReceipt"
                title={translate('backOfficeDevices.onlyPrintTokenOnReceipt')}
                isToggled={
                  form?.tokenSettings?.onlyPrintTokenOnReceipts || false
                }
                onToggle={() =>
                  onChangeTokenSettings(
                    'onlyPrintTokenOnReceipts',
                    !form?.tokenSettings?.onlyPrintTokenOnReceipts,
                  )
                }
                containerStyle={theme.forms.inputFluid}
              />
            </View>
          </>
        ) : null}
      </>
    </Section>
  );
};
