import { useMutation } from '@apollo/client/react/hooks';
import { CreateCustomerDisplayInput } from '@oolio-group/domain';
import { useCallback } from 'react';
import {
  CREATE_CUSTOMER_DISPLAY,
  FORGET_CUSTOMER_DISPLAY,
} from '../../graphql/customerDisplay';
import { noopHandler } from '../../utils/errorHandlers';
import { useSession } from './useSession';

export function useCustomerDisplay() {
  const [session, setSession] = useSession();
  const [createCdsOnDevice, createdCDS] = useMutation(CREATE_CUSTOMER_DISPLAY, {
    onError: noopHandler,
    onCompleted: data => {
      setSession({
        ...session,
        device: {
          ...session.device,
          customerDisplay: data.createCustomerDisplayOnDevice,
        },
      });
    },
  });

  const onCreateCdsOnDevice = useCallback(
    async (input: CreateCustomerDisplayInput) => {
      return await createCdsOnDevice({
        variables: {
          input: {
            deviceId: input.deviceId,
            name: input.name,
            deviceUuid: input.deviceUuid,
            isPaired: input.isPaired,
          } as CreateCustomerDisplayInput,
        },
      });
    },
    [createCdsOnDevice],
  );

  const [forgetCdsOnDevice, forgetCdsResult] = useMutation(
    FORGET_CUSTOMER_DISPLAY,
    {
      onError: noopHandler,
      onCompleted: () => {
        setSession({
          ...session,
          device: {
            ...session.device,
            customerDisplay: undefined,
          },
        });
      },
    },
  );

  const onForgetCdsOnDevice = useCallback(
    async (input: { deviceId: string }) => {
      return await forgetCdsOnDevice({
        variables: {
          input: {
            deviceId: input.deviceId,
          },
        },
      });
    },
    [forgetCdsOnDevice],
  );

  return {
    createCdsOnDevice: onCreateCdsOnDevice,
    error: createdCDS?.error || forgetCdsResult?.error,
    forgetCdsOnDevice: onForgetCdsOnDevice,
  };
}
