import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { isWeb } from '../../../../common/theme';

export const styles = StyleSheet.create({
  iconButtonStyle: {
    position: 'absolute',
    left: 15,
  },
  keyboardAvoidingViewStyle: {
    flex: 1,
    justifyContent: 'center',
  },
  modalStyle: {
    width: 320,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  containerStyle: {
    width: 320,
    alignSelf: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    paddingHorizontal: 25,
    ...theme.shadow.strong,
  },
  buttonContainerStyle: {
    marginBottom: 20,
  },
  inputContainerStyle: {
    marginVertical: isWeb ? 20 : 10,
    borderRadius: theme.radius.s,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    paddingBottom: 10,
  },
  buttonStyle: {
    marginTop: 20,
  },
  emailTextInputStyle: {
    width: '100%',
    marginVertical: 10,
  },
  title: {
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    marginRight: 30,
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  inputDate: {
    marginBottom: 20,
  },
  presets: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 1,
  },
  btnPreset: {
    width: 130,
    marginBottom: 10,
  },
});
