import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import DevicesScreen from '../../StoreSettings/Devices/Devices';
import { DeviceProfiles } from '../../StoreSettings/DeviceProfiles/DeviceProfiles';
import Printers from '../../StoreSettings/Printers/Printers';
import { Checkout } from '../../StoreSettings/Checkout/Checkout';
import { ProfitMargins } from '../../StoreSettings/ProfitMargins/ProfitMargins';
import { Details } from '../../StoreSettings/Details/Details';
import Adjustments from '../Adjustments/Adjustments';
import { ManageCashScreen } from '../ManageCash/ManageCash';
import { useSession } from '../../../../../hooks/app/useSession';
import { SectionsScreen } from '../Sections/SectionsScreen';
// import { AccountSales } from '../AccountSales/AccountSales';
import { translate } from '@oolio-group/localization';
import { useCheckFeatureEnabled } from '../../../../../hooks/app/features/useCheckFeatureEnabled';
import { AdjustmentType, FeatureIDs } from '@oolio-group/domain';
import KitchenDisplays from '../../StoreSettings/KitchenDisplay/KitchenDisplays';
import { useReactiveVar } from '@apollo/client';
import { isAdvanceDiscountEnabledVar } from '../../../../../state/cache';

const Tab = createMaterialTopTabNavigator();
const MyStoreSettingsTabs: React.FC = () => {
  const [session] = useSession();
  const isAdvanceDiscountFeatureEnabled = useReactiveVar(
    isAdvanceDiscountEnabledVar,
  );

  const params = {
    storeId:
      session.currentStore?.id ||
      (session?.user?.venues && session?.user?.venues[0].stores[0].id),
    venueId:
      session.currentVenue?.id ||
      (session?.user?.venues && session?.user?.venues[0].id),
    type: undefined,
  };

  const isFeatureEnabled = useCheckFeatureEnabled();
  const isTableFeatureEnabled = isFeatureEnabled(
    FeatureIDs.TABLE_MANAGEMENT,
    params.venueId,
  );

  const isKitchenDisplayEnabled = isFeatureEnabled(
    FeatureIDs.KDS,
    params.venueId,
  );

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} showBack={false} />
      )}
      initialRouteName={'Details'}
    >
      <Tab.Screen name="Details" component={Details} initialParams={params} />
      <Tab.Screen
        name="Devices"
        component={DevicesScreen}
        initialParams={params}
      />
      <Tab.Screen
        name={translate('storesSettings.tabNames.deviceProfiles')}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.deviceProfiles'),
        }}
        component={DeviceProfiles}
        initialParams={params}
      />
      <Tab.Screen name="Printers" component={Printers} initialParams={params} />
      {isKitchenDisplayEnabled && (
        <Tab.Screen
          name="KitchenDisplays"
          options={{ tabBarLabel: 'Kitchen Display' }}
          component={KitchenDisplays}
          initialParams={params}
        />
      )}
      {isTableFeatureEnabled && (
        <Tab.Screen
          name="SectionManagement"
          component={SectionsScreen}
          options={{ tabBarLabel: 'Sections & Tables' }}
          initialParams={params}
        />
      )}
      {!isAdvanceDiscountFeatureEnabled ? (
        <Tab.Screen
          name="Adjustments"
          component={Adjustments}
          initialParams={params}
        />
      ) : (
        <>
          <Tab.Screen
            name="Discounts"
            component={Adjustments}
            initialParams={{ ...params, type: AdjustmentType.DISCOUNT }}
          />
          <Tab.Screen
            name="Surcharges"
            component={Adjustments}
            initialParams={{ ...params, type: AdjustmentType.SURCHARGE }}
          />
        </>
      )}
      <Tab.Screen
        name="CashManagement"
        component={ManageCashScreen}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.manageCash'),
        }}
        initialParams={params}
      />
      <Tab.Screen name="Checkout" component={Checkout} initialParams={params} />
      <Tab.Screen
        name="ProfitMargins"
        options={{
          tabBarLabel: translate('storesSettings.tabNames.profitMargins'),
        }}
        component={ProfitMargins}
        initialParams={params}
      />
      {/* <Tab.Screen
        name="AccountSales"
        options={{
          tabBarLabel: translate('storesSettings.tabNames.customerAccount'),
        }}
        component={AccountSales}
        initialParams={params}
      /> */}
    </Tab.Navigator>
  );
};

export default MyStoreSettingsTabs;
