/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { DeviceData } from '../Devices';
import { NO_DATA } from '@oolio-group/domain';
import {
  DEVICE_MODE_COLOR_MAPPING,
  DEVICE_MODE_MAPPING,
} from '../../../../../../types/Common';
import styles from '../Devices.styles';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';
import Sticker from '../../../../../../components/Shared/Sticker/Sticker';

interface Props {
  device: DeviceData;
  storeId: string;
}

const DevicesRow: React.FC<Props> = ({ device, storeId }) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();

  const onPressRow = useCallback(() => {
    navigation.navigate('DeviceSettings', {
      deviceId: device.id,
      storeId,
    });
  }, [device.id, navigation, storeId]);

  const name = `${device.name} ${
    device.isCurrent ? `(${translate('backOfficeDevices.currentLabel')})` : ''
  }`;

  const hasVersion = Boolean(device.appVersion);

  return (
    <TouchableOpacity
      testID="row-device"
      onPress={onPressRow}
      style={[
        theme.tables.row,
        {
          backgroundColor: device.isCurrent
            ? theme.colors.blueLightest
            : theme.colors.white,
        },
      ]}
    >
      <View
        testID="mode-color"
        style={[
          styles.rowLight,
          { backgroundColor: DEVICE_MODE_COLOR_MAPPING[device.mode] },
        ]}
      />
      <Text numberOfLines={1} style={styles.cellName}>
        {name}
      </Text>
      <Text numberOfLines={1} style={styles.cellType}>
        {device.mode ? DEVICE_MODE_MAPPING[device.mode] : NO_DATA}
      </Text>
      <Text numberOfLines={1} style={styles.cellProfile}>
        {device.deviceProfile.name}
      </Text>
      <Text
        numberOfLines={1}
        style={[
          styles.cellVersion,
          { color: hasVersion ? theme.colors.black1 : theme.colors.grey4 },
        ]}
      >
        {hasVersion ? device.appVersion : NO_DATA}
      </Text>
      <Sticker
        testID="sticker-status"
        label={
          device.isPaired
            ? translate('backOfficeDevices.paired')
            : translate('backOfficeDevices.unpaired')
        }
        type={device.isPaired ? 'positiveLight' : 'cancel'}
      />
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export default DevicesRow;
