import React, { useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';

interface AddPrinterProfileProps {
  onSubmit: (selectedPrinterProfiles: string[]) => void;
  printerProfileOptions: { label: string; value: string }[];
  countOfproducts: number;
}

export const AddPrinterProfile: React.FC<AddPrinterProfileProps> = ({
  onSubmit,
  printerProfileOptions,
}: AddPrinterProfileProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const defaultProfile = printerProfileOptions?.[0]?.value || '';
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([
    defaultProfile,
  ]);

  return (
    <TreatModal
      title={translate('productBulkOperations.assignPrnterProfile')}
      onConfirm={{
        label: translate('productBulkOperations.assign'),
        action: () => onSubmit(selectedProfiles),
      }}
      onDismiss={{ action: closeModal }}
    >
      <SelectMultiple
        testID="select-profiles"
        title={translate('productSettings.printerProfile')}
        options={printerProfileOptions}
        selectedValues={selectedProfiles}
        onValueChange={val => setSelectedProfiles(val)}
      />
    </TreatModal>
  );
};
