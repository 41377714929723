import {
  Order,
  OrderStatus,
  LOYALTY_REWARD_TYPE_MAP,
} from '@oolio-group/domain';
import { Resolvers, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import { GET_ORDER, GET_ORDERS } from '../hooks/app/orders/graphql';
import isEmpty from 'lodash/isEmpty';
import { NUMBER_LIMIT_OF_CACHED_ORDERS } from '../constants';
import { orderBy } from 'lodash';
import { mapTableToGraphObject } from '@oolio-group/order-helper';

export const GET_ALL_ORDER_TYPES = gql`
  query getOrderTypeOptions {
    orderTypes {
      id
      name
      code
    }
  }
`;

export const EMAIL_ORDER_RECEIPT = gql`
  mutation emailOrderReceipt($input: OrderReceiptInput!) {
    emailOrderReceipt(input: $input)
  }
`;

/**
 * Adds typename property to all objects
 * @param order
 */
export const mapOrderToGraphObject = (order: Order) => ({
  ...order,
  id: order.id,
  __typename: 'Order',
  roundingAmount: order.roundingAmount || 0,
  surchargeAmount: order?.surchargeAmount || 0,
  customer: order.customer
    ? {
        ...order.customer,
        type: 'id',
        id: order.customer.id,
        customerAccountDetails: isEmpty(order?.customer?.customerAccountDetails)
          ? null
          : order?.customer?.customerAccountDetails,
      }
    : null,
  orderItems: (order.orderItems || [])
    .filter(i => !i?.parentCombo?.id)
    .map(item => ({
      ...item,
      id: item.id,
      notes: item.notes || null,
      comboItems: item.comboItems?.length
        ? item.comboItems.map(comboItem => ({
            ...comboItem,
            id: comboItem.id,
            variant: comboItem.variant
              ? {
                  type: 'id',
                  id: item.variant?.id,
                  __typename: 'Variant',
                }
              : null,
            status: comboItem.status || item.status,
            product: {
              id: comboItem.product?.id,
              __typename: 'Product',
            },
            course: comboItem?.course?.id
              ? {
                  id: comboItem?.course?.id,
                  __typename: 'Course',
                }
              : null,
            __typename: 'OrderItem',
          }))
        : [],
      surchargeAmount: item.surchargeAmount || 0,
      __typename: 'OrderItem',
      product: item.product
        ? {
            type: 'id',
            id: item.product.id,
            __typename: 'Product',
          }
        : null,
      variant: item.variant
        ? {
            type: 'id',
            id: item.variant?.id,
            __typename: 'Variant',
          }
        : null,
      discounts: item.discounts
        ? item.discounts.map(x => ({
            ...x,
            discount: { ...x, type: 'id', __typename: 'Discount' },
            __typename: 'Discount',
          }))
        : [],
      modifiers: item.modifiers
        ? item.modifiers.map(modifier => ({
            ...modifier,
            modifierGroupPriority: modifier?.modifierGroupPriority || null,
            alternateNames: modifier?.alternateNames || null,
            __typename: 'OrderItemModifier',
            type: 'id',
            taxes: item.taxes
              ? item.taxes.map(x => ({
                  id: x.id,
                  __typename: 'Tax',
                  type: 'id',
                }))
              : [],
          }))
        : [],
      adjustments: item.adjustments
        ? item.adjustments.map(adjustment => ({
            ...adjustment,
            name: adjustment.name || null,
            quantity: adjustment.quantity || null,
            itemQuantity: adjustment.itemQuantity || null,
            pointsRequired: adjustment.pointsRequired || null,
            maximumAmount: adjustment.maximumAmount || null,
            type: 'id',
            __typename: 'Adjustment',
          }))
        : [],
      taxes: item.taxes
        ? item.taxes.map(x => ({ id: x.id, __typename: 'Tax', type: 'id' }))
        : [],
      reason: item.reason || null,
      itemFired: item.itemFired || null,
      seatNumber: item.seatNumber || null,
      createdAt: item.createdAt || null,
      saved: item.saved || null,
      course: item?.course?.id
        ? {
            id: item?.course?.id,
            __typename: 'Course',
          }
        : null,
      paymentStatus: item?.paymentStatus || null,
      transferInfo: item?.transferInfo
        ? {
            ...item.transferInfo,
            transferredBy: {
              id: item.transferInfo.transferredBy?.id,
              __typename: 'User',
            },
          }
        : null,
    })),
  orderType: order.orderType
    ? {
        id: order.orderType.id,
        __typename: 'OrderType',
        type: 'id',
      }
    : null,
  table:
    order.table && order.table.id ? mapTableToGraphObject(order.table) : null,
  tables: order.tables?.length ? order.tables.map(mapTableToGraphObject) : null,
  venue: order.venue
    ? { id: order.venue.id, type: 'id', __typename: 'Venue' }
    : null,
  store: order.store
    ? { id: order.store.id, type: 'id', __typename: 'Store' }
    : null,
  organization: order.organization
    ? { id: order.organization.id, type: 'id', __typename: 'Organization' }
    : null,
  payments: order.payments
    ? order.payments.map(x => ({
        ...x,
        id: x.id,
        roundOffDifference: x.roundOffDifference || null,
        paymentRequestId: x.paymentRequestId || null,
        updatedByDeviceId: x.updatedByDeviceId || null,
        paymentTransactionRef: x.paymentTransactionRef || null,
        paymentCompletedAt: x.paymentCompletedAt || null,
        paymentReceipt: x.paymentReceipt || null,
        paymentSurcharge: x.paymentSurcharge || null,
        cardType: x.cardType || null,
        splitPaymentBy: x.splitPaymentBy || null,
        merchantCode: x.merchantCode || null,
        isPrePayment: x.isPrePayment || null,
        paymentType: x.paymentType?.id
          ? {
              __typename: 'PaymentType',
              id: x.paymentType.id,
              type: 'id',
            }
          : null,
        __typename: 'OrderPayment',
      }))
    : [],
  orderNote: order.orderNote ?? '',
  amountDue: order.amountDue === undefined ? null : order.amountDue,
  createdBy: order.createdBy
    ? { id: order.createdBy.id, __typename: 'User', type: 'id' }
    : null,
  updatedBy: order.updatedBy
    ? { id: order.updatedBy.id, __typename: 'User', type: 'id' }
    : null,
  createdByDevice: order.createdByDevice
    ? { id: order.createdByDevice.id, __typename: 'Device', type: 'id' }
    : null,
  updatedByDevice: order.updatedByDevice
    ? { id: order.updatedByDevice.id, __typename: 'Device', type: 'id' }
    : null,
  taxes: order.taxes
    ? order.taxes.map(x => ({
        ...x,
        tax: { ...x.tax, type: 'id', __typename: 'Tax' },
        __typename: 'OrderTax',
      }))
    : [],
  discounts: order.discounts
    ? order.discounts.map(x => ({
        ...x,
        discount: { ...x, type: 'id', __typename: 'Discount' },
        __typename: 'Discount',
      }))
    : [],
  adjustments: order.adjustments
    ? order.adjustments.map(adjustment => ({
        ...adjustment,
        createdAt: adjustment.createdAt || null,
        maximumAmount: adjustment.maximumAmount || null,
        name: adjustment.name || null,
        quantity: adjustment.quantity || null,
        pointsRequired: adjustment.pointsRequired || null,
        doNotIncludeInSalesAmount: adjustment.doNotIncludeInSalesAmount || null,
        displayNameOnReceipt: adjustment.displayNameOnReceipt || null,
        itemQuantity: adjustment.itemQuantity || null,
        saved: adjustment.saved || null,
        type: 'id',
        __typename: 'Adjustment',
      }))
    : [],
  reason: order.reason || null,
  reservation: order.reservation || null,
  refundOf: order.refundOf || null,
  placedAt: order.placedAt || null,
  isEdited: order.isEdited || false,
  lastSyncedEventId: order.lastSyncedEventId,
  salesChannel: order.salesChannel
    ? { id: order.salesChannel.id, type: 'id', __typename: 'SalesChannel' }
    : null,
  integrationInfo: order.integrationInfo
    ? {
        id: order.integrationInfo.id,
        app: order.integrationInfo.app,
        channelOrderId: order?.integrationInfo?.channelOrderId,
        channelOrderDisplayId: order?.integrationInfo?.channelOrderDisplayId,
        type: 'id',
        __typename: 'IntegrationInfo',
      }
    : null,
  requiredAt: order.requiredAt || null,
  lastCheck: order.lastCheck ?? order.updatedAt,
  lastOrderedAt: order.lastOrderedAt || null,
  shippingAddress: order.shippingAddress || null,
  courier: order.courier || null,
  deliveryFee: order.deliveryFee || null,
  ordersMerged: order.ordersMerged || null,
  serviceCharge: order.serviceCharge || null,
  tip: order.tip || 0,
  rewardDiscountAmount: order.rewardDiscountAmount || 0,
  allergens: order.allergens,
  loyaltySnapshot: order.loyaltySnapshot
    ? {
        ...order.loyaltySnapshot,
        availableRewards: order.loyaltySnapshot?.availableRewards?.map(
          reward => ({
            ...reward,
            __typename: LOYALTY_REWARD_TYPE_MAP[reward.rewardType],
          }),
        ),
        __typename: 'LoyaltySnapshot',
      }
    : null,
  dueAt: order.dueAt || null,
  prepTime: order.prepTime || 0,
  orderName: order.orderName ?? null,
  source: order.source ?? null,
  tokenNumber: order.tokenNumber ?? null,
  refundType: order?.refundType ?? null,
  eventsCount: order?.eventsCount || 0,
});

export const saveOrder = (input: Order, cache: InMemoryCache): Order => {
  /**
   * Filtered splitted combo items,
   * this is only used to show it course for combo items
   */
  const order = mapOrderToGraphObject(input) as unknown as Order;
  cache.writeQuery({
    query: GET_ORDER,
    variables: { orderId: input.id },
    data: {
      order,
    },
  });

  const isOnline = !!order?.integrationInfo?.id;

  const resolvedOrder = cache.readQuery<{ order: Order }>({
    query: GET_ORDER,
    returnPartialData: true,
    variables: { orderId: input.id },
  })?.order;

  // Orders in this state can be discarded
  // Saving into get orders cache will cause discarded orders to be shown in open orders
  // Exiting now will also improve performance when creating new orders
  if (input.status === OrderStatus.CREATED && !isOnline) {
    return resolvedOrder || input;
  }

  const removeOrderFromStatus = removeOrderFromStatusFn(cache, order);
  const updateOrderFromStatus = updateOrderFromStatusFn(cache, order);

  switch (input.status) {
    case OrderStatus.COMPLETED:
      removeOrderFromStatus(OrderStatus.IN_PROGRESS);
      removeOrderFromStatus(OrderStatus.ON_HOLD);
      removeOrderFromStatus(OrderStatus.ON_ACCOUNT);
      updateOrderFromStatus(OrderStatus.COMPLETED);
      break;
    case OrderStatus.VOID:
      removeOrderFromStatus(OrderStatus.IN_PROGRESS);
      removeOrderFromStatus(OrderStatus.ON_HOLD);
      updateOrderFromStatus(OrderStatus.VOID);
      break;
    case OrderStatus.CANCELLED:
      removeOrderFromStatus(OrderStatus.IN_PROGRESS);
      updateOrderFromStatus(OrderStatus.CANCELLED);
      break;
    case OrderStatus.PARTNER_CANCELLED:
      removeOrderFromStatus(OrderStatus.IN_PROGRESS);
      updateOrderFromStatus(OrderStatus.PARTNER_CANCELLED);
      break;
    case OrderStatus.REFUNDED:
      updateOrderFromStatus(OrderStatus.REFUNDED);
      break;
    case OrderStatus.IN_PROGRESS:
      removeOrderFromStatus(OrderStatus.CREATED);
      updateOrderFromStatus(OrderStatus.IN_PROGRESS);
      break;
    case OrderStatus.MERGED:
      removeOrderFromStatus(OrderStatus.IN_PROGRESS);
      updateOrderFromStatus(OrderStatus.MERGED);
      break;
    case OrderStatus.ON_HOLD:
      removeOrderFromStatus(OrderStatus.CREATED);
      updateOrderFromStatus(OrderStatus.ON_HOLD);
      break;
    default:
      updateOrderFromStatus(input.status);
      break;
  }

  return resolvedOrder || input;
};

export const resolvers: Resolvers = {
  Mutation: {
    // TODO: benchmark with around 1000 orders in cache
    saveOrder: (
      _,
      { input }: { input: Order },
      { cache }: { cache: InMemoryCache },
    ): Order => {
      const order = saveOrder(input, cache);
      return order;
    },

    // TODO: benchmark with around 1000 orders in cache
    saveOrders: (
      _,
      { input }: { input: Order[] },
      { cache }: { cache: InMemoryCache },
    ): Order[] => {
      const orders = [] as Order[];

      input.forEach(eachOrder => {
        const order = saveOrder(eachOrder, cache);
        orders.push(order);
      });
      return orders;
    },
  },
};

function removeOrderFromStatusFn(cache: InMemoryCache, order: Order) {
  return (status: OrderStatus) => {
    updateOrdersInCache(cache, status, 'REMOVE', order);
  };
}

function updateOrderFromStatusFn(cache: InMemoryCache, order: Order) {
  return (status: OrderStatus) => {
    updateOrdersInCache(cache, status, 'UPDATE', order);
  };
}

function updateOrdersInCache(
  cache: InMemoryCache,
  status: OrderStatus,
  action: 'UPDATE' | 'REMOVE',
  order: Order,
) {
  let existingOrders: Order[] = [];
  let updatedOrders: Order[];
  const isOnline = !!order?.integrationInfo?.id;

  try {
    existingOrders =
      cache.readQuery<{ orders: Order[] }>({
        query: GET_ORDERS,
        returnPartialData: true,
        variables: { filter: { status, ...(isOnline && { isOnline }) } },
      })?.orders || [];
  } catch (e) {}
  switch (action) {
    case 'REMOVE':
      updatedOrders = existingOrders.filter(x => x.id !== order.id);
      break;
    case 'UPDATE':
      updatedOrders = [...existingOrders.filter(x => x.id !== order.id), order];
      break;
  }
  const limitOfOrdersByStatus = NUMBER_LIMIT_OF_CACHED_ORDERS[status];
  if (limitOfOrdersByStatus) {
    const sortedOrders = orderBy(
      updatedOrders,
      [limitOfOrdersByStatus.sortByField],
      'desc',
    );
    updatedOrders = sortedOrders.slice(0, limitOfOrdersByStatus.value);
    const removedOrders = sortedOrders.slice(limitOfOrdersByStatus.value);
    if (removedOrders.length) {
      // clear the order from cache
      removedOrders.forEach(x => {
        cache.evict({
          id: `Order:${x.id}`,
        });
      });
    }
  }
  cache.writeQuery({
    query: GET_ORDERS,
    variables: { filter: { status, ...(isOnline && { isOnline }) } },
    data: {
      orders: updatedOrders,
    },
    broadcast: true,
  });
}
