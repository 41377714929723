/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import {
  CloseShiftInput,
  DefaultPaymentTypes,
  PaymentType,
  Shift,
  PaymentTypeSaleSummary,
  PaymentTypeSaleSummaryInput,
} from '@oolio-group/domain';
import { limitDecimalCount, sumDecimals } from '@oolio-group/order-helper';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useShifts } from '../../../../../hooks/app/useShifts';
import { useSession } from '../../../../../hooks/app/useSession';
import { useNotification } from '../../../../../hooks/Notification';
import { usePaymentTypes } from '../../../../../hooks/app/usePaymentTypes';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import { userUtility } from '../../../../../state/userUtility';
import { analyticsService } from '../../../../../analytics/AnalyticsService';
import { sentenceCase } from 'change-case';
import styles from './CloseShift.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../Icon/Icon';
import Message from '../../../../Office/Message/Message';
import TreatButton from '../../../../Shared/TreatButton/TreatButton';
import NumberPad from '../../../../Shared/Modals/NumberPad/NumberPad';
import { OPEN_DAYS_FOR_SHIFT } from '../../../../../state/preferences';
import * as storage from '../../../../../storage/interface';

export interface CloseShiftProps {
  data: Shift;
  showViewClosureDifferences?: boolean;
  printShiftReceipt?: (shift: Shift) => void;
  openCashDrawer?: () => void;
  getDeviceDataById?: (deviceId: string) => void;
  forceCloseShiftRef?: React.MutableRefObject<boolean>;
  openDaysForShift?: number;
}

const INTEGRATED_TYPES: string[] = [
  DefaultPaymentTypes.CARD,
  DefaultPaymentTypes.ONLINE,
  DefaultPaymentTypes.ON_ACCOUNT,
];

const CloseShift: React.FC<CloseShiftProps> = ({
  data,
  showViewClosureDifferences = false,
  printShiftReceipt,
  openCashDrawer,
  getDeviceDataById,
  forceCloseShiftRef = { current: false },
  openDaysForShift = 0,
}: CloseShiftProps) => {
  const [session] = useSession();
  const { formatCurrency } = useCurrency();
  const { paymentTypes } = usePaymentTypes({ fetchPolicy: 'cache-first' });
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const { closeShift, error, mutationLoading, closedShiftData } = useShifts();
  const { showNotification } = useNotification();
  const [selectedPaymentType, setSelectedPaymentType] = useState('');

  const salesMap = data.salesByPaymentType.reduce<
    Record<string, PaymentTypeSaleSummary>
  >((acc, x) => ({ ...acc, [x.paymentType.id]: x }), {});

  const [saleByPaymentType, setSaleByPaymentType] = useState<
    Record<string, PaymentTypeSaleSummaryInput>
  >(
    paymentTypes.reduce(
      (acc, x) => ({
        ...acc,
        [x.id]: {
          paymentType: x.id,
          totalCounted:
            (INTEGRATED_TYPES.includes(x.name)
              ? salesMap[x.id]?.recordedAmount // show recorded amount for integrated types
              : salesMap[x.id]?.totalCounted) || 0,
        },
      }),
      {},
    ),
  );

  const [countableTypes, integratedTypes] = useMemo(() => {
    const countableTypes: PaymentType[] = [],
      integratedTypes: PaymentType[] = [];

    paymentTypes.forEach(type =>
      !INTEGRATED_TYPES.includes(type.name)
        ? countableTypes.push(type)
        : integratedTypes.push(type),
    );
    return [countableTypes, integratedTypes];
  }, [paymentTypes]);

  const totalCounted = useMemo(
    () =>
      limitDecimalCount(
        Object.values(saleByPaymentType).reduce(
          (acc, x) => sumDecimals([acc, x.totalCounted || 0]),
          0,
        ),
      ),
    [saleByPaymentType],
  );

  const difference = useMemo(() => {
    return totalCounted - data.totalRecorded;
  }, [totalCounted, data.totalRecorded]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [showNotification, error]);

  useEffect(() => {
    if (closedShiftData) {
      showNotification({
        success: true,
        message: translate('shift.finaliseSuccessMessage'),
      });
      closeModal();
    }
  }, [showNotification, closeModal, translate, closedShiftData]);

  const onSubmitValue = useCallback(
    (prop: string, value: number) => {
      const x = { ...saleByPaymentType[prop] };
      x.totalCounted = value;
      setSaleByPaymentType(existingSale => ({
        ...existingSale,
        [prop]: x,
      }));
      setSelectedPaymentType('');
    },
    [saleByPaymentType],
  );

  useEffect(() => {
    if (closedShiftData && session?.device?.id && getDeviceDataById) {
      getDeviceDataById(session?.device?.id);
    }
  }, [getDeviceDataById, closedShiftData, session?.device?.id]);

  useEffect(() => {
    if (closedShiftData) {
      const closedAt = Date.now();
      const posUser = userUtility.posUser;

      const printShiftInput = stripProperties(
        {
          ...closedShiftData,
          closedAt,
          closedBy: {
            id: posUser?.id,
            name: posUser?.name,
          },
          closedByDevice: {
            id: session.device?.id,
            name: session.device?.name,
          },
          store: {
            id: data?.store?.id,
            showHourlySplit: data?.store?.showHourlySplit ?? false,
          },
        },
        '__typename',
      );

      printShiftReceipt &&
        printShiftReceipt({
          ...printShiftInput,
          salesByPaymentType: printShiftInput.salesByPaymentType.map(
            (paymentSales: PaymentTypeSaleSummaryInput) => ({
              ...paymentSales,
            }),
          ),
          taxes: data.taxes,
        } as unknown as Shift);
    }
  }, [
    data.taxes,
    error,
    mutationLoading,
    paymentTypes,
    printShiftReceipt,
    session.device?.id,
    session.device?.name,
    session.user,
    closedShiftData,
    data.store?.id,
    data.store?.showHourlySplit,
  ]);

  const onOpenDraw = useCallback(() => {
    analyticsService.capture('shift_event', {
      event: 'Opened Draw',
    });
    openCashDrawer && openCashDrawer();
  }, [openCashDrawer]);

  const setPreviousShiftValue = useCallback(() => {
    if (forceCloseShiftRef.current) {
      storage.removeItem(OPEN_DAYS_FOR_SHIFT);
    } else if (openDaysForShift) {
      storage.setItem(OPEN_DAYS_FOR_SHIFT, String(openDaysForShift));
    }
    forceCloseShiftRef.current = false;
  }, [forceCloseShiftRef, openDaysForShift]);

  const onCloseShift = useCallback(async () => {
    const closedAt = Date.now();
    const posUser = userUtility.posUser;

    const input = {
      id: data.id,
      closedAt,
      closedBy: posUser?.id,
      salesByPaymentType: Object.values(saleByPaymentType),
    } as CloseShiftInput;

    analyticsService.capture('shift_event', {
      event: 'Closed Shift',
    });
    closeShift(input);
    setPreviousShiftValue();
  }, [data.id, saleByPaymentType, closeShift, setPreviousShiftValue]);

  return (
    <>
      {!!selectedPaymentType ? (
        <NumberPad
          title={translate('shift.enterAmount', {
            type:
              paymentTypes.find(x => x.id === selectedPaymentType)?.name || '',
          })}
          amount={(
            saleByPaymentType[selectedPaymentType].totalCounted || 0
          ).toString()}
          onCancel={() => setSelectedPaymentType('')}
          onSubmit={onSubmitValue.bind(null, selectedPaymentType)}
          showDenominations={
            paymentTypes.find(x => x.id === selectedPaymentType)?.name ===
            'Cash'
          }
        />
      ) : (
        <View style={styles.container}>
          <View testID="title" style={styles.header}>
            {!forceCloseShiftRef.current && (
              <TouchableOpacity onPress={closeModal} style={styles.btnClose}>
                <Icon name="times" size={20} color={theme.colors.black1} />
              </TouchableOpacity>
            )}
            <Text style={styles.headerText}>
              {`${translate('shift.closureBy')} ${sentenceCase(
                data.shiftType,
              )}`}
            </Text>
          </View>
          <View style={styles.body}>
            <Message
              type="neutral"
              message={translate('shift.closureMessage')}
              containerStyle={{ marginBottom: 20 }}
            />
            <View>
              <ScrollView style={styles.counts}>
                {countableTypes
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((type, i) => (
                    <TouchableOpacity
                      key={i}
                      style={styles.count}
                      testID={`payment-type-${i}`}
                      onPress={setSelectedPaymentType.bind(null, type.id)}
                    >
                      <Text numberOfLines={1} style={styles.totalText}>
                        {type.name}
                      </Text>
                      <Text style={styles.totalText}>
                        {formatCurrency(
                          saleByPaymentType[type.id].totalCounted || 0,
                        )}
                      </Text>
                    </TouchableOpacity>
                  ))}
                {integratedTypes
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((type, i) => (
                    <View key={i} style={styles.total}>
                      <Text style={styles.totalText}>{type.name}</Text>
                      <Text style={styles.totalText}>
                        {formatCurrency(
                          saleByPaymentType[type.id]?.totalCounted || 0,
                        )}
                      </Text>
                    </View>
                  ))}
              </ScrollView>
            </View>
            <View style={styles.totals}>
              <View style={styles.total}>
                <Text style={styles.totalText}>
                  {translate('shift.totalCounted')}
                </Text>
                <Text style={styles.totalText}>
                  {formatCurrency(totalCounted)}
                </Text>
              </View>
              {showViewClosureDifferences && (
                <View
                  style={[
                    styles.total,
                    {
                      backgroundColor:
                        difference !== 0
                          ? theme.colors.redLight
                          : theme.colors.greenLight,
                    },
                  ]}
                >
                  <Text style={styles.totalText}>
                    {translate('shift.difference')}
                  </Text>
                  <Text
                    style={[
                      styles.totalText,
                      {
                        color:
                          difference !== 0
                            ? theme.colors.red
                            : theme.colors.green,
                      },
                    ]}
                  >
                    {formatCurrency(difference)}
                  </Text>
                </View>
              )}
            </View>
            <View style={styles.actions}>
              {openCashDrawer && (
                <TreatButton
                  type="neutral"
                  testID="btn-openDraw"
                  onPress={onOpenDraw}
                  label={translate('shift.openDraw')}
                  disabled={mutationLoading}
                  containerStyle={{ flex: 1, marginRight: 10 }}
                />
              )}
              <TreatButton
                type="focus"
                testID="btn-endShift"
                label={translate('shift.close')}
                onPress={onCloseShift}
                isLoading={mutationLoading}
                containerStyle={{ flex: 1 }}
              />
            </View>
          </View>
        </View>
      )}
    </>
  );
};

export default CloseShift;
