import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 620,
    alignSelf: 'center',
  },
  content: {
    padding: 40,
    height: 474,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  response: {
    padding: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.greenLightest,
    marginBottom: 20,
  },
  iconSuccess: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 3,
    borderColor: theme.colors.green,
    backgroundColor: theme.colors.greenLight,
  },
  textPrimary: {
    fontSize: 20,
    lineHeight: 24,
    color: theme.colors.green,
    fontFamily: theme.fonts.semibold,
  },
  textSecondary: {
    marginTop: 12,
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.green,
    fontFamily: theme.fonts.medium,
  },
  textContext: {
    marginTop: 20,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  marginTop10: {
    marginTop: 10,
  },
  actionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  splitProductMessage: {
    marginTop: 10,
    marginBottom: 30,
  },
  splitPrompt: {
    height: 230,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  splitPromptText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  cardProcessState: {
    height: 254,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusText: {
    fontSize: 16,
    lineHeight: 22,
    fontFamily: theme.fonts.medium,
    color: theme.colors.grey6,
    marginTop: 20,
  },
  btnPrintReceipt: {
    flex: 1,
    marginRight: 5,
  },
});

export default styles;
