import React, { useState } from 'react';
import { CreatePricingGroupInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';

interface CreatePriceListModalProps {
  onCreate: (form: Partial<CreatePricingGroupInput>) => void;
}

const CreatePriceListModal: React.FC<CreatePriceListModalProps> = ({
  onCreate,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [name, setName] = useState<string>('');

  const onHandleCreate = () => {
    if (name.length < 1) {
      showNotification({
        error: true,
        message: translate('pricings.pricingNameIsRequired'),
      });
    }

    const form: Partial<CreatePricingGroupInput> = {
      name: name,
      venues: [],
      orderTypes: [],
      prices: [],
      stores: [],
      salesChannels: [],
      isActive: true,
    };

    onCreate(form);
    closeModal();
    setName('');
  };

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <TreatModal
        type="positive"
        title={translate('pricings.quickCreatePricingGroup')}
        onConfirm={{ action: onHandleCreate }}
        onDismiss={{ action: closeModal }}
      >
        <InputText
          testID="input-nameNew"
          title={translate('pricings.name')}
          placeholder={translate('pricings.name')}
          value={name}
          onChangeText={setName}
        />
      </TreatModal>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, alignContent: 'center', justifyContent: 'center' },
});

export default CreatePriceListModal;
