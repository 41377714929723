import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../../../components/TabBar/TabBar';
import { StoreSettings } from './Settings';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();
interface RouteParams {
  params: {
    locationId: string;
    storeName: string;
  };
}
const DeputyStoreTab: React.FC = () => {
  const route = useRoute();
  const params = route.params as RouteParams;
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'DeputySettings'}
        />
      )}
      initialRouteName="DeputyAboutTab"
    >
      <Tab.Screen
        name="DeputyAboutTab"
        component={StoreSettings}
        options={{ tabBarLabel: params?.params?.storeName }}
      />
    </Tab.Navigator>
  );
};

export default DeputyStoreTab;
