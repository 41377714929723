import theme from '../../common/default-theme';

export const DEFAULT_GRADIENT = {
  start: { x: 0.0, y: 0.5 },
  end: { x: 0.5, y: 1.0 },
  colors: [theme.colors.primary, '#4A2EB1', '#3023AE'],
  locations: [0.4, 0.8, 1],
  useAngle: true,
  angle: 135,
};
