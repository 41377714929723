import { gql } from '@apollo/client';

export const VALIDATE_VOUCHER = gql`
  query ValidateVoucher($voucherCode: String!) {
    validateVoucher(code: $voucherCode) {
      requestId
      success
      message
      voucherInfo {
        id
        voucherType
        voucherValue
        redeemed
        redeemedDate
        productIds
      }
    }
  }
`;
