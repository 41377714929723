import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const textStyle: TextStyle = {
  fontSize: 13,
  lineHeight: 20,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  container: {
    marginTop: 'auto',
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    marginBottom: 4,
  },
  label: {
    ...textStyle,
    color: theme.colors.grey6,
  },
  value: {
    ...textStyle,
    color: theme.colors.dark,
    textAlign: 'right',
    fontVariant: ['tabular-nums'],
  },
});

export default styles;
