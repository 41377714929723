import { OrderPayment, PaymentType, RoundOffValue } from '@oolio-group/domain';
import { roundOffByValue } from '@oolio-group/client-utils';
import { convertEnumToArray } from './enumToArray';
import { Session } from '../state/Session';
import { sumDecimals } from '@oolio-group/order-helper';

//TODO Add it in a common place
const CASH_PAYMENT_TYPE = 'cash';

export const getRoundOffValue = (roundOffValue: string | undefined) => {
  const roundOffSelector = roundOffValue || '1';
  const roundOffOptions = convertEnumToArray(RoundOffValue);
  const roundOffValueRes = roundOffSelector
    ? parseFloat(
        roundOffOptions.find(elem => elem.key == roundOffSelector)?.value ||
          '1',
      )
    : 1;
  return roundOffValueRes;
};

export const setTotalPaymentAmountForRoundOff = (
  orderPayment: OrderPayment | undefined,
  paymentType: PaymentType,
  session: Session,
): number | undefined => {
  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;

  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );

  if (paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE && enableRoundOff) {
    return roundOffByValue(orderPayment?.tendered || 0, roundOffValue || 1);
  }

  return undefined;
};

export const getRoundingAmount = (
  session: Session,
  paymentType: PaymentType,
  orderPayment?: OrderPayment,
) => {
  let roundingAmount = 0;
  if (orderPayment) {
    const roundedTotalPrice = setTotalPaymentAmountForRoundOff(
      orderPayment,
      paymentType,
      session,
    );
    roundingAmount = roundedTotalPrice
      ? sumDecimals([roundedTotalPrice, -orderPayment.tendered])
      : 0;

    return roundingAmount;
  }
  return roundingAmount;
};
