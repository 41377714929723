import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Settings from './Settings/Settings';

const Stack = createStackNavigator();

const LoyaltyStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="Settings"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={Settings} name="Settings" />
  </Stack.Navigator>
);

export default LoyaltyStack;
