import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 600,
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.s,
    padding: 30,
  },
  ruleText: {
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.states.positive,
    marginBottom: 16,
    marginLeft: 10,
    fontFamily: theme.fonts.semibold,
  },
  discountRuleSection: {
    margin: 5,
  },
  buttonAction: {
    alignSelf: 'flex-end',
    marginTop: 40,
  },
});

export default styles;
