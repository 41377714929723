import { OrderType, OrderTypeCode } from '@oolio-group/domain';

export type OrderTypesMap = Record<string, OrderType & { colorId: number }>;

export const createOrderTypesMap = (orderTypes: OrderType[]) =>
  orderTypes.reduce<OrderTypesMap>(
    (map, x, index) => ({ ...map, [x.id]: { ...x, colorId: index + 1 } }),
    {},
  );

export const isOrderTypeEnabled = (
  enabledOrderTypeIds: string[],
  orderTypes: OrderType[],
  orderTypeCode: OrderTypeCode,
): boolean => {
  const orderTypeId = orderTypes.find(
    orderType => orderType.code === orderTypeCode,
  )?.id;
  return (
    (orderTypeId && enabledOrderTypeIds.indexOf(orderTypeId) !== -1) || false
  );
};
