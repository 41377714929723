import { useApolloClient, useQuery } from '@apollo/client/react/hooks';
import {
  Connection,
  Order,
  OrderFilterInput,
  OrderStatus,
} from '@oolio-group/domain';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { useCallback, useState } from 'react';
import {
  GET_ORDER,
  GET_ORDERS,
  GET_ORDER_FRAGMENT,
  GET_PAGINATED_ORDER_QUERY,
} from './graphql';
import { refetchOrderObservable } from './ordersObservableUtils';
import { useIsMounted } from '../../../useIsMounted';
import { useSession } from '../useSession';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';

export interface Variables {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface CursorType {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  filter?: OrderFilterInput;
}

export interface UseOrdersProps {
  orders: Record<string, Order>;
  loading: boolean;
  error: string | undefined;
  getOnlineOrders: (statuses: OrderStatus[]) => void;
  getOrdersFromCache: (status: OrderStatus, isOnline?: boolean) => void;
  getOrderFromCache: (orderId: string) => Order | undefined;
  returnOrdersFromCache: (status: OrderStatus, isOnline?: boolean) => Order[];
  returnFilteredOrdersFromCache: (filterArr: OrderFilterInput[]) => Order[];
  refetchOrdersFromServer: () => Promise<boolean>;
  getLatestCompletedOrderByDevice: (deviceId?: string) => Order | undefined;
  openOrdersCount: number;
}

const GET_PAGINATED_IN_PROGRESS_ORDERS = {
  query: GET_PAGINATED_ORDER_QUERY,
  variables: {
    filter: {
      status: OrderStatus.IN_PROGRESS,
    },
  },
};

const GET_ON_HOLD_ORDERS = {
  query: GET_PAGINATED_ORDER_QUERY,
  variables: {
    after: '',
    filter: {
      status: OrderStatus.ON_HOLD,
    },
  },
};

export function useOrders(): UseOrdersProps {
  const [orders, setOrdersData] = useState<Record<string, Order>>({});
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string>('');
  const client = useApolloClient();
  const isMounted = useIsMounted();
  const [session] = useSession();

  const { data: openOrdersData } = useQuery(GET_ORDERS, {
    fetchPolicy: 'cache-only',
    returnPartialData: true,
    variables: { filter: { status: OrderStatus.IN_PROGRESS } } as {
      filter: OrderFilterInput;
    },
  });

  const openOrdersCount = openOrdersData?.orders?.length || 0;

  const getOrdersFromCache = useCallback(
    (status: OrderStatus, isOnline?: boolean) => {
      if (client) {
        const clientOrders = client.cache.readQuery({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
        }) as { orders: Order[] };

        const orders = clientOrders?.orders;

        if (
          (status === OrderStatus.IN_PROGRESS ||
            status === OrderStatus.CREATED) &&
          Array.isArray(clientOrders?.orders)
        ) {
          // Not keeping previous ones, as order might be voided.
          // VOID orders will be removed from CREATED or IN_PROGRESS cache.
          setOrdersData(keyBy(orders, 'id'));
        } else if (clientOrders?.orders?.length) {
          setOrdersData(prev => ({ ...keyBy(orders, 'id'), ...prev }));
        }
      }
      return [];
    },
    [client],
  );

  const getOrderFromCache = useCallback(
    (orderId: string): Order | undefined => {
      if (client) {
        const cacheQueryResult = client.cache.readQuery({
          query: GET_ORDER,
          returnPartialData: true,
          variables: { orderId },
        }) as { order: Order };
        if (cacheQueryResult?.order) return cacheQueryResult?.order;

        const cacheFragmentResult =
          client.cache.readFragment<Order>({
            id: `Order:${orderId}`,
            fragment: GET_ORDER_FRAGMENT,
          }) || undefined;
        return cacheFragmentResult;
      }
      return undefined;
    },
    [client],
  );

  const returnOrdersFromCache = useCallback(
    (status: OrderStatus, isOnline?: boolean): Order[] => {
      if (client) {
        const clientOrders = client.cache.readQuery<{ orders: Order[] }>({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
        });
        return clientOrders?.orders || [];
      } else {
        return [];
      }
    },
    [client],
  );

  const returnFilteredOrdersFromCache = useCallback(
    (filterArr: OrderFilterInput[]): Order[] => {
      if (client) {
        let orders: Order[] = [];
        filterArr.forEach(filter => {
          const clientOrders = client.cache.readQuery({
            query: GET_ORDERS,
            returnPartialData: true,
            variables: {
              filter,
            } as {
              filter: OrderFilterInput;
            },
          }) as { orders: Order[] };

          if (Array.isArray(clientOrders?.orders)) {
            orders = orders.concat(clientOrders?.orders);
          }
        });

        return orders;
      }

      return [];
    },
    [client],
  );

  const writeOrdersToCache = useCallback(
    (
      ordersData: { paginatedOrders: Connection<Order> },
      status: OrderStatus,
      isOnline?: boolean,
    ) => {
      /**
       * For CREATED, IN_PROGRESS orders we are accepting blank array as well,
       * As we have to clear cache if there are no open orders.
       */
      if (
        client &&
        (((status === OrderStatus.IN_PROGRESS ||
          status === OrderStatus.CREATED) &&
          Array.isArray(ordersData?.paginatedOrders?.edges)) ||
          (status && ordersData?.paginatedOrders?.edges?.length))
      ) {
        const paginatedOrders = ordersData.paginatedOrders as Connection<Order>;
        const ordersMap: Record<string, Order> = {};
        paginatedOrders.edges.forEach(eachEdge => {
          eachEdge?.node?.id &&
            (ordersMap[eachEdge?.node?.id] = {
              ...eachEdge.node,
              lastSyncedEventId: eachEdge.node.prevEventId,
            });
        });

        const existingOrders =
          client.cache.readQuery<{ orders: Order[] }>({
            query: GET_ORDERS,
            returnPartialData: true,
            variables: {
              filter: { status, ...(isOnline && { isOnline }) },
            } as {
              filter: OrderFilterInput;
            },
          })?.orders || [];

        const updatedOrders: Record<string, Order> = {
          ...ordersMap,
        };

        if (
          status !== OrderStatus.IN_PROGRESS &&
          status !== OrderStatus.CREATED
        ) {
          Object.assign(updatedOrders, keyBy(existingOrders, 'id'));
        }

        client.cache.writeQuery({
          query: GET_ORDERS,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
          data: {
            orders: Object.values(updatedOrders) as Order[],
          },
        });
        isMounted() && setOrdersData(prev => ({ ...ordersMap, ...prev }));
      }
    },
    [client, isMounted],
  );

  const getOnlineOrders = useCallback(
    async (statuses: OrderStatus[]) => {
      setLoading(true);
      try {
        await Promise.all(
          statuses.map(async status => {
            const today = new Date(Date.now());

            // Query online completed order for 2 days.
            const dateFilters = {
              fromDateTime: startOfDay(subDays(today, 2)).getTime(),
              toDateTime: today.getTime(),
            };
            const result = await client.query({
              query: GET_PAGINATED_ORDER_QUERY,
              variables: {
                filter: {
                  ...(status === OrderStatus.COMPLETED && dateFilters),
                  status,
                  isOnline: true,
                },
              },
              fetchPolicy: 'network-only',
              errorPolicy: 'ignore',
            });

            writeOrdersToCache(
              result?.data as { paginatedOrders: Connection<Order> },
              status,
              true,
            );
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    [client, writeOrdersToCache],
  );

  const refetchOrdersFromServer = useCallback(async () => {
    /**
     * Calls paginated query and keeps data in cache
     * get all open orders
     * get 50 completed orders
     * usually calls / used when pos app logges in
     * Let IN_PROGRESS order overwrite COMPLETE order
     * Then status of table in table management will be based on IN_PROGRESS order
     * calls online in progess and created status orders if online orders is turned on
     */

    const inProgressOrders = await client.query({
      ...GET_PAGINATED_IN_PROGRESS_ORDERS,
      fetchPolicy: 'network-only',
    });

    writeOrdersToCache(
      inProgressOrders?.data as { paginatedOrders: Connection<Order> },
      OrderStatus.IN_PROGRESS,
    );

    const onHoldOrders = await client.query({
      ...GET_ON_HOLD_ORDERS,
      fetchPolicy: 'network-only',
    });

    writeOrdersToCache(
      onHoldOrders?.data as { paginatedOrders: Connection<Order> },
      OrderStatus.ON_HOLD,
    );

    const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;
    if (isOnlineOrdersEnabled) {
      await getOnlineOrders([
        OrderStatus.CREATED,
        OrderStatus.IN_PROGRESS,
        OrderStatus.COMPLETED,
      ]);
    }

    refetchOrderObservable.next({
      timestamp: Date.now(),
    });

    return true;
  }, [
    client,
    getOnlineOrders,
    session?.deviceProfile?.enableOnlineOrders,
    writeOrdersToCache,
  ]);

  const getLatestCompletedOrderByDevice = useCallback(
    (deviceId?: string) => {
      if (!deviceId) return;
      const completedOrders = returnOrdersFromCache(OrderStatus.COMPLETED);
      const completedOnlineOrders = returnOrdersFromCache(
        OrderStatus.COMPLETED,
        true,
      );
      const sortedOrdersByDevices = orderBy(
        [...completedOrders, ...completedOnlineOrders].filter(
          order => order?.updatedByDevice?.id === deviceId,
        ),
        ['updatedAt'],
        ['desc'],
      );
      return sortedOrdersByDevices[0];
    },
    [returnOrdersFromCache],
  );

  return {
    orders,
    loading,
    error: error,
    getOrdersFromCache,
    refetchOrdersFromServer: refetchOrdersFromServer,
    returnOrdersFromCache,
    returnFilteredOrdersFromCache,
    getOrderFromCache,
    getLatestCompletedOrderByDevice,
    getOnlineOrders,
    openOrdersCount,
  };
}
