import React, { FC, useCallback } from 'react';
import { View, Text, StyleSheet, Linking } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../common/default-theme';
import Icon from '../../components/Icon/Icon';
import Gradient from '../../components/Gradient/Gradient';
import TreatButton from '../../components/Shared/TreatButton/TreatButton';
import { useDeviceInfo } from '../../hooks/app/useDeviceInfo';

export type AppVersionPayload = {
  minAppVersion: string;
  required: boolean;
};

interface UnsupportedVersionScreenProps {
  upgradeTo: string;
  onDismiss?: () => void;
}

// FIXME: Move To ENV
const APP_ID = process.env.REACT_APP_IOS_APP_ID;

const formatVersionNumber = (versionNumber: string) =>
  versionNumber.replaceAll(/\-|\_/g, '.');

export const UnsupportedVersionScreen: FC<UnsupportedVersionScreenProps> = ({
  upgradeTo,
  onDismiss,
}) => {
  const { translate } = useTranslation();
  const { versionNum } = useDeviceInfo();

  const onPressUpdate = useCallback(() => {
    const deepLink = `https://beta.itunes.apple.com/v1/app/${APP_ID}`;
    Linking.openURL(deepLink);
  }, []);

  const onPressDismiss = useCallback(() => {
    onDismiss && onDismiss();
  }, [onDismiss]);

  return (
    <Gradient style={styles.screen}>
      <View style={styles.container}>
        <Icon
          size={44}
          style={styles.icon}
          name="exclamation-triangle"
          color={theme.colors.states.negative}
        />
        <Text style={styles.title}>{`${translate('cart.update')} ${
          !onDismiss
            ? translate('modifiers.required')
            : translate('enums.AVAILABLE')
        }`}</Text>
        <Text style={styles.subtitle}>
          {!onDismiss
            ? translate('home.updateRequiredSubtitle', {
                currentVersion: versionNum,
                requiredVersion: formatVersionNumber(upgradeTo),
              })
            : translate('home.updateAvailableSubtitle')}
        </Text>
        <View style={styles.actions}>
          <TreatButton
            testID="btn-update"
            type="positive"
            label={translate('cart.update')}
            onPress={onPressUpdate}
          />
          {onDismiss ? (
            <TreatButton
              testID="btn-cancel"
              type="cancel"
              label={translate('button.dismiss')}
              onPress={onPressDismiss}
              containerStyle={styles.btnDismiss}
            />
          ) : null}
        </View>
      </View>
    </Gradient>
  );
};

export default UnsupportedVersionScreen;

const styles = StyleSheet.create({
  screen: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 400,
    alignSelf: 'center',
    paddingVertical: 50,
    paddingHorizontal: 60,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
  },
  icon: {
    alignSelf: 'center',
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    marginTop: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
  },
  subtitle: {
    fontSize: 14,
    marginTop: 20,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  actions: {
    width: 280,
    marginTop: 40,
  },
  btnDismiss: {
    marginTop: 10,
  },
});
