import React from 'react';
import {
  Text,
  View,
  ViewStyle,
  TouchableOpacity,
  GestureResponderEvent,
  ActivityIndicator,
} from 'react-native';
import theme from '../../../common/default-theme';
import {
  getContainerStyle,
  getLabelStyle,
  getIconColor,
} from './TreatButton.styles';
import Icon from '../../Icon/Icon';

export interface TreatButtonProps {
  testID?: string;
  label: string;
  icon?: string;
  isLoading?: boolean;
  type?:
    | 'primary'
    | 'primaryLight'
    | 'accent'
    | 'positive'
    | 'negative'
    | 'neutral'
    | 'positiveLight'
    | 'negativeLight'
    | 'neutralLight'
    | 'focus'
    | 'focusLight'
    | 'cancel'
    | 'interface';
  onPress: (event?: GestureResponderEvent) => void;
  containerStyle?: ViewStyle | ViewStyle[];
  disabled?: boolean;
  uppercase?: boolean;
  height?: number;
}

const TreatButton: React.FC<TreatButtonProps> = ({
  type,
  testID,
  label,
  icon,
  onPress,
  disabled,
  isLoading,
  containerStyle,
  uppercase = false,
  height = 44,
}: TreatButtonProps) => {
  return (
    <View style={containerStyle}>
      <TouchableOpacity
        testID={testID}
        style={[
          { height: height },
          getContainerStyle(
            !disabled
              ? type
              : type === 'interface'
              ? 'disabledInterface'
              : 'disabled',
          ),
        ]}
        onPress={onPress}
        disabled={disabled}
      >
        {isLoading ? (
          <ActivityIndicator
            color={
              disabled
                ? theme.colors.dark
                : type?.includes('Light')
                ? theme.colors.dark
                : theme.colors.light
            }
          />
        ) : (
          <>
            <Text
              style={getLabelStyle(
                !disabled ? type : 'disabled',
                uppercase,
                height,
              )}
            >
              {label}
            </Text>
            {icon ? (
              <Icon
                size={20}
                name={icon}
                color={getIconColor(
                  !disabled
                    ? type
                    : type === 'interface'
                    ? 'disabledInterface'
                    : 'disabled',
                )}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{ marginLeft: 8 }}
              />
            ) : null}
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};

TreatButton.defaultProps = {
  label: 'Button Label',
};

export default TreatButton;
