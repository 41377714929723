import {
  StackNavigationState,
  PartialState,
  LinkingOptions,
} from '@react-navigation/native';
import { App, FeatureIDs, FeatureSetting, Resource } from '@oolio-group/domain';
import { SsoClient } from '@oolio-group/pkce-sso-sdk';
import { auth0Config } from '../constants';
import { FixedLink } from '../components/Office/Navigation/Navigation.types';

type OfficeLinkGroup = {
  id: string;
  title: string;
  icon: string;
  links: {
    id: string;
    title: string;
    route: string;
    isExternal?: boolean;
    feature?: FeatureSetting;
    resource: Resource;
  }[];
};

type ParamsList = Record<string, object | undefined>;

export const navigateToBackOfficeParams:
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> = {
  index: 0,
  routes: [
    {
      name: 'BackOffice',
      state: {
        routes: [{ name: 'Home', state: { routes: [{ name: 'Overview' }] } }],
      },
    },
  ],
};

export const redirectToSsoProvider = (initialURL: string) => {
  const url = new URL(initialURL);
  const ssoClient = new SsoClient(
    {
      issuer: auth0Config.issuer,
      clientId: auth0Config.clientId,
      scope: auth0Config.scopes.join(' '),
      audience: auth0Config.additionalParameters.audience,
      redirectUrl: location.origin + '/onboarding',
    },
    () => undefined,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  ssoClient.authenticate(params);
  return undefined;
};

export const navigateToLockScreen = (
  app: App = App.POS_APP,
  notAutoRedirect?: boolean,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => ({
  index: 0,
  routes: [
    {
      name: 'POS',
      state: {
        routes: [
          {
            name: 'Lock',
            params: {
              app,
              notAutoRedirect,
            },
          },
        ],
      },
    },
  ],
});

export const navigateToCallback = (
  link: string,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => {
  const url = new URL(link);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return {
    index: 0,
    routes: [
      {
        name: 'callback',
        params: params,
      },
    ],
  };
};

export const navigateToOnboardingScreen = (
  link: string,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => {
  const url = new URL(link);
  const state = url.searchParams.get('state') ?? '';
  const code = url.searchParams.get('code') ?? '';
  let params = {};
  try {
    params = JSON.parse(atob(state));
  } catch (error) {}
  return {
    index: 0,
    routes: [
      {
        name: 'onboarding',
        params: {
          code: code,
          state: state,
          ...params,
        },
      },
    ],
  };
};

export const deepLinkConfig: LinkingOptions['config'] = {
  screens: {
    LoginTypeSelection: 'login-type-selection',
    Onboard: 'onboarding',
    Logout: 'logout',
    Signup: 'signup',
    POS: {
      path: 'pos',
      screens: {
        AssignToDevice: 'assign-to-device',
        Orders: {
          screens: {
            TakeOrder: 'order/:id?',
            OpenOrders: 'orders/open-orders',
            Payment: 'orders/payment',
            SearchCustomer: 'orders/:id/search-customer',
            AddCustomer: 'orders/:id/add-customer',
            PrintJobs: 'orders/print-queue',
            OnlineOrders: 'orders/online-orders',
            FloorView: {
              path: 'orders/floor-view',
              screens: {
                Section: ':name?',
              },
            },
          },
        },
        POSSettings: {
          path: 'settings',
          screens: {
            Account: 'account',
            POSMenuEditor: 'menu-editor',
          },
        },
        Lock: 'unlock',
        ManageMoney: 'manage-money',
        OrderHistory: 'orders/history',
        Reservations: 'reservations',
        Customers: {
          screens: {
            Customers: 'customers',
            OnAccountOrders: 'customers/on-account',
          },
        },
        Shift: 'shift',
      },
    },
    BackOffice: {
      path: 'backoffice',
      initialRouteName: 'Home',
      screens: {
        Home: {
          initialRouteName: 'Overview',
          path: 'home',
          screens: {
            Overview: 'overview',
          },
        },
        Dashboard: {
          initialRouteName: 'SalesOverview',
          path: 'dashboard',
          screens: {
            SalesOverview: 'sales-overview',
          },
        },
        Products: {
          initialRouteName: 'Manage',
          path: 'products',
          screens: {
            Menus: 'menus',
            MenuSettings: {
              initialRouteName: 'CreateMenu',
              path: 'menus/:menuId',
              screens: {
                CreateMenu: 'create-menu',
              },
            },
            Pages: 'pages',
            PageSettings: {
              initialRouteName: 'PageDetail',
              path: 'page/:pageId',
              screens: {
                PageDetail: 'page-detail',
              },
            },
            Products: 'manage',
            Modifiers: {
              initialRouteName: 'ModifiersTabs',
              path: 'modifiers',
              screens: {
                ModifiersTabs: {
                  initialRouteName: 'ModifierGroupsTab',
                  path: 'groups',
                  screens: {
                    ModifierGroupsTab: 'modifier-groups-tab',
                    ModifierTab: 'modifier-list',
                  },
                },
                CreateModifierGroupTab: {
                  initialRouteName: 'CreateModifierGroup',
                  path: 'modifierGroup/:modifierGroupId',
                  screens: {
                    CreateModifierGroup: 'modifierGroup',
                  },
                },
              },
            },
            Courses: 'courses',
            // Categories: 'categories',
            PriceLists: 'price-lists',
            ProductTags: 'product-tags',
            SideGroups: 'side-groups',
            PriceListSettings: {
              initialRouteName: 'Details',
              path: 'price/:pricingGroupId',
              screens: {
                Details: 'details',
                Products: 'products',
              },
            },
            ProductSettings: {
              initialRouteName: 'Availability',
              path: 'product/:productId',
              screens: {
                Availability: 'availability',
                General: 'general',
                Options: 'options',
                Pricing: 'pricing',
                Variants: 'variants',
                Modifiers: 'modifiers',
                // Sides: 'sides',
              },
            },
          },
        },
        Customers: {
          initialRouteName: 'ManageCustomers',
          path: 'customers',
          screens: {
            ManageCustomers: {
              initialRouteName: 'ManageCustomersTab',
              path: 'manage',
              screens: {
                ManageCustomersTab: 'manage-customers-tab',
              },
            },
          },
        },
        Loyalty: {
          initialRouteName: 'Overview',
          path: 'Loyalty',
          screens: {
            Overview: 'overview',
            ActivityLog: 'activity-log',
            Customers: 'customers',
            Settings: 'settings',
          },
        },
        Users: {
          initialRouteName: 'ManageUsers',
          path: 'users',
          screens: {
            ManageUsers: {
              initialRouteName: 'ManageUsersTab',
              path: 'manage',
              screens: {
                ManageUsersTab: 'manage-users-tab',
              },
            },
            UserDetails: {
              initialRouteName: 'General',
              path: 'details',
              screens: {
                General: 'general',
                Venues: 'venues',
              },
            },
            JobRoles: 'job-roles',
            JobRoleSettings: {
              initialRouteName: 'CreateOrEditRole',
              path: 'job-roles/:roleId',
              screens: {
                CreateOrEditRole: 'details',
              },
            },
          },
        },
        Features: {
          initialRouteName: 'ManageFeatures',
          path: 'features',
          screens: {
            ManageFeatures: {
              initialRouteName: 'FeaturesTab',
              path: 'manage',
              screens: {
                FeaturesTab: {
                  initialRouteName: 'FeaturesOverview',
                  path: 'features-tab',
                  screens: {
                    ManageFeature: 'manage-feature',
                    FeaturesOverview: 'overview',
                  },
                },
                IntegrationsTab: 'integrations-tab',
              },
            },
            FeaturesSettings: {
              initialRouteName: 'AboutTab',
              path: 'about-feature/:featureId',
              screens: {
                AboutTab: 'about-feature',
              },
            },
            DoshiiSettings: {
              initialRouteName: 'AboutTab',
              path: 'doshii',
              screens: {
                AboutTab: 'doshii-about',
                SettingsTab: 'doshii-settings',
              },
            },
            DeliveritSettings: {
              initialRouteName: 'AboutTab',
              path: 'deliverit',
              screens: {
                AboutTab: 'deliverit-about',
                SettingsTab: 'deliverit-settings',
              },
            },
            XeroSettings: {
              initialRouteName: 'AboutTab',
              path: 'xero',
              screens: {
                AboutTab: 'xero-about',
                SettingsTab: 'xero-settings',
              },
            },
            DeputySettings: {
              initialRouteName: 'AboutTab',
              path: 'deputy',
              screens: {
                AboutTab: 'deputy-about',
                SettingsTab: 'settings',
                DeputyStoreTab: {
                  initialRouteName: 'DeputyStoreTab',
                  path: 'about-feature/:storeName',
                  screens: {
                    DeputyStoreTab: 'about-feature',
                  },
                },
              },
            },
            OolioStoreSettings: {
              initialRouteName: 'AboutTab',
              path: 'oolio-online-store',
              screens: {
                AboutTab: 'about',
                SettingsTab: 'settings',
              },
            },
            OnlineOrderIntegrationsSettings: {
              initialRouteName: 'AboutTab',
              path: 'online-order-integration/:app',
              screens: {
                AboutTab: 'about',
                SettingsTab: 'settings',
              },
            },
          },
        },
        Reports: {
          initialRouteName: 'SalesFeed',
          path: 'reports',
          screens: {
            Sales: {
              path: 'sales',
              screens: {
                SalesFeed: 'sales-feed',
                SalesSummary: 'sales-summary',
                Devices: 'devices',
                Products: 'products',
                HourlySales: 'hourly-sales',
                Staff: 'staff',
                Taxes: 'taxes',
                Customers: 'customers',
                ProductsSummary: 'products-summary',
                Dashboard: 'dashboard',
                Adjustments: 'adjustments',
              },
            },
            Payments: {
              path: 'payments-summary',
              screens: {
                Payments: 'payments',
              },
            },
            ShiftSummaries: {
              path: 'shift-summaries',
              screens: {
                Shifts: 'shifts',
              },
            },
            Users: {
              path: 'users-summary',
              screens: {
                Attendance: 'attendance',
              },
            },
            VoidsRefunds: {
              path: 'voids-refunds',
              screens: {
                Voids: 'voids',
              },
            },
          },
        },
        Settings: {
          initialRouteName: 'General',
          path: 'settings',
          screens: {
            General: 'general',
            Organisation: 'organisation',
            VenuesStores: 'venues-and-stores',
            Printing: {
              initialRouteName: 'PrinterProfile',
              path: 'printing',
              screens: {
                PrinterProfile: 'printer-profile',
                ReceiptTemplate: 'receipt-template',
              },
            },
            Other: {
              initialRouteName: 'RefundReasons',
              path: 'other',
              screens: {
                RefundReasons: 'refund-reasons',
                ProductTypes: 'product-types',
                ReportSettings: 'report-settings',
              },
            },
            TaxesFees: {
              initialRouteName: 'Taxes',
              path: 'taxes-fees',
              screens: {
                Taxes: 'taxes',
                Fees: 'fees',
              },
            },
            PaymentTypes: 'payment-types',
            Discount: {
              path: 'discount',
              screens: {
                CreateOrEditAdjustment: '',
              },
            },
            Surcharge: {
              path: 'surcharge',
              screens: {
                CreateOrEditAdjustment: '',
              },
            },
            Adjustment: {
              screens: {
                CreateOrEditAdjustment: 'adjustment',
              },
            },
            VenueSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId',
              screens: {
                Details: 'details',
                Adjustments: 'adjustments',
                Discounts: 'discounts',
                Surcharges: 'surcharges',
                CashManagement: 'manage-cash',
                FunctionMaps: 'function-maps',
                SectionManagement: 'manage-section',
                AccountSales: 'account-sales',
              },
            },
            StoreSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId/store/:storeId',
              screens: {
                Details: 'details',
                Checkout: 'checkout',
                Devices: 'devices',
                DeviceProfiles: 'device-profiles',
                Tables: 'tables',
                Printers: 'printers',
                ProfitMargins: 'profit-margins',
                DevicePrinterSetup: 'printing-setup',
              },
            },
            DeviceProfileSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/device-profile/:deviceProfileId/venue/:venueId?',
              screens: {
                Details: 'details',
                Options: 'options',
                Workflow: 'workflow',
              },
            },
            DeviceSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/device/:deviceId',
              screens: {
                Details: 'details',
                PrintingSetup: 'printing-setup',
              },
            },
            Developer: 'Developer',
            SectionSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId/section/:sectionId',
              screens: {
                Details: 'details',
              },
            },
            SalesChannels: 'sales-channels',
            PrinterDetails: {
              initialRouteName: 'Details',
              path: 'store/:storeId/printer/:printerId',
              screens: {
                Details: 'details',
              },
            },
            KioskDetailsSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/kiosk/:kioskId/venue/:venueId?',
              screens: {
                Details: 'details',
              },
            },
          },
        },
      },
    },
  },
};

export const OfficeFixedLinks: FixedLink[] = [
  {
    id: 'home',
    title: 'Home',
    icon: 'estate',
    route: 'Home',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: 'tachometer-fast',
    route: 'Dashboard',
  },
];

export const OfficeLinkGroups: OfficeLinkGroup[] = [
  {
    id: 'products',
    title: 'Products',
    icon: 'tag-alt',
    links: [
      {
        id: 'products',
        title: 'Products',
        route: 'Products::Manage',
        resource: Resource.MANAGE_PRODUCTS,
      },
      {
        id: 'modifiers',
        title: 'Modifiers',
        route: 'Products::Modifiers',
        resource: Resource.MANAGE_PRODUCTS,
      },
      {
        id: 'variants',
        title: 'Variants',
        route: 'Products::Variants',
        resource: Resource.MANAGE_PRODUCTS,
      },
      {
        id: 'option-groups',
        title: 'Option Groups',
        route: 'Products::OptionGroups',
        resource: Resource.MANAGE_PRODUCTS,
      },
      {
        id: 'categories',
        title: 'Categories',
        route: 'Products::Categories',
        resource: Resource.MANAGE_PRODUCTS,
      },
      {
        id: 'menus',
        title: 'Menus',
        route: 'Products::Menus',
        resource: Resource.MANAGE_MENUS,
      },
      {
        id: 'price-lists',
        title: 'Price Lists',
        route: 'Products::PriceLists',
        feature: { featureId: FeatureIDs.PRICE_LIST },
        resource: Resource.PRICE_LISTS,
      },
      {
        id: 'courses',
        title: 'Courses',
        route: 'Products::Courses',
        feature: { featureId: FeatureIDs.COURSES },
        resource: Resource.MANAGE_PRODUCTS,
      },
    ],
  },
  {
    id: 'people',
    title: 'People',
    icon: 'users-alt',
    links: [
      {
        id: 'loyalty',
        title: 'Loyalty',
        route: 'Loyalty::Settings',
        feature: {
          featureId: FeatureIDs.LOYALTY,
        },
        resource: Resource.CUSTOMERS,
      },
      {
        id: 'customers',
        title: 'Customers',
        route: 'Customers::ManageCustomers',
        resource: Resource.CUSTOMERS,
      },
      {
        id: 'users',
        title: 'Users',
        route: 'Users::ManageUsers',
        resource: Resource.MANAGE_USERS,
      },
      {
        id: 'users',
        title: 'User Roles',
        route: 'Users::JobRoles',
        resource: Resource.MANAGE_ROLES,
      },
    ],
  },
  {
    id: 'reports',
    title: 'Reporting',
    icon: 'chart',
    links: [
      {
        id: 'insights',
        title: 'Insights',
        route: process.env.REACT_APP_INSIGHTS_URL || '',
        isExternal: true,
        resource: Resource.REPORT_SALES,
      },
      {
        id: 'insights',
        title: 'Reporting Groups',
        route: 'Reporting::ReportingGroups',
        resource: Resource.REPORT_SALES,
      },
      {
        id: 'insights',
        title: 'Settings',
        route: 'Reporting::ReportSettings',
        resource: Resource.REPORT_SALES,
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: 'setting',
    links: [
      {
        id: 'organisation',
        title: 'Organisation',
        route: 'Settings::Organisation',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'venuesAndStores',
        title: 'Venues & Stores',
        route: 'Settings::VenuesStores',
        feature: {
          featureId: FeatureIDs.MULTI_VENUE,
        },
        resource: Resource.VENUE_SETTINGS,
      },
      {
        id: 'myStore',
        title: 'My Store',
        route: 'Settings::MyStore',
        feature: {
          featureId: FeatureIDs.MULTI_VENUE,
          showTabIfDisabled: true,
        },
        resource: Resource.STORE_SETTINGS,
      },
      {
        id: 'features',
        title: 'Features',
        route: 'Features::ManageFeatures',
        resource: Resource.FEATURES,
      },
      {
        id: 'taxes',
        title: 'Taxes',
        route: 'Settings::TaxesFees',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'paymentTypes',
        title: 'Payment Types',
        route: 'Settings::PaymentTypes',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'salesChannels',
        title: 'Sales Channels',
        route: 'Settings::SalesChannels',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'printing',
        title: 'Printing',
        route: 'Settings::Printing',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'refundReasons',
        title: 'Refund Reasons',
        route: 'Settings::RefundReasons',
        feature: {
          featureId: FeatureIDs.REFUND_REASON,
        },
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'logs',
        title: 'Logs',
        isExternal: true,
        route: process.env.REACT_APP_ORDERS_URL || '',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
      {
        id: 'developer',
        title: 'Developer',
        route: 'Settings::Developer',
        resource: Resource.ORGANIZATION_SETTINGS,
      },
    ],
  },
];
