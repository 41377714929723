import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import {
  Adjustment,
  AdjustmentType,
  AdjustmentUnit,
  CUSTOM_ADJUSTMENT_ID,
  OrderItem,
  PaymentType,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { getAdjustmentValue } from '@oolio-group/order-helper';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { formatPercent } from '../../../../utils/formatPercent';
import {
  SetPriceModalMap,
  SET_PRICE_OPTIONS,
} from '../../../Modals/FunctionMaps/SetPrice';
import styles from './PaymentSurcharge.styles';
import Sticker from '../../../Shared/Sticker/Sticker';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import PaymentHeader from '../../PaymentsScreen/PaymentHeader/PaymentHeader';
import PaymentAmount from '../../PaymentsScreen/PaymentAmount/PaymentAmount';
import { camelCase } from 'change-case';

export interface PaymentSurchargeModalProps {
  orderTotal?: number;
  paymentType: PaymentType;
  onSubmit?: (paymentType: PaymentType, item?: Adjustment) => void;
  onClose?: () => void | Promise<void>;
  checkAllowAdjustmentPermission?: () => boolean;
}

export interface CardProps {
  testID: string;
  item: Adjustment;
  orderTotal?: number;
  onPress?: (item: Adjustment) => void;
}

const CardComponent: React.FC<CardProps> = ({
  testID,
  item,
  orderTotal,
  onPress,
}) => {
  const { formatCurrency } = useCurrency();

  const onCardClicked = useCallback((): void => {
    onPress && onPress(item);
  }, [onPress, item]);

  const surchargeAmount = orderTotal
    ? getAdjustmentValue(orderTotal, [item], {
        adjustmentType: item.adjustmentType,
      })
    : 0;

  const getItemAmount = {
    [AdjustmentUnit.PERCENTAGE]: function () {
      return formatPercent(item.amount);
    },
    [AdjustmentUnit.FLAT]: function () {
      return formatCurrency(item.amount);
    },
  };

  const itemAmount = item ? getItemAmount[item?.adjustmentUnit]() : 0;

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onCardClicked}
      style={styles.card}
    >
      <Sticker
        testID="sticker-rate"
        type="neutral"
        label={String(itemAmount)}
      />
      <Text testID="surcharge-name" numberOfLines={2} style={styles.cardTitle}>
        {item.name}
      </Text>
      <Text testID="surcharge-value" style={styles.cardSubtitle}>
        {formatCurrency((orderTotal || 0) + surchargeAmount)}
      </Text>
    </TouchableOpacity>
  );
};

export const Card = React.memo(CardComponent);

const PaymentSurcharge: React.FC<PaymentSurchargeModalProps> = ({
  orderTotal = 0,
  paymentType,
  onSubmit,
  onClose,
  checkAllowAdjustmentPermission = () => false,
}: PaymentSurchargeModalProps) => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const adjustmentsToShow = useMemo(() => {
    const adjustments = session?.currentVenue?.adjustments || [];
    return adjustments.filter(
      (adjustment: Adjustment) => adjustment?.allowOnPaymentType,
    );
  }, [session?.currentVenue]);

  const onCloseModal = useCallback(() => {
    closeModal();
    onClose && onClose();
  }, [closeModal, onClose]);

  const onRemoveSurcharge = useCallback(() => {
    const isAllowAdjustment = checkAllowAdjustmentPermission();
    closeModal();
    if (!isAllowAdjustment) return;
    onSubmit && onSubmit(paymentType);
  }, [checkAllowAdjustmentPermission, onSubmit, paymentType, closeModal]);

  const applyCustomSurcharge = useCallback(
    (_unitPrice: number, surcharges: Adjustment[]) => {
      closeModal();
      if (!surcharges || !surcharges.length) return;
      const surcharge = surcharges[0];
      const amount = Math.abs(surcharge.amount);
      const customSurcharge = {
        id: CUSTOM_ADJUSTMENT_ID,
        amount: amount,
        adjustmentUnit: surcharge.adjustmentUnit,
        allowOnPaymentType: false,
        adjustmentType: AdjustmentType.SURCHARGE,
      };
      onSubmit && onSubmit(paymentType, customSurcharge);
    },
    [closeModal, onSubmit, paymentType],
  );

  const onCustomSurcharge = useCallback(() => {
    const isAllowAdjustment = checkAllowAdjustmentPermission();
    if (!isAllowAdjustment) return;
    showModal(
      <SetPriceModalMap
        item={{} as OrderItem}
        onSubmit={applyCustomSurcharge}
        adjustmentType={SET_PRICE_OPTIONS.SURCHARGE_PERCENT}
        defaultPrice={orderTotal || 0}
      />,
    );
  }, [
    checkAllowAdjustmentPermission,
    showModal,
    applyCustomSurcharge,
    orderTotal,
  ]);

  const onSurchargeSelected = useCallback(
    item => {
      const isAllowAdjustment = checkAllowAdjustmentPermission();
      if (!isAllowAdjustment) return;
      closeModal();
      onSubmit && onSubmit(paymentType, item);
    },
    [closeModal, paymentType, onSubmit, checkAllowAdjustmentPermission],
  );

  return (
    <View style={styles.container}>
      <PaymentHeader
        title={translate('adjustment.applyAdjustment')}
        onClose={onCloseModal}
      />
      <View style={styles.content}>
        <PaymentAmount value={orderTotal} />
        <View style={styles.body}>
          <Text style={styles.title}>
            {translate('adjustment.selectSurcharge')}
          </Text>
          <ScrollView horizontal style={styles.cards}>
            {adjustmentsToShow.map((adjustment, i) => (
              <Card
                key={i}
                testID={`surcharge-${camelCase(
                  adjustment.name || i.toString(),
                )}`}
                item={adjustment}
                orderTotal={orderTotal}
                onPress={onSurchargeSelected}
              />
            ))}
          </ScrollView>
        </View>
        <View style={styles.actions}>
          <TreatButton
            testID="btn-custom"
            height={60}
            type="neutral"
            label={translate('adjustment.customAdjustment')}
            onPress={onCustomSurcharge}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ flex: 1, marginRight: 5 }}
          />
          <TreatButton
            testID="btn-noSurcharge"
            height={60}
            type="focus"
            label={translate('adjustment.noAdjustment')}
            onPress={onRemoveSurcharge}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ flex: 1, marginLeft: 5 }}
          />
        </View>
      </View>
    </View>
  );
};

export default PaymentSurcharge;
