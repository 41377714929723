import React, { useCallback, useMemo } from 'react';
import { ViewStyle } from 'react-native';
import { OrderStatus } from '@oolio-group/domain';
import { translate, useCurrency } from '@oolio-group/localization';
import { PaymentTypeDisplay } from '../../../../screens/POS/Orders/OpenOrders/OpenOrders.types';
import ButtonIcon from '../../../Shared/TreatButton/ButtonIcon';
import ButtonActions from '../../../Shared/TreatButton/ButtonActions';

export interface PrintReceiptButtonProps {
  orderStatus: OrderStatus;
  options: PaymentTypeDisplay[];
  onPrintReceipt: (index?: number) => void;
  reprintDocket?: (index?: number) => void;
  containerStyle?: ViewStyle | ViewStyle[];
}

const DEFAULT_OPTIONS = {
  REPRINT_DOCKETS: 'REPRINT_DOCKETS',
  PRINT_ALL: 'PRINT_ALL',
};

const PrintReceiptButton: React.FC<PrintReceiptButtonProps> = ({
  options,
  onPrintReceipt,
  reprintDocket,
  orderStatus,
  containerStyle,
}) => {
  const { formatCurrency } = useCurrency();

  const includedPrintOrderOptions = useMemo(
    () =>
      options.concat([
        {
          name: translate('button.printOrder'),
          amount: 0,
          id: DEFAULT_OPTIONS.PRINT_ALL,
        },
        {
          name: translate('button.reprintOrder'),
          amount: 0,
          id: DEFAULT_OPTIONS.REPRINT_DOCKETS,
        },
      ]),
    [options],
  );

  const onPressOption = useCallback(
    (selectedIndex: number) => {
      if (
        includedPrintOrderOptions[selectedIndex].id ===
        DEFAULT_OPTIONS.REPRINT_DOCKETS
      ) {
        reprintDocket && reprintDocket();
      } else if (
        includedPrintOrderOptions[selectedIndex].id ===
        DEFAULT_OPTIONS.PRINT_ALL
      ) {
        onPrintReceipt();
      } else {
        onPrintReceipt(selectedIndex);
      }
    },
    [includedPrintOrderOptions, onPrintReceipt, reprintDocket],
  );

  const printOptions = useMemo(
    () =>
      includedPrintOrderOptions.map((action, i) => ({
        label: Object.keys(DEFAULT_OPTIONS).includes(action.id)
          ? action.name
          : `${action.name} (${formatCurrency(action.amount)})`,
        action: () => onPressOption(i),
      })),
    [formatCurrency, includedPrintOrderOptions, onPressOption],
  );

  const showPopoverPayments =
    (orderStatus === OrderStatus.COMPLETED &&
      includedPrintOrderOptions.length > 1) ||
    (orderStatus !== OrderStatus.COMPLETED &&
      includedPrintOrderOptions.length >= 1) ||
    true;

  if (!showPopoverPayments)
    return (
      <ButtonIcon
        testID="print-receipt-button"
        size={34}
        icon="print"
        type="neutralLight"
        onPress={() => onPrintReceipt()}
      />
    );

  return (
    <ButtonActions
      testID="print-receipt-button"
      icon="print"
      label="Print"
      height={34}
      type="neutralLight"
      actions={printOptions}
      containerStyle={containerStyle}
    />
  );
};

export default PrintReceiptButton;
