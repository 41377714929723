import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const cartTextStyle = {
  fontSize: CART_FONT_SIZE,
  lineHeight: 20,
  paddingVertical: 4,
  color: theme.colors.dark,
  fontFamily: theme.fonts.medium,
};

const styles = StyleSheet.create({
  container: {
    minHeight: 28,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  quantity: {
    minWidth: 28,
    height: '100%',
    marginRight: 4,
    fontSize: CART_FONT_SIZE,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  quantityText: {
    textAlign: 'center',
    ...cartTextStyle,
  },
  name: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  nameText: {
    flex: 1,
    ...cartTextStyle,
  },
  priceText: {
    minWidth: 60,
    marginLeft: 10,
    textAlign: 'right',
    ...cartTextStyle,
  },
});

export default styles;
