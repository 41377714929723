import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { Feature } from '@oolio-group/domain';
import theme from '../../../../../common/default-theme';
import styles from './FeatureGrid.styles';
import FeatureTile from '../FeatureTile/FeatureTile';
import Icon from '../../../../../components/Icon/Icon';

export interface FeatureGridProps {
  features: Feature[];
  onPressFeature: (selectedFeature: Feature) => void;
}

const FeatureGrid: React.FC<FeatureGridProps> = ({
  features,
  onPressFeature,
}) => {
  const { translate } = useTranslation();

  return (
    <View style={styles.gridContainer}>
      {features?.length ? (
        features.map((feature: Feature) => {
          return (
            <FeatureTile
              key={feature.id}
              feature={feature}
              onPress={onPressFeature}
            />
          );
        })
      ) : (
        <View style={styles.emptyList}>
          <Icon name="exclamation-triangle" color={theme.colors.states.focus} />
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <Text testID="text-no-feature" style={{ marginLeft: 12 }}>
            {translate('backOffice.features.noFeaturesFound')}
          </Text>
        </View>
      )}
    </View>
  );
};

export default FeatureGrid;
