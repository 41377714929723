import { Platform } from 'react-native';
import { MMKVLoader, MMKVInstance } from 'react-native-mmkv-storage';

const MMKV_POS_INSTANCE_ID = 'POS_GENERAL_STORAGE';

export class MMKV {
  mmkv?: MMKVInstance;
  constructor() {
    if (Platform.OS !== 'web') {
      this.mmkv = new MMKVLoader()
        .withInstanceID(MMKV_POS_INSTANCE_ID)
        .withEncryption()
        .initialize();
    }
  }

  getItem = async <T>(key: string): Promise<T | undefined> => {
    try {
      const data = await this.mmkv?.getStringAsync(key);

      if (data && typeof data === 'string') {
        const value = JSON.parse(data) as T;
        return value;
      }
    } catch (error) {}
    return undefined;
  };

  setItem = async <T>(key: string, item: T): Promise<boolean | undefined> => {
    try {
      const string = JSON.stringify(item);
      await this.mmkv?.setStringAsync(key, string);
      return true;
    } catch (error) {
      return undefined;
    }
  };

  removeItem = async (key: string): Promise<boolean | undefined> => {
    try {
      await this.mmkv?.removeItem(key);
      return true;
    } catch (error) {
      return undefined;
    }
  };

  clear = async () => {
    await this.mmkv?.clearMemoryCache();
    await this.mmkv?.clearStore();
  };

  addSubscription = () => undefined;

  removeSubscription = () => undefined;

  getStorage = () => this.mmkv;
}

const mmkvStorage = new MMKV();
const storageInterface = {
  getItem: mmkvStorage.getItem,
  setItem: mmkvStorage.setItem,
  removeItem: mmkvStorage.removeItem,
};

export { mmkvStorage, storageInterface };
