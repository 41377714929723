import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import theme from '../../../common/default-theme';
import styles from './Section.styles';

export interface SectionProps {
  title?: string;
  subtitle?: string;
  children: React.ReactNode | React.ReactNode[];
  layoutWidth?: 'small' | 'medium' | 'large' | 'full';
}

const getLayoutWidth = (width: string): ViewStyle => {
  switch (width) {
    case 'small':
      return { maxWidth: theme.layoutWidth.s };
    case 'medium':
      return { maxWidth: theme.layoutWidth.m };
    case 'large':
      return { maxWidth: theme.layoutWidth.l };
    case 'full':
      return { flex: 1 };
    default:
      return { maxWidth: theme.layoutWidth.s };
  }
};

const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  children,
  layoutWidth,
}: SectionProps) => {
  return (
    <View style={[styles.container, getLayoutWidth(layoutWidth || 'small')]}>
      {!!title && (
        <View
          testID="section-title"
          // eslint-disable-next-line react-native/no-inline-styles
          style={[styles.text, { paddingVertical: !!subtitle ? 12 : 8 }]}
        >
          <Text style={styles.title}>{title}</Text>
          {!!subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        </View>
      )}
      <View style={styles.form}>{children}</View>
    </View>
  );
};

export default Section;
