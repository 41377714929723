import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

export const styles = StyleSheet.create({
  container: {
    width: 700,
    alignSelf: 'center',
  },
  title: {
    height: 44,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    flex: 1,
    marginRight: 44,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    padding: 30,
    marginTop: 8,
    width: 700,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    alignSelf: 'center',
    ...theme.shadow.strong,
  },
  input: {
    marginTop: 20,
  },
  btnConfirm: {
    marginTop: 30,
    width: 100,
    marginLeft: 'auto',
  },
  channelName: {
    flex: 1,
    alignSelf: 'center',
    color: theme.colors.black1,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.semibold,
  },
  optionContainer: {
    flexDirection: 'row',
    marginBottom: 12,
    justifyContent: 'center',
    height: 62,
    borderBottomColor: theme.colors.grey3,
    borderBottomWidth: 1,
  },
  optionItemStyle: {
    marginLeft: 10,
    height: 44,
    minWidth: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
  optionText: {
    fontFamily: theme.fonts.semibold,
  },
  notSupported: {
    flex: 1,
    alignSelf: 'center',
  },
});

export default styles;
