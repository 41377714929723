import {
  Product,
  UpdateProductsAvailabilityEventInput,
} from '@oolio-group/domain';
import { useCallback } from 'react';
import { inventoryProductFragment, useProducts } from '../products/useProducts';
import { cloneDeep, keyBy } from 'lodash';
import { IMap } from '../../../../src/screens/BackOffice/Reports/types';
import { areProductsAvailabilityUpdatedViaSubscription } from '../../../../src/state/cache';

interface UseProductEventsProps {
  productEventsHandler: (
    events: UpdateProductsAvailabilityEventInput[],
  ) => void;
}
export const useProductEvents = (): UseProductEventsProps => {
  const { getProductsFromCache, updateProductsInCache } = useProducts(
    undefined,
    inventoryProductFragment,
  );

  const productEventsHandler = useCallback(
    async (events: UpdateProductsAvailabilityEventInput[]) => {
      const existingProductMaps = keyBy(getProductsFromCache(), 'id');
      const updatedProducts: IMap<Product> = {};

      events.forEach(event => {
        const { products } = event;
        products.forEach(product => {
          const updatedProduct = cloneDeep(existingProductMaps[product.id]);
          if (updatedProduct) {
            updatedProduct.storesInventory = product.storesInventory;
            updatedProducts[product.id] = updatedProduct;
          }
        });
      });

      Object.keys(updatedProducts).length &&
        updateProductsInCache(updatedProducts);
      Object.keys(updatedProducts).length &&
        areProductsAvailabilityUpdatedViaSubscription(true);
    },
    [getProductsFromCache, updateProductsInCache],
  );

  return {
    productEventsHandler,
  };
};
