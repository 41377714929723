import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { User, CreateUserRequest } from '@oolio-group/domain';
import {
  useTranslation,
  formatPhoneNumber,
  parsePhoneNumber,
} from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useUsers } from '../../../../hooks/app/useUsers';
import { useUserRoles } from '../../../../hooks/app/users/useUserRoles';
import { useNotification } from '../../../../hooks/Notification';
import { Operation } from '../../../../types/Operation';
import { mapUserRolesByRoleIdAndStoreIds } from './UsersSettings/Permissions/userRoleInputUtility';
import { CreateUserModal } from './CreateUser/CreateUserModal';
import Icon from '../../../../components/Icon/Icon';
import Search from '../../../../components/Shared/Search/Search';
import Section from '../../../../components/Office/Section/Section';
import Pagination from '../../../../components/Office/Pagination/Pagination';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../common/default-theme';
import { useSession } from '../../../../hooks/app/useSession';
import styles from './ManageUsers.styles';

interface UserRowProps {
  userData: User;
}

export const UserRow: React.FC<UserRowProps> = ({ userData }: UserRowProps) => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();

  const [session] = useSession();
  const orgCountry = session.currentOrganization?.country as unknown as string;

  const userId = userData.id;

  const { userRoles: userRolesById, fetchUserRoles } = useUserRoles({
    userId: userId,
  });

  useEffect(() => {
    if (isFocused && userId) {
      fetchUserRoles(userId);
    }
  }, [fetchUserRoles, userId, isFocused]);

  const navigateToUserDetails = useCallback(() => {
    navigation.navigate('UserDetails', {
      userId: userData.id,
    });
  }, [navigation, userData.id]);

  const userRoles = useMemo(() => {
    return mapUserRolesByRoleIdAndStoreIds(userRolesById);
  }, [userRolesById]);

  const hasPermissionsAssigned = Object.values(userRoles).length > 0;

  const userCountry = parsePhoneNumber(userData?.phone).countryCode;

  return (
    <TouchableOpacity
      testID="usersRow"
      onPress={navigateToUserDetails}
      style={[
        theme.tables.row,
        // eslint-disable-next-line react-native/no-inline-styles
        { height: hasPermissionsAssigned ? 50 : 64 },
      ]}
    >
      <View style={styles.cellName}>
        <Text numberOfLines={1} style={styles.name}>
          {`${userData.isOwner ? '★ ' : ''}${userData.firstName} ${
            userData.lastName
          }`}
        </Text>
        {!hasPermissionsAssigned ? (
          <Text style={styles.warning}>
            {translate('backOfficeUsers.noPermissionsAssigned')}
          </Text>
        ) : (
          false
        )}
      </View>
      <Text numberOfLines={1} style={styles.cellEmail}>
        {userData.email}
      </Text>
      <Text numberOfLines={1} style={styles.cellPhone}>
        {userData?.phone
          ? formatPhoneNumber(userData?.phone, {
              countryCode: userCountry,
              intl: userCountry != orgCountry,
            })
          : 'N/A'}
      </Text>
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export const ManageUsers: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    usersArray,
    refetch,
    error,
    loading,
    createUser,
    createdUserId,
    operation,
  } = useUsers();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [createdFlag, setCreatedFlag] = useState(false);
  const [selectedEditMore, setSelectedEditMore] = useState(true);

  const onPressCreate = useCallback(() => {
    showModal(
      <CreateUserModal
        onDismiss={closeModal}
        createUser={(input: CreateUserRequest, isEditMore: boolean) => {
          setSelectedEditMore(isEditMore);
          setCreatedFlag(false);
          createUser(input);
          closeModal();
        }}
      />,
    );
  }, [closeModal, createUser, showModal]);

  useEffect(() => {
    if (isFocused) {
      refetch();
      setSearchString('');
    }
  }, [isFocused, refetch, setSearchString]);

  useEffect(() => {
    if (
      createdUserId &&
      !error &&
      !loading &&
      operation === Operation.CREATE &&
      !createdFlag
    ) {
      showNotification({
        success: true,
        message: translate('backOfficeUsers.userCreatedSuccessfully'),
      });
      setCreatedFlag(true);
      closeModal();
      if (selectedEditMore) {
        navigation.navigate('UserDetails', {
          userId: createdUserId,
        });
      }
    }
  }, [
    createdUserId,
    error,
    loading,
    operation,
    showNotification,
    translate,
    navigation,
    closeModal,
    createdFlag,
    selectedEditMore,
  ]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const filteredList = useMemo(() => {
    return usersArray
      .filter(user =>
        `${user.name} ${user.firstName} ${user.lastName} ${user.email} ${user.phone}`
          .toLowerCase()
          .includes(searchString.toLowerCase()),
      )
      .sort((a, b) => a.firstName.localeCompare(b.firstName));
  }, [usersArray, searchString]);

  const pageItems = useMemo(
    () =>
      filteredList.slice(
        (currentPage - 1) * DEFAULT_PAGE_SIZE,
        currentPage * DEFAULT_PAGE_SIZE,
      ),
    [filteredList, currentPage],
  );

  return (
    <ScreenLayout
      hideFooter={true}
      title={translate('navigation.users', { appName: translate('appName') })}
    >
      <Section
        layoutWidth="medium"
        title={translate('backOfficeUsers.manageUsers')}
        subtitle={translate('backOfficeUsers.manageUsersSubtitle')}
      >
        <View style={styles.filters}>
          <Search
            testID="search-users"
            value={searchString}
            onChangeText={setSearchString}
            placeholder={translate('backOfficeUsers.searchPlaceholder')}
            containerStyle={styles.searchContainer}
          />
          <CreateButton onPress={onPressCreate} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text
              numberOfLines={1}
              style={[theme.tables.headerText, styles.headerName]}
            >
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellEmail]}>
              {translate('form.email')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerPhone]}>
              {translate('form.phone')}
            </Text>
            <View style={theme.tables.disclosure} />
          </View>
          {filteredList.length > 0 ? (
            pageItems.map((user: User) => (
              <UserRow userData={user} key={user.id} />
            ))
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {translate('common.noMatches', {
                  entity: `"${searchString}"`,
                })}
              </Text>
            </View>
          )}
          <Pagination
            page={currentPage}
            onPageChange={setCurrentPage}
            pageLength={pageItems.length}
            dataLength={filteredList.length}
            entityName={translate('backofficeManageRoles.userCount')}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
