import React from 'react';
import { View, ViewStyle, StyleSheet } from 'react-native';
import theme from '../../common/default-theme';
import Svg, { Path, G } from 'react-native-svg';

export interface LogoProps {
  width?: number;
  height?: number;
  type?: 'logotype' | 'brandmark';
  color: 'light' | 'dark' | 'primary';
  style?: ViewStyle | ViewStyle[];
}

const Logo: React.FC<LogoProps> = ({ style, type, color, width, height }) => {
  const getFillColor = (color: string) => {
    switch (color) {
      case 'light':
        return theme.colors.light;
      case 'dark':
        return theme.colors.dark;
      default:
        return theme.colors.primary;
    }
  };

  return (
    <View style={[styles.container, style]} testID="app-logo">
      {type === 'brandmark' ? (
        <Svg
          width={width ? width : 70}
          height={height ? height : 34}
          viewBox="0 0 404 242"
        >
          <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <G
              transform="translate(-200.000000, -200.000000)"
              fill={getFillColor(color)}
            >
              <G transform="translate(200.000000, 200.000000)">
                <Path
                  d="M283,0 C349.826455,0 404,54.1735453 404,121 C404,187.826455 349.826455,242 283,242 C251.847662,242 223.444958,230.227418 201.998094,210.888462 C180.555924,230.227053 152.152821,242 121,242 C54.1735453,242 0,187.826455 0,121 C0,54.1735453 54.1735453,0 121,0 C152.152821,0 180.555924,11.7729468 202.002904,31.1124375 C223.444958,11.7725816 251.847662,0 283,0 Z M283,81 C260.90861,81 243,98.90861 243,121 C243,143.09139 260.90861,161 283,161 C305.09139,161 323,143.09139 323,121 C323,98.90861 305.09139,81 283,81 Z M121,81 C98.90861,81 81,98.90861 81,121 C81,143.09139 98.90861,161 121,161 C143.09139,161 161,143.09139 161,121 C161,98.90861 143.09139,81 121,81 Z"
                  id="Symbol_Primary"
                ></Path>
              </G>
            </G>
          </G>
        </Svg>
      ) : (
        <Svg
          width={width ? width : 130}
          height={height ? height : 34}
          viewBox="0 0 926 242"
        >
          <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <G
              transform="translate(-804.000000, -200.000000)"
              fill={getFillColor(color)}
            >
              <G transform="translate(804.000000, 200.000000)">
                <Path
                  d="M805,0 C871.826455,0 926,54.1735453 926,121 C926,187.826455 871.826455,242 805,242 C738.173545,242 684,187.826455 684,121 C684,54.1735453 738.173545,0 805,0 Z M805,81 C782.90861,81 765,98.90861 765,121 C765,143.09139 782.90861,161 805,161 C827.09139,161 845,143.09139 845,121 C845,98.90861 827.09139,81 805,81 Z M604,0 L664,0 C669.522847,-1.01453063e-15 674,4.4771525 674,10 L674,232 C674,237.522847 669.522847,242 664,242 L604,242 C598.477153,242 594,237.522847 594,232 L594,10 C594,4.4771525 598.477153,1.01453063e-15 604,0 Z M424,242 C418.477153,242 414,237.522847 414,232 L414,10 C414,4.4771525 418.477153,1.01453063e-15 424,0 L484,0 C489.522847,-1.01453063e-15 494,4.4771525 494,10 L494,162 L574,162 C579.522847,162 584,166.477153 584,172 L584,232 C584,237.522847 579.522847,242 574,242 L424,242 Z M283,0 C349.826455,0 404,54.1735453 404,121 C404,187.826455 349.826455,242 283,242 C251.847662,242 223.444958,230.227418 201.998094,210.888462 C180.555924,230.227053 152.152821,242 121,242 C54.1735453,242 0,187.826455 0,121 C0,54.1735453 54.1735453,0 121,0 C152.152821,0 180.555924,11.7729468 202.002904,31.1124375 C223.444958,11.7725816 251.847662,0 283,0 Z M283,81 C260.90861,81 243,98.90861 243,121 C243,143.09139 260.90861,161 283,161 C305.09139,161 323,143.09139 323,121 C323,98.90861 305.09139,81 283,81 Z M121,81 C98.90861,81 81,98.90861 81,121 C81,143.09139 98.90861,161 121,161 C143.09139,161 161,143.09139 161,121 C161,98.90861 143.09139,81 121,81 Z"
                  id="Logotype_Primary"
                ></Path>
              </G>
            </G>
          </G>
        </Svg>
      )}
    </View>
  );
};

export default Logo;

Logo.defaultProps = {
  type: 'brandmark',
  color: 'primary',
};

const styles = StyleSheet.create({
  container: {
    padding: 0,
    alignItems: 'center',
    resizeMode: 'contain',
    justifyContent: 'center',
  },
});
