import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import * as constants from '../constants';
import * as settings from '../state/preferences';
import { tokenUtility } from '../state/tokenUtility';

export const refreshTokenAccessToken = async () => {
  const tokenState = await settings.getAuthenticationState();
  const tokenRequest = new TokenRequest({
    client_id: constants.auth0Config.clientId,
    redirect_uri: constants.auth0Config.redirectUrl,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    refresh_token: tokenState?.refreshToken,
  });
  const issuerConfig = await AuthorizationServiceConfiguration.fetchFromIssuer(
    constants.auth0Config.issuer || '',
    new FetchRequestor(),
  );
  const response = (await new BaseTokenRequestHandler(
    new FetchRequestor(),
  ).performTokenRequest(
    issuerConfig,
    tokenRequest,
  )) as unknown as Promise<Response>;

  const { accessToken, refreshToken, expiresIn } =
    response as unknown as TokenResponse;

  const newTokenPayload: constants.TokenInfo = {
    ...tokenState,
    token: accessToken as string,
    refreshToken: refreshToken as string,
    expiresAfter: settings.getExpiresAfter(expiresIn as number),
  };
  tokenUtility.setTokenInfo(newTokenPayload);
  await settings.setAuthenticationState(newTokenPayload);
  return { accessToken };
};
