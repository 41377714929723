import React, { useCallback, useEffect, useState } from 'react';
import { AlternateName, LOCALE, ModifierGroup } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { decodeAlternateNameValuesToBase64 } from '@oolio-group/client-utils';
import { updateAlternateNamesWithNewValue } from '../../../../utils/AlternateNameHelper';
import { AlternateNamesSection } from '../ProductSettings/General/AlternateNamesSection';
import TreatModal from '../../../../components/Shared/Modals/Modal/Modal';

export enum ConfirmModalType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  FOCUS = 'focus',
  NEUTRAL = 'neutral',
}

export interface TranslationModalProps {
  onConfirm?: (id: string, alternateNames: AlternateName[]) => void;
  alternateNames: AlternateName[];
  id: string;
}

const TranslationsModal: React.FC<TranslationModalProps> = ({
  onConfirm,
  alternateNames,
  id,
}: TranslationModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [localisedNames, setLocalisedNames] = useState<AlternateName[]>();

  const onChange = useCallback(
    (prop: string, value: string, locale: LOCALE): void => {
      const temp = updateAlternateNamesWithNewValue(
        { alternateNames: localisedNames } as ModifierGroup,
        locale,
        prop,
        value,
      ) as ModifierGroup;
      setLocalisedNames(temp?.alternateNames || []);
    },
    [localisedNames],
  );

  useEffect(() => {
    if (alternateNames?.length) {
      setLocalisedNames(decodeAlternateNameValuesToBase64(alternateNames));
    }
  }, [alternateNames]);

  const onUpdate = useCallback(() => {
    if (localisedNames && localisedNames?.length) {
      onConfirm && onConfirm(id, localisedNames);
    } else {
      closeModal();
    }
  }, [closeModal, onConfirm, id, localisedNames]);

  return (
    <TreatModal
      size="medium"
      type="neutral"
      title="Update Translations"
      onConfirm={{ label: translate('button.update'), action: onUpdate }}
      onDismiss={{ action: closeModal }}
    >
      <AlternateNamesSection
        id={id}
        onChangeAlternateName={onChange}
        alternateNames={localisedNames || []}
      />
    </TreatModal>
  );
};

export default TranslationsModal;
