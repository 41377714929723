import React, { useCallback, useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { TileItem } from '../../types';
import styles from './Modals.styles';
import theme from '../../../../../../common/default-theme';
import modalStyles from '../../../../../../components/Shared/Modals/Modal/Modal.styles';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import SelectColour from '../../../../../../components/Shared/Select/SelectColour';

export interface AddExistingProductsProps {
  onUpdateProduct: (input: TileItem) => void;
  item: TileItem;
}

const EditProduct: React.FC<AddExistingProductsProps> = ({
  onUpdateProduct,
  item,
}) => {
  const { translate } = useTranslation();
  const [selectedColor, setSelectedColor] = useState<string>();

  const { closeModal } = useModal();

  const onChangeColor = useCallback((color: string) => {
    setSelectedColor(color);
  }, []);

  const onUpdate = useCallback(() => {
    onUpdateProduct({ ...item, color: selectedColor });
  }, [item, onUpdateProduct, selectedColor]);

  useEffect(() => {
    setSelectedColor(item?.color || theme.colors.deepPurple);
  }, [item?.color]);

  return (
    <View style={styles.background}>
      <View style={styles.container}>
        <View style={modalStyles.title}>
          <Text style={styles.titleText}>{translate('menus.editTile')}</Text>
        </View>
        <View style={styles.content}>
          <SelectColour
            testID="select-colour"
            title={translate('menus.tileColour')}
            value={selectedColor}
            onChange={onChangeColor}
            containerStyle={styles.input}
          />
        </View>
        <View style={styles.actions}>
          <TreatButton
            type="neutral"
            testID="btn-confirm"
            onPress={onUpdate}
            label={translate('menus.updateTile')}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginBottom: 10 }}
          />
          <TreatButton
            type="cancel"
            testID="btn-dismiss"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
        </View>
      </View>
    </View>
  );
};

export default EditProduct;
