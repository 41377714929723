import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import {
  CatalogueSchedule,
  CatalogueScheduleItem,
  DayOfWeek,
  TimeRange,
} from '@oolio-group/domain';
import commonStyles from './MenuSchedules.styles';
import styles from './MenuScheduleRow.styles';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import { daysOption } from '../../../../../utils/dateHelper';
import { useNavigation } from '@react-navigation/native';
import { translate } from '@oolio-group/localization';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

interface MenuScheduleRowProps {
  menuSchedule: CatalogueScheduleItem;
  menuOptions?: { label: string; value: string }[];
  onUpdateActiveMenu?: (id: string) => void;
}

const MenuScheduleRow: React.FC<MenuScheduleRowProps> = ({
  menuSchedule,
  menuOptions,
  onUpdateActiveMenu,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigation = useNavigation();

  const onNavigateToEdit = useCallback(
    (id: string) => {
      navigation.navigate('ScheduleDetail', {
        title: translate('menus.menuSchedules.details'),
        id,
      });
    },
    [navigation],
  );

  const menuScheduleSubDetailDate = useCallback(
    (schedule: CatalogueSchedule) => {
      return (
        (schedule.dateRange?.startDate || schedule.dateRange?.endDate) && (
          <Text
            style={[
              styles.menuScheduleRowSubDetailRowText,
              styles.menuScheduleRowSubDetailDateRangeContainer,
            ]}
          >
            {schedule.dateRange?.startDate &&
              `From ${format(schedule.dateRange.startDate, 'P', {
                locale: ptBrLocale,
              })}`}{' '}
            {schedule.dateRange?.endDate &&
              `To ${format(schedule.dateRange.endDate, 'P', {
                locale: ptBrLocale,
              })}`}
          </Text>
        )
      );
    },
    [],
  );

  const menuScheduleSubDetailTime = useCallback((timeBlock: TimeRange) => {
    return timeBlock?.days?.length > 0 &&
      !timeBlock?.days?.includes(DayOfWeek.ALL) ? (
      <Text
        style={[
          styles.menuScheduleRowSubDetailRowText,
          styles.menuScheduleRowSubDetailTimeBlockDay,
        ]}
      >
        {timeBlock.days
          .map(
            day => daysOption.find(dayOption => dayOption.value === day)?.label,
          )
          .join(', ')}
      </Text>
    ) : null;
  }, []);

  return (
    <View style={styles.menuScheduleRowContainer}>
      <View style={styles.menuScheduleRowMainDetail}>
        <ButtonIcon
          icon={expanded ? 'angle-down' : 'angle-right'}
          onPress={() => setExpanded(cur => !cur)}
          type="cancel"
        />
        <InputToggle
          testID="menu-schedule-row-toggle"
          isToggled={menuSchedule.isActive || false}
          type="switch"
          containerStyle={commonStyles.cellAction}
          onToggle={() =>
            onUpdateActiveMenu && onUpdateActiveMenu(menuSchedule.id)
          }
        />
        <View style={commonStyles.cellMenus}>
          <Text>
            {
              (menuOptions || []).find(
                option => option.value === menuSchedule.menu,
              )?.label
            }
          </Text>
        </View>
        <View style={commonStyles.cellOrderTypes}>
          <Text>
            {menuSchedule.orderTypes
              .map(orderType => orderType.name)
              .join(', ')}
          </Text>
        </View>
        <View style={commonStyles.cellStores}>
          <Text>{menuSchedule.stores.map(store => store.name).join(', ')}</Text>
        </View>
        <ButtonIcon
          type="neutralLight"
          icon={'pen'}
          size={34}
          containerStyle={styles.scheduleRowEditIcon}
          onPress={() => onNavigateToEdit(menuSchedule.id)}
        />
      </View>
      {expanded ? (
        <View style={styles.menuScheduleRowSubDetailContainer}>
          {menuSchedule.schedules.map((schedule, index) => {
            return (
              <View key={index} style={styles.menuScheduleRowSubDetailRow}>
                {menuScheduleSubDetailDate(schedule)}
                <View
                  style={styles.menuScheduleRowSubDetailDateRangeRowContainer}
                >
                  {(schedule.timeBlocks || []).map((timeBlock, index) => {
                    return (
                      <View
                        key={index}
                        style={styles.menuScheduleRowSubDetailRoutineContainer}
                      >
                        {menuScheduleSubDetailTime(timeBlock)}
                        <Text style={styles.menuScheduleRowSubDetailRowText}>
                          {timeBlock?.timeSlot?.startTime} to{' '}
                          {timeBlock?.timeSlot?.endTime}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          })}
        </View>
      ) : null}
    </View>
  );
};

export default MenuScheduleRow;
