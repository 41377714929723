import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 240,
    backgroundColor: theme.colors.black,
  },
  collapsed: {
    width: 28,
    paddingVertical: 16,
    backgroundColor: theme.colors.black,
  },
  btnCollapse: {
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fixed: {
    rowGap: 4,
    paddingBottom: 16,
    paddingHorizontal: 16,
    zIndex: 1,
  },
  links: {
    gap: 16,
    paddingBottom: 16,
    paddingHorizontal: 16,
    zIndex: 1,
  },
});

export default styles;
