import React, { useEffect, useMemo } from 'react';
import MenuDetail from './MenuDetail';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useIsFocused, useRoute } from '@react-navigation/native';
import MenuLayout from '../MenuLayout/MenuLayout';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import { CatalogueType, EntityType } from '@oolio-group/domain';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { productsFragment, variantsFragment } from './graphql';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import { useCategories } from '../../../../../hooks/app/categories/useCategories';
import keyBy from 'lodash/keyBy';
import difference from 'lodash/difference';
import { ProductItemType } from '../types';
import { translate } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useVenues } from '../../../../../hooks/app/useVenues';

const Tab = createMaterialTopTabNavigator();

const CreateMenuTab: React.FC<{}> = () => {
  const route = useRoute();
  const params = route.params as {
    menuId: string;
    name: string;
    type: CatalogueType;
  };
  const { menuId, type } = params;
  const { showNotification } = useNotification();

  const {
    updateMenu,
    loading: menusLoading,
    error: menuError,
    deleteMenu,
    menus: menuMaps,
    getMenu,
  } = useMenus();
  const menu = menuMaps[menuId];
  const isFocused = useIsFocused();

  const availabilityStoreIds = menu?.stores.map(store => store.id);
  const {
    products: productMaps,
    getAllProducts,
    loading: productsLoading,
  } = useProducts(
    undefined,
    productsFragment,
    availabilityStoreIds,
    'cache-first',
  );

  const {
    variants: variantMaps,
    getAllVariants,
    loading: variantLoading,
  } = useVariants(
    undefined,
    variantsFragment,
    availabilityStoreIds,
    'cache-first',
  );

  const {
    getPages,
    pages: pageMaps,
    loading: pageLoading,
    createOrUpdatePage,
    error: pageError,
  } = usePages();

  const {
    getCategories,
    categoryMaps: categoryMaps,
    loading: categoriesLoading,
  } = useCategories({ fetchPolicy: 'cache-and-network' });

  const { getVenues, venues: venueMaps, loading: venuesLoading } = useVenues();

  const variantProductMaps = useMemo(
    () =>
      keyBy(
        Object.values(variantMaps).flatMap(variant => variant.products),
        'id',
      ),
    [variantMaps],
  );

  const productItems = useMemo<ProductItemType[]>(() => {
    const normalProductIds = difference(
      Object.keys(productMaps),
      Object.keys(variantProductMaps),
    );
    const allProducts = normalProductIds.map(productId => ({
      name: productMaps[productId].name,
      id: productId,
      entityType: EntityType.Product,
    }));

    const allVariants = Object.values(variantMaps).map(variant => ({
      name: variant.name,
      id: variant.id,
      entityType: EntityType.Variant,
    }));
    return allProducts.concat(allVariants);
  }, [productMaps, variantProductMaps, variantMaps]);

  const venues = useMemo(() => Object.values(venueMaps), [venueMaps]);

  useEffect(() => {
    if (!isFocused || !menuId) return;
    getAllProducts();
    getAllVariants();
    getPages();
    getCategories();
    getVenues();
    getMenu(menuId);
  }, [
    getAllProducts,
    getAllVariants,
    getCategories,
    getMenu,
    getPages,
    getVenues,
    isFocused,
    menuId,
  ]);

  const error = pageError || menuError;

  useEffect(() => {
    if (!error) return;
    showNotification({ message: error, error: true });
  }, [error, showNotification]);

  const isPosMenuType = type === CatalogueType.POS;

  const loading =
    productsLoading ||
    variantLoading ||
    pageLoading ||
    categoriesLoading ||
    menusLoading ||
    venuesLoading;

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} previousRoute={'Menus'} />
      )}
      initialRouteName="MenuDetail"
      lazy
      // disable swipe behavior of tab navigation
      gestureHandlerProps={{ enabled: false, activeOffsetX: [-1000, 1000] }}
    >
      <Tab.Screen
        name="MenuDetail"
        options={{ tabBarLabel: params.name }}
        initialParams={params}
      >
        {() => (
          <MenuDetail
            menu={menu}
            pageMaps={pageMaps}
            loading={loading}
            venues={venues}
            updateMenu={updateMenu}
            deleteMenu={deleteMenu}
          />
        )}
      </Tab.Screen>
      {isPosMenuType && (
        <Tab.Screen
          name="MenuLayout"
          options={{
            tabBarLabel: translate('menus.menuLayout'),
          }}
        >
          {() => (
            <MenuLayout
              menu={menu}
              loading={loading}
              productItems={productItems}
              pageMaps={pageMaps}
              categoryMaps={categoryMaps}
              updateMenu={updateMenu}
              createOrUpdatePage={createOrUpdatePage}
            />
          )}
        </Tab.Screen>
      )}
    </Tab.Navigator>
  );
};

export default CreateMenuTab;
