import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import {
  Booking,
  BookingFilterInput,
  CreateBookingInput,
  UpdateBookingInput,
} from '@oolio-group/domain';
import { useCallback, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { CREATE_BOOKING, GET_BOOKINGS, UPDATE_BOOKING } from './graphql';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import { useState } from 'react';
import { parseApolloError } from '../../../utils/errorHandlers';
import { keyBy } from 'lodash';

export interface UseOrderTypeProps {
  loading: boolean;
  error: string | undefined;
  bookingMaps: Record<string, Booking>;
  getBookings: (filter: BookingFilterInput) => void;
  createBooking: (input: CreateBookingInput) => void;
  updateBooking: (input: UpdateBookingInput) => void;
}

interface UseBookingsInput {
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
}

export function useBookings(input?: UseBookingsInput): UseOrderTypeProps {
  const [bookingMaps, setBookingMaps] = useState<Record<string, Booking>>({});
  const [getBookingsReq, getBookingsRes] = useLazyQuery<
    {
      bookings: Booking[];
    },
    { filters: BookingFilterInput }
  >(GET_BOOKINGS, {
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    nextFetchPolicy: input?.nextFetchPolicy || 'cache-and-network',
    onCompleted: data => {
      if (data.bookings) setBookingMaps(keyBy(data.bookings, 'id'));
    },
  });

  const [createBookingReq, createBookingRes] = useMutation<
    {
      createBooking: Booking;
    },
    { input: CreateBookingInput }
  >(CREATE_BOOKING, {
    onCompleted: data => {
      setBookingMaps(pre => {
        return { ...pre, [data.createBooking.id]: data.createBooking };
      });
    },
  });

  const [updateBookingReq, updateBookingRes] = useMutation<
    {
      updateBooking: Booking;
    },
    { input: UpdateBookingInput }
  >(UPDATE_BOOKING, {
    onCompleted: data => {
      setBookingMaps(pre => {
        return { ...pre, [data.updateBooking.id]: data.updateBooking };
      });
    },
  });

  const getBookings = useCallback(
    (filter: BookingFilterInput) => {
      getBookingsReq({ variables: { filters: filter } });
    },
    [getBookingsReq],
  );

  const createBooking = useCallback(
    (input: CreateBookingInput) => {
      createBookingReq({ variables: { input } });
    },
    [createBookingReq],
  );

  const updateBooking = useCallback(
    (input: UpdateBookingInput) => {
      updateBookingReq({ variables: { input } });
    },
    [updateBookingReq],
  );

  const RESPONSE_ENTITIES = [
    getBookingsRes,
    createBookingRes,
    updateBookingRes,
  ];

  const error: ApolloError | undefined = getError(RESPONSE_ENTITIES);
  const loading: boolean = isLoading(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      bookingMaps,
      getBookings,
      createBooking,
      updateBooking,
    }),
    [bookingMaps, error, getBookings, loading, createBooking, updateBooking],
  );
}
