import React from 'react';
import PageDetail from './PageDetail';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();

const PageStack: React.FC = () => {
  const route = useRoute();
  const params = route.params as { pageId: string; name: string };

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} />
      )}
      initialRouteName="PageDetail"
    >
      <Tab.Screen
        name="PageDetail"
        component={PageDetail}
        options={{ tabBarLabel: params.name }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default PageStack;
