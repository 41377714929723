export const parseToFloat = (value: string): boolean | number => {
  const parsedValue = Number.parseFloat(value);
  if (Number.isNaN(parsedValue)) {
    return false;
  } else return parsedValue;
};

export const toFixedNoRound = (num: number, fixed = 2) => {
  return +(
    `${num}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`)) as string[]
  )[0];
};
