import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { combineLatest, Subscription } from 'rxjs';
import { App } from '@oolio-group/domain';
import arrayToTree from 'array-to-tree';
import {
  filterPermissionsBySearchTerm,
  jobRoleSettingsUtility,
  PermissionTreeNode,
} from '../../../../../state/jobRoleSettingsUtility';
import { map } from 'rxjs/operators';
import PermissionTree from './PermissionTree';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../../common/default-theme';

const POSAppPermissions = () => {
  const { translate } = useTranslation();
  const [permissionsTree, setPermissionsTree] = useState<PermissionTreeNode[]>(
    [],
  );

  useEffect(() => {
    const subscription: Subscription = combineLatest([
      jobRoleSettingsUtility.permissions$.pipe(
        map(permissions =>
          permissions.filter(
            permission => permission.clientApp === App.POS_APP,
          ),
        ),
      ),
      jobRoleSettingsUtility.search$,
    ]).subscribe(([posPermissions, searchTerm]) => {
      if (posPermissions?.length > 0) {
        const data = filterPermissionsBySearchTerm(posPermissions, searchTerm);

        const sortedPermissionTree = arrayToTree(data, {
          parentProperty: 'parent',
          customID: 'id',
        }).sort((a, b) => a.sortOrder - b.sortOrder);
        setPermissionsTree(sortedPermissionTree);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={theme.tables.header}>
        <Text style={[theme.tables.headerText, styles.cellPermission]}>
          {translate('backofficeManageRoles.permission')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellToggle]}>
          {translate('backofficeManageRoles.operations.enable')}
        </Text>
      </View>
      <View>
        {permissionsTree.map(permissionTree => {
          return (
            <PermissionTree
              permission={permissionTree}
              key={permissionTree.id}
            />
          );
        })}
      </View>
    </View>
  );
};

export default POSAppPermissions;

const styles = StyleSheet.create({
  container: {
    marginBottom: 40,
  },
  cellPermission: {
    flex: 1,
    paddingLeft: 38,
  },
  cellToggle: {
    width: 80,
    paddingRight: 20,
    textAlign: 'center',
  },
});
