import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: 340,
    padding: 30,
    paddingTop: 40,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.border.solid,
    ...theme.shadow.elevated1,
  },
  content: {
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    color: theme.colors.black1,
    fontFamily: theme.fonts.semibold,
    marginTop: 16,
    marginBottom: 8,
  },
  message: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.black2,
    fontFamily: theme.fonts.medium,
  },
  actions: {
    rowGap: 10,
    marginTop: 30,
  },
});

export default styles;
