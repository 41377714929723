/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { PricingGroup, DEFAULT_PRICING_GROUP } from '@oolio-group/domain';
import Icon from '../../../../components/Icon/Icon';
import InputToggle from '../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import theme from '../../../../common/default-theme';
import styles from './PriceLists.styles';

interface PriceListRowProps {
  list: PricingGroup;
  onPressStatusChange: (id: string, status: boolean) => void;
  onPressCopy: (id: string) => void;
}

const PriceListRow: React.FC<PriceListRowProps> = ({
  list,
  onPressStatusChange,
  onPressCopy,
}) => {
  const navigation = useNavigation();

  const isStandard = list.name === DEFAULT_PRICING_GROUP;
  const products = list.prices?.length || 0;

  const onPressRow = useCallback(
    (id: string, name: string) => {
      navigation.navigate('PriceListSettings', {
        pricingGroupId: id,
        title: name,
        isDefault: isStandard,
      });
    },
    [isStandard, navigation],
  );

  return (
    <TouchableOpacity
      testID="row-list"
      style={[theme.tables.row, { height: 54 }]}
      onPress={() => onPressRow(list.id, list.name)}
    >
      {isStandard ? (
        <View style={{ width: 44 }} />
      ) : (
        <InputToggle
          testID="toggle-active"
          type="switch"
          isToggled={list.isActive || false}
          onToggle={(): void => onPressStatusChange(list.id, !list.isActive)}
          disabled={list.name === DEFAULT_PRICING_GROUP}
        />
      )}
      <Text testID="input-name" style={styles.cellName}>
        {list.name}
      </Text>
      <Text
        style={[
          styles.cellProducts,
          { color: products > 1 ? theme.colors.black1 : theme.colors.grey5 },
        ]}
      >
        {list.prices?.length || 0}
      </Text>
      <ButtonIcon
        testID="btn-copy"
        icon="copy"
        type="neutralLight"
        onPress={() => onPressCopy(list.id)}
      />
      <View style={theme.tables.disclosure}>
        <Icon size={20} name="angle-right" color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export default PriceListRow;
