import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    ...theme.forms.inputFluid,
    height: 44,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    borderWidth: 1,
    overflow: 'hidden',
  },
  input: {
    justifyContent: 'center',
  },
  inputText: {
    fontSize: 14,
    position: 'absolute',
    top: 12,
    bottom: 12,
    left: 6,
  },
  btnRemove: {
    width: 38,
    height: 38,
    borderRadius: 28,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 2,
    bottom: 2,
    right: 0,
  },
  webInput: {
    transform: [{ scale: 0 }],
    zIndex: -2,
  },
});

export default styles;
