import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    rowGap: 8,
    width: 340,
    alignSelf: 'center',
  },
  product: {
    padding: 20,
    paddingBottom: 16,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  options: {
    maxHeight: 275,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  option: {
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  optionLabel: {
    flex: 1,
  },
  optionText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
