import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    minWidth: theme.layoutWidth.s,
  },
  form: {
    width: '100%',
    marginVertical: 20,
  },
  text: {
    width: '100%',
    paddingHorizontal: 14,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.accent,
    fontFamily: theme.fonts.regular,
  },
  subtitle: {
    marginTop: 2,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.regular,
  },
});

export default styles;
