/* eslint-disable react-native/no-inline-styles */
import React, { useState, useCallback } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { KeyboardAvoidingView, Platform, View, Text } from 'react-native';
import Button, { ButtonProps } from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import { convertAlphaNumbericToNumber } from '@oolio-group/client-utils';
import theme from '../../../../../common/default-theme';
import styles from './SetTimeModal.styles';
import { analyticsService } from '../../../../../analytics/AnalyticsService';

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <IconButton icon="times" onPress={onPress} iconColor={theme.colors.white} />
);

interface SetPrepTimeModalprops {
  setPreparationTime?: (time: number) => void;
}

export const SetPrepTimeModal: React.FC<SetPrepTimeModalprops> = ({
  setPreparationTime,
}: SetPrepTimeModalprops) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [prepTime, setPrepTime] = useState(0);

  const onSetPrepTime = useCallback(() => {
    analyticsService.capture('oo_event', {
      event: 'Set Prep Time',
    });
    setPreparationTime && setPreparationTime(prepTime);
    closeModal();
  }, [closeModal, setPreparationTime, prepTime]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.background}
    >
      <View style={styles.titleContainer}>
        <CloseButton onPress={closeModal} />
        <Text style={styles.titleText}>
          {translate('onlineOrders.setPrepTime')}
        </Text>
        <View style={{ width: 24 }} />
      </View>
      <View style={styles.bodyContainer}>
        <FormInput
          error={false}
          testID="default-prep-time"
          placeholder={translate('backOfficeFeatures.defaultPrepTime')}
          title={translate('backOfficeFeatures.defaultPrepTime')}
          value={prepTime.toString()}
          alignTitle="left"
          onChangeText={value =>
            setPrepTime(convertAlphaNumbericToNumber(value))
          }
          keyboardType="number-pad"
          containerStyle={{ width: 280 }}
        />
        <Button
          testID="submit-button"
          onPress={onSetPrepTime}
          labelStyle={{ color: theme.colors.white }}
          containerStyle={styles.confirmButtonContainer}
          title={translate('onlineOrders.setPrepTime').toUpperCase()}
        />
      </View>
    </KeyboardAvoidingView>
  );
};
