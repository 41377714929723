import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  content: {
    height: '100%',
  },
  filters: {
    margin: 20,
    flexDirection: 'row',
  },
  search: {
    flex: 1,
    marginRight: 10,
  },
  dropdown: {
    width: 180,
    marginRight: 10,
  },
  emptyList: {
    margin: 20,
    height: 300,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.grey1,
  },
  emptyText: {
    marginTop: 16,
    marginBottom: 40,
    textAlign: 'center',
  },
  table: {
    marginBottom: 80,
    marginHorizontal: 20,
  },
  tableHeader: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  tableBody: {
    marginVertical: 8,
  },
  tableRow: {
    height: 50,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  cellType: {
    width: 60,
    marginLeft: 12,
    textAlign: 'center',
  },
  cellDate: {
    width: 110,
    marginLeft: 12,
  },
  cellUser: {
    width: 140,
    marginLeft: 12,
  },
  cellReason: {
    width: 140,
    marginLeft: 12,
  },
  cellNote: {
    flex: 1,
    marginLeft: 20,
  },
  headerAmount: {
    width: 110,
    marginLeft: 20,
    marginRight: 106,
    textAlign: 'right',
  },
  cellAmount: {
    width: 110,
    marginRight: 20,
    textAlign: 'right',
  },
  cellInfo: {
    marginRight: 6,
  },
  cellPrint: {
    marginRight: 12,
  },
});

export default styles;
