import React, { useEffect, useState } from 'react';
import { CustomerAccountFeatureSettings } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import { useFeatures } from '../../../../../hooks/app/features/useFeatures';

const CustomerAccountScreen: React.FC = ({}) => {
  const { translate } = useTranslation();
  const {
    getAccountFeatureSettings,
    featureSettings,
    createOrUpdateAccountFeature,
  } = useFeatures();
  const [form, setForm] = useState<Partial<CustomerAccountFeatureSettings>>({
    id: '',
    paymentDetails: '',
    additionalNotes: '',
  });

  useEffect(() => {
    getAccountFeatureSettings();
  }, [getAccountFeatureSettings]);

  useEffect(() => {
    if (featureSettings) {
      setForm({
        id: featureSettings?.id ?? '',
        paymentDetails: featureSettings?.paymentDetails ?? '',
        additionalNotes: featureSettings?.additionalNotes ?? '',
      });
    }
  }, [featureSettings]);

  const onChangeText = (key: string, value: string) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const onSave = () => {
    createOrUpdateAccountFeature(form as CustomerAccountFeatureSettings);
  };

  return (
    <ScreenLayout
      loading={false}
      title={translate('navigation.featuresPageTitle', {
        appName: translate('appName'),
      })}
      onSave={onSave}
    >
      <Section title={translate('onAccount.statementPreferences')}>
        <InputText
          testID="payment-details"
          title={translate('onAccount.paymentDetails')}
          value={form.paymentDetails}
          multiline
          maxLength={300}
          placeholder={translate('onAccount.paymentDetails')}
          onChangeText={text => onChangeText('paymentDetails', text)}
        />
        <InputText
          testID="additional-notes"
          title={translate('onAccount.additionalNotes')}
          value={form.additionalNotes}
          multiline
          maxLength={300}
          placeholder={translate('onAccount.additionalNotes')}
          onChangeText={text => onChangeText('additionalNotes', text)}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginTop: 20 }}
        />
      </Section>
    </ScreenLayout>
  );
};

export default CustomerAccountScreen;
