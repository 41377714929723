/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useRef } from 'react';
import { Text, View, ViewStyle, TextInput, TextInputProps } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { Icon } from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import styles from './Inputs.styles';

export interface InputTextProps extends TextInputProps {
  title?: string;
  testID: string;
  label?: string;
  value?: string | undefined;
  placeholder: string;
  errorMessage?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  inputWrapperStyle?: ViewStyle | ViewStyle[];
  isVerified?: boolean;
  alignText?: 'left' | 'center' | 'right';
  numericOnly?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  title,
  value,
  label,
  testID,
  isVerified,
  placeholder,
  errorMessage,
  containerStyle,
  inputWrapperStyle,
  alignText,
  numericOnly,
  ...props
}: InputTextProps) => {
  const inputRef = useRef<TextInput>(null);
  const { translate } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const input = inputRef.current;
      if (input && !!props.editable && props.autoFocus === true) {
        input.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef, props.autoFocus, props.editable]);

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <View
          style={[
            styles.inputWrapper,
            inputWrapperStyle,
            props.editable === true
              ? theme.containers.enabled
              : theme.containers.disabled,
            props.multiline && { height: 100 },
          ]}
        >
          {!!label && (
            <View style={styles.labelContainer}>
              <Text style={styles.labelText}>{label}</Text>
            </View>
          )}
          <TextInput
            testID={testID}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={theme.colors.grey5}
            style={[
              styles.inputContainer,
              { textAlign: alignText },
              props.multiline && { paddingVertical: 12 },
            ]}
            autoCorrect={false}
            textAlign={alignText}
            {...props}
            ref={inputRef}
          />
        </View>
        {isVerified !== undefined && (
          <View accessibilityRole="none" style={styles.accessory}>
            <Icon
              size={20}
              color={
                isVerified
                  ? theme.colors.states.positive
                  : theme.colors.states.negative
              }
              name={isVerified ? 'check' : 'times'}
            />
          </View>
        )}
      </View>
      {numericOnly && value && !/^\d*\.?\d*$/.test(value) ? (
        <Text style={styles.errorText}>{translate('form.enterNumbers')}</Text>
      ) : null}
      {!!errorMessage ? (
        <Text style={styles.errorText}>{errorMessage}</Text>
      ) : null}
    </View>
  );
};

export default InputText;

InputText.defaultProps = {
  editable: true,
  numericOnly: false,
};
