import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 600,
    padding: 30,
    rowGap: 30,
    alignSelf: 'center',
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
  },
  header: {
    rowGap: 12,
    maxWidth: 480,
    paddingTop: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    textAlign: 'center',
    color: theme.colors.black1,
    fontFamily: theme.fonts.bold,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey7,
    fontFamily: theme.fonts.regular,
  },
  messages: {
    rowGap: 4,
  },
  // Message Row
  messageRow: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 12,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    color: theme.colors.black2,
  },
  icon: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
