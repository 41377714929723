import React, { useEffect, useCallback, useState } from 'react';
import { OrderType, CreateSalesChannelInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useMutation } from '@apollo/client/react/hooks';
import { CREATE_SALES_CHANNEL } from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';

interface SalesChannel {
  id: string;
  name: string;
  isActive: boolean;
  integrationStatus: string;
  orderTypes?: OrderType[];
}

interface CreateChannelModalProps {
  orderTypeData: OrderType[];
  onAddSalesChannel: (channel: SalesChannel) => void;
}

export const CreateChannelModal: React.FC<CreateChannelModalProps> = ({
  orderTypeData,
  onAddSalesChannel,
}: CreateChannelModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [createForm, setCreateForm] = useState({} as CreateSalesChannelInput);

  const onChange = useCallback((prop: string, value) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeMultiSelect = useCallback(
    (prop: string, value: string[]) => {
      setCreateForm({
        ...createForm,
        [prop]: value,
      });
    },
    [createForm],
  );

  const [createSalesChannel, createOperation] = useMutation(
    CREATE_SALES_CHANNEL,
    {
      onError: noopHandler,
    },
  );

  const onCreateSalesChannel = useCallback(() => {
    createSalesChannel({
      variables: {
        input: {
          name: createForm.name,
          isActive: createForm.isActive || false,
          orderTypes: createForm.orderTypes || [],
          integrationStatus: '',
        } as CreateSalesChannelInput,
      },
    });
  }, [createForm, createSalesChannel]);

  useEffect(() => {
    if (createOperation.data) {
      onAddSalesChannel({
        id: createOperation.data.createSalesChannel.id,
        name: createOperation.data.createSalesChannel.name,
        isActive: createOperation.data.createSalesChannel.isActive,
        orderTypes: (createForm.orderTypes || []).map(id => ({ id })),
      } as SalesChannel);
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.newSalesChannelSuccessfullyAdded',
        ),
      });
    }
  }, [
    createOperation.data,
    showNotification,
    closeModal,
    onAddSalesChannel,
    translate,
    createForm.orderTypes,
  ]);

  useEffect(() => {
    if (createOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(createOperation.error),
      });
    }
  }, [createOperation.error, showNotification]);

  return (
    <TreatModal
      title={translate('backOfficeSettings.createChannelName')}
      type="positive"
      onConfirm={{ action: onCreateSalesChannel }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('backOfficeSettings.channelName')}
        value={createForm.name}
        placeholder={translate('backOfficeSettings.channelName')}
        onChangeText={onChange.bind(null, 'name')}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginBottom: 20 }}
      />
      <SelectMultiple
        testID="select-orderTypes"
        title={translate('backOfficeSettings.assignedOrderTypes')}
        options={orderTypeData.map(order => ({
          label: order.name,
          value: order.id,
        }))}
        selectedValues={createForm.orderTypes || []}
        onValueChange={(value): void =>
          onChangeMultiSelect('orderTypes', value)
        }
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginBottom: 20 }}
      />
      <InputToggle
        testID="toggle-active"
        title={translate('backOfficeSettings.activateNow')}
        isToggled={createForm.isActive}
        onToggle={onChange.bind(null, 'isActive', !createForm.isActive)}
      />
    </TreatModal>
  );
};
