/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text, ViewStyle, TouchableOpacity } from 'react-native';
import { isUndefined } from 'lodash';
import theme from '../../../common/default-theme';
import Icon from '../../../components/Icon/Icon';
import styles from './InputToggle.styles';

interface InputToggleProps {
  testID: string;
  isToggled: boolean;
  isPartial?: boolean;
  onToggle?: () => void;
  title?: string;
  subtitle?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  type?: 'checkbox' | 'switch' | 'eye' | 'radio';
  disabled?: boolean;
  size?: 34 | 38 | 44;
  partialIconColor?: string;
  direction?: 'ltr' | 'rtl';
}

const getToggleIcon = (
  type: string,
  isToggled?: boolean,
  isPartial?: boolean,
) => {
  switch (type) {
    case 'switch':
      return isToggled ? 'toggle-on' : 'toggle-off';
    case 'eye':
      return isToggled ? 'eye' : 'eye-slash';
    case 'radio':
      return isToggled ? 'check-circle' : 'circle';
    default:
      return isPartial
        ? 'minus-square-full'
        : isToggled
        ? 'check-square'
        : 'square-full';
  }
};

const InputToggle: React.FC<InputToggleProps> = ({
  testID,
  type = 'checkbox',
  title,
  subtitle,
  onToggle,
  isToggled,
  isPartial,
  containerStyle,
  disabled,
  size = 44,
  partialIconColor = '',
  direction = 'ltr',
}: InputToggleProps) => {
  const getIconColor = () => {
    if (isToggled) {
      return theme.colors.states.positive;
    }

    if (isPartial) {
      return partialIconColor ?? theme.colors.states.neutral;
    }

    if (disabled) {
      return theme.colors.grey4;
    }

    return theme.colors.grey5;
  };

  const getBackgroundColor = () => {
    if (isToggled && title) {
      return theme.colors.states.positiveXL;
    }

    if (isUndefined(title)) {
      return theme.colors.transparent;
    }

    if (isPartial) {
      return theme.colors.states.neutralXL;
    }

    return theme.colors.grey1;
  };

  const icon = (
    <Icon
      size={20}
      color={getIconColor()}
      name={getToggleIcon(type, isToggled, isPartial)}
    />
  );

  if (!title && !subtitle) {
    return (
      <TouchableOpacity
        testID={testID}
        onPress={onToggle}
        disabled={disabled}
        style={[styles.iconOnly, { height: size, width: size }, containerStyle]}
      >
        {icon}
      </TouchableOpacity>
    );
  }

  return (
    <View style={containerStyle}>
      <TouchableOpacity
        testID={testID}
        disabled={disabled}
        onPress={onToggle}
        style={[
          styles.container,
          { backgroundColor: getBackgroundColor() },
          { padding: !subtitle ? 12 : 16 },
          direction === 'rtl' && { flexDirection: 'row-reverse' },
        ]}
      >
        <View style={styles.icon}>{icon}</View>
        <View style={styles.text}>
          {!!title && (
            <Text numberOfLines={1} style={styles.title}>
              {title}
            </Text>
          )}
          {!!subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default InputToggle;
