/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useFeatures } from '../../../../hooks/app/features/useFeatures';
import orderBy from 'lodash/orderBy';
import { Feature } from '@oolio-group/domain';
import Search from '../../../../components/Shared/Search/Search';
import TreatPicker from '../../../../components/Shared/Select/Picker';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import FeatureGrid from '../Layout/FeatureGrid/FeatureGrid';
import Section from '../../../../components/Office/Section/Section';

const ManageFeaturesScreen = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const isFocused = useIsFocused();

  const { features, getFeatures, loading } = useFeatures();

  useEffect(() => {
    if (isFocused) getFeatures();
  }, [getFeatures, isFocused]);

  const [searchValue, setSearchValue] = useState('');
  const [selectedCategory, setFilteredFeature] = useState('');

  const filteredFeatures = useMemo(() => {
    return orderBy(
      features.filter(({ name, category }) => {
        return (
          name.toLowerCase().includes(searchValue.toLowerCase()) &&
          category.toLowerCase().includes(selectedCategory.toLowerCase())
        );
      }),
      feature => feature.enabled,
      ['desc'],
    );
  }, [features, searchValue, selectedCategory]);

  const onPressFeature = useCallback(
    (selectedFeature: Feature) => {
      navigation.navigate('FeaturesSettings', {
        featureId: selectedFeature.featureId,
        title: selectedFeature.name,
      });
    },
    [navigation],
  );

  const CATEGORIES = [
    {
      label: translate('backOffice.features.categories.all'),
      value: '',
    },
    {
      label: translate('backOffice.features.categories.menu'),
      value: translate('backOffice.features.categories.menu'),
    },
    {
      label: translate('backOffice.features.categories.POS'),
      value: translate('backOffice.features.categories.POS'),
    },
    {
      label: translate('backOffice.features.categories.multiVenue'),
      value: translate('backOffice.features.categories.multiVenue'),
    },
    {
      label: translate('backOffice.features.categories.venue'),
      value: translate('backOffice.features.categories.venue'),
    },
  ];

  return (
    <ScreenLayout
      loading={loading}
      hideFooter={true}
      title={translate('navigation.featuresPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section layoutWidth="large">
        <View style={styles.filtersContainer}>
          <TreatPicker
            testID="select-category"
            label="Category"
            options={CATEGORIES}
            onValueChange={setFilteredFeature}
            containerStyle={styles.dropdownContainer}
          />
          <Search
            testID="search-feature"
            value={searchValue}
            onChangeText={setSearchValue}
            placeholder={translate('backOffice.features.searchFeature')}
            containerStyle={{ flex: 1 }}
          />
        </View>
        <FeatureGrid
          features={filteredFeatures}
          onPressFeature={onPressFeature}
        />
      </Section>
    </ScreenLayout>
  );
};

export default ManageFeaturesScreen;

const styles = StyleSheet.create({
  filtersContainer: {
    height: 44,
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownContainer: {
    width: 260,
    marginRight: 10,
  },
});
