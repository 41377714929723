import { Order } from '@oolio-group/domain';
import { translate, useCurrency } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { StyleFn, RenderProps } from '@oolio-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { StyleSheet, View, Text } from 'react-native';
import { addToValue } from '../../../utils/keypadHelper';
import Button from '../../Button/Button';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import { FelaComponent } from 'react-fela';
import IconButton from '../../Button/IconButton';
import { ButtonProps } from '../../Button/Button';
import { isWeb } from '../../../common/theme';
export interface SetTableNameModalProps {
  order?: Order;
  showGuestCounter?: boolean;
  onSubmit: (tableNumber: string, guestCount: number) => void;
  onCancel?: () => void;
}

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * (isWeb ? 1.25 : 0.75),
  alignSelf: 'center',
});

const keyRowStyle: StyleFn = () => ({
  marginTop: '2%',
});

const numberInputStyle: StyleFn = () => ({
  flexGrow: 1,
});

const containerStyle: StyleFn = () => ({
  width: 350,
  alignSelf: 'center',
  paddingTop: 0,
  paddingBottom: 0,
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  keypad: {
    flex: 0.7,
  },
});

const redoStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
  transform: [{ rotate: '180deg' }],
});

const guestCounter: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 50,
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 20,
  borderColor: theme.colors.border,
  borderWidth: 1,
  borderRadius: theme.radius.small,
});

const guestLabel: StyleFn = ({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fontSize.small,
  alignSelf: 'center',
  paddingLeft: theme.padding.medium,
});

const guestCounterButtonContainer: StyleFn = () => ({
  height: 50,
  flexDirection: 'row',
});

const guestCounterButton: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  width: 38,
  height: 38,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
  marginHorizontal: 5,
});

const headerStyle: StyleFn = ({ theme }) => ({
  width: 350,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});
const inputContainerStyle: StyleFn = () => ({
  height: 70,
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

export const SetTableNameModal: React.FC<SetTableNameModalProps> = ({
  order,
  onSubmit,
  showGuestCounter,
  onCancel,
}) => {
  const { css } = useFela({ order });
  const { closeModal } = useModal();
  const { decimalSeparator } = useCurrency();
  const [input, setInput] = useState(order?.table?.name || 'T-');
  const [guestCount, setGuestCount] = useState<number>(
    order?.table?.guestCount || 1,
  );

  const onPressIncrement = useCallback(
    () => setGuestCount(guestCount + 1),
    [guestCount, setGuestCount],
  );

  const onPressDecrement = useCallback(() => {
    if (guestCount > 1) setGuestCount(guestCount - 1);
  }, [guestCount, setGuestCount]);

  const onPressKey = useCallback(
    (key: string): void => {
      setInput(value => addToValue(value.toString(), key, decimalSeparator));
    },
    [decimalSeparator],
  );

  const onPressSubmit = useCallback(() => {
    onSubmit(input, guestCount);
  }, [input, guestCount, onSubmit]);

  const onPressDelete = useCallback(() => {
    if (input !== 'T-') {
      setInput(input.slice(0, -1));
    }
  }, [input, setInput]);

  const onCloseModel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [closeModal, onCancel]);

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={translate('order.setTableNumber')}
        titleLeft={<CloseButtons onPress={onCloseModel} />}
      />
      <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
        {showGuestCounter && (
          <View style={css(guestCounter)}>
            <Text style={css(guestLabel)} testID="guestCount">
              {`${translate('order.guests')}: ${guestCount}`}
            </Text>
            <View style={css(guestCounterButtonContainer)}>
              <IconButton
                testID="guestIncrement"
                icon="AngleUp"
                iconColor="black"
                containerStyle={css(guestCounterButton)}
                onPress={onPressIncrement}
                iconSize={28}
              />
              <IconButton
                testID="guestDecrement"
                icon="AngleDown"
                iconColor="black"
                containerStyle={css(guestCounterButton)}
                onPress={onPressDecrement}
                iconSize={28}
              />
            </View>
          </View>
        )}
        <InputDisplay
          containerStyle={css(inputContainerStyle)}
          value={input}
          right={
            <IconButton
              testID="reset"
              icon="cancel"
              containerStyle={css(redoStyle)}
              onPress={onPressDelete}
              iconSize={36}
            />
          }
        />
        <View style={[css(keypadStyle), styles.fullWidth]}>
          <NumberInput
            keyWidth={95}
            keyHeight={70}
            onPressKey={onPressKey}
            rowStyle={css(keyRowStyle)}
            containerStyle={css(numberInputStyle)}
            keys={[
              { value: '1' },
              { value: '2' },
              { value: '3' },
              { value: '4' },
              { value: '5' },
              { value: '6' },
              { value: '7' },
              { value: '8' },
              { value: '9' },
              { value: '0', width: 300 },
            ]}
          />
        </View>
        <Button
          fluid
          success
          testID="submit-button"
          onPress={onPressSubmit}
          title={translate('order.setTableNumber')}
        />
      </Modal>
    </View>
  );
};
