import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, ScrollView } from 'react-native';
import { Address, Customer, CustomerAddress } from '@oolio-group/domain';
import {
  isValidEmail,
  isValidName,
  trimString,
} from '../../../utils/validator';
import {
  useTranslation,
  usePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from '@oolio-group/localization';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import { isIos } from '../../../common/theme';
import { useSession } from '../../../hooks/app/useSession';
import theme from '../../../common/default-theme';
import InputText from '../../Shared/Inputs/InputText';
import InputEmail from '../../Shared/Inputs/InputEmail';
import InputPhone from '../../Shared/Inputs/InputPhone';
import AddressForm from '../../Shared/Forms/AddressForm';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import InputToggle from '../../Shared/Inputs/InputToggle';
import { EnrollmentSource } from '@oolio-group/loyalty-sdk';
import { useOolioLoyalty } from '../../../hooks/useOolioLoyalty';
import styles from './AddCustomer.styles';

export interface AddCustomerProps {
  name?: string;
  customer?: Customer;
  // If orderId provided (in open orders screen), the customer can be assigned to a specific order (useCart will use this order id)
  orderId?: string;
  isEditing?: boolean;
  assignCustomerToOrder?: (customer: Customer) => void;
  onSuccess?: () => void;
}

interface CustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  address?: Address;
  phoneCountry: string;
  phone: string;
  loyaltyMember: boolean;
}

const AddCustomer: React.FC<AddCustomerProps> = ({
  name,
  customer,
  isEditing = false,
  assignCustomerToOrder,
  onSuccess,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { closeModal } = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const { getFullFormattedPhoneNumber } = usePhoneNumber();
  const [session] = useSession();
  const { isLoyaltyEnabled: isNewLoyaltyEnabled } = useOolioLoyalty();

  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const { isoCountryCode = country } = customer?.preferredAddress || {};

  const phoneCountry =
    parsePhoneNumber(customer?.phone ?? '')?.countryCode || isoCountryCode;

  const [form, setForm] = useState<CustomerForm>({
    phone: customer?.phoneNumber || '',
    email: customer?.email || '',
    address: {
      ...customer?.preferredAddress,
      isoCountryCode,
    } as Address,
    phoneCountry: phoneCountry || 'AU',
    firstName:
      isEditing && customer?.firstName
        ? customer.firstName
        : name?.split(' ')[0] ?? '',
    lastName:
      isEditing && customer?.lastName
        ? customer.lastName
        : name?.split(' ')[1] ?? '',
    loyaltyMember: false,
  });

  const {
    addNewCustomer,
    updateCustomer,
    enrollMemberLoyalty,
    error,
    loading,
  } = useCustomers();

  const isPhoneValid = isValidPhoneNumber(
    getFullFormattedPhoneNumber(form.phoneCountry, form.phone),
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({ error: true, message: errorMessage });
    }
  }, [errorMessage, showNotification, translate]);

  const onChangeFormInput = useCallback(
    (prop: keyof CustomerForm | string, value: string | boolean | Address) => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [errorMessage],
  );

  const onAddNewCustomer = useCallback(async () => {
    const { firstName, lastName, email, phone, loyaltyMember } = form;
    if (!isNewLoyaltyEnabled) {
      if (firstName === '' && lastName === '') {
        setErrorMessage(translate('customer.emptyNameMessage'));
        return;
      } else if (email === '' && phone.length === 0) {
        setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
        return;
      } else if (email && !isValidEmail(email)) {
        setErrorMessage(translate('customer.invalidEmailMessage'));
        return;
      } else if (phone && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      }
    } else {
      if (
        !loyaltyMember &&
        trimString(firstName) === '' &&
        trimString(lastName) === '' &&
        email === '' &&
        !phone.length
      ) {
        setErrorMessage(
          translate('backOfficeCustomerModal.errorMinOneFieldMissing'),
        );
        return;
      } else if (loyaltyMember && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      } else if (email && !isValidEmail(email)) {
        setErrorMessage(translate('customer.invalidEmailMessage'));
        return;
      } else if (phone && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      }
    }

    const { line1, line2, state, city, suburb, postalCode, isoCountryCode } =
      form?.address || {};

    const preferredAddress = {
      line1,
      line2,
      state,
      city,
      suburb,
      postalCode,
      isoCountryCode,
    } as CustomerAddress;

    const newCustomer = await addNewCustomer({
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(form.phoneCountry, form.phone),
      phoneNumber: form.phone,
      preferredAddress,
      loyaltyMember,
      ...(loyaltyMember && {
        loyaltyEnrolmentSource: EnrollmentSource.POS,
      }),
    });
    if (newCustomer?.id) {
      onSuccess && onSuccess();
      assignCustomerToOrder && assignCustomerToOrder(newCustomer);
      closeModal();
    }
  }, [
    form,
    isPhoneValid,
    translate,
    addNewCustomer,
    getFullFormattedPhoneNumber,
    onSuccess,
    assignCustomerToOrder,
    closeModal,
    isNewLoyaltyEnabled,
  ]);

  const onEditCustomer = useCallback(async () => {
    const { firstName, lastName, email, phone, loyaltyMember } = form;
    if (!isNewLoyaltyEnabled) {
      if (firstName === '' && lastName === '') {
        setErrorMessage(translate('customer.emptyNameMessage'));
        return;
      } else if (!email && !phone) {
        setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
        return;
      } else if (email && !isValidEmail(email)) {
        setErrorMessage(translate('customer.invalidEmailMessage'));
        return;
      } else if (phone && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      }
    } else {
      if (customer?.loyaltyMember || form?.loyaltyMember) {
        if (!isPhoneValid) {
          setErrorMessage(translate('customer.invalidPhoneMessage'));
          return;
        }
      } else {
        if (
          trimString(firstName) === '' &&
          trimString(lastName) === '' &&
          email === '' &&
          !phone.length
        ) {
          setErrorMessage(
            translate('backOfficeCustomerModal.errorMinOneFieldMissing'),
          );
          return;
        } else if (email && !isValidEmail(email)) {
          setErrorMessage(translate('customer.invalidEmailMessage'));
          return;
        } else if (phone && !isPhoneValid) {
          setErrorMessage(translate('customer.invalidPhoneMessage'));
          return;
        }
      }
    }

    const { line1, line2, state, city, suburb, postalCode, isoCountryCode } =
      form?.address || {};

    const preferredAddress = {
      line1,
      line2,
      state,
      city,
      suburb,
      postalCode,
      isoCountryCode,
    } as CustomerAddress;

    const updatedCustomer = await updateCustomer({
      id: customer?.id ?? '',
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(form.phoneCountry, form.phone),
      phoneNumber: form.phone,
      preferredAddress,
    });
    if (updatedCustomer?.id && loyaltyMember) {
      if (customer && customer?.loyaltyMember !== loyaltyMember) {
        await enrollMemberLoyalty(customer.id, EnrollmentSource.POS);
      }
    }
    onSuccess && onSuccess();
    assignCustomerToOrder &&
      updatedCustomer &&
      assignCustomerToOrder(updatedCustomer);
    closeModal();
  }, [
    form,
    isPhoneValid,
    isNewLoyaltyEnabled,
    translate,
    updateCustomer,
    customer,
    getFullFormattedPhoneNumber,
    onSuccess,
    assignCustomerToOrder,
    closeModal,
    enrollMemberLoyalty,
  ]);

  const onSave = useCallback(async () => {
    if (isEditing) {
      onEditCustomer();
    } else {
      onAddNewCustomer();
    }
  }, [isEditing, onEditCustomer, onAddNewCustomer]);

  const maxHeight = theme.useModalMaxHeight();

  return (
    <KeyboardAvoidingView behavior={isIos ? 'padding' : undefined}>
      <View style={[styles.container, maxHeight]}>
        <ScrollView>
          <View style={styles.title}>
            <Text
              style={[
                styles.titleText,
                {
                  color: isEditing
                    ? theme.colors.states.neutral
                    : theme.colors.states.positive,
                },
              ]}
            >
              {translate(
                isEditing ? 'customer.editCustomer' : 'customer.addNewCustomer',
              )}
            </Text>
          </View>
          <View style={styles.contentContainer}>
            <View style={theme.forms.row}>
              <InputText
                title={translate('form.firstName')}
                value={form.firstName}
                onChangeText={text => onChangeFormInput('firstName', text)}
                isVerified={
                  !isNewLoyaltyEnabled ? isValidName(form.firstName) : undefined
                }
                placeholder={'First Name'}
                containerStyle={theme.forms.inputHalf}
                testID="customer-first-name"
              />
              <InputText
                title={translate('form.lastName')}
                value={form.lastName}
                onChangeText={text => onChangeFormInput('lastName', text)}
                isVerified={
                  !isNewLoyaltyEnabled ? isValidName(form.lastName) : undefined
                }
                placeholder={'Last Name'}
                containerStyle={theme.forms.inputHalf}
                testID="customer-last-name"
              />
            </View>
            <View style={theme.forms.row}>
              <InputPhone
                testID="customer-phone"
                title={translate('form.phoneNumber')}
                value={form.phone}
                onChangeText={text => onChangeFormInput('phone', text)}
                errorMessage={
                  form.loyaltyMember && !form.phone
                    ? translate('customer.invalidPhoneMessage')
                    : undefined
                }
                containerStyle={theme.forms.inputHalf}
                onPressCountry={country =>
                  onChangeFormInput('phoneCountry', country)
                }
                defaultCountry={form.phoneCountry}
              />
              <InputEmail
                title={translate('common.emailAddress')}
                value={form.email}
                onChangeText={text => onChangeFormInput('email', text)}
                placeholder={'Email'}
                containerStyle={theme.forms.inputHalf}
                testID="customer-email"
              />
            </View>
            <AddressForm
              onChangeAddress={onChangeFormInput}
              values={form.address}
              keyName="address"
              targetCountry={country}
            />
            {isNewLoyaltyEnabled && !customer?.loyaltyMember && (
              <View style={theme.forms.row}>
                <InputToggle
                  testID="toggle-loyalty-status"
                  isToggled={form.loyaltyMember}
                  onToggle={() =>
                    onChangeFormInput('loyaltyMember', !form.loyaltyMember)
                  }
                  type="switch"
                  title={translate('form.enrolToLoyalty')}
                  subtitle={translate('form.enrolToLoyaltySubtitle')}
                  containerStyle={theme.forms.inputFluid}
                />
              </View>
            )}
          </View>
          <View style={styles.actions}>
            <TreatButton
              type="cancel"
              testID="btn-dismiss"
              disabled={loading}
              onPress={closeModal}
              label={translate('button.dismiss')}
            />
            <TreatButton
              type={isEditing ? 'neutral' : 'positive'}
              testID="btn-save"
              onPress={onSave}
              label={
                isEditing
                  ? translate('button.saveChanges')
                  : translate('productSettings.create')
              }
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{ marginLeft: 10 }}
              isLoading={loading}
            />
          </View>
        </ScrollView>
      </View>
    </KeyboardAvoidingView>
  );
};

export default AddCustomer;
