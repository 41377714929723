import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    alignSelf: 'center',
  },
  content: {
    padding: 30,
    marginTop: 8,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  input: {
    marginTop: 20,
  },
  btnConfirm: {
    marginTop: 30,
  },
});

export default styles;
