import { StyleSheet } from 'react-native';
import scale from '../../../common/theme';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.colors.primaryLight,
  },
  bgStyle: {
    width: '100%',
    position: 'absolute',
    height: scale.deviceHeight,
  },
  popup: {
    width: 216,
    maxHeight: 300,
    padding: 8,
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    ...theme.shadow.elevated1,
  },
  link: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
  },
  color: {
    width: 8,
    height: 8,
    marginRight: 8,
    borderRadius: 2,
    backgroundColor: theme.colors.primary,
  },
  linkText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.regular,
  },
});

export default styles;
