import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { View } from 'react-native';
import {
  Product,
  Customer,
  FeatureIDs,
  ProductMaps,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { convertToBoolean } from '@oolio-group/client-utils';
import { getOnAccountPayment } from '@oolio-group/order-helper';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import { AppScreen } from '../../../../types/AppScreen';
import { useSession } from '../../../../hooks/app/useSession';
import { useLoyalty } from '../../../../hooks/app/loyalty/useLoyalty';
import { useCart } from '../../../../hooks/orders/useCart';
import { useCatalogue } from '../../../../hooks/app/catalogue/useCatalogue';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import { failedPrintJobsCountVar } from '../../../../state/cache';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import styles from './TakeOrder.styles';
import TakeOrderContainer from './TakeOrder';
import ActionBanner from '../../../../components/POS/Banners/BannerAction';
import OrdersSegmentTabs from '../OrdersSegmentTabs';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import ButtonActions from '../../../../components/Shared/TreatButton/ButtonActions';
import AssignCustomer from '../../../../components/POS/AssignCustomer/AssignCustomer';
import NewOrderButton from '../../../../components/POS/NewOrderButton/NewOrderButton';
import SearchProduct from '../../../../components/POS/Modals/SearchProduct/SearchProduct';
import { useCustomers } from '../../../../hooks/orders/useCustomers';
import NoBarcodeMatchModal from '../../../../components/Modals/NoBarcodeMatch/NoBarcodeMatch';
import { catalogueUtility } from '../../../../state/catalogueUtility';
import { distinctUntilChanged, pluck } from 'rxjs';
import { useCodeScanner } from '../../../../hooks/useCodeScanner';
import {
  mapMemberToCustomer,
  useOolioLoyalty,
} from '../../../../hooks/useOolioLoyalty';
import { useRewards } from '../../../../hooks/orders/useRewards';
import CustomerRewardModal from '../../../../components/Modals/CustomerLoyalty/CustomerRewardModal';
import { useNotification } from '../../../../hooks/Notification';

interface RouteParams {
  orderType: string;
  table: string;
  id: string;
  searchedProduct: Product;
  isCompleted: boolean;
  isExisting: boolean;
  showSpinner?: boolean;
}

const TakeOrderLayout: React.FC = () => {
  const { showNotification } = useNotification();
  const assignCustomerFromTakeOrder =
    useRef<(customer: Customer) => void | null>(null);
  const pressPrintReceiptFromTakeOrder = useRef<Function>(null);
  const handleSelectProductFromTakeOrder = useRef<Function>(null);
  const unassignCustomerFromOrder = useRef<(arg: null) => void | null>(null);
  const newOrderButtonOnSaveDiscardModalFromTakeOrder = useRef<Function>(null);

  const route = useRoute();
  const [session] = useSession();
  const {
    customerMaps,
    getCustomerById,
    loading: customerLoading,
  } = useCustomers();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { order, isDirty, getCartUnSavedStatus } = useCart();
  const { loyaltySettings, getLoyaltyPrograms, rewardRules } = useLoyalty({
    venueId: session.currentVenue?.id,
    fetchPolicy: 'cache-first',
  });
  const { searchMembers, isLoyaltyEnabled } = useOolioLoyalty();

  const isCartUnSaved = getCartUnSavedStatus();

  const params = route.params as RouteParams;
  const currentStoreId = session?.currentStore?.id;
  const orderId = order?.id;

  const [hasNewCatalogVersion, setNewCatalogVersion] = useState(false);
  const isOrderComplete = convertToBoolean(params?.isCompleted);
  const failedPrintJobsCount = useReactiveVar<number>(failedPrintJobsCountVar);

  const currentMenuId = session?.deviceProfile?.menu?.id;

  useEffect(() => {
    const isLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
    if (isLoyaltyEnabled) {
      getLoyaltyPrograms();
    }
  }, [isFeatureEnabled, getLoyaltyPrograms]);

  const assignedCustomer = useMemo(
    () => customerMaps[order?.customer?.id || ''],
    [customerMaps, order?.customer?.id],
  );

  const {
    productMaps,
    variantMaps,
    menuOptions,
    loading,
    allPageItemMaps,
    allNestedPages,
    sortedMenuItems,
    getAllMenuOptions,
  } = useCatalogue({
    store: currentStoreId,
    menuId: currentMenuId,
    subscribeToData: true,
  });

  const isValidProduct = useCallback(
    (product: Product) => {
      if (
        product.isCombo &&
        product.comboType &&
        !product.modifierGroups?.length
      ) {
        showNotification({
          error: true,
          message: translate('order.emptyOptionsForComboProducts'),
        });
        return false;
      }
      return true;
    },
    [showNotification, translate],
  );

  const onHandleSelectProduct = useCallback(
    (selectedProduct: Product) => {
      if (
        handleSelectProductFromTakeOrder?.current &&
        isValidProduct(selectedProduct)
      ) {
        handleSelectProductFromTakeOrder.current(selectedProduct);
      }
    },
    [isValidProduct],
  );

  const onPressSearch = useCallback(() => {
    analyticsService.capture('search_product');
    let selectedProduct: Product;
    const onSubmit = (product: Product) => {
      if (!isValidProduct(product)) return;
      selectedProduct = product;
    };
    const onCloseModalCompleted = () => {
      if (selectedProduct) onHandleSelectProduct(selectedProduct);
    };
    showModal(
      <SearchProduct onSelectProduct={onSubmit} allProducts={productMaps} />,
      {
        onBackdropPress: closeModal,
        onModalHide: onCloseModalCompleted,
      },
    );
  }, [
    showModal,
    productMaps,
    closeModal,
    isValidProduct,
    onHandleSelectProduct,
  ]);

  const barcodeMaps: ProductMaps = useMemo(() => {
    const barcodeProductMaps: ProductMaps = {};
    Object.entries(productMaps).forEach(([, product]) => {
      if (product.barcode) {
        barcodeProductMaps[product.barcode] = product;
      }
    });
    return barcodeProductMaps;
  }, [productMaps]);

  const onAssignCustomerToOrder = useCallback(
    customer => {
      if (!getOnAccountPayment(order?.payments ?? []) && !isOrderComplete) {
        analyticsService.capture('assign_customer', {
          location: 'Take Order',
        });
        if (assignCustomerFromTakeOrder?.current) {
          assignCustomerFromTakeOrder?.current(customer);
        }
      }
    },
    [isOrderComplete, order?.payments],
  );

  const onUnAssignCustomerToOrder = useCallback(() => {
    if (unassignCustomerFromOrder?.current)
      unassignCustomerFromOrder.current(null);
  }, []);

  const { redeemRewards } = useRewards(rewardRules);

  const onScanBarcode = useCallback(
    async (barcodeSearchString: string) => {
      if (barcodeSearchString) {
        if (barcodeSearchString.startsWith('LY')) {
          // loyalty member barcode
          const members = await searchMembers(barcodeSearchString);
          if (members?.items.length) {
            const customer = mapMemberToCustomer(members.items[0]);
            onAssignCustomerToOrder(customer);
            if (isLoyaltyEnabled && customer.loyaltyMember) {
              showModal(
                <CustomerRewardModal
                  customer={customer}
                  onRedeem={(...args) => {
                    redeemRewards(...args);
                    closeModal();
                  }}
                  rewardRules={rewardRules}
                />,
              );
            }
            return;
          }
        }

        const result: Product | undefined = barcodeMaps[barcodeSearchString];
        if (result) {
          onHandleSelectProduct(result);
        } else {
          showModal(
            <NoBarcodeMatchModal
              title={translate('barcodeModal.title')}
              message={translate('barcodeModal.message')}
              onSearch={onPressSearch}
            />,
          );
        }
      }
    },
    [
      barcodeMaps,
      isLoyaltyEnabled,
      onAssignCustomerToOrder,
      onPressSearch,
      redeemRewards,
      rewardRules,
      searchMembers,
      showModal,
      translate,
      closeModal,
      onHandleSelectProduct,
    ],
  );

  useCodeScanner({
    enabled: session.settings?.enableBarcodeScanningMode,
    callback: onScanBarcode,
  });

  useEffect(() => {
    const subscription = catalogueUtility.getSubscriptionState$
      .pipe(
        pluck('hasCatalogueUpdates'),
        distinctUntilChanged((pre, curr) => pre === curr),
      )
      .subscribe(state => {
        setNewCatalogVersion(!!state);
      });
    return () => subscription.unsubscribe?.();
  }, []);

  const onPressPrintReceipt = useCallback(async () => {
    if (pressPrintReceiptFromTakeOrder?.current)
      pressPrintReceiptFromTakeOrder.current();
  }, []);

  const confirmPrintingWithUnSavedData = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={translate('cart.printWithUnSavedItems.title')}
        message={translate('cart.printWithUnSavedItems.message')}
        onConfirm={(): void => {
          onPressPrintReceipt();
          closeModal();
        }}
      />,
    );
  }, [showModal, onPressPrintReceipt, translate, closeModal]);

  const newOrderButtonOnSaveDiscardModal = useCallback(
    (callback?: Function) => {
      if (newOrderButtonOnSaveDiscardModalFromTakeOrder?.current)
        newOrderButtonOnSaveDiscardModalFromTakeOrder.current(callback);
    },
    [],
  );

  const onPressFloorView = useCallback(() => {
    navigation.navigate('FloorView');
  }, [navigation]);

  const onPressPrintOrder = useCallback(
    (hasOrders: boolean) => {
      analyticsService.capture('print_receipt', {
        location: 'Action Bar',
      });

      if (hasOrders) {
        if (isDirty) {
          confirmPrintingWithUnSavedData();
        } else {
          onPressPrintReceipt();
        }
      }
    },
    [confirmPrintingWithUnSavedData, isDirty, onPressPrintReceipt],
  );

  const onIgnoreCatalogueUpdates = useCallback(() => {
    catalogueUtility.onIgnoreUpdates();
  }, []);

  const onAcceptCatalogueUpdates = useCallback(() => {
    catalogueUtility.onAcceptUpdates();
  }, []);
  const HeaderActionsRight = useMemo(() => {
    const hasOrders = (order?.orderItems || []).length > 0;
    const enableFloorView = session?.deviceProfile?.enableFloorView;
    const isTableFeatureEnabled = isFeatureEnabled(
      FeatureIDs.TABLE_MANAGEMENT,
      session.currentVenue?.id,
    );

    return (
      <>
        {enableFloorView && isTableFeatureEnabled && (
          <ButtonIcon
            testID="btn-floorView"
            size={44}
            icon="crockery"
            type="interface"
            onPress={onPressFloorView}
            containerStyle={styles.btnGap}
          />
        )}
        <ButtonIcon
          testID="btn-searchProducts"
          size={44}
          icon="search"
          type="interface"
          onPress={onPressSearch}
          containerStyle={styles.btnGap}
        />
        <View style={styles.btnPrint}>
          {failedPrintJobsCount ? <View style={styles.indicator} /> : null}
          <ButtonActions
            testID="btn-printOrder"
            height={44}
            icon="print"
            label="Print"
            type="interface"
            disabled={!failedPrintJobsCount && !hasOrders}
            actions={[
              {
                id: 'btn-printCurrentReceipt',
                label: translate('order.header.printCurrentReceipt'),
                disabled: !hasOrders,
                action: () => onPressPrintOrder(hasOrders),
              },
              {
                id: 'btn-viewPrintingQueue',
                label: translate('order.header.viewPrintingQueue', {
                  count: failedPrintJobsCount,
                }),
                action: () => navigation.navigate('PrintJobs'),
              },
            ]}
          />
        </View>
        <NewOrderButton
          isCartUnsaved={isCartUnSaved}
          onSave={newOrderButtonOnSaveDiscardModal}
        />
      </>
    );
  }, [
    order?.orderItems,
    session?.deviceProfile?.enableFloorView,
    session.currentVenue?.id,
    isFeatureEnabled,
    onPressFloorView,
    onPressSearch,
    failedPrintJobsCount,
    translate,
    isCartUnSaved,
    newOrderButtonOnSaveDiscardModal,
    onPressPrintOrder,
    navigation,
  ]);

  const HeaderActionsLeft = useMemo(() => {
    const customerName = assignedCustomer
      ? `${assignedCustomer?.firstName} ${assignedCustomer?.lastName}`
      : translate('button.addCustomer');

    if (!orderId) {
      return <></>;
    }

    return (
      <AssignCustomer
        secondary
        orderId={orderId}
        name={customerName}
        assignedCustomer={assignedCustomer}
        payments={undefined}
        loyaltySettings={loyaltySettings}
        rewardRules={rewardRules}
        onAssign={onAssignCustomerToOrder}
        onUnassign={onUnAssignCustomerToOrder}
        containerStyles={styles.inputCustomer}
        loading={customerLoading}
      />
    );
  }, [
    assignedCustomer,
    translate,
    orderId,
    loyaltySettings,
    rewardRules,
    onAssignCustomerToOrder,
    onUnAssignCustomerToOrder,
    customerLoading,
  ]);

  useEffect(() => {
    getAllMenuOptions();
  }, [getAllMenuOptions]);

  useEffect(() => {
    if (!assignedCustomer && order?.customer?.id) {
      getCustomerById(order.customer.id);
    }
  }, [assignedCustomer, getCustomerById, order?.customer?.id]);

  return (
    <ScreenLayout
      title="Order"
      hideNewOrder
      loading={loading}
      scrollEnabled={false}
      actionsLeft={HeaderActionsLeft}
      actionsRight={HeaderActionsRight}
      tabs={
        <OrdersSegmentTabs
          activeScreen={AppScreen.NEW_ORDER}
          previousScreen={AppScreen.NEW_ORDER}
        />
      }
      banner={
        <ActionBanner
          show={hasNewCatalogVersion}
          dismissAction={{ onPress: onIgnoreCatalogueUpdates }}
          confirmAction={{ onPress: onAcceptCatalogueUpdates }}
          message={translate('menus.menuChangedInfo')}
        />
      }
    >
      <TakeOrderContainer
        onAssignCustomer={assignCustomerFromTakeOrder}
        unAssignCustomer={unassignCustomerFromOrder}
        newOrderButtonOnSaveCallback={
          newOrderButtonOnSaveDiscardModalFromTakeOrder
        }
        handleSelectProductFromSearch={handleSelectProductFromTakeOrder}
        pressPrintReceiptCallback={pressPrintReceiptFromTakeOrder}
        testID={'TakeOrderScreen'}
        menus={menuOptions}
        allPageItemMaps={allPageItemMaps}
        allVariants={variantMaps}
        allProducts={productMaps}
        allNestedPages={allNestedPages}
        sortedMenuItems={sortedMenuItems}
        assignedCustomer={assignedCustomer}
      />
    </ScreenLayout>
  );
};

export default TakeOrderLayout;
