import React from 'react';
import { View, Text } from 'react-native';
import { RewardRule } from '@oolio-group/domain';
import styles from '../Settings.styles';
import theme from '../../../../../common/default-theme';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

export interface RewardRuleRowProps {
  item: RewardRule;
  singularTerm: string;
  pluralTerm: string;
  disabled?: boolean;
  onEdit: () => void;
  onDeleteItem: () => void;
}

const RedeemRewardRow: React.FC<RewardRuleRowProps> = ({
  item,
  onEdit,
  singularTerm,
  pluralTerm,
  onDeleteItem,
  disabled,
}) => {
  const isMultiple = item.pointsRequired;

  return (
    <View testID="row-reward" style={theme.tables.row}>
      <Text testID="text-name" style={styles.rowName}>
        {item.rewardName}
      </Text>
      <Text testID="text-value" style={styles.rowValue}>{`${
        item.pointsRequired
      } ${isMultiple ? pluralTerm : singularTerm}`}</Text>
      <ButtonIcon
        testID="btn-editReward"
        type="neutralLight"
        icon="pen"
        onPress={onEdit}
      />
      <ButtonIcon
        testID="btn-deleteReward"
        type="negativeLight"
        icon="trash-alt"
        disabled={disabled}
        onPress={onDeleteItem}
        containerStyle={styles.btnDelete}
      />
    </View>
  );
};

export default RedeemRewardRow;
