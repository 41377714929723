import { useCurrency } from '@oolio-group/localization/dist/hooks/useCurrency';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import Icon from '../../Icon/Icon';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
import { useFela } from 'react-fela';
import { StyleFn } from '@oolio-group/domain';
import { useSession } from '../../../hooks/app/useSession';
import { AppScreen } from '../../../types/AppScreen';
import { isValidEmail } from '../../../utils/validator';
import { useNotification } from '../../../hooks/Notification';

export interface PaymentConfirmationModalProps {
  changeDue?: number;
  sendReceipt?: (email: string) => void;
  customer?: {
    email?: string;
    id?: string;
    firstName?: string;
    lastName?: string;
    customerAccountDetails?: {
      accountPayment?: boolean;
      currentBalance?: number;
      maxBalance?: number;
      maxOrderLimit?: number;
    };
  };
  isRefundOrder?: boolean;
  onPressPrintReceipt: () => Promise<void>;
  amount?: number;
}

export interface ReceiptPrintFormProps {
  email: string;
  phone: string;
}

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  marginVertical: theme.spacing.small * 2,
  flexDirection: 'column',
  justifyContent: 'center',
});

const modalTitleContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing.big,
  backgroundColor: theme.colors.background,
  width: 350,
  height: 165,
});

const completedTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontSize: theme.fontSize.largest,
  fontFamily: theme.font.bold,
  textTransform: 'capitalize',
  letterSpacing: -0.5,
});

const completedOnAccountTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontSize: theme.fontSize.large,
  fontFamily: theme.font.bold,
  textTransform: 'capitalize',
  letterSpacing: -0.5,
});

const customerAccountTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
});

const phoneFormStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: 44,
  marginBottom: theme.spacing.medium + 1,
});

const emailFormStyle: StyleFn = () => ({
  width: 300,
  height: 44,
});

const modalButtonStyle: StyleFn = ({ theme }) => ({
  marginVertical: theme.spacing.small * 0.5,
  width: 300,
  height: 44,
  paddingHorizontal: theme.spacing.small * 2.5,
});

const dismissStyle: StyleFn = () => ({
  alignSelf: 'center',
});

const modalStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  width: 350,
  alignSelf: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const completedIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  marginBottom: theme.spacing.small * 1.5,
});

const dismissBtnLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
});

const inputContainerStyle: StyleFn = () => ({ alignItems: 'center' });

const PaymentConfirmationModal: React.FC<PaymentConfirmationModalProps> = ({
  changeDue,
  sendReceipt,
  customer = {},
  onPressPrintReceipt,
  isRefundOrder,
  amount,
}: PaymentConfirmationModalProps) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { css } = useFela();
  const [session] = useSession();
  const navigation = useNavigation();
  const { closeModal } = useModal();
  const { postSaleScreen } = session.deviceProfile || {};
  const [userAccountBalance, setUserAccountBalance] = useState(0);
  const [formData, setFormData] = useState<ReceiptPrintFormProps>({
    email: customer?.email || '',
    phone: '',
  });
  const { showNotification } = useNotification();

  const onIgnoreModal = useCallback(() => {
    closeModal();
    if (!isRefundOrder) {
      const screen = postSaleScreen
        ? AppScreen[postSaleScreen]
        : AppScreen.NEW_ORDER;
      const params = screen === AppScreen.NEW_ORDER ? { id: undefined } : {};
      navigation.navigate(screen, params);
    }
  }, [closeModal, isRefundOrder, postSaleScreen, navigation]);

  const _onChangeProp = (prop: string, text: string): void => {
    setFormData({ ...formData, [prop]: text });
  };
  const _sendEmailReceipt = useCallback(() => {
    if (formData.email && sendReceipt) {
      if (isValidEmail(formData.email)) {
        sendReceipt(formData.email);
      } else {
        showNotification({
          error: true,
          message: 'Invalid email',
        });
      }
    } else {
      showNotification({
        error: true,
        message: 'Provide email address',
      });
    }
  }, [sendReceipt, formData, showNotification]);
  useEffect(() => {
    if (customer?.customerAccountDetails?.currentBalance && amount) {
      setUserAccountBalance(
        customer?.customerAccountDetails?.currentBalance - amount,
      );
    }
  }, [
    amount,
    customer?.customerAccountDetails?.currentBalance,
    showNotification,
  ]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={css(modalStyle)}
    >
      {customer?.customerAccountDetails?.accountPayment ? (
        <View style={css(modalTitleContainerStyle)}>
          <Icon name="check-circle" size={60} style={css(completedIconStyle)} />
          <Text style={css(completedOnAccountTextStyle)}>
            {translate('payment.totalOutstanding')}:{' '}
            {formatCurrency(userAccountBalance as number)}
          </Text>
          <Text style={css(customerAccountTextStyle)}>
            {translate('payment.customer')}: {customer?.firstName}{' '}
            {customer?.lastName}
          </Text>
          <Text style={css(customerAccountTextStyle)}>
            {translate('payment.orderTotal')}:{' '}
            {formatCurrency(amount as number)}
          </Text>
        </View>
      ) : (
        <View style={css(modalTitleContainerStyle)}>
          <Icon name="check-circle" size={60} style={css(completedIconStyle)} />
          <Text style={css(completedTextStyle)}>
            {changeDue !== undefined
              ? `${translate('payment.change')}: ${formatCurrency(changeDue)}`
              : isRefundOrder
              ? `${translate('refundOrder.refundCompleted')}`
              : `${translate('payment.completed')}`}
          </Text>
        </View>
      )}
      <View style={css(inputContainerStyle)}>
        <FormInput
          placeholder={translate('payment.phonePlaceholder')}
          placeHolderIcon={'phone-volume'}
          containerStyle={css(phoneFormStyle)}
        />
        <FormInput
          placeholder={translate('common.emailPlaceholder')}
          placeHolderIcon={'envelope-alt'}
          containerStyle={css(emailFormStyle)}
          value={formData.email}
          onChangeText={text => _onChangeProp('email', text)}
        />
        <View style={css(buttonContainerStyle)}>
          <Button
            testID="SendEmailReceipt"
            success
            size="small"
            title="Send Receipt"
            containerStyle={css(modalButtonStyle)}
            onPress={_sendEmailReceipt}
          />
          <Button
            secondary
            size="small"
            title="Print"
            containerStyle={css(modalButtonStyle)}
            onPress={onPressPrintReceipt}
          />
          <Button
            fluid
            testID="dismiss"
            title={translate('order.dismiss')}
            onPress={onIgnoreModal}
            labelStyle={css(dismissBtnLabelStyle)}
            containerStyle={css(dismissStyle)}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default PaymentConfirmationModal;
