import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

export const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: 360,
    paddingVertical: 60,
    paddingHorizontal: 40,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  logo: {
    marginBottom: 40,
  },
  btnCode: {
    marginTop: 10,
    marginBottom: 40,
  },
  btnFooter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnFooterText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  btnCreate: {
    marginTop: 12,
  },
  phone: {
    color: theme.colors.primary,
  },
});
