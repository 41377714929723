import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import styles from './CartKeypad.styles';

export interface CartKeypadNumberItemProps {
  number: string;
  onPressKey: (number: string) => void;
}

const CartKeyPadButton: React.FC<CartKeypadNumberItemProps> = ({
  number,
  onPressKey,
}) => {
  return (
    <TouchableOpacity
      testID="btn-numberKey"
      onPress={() => onPressKey(number)}
      style={[
        styles.keyNumber,
        // eslint-disable-next-line react-native/no-inline-styles
        { width: number === '0' ? 152 : 74 },
      ]}
    >
      <Text style={styles.numpadText}>{number}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(CartKeyPadButton);
