import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@oolio-group/localization';
import {
  OnboardingAction,
  OnboardingSection,
  OnboardingArea,
  OnboardingCheckList,
  PaymentType,
  UpdatePaymentTypeInput,
  DefaultPaymentTypes,
} from '@oolio-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { UPDATE_PAYMENT_TYPES } from '../../../../graphql/settings';
import { usePaymentTypes } from '../../../../hooks/app/usePaymentTypes';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../hooks/Notification';
import { useOnboarding } from '../../../../hooks/app/useOnboarding';
import { noopHandler, parseApolloError } from '../../../../utils/errorHandlers';
import { stripProperties } from '../../../../utils/stripObjectProps';
import { CreatePaymentModal } from './CreateModal/CreatePaymentTypeModal';
import { DeletePaymentModal } from './DeleteModal/DeletePaymentModal';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import theme from '../../../../common/default-theme';
import styles from './PaymentsTypes.styles';

export interface PaymentRowProps {
  paymentType: PaymentType;
  onDeletePayment: (index: number, id: string) => void;
  onChange: (index: number, prop: string, value: string | boolean) => void;
  index: number;
}

interface PaymentTypeDisplay extends PaymentType {
  value: string;
  label: string;
}

const PaymentRow: React.FC<PaymentRowProps> = ({
  paymentType,
  onDeletePayment,
  onChange,
  index,
}: PaymentRowProps) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const onPaymentTypePromptChange = () => {
    onChange(index, 'adjustmentPrompt', !paymentType.adjustmentPrompt);
  };

  const isPreDefinedPaymentType = Object.values(DefaultPaymentTypes).includes(
    paymentType.name as DefaultPaymentTypes,
  );

  const onPressDelete = useCallback(
    (index: number, id: string, name: string): void => {
      showModal(
        <DeletePaymentModal
          index={index}
          id={id}
          name={name}
          onDeletePayment={onDeletePayment}
        />,
      );
    },
    [showModal, onDeletePayment],
  );

  return (
    <View testID="row-payment-type" style={theme.tables.row}>
      <InputText
        testID="input-name"
        value={paymentType.name}
        editable={!isPreDefinedPaymentType}
        placeholder={translate('backOfficeSettings.paymentTypeName')}
        onChangeText={
          !isPreDefinedPaymentType
            ? onChange.bind(null, index, 'name')
            : undefined
        }
        containerStyle={styles.cellName}
      />
      <InputToggle
        testID="toggle-prompt"
        type="checkbox"
        onToggle={onPaymentTypePromptChange}
        isToggled={paymentType.adjustmentPrompt}
        title={translate('backOfficeSettings.promptAdjustment')}
        containerStyle={styles.cellToggle}
      />
      <ButtonIcon
        testID="btn-delete"
        icon="trash-alt"
        type={isPreDefinedPaymentType ? 'cancel' : 'negativeLight'}
        disabled={isPreDefinedPaymentType}
        onPress={(): void =>
          onPressDelete(index, paymentType.id, paymentType.name)
        }
      />
    </View>
  );
};

export const PaymentsTypes: React.FC = () => {
  const { showModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { updateOnboardingStatus } = useOnboarding();
  const [paymentTypesData, setPaymentTypesData] = useState([] as PaymentType[]);

  const { paymentTypes, status, refetch } = usePaymentTypes();

  const [updatePaymentTypes, updatePaymentRequest] = useMutation(
    UPDATE_PAYMENT_TYPES,
    {
      onError: noopHandler,
    },
  );

  useEffect((): void => {
    if (updatePaymentRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(updatePaymentRequest.error),
      });
    }
  }, [updatePaymentRequest.error, showNotification]);

  useEffect((): void => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect((): void => {
    if (paymentTypes.length) {
      setPaymentTypesData(
        (paymentTypes as PaymentTypeDisplay[]).map(x =>
          stripProperties(x, 'label', 'value'),
        ),
      );
    }
  }, [paymentTypes]);

  useEffect(() => {
    updateOnboardingStatus(
      OnboardingArea.SETTINGS,
      OnboardingSection.VIEW_ADD_PAYMENT_TYPE,
      OnboardingCheckList.PAYMENT_TYPES,
      OnboardingAction.READ,
    );
  }, [updateOnboardingStatus]);

  const onAddPayment = useCallback(
    (paymentType: PaymentType): void => {
      const paymentTypesTempData = [...paymentTypesData];
      paymentTypesTempData.push(paymentType);
      setPaymentTypesData(paymentTypesTempData);
      refetch && refetch();
    },
    [paymentTypesData, refetch],
  );

  const onDeletePayment = useCallback(
    (index: number, id: string): void => {
      const paymentTypesDataTemp = [...paymentTypesData];
      if (paymentTypesDataTemp[index].id === id) {
        paymentTypesDataTemp.splice(index, 1);
        setPaymentTypesData(paymentTypesDataTemp);
        refetch();
      }
    },
    [paymentTypesData, refetch],
  );

  const onPressSave = useCallback((): void => {
    const input = stripProperties(
      paymentTypesData,
      '__typename',
    ) as PaymentType[];

    const inputMapped = input.map(paymentType => {
      paymentType.adjustmentPrompt = Boolean(paymentType.adjustmentPrompt);
      return paymentType;
    });

    if (input.some(paymentType => !paymentType.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeDevices.fieldsMissing'),
      });
    } else {
      updatePaymentTypes({
        variables: { input: inputMapped as UpdatePaymentTypeInput[] },
      });
    }
  }, [paymentTypesData, updatePaymentTypes, showNotification, translate]);

  const onCreatePaymentType = useCallback(() => {
    showModal(<CreatePaymentModal onAddPayment={onAddPayment} />);
  }, [onAddPayment, showModal]);

  const sortedPaymentTypes = useMemo(() => {
    const predefinedTypes: PaymentType[] = [];
    const customTypes: PaymentType[] = [];

    paymentTypesData.forEach(paymentType => {
      const isPredefined = Object.values(DefaultPaymentTypes).includes(
        paymentType.name as DefaultPaymentTypes,
      );

      if (isPredefined) {
        predefinedTypes.push(paymentType);
      } else {
        customTypes.push(paymentType);
      }
    });

    return [...predefinedTypes, ...customTypes];
  }, [paymentTypesData]);

  const onChange = useCallback(
    (index: number, prop, value): void => {
      const formDataTemp = sortedPaymentTypes;
      formDataTemp[index] = { ...formDataTemp[index], [prop]: value };
      setPaymentTypesData([...formDataTemp]);
    },
    [sortedPaymentTypes],
  );

  return (
    <ScreenLayout
      loading={status.loading || updatePaymentRequest.loading}
      title="Payment Types | Oolio"
      onSave={onPressSave}
    >
      <Section
        layoutWidth="medium"
        title={translate('backOfficeSettings.paymentTypes')}
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('form.name')}
            </Text>
          </View>
          <View>
            {sortedPaymentTypes.map((type: PaymentType, i: number) => (
              <PaymentRow
                index={i}
                key={type.id}
                paymentType={type}
                onDeletePayment={onDeletePayment}
                onChange={onChange}
              />
            ))}
          </View>
        </View>
        <CreateButton onPress={onCreatePaymentType} />
      </Section>
    </ScreenLayout>
  );
};
