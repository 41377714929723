import { useCallback, useEffect, useRef } from 'react';
import { Platform, AppState } from 'react-native';
import { isOlderVersion } from '../../analytics/AnalyticsHelpers';
import { analyticsService } from '../../analytics/AnalyticsService';
import { AuthState, FEATURES } from '../../constants';
import { useDeviceInfo } from '../../hooks/app/useDeviceInfo';
import { forceUpdateVersionVar } from '../../state/cache';

export interface OrderSyncEffectProps {
  authState: AuthState;
}

const isProdEnv = (process.env.REACT_APP_LOGGING_ENV || '').startsWith('prod');

export const useFeatureFlags = () => {
  const { versionNum } = useDeviceInfo();
  const previousAppState = useRef<string>('');

  const checkAppVersion = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (featurePayload: any) => {
      if (Platform.OS === 'web') return;
      const minAppVersion = featurePayload[FEATURES.MIN_SUPPORTED_VERSION];
      if (
        isProdEnv &&
        minAppVersion &&
        isOlderVersion(versionNum, minAppVersion)
      ) {
        forceUpdateVersionVar(minAppVersion);
      } else {
        forceUpdateVersionVar('');
      }
    },
    [versionNum],
  );

  const checkFeatureFlag = useCallback(async () => {
    const res = await analyticsService.getAllFeatureFlags();
    checkAppVersion(res[0]);
  }, [checkAppVersion]);

  useEffect(() => {
    checkFeatureFlag();
    const appStateSub = AppState.addEventListener('change', appState => {
      if (/^active$/.test(appState)) {
        checkFeatureFlag();
      }
      previousAppState.current = appState;
    });
    return appStateSub?.remove;
  }, [checkFeatureFlag]);
};
