import React, { useState, useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import {
  CheckoutOptions,
  UpdateStoreCheckoutOptionsInput,
  RoundOffValue,
} from '@oolio-group/domain';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import {
  GET_CHECKOUT_OPTIONS_QUERY,
  UPDATE_STORE_CHECKOUT_OPTIONS,
} from '../../../../../graphql/store';
import { useSession } from '../../../../../hooks/app/useSession';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import TreatPicker from '../../../../../components/Shared/Select/Picker';

export const Checkout: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({} as CheckoutOptions);
  const [session, updateSession] = useSession();

  const route = useRoute();

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const { venueId, storeId } = params;

  const checkoutDetailsQuery = useQuery(GET_CHECKOUT_OPTIONS_QUERY, {
    variables: { id: storeId },
    fetchPolicy: 'cache-and-network',
  });

  const onUpdateStoreSuccess = (data: {
    updateStoreCheckoutOptions: { checkoutOptions: CheckoutOptions };
  }) => {
    const checkOutOptions = data?.updateStoreCheckoutOptions?.checkoutOptions;
    if (checkOutOptions) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
      updateCheckoutOptionInSession(checkOutOptions);
    }
  };

  const [updateStore, updateOperation] = useMutation(
    UPDATE_STORE_CHECKOUT_OPTIONS,
    {
      onError: noopHandler,
      onCompleted: onUpdateStoreSuccess,
      context: {
        headers: { venue: venueId, store: storeId },
      },
    },
  );

  useEffect((): void => {
    if (updateOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(updateOperation.error),
      });
    }
  }, [updateOperation.error, showNotification]);

  const updateCheckoutOptionInSession = useCallback(
    (input: CheckoutOptions) => {
      updateSession({
        ...session,
        currentStore: {
          ...session.currentStore,
          checkoutOptions: input,
        },
      });
    },
    [session, updateSession],
  );

  useEffect(() => {
    if (checkoutDetailsQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(checkoutDetailsQuery.error),
      });
    }
  }, [checkoutDetailsQuery.error, showNotification]);

  useEffect(() => {
    if (checkoutDetailsQuery.data) {
      setForm({
        ...checkoutDetailsQuery.data.store.checkoutOptions,
      });
    }
  }, [checkoutDetailsQuery.data]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onPressSave = useCallback((): void => {
    let formTemp = {
      checkoutOptions: {
        ...form,
        enableTips: form?.enableTips || false,
      } as CheckoutOptions,
      id: storeId,
    };
    // removing __typename from object
    formTemp = stripProperties(formTemp, '__typename');
    updateStore({
      variables: {
        input: formTemp as unknown as UpdateStoreCheckoutOptionsInput,
      },
    });
  }, [form, updateStore, storeId]);

  return (
    <ScreenLayout title="Checkout | Oolio" onSave={onPressSave}>
      <Section title="Rounding">
        <InputToggle
          testID="toggle-rounding"
          type="switch"
          title={translate('backOfficeSettings.enableRoundOff')}
          isToggled={form.enableRoundOff}
          onToggle={onChange.bind(null, 'enableRoundOff', !form.enableRoundOff)}
          containerStyle={styles.inputContainer}
        />
        {form.enableRoundOff ? (
          <TreatPicker
            testID="select-roundoff"
            options={Object.keys(RoundOffValue).map((value, index) => ({
              value: value,
              label: Object.values(RoundOffValue)[index],
            }))}
            title={translate('backOfficeSettings.value')}
            selectedValue={form.roundOffValue}
            onValueChange={onChange.bind(null, 'roundOffValue')}
            containerStyle={styles.inputContainer}
          />
        ) : (
          <></>
        )}
      </Section>
      <Section title="Tipping">
        <InputToggle
          testID="toggle-tipping"
          type="switch"
          title={translate('backOfficeSettings.enableTipping')}
          isToggled={form.enableTips}
          onToggle={onChange.bind(null, 'enableTips', !form.enableTips)}
          containerStyle={styles.inputContainer}
        />
      </Section>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    marginBottom: 20,
  },
});
