import { StyleSheet, ViewStyle } from 'react-native';
import theme from '../../../common/default-theme';

const row: ViewStyle = {
  columnGap: 10,
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.radius.s,
  backgroundColor: theme.colors.grey1,
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: 700,
    height: 600,
    padding: 30,
    rowGap: 20,
    borderRadius: theme.radius.l,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 16,
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  filters: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    marginBottom: 20,
  },
  search: {
    flex: 1,
  },
  list: {
    height: 404,
  },
  cellName: {
    flex: 1,
    rowGap: 4,
  },
  rowOrg: {
    ...row,
    padding: 16,
    marginBottom: 8,
  },
  rowVenue: {
    marginBottom: 20,
  },
  rowStore: {
    ...theme.tables.row,
    height: 50,
    columnGap: 10,
    paddingHorizontal: 12,
  },
  rowDevice: {
    ...row,
    padding: 16,
    marginBottom: 8,
  },
  rowTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  rowSubtitle: {
    fontSize: 13,
    lineHeight: 16,
    color: theme.colors.grey5,
    fontFamily: theme.fonts.medium,
    fontVariant: ['tabular-nums'],
  },
  unpairedText: {
    fontSize: 13,
    lineHeight: 16,
    color: theme.colors.states.neutral,
    fontFamily: theme.fonts.regular,
  },
  rowError: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.states.negative,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
