import { useLazyQuery, useMutation } from '@apollo/client';
import { useMemo, useCallback } from 'react';
import { noopHandler } from '../../../utils/errorHandlers';
import { GET_EXPORT_ENTITY_DETAIL, EXPORT_PRODUCT } from './graphql';

export function useProductExport() {
  const [getExportEntityDetail, exportEntityDetailResponse] = useLazyQuery(
    GET_EXPORT_ENTITY_DETAIL,
    {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
    },
  );

  const [exportProductMutation, exportProductResponse] = useMutation(
    EXPORT_PRODUCT,
    {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
    },
  );

  const processExportProduct = useCallback(() => {
    exportProductMutation();
  }, [exportProductMutation]);

  const getExportProductDetail = useCallback(
    (id: string) => {
      getExportEntityDetail({ variables: { id } });
    },
    [getExportEntityDetail],
  );

  const loading = exportProductResponse.loading;

  return useMemo(
    () => ({
      loading,
      exportProductJobId: exportProductResponse.data?.exportProducts ?? '',
      processExportProduct,
      exportProductDetail:
        exportEntityDetailResponse.data?.getExportEntityDetail,
      getExportProductDetail,
    }),
    [
      loading,
      exportProductResponse.data?.exportProducts,
      processExportProduct,
      exportEntityDetailResponse.data?.getExportEntityDetail,
      getExportProductDetail,
    ],
  );
}
