import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  CreateOrUpdatePageInput,
  Catalogue,
  Page,
  Category,
  UpdateCatalogueInput,
  PageItem,
  Product,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused } from '@react-navigation/native';
import sortBy from 'lodash/sortBy';
import {
  difference,
  differenceWith,
  find,
  isEqual,
  keyBy,
  last,
  maxBy,
  omit,
} from 'lodash';
import PageItemsLayout from './PageItemsLayout';
import MenuItemsLayout from './MenuItemsLayout';

import {
  ProductItemType,
  EditMenuAction,
  EditMenuPayload,
  EditMenuState,
  TileItem,
} from '../types';
import {
  filterEntityByStore,
  getLocaleEntity,
} from '@oolio-group/client-utils';
import { useLocalization } from '@oolio-group/localization';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';

export const itemsPerSection = 24;
export const menuItemsPerSection = 9;

export interface EditMenuActionProp {
  type: EditMenuAction;
  payload: Partial<EditMenuPayload>;
}

export const paginationItem = {
  key: 'pagination',
  entityType: 'PAGINATION',
  disabledReSorted: true,
};

const findNextSelectPageId = (
  menuItems: TileItem[],
  removeIndex: number,
): { nextSelectedMenuId?: string; pageNumber?: number } => {
  let nextSelectIndex = removeIndex;
  const length = menuItems.length;
  for (let index = 1; index < length; index++) {
    const validIndex =
      index + removeIndex >= length
        ? index + removeIndex - length
        : index + removeIndex;

    if (nextSelectIndex == removeIndex && menuItems[validIndex]?.entityId) {
      nextSelectIndex = validIndex;
    }
  }

  return {
    nextSelectedMenuId: menuItems[nextSelectIndex].id,
    pageNumber: Math.ceil(nextSelectIndex / menuItemsPerSection) || 1,
  };
};
const checkDuplicatedPriority = <DataType extends { priority?: number }>(
  data: DataType[],
): DataType[] => {
  if (data?.length <= 1) return data || [];
  const sortedData = sortBy(data, item => item.priority);
  let duplicated = false;
  for (let index = 0; index < sortedData.length - 1; index++) {
    if (sortedData[index].priority === sortedData[index + 1].priority) {
      duplicated = true;
    }
  }
  if (duplicated) {
    return sortedData.map((item, index) => ({ ...item, priority: index }));
  }
  return sortedData;
};

const formatItemsToCompare = (
  data: TileItem[],
): Pick<
  TileItem,
  'entityId' | 'id' | 'priority' | 'color' | 'entityType' | 'category'
>[] =>
  data.map(({ entityId, id, color, priority, entityType, category }) => ({
    entityId,
    id,
    color,
    priority,
    entityType,
    category,
  }));

const getItemIds = (data: TileItem[]): string[] =>
  data.map(data => data?.id).filter(id => id) as string[];

const addNewPriorityToItems = (data: TileItem[]): TileItem[] =>
  data.map((item, index) => ({ ...item, priority: index }));

const notEmptyValue = <T extends { id?: string }>(item: T): boolean =>
  Boolean(item?.id);

function editMenuLayoutReducer(
  menuLayoutState: EditMenuState,
  action: EditMenuActionProp,
): EditMenuState {
  const { type, payload } = action;

  switch (type) {
    // --------MENU ITEMS SECTION -------------------
    case EditMenuAction.ON_CHANGE_MENU_ITEMS_PAGINATION:
      return {
        ...menuLayoutState,
        currentMenuItemSection: payload.page as number,
      };
    case EditMenuAction.UPDATE_MENU_ITEM_DATA:
      return {
        ...menuLayoutState,
        menuItems: payload.data as TileItem[],
      };
    case EditMenuAction.UPDATE_MENU_ITEM_DATA_FROM_SERVER:
      return {
        ...menuLayoutState,
        menuItems: payload.data as TileItem[],
        originalMenuItems: payload.originalData as TileItem[],
      };
    case EditMenuAction.UPDATE_SELECTED_MENU_ITEM:
      return {
        ...menuLayoutState,
        selectedMenuItemId: payload.selectedMenuItemId as string,
        currentPageItemsSection: 1,
        viewingNestedPageIds: [],
      };

    case EditMenuAction.INSERT_MENU_ITEMS:
      const insertedMenuItems = [...menuLayoutState.menuItems];
      insertedMenuItems.splice(
        payload.position as number,
        1,
        ...(payload.data as TileItem[]),
      );

      return {
        ...menuLayoutState,
        menuItems: insertedMenuItems,
      };

    case EditMenuAction.CLEAR_MENU_ITEM_TILE:
      const clearedMenuItems = [...menuLayoutState.menuItems];
      const clearMenuItemIndex = payload.position as number;
      const clearMenuItem = clearedMenuItems[clearMenuItemIndex];
      clearedMenuItems[clearMenuItemIndex] = { key: clearMenuItem.key };
      const isClearSelectingItem =
        menuLayoutState.selectedMenuItemId === clearMenuItem.id;
      const {
        nextSelectedMenuId = menuLayoutState.selectedMenuItemId,
        pageNumber = menuLayoutState.currentMenuItemSection,
      } = isClearSelectingItem
        ? findNextSelectPageId(clearedMenuItems, clearMenuItemIndex)
        : {};
      return {
        ...menuLayoutState,
        menuItems: clearedMenuItems,
        selectedMenuItemId: nextSelectedMenuId,
        currentMenuItemSection: pageNumber,
      };

    //------------ PAGE ITEMS SECTION -----------
    case EditMenuAction.ON_CHANGE_PAGE_ITEM_PAGINATION:
      return {
        ...menuLayoutState,
        currentPageItemsSection: payload.page as number,
      };
    case EditMenuAction.INSERT_PAGE_ITEMS:
      const insertedPageItems = [...menuLayoutState.pageItems];

      // when add a category with no product belong
      if (payload.data?.length) {
        insertedPageItems.splice(
          payload.position as number,
          1,
          ...(payload.data as TileItem[]),
        );
      }

      return {
        ...menuLayoutState,
        pageItems: insertedPageItems,
      };

    case EditMenuAction.UPDATE_PAGE_ITEM_DATA:
      return {
        ...menuLayoutState,
        pageItems: payload.data as TileItem[],
        assigningCategoryIds:
          payload?.assigningCategoryIds || menuLayoutState.assigningCategoryIds,
      };

    case EditMenuAction.UPDATE_PAGE_ITEM_DATA_FROM_SERVER:
      return {
        ...menuLayoutState,
        pageItems: [...(payload.data as TileItem[])],
        originalPageItems: payload.originalData as TileItem[],
        originalAssignedCategoryIds: payload?.originalAssignedCategoryIds || [],
        assigningCategoryIds: payload?.originalAssignedCategoryIds || [],
      };
    case EditMenuAction.CLEAR_PAGE_ITEM_TILE:
      const clearedPageItems = [...menuLayoutState.pageItems];
      const clearPageItemIndex = payload.position as number;
      const clearPageItem = menuLayoutState.pageItems[clearPageItemIndex];
      clearedPageItems[clearPageItemIndex] = { key: clearPageItem.key };
      return {
        ...menuLayoutState,
        pageItems: clearedPageItems,
      };

    case EditMenuAction.CLEAR_SAME_PAGE_ITEM_TILE:
      const batchesRemoveIds = [] as string[];
      const updatedClearItems = [...menuLayoutState.pageItems].map(item => {
        if (item.entityId === payload.clearEntityId && !item.category) {
          batchesRemoveIds.push(item.id as string);
          return { key: item.key };
        }
        return item;
      });
      return {
        ...menuLayoutState,
        pageItems: updatedClearItems,
      };

    case EditMenuAction.RESET_SAVE_CHANGES:
      return {
        ...menuLayoutState,
        currentPageItemsSection: 1,
      };

    case EditMenuAction.VIEW_NESTED_PAGE:
      return {
        ...menuLayoutState,
        viewingNestedPageIds: menuLayoutState.viewingNestedPageIds.concat(
          payload.nestedPageId as string,
        ),
        currentPageItemsSection: 1,
      };

    case EditMenuAction.VIEW_PREVIOUS_PAGE:
      const updatedViewingNestedPageIds = [
        ...menuLayoutState.viewingNestedPageIds,
      ];
      updatedViewingNestedPageIds.pop();
      return {
        ...menuLayoutState,
        viewingNestedPageIds: updatedViewingNestedPageIds,
      };
    default:
      return menuLayoutState;
  }
}
const initialState: EditMenuState = {
  pageItems: [],
  menuItems: [],
  selectedMenuItemId: '',
  selectedProduct: '',
  currentMenuItemSection: 1,
  currentPageItemsSection: 1,
  viewingNestedPageIds: [],
  originalPageItems: [],
  originalMenuItems: [],
  originalAssignedCategoryIds: [],
  assigningCategoryIds: [],
};

interface MenuLayoutProp {
  menu?: Catalogue;
  loading: boolean;
  productItems: ProductItemType[];
  updateMenu: (updateMenuInput: UpdateCatalogueInput) => Promise<void>;
  createOrUpdatePage: (
    updateMenuInput: CreateOrUpdatePageInput,
  ) => Promise<Page | undefined>;
  pageMaps: Record<string, Page>;
  categoryMaps: Record<string, Category>;
}
const MenuLayout: React.FC<MenuLayoutProp> = ({
  menu,
  loading,
  productItems,
  updateMenu,
  pageMaps,
  categoryMaps,
  createOrUpdatePage,
}) => {
  const { translate } = useTranslation();
  const isFocused = useIsFocused();
  const { locale } = useLocalization();

  const [menuLayoutState, dispatch] = useReducer(
    editMenuLayoutReducer,
    initialState,
  );

  const {
    selectedMenuItemId,
    menuItems,
    originalPageItems,
    originalMenuItems,
    pageItems,
    originalAssignedCategoryIds,
    assigningCategoryIds,
    viewingNestedPageIds,
  } = menuLayoutState;

  const removeCategoryIds = useMemo(
    () => difference(originalAssignedCategoryIds, assigningCategoryIds),
    [assigningCategoryIds, originalAssignedCategoryIds],
  );

  const addCategoryIds = useMemo(
    () => difference(assigningCategoryIds, originalAssignedCategoryIds),
    [assigningCategoryIds, originalAssignedCategoryIds],
  );

  const selectedPageId = useMemo(
    () =>
      menuItems.find(menuItem => menuItem?.id === selectedMenuItemId)
        ?.entityId || '',

    [menuItems, selectedMenuItemId],
  );

  const removeMenuItemIds = useMemo(() => {
    return difference(getItemIds(originalMenuItems), getItemIds(menuItems));
  }, [menuItems, originalMenuItems]);

  const changedMenuItems = useMemo(
    () =>
      differenceWith(
        formatItemsToCompare(addNewPriorityToItems(menuItems)),
        formatItemsToCompare(originalMenuItems),
        isEqual,
      ).filter(notEmptyValue),
    [menuItems, originalMenuItems],
  );

  const changePageItems = useMemo(() => {
    const validPageItems = [...pageItems];
    if (viewingNestedPageIds.length) {
      // remove the back navigation before compare;
      validPageItems.shift();
    }
    return differenceWith(
      formatItemsToCompare(addNewPriorityToItems(validPageItems)),
      formatItemsToCompare(originalPageItems),
      isEqual,
    ).filter(notEmptyValue);
  }, [originalPageItems, pageItems, viewingNestedPageIds.length]);

  const removePageItemIds = useMemo(() => {
    return difference(getItemIds(originalPageItems), getItemIds(pageItems));
  }, [originalPageItems, pageItems]);

  const hasPageItemsChange = Boolean(
    changePageItems.length ||
      removePageItemIds.length ||
      addCategoryIds.length ||
      removeCategoryIds.length,
  );

  const hasMenuItemsChange = Boolean(
    changedMenuItems.length || removeMenuItemIds.length,
  );

  const hasDataChanged = hasPageItemsChange || hasMenuItemsChange;

  const handleSaveLayout = useCallback(async () => {
    const addOrUpdateItems = changePageItems.map(item =>
      omit(item, ['name', 'key', '__typename']),
    ) as CreateOrUpdatePageInput['addOrUpdateItems'];

    const lastNestedPageId = last(viewingNestedPageIds);
    const currentViewPageId = lastNestedPageId || selectedPageId;

    if (hasPageItemsChange) {
      createOrUpdatePage({
        id: currentViewPageId,
        addOrUpdateItems,
        removeItemIds: removePageItemIds,
        addCategoryIds,
        removeCategoryIds,
      });
    }
    if (hasMenuItemsChange) {
      const addOrUpdateCatalogueItems = changedMenuItems.map(item => ({
        id: item.id as string,
        page: item.entityId as string,
        priority: item.priority as number,
        color: item?.color || '',
      }));
      updateMenu({
        id: menu?.id as string,
        name: menu?.name,
        stores: menu?.stores?.map(store => store.id),
        venues: menu?.venues?.map(venue => venue.id),
        addOrUpdateCatalogueItems,
        removeItemIds: removeMenuItemIds,
      });
    }
  }, [
    changePageItems,
    viewingNestedPageIds,
    selectedPageId,
    hasPageItemsChange,
    hasMenuItemsChange,
    createOrUpdatePage,
    removePageItemIds,
    addCategoryIds,
    removeCategoryIds,
    changedMenuItems,
    updateMenu,
    menu?.id,
    menu?.name,
    menu?.stores,
    menu?.venues,
    removeMenuItemIds,
  ]);

  useEffect(() => {
    if (selectedMenuItemId || !menuItems.length || !isFocused) return;
    const firstMenuItemId = find(menuItems, item => Boolean(item.id))?.id;
    dispatch({
      type: EditMenuAction.UPDATE_SELECTED_MENU_ITEM,
      payload: {
        selectedMenuItemId: firstMenuItemId,
      },
    });
  }, [isFocused, menuItems, selectedMenuItemId, selectedPageId]);

  useEffect(() => {
    // Update MENU ITEM WIT SERVER DATA
    if (!isFocused) return;
    const formattedOriginalItems =
      menu?.items?.map(item => ({
        ...item,
        entityId: item?.page?.id,
        name: getLocaleEntity(item?.page, locale.languageTag)?.name,
      })) || [];
    const menuItems = checkDuplicatedPriority(formattedOriginalItems);
    const maxPriority = (maxBy(menuItems, d => d.priority)?.priority || 0) + 1;
    const numOfRequiredPage = Math.ceil(maxPriority / menuItemsPerSection);
    const totalItems = numOfRequiredPage * menuItemsPerSection;
    const itemPriorityMaps = keyBy(menuItems, 'priority');
    const updatedData = Array(totalItems)
      .fill(null)
      .map((_, index) => {
        return {
          ...itemPriorityMaps[index],
          key: `${index}`,
        };
      });
    dispatch({
      type: EditMenuAction.UPDATE_MENU_ITEM_DATA_FROM_SERVER,
      payload: {
        data: updatedData,
        originalData: formattedOriginalItems,
      },
    });
  }, [isFocused, locale.languageTag, menu?.items]);

  const lastNestedPageId = useMemo(
    () => last(viewingNestedPageIds),
    [viewingNestedPageIds],
  );

  const currentViewPage = useMemo(() => {
    const currentViewPageId = lastNestedPageId || selectedPageId;
    return pageMaps[currentViewPageId];
  }, [lastNestedPageId, pageMaps, selectedPageId]);

  const assignedStoreMenuIds = useMemo(
    () => menu?.stores?.map(store => store.id),
    [menu?.stores],
  );

  useEffect(() => {
    if (!isFocused) return;

    const {
      items: originalItems = [],
      products = [],
      variants = [],
      pages = [],
      categories,
    } = currentViewPage || {};

    const filteredProducts = filterEntityByStore(
      products,
      assignedStoreMenuIds,
    );

    const filteredVariants = filterEntityByStore(
      variants,
      assignedStoreMenuIds,
    );

    const allPageItemsMap = keyBy(
      [...filteredProducts, ...filteredVariants, ...pages],
      'id',
    );

    const arrangedItems = checkDuplicatedPriority(originalItems);
    const maxPriority = maxBy(arrangedItems, d => d.priority)?.priority || 0;
    const numOfRequiredPage = Math.ceil((maxPriority + 1) / itemsPerSection);
    const totalItems = numOfRequiredPage * itemsPerSection;
    const itemPriorityMaps = keyBy(arrangedItems, 'priority');

    const prioritizedData = Array(totalItems)
      .fill(null)
      .map((_, index) => {
        const item = itemPriorityMaps[index] as PageItem;
        return {
          ...item,
          ...(item?.entityId && {
            name: getLocaleEntity(
              allPageItemsMap[item.entityId],
              locale.languageTag,
            )?.name,
            available: (allPageItemsMap[item.entityId] as Product)?.isSellable,
          }),
          key: `${index}`,
        };
      }) as TileItem[];

    if (lastNestedPageId) {
      prioritizedData.unshift({
        entityType: 'BACK_NAVIGATION',
        key: 'BACK_NAVIGATION',
        disabledReSorted: true,
      } as TileItem);
    }

    dispatch({
      type: EditMenuAction.UPDATE_PAGE_ITEM_DATA_FROM_SERVER,
      payload: {
        data: prioritizedData,
        originalData: originalItems,
        originalAssignedCategoryIds: categories,
      },
    });
  }, [
    assignedStoreMenuIds,
    currentViewPage,
    isFocused,
    lastNestedPageId,
    locale.languageTag,
  ]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${menu?.name || 'Menu Layout'} | Oolio`}
      onSave={handleSaveLayout}
      hideFooter={!currentViewPage || !hasDataChanged}
    >
      <Section
        title={translate('menus.menuLayout')}
        subtitle={translate('menus.menuLayoutDescription')}
        layoutWidth="large"
      >
        <View style={styles.container}>
          <View style={styles.items}>
            <PageItemsLayout
              dispatch={dispatch}
              state={menuLayoutState}
              productItems={productItems}
              categoryMaps={categoryMaps}
              pageMaps={pageMaps}
              hasPageItemsChange={hasPageItemsChange}
              onSaveMenu={handleSaveLayout}
              createOrUpdatePage={createOrUpdatePage}
            />
          </View>
          <View style={styles.pages}>
            <MenuItemsLayout
              dispatch={dispatch}
              state={menuLayoutState}
              hasPageItemsChange={hasPageItemsChange}
              pageMaps={pageMaps}
              onSaveMenu={handleSaveLayout}
              createOrUpdatePage={createOrUpdatePage}
            />
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default MenuLayout;

const styles = StyleSheet.create({
  container: {
    width: 900,
    height: 800,
    alignSelf: 'center',
    flexDirection: 'row',
  },
  pages: {
    width: 140,
    marginLeft: 20,
  },
  items: {
    flex: 1,
  },
});
