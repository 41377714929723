import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 600,
    rowGap: 8,
    alignSelf: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.s,
    padding: 30,
  },
  comboRow: {
    minHeight: 96,
    backgroundColor: theme.colors.grey1,
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  description: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  iconContainer: {
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.states.positive,
    marginBottom: 20,
    marginLeft: 12,
    fontFamily: theme.fonts.semibold,
  },
  buttonAction: {
    alignSelf: 'flex-end',
    marginTop: 40,
  },
  comboTypeSelectionDisabled: {
    opacity: 0.4,
  },
});

export default styles;
