/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import styles from './TransferPreview.styles';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import CartProduct from '../../Cart/CartProduct/CartProduct';
import { computeLineTotal } from '@oolio-group/order-helper';
import {
  OrderItem,
  OrderAction,
  SplitQuantityOrderItemEvent,
  OrderItemStatus,
  OrderPaymentStatus,
  Order,
  OrderEvent,
  App,
  Table,
  OrderTransferType,
} from '@oolio-group/domain';
import { CartSelectionState } from '../../Cart/Cart';
import KeypadModal from '../Keypad/KeypadModal';
import { useModal } from '@oolio-group/rn-use-modal';
import { v4 as uuidv4 } from 'uuid';
import { groupBy } from 'lodash';
import { generateOrderEvent } from '../../../../utils/orderEventHelper';
import { useSession } from '../../../../hooks/app/useSession';
import { userUtility } from '../../../../state/userUtility';
import { computeOrderState } from '@oolio-group/order-helper';
import ModalTitle from '../ModalTitle/ModalTitle';
import { transferItemObservable } from '../../../../hooks/app/orders/ordersObservableUtils';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { useCatalogue } from '../../../../hooks/app/catalogue/useCatalogue';

export interface TransferItem {
  orderId: string;
  table: Partial<Table>;
  orderItems: OrderItem[];
  pendingEvents: OrderEvent[];
}
export interface TransferPreviewProps {
  orders?: Order[];
  position?: 'left' | 'right';
  onSubmit: (
    itemsToTransfer: TransferItem[],
    transferType?: OrderTransferType,
  ) => void;
  onClose?: () => void | Promise<void>;
}
export interface OrderItemExtended extends OrderItem {
  orderId: string;
}

const getUpdatedOrders = (orders: Order[], updatedOrder: Order): Order[] => {
  return orders.map(order =>
    order.id === updatedOrder.id ? updatedOrder : order,
  );
};

const sanitizeOrderMap = (
  ordersMap: Record<string, OrderItemExtended[]>,
): Record<string, OrderItem[]> => {
  const updatedOrdersMap: Record<string, OrderItem[]> = {};

  for (const [orderId, orderItems] of Object.entries(ordersMap)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updatedOrdersMap[orderId] = orderItems.map(({ orderId, ...rest }) => rest);
  }

  return updatedOrdersMap;
};

const TransferPreview: React.FC<TransferPreviewProps> = ({
  orders: initialOrders = [],
  onSubmit,
  onClose,
  position = 'left',
}: TransferPreviewProps) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { formatCurrency } = useCurrency();
  const [session] = useSession();
  const { variantMaps } = useCatalogue({
    store: session.currentStore?.id,
    menuId: session.deviceProfile?.menu?.id,
    subscribeToData: true,
  });
  const [orders, setOrders] = useState<Array<Order>>(initialOrders);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

  const [pendingEvents, setPendingEvents] = useState<Array<OrderEvent>>([]);

  const { value: transferItem, setValue: setTransferItem } =
    useBehaviorSubjectState<string | null>(transferItemObservable);

  const clearTransferItem = useCallback(() => {
    setTransferItem(null);
  }, [setTransferItem]);

  const orderItems: OrderItemExtended[] = orders
    .map(order =>
      order.orderItems
        .filter(
          item =>
            ![OrderItemStatus.CANCELLED, OrderItemStatus.VOID].includes(
              item.status,
            ),
        )
        .map(orderItem => ({
          ...orderItem,
          orderId: order.id,
          ...(orderItem.variant && {
            variant: {
              ...orderItem.variant,
              name:
                variantMaps[orderItem?.variant?.id]?.name ||
                orderItem.variant.name,
            },
          }),
        })),
    )
    .flat()
    .sort((a, b) => (a?.createdAt ?? 0) - (b?.createdAt ?? 0));

  const eventSourceInfo = useMemo(
    () => ({
      organizationId: session.currentOrganization?.id,
      venueId: session.currentVenue?.id,
      deviceId: session.device?.id,
      storeId: session.currentStore?.id,
      triggeredBy: userUtility?.posUser?.id || session.user?.id,
    }),
    [session],
  );

  const isItemSelected = useCallback(
    (itemId: string) => {
      return selectedItems.includes(itemId);
    },
    [selectedItems],
  );

  const onSubmitTransfer = useCallback(() => {
    const selection = sanitizeOrderMap(
      !!selectedItems.length
        ? groupBy(
            orderItems.filter(x => selectedItems.includes(x.id)),
            item => item.orderId,
          )
        : groupBy(
            orderItems.filter(x => x.status === OrderItemStatus.IN_PROGRESS),
            item => item.orderId,
          ),
    );

    const itemsToTransfer: TransferItem[] = [];

    Object.entries(selection).forEach(([orderId, orderItems]) => {
      itemsToTransfer.push({
        orderId,
        table: orders.find(x => x.id === orderId)?.table ?? {
          id: '',
          name: '',
        },
        orderItems,
        pendingEvents: pendingEvents.filter(x => x.orderId === orderId),
      });
    });

    const transferType = selectedItems.length
      ? OrderTransferType.ITEMS
      : OrderTransferType.TABLES;

    onSubmit(itemsToTransfer, transferType);
  }, [onSubmit, orderItems, orders, pendingEvents, selectedItems]);

  const onPressClose = useCallback(() => {
    clearTransferItem();
    onClose && onClose();
  }, [onClose, clearTransferItem]);

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onSelectItem = useCallback(
    (state: CartSelectionState) => {
      const { item } = state;
      const orderItem = orderItems.find(x => x.id === item);

      if (!orderItem || orderItem.status !== OrderItemStatus.IN_PROGRESS)
        return;

      const onConfirmSetQuantity = (qty: number) => {
        if (qty >= orderItem.quantity) {
          setSelectedItems(prev => [...prev, orderItem.id]);
        } else {
          const order = orders.find(x => x.id === orderItem.orderId);
          const splitOrderItemId = uuidv4();

          const event = generateOrderEvent<SplitQuantityOrderItemEvent>(
            OrderAction.ORDER_ITEM_SPLIT_QUANTITY,
            eventSourceInfo,
            {
              orderId: order?.id,
              previous: order?.prevEventId,
              source: App.POS_APP,
              quantity: qty,
              orderItemId: orderItem.id,
              splitOrderItemId,
            },
          );

          const updatedOrder = computeOrderState([event], order);

          setPendingEvents(prev => [...prev, event]);
          setOrders(prev => getUpdatedOrders(prev, updatedOrder));
          setSelectedItems(prev => [...prev, splitOrderItemId]);
        }
      };

      const isSelected = isItemSelected(item);

      if (isSelected) {
        setSelectedItems(prev => prev.filter(x => x !== item));
      } else {
        const { variableQuantity } = orderItem.product;
        if (!variableQuantity && orderItem.quantity > 1) {
          showModal(
            <KeypadModal
              title={translate('order.selectTransferQty')}
              initialValue={(orderItem?.quantity ?? 1).toString()}
              mode={'integer'}
              product={orderItem}
              onConfirm={onConfirmSetQuantity}
              onDismiss={onCloseModal}
              maxValue={orderItem.quantity}
              minValue={1}
            />,
          );
        } else {
          setSelectedItems(prev => [...prev, item]);
        }
      }
    },
    [
      eventSourceInfo,
      isItemSelected,
      onCloseModal,
      orderItems,
      orders,
      showModal,
      translate,
    ],
  );

  useEffect(() => {
    if (!!transferItem) {
      onSelectItem({ item: transferItem });
      clearTransferItem();
    }
  }, [clearTransferItem, onSelectItem, transferItem]);

  const renderItem = useCallback(
    (item: OrderItem) => {
      const isSelected = isItemSelected(item.id);

      return (
        <CartProduct
          testID={`cart-item-${item.id}`}
          orderItem={item}
          isSelected={isSelected}
          onSelect={onSelectItem}
          isPaid={item.paymentStatus === OrderPaymentStatus.COMPLETE}
        />
      );
    },
    [isItemSelected, onSelectItem],
  );

  const itemTotal = useMemo(() => {
    return orderItems.reduce((sum, item) => {
      if (isItemSelected(item.id)) {
        const lineTotal = computeLineTotal(item);
        return sum + (lineTotal ?? 0);
      }
      return sum;
    }, 0);
  }, [isItemSelected, orderItems]);

  return (
    <View style={styles.backdrop}>
      <View style={[styles.container, { [position]: 0 }]}>
        <ModalTitle
          title={translate('transfer.selectProduct')}
          onDismiss={onPressClose}
        />
        <View style={styles.content}>
          <View style={styles.itemsContainer}>
            <ScrollView
              style={styles.scrollContainer}
              showsVerticalScrollIndicator={false}
            >
              {orderItems.map(item => renderItem(item))}
            </ScrollView>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>{translate('transfer.total')}</Text>
          <Text style={styles.footerText}>{formatCurrency(itemTotal)}</Text>
        </View>
        <TreatButton
          testID="btn-confirm"
          type={'positive'}
          label={
            !!selectedItems.length
              ? translate('transfer.transferSelected')
              : translate('transfer.transferOrder')
          }
          onPress={onSubmitTransfer}
        />
      </View>
    </View>
  );
};

export default TransferPreview;
