import {
  DefaultPaymentTypes,
  Order,
  OrderAction,
  OrderPayment,
  OrderPaymentStatus,
} from '@oolio-group/domain';
import { formatMoneyValue } from '@oolio-group/localization';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { simplifiedPaymentResponse } from './adyenPayload';
import { isCompletedOrRefundedPayment } from '@oolio-group/order-helper';

export type FixedTuple = [string, string, string];

const getOrderPaymentAmount = (payment: OrderPayment) => {
  return payment.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT
    ? payment.amount - payment.tendered
    : payment.amount;
};

const getPaidAmount = (payment: OrderPayment) => {
  return payment.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT
    ? payment.amount - payment.tendered
    : payment.tendered;
};

const getAuthCode = (payment: OrderPayment): [string, string, string][] =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payment.paymentReceipt as any)?.authCode
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [['Auth Code', '', (payment?.paymentReceipt as any)?.authCode]]
    : [];

/**
 * Payment details section has two columns and `n` row(s)
 */
const config: TableUserConfig = {
  columns: {
    0: {
      width: 17,
    },
    1: {
      width: 1,
    },
    2: {
      width: 18,
      paddingLeft: 2,
      alignment: 'right',
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    // TODO: get these from api / our custom hook
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateSplitPaymentDetails = (
  paymentsToPrint: OrderPayment[],
  currency: string,
): string => {
  const paymentsTable: [string, string, string][] = [];
  if (paymentsToPrint.length <= 0) return '';

  paymentsToPrint.forEach(payment => {
    if (payment.paymentType?.name?.toUpperCase() === 'CASH') {
      // should create constant
      paymentsTable.push([
        payment.paymentType.name || '',
        '',
        formatMoneyValue(getOrderPaymentAmount(payment), currency),
      ]);
    } else {
      paymentsTable.push(
        simplifiedPaymentResponse(
          payment.paymentReceipt,
          formatMoneyValue(getOrderPaymentAmount(payment), currency),
          payment.paymentType?.name,
        ),
        ...getAuthCode(payment),
      );
    }
  });

  return table(paymentsTable, config);
};

export const generateCurrentNthPayment = (
  order: Order,
  currency: string,
  nthPaymentToPrint?: number,
): string => {
  if (typeof nthPaymentToPrint !== 'number') return '';

  const paymentsTable: [string, string, string][] = [];
  const completedPayments = (order?.payments || []).filter(
    x =>
      isCompletedOrRefundedPayment(x.status as OrderPaymentStatus) ||
      x.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT,
  );

  const nthPayment = completedPayments[nthPaymentToPrint] || order.payments[-1];
  // need to use payment id, doing this only because of bug where latest payment is not yet marked as complete

  if (!nthPayment) return '';

  const paymentTypeName = nthPayment.paymentType?.name || '';

  if (paymentTypeName.toUpperCase() === 'CASH') {
    paymentsTable.push([
      paymentTypeName,
      '',
      formatMoneyValue(
        paymentTypeName === OrderAction.ON_ACCOUNT_PAYMENT_TYPE
          ? nthPayment.amount - nthPayment.tendered
          : nthPayment.tendered,
        currency,
      ),
    ]);
  } else {
    paymentsTable.push(
      simplifiedPaymentResponse(
        nthPayment.paymentReceipt,
        formatMoneyValue(
          paymentTypeName === OrderAction.ON_ACCOUNT_PAYMENT_TYPE
            ? nthPayment.amount - nthPayment.tendered
            : nthPayment.tendered,
          currency,
        ),
        paymentTypeName,
      ),
      ...getAuthCode(nthPayment),
    );
  }

  return table(paymentsTable, config);
};

/**
 * Generate the payment details rows for billing receipt
 * @param order
 *
 * Example output:
 * ```bash
 * Total Paid                            $34.34
 * Total Price                          $202.10
 * ```
 */
export const generatePaymentDetails = (
  order: Order,
  currency: string,
  nthPaymentToPrint?: number,
): string => {
  // Print Split Payment
  const completedPayments = (order?.payments || []).filter(
    x =>
      isCompletedOrRefundedPayment(x.status as OrderPaymentStatus) ||
      x.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT,
  );
  if (typeof nthPaymentToPrint == 'number')
    return generateSplitPaymentDetails(
      completedPayments
        .slice(0, nthPaymentToPrint)
        .concat(completedPayments.slice(nthPaymentToPrint + 1)),
      currency,
    );

  // Print Payment In Full
  const paymentsTable: [string, string, string][] = [];
  if (completedPayments.length > 1) {
    completedPayments.forEach(payment => {
      if (payment.paymentType?.name?.toUpperCase() === 'CASH') {
        paymentsTable.push([
          payment.paymentType.name || '',
          '',
          formatMoneyValue(getOrderPaymentAmount(payment), currency),
        ]);
      } else {
        paymentsTable.push(
          simplifiedPaymentResponse(
            payment.paymentReceipt,
            formatMoneyValue(getOrderPaymentAmount(payment), currency),
            payment.paymentType?.name,
          ),
          ...getAuthCode(payment),
        );
      }
    });
    return table(paymentsTable, config);
  } else if (completedPayments.length === 1) {
    const amountPaid = completedPayments.reduce(
      (t, payment) => t + getPaidAmount(payment),
      0,
    );
    if (completedPayments[0].paymentType?.name?.toUpperCase() === 'CASH') {
      paymentsTable.push([
        completedPayments[0].paymentType.name,
        '',
        formatMoneyValue(amountPaid, currency),
      ]);
      const changeDue =
        amountPaid - (order.roundingAmount || 0) - order.totalPaymentAmount;

      if (changeDue > 0)
        paymentsTable.push([
          'Change due',
          '',
          formatMoneyValue(changeDue, currency),
        ]);
    } else {
      paymentsTable.push(
        simplifiedPaymentResponse(
          completedPayments[0].paymentReceipt,
          formatMoneyValue(amountPaid, currency),
          completedPayments[0].paymentType?.name,
        ),
        ...getAuthCode(completedPayments[0]),
      );
    }
    return table(paymentsTable, config);
  } else {
    return '';
  }
};

export const generateRefundPaymentDetails = (
  order: Order,
  currency: string,
): string => {
  const paymentsTable = [
    [
      'Refund Method',
      '',
      (order.payments[0].paymentType as unknown as { name: string })?.name ||
        order.payments[0].paymentType ||
        '',
    ],
    ['Total Refund', '', formatMoneyValue(order.payments[0].amount, currency)],
  ];
  return table(paymentsTable, config);
};

export const generateTotalOutStanding = (
  order: Order,
  currency: string,
  nthPaymentToPrint?: number,
): string => {
  if (typeof nthPaymentToPrint !== 'number') return '';

  const totalAmountDue = order.totalPaymentAmount;
  const completedPayments = (order?.payments || []).filter(
    x =>
      isCompletedOrRefundedPayment(x.status as OrderPaymentStatus) ||
      x.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT,
  );
  const totalAmountPaid = completedPayments.reduce((sum, payment) => {
    sum = sum + payment.amount - (payment.roundOffDifference || 0);
    return sum;
  }, 0);
  const totalAmountOutstanding = (totalAmountDue - totalAmountPaid).toFixed(2);

  if (Number(totalAmountOutstanding) <= 0) return '';

  const totalOutstandingTable = [
    [
      'Total Outstanding',
      '',
      formatMoneyValue(Number(totalAmountOutstanding), currency),
    ],
  ];
  return table(totalOutstandingTable, config);
};
