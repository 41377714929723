import { useIntercom as useIntercomWeb } from 'react-use-intercom';
import { useEffect, useMemo } from 'react';
import useBehaviorSubjectState from '../app/useSubjectState';
import { closeButtonVisibilityController } from './intercomObservables';
import { useSession } from '../app/useSession';

type CallbackFn = () => void;

export interface User {
  email: string;
  userId: string;
  name: string;
  customAttributes?: {
    app: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  companies?: Array<any>;
}

export function useIntercom() {
  const { boot, shutdown, hide, show, update, trackEvent } = useIntercomWeb();
  const [session] = useSession();

  const { setValue: setVisible } = useBehaviorSubjectState<boolean>(
    closeButtonVisibilityController,
  );

  const handler = useMemo(() => {
    return {
      start(user: User, callback?: CallbackFn) {
        boot(user);
        callback && callback();
        setVisible(true);
      },
      stop() {
        shutdown();
        setVisible(false);
      },

      show() {
        show();
      },

      hide() {
        hide();
      },
      update() {
        update();
      },
      register(user: User) {
        boot(user);
      },
      logEvent(event: string, metadata?: object) {
        trackEvent(event, metadata);
      },
    };
  }, [boot, setVisible, shutdown, show, hide, update, trackEvent]);

  useEffect(() => {
    const IntercomUser: User = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          companyId: session?.currentOrganization?.id || '',
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };
    update(IntercomUser);
  }, [handler, session, update]);

  return handler;
}
