import { StyleSheet } from 'react-native';
import theme from '../../../../../../../common/default-theme';

const styles = StyleSheet.create({
  imageContainer: {
    width: theme.layoutWidth.s,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    width: theme.layoutWidth.s,
    marginBottom: 40,
  },
  btnCopy: {
    marginLeft: 10,
    marginTop: 'auto',
  },
  headerDay: {
    flex: 1,
  },
  headerStart: {
    width: 100,
    marginRight: 10,
    textAlign: 'center',
  },
  headerClose: {
    width: 88,
    textAlign: 'center',
  },
  cellDay: {
    marginLeft: 10,
    marginRight: 'auto',
  },
  cellTime: {
    width: 100,
    marginLeft: 10,
  },
});

export default styles;
