import React from 'react';
import {
  DateRangeFilter,
  FilterObject,
  Filters,
  FilterValue,
  ReportFilterKey,
} from '@oolio-group/domain';
import { HeaderStyles } from '../../styles/SalesDashboard.styles';
import { View } from 'react-native';
import { FilterInput } from '../../UIComponents/FilterInput';
import { DateRangeFilters } from '../../UIComponents/DateRangeFilters';
import IconButton from '../../../../../components/Button/IconButton';
import Button from '../../../../../components/Button/Button';
import { useTranslation } from '@oolio-group/localization';
import { TRADING_PERIOD_KEY } from '../../reportsHelper';

interface HeaderProps {
  allFilters: Filters;
  filters: FilterValue;
  dateRangeFilter: DateRangeFilter;
  updateReport: () => void;
  toggleFilters: () => void;
  updateFilters: (filter: string, value: string[]) => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
}

export const SalesDashboardHeader: React.FC<HeaderProps> = ({
  allFilters,
  filters,
  dateRangeFilter,
  updateReport,
  toggleFilters,
  updateFilters,
  updateDateRangeFilters,
}) => {
  const { translate } = useTranslation();
  const styles = HeaderStyles();

  const headerFilters = [
    {
      key: ReportFilterKey.VENUES,
      title: `backOfficeReports.filters.${ReportFilterKey.VENUES}` as string,
      values: allFilters[ReportFilterKey.VENUES as keyof Filters],
    },
    {
      key: ReportFilterKey.STORES,
      title: `backOfficeReports.filters.${ReportFilterKey.STORES}` as string,
      values: allFilters[ReportFilterKey.STORES as keyof Filters],
    },
  ];

  const tradingPeriods: FilterObject[] =
    allFilters[TRADING_PERIOD_KEY as keyof Filters];

  const dateRangeStyleOverride = { ...styles.dateRangeFilters, marginTop: 20 };

  return (
    <View style={styles.pageStyle}>
      <View style={styles.row1}>
        {headerFilters.map((headerFilter, headerFilterIndex) => (
          <View
            key={`sales-dashboard-header-filter-${headerFilterIndex}`}
            style={styles.venueFilterView}
          >
            <FilterInput
              key={0}
              index={0}
              options={headerFilter.values as FilterObject[]}
              name={headerFilter.key as string}
              title={headerFilter.title}
              showLabel={false}
              selectedValues={
                filters[headerFilter?.key as keyof FilterValue] || []
              }
              updateFilters={updateFilters}
            />
          </View>
        ))}
        <View style={dateRangeStyleOverride}>
          <DateRangeFilters
            tradingPeriods={tradingPeriods}
            dateRangeFilter={dateRangeFilter}
            updateDateRangeFilters={updateDateRangeFilters}
          ></DateRangeFilters>
        </View>
        <View style={styles.iconStyle}>
          <IconButton
            icon="sliders-v"
            onPress={() => toggleFilters()}
            primary
            containerStyle={styles.filtersButtonStyle}
            iconStyle={styles.filtersIconStyle}
            containerSize={38}
            iconSize={25}
          ></IconButton>
        </View>
        <View style={styles.updateContainerStyle}>
          <Button
            testID="update-report"
            fluid
            title={translate('backOfficeReports.header.updatedBtn')}
            containerStyle={styles.updateButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={updateReport}
          />
        </View>
      </View>
    </View>
  );
};
