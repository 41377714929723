import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  STANDARD_MENU,
  Catalogue,
  AppScreen,
  CreateDeviceProfileInput,
  FunctionMap,
  DeviceProfile,
  Store,
  UpdateDeviceProfileDetailsInput,
  OrderTypeCode,
  FeatureIDs,
  DeviceMode,
  DeviceProfileStatus,
  OrderIdentifier,
} from '@oolio-group/domain';
import { useNotification } from '../../../../../../hooks/Notification';
import { pick } from 'lodash';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';
import { useStores } from '../../../../../../hooks/app/useStores';
import { useMenus } from '../../../../../../hooks/app/menus/useMenus';
import { Operation } from '../../../../../../types/Operation';
import { OrderType } from '@oolio-group/domain';
import { useSections } from '../../../../../../hooks/app/sections/useSections';
import styles from '../DeviceProfiles.styles';
import { WithFeature } from '../../../../../../components/features/WithFeature';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import theme from '../../../../../../common/default-theme';

interface OrderTypesRowProps {
  orderType: OrderType;
  enabled: boolean;
  isDefault: boolean;
  onToggle: (id: string, value: boolean) => void;
  onSelectDefault: (id: string) => void;
}

export const OrderTypesRow: React.FC<OrderTypesRowProps> = ({
  onToggle,
  orderType,
  enabled,
  isDefault,
  onSelectDefault,
}: OrderTypesRowProps) => {
  const onPressHandler = useCallback(() => {
    onToggle(orderType.id, !enabled);
  }, [onToggle, orderType.id, enabled]);

  const onPressDefault = useCallback(() => {
    onSelectDefault(orderType.id);
  }, [onSelectDefault, orderType.id]);

  return (
    <View style={theme.tables.row}>
      <InputToggle
        type="switch"
        testID="toggle-orderType"
        isToggled={enabled}
        onToggle={onPressHandler}
        title={orderType.name}
        containerStyle={styles.cellOrderType}
      />
      {enabled && (
        <InputToggle
          type="radio"
          testID="toggle-default"
          isToggled={isDefault}
          onToggle={onPressDefault}
          containerStyle={styles.cellDefault}
        />
      )}
    </View>
  );
};

export const Details: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({
    printerOptions: {
      emailReceipt: false,
      printReceipt: false,
    },
  } as DeviceProfile);

  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
    venueId: string;
    operation: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';
  const venueId = params.venueId || '';
  const operationRef = useRef(params.operation || 'edit');

  const {
    deviceProfiles: deviceProfiles,
    orderTypes,
    functionMaps,
    loading: useDeviceProfilesLoading,
    error: useDeviceProfilesError,
    updatedDeviceProfileId,
    unAssignedDeviceProfileId,
    deletedDeviceProfile,
    getOrderTypes,
    getFunctionMaps,
    createDeviceProfile,
    updateDeviceProfileDetails,
    deleteDeviceProfile,
  } = useDeviceProfiles({ deviceProfileId, storeId, venueId });
  const {
    stores,
    getStores,
    loading: useStoresLoading,
    error: useStoresError,
  } = useStores();
  const {
    sections,
    getSections,
    loading: useSectionsLoading,
    error: useSectionsError,
  } = useSections({ venueId });

  const {
    getMenusOptions,
    loading: menuLoading,
    error: menuErr,
    menus,
  } = useMenus();

  const error =
    useDeviceProfilesError || useStoresError || menuErr || useSectionsError;
  const loading =
    useDeviceProfilesLoading ||
    useStoresLoading ||
    menuLoading ||
    useSectionsLoading;

  const returnDefaultOrderType = (form: DeviceProfile) => {
    if (form.defaultOrderType) {
      return form.defaultOrderType?.id;
    } else if (form.orderTypes && form.orderTypes.length > 0) {
      return form.orderTypes[0].id;
    }
    return '';
  };

  const [defaultOrderType, setDefaultOrderType] = useState<string>(
    returnDefaultOrderType(form),
  );

  useEffect(() => {
    if (!loading && !error && operationRef.current === '') {
      showNotification({
        success: true,
        message: translate('backOfficeDeviceProfiles.deviceCreated'),
      });

      navigation.navigate('StoreSettings', {
        storeId: storeId,
        venueId,
        screen: 'DeviceProfiles',
      });
    }
  }, [
    error,
    loading,
    navigation,
    showNotification,
    storeId,
    translate,
    venueId,
  ]);

  useEffect(() => {
    getOrderTypes();
    getMenusOptions();
  }, [getOrderTypes, getMenusOptions]);

  useEffect(() => {
    getFunctionMaps();
  }, [getFunctionMaps]);

  useEffect(() => {
    getStores();
  }, [getStores]);

  useEffect(() => {
    getSections();
  }, [getSections]);

  const menuOptions = useMemo(() => {
    const menusData = Object.values(menus);
    return menusData.map(x => ({ value: x.id, label: x.name }));
  }, [menus]);

  const standardMenu = useMemo(() => {
    const standardMenuDetails = menuOptions.filter(
      x => x && x.label === STANDARD_MENU,
    );
    return standardMenuDetails?.[0];
  }, [menuOptions]);

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm(form => {
        return {
          ...form,
          ...deviceProfiles[deviceProfileId],
        };
      });
      setDefaultOrderType(deviceProfiles[deviceProfileId].defaultOrderType?.id);
    } else if (operationRef.current === Operation.CREATE) {
      setForm(form => {
        return {
          ...form,
          store: { id: storeId },
          orderTypes: [],
          sections: [],
        } as unknown as DeviceProfile;
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId, storeId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  const pricingGroupOptions = useMemo(() => {
    if (stores[storeId]?.pricingGroups?.length) {
      return stores[storeId]?.pricingGroups?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }
    return [];
  }, [stores, storeId]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onChangeStore = useCallback((value): void => {
    setForm(form => {
      return {
        ...form,
        store: { id: value } as Store,
      };
    });
  }, []);

  const onChangeSection = useCallback((values: string[]): void => {
    setForm(form => {
      return {
        ...form,
        sections: values.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (value: string, index: any) => ({ id: value, order: index } as any),
        ),
      };
    });
  }, []);

  const onChangeFunctionMap = useCallback((value): void => {
    setForm(form => {
      return {
        ...form,
        functionMap: { id: value } as FunctionMap,
      };
    });
  }, []);

  const onChangeMenu = useCallback((value): void => {
    setForm(form => {
      return {
        ...form,
        menu: { id: value } as Catalogue,
      };
    });
  }, []);

  const validateDineInOrderTypeForFloorViewEnabled = useCallback(
    (orderTypeId: string) => {
      const orderType = form.orderTypes.find(
        orderType => orderType.id === orderTypeId,
      );
      return form.enableFloorView && orderType?.code === OrderTypeCode.DINE_IN;
    },
    [form.enableFloorView, form.orderTypes],
  );

  const onChangeOrderTypes = useCallback(
    (id: string, value: boolean): void => {
      if (id === defaultOrderType) {
        showNotification({
          error: true,
          message: translate('backOfficeDeviceProfiles.disableDefaultWarning'),
        });
        return;
      }
      if (value) {
        const orderTypeData = orderTypes.find(orderType => orderType.id === id);
        orderTypeData &&
          setForm({ ...form, orderTypes: [...form.orderTypes, orderTypeData] });
      } else {
        if (validateDineInOrderTypeForFloorViewEnabled(id)) {
          showNotification({
            error: true,
            message: translate('backOfficeDeviceProfiles.disableDineInWarning'),
          });
          return;
        }
        const index = form.orderTypes.findIndex(
          orderType => orderType.id === id,
        );
        if (index !== -1) {
          const orderTypes = [...form.orderTypes];
          orderTypes.splice(index, 1);
          setForm({
            ...form,
            orderTypes,
          });
        }
      }
    },
    [
      defaultOrderType,
      form,
      orderTypes,
      showNotification,
      translate,
      validateDineInOrderTypeForFloorViewEnabled,
    ],
  );

  const updateDefaultOrderType = useCallback(
    (orderTypeId: string) => {
      if (defaultOrderType !== orderTypeId) {
        setDefaultOrderType(orderTypeId);
      }
    },
    [defaultOrderType],
  );

  const onPressSave = useCallback((): void => {
    if (!form.name) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.nameRequired'),
      });
      return;
    } else if (form.orderTypes.length === 0) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.orderTypeRequired'),
      });
      return;
    }
    const params = pick(form, [
      'name',
    ]) as unknown as UpdateDeviceProfileDetailsInput;

    if (Object.values(params).some(value => !value)) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.fieldsMissing'),
      });

      return;
    }
    params.isDefault = form.isDefault;
    params.printerOptions = pick(form.printerOptions, [
      'emailReceipt',
      'printReceipt',
    ]);
    params.orderTypes = form.orderTypes.map(orderType => orderType.id);
    params.menu = form?.menu?.id || standardMenu?.value;
    params.pricingGroupId =
      form?.pricingGroupId || stores[storeId]?.pricingGroups?.[0]?.id;
    params.sections = form.sections.map(({ id }) => id);
    params.orderIdentifier = form.orderIdentifier || OrderIdentifier.TOKEN_ID;
    params.promptGuestCount = form.promptGuestCount || false;
    params.takingOrders = form.takingOrders || false;
    params.payAtTable = form.payAtTable || !form.takingOrders;

    params.defaultOrderType = defaultOrderType || params.orderTypes[0];

    if (operationRef.current === Operation.CREATE) {
      operationRef.current = '';
      createDeviceProfile({
        ...params,
        defaultSaleScreen: AppScreen.NEW_ORDER,
        postSaleScreen: AppScreen.NEW_ORDER,
      } as CreateDeviceProfileInput);
    } else {
      const deviceProfileId = pick(form, ['id']) as { id: string };

      params.isDefault = form.isDefault;
      if (form.store?.id) params.store = form.store.id;
      params.printerOptions = pick(form.printerOptions, [
        'emailReceipt',
        'printReceipt',
      ]);
      if (form.functionMap) params.functionMap = form.functionMap.id;

      updateDeviceProfileDetails({
        ...params,
        ...deviceProfileId,
      } as UpdateDeviceProfileDetailsInput);
    }
  }, [
    form,
    standardMenu?.value,
    defaultOrderType,
    showNotification,
    translate,
    stores,
    storeId,
    createDeviceProfile,
    updateDeviceProfileDetails,
  ]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
        venueId,
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
    venueId,
  ]);

  useEffect(() => {
    if (unAssignedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.registerUnassignedSuccessfully'),
      });
    }
  }, [showNotification, translate, unAssignedDeviceProfileId]);

  const selectedOrderTypes =
    (form.orderTypes &&
      form.orderTypes.reduce<Record<string, OrderType>>(
        (acc, x) => ({ ...acc, [x.id]: x }),
        {},
      )) ||
    {};

  const promptOrderName =
    form.orderIdentifier === OrderIdentifier.CUSTOMER_NAME;

  const onPressUnassignOrDelete = useCallback(() => {
    const name = deviceProfiles[deviceProfileId]?.name;

    form.status === DeviceProfileStatus.ASSIGNED
      ? showModal(
          <ConfirmationDialog
            title={translate('dialog.unassignTitle')}
            message={translate('dialog.unassignConfirmation')}
            onConfirm={() => {
              closeModal();
            }}
          />,
        )
      : showModal(
          <ConfirmationDialog
            title={translate('dialog.deleteTitle')}
            message={translate('dialog.deleteConfirmation', { label: name })}
            onConfirm={() => {
              closeModal();
              deleteDeviceProfile(deviceProfileId);
            }}
          />,
        );
  }, [
    deviceProfiles,
    deviceProfileId,
    form.status,
    showModal,
    translate,
    closeModal,
    deleteDeviceProfile,
  ]);

  return (
    <ScreenLayout
      loading={loading}
      title="Device Profile | Oolio"
      onSave={onPressSave}
      onDelete={onPressUnassignOrDelete}
      onDeleteLabel={
        form.status === DeviceProfileStatus.ASSIGNED
          ? translate('button.unassign')
          : translate('button.delete')
      }
      onDeleteDisabled={
        deviceProfiles[deviceProfileId]?.isDefault || !deviceProfileId
      }
    >
      <Section title={translate('backOfficeSettings.registerDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            placeholder={translate('form.registerName')}
            title={translate('form.registerName')}
            value={form.name || ''}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-functions"
            title={translate('form.functionMap')}
            options={[
              {
                value: '',
                label: 'None',
              },
              ...(functionMaps
                ? functionMaps?.map(fnMap => {
                    return { value: fnMap.id, label: fnMap.name };
                  })
                : []),
            ]}
            selectedValue={form.functionMap?.id || ''}
            onValueChange={onChangeFunctionMap}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-menu"
            title={translate('form.selectMenu')}
            options={menuOptions}
            selectedValue={form?.menu?.id || standardMenu?.value}
            onValueChange={value => onChangeMenu(value)}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-pricelist"
            title={translate('backOfficeFeatures.priceList')}
            options={pricingGroupOptions}
            selectedValue={form.pricingGroupId}
            onValueChange={onChange.bind(null, 'pricingGroupId')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-stores"
            title={translate('form.assignedStore')}
            options={Object.values(stores).map(store => ({
              label: store.name,
              value: store.id,
            }))}
            selectedValues={[form.store?.id]}
            onValueChange={values => onChangeStore(values[1])}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
        <WithFeature
          feature={{
            featureId: FeatureIDs.TABLE_MANAGEMENT,
          }}
          venue={params.venueId}
        >
          <View style={theme.forms.row}>
            <SelectMultiple
              testID="select-sections"
              title={translate('form.assignedSection')}
              options={Object.values(sections).map(section => ({
                label: section.name,
                value: section.id,
              }))}
              selectedValues={(form.sections || []).map(({ id }) => id)}
              onValueChange={onChangeSection}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        </WithFeature>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-defaultProfile"
            title={translate('deviceProfileSettings.setAsDefault')}
            isToggled={form.isDefault}
            onToggle={onChange.bind(null, 'isDefault', !form.isDefault)}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      {deviceProfiles[deviceProfileId]?.mode === DeviceMode.F_POS ||
      deviceProfiles[deviceProfileId]?.mode === DeviceMode.M_POS ? (
        <Section title={translate('storesSettings.preferences')}>
          <View style={theme.forms.row}>
            <InputToggle
              type="switch"
              testID="toggle-orderPrompt"
              title={translate(
                'deviceProfileSettings.promptOrderNameForTakeAwayAndPickup',
              )}
              isToggled={promptOrderName}
              onToggle={onChange.bind(
                null,
                'orderIdentifier',
                promptOrderName
                  ? OrderIdentifier.TOKEN_ID
                  : OrderIdentifier.CUSTOMER_NAME,
              )}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
          <View style={theme.forms.row}>
            <InputToggle
              type="switch"
              testID="toggle-guest-count"
              title={translate(
                'deviceProfileSettings.promptGuestCountForDineIn',
              )}
              isToggled={form?.promptGuestCount || false}
              onToggle={onChange.bind(
                null,
                'promptGuestCount',
                !form.promptGuestCount,
              )}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
          {deviceProfiles[deviceProfileId]?.mode === DeviceMode.M_POS ? (
            <View style={theme.forms.row}>
              <InputToggle
                testID="toggle-enable-taking-orders"
                title={translate('backOfficeDevices.takingOrders')}
                isToggled={!!form.takingOrders}
                onToggle={() => {
                  onChange('takingOrders', !form.takingOrders);
                  onChange('payAtTable', !form.payAtTable);
                }}
                containerStyle={theme.forms.inputHalf}
              />
              <InputToggle
                testID="toggle-enable-pay-at-table"
                title={translate('backOfficeDevices.payAtTable')}
                isToggled={
                  form.takingOrders !== null ? !form.takingOrders : true
                }
                onToggle={() => {
                  onChange('takingOrders', !form.takingOrders);
                  onChange('payAtTable', !form.payAtTable);
                }}
                containerStyle={theme.forms.inputHalf}
              />
            </View>
          ) : (
            <></>
          )}
        </Section>
      ) : (
        <></>
      )}
      <Section title={translate('backOfficeDeviceProfiles.orderTypes')}>
        <View>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('deviceProfileSettings.EnableDisableOrderType')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerDefault]}>
              {translate('deviceProfileSettings.Default')}
            </Text>
          </View>
          <View>
            {orderTypes.map((type: OrderType, i: number) => (
              <OrderTypesRow
                key={i}
                orderType={type}
                onToggle={onChangeOrderTypes}
                enabled={selectedOrderTypes[type.id] ? true : false}
                isDefault={type.id === defaultOrderType}
                onSelectDefault={updateDefaultOrderType}
              />
            ))}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
