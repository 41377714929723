/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo, useRef } from 'react';
import { View, Text, Image, ScrollView } from 'react-native';
import theme from '../../../../../common/default-theme';
import styles from './FeatureDetail.styles';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import {
  Feature,
  FeatureContext,
  Venue,
  IntegrationApps,
} from '@oolio-group/domain';
import { ImageCarousel } from '../../../../../components/Office/ImageCarousel/ImageCarousel';
import FeatureTile from '../FeatureTile/FeatureTile';
import Icon from '../../../../../components/Icon/Icon';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';

export interface FeatureDetailProps {
  feature: Feature | undefined;
  relatedFeatures?: Feature[];
  venues?: Venue[];
  onEnable: () => void;
  onDisable?: () => void;
  onShowModal?: () => void;
  active?: boolean;
  totalLocationConnected?: number;
}

const FeatureDetail: React.FC<FeatureDetailProps> = ({
  feature,
  relatedFeatures,
  venues,
  onEnable,
  onDisable,
  onShowModal,
  active,
  totalLocationConnected = 0,
}) => {
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const scrollViewRef = useRef<ScrollView | null>(null);

  const onPressTile = useCallback(
    (feature: Feature) => {
      navigation.setParams({
        featureId: feature.featureId,
        title: feature.name,
      });
      scrollViewRef.current?.scrollTo({ y: 0 });
    },
    [navigation],
  );

  const isOOMApp = useMemo(
    () => feature?.app === IntegrationApps.OOM,
    [feature?.app],
  );

  const isActive = useMemo(() => {
    return feature?.enabled || active || totalLocationConnected > 0;
  }, [totalLocationConnected, feature?.enabled, active]);

  const connectingLabel = useMemo(() => {
    if (isOOMApp && totalLocationConnected) {
      return isActive
        ? translate('backOffice.features.connectingLabel', {
            totalLocationConnected: totalLocationConnected,
            sText: totalLocationConnected > 1 ? 's' : '',
          })
        : translate('backOffice.features.buttons.enable');
    }
    if (
      feature?.app === IntegrationApps.OOLIO_RESERVATION &&
      totalLocationConnected
    ) {
      return isActive
        ? translate('backOffice.features.connectingLabel', {
            totalLocationConnected: totalLocationConnected,
            sText: totalLocationConnected > 1 ? 's' : '',
          })
        : translate('backOffice.features.buttons.enable');
    }
    return translate(
      isActive
        ? 'backOffice.features.buttons.disable'
        : 'backOffice.features.buttons.enable',
    );
  }, [feature, isActive, isOOMApp, totalLocationConnected, translate]);

  return (
    <View style={styles.screen}>
      <View style={styles.header}>
        {isOOMApp && isActive ? (
          <View style={styles.message}>
            <Text style={[styles.messageContent, { textAlign: 'center' }]}>
              {translate('backOffice.features.oomMessageDeactivate')}
            </Text>
          </View>
        ) : null}
        {feature?.featureId ? (
          <View style={styles.featureIconContainer}>
            <Icon size={20} name={feature?.icon} color={theme.colors.accent} />
          </View>
        ) : (
          <Image
            style={styles.imageContainer}
            source={{ uri: feature?.icon }}
          />
        )}
        <Text
          testID={'title'}
          style={[styles.heading1, { marginTop: 20, marginBottom: 12 }]}
        >
          {feature?.title}
        </Text>
        <Text style={[styles.paragraph, { textAlign: 'center' }]}>
          {feature?.description}
        </Text>
        <View style={styles.actions}>
          <TreatButton
            testID="toggle-feature-action"
            label={connectingLabel}
            type={isActive ? (isOOMApp ? 'positive' : 'negative') : 'positive'}
            onPress={
              isOOMApp
                ? () => navigation.navigate('SettingsTab')
                : feature?.enabled && onDisable
                ? onDisable
                : onEnable
            }
          />
          {venues &&
            onShowModal &&
            feature?.context == FeatureContext.VENUE &&
            venues?.length > 1 &&
            feature?.enabled && (
              <TreatButton
                type="neutral"
                testID="configure-action"
                onPress={onShowModal}
                label={translate('backOffice.features.buttons.configure')}
                containerStyle={{ marginLeft: 10 }}
              />
            )}
        </View>
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.heading2}>
            {translate('backOffice.features.about')}
          </Text>
          <Text style={[styles.paragraph, { marginTop: 12 }]}>
            {feature?.about}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading2}>
            {translate('backOffice.features.featureHeader')}
          </Text>
          {feature?.subFeatureList?.map((subfeature, i) => {
            return (
              <View style={styles.subSection} key={i}>
                <Text style={styles.heading3}>{subfeature.name}</Text>
                <Text style={styles.paragraph}>{subfeature.description}</Text>
              </View>
            );
          })}
        </View>
        <View style={styles.section}>
          <Text style={[styles.heading2, { marginBottom: 20 }]}>
            {translate('backOffice.features.screenshots')}
          </Text>
          <ImageCarousel
            containerStyle={{ width: 600 }}
            imageUrls={feature?.screenshots || []}
          />
        </View>
      </View>
      {relatedFeatures && relatedFeatures.length > 0 && (
        <View style={styles.similarGrid}>
          <Text style={styles.heading2}>
            {translate('backOffice.features.similarFeatures')}
          </Text>
          <View style={styles.tiles}>
            {relatedFeatures.map(feature => (
              <FeatureTile
                key={feature.id}
                feature={feature}
                onPress={onPressTile}
              />
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

export default FeatureDetail;
