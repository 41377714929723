import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { View, Text, FlatList, TouchableOpacity } from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@oolio-group/domain';
import Popover, { PopoverPlacement } from 'react-native-popover-view';

import scale from '../../../../../../common/theme';
import Item from './CommonItem';
import { translate } from '@oolio-group/localization';
import Icon from '../../../../../../components/Icon/Icon';
import defaultTheme from '../../../../../../common/default-theme';
import { MenuItemAction } from '../../types';

const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});

const toolTipContainer: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.large,
  flexDirection: 'column',
  backgroundColor: theme.colors.white,
  paddingVertical: theme.padding.medium,
  borderWidth: 2,
  borderColor: theme.colors.white,
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  ...theme.font14RegularCharcoal,
});

const popoverStyle: StyleFn = ({ theme }) => ({
  width: 170,
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.white,
});

const renderItemContainerStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(25),
  backgroundColor: theme.colors.white,
  justifyContent: 'center',
  paddingHorizontal: 5,
});

const rowContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  padding: theme.padding.small,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const labelTextContainer: StyleFn = () => ({
  width: scale.moderateScale(17),
});

interface MenuEmptyItemProps {
  onLongPress: () => void;
  onSelectOption: (option: MenuItemAction) => void;
}

const MenuEmptyItem: React.FC<MenuEmptyItemProps> = ({
  onLongPress,
  onSelectOption,
}) => {
  const { css, theme } = useFela();
  const [showOption, setShowOption] = useState(false);
  const selectedOptionRef = useRef<MenuItemAction | undefined>();

  const onToggleOptions = () => {
    setShowOption(!showOption);
  };

  const onRequestClosePopover = () => {
    setShowOption(false);
  };

  const options = useMemo(() => {
    return [
      {
        label: translate('menus.existingPage'),
        name: MenuItemAction.ADD_EXISTING_PAGE,
      },
      {
        label: translate('menus.newPage'),
        name: MenuItemAction.ADD_NEW_PAGE,
      },
    ];
  }, []);

  const onSelectPageOption = useCallback(option => {
    selectedOptionRef.current = option.name as MenuItemAction;
    onRequestClosePopover();
  }, []);

  const onClosePopoverCompleted = useCallback(() => {
    onSelectOption(selectedOptionRef.current as MenuItemAction);
  }, [onSelectOption]);

  const onOpenPopoverStart = useCallback(() => {
    selectedOptionRef.current = undefined;
  }, []);

  return (
    <Popover
      placement={PopoverPlacement.AUTO}
      popoverStyle={css(popoverStyle({ theme }))}
      from={
        <Item
          onLongPress={onLongPress}
          onPress={onToggleOptions}
          color={defaultTheme.colors.grey1}
          isEmptyTile
        />
      }
      backgroundStyle={css(backgroundStyle)}
      isVisible={showOption}
      onRequestClose={onRequestClosePopover}
      onCloseComplete={onClosePopoverCompleted}
      onOpenStart={onOpenPopoverStart}
    >
      <View style={css(toolTipContainer)}>
        <FlatList
          data={options}
          renderItem={({ item }): ReactElement => {
            return (
              <TouchableOpacity
                onPress={() => onSelectPageOption(item)}
                testID="option-item"
              >
                <View
                  style={css(
                    renderItemContainerStyle({
                      theme,
                    }),
                  )}
                >
                  <View style={css(rowContainerStyle)}>
                    <Text style={css(optionLabelStyle)}>{item.label}</Text>
                    <View style={css(labelTextContainer)}>
                      <Icon
                        name={'angle-right'}
                        size={24}
                        color={defaultTheme.colors.grey4}
                      />
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
          keyExtractor={item => item.label?.trim()}
        />
      </View>
    </Popover>
  );
};

export default MenuEmptyItem;
