import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import { useSyncOrderEvents } from '../../../../hooks/app/useSyncOrderEvents';
import { userUtility } from '../../../../state/userUtility';
import { generateNotificationEvent } from '../../../../utils/sendReceiptHelper';
import { OrderHistoryItem } from '../../../POS/Orders/OpenOrders/OpenOrders.types';
import styles from './OrderHistoryTable.styles';
import theme from '../../../../common/default-theme';
import OrderHistoryRow from './OrderHistoryRow';
import Empty from '../../../../components/Shared/Empty/Empty';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import OrderReceiptActionModal from '../../../../components/Modals/SendOrderReceipt/OrderReceiptAction';

interface OrderHistoryTableProps {
  data: OrderHistoryItem[];
  isOffline?: boolean;
  onPressRow: (order: OrderHistoryItem) => void;
  onPressReprintDocket: (orderId: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
}

const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({
  data,
  isOffline,
  onPressRow,
  onPressPrintReceipt,
  onPressReprintDocket,
  currentPage,
  onPageChange,
  itemsPerPage,
}) => {
  const [session] = useSession();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const onSyncComplete = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
  };
  const { syncOrderEvents } = useSyncOrderEvents(onSyncComplete);

  const pageItems = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  }, [data, currentPage, itemsPerPage]);

  const sendOrderReceipt = useCallback(
    async (order, email, phone) => {
      if (session && order?.id) {
        const event = generateNotificationEvent(
          session,
          order?.id,
          userUtility.userActivity.posUser?.id || '',
          email,
          phone,
        );

        if (event) {
          syncOrderEvents([event]);
        }
      }
    },
    [session, syncOrderEvents],
  );

  const onPressSendReceipt = useCallback(
    order => {
      if (order) {
        showModal(
          <OrderReceiptActionModal
            order={order}
            sendReceipt={sendOrderReceipt}
          />,
        );
      }
    },
    [sendOrderReceipt, showModal],
  );

  const handleRowPress = useCallback(
    (order: OrderHistoryItem) => {
      if (isOffline) {
        showNotification({
          error: true,
          message: translate('offline.orderHistory'),
        });
      } else {
        onPressRow(order);
      }
    },
    [isOffline, onPressRow, showNotification, translate],
  );

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.headerDate]}>
          {translate('orderHistory.completedAt')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerNo]}>
          {translate('backOfficeSalesFeed.OrderNo')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerName]}>
          {translate('openOrders.orderName')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerPayments]}>
          {translate('payment.payments')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerAmt]}>
          {translate('backOfficeSalesFeed.Total')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((order, i) => (
            <OrderHistoryRow
              order={order}
              key={`${order.id}-${i}`}
              onPress={() => handleRowPress(order)}
              onPressPrintReceipt={onPressPrintReceipt}
              onPressReprintDocket={onPressReprintDocket}
              onPressSendReceipt={onPressSendReceipt}
            />
          ))
        ) : (
          <Empty label={translate('orderHistory.emptyList')} />
        )}
      </View>
      {data.length > itemsPerPage ? (
        <Pagination
          pageLength={itemsPerPage}
          page={currentPage}
          onPageChange={onPageChange}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default OrderHistoryTable;
