import { OrderType, OrderTypeCode } from '@oolio-group/domain';

export function disableTokenNumberForOrderType(orderType: OrderType) {
  if (
    [OrderTypeCode.DELIVERY, OrderTypeCode.DINE_IN].includes(
      orderType?.code as OrderTypeCode,
    )
  ) {
    return true;
  }
  return false;
}
