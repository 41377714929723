import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 650,
    alignSelf: 'center',
    position: 'absolute',
    top: 40,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.s,
  },
  btnClose: {
    position: 'absolute',
    top: -0,
    left: -54,
    width: 44,
    height: 44,
    borderRadius: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
  },
  headerContainer: {
    borderTopLeftRadius: theme.radius.s,
    borderTopRightRadius: theme.radius.s,
    flexDirection: 'row',
    height: 44,
  },
  headerText: {
    fontFamily: theme.fonts.medium,
    lineHeight: 20,
    marginLeft: 10,
  },
  filterText: {
    marginLeft: 25,
    marginTop: 12,
    color: theme.colors.grey6,
    width: 500,
  },
  allergensContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 10,
    marginHorizontal: 15,
  },
  toggleInputStyle: {
    paddingLeft: 10,
    marginBottom: 4,
    marginRight: 4,
    width: 200,
    borderRadius: theme.radius.s,
  },
  clearFilterText: {
    height: 44,
    color: theme.colors.red,
    fontFamily: theme.fonts.medium,
    marginLeft: 10,
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    textAlign: 'right',
    height: 60,
    borderBottomLeftRadius: theme.radius.s,
    borderBottomRightRadius: theme.radius.s,
  },
});

export default styles;
