import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 44,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.elevated1,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
    marginRight: 44,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
