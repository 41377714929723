import { Order } from '@oolio-group/domain';

const env = `${process.env.REACT_APP_ONLINE_STORE_PREVIEW_URL}`;

export const generateQRURL = (order: Order): string => {
  let qrURL = '';
  const payURL = env + '/pay?orderId=';
  const receiptURL = env + '/r/';

  if (order.status == 'COMPLETED') {
    qrURL = receiptURL + order.id;
  } else {
    qrURL = payURL + order.id;
  }

  return qrURL;
};
