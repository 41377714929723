import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { View, Text } from 'react-native';
import {
  UpdatePricingGroupInput,
  ProductPricingInput,
  Currency,
  DEFAULT_PRICING_GROUP,
  FeatureIDs,
} from '@oolio-group/domain';
import { ProductModel } from '@oolio-group/dal';
import { UpdateProductInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useRoute } from '@react-navigation/native';
import { find, isEqual, keyBy, pickBy } from 'lodash';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useTaxes } from '../../../../../hooks/app/useTaxes';
import { useProductPricings } from '../../../../../hooks/app/productPricings/useProductPricings';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import { isValidPrice } from '../../../../../utils/validator';
import { PRODUCT_PRICING_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import { WithFeature } from '../../../../../components/features/WithFeature';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import theme from '../../../../../common/default-theme';
import styles from './Pricing.styles';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import SelectSearch from '../../../../../components/Shared/Select/SelectSearch';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import { PRICING_GROUP_OPTIONS } from './graphql';

interface CustomProductModel extends ProductModel {
  priceGroupsDictionary: { [key: string]: UpdatePricingGroupInput };
  defaultPriceGroupId: string;
}

const PRICE_LIST_SETTING = {
  featureId: FeatureIDs.PRICE_LIST,
};

export const Pricing: React.FC = () => {
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { currency, currencySymbol } = useCurrency();
  const productId = (route.params as { productId: string })?.productId || '';
  const [productData, setProductData] = useState({} as CustomProductModel);
  const { taxesOptions, error: taxesError } = useTaxes();
  const {
    products,
    updateProduct: updateProductDetails,
    error: productError,
    loading: productLoading,
  } = useProducts(productId, PRODUCT_PRICING_FRAGMENT);
  const product = useMemo(() => products[productId], [productId, products]);
  // PP refers to product pricing
  const {
    update: updatePP,
    addProductPricing,
    error: errorPPReq,
    loading: loadingPP,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    operation: PPMethod,
    delete: deletePricing,
  } = useProductPricings({ customFragment: PRODUCT_PRICING_FRAGMENT });

  const {
    getAllPricingGroups,
    pricingGroups,
    loading: loadingPG,
    error: errorPG,
  } = usePricingGroups({ customFragment: PRICING_GROUP_OPTIONS });

  const error = errorPPReq || productError || taxesError || errorPG;
  const loading = loadingPP || productLoading || loadingPG;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const defaultPrice = useMemo(() => {
    if (
      productData?.defaultPriceGroupId &&
      productData?.priceGroupsDictionary
    ) {
      return productData.priceGroupsDictionary[productData.defaultPriceGroupId];
    } else {
      return {} as UpdatePricingGroupInput;
    }
  }, [productData]);

  useEffect(() => {
    if (product) {
      const productModelData = {} as CustomProductModel;
      productModelData.id = product.id;
      productModelData.name = product.name;
      productModelData.variablePricing = product.variablePricing || false;
      productModelData.maxSellingPrice = product.maxSellingPrice;
      productModelData.minSellingPrice = product.minSellingPrice;
      const priceGroupsFormated = product.pricingGroups.map(
        eachPriceGroup =>
          ({
            id: eachPriceGroup.id,
            name: eachPriceGroup.name,
            prices: eachPriceGroup.prices.map(eachPrice => ({
              product: eachPrice.product?.id,
              id: eachPrice.id,
              taxInclusive: eachPrice.taxInclusive || false,
              costTax:
                (eachPrice?.costTax as unknown as { id: string })?.id || '',
              sellingTax:
                (eachPrice?.sellingTax as unknown as { id: string })?.id || '',
              costPrice: {
                amount: eachPrice.costPrice?.amount || 0,
                currency: eachPrice?.costPrice?.currency || currency,
              },
              sellingPrice: {
                amount: eachPrice.sellingPrice?.amount || 0,
                currency: eachPrice.sellingPrice?.currency || currency,
              },
            })),
          } as unknown as UpdatePricingGroupInput),
      );
      productModelData.priceGroupsDictionary = keyBy(priceGroupsFormated, 'id');
      productModelData.defaultPriceGroupId =
        find(priceGroupsFormated, { name: DEFAULT_PRICING_GROUP })?.id || '';
      setProductData(productModelData);
    }
  }, [product, productId, currency, productData.id]);

  useEffect(() => {
    getAllPricingGroups();
  }, [getAllPricingGroups]);

  const onChangeProduct = useCallback((prop, value) => {
    setProductData(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onChange = useCallback(
    (priceGroupId, prop: string, value) => {
      const productInfo = { ...productData };
      if (prop === 'costPrice' || prop === 'sellingPrice') {
        value = { currency: currency as Currency, amount: value };
      }
      productData.priceGroupsDictionary[priceGroupId].prices[0] = {
        ...productData.priceGroupsDictionary[priceGroupId].prices[0],
        [prop]: value,
      };
      if (
        prop === 'costPrice' &&
        Object.keys(productData.priceGroupsDictionary).length > 1
      ) {
        for (const key in productData.priceGroupsDictionary) {
          // Updating the cost price in other Price lists too.
          if (key !== priceGroupId) {
            productData.priceGroupsDictionary[key].prices[0] = {
              ...productData.priceGroupsDictionary[key].prices[0],
              [prop]: value,
            };
          }
        }
      }
      setProductData(productInfo);
    },
    [productData, currency],
  );

  const onSaveProduct = useCallback((): void => {
    if (
      (defaultPrice.prices[0].sellingPrice.amount &&
        !isValidPrice(String(defaultPrice.prices[0].sellingPrice.amount))) ||
      (defaultPrice.prices[0].costPrice.amount &&
        !isValidPrice(String(defaultPrice.prices[0].costPrice.amount)))
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'price' }),
      });
    } else {
      const updateProductPricingsData = Object.values(
        productData.priceGroupsDictionary,
      ).map(x => {
        const tempPrices = { ...x.prices[0] };
        tempPrices.sellingPrice.amount = +tempPrices.sellingPrice.amount;
        tempPrices.costPrice.amount = +tempPrices.costPrice.amount;
        return {
          ...x.prices[0],
          pricingGroupId: x.id,
          product: productData.id,
          // add the default selling tax to pricing groups as it is not available in UI
          sellingTax: defaultPrice?.prices?.[0]?.sellingTax,
        };
      }) as ProductPricingInput[];
      updatePP(updateProductPricingsData);

      const updateProductInput = {
        id: productData.id,
        variablePricing: productData.variablePricing,
        maxSellingPrice: +productData.maxSellingPrice,
        minSellingPrice: +productData.minSellingPrice,
      } as UpdateProductInput;
      const { variablePricing, maxSellingPrice, minSellingPrice, id } = product;
      const originalData = {
        variablePricing: variablePricing || false,
        maxSellingPrice,
        minSellingPrice,
        id,
      };
      const isProductDataChange = !isEqual(originalData, updateProductInput);
      isProductDataChange && updateProductDetails(updateProductInput);
    }
  }, [
    defaultPrice.prices,
    showNotification,
    translate,
    productData,
    updatePP,
    product,
    updateProductDetails,
  ]);

  const onDeletePricing = useCallback(
    (pricingGroupId, name): void => {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', { label: name })}
          onConfirm={() => {
            deletePricing([{ pricingGroupId, productId }]);
            closeModal();
          }}
        />,
      );
    },
    [showModal, translate, deletePricing, productId, closeModal],
  );

  const onPressAddOrRemovePriceList = (listId: string, listName: string) => {
    if (productData.priceGroupsDictionary[listId]) {
      onDeletePricing(listId, listName);
      return;
    }
    const tempPrices = { ...defaultPrice?.prices[0] };
    tempPrices.sellingPrice = {
      amount: +tempPrices.sellingPrice?.amount,
      currency: tempPrices.sellingPrice?.currency,
    };
    tempPrices.costPrice = {
      amount: +tempPrices.costPrice?.amount,
      currency: tempPrices.costPrice?.currency,
    };
    const productPricing = {
      ...tempPrices,
      sellingTax: tempPrices.sellingTax,
      costTax: tempPrices.costTax,
      pricingGroupId: listId,
      product: productData.id,
    } as ProductPricingInput;
    addProductPricing(productId, [{ pricingGroupId: listId, productPricing }]);
  };

  const pricingGroupDataArray = useMemo(() => {
    return Object.values(
      pickBy(pricingGroups, list => {
        return list.name !== DEFAULT_PRICING_GROUP;
      }),
    ).map(list => ({
      title: list.name,
      value: list.id,
    }));
  }, [pricingGroups]);

  const defaultSellingPrice = defaultPrice?.prices?.[0]?.sellingPrice?.amount;
  const defaultCostPrice = defaultPrice?.prices?.[0]?.costPrice?.amount;
  const pricesList = Object.values(
    pickBy(productData?.priceGroupsDictionary, list => {
      return list.name !== DEFAULT_PRICING_GROUP;
    }) || {},
  );

  return (
    <ScreenLayout
      loading={loading}
      title={`${productData.name || 'Product'} Pricing | Oolio`}
      onSave={onSaveProduct}
    >
      <Section title={translate('productSettings.productPricing')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-costPrice"
            title="Cost Price"
            label={currencySymbol}
            value={defaultCostPrice?.toString()}
            placeholder="0.00"
            onChangeText={value => {
              onChange(
                defaultPrice.id,
                'costPrice',
                formatToDigitsStr(value, false),
              );
            }}
            keyboardType="decimal-pad"
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-costTax"
            title="Cost Tax"
            options={taxesOptions}
            selectedValue={defaultPrice?.prices?.[0]?.costTax || ''}
            onValueChange={value => onChange(defaultPrice.id, 'costTax', value)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-sellingPrice"
            title="Selling Price"
            label={currencySymbol}
            value={defaultSellingPrice?.toString()}
            placeholder="0.00"
            onChangeText={value => {
              onChange(
                defaultPrice.id,
                'sellingPrice',
                formatToDigitsStr(value, false),
              );
            }}
            keyboardType="decimal-pad"
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-sellingTax"
            title="Selling Tax"
            options={taxesOptions}
            selectedValue={defaultPrice?.prices?.[0]?.sellingTax || ''}
            onValueChange={value =>
              onChange(defaultPrice.id, 'sellingTax', value)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-includeTax"
            isToggled={defaultPrice?.prices?.[0]?.taxInclusive}
            title={translate('productSettings.includeTaxInDisplayPrice')}
            onToggle={onChange.bind(
              null,
              defaultPrice.id,
              'taxInclusive',
              !defaultPrice?.prices?.[0]?.taxInclusive,
            )}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('productSettings.variablePricing')}>
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-includeTax"
            isToggled={productData.variablePricing}
            title={translate('productSettings.variablePricing')}
            onToggle={() =>
              onChangeProduct('variablePricing', !productData.variablePricing)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        {productData.variablePricing ? (
          <View style={theme.forms.row}>
            <InputText
              testID="input-minPrice"
              title="Min Price"
              label={currencySymbol}
              value={productData?.minSellingPrice?.toString()}
              placeholder="0.00"
              onChangeText={value => {
                onChangeProduct(
                  'minSellingPrice',
                  formatToDigitsStr(value, false),
                );
              }}
              keyboardType="decimal-pad"
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              testID="input-minPrice"
              title="Max Price"
              label={currencySymbol}
              value={productData?.maxSellingPrice?.toString()}
              placeholder="0.00"
              onChangeText={value => {
                onChangeProduct(
                  'maxSellingPrice',
                  formatToDigitsStr(value, false),
                );
              }}
              keyboardType="decimal-pad"
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        ) : (
          <></>
        )}
      </Section>
      <WithFeature feature={PRICE_LIST_SETTING}>
        <Section title={translate('productSettings.priceLists')}>
          <SelectSearch
            testID="search-pricelist"
            placeholder={translate('productSettings.searchPriceList')}
            options={pricingGroupDataArray}
            onChangeOption={(s, e) =>
              onPressAddOrRemovePriceList(e.value, e.title)
            }
          />
          <View style={styles.tableContainer}>
            <View style={theme.tables.header}>
              <Text style={[theme.tables.headerText, styles.cellName]}>
                {translate('productSettings.listName')}
              </Text>
              <Text style={[theme.tables.headerText, styles.headerPrice]}>
                {translate('productSettings.default')}
              </Text>
              <Text style={[theme.tables.headerText, styles.headerSetPrice]}>
                {translate('productSettings.setPrice')}
              </Text>
            </View>
            <View>
              {pricesList.map((list, i: number) => {
                const sellingPrice = list.prices?.length
                  ? list.prices?.[0]?.sellingPrice?.amount.toString()
                  : '';

                return (
                  <View testID="row-priceList" key={i} style={theme.tables.row}>
                    <InputText
                      testID="input-listName"
                      editable={false}
                      value={list.name}
                      placeholder={list.name}
                      containerStyle={styles.cellName}
                    />
                    <InputText
                      testID="input-listDefault"
                      placeholder="0.00"
                      editable={false}
                      alignText="right"
                      label={currencySymbol}
                      value={defaultSellingPrice?.toString()}
                      containerStyle={styles.cellPrice}
                    />
                    <InputText
                      testID="input-listPrice"
                      placeholder="0.00"
                      alignText="right"
                      label={currencySymbol}
                      value={sellingPrice}
                      onChangeText={value => {
                        onChange(
                          list.id,
                          'sellingPrice',
                          formatToDigitsStr(value, false),
                        );
                      }}
                      containerStyle={styles.cellPrice}
                    />
                    <ButtonIcon
                      testID="btn-delete"
                      type="negativeLight"
                      icon="trash-alt"
                      onPress={onDeletePricing.bind(null, list.id, list.name)}
                      // eslint-disable-next-line react-native/no-inline-styles
                      containerStyle={{ marginLeft: 10 }}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        </Section>
      </WithFeature>
    </ScreenLayout>
  );
};
