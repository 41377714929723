import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { PricingGroup, SELECT_ALL } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import {
  timeSlotOptions,
  weekDaysOptions,
} from '../../../../../../utils/dateHelper';
import theme from '../../../../../../common/default-theme';
import Message from '../../../../../../components/Office/Message/Message';
import Section from '../../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import SelectDate from '../../../../../../components/Shared/Select/SelectDate';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import SelectMultiple from '../../../../../../components/Shared/Select/SelectMultiple';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import styles from '../../PriceLists.styles';

interface DetailFormProps {
  loading?: boolean;
  pricingGroupData: PricingGroup;
  onChange: (prop: string, value: unknown) => void;
  venueOptions: { label: string; value: string }[];
  storeOptions: { label: string; value: string }[];
  orderTypeOptions: { label: string; value: string }[];
  channelOptions: { label: string; value: string }[];
  onSave: () => void;
  onDelete: () => void;
  isDefault: boolean;
  onCreateTimeSlot: () => void;
  onDeleteTimeSlot: (id: number) => void;
}

export const DetailForm: React.FC<DetailFormProps> = ({
  loading,
  pricingGroupData,
  storeOptions,
  venueOptions,
  orderTypeOptions,
  onChange,
  onSave,
  onDelete,
  isDefault,
  onCreateTimeSlot,
  onDeleteTimeSlot,
}) => {
  const { translate } = useTranslation();
  const { dateRange, timeBlocks } = pricingGroupData.schedule || {};

  const isEmpty = pricingGroupData?.prices?.length < 1;

  const handleDateRange = useCallback(
    (id: string, date?: Date) => {
      if (id === 'fromDateRange') {
        onChange('dateRange', {
          startDate: new Date(date || Date.now()).valueOf(),
          endDate: dateRange ? dateRange.endDate : undefined,
        });
      } else {
        onChange('dateRange', {
          startDate: dateRange ? dateRange.startDate : Date.now(),
          endDate: date ? new Date(date).valueOf() : undefined,
        });
      }
    },
    [dateRange, onChange],
  );

  const getStartDate = () => {
    return dateRange?.startDate ? new Date(dateRange?.startDate) : new Date();
  };

  const getEndDate = () => {
    return dateRange?.endDate ? new Date(dateRange?.endDate) : undefined;
  };

  return (
    <ScreenLayout
      title={`${pricingGroupData?.name || 'Price List'} | Oolio`}
      hideFooter={isDefault}
      loading={loading}
      onSave={onSave}
      onDelete={onDelete}
      onDeleteDisabled={!pricingGroupData.id}
    >
      {!isDefault && isEmpty ? (
        <Message
          type="negative"
          icon="exclamation-triangle"
          message={translate('pricings.emptyPriceList')}
          containerStyle={styles.message}
        />
      ) : (
        <></>
      )}
      <Section title={translate('backOfficeProducts.courseDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('form.name')}
            value={pricingGroupData?.name || ''}
            placeholder={translate('pricings.name')}
            onChangeText={onChange.bind(null, 'name')}
            editable={!isDefault}
            containerStyle={theme.forms.inputHalf}
          />
          <SelectMultiple
            testID="select-venues"
            title={translate('pricings.venues')}
            options={[
              { label: translate('pricings.allVenues'), value: SELECT_ALL },
            ].concat(venueOptions)}
            selectedValues={(pricingGroupData?.venues || []).map(x => x.id)}
            onValueChange={onChange.bind(null, 'venues')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-stores"
            title={translate('pricings.stores')}
            options={[
              { label: translate('pricings.allStores'), value: SELECT_ALL },
            ].concat(storeOptions)}
            selectedValues={(pricingGroupData?.stores || []).map(x => x.id)}
            onValueChange={onChange.bind(null, 'stores')}
            containerStyle={theme.forms.inputHalf}
          />
          <SelectMultiple
            testID="select-orderTypes"
            title={translate('pricings.orderTypes')}
            options={[
              {
                label: translate('pricings.allOrderTypes'),
                value: SELECT_ALL,
              },
            ].concat(orderTypeOptions)}
            selectedValues={(pricingGroupData?.orderTypes || []).map(x => x.id)}
            onValueChange={onChange.bind(null, 'orderTypes')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('pricings.schedule')}>
        <View style={theme.forms.row}>
          <SelectDate
            testID="date-from"
            title={translate('pricings.validFromDate')}
            valueDate={getStartDate()}
            onChangeDate={handleDateRange.bind(null, 'fromDateRange')}
            containerStyle={theme.forms.inputHalf}
          />
          <SelectDate
            optional
            testID="date-to"
            title={translate('pricings.validToDate')}
            valueDate={getEndDate()}
            minDate={getStartDate()}
            onChangeDate={handleDateRange.bind(null, 'toDateRange')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={styles.table}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerDay]}>
              {translate('pricings.weekDays')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerTime]}>
              {translate('pricings.startTime')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerTime]}>
              {translate('pricings.endTime')}
            </Text>
          </View>
          <View>
            {timeBlocks && timeBlocks.length > 0 ? (
              timeBlocks?.map((block, i: number) => (
                <View
                  testID="row-timeBlock"
                  key={`routine-${i}`}
                  style={theme.tables.row}
                >
                  <TreatPicker
                    testID="select-day"
                    options={weekDaysOptions}
                    selectedValue={block.day}
                    onValueChange={value =>
                      onChange('timeSlot', { idx: i, day: value })
                    }
                    containerStyle={styles.cellDay}
                  />
                  <TreatPicker
                    testID="select-startTime"
                    options={timeSlotOptions}
                    selectedValue={block.timeSlot.startTime}
                    onValueChange={value =>
                      onChange('timeSlot', { idx: i, startTime: value })
                    }
                    containerStyle={styles.cellTime}
                  />
                  <TreatPicker
                    testID="select-endTime"
                    options={timeSlotOptions}
                    selectedValue={block.timeSlot.endTime}
                    onValueChange={value =>
                      onChange('timeSlot', { idx: i, endTime: value })
                    }
                    containerStyle={styles.cellTime}
                  />
                  <ButtonIcon
                    testID="btn-deleteBlock"
                    type="negativeLight"
                    icon="trash-alt"
                    onPress={() => onDeleteTimeSlot(i)}
                  />
                </View>
              ))
            ) : (
              <View style={theme.tables.emptyView}>
                <Text style={theme.tables.emptyText}>
                  {translate('pricings.noBlocks')}
                </Text>
              </View>
            )}
          </View>
          <ButtonIcon
            size={34}
            icon="plus"
            type="positive"
            onPress={onCreateTimeSlot}
            containerStyle={styles.btnAddSlot}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
