import React, { useEffect, useState } from 'react';
import { useAudioNotification } from '../../hooks/useAudioNotification/useAudioNotification';
import { useReactiveVar } from '@apollo/client/react/hooks';
import {
  alertAndPrintTriggerVar,
  pendingOnlineOrdersCountVar,
  AlertAndPrintTrigger,
} from '../../state/cache';
import { getItem } from '../../storage/interface';
import { useSession } from '../../hooks/app/useSession';
import { IntegrationPartner } from '@oolio-group/domain';
import { INTEGRATION_SETTINGS_KEY } from '../../types/Common';
import { usePreviousValue } from '@oolio-group/hooks';

const NOTIFICATION_DELAY = 2000;
const NOTIFICATION_INTERVAL = 60000;

const OnlineOrderAudioNotification: React.FC = () => {
  const alertAndPrintTrigger = useReactiveVar<AlertAndPrintTrigger>(
    alertAndPrintTriggerVar,
  );

  const onlineOrdersCount = useReactiveVar<number>(pendingOnlineOrdersCountVar);

  const previousCount = usePreviousValue(onlineOrdersCount);

  const [session] = useSession();
  const [playCount, setPlayCount] = useState<number>(0);
  const [autoAcceptEnabled, setAutoAcceptEnabled] = useState<boolean>(false);

  const audioNotification = useAudioNotification();

  useEffect(() => {
    if (!session.deviceProfile?.enableOnlineOrders) return;
    (async () => {
      const integrationPartners: IntegrationPartner[] =
        (await getItem(INTEGRATION_SETTINGS_KEY)) || [];

      const settings = integrationPartners.find(
        (partner: IntegrationPartner) =>
          partner.appName === alertAndPrintTrigger?.app,
      );

      const isAutoAcceptEnabled =
        settings?.preferences?.onlineOrdering?.autoAcceptOrders || false;
      setAutoAcceptEnabled(isAutoAcceptEnabled);

      if (onlineOrdersCount > previousCount) {
        audioNotification.playWithDelay(NOTIFICATION_DELAY);
      }
    })();
  }, [
    alertAndPrintTrigger,
    audioNotification,
    onlineOrdersCount,
    previousCount,
    session?.device?.id,
    session?.deviceProfile?.enableOnlineOrders,
  ]);

  useEffect(() => {
    if (autoAcceptEnabled) return;
    const interval = setInterval(() => {
      if (onlineOrdersCount > 0 && playCount < 10) {
        audioNotification.playWithDelay(NOTIFICATION_DELAY);
        setPlayCount(prev => prev + 1);
      }
    }, NOTIFICATION_INTERVAL);

    if (onlineOrdersCount === 0) {
      clearInterval(interval);
      setPlayCount(0);
    }

    if (onlineOrdersCount > previousCount) {
      setPlayCount(0);
    }

    return () => clearInterval(interval);
  }, [
    audioNotification,
    autoAcceptEnabled,
    onlineOrdersCount,
    playCount,
    previousCount,
  ]);

  return <></>;
};

export default OnlineOrderAudioNotification;
