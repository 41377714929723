import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 600,
    alignSelf: 'center',
    position: 'absolute',
    top: 40,
  },
  btnClose: {
    position: 'absolute',
    top: -0,
    left: -54,
    width: 44,
    height: 44,
    borderRadius: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
  },
  list: {
    marginTop: 8,
    maxHeight: 300,
    overflow: 'hidden',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  option: {
    height: 50,
    paddingLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
});

export default styles;
