/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Tax, CreateTaxInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useMutation } from '@apollo/client/react/hooks';
import { CREATE_TAX } from '../../../../../../graphql/settings';
import { useNotification } from '../../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../../utils/errorHandlers';
import keyBy from 'lodash/keyBy';
import SelectMultiple, {
  SelectMultipleOption,
} from '../../../../../../components/Shared/Select/SelectMultiple';
import TreatModal from '../../../../../../components/Shared/Modals/Modal/Modal';
import InputText from '../../../../../../components/Shared/Inputs/InputText';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface CreateGroupTaxModalProps {
  onAddTax: (tax: TaxToTax) => void;
  taxesWithoutGroupTax: { label: string; value: string }[];
  taxes: TaxToTax[];
}

export const CreateGroupTaxModal: React.FC<CreateGroupTaxModalProps> = ({
  taxes,
  taxesWithoutGroupTax,
  onAddTax,
}: CreateGroupTaxModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [createForm, setCreateForm] = useState({
    assignedTaxes: taxesWithoutGroupTax,
  } as {
    name: string;
    code: string;
    rate: string;
    assignedTaxes: SelectMultipleOption[];
    selectedTaxes: string[];
  });

  const taxesDictionary = useMemo(() => {
    return keyBy(taxes, 'id');
  }, [taxes]);

  const onChangeTax = useCallback((prop: string, value: string) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeGroupTax = useCallback(
    (prop: string, value: string[]) => {
      setCreateForm({
        ...createForm,
        [prop]: value,
      });
    },
    [createForm],
  );

  const [createTax, createOperation] = useMutation(CREATE_TAX, {
    onError: noopHandler,
  });

  const calculatedTax = useMemo(() => {
    let value = 0;
    (createForm.selectedTaxes || []).map(taxId => {
      const tax = taxesDictionary[taxId];
      value = value + parseFloat(tax?.rate || '0');
      return value;
    });
    return value;
  }, [createForm, taxesDictionary]);

  const onCreateTax = useCallback(() => {
    if (
      !createForm.name ||
      !createForm.code ||
      !createForm.selectedTaxes.length
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      const createInput = {
        code: createForm.code,
        name: createForm.name,
        rate: calculatedTax,
        taxes: createForm.selectedTaxes,
      } as CreateTaxInput;
      createTax({
        variables: {
          input: createInput,
        },
      });
    }
  }, [createForm, createTax, showNotification, translate, calculatedTax]);

  useEffect(() => {
    if (createOperation.data && createOperation.data.createTax.id) {
      const taxesToTaxesAdded: Tax[] = createForm.selectedTaxes.map(taxId => {
        const taxTemp = taxesDictionary[taxId];
        return {
          code: taxTemp?.code,
          id: taxTemp?.id,
          name: taxTemp?.name,
          isActive: taxTemp?.isActive,
          rate: parseFloat(taxTemp?.rate || '0'),
        } as Tax;
      });
      onAddTax({
        code: createForm.code,
        id: createOperation.data.createTax.id,
        name: createForm.name,
        isActive: false,
        rate: createOperation.data.createTax.rate,
        taxes: taxesToTaxesAdded,
      });
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.TaxItemCreatedSuccessfully'),
      });
    }
  }, [
    taxes,
    onAddTax,
    createForm,
    createOperation.data,
    showNotification,
    closeModal,
    translate,
    taxesDictionary,
  ]);

  useEffect(() => {
    if (createOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(createOperation.error),
      });
    }
  }, [createOperation.error, showNotification]);

  return (
    <TreatModal
      type="positive"
      title={translate('backOfficeSettings.createGroupTax')}
      onConfirm={{ action: onCreateTax }}
      onDismiss={{ action: closeModal }}
    >
      <InputText
        testID="input-name"
        title={translate('backOfficeSettings.groupName')}
        value={createForm.name}
        placeholder={translate('backOfficeSettings.groupName')}
        onChangeText={onChangeTax.bind(null, 'name')}
        containerStyle={{ marginBottom: 20 }}
      />
      <InputText
        testID="input-code"
        title={translate('backOfficeSettings.groupTaxCode') + '*'}
        value={createForm.code}
        placeholder={translate('backOfficeSettings.groupTaxCode')}
        onChangeText={onChangeTax.bind(null, 'code')}
        containerStyle={{ marginBottom: 20 }}
      />
      <SelectMultiple
        testID="select-taxes"
        title={translate('backOfficeSettings.selectTaxes') + '*'}
        options={createForm.assignedTaxes}
        selectedValues={createForm.selectedTaxes || []}
        onValueChange={(value): void =>
          onChangeGroupTax('selectedTaxes', value)
        }
        containerStyle={{ marginBottom: 20 }}
      />
      <InputText
        testID="input-rate"
        editable={false}
        alignText="right"
        title={translate('backOfficeSettings.groupTaxesRate')}
        value={(calculatedTax.toString() || '0') + '%'}
        placeholder={translate('backOfficeSettings.groupName')}
        onChangeText={onChangeTax.bind(null, 'rate')}
      />
    </TreatModal>
  );
};
