import {
  DefaultPaymentTypes,
  OrderPaymentStatus,
  OrderType,
} from '@oolio-group/domain';
import { Order } from '@oolio-group/domain';
import { OnAccountItem } from '../screens/POS/Customers/OnAccountOrders/OnAccountOrdersTable';
import { IN_COMPLETE_PAYMENT_STATUS_LIST } from '../types/Common';
import { createOrderTypesMap, OrderTypesMap } from './orderTypesHelper';

export const mapOrderToOnAccountView = (
  obj: Order,
  orderTypes: OrderTypesMap,
): OnAccountItem => {
  const orderType = (obj.orderType || {}) as unknown as OrderType;
  return {
    id: obj.id,
    status: obj.status,
    orderNumber: obj.orderNumber,
    createdAt: obj.createdAt,
    completedAt: obj.updatedAt || 0,
    customer: obj.customer,
    customerName: obj.customer
      ? obj.customer?.firstName + ' ' + obj.customer?.lastName
      : obj.orderName ?? 'N/A',
    orderType: {
      ...orderType,
      colorId: orderTypes[orderType.id]?.colorId || 0,
      code: orderTypes[orderType.id]?.code || orderType.code || '',
    },
    payTypes: obj.payments
      .filter(
        payment =>
          !IN_COMPLETE_PAYMENT_STATUS_LIST.includes(
            payment.status as OrderPaymentStatus,
          ) || payment.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT,
      )
      .map(payment => {
        return {
          id: payment?.paymentType?.id,
          name: payment?.paymentType?.name,
          amount:
            payment.paymentType?.name === DefaultPaymentTypes.ON_ACCOUNT
              ? payment.amount - payment.tendered
              : payment.amount,
        };
      }),
    totalValue: obj.totalPaymentAmount + (obj?.roundingAmount || 0),
    table: obj.table,
    tables: obj.tables,
  };
};

export const mapOrdersToOrdersView = (
  orders: Order[],
  orderTypes: OrderType[],
): OnAccountItem[] => {
  const orderTypesDict = createOrderTypesMap(orderTypes);
  return orders.map(item => mapOrderToOnAccountView(item, orderTypesDict));
};
