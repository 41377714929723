import { Platform } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { makeVar, useReactiveVar } from '@apollo/client';
import * as Sentry from '@sentry/react-native';
import formatDate from 'date-fns/format';

interface NetworkStatus {
  isConnected: boolean;
  isLoading: boolean;
}

const initialNetworkStatus: NetworkStatus = {
  isConnected: true,
  isLoading: true,
};

export const netWorkStatus = makeVar<NetworkStatus>(initialNetworkStatus);

NetInfo.configure({
  useNativeReachability: Platform.OS !== 'web',
  reachabilityUrl: 'https://ok.oolio.dev',
  reachabilityTest: async response => response.status === 200,
  reachabilityLongTimeout: 10 * 1000, // 10s
  reachabilityShortTimeout: 10 * 1000, // 10s
  reachabilityRequestTimeout: 5 * 1000, // 5s
  reachabilityMethod: 'GET',
});

const setOffline = () => {
  netWorkStatus({ isConnected: false, isLoading: false });
  Sentry.captureMessage(
    `Device is Offline at ${formatDate(Date.now(), 'PPpp')}`,
  );
};
const setOnline = () => {
  netWorkStatus({ isConnected: true, isLoading: false });
  Sentry.captureMessage(
    `Device is Online at ${formatDate(Date.now(), 'PPpp')}`,
  );
};

NetInfo.addEventListener(state => {
  const { isConnected, isInternetReachable } = state;
  if (isConnected) {
    if (isInternetReachable) {
      setOnline();
    } else if (isInternetReachable === false) {
      setOffline();
    }
  } else {
    setOffline();
  }
});

export const useNetworkStatus = () => useReactiveVar(netWorkStatus);
