import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import styles from './AllergensWarning.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import { AllergensKey, ProductAllergens } from '@oolio-group/domain';

interface AllergensWarningProp {
  name: string;
  itemAllergens?: AllergensKey[];
  orderAllergens?: AllergensKey[];
  addToCart: () => void;
  onClose: () => void;
}

const AllergensWarningModal = ({
  name,
  orderAllergens = [],
  itemAllergens = [],
  addToCart,
  onClose,
}: AllergensWarningProp) => {
  const { translate } = useTranslation();

  const allergens = useMemo(() => {
    return itemAllergens
      .map(a => (orderAllergens.includes(a) ? ProductAllergens[a] : ''))
      .filter(a => a)
      .join(', ');
  }, [orderAllergens, itemAllergens]);

  return (
    <View style={styles.container}>
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginVertical: 20 }}
      >
        <Icon
          size={44}
          color={theme.colors.states.focus}
          name={'exclamation-triangle'}
        />
      </View>
      <View>
        <Text style={styles.titleText}>
          {translate('modal.containsAllergensTitle')}
        </Text>
        <Text style={styles.subtitleText}>
          {translate('modal.containsAllergensDescription', {
            name,
            allergens,
          })}
        </Text>
      </View>
      <View style={styles.actions}>
        <TreatButton
          type="focus"
          testID="btn-allergens-add-anyway"
          label={translate('button.addAnyway')}
          onPress={addToCart}
        />
        <TreatButton
          type="cancel"
          testID="btn-allergens-dismiss"
          label={translate('button.dismiss')}
          onPress={onClose}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginTop: 10 }}
        />
      </View>
    </View>
  );
};

export default AllergensWarningModal;
