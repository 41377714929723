import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { AlternateName, LOCALE } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import {
  Language,
  LANGUAGES,
} from '../../../../../components/Modals/TranslationModal/TranslationModal';

interface AlternateNamesSectionProps {
  alternateNames: Array<AlternateName>;
  onChangeAlternateName: (
    prop: string,
    value: string,
    locale: LOCALE,
    id?: string,
  ) => void;
  id?: string;
}

export const AlternateNamesSection: React.FC<AlternateNamesSectionProps> = ({
  alternateNames,
  onChangeAlternateName,
  id,
}: AlternateNamesSectionProps) => {
  const { translate } = useTranslation();
  const [activeLocale, setActiveLocale] = useState<number | undefined>();

  const onChangeActiveLocale = useCallback(
    (activeIndex: number) => {
      if (activeIndex === activeLocale) {
        setActiveLocale(undefined);
      } else {
        setActiveLocale(activeIndex);
      }
    },
    [activeLocale],
  );

  const renderContent = (section: Language) => {
    const alternateName = alternateNames?.find(
      altName => altName.locale === section.locale,
    );

    return (
      <View style={styles.form}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-alternateName"
            placeholder={translate('productSettings.displayName')}
            title={translate('productSettings.displayName')}
            value={alternateName?.display || ''}
            onChangeText={value =>
              onChangeAlternateName('display', value, section.locale, id)
            }
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-alternateName"
            placeholder={translate('productSettings.posName')}
            title={translate('productSettings.posName')}
            value={alternateName?.receipt || ''}
            onChangeText={value =>
              onChangeAlternateName('receipt', value, section.locale, id)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-alternateNameKitchen"
            placeholder={translate('productSettings.kitchenName')}
            title={translate('productSettings.kitchenName')}
            value={alternateName?.kitchen || ''}
            onChangeText={value =>
              onChangeAlternateName('kitchen', value, section.locale, id)
            }
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-alternateDescription"
            placeholder={translate('productSettings.addDescription') + '...'}
            title={translate('productSettings.description')}
            value={alternateName?.description || ''}
            maxLength={300}
            onChangeText={value =>
              onChangeAlternateName('description', value, section.locale, id)
            }
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </View>
    );
  };

  return (
    <Section title={translate('backOfficeSettings.translations')}>
      {LANGUAGES.map((locale, i: number) => (
        <>
          <TouchableOpacity
            key={i}
            style={styles.parent}
            onPress={() => onChangeActiveLocale(i)}
            testID="locale-parent"
          >
            <Text>{locale.name}</Text>
            <View style={styles.icon}>
              <Icon
                size={20}
                color={theme.colors.dark}
                name={activeLocale === i ? 'angle-up' : 'angle-down'}
              />
            </View>
          </TouchableOpacity>
          {activeLocale === i && renderContent(LANGUAGES[i])}
        </>
      ))}
    </Section>
  );
};

const styles = StyleSheet.create({
  form: {
    marginVertical: 20,
  },
  parent: {
    paddingLeft: 12,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  icon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
