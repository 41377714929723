import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import TreatButton from '../../../../Shared/TreatButton/TreatButton';
import styles from './ClosePreviousShiftWarning.styles';

import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import { OPEN_DAYS_FOR_SHIFT } from '../../../../../state/preferences';
import * as storage from '../../../../../storage/interface';

const MAX_OPEN_DAYS_ALLOWED = 5;
export interface ClosePreviousShiftWarningProps {
  closeShift: (openDaysForShift?: number) => void;
  openDaysForShift: number;
  forceCloseShiftRef: React.MutableRefObject<boolean>;
}

const ClosePreviousShiftWarning: React.FC<ClosePreviousShiftWarningProps> = ({
  closeShift,
  openDaysForShift,
  forceCloseShiftRef,
}: ClosePreviousShiftWarningProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const isForceClose = openDaysForShift > MAX_OPEN_DAYS_ALLOWED ? true : false;

  const onContinueShift = useCallback(() => {
    storage.setItem(OPEN_DAYS_FOR_SHIFT, openDaysForShift);
    closeModal();
  }, [openDaysForShift, closeModal]);

  const onCloseShift = useCallback(() => {
    if (isForceClose) forceCloseShiftRef.current = true;
    else {
      storage.setItem(OPEN_DAYS_FOR_SHIFT, openDaysForShift);
      forceCloseShiftRef.current = false;
    }
    closeShift(openDaysForShift);
  }, [closeShift, forceCloseShiftRef, isForceClose, openDaysForShift]);

  return (
    <View style={styles.container}>
      <View style={styles.accessory}>
        <Icon
          size={50}
          color={theme.colors.states.negative}
          name={'exclamation-triangle'}
        />
      </View>
      <View>
        <Text style={styles.titleText}>{translate('shift.unclosedShift')}</Text>
        <Text style={styles.subtitleText}>
          {translate(
            isForceClose
              ? 'shift.previousOpenShiftForceClosureMessage'
              : 'shift.previousOpenShiftMessage',
          )}
        </Text>
      </View>
      <View style={styles.actions}>
        <TreatButton
          type="positive"
          testID="btn-closeShift"
          label={translate('shift.closeShift')}
          onPress={onCloseShift}
        />
        {!isForceClose && (
          <TreatButton
            type="cancel"
            testID="btn-cancel"
            label={translate('shift.continueWithShift')}
            onPress={onContinueShift}
            containerStyle={styles.btnContinueShift}
          />
        )}
      </View>
    </View>
  );
};

export default ClosePreviousShiftWarning;
