import { FeatureSetting } from '@oolio-group/domain';
import React, { FC } from 'react';
import { useCheckFeatureEnabled } from '../../../src/hooks/app/features/useCheckFeatureEnabled';
interface Props {
  feature?: FeatureSetting;
  venue?: string;
  children?: React.ReactNode;
}

export const WithFeature: FC<Props> = ({ feature, venue, children }) => {
  const isFeatureEnabled = useCheckFeatureEnabled();
  if (feature) {
    const { featureId, showTabIfDisabled } = feature;
    const isEnabled = isFeatureEnabled(featureId, venue);
    const showTab = showTabIfDisabled ? !isEnabled : isEnabled;
    if (showTab) {
      return <>{children}</>;
    }
    return null;
  }
  return <>{children}</>;
};
