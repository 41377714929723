import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  search: {
    zIndex: 999,
  },
  popup: {
    position: 'absolute',
    top: 50,
    left: 2,
    right: 2,
    borderRadius: theme.radius.m,
    borderWidth: 1,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    ...theme.shadow.elevated2,
  },
  result: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  text: {
    flex: 1,
    rowGap: 4,
  },
  title: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
    fontVariant: ['tabular-nums'],
  },
  icon: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
