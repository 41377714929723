import React, { useCallback, useEffect } from 'react';
import { Linking } from 'react-native';
import { IntegrationApps } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import { useAccountingIntegration } from '../../../../../../hooks/app/accountingIntegrations/useAccountingIntegration';
import { useNotification } from '../../../../../../hooks/Notification';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { useIsFocused } from '@react-navigation/native';
import FeatureDetail from '../../../Layout/FeatureDetail/FeatureDetail';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@oolio-group/rn-use-modal';

export const AboutTab: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const isFocused = useIsFocused();
  const { showModal, closeModal } = useModal();

  const { getIntegrations, integrations, loading } = useFeatures();

  const featureData = integrations.find(
    integration => integration.app === IntegrationApps.XERO,
  );

  useEffect(() => {
    if (isFocused) getIntegrations();
  }, [getIntegrations, isFocused]);

  const {
    accountingPartnerConsentUrl,
    getAccountingPartnerConsentUrl,
    loading: accountingIntegrationLoading,
    integrationData,
    saveIntegrationDetails,
    disconnectAccountingIntegration,
  } = useAccountingIntegration();

  const {
    loading: xeroLoading,
    getIntegrationPartnerSettings,
    integrationPartners,
  } = useIntegrationPartners();

  useEffect(() => {
    getIntegrationPartnerSettings({ appName: IntegrationApps.XERO });
  }, [getIntegrationPartnerSettings]);

  useEffect(() => {
    async function redirectToXero() {
      if (accountingPartnerConsentUrl) {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(accountingPartnerConsentUrl);

        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(accountingPartnerConsentUrl);
        } else {
          showNotification({
            message: translate('backOfficeFeatures.invalidUrl'),
            error: true,
          });
        }
      }
    }
    redirectToXero();
  }, [accountingPartnerConsentUrl, showNotification, translate]);

  useEffect(() => {
    async function getURL() {
      const url = await Linking.getInitialURL();
      const isValidOAuthCallBackURL =
        !url?.includes('error=access_denied') &&
        url?.includes('code') &&
        url?.includes('state') &&
        url?.includes('scope');

      if (url && isValidOAuthCallBackURL) {
        saveIntegrationDetails(url);
      }
    }
    getURL();
  }, [saveIntegrationDetails]);

  const integrationPartnerData = Object.values(integrationPartners)[0];

  const onConfirm = useCallback(() => {
    disconnectAccountingIntegration(IntegrationApps.XERO);
    closeModal();
  }, [disconnectAccountingIntegration, closeModal]);

  const showConfirmationModal = useCallback(() => {
    showModal(
      <ConfirmationModal
        onConfirm={onConfirm}
        title={translate('backOfficeFeatures.disableConfirmationMessage')}
        confirmLabel={translate('settings.confirm')}
        message={translate('backOfficeFeatures.disableConfirmationDescription')}
      />,
      { onBackdropPress: closeModal },
    );
  }, [showModal, translate, closeModal, onConfirm]);

  const toggleAccountingPartnerConnection = useCallback(() => {
    if ((integrationData || integrationPartnerData)?.isActive) {
      showConfirmationModal();
    } else {
      getAccountingPartnerConsentUrl();
    }
  }, [
    getAccountingPartnerConsentUrl,
    integrationData,
    integrationPartnerData,
    showConfirmationModal,
  ]);

  return (
    <ScreenLayout
      hideFooter
      title="Xero | Oolio"
      loading={loading || accountingIntegrationLoading || xeroLoading}
    >
      <FeatureDetail
        feature={featureData}
        active={(integrationData || integrationPartnerData)?.isActive}
        onEnable={toggleAccountingPartnerConnection}
      />
    </ScreenLayout>
  );
};
