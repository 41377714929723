import React, { useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { jobRoleSettingsUtility } from '../../../../../state/jobRoleSettingsUtility';
import Search from '../../../../../components/Shared/Search/Search';

const SearchPermissionInput = () => {
  const { translate } = useTranslation();
  const [searchText, setSearchText] = useState('');

  const onChangeSearchText = (value: string) => {
    setSearchText(value);
    jobRoleSettingsUtility.search = value;
  };

  return (
    <Search
      testID="search-permission-input"
      value={searchText}
      onChangeText={onChangeSearchText}
      placeholder={translate('backofficeManageRoles.form.searchPermission')}
      // eslint-disable-next-line react-native/no-inline-styles
      containerStyle={{ marginBottom: 20 }}
    />
  );
};

export default SearchPermissionInput;
