import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
    justifyContent: 'center',
  },
  container: {
    width: 540,
    height: 410,
    alignSelf: 'center',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.m,
    padding: 40,
  },
  cameraContainer: {
    width: 540,
    height: 592,
    alignSelf: 'center',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.m,
    padding: 40,
  },
  cameraWrapper: {
    width: 400,
    height: 300,
    marginTop: 10,
    backgroundColor: theme.colors.blueDark,
    borderRadius: theme.radius.m,
  },
  camera: {
    width: 400,
    height: 280,
    marginTop: 10,
    overflow: 'hidden',
    borderRadius: theme.radius.m,
  },
  icon: {
    height: 60,
    width: 60,
    borderRadius: 40,
    borderWidth: 2,
    borderColor: theme.colors.states.neutral,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.states.neutralXL,
  },
  title: {
    fontSize: 20,
    color: theme.colors.black,
    fontFamily: theme.fonts.bold,
    lineHeight: 20,
    textAlign: 'center',
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 14,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
    lineHeight: 20,
    textAlign: 'center',
  },
  input: {
    flex: 1,
  },
  inputWrapper: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 6,
    marginTop: 20,
    justifyContent: 'space-between',
  },
  errorText: {
    paddingTop: 6,
    paddingLeft: 12,
    color: theme.colors.red,
  },
  btnWrapper: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
  },
  btnWrapperCameraMode: {
    flexDirection: 'row',
    width: 420,
    marginTop: 20,
  },
  btn: {
    flex: 1,
    height: 44,
    paddingHorizontal: 6,
  },
  iconBtn: {
    width: 44,
    height: 44,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.neutralXL,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
});

export default styles;
