import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    rowGap: 4,
  },
  parent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    columnGap: 8,
    borderRadius: theme.radius.s,
  },
  icon: {
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
  title: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
  titleText: {
    fontSize: 13,
    lineHeight: 20,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
  links: {
    paddingLeft: 28,
    rowGap: 4,
  },
  link: {
    flex: 1,
    paddingLeft: 8,
    borderRadius: theme.radius.s,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
    position: 'relative',
  },
  linkText: {
    fontSize: 13,
    lineHeight: 28,
    color: theme.colors.grey3,
    fontFamily: theme.fonts.medium,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: theme.colors.states.focus,
    position: 'absolute',
    top: 10,
    left: -6,
  },
});

export default styles;
