import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.s,
    borderColor: theme.colors.grey3,
    overflow: 'hidden',
  },
  pickerStyle: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    paddingLeft: 8,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.regular,
  },
  secondaryContainer: {
    height: 38,
    marginHorizontal: 2,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    overflow: 'hidden',
  },
});

export default styles;
