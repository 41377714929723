import { gql } from '@apollo/client';

const featureQuery = `
    id
    featureId
    name
    category
    title
    description
    app
    icon
    context
    enabled
    backgroundImageUrl
    subscriptionLevel
    createdBy
    subFeatureList {
      name
      description
    }
    screenshots
    about
    webhookUrl
    venueIds
`;

export const GET_FEATURES = gql`
  query features {
    features {
      ${featureQuery}
    }
  }
`;

export const GET_ACCOUNT_FEATURE_SETTINGS = gql`
  query customerAccountFeatureSettings($featureId: String!) {
    customerAccountFeatureSettings(featureId: $featureId) {
      id
      featureId
      paymentDetails
      additionalNotes
    }
  }
`;

export const GET_INTEGRATIONS = gql`
  query integrations {
    integrations {
      ${featureQuery}
    }
  }
`;

export const TOGGLE_FEATURE = gql`
  mutation toggleFeature($input: ToggleFeatureInput!) {
    toggleFeature(input: $input){
      ${featureQuery}
    }
  }
`;

export const CREATE_UPDATE_ACCOUNT_FEATURE_SETTINGS = gql`
  mutation CreateOrUpdateFeatureCustomerAccountSettings(
    $input: CustomerAccountFeatureSettingsInput!
  ) {
    createOrUpdateFeatureCustomerAccountSettings(input: $input) {
      id
      featureId
      paymentDetails
      additionalNotes
    }
  }
`;
