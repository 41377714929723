import React from 'react';
import { FilterValue, FilterObject } from '@oolio-group/domain';
import { FiltersStyles } from '../styles/Component.styles';
import { ScrollView, Text, View } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import IconButton from '../../../../components/Button/IconButton';
import { FilterInput } from './FilterInput';
import Button from '../../../../components/Button/Button';
import { DropDownFilter } from '../types';

interface FilterProps {
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  toggleFilters: () => void;
  updateFilters: (filter: string, value: string[]) => void;
  resetFilters: () => void;
}

export const Filters: React.FC<FilterProps> = ({
  filterOptions,
  filters,
  toggleFilters,
  updateFilters,
  resetFilters,
}) => {
  const { translate } = useTranslation();
  const styles = FiltersStyles();
  return (
    <View style={styles.pageStyle}>
      <View style={styles.headerStyle}>
        <Text style={styles.headerTextStyle}>
          {translate('backOfficeReports.filters.header')}
        </Text>
        <View style={styles.headerCloseIconStyle}>
          <IconButton
            testID="close-icon"
            icon="times"
            onPress={() => toggleFilters()}
            primary
            containerStyle={styles.headerCloseIconStyleContainer}
            containerSize={25}
            iconSize={25}
          ></IconButton>
        </View>
      </View>
      <ScrollView style={styles.filterContainer}>
        {filterOptions.map((filter, index) => (
          <FilterInput
            key={index}
            index={index}
            options={filter.values as FilterObject[]}
            name={filter.key}
            title={filter.title}
            selectedValues={filters[filter.key as keyof FilterValue] || []}
            updateFilters={updateFilters}
          />
        ))}
      </ScrollView>
      <View style={styles.actionsContainerStyle}>
        <Button
          fluid
          testID="reset-filters"
          title={translate('backOfficeReports.filters.resetBtn')}
          containerStyle={styles.saveButtonStyle}
          labelStyle={styles.titleStyle}
          onPress={resetFilters}
        />
      </View>
    </View>
  );
};
