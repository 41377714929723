/* eslint-disable @typescript-eslint/no-explicit-any */
import { Order, OrderEvent } from '@oolio-group/domain';

export interface OrderStoreState {
  currentState: Order | undefined;
  error: string;
  pendingEvents: OrderEvent[];
  originalState: Order | undefined;
  isDirty: boolean;
}

export interface OrderStore {
  setState: {
    (key: keyof OrderStoreState, value: any): void;
    (updateFunction: (prevState: OrderStoreState) => OrderStoreState): void;
  };
  getSnapshot: () => OrderStoreState;
  subscribe: (listener: () => void) => () => void;
  select: <T>(selector: (state: OrderStoreState) => T) => T;
}

const createOrderStore = (initialState: OrderStoreState): OrderStore => {
  let state = initialState;
  const listeners = new Set<() => void>();

  function subscribe(listener: () => void) {
    listeners.add(listener);

    return () => {
      listeners.delete(listener);
    };
  }

  function getSnapshot() {
    return state;
  }

  function setState(key: keyof OrderStoreState, value: any): void;
  function setState(
    updateFunction: (prevState: OrderStoreState) => OrderStoreState,
  ): void;
  function setState(
    arg1:
      | keyof OrderStoreState
      | ((prevState: OrderStoreState) => OrderStoreState),
    arg2?: any,
  ) {
    if (typeof arg1 === 'function') {
      state = { ...state, ...arg1(state) };
      updateState(state);
    } else {
      state = {
        ...state,
        [arg1]: arg2,
      };
      updateState(state);
    }
  }

  function updateState(newState: OrderStoreState) {
    state = newState;
    listeners.forEach(listener => listener());
  }

  function select(selector: (state: OrderStoreState) => any) {
    return selector(state);
  }

  return {
    setState,
    getSnapshot,
    subscribe,
    select,
  };
};

export const globalOrderStore = createOrderStore({
  currentState: undefined,
  error: '',
  pendingEvents: [],
  originalState: undefined,
  isDirty: false,
});

export const createNewOrderStore = () => {
  return createOrderStore({
    currentState: undefined,
    error: '',
    pendingEvents: [],
    originalState: undefined,
    isDirty: false,
  });
};
