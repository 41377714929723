import React, { useState, useCallback, useMemo, useRef } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  ScrollView,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import theme from '../../../common/default-theme';
import styles from './Select.styles';
import Search from '../Search/Search';
import Icon from '../../Icon/Icon';

export type SelectMultipleOption = {
  value: string;
  label: string;
  isSelected?: boolean;
};

interface SelectedItem {
  name: string;
  clearable?: boolean;
  onRemove: () => void;
}

export interface SelectMultipleProps {
  testID: string;
  title?: string;
  disabled?: boolean;
  clearable?: boolean;
  options: SelectMultipleOption[];
  selectedValues?: string[];
  containerStyle?: ViewStyle;
  onAddItem?: (name: string) => void;
  onValueChange?: (selectedValues: string[]) => void;
  deleteRequestModal?: (
    functionCallback: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
  addRequestModal?: (
    functionCallback: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
  itemRender?: (
    item: SelectMultipleOption,
    isSelected: boolean,
    onChange: (status: boolean, value: string) => void,
  ) => React.ReactElement;
}

export const SelectedItem: React.FC<SelectedItem> = ({
  name,
  clearable,
  onRemove,
}: SelectedItem) => {
  return (
    <TouchableOpacity
      onPress={onRemove}
      disabled={!clearable}
      style={styles.valueContainer}
    >
      <Text style={styles.value} numberOfLines={1}>
        {name}
      </Text>
      {clearable && (
        <Icon
          name="times"
          size={16}
          color={theme.colors.blue}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ paddingLeft: 8 }}
        />
      )}
    </TouchableOpacity>
  );
};

const SelectMultiple: React.FC<SelectMultipleProps> = ({
  testID,
  title,
  disabled,
  clearable,
  options,
  selectedValues: selectedOptions,
  containerStyle,
  onAddItem,
  onValueChange,
  deleteRequestModal,
  addRequestModal,
  itemRender,
}: SelectMultipleProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchString, setSearchString] = useState('');

  const tabsToRender = 2;

  const addItem = useCallback((): void => {
    if (searchString && onAddItem) {
      onAddItem(searchString);
    }
  }, [onAddItem, searchString]);

  const handleAddItem = useCallback(() => {
    addItem();
  }, [addItem]);

  const onSearchTextChange = useCallback((value): void => {
    setSearchString(value);
  }, []);

  const searchResults = useMemo(() => {
    if (searchString !== '' && options && options.length) {
      const searchLabel = options.filter((eachValue: SelectMultipleOption) =>
        eachValue.label.toLowerCase().includes(searchString.toLowerCase()),
      );
      return searchLabel;
    } else {
      return options;
    }
  }, [searchString, options]);

  const onToggleOptions = useCallback((): void => {
    !disabled && setShowOptions(!showOptions);
    setSearchString('');
  }, [showOptions, disabled]);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
    setSearchString('');
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const filteredOptions = useMemo(() => {
    return searchResults;
  }, [searchResults]);

  const onChangeValues = useCallback(
    async (selected, id) => {
      let newSelectedValues = [] as string[];
      if (selectedOptions) {
        newSelectedValues = [...selectedOptions];
        const index = newSelectedValues.indexOf(id);
        if (selected && index <= -1) {
          newSelectedValues.push(id);
        } else {
          newSelectedValues.splice(index, 1);
        }
      } else {
        newSelectedValues.push(id);
      }

      onValueChange && (await onValueChange(newSelectedValues));
    },
    [selectedOptions, onValueChange],
  );

  const onChange = useCallback(
    async (selected, id) => {
      if (disabled) return;
      let newSelectedValues = [] as string[];
      if (selectedOptions) {
        newSelectedValues = [...selectedOptions];
        const index = newSelectedValues.indexOf(id);

        if (selected && index <= -1) {
          addRequestModal
            ? addRequestModal(onChangeValues, selected, id)
            : newSelectedValues.push(id);
        } else {
          deleteRequestModal
            ? deleteRequestModal(onChangeValues, selected, id)
            : newSelectedValues.splice(index, 1);
        }
      } else {
        newSelectedValues.push(id);
      }

      if (!addRequestModal && !deleteRequestModal) {
        onValueChange && (await onValueChange(newSelectedValues));
      }
    },
    [
      disabled,
      selectedOptions,
      addRequestModal,
      deleteRequestModal,
      onChangeValues,
      onValueChange,
    ],
  );

  const selectedItems = useMemo(() => {
    const itemsCanFit = Math.floor(tabsToRender);
    const selectedObjects = [] as SelectMultipleOption[];
    if (selectedOptions && selectedOptions.length > 0) {
      selectedOptions.forEach(selectedSection => {
        options.forEach(section => {
          if (section.value === selectedSection) {
            selectedObjects.push(section);
          }
        });
      });
    }
    if (!selectedObjects.length) {
      return <Text style={styles.placeholder}>Select...</Text>;
    } else if (
      selectedObjects.length > 0 &&
      selectedObjects.length <= itemsCanFit
    ) {
      return selectedObjects.map((item, index) => (
        <SelectedItem
          key={index}
          name={item.label}
          clearable={clearable}
          onRemove={() => onChange(item.isSelected, item.value)}
        />
      ));
    } else if (selectedObjects.length > itemsCanFit) {
      const otherItemText = `+${selectedObjects.length - (itemsCanFit - 1)}`;
      return (
        <>
          {selectedObjects.slice(0, itemsCanFit - 1).map((item, index) => (
            <SelectedItem
              key={index}
              name={item.label}
              clearable={clearable}
              onRemove={() => onChange(item.isSelected, item.value)}
            />
          ))}
          <View style={styles.valueContainer}>
            <Text style={styles.value}>{otherItemText}</Text>
          </View>
        </>
      );
    } else return;
  }, [selectedOptions, options, clearable, onChange]);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const touchable = useRef(null);

  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <TouchableOpacity
          ref={touchable}
          testID={testID}
          onPress={onToggleOptions}
          onBlur={onBlur}
          disabled={disabled}
          style={[
            styles.inputContainer,
            showOptions
              ? theme.containers.active
              : disabled
              ? theme.containers.disabled
              : theme.containers.enabled,
          ]}
        >
          <View style={styles.values}>{selectedItems}</View>
          {!disabled && (
            <View style={styles.accessory}>
              <Icon
                size={20}
                color={theme.colors.dark}
                name={showOptions ? 'angle-up' : 'angle-down'}
              />
            </View>
          )}
        </TouchableOpacity>
        <Popover
          from={touchable}
          isVisible={showOptions}
          placement={PopoverPlacement.AUTO}
          onRequestClose={onRequestClosePopover}
          backgroundStyle={styles.bgStyle}
          popoverStyle={styles.optionsContainer}
        >
          <View style={styles.optionsPopup}>
            <Search
              testID="search-option"
              onChangeText={onSearchTextChange}
              placeholder="Search..."
              containerStyle={styles.search}
            />
            <ScrollView>
              {filteredOptions.map((option, i: number) => {
                const isSelected =
                  (selectedOptions &&
                    selectedOptions.indexOf(option.value) !== -1) ||
                  false;

                if (itemRender) return itemRender(option, isSelected, onChange);

                return (
                  <TouchableOpacity
                    key={i}
                    style={styles.option}
                    onPress={(): Promise<void> =>
                      onChange(!option.isSelected, option.value)
                    }
                  >
                    <Icon
                      name={isSelected ? 'check-square' : 'square-full'}
                      size={24}
                      color={
                        isSelected ? theme.colors.green : theme.colors.grey3
                      }
                    />
                    <Text style={styles.optionText}>{option.label}</Text>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
            {onAddItem && !!searchString && (
              <TouchableOpacity
                style={styles.optionCreate}
                onPress={() => handleAddItem()}
              >
                <Text
                  style={styles.optionCreateText}
                >{`Create '${searchString}'`}</Text>
                <Icon name="plus" size={20} color={theme.colors.green} />
              </TouchableOpacity>
            )}
          </View>
        </Popover>
      </View>
    </View>
  );
};

export default SelectMultiple;
