/* eslint-disable react-native/no-inline-styles */
import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { View, Text } from 'react-native';
import {
  ProductPricingInput,
  DeleteProductPricingInput,
  PricingGroup,
} from '@oolio-group/domain';
import { ProductPricing } from './Products.type';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useNotification } from '../../../../../../hooks/Notification';
import { CATEGORIES_WITH_ENTITY_VARIANT_PRODUCTS } from '../../../../../../hooks/app/categories/graphql';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { usePricingGroups } from '../../../../../../hooks/app/usePricingGroups';
import { useCategories } from '../../../../../../hooks/app/categories/useCategories';
import { useProductPricings } from '../../../../../../hooks/app/productPricings/useProductPricings';
import { find, keyBy } from 'lodash';
import { ProductsRow } from './ProductsRow';
import { ProductsFilters } from './ProductsFilters';
import { PriceListContext } from '../PriceListSettingsNavigator';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import { SetPriceModal } from '../../../../../../components/Modals/ProductPrices/SetPrices';
import Search from '../../../../../../components/Shared/Search/Search';
import Section from '../../../../../../components/Office/Section/Section';
import Message from '../../../../../../components/Office/Message/Message';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import Pagination from '../../../../../../components/Office/Pagination/Pagination';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import theme, {
  DEFAULT_PAGE_SIZE,
} from '../../../../../../common/default-theme';
import styles from '../../PriceLists.styles';

export const Products: React.FC = () => {
  const priceListContext = useContext(PriceListContext);
  const isFocused = useIsFocused();
  const route = useRoute();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { currencySymbol, unAppendCurrency } = useCurrency();
  const { categoryMaps, getCategories } = useCategories({
    fetchPolicy: 'cache-and-network',
    customFragment: CATEGORIES_WITH_ENTITY_VARIANT_PRODUCTS,
  });

  const {
    error: errorProductPrices,
    loading: loadingProductPrices,
    addBulkProductPricings,
    delete: deleteProductPricings,
    update: updateProductPricings,
  } = useProductPricings();

  const {
    pricingGroups,
    defaultPricingGroup,
    error: errorPriceLists,
    loading: loadingPriceLists,
    getPricingGroup,
    getDefaultPricingGroup,
  } = usePricingGroups();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [productPricingData, setProductPricingData] = useState<
    Record<string, ProductPricing>
  >({});
  const [deletedProductPricing, setDeletedProductPricing] = useState<
    DeleteProductPricingInput[]
  >([]);

  const params =
    priceListContext.params ||
    (route.params as {
      pricingGroupId: string;
      screen?: string;
      isDefault?: boolean;
    });

  const pricingGroupId = params?.pricingGroupId || '';
  const isDefault = params?.isDefault;

  const error = errorPriceLists || errorProductPrices;
  const loading = loadingPriceLists || loadingProductPrices;

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  const formatProductPricings = useCallback(
    (pricingGroup?: PricingGroup): Record<string, ProductPricing> => {
      if (!pricingGroup) return {};
      const productPricings = (pricingGroup?.prices || []).map(x => ({
        ...x,
        productId: x.product?.id,
        productName: x.product?.name,
      }));
      return keyBy(productPricings, 'productId');
    },
    [],
  );

  const nonDefaultProductPrices = useMemo<
    Record<string, ProductPricing>
  >(() => {
    return formatProductPricings(pricingGroups?.[pricingGroupId]);
  }, [formatProductPricings, pricingGroupId, pricingGroups]);

  useEffect(() => {
    if (!isDefault && pricingGroups && pricingGroupId) {
      setProductPricingData(nonDefaultProductPrices);
    }
  }, [isDefault, nonDefaultProductPrices, pricingGroupId, pricingGroups]);

  const standardPricesMaps = useMemo<Record<string, ProductPricing>>(() => {
    return formatProductPricings(defaultPricingGroup);
  }, [defaultPricingGroup, formatProductPricings]);

  const standardPrices = useMemo<ProductPricing[]>(() => {
    return Object.values(standardPricesMaps);
  }, [standardPricesMaps]);

  useEffect(() => {
    if (isDefault && defaultPricingGroup) {
      setProductPricingData(standardPricesMaps);
    }
  }, [defaultPricingGroup, isDefault, standardPricesMaps]);

  const categoryOptions = useMemo(() => {
    const categories = Object.values(categoryMaps || {})
      .filter(category => category.products && category.products.length > 0)
      .map(category => ({
        label: category.name,
        value: category.id,
      }));

    return [
      {
        label: translate('backOfficeProducts.productFilterByCategory'),
        value: '',
      },
      ...categories,
    ];
  }, [categoryMaps, translate]);

  const productCategoryMap = useMemo(() => {
    const map = {};
    Object.values(categoryMaps || {}).forEach(category => {
      category.products.forEach(product => {
        map[product.id] = category.id;
      });
    });
    return map;
  }, [categoryMaps]);

  useEffect(() => {
    if (!defaultPricingGroup && isFocused) {
      getDefaultPricingGroup();
    }
  }, [defaultPricingGroup, getDefaultPricingGroup, isFocused]);

  useEffect(() => {
    if (!isFocused) return;
    getCategories();
  }, [getCategories, isFocused]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const priceListProducts = useMemo(() => {
    return Object.values(productPricingData).filter(
      x => x.product?.id,
    ) as ProductPricing[];
  }, [productPricingData]);

  const selectedProductPrices = useMemo(() => {
    return priceListProducts.filter(x => x.isSelected);
  }, [priceListProducts]);

  const selectAllStatus = useMemo(() => {
    if (find(priceListProducts, { isSelected: true })) {
      return true;
    }
    return false;
  }, [priceListProducts]);

  const onSetPrices = useCallback(
    (newPrice: number) => {
      const tempObj = {} as Record<string, ProductPricing>;
      selectedProductPrices.forEach(x => {
        tempObj[x.productId] = {
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: newPrice,
          },
          isChanged: true,
        };
      });
      setProductPricingData(prev => {
        return {
          ...prev,
          ...tempObj,
        };
      });
      showNotification({
        success: true,
        message: translate('pricings.operationSetSuccess', {
          value: currencySymbol + newPrice,
        }),
      });
    },
    [currencySymbol, selectedProductPrices, showNotification, translate],
  );

  const setPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <SetPriceModal
          onSubmit={newPrice => {
            onSetPrices(newPrice);
            closeModal();
          }}
          title="set prices"
          mode="set"
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onSetPrices,
  ]);

  const onModifyPrices = useCallback(
    (input: number, sign: boolean, percent: boolean) => {
      const tempObj = {} as Record<string, ProductPricing>;
      selectedProductPrices.forEach(x => {
        const currentPrice = x.sellingPrice.amount;
        let updatedPrice = 0;
        if (percent) {
          const priceVariation = (currentPrice * input) / 100;
          updatedPrice = sign
            ? currentPrice + priceVariation
            : currentPrice - priceVariation;
        } else {
          updatedPrice = sign ? currentPrice + input : currentPrice - input;
        }
        tempObj[x.productId] = {
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: Math.round(updatedPrice * 100) / 100,
          },
          isChanged: true,
        };
      });
      setProductPricingData(prev => {
        return {
          ...prev,
          ...tempObj,
        };
      });
      showNotification({
        success: true,
        message: translate('pricings.operationModifySuccess', {
          value: (sign ? '+' : '-') + input + (percent ? '%' : currencySymbol),
        }),
      });
    },
    [selectedProductPrices, showNotification, translate, currencySymbol],
  );

  const modifyPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <SetPriceModal
          onSubmit={(input, sign, percent) => {
            onModifyPrices(input, sign, percent);
            closeModal();
          }}
          title="modify prices"
          mode="modify"
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onModifyPrices,
  ]);

  const onResetPrices = useCallback(() => {
    const tempObj = {} as Record<string, ProductPricing>;
    selectedProductPrices.forEach(x => {
      tempObj[x.productId] = {
        ...x,
        sellingPrice:
          standardPricesMaps[x.product.id]?.sellingPrice || x.sellingPrice,
        isChanged: true,
      };
    });
    setProductPricingData(prev => {
      return {
        ...prev,
        ...tempObj,
      };
    });
    showNotification({
      success: true,
      message: translate('pricings.operationResetSuccess'),
    });
  }, [selectedProductPrices, standardPricesMaps, showNotification, translate]);

  const resetPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <ConfirmationModal
          title={translate('pricings.resetTitle')}
          confirmLabel={translate('pricings.resetActionTitle')}
          message={translate('pricings.resetMessage')}
          onConfirm={() => {
            onResetPrices();
            closeModal();
          }}
        />,
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onResetPrices,
  ]);

  const onChange = useCallback(
    (id, prop, value) => {
      if (prop === 'sellingPrice') {
        setProductPricingData(prev => {
          const sellingPrice = prev[id]?.sellingPrice;
          return {
            ...prev,
            [id]: {
              ...prev[id],
              costPrice: standardPricesMaps[id].costPrice,
              [prop]: {
                ...sellingPrice,
                amount: unAppendCurrency(value),
              },
              isChanged: true,
            },
          };
        });
      } else if (prop === 'isSelected') {
        setProductPricingData(prev => {
          return {
            ...prev,
            [id]: {
              ...prev[id],
              isSelected: value,
            },
          };
        });
      }
    },
    [standardPricesMaps, unAppendCurrency],
  );

  const selectToggle = useCallback(
    (isSelected): void => {
      const tempObj = {} as Record<string, ProductPricing>;
      priceListProducts.forEach(x => {
        tempObj[x.productId] = {
          ...x,
          isSelected: isSelected,
        };
      });
      setProductPricingData(tempObj);
    },
    [priceListProducts],
  );

  const changedProductPricings = useMemo(() => {
    return priceListProducts.filter(x => x && x.isChanged);
  }, [priceListProducts]);

  useEffect(() => {
    if (pricingGroupId && isFocused && !isDefault) {
      getPricingGroup(pricingGroupId);
    }
  }, [getPricingGroup, pricingGroupId, isFocused, isDefault]);

  const addProductPricingData = useCallback(() => {
    const input = Object.values(productPricingData)
      .filter(x => x.isNew)
      .map(x => {
        const productId = x.productId;
        const sellingTax = x.sellingTax?.id;
        const costPrice = x.costPrice;

        const newProductPricing = stripProperties(
          x,
          'isSelected',
          'id',
          'isNew',
          'isChanged',
          '__typename',
          'isActive',
          'pricingGroup',
          'productId',
          'costPrice',
          'productName',
        );

        return {
          productId,
          pricings: [
            {
              pricingGroupId,
              productPricing: {
                ...newProductPricing,
                product: productId,
                pricingGroupId,
                sellingPrice: {
                  ...newProductPricing.sellingPrice,
                  amount: +newProductPricing?.sellingPrice?.amount,
                },
                sellingTax,
                ...(costPrice && {
                  costPrice: {
                    amount: +costPrice.amount,
                    currency: costPrice.currency,
                  },
                }),
              } as ProductPricingInput,
            },
          ],
        };
      });
    if (input.length > 0) {
      addBulkProductPricings(input);
    }
  }, [productPricingData, pricingGroupId, addBulkProductPricings]);

  const deleteProductPricing = useCallback(() => {
    if (deletedProductPricing.length > 0) {
      deleteProductPricings(deletedProductPricing);
    }
  }, [deleteProductPricings, deletedProductPricing]);

  const updateProductPricingsData = useCallback(() => {
    const updateProductPricingsInput = changedProductPricings
      .filter(x => !x.isNew)
      .map(x => ({
        id: x.id,
        sellingPrice: {
          amount: +x.sellingPrice.amount,
          currency: x.sellingPrice.currency,
        },
        ...(x.costPrice && {
          costPrice: {
            amount: +x.costPrice.amount,
            currency: x.costPrice.currency,
          },
        }),
        product: x.product.id,
        pricingGroupId,
      })) as ProductPricingInput[];
    if (updateProductPricingsInput.length > 0) {
      updateProductPricings(updateProductPricingsInput);
    }
  }, [changedProductPricings, updateProductPricings, pricingGroupId]);

  const onPressSave = useCallback((): void => {
    if (
      !changedProductPricings.length &&
      !deletedProductPricing.length &&
      !Object.values(productPricingData).some(x => x.isNew)
    ) {
      showNotification({
        error: true,
        message: translate('pricings.noRecordsChanged'),
      });
    } else {
      deleteProductPricing();
      addProductPricingData();
      updateProductPricingsData();
    }
  }, [
    changedProductPricings,
    deletedProductPricing,
    updateProductPricingsData,
    addProductPricingData,
    deleteProductPricing,
    translate,
    showNotification,
    productPricingData,
  ]);

  const onPressRemoveProduct = useCallback(
    (id: string) => {
      if (isDefault) {
        showNotification({
          error: true,
          message: translate('pricings.defaultPricingGroupError'),
        });
      } else {
        if (nonDefaultProductPrices && nonDefaultProductPrices[id]) {
          setDeletedProductPricing(prev => [
            ...prev,
            { productId: id, pricingGroupId },
          ]);
        }
        setProductPricingData(prev => {
          const temp = { ...prev };
          delete temp[id];
          return temp;
        });
      }
    },
    [
      isDefault,
      showNotification,
      translate,
      nonDefaultProductPrices,
      pricingGroupId,
    ],
  );

  const onPressAddProduct = useCallback(
    (productId: string) => {
      const isExistsInState = productPricingData?.[productId];
      if (isExistsInState?.id) {
        onPressRemoveProduct(productId);
        showNotification({
          error: true,
          message: translate('pricings.productPricingsDeletedSuccessfully'),
        });
      } else {
        const pricingData = standardPricesMaps[productId];
        setProductPricingData(prev => ({
          ...prev,
          [productId]: { ...pricingData, isNew: true },
        }));
        const remainingProductPricingToDelete = deletedProductPricing.filter(
          x => x.productId !== productId,
        );
        setDeletedProductPricing(remainingProductPricingToDelete);
      }
    },
    [
      productPricingData,
      onPressRemoveProduct,
      showNotification,
      translate,
      standardPricesMaps,
      deletedProductPricing,
    ],
  );

  const onPressAddAllProducts = useCallback(
    (productIds: string[]) => {
      const newProductIds = productIds.filter(
        productId => !productPricingData?.[productId],
      );

      const newProductPricingData = newProductIds.reduce((acc, id) => {
        const pricingData = standardPricesMaps[id];
        return {
          ...acc,
          [id]: { ...pricingData, isNew: true },
        };
      }, {} as Record<string, ProductPricing>);
      setProductPricingData(prev => ({
        ...prev,
        ...newProductPricingData,
      }));
      const recentAddProductIds = new Set(productIds);
      const remainingProductPricingToDelete = deletedProductPricing.filter(
        item => !recentAddProductIds.has(item.productId),
      );
      setDeletedProductPricing(remainingProductPricingToDelete);
    },
    [deletedProductPricing, productPricingData, standardPricesMaps],
  );

  const filteredList = useMemo(() => {
    return priceListProducts
      .filter(productPricing => {
        const matchesSearch = productPricing.product.name
          .toLowerCase()
          .includes(searchString.toLowerCase());

        const matchesCategory =
          selectedCategory === '' ||
          productCategoryMap[productPricing.product.id] === selectedCategory;

        return matchesSearch && matchesCategory;
      })
      .sort((a, b) => a.product.name.localeCompare(b.product.name));
  }, [priceListProducts, searchString, selectedCategory, productCategoryMap]);

  const pageItems = useMemo(() => {
    return filteredList.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [filteredList, currentPage]);

  const addedProductIds = useMemo<string[]>(() => {
    return priceListProducts.map(x => x.product.id);
  }, [priceListProducts]);

  // Reset current page if user deletes items
  useEffect(() => {
    const pages = Math.ceil(filteredList.length / DEFAULT_PAGE_SIZE);
    setCurrentPage(currentPage => {
      if (currentPage > pages) {
        return 1;
      }
      return currentPage;
    });
  }, [filteredList.length]);

  return (
    <ScreenLayout
      loading={loading}
      onSave={onPressSave}
      hideFooter={isDefault}
      title={pricingGroups[pricingGroupId]?.name || 'Price List'}
    >
      <Section layoutWidth="large">
        {isDefault ? (
          <View>
            <Message
              type="neutral"
              message={translate('pricings.defaultPricingText')}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={{ marginBottom: 20 }}
            />
            <View style={styles.filters}>
              <TreatPicker
                testID="select-category"
                options={categoryOptions}
                selectedValue={selectedCategory}
                onValueChange={setSelectedCategory}
                containerStyle={styles.dropdown}
              />
              <Search
                testID="search-products"
                value={searchString}
                onChangeText={setSearchString}
                placeholder={translate('pricings.searchProducts')}
                containerStyle={styles.search}
              />
            </View>
          </View>
        ) : (
          <ProductsFilters
            isDefault={isDefault}
            standardPrices={standardPrices}
            addedProducts={addedProductIds}
            categoryOptions={categoryOptions}
            selectedCategory={selectedCategory}
            setPrices={setPrices}
            modifyPrices={modifyPrices}
            resetPrices={resetPrices}
            onPressAdd={onPressAddProduct}
            onPressAddAll={onPressAddAllProducts}
            onChangeCategory={setSelectedCategory}
            categoryMaps={categoryMaps}
          />
        )}
        <View style={styles.table}>
          <View style={theme.tables.header}>
            {!isDefault && (
              <InputToggle
                testID="toggle-all"
                isToggled={selectAllStatus}
                isPartial={
                  selectedProductPrices.length > 0 &&
                  selectedProductPrices.length !== priceListProducts.length
                }
                onToggle={() => selectToggle(!selectAllStatus)}
                containerStyle={{ marginLeft: -12 }}
              />
            )}
            <Text style={[theme.tables.headerText, styles.cellProduct]}>
              {translate('pricings.productName')}
            </Text>
            <Text
              style={[
                theme.tables.headerText,
                styles.cellPrice,
                isDefault && { textAlign: 'right', paddingRight: 8 },
              ]}
            >
              {translate('pricings.defaultPrice')}
            </Text>
            {!isDefault && (
              <>
                <Text style={[theme.tables.headerText, styles.cellPrice]}>
                  {translate('pricings.newPrice')}
                </Text>
                <Text style={[theme.tables.headerText, styles.cellPrice]}>
                  {translate('pricings.changePrice')}
                </Text>
                <View style={{ marginRight: 32 }} />
              </>
            )}
          </View>
          <View>
            {pageItems.length > 0 ? (
              pageItems.map((product, i: number) => (
                <ProductsRow
                  key={`${i}-${product.id}`}
                  rowIndex={i}
                  defaultPricing={standardPricesMaps[product.productId]}
                  productPricing={product}
                  isDefault={isDefault}
                  onChange={onChange}
                  onDeleteRow={onPressRemoveProduct}
                />
              ))
            ) : (
              <View style={theme.tables.emptyView}>
                <Text testID="empty-message" style={theme.tables.emptyText}>
                  {isDefault
                    ? translate('common.noMatches', { entity: searchString })
                    : priceListProducts.length < 1
                    ? translate('pricings.noProductPricingMessage')
                    : translate('backOfficeProducts.emptyTable')}
                </Text>
              </View>
            )}
          </View>
          <Pagination
            page={currentPage}
            dataLength={filteredList.length}
            onPageChange={setCurrentPage}
            entityName={translate('backOfficeProducts.products')}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
