import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 485,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  groups: {
    width: 260,
    padding: 20,
    backgroundColor: theme.colors.grey1,
  },
  product: {
    marginBottom: 20,
    flexDirection: 'row',
    borderBottomWidth: 1,
    paddingLeft: 12,
    paddingVertical: 12,
    borderBottomColor: theme.colors.grey3,
  },
  productText: {
    fontSize: 14,
    lineHeight: 16,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
    paddingTop: 5,
  },
  backIconContainerStye: {
    backgroundColor: theme.colors.white,
    justifyContent: 'center',
    marginRight: 20,
  },
  group: {
    padding: 12,
    marginBottom: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  groupActive: {
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.accent,
    ...theme.shadow.strong,
  },
  comboGroup: {
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedComboProductTextContainer: {
    paddingVertical: 5,
    backgroundColor: theme.colors.grey2,
    borderRadius: theme.radius.s,
  },
  selectedComboProductText: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 12,
  },
  notes: {
    color: theme.colors.orange,
    fontSize: 12,
  },
  selectedComboProductModifierText: {
    color: theme.colors.grey6,
    fontSize: 12,
  },
  comboOption: {
    flex: 1,
    marginBottom: 12,
  },
  comboOptionBorder: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.grey3,
  },
  row: {
    flexDirection: 'row',
  },
  text: {
    flex: 1,
    marginRight: 6,
  },
  title: {
    fontSize: 14,
    lineHeight: 14,
    paddingBottom: 10,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
    opacity: 0.6,
  },
  activeText: {
    color: theme.colors.white,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
    paddingBottom: 5,
  },
  modifiers: {
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.grey5,
    fontFamily: theme.fonts.medium,
    paddingBottom: 5,
  },
  icon: {
    width: 20,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    flex: 1,
    width: 680,
    padding: 20,
  },
  bodyTitle: {
    padding: 12,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    justifyContent: 'space-between',
    backgroundColor: theme.colors.redLight,
    flex: 1,
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: 8,
    borderRadius: 10,
    backgroundColor: theme.colors.red,
  },
  bodyTitleText: {
    flex: 1,
    fontFamily: theme.fonts.medium,
  },
  gridScroll: {
    maxHeight: 320,
    marginBottom: 10,
  },
  grid: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  actions: {
    height: 44,
    marginTop: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  noteContainer: {
    alignItems: 'flex-end',
    marginVertical: 10,
  },
  notesLabel: {
    color: theme.colors.blue,
    fontSize: 12,
  },
  btnCart: {
    width: 250,
    marginLeft: 10,
  },
  nextBtn: {
    marginLeft: 10,
  },
  btnClear: {
    color: theme.colors.states.negative,
  },
  formattingSelection: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: theme.radius.s,
    minWidth: 80,
    justifyContent: 'center',
  },
  optionInfoTextContainer: {
    flexDirection: 'row',
  },
  resetOptions: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.redLight,
    height: 50,
    width: 40,
    borderRadius: theme.radius.s,
    marginLeft: 5,
  },
});

export default styles;
