import { EntityType } from '@oolio-group/domain';

export interface EditMenuState {
  menuItems: TileItem[];
  originalMenuItems: TileItem[];
  pageItems: TileItem[];
  originalPageItems: TileItem[];
  currentMenuItemSection: number;
  currentPageItemsSection: number;
  selectedMenuItemId: string;
  selectedProduct: string;
  viewingNestedPageIds: string[];
  assigningCategoryIds: string[];
  originalAssignedCategoryIds: string[];
}

export interface EditMenuPayload {
  id: string;
  data: TileItem[];
  selectedMenuItemId: string;
  page: number;
  position: number;
  updateItem: TileItem;
  clearEntityId: string;
  selectedCategory: string;
  nestedPageId: string;
  originalData: Partial<TileItem>[];
  originalAssignedCategoryIds: string[];
  assigningCategoryIds: string[];
}

export enum EditMenuAction {
  ON_CHANGE_MENU_ITEMS_PAGINATION,
  UPDATE_MENU_ITEM_DATA,
  UPDATE_MENU_ITEM_DATA_FROM_SERVER,
  UPDATE_SELECTED_MENU_ITEM,
  INSERT_MENU_ITEMS,
  CLEAR_MENU_ITEM_TILE,
  UPDATE_MENU_ITEM_INFO,

  UPDATE_PAGE_ITEM_DATA,
  UPDATE_PAGE_ITEM_DATA_FROM_SERVER,
  INSERT_PAGE_ITEMS,
  ON_CHANGE_PAGE_ITEM_PAGINATION,
  ON_VIEW_A_PAGE_ITEM,
  CLEAR_PAGE_ITEM_TILE,
  CLEAR_SAME_PAGE_ITEM_TILE,

  RESET_SAVE_CHANGES,

  VIEW_NESTED_PAGE,

  VIEW_PREVIOUS_PAGE,
}

export enum PageItemAction {
  ADD_EXISTING_PRODUCT,
  ADD_NEW_PRODUCT,
  ADD_EXISTING_PAGE,
  ADD_NEW_PAGE,
  ADD_FROM_CATEGORY,
  VIEW_PAGE,
  CLEAR_TILE,
  EDIT_TILE,
  EDIT_PAGE_TILE,
  MOVE_NEXT_SECTION,
  MOVE_PREVIOUS_SECTION,
}

export enum MenuItemAction {
  VIEW_PAGE,
  EDIT_PAGE,
  CLEAR_TILE,

  ADD_EXISTING_PAGE,
  ADD_NEW_PAGE,
}

export type ProductItemType = {
  name: string;
  id: string;
  entityType: EntityType;
  color?: string;
  category?: string;
};

export interface TileItem {
  key: string;
  name?: string;
  color?: string;
  id?: string;
  entityId?: string;
  category?: string;
  entityType?: string;
  disabledReSorted?: boolean;
  priority?: number;
  available?: boolean;
}

export interface MenuScheduleItem {
  active: boolean;
  menu: string;
  orderType: string;
  store: string;
}
