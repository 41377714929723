import { gql } from '@apollo/client';

export const IMPORT_PRODUCT = gql`
  mutation importProducts($file: FileUploadInput!) {
    importProducts(file: $file)
  }
`;

export const GET_IMPORT_ENTITY_DETAIL = gql`
  query getImportEntityDetail($id: String!) {
    getImportEntityDetail(id: $id) {
      id
      successCount
      failedCount
      updateCount
      validationMessage {
        productName
        lineNo
        messages
      }
      createdAt
      fileLocation
      status
    }
  }
`;

export const GET_IMPORT_ENTITY_FILE_DATA = gql`
  query getImportEntityFileData($type: String) {
    getImportEntityFileData(type: $type)
  }
`;
