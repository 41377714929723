import React from 'react';
import { useCurrency } from '@oolio-group/localization';
import { View, Text, TouchableOpacity } from 'react-native';
import { CartRewardSelectionState } from '../Cart';
import styles from './CartReward.styles';
import theme from '../../../../common/default-theme';

export interface CartRewardProps {
  id: string;
  testID: string;
  name: string;
  quantity: number;
  amount: number;
  isSelected?: boolean;
  productId?: string;
  onSelectReward?: (reward: CartRewardSelectionState) => void;
}

const CartReward: React.FC<CartRewardProps> = ({
  id,
  testID,
  name,
  quantity,
  amount,
  isSelected,
  productId,
  onSelectReward,
}) => {
  const { formatCurrency } = useCurrency();

  const handleOnSelect = () => {
    onSelectReward && onSelectReward({ reward: id, productId });
  };

  const bgColor = isSelected ? theme.colors.states.neutral : theme.colors.white;
  const fgColor = isSelected ? theme.colors.white : theme.colors.dark;

  return (
    <TouchableOpacity
      testID={testID}
      onPress={handleOnSelect}
      style={styles.container}
    >
      <View style={[styles.quantity, { backgroundColor: bgColor }]}>
        <Text
          testID="quantity"
          style={[styles.quantityText, { color: fgColor }]}
        >
          {quantity}
        </Text>
      </View>
      <View style={[styles.name, { backgroundColor: bgColor }]}>
        <Text
          testID="name"
          style={[styles.nameText, { color: fgColor }]}
        >{`★ ${name}`}</Text>
        {!!amount ? (
          <Text testID="price" style={[styles.priceText, { color: fgColor }]}>
            {formatCurrency(amount)}
          </Text>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

export default CartReward;
