import { format } from 'date-fns';
import { IntegrationApps, Order, OrderTypeCode } from '@oolio-group/domain';
import { table, TableUserConfig } from 'table';
import { getCustomerInfo } from './customer';

export const isOnlineOrder = (order: Order) => {
  // only apply for few integration app: #5678
  return [
    IntegrationApps.OOLIO_STORE,
    IntegrationApps.DELIVERECT,
    IntegrationApps.DOSHII,
    IntegrationApps.OOM,
  ].includes(order?.integrationInfo?.app as IntegrationApps);
};

export const getOnlineOrderType = (
  order: Order,
  tableConfig: TableUserConfig,
  showCustomerName: boolean,
): string => {
  const orderTypeName = order.orderType?.name?.toLocaleUpperCase();

  let orderReceiver = '';
  if (orderTypeName == 'DINE IN') {
    orderReceiver = `Table ${order?.table?.name}`;
  } else {
    if (showCustomerName) {
      const { customerName } = getCustomerInfo(order);
      orderReceiver = customerName;
    } else {
      orderReceiver =
        order.integrationInfo?.channelOrderDisplayId || order.orderNumber;
    }
  }
  return table([[orderTypeName, orderReceiver]], tableConfig);
};

export const getPickupTime = (order: Order): string => {
  const pickUpTime = order?.dueAt || order.requiredAt || order.createdAt;

  return pickUpTime ? format(pickUpTime, 'dd-MM-yyyy hh:mm aa') : '';
};

export const getOnlineOrderDetail = (
  order: Order,
  tableConfig: TableUserConfig,
): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableData: any[] = [];
  // Order Number
  tableData.push(['Order No:', order.orderNumber]);

  //  Due At
  if (
    [
      OrderTypeCode.PICK_UP.toString(),
      OrderTypeCode.DELIVERY.toString(),
    ].includes(order.orderType.code)
  ) {
    tableData.push(['Due At:', getPickupTime(order)]);
  }

  return table(tableData, tableConfig);
};
