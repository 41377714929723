import theme from '../../../common/default-theme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  header: {
    marginTop: 20,
    marginBottom: 20,
    paddingHorizontal: 12,
  },
  title: {
    fontSize: 18,
    lineHeight: 18,
    fontFamily: theme.fonts.medium,
  },
  subtitle: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.grey6,
  },
  shortcuts: {
    flexDirection: 'row',
    gap: 10,
  },
  btnShortcut: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 14,
    paddingHorizontal: 16,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.black,
    ...theme.shadow.elevated1,
  },
  shortcutLabel: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    color: theme.colors.white,
    fontFamily: theme.fonts.regular,
  },
  grid: {
    flex: 1,
    rowGap: 20,
  },
  row: {
    flexDirection: 'row',
    columnGap: 20,
  },
});

export default styles;
