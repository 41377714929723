import React from 'react';
import styles from './DurationOptions.styles';
import theme from '../../../../../common/default-theme';

import { View, Text, TouchableOpacity } from 'react-native';

interface PauseDurationOptionsProp {
  options: { value: number; label: string }[];
  selectedOption: number;
  onSelectOption: (value: number) => void;
}

export const DurationOptions: React.FC<PauseDurationOptionsProp> = ({
  options,
  selectedOption = 0,
  onSelectOption,
}) => {
  return (
    <View style={styles.optionContainer}>
      {options.map(option => {
        const isSelected = option.value == selectedOption;
        return (
          <TouchableOpacity
            key={option.value}
            style={[
              styles.optionItemStyle,
              {
                backgroundColor: isSelected
                  ? theme.colors.states.neutral
                  : theme.colors.grey2,
              },
            ]}
            onPress={() => {
              onSelectOption(option.value);
            }}
          >
            <Text
              style={[
                styles.optionText,
                {
                  color: isSelected ? theme.colors.white : theme.colors.black1,
                },
              ]}
            >
              {option.label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
